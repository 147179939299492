import _ from 'lodash'

const checkFacture = (factures) => {
  if (!_.isNil(factures)) {
    for (let e of factures) {
      if (e.trim() === '') {
        return true
      }
    }
    return false
  } else {
    return false
  }
}

const IndexErr = (factures) => {
  const indexErr = factures
    .map((item, index) => {
      if (item.trim() === '') return index
    })
    .filter((item) => item != undefined)
  return indexErr
}

export default { checkFacture, IndexErr }
