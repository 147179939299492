import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import './EmptyTable.scss'
import PropTypes from 'prop-types'

/** An Empty table component */
export default function EmptyTable({ colspan, message }) {
  return (
    <TableBody>
      <TableRow>
        <td colSpan={colspan}>
          <div className="empty-table-container">
            <ErrorOutlineIcon />
            <h4 className="empty-table">{message ? message : 'Pas de données'}</h4>
          </div>
        </td>
      </TableRow>
    </TableBody>
  )
}
EmptyTable.propTypes = {
  colspan: PropTypes.number,
  message: PropTypes.string,
}
