import React, { useEffect, useState } from 'react'
import { GoogleMap, useLoadScript, Polyline } from '@react-google-maps/api'

import PropTypes from 'prop-types'
import { decode } from '@mapbox/polyline'
import MarkerInfo from './MarkerDetails'

const colorArray = [
  '#2979ff',
  '#ff1744',
  '#76ff03',
  '#ff00ff',
  '#ff6d00',
  '#ffff00',
  '#00e5ff',
  '#ff5252',
  '#00c853',
  '#aa00ff',
  '#ff9100',
  '#ffd600',
  '#304ffe',
  '#d50000',
  '#b2ff59',
  '#e040fb',
  '#ffab40',
  '#ffff00',
  '#00b8d4',
  '#d500f9',
  '#6200ea',
]
export default function TourMapComponent({ polyLines, tours }) {
  const [poly, setPoly] = useState([])
  const mapContainerStyle = {
    height: '100%',
    width: '100%',
  }
  const DEFAULT_UI_OPTIONS = {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  }
  const center = {
    lat: 46.227638,
    lng: 2.213749,
  }
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    googleMapsClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  })

  // const [path, setPath] = useState([])

  const onMapLoaded = () => {
    if (polyLines) {
      const decoded = polyLines.map((poly) => {
        const int = decode(poly, 6).map((e) => {
          return { lat: e[0], lng: e[1] }
        })
        return int
      })
      setPoly(decoded)
    }
  }
  useEffect(() => {
    if (polyLines) {
      const decoded = polyLines.map((poly) => {
        if (poly !== undefined && poly !== null) {
          const int = decode(poly, 6).map((e) => {
            return { lat: e[0], lng: e[1] }
          })
          return int
        }
      })
      setPoly(decoded)
    }
  }, [polyLines])
  return isLoaded ? (
    <GoogleMap
      id="tour_map"
      mapContainerStyle={mapContainerStyle}
      zoom={7}
      center={center}
      options={{
        ...DEFAULT_UI_OPTIONS,
      }}
    >
      {poly.map((e, i) => {
        return (
          <React.Fragment key={i}>
            {tours.map((tour, index) => {
              return (
                <div key={`marker_info_${index}`}>
                  <MarkerInfo index={index} onMapLoaded={onMapLoaded} tours={tour} />
                </div>
              )
            })}
            <Polyline
              id={`tour_polyline_${i}`}
              path={e}
              onLoad={onMapLoaded}
              options={{
                strokeColor: colorArray[i % colorArray.length],
                strokeWeight: 5,
              }}
            />
          </React.Fragment>
        )
      })}
    </GoogleMap>
  ) : (
    loadError && <div> Error </div>
  )
}

TourMapComponent.propTypes = {
  polyLines: PropTypes.any,
  tours: PropTypes.array,
}
