import { connect } from 'react-redux'

import Tours from '../../../components/NavFilter/components/Filter/cell/Tours'

import {
  getTourFilterList,
  getIsTourListLoading,
  getCountListTour,
  getIsTourListMoreLoading,
} from '../../../redux/filter/selectors'

import { getTourList, getTourListMore } from '../../../redux/filter/actions'

const mapStateToProps = (state) => ({
  tourList: getTourFilterList(state),
  tourListLoading: getIsTourListLoading(state),
  countListTour: getCountListTour(state),
  tourListMoreLoading: getIsTourListMoreLoading(state),
})
const mapDisptachToProps = (dispatch) => ({
  getTourList: (filter) => dispatch(getTourList(filter)),
  getTourListMore: (filter) => dispatch(getTourListMore(filter)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Tours)
