import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'

import TextComponent from './texComponent'
import { StyledCell } from './style'
const ColumnTextComponentEditable = ({
  content,
  key,
  id,
  editable,
  onChange,
  nameAttribut,
  onDelete,
  errorsCourseEditable,
  disabledColumn,
}) => {
  const [valueContent, setValueContent] = useState(content === '-' ? '0' : content)
  useEffect(() => {
    if (editable) setValueContent(content === '-' ? '0' : content)
  }, [editable])
  const onChangeValue = (valueEvent) => {
    const value = valueEvent.trim()

    if (value >= 0) {
      if (value === content) {
        onDelete([nameAttribut])
        setValueContent(value)
        return
      }
      onChange({ [`${nameAttribut}`]: Number(value) })
      setValueContent(value)
    }
  }
  return (
    <StyledCell id={id} sx={{ color: disabledColumn && '#8E8E8E' }}>
      {editable ? (
        <TextField
          id="editableText"
          value={valueContent}
          name="editableText"
          variant="outlined"
          onChange={(event) => {
            onChangeValue(event.target.value)
          }}
          error={errorsCourseEditable.includes(nameAttribut)}
          type="number"
          InputProps={{
            inputProps: {
              min: 1,
              type: 'text',
              pattern: '[0-9]*',
            },
          }}
        />
      ) : (
        <TextComponent content={content} key={key} />
      )}
    </StyledCell>
  )
}
ColumnTextComponentEditable.propTypes = {
  content: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  nameAttribut: PropTypes.string,
  onDelete: PropTypes.func,
  errorsCourseEditable: PropTypes.array,
  disabledColumn: PropTypes.bool,
}

export default ColumnTextComponentEditable
