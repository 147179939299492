import React from 'react'

import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import TourTextComponent from './TextComponent'
import { getCourseType, getCreneauHoursMinutes } from '../../../../utils/utils'
import { getDays, getHours } from '../../../../utils/dateAdapter'
export function CourseTableContent({
  deliveryDate,
  labels,
  index,
  visit,
  getChecked,
}) {
  const rowContent = {
    LDV: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-0`}
        key={0}
        text={visit?.ldv ?? 'N/A'}
        display={getChecked('Lettre de Voiture')}
      />
    ),
    estimatedArrivalTime: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-1`}
        key={1}
        text={getHours(visit?.estimatedArrivalTime) ?? 'N/A'}
        display={getChecked('Heure d’arivée')}
      />
    ),
    estimatedDepartureTime: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-2`}
        key={2}
        text={getHours(visit?.estimatedDepartureTime) ?? 'N/A'}
        display={getChecked('Heure de départ')}
      />
    ),
    courseType: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-3`}
        key={3}
        text={getCourseType(visit?.course?.courseType) ?? 'N/A'}
        display={getChecked('Type de la Course')}
      />
    ),
    type: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-4`}
        key={4}
        text={visit?.type ?? 'N/A'}
        display={getChecked('Type')}
      />
    ),
    creneauDepart: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-5`}
        key={5}
        text={
          getCreneauHoursMinutes(visit?.windowStart, visit?.windowEnd, '~') ?? 'N/A'
        }
        display={getChecked('Créneau de départ')}
      />
    ),
    creneauLivraison: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-6`}
        key={6}
        text={
          getCreneauHoursMinutes(visit?.windowStart, visit?.windowEnd, '~') ?? 'N/A'
        }
        display={getChecked('Créneau de Livraison')}
      />
    ),
    addressArrivee: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-7`}
        key={7}
        text={visit?.cachedAddress?.locationName ?? 'N/A'}
        display={getChecked('Addresse d’arivée')}
      />
    ),
    volume: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-8`}
        key={8}
        text={visit?.course?.volume ?? 'N/A'}
        display={getChecked('Volume')}
      />
    ),
    weight: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-9`}
        key={9}
        text={visit?.course?.weight ?? 'N/A'}
        display={getChecked('Poids')}
      />
    ),
    prix: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-10`}
        key={10}
        text={visit?.course?.montantPrestaHT ?? 'N/A'}
        display={getChecked('Prix')}
      />
    ),
    dropOffRank: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-11`}
        key={11}
        text={(index + 1).toString()}
        display={getChecked('DropOff Order')}
      />
    ),
    deliveryDate: (
      <TourTextComponent
        id={`table_courseTour_cell_${index}-12`}
        key={12}
        text={deliveryDate && getDays(deliveryDate)}
        display={getChecked('Jour')}
      />
    ),
  }

  return (
    <TableRow key={index}>
      {labels.map((el) => {
        return rowContent[el.id]
      })}
    </TableRow>
  )
}
CourseTableContent.propTypes = {
  labels: PropTypes.array,
  index: PropTypes.number,
  visit: PropTypes.object,
  getChecked: PropTypes.func,
  deliveryDate: PropTypes.string,
}
