import {
  ASSIGNED_STATUS,
  PROGRAMMED_STATUS,
  SUPPLY_WAIT_STATUS,
  SUPPLY_IN_PROGRESS_STATUS,
  SUPPLY_COMPLETE_STATUS,
  SCHEDULE_WAIT_STATUS,
  ACCEPTED_STATUS,
  VERS_ENLEVEMENT_STATUS,
  CHARGEMENT_STATUS,
  VERS_DECHARGEMENT_STATUS,
  ENLEVEE_STATUS,
  DECHARGEMENT_EN_COURS_STATUS,
} from './values'

export const getOrderStatus = (order) => {
  const status = order.status ? order.status.code : order.code
  const courseSource = order?.courseSource ? order.courseSource : order.code
  if (
    COURSE_STATUS.editable.includes(status) ||
    SOURCE_COURSE_EDITABLE.includes(courseSource)
  ) {
    return COURSE_EDIT_TYPE.editable
  } else if (COURSE_STATUS.semiEdit.includes(status)) {
    return COURSE_EDIT_TYPE.semiEdit
  } else if (COURSE_STATUS.partialEdit.includes(status)) {
    return COURSE_EDIT_TYPE.partialEdit
  } else {
    return COURSE_EDIT_TYPE.nonEditable
  }
}

export const COURSE_STATUS = {
  editable: [
    PROGRAMMED_STATUS.code,
    SUPPLY_WAIT_STATUS.code,
    SUPPLY_IN_PROGRESS_STATUS.code,
    SUPPLY_COMPLETE_STATUS.code,
  ],
  semiEdit: [
    ACCEPTED_STATUS.code,
    VERS_ENLEVEMENT_STATUS.code,
    CHARGEMENT_STATUS.code,
    ASSIGNED_STATUS.code,
  ],
  partialEdit: [
    VERS_DECHARGEMENT_STATUS.code,
    ENLEVEE_STATUS.code,
    DECHARGEMENT_EN_COURS_STATUS.code,
  ],
}

export const COURSE_EDIT_TYPE = {
  editable: 'EDITABLE',
  semiEdit: 'SEMI_EDITABLE',
  partialEdit: 'PARTIAL_EDITABLE',
  nonEditable: 'NON_EDITABLE',
}

export const getOrderStatusMofifiable = (order) => {
  const status = order.status ? order.status.code : order.code
  if (
    [
      SUPPLY_WAIT_STATUS.code,
      SUPPLY_IN_PROGRESS_STATUS.code,
      SUPPLY_COMPLETE_STATUS.code,
      SCHEDULE_WAIT_STATUS.code,
    ].includes(status)
  )
    return true

  return false
}

export const SOURCE_COURSE_EDITABLE = ['EUROMATIC', 'SCHMIDT']
