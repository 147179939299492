import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { replaceDotWithComma } from '../../../../../utils/floatFormatting'
import RoomIcon from '@mui/icons-material/Room'
import EuroIcon from '@mui/icons-material/Euro'
import WeightIcon from '../../../../../assets/icons/kg-measure-weight.png'
import VolumeIcon from '../../../../../assets/icons/boxes.png'
import CalendarIcon from '../../../../../assets/icons/calendarAlt.png'

import moment from 'moment'

const initialValue = {
  weight: 0,
  volume: 0,
  number: 0,
  price: 0,
}

export default function ShipmentsInfos({
  ldt,
  selectedCourses,
  shipments,
  dropOffStart,
}) {
  const [dataCounters, setDataCounters] = useState(initialValue)

  useEffect(() => {
    let accumulation = initialValue
    if (selectedCourses.length) {
      shipments
        .filter(({ code }) => selectedCourses.includes(code))
        .map(({ weight, volume, montantHT }) => {
          accumulation = {
            weight: accumulation.weight + weight || 0,
            volume: accumulation.volume + volume || 0,
            price: accumulation.price + montantHT || 0,
            number: selectedCourses.length,
          }
        })
    }
    accumulation = {
      ...accumulation,
      volumeToDisplay: getDisplayValue(accumulation.volume),
      weightToDisplay: getDisplayValue(accumulation.weight),
      priceToDisplay: getDisplayValue(accumulation.price),
    }
    setDataCounters(accumulation)
  }, [selectedCourses])

  const getDisplayValue = (value) =>
    value === 0 ? 0 : replaceDotWithComma(parseFloat(value).toFixed(2))

  return ldt ? (
    <div
      style={{
        display: 'flex',
        padding: '25px 27px',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <CellItem
        icon={
          <RoomIcon
            sx={{
              color: '#7f7f7f',
              fontSize: '20px !important',
              marginRight: '6px',
            }}
          />
        }
        content={ldt}
        unit={''}
      />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        padding: '25px 27px',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <CellItem
        icon={
          <img
            src={CalendarIcon}
            style={{
              color: '#7f7f7f',
              fontSize: '20px !important',
              marginRight: '6px',
            }}
          />
        }
        content={moment(dropOffStart).format('DD/MM/YYYY')}
        unit={''}
      />
      <CellItem
        icon={
          <RoomIcon
            sx={{
              color: '#7f7f7f',
              fontSize: '20px !important',
              marginRight: '6px',
            }}
          />
        }
        content={dataCounters.number}
        unit={'Sélectionnées'}
      />
      <CellItem
        icon={
          <img
            src={WeightIcon}
            style={{
              color: '#7f7f7f',
              fontSize: '20px',
              marginRight: '6px',
              height: '1em',
              width: '1em',
            }}
          />
        }
        content={dataCounters.weightToDisplay}
        unit={'Kg'}
      />
      <CellItem
        icon={
          <img
            src={VolumeIcon}
            style={{
              color: '#7f7f7f',
              fontSize: '20px',
              marginRight: '6px',
              height: '1em',
              width: '1em',
            }}
          />
        }
        content={dataCounters.volumeToDisplay}
        unit={'m³'}
      />
      <CellItem
        icon={
          <EuroIcon
            sx={{
              color: '#7f7f7f',
              fontSize: '20px !important',
              marginRight: '6px',
            }}
          />
        }
        content={dataCounters.priceToDisplay}
        unit={'Ht'}
      />
    </div>
  )
}
ShipmentsInfos.propTypes = {
  open: PropTypes.bool,
  hideDialog: PropTypes.func,
  selectedCourses: PropTypes.array,
  shipments: PropTypes.array,
  dropOffStart: PropTypes.any,
  ldt: PropTypes.any,
}

const CellItem = ({ icon, content, unit }) => (
  <div style={{ display: 'flex', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
    {icon}
    <span
      style={{
        fontSize: '13px',
        color: '#7f7f7f',
        marginTop: '4px',
        marginRight: '5px',
      }}
    >
      {content} {unit}
    </span>
  </div>
)

CellItem.propTypes = {
  icon: PropTypes.any,
  content: PropTypes.any,
  unit: PropTypes.string,
}
