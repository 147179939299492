import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import Lightbox from 'react-18-image-lightbox'
import 'react-18-image-lightbox/style.css' // This only needs to be imported once in your app
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import queryString from 'query-string'
import { IMAGE_SIGNATURE_PHOTO_TYPE } from '../../../../../../utils/values'
import AddIcon from '@mui/icons-material/Add'
import Error from '../../../../../../components/Modal/HttpErrorPopUp'
import { validateUploadImage } from '../../../../../../utils/validator/UploadFileDiscussion'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import MdiCloudUpload from '../../../../../../assets/icons/detailsLivraison/MdiCloudUpload'
import CircularProgress from '@mui/material/CircularProgress'
import { useParams } from 'react-router-dom'
import CardAccordion from '../card/cardWithAccordion'
import { Box } from '@mui/material'

export default function PhotoLivraison({
  downloadImage,
  onUploadPhotos,
  setErrorFileUpload,
  isLoadingUploadPhoto,
  errorFileUpload,
  orderCode,
  codeStatus,
  isLoading,
  isActionOrderPhotoDone,
  isLoadingOrderPhoto,
  getOrderPhotoByLdv,
  orderPhoto,
}) {
  const [lightboxIsopen, setLightboxIsopen] = useState(false)
  const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState(0)
  const [errorUploadFile, setErrorUploadFile] = useState(null)
  const [open, setOpen] = useState(false)
  const [images, setImages] = useState([])
  const [paramsInit, setParams] = useState(null)

  const params = useParams()

  useEffect(() => {
    if (orderCode) {
      getOrderPhotoByLdv(params.ldv)
      setParams(params)
    }
  }, [orderCode])

  useEffect(() => {
    if (paramsInit && paramsInit.ldv != params.ldv && orderCode) {
      setParams(params)
      getOrderPhotoByLdv(params.ldv)
    }
  }, [params])

  useEffect(() => {
    if (isActionOrderPhotoDone) {
      anullerImage()
      getOrderPhotoByLdv(params.ldv)
    }
  }, [isActionOrderPhotoDone])

  const getImage = (url) => {
    const api = process.env.REACT_APP_API_URL
    const token = localStorage.getItem('token')
    const dataSend = {
      url,
      token,
    }
    return `${api}downloadimage?${queryString.stringify(dataSend)}`
  }

  const onChangeFile = (event) => {
    setErrorUploadFile(null)
    const error = validateUploadImage(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
      setOpen(true)
      event.target.value = null
      setImages([])
    } else {
      const imagesUpload = Array.from(event.target.files)
      const imagesUnique = [
        ...new Map(
          [...images, ...imagesUpload].map((item) => [item['name'], item])
        ).values(),
      ]
      setImages(imagesUnique)
    }
  }
  const getURLImage = (image) => URL.createObjectURL(image)
  const anullerImage = (index) => {
    let filesUploadStateDelete = []

    if (index > -1) {
      filesUploadStateDelete = images
      filesUploadStateDelete.splice(index, 1)
      setImages([...filesUploadStateDelete])
    }
    if (filesUploadStateDelete.length == 0 || index == -1) {
      setImages([])
    }
  }
  const onUpload = () => {
    const formData = new FormData()
    images.forEach((image, i) => formData.append(`pictures[${i}]`, image))
    formData.append('codeStatus', codeStatus)
    onUploadPhotos(formData, orderCode)
  }
  const onErrorClose = () => {
    setOpen(false)
    setErrorUploadFile(null)
    setErrorFileUpload(false)
  }
  return (
    <>
      {(errorUploadFile?.isError || errorFileUpload) && (
        <Error
          statusText={
            errorUploadFile?.message ||
            "une erreur s'est produite merci d'actualiser la page"
          }
          open={open || errorFileUpload}
          setOpen={onErrorClose}
        />
      )}
      <CardAccordion
        icon={
          <ImageOutlinedIcon
            sx={{ marginRight: '4px', width: '20px', height: '20px' }}
          />
        }
        title={'Photos'}
        action={
          <>
            {isLoadingUploadPhoto ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '6px 7px 0px 0px',
                }}
              >
                <span>Upload en cours ...</span>
                <CircularProgress
                  sx={{
                    '&.MuiCircularProgress-root': {
                      width: '25px !important',
                      height: '25px !important',
                    },
                  }}
                />
              </Box>
            ) : (
              orderCode && (
                <Box sx={{ paddingRight: '7px', display: 'flex' }}>
                  <Box sx={{ display: 'flex', marginTop: '5px' }}>
                    <input
                      type="file"
                      style={{
                        width: '130px',
                        cursor: 'pointer',
                        opacity: '0',
                        zIndex: '2',
                        position: 'static',
                        marginRight: '-20px',
                      }}
                      onChange={onChangeFile}
                      onClick={(event) => event.stopPropagation()}
                      multiple
                    />

                    <AddIcon sx={{ cursor: 'pointer' }} />
                  </Box>
                  {images.length > 0 && (
                    <IconButton
                      aria-label="delete"
                      sx={{
                        marginLeft: '15px',
                        padding: '0px',
                        width: '33px',
                        height: '29px',
                      }}
                    >
                      <MdiCloudUpload
                        style={{
                          width: '27px',
                          height: '27px',
                          color: '#1976d2',

                          cursor: 'pointer',
                        }}
                        onClick={(event) => {
                          onUpload()
                          event.stopPropagation()
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
              )
            )}
          </>
        }
        content={
          <>
            {!isLoadingUploadPhoto && images.length > 0 && (
              <Box
                sx={{
                  maxHeight: '300px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  boxSizing: 'border-box',
                  overflowY: 'auto',
                }}
              >
                {images.map((image, index) => {
                  return (
                    <Box
                      sx={{
                        position: 'relative',
                        width: '112px',
                        height: '100px',
                      }}
                      key={index}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: '#eeeeee !important',
                          position: 'absolute',
                          width: '10px',
                          height: '10px',
                          right: '0',
                          top: '2px',
                          zIndex: '2',
                        }}
                        onClick={() => {
                          anullerImage(index)
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Box
                        sx={{
                          display: 'flex',
                        }}
                      >
                        <img
                          src={getURLImage(image)}
                          style={{
                            objectFit: 'contain',
                            width: '100px',
                            height: '100px',
                            padding: '2%',
                          }}
                        />
                        <Box
                          sx={{
                            width: '100%',
                            cursor: 'pointer',
                            height: '100%',
                            opacity: '0',
                            padding: '4px 4px',
                            position: 'absolute',
                            '&:hover': {
                              opacity: '1',
                              backgroundColor: 'hsla(0,0%,97.6%,.4117647058823529)',
                              transition: '0.5s',
                            },
                          }}
                        >
                          <a
                            href={getURLImage(image)}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <VisibilityOutlinedIcon
                              sx={{
                                width: '40px',
                                border: 'none',
                                cursor: 'pointer',
                                height: '28px',
                                opacity: '0.7',
                                borderRadius: '5px',
                                backgroundColor: 'white',
                                position: 'absolute',
                                top: '45%',
                                left: '27%',
                              }}
                            />
                          </a>
                        </Box>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            )}

            {isLoadingOrderPhoto || isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              orderPhoto &&
              orderPhoto.length > 0 && (
                <>
                  {orderPhoto.filter(
                    (image) => image.type == IMAGE_SIGNATURE_PHOTO_TYPE.PickUp
                  ).length > 0 && (
                    <>
                      <Box
                        sx={{
                          fontSize: '13px',
                          fontWeight: '550',
                          padding: '7px 5px',
                        }}
                      >
                        Image de retrait
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          boxSizing: 'border-box',
                          maxHeight: '422px',
                          overflowY: 'auto',
                        }}
                      >
                        {orderPhoto
                          .filter(
                            (image) =>
                              image.type == IMAGE_SIGNATURE_PHOTO_TYPE.PickUp
                          )
                          .map((picture, index) => {
                            return (
                              <Box
                                sx={{
                                  position: 'relative',
                                  width: '100%',
                                  maxWidth: '150px',
                                  margin: '4px',
                                }}
                                key={index}
                                onClick={(event) => {
                                  setLightboxPhotoIndex(index)
                                  setLightboxIsopen(true)
                                  event.stopPropagation()
                                }}
                              >
                                <img
                                  key={`photo-${index}`}
                                  alt="photo-b2h"
                                  style={{ width: '100%', height: 'auto' }}
                                  src={getImage(picture.url)}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '0',
                                    opacity: '0',
                                    height: '100%',
                                    width: '100%',
                                    cursor: 'pointer',
                                    textAlign: 'end',
                                    padding: '4px 4px',
                                    '&:hover': {
                                      opacity: '1',
                                      backgroundColor:
                                        'hsla(0,0%,97.6%,.4117647058823529)',
                                      transition: '0.5s',
                                    },
                                  }}
                                >
                                  <CloudDownloadOutlinedIcon
                                    onClick={(event) => {
                                      downloadImage(picture.url)
                                      event.stopPropagation()
                                    }}
                                    sx={{
                                      width: '40px !important',
                                      height: '28px !important',
                                      cursor: 'pointer',
                                      border: 'none',
                                      opacity: '0.7',
                                      verticalAlign: 'middle',
                                      marginLeft: '4px',
                                      backgroundColor: 'white',
                                      borderRadius: '5px',
                                      '&:hover': {
                                        backgroundColor: '#8e8888',
                                        transition: '0.5s',
                                      },
                                    }}
                                    download
                                  />
                                  <VisibilityOutlinedIcon
                                    onClick={(event) => {
                                      setLightboxPhotoIndex(index)
                                      setLightboxIsopen(true)
                                      event.stopPropagation()
                                    }}
                                    sx={{
                                      width: '40px !important',
                                      height: '28px !important',
                                      cursor: 'pointer',
                                      border: 'none',
                                      opacity: '0.7',
                                      verticalAlign: 'middle',
                                      marginLeft: '4px',
                                      backgroundColor: 'white',
                                      borderRadius: '5px',
                                      '&:hover': {
                                        backgroundColor: '#8e8888',
                                        transition: '0.5s',
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                            )
                          })}
                      </Box>
                    </>
                  )}
                  {orderPhoto.filter(
                    (image) => image.type == IMAGE_SIGNATURE_PHOTO_TYPE.DropOff
                  ).length > 0 && (
                    <>
                      <Box
                        sx={{
                          fontSize: '13px',
                          fontWeight: '550',
                          padding: '7px 5px',
                        }}
                      >
                        Image de livraison
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          boxSizing: 'border-box',
                          maxHeight: '422px',
                          overflowY: 'auto',
                        }}
                      >
                        {orderPhoto
                          .filter(
                            (image) =>
                              image.type == IMAGE_SIGNATURE_PHOTO_TYPE.DropOff
                          )
                          .map((picture, index) => {
                            return (
                              <Box
                                sx={{
                                  position: 'relative',
                                  width: '100%',
                                  maxWidth: '150px',
                                  margin: '4px',
                                }}
                                key={index}
                                onClick={(event) => {
                                  setLightboxPhotoIndex(
                                    index +
                                      orderPhoto.filter(
                                        (image) =>
                                          image.type ==
                                          IMAGE_SIGNATURE_PHOTO_TYPE.PickUp
                                      ).length
                                  )
                                  setLightboxIsopen(true)
                                  event.stopPropagation()
                                }}
                              >
                                <img
                                  key={`photo-${index}`}
                                  alt="photo-b2h"
                                  style={{ width: '100%', height: 'auto' }}
                                  src={getImage(picture.url)}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: '0',
                                    opacity: '0',
                                    height: '100%',
                                    width: '100%',
                                    cursor: 'pointer',
                                    textAlign: 'end',
                                    padding: '4px 4px',
                                    '&:hover': {
                                      opacity: '1',
                                      backgroundColor:
                                        'hsla(0,0%,97.6%,.4117647058823529)',
                                      transition: '0.5s',
                                    },
                                  }}
                                >
                                  <CloudDownloadOutlinedIcon
                                    onClick={(event) => {
                                      downloadImage(picture.url)
                                      event.stopPropagation()
                                    }}
                                    sx={{
                                      width: '40px !important',
                                      height: '28px !important',
                                      cursor: 'pointer',
                                      border: 'none',
                                      opacity: '0.7',
                                      verticalAlign: 'middle',
                                      marginLeft: '4px',
                                      backgroundColor: 'white',
                                      borderRadius: '5px',
                                      '&:hover': {
                                        backgroundColor: '#8e8888',
                                        transition: '0.5s',
                                      },
                                    }}
                                    download
                                  />
                                  <VisibilityOutlinedIcon
                                    onClick={(event) => {
                                      setLightboxPhotoIndex(index)
                                      setLightboxIsopen(true)
                                      event.stopPropagation()
                                    }}
                                    sx={{
                                      width: '40px !important',
                                      height: '28px !important',
                                      cursor: 'pointer',
                                      border: 'none',
                                      opacity: '0.7',
                                      verticalAlign: 'middle',
                                      marginLeft: '4px',
                                      backgroundColor: 'white',
                                      borderRadius: '5px',
                                      '&:hover': {
                                        backgroundColor: '#8e8888',
                                        transition: '0.5s',
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                            )
                          })}
                      </Box>
                    </>
                  )}
                </>
              )
            )}
          </>
        }
      />

      {lightboxIsopen && (
        <Box sx={{ top: '57px' }}>
          <Lightbox
            mainSrc={getImage(orderPhoto[lightboxPhotoIndex].url)}
            nextSrc={orderPhoto[(lightboxPhotoIndex + 1) % orderPhoto.length]}
            prevSrc={
              orderPhoto[
                (lightboxPhotoIndex + orderPhoto.length - 1) % orderPhoto.length
              ]
            }
            onCloseRequest={() => setLightboxIsopen(false)}
            onMovePrevRequest={() => {
              setLightboxPhotoIndex(
                (lightboxPhotoIndex + orderPhoto.length - 1) % orderPhoto.length
              )
            }}
            onMoveNextRequest={() => {
              setLightboxPhotoIndex((lightboxPhotoIndex + 1) % orderPhoto.length)
            }}
            toolbarButtons={[
              <CloudDownloadOutlinedIcon
                onClick={() => downloadImage(orderPhoto[lightboxPhotoIndex].url)}
                sx={{
                  width: '40px !important',
                  height: '28px !important',
                  cursor: 'pointer',
                  border: 'none',
                  opacity: '0.7',
                  verticalAlign: 'middle',
                  marginLeft: '4px',
                  borderRadius: '5px',
                  '&:hover': {
                    opacity: '1',
                  },
                }}
                key={'buttonLightbox'}
                download
              />,
            ]}
          />
        </Box>
      )}
    </>
  )
}
PhotoLivraison.propTypes = {
  downloadImage: PropTypes.func,
  onUploadPhotos: PropTypes.func,
  setErrorFileUpload: PropTypes.func,
  isLoadingUploadPhoto: PropTypes.bool,
  errorFileUpload: PropTypes.bool,
  orderCode: PropTypes.string,
  codeStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  isActionOrderPhotoDone: PropTypes.bool,
  isLoadingOrderPhoto: PropTypes.bool,
  getOrderPhotoByLdv: PropTypes.func,
  orderPhoto: PropTypes.array,
}
