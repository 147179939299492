import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Filter from '../../../../Orders/Table/Filter'
import TableHead from '@mui/material/TableHead'
import LongTextComponent from '../../../../Orders/Table/LongTextComponent'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import '../index.scss'
import Button from '@mui/material/Button'
import EmptyTable from '../../../../../../components/EmptyTable/EmptyTable'

export const list = [
  { id: 'codeCourse', label: 'Code', minWidth: 35, name: 'Code', checked: true },
  { id: 'ldv', label: 'LDV', minWidth: 35, name: 'LDV', checked: true },
  {
    id: 'dateDemarrage',
    label: 'Date Demarrage',
    minWidth: 80,
    name: 'Date Demarrage',
    checked: true,
  },
  {
    id: 'salesChannel',
    label: 'Canal Vente',
    minWidth: 80,
    name: 'Canal Vente',
    checked: true,
  },
  { id: 'status', label: 'Status', minWidth: 80, name: 'Status', checked: true },
]

export default function DashboardAlertsTable({ delayedShipments }) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [columns, setColumns] = React.useState(list)
  const [columsOrder, setColumnsOrder] = React.useState(columns.map((c) => c.id))

  useEffect(() => {
    setColumnsOrder(columns.map((c) => c.id))
  }, [columns])
  const handleColumns = (list) => {
    setColumns(list)
  }
  const getChecked = (name) => {
    let isChecked = false
    columns.filter((item) => {
      if (item.name === name) {
        isChecked = item.checked
      }
    })
    return isChecked
  }
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, delayedShipments.length - page * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div className="dashboard-orders-table">
      <Toolbar className="toolbar-contant toolbar-orders-contant">
        <Filter columns={columns} handleColumns={handleColumns} isDashboard={true} />
      </Toolbar>
      <TableContainer component={Paper} className="order-table-root ">
        <Table
          sx={{
            minWidth: 500,
            border: '1px solid rgba(224, 224, 224, 1)',
          }}
          aria-label="custom pagination table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  align="center"
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {delayedShipments.length > 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? delayedShipments.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : delayedShipments
              ).map(
                (
                  { codeCourse, dateDemarrage, ldv, salesChannel, status },
                  index
                ) => (
                  <TableRow key={`table_row_${index}`}>
                    {[
                      {
                        name: 'codeCourse',
                        content: codeCourse,
                        show: getChecked('Code'),
                      },

                      { name: 'ldv', content: ldv, show: getChecked('LDV') },
                      {
                        name: 'dateDemarrage',
                        content: dateDemarrage,
                        show: getChecked('Date Demarrage'),
                      },
                      {
                        name: 'salesChannel',
                        content: salesChannel,
                        show: getChecked('Canal Vente'),
                      },
                      {
                        name: 'status',
                        content: status.label,
                        show: getChecked('Status'),
                      },
                    ]
                      .sort(
                        (a, b) =>
                          columsOrder.indexOf(a.name) - columsOrder.indexOf(b.name)
                      )
                      .filter((element) => element.show)
                      .map((item, index) =>
                        item.name === 'status' ? (
                          <td className="MuiTableCell-root" key={`td_${index}`}>
                            <Button
                              className="order-table-button"
                              disabled={true}
                              variant="contained"
                              style={{
                                color: status.color,
                                border: `1px solid ${status.color}`,
                              }}
                            >
                              {' '}
                              {status.label}{' '}
                            </Button>
                          </td>
                        ) : (
                          <LongTextComponent
                            display={true}
                            text={item.content}
                            key={`ltc_${index}`}
                          />
                        )
                      )}
                  </TableRow>
                )
              )}

              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <EmptyTable colspan={columns.length} />
          )}
          {delayedShipments.length > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    20,
                    30,
                    50,
                    100,
                    200,
                    300,
                    { label: 'All', value: -1 },
                  ]}
                  count={delayedShipments.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage="lignes par page"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} sur ${count}`
                  }
                  ActionsComponent={TablePaginationActions}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
DashboardAlertsTable.propTypes = {
  delayedShipments: PropTypes.array,
}
