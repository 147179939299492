export default () => {
  return {
    filter: {
      limit: 25,
      offset: 0,
    },
    vehiculesList: [],
    vehiculesListCount: 0,
    isLoadingVehiculesList: false,

    errorMessage: null,
    isErrorFetch: false,

    isLoadingVehiculeAction: false,
    success: false,
    isSuccessRefresh: false,
  }
}
