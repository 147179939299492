import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import CardActions from './components/details/CardActions'
import Plannification from '../../../../containers/main/Plannification'
import Error from '../../../../components/Modal/HttpErrorPopUp'
import Loading from '../../../../components/Loading'
import DateLivraison from '../../../../containers/main/DetailsDelivery/DateLivraison'
import DetailsColis from '../../../../containers/main/DetailsDelivery/Colis/detailsColis'
import Notifications from '../../../../containers/main/DetailsDelivery/notification'

import InformationLivraison from './components/details/informations'
import PhotoLivraison from '../../../../containers/main/DetailsDelivery/photoLivraison'
import PiecesJointesLivraison from '../../../../containers/main/DetailsDelivery/piecesJointes'
import PrisLivraison from './components/details/pris'
import SignaturesLivraison from '../../../../containers/main/DetailsDelivery/signatureLivraison'
import DownloadFILE from './components/details/download'
import NoteInterne from './components/details/noteInterne'
import Chauffeur from './components/details/chauffeur'
import DatePrevues from './components/details/datePrevues'
import DateRealisees from './components/details/dateRealisees'
import Status from './components/details/status'
import FilDiscussion from '../../../../containers/main/DetailsDelivery/discussion'
import Historique from '../../../../containers/main/DetailsDelivery/historique'
import Options from './components/details/options'
import ConterRemboursement from './components/details/conterRemboursement'
import HistoriqueLivraison from '../../../../containers/main/DetailsDelivery/historiqueLivraison'
import ActivityHistory from '../../../../containers/main/DetailsDelivery/activityHistory'
import HistoriqueDeltaCost from '../../../../containers/main/DetailsDelivery/historiqueDeltaCost'
import Details from '../../../../containers/main/DetailsDelivery/details'
import PlanCadencement from './components/details/planCadencement'
import Prestation from './components/details/prestation'
import { CardSyled } from './components/card/style'
import Activity from '../../../../containers/main/DetailsDelivery/activity'
import { emailCheck } from '../../../../utils/utils'

const DetailsDelivery = ({
  order,
  isLoading,
  error,
  getOrderByLdv,
  resetOrderDetails,
  isActionShipmentDone,
  isViewPlannification,
  viewPlannification,
  errorActionCourse,
  removeErrorAction,
  resetOrderPackage,
  resetOrderAction,
  tourAction,
  resetTourAction,
  isLoadingActionOrder,
  duplicatedCourse,
  getCourseDuplicated,
}) => {
  const params = useParams()
  const [paramsInit, setParams] = useState(null)
  const connectedUserEmail = JSON.parse(localStorage.getItem('currentUser')).login

  useEffect(() => {
    getOrderByLdv(params.ldv)
    setParams(params)
    return () => {
      resetOrderDetails()
      viewPlannification(false)
      resetOrderPackage()
      resetOrderAction()
    }
  }, [])

  useEffect(() => {
    if (paramsInit && paramsInit.ldv != params.ldv) {
      setParams(params)
      getOrderByLdv(params.ldv)
    }
    return () => {
      resetOrderDetails()
      resetOrderPackage()
      resetOrderAction()
    }
  }, [params])

  useEffect(() => {
    if (tourAction) {
      resetTourAction()
      resetOrderDetails()
      resetOrderPackage()
      getOrderByLdv(params.ldv)
    }
  }, [tourAction])
  useEffect(() => {
    if (isActionShipmentDone && duplicatedCourse) {
      resetOrderAction()
      getCourseDuplicated({ id: order?.commande?.id })
    } else if (isActionShipmentDone) {
      resetOrderAction()
      resetOrderDetails()
      resetOrderPackage()
      getOrderByLdv(params.ldv)
    }
  }, [isActionShipmentDone])

  const hidePopUp = () => {
    window.location.reload()
  }

  const handleCloseErrorAction = () => {
    removeErrorAction()
  }

  return (
    <>
      {isViewPlannification ? (
        <div className="container">
          <Plannification />
        </div>
      ) : (
        <div style={{ paddingBottom: '8px' }}>
          {isLoading || tourAction || isActionShipmentDone ? (
            <Loading />
          ) : (
            <>
              {error ? (
                <Error
                  statusText={error?.statusText}
                  open={error != null}
                  setOpen={hidePopUp}
                />
              ) : (
                <Container maxWidth={false}>
                  <Grid item xs={12} md={12} sm={12}>
                    <CardSyled>
                      <p style={{ textAlign: 'center', fontSize: '30px' }}>
                        {`LETTRE DE VOITURE  ${paramsInit?.ldv}`}{' '}
                        {order?.ldvRelatedCourse && (
                          <p style={{ fontSize: '20px', margin: '7px' }}>
                            {' '}
                            COURSE ASSOCIÉE{' '}
                            <Link
                              style={{ fontSize: '20px', color: '#1876D2' }}
                              to={'/deliveries/' + order.ldvRelatedCourse}
                              id={`Cours-Associee-${order.ldvRelatedCourse}`}
                            >
                              {order.ldvRelatedCourse}
                            </Link>
                          </p>
                        )}
                      </p>
                    </CardSyled>
                  </Grid>

                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12} md={8} sm={12}>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <DateLivraison order={order} />
                          <DatePrevues order={order} isLoading={isLoading} />
                          <DateRealisees order={order} isLoading={isLoading} />
                          <InformationLivraison
                            order={order}
                            isLoading={isLoading}
                          />
                          <PlanCadencement order={order} isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <CardActions order={order} isLoading={isLoading} />
                          <Status
                            order={order}
                            isLoading={isLoading}
                            isLoadingActionOrder={isLoadingActionOrder}
                          />
                          <Details order={order} isLoading={isLoading} />
                          <Options order={order} isLoading={isLoading} />
                          <PrisLivraison order={order} isLoading={isLoading} />
                          <ConterRemboursement order={order} isLoading={isLoading} />
                          <Prestation order={order} />
                          <Chauffeur order={order} isLoading={isLoading} />
                          <NoteInterne order={order} />
                          <DownloadFILE order={order} isLoading={isLoading} />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <DetailsColis order={order} isLoading={isLoading} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sm={12}>
                      <FilDiscussion order={order} isLoading={isLoading} />
                      <Notifications order={order} isLoading={isLoading} />
                      <Historique order={order} isLoading={isLoading} />
                      <HistoriqueLivraison order={order} isLoading={isLoading} />
                      <ActivityHistory order={order} isLoading={isLoading} />
                      <HistoriqueDeltaCost
                        orderCode={order?.code}
                        isLoading={isLoading}
                      />
                      <PiecesJointesLivraison
                        orderCode={order?.code}
                        codeStatus={order?.status?.code}
                        isLoading={isLoading}
                      />
                      <PhotoLivraison
                        orderCode={order?.code}
                        codeStatus={order?.status?.code}
                        isLoading={isLoading}
                      />
                      <SignaturesLivraison
                        orderCode={order?.code}
                        codeStatus={order?.status?.code}
                        isLoading={isLoading}
                      />
                      {emailCheck(connectedUserEmail) && (
                        <Activity order={order} isLoading={isLoading} />
                      )}
                    </Grid>
                  </Grid>
                </Container>
              )}
            </>
          )}
          <Snackbar
            open={errorActionCourse != null}
            autoHideDuration={6000}
            onClose={handleCloseErrorAction}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity="error" onClose={handleCloseErrorAction} elevation={6}>
              {errorActionCourse}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  )
}
DetailsDelivery.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  getOrderByLdv: PropTypes.func,
  resetOrderDetails: PropTypes.func,
  isActionShipmentDone: PropTypes.bool,
  isSimLoading: PropTypes.bool,
  isSimError: PropTypes.bool,
  isPlanningError: PropTypes.bool,
  simulationErrorMessage: PropTypes.string,
  simErrorStatus: PropTypes.string,
  isRecalculateLoading: PropTypes.bool,
  viewPlannification: PropTypes.func,
  isViewPlannification: PropTypes.bool,
  isActionOrderSignatureDone: PropTypes.bool,
  isActionOrderPhotoDone: PropTypes.bool,
  errorActionCourse: PropTypes.string,
  removeErrorAction: PropTypes.func,
  resetOrderPackage: PropTypes.func,
  resetOrderAction: PropTypes.func,
  tourAction: PropTypes.bool,
  resetTourAction: PropTypes.func,
  isLoadingActionOrder: PropTypes.bool,
  duplicatedCourse: PropTypes.bool,
  getCourseDuplicated: PropTypes.func,
}

export default DetailsDelivery
