export const OrderPackageConstants = {
  RESET_ORDER_PACKAGE: 'RESET_ORDER_PACKAGE',
  GET_PACKAGES_ORDER: 'GET_PACKAGES_ORDER',
  GET_PACKAGES_ORDER_SUCCESS: 'GET_PACKAGES_ORDER_SUCCESS',
  GET_PACKAGES_ORDER_FAILED: 'GET_PACKAGES_ORDER_FAILED',

  GET_PACKAGES_STATUS: 'GET_PACKAGES_STATUS',
  GET_PACKAGES_STATUS_SUCCESS: 'GET_PACKAGES_STATUS_SUCCESS',
  GET_PACKAGES_STATUS_FAILED: 'GET_PACKAGES_STATUS_FAILED',

  POINTAGE_PACKAGES: 'POINTAGE_PACKAGES',
  POINTAGE_PACKAGES_SUCCESS: 'POINTAGE_PACKAGES_SUCCESS',
  POINTAGE_PACKAGES_FAILED: 'POINTAGE_PACKAGES_FAILED',

  POINTAGE_FORCE_PACKAGES: 'POINTAGE_FORCE_PACKAGES',
  POINTAGE_FORCE_PACKAGES_SUCCESS: 'POINTAGE_FORCE_PACKAGES_SUCCESS',
  POINTAGE_FORCE_PACKAGES_FAILED: 'POINTAGE_FORCE_PACKAGES_FAILED',

  SET_ERROR_UPLOAD_IMAGE_LITIGE: 'SET_ERROR_UPLOAD_IMAGE_LITIGE',

  UPLOAD_IMAGE_LITIGE: 'UPLOAD_IMAGE_LITIGE',
  UPLOAD_IMAGE_LITIGE_SUCCESS: 'UPLOAD_IMAGE_LITIGE_SUCCESS',
  UPLOAD_IMAGE_LITIGE_FAILED: 'UPLOAD_IMAGE_LITIGE_FAILED',

  SET_LOADING_UPLOAD_IMAGE_LITIGE: 'SET_LOADING_UPLOAD_IMAGE_LITIGE',

  DELETE_IMAGE_LITIGE: 'DELETE_IMAGE_LITIGE',

  GET_PACKAGES_LITIGE_MOTIF: 'GET_PACKAGES_LITIGE_MOTIF',
  GET_PACKAGES_LITIGE_MOTIF_SUCCESS: 'GET_PACKAGES_LITIGE_MOTIF_SUCCESS',
  GET_PACKAGES_LITIGE_MOTIF_FAILED: 'GET_PACKAGES_LITIGE_MOTIF_FAILED',

  GET_PACKAGES_LITIGE_RESPONSABILITE: 'GET_PACKAGES_LITIGE_RESPONSABILITE',
  GET_PACKAGES_LITIGE_RESPONSABILITE_SUCCESS:
    'GET_PACKAGES_LITIGE_RESPONSABILITE_SUCCESS',
  GET_PACKAGES_LITIGE_RESPONSABILITE_FAILED:
    'GET_PACKAGES_LITIGE_RESPONSABILITE_FAILED',

  GET_LITIGE_HISTORIQUE_PACKAGE: 'GET_LITIGE_HISTORIQUE_PACKAGE',
  GET_LITIGE_HISTORIQUE_PACKAGE_SUCCESS: 'GET_LITIGE_HISTORIQUE_PACKAGE_SUCCESS',
  GET_LITIGE_HISTORIQUE_PACKAGE_FAILED: 'GET_LITIGE_HISTORIQUE_PACKAGE_FAILED',

  GET_PRODUITS_PACKAGE: 'GET_PRODUITS_PACKAGE_PACKAGE',
  GET_PRODUITS_PACKAGE_SUCCESS: 'GET_PRODUITS_PACKAGE_SUCCESS',
  GET_PRODUITS_PACKAGE_FAILED: 'GET_PRODUITS_PACKAGE_FAILED',

  GET_HISTORIQUES_PACKAGE: 'GET_HISTORIQUES_PACKAGE',
  GET_HISTORIQUES_PACKAGE_SUCCESS: 'GET_HISTORIQUES_PACKAGE_SUCCESS',
  GET_HISTORIQUES_PACKAGE_FAILED: 'GET_HISTORIQUES_PACKAGE_FAILED',

  GET_PHOTOS_HISTORIQUE_PACKAGE: 'GET_PHOTOS_HISTORIQUE_PACKAGE',
  GET_PHOTOS_HISTORIQUE_PACKAGE_SUCCESS: 'GET_PHOTOS_HISTORIQUE_PACKAGE_SUCCESS',
  GET_PHOTOS_HISTORIQUE_PACKAGE_FAILED: 'GET_PHOTOS_HISTORIQUE_PACKAGE_FAILED',

  GET_ETAT_PACKAGE: 'GET_ETAT_PACKAGE',
  GET_ETAT_PACKAGE_SUCCESS: 'GET_ETAT_PACKAGE_SUCCESS',
  GET_ETAT_PACKAGE_FAILED: 'GET_ETAT_PACKAGE_FAILED',

  ANNULATION_LIVRAISON_PARTIELLE_PACKAGE: 'ANNULATION_LIVRAISON_PARTIELLE_PACKAGE',

  LIVRAISON_PARTIELLE_PACKAGE: 'LIVRAISON_PARTIELLE_PACKAGE',
  LIVRAISON_PARTIELLE_PACKAGE_SUCCESS: 'LIVRAISON_PARTIELLE_PACKAGE_SUCCESS',
  LIVRAISON_PARTIELLE_PACKAGE_FAILED: 'LIVRAISON_PARTIELLE_PACKAGE_FAILED',

  GET_PAKAGE_CONFIG_MDM: 'GET_PAKAGE_CONFIG_MDM',
  GET_PAKAGE_CONFIG_MDM_SUCCESS: 'GET_PAKAGE_CONFIG_MDM_SUCCESS',
  GET_PAKAGE_CONFIG_MDM_FAILED: 'GET_PAKAGE_CONFIG_MDM_FAILED',

  UPLOAD_IMAGE_MDM: 'UPLOAD_IMAGE_MDM',
  UPLOAD_IMAGE_MDM_SUCCESS: 'UPLOAD_IMAGE_MDM_SUCCESS',
  UPLOAD_IMAGE_MDM_FAILED: 'UPLOAD_IMAGE_MDM_FAILED',

  SET_LOADING_UPLOAD_IMAGE_MDM: 'SET_LOADING_UPLOAD_IMAGE_MDM',

  EDIT_PACKAGE: 'EDIT_PACKAGE',
  EDIT_PACKAGE_SUCCESS: 'EDIT_PACKAGE_SUCCESS',
  EDIT_PACKAGE_FAILED: 'EDIT_PACKAGE_FAILED',
}
