import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import TabDrivers from '../../../../../components/Table'
import TableContent from './DriversTabContent'
import TableFooter from './DriversTabFooter'

const checkActions = [
  {
    id: 'check',
    label: 'Selectionner',
    name: 'Selectionner',
    checked: true,
  },
]
const columnsList = [
  {
    id: 'id',
    label: 'Id',
    name: 'id',
    checked: true,
  },
  {
    id: 'prenom',
    label: 'Prénom',
    name: 'prenom',
    checked: true,
  },
  {
    id: 'nom',
    label: 'Nom',
    name: 'nom',
    checked: true,
  },
  {
    id: 'phone',
    label: 'Phone',
    name: 'phone',
    checked: true,
  },
  {
    id: 'volume',
    label: 'Volume',
    name: 'volume',
    checked: true,
  },

  {
    id: 'poids',
    label: 'Poids Max',
    name: 'poids',
    checked: false,
  },
  {
    id: 'tempsTravail',
    label: 'Temps de travail',
    name: 'tempsTravail',
    checked: false,
  },
]

const settings = [
  {
    id: 'settings',
    label: 'Settings',
    minWidth: 100,
    name: 'Settings',
    checked: true,
  },
]
const columnsKey = 'columns_list_drivers'
export default function DriversTab({
  drivers,
  isLoading,
  setDrivers,
  ldt,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  page,
  driversOptimiserCount,
  setDriversOriginalSelected,
  driversOriginalSelected,
  setErrorDialogOpen,
  setErrorMessage,
}) {
  const [columns, setColumns] = useState([])
  const [checkedAllDrivers, setCheckedAllDrivers] = useState(false)

  useEffect(() => {
    const list = JSON.parse(localStorage.getItem(columnsKey))
    if (list) setColumns(list)
    else setColumns(columnsList)
  }, [])

  const verifChauffeurChecked = (drivers, id) => {
    let driversTest = []
    id !== null
      ? (driversTest = drivers.filter((driver) => driver.id === id))
      : (driversTest = drivers)
    return driversTest.map((driver) => {
      if (
        !(
          driver?.operationalHours?.closeTime &&
          driver?.operationalHours?.openTime &&
          driver?.adresseArrivee !== null &&
          driver?.adresseArrivee?.address &&
          driver?.adresseArrivee?.latitude &&
          driver?.adresseArrivee?.longitude &&
          driver?.adresseArrivee?.id &&
          driver?.adresseDepart !== null &&
          driver?.adresseDepart?.address &&
          driver?.adresseDepart?.latitude &&
          driver?.adresseDepart?.longitude &&
          driver?.adresseDepart?.id &&
          driver?.vehiculeId !== null &&
          driver?.vehiculeId?.vehicule_category &&
          driver?.vehiculeId?.vehicule_category.volumeMax !== null &&
          driver?.vehiculeId?.vehicule_category?.weight !== null
        )
      ) {
        setErrorDialogOpen(true)
        setErrorMessage('Il manque des informations au chauffeur')
        return false
      }
      return true
    })
  }

  const handleCheckAll = () => {
    if (verifChauffeurChecked(drivers, null).every((val) => val === true)) {
      const driversSelected = drivers.map((driver) => {
        return {
          ...driver,
          checked: !checkedAllDrivers,
        }
      })
      setDriversOriginalSelected(driversSelected.filter((driver) => driver.checked))
      setDrivers(driversSelected)
      setCheckedAllDrivers(!checkedAllDrivers)
    }
  }

  const handleCheck = (id, checked) => {
    if (verifChauffeurChecked(drivers, id).every((val) => val === true)) {
      const driversState = drivers.map((driver) => {
        if (ldt) {
          if (driver.id === id) {
            return {
              ...driver,
              checked: !checked,
            }
          } else {
            return {
              ...driver,
              checked: false,
            }
          }
        } else if (driver.id === id) {
          return {
            ...driver,
            checked: !checked,
          }
        }
        return driver
      })

      const driversStateOriginals = driversOriginalSelected.map((driver) => {
        if (ldt) {
          if (driver.id === id) {
            return {
              ...driver,
              checked: !checked,
            }
          } else {
            return {
              ...driver,
              checked: false,
            }
          }
        } else if (driver.id === id) {
          return {
            ...driver,
            checked: !checked,
          }
        }
        return driver
      })
      const driversOriginals = new Set([
        ...driversStateOriginals,
        ...driversState.filter((el) => el.checked),
      ])
      setDriversOriginalSelected(
        Array.from(driversOriginals).filter((driver) => driver.checked)
      )
      verifCheckedAll()
      setDrivers(driversState)
    }
  }

  const handleColumns = (list) => {
    setColumns(list)
    localStorage.setItem(columnsKey, JSON.stringify(list))
  }

  const verifCheckedAll = () => {
    return (
      drivers.length > 0 &&
      drivers.map((driver) => driver.checked).every((checked) => checked)
    )
  }
  useEffect(() => {
    verifCheckedAll()
  }, [drivers])

  return (
    <TabDrivers
      ldt={ldt}
      checkActions={checkActions}
      columns={columns}
      settings={settings}
      handleCheckAll={handleCheckAll}
      checkedAllDrivers={verifCheckedAll()}
      handleColumns={handleColumns}
      localStorageNameKey={columnsKey}
      isLoading={isLoading}
      colSpan={columns.filter((column) => column.checked).length + 2}
      TableContent={
        <TableContent
          handleCheck={handleCheck}
          columns={columns}
          drivers={drivers}
          colSpan={columns.filter((column) => column.checked).length + 2}
          driversOriginalSelected={driversOriginalSelected}
        />
      }
      TableFooter={
        <TableFooter
          columns={columns}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          page={page}
          driversOptimiserCount={driversOptimiserCount}
        />
      }
    />
  )
}
DriversTab.propTypes = {
  drivers: PropTypes.array,
  isLoading: PropTypes.bool,
  setDriversSelected: PropTypes.func,
  setErrorCheck: PropTypes.func,
  setDrivers: PropTypes.func,
  ldt: PropTypes.any,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  driversOptimiserCount: PropTypes.number,
  setDriversOriginalSelected: PropTypes.func,
  driversOriginalSelected: PropTypes.array,
  setErrorDialogOpen: PropTypes.func,
  setErrorMessage: PropTypes.func,
}
