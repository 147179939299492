import React from 'react'
import PropTypes from 'prop-types'

import NavBar from '../../containers/main/NavBar'
import { Route, Switch } from 'react-router-dom'
import { mainRoutes } from '../data/mainRoutes'
import NotFound from '../../containers/common/PageNotFound'
import { routeCanAccess } from '../../utils/roleManagement'

const MainRoutes = ({ userRole }) => (
  <>
    <div className="navbar-container">
      <NavBar />
    </div>
    <Switch>
      {routeCanAccess(mainRoutes, userRole).map((route, index) => (
        <Route key={index} path={route.path} exact component={route.component} />
      ))}
      {/*When indicationg a route without path,
      it will always be rendered, so we may use it for non valid urls */}
      <Route component={NotFound} />
    </Switch>
  </>
)

MainRoutes.propTypes = {
  userRole: PropTypes.string,
}

export default MainRoutes
