import Tournee from '../../../pages/main/Tournee'
import { connect } from 'react-redux'

import {
  getDriversPlannings,
  deleteTours,
  getToursFromPolaris,
  viewPlannification,
} from '../../../redux/simulateTournee/actions'
import {
  getDefaultFilterTours,
  getFilterTours,
} from '../../../redux/filter/selectors'
import { resetFilterTour, setFilterTour } from '../../../redux/filter/actions'
import {
  getIsViewPlannification,
  isTourActionsSimulate,
  getSimulationIsError,
  getSimulationsIsLoading,
  getSimulationsErrorMessage,
  getCountTours,
  getToursList,
} from '../../../redux/simulateTournee/selectors'
import { pageChanged, pageLimitChanged } from '../../../redux/filter/actions'
const mapStateToProps = (state) => ({
  tourList: getToursList(state),
  countTours: getCountTours(state),
  isLoading: getSimulationsIsLoading(state),
  isError: getSimulationIsError(state),
  errorMessage: getSimulationsErrorMessage(state),
  isViewPlannification: getIsViewPlannification(state),
  isTourActionSimulate: isTourActionsSimulate(state),
  filterReducer: getFilterTours(state),
  defaultFilterTours: getDefaultFilterTours(state),
})

const mapDisptachToProps = (dispatch) => ({
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(getToursFromPolaris())
  },

  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(getToursFromPolaris())
  },

  deleteTours: (data) => {
    dispatch(deleteTours(data))
  },

  getDriversPlannings: (data) => {
    dispatch(getDriversPlannings(data))
  },

  setFilter: (data, fromAppliquer) => {
    dispatch(setFilterTour(data, fromAppliquer))
  },

  resetFilterTour: (date) => {
    dispatch(resetFilterTour(date))
  },
  viewPlannification: (isViewPlannif) => {
    dispatch(viewPlannification(isViewPlannif))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(Tournee)
