import { connect } from 'react-redux'

import TourneeModal from '../../../pages/settings/components/Modals/TourneeModal'
import {
  fetchParticipants,
  getParticipantsMore,
  onSearch,
  pageChanged,
  resetParticipantConfig,
  roleDriverFiltreChange,
} from '../../../redux/participants/actions'
import {
  getParticipants,
  getParticipantsCount,
  getParticipantsListIsLoading,
} from '../../../redux/participants/selectors'
import {
  changeOffsetWareHouse,
  fetchWareHouses,
  fetchWareHousesMore,
  resetConfigWareHouse,
  searchWareHouses,
} from '../../../redux/simulateTournee/actions'
import {
  getIsLoadingWarehouses,
  getWarehouses,
  getWarehousesCount,
} from '../../../redux/simulateTournee/selectors'
import { createTournee, updateTournee } from '../../../redux/tournees/actions'
import {
  getErrorMessage,
  getIsLoadingTourneeAction,
  getSuccess,
} from '../../../redux/tournees/selectors'
import {
  fetchVehicules,
  getVehiculesMore,
  onSearchVehicules,
  pageChangedVehicules,
  resetVehiculeConfig,
} from '../../../redux/vehicules/actions'
import {
  getIsLoadingVehiculesList,
  getVehiculesList,
  getVehiculesListCount,
} from '../../../redux/vehicules/selectors'

const mapStateToProps = (state) => ({
  isLoadingTourneeAction: getIsLoadingTourneeAction(state),
  success: getSuccess(state),
  errorMessage: getErrorMessage(state),

  drivers: getParticipants(state),
  driversCount: getParticipantsCount(state),
  isLoadingDrivers: getParticipantsListIsLoading(state),

  vehicules: getVehiculesList(state),
  vehiculesCount: getVehiculesListCount(state),
  isLoadingVehiculesList: getIsLoadingVehiculesList(state),

  warehouses: getWarehouses(state),
  warehousesCount: getWarehousesCount(state),
  isLoadingWarehouses: getIsLoadingWarehouses(state),
})

const mapDisptachToProps = (dispatch) => ({
  createTournee: (tournee) => dispatch(createTournee(tournee)),
  updateTournee: (newTournee) => dispatch(updateTournee(newTournee)),

  fetchDrivers: () => {
    dispatch(roleDriverFiltreChange())
    dispatch(fetchParticipants())
  },
  searchDrivers: (search) => {
    dispatch(roleDriverFiltreChange())
    dispatch(pageChanged(0))
    dispatch(onSearch(search))
    dispatch(fetchParticipants())
  },
  fetchMoreDrivers: (offset, search) => {
    dispatch(roleDriverFiltreChange())
    dispatch(pageChanged(offset))
    dispatch(onSearch(search))
    dispatch(getParticipantsMore())
  },

  fetchVehicules: () => dispatch(fetchVehicules()),
  searchVehicules: (search) => {
    dispatch(onSearchVehicules(search))
    dispatch(pageChangedVehicules(0))
    dispatch(fetchVehicules())
  },
  fetchMoreVehicules: (offset, search) => {
    dispatch(pageChangedVehicules(offset))
    dispatch(onSearchVehicules(search))
    dispatch(getVehiculesMore())
  },

  resetDriver: () => dispatch(resetParticipantConfig()),
  resetVehicule: () => dispatch(resetVehiculeConfig()),
  resetConfigWareHouse: () => dispatch(resetConfigWareHouse()),

  fetchWareHouses: (search) => {
    dispatch(searchWareHouses(search))
    dispatch(fetchWareHouses())
  },
  fetchWareHousesMore: (offset) => {
    dispatch(changeOffsetWareHouse(offset))
    dispatch(fetchWareHousesMore())
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(TourneeModal)
