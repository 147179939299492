import React from 'react'
import PropTypes from 'prop-types'

import DateFilterRange from './DateFilterRange'
import DateFilter from '../../../../../containers/main/NavFilter/DateFilter'
import Departements from '../../../../../containers/main/NavFilter/Departements'
import Drivers from '../../../../../containers/main/NavFilter/Drivers'
import MacroCanal from '../../../../../containers/main/NavFilter/MacroCanal'
import Prestations from '../../../../../containers/main/NavFilter/Prestations'
import SalesChannels from '../../../../../containers/main/NavFilter/SalesChannels'
import Status from './Status'
import Tours from '../../../../../containers/main/NavFilter/Tours'
import WareHouseList from '../../../../../containers/main/NavFilter/WareHouses'
import CourseType from './CourseType'
import BusinessOwnerFilter from '../../../../../containers/main/NavFilter/BusinessOwner'
import SubjectFilter from '../../../../../containers/main/NavFilter/SubjectFilter'
import ProcessingDepartmentFilter from './ProcessingDepartmentFilter'
import NatureDiscussionFilter from './nature'
import Rdi from '../../../../../containers/main/NavFilter/Rdi'

export default function Cell({
  index,
  disableFilter,
  filterStateChanged,
  filtersValues,
}) {
  switch (index) {
    case 1:
      return (
        <DateFilterRange
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          dateRangeFiltersValues={filtersValues['daterange']}
          dateFilterLabel={'Date de démarrage'}
          nameFilter={'daterange'}
        />
      )
    case 2:
      return (
        <DateFilter
          deliveryDateFilter={true}
          filterStateChanged={filterStateChanged}
          datePickedFiltersValues={filtersValues['dropOffDay']}
          nameFilter={'dropOffDay'}
          dateFilterLabel={'Date de livraison'}
        />
      )
    case 3:
      return (
        <DateFilter
          deliveryDateFilter={true}
          dateFilterLabel={'Date de livraison'}
          filterStateChanged={filterStateChanged}
          datePickedFiltersValues={filtersValues['dropOffDay']}
          nameFilter={'dropOffDay'}
        />
      )
    case 4:
      return (
        <DateFilterRange
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          dateRangeFiltersValues={filtersValues['dropOffDayRange']}
          dateFilterLabel={'Date de livraison'}
          nameFilter={'dropOffDayRange'}
        />
      )
    case 5:
      return (
        <DateFilterRange
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          dateRangeFiltersValues={filtersValues['createdAt']}
          dateFilterLabel={'Date de création'}
          nameFilter={'createdAt'}
          isTodayDate={true}
          nameFilterTodayDate={'createdAtTodayDate'}
          dateRangeFiltersTodayDate={filtersValues['createdAtTodayDate']}
        />
      )
    case 6:
      return (
        <DateFilter
          deliveryDateFilter={false}
          dateFilterLabel={'Deadline'}
          filterStateChanged={filterStateChanged}
          datePickedFiltersValues={filtersValues['datepicked']}
          nameFilter={'datepicked'}
        />
      )
    case 7:
      return (
        <DateFilterRange
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          dateRangeFiltersValues={filtersValues['receptionDate']}
          dateFilterLabel={'Date de réception'}
          nameFilter={'receptionDate'}
        />
      )
    case 8:
      return (
        <Status
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          statusFiltersValues={filtersValues['status']}
        />
      )
    case 9:
      return (
        <Drivers
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          driversFiltersValues={filtersValues['drivers']}
        />
      )
    case 10:
      return (
        <MacroCanal
          filterStateChanged={filterStateChanged}
          macroCanalFiltersValues={filtersValues['client']}
        />
      )
    case 11:
      return (
        <SalesChannels
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          channelsFiltersValues={filtersValues['salesChannels']}
        />
      )
    case 12:
      return (
        <BusinessOwnerFilter
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          businessOwnerFiltersValues={filtersValues['businessOwner']}
        />
      )
    case 13:
      return (
        <CourseType
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          courseTypeFiltersValues={filtersValues['courseType']}
        />
      )
    case 14:
      return (
        <Rdi
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          rdiFiltersValues={filtersValues['rdi']}
        />
      )
    case 15:
      return (
        <Prestations
          disabled={disableFilter}
          filterStateChanged={filterStateChanged}
          prestationFiltersValues={filtersValues['prestation']}
        />
      )
    case 16:
      return (
        <Departements
          filterStateChanged={filterStateChanged}
          departmentFiltersValues={filtersValues['department']}
        />
      )
    case 17:
      return (
        <WareHouseList
          filterStateChanged={filterStateChanged}
          wareHouseFiltersValues={filtersValues['warehouse']}
          disabled={disableFilter}
        />
      )
    case 18:
      return (
        <Tours
          filterStateChanged={filterStateChanged}
          tourListFilterValues={filtersValues['tourList']}
          disabled={disableFilter}
        />
      )
    case 19:
      return (
        <NatureDiscussionFilter
          filterStateChanged={filterStateChanged}
          natureDiscussion={filtersValues['nature']}
        />
      )
    case 20:
      return (
        <SubjectFilter
          filterStateChanged={filterStateChanged}
          subjectFiltersValues={filtersValues['subject']}
        />
      )
    case 21:
      return (
        <ProcessingDepartmentFilter
          filterStateChanged={filterStateChanged}
          processingDepartmentFiltersValues={filtersValues['processingDepartment']}
        />
      )

    default:
      return null
  }
}
Cell.propTypes = {
  index: PropTypes.number,
  disableFilter: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  filtersValues: PropTypes.any,
}
