import { connect } from 'react-redux'

import ImportationParticipants from '../../../pages/settings/components/Modals/ImportationParticipants'

import {
  changeParticipantsStatus,
  fetchParticipantsCustomizedFilter,
  fetchParticipantsCustomizedFilterMore,
  resetParticipantConfig,
  resetParticipantList,
} from '../../../redux/participants/actions'

import {
  getParticipantsList,
  getIsLoadingParticipants,
  getParticipantsListCount,
  getisLoadingParticipantAction,
  getSuccess,
  getErrorMessage,
} from '../../../redux/participants/selectors'

const mapStateToProps = (state) => ({
  options: getParticipantsList(state),
  isLoading: getIsLoadingParticipants(state),
  countOptions: getParticipantsListCount(state),

  isLoadingParticipantAction: getisLoadingParticipantAction(state),
  success: getSuccess(state),
  errorMessage: getErrorMessage(state),
})

const mapDisptachToProps = (dispatch) => ({
  onSearch: (filter) => {
    dispatch(fetchParticipantsCustomizedFilter(filter))
  },
  onSearchMore: (filter) => dispatch(fetchParticipantsCustomizedFilterMore(filter)),
  resetParticipantList: () => dispatch(resetParticipantList()),
  changeParticipantsStatus: (ids) => dispatch(changeParticipantsStatus(ids)),
  resetParticipantConfig: () => dispatch(resetParticipantConfig()),
})

export default connect(mapStateToProps, mapDisptachToProps)(ImportationParticipants)
