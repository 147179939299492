import { connect } from 'react-redux'
import Payment from '../../../pages/main/PlaceOrders/Payment/index'

import {
  getTotalPriceState,
  getSelectedlientState,
  getOrdersState,
  getPaymentTypes,
  getIsNewClientState,
  getClientState,
  getCodeAssembly,
  getCodePromoVerification,
  getNewCreatedCourse,
  getShowPopUp,
  isLoading,
  getErrorMessage,
  getCommand,
  getDevis,
  getShowPopUpDevis,
} from '../../../redux/shipment/selectors'

import {
  createNewOrder,
  verifyPromocode,
  hidePopUp,
  setCommand,
  setPaymentForm,
  assemblySaved,
  changeStatusDevis,
} from '../../../redux/shipment/actions'

const mapStateToProps = (state) => ({
  totalPrice: getTotalPriceState(state),
  codePromotion: getCodePromoVerification(state),
  selectedClient: getSelectedlientState(state),
  orders: getOrdersState(state),
  paymentTypes: getPaymentTypes(state),
  showPopUp: getShowPopUp(state),
  client: getClientState(state),
  newClient: getIsNewClientState(state),
  createdNewCourse: getNewCreatedCourse(state),
  showPopUpDevis: getShowPopUpDevis(state),
  loading: isLoading(state),
  codeAssembly: getCodeAssembly(state),
  command: getCommand(state),
  errorMessage: getErrorMessage(state),
  devis: getDevis(state),
})
const mapDisptachToProps = (dispatch) => ({
  verifyPromocode: (codePromo) => dispatch(verifyPromocode(codePromo)),
  hidePopUp: () => dispatch(hidePopUp()),
  setPaymentFormAction: (paymentForm) => dispatch(setPaymentForm(paymentForm)),
  setCommand: (command) => dispatch(setCommand(command)),
  assemblySaved: () => dispatch(assemblySaved()),
  createNewOrder: (order, type, statement) =>
    dispatch(createNewOrder(order, type, statement)),
  changeStatusDevis: (status) => dispatch(changeStatusDevis(status)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Payment)
