export const getParticipantsState = ({ participants }) => participants

export const getParticipantsListIsLoading = (state) =>
  getParticipantsState(state).isLoadingParticipantsList
export const getParticipants = (state) => getParticipantsState(state).participants
export const getParticipantsCount = (state) =>
  getParticipantsState(state).participantsCount
export const getErrorMessage = (state) => getParticipantsState(state).errorMessage
export const getIsErrorFetch = (state) => getParticipantsState(state).isErrorFetch

export const getIsSuccessRefresh = (state) =>
  getParticipantsState(state).isSuccessRefresh

export const getSuccess = (state) => getParticipantsState(state).success

export const getisLoadingParticipantAction = (state) =>
  getParticipantsState(state).isLoadingParticipantAction

export const getParticipantsList = (state) =>
  getParticipantsState(state).participantsList
export const getParticipantsListCount = (state) =>
  getParticipantsState(state).participantsListCount
export const getIsLoadingParticipants = (state) =>
  getParticipantsState(state).isLoadingParticipants
