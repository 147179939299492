import { styled } from '@mui/material/styles'
import { DateRangePicker } from 'react-date-range'

export const StyledDateRangePicker = styled(DateRangePicker)({
  width: '100%',
  '& .rdrDefinedRangesWrapper': {
    display: 'none',
  },
  '& .rdrDateDisplayWrapper': {
    display: 'none',
  },
  '& .rdrCalendarWrapper': {
    width: '100%',
  },
  '& .rdrNextPrevButton': {
    background: 'transparent',
  },
  '& .rdrMonthAndYearPickers select:hover': {
    background: 'transparent',
  },
  '& .rdrMonthAndYearPickers select ': {
    fontWeight: '600',
  },
})
