import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import IconFilterTableCell from '../../../../assets/icons/iconFilterTableCell'
import PopHover from '../../../PopHover'
import ActionButtons from '../../../ActionButton'
import AutoCompleteSearch from '../../../ListWithSearch/selectListWithSearch/AutoCompleteSearch'

export default function Filter({
  dataList,
  onDataChanged,
  filtersValues,
  id,
  filterKey,
}) {
  const [index, setIndex] = useState(25)

  const [anchorEl, setAnchorEl] = useState(null)
  const [data, setData] = useState(dataList?.slice(0, 25))
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState([])
  const [checkedAll, setCheckedAll] = useState(true)
  const [init, setInit] = useState(false)
  const [isMoreLoading, setIsMoreLoading] = useState(false)

  //Init selected List
  useEffect(() => {
    if (dataList) {
      const checkedAll = filtersValues?.includes('TOUS') || !filtersValues
      if (filtersValues && filtersValues.length > 0 && !checkedAll) {
        const initFilterTAB = filtersValues ? filtersValues : []
        setSelected(initFilterTAB)
      } else if (selected.length === 0 || checkedAll) {
        const selecteds = dataList.map((n) => n.code)
        setSelected(selecteds)
      }

      setData(dataList.slice(0, 25))
      setInit(true)
    }
  }, [dataList, filtersValues])
  //FETCH more data with search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        onSearch(search)
      } else {
        if (init) {
          setData(dataList.slice(0, 25))
          setIndex(25)
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    let val = value.trim()
    setSearch(val)
    setIndex(25)
  }

  const onSearch = (search) => {
    const filteredSuggestions = dataList
      .filter((suggestion) =>
        suggestion.name.toLowerCase().includes(search.toLowerCase())
      )
      .filter((item) => item)
      .slice(0, 25)
    setData(filteredSuggestions)
  }

  //FETCH more data with scroll
  const fetchMoreData = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) <=
        event.target.clientHeight &&
      index < dataList.length &&
      !isMoreLoading
    ) {
      setIsMoreLoading(true)
      setTimeout(() => {
        setIndex(index + 25)
        if (search.length > 0) {
          const filteredSuggestions = dataList
            .filter((suggestion) =>
              suggestion.name.toLowerCase().includes(search.toLowerCase())
            )
            .filter((item) => item)
          setData(filteredSuggestions.slice(0, index + 25))
        } else {
          setData(dataList.slice(0, index + 25))
        }
        setIsMoreLoading(false)
      }, 500)
    }
  }

  // Click All list DATA
  const handleToggleAll = (event) => {
    setCheckedAll(event.target.checked)
    if (event.target.checked) {
      const newSelecteds = dataList.map((n) => n.code)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  // Click single data
  const handleToggle = (value) => () => {
    const selectedIndex = selected.indexOf(value)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  // Click DOUBLE item
  const handleDoubleClickAll = () => {
    setCheckedAll(!checkedAll)
    if (!checkedAll) {
      const newSelecteds = dataList.map((n) => n.code)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  // Click DOUBLE item ALL
  const handleDoubleClick = (value) => () => {
    setSelected([value])
  }

  //Verif checked element
  const isSelected = (code) => selected.indexOf(code) !== -1

  const open = Boolean(anchorEl)

  // Enregistrer Filter
  const onApply = () => {
    if (selected.length === 0) {
      setSelected(dataList.map((n) => n.code))
      onDataChanged(filterKey, null)
    } else {
      onDataChanged(
        filterKey,
        selected.length === dataList.length ? ['TOUS'] : [...new Set(selected)]
      )
    }
    setAnchorEl(false)
  }

  // Annuler Filter
  const onCancel = () => {
    setSelected(dataList.map((n) => n.code))
    onDataChanged(filterKey, null)
    setAnchorEl(false)
  }
  return (
    <>
      <IconFilterTableCell
        style={{
          width: '18px',
          height: '18px',
          alignSelf: 'center',
          marginLeft: '10px',
          cursor: 'pointer',
        }}
        color={filtersValues ? '#00e676' : 'balck'}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <PopHover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        content={
          <div style={{ width: '240px' }}>
            <AutoCompleteSearch
              handleToggle={handleToggle}
              handleDoubleClick={handleDoubleClick}
              dataList={data}
              fetchMoreData={fetchMoreData}
              onChangeSearch={onChangeSearch}
              search={search}
              handleDoubleClickAll={handleDoubleClickAll}
              handleToggleAll={handleToggleAll}
              numSelected={selected.length === dataList?.length}
              isSelected={isSelected}
              isProgrammer={true}
            />
            <ActionButtons
              isProgrammer={true}
              onApply={onApply}
              onCancel={onCancel}
            />
          </div>
        }
        id={id}
        className="pophover-container"
      />
    </>
  )
}
Filter.propTypes = {
  dataList: PropTypes.array,
  onDataChanged: PropTypes.func,
  defaultLabel: PropTypes.string,
  filtersValues: PropTypes.array,
  id: PropTypes.bool,
  filterKey: PropTypes.string,
}
