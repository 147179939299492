export const shipmentFormData = (others) => {
  return {
    ...others,
    selectedSalesChannel: {},
    prestation: null,
    selectPresCanalService: null,
    originQuery: '',
    selectedOrigin: null,
    destinationQuery: '',
    selectedDestination: null,
    direction: undefined,
    date: null,
    time: undefined,
    weight: '',
    nbItems: '',
    purchaseAmount: '',
    estimatedPrice: null,
    clientPrice: 0,
    providerPrice: 0,
    level: 0,
    manutention: true,
    manutentionDouble: false,
    vehiculeType: 'M',
    manutentionOptions: 'avecManutention',
    factures: [''],
    courseType: 'ClientDelivery',
    clientInfos: true,
    volume: '',
    envoiMailArrivee: false,
    orderBalance: '',
    plVlAdresseArrivee: true,
    plVlAdresseDepart: true,
    nbrMarcheAvantAscenseurAdresseArrivee: 0,
    nbrMarcheAvantAscenseurAdresseDepart: 0,
  }
}
