import React from 'react'
import PropTypes from 'prop-types'
import FluentPersonSettings16Filled from '../../../../../../assets/icons/detailsLivraison/FluentPersonSettings16Filled'
import { CardSyled, TitleWithIcon } from '../card/style'

export default function ChauffeurLivraison({ order }) {
  return (
    <>
      {order?.prestationServiceCanal && (
        <CardSyled>
          <TitleWithIcon>
            <FluentPersonSettings16Filled
              style={{
                width: '20',
                height: '20',
                marginRight: '4px',
              }}
            />
            Prestations
          </TitleWithIcon>

          <div style={{ padding: '8px' }}>
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                lineHeight: '30px',
                fontSize: '13px',
                fontWeight: 600,
                textDecoration: 'none',
                color: '#2d2c2c',
              }}
            >
              {order.prestationServiceCanal?.label}
            </span>
          </div>
        </CardSyled>
      )}
    </>
  )
}
ChauffeurLivraison.propTypes = {
  order: PropTypes.object,
}
