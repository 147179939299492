import { styled } from '@mui/material/styles'

export const StyledHeaderPlanning = styled('div')({
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    height: '10px', // hauteur de la scrollbar horizontale
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'blue',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },

  '@media (max-width: 600px)': {
    position: 'absolute',
    zIndex: 1,
  },
})
export const StyledDivSwitch = styled('div')({
  paddingLeft: '0px',
  paddingRight: '0px',
  display: 'flex',
  alignItems: 'center',
})
