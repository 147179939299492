import { connect } from 'react-redux'
import Client from '../../../pages/main/PlaceOrders/Client/index'

import {
  getSelectedlientState,
  getIsNewClientState,
  getClientState,
  getOrdersState,
} from '../../../redux/shipment/selectors'

import {
  setSelectedClientAction,
  setIsNewClientAction,
  setClientErrorsAction,
  setOrderClientAction,
  setInfoComplementaire,
} from '../../../redux/shipment/actions'

const mapStateToProps = (state) => ({
  selectedClient: getSelectedlientState(state),
  newClient: getIsNewClientState(state),
  client: getClientState(state),
  orders: getOrdersState(state),
})
const mapDisptachToProps = (dispatch) => ({
  setSelectedClient: (data) => dispatch(setSelectedClientAction(data)),
  handleNewClient: (isNew) => dispatch(setIsNewClientAction(isNew)),
  updateClient: (client) => dispatch(setOrderClientAction(client)),
  setClientErrors: (errors) => dispatch(setClientErrorsAction(errors)),
  // TODO : use the update order action
  setShipmentComplInfo: (id, info) => dispatch(setInfoComplementaire(id, info)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Client)
