import React from 'react'

export function MaterialSymbolsPostAdd(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5 21q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h9v2H5v14h14v-9h2v9q0 .825-.588 1.413T19 21H5Zm3-4v-2h8v2H8Zm0-3v-2h8v2H8Zm0-3V9h8v2H8Zm9-2V7h-2V5h2V3h2v2h2v2h-2v2h-2Z"
      ></path>
    </svg>
  )
}
export default MaterialSymbolsPostAdd
