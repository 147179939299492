import { httpDelete, httpPost } from '../http/actions'
import {
  GET_VEHICULES,
  GET_VEHICULES_SUCCESS,
  GET_VEHICULES_FAILURE,
  GET_VEHICULES_MORE,
  GET_VEHICULES_MORE_SUCCESS,
  GET_VEHICULES_MORE_FAILURE,
  ADD_VEHICULE,
  ADD_VEHICULE_FAILURE,
  ADD_VEHICULE_SUCCESS,
  UPDATE_VEHICULE,
  UPDATE_VEHICULE_SUCCESS,
  UPDATE_VEHICULE_FAILURE,
  DELETE_VEHICULE,
  DELETE_VEHICULE_SUCCESS,
  DELETE_VEHICULE_FAILURE,
} from './constants'

import { axiosHTTP } from '../../utils'
import { removeNull } from '../../utils/utils'

const geodisApi = process.env.REACT_APP_GEODIS_MS_URL

let CancelToken = axiosHTTP.CancelToken
let cancel
export default (store) => (next) => (action) => {
  // GET VEHICULES
  if (action.type === GET_VEHICULES) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      vehicules: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}vehicule/getVehicleByfilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: GET_VEHICULES_SUCCESS,
        failure: GET_VEHICULES_FAILURE,
      })
    )
  }

  if (action.type === GET_VEHICULES_MORE) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const {
      vehicules: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}vehicule/getVehicleByfilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: GET_VEHICULES_MORE_SUCCESS,
        failure: GET_VEHICULES_MORE_FAILURE,
      })
    )
  }

  if (action.type === ADD_VEHICULE) {
    const { vehicule } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}vehicule/save`,
        data: vehicule,
        success: ADD_VEHICULE_SUCCESS,
        failure: ADD_VEHICULE_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_VEHICULE) {
    const { newVehicule } = action.payload

    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}vehicule/save`,
        data: newVehicule,
        success: UPDATE_VEHICULE_SUCCESS,
        failure: UPDATE_VEHICULE_FAILURE,
      })
    )
  }

  if (action.type === DELETE_VEHICULE) {
    const { id } = action.payload

    store.dispatch(
      httpDelete({
        endPoint: `${geodisApi}vehicule/${id}`,
        success: DELETE_VEHICULE_SUCCESS,
        failure: DELETE_VEHICULE_FAILURE,
      })
    )
  }

  next(action)
}
