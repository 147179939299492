export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_GOOGLE_ACCOUNT = 'USER_LOGIN_GOOGLE_ACCOUNT'
export const USER_LOGIN_MICROSOFT_ACCOUNT = 'USER_LOGIN_MICROSOFT_ACCOUNT'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const USER_LOGOUT = 'USER_LOGOUT'

// login actions ---
export const login = (login, password, tokenRecaptcha) => ({
  type: USER_LOGIN,
  payload: { login, password, tokenRecaptcha },
})
export const googleLogin = (googleToken) => ({
  type: USER_LOGIN_GOOGLE_ACCOUNT,
  payload: { googleToken },
})
export const microsoftLogin = (azureToken) => ({
  type: USER_LOGIN_MICROSOFT_ACCOUNT,
  payload: { azureToken },
})
// logout actions ---
export const logout = () => ({
  type: USER_LOGOUT,
  payload: {},
})
