import React, { useEffect } from 'react'
import Pagination from '@mui/material/Pagination'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PropTypes from 'prop-types'
import PopHover from '../PopHover'
import PrimaryOutlineButton from '../Buttons/PrimaryOutlineButton'
import SelectedListItem from './LimitPaginationList'
import { StyledPaginationContainer, StyledText } from './style'
/** The Custom Pagination component enables the user to select a specific page from a range of pages. It provides also the management of the limit number of items per page */
export default function CustomPagination({
  selectedPage,
  onPageChange,
  pageTotal,
  itemsTotal,
  handlePageLimitChange,
  limitIndex,
  onLimitChange,
  limit,
}) {
  const [page, setPage] = React.useState(1)
  const [anchorEl, setAnchorEl] = React.useState(null)
  useEffect(() => {
    setPage(selectedPage)
  }, [selectedPage])
  // codeStatus
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSelect = (value, index, label) => {
    setAnchorEl(null)
    handlePageLimitChange(value)
    onLimitChange(index, label)
  }
  const handleChange = (event, value) => {
    setPage(value)
    onPageChange(value)
  }
  const open = Boolean(anchorEl)

  return (
    <StyledPaginationContainer>
      {pageTotal > 1 && (
        <StyledText>
          Pages: {page} / {pageTotal}
        </StyledText>
      )}
      {limit !== 'Tous' && (
        <Pagination
          count={pageTotal}
          page={page}
          onChange={handleChange}
          color="secondary"
        />
      )}
      <PrimaryOutlineButton
        style={{ height: 30 }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        content={limit >= itemsTotal ? 'Tous' : limit}
        icon={<KeyboardArrowDownIcon />}
        disabled={limit > itemsTotal}
      />
      <StyledText>
        {'Total :'}
        {itemsTotal}
      </StyledText>
      <PopHover
        content={
          <SelectedListItem
            onSelect={handleSelect}
            selectedIndex={limitIndex}
            itemsTotal={itemsTotal}
          />
        }
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        id="pagination-limit-list"
        className="list-limit"
      />
    </StyledPaginationContainer>
  )
}
CustomPagination.propTypes = {
  /** The executed function on the page change event */
  onPageChange: PropTypes.func,
  /** The function that is executed after the limit change in order to refresh the item list */
  handlePageLimitChange: PropTypes.func,
  /** The actual selected page number */
  selectedPage: PropTypes.number,
  /** The total page numbers */
  pageTotal: PropTypes.number,
  /** The total items number */
  itemsTotal: PropTypes.number,
  /** The selected limit index   */
  limitIndex: PropTypes.number,
  /** The function that handles the limit change in the UI  */
  onLimitChange: PropTypes.func,
  /** The  text content of the limit button */
  limit: PropTypes.string,
}
