import React from 'react'
import PropTypes from 'prop-types'

import TableFreights from '../../../../containers/main/Entrepot/freights/tableFreights'
import NavFilter from '../../../../containers/main/NavFilter'
import { useHistory } from 'react-router-dom'

const Freights = ({
  filterReducer,
  setFilter,
  resetFilterFreights,
  defaultFilterFreights,
}) => {
  const history = useHistory()

  return (
    <>
      <NavFilter
        DateFilterReception={true}
        filterReducer={filterReducer}
        setFilter={setFilter}
        resetFilter={resetFilterFreights}
        pathnameFilter={history.location.pathname}
        defaultFilter={defaultFilterFreights}
      />
      <div style={{ paddingBottom: '20px' }}>
        <TableFreights />
      </div>
    </>
  )
}
Freights.propTypes = {
  filterReducer: PropTypes.object,
  setFilter: PropTypes.func,
  classes: PropTypes.object,
  resetFilterFreights: PropTypes.func,
  defaultFilterFreights: PropTypes.object,
}
export default Freights
