import React from 'react'

export function MingcuteExchangeEuroLine(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"></path>
        <path
          fill="currentColor"
          d="M3.399 9.176a1 1 0 0 1-.496-1.324C4.89 3.482 9.799 1.06 14.59 2.343a10.002 10.002 0 0 1 7.414 9.581c.007.903-.995 1.402-1.713.918l-2.678-1.804c-1.006-.678-.334-2.247.85-1.987l1.064.234A8.002 8.002 0 0 0 4.723 8.68a1 1 0 0 1-1.324.496Zm17.206 5.653a1 1 0 0 1 .497 1.324c-1.988 4.37-6.897 6.793-11.688 5.509A10.003 10.003 0 0 1 2 12.08c-.007-.903.995-1.402 1.713-.918l2.678 1.804c1.006.678.334 2.247-.85 1.987l-1.064-.234a8.002 8.002 0 0 0 14.804.605a1 1 0 0 1 1.324-.496ZM12.53 8.5c-.99 0-2.092.927-2.428 2.5H13a1 1 0 1 1 0 2h-2.898c.335 1.573 1.438 2.5 2.428 2.5c.6 0 1.203-.304 1.69-.91a1 1 0 1 1 1.56 1.25c-.787.983-1.927 1.66-3.25 1.66c-2.388 0-4.08-2.092-4.453-4.5H8a1 1 0 1 1 0-2h.077c.372-2.408 2.065-4.5 4.453-4.5c1.323 0 2.463.677 3.25 1.66a1 1 0 0 1-1.56 1.25c-.487-.606-1.09-.91-1.69-.91Z"
        ></path>
      </g>
    </svg>
  )
}
export default MingcuteExchangeEuroLine
