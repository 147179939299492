import React from 'react'
import PropTypes from 'prop-types'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import ListItemsComponent from '../listItemComponent'
import { styled } from '@mui/material/styles'

export const StyledList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  overflow: 'auto',
  overflowX: 'hidden',
  maxHeight: 'calc(100vh - 335px)',
  overflowY: 'overlay',
  '& .MuiList-padding': {
    paddingTop: '0',
  },
}))

export const StyledFirstItemList = styled(List)(() => ({
  '& .MuiList-padding': {
    marginLeft: '1px',
  },
}))

const FilterItemsList = ({
  dataList,
  handleToggle,
  handleDoubleClick,
  fetchMoreData,
  handleDoubleClickAll,
  handleToggleAll,
  numSelected,
  isSelected,
  search,
}) => {
  return (
    <>
      {search === '' && (
        <StyledFirstItemList dense>
          <ListItem key={0} button onDoubleClick={handleDoubleClickAll}>
            <ListItemText id={'checkbox-list-secondary-label-0'} primary={'Tous'} />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onClick={handleToggleAll}
                checked={dataList.length > 0 && numSelected === dataList.length}
                inputProps={{ 'aria-labelledby': 'checkbox-list-secondary-label-0' }}
                style={{ cursor: 'pointer' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </StyledFirstItemList>
      )}

      <div style={{ marginLeft: '1px' }}>
        <StyledList dense onScroll={fetchMoreData}>
          <ListItemsComponent
            data={dataList}
            handleToggle={handleToggle}
            handleDoubleClick={handleDoubleClick}
            isSelected={isSelected}
          />
        </StyledList>
      </div>
    </>
  )
}
FilterItemsList.propTypes = {
  handleToggle: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  dataList: PropTypes.array,
  fetchMoreData: PropTypes.func,
  handleDoubleClickAll: PropTypes.func,
  handleToggleAll: PropTypes.func,
  numSelected: PropTypes.number,
  isSelected: PropTypes.func,
  search: PropTypes.string,
}
export default FilterItemsList
