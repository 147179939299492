import { httpPatch, httpPost } from '../http/actions'
import {
  GET_PARTICIPANTS,
  GET_PARTICIPANTS_SUCCESS,
  GET_PARTICIPANTS_FAILURE,
  ADD_PARTICIPANT,
  ADD_PARTICIPANT_FAILURE,
  ADD_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT,
  UPDATE_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT_FAILURE,
  GET_PARTICIPANTS_LIST_MORE,
  GET_PARTICIPANTS_LIST_MORE_SUCCESS,
  GET_PARTICIPANTS_LIST_MORE_FAILURE,
  GET_PARTICIPANTS_CUSTOMIZED,
  GET_PARTICIPANTS_CUSTOMIZED_SUCCESS,
  GET_PARTICIPANTS_CUSTOMIZED_FAILURE,
  GET_PARTICIPANTS_CUSTOMIZED_MORE,
  GET_PARTICIPANTS_CUSTOMIZED_MORE_SUCCESS,
  GET_PARTICIPANTS_CUSTOMIZED_MORE_FAILURE,
  CHANGE_PARTICIPANTS_STATUS,
  CHANGE_PARTICIPANTS_STATUS_SUCCESS,
  CHANGE_PARTICIPANTS_STATUS_FAILURE,
  ANNULATION_PARTICIPATION,
  ANNULATION_PARTICIPATION_SUCCESS,
  ANNULATION_PARTICIPATION_FAILURE,
} from './constants'

import { axiosHTTP } from '../../utils'
import { removeNull } from '../../utils/utils'

const geodisApi = process.env.REACT_APP_GEODIS_MS_URL

let CancelToken = axiosHTTP.CancelToken
let cancel
export default (store) => (next) => (action) => {
  // GET PARTICIPANTS
  if (action.type === GET_PARTICIPANTS) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      participants: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}participant/getParticipantsByfilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: GET_PARTICIPANTS_SUCCESS,
        failure: GET_PARTICIPANTS_FAILURE,
      })
    )
  }

  if (action.type === GET_PARTICIPANTS_LIST_MORE) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const {
      participants: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}participant/getParticipantsByfilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: GET_PARTICIPANTS_LIST_MORE_SUCCESS,
        failure: GET_PARTICIPANTS_LIST_MORE_FAILURE,
      })
    )
  }

  if (action.type === ADD_PARTICIPANT) {
    const { participant } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}participant/create`,
        data: participant,
        success: ADD_PARTICIPANT_SUCCESS,
        failure: ADD_PARTICIPANT_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_PARTICIPANT) {
    store.dispatch(
      httpPatch({
        endPoint: `${geodisApi}participant/update`,
        data: action.payload,
        success: UPDATE_PARTICIPANT_SUCCESS,
        failure: UPDATE_PARTICIPANT_FAILURE,
      })
    )
  }

  if (action.type === GET_PARTICIPANTS_CUSTOMIZED) {
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}participant/getParticipantsByfilter`,
        data: removeNull(action.payload),
        success: GET_PARTICIPANTS_CUSTOMIZED_SUCCESS,
        failure: GET_PARTICIPANTS_CUSTOMIZED_FAILURE,
      })
    )
  }

  if (action.type === GET_PARTICIPANTS_CUSTOMIZED_MORE) {
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}participant/getParticipantsByfilter`,
        data: removeNull(action.payload),

        success: GET_PARTICIPANTS_CUSTOMIZED_MORE_SUCCESS,
        failure: GET_PARTICIPANTS_CUSTOMIZED_MORE_FAILURE,
      })
    )
  }

  if (action.type === CHANGE_PARTICIPANTS_STATUS) {
    store.dispatch(
      httpPatch({
        endPoint: `${geodisApi}participant/changeParticipantStatus`,
        data: action.payload,
        success: CHANGE_PARTICIPANTS_STATUS_SUCCESS,
        failure: CHANGE_PARTICIPANTS_STATUS_FAILURE,
      })
    )
  }

  if (action.type === ANNULATION_PARTICIPATION) {
    store.dispatch(
      httpPatch({
        endPoint: `${geodisApi}participant/update`,
        data: action.payload,
        success: ANNULATION_PARTICIPATION_SUCCESS,
        failure: ANNULATION_PARTICIPATION_FAILURE,
      })
    )
  }

  next(action)
}
