import QRCode from 'qrcode.react'
import PropTypes from 'prop-types'
import React from 'react'
export default function QRCodeComponent({ qrcode }) {
  return (
    <QRCode value={qrcode} size={210} level="M" style={{ margin: '10px auto' }} />
  )
}

QRCodeComponent.propTypes = {
  qrcode: PropTypes.string,
}
