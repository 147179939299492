import { connect } from 'react-redux'
import updateWarehouse from '../../../components/ShipmentAction/UpdateWarehouse'
import { OnUpdateWarehouse } from '../../../redux/order/actions'
import { getWareHouseListRequest } from '../../../redux/filter/actions'
import { getPackagWarehouse } from '../../../redux/orderPackage/selectors'

import {
  getIsWareHouseLoading,
  getWareHouses,
} from '../../../redux/filter/selectors'
import {
  getErrorActionCourse,
  getIsLoadingActionOrder,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  warehouses: getWareHouses(state),
  isLoading: getIsWareHouseLoading(state),
  warehousePackage: getPackagWarehouse(state),
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  errorActionCourse: getErrorActionCourse(state),
})

const mapDisptachToProps = (dispatch) => ({
  onUpdateWarehouse: (payload) => {
    dispatch(OnUpdateWarehouse(payload))
  },
  getWareHouses: () => dispatch(getWareHouseListRequest()),
})

export default connect(mapStateToProps, mapDisptachToProps)(updateWarehouse)
