import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import Button from '@mui/material/Button'
import GetAppIcon from '@mui/icons-material/GetApp'

const styles = {
  listItem: () => ({
    padding: ' 0 3px',
    borderRadius: '8px',
  }),
}
export default function Telechargement({
  downloadTransfert,
  downloadPreparation,
  downloadBonLivraison,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{ paddingRight: '9px' }}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        sx={{
          width: '150px',
          backgroundColor: 'white',
          color: '#1976D2',
          '&:hover': {
            width: '150px',
            backgroundColor: 'white',
            color: '#1976D2',
          },
          '&.MuiButton-root': {
            width: '150px',
            backgroundColor: 'white',
            color: '#1976D2',
            '&:hover': {
              width: '150px',
              backgroundColor: 'white',
              color: '#1976D2',
            },
          },
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              fontSize: '14px',
              textTransform: 'none',
            }}
          >
            Télécharger
          </span>

          {anchorEl ? <ExpandLess /> : <ExpandMore />}
        </div>
      </Button>

      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiMenu-paper': {
            border: '1px solid #d3d4d5 !important',
            width: '150px',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            downloadBonLivraison()
          }}
          sx={{
            '&.MuiMenuItem-root': {
              minHeight: '38px !important',

              '&:hover': {
                backgroundColor: '#0080ff24',
                color: '#1976d2',
              },
              '& .MuiListItemText-root': {
                flex: 'none',
              },
            },
          }}
        >
          <ListItemText
            primary={
              <div
                style={{
                  display: 'flex',
                  color: '#6D7B8E',
                  '&:hover': {
                    display: 'flex',
                    color: '#1976d2',
                    '& .MuiSvgIcon-root': {
                      color: '#1976d2 !important',
                    },
                  },
                }}
                id="BLs"
              >
                <GetAppIcon style={{ marginRight: '5px', color: '#373D57' }} />
                BLs{' '}
              </div>
            }
            style={styles.listItem()}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            downloadTransfert()
          }}
          sx={{
            '&.MuiMenuItem-root': {
              minHeight: '38px !important',

              '&:hover': {
                backgroundColor: '#0080ff24',
                color: '#1976d2',
              },
              '& .MuiListItemText-root': {
                flex: 'none',
              },
            },
          }}
        >
          <ListItemText
            primary={
              <div
                style={{
                  display: 'flex',
                  color: '#6D7B8E',
                  '&:hover': {
                    display: 'flex',
                    color: '#1976d2',
                    '& .MuiSvgIcon-root': {
                      color: '#1976d2 !important',
                    },
                  },
                }}
                id="Listes"
              >
                <GetAppIcon style={{ marginRight: '5px', color: '#373D57' }} />
                Listes
              </div>
            }
            style={styles.listItem()}
          />
        </MenuItem>{' '}
        <MenuItem
          onClick={() => {
            downloadPreparation()
          }}
          sx={{
            '&.MuiMenuItem-root': {
              minHeight: '38px !important',

              '&:hover': {
                backgroundColor: '#0080ff24',
                color: '#1976d2',
              },
              '& .MuiListItemText-root': {
                flex: 'none',
              },
            },
          }}
        >
          <ListItemText
            primary={
              <div
                style={{
                  display: 'flex',
                  color: '#6D7B8E',
                  '&:hover': {
                    display: 'flex',
                    color: '#1976d2',
                    '& .MuiSvgIcon-root': {
                      color: '#1976d2 !important',
                    },
                  },
                }}
                id="preparations"
              >
                <GetAppIcon style={{ marginRight: '5px', color: '#373D57' }} />
                Préparations{' '}
              </div>
            }
            style={styles.listItem()}
          />
        </MenuItem>
      </Menu>
    </div>
  )
}
Telechargement.propTypes = {
  downloadTransfert: PropTypes.func,
  downloadPreparation: PropTypes.func,
  downloadBonLivraison: PropTypes.func,
}
