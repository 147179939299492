import {
  GET_SESSIONS,
  PAGE_CHANGED,
  PAGE_LIMIT_CHANGED,
  REMOVE_ERROR,
  RESET_SUCCESS_ACTION,
  RESET_CONFIG_SESSION,
  SEARCH_SESSIONS,
  ADD_SESSION,
  UPDATE_SESSION,
  SET_ERROR_MESSAGE,
  GET_SESSION_BY_ID,
  FETCH_SESSION_DETAILS,
  FETCH_SESSION_PARTICIPANTS,
  SESSION_PARTICIPANT_UPDATE,
  SESSION_DELETE,
} from './constants'

export const fetchSessions = (filter) => ({
  type: GET_SESSIONS,
  payload: filter,
})

export const pageChanged = (offset) => ({
  type: PAGE_CHANGED,
  payload: { offset },
})

export const pageLimitChanged = (limit) => ({
  type: PAGE_LIMIT_CHANGED,
  payload: { limit },
})

export const removeError = () => ({
  type: REMOVE_ERROR,
})

export const resetSuccessAction = () => ({
  type: RESET_SUCCESS_ACTION,
})

export const resetSessionConfig = () => ({
  type: RESET_CONFIG_SESSION,
})

export const onSearch = (search) => ({
  type: SEARCH_SESSIONS,
  payload: { search },
})

export const createSession = (session) => ({
  type: ADD_SESSION,
  payload: { session },
})

export const updateSession = (payload) => ({
  type: UPDATE_SESSION,
  payload,
})

export const setErrorUploadFile = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: { message },
})

export const fetchSessionById = (id) => ({
  type: GET_SESSION_BY_ID,
  payload: { id: id },
})

export const fetchSessionDetails = (id) => ({
  type: FETCH_SESSION_DETAILS,
  payload: { id },
})

export const fetchSessionsParticipants = (id) => ({
  type: FETCH_SESSION_PARTICIPANTS,
  payload: { id },
})

export const updateSessionParticipant = (payload) => ({
  type: SESSION_PARTICIPANT_UPDATE,
  payload,
})

export const deleteSession = (payload) => ({
  type: SESSION_DELETE,
  payload,
})
