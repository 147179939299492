import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'

import MenuIcon from '@mui/icons-material/Menu'

import ListMenu from '../../../containers/settings/sidebar/listMenu'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { Box, Divider, ListItemButton } from '@mui/material'
const StyledListItemButton = styled(ListItemButton)({
  padding: '20px 0px',
  placeContent: 'center',
  fontSize: '16px',
  color: '#1976d2',
})
const drawerWidth = 273

const iconStyle = {
  fontSize: '24px',
}
const StyledCloseIcon = styled(CloseIcon)(() => iconStyle)
const StyledMenuIcon = styled(MenuIcon)(() => iconStyle)

export default function MenuDrawer({ userLogout }) {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <>
      {!mobileOpen ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            ml: 1,
            display: { lg: 'none' },
            position: 'absolute',
            padding: '16px',
            marginLeft: '0px',
            color: 'white',
          }}
        >
          <StyledMenuIcon />
        </IconButton>
      ) : (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            ml: 1,
            display: { lg: 'none' },
            position: 'absolute',
            padding: '16px',
            marginLeft: '0px',
            color: 'white',
          }}
        >
          <StyledCloseIcon />
        </IconButton>
      )}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { md: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#fff !important',
            top: '55px',
            height: 'calc(100vh - 55px)',
          },
        }}
      >
        <ListMenu onCloseDrawer={handleDrawerToggle} />
        <Box sx={{ bottom: '0px', position: 'absolute', width: '100%' }}>
          <Divider sx={{ margin: 'auto', width: '80%', height: '1px' }} />
          <StyledListItemButton onClick={userLogout}>
            Déconnexion
          </StyledListItemButton>
        </Box>
      </Drawer>
    </>
  )
}

MenuDrawer.propTypes = {
  userLogout: PropTypes.func,
}
