import React from 'react'
import PropTypes from 'prop-types'

import PrestationsList from '../../../containers/main/FilterOptimiser/PrestationsList'
import StatusDrivers from './StatusDrivers'

export default function Cell({ index, filterStateChanged, filtersValues }) {
  switch (index) {
    case 1:
      return (
        <PrestationsList
          filterStateChanged={filterStateChanged}
          prestationFiltersValues={filtersValues['prestation']}
        />
      )

    case 2:
      return <StatusDrivers filterStateChanged={filterStateChanged} />

    default:
      return null
  }
}
Cell.propTypes = {
  index: PropTypes.number,
  disableFilter: PropTypes.bool,
  filtersValues: PropTypes.any,
  filterStateChanged: PropTypes.func,
}
