import React from 'react'
import PropTypes from 'prop-types'
import ConterRemboursementIcon from '../../../../../../assets/icons/detailsLivraison/ConterRemboursement'
import CircularProgress from '@mui/material/CircularProgress'
import { CardSyled, TitleWithIcon } from '../card/style'

export default function ConterRemboursement({ order, isLoading }) {
  return (
    <CardSyled>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 20px 10px 1px',
        }}
      >
        <TitleWithIcon>
          <ConterRemboursementIcon
            style={{ marginRight: '4px', width: '20px', height: '20px' }}
          />
          Contre remboursement
        </TitleWithIcon>
        <div style={{ display: 'flex', alignSelf: 'center' }}>
          {isLoading ? (
            <CircularProgress
              color="primary"
              sx={{
                '&.MuiCircularProgress-root': {
                  width: '25px !important',
                  height: '25px !important',
                },
              }}
            />
          ) : (
            <span>
              {`${
                order?.courseMetadata?.orderBalance
                  ? order?.courseMetadata?.orderBalance
                  : '0'
              } €`}
            </span>
          )}
        </div>
      </div>
    </CardSyled>
  )
}
ConterRemboursement.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
}
