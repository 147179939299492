import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'

const DialogCostumized = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    maxHeight: 'calc(100% - 157px)',
  },
  '& .MuiDialogContent-root': {
    padding: '16px 0px 0px 16px',
  },
  '& .MuiDialogActions-root': {
    padding: '12px',
  },
}))

function DialogTitleCostumized(props) {
  const { children, onClose, isClosedIcon, isClosedIconLoading } = props

  return (
    <DialogTitle
      sx={{
        m: 0,
        color: 'white',
        padding: isClosedIcon && '20px',
        background: '#1976d2',
        display: 'flex',
      }}
      id={'dialog-title'}
    >
      {children}
      {isClosedIcon ? (
        isClosedIconLoading ? (
          <div
            style={{
              position: 'absolute',
              right: 15,
              top: 20,
            }}
          >
            <CircularProgress
              sx={{
                color: 'white',
                width: '20px !important',
                height: '20px !important',
              }}
            />
          </div>
        ) : (
          <IconButton
            id={'button-fermer'}
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 15,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
        )
      ) : null}
    </DialogTitle>
  )
}

DialogTitleCostumized.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  isClosedIcon: PropTypes.bool,
  isClosedIconLoading: PropTypes.bool,
}

export default function CustomizedDialogs({
  open,
  title,
  iconTitle,
  content,
  handleClose,
  isClosedIcon,
  handleClickAction,
  disabled,
  maxWidthDialog,
  isLoadingButtonAction,
  isModalActionFermer,
  isClosedIconLoading,
  isModalActionAnnuler,
  style,
  fullWidth,
  notPadding,
}) {
  return (
    <DialogCostumized
      open={open}
      maxWidth={maxWidthDialog}
      id={`dialog-${title}`}
      sx={style}
      fullWidth={fullWidth}
    >
      <DialogTitleCostumized
        onClose={handleClose}
        isClosedIcon={isClosedIcon}
        isClosedIconLoading={isClosedIconLoading}
      >
        {iconTitle || null}
        {title}
      </DialogTitleCostumized>
      <DialogContent dividers sx={{ padding: notPadding && '0px !important' }}>
        {content}
      </DialogContent>
      {(handleClickAction || isModalActionFermer || isModalActionAnnuler) && (
        <DialogActions>
          {isModalActionAnnuler ? (
            <Button
              id={'button-annuler'}
              variant={'contained'}
              onClick={handleClose}
              sx={{
                width: '120px',
                marginLeft: '15px',
                marginRight: '12px',
                textTransform: 'none ',
                fontSize: '13px ',
                fontWeight: '400 ',
                backgroundColor: '#f61057',
                ':hover': {
                  backgroundColor: '#d32f2f',
                },
              }}
            >
              Annuler
            </Button>
          ) : isModalActionFermer ? (
            <Button
              id={'button-annuler'}
              variant={'contained'}
              onClick={handleClose}
              sx={{
                width: '120px',
                marginLeft: '15px',
                marginRight: '12px',
                textTransform: 'none ',
                fontSize: '13px ',
                fontWeight: '400 ',
                backgroundColor: '#f61057',
                ':hover': {
                  backgroundColor: '#d32f2f',
                },
              }}
            >
              Fermer
            </Button>
          ) : (
            <>
              <Button
                id={'button-annuler'}
                variant={'contained'}
                onClick={handleClose}
                sx={{
                  width: '120px',
                  marginLeft: '15px',
                  marginRight: '12px',
                  textTransform: 'none ',
                  fontSize: '13px ',
                  fontWeight: '400 ',
                  backgroundColor: '#f61057',
                  ':hover': {
                    backgroundColor: '#d32f2f',
                  },
                }}
                disabled={isLoadingButtonAction}
              >
                Annuler
              </Button>
              {isLoadingButtonAction ? (
                <div
                  style={{
                    display: 'inline-flex',
                    verticalAlign: 'middle',
                    justifyContent: 'center',
                    width: '120px',
                  }}
                >
                  <CircularProgress
                    color="primary"
                    style={{ width: '30px', height: '30px' }}
                  />
                </div>
              ) : (
                <Button
                  id={'button-confirmer'}
                  variant={'contained'}
                  disabled={disabled}
                  sx={{
                    width: '120px',
                    textTransform: 'none ',
                    fontSize: '13px ',
                    fontWeight: '400 ',
                    backgroundColor: '#10A549',
                    ':hover': {
                      backgroundColor: '#158f44',
                    },
                  }}
                  onClick={handleClickAction}
                  type="submit"
                >
                  Confirmer
                </Button>
              )}
            </>
          )}
        </DialogActions>
      )}
    </DialogCostumized>
  )
}

CustomizedDialogs.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  iconTitle: PropTypes.any,
  content: PropTypes.any,
  handleClose: PropTypes.func,
  isClosedIcon: PropTypes.bool,
  handleClickAction: PropTypes.func,
  disabled: PropTypes.bool,
  maxWidthDialog: PropTypes.string,
  isLoadingButtonAction: PropTypes.bool,
  isModalActionFermer: PropTypes.bool,
  isClosedIconLoading: PropTypes.bool,
  isModalActionAnnuler: PropTypes.bool,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
  notPadding: PropTypes.bool,
}
