import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Button from '@mui/material/Button'
import { ORDER_TYPE } from '../../../../constants/appContants'
import './style.scss'
import { replaceDotWithComma } from '../../../../utils/floatFormatting'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'

export const StyledButton = styled(Button)({
  textTransform: 'initial !important',
  fontSize: '13px !important',
  fontWeight: '400 !important',
  boxShadow:
    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important', //kif kif
})

const PlaceOrderFooter = ({
  initOrder,
  steps,
  activeStep,
  shipmentSalesChannelList,
  assemblySalesChannelList,
  orders,
  addOrder,
  submitFirstStep,
  setActiveStep,
  client,
  submitSecondStep,
  isGetSalesChannelListLoading,
  setCommand,
  paymentForm,
  totalPrice,
  devis,
  isLoading,
}) => {
  // const [isPriceValidate, setvalidatePrice] = useState(true)
  useEffect(() => {
    /**Adding initial order  */
    addOrder(ORDER_TYPE.DELIVERY)
    return () => {
      /** Deleting order on unmount */
      initOrder()
    }
  }, [])
  // useEffect(() => {
  //   if (orders.length) {
  //     setvalidatePrice(
  //       orders[0].shipmentEstimatedPriceError?.data?.code < 400 ||
  //         !orders[0].shipmentEstimatedPriceError
  //     )
  //   }
  // }, [orders])

  useEffect(() => {
    if (!orders.length) {
      if (shipmentSalesChannelList?.length) {
        addOrder(ORDER_TYPE.DELIVERY)
      } else if (assemblySalesChannelList?.length) {
        addOrder(ORDER_TYPE.ASSEMBLY)
      }
    }
  }, [shipmentSalesChannelList, assemblySalesChannelList])
  let history = useHistory()
  useEffect(() => {
    return history.listen((location) => {
      switch (parseInt(location.hash[1])) {
        case 0:
          setActiveStep(0)

          break

        case 1:
          setActiveStep(1)

          break

        case 2:
          setActiveStep(2)

          break

        default:
          break
      }
    })
  }, [history])
  useEffect(() => {
    history.push(window.location.pathname + '#0')
  }, [])

  const errorFocus = (errors) => {
    const ErrorValuesShipment = [
      'selectedSalesChannel',
      'selectedOrigin',
      'originMail',
      'level',
      'selectedDestination',
      'destinationMail',
      'number',
      'date',
      'time',
      'nbItems',
      'weight',
      'purchaseAmount',
      'articleFamilies',
      'noteIntern',
      'factures',
      'clientPrice',
      'providerPrice',
      'destinationFirstname',
      'destinationLastname',
      'destinationPhone',
    ]
    const ErrorValuesAssembly = [
      'selectedSalesChannel',
      'selectedAddress',
      'date',
      'time',
      'category',
      'purchaseAmount',
      'observation',
      'clientPrice',
      'fileUploaded',
    ]

    var error = errors[0]
    var i
    if (!_.isNil(_.get(error, 'shipment'))) {
      for (i = 0; i < ErrorValuesShipment.length; i++) {
        if (
          ErrorValuesShipment[i] === 'factures' &&
          error.shipment.includes(ErrorValuesShipment[i])
        ) {
          return `facture-${error.factures[0]}-shipment`
        } else if (error.shipment.includes(ErrorValuesShipment[i]))
          return ErrorValuesShipment[i] + '-shipment'
      }
    }
    if (!_.isNil(_.get(error, 'assembly'))) {
      for (i = 0; i < ErrorValuesAssembly.length; i++) {
        if (error.assembly.includes(ErrorValuesAssembly[i]))
          return ErrorValuesAssembly[i] + '-assembly'
      }
    }
    return null
  }
  const handleInvalidActiveStep = () => {
    var b = {}
    const errors = orders.map((o) => {
      if (o.type === ORDER_TYPE.DELIVERY) {
        b.shipment = Object.keys(_.get(o, 'shipment.errors'))
        if (b.shipment.includes('factures')) {
          b.factures = _.get(o, 'shipment.errors.factures.required.index')
        }
        return b
      } else if (o.type === ORDER_TYPE.ASSEMBLY) {
        b.assembly = Object.keys(_.get(o, 'assembly.errors'))
        return b
      } else if (o.type === ORDER_TYPE.DELIVERY_ASSEMBLY) {
        if (!_.isEqual(_.get(o, 'shipment.errors'), {})) {
          b.shipment = Object.keys(_.get(o, 'shipment.errors'))
          if (b.shipment.includes('factures')) {
            b.factures = _.get(o, 'shipment.errors.factures.required.index')
          }
        }
        if (!_.isEqual(_.get(o, 'assembly.errors'), {}))
          b.assembly = Object.keys(_.get(o, 'assembly.errors'))
        return b
      }
    })
    const idFocus = errorFocus(errors)
    if (idFocus != null) {
      if (
        idFocus === 'date-assembly' ||
        idFocus === 'fileUploaded-assembly' ||
        idFocus === 'date-shipment' ||
        idFocus === 'articleFamilies-shipment'
      )
        window.location.hash = `#${idFocus}`
      document.getElementById(idFocus).focus()
    }
  }
  const isCommandValid = () => {
    const isStep1Valid =
      orders.filter(
        (o) =>
          (o.type === ORDER_TYPE.DELIVERY &&
            _.isEqual(_.get(o, 'shipment.errors'), {})) ||
          (o.type === ORDER_TYPE.ASSEMBLY &&
            _.isEqual(_.get(o, 'assembly.errors'), {})) ||
          (o.type === ORDER_TYPE.DELIVERY_ASSEMBLY &&
            _.isEqual(_.get(o, 'shipment.errors'), {}) &&
            _.isEqual(_.get(o, 'assembly.errors'), {}))
      ).length === orders.length
    const isStep2Valid = _.isEqual(_.get(client, 'errors'), {})
    const isStep3Valid = paymentForm === true

    //console.log(isStep1Valid, isStep2Valid, isStep3Valid)
    return !isStep1Valid || !isStep2Valid || !isStep3Valid || isLoading
  }

  const isStepValid = () => {
    if (activeStep === 0) {
      submitFirstStep()
      return (
        orders.filter(
          (o) =>
            (o.type === ORDER_TYPE.DELIVERY &&
              _.isEqual(_.get(o, 'shipment.errors'), {})) ||
            (o.type === ORDER_TYPE.ASSEMBLY &&
              _.isEqual(_.get(o, 'assembly.errors'), {})) ||
            (o.type === ORDER_TYPE.DELIVERY_ASSEMBLY &&
              _.isEqual(_.get(o, 'shipment.errors'), {}) &&
              _.isEqual(_.get(o, 'assembly.errors'), {}))
        ).length === orders.length
      )
    } else if (activeStep === 1) {
      submitSecondStep()
      console.log(client.errors)
      console.log('validation return', _.isEqual(_.get(client, 'errors'), {}))
      return _.isEqual(_.get(client, 'errors'), {})
    } else {
      return true
    }
  }
  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      if (isStepValid()) {
        window.scrollTo(0, 0)
        const next = activeStep + 1
        history.push(window.location.pathname + '#' + next)

        setActiveStep(next)
      } else {
        handleInvalidActiveStep()
      }
    }
  }
  const handleBackStep = () => {
    window.scrollTo(0, 0)
    if (activeStep > 0) {
      const before = activeStep - 1

      history.push(window.location.pathname + '#' + before)

      setActiveStep(before)
    }
  }
  /** To returns if orders have exceeded limit */
  // const LivraisonLimit = () => {
  //   const tabLivraison= orders.filter(a=>( a.type===ORDER_TYPE.DELIVERY ))
  //   return !(tabLivraison.length < 1 || tabLivraison.length === 0 || !tabLivraison)
  // }
  // const MontageLimit = () => {
  //   const tabmontage= orders.filter(a=> a.type===ORDER_TYPE.ASSEMBLY )
  //   return !(tabmontage.length < 1 || tabmontage.length === 0 || !tabmontage)
  // }
  return (
    <div className="recap-footer" style={{ height: 120 }}>
      {!isGetSalesChannelListLoading && (
        <>
          <div
            className="appbarFooter"
            style={{
              justifyContent: activeStep === 0 ? 'flex-end' : 'space-between',
            }}
          >
            {activeStep > 0 && (
              <StyledButton
                id="Retour"
                variant="contained"
                color="primary"
                onClick={() => handleBackStep()}
                sx={{
                  marginLeft: '20px !important',
                  float: 'left',
                  width: '100px',
                }}
              >
                Retour
              </StyledButton>
            )}
            <div className="right-buttons">
              <span className="recap-title">
                <p className="bold-style-text">
                  {' '}
                  Prix à payer : {replaceDotWithComma(totalPrice.toFixed(2))} €
                </p>
              </span>

              {activeStep < 2 ? (
                <StyledButton
                  id="Suivant"
                  variant="contained"
                  onClick={() => handleNextStep()}
                  sx={{
                    backgroundColor: 'white',
                    color: '#10A549',
                    border: '1px solid #10A549',
                    ':hover': {
                      backgroundColor: 'white',
                      border: '1px solid #158f44',
                      color: '#158f44',
                    },
                  }}
                >
                  Suivant
                </StyledButton>
              ) : (
                <>
                  {devis ? (
                    <StyledButton
                      variant="contained"
                      onClick={() => setCommand(true)}
                      disabled={isCommandValid()}
                      sx={{
                        backgroundColor: '#10A549',
                        ':hover': {
                          backgroundColor: '#158f44',
                        },
                      }}
                    >
                      Devis
                    </StyledButton>
                  ) : (
                    <StyledButton
                      id="commander"
                      variant="contained"
                      onClick={() => setCommand(true)}
                      disabled={isCommandValid()}
                      sx={{
                        backgroundColor: '#10A549',
                        ':hover': {
                          backgroundColor: '#158f44',
                        },
                      }}
                    >
                      Commander
                    </StyledButton>
                  )}
                </>
              )}
            </div>
          </div>{' '}
        </>
      )}
    </div>
  )
}
PlaceOrderFooter.propTypes = {
  initOrder: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  assemblySalesChannelList: PropTypes.array.isRequired,
  shipmentSalesChannelList: PropTypes.array.isRequired,
  orders: PropTypes.array.isRequired,
  addOrder: PropTypes.func.isRequired,
  submitFirstStep: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  submitSecondStep: PropTypes.func.isRequired,
  isGetSalesChannelListLoading: PropTypes.bool.isRequired,
  setCommand: PropTypes.func,
  paymentForm: PropTypes.bool,
  devis: PropTypes.bool,
  totalPrice: PropTypes.any,
  isLoading: PropTypes.bool,
}
export default PlaceOrderFooter
