import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../../../../ModalDialog'
import DialogContentText from '@mui/material/DialogContentText'
import IconRenommerFilter from '../../../../../assets/icons/navFilter/MaterialSymbolsDriveFileRenameOutlineOutlineSharp'

export default function ModificationFilter({
  open,
  handleClose,
  payload,
  onModifierFilter,
  isLoadingActionFilter,
  isActionModifierFilter,
  errorActionFilter,
  resetActionModifierFilter,
}) {
  const modifierFilter = () => {
    onModifierFilter(payload)
  }

  useEffect(() => {
    if (errorActionFilter) {
      handleClose()
    }
  }, [errorActionFilter])

  useEffect(() => {
    if (isActionModifierFilter) {
      handleClose()
      resetActionModifierFilter()
    }
  }, [isActionModifierFilter])

  return (
    <Dialog
      maxWidthDialog={'sm'}
      open={open}
      title={'Modifier Filter'}
      iconTitle={
        <IconRenommerFilter
          style={{
            width: '20px',
            height: '20px',
            marginRight: '10px',
          }}
        />
      }
      content={
        <DialogContentText sx={{ pr: 2, pb: 2 }}>
          <div style={{ width: '400px', paddingLeft: '9px' }}>
            {'Êtes-vous sûr de vouloir modifier le filtre ?'}
          </div>
        </DialogContentText>
      }
      handleClose={handleClose}
      handleClickAction={modifierFilter}
      isLoadingButtonAction={isLoadingActionFilter}
    />
  )
}
ModificationFilter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  payload: PropTypes.object,
  onModifierFilter: PropTypes.func,
  isLoadingActionFilter: PropTypes.bool,
  isActionModifierFilter: PropTypes.bool,
  errorActionFilter: PropTypes.string,
  resetActionModifierFilter: PropTypes.func,
}
