import {
  ADD_KEYS,
  ASSIGN_KEYS_CHANNEL,
  ASSIGN_KEYS_DRIVER,
  GET_ASSIGNED_CHANNEL,
  GET_ASSIGNED_DRIVER,
  GET_KEYS,
  ON_SEARCH,
  PAGE_CHANGED,
  PAGE_LIMIT_CHANGED,
  RESET_CONFIG,
  SEARCH_KEYS,
  UNASSIGN_KEYS_CHANNEL,
  UNASSIGN_KEYS_DRIVER,
  UPDATE_KEY,
  REMOVE_ERROR,
  RESET_SUCCESS_ACTION,
  GET_KEYS_LIST_MORE,
  SEARCH_KEYS_COMPETENCE,
  SEARCH_KEYS_RESSOURCES,
} from './constants'

export const fetchKeys = (filter) => ({
  type: GET_KEYS,
  payload: filter,
})
export const fetchAssignedSalesChannel = (filter) => ({
  type: GET_ASSIGNED_CHANNEL,
  payload: filter,
})
export const fetchAssignedDriver = (filter) => ({
  type: GET_ASSIGNED_DRIVER,
  payload: filter,
})
export const CreatNewKey = (keys) => ({
  type: ADD_KEYS,
  payload: { keys },
})

export const resetConfig = () => ({
  type: RESET_CONFIG,
})

export const UpdateKey = (payload) => ({
  type: UPDATE_KEY,
  payload,
})

export const pageChanged = (offset) => ({
  type: PAGE_CHANGED,
  payload: { offset },
})

export const pageLimitChanged = (limit) => ({
  type: PAGE_LIMIT_CHANGED,
  payload: { limit },
})
export const getKeysMore = (data) => ({
  type: GET_KEYS_LIST_MORE,
  payload: data,
})

export const onSearchKeys = (search) => ({
  type: SEARCH_KEYS,
  payload: { search },
})

export const assignToDriver = (data) => ({
  type: ASSIGN_KEYS_DRIVER,
  payload: data,
})

export const unassignToDriver = (data) => ({
  type: UNASSIGN_KEYS_DRIVER,
  payload: data,
})

export const assignToChannel = (data) => ({
  type: ASSIGN_KEYS_CHANNEL,
  payload: data,
})

export const unassignToChannel = (data) => ({
  type: UNASSIGN_KEYS_CHANNEL,
  payload: data,
})

export const onSearch = (search) => ({
  type: ON_SEARCH,
  payload: { search },
})

export const removeErrorConfig = () => ({
  type: REMOVE_ERROR,
})

export const resetSuccessAction = () => ({
  type: RESET_SUCCESS_ACTION,
})

export const onSearchCleCompetence = (search) => ({
  type: SEARCH_KEYS_COMPETENCE,
  payload: { search },
})

export const onSearchCleRessources = (search) => ({
  type: SEARCH_KEYS_RESSOURCES,
  payload: { search },
})
