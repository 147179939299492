import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  ACCEPTEE_CHARGEMENT_DECHARGEMENT_STATUS,
  programStatusList,
  STATUS_LABEL_LIST,
  defaultUncheckedStatus,
} from '../../../../../../utils/values'
import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import IconStatus from '../../../../../../assets/icons/navFilter/StatusIcon'

export default function StatusFilter({
  disabled,
  filterStateChanged,
  statusFiltersValues,
}) {
  const [checked, setChecked] = useState([])
  const [isProgramPage, setIsProgramPage] = useState(
    window.location.pathname.includes('programmer')
  )

  useEffect(() => {
    let location = window.location.pathname.includes('programmer')
    setIsProgramPage(location)
    const list = location ? programStatusList : STATUS_LABEL_LIST
    const filteredList = list.map((elem) => {
      let codesList = []
      if (elem.label === ACCEPTEE_CHARGEMENT_DECHARGEMENT_STATUS.label) {
        elem.codes.forEach((elem) => codesList.push(elem))
      }
      return {
        code: elem.code,
        name: elem.label,
      }
    })
    setChecked(filteredList)
  }, [])

  const onCodeStatusChanged = (selectedStatus) => {
    const status = selectedStatus
      ? selectedStatus
      : !isProgramPage
      ? STATUS_LABEL_LIST.filter((c) => !defaultUncheckedStatus.includes(c.code))
          .map((e) => e.code)
          .flat(1)
      : programStatusList.map((el) => el.code)
    filterStateChanged('status', status)
  }

  return (
    <SelectListWithSearch
      disabled={disabled}
      defaultLabel={'Statuts'}
      dataList={checked}
      onDataChanged={onCodeStatusChanged}
      filtersValues={statusFiltersValues}
      Icon={IconStatus}
      id={'Status'}
    />
  )
}
StatusFilter.propTypes = {
  disabled: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  statusFiltersValues: PropTypes.array,
}
