import { connect } from 'react-redux'

import GestionFormations from '../../../pages/settings/formations'
import {
  removeErrorConfig,
  resetSuccessAction,
} from '../../../redux/configuration/actions'
import { getErrorMessageConfig } from '../../../redux/configuration/selectors'
import {
  fetchFilesById,
  fetchFormationById,
  fetchFormations,
  onSearchFormation,
  pageChangedFormation,
  pageLimitChanged,
  resetConfigFormation,
  removeError,
} from '../../../redux/formations/actions'

import {
  getFormations,
  getFormationsCount,
  getFormationsListIsLoading,
  getErrorMessage,
  getIsErrorFetch,
  getIsSuccessRefresh,
  getSuccess,
} from '../../../redux/formations/selectors'

const mapStateToProps = (state) => ({
  isLoadingFormationsList: getFormationsListIsLoading(state),
  formations: getFormations(state),
  formationsCount: getFormationsCount(state),
  errorMessage: getErrorMessage(state),
  isErrorFetch: getIsErrorFetch(state),
  isSuccessRefresh: getIsSuccessRefresh(state),
  success: getSuccess(state),
  errorMessageConfig: getErrorMessageConfig(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchFormations()),
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(fetchFormations())
  },
  pageChanged: (offset) => {
    dispatch(pageChangedFormation(offset))
    dispatch(fetchFormations())
  },
  removeError: () => dispatch(removeError()),
  resetSuccessAction: () => dispatch(resetSuccessAction()),
  onCloseReset: () => dispatch(resetConfigFormation()),

  searchData: (search) => {
    dispatch(onSearchFormation(search))
    dispatch(fetchFormations())
  },
  fetchFiles: (id) => {
    dispatch(fetchFilesById(id, 'formation'))
  },
  fetchDataById: (id) => {
    dispatch(fetchFormationById(id))
  },
  removeErrorConfig: () => dispatch(removeErrorConfig()),
})

export default connect(mapStateToProps, mapDisptachToProps)(GestionFormations)
