import { connect } from 'react-redux'
import Activity from '../../../pages/main/Orders/DetailsDelivery/components/details/Activity'
import {
  getIsLoadingActivity,
  getOrderActivity,
} from '../../../redux/order/selectors'
import { getActitvity } from '../../../redux/order/actions'

const mapStateToProps = (state) => ({
  orderActivity: getOrderActivity(state),
  isLoading: getIsLoadingActivity(state),
})
const mapDisptachToProps = (dispatch) => ({
  getActitvity: (entitiyId, type, category) =>
    dispatch(getActitvity(entitiyId, type, category)),
})
export default connect(mapStateToProps, mapDisptachToProps)(Activity)
