//TODO LINT
/* eslint-disable */
import moment from 'moment'
import _ from 'lodash'
import { ORDER_TYPE } from '../constants/appContants'
import { parseFloatWithDot } from './floatFormatting'
import { PROGRAMMED_STATUS } from './values'

const getInfoOrder = (
  orders,
  client,
  codePromotion,
  paymentForm,
  newClient,
  selectedClient
) => {
  const infosCourse = orders
    .filter((e) => e.type !== ORDER_TYPE.ASSEMBLY)
    .map((order) => {
      let infosAssembly
      if (order.type === ORDER_TYPE.DELIVERY_ASSEMBLY) {
        infosAssembly = getInfoAssembly(order, client)
        console.log('assembly', infosAssembly)
      }
      const info = getInfoCourse(order, client)[0]
      return {
        ...info,
        infosAssembly: infosAssembly,
      }
    })

  const infosAssembly = orders
    .filter((e) => e.type === ORDER_TYPE.ASSEMBLY)
    .map((order) => {
      const infosAssembly = getInfoAssembly(order, client)
      return {
        ...infosAssembly,
      }
    })
  const orderDetails = {
    codeCanal:
      orders[0].shipment?.selectedSalesChannel?.code ||
      orders[0].assembly?.selectedSalesChannel?.code,
    codePromotion: codePromotion,
    moyenPaiement: paymentForm,
    client: {
      id: newClient ? client.id : selectedClient.id,
      firstName: client.firstname,
      lastName: client.lastname,
      vip: client.vip || false,
      phone: client.phone,
      email: client.mail.replace(/\s/g, ''),
    },
    infosCourse: infosCourse,
    infosAssembly: infosAssembly,
  }
  return orderDetails
}
const filterOptions = (options) => {
  try {
    return options.filter((elem) => elem.quantity > 0)
  } catch (e) {
    return []
  }
}
const getInfoAssembly = (order, selectedClient) => {
  const start = _.get(order.assembly.time, 'start')
  const dateDemarrage = start[0] + start[1]
  return {
    nonEnvoiMail: !selectedClient.envoiMail,
    purchaseAmount: parseInt(parseFloatWithDot(order.assembly?.purchaseAmount)),
    category: order.assembly?.category,
    location: order.assembly?.selectedAddress?.formattedAddress,
    clientPrice: parseFloatWithDot(order.assembly?.clientPrice),
    observation: order.assembly?.observation || '',
    options: filterOptions(order.assembly?.options),
    start_at: moment
      .tz(
        moment(new Date(order.assembly.date)).format('YYYY-MM-DD ') + dateDemarrage,
        'Europe/Paris'
      )
      .format(),
    assembly_source: order.assembly?.assembly_source,
  }
}
const getInfoCourse = (order, selectedClient) => {
  const dateDemarrage = _.get(order.shipment.time, 'start')
  const observationDepart = order.shipment.originObservation
  const observationArrivee = order.shipment.observation
  const envoiMailArrivee = !order.shipment.clientInfos
    ? !order.shipment.envoiMailArrivee
    : undefined

  const contactDepart = {
    id: order.shipment.originId,
    firstName: order.shipment.originFirstname,
    lastName: order.shipment.originLastname,
    phone: order.shipment.originPhone,
    mail: order.shipment.originMail,
    observation: observationDepart,
  }
  const contactArrivee = {
    id: order.shipment.destinationId,
    firstName: order.shipment.destinationFirstname,
    lastName: order.shipment.destinationLastname,
    phone: order.shipment.destinationPhone,
    mail: order.shipment.destinationMail,
    observation: observationArrivee,
  }
  let prestation = null
  if (order?.shipment?.prestation) {
    prestation = order.shipment.prestation
  } else if (order?.shipment?.selectedSalesChannel?.configs?.defaultServiceId) {
    prestation = order?.shipment?.selectedSalesChannel?.configs?.defaultServiceId?.id
      ? order.shipment.selectedSalesChannel.configs.defaultServiceId.id
      : null
  }
  let selectPresCanalService = null
  if (order?.shipment?.selectPresCanalService) {
    selectPresCanalService = order.shipment.selectPresCanalService
  }

  return [
    {
      adresseArrivee: order.shipment.selectedDestination.formattedAddress,
      adresseDepart: order.shipment.selectedOrigin.formattedAddress,
      articleFamilies: order?.shipment?.articleFamilies
        ? order.shipment.articleFamilies.filter((e) => e.checked === true)
        : [],
      avecAscenseurAdresseArrivee: order.shipment.destinationIsElevator,
      avecAscenseurAdresseDepart: order.shipment.originIsElevator,
      codePaysAdresseArrivee: order.shipment.selectedDestination.country.code,
      codePaysAdresseDepart: order.shipment.selectedOrigin.country?.code,
      dateDemarrage: moment
        .tz(
          moment(new Date(order.shipment.date)).format('YYYY-MM-DD ') +
            dateDemarrage,
          'Europe/Paris'
        )
        .format(),
      dateDemarrageMeta: order.shipment.time.dateDemarrageMeta,
      dureeCourse: order.shipment.direction.selectedDirection.duration,
      estimatedKM: order.shipment.direction.selectedDirection.distance,
      etageAdresseArrivee: order.shipment.destinationLevel,
      etageAdresseDepart: order.shipment.originLevel,
      factures: order.shipment.factures ? order.shipment.factures : '',
      idAdresseDepart: order.shipment.selectedOrigin.id,
      latitudeAdresseArrivee: order.shipment.selectedDestination.latlng.lat,
      latitudeAdresseDepart: order.shipment.selectedOrigin.latlng.lat,
      longitudeAdresseArrivee: order.shipment.selectedDestination.latlng.lng,
      longitudeAdresseDepart: order.shipment.selectedOrigin.latlng.lng,
      manutention: order.shipment.manutention,
      manutentionDouble: order.shipment.manutentionDouble,
      montantCB: null,
      montantEspece: null,
      //Add HT conversion
      montantHT: (parseFloatWithDot(order.shipment.clientPrice) / 1.2).toFixed(2),
      montantPrestataireHT: (
        parseFloatWithDot(order.shipment.providerPrice) / 1.2
      ).toFixed(2),
      nbColis: parseFloatWithDot(order.shipment.nbItems),
      nonEnvoiMail: !selectedClient.envoiMail,
      noteInterne: order.shipment.noteInterne,
      /*observation: selectedClient.observation,*/
      postalCodeAdresseArrivee: order.shipment.selectedDestination.postalCode,
      postalCodeAdresseDepart: order.shipment.selectedOrigin.postalCode,
      purchaseAmount: parseFloatWithDot(order.shipment.purchaseAmount),
      type: selectedClient.type?.toLowerCase(),
      billingAddress: selectedClient.selectedAddress?.formattedAddress,
      billingCity: selectedClient.selectedAddress?.city,
      billingZip: selectedClient.selectedAddress?.postalCode,
      companyName: selectedClient.name,
      typeCourse: PROGRAMMED_STATUS.code,
      vehiculeType: order.shipment.vehiculeType || 'M',
      weight: parseFloatWithDot(order.shipment.weight),
      dgCodeAdresseArrivee: order.shipment.number,
      contactArrivee: contactArrivee,
      contactDepart: contactDepart,
      options: filterOptions(order.shipment?.options),
      prestation,
      selectPresCanalService,
      courseType: order.shipment.courseType,
      volume: parseFloatWithDot(order.shipment.volume),
      isClientInfo: order.shipment.clientInfos,
      envoiMailArrivee: envoiMailArrivee,
      orderBalance: parseFloatWithDot(order.shipment.orderBalance),
      cityAdresseArrivee: order.shipment.selectedDestination.city,
      cityAdresseDepart: order.shipment.selectedOrigin.city,
      plVlAdresseArrivee: order.shipment.plVlAdresseArrivee,
      plVlAdresseDepart: order.shipment.plVlAdresseDepart,
      nbrMarcheAvantAscenseurAdresseArrivee:
        order.shipment.nbrMarcheAvantAscenseurAdresseArrivee,
      nbrMarcheAvantAscenseurAdresseDepart:
        order.shipment.nbrMarcheAvantAscenseurAdresseDepart,
    },
  ]
}

export { getInfoCourse, getInfoAssembly, getInfoOrder }
