import { connect } from 'react-redux'
import RDVImpossible from '../../../components/ShipmentAction/RDVimpossibe'
import {
  getReasonsOrder,
  getIsLoadingReasonsOrder,
  getIsLoadingActionOrder,
  getErrorActionCourse,
  getCanalConfig,
  getIsLoadingGetConfig,
} from '../../../redux/order/selectors'

import {
  OnRDVImpossible,
  getOrderReasons,
  getConfigCanal,
  resetConfigCanal,
} from '../../../redux/order/actions'
const mapStateToProps = (state) => ({
  motifsRDVImpo: getReasonsOrder(state),
  isLoadingMotifsRDVImpo: getIsLoadingReasonsOrder(state),
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  errorActionCourse: getErrorActionCourse(state),
  configCanal: getCanalConfig(state),
  isLoadingGetConfig: getIsLoadingGetConfig(state),
})

const mapDisptachToProps = (dispatch) => ({
  rdvImpossible: (payload) => {
    dispatch(OnRDVImpossible(payload))
  },
  getRDVImpoMOtifs: (payload) => dispatch(getOrderReasons(payload)),
  getConfigCanal: (codeCanal, metaKey) =>
    dispatch(getConfigCanal(codeCanal, metaKey)),
  resetConfigCanal: () => dispatch(resetConfigCanal()),
})

export default connect(mapStateToProps, mapDisptachToProps)(RDVImpossible)
