import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import PropTypes from 'prop-types'
import PrimaryOutlineNoBorderButton from '../../Buttons/PrimaryOutlineNoBorderButton'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function HttpError({ statusText, open }) {
  const onRefresh = () => {
    window.location.reload()
  }

  return (
    <div className="error-container">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{'Alert'}</DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto', fontSize: 18, width: 350 }}>
            {statusText}
          </div>
        </DialogContent>
        <DialogActions>
          <PrimaryOutlineNoBorderButton onClick={onRefresh} label="Actualiser" />
        </DialogActions>
      </Dialog>
    </div>
  )
}
HttpError.propTypes = {
  statusText: PropTypes.string,
  open: PropTypes.bool,
}
