import initialState from './initialState'
import { getValue } from '../../utils'
import saveExpirationDate from '../../utils/saveExpirationDate'
import {
  DEPARTEMENT_EXPIRATION_DATE,
  DRIVERS_FILTER_EXPIRATION_DATE,
  MACRO_CANAL_EXPIRATION_DATE,
  SALES_CHANNEL_EXPIRATION_DATE,
  SERVICE_EXPIRATION_DATE,
  SUBJECT_EXPIRATION_DATE,
  WAREHOUSE_EXPIRATION_DATE,
} from '../../utils/checkExpirationDate'
import {
  SET_FILTER_COMMANDE,
  SET_FILTER_TABLEAUBORD,
  SET_FILTER_PLANIFICATION,
  SET_FILTER_ENTREPOT,
  SET_FILTER_ERROR,
  REMOVE_FILTER_ERROR,
  GET_BUSINESS_OWNER,
  GET_BUSINESS_OWNER_SUCCESS,
  GET_BUSINESS_OWNER_FAILURE,
  RESET_FILTER_COMMANDE,
  RESET_FILTER_TABLEAUBORD,
  RESET_FILTER_PLANIFICATION,
  RESET_FILTER_ENTREPOT,
  SAVE_SEARCH_CORNER_ONGLET,
  SET_FILTER_FREIGHTS,
  RESET_FILTER_FREIGHTS,
  RESET_ALL_FILTER,
  RESET_FILTER_TOUR,
  SET_FILTER_TOUR,
  RESET_FILTER_DISCUSSION,
  SET_FILTER_DISCUSSION,
  GET_ALL_SUBJECT,
  GET_ALL_SUBJECT_SUCCESS,
  GET_ALL_SUBJECT_FAILURE,
  FILTER_CHANGED,
  PAGE_CHANGED,
  PAGE_LIMIT_CHANGED,
  IS_OPEN_CHANGED,
  SEARCH_DISCUSSION,
  SET_SELECTED_DATE,
  FETCH_DRIVERS_FILTER_SUCCESS,
  FETCH_DRIVERS_FILTER,
  FETCH_DRIVERS_FILTER_FAILURE,
  DRIVERS_FILTER,
  GET_PRESTATIONS,
  GET_PRESTATIONS_SUCCESS,
  GET_PRESTATIONS_FAILURE,
  GET_DEPARTEMENTS,
  GET_DEPARTEMENTS_SUCCESS,
  GET_DEPARTEMENTS_FAILURE,
  GET_MACRO_CANAL,
  GET_MACRO_CANAL_SUCCESS,
  GET_MACRO_CANAL_FAILURE,
  GET_SALES_CHANNEL_FAILURE,
  GET_SALES_CHANNEL_SUCCESS,
  GET_SALES_CHANNEL,
  GET_TOUR_LIST,
  GET_TOUR_LIST_MORE,
  GET_TOUR_LIST_SUCCESS,
  GET_TOUR_LIST_MORE_SUCCESS,
  GET_TOUR_LIST_FAILURE,
  GET_TOUR_LIST_MORE_FAILURE,
  GET_WAREHOUSE,
  GET_WAREHOUSE_SUCCESS,
  GET_WAREHOUSE_FAILURE,
  GET_FILTER_PARAMS,
  RESET_FROMAPPLIQUER,
  SET_DEFAULT_FILTER_TABLEAUBORD,
  SET_DEFAULT_FILTER_COMMANDE,
  SET_DEFAULT_FILTER_PLANIFICATION,
  SET_DEFAULT_FILTER_ENTREPOT,
  GET_FILTER_COLLABORATEUR,
  GET_FILTER_COLLABORATEUR_SUCCESS,
  GET_FILTER_COLLABORATEUR_FAILURE,
  SAVE_DEFAULT_FILTER_COMMANDE,
  RESET_DEFAULT_FILTER_COMMANDE,
  DISPLAY_MODE_CHANGED,
  CREATE_FILTER_COLLABORATEUR,
  CREATE_FILTER_COLLABORATEUR_SUCCESS,
  CREATE_FILTER_COLLABORATEUR_FAILURE,
  RESET_ACTIONS_CREATION_FILTER,
  DELETE_FILTER_COLLABORATEUR,
  DELETE_FILTER_COLLABORATEUR_SUCCESS,
  DELETE_FILTER_COLLABORATEUR_FAILURE,
  RESET_ACTION_DELETE_FILTER,
  RENOMMER_FILTER_COLLABORATEUR,
  RENOMMER_FILTER_COLLABORATEUR_SUCCESS,
  RENOMMER_FILTER_COLLABORATEUR_FAILURE,
  RESET_ACTION_FILTER,
  SET_ONGLET_FILTER_OPEN,
  MODIFICATION_FILTER_COLLABORATEUR,
  MODIFICATION_FILTER_COLLABORATEUR_SUCCESS,
  MODIFICATION_FILTER_COLLABORATEUR_FAILURE,
  RESET_ACTIONS_MODIFICATION_FILTER,
  SAVE_SEARCH_ONGLET,
  SAVE_SEARCH_CORNER,
  RESET_FILTER_COLLABORATEUR,
} from './actionsConsts'

import {
  FilterCommande,
  FilterTableauBord,
  FilterPlanification,
  FilterEntrepot,
  FilterFreights,
  FilterTour,
  FilterDiscussion,
} from './defaultFilter'
import { driversListFilter } from './selectors'
import onFailure from '../OnFailure'
export default (state = initialState(), action) => {
  if (action.type === FILTER_CHANGED) {
    const { filterValues } = action.payload

    return {
      ...state,
      filter: filterValues,
      selectedCourses: [],
    }
  }

  if (action.type === PAGE_CHANGED) {
    const { offset } = action.payload
    const filter = { ...state.filter }
    filter.offset = offset
    return {
      ...state,
      filter,
    }
  }

  if (action.type === PAGE_LIMIT_CHANGED) {
    const { limit } = action.payload
    const filter = { ...state.filter }
    filter.limit = limit
    filter.offset = 0
    return {
      ...state,
      filter,
    }
  }

  if (action.type === IS_OPEN_CHANGED) {
    const { isOpen } = action.payload
    const filter = { ...state.filter }
    filter.offset = 0
    return {
      ...state,
      filter,
      isOpen,
    }
  }

  if (action.type === SEARCH_DISCUSSION) {
    const { ldvSearch } = action.payload
    const filter = { ...state.filter }
    filter.offset = 0
    return {
      ...state,
      filter,
      ldvs: ldvSearch,
    }
  }

  if (action.type === SET_SELECTED_DATE) {
    const { pickedDate } = action.payload
    state.programmerPickedDate = pickedDate

    return {
      ...state,
    }
  }

  if (action.type === SET_FILTER_COMMANDE) {
    const { filter, fromAppliquer } = action.payload
    return {
      ...state,
      commande: filter,
      fromAppliquer,
    }
  }

  if (action.type === SET_FILTER_TABLEAUBORD) {
    const { filter, fromAppliquer } = action.payload
    return {
      ...state,
      tableauBord: filter,
      fromAppliquer,
    }
  }

  if (action.type === SET_FILTER_PLANIFICATION) {
    const { filter, fromAppliquer } = action.payload
    return {
      ...state,
      planification: filter,
      fromAppliquer,
    }
  }

  if (action.type === SET_FILTER_ENTREPOT) {
    const { filter, fromAppliquer } = action.payload
    return {
      ...state,
      entrepot: filter,
      fromAppliquer,
    }
  }
  if (action.type === SET_FILTER_DISCUSSION) {
    const { filter } = action.payload
    return {
      ...state,
      discussion: filter,
    }
  }

  if (action.type === SET_FILTER_TOUR) {
    const { filter, fromAppliquer } = action.payload
    return {
      ...state,
      tours: filter,
      fromAppliquer,
    }
  }

  if (action.type === SET_FILTER_ERROR) {
    return {
      ...state,
      error:
        'Le nombre de sélection dans le filtre est trop élevé, veuillez réduire votre sélection.',
    }
  }
  if (action.type === REMOVE_FILTER_ERROR) {
    return {
      ...state,
      error: null,
    }
  }

  if (action.type === GET_BUSINESS_OWNER) {
    return {
      ...state,
      isLoadingBusinessOwner: true,
      businessOwnerList: [],
    }
  }

  if (action.type === GET_BUSINESS_OWNER_SUCCESS) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingBusinessOwner: false,
      businessOwnerList: response,
    }
  }

  if (action.type === GET_BUSINESS_OWNER_FAILURE) {
    return {
      ...state,
      isLoadingBusinessOwner: false,
    }
  }

  if (action.type === SAVE_SEARCH_CORNER_ONGLET) {
    const { search } = action.payload
    return {
      ...state,
      searchCornerOnglet: search,
    }
  }

  // SAVE SEARCH ONGLET (Collaborateur)
  if (action.type === SAVE_SEARCH_ONGLET) {
    const { search, posCollabFilter } = action.payload

    const newFiltersCollaborateur = state.filtersCollaborateur
    if (posCollabFilter >= 0) {
      newFiltersCollaborateur[posCollabFilter].search = search
      return {
        ...state,
        filtersCollaborateur: [...newFiltersCollaborateur],
      }
    }
  }

  if (action.type === RESET_FILTER_COMMANDE) {
    return {
      ...state,
      commande: state.defaultFilterCommande,
    }
  }
  if (action.type === RESET_FILTER_TABLEAUBORD) {
    return {
      ...state,
      tableauBord: FilterTableauBord(),
    }
  }
  if (action.type === RESET_FILTER_PLANIFICATION) {
    return {
      ...state,
      planification: FilterPlanification(),
    }
  }
  if (action.type === RESET_FILTER_ENTREPOT) {
    return {
      ...state,
      entrepot: FilterEntrepot(),
    }
  }

  if (action.type === RESET_FILTER_DISCUSSION) {
    return {
      ...state,
      discussion: FilterDiscussion(),
    }
  }

  if (action.type === RESET_FILTER_TOUR) {
    return {
      ...state,
      tours: FilterTour(),
    }
  }

  if (action.type === SET_FILTER_FREIGHTS) {
    const { filter, fromAppliquer } = action.payload
    return {
      ...state,
      freights: filter,
      fromAppliquer,
    }
  }

  if (action.type === RESET_FILTER_FREIGHTS) {
    return {
      ...state,
      freights: FilterFreights(),
    }
  }

  if (action.type === RESET_ALL_FILTER) {
    return {
      ...state,
      searchCornerOnglet: null,
      searchCorner: null,
      commande: FilterCommande(),
      tableauBord: FilterTableauBord(),
      planification: FilterPlanification(),
      entrepot: FilterEntrepot(),
      freights: FilterFreights(),
      tours: FilterTour(),
      discussion: FilterDiscussion(),
    }
  }

  if (action.type === GET_ALL_SUBJECT) {
    const salesChannelsList = getValue('subjects', [])
    if (Object.entries(salesChannelsList).length === 0) {
      return {
        ...state,
        isLoadingSubjects: true,
      }
    }
  }

  if (action.type === GET_ALL_SUBJECT_SUCCESS) {
    const { response } = action.payload.data
    try {
      localStorage.setItem('subjects', JSON.stringify(response))
      saveExpirationDate(SUBJECT_EXPIRATION_DATE, 12)
    } catch (e) {
      console.log('LocalStorage', e)
    }
    return {
      ...state,
      subjects: response,
      isLoadingSubjects: false,
    }
  }

  if (action.type === GET_ALL_SUBJECT_FAILURE) {
    return {
      ...state,
      isLoadingSubjects: false,
    }
  }
  if (action.type === FETCH_DRIVERS_FILTER) {
    const retrievedDrivers = getValue(DRIVERS_FILTER, [])
    if (Object.entries(retrievedDrivers).length === 0) {
      return {
        ...state,
        isLoadingDriverFilter: true,
      }
    }
  }

  if (action.type === FETCH_DRIVERS_FILTER_SUCCESS) {
    const { response } = action.payload.data
    let filteredDrivers = response.length > 0 ? driversListFilter(response) : []

    try {
      localStorage.setItem(DRIVERS_FILTER, JSON.stringify(filteredDrivers))
      saveExpirationDate(DRIVERS_FILTER_EXPIRATION_DATE, 12)
    } catch (e) {
      console.log('LocalStorage', e)
    }

    return {
      ...state,
      driverfilter: filteredDrivers,
      isLoadingDriverFilter: false,
    }
  }

  if (action.type === FETCH_DRIVERS_FILTER_FAILURE) {
    return {
      ...state,
      isLoadingDriverFilter: false,
    }
  }

  if (action.type === GET_PRESTATIONS) {
    const salesChannelsList = getValue('services', [])
    if (Object.entries(salesChannelsList).length === 0) {
      return {
        ...state,
        isGetServicesListLoading: true,
      }
    }
  }

  if (action.type === GET_PRESTATIONS_SUCCESS) {
    const services = action.payload.data.prestations

    try {
      localStorage.setItem('services', JSON.stringify(services))
      saveExpirationDate(SERVICE_EXPIRATION_DATE, 12)
    } catch (e) {
      console.log('LocalStorage', e)
    }
    return {
      ...state,
      services,
      isGetServicesListLoading: false,
    }
  }

  if (action.type === GET_PRESTATIONS_FAILURE) {
    return {
      ...state,
      isGetServicesListLoading: false,
    }
  }

  if (action.type === GET_DEPARTEMENTS) {
    const departments = getValue('departments', [])
    if (Object.entries(departments).length === 0) {
      return {
        ...state,
        isGetDepartementsLoading: true,
      }
    }
  }

  if (action.type === GET_DEPARTEMENTS_SUCCESS) {
    const departments = action.payload.data.departements

    try {
      localStorage.setItem('departments', JSON.stringify(departments))
      saveExpirationDate(DEPARTEMENT_EXPIRATION_DATE, 12)
    } catch (e) {
      console.log('LocalStorage', e)
    }
    return {
      ...state,
      departments,
      isGetDepartementsLoading: false,
    }
  }

  if (action.type === GET_DEPARTEMENTS_FAILURE) {
    return {
      ...state,
      isGetDepartementsLoading: false,
    }
  }

  if (action.type === GET_MACRO_CANAL) {
    const macroCanal = getValue('macroCanal', [])
    if (Object.entries(macroCanal).length === 0) {
      return {
        ...state,
        isGetMacroCanalLoading: true,
      }
    }
  }

  if (action.type === GET_MACRO_CANAL_SUCCESS) {
    const macroCanal = action.payload.data.macroCanals
    try {
      localStorage.setItem('macroCanal', JSON.stringify(macroCanal))
      saveExpirationDate(MACRO_CANAL_EXPIRATION_DATE, 12)
    } catch (e) {
      console.log('LocalStorage', e)
    }

    return {
      ...state,
      macroCanal,
      isGetMacroCanalLoading: false,
    }
  }

  if (action.type === GET_MACRO_CANAL_FAILURE) {
    return {
      ...state,
      isGetMacroCanalLoading: false,
    }
  }

  if (action.type === GET_SALES_CHANNEL) {
    const salesChannelsList = getValue('salesChannels', [])
    if (Object.entries(salesChannelsList).length === 0) {
      return {
        ...state,
        isGetSalesChannelListLoading: true,
        errorGetSalesChannel: false,
      }
    }
  }

  if (action.type === GET_SALES_CHANNEL_SUCCESS) {
    const salesChannelResponse = action.payload.data.response
    const salesChannelListGroup = salesChannelResponse.reduce(
      (a, v) => ({
        ...a,
        [v.canalPrincipal.code]: {
          radioGroupe: v.canalPrincipal,
          radios: v.sousCanaux,
          countRadios: v.sousCanaux.length,
          index: 0,
        },
      }),
      {}
    )
    const salesChannelList = []
    salesChannelResponse.map((data) => salesChannelList.push(...data.sousCanaux))

    try {
      localStorage.setItem('salesChannels', JSON.stringify(salesChannelList))
      localStorage.setItem(
        'salesChannelsGroupe',
        JSON.stringify(salesChannelListGroup)
      )
      saveExpirationDate(SALES_CHANNEL_EXPIRATION_DATE, 12)
    } catch (e) {
      console.log('LocalStorage', e)
    }
    return {
      ...state,
      salesChannelList,
      salesChannelListGroup,
      isGetSalesChannelListLoading: false,
      errorGetSalesChannel: false,
    }
  }

  if (action.type === GET_SALES_CHANNEL_FAILURE) {
    return {
      ...state,
      isGetSalesChannelListLoading: false,
      errorGetSalesChannel: true,
    }
  }
  if (action.type === GET_TOUR_LIST) {
    return {
      ...state,
      isGetTourListLoading: true,
    }
  }
  if (action.type === GET_TOUR_LIST_MORE) {
    return {
      ...state,
      isGetTourListMoreLoading: true,
    }
  }
  if (action.type === GET_TOUR_LIST_SUCCESS) {
    const { tour, count } = action.payload.data
    return {
      ...state,
      tourList: tour,
      countListTour: count,
      isGetTourListLoading: false,
    }
  }

  if (action.type === GET_TOUR_LIST_MORE_SUCCESS) {
    const { tour, count } = action.payload.data
    return {
      ...state,
      tourList: [...state.tourList, ...tour],
      countListTour: count,
      isGetTourListMoreLoading: false,
    }
  }
  if (
    action.type === GET_TOUR_LIST_FAILURE ||
    action.type === GET_TOUR_LIST_MORE_FAILURE
  ) {
    return {
      ...state,
      isGetTourListLoading: false,
      isGetTourListMoreLoading: false,
    }
  }
  if (action.type === GET_WAREHOUSE) {
    const wareHouseList = getValue('wareHouses', [])
    if (Object.entries(wareHouseList).length === 0) {
      return {
        ...state,
        isGetWareHouseListLoading: true,
      }
    }
  }

  if (action.type === GET_WAREHOUSE_SUCCESS) {
    const wareHouseList = action.payload.data.warehouses

    try {
      localStorage.setItem('wareHouses', JSON.stringify(wareHouseList))
      saveExpirationDate(WAREHOUSE_EXPIRATION_DATE, 12)
    } catch (e) {
      console.log('LocalStorage', e)
    }
    return {
      ...state,
      wareHouseList,
      isGetWareHouseListLoading: false,
    }
  }

  if (action.type === GET_WAREHOUSE_FAILURE) {
    return {
      ...state,
      isGetWareHouseListLoading: false,
    }
  }

  if (action.type === GET_FILTER_PARAMS) {
    const { count, limit, offset } = action.payload
    const filter = { ...state.filter }
    filter.limit = limit
    filter.offset = offset
    return {
      ...state,
      filter,
      count: parseInt(count),
    }
  }

  if (action.type === RESET_FROMAPPLIQUER) {
    return {
      ...state,
      fromAppliquer: false,
    }
  }

  if (action.type === SET_DEFAULT_FILTER_TABLEAUBORD) {
    const { filter } = action.payload
    return {
      ...state,
      defaultFilterTableauBord: filter,
    }
  }

  if (action.type === SET_DEFAULT_FILTER_COMMANDE) {
    const { filter } = action.payload
    return {
      ...state,
      defaultFilterCommande: filter,
    }
  }

  if (action.type === SET_DEFAULT_FILTER_PLANIFICATION) {
    const { filter } = action.payload
    return {
      ...state,
      defaultFilterPlanification: filter,
    }
  }

  if (action.type === SET_DEFAULT_FILTER_ENTREPOT) {
    const { filter } = action.payload
    return {
      ...state,
      defaultFilterEntrepot: filter,
    }
  }

  //GET FILTER BY COLLABORATEUR
  if (action.type === GET_FILTER_COLLABORATEUR) {
    return {
      ...state,
      isLoadingGetFilters: true,
    }
  }

  if (action.type === GET_FILTER_COLLABORATEUR_SUCCESS) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingGetFilters: false,
      filtersCollaborateur: response || [],
      searchCornerOnglet: null,
    }
  }

  if (action.type === GET_FILTER_COLLABORATEUR_FAILURE) {
    return {
      ...state,
      isLoadingGetFilters: false,
      filtersCollaborateur: [],
    }
  }

  if (action.type === SAVE_DEFAULT_FILTER_COMMANDE) {
    return {
      ...state,
      defaultFilterSave: state.defaultFilterCommande,
      filterReducerSave: state.commande,
    }
  }

  if (action.type === RESET_DEFAULT_FILTER_COMMANDE) {
    return {
      ...state,
      defaultFilterCommande: state.defaultFilterSave,
      commande: state.filterReducerSave,
    }
  }

  //Change display mode (grid/table) (commande en cours)
  if (action.type === DISPLAY_MODE_CHANGED) {
    const displayGrid = state.displayGrid

    return {
      ...state,
      displayGrid: !displayGrid,
    }
  }

  //Creation new Filter
  if (action.type === CREATE_FILTER_COLLABORATEUR) {
    return {
      ...state,
      isLoadingActionFilter: true,
      error: null,
      isActionCreationFilter: false,
    }
  }

  if (action.type === CREATE_FILTER_COLLABORATEUR_SUCCESS) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingActionFilter: false,
      filtersCollaborateur: [...state.filtersCollaborateur, response],
      isActionCreationFilter: true,
    }
  }

  if (action.type === CREATE_FILTER_COLLABORATEUR_FAILURE) {
    let error = onFailure(action.payload)
    return {
      ...state,
      error: error?.statusText,
      isLoadingActionFilter: false,
    }
  }

  //Reset Action Creation new Filter
  if (action.type === RESET_ACTIONS_CREATION_FILTER) {
    return {
      ...state,
      isActionCreationFilter: false,
    }
  }

  //Delete Filter
  if (action.type === DELETE_FILTER_COLLABORATEUR) {
    return {
      ...state,
      isLoadingActionFilter: true,
      error: null,
      isActionDeleteFilter: false,
    }
  }

  if (action.type === DELETE_FILTER_COLLABORATEUR_SUCCESS) {
    const { response } = action.payload.data
    const newFiltersCollaborateur = state.filtersCollaborateur.filter(
      (filter) => filter.id != Number(response)
    )
    return {
      ...state,
      isLoadingActionFilter: false,
      filtersCollaborateur: [...newFiltersCollaborateur],
      isActionDeleteFilter: true,
    }
  }

  if (action.type === DELETE_FILTER_COLLABORATEUR_FAILURE) {
    let error = onFailure(action.payload)
    return {
      ...state,
      error: error?.statusText,
      isLoadingActionFilter: false,
    }
  }
  //Reset Action DELETE Filter
  if (action.type === RESET_ACTION_DELETE_FILTER) {
    return {
      ...state,
      isActionDeleteFilter: false,
    }
  }

  //Renommer Filter
  if (action.type === RENOMMER_FILTER_COLLABORATEUR) {
    return {
      ...state,
      isLoadingActionFilter: true,
      error: null,
      isActionFilter: false,
    }
  }

  if (action.type === RENOMMER_FILTER_COLLABORATEUR_SUCCESS) {
    const { response } = action.payload.data

    const filterRenommableIndex = state.filtersCollaborateur
      .map((el) => el.id)
      .indexOf(response.id)

    let newFiltersCollaborateur = []

    if (filterRenommableIndex === 0) {
      newFiltersCollaborateur = newFiltersCollaborateur.concat(
        response,
        state.filtersCollaborateur.slice(1)
      )
    } else if (filterRenommableIndex === state.filtersCollaborateur.length - 1) {
      newFiltersCollaborateur = newFiltersCollaborateur.concat(
        state.filtersCollaborateur.slice(0, -1),
        response
      )
    } else if (filterRenommableIndex > 0) {
      newFiltersCollaborateur = newFiltersCollaborateur.concat(
        state.filtersCollaborateur.slice(0, filterRenommableIndex),
        response,
        state.filtersCollaborateur.slice(filterRenommableIndex + 1)
      )
    }
    return {
      ...state,
      isLoadingActionFilter: false,
      filtersCollaborateur: [...newFiltersCollaborateur],
      isActionFilter: true,
    }
  }

  if (action.type === RENOMMER_FILTER_COLLABORATEUR_FAILURE) {
    let error = onFailure(action.payload)
    return {
      ...state,
      error: error?.statusText,
      isLoadingActionFilter: false,
    }
  }
  //Reset Action renommer Filter
  if (action.type === RESET_ACTION_FILTER) {
    return {
      ...state,
      isActionFilter: false,
    }
  }

  //Set id onglet filter  opnened
  if (action.type === SET_ONGLET_FILTER_OPEN) {
    const { id } = action.payload
    return {
      ...state,
      idOngletFilterOpen: id,
    }
  }

  //Modificaiton Filter
  if (action.type === MODIFICATION_FILTER_COLLABORATEUR) {
    return {
      ...state,
      isLoadingActionFilter: true,
      error: null,
      isActionModifierFilter: false,
    }
  }

  if (action.type === MODIFICATION_FILTER_COLLABORATEUR_SUCCESS) {
    const { response } = action.payload.data
    const filterRenommableIndex = state.filtersCollaborateur
      .map((el) => el.id)
      .indexOf(response.id)

    let newFiltersCollaborateur = []

    if (filterRenommableIndex === 0) {
      newFiltersCollaborateur = newFiltersCollaborateur.concat(
        response,
        state.filtersCollaborateur.slice(1)
      )
    } else if (filterRenommableIndex === state.filtersCollaborateur.length - 1) {
      newFiltersCollaborateur = newFiltersCollaborateur.concat(
        state.filtersCollaborateur.slice(0, -1),
        response
      )
    } else if (filterRenommableIndex > 0) {
      newFiltersCollaborateur = newFiltersCollaborateur.concat(
        state.filtersCollaborateur.slice(0, filterRenommableIndex),
        response,
        state.filtersCollaborateur.slice(filterRenommableIndex + 1)
      )
    } else {
      newFiltersCollaborateur = state.filtersCollaborateur
    }

    return {
      ...state,
      isLoadingActionFilter: false,
      filtersCollaborateur: [...newFiltersCollaborateur],
      isActionModifierFilter: true,
    }
  }

  if (action.type === MODIFICATION_FILTER_COLLABORATEUR_FAILURE) {
    let error = onFailure(action.payload)
    return {
      ...state,
      error: error?.statusText,
      isActionModifierFilter: false,
      isLoadingActionFilter: false,
    }
  }

  //Reset Action Modification new Filter
  if (action.type === RESET_ACTIONS_MODIFICATION_FILTER) {
    return {
      ...state,
      isActionModifierFilter: false,
    }
  }

  // SAVE SEARCH IN SEARCH AND DEFAULTSEARCH STATE
  if (action.type === SAVE_SEARCH_CORNER) {
    const { search } = action.payload
    return {
      ...state,
      searchCorner: search,
    }
  }

  // Reset FiltersCollaborateur
  if (action.type === RESET_FILTER_COLLABORATEUR) {
    return {
      ...state,
      filtersCollaborateur: null,
    }
  }
  return state
}
