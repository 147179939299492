import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import AutoCompleteSearch from './AutoCompleteSearch'

import DrawerFilter from '../../Drawer'

export default function SelectListWithSearch({
  dataList,
  disabled,
  onDataChanged,
  defaultLabel,
  filtersValues,
  Icon,
  id,
  isExternalBlock,
  contentExternal,
  isImportantTous,
  isTousSelected,
}) {
  const [opentDrawer, setOpentDrawer] = useState(false)
  const [index, setIndex] = useState(25)
  const [selected, setSelected] = useState([])
  const [checkedAll, setCheckedAll] = useState(true)
  const [search, setSearch] = useState('')
  const [label, setLabel] = useState('Tous')
  const [init, setInit] = useState(false)
  const [data, setData] = useState(dataList.slice(0, 25))
  const [isMoreLoading, setIsMoreLoading] = useState(false)

  //Init selected List
  useEffect(() => {
    if (dataList) {
      const checkedAll = filtersValues?.includes('TOUS')
      if (isImportantTous) {
        if (checkedAll && isTousSelected) {
          const selecteds = dataList.map((n) => n.code)
          setSelected(selecteds)
        } else {
          const initFilterTAB = filtersValues ? filtersValues : []
          setSelected(initFilterTAB)
        }
      } else if (filtersValues && filtersValues.length > 0 && !checkedAll) {
        const initFilterTAB = filtersValues ? filtersValues : []
        setSelected(initFilterTAB)
      } else if (selected.length === 0 || checkedAll) {
        const selecteds = dataList.map((n) => n.code)
        setSelected(selecteds)
      }
      setData(dataList.slice(0, 25))
      setInit(true)
    }
  }, [dataList, filtersValues])
  //Change lable
  useEffect(() => {
    handleLabelDisplay()
  }, [selected])
  const handleLabelDisplay = () => {
    let text = ''
    text = dataList
      .filter((el) => selected.includes(el.code))
      .map((el) => el.name)
      .join(', ')

    if (text.length > 20) {
      text = text.substring(0, 20).concat('...')
    }
    setLabel(
      isImportantTous && selected.length == 0
        ? ''
        : selected.length > 0 && selected.length < dataList.length
        ? `${text}`
        : 'Tous'
    )
  }

  //FETCH more data with search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        onSearch(search.trim())
      } else {
        if (init) {
          setData(dataList.slice(0, 25))
          setIndex(25)
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    let val = value
    setSearch(val)
    setIndex(25)
  }

  const onSearch = (search) => {
    const filteredSuggestions = dataList
      .filter((suggestion) =>
        suggestion.name.toLowerCase().includes(search.toLowerCase())
      )
      .filter((item) => item)
    setData(filteredSuggestions)
  }

  //FETCH more data with scroll
  const fetchMoreData = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) <=
        event.target.clientHeight &&
      index < dataList.length &&
      !isMoreLoading
    ) {
      setIsMoreLoading(true)
      setTimeout(() => {
        setIndex(index + 25)
        setData(dataList.slice(0, index + 25))
        setIsMoreLoading(false)
      }, 500)
    }
  }
  // Click single data
  const handleToggle = (value) => () => {
    const selectedIndex = selected.indexOf(value)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleDoubleClick = (value) => () => {
    setSelected([value])
  }
  // Click All list DATA
  const handleToggleAll = (event) => {
    setCheckedAll(event.target.checked)
    if (event.target.checked) {
      const newSelecteds = dataList.map((n) => n.code)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }
  const handleDoubleClickAll = () => {
    setCheckedAll(!checkedAll)
    if (!checkedAll) {
      const newSelecteds = dataList.map((n) => n.code)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  //Verif checked element
  const isSelected = (code) => selected.indexOf(code) !== -1

  // open && close drawer
  const toggleDrawer = (open, event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    if (!open) {
      if (isImportantTous && selected.length === 0) {
        setSelected([])
        onDataChanged(null)
      } else if (selected.length === 0) {
        setSelected(dataList.map((n) => n.code))
        onDataChanged(null)
      } else {
        onDataChanged(
          isImportantTous && selected.length === dataList.length
            ? dataList.map((n) => n.code)
            : selected.length === dataList.length
            ? ['TOUS']
            : [...new Set(selected)]
        )
      }
    }
    setOpentDrawer(open)
    setSearch('')
    setData(dataList.slice(0, 25))
  }

  return (
    <DrawerFilter
      filterLabel={`${defaultLabel} ${label ? ':' : ''} ${label ? label : ''}`}
      disabled={disabled}
      content={
        <>
          <AutoCompleteSearch
            dataList={data}
            handleToggle={handleToggle}
            handleDoubleClick={handleDoubleClick}
            fetchMoreData={fetchMoreData}
            onChangeSearch={onChangeSearch}
            search={search}
            handleDoubleClickAll={handleDoubleClickAll}
            handleToggleAll={handleToggleAll}
            numSelected={selected.length === dataList.length}
            isSelected={isSelected}
          />
          {isExternalBlock && contentExternal}
        </>
      }
      toggleDrawer={toggleDrawer}
      opentDrawer={opentDrawer}
      Icon={Icon}
      id={id}
    />
  )
}
SelectListWithSearch.propTypes = {
  dataList: PropTypes.array,
  disabled: PropTypes.bool,
  onDataChanged: PropTypes.func,
  defaultLabel: PropTypes.string,
  filtersValues: PropTypes.array,
  Icon: PropTypes.any,
  id: PropTypes.string,
  isExternalBlock: PropTypes.bool,
  contentExternal: PropTypes.any,
  isImportantTous: PropTypes.bool,
  isTousSelected: PropTypes.bool,
}
