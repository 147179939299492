import React from 'react'

export function PhTreeStructure(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M168 112h48a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v16h-8a32.1 32.1 0 0 0-32 32v24H88v-12a16 16 0 0 0-16-16H32a16 16 0 0 0-16 16v40a16 16 0 0 0 16 16h40a16 16 0 0 0 16-16v-12h24v24a32.1 32.1 0 0 0 32 32h8v16a16 16 0 0 0 16 16h48a16 16 0 0 0 16-16v-48a16 16 0 0 0-16-16h-48a16 16 0 0 0-16 16v16h-8a16 16 0 0 1-16-16V96a16 16 0 0 1 16-16h8v16a16 16 0 0 0 16 16Zm-96 36H32v-40h40v40Zm96 12h48v48h-48Zm0-112h48v48h-48Z"
      ></path>
    </svg>
  )
}
export default PhTreeStructure
