import React from 'react'
import PropTypes from 'prop-types'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CustomButton from './CustomButton'
import { styled } from '@mui/material/styles'

const StyledCircularProgress = styled(CircularProgress)(() => ({
  '&.MuiCircularProgress-root': {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '35px !important',
    height: '35px !important',
  },
}))

export default function AssemblyDialog({
  hideDialog,
  open,
  codeAssembly,
  downloadFiles,
  isLoadingFiles,
}) {
  const handleDownload = () => {
    const fileDetail = {
      code: codeAssembly,
      generateApi: 'generateFacture',
      codeType: 'codeMontage',
    }
    downloadFiles(fileDetail)
  }

  return (
    <Dialog
      open={open}
      onClose={() => hideDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        width: '95% !important',
        height: 700,
        margin: 'auto',
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialogTitle">
        <p style={{ margin: 0 }}>{'Commande prise en compte'}</p>
      </DialogTitle>
      <DialogContent>
        <p style={{ textAlign: 'center', fontWeight: 600, marginBottom: 15 }}>
          Montage
        </p>
        {codeAssembly.length > 0 && (
          <div
            className="button-alignment"
            style={codeAssembly.length >= 2 ? { height: 85 } : { height: 45 }}
          >
            {codeAssembly.map((elem, index) => (
              <CustomButton
                key={elem}
                text={
                  codeAssembly.length >= 2
                    ? `Montage_${index + 1}: ${elem}`
                    : `Montage: ${elem}`
                }
                icon={<CloudDownloadIcon />}
                handleClick={() => handleDownload()}
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.23) !important',
                  padding: '5px 15px !important',
                  textTransform: 'initial !important',
                  color: 'rgba(0, 0, 0, 0.87) !important',
                  fontSize: '12px !important',
                }}
                variant={'outlined'}
                disabled={isLoadingFiles}
              />
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {isLoadingFiles ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '2px 5px',
            }}
          >
            <StyledCircularProgress />
          </div>
        ) : (
          <CustomButton
            text={'OK'}
            icon={null}
            handleClick={() => hideDialog()}
            style={{
              color: 'rgba(0, 0, 0, 0.87) !important',
              boxShadow:
                '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
              backgroundColor: '#e0e0e0  !important',
              textTransform: 'initial  !important',
              fontSize: '12px !important',
            }}
            variant={'contained'}
            disabled={isLoadingFiles}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}
AssemblyDialog.propTypes = {
  open: PropTypes.bool,
  hideDialog: PropTypes.func,
  codeAssembly: PropTypes.string,
  downloadFiles: PropTypes.func,
  isLoadingFiles: PropTypes.bool,
}
