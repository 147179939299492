import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import CustomButton from './CustomButton'
import { styled } from '@mui/material/styles'

const StyledCircularProgress = styled(CircularProgress)(() => ({
  '&.MuiCircularProgress-root': {
    color: 'rgba(0, 0, 0, 0.54)',
    width: '35px !important',
    height: '35px !important',
  },
}))

export default function DevisDialog({
  hideDialog,
  open,
  shipment,
  downloadFiles,
  isLoadingFiles,
}) {
  const handleDownload = () => {
    const codeCourse = shipment[0].code

    const fileDetail = {
      code: codeCourse,
      generateApi: 'generateDevis',
      codeType: 'codeCourse',
    }
    downloadFiles(fileDetail)
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <p
          style={{
            textAlign: 'center',
            fontWeight: '400',
            fontSize: '27px',
            margin: '5px 83px 10px 10px',
          }}
        >
          Télécharger le devis
        </p>
      </DialogContent>
      <DialogActions>
        {isLoadingFiles ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '2px 5px',
            }}
          >
            <StyledCircularProgress />
          </div>
        ) : (
          <CustomButton
            text={'devis'}
            icon={<CloudDownloadIcon />}
            handleClick={() => handleDownload()}
            style={{
              color: 'rgba(0, 0, 0, 0.87) !important',
              boxShadow:
                '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
              backgroundColor: '#e0e0e0  !important',
              textTransform: 'initial  !important',
              fontSize: '12px !important',
            }}
            variant={'contained'}
            disabled={isLoadingFiles}
          />
        )}
        <CustomButton
          text={'OK'}
          icon={null}
          handleClick={() => hideDialog()}
          style={{
            color: 'rgba(0, 0, 0, 0.87) !important',
            boxShadow:
              '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            backgroundColor: '#e0e0e0  !important',
            textTransform: 'initial  !important',
            fontSize: '12px !important',
          }}
          variant={'contained'}
          disabled={isLoadingFiles}
        />
      </DialogActions>
    </Dialog>
  )
}
DevisDialog.propTypes = {
  open: PropTypes.bool,
  hideDialog: PropTypes.func,
  shipment: PropTypes.array,
  downloadFiles: PropTypes.func,
  isLoadingFiles: PropTypes.bool,
}
