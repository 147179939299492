import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ListItem from '@mui/material/ListItem'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const NavListItem = ({ item, onClick }) => {
  const handleClick = (event) => {
    if (item.path === window.location.pathname) {
      event.preventDefault() // prevent reloading if the link points to the current page
    }
  }
  return (
    <Link
      to={item.path}
      value={item.path}
      style={{
        backgroundColor: 'red',
        color: '#1976d2 !important',
        textDecoration: 'none !important',
        fontSize: '17px !important',
        margin: '0 !important',
      }}
      onClick={handleClick}
    >
      <ListItem
        button
        onClick={onClick}
        sx={{
          color: '#1976d2  !important',
          margin: '5px 73px  !important',
          fontSize: '16px  !important',
        }}
      >
        {item.label}
      </ListItem>
    </Link>
  )
}

NavListItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
}

const SubNavList = ({ items, onClick, open }) => {
  return (
    <Collapse in={open} unmountOnExit>
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <NavListItem key={item.title + index} item={item} onClick={onClick} />
        ))}
      </List>
    </Collapse>
  )
}

SubNavList.propTypes = {
  items: PropTypes.array,
  open: PropTypes.bool,
  onClick: PropTypes.func,
}

const SubNavListItem = ({ tab, key, onClick }) => {
  const [open, setOpen] = React.useState(false)
  const subMenuClick = () => {
    setOpen(!open)
  }
  return (
    <div>
      <ListItem button key={key} className="drawerPaper" onClick={subMenuClick}>
        {tab.label}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <SubNavList items={tab.children} open={open} onClick={onClick} />
    </div>
  )
}
SubNavListItem.propTypes = {
  tab: PropTypes.object,
  key: PropTypes.number,
  onClick: PropTypes.func,
}
export default SubNavListItem
