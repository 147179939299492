import React, { useState } from 'react'

import PropTypes from 'prop-types'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '../../../../../../components/switch/SwitchComponent'

export default function ToursFilter({
  filterStateChanged,
  savFiltersValues,
  disabled,
}) {
  const [checked, setChecked] = useState(
    savFiltersValues?.length > 0 ? savFiltersValues[0] == true : false
  )
  const onChecked = (event) => {
    setChecked(event.target.checked)
    filterStateChanged('sav', [
      !(savFiltersValues?.length > 0 ? savFiltersValues[0] == true : false),
    ])
  }
  return (
    <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              value={checked}
              defaultChecked={
                savFiltersValues?.length > 0 ? savFiltersValues[0] == true : false
              }
              onChange={onChecked}
              disabled={disabled}
              id={'SAV'}
            />
          }
          label={<span style={{ color: '#6D7B8E' }}>{'SAV'}</span>}
        />
      </FormGroup>
    </div>
  )
}
ToursFilter.propTypes = {
  filterStateChanged: PropTypes.func,
  savFiltersValues: PropTypes.array,
  disabled: PropTypes.bool,
}
