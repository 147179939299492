import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import IconPrestation from '../../../../../../assets/icons/navFilter/PrestationIcon'
import { getValue } from '../../../../../../utils'
export default function PrestationsList({
  disabled,
  filterStateChanged,
  prestationFiltersValues,
  prestations,
  isLoading,
}) {
  const [checked, setChecked] = useState([])

  useEffect(() => {
    let list = prestations.map(({ id, label }) => {
      return {
        code: id + '',
        name: label,
      }
    })
    setChecked(list)
  }, [prestations])

  const onPrestationChanged = (selectedPrestation) => {
    const service = selectedPrestation
      ? selectedPrestation
      : getValue('currentUser', {})?.prestations.map((e) => {
          return e.id + ''
        })

    filterStateChanged('prestation', service)
  }

  return (
    <SelectListWithSearch
      disabled={disabled || isLoading || prestations.length <= 0}
      onDataChanged={onPrestationChanged}
      defaultLabel={'Prestations'}
      dataList={checked}
      filtersValues={prestationFiltersValues}
      Icon={IconPrestation}
      id={'Prestation-Filter'}
    />
  )
}
PrestationsList.propTypes = {
  prestations: PropTypes.array,
  disabled: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  prestationFiltersValues: PropTypes.array,
  isLoading: PropTypes.bool,
}
