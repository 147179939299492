import React from 'react'
import PropTypes from 'prop-types'
import {
  BOX2HOME_PRIMARY_COLOR,
  locale,
  localeMap,
} from '../../../../../../../utils/values'
import { StyledCalendar } from './style'

export default function DatePicker({ state, setState }) {
  return (
    <StyledCalendar
      onChange={(item) => setState(item)}
      locale={localeMap[locale]}
      moveRangeOnFirstSelection={false}
      date={state}
      direction="horizontal"
      staticRanges={[]}
      rangeColors={[BOX2HOME_PRIMARY_COLOR, '#ffffff', BOX2HOME_PRIMARY_COLOR]}
    />
  )
}
DatePicker.propTypes = {
  state: PropTypes.instanceOf(Date),
  setState: PropTypes.func,
}
