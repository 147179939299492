import {
  GET_DAY_DISPONIBLE,
  GET_DAY_DISPONIBLE_SUCCESS,
  GET_DAY_DISPONIBLE_FAILURE,
  RESET_DAY_DISPONIBLE,
  GET_DAY_DISPONIBILITES,
} from './constants'

export const getDayDispo = (dateDebut, dateFin, codePostal) => ({
  type: GET_DAY_DISPONIBLE,
  payload: { dateDebut, dateFin, codePostal },
})

export const getDayDispoSuccess = (response) => ({
  type: GET_DAY_DISPONIBLE_SUCCESS,
  payload: { response },
})
export const getDayDispoFailure = (error) => ({
  type: GET_DAY_DISPONIBLE_FAILURE,
  payload: { error },
})

export const resetRdvDispo = () => ({
  type: RESET_DAY_DISPONIBLE,
})

export const getDayDisponibilites = (
  start,
  end,
  channelKey,
  serviceTime,
  zipCode
) => ({
  type: GET_DAY_DISPONIBILITES,
  payload: { start, end, channelKey, serviceTime, zipCode },
})
