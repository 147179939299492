import _ from 'lodash'
import moment from 'moment'
import { ASSEMBLY_TIME_LIST } from '../constants/appContants'

const getAssemblyTimes = (shipmentDate, assemblyDate, shipmentTime) => {
  if (!_.isNil(shipmentDate)) {
    if (
      moment(new Date(shipmentDate)).format('YYYY-MM-DD') ===
      moment(new Date(assemblyDate)).format('YYYY-MM-DD')
    ) {
      if (!_.isNil(shipmentTime) && _.get(ASSEMBLY_TIME_LIST, 'length')) {
        return ASSEMBLY_TIME_LIST.filter((t) => t.start > shipmentTime.start)
      } else {
        return ASSEMBLY_TIME_LIST
      }
    } else {
      return ASSEMBLY_TIME_LIST
    }
  } else {
    return ASSEMBLY_TIME_LIST
  }
}
export default getAssemblyTimes
