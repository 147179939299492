import React from 'react'

export function DirectionFilterIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '5px', verticalAlign: 'baseline' }}
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="#373d57"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M4 21V8a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H8l-4 4"></path>
        <path d="m10 9l-2 2l2 2m4-4l2 2l-2 2"></path>
      </g>
    </svg>
  )
}
export default DirectionFilterIcon
