import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@mui/material/Tooltip/Tooltip'
import TableCell from '@mui/material/TableCell/TableCell'

function LongTextComponent({ text, display, ...props }) {
  return (
    display && (
      <TableCell>
        {text !== null && text !== undefined ? (
          <Tooltip
            arrow
            title={
              <span
                style={{
                  fontSize: '10px',
                  textTransform: 'capitalize',
                  fontWeight: '400',
                }}
              >
                {text}
              </span>
            }
          >
            <p
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '150px',
              }}
              {...props}
            >
              {' '}
              {text}
            </p>
          </Tooltip>
        ) : (
          <p
            style={{
              fontSize: '10px',
              color: 'dimgrey',
            }}
          >
            N/A
          </p>
        )}
      </TableCell>
    )
  )
}
LongTextComponent.propTypes = {
  text: PropTypes.any,
  display: PropTypes.bool,
}
export default LongTextComponent
