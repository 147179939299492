import React from 'react'
import PropTypes from 'prop-types'
import Routes from '../../routes'
import { Helmet } from 'react-helmet'

import './index.scss'
export default function App({ isLoggedIn, userRole }) {
  // THIS IS CORNER :D
  if (process.env.REACT_APP_ENV === 'production') {
    console.log = function () {}
  }
  return (
    <div className="App">
      <Helmet titleTemplate="Corner | Box2Home" defaultTitle="Corner | Box2Home" />
      <Routes isLoggedIn={isLoggedIn} userRole={userRole} />
    </div>
  )
}
App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
}
