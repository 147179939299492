import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import CircularProgress from '@mui/material/CircularProgress'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ImageFile from '../../../../../assets/images/file.png'
import CloseIcon from '@mui/icons-material/Close'
import {
  validateUploadFile,
  getExtension,
} from '../../../../../utils/validator/UploadFileDiscussion'
import { AVAILABLE_EXTENSION } from '../../../../../utils/values'

export default function InputMessage({
  pushMessage,
  focus,
  notFocus,
  disabled,
  setIsSelected,
  isSelected,
  loadingSendMessage,
  onUploadfileDiscussion,
  loadingUploadFile,
  errorUploadFile,
  filesUpload,
  removeFileDiscussion,
  setLoadingUploadFile,
  setErrorUploadFile,
  deleteFile,
}) {
  const [messageValueInput, setMessageValueInoput] = useState('')
  const [sendButtonBool, setsendButtonBool] = useState(false)
  const [files, setFiles] = useState([])
  const [filesUploadState, setFilesUpload] = useState([])

  const getMessageFromInput = (event) => {
    setMessageValueInoput(event.target.value)
  }

  //after a click of enter keyBoard or the send button we make the input empty
  const clearInput = () => {
    setMessageValueInoput('')
  }
  const sendMessage = () => {
    if (messageValueInput.trim() != '' || filesUploadState.length > 0) {
      pushMessage(messageValueInput, filesUploadState)
    }
  }
  useEffect(() => {
    return () => {
      clearInput()
      anullerImage(-1)
      setsendButtonBool(false)
    }
  }, [])

  useEffect(() => {
    if (!loadingSendMessage) {
      clearInput()
      anullerImage(-1)
      setsendButtonBool(false)
    }
  }, [loadingSendMessage])

  useEffect(() => {
    if (messageValueInput.trim() != '') {
      setsendButtonBool(true)
    } else if (messageValueInput.trim() === '' && filesUploadState.length > 0) {
      setsendButtonBool(false)
    }
  }, [messageValueInput])

  useEffect(() => {
    if (errorUploadFile) {
      setFilesUpload([])
      setFiles([])
      setIsSelected(false)
      if (messageValueInput.trim() === '') {
        setsendButtonBool(false)
      }
    }
  }, [errorUploadFile])

  useEffect(() => {
    if (filesUpload?.length > 0) {
      setFilesUpload(filesUpload)
      const files = []

      filesUpload.forEach((file) =>
        files.push({
          isImage: AVAILABLE_EXTENSION.IMAGE.includes(getExtension(file.filename)),
          url: file.location,
          filename: file.filename,
        })
      )
      setFiles(files)
    }
  }, [filesUpload])

  const onUpload = (event) => {
    setLoadingUploadFile()
    const error = validateUploadFile(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
    } else {
      setIsSelected(true)
      setsendButtonBool(true)

      onUploadfileDiscussion(event.target.files)
    }
    event.target.value = null
  }

  const anullerImage = (index) => {
    let filesUploadStateDelete = []
    if (index > -1) {
      filesUploadStateDelete = filesUploadState
      filesUploadStateDelete.splice(index, 1)
    }

    deleteFile(filesUploadStateDelete)

    if (filesUploadStateDelete.length == 0 || index == -1) {
      setFiles([])
      setFilesUpload([])
      setIsSelected(false)
      if (messageValueInput.trim() === '') {
        setsendButtonBool(false)
      }
    }
  }

  return (
    <>
      {!disabled && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
        >
          <div
            style={{
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <input
              type="file"
              style={
                files.length > 0 || loadingUploadFile
                  ? {
                      opacity: '0',
                      width: '42px',
                      height: '41px',
                      position: 'absolute',
                      marginTop: '111px',
                      zIndex: '2',
                      cursor: 'pointer',
                    }
                  : {
                      opacity: '0',
                      width: '42px',
                      height: '41px',
                      position: 'absolute',
                      marginTop: '11px',
                      zIndex: '2',
                      cursor: 'pointer',
                    }
              }
              onChange={onUpload}
              multiple
            />
            <IconButton
              sx={
                files.length > 0 || loadingUploadFile
                  ? {
                      backgroundColor: '#3f51b5!important',
                      marginTop: '10px !important',
                      marginRight: '10px !important',
                      top: '100px !important',
                      height: '40px !important',
                      width: '40px !important',
                    }
                  : {
                      backgroundColor: '#3f51b5!important',
                      marginTop: '10px !important',
                      marginRight: '10px !important',
                      height: '40px !important',
                      width: '40px !important',
                    }
              }
            >
              <AttachFileIcon sx={{ color: 'white', transform: 'rotate(45deg)' }} />
            </IconButton>
          </div>
          <div
            style={
              sendButtonBool && !loadingUploadFile
                ? {
                    width: 'calc(100% - 100px)',
                    boxShadow: '0 1px 7px 0 rgb(0 0 0 / 10%)',
                    borderRadius: '5px',
                  }
                : {
                    width: 'calc(100% - 60px)',
                    boxShadow: '0 1px 7px 0 rgb(0 0 0 / 10%)',
                    borderRadius: '5px',
                  }
            }
          >
            {isSelected && (
              <div
                style={{
                  display: 'flex',
                  overflow: 'auto hidden',
                  height: '100px',
                }}
              >
                {loadingUploadFile ? (
                  <div
                    style={{
                      padding: '30px',
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <>
                    {!errorUploadFile && (
                      <>
                        {files.length > 0 && (
                          <>
                            {files.map((file, index) => {
                              return (
                                <div
                                  style={{
                                    position: 'relative',
                                    width: '112px',
                                    height: '100px',
                                    marginRight: '10px',
                                  }}
                                  key={index}
                                >
                                  <IconButton
                                    sx={{
                                      backgroundColor: '#eeeeee !important',
                                      position: 'absolute',
                                      width: '10px',
                                      height: '10px',
                                      right: '0',
                                      top: '2px',
                                    }}
                                    onClick={() => {
                                      anullerImage(index)
                                      removeFileDiscussion(file.filename)
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                  {file.isImage ? (
                                    <img
                                      src={file.url}
                                      style={{
                                        objectFit: 'contain',
                                        width: '100px',
                                        height: '100px',
                                        padding: '2%',
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={ImageFile}
                                      style={{
                                        objectFit: 'contain',
                                        width: '100px',
                                        height: '100px',
                                        padding: '2%',
                                      }}
                                    />
                                  )}
                                </div>
                              )
                            })}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            <TextField
              required
              placeholder="Enter message"
              variant="outlined"
              multiline
              rows={2}
              onFocus={focus}
              onBlur={notFocus}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter' && (ev.ctrlKey || ev.metaKey)) {
                  sendMessage()
                  ev.preventDefault()
                }
              }}
              value={messageValueInput}
              onChange={(e) => getMessageFromInput(e)}
              id="outlined-basic"
              disabled={loadingSendMessage}
            />
          </div>
          {sendButtonBool && !loadingUploadFile && (
            <div>
              <IconButton
                sx={
                  isSelected
                    ? {
                        backgroundColor: '#3f51b5!important',
                        marginTop: '10px !important',
                        marginLeft: '10px !important',
                        top: '100px !important',
                        height: '40px !important',
                        width: '40px !important',
                      }
                    : {
                        backgroundColor: '#3f51b5!important',
                        marginTop: '10px !important',
                        marginLeft: '10px !important',
                        height: '40px !important',
                        width: '40px !important',
                      }
                }
                onClick={sendMessage}
                disabled={loadingSendMessage}
              >
                {loadingSendMessage ? (
                  <CircularProgress
                    sx={{
                      color: 'white !important',
                      width: '18px!important',
                      height: '18px!important',
                    }}
                  />
                ) : (
                  <SendIcon sx={{ color: 'white' }} />
                )}
              </IconButton>
            </div>
          )}
        </div>
      )}
    </>
  )
}

InputMessage.propTypes = {
  pushMessage: PropTypes.func,
  focus: PropTypes.func,
  notFocus: PropTypes.func,
  disabled: PropTypes.bool,
  loadingSendMessage: PropTypes.bool,
  onUploadfileDiscussion: PropTypes.func,
  loadingUploadFile: PropTypes.bool,
  errorUploadFile: PropTypes.any,
  filesUpload: PropTypes.array,
  removeFileDiscussion: PropTypes.func,
  setIsSelected: PropTypes.func,
  isSelected: PropTypes.bool,
  setLoadingUploadFile: PropTypes.func,
  setErrorUploadFile: PropTypes.func,
  deleteFile: PropTypes.func,
}
