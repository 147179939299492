import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ClosedConversationList from './closedConversationList'
import OpenConversationList from './openConversationList'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

export default function ConversationContainer({
  conversationList,
  getMessageListByConversationId,
  conversationSelect,
  desSelectConversation,
  isLoadingGetConversationList,
  getCloseConversationMore,
  getOpenConversationMore,
  count,
  offset,
  isLoadingConvMore,
  isOpenChanged,
  isOpened,
}) {
  const [value, setValue] = React.useState(isOpened ? 0 : 1)

  const handleChange = (event, newValue) => {
    isOpenChanged(newValue == 0 ? true : false)
    setValue(newValue)
    desSelectConversation()
  }
  return (
    <div style={{ padding: '10px 0px 0px' }}>
      <div style={{ paddingBottom: '10px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab
            id="Discussion-tab-open"
            label={
              !isLoadingGetConversationList && value == 0
                ? `Ouvertes ( ${count} ) `
                : 'Ouvertes'
            }
            wraped
            sx={{ textTransform: 'capitalize', width: '50%', fontSize: '12px' }}
          />
          <Tab
            id="Discussion-tab-close"
            label={
              !isLoadingGetConversationList && value == 1
                ? `Fermées ( ${count} )`
                : 'Fermées'
            }
            sx={{ textTransform: 'capitalize', width: '50%', fontSize: '12px' }}
          />
        </Tabs>
      </div>
      <div style={{ height: 'calc(100vh - 378px)' }}>
        {isLoadingGetConversationList ? (
          <div style={{ padding: '40%' }}>
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            {value === 0 ? (
              <OpenConversationList
                conversations={conversationList}
                getMessageListByConversationId={getMessageListByConversationId}
                conversationSelect={conversationSelect}
                getOpenConversationMore={getOpenConversationMore}
                count={count}
                offset={offset}
                isLoadingConvMore={isLoadingConvMore}
              />
            ) : (
              <ClosedConversationList
                conversations={conversationList}
                getMessageListByConversationId={getMessageListByConversationId}
                conversationSelect={conversationSelect}
                getCloseConversationMore={getCloseConversationMore}
                count={count}
                offset={offset}
                isLoadingConvMore={isLoadingConvMore}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

ConversationContainer.propTypes = {
  conversationList: PropTypes.array,
  getMessageListByConversationId: PropTypes.func,
  changeTabConversation: PropTypes.func,
  conversationSelect: PropTypes.string,
  desSelectConversation: PropTypes.func,
  isLoadingGetConversationList: PropTypes.bool,
  getCloseConversationMore: PropTypes.func,
  getOpenConversationMore: PropTypes.func,
  count: PropTypes.number,
  offset: PropTypes.number,
  isLoadingConvMore: PropTypes.bool,
  isOpenChanged: PropTypes.func,
  isOpened: PropTypes.bool,
}
