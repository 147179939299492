import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { StyledContainer } from '../styled'
import {
  Box,
  Button,
  Fab,
  Tooltip,
  CircularProgress,
  TablePagination,
  Typography,
} from '@mui/material'
import SearchBarre from '../components/searchBarre'
import SessionsIcon from '../../../assets/icons/settingsCorner/sessionsIcon'
import moment from 'moment'
import { etatColor } from './data'
import {
  StyledBoxCards,
  StyledBoxDetail,
  StyledCard,
  StyledCardContent,
  StyledContainerCards,
  StyledContainerHeader,
  StyledTypographyInfoDetail,
  StyledTypographyTitleDetail,
} from './style'
import SessionModal from '../../../containers/settings/Modals/SessionModal'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ModalError from '../../../components/ModalError'
import CancelSession from '../../../containers/settings/Modals/CancelSession'
import { Link } from 'react-router-dom'

import DeleteSession from '../../../containers/settings/Modals/DeleteSession'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const GestionSessions = ({
  fetchData,
  isLoadingSessionsList,
  sessions,
  sessionsCount,
  errorMessage,
  removeError,
  isErrorFetch,
  pageLimitChanged,
  pageChanged,
  success,
  isSuccessRefresh,
  resetSuccessAction,
  searchData,
  onCloseReset,
  fetchSessionById,
}) => {
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', onCloseReset)

    return () => {
      onCloseReset()
      window.removeEventListener('beforeunload', onCloseReset)
    }
  }, [])

  const [openModalSession, setOpenModalSession] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [row, setRow] = useState({})
  const [sessionId, setSessionId] = useState({})

  const [openCancelSession, setOpenCancelSession] = useState(false)
  const [openDeleteSession, setOpenDeleteSession] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    pageChanged(newPage * rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageLimitChanged(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    if (success && isSuccessRefresh) {
      fetchData()
      setPage(0)
      setRowsPerPage(25)
    }
    if (success || isSuccessRefresh) {
      resetSuccessAction()
    }
  }, [success])

  const handleClose = () => {
    setOpenModalSession(false)
    setIsEdit(false)
  }

  useEffect(() => {
    if (errorMessage) {
      setOpenErrorDialog(true)
    }
  }, [errorMessage])

  const setPayloadCancelSession = () => {
    let formData = new FormData()

    formData.append(
      'sessionDto',
      JSON.stringify({ id: row.id, state: etatColor[0].etat })
    )
    return formData
  }

  return (
    <>
      <StyledContainer>
        <StyledContainerHeader>
          <Box sx={{ margin: 0 }}>
            <Typography
              sx={{
                lineHeight: '1.5',
                letterSpacing: '0.00938em',
                fontSize: '18px',
              }}
              variant="h1"
            >
              Gestion Sessions
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SearchBarre
              globalID={'Sessions'}
              onSearch={searchData}
              placeholder={'Rechercher par session'}
            />
            <Button
              sx={{
                height: '36px',
                textTransform: 'capitalize',
                fontSize: '13px',
                fontWeight: 600,
                marginLeft: '9px',
              }}
              variant="contained"
              startIcon={<SessionsIcon />}
              onClick={() => {
                setOpenModalSession(true)
              }}
            >
              Ajouter Session
            </Button>
          </Box>
        </StyledContainerHeader>
        <StyledContainerCards>
          {/* CARD STARTS  */}

          {isLoadingSessionsList ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress sx={{ color: '#1976D2' }} />
            </Box>
          ) : sessions.length > 0 ? (
            sessions.map((el, index) => (
              <StyledBoxCards key={index}>
                <Link
                  id={index}
                  key={`session_${index}`}
                  to={`session/${el.id}`}
                  style={{ color: 'inherit', textDecoration: 'inherit' }}
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                >
                  <StyledCard>
                    <StyledCardContent>
                      <StyledBoxDetail>
                        <StyledTypographyTitleDetail variant="h2">
                          {'Mise en Service:'}
                        </StyledTypographyTitleDetail>
                        <StyledTypographyInfoDetail component="div">
                          {moment(new Date(el.startDate)).format('YYYY-MM-DD')}
                        </StyledTypographyInfoDetail>
                      </StyledBoxDetail>
                      <StyledBoxDetail>
                        <StyledTypographyTitleDetail variant="h2">
                          {'Formation:'}
                        </StyledTypographyTitleDetail>
                        <StyledTypographyInfoDetail component="div">
                          {el.formationName || '--'}
                        </StyledTypographyInfoDetail>
                      </StyledBoxDetail>
                      <StyledBoxDetail>
                        <StyledTypographyTitleDetail variant="h2">
                          {'Date Début:'}
                        </StyledTypographyTitleDetail>
                        <StyledTypographyInfoDetail component="div">
                          {moment(new Date(el.startDate)).format('YYYY-MM-DD') ||
                            '--'}
                        </StyledTypographyInfoDetail>
                      </StyledBoxDetail>
                      <StyledBoxDetail>
                        <StyledTypographyTitleDetail variant="h2">
                          {'Date Fin:'}
                        </StyledTypographyTitleDetail>
                        <StyledTypographyInfoDetail component="div">
                          {moment(new Date(el.endDate)).format('YYYY-MM-DD') || '--'}
                        </StyledTypographyInfoDetail>
                      </StyledBoxDetail>
                      <StyledBoxDetail>
                        <StyledTypographyTitleDetail variant="h2">
                          {'Lieu:'}
                        </StyledTypographyTitleDetail>
                        <StyledTypographyInfoDetail component="div">
                          {el.place || '--'}
                        </StyledTypographyInfoDetail>
                      </StyledBoxDetail>
                      <StyledBoxDetail>
                        <StyledTypographyTitleDetail variant="h2">
                          {'Formatteur:'}
                        </StyledTypographyTitleDetail>
                        <StyledTypographyInfoDetail component="div">
                          {el.former || '--'}
                        </StyledTypographyInfoDetail>
                      </StyledBoxDetail>
                      <StyledBoxDetail>
                        <StyledTypographyTitleDetail variant="h2">
                          {'Nombre de participants:'}
                        </StyledTypographyTitleDetail>
                        <StyledTypographyInfoDetail component="div">
                          {el.participantsNbre || '--'}
                        </StyledTypographyInfoDetail>
                      </StyledBoxDetail>

                      <StyledBoxDetail>
                        <StyledTypographyTitleDetail variant="h2">
                          {'Etat:'}
                        </StyledTypographyTitleDetail>

                        <StyledTypographyInfoDetail
                          backgroundColor={
                            el.state &&
                            etatColor.find((e) => e.etat === el.state)
                              .backgroundColor
                          }
                          color={
                            el.state &&
                            etatColor.find((e) => e.etat === el.state).color
                          }
                          isEtat={el.state}
                          component="div"
                        >
                          {etatColor.find((e) => e.etat === el.state)?.label}
                        </StyledTypographyInfoDetail>
                      </StyledBoxDetail>

                      <Box>
                        <Tooltip
                          title={
                            <span className="tootltip-content">Annuler Session</span>
                          }
                          arrow
                        >
                          <Fab
                            sx={{
                              backgroundColor: 'transparent',
                              color: 'red',
                              zIndex: 0,
                              '&:hover': {
                                backgroundColor: '#e3effa',
                              },
                            }}
                            aria-label="close"
                            variant="extended"
                            className="fab-extended-button"
                            onClick={(event) => {
                              setRow(el)
                              setOpenCancelSession(true)
                              event.preventDefault()
                            }}
                            id={'Annuler-Session'}
                          >
                            <ClearIcon />
                          </Fab>
                        </Tooltip>
                        <Tooltip
                          title={
                            <span className="tootltip-content">Modifer Session</span>
                          }
                          arrow
                        >
                          <Fab
                            sx={{
                              backgroundColor: 'transparent',
                              color: '#1976D2',
                              zIndex: 0,
                              '&:hover': {
                                backgroundColor: '#e3effa',
                              },
                            }}
                            aria-label="close"
                            variant="extended"
                            className="fab-extended-button"
                            onClick={(event) => {
                              fetchSessionById(el.id)
                              setSessionId(el.id)
                              setOpenModalSession(true)
                              setIsEdit(true)
                              event.preventDefault()
                            }}
                            id={'Modifer-Session'}
                          >
                            <EditIcon style={{ width: 22 }} />
                          </Fab>
                        </Tooltip>
                        <Tooltip
                          title={
                            <span className="tootltip-content">
                              Supprimer Session
                            </span>
                          }
                          arrow
                        >
                          <Fab
                            sx={{
                              backgroundColor: 'transparent',
                              color: '#B70707',
                              zIndex: 0,
                              '&:hover': {
                                backgroundColor: '#e3effa',
                              },
                            }}
                            aria-label="close"
                            variant="extended"
                            className="fab-extended-button"
                            onClick={(event) => {
                              setRow(el)
                              setOpenDeleteSession(true)
                              event.preventDefault()
                            }}
                            id={'Supprimer-Session'}
                          >
                            <DeleteIcon />
                          </Fab>
                        </Tooltip>
                      </Box>
                    </StyledCardContent>
                  </StyledCard>
                </Link>
              </StyledBoxCards>
            ))
          ) : (
            <Box sx={{ display: 'flex', margin: 'auto', width: 'fit-content' }}>
              <ErrorOutlineIcon style={{ margin: 'auto 5px', fontSize: '20px' }} />
              <Typography
                sx={{
                  lineHeight: '1.5',
                  letterSpacing: '0.00938em',
                  fontSize: '18px',
                }}
                variant="h1"
              >
                Pas de données
              </Typography>
            </Box>
          )}

          {/* CARD ENDS  */}
        </StyledContainerCards>
      </StyledContainer>
      <TablePagination
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
        count={sessionsCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {openModalSession && (
        <SessionModal
          isEdit={isEdit}
          sessionId={sessionId}
          handleClose={handleClose}
          modalTitle={isEdit ? ' Modifier Sessions ' : 'Ajouter Session'}
          open={openModalSession}
        />
      )}
      {openCancelSession && (
        <CancelSession
          handleClose={() => setOpenCancelSession(false)}
          textContent={'Vous voulez vraiment annuler cette session.'}
          modalTitle={'Annuler Session'}
          open={openCancelSession}
          actionData={setPayloadCancelSession()}
        />
      )}
      {errorMessage && (
        <ModalError
          open={openErrorDialog}
          handleClose={() => {
            setOpenErrorDialog(false)
            removeError()
            isErrorFetch && fetchData()
          }}
          message={errorMessage}
          isModalActionActualiser={isErrorFetch}
        />
      )}
      {openDeleteSession && (
        <DeleteSession
          handleClose={() => setOpenDeleteSession(false)}
          textContent={'Vous voulez vraiment supprimer cette session.'}
          modalTitle={'Supprimer Session'}
          open={openDeleteSession}
          row={row}
          actionData={{ ids: [row.id] }}
        />
      )}
    </>
  )
}
GestionSessions.propTypes = {
  fetchData: PropTypes.func,
  isLoadingSessionsList: PropTypes.bool,
  sessions: PropTypes.array,
  sessionsCount: PropTypes.number,

  errorMessage: PropTypes.string,
  removeError: PropTypes.func,
  isErrorFetch: PropTypes.bool,

  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,

  success: PropTypes.bool,
  isSuccessRefresh: PropTypes.bool,
  resetSuccessAction: PropTypes.func,

  searchData: PropTypes.func,
  onCloseReset: PropTypes.func,
  fetchSessionById: PropTypes.func,
}
export default GestionSessions
