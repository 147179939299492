import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import Tab from '@mui/material/Tab'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { styled } from '@mui/material/styles'

export const StyledTab = styled(Tab)(() => ({
  fontWeight: 500,
  padding: '12px 16px',
  lineHeight: 1.75,
  width: 182,
  margin: 3,
  color: 'white !important',
  cursor: 'pointer',
  textDecoration: 'none',
  fontSize: 12,
  fontFamily: "'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
  display: 'flex',
  flexFlow: 'nowrap',
  flexDirection: 'row-reverse',
}))

export const selectedLink = {
  color: 'white !important',
  padding: '24px 6px',
  margin: 0,
  textDecoration: 'none',
  fontSize: 12,
}

const SubNavListItem = ({ tab, index, value }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(Boolean(anchorEl))
  const anchorRef = useRef(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleClose = (event, path) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setAnchorEl(null)
    setOpen(false)
    if (path === window.location.pathname) {
      event.preventDefault() // prevent reloading if the link points to the current page
    }
  }

  return (
    <>
      <StyledTab
        activeclassname={selectedLink}
        index={`tabb_${index}`}
        label={tab.label}
        onClick={handleClick}
        icon={open ? <ExpandLess /> : <ExpandMore />}
        id={`tab-${index}`}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-controls={open ? 'menu-list-grow' : undefined}
        ref={anchorRef}
        value={value}
      />
      <ClickAwayListener onClickAway={handleClose}>
        <Menu
          id="menu-list-grow"
          sx={{
            zIndex: 10000,
            top: '28px',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >
          {tab.children.map((item, index) => (
            <MenuItem
              component={Link}
              button
              to={item.path}
              value={item.path}
              style={{ color: '#1976d2', fontSize: '1.2rem' }}
              key={index}
              onClick={(e) => handleClose(e, item.path)}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </ClickAwayListener>
    </>
  )
}
SubNavListItem.propTypes = {
  tab: PropTypes.object,
  index: PropTypes.number,

  value: PropTypes.number,
}
export default SubNavListItem
