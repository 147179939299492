import { connect } from 'react-redux'

import CoursesOptimisation from '../../../components/NavBar/main/ActionsMenu/coursesOptimisation'

import {
  changeOffsetWareHouse,
  fetchWareHouses,
  fetchWareHousesMore,
  optimiserCourses,
  resetConfigWareHouse,
  searchWareHouses,
} from '../../../redux/simulateTournee/actions'
import {
  getIsLoadingWarehouses,
  getWarehouses,
  getWarehousesCount,
} from '../../../redux/simulateTournee/selectors'

const mapStateToProps = (state) => ({
  warehouses: getWarehouses(state),
  warehousesCount: getWarehousesCount(state),
  isLoadingWarehouses: getIsLoadingWarehouses(state),
})
const mapDisptachToProps = (dispatch) => ({
  fetchWareHouses: (search) => {
    dispatch(searchWareHouses(search))
    dispatch(fetchWareHouses())
  },
  fetchWareHousesMore: (offset) => {
    dispatch(changeOffsetWareHouse(offset))
    dispatch(fetchWareHousesMore())
  },
  resetConfigWareHouse: () => dispatch(resetConfigWareHouse()),

  optimiserCourses: (payload) => dispatch(optimiserCourses(payload)),
})

export default connect(mapStateToProps, mapDisptachToProps)(CoursesOptimisation)
