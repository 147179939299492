import initialState from './initialState'
import {
  GET_DAY_DISPONIBLE,
  GET_DAY_DISPONIBLE_SUCCESS,
  GET_DAY_DISPONIBLE_FAILURE,
  RESET_DAY_DISPONIBLE,
  GET_DAY_DISPONIBILITES,
  GET_DAY_DISPONIBILITES_SUCCESS,
  GET_DAY_DISPONIBILITES_FAILURE,
} from './constants'

export default (state = initialState, action) => {
  if (action.type === GET_DAY_DISPONIBLE || action.type === GET_DAY_DISPONIBILITES) {
    return {
      ...state,
      loading: true,
      error: false,
    }
  }
  if (action.type === GET_DAY_DISPONIBLE_SUCCESS) {
    const { TabRdvDispo } = action.payload.response
    return {
      ...state,
      TabRdvDispo: TabRdvDispo,
      loading: false,
      error: null,
    }
  }
  if (action.type === GET_DAY_DISPONIBILITES_SUCCESS) {
    const { data } = action.payload.data
    return {
      ...state,
      TabRdvDispo: data,
      loading: false,
      error: null,
    }
  }

  if (
    action.type === GET_DAY_DISPONIBLE_FAILURE ||
    action.type === GET_DAY_DISPONIBILITES_FAILURE
  ) {
    const error = 'UNE ERREUR S’EST PRODUITE'
    return {
      ...state,
      loading: false,
      error,
    }
  }

  if (action.type === RESET_DAY_DISPONIBLE) {
    return {
      ...state,
      TabRdvDispo: [],
    }
  }

  return state
}
