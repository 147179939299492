import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import ActivityHistoryIcon from '../../../../../../assets/icons/detailsLivraison/IcOutlineManageHistory'
import CardAccordion from '../card/cardWithAccordion'

const ActivityHistory = ({
  order,
  orderActivityHistory,
  getActivityHistory,
  isLoadingActivityHistory,
  isLoading,
}) => {
  useEffect(() => {
    if (order?.code) {
      getActivityHistory(order.code)
    }
  }, [order])
  return (
    <CardAccordion
      icon={
        <ActivityHistoryIcon
          style={{ marginRight: '4px', width: '20px', height: '20px' }}
        />
      }
      title={'Historique activité'}
      content={
        <>
          {isLoadingActivityHistory || isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px',
              }}
            >
              <CircularProgress color="primary" />
            </div>
          ) : (
            <>
              {orderActivityHistory?.length > 0 ? (
                <div style={{ padding: '5px 5px 10px' }}>
                  {orderActivityHistory.map((activity, i) => {
                    return (
                      <div key={i} style={{ paddingBottom: '5px' }}>
                        <div
                          style={{
                            color: '#696969',
                            fontSize: '14px',
                            fontWeight: '400',

                            paddingLeft: '25px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {activity.date}
                        </div>
                        {activity.historyDetails.map((step, i) => (
                          <>
                            <div
                              key={i}
                              style={{
                                display: 'flex',
                                padding: '10px 40px',
                                borderBottom: '1px solid #f5f5f5',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  marginRight: '10px',
                                }}
                              >
                                {step.hour}
                              </span>
                              <div
                                style={{
                                  display: 'grid',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: '450',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {step?.isCreated && step?.collaborateurId
                                    ? `${step.collaborateurId} a crée la commande.`
                                    : !step?.collaborateurId
                                    ? 'la commande a été crée.'
                                    : `${step.collaborateurId} a modifier le type de course de:
                               ${step.oldValue} vers ${step.newValue}.`}
                                </span>
                                {step?.changeSource && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      paddingTop: '5px',
                                    }}
                                  >
                                    {' '}
                                    <span
                                      style={{
                                        fontSize: '13px',
                                        fontWeight: '550',
                                        marginRight: '10px',
                                      }}
                                    >
                                      {'Course source: '}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '450',
                                        wordBreak: 'break-word',
                                      }}
                                    >
                                      {step.changeSource}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <span
                    style={{
                      overflowWrap: 'break-word',
                      fontSize: '12px',
                      fontWeight: '550',
                    }}
                  >
                    {'Activité de livraison est vide'}
                  </span>
                </div>
              )}
            </>
          )}
        </>
      }
    />
  )
}
ActivityHistory.propTypes = {
  order: PropTypes.object,
  orderActivityHistory: PropTypes.array,
  isLoadingActivityHistory: PropTypes.bool,
  getActivityHistory: PropTypes.func,
  isLoading: PropTypes.bool,
}
export default ActivityHistory
