import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Divider from '@mui/material/Divider'

export const StyledContainerSessionDetails = styled(Box)({
  padding: '50px 15px',
})

export const StyledContainerDetails = styled(Box)({ height: '100%' })

export const StyledBlocDetailsTitle = styled(Box)({
  marginBottom: '20px',
})

export const StyledDetailsTitle = styled(Box)({
  fontSize: '24px',
})

export const StyledCardDetails = styled(Box)({
  boxShadow: '0px 3px 6px #00000029',
  color: '#3D444D',
  padding: '10px 0px',
})
export const StyledBlocDetailsElement = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px 16px',
})

export const StyledDetailsElement = styled(Box)({
  fontSize: '13px',
  fontWeight: '600',
  color: '#3D444D',
})

export const StyledDetailsSecondElement = styled(Box)({
  color: '#6D7B8E',
})

export const StyledContainerParticipant = styled(Box)({
  padding: '15px',
})

export const StyledBlocTitleParticipant = styled(Box)({
  backgroundColor: '#E3EFFA',
  padding: '22px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '32px',
  borderRadius: '4px',
  border: '1px solid #F0F0F0',
})

export const StyledTitleParticipant = styled(Box)({
  color: '#3D444D',
  fontSize: '14px',
})

export const StyleblocTableParticipant = styled(Box)({
  paddingTop: '8px',
})

export const StyleDivider = styled(Divider)({
  borderColor: '#EBEDF0',
})
