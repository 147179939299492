import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '@mui/material/ListItem'
import Drawer from '@mui/material/Drawer'
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import './style.css'
import { styled } from '@mui/material/styles'

export const StyledListItem = styled(ListItem)(() => ({
  color: '#6D7B8E !important',
  height: '35px',
  '&:hover': {
    backgroundColor: '#0080ff24 !important',
    color: '#1976d2 !important',
    '& .IconListItem': {
      color: '#1976d2 !important',
    },
  },
}))

export default function DrawerDateFilter({
  filterLabel,
  disabled,
  content,
  toggleDrawer,
  opentDrawer,
  Icon,
  id,
  actionButtons,
}) {
  return (
    <>
      <StyledListItem
        button
        disabled={disabled}
        onClick={(event) => toggleDrawer(true, event)}
        id={id}
      >
        <Icon className="IconListItem" />
        <span>{filterLabel}</span>
      </StyledListItem>
      <Drawer
        anchor={'right'}
        open={opentDrawer}
        onClose={(event) => toggleDrawer(false, event)}
        PaperProps={{
          sx: {
            top: 120,
            boxShadow:
              'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 0px rgb(60 64 67 / 15%)',
          },
        }}
        BackdropProps={{ invisible: true }}
        style={{ zIndex: 2 }}
      >
        <div role="presentation" style={{ width: 280 }}>
          <div
            style={{
              display: 'flex ',
              alignItems: 'center',
              fontSize: '13px',
              paddingTop: '15px',
              fontWeight: '600',
            }}
          >
            <KeyboardArrowLeftOutlinedIcon
              style={{
                cursor: 'pointer',
                marginRight: '2px',
                width: '20px',
                height: '20px',
              }}
              onClick={(event) => toggleDrawer(false, event)}
            />
            {filterLabel}
          </div>
          {content}
          {actionButtons}
        </div>
      </Drawer>
    </>
  )
}
DrawerDateFilter.propTypes = {
  disabled: PropTypes.bool,
  filterLabel: PropTypes.string,
  content: PropTypes.any,
  toggleDrawer: PropTypes.func,
  opentDrawer: PropTypes.bool,
  Icon: PropTypes.any,
  id: PropTypes.string,
  actionButtons: PropTypes.any,
}
