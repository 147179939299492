export const OrderConstants = {
  FETCH_ORDER_BY_FILTER: 'FETCH_ORDER_BY_FILTER',
  FETCH_ORDER_BY_FILTER_SUCCESS: 'FETCH_ORDER_BY_FILTER_SUCCESS',
  FETCH_ORDER_BY_FILTER_FAILURE: 'FETCH_ORDER_BY_FILTER_FAILURE',

  FETCH_ORDER_BY_AGENTS: 'FETCH_ORDER_BY_AGENTS',
  FETCH_ORDER_BY_AGENTS_SUCCESS: 'FETCH_ORDER_BY_AGENTS_SUCCESS',
  FETCH_ORDER_BY_AGENTS_FAILURE: 'FETCH_ORDER_BY_AGENTS_FAILURE',

  FETCH_COURSES_ONGLET_CORNER_SEARCH: 'FETCH_COURSES_ONGLET_CORNER_SEARCH',
  FETCH_COURSES_CORNER_SEARCH: 'FETCH_COURSES_CORNER_SEARCH',
  FETCH_COURSES_CORNER_SEARCH_SUCCESS: 'FETCH_COURSES_CORNER_SEARCH_SUCCESS',
  FETCH_COURSES_CORNER_SEARCH_FAILURE: 'FETCH_COURSES_CORNER_SEARCH_FAILURE',

  FETCH_COURSES_CORNER: 'FETCH_COURSES_CORNER',

  FETCH_COURSES_CORNER_SEARCH_SAVE: 'FETCH_COURSES_SEARCH_CORNER_SAVE',
  FETCH_COURSES_CORNER_SEARCH_SAVE_SUCCESS:
    'FETCH_COURSES_CORNER_SEARCH_SAVE_SUCCESS',
  FETCH_COURSES_CORNER_SEARCH_SAVE_FAILURE:
    'FETCH_COURSES_CORNER_SEARCH_SAVE_FAILURE',

  RESET_ORDER: 'RESET_ORDER',

  ADD_SELECTED_COURSES: 'ADD_SELECTED_COURSES',
  REMOVE_SELECTED_COURSES: 'REMOVE_SELECTED_COURSES',
  REMOVE_ALL_SELECTED_COURSES: 'REMOVE_ALL_SELECTED_COURSES',

  REMOVE_ERROR: 'REMOVE_ERROR',
  REMOVE_ERROR_ACTION: 'REMOVE_ERROR_ACTION',

  FETCH_RELATED_COURSE_INFO: 'FETCH_RELATED_COURSE_INFO',
  FETCH_RELATED_COURSE_INFO_SUCCESS: 'FETCH_RELATED_COURSE_INFO_SUCCESS',
  FETCH_RELATED_COURSE_INFO_FAILURE: 'FETCH_RELATED_COURSE_INFO_FAILURE',

  GET_DELAYED_ORDERS: 'GET_DELAYED_ORDERS',
  GET_DELAYED_ORDERS_SUCCESS: 'GET_DELAYED_ORDERS_SUCCESS',
  GET_DELAYED_ORDERS_FAILURE: 'GET_DELAYED_ORDERS_FAILURE',

  GET_ORDER_BY_LDV: 'GET_ORDER_BY_LDV',
  GET_ORDER_BY_LDV_SUCCESS: 'GET_ORDER_BY_LDV_SUCCESS',
  GET_ORDER_BY_LDV_FAILED: 'GET_ORDER_BY_LDV_FAILED',

  RESET_ORDER_DETAILS: 'RESET_ORDER_DETAILS',

  UPLOAD_SIGNATURE: 'UPLOAD_SIGNATURE',
  UPLOAD_SIGNATURE_SUCCESS: 'UPLOAD_SIGNATURE_SUCCESS',
  UPLOAD_SIGNATURE_FAILED: 'UPLOAD_SIGNATURE_FAILED',

  GET_ORDER_SIGNATURE_BY_LDV: 'GET_ORDER_SIGNATURE_BY_LDV',
  GET_ORDER_SIGNATURE_BY_LDV_SUCCESS: 'GET_ORDER_SIGNATURE_BY_LDV_SUCCESS',
  GET_ORDER_SIGNATURE_BY_LDV_FAILED: 'GET_ORDER_SIGNATURE_BY_LDV_FAILED',

  UPLOAD_PHOTO: 'UPLOAD_PHOTO',
  UPLOAD_PHOTO_SUCCESS: 'UPLOAD_PHOTO_SUCCESS',
  UPLOAD_PHOTO_FAILED: 'UPLOAD_PHOTO_FAILED',

  GET_ORDER_PHOTO_BY_LDV: 'GET_ORDER_PHOTO_BY_LDV',
  GET_ORDER_PHOTO_BY_LDV_SUCCESS: 'GET_ORDER_PHOTO_BY_LDV_SUCCESS',
  GET_ORDER_PHOTO_BY_LDV_FAILED: 'GET_ORDER_PHOTO_BY_LDV_FAILED',

  DOWNLOAD_IMAGE_ORDER: 'DOWNLOAD_IMAGE_ORDER',

  UPLOAD_ATTACHMENT: 'UPLOAD_ATTACHMENT',
  UPLOAD_ATTACHMENT_SUCCESS: 'UPLOAD_ATTACHMENT_SUCCESS',
  UPLOAD_ATTACHMENT_FAILED: 'UPLOAD_ATTACHMENT_FAILED',

  GET_ORDER_ATTACHMENT_BY_LDV: 'GET_ORDER_ATTACHMENT_BY_LDV',
  GET_ORDER_ATTACHMENT_BY_LDV_SUCCESS: 'GET_ORDER_ATTACHMENT_BY_LDV_SUCCESS',
  GET_ORDER_ATTACHMENT_BY_LDV_FAILED: 'GET_ORDER_ATTACHMENT_BY_LDV_FAILED',

  GET_PIECE_JOINTE_ORDER: 'GET_PIECE_JOINTE_ORDER',
  GET_PIECE_JOINTE_ORDER_SUCCESS: 'GET_PIECE_JOINTE_ORDER_SUCCESS',
  GET_PIECE_JOINTE_ORDER_FAILED: 'GET_PIECE_JOINTE_ORDER_FAILED',

  SET_ERROR_UPLOAD_FILE: 'SET_ERROR_UPLOAD_FILE',

  GET_NOTIFICATION_ORDER: 'GET_NOTIFICATION_ORDER',
  GET_NOTIFICATION_ORDER_SUCCESS: 'GET_NOTIFICATION_ORDER_SUCCESS',
  GET_NOTIFICATION_ORDER_FAILED: 'GET_NOTIFICATION_ORDER_FAILED',

  GET_HISTORIQUE_DELTA_COST_ORDER: 'GET_HISTORIQUE_DELTA_COST_ORDER',
  GET_HISTORIQUE_DELTA_COST_ORDER_SUCCESS: 'GET_HISTORIQUE_DELTA_COST_ORDER_SUCCESS',
  GET_HISTORIQUE_DELTA_COST_ORDER_FAILED: 'GET_HISTORIQUE_DELTA_COST_ORDER_FAILED',

  GET_HISTORIQUE_LIVRAISON_ORDER: 'GET_HISTORIQUE_LIVRAISON_ORDER',
  GET_HISTORIQUE_LIVRAISON_ORDER_SUCCESS: 'GET_HISTORIQUE_LIVRAISON_ORDER_SUCCESS',
  GET_HISTORIQUE_LIVRAISON_ORDER_FAILED: 'GET_HISTORIQUE_LIVRAISON_ORDER_FAILED',

  GET_HISTORIQUE_RDV_ORDER: 'GET_HISTORIQUE_RDV_ORDER',
  GET_HISTORIQUE_RDV_ORDER_SUCCESS: 'GET_HISTORIQUE_RDV_ORDER_SUCCESS',
  GET_HISTORIQUE_RDV_ORDER_FAILED: 'GET_HISTORIQUE_RDV_ORDER_FAILED',

  GET_ACTIVITY_ORDER_HISTORY: 'GET_ACTIVITY_ORDER_HISTORY',
  GET_ACTIVITY_ORDER_HISTORY_SUCCESS: 'GET_ACTIVITY_ORDER_HISTORY_SUCCESS',
  GET_ACTIVITY_ORDER_HISTORY_FAILED: 'GET_ACTIVITY_ORDER_HISTORY_FAILED',

  FETCH_ORDER_REASONS: 'FETCH_ORDER_REASONS_REASONS',
  FETCH_ORDER_REASONS_SUCCESS: 'FETCH_ORDER_REASONS_SUCCESS',
  FETCH_ORDER_REASONS_FAILURE: 'FETCH_ORDER_REASONS_FAILURE',

  CHANGE_STATUS_COURSE_DISPATCH: 'CHANGE_STATUS_COURSE_DISPATCH',
  CHANGE_STATUS_COURSE_DISPATCH_SUCCESS: 'CHANGE_STATUS_COURSE_DISPATCH_SUCCESS',
  CHANGE_STATUS_COURSE_DISPATCH_FAILURE: 'CHANGE_STATUS_COURSE_DISPATCH_FAILURE',

  ASSIGN_DRIVER: 'ASSIGN_DRIVER',
  ASSIGN_DRIVER_SUCCESS: 'ASSIGN_DRIVER_SUCCESS',
  ASSIGN_DRIVER_FAILURE: 'ASSIGN_DRIVER_FAILURE',

  UPDATE_WAREHOUSE: 'UPDATE_WAREHOUSE',
  UPDATE_WAREHOUSE_SUCCESS: 'UPDATE_WAREHOUSE_SUCCESS',
  UPDATE_WAREHOUSE_FAILURE: 'UPDATE_WAREHOUSE_FAILURE',

  PROGRAM_COURSE: 'PROGRAM_COURSE',
  PROGRAM_COURSE_SUCESS: 'PROGRAM_COURSE_SUCESS',
  PROGRAM_COURSE_FAILURE: 'PROGRAM_COURSE_FAILURE',

  SET_RDV_IMPOSSIBLE: 'SET_RDV_IMPOSSIBLE',
  SET_RDV_IMPOSSIBLE_SUCCESS: 'SET_RDV_IMPOSSIBLE_SUCCESS',
  SET_RDV_IMPOSSIBLE_FAILURE: 'SET_RDV_IMPOSSIBLE_FAILURE',

  SET_PREPARED: 'SET_PREPARED',
  SET_PREPARED_SUCCESS: 'SET_PREPARED_SUCCESS',
  SET_PREPARED_FAILURE: 'SET_PREPARED_FAILURE',

  RESET_ORDER_ACTION: 'RESET_ORDER_ACTION',

  GET_COLLABORATEURS: 'GET_COLLABORATEURS',
  GET_COLLABORATEURS_SUCCESS: 'GET_COLLABORATEURS_SUCCESS',
  GET_COLLABORATEURS_FAILED: 'GET_COLLABORATEURS_FAILED',

  GET_CLIENTSPRO: 'GET_CLIENTSPRO',
  GET_CLIENTSPRO_SUCCESS: 'GET_CLIENTSPRO_SUCCESS',
  GET_CLIENTSPRO_FAILED: 'GET_CLIENTSPRO_FAILED',

  SET_LOADING: 'SET_LOADING',

  REFRESH_FETCH_ORDER_BY_FILTER: 'REFRESH_FETCH_ORDER_BY_FILTER',
  REFRESH_FETCH_ORDER_BY_FILTER_SUCCESS: 'REFRESH_FETCH_ORDER_BY_FILTER_SUCCESS',
  REFRESH_FETCH_ORDER_BY_FILTER_FAILED: 'REFRESH_FETCH_ORDER_BY_FILTER_FAILED',

  REFRESH_COURSES_ONGLET_CORNER_SEARCH: 'REFRESH_COURSES_ONGLET_CORNER_SEARCH',
  REFRESH_COURSES_ONGLET_CORNER_SEARCH_SUCCESS:
    'REFRESH_COURSES_ONGLET_CORNER_SEARCH_SUCCESS',
  REFRESH_COURSES_ONGLET_CORNER_SEARCH_FAILED:
    'REFRESH_COURSES_ONGLET_CORNER_SEARCH_FAILED',

  FETCH_MACRO_CANAL: 'FETCH_MACRO_CANAL',
  FETCH_MACRO_CANAL_SUCCESS: 'FETCH_MACRO_CANAL_SUCCESS',
  FETCH_MACRO_CANAL_FAILED: 'FETCH_MACRO_CANAL_FAILED',

  IMPORT_COURSES: 'IMPORT_COURSES',
  IMPORT_COURSES_SUCCESS: 'IMPORT_COURSES_SUCCESS',
  IMPORT_COURSES_FAILED: 'IMPORT_COURSES_FAILED',

  RESET_ACTION_IMPORT_COURSES: 'RESET_ACTION_IMPORT_COURSES',
  REMOVE_ERROR_IMPORT_COURSES: 'REMOVE_ERROR_IMPORT_COURSES',

  ADD_TO_NAVETTE: 'ADD_TO_NAVETTE',
  ADD_TO_NAVETTE_SUCCESS: 'ADD_TO_NAVETTE_SUCCESS',
  ADD_TO_NAVETTE_FAILURE: 'ADD_TO_NAVETTE_FAILURE',

  TO_CLIENT_DELIVERY: 'TO_CLIENT_DELIVERY',
  TO_CLIENT_DELIVERY_SUCCESS: 'TO_CLIENT_DELIVERY_SUCCESS',
  TO_CLIENT_DELIVERY_FAILURE: 'TO_CLIENT_DELIVERY_FAILURE',

  SET_DUPLICATED_COURSE: 'SET_DUPLICATED_COURSE',
  GET_DUPLICATED_COURSE: 'GET_DUPLICATED_COURSE',
  GET_DUPLICATED_COURSE_SUCCESS: 'GET_DUPLICATED_COURSE_SUCCESS',
  GET_DUPLICATED_COURSE_FAILURE: 'GET_DUPLICATED_COURSE_FAILURE',

  SET_ACTION_ORDER: 'SET_ACTION_ORDER',

  GET_ACTIVITY: 'GET_ACTIVITY',
  GET_ACTIVITY_SUCCESS: 'GET_ACTIVITY_SUCCESS',
  GET_ACTIVITY_FAILURE: 'GET_ACTIVITY_FAILURE',

  FETCH_COURSES_ONGLET_CORNER_SEARCH_SAVE: 'FETCH_COURSES_ONGLET_SEARCH_CORNER_SAVE',

  SET_ALL_CHECK_COURSES: 'SET_ALL_CHECK_COURSES',
  SET_CHECK_COURSES: 'SET_CHECK_COURSES',
  INIT_CHECK_COURSES: 'INIT_CHECK_COURSES',

  CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH: 'CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH',
  CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH_SUCCESS:
    'CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH_SUCCESS',
  CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH_FAILURE:
    'CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH_FAILURE',

  GET_ORDER_QUESTION_BY_LDV: 'GET_ORDER_QUESTION_BY_LDV',
  GET_ORDER_QUESTION_BY_LDV_SUCCESS: 'GET_ORDER_QUESTION_BY_LDV_SUCCESS',
  GET_ORDER_QUESTION_BY_LDV_FAILURE: 'GET_ORDER_QUESTION_BY_LDV_FAILURE',

  REFRESH_COURSES_CORNER_SEARCH: 'REFRESH_COURSES_CORNER_SEARCH',

  GET_CONFIG_CANAL: 'GET_CONFIG_CANAL',
  GET_CONFIG_CANAL_SUCCESS: 'GET_CONFIG_CANAL_SUCCESS',
  GET_CONFIG_CANAL_FAILED: 'GET_CONFIG_CANAL_FAILED',

  RESET_CONFIG_CANAL: 'RESET_CONFIG_CANAL',
}
