//TODO LINT
/* eslint-disable */
import { ASSEMBLY_TIME_LIST } from '../../../../constants/appContants'
import { ASSEMBLY_DEFAULT_SOURCE } from '../../../../utils/values'

export const assemblyFormData = (others) => {
  return {
    ...others,
    selectedSalesChannel: '',
    selectedAddress: '',
    queryaddress: '',
    times: ASSEMBLY_TIME_LIST,
    date: null,
    time: '',
    purchaseAmount: '',
    estimatedPrice: null,
    clientPrice: 0,
    minDate: null,
    category: '',
    observation: '',
    fileUploaded: false,
    files: null,
    assembly_source: ASSEMBLY_DEFAULT_SOURCE.code,
    originQuery: '',
    selectedOrigin: null,
    destinationQuery: '',
    selectedDestination: null,
    direction: undefined,
  }
}
