import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { settingsSidebarLinks } from './sidebarItemsData'
import { styled } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { routeCanAccess } from '../../utils/roleManagement'

const StyledListItemText = styled(ListItemText)({
  display: 'flex',
  margin: '0px 0px 0px 11px',
  '& .MuiTypography-root': {
    fontWeight: '600',
  },
})

const ListLinkItem = ({ component, id, key, label, value, to }) => {
  return (
    <Link
      id={id}
      key={`link_${key}`}
      label={label}
      value={value}
      to={to}
      style={{ color: 'inherit', textDecoration: 'inherit' }}
    >
      {component}
    </Link>
  )
}

ListLinkItem.propTypes = {
  component: PropTypes.any,
  id: PropTypes.string,
  key: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  to: PropTypes.string,
}

export default function ListMenu({ onCloseDrawer, userRole }) {
  const [indexOfActiveItem, setIndexOfActiveItem] = useState()

  const [openedItems, setOpenedItems] = useState([])

  const handleClick = (element) => {
    let index = openedItems.indexOf(element)
    if (index === -1) {
      setOpenedItems([...openedItems, element])
    } else {
      let updatedList = [...openedItems]
      updatedList.splice(index, 1)
      setOpenedItems(updatedList)
    }
  }

  let location = useLocation()

  useEffect(() => {
    const routesCanAccess = routeCanAccess(settingsSidebarLinks, userRole)
    const activeIndex = routesCanAccess.findIndex(
      (item) => item.path === location.pathname
    )
    if (activeIndex !== -1) {
      // we will change this state to a state that takes [index of the active item ,-1]
      //-1 : there is no children to the item
      // there is no children then we gonna change directly the color of the active item
      setIndexOfActiveItem(activeIndex)
    } else {
      const activeIndexChildren = routesCanAccess.findIndex((link) =>
        link.children?.some((item) => item.path === location.pathname)
      )
      if (activeIndexChildren !== -1) {
        // we will change this state to a state that takes :
        // [index of parent of the active children , index of active children]
        // then we will change the color of the active item of children
        setIndexOfActiveItem(activeIndexChildren)
      } else {
        const activeIndexlinkRelated = routesCanAccess.findIndex((link) =>
          link.linkRelated?.some((item) => location.pathname.includes(item.path))
        )
        if (activeIndexlinkRelated !== -1) {
          // we will change this state to a state that takes :
          // [index of parent of the active linkRelated , index of active children]
          // then we will change the color of the active item of linkRelated
          setIndexOfActiveItem(activeIndexlinkRelated)
        } else {
          const activeIndexChildrenLinkRelated = routesCanAccess.findIndex((link) =>
            link.children?.some((item) =>
              item.linkRelated?.some((linkRelated) =>
                location.pathname.includes(linkRelated.path)
              )
            )
          )
          if (activeIndexChildrenLinkRelated !== -1) {
            // we will change this state to a state that takes :
            // [index of parent of the active childreen linkRelated , index of active children]
            // then we will change the color of the active item of childreen
            setIndexOfActiveItem(activeIndexChildrenLinkRelated)
          }
        }
      }
    }
  }, [location])

  const activeChildren = (activeLink) => {
    return (
      activeLink.path === location.pathname ||
      activeLink?.linkRelated?.some((linkRelated) =>
        location.pathname.includes(linkRelated.path)
      )
    )
  }
  return (
    <>
      <List sx={{ paddingTop: '30px' }}>
        {routeCanAccess(settingsSidebarLinks, userRole).map((item, index) =>
          item.children?.length > 0 ? (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  id={'setting-children-list'}
                  onClick={() => handleClick(index)}
                  sx={{
                    padding: '0 0 0 23px',
                    height: '38px',
                    borderRadius: '0px 4px 4px 0px',
                    ...(indexOfActiveItem === index && {
                      background: '#E3EFFA',
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '20px',
                      ...(indexOfActiveItem === index && {
                        color: '#1876D2',
                      }),
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>

                  <StyledListItemText
                    primary={item.label}
                    sx={{
                      ...(indexOfActiveItem === index && {
                        color: '#1876D2',
                      }),
                    }}
                  />
                  {openedItems.indexOf(index) !== -1 ? (
                    <ExpandLess sx={{ position: 'absolute', right: '10px' }} />
                  ) : (
                    <KeyboardArrowRightIcon
                      sx={{ position: 'absolute', right: '10px' }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse
                key={index}
                in={openedItems.indexOf(index) !== -1}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item.children.map((childItem, childIndex) => (
                    <ListLinkItem
                      id={`children-links-${childIndex}`}
                      key={childItem.path}
                      label={childItem.label}
                      value={childIndex + 1}
                      to={childItem.path}
                      component={
                        <ListItemButton
                          sx={{
                            padding: '0 0 0 52px',
                            height: '38px',
                            borderRadius: '0px 4px 4px 0px',
                          }}
                          onClick={onCloseDrawer}
                        >
                          <StyledListItemText
                            sx={{
                              marginLeft: 0,
                              color: '#7A7D8F',
                              '& .MuiTypography-root': {
                                fontWeight: activeChildren(childItem) ? 800 : 200,
                              },
                            }}
                            primary={childItem.label}
                          />
                        </ListItemButton>
                      }
                    />
                  ))}
                </List>
              </Collapse>
            </>
          ) : (
            <ListLinkItem
              id={item.path}
              key={item.path}
              label={item.label}
              value={index + 1}
              to={item.path}
              component={
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    onClick={onCloseDrawer}
                    sx={{
                      padding: '0 0 0 23px',
                      height: '38px',
                      ...(indexOfActiveItem === index && {
                        background: '#E3EFFA',
                      }),

                      borderRadius: '0px 4px 4px 0px',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '21px',
                        ...(indexOfActiveItem === index && {
                          color: '#1876D2',
                        }),
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <StyledListItemText
                      sx={{
                        ...(indexOfActiveItem === index && {
                          color: '#1876D2',
                        }),
                      }}
                      primary={item.label}
                    />
                  </ListItemButton>
                </ListItem>
              }
            />
          )
        )}
      </List>
    </>
  )
}
ListMenu.propTypes = {
  onCloseDrawer: PropTypes.func,
  userRole: PropTypes.string,
}
