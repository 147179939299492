import React from 'react'
import PropTypes from 'prop-types'
import CommentIcon from '@mui/icons-material/Comment'
import CardAccordion from '../card/cardWithAccordion'
export default function NoteInterne({ order }) {
  return (
    <>
      {order?.noteInterne && (
        <CardAccordion
          icon={
            <CommentIcon
              style={{ marginRight: '4px', width: '20px', height: '20px' }}
            />
          }
          title={'Note Interne'}
          content={
            <div
              style={{
                fontSize: '14px',
                padding: '10px',
              }}
            >
              <p
                style={{ overflowWrap: 'break-word' }}
                dangerouslySetInnerHTML={{
                  __html: order.noteInterne,
                }}
              />
            </div>
          }
        />
      )}
    </>
  )
}
NoteInterne.propTypes = {
  order: PropTypes.object,
}
