import { connect } from 'react-redux'

import VehiculeModal from '../../../pages/settings/components/Modals/VehiculeModal'

import {
  getKeys,
  getKeysCount,
  getKeysListIsLoading,
  getErrorMessageConfig,
  getConfigSuccess,
  getKeyCreatedObject,
} from '../../../redux/configuration/selectors'
import {
  fetchKeys,
  getKeysMore,
  onSearchCleRessources,
  pageChanged,
  resetConfig,
} from '../../../redux/configuration/actions'

import { createVehicule, updateVehicule } from '../../../redux/vehicules/actions'

import {
  getIsLoadingVehiculeAction,
  getSuccess,
  getErrorMessage,
} from '../../../redux/vehicules/selectors'

const mapStateToProps = (state) => ({
  isLoadingVehiculeAction: getIsLoadingVehiculeAction(state),
  success: getSuccess(state),

  errorMessage: getErrorMessage(state),
  errorMessageConfig: getErrorMessageConfig(state),

  keys: getKeys(state),
  isLoadingKeysList: getKeysListIsLoading(state),
  keysCount: getKeysCount(state),

  successConfig: getConfigSuccess(state),
  keyCreatedObject: getKeyCreatedObject(state),
})

const mapDisptachToProps = (dispatch) => ({
  createVehicule: (vehicule) => dispatch(createVehicule(vehicule)),
  updateVehicule: (newVehicule) => dispatch(updateVehicule(newVehicule)),

  fetchKeys: () => {
    dispatch(onSearchCleRessources(''))
    dispatch(fetchKeys())
  },
  searchKeys: (search) => {
    dispatch(onSearchCleRessources(search))
    dispatch(pageChanged(0))
    dispatch(fetchKeys())
  },
  fetchMoreKeys: (offset, search) => {
    dispatch(onSearchCleRessources(search))
    dispatch(pageChanged(offset))
    dispatch(getKeysMore())
  },
  resetKey: () => dispatch(resetConfig()),
})

export default connect(mapStateToProps, mapDisptachToProps)(VehiculeModal)
