import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import { TextField } from '@mui/material'
import CreateIcon from '../../../../assets/icons/settingsCorner/iconsCreate'
import EditIcon from '../../../../assets/icons/settingsCorner/iconEdit'
import { VehicleType } from '../../../../utils/values'
import {
  StyledDivContent,
  StyledDivTopInputs,
  StyledInputModal,
  StyledInputTitle,
  sxInput,
} from '../../styled'
import { MenuItem } from '@mui/material'
import { useFormik } from 'formik'
import AutoComplete from '../../../../components/AutoComplete'
import './styleModal.scss'
// which keys are symmetrical to our values/initialValues
const validate = ({ name, immatriculation, weight, volume, type }) => {
  const errors = {}

  if (name == '') {
    errors.name = 'Nom est obligatoire'
  }

  if (immatriculation == '') {
    errors.immatriculation = 'Immatriculation est obligatoire'
  }

  if (type == 'null') {
    errors.type = 'Le type de véhicule est obligatoire'
  }

  if (String(weight) == '') {
    errors.weight = 'Poids est obligatoire'
  }

  if (String(volume) == '') {
    errors.volume = 'Volume est obligatoire'
  }

  return errors
}

function VehicleModal({
  open,
  isEdit,
  handleClose,
  modalTitle,
  createVehicule,
  updateVehicule,
  isLoadingVehiculeAction,
  success,
  errorMessage,
  row,
  keys,
  fetchKeys,
  searchKeys,
  isLoadingKeysList,
  fetchMoreKeys,
  keysCount,
  resetKey,
  errorMessageConfig,
  successConfig,
  keyCreatedObject,
}) {
  const [select, setSelect] = useState(false)
  const [init, setInit] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [offset, setOffset] = useState(25)

  const formik = useFormik({
    initialValues: {
      //TEST with Mohamed REMPLACE LEGACY_ID
      name: '',
      immatriculation: '',
      emisson: '',
      price: '',
      type: 'null',
      competences: [],
      weight: '',
      volume: '',
    },
    validate,
    onSubmit: (values) => {
      if (isEdit) {
        updateVehicule({
          ...values,
          volume: parseFloat(values.volume) || 0,
          weight: parseFloat(values.weight) || 0,
          emisson: parseFloat(values.emisson) || 0,
          price: parseFloat(values.price) || 0,
          id: row.id,
        })
      } else {
        const valuesParse = {
          ...values,
          volume: parseFloat(values.volume) || 0,
          weight: parseFloat(values.weight) || 0,
          emisson: parseFloat(values.emisson) || 0,
          price: parseFloat(values.price) || 0,
        }
        createVehicule({ ...valuesParse })
      }
    },
  })

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        ...formik.values,
        name: row.name,
        immatriculation: row.immatriculation,
        emisson: row.emisson,
        price: row.price,
        type: row.type,
        competences: row.competences,
        weight: row.weight,
        volume: row.volume,
      })
    }
  }, [isEdit])

  useEffect(() => {
    if (success) {
      handleClose()
    }
  }, [success])

  useEffect(() => {
    if (successConfig && keyCreatedObject) {
      formik.setFieldValue('competences', [
        ...formik.values.competences,
        keyCreatedObject,
      ])
      setSearchKey('')
    }
  }, [successConfig])

  const verifEdit = () => {
    return (
      isEdit &&
      formik.values.name == row.name &&
      formik.values.immatriculation == row.immatriculation &&
      formik.values.emisson == row.emisson &&
      formik.values.price == row.price &&
      formik.values.type == row.type &&
      formik.values.competences == row.competences &&
      formik.values.weight == row.weight &&
      formik.values.volume == row.volume
    )
  }
  useEffect(() => {
    fetchKeys()
    setInit(true)
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', resetKey)

    return () => {
      resetKey()
      window.removeEventListener('beforeunload', resetKey)
    }
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchKey.length > 0 && !select) {
        searchKeys(searchKey)
        setOffset(25)
      } else if (searchKey === '') {
        if (init) {
          searchKeys('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchKey])

  const onSelectKey = (value) => {
    if (value) {
      formik.setFieldValue('competences', value)
      setSelect(true)
    }
  }

  const onChangeSearchKey = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchKey(val)
    } else {
      setSearchKey('')
      setSelect(false)
    }
  }

  const loadMoreKeys = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      keysCount >= offset
    ) {
      setOffset(offset + 25)
      fetchMoreKeys(offset, searchKey)
    }
  }
  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Dialog
          maxWidthDialog={'md'}
          open={open}
          title={modalTitle}
          iconTitle={isEdit ? <EditIcon /> : <CreateIcon />}
          style={{ display: (errorMessage || errorMessageConfig) && 'none' }}
          content={
            <StyledDivContent>
              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Nom / Modéle *</StyledInputTitle>
                  <TextField
                    placeholder="nom / modéle"
                    id="Vehicule-name"
                    name="name"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.name}
                    error={formik.errors.name && formik.touched.name}
                    helperText={
                      formik.errors.name && formik.touched.name
                        ? formik.errors.name
                        : null
                    }
                  />
                </StyledInputModal>
                <StyledInputModal>
                  <StyledInputTitle>Immatriculation * </StyledInputTitle>
                  <TextField
                    id="Vehicule-immatriculation"
                    name="immatriculation"
                    variant="outlined"
                    placeholder="immatriculation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.immatriculation}
                    error={
                      formik.errors.immatriculation && formik.touched.immatriculation
                    }
                    helperText={
                      formik.errors.immatriculation && formik.touched.immatriculation
                        ? formik.errors.immatriculation
                        : null
                    }
                  />
                </StyledInputModal>
              </StyledDivTopInputs>
              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Types de vehicules * </StyledInputTitle>
                  <TextField
                    id="Vehicule-type"
                    name="type"
                    variant="outlined"
                    placeholder="types de vehicules"
                    onChange={formik.handleChange}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.type}
                    select
                    error={formik.errors.type && formik.touched.type}
                    helperText={
                      formik.errors.type && formik.touched.type
                        ? formik.errors.type
                        : null
                    }
                  >
                    <MenuItem value="null" disabled>
                      Types de vehicules
                    </MenuItem>
                    {VehicleType.map((key, index) => (
                      <MenuItem value={key.code} key={index}>
                        {key.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </StyledInputModal>
                <StyledInputModal>
                  <StyledInputTitle>Emisson co2 (g/km)*</StyledInputTitle>
                  <TextField
                    placeholder="Emisson co2 "
                    id="Vehicule-poids"
                    name="emisson"
                    variant="outlined"
                    onChange={(event) => {
                      const inputValue = event.target.value
                      if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
                        formik.setFieldValue('emisson', inputValue)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.emisson}
                  />
                </StyledInputModal>
              </StyledDivTopInputs>
              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Poids (Kg)*</StyledInputTitle>
                  <TextField
                    placeholder="poids"
                    id="Vehicule-poids"
                    name="weight"
                    variant="outlined"
                    onChange={(event) => {
                      const inputValue = event.target.value
                      if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
                        formik.setFieldValue('weight', inputValue)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.weight}
                    error={formik.errors.weight && formik.touched.weight}
                    helperText={
                      formik.errors.weight && formik.touched.weight
                        ? formik.errors.weight
                        : null
                    }
                  />
                </StyledInputModal>
                <StyledInputModal>
                  <StyledInputTitle>Coût</StyledInputTitle>
                  <TextField
                    placeholder="coût"
                    id="Vehicule-cout"
                    name="price"
                    variant="outlined"
                    onChange={(event) => {
                      const inputValue = event.target.value
                      if (/^\d*\.?\d*$/.test(inputValue) || inputValue === '') {
                        formik.setFieldValue('price', inputValue)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.price}
                    error={formik.errors.price && formik.touched.price}
                    helperText={
                      formik.errors.price && formik.touched.price
                        ? formik.errors.price
                        : null
                    }
                  />
                </StyledInputModal>
              </StyledDivTopInputs>
              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>
                    Volume (m<sup>3</sup>) *
                  </StyledInputTitle>
                  <TextField
                    placeholder="volume"
                    id="Vehicule-volume"
                    name="volume"
                    variant="outlined"
                    onChange={(event) => {
                      const inputValue = event.target.value

                      if (/^\d*\.?\d*$/.test(inputValue) || inputValue == '') {
                        formik.setFieldValue('volume', inputValue)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.volume}
                    error={formik.errors.volume && formik.touched.volume}
                    helperText={
                      formik.errors.volume && formik.touched.volume
                        ? formik.errors.volume
                        : null
                    }
                  />
                </StyledInputModal>
              </StyledDivTopInputs>

              <StyledInputTitle>Clés associés</StyledInputTitle>
              <div style={{ display: 'flex' }}>
                <AutoComplete
                  freeSolo
                  value={formik.values?.competences}
                  name={'competences'}
                  onChange={(event, value) => {
                    onSelectKey(value)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onInputChange={(event, value) => onChangeSearchKey(value)}
                  id="Vehicule-Competances"
                  options={keys}
                  getOptionLabel={(option) => option.value}
                  variant="outlined"
                  placeholder={'Choisir une option'}
                  sxInputStyle={sxInput}
                  multiple={true}
                  competance={true}
                  error={formik.errors.competences && formik.touched.competences}
                  helperText={
                    formik.errors.competences && formik.touched.competences
                      ? formik.errors.competences
                      : null
                  }
                  fullWidth={true}
                  ListboxProps={{
                    onScroll: loadMoreKeys,
                  }}
                  renderOption={(props, option) => (
                    <li {...props} id={`Compétences-${option.id}`} key={option.id}>
                      <div className="AutoCompteFilterOption">{option.value}</div>
                    </li>
                  )}
                  loading={isLoadingKeysList}
                  inputValue={searchKey}
                />
              </div>
            </StyledDivContent>
          }
          handleClose={handleClose}
          handleClickAction={formik.handleSubmit}
          disabled={verifEdit()}
          isLoadingButtonAction={isLoadingVehiculeAction}
        />{' '}
      </form>
    </>
  )
}
VehicleModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  keys: PropTypes.array,
  createVehicule: PropTypes.func,
  errorMessage: PropTypes.string,
  isEdit: PropTypes.bool,
  errorActionCourse: PropTypes.string,
  modalTitle: PropTypes.string,
  isLoadingVehiculeAction: PropTypes.bool,
  success: PropTypes.bool,
  updateVehicule: PropTypes.func,
  row: PropTypes.object,
  fetchKeys: PropTypes.func,
  isLoadingKeysList: PropTypes.bool,
  searchKeys: PropTypes.func,
  fetchMoreKeys: PropTypes.func,
  keysCount: PropTypes.number,
  resetKey: PropTypes.func,
  errorMessageConfig: PropTypes.string,
  successConfig: PropTypes.bool,
  keyCreatedObject: PropTypes.object,
}

export default VehicleModal
