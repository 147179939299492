import { connect } from 'react-redux'
import CourseFiles from '../../../components/FilesCourse/newCommande/CourseDialog'
import { downloadFiles } from '../../../redux/shipment/actions'
import { getIsLoadingFiles } from '../../../redux/shipment/selectors'

const mapStateToProps = (state) => ({
  isLoadingFiles: getIsLoadingFiles(state),
})
const mapDisptachToProps = (dispatch) => ({
  downloadFiles: (fileDetail) => dispatch(downloadFiles(fileDetail)),
})

export default connect(mapStateToProps, mapDisptachToProps)(CourseFiles)
