import React from 'react'
import ListItem from '@mui/material/ListItem'
import Avatar from '@mui/material/Avatar'

import PropTypes from 'prop-types'
import moment from 'moment'
import { EVENT_SOCKET } from '../../../../../utils/discussion/values'
import ImageFile from '../../../../../assets/images/file.png'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'

function NewlineText(props) {
  const text = props.text
  return text.split('\n').map((str, i) => (
    <p
      style={{
        display: 'block',
        boxSizing: 'border-box',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        whitespace: 'break-spaces',
        textOverflow: 'unset',
        textAlign: 'left',
        fontSize: '14px',
      }}
      key={i}
    >
      {str}
    </p>
  ))
}
const styles = {
  listItem: (isOwnMessage) => ({
    flexDirection: isOwnMessage ? 'row-reverse' : 'row',
    width: '100%',
  }),
  container: (isOwnMessage) => ({
    maxWidth: '100%',
    fontSize: 12,
    backgroundColor: isOwnMessage ? '#e7f2ff' : '#f4effc',
    overflowWrap: 'break-word',
    borderRadius: isOwnMessage ? '10px 0 10px 10px' : '0 10px 10px 10px',
    display: 'grid',
    margin: '2px 10px 20px',
    padding: '10px',
    paddingRight: '12px',
  }),
  conversationStatus: (success) => ({
    color: success ? '#26a69a' : '#9e9e9e',
  }),
  author: (meTest) => ({
    textAlign: meTest ? 'right' : 'left',
    paddingRight: '12px',
    paddingLeft: '12px',
    fontSize: '11px',
    color: 'grey',
  }),
}

export default function ChatItem({
  message,
  DerMessages,
  connectedUser,
  varFocus,
  conversationOpen,
  entryConversations,
  seenMessage,
  downloadFile,
}) {
  let conversation = entryConversations.filter(
    (conv) => conv.id == conversationOpen.id
  )

  if (
    connectedUser.id.toString() != DerMessages.sender.legacyID &&
    varFocus &&
    conversation[0].notSeen > 0
  ) {
    const entry = {
      type: EVENT_SOCKET.SEEN_EVENT,
      entryId: DerMessages.id,
      conversationID: conversationOpen.id,
    }
    seenMessage(entry, connectedUser)
    conversation[0].notSeen = 0
  }

  const IsImage = (filename) => {
    return filename.match(/.(jpg|jpeg|png|gif)$/i) ? true : false
  }
  return (
    <>
      {message.type === EVENT_SOCKET.CLOSE_EVENT ||
      message.type === EVENT_SOCKET.OPEN_EVENT ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <h1
            style={styles.conversationStatus(
              message.type === EVENT_SOCKET.OPEN_EVENT
            )}
          >
            {message.content}
          </h1>
        </div>
      ) : (
        <ListItem
          style={styles.listItem(
            connectedUser.id.toString() === message.sender.legacyID
          )}
        >
          <Avatar alt="Remy Sharp" src={message.sender.photoIdentity} />
          <div>
            <div
              style={styles.author(
                connectedUser.id.toString() === message.sender.legacyID
              )}
            >
              {message.sender.firstName} {message.sender.name}
            </div>
            <div
              style={styles.container(
                connectedUser.id.toString() === message.sender.legacyID
              )}
            >
              <div
                style={{
                  display: 'flex',
                  boxSizing: 'border-box',
                  flexWrap: 'wrap',
                }}
              >
                {message?.attachements && message?.attachements?.length > 0 && (
                  <>
                    {message.attachements.map((attachement, index) => {
                      return (
                        attachement?.filename && (
                          <div
                            style={{
                              position: 'relative',
                              width: '100px',
                              height: '100px',
                            }}
                            key={index}
                          >
                            {IsImage(attachement.filename) ? (
                              <img
                                src={attachement.location}
                                style={{
                                  objectFit: 'contain',
                                  width: '100px',
                                  height: '100px',
                                  padding: '2%',
                                }}
                              />
                            ) : (
                              <img
                                src={ImageFile}
                                style={{
                                  objectFit: 'contain',
                                  width: '100px',
                                  height: '100px',
                                  padding: '2%',
                                }}
                              />
                            )}
                            <div
                              style={{
                                position: 'absolute',
                                top: '0',
                                opacity: '0',
                                height: '100%',
                                width: '100%',
                                textAlign: 'end',
                                padding: '4px 4px',
                                '&:hover': {
                                  opacity: '1',
                                  backgroundColor:
                                    'hsla(0,0%,97.6%,.4117647058823529)',
                                  transition: '0.5s',
                                },
                              }}
                            >
                              <CloudDownloadOutlinedIcon
                                sx={{
                                  width: '40px',
                                  height: '28px',
                                  cursor: 'pointer',
                                  border: 'none',
                                  opacity: '0.7',
                                  verticalAlign: 'middle',
                                  marginLeft: '4px',
                                  backgroundColor: 'white',
                                  borderRadius: '5px',
                                  '&:hover': {
                                    backgroundColor: '#8e8888',
                                    transition: '0.5s',
                                  },
                                }}
                                onClick={() => {
                                  downloadFile(attachement.location)
                                }}
                                download
                              />
                            </div>
                          </div>
                        )
                      )
                    })}
                  </>
                )}
              </div>
              <NewlineText text={message.content.trim()} />
              <div
                style={{
                  textAlign: 'right',
                  color: 'grey',
                  fontSize: '12px',
                }}
              >
                {moment(message.createdAt).format('Do MMMM YYYY, h:mm:ss a')}
              </div>
            </div>
          </div>
        </ListItem>
      )}
    </>
  )
}

ChatItem.propTypes = {
  message: PropTypes.object,
  connectedUser: PropTypes.object,
  DerMessages: PropTypes.object,
  varFocus: PropTypes.bool,
  conversationOpen: PropTypes.string,
  entryConversations: PropTypes.array,
  notFocus: PropTypes.func,
  seenMessage: PropTypes.func,
  downloadFile: PropTypes.func,
}
