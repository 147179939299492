import { connect } from 'react-redux'
import PhotoLivraison from '../../../pages/main/Orders/DetailsDelivery/components/details/photos'

import {
  downloadImageOrder,
  onUploadPhoto,
  setErrorFileUpload,
  getOrderPhotoByLdv,
} from '../../../redux/order/actions'
import {
  getLoadingUploadPhoto,
  getErrorUploadFile,
  getOrderPhoto,
  getLodingOrderPhoto,
  getActionOrderOrderPhotoDone,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingUploadPhoto: getLoadingUploadPhoto(state),
  errorFileUpload: getErrorUploadFile(state),
  orderPhoto: getOrderPhoto(state),
  isLoadingOrderPhoto: getLodingOrderPhoto(state),
  isActionOrderPhotoDone: getActionOrderOrderPhotoDone(state),
})

const mapDisptachToProps = (dispatch) => ({
  downloadImage: (codeCourse) => dispatch(downloadImageOrder(codeCourse)),
  onUploadPhotos: (file, codeCourse) => dispatch(onUploadPhoto(file, codeCourse)),
  setErrorFileUpload: (isError) => dispatch(setErrorFileUpload(isError)),
  getOrderPhotoByLdv: (ldv) => dispatch(getOrderPhotoByLdv(ldv)),
})

export default connect(mapStateToProps, mapDisptachToProps)(PhotoLivraison)
