import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import SettingsIcon from '@mui/icons-material/Settings'
import MenuItem from '@mui/material/MenuItem'

export const StyledFilterCell = styled(TableCell)({
  backgroundColor: '#F7F8F9',
  color: '#2A304C',
  position: 'sticky',
  top: '0',
  right: '0',
  left: 'auto',
  boxShadow: '-5px 0px 5px -3px #ccc',
})
export const StyledFilterSettingsIcon = styled(SettingsIcon)({
  cursor: 'pointer',
  backgroundColor: '#F7F8F9',
  color: '#2A304C',
  fontSize: 28,
})
export const StyledFilterMenuItem = styled(MenuItem)({
  width: 200,
  display: 'flex',
  justifyContent: 'space-between',
})
