import { httpConstants } from './constants'
export const HTTP_FETCH = 'HTTP_FETCH'
export const HTTP_POST = 'HTTP_POST'
export const HTTP_POST_CALLBACK_DATA = 'HTTP_POST_CALLBACK_DATA'
export const HTTP_PATCH = 'HTTP_PATCH'
export const HTTP_PUT = 'HTTP_PUT'
export const httpPost = ({
  endPoint,
  data,
  headers,
  success,
  failure,
  cancelToken,
}) => ({
  type: HTTP_POST,
  payload: { endPoint, data, headers, success, failure, cancelToken },
})
export const httpPostCallbackData = ({
  endPoint,
  data,
  headers,
  success,
  failure,
  secData,
  cancelToken,
}) => ({
  type: HTTP_POST_CALLBACK_DATA,
  payload: { endPoint, data, headers, success, failure, secData, cancelToken },
})

export const httpSuccess = (type, data) => ({
  type,
  payload: { data },
})

export const httpError = (type, data) => ({
  type,
  payload: { data },
})

export const httpFetch = ({ endPoint, success, failure, cancelToken }) => ({
  type: HTTP_FETCH,
  payload: { endPoint, success, failure, cancelToken },
})

export const httpGet = ({ endPoint, success, failure, cancelToken }) => ({
  type: httpConstants.GET_REQUEST,
  payload: { endPoint, success, failure, cancelToken },
})

export const httpDelete = ({ endPoint, success, failure, cancelToken }) => ({
  type: httpConstants.DELETE_REQUEST,
  payload: { endPoint, success, failure, cancelToken },
})

export const httpPatch = ({
  endPoint,
  data,
  headers,
  success,
  failure,
  cancelToken,
}) => ({
  type: HTTP_PATCH,
  payload: { endPoint, data, headers, success, failure, cancelToken },
})
export const httpPut = ({
  endPoint,
  data,
  headers,
  success,
  failure,
  cancelToken,
}) => ({
  type: HTTP_PUT,
  payload: { endPoint, data, headers, success, failure, cancelToken },
})
