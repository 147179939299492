import moment from 'moment'
import { getfullNameMaj } from './values'

export const RDV_IMPO = [
  { code: 'ACE', label: 'PROBLEME ACCES' },
  { code: 'AMR', label: 'ATTENTE CONNEXION NOUVEAU COPIEUR' },
  { code: 'ATC', label: 'ATTENTE CHARGEMENT COURSE' },
  {
    code: 'ATT',
    label: 'EN ATTENTE DES INSTRUCTIONS A FAIRE, 3 APPELS NON ABOUTIS',
  },
  { code: 'CTC', label: 'MESSAGE POUR PRISE CONTACT OU RELANCE CLIENT' },
  { code: 'DAF', label: 'DESTINATAIRE ABSENT, FERME, CONGES/ A RECONTACTER' },
  { code: 'DES', label: 'OPERATION REPORTEE PAR LE CLIENT DESTINATAIRE' },
  { code: 'DOC', label: 'DOCUMENT MANQUANT' },
  {
    code: 'ERR',
    label:
      'CONTACT OU TEL INCORRECT OU MANQUANT, EN ATTENTE DES INSTRUCTIONS A FAIRE',
  },
  {
    code: 'EXP',
    label:
      "OPERATION REPORTEE PAR CLIENT DONNEUR D'ORDRE, EN ATTENTE DES INSTRUCTIONS A FAIRE",
  },
  { code: 'LOC', label: 'LOCAUX NON PRETS' },
  {
    code: 'MAT',
    label: 'MATERIEL A LIVRER NON RECU-EN ATTENTE DES INSTRUCTIONS A FAIRE',
  },
  { code: 'MDE', label: 'MATERIEL DEJA ENLEVE, DES INSTRUCTIONS A FAIRE' },
  { code: 'MSG', label: 'MESSAGE TELEPHONIQUE (INF.3)' },
  {
    code: 'NDI',
    label:
      'MATERIEL A REPRENDRE NON DISPONIBLE, EN ATTENTE DES INSTRUCTIONS A FAIRE',
  },
  { code: 'NON', label: 'CLIENT NE REPOND PAS (INF. 3)' },
  { code: 'NRV', label: 'NOUVEAU RDV A REPRENDRE' },
  { code: 'PRV', label: 'PAS DE RDV A PRENDRE' },
  { code: 'RDV', label: 'RDV EN LIGNE,MESSAGE ENVOYE AU CLIENT DESTINATAIRE' },
  { code: 'REC', label: 'REPRISE IMPOSSIBLE, PRODUIT A LIVRER NON PRET/RECU' },
  { code: 'REL', label: 'RDV EN LIGNE,RELANCE ENVOYEE AU CLIENT DESTINATAIRE' },
  {
    code: 'RFC',
    label: 'REFUS OPERATION À LA PRISE DE RDV TEL, EN ATTENTE INSTRUCTIONS A FAIRE',
  },
  {
    code: 'TEC',
    label: 'ATTENTE PASSAGE TECHNICIEN, EN ATTENTE INSTRUCTIONS A FAIRE',
  },
  {
    code: 'NRA',
    label: 'NOUVEAU RDV A REPRENDRE SUITE A ANOMALIE EXPLOITATION',
  },
]
export const getLabel = (historique) => {
  let label = null
  const foundJustif = RDV_IMPO.filter((elem) => elem.code === historique)
  if (foundJustif.length) {
    label = foundJustif[0].label
  }
  return label
}

export const getActivityHistory = (historiques) => {
  let historiquesActivity = CollectActivityData(historiques)
  const list = historiquesActivity.map(({ createdAt, ...rest }) => {
    const tzDate = moment(createdAt).tz('Europe/Paris', true)
    const theDay = moment(tzDate).startOf('day').format('dddd, D MMMM, YYYY')
    const theHour = moment(tzDate).format('HH:mm')
    return {
      ...rest,
      day: theDay,
      hour: theHour,
    }
  })
  const listFiltered = []
  const dateList = list.map(({ day }) => day)
  const filteredDateList = [...new Set(dateList)]
  filteredDateList.forEach((elem) => {
    listFiltered.push({
      date: elem,
      historyDetails: [],
    })
  })
  listFiltered.forEach((elem) => {
    const details = list
      .filter(({ day }) => elem.date === day)
      .map(({ ...rest }) => rest)
    elem.historyDetails = details
  })
  historiquesActivity = listFiltered
  return historiquesActivity
}
export const collectDeliveryData = (historiques) => {
  // Convertir les dates en objets Moment pour faciliter le tri
  const momentDates = historiques.map((item) => ({
    createdAt: moment(item.createdAt, 'YYYY-MM-DD HH:mm:ss'),
    hour: moment(item.createdAt).format('HH:mm'),
    day: moment(item.createdAt).format('dddd, D MMMM, YYYY'),
    adminJustification: item.motif,
    comment: item.reason,
    client: getfullNameMaj(item?.collaborateur, undefined),
    label: item.label,
  }))

  // Trier les dates par ordre décroissant
  momentDates.sort((a, b) => b.createdAt - a.createdAt)

  // Grouper les dates par jour dans un tableau
  const HistoriqueRDV = momentDates.reduce((acc, curr) => {
    const dateKey = curr.createdAt.format('dddd, D MMMM, YYYY')
    const index = acc.findIndex((item) => item.date === dateKey)
    if (index !== -1) {
      acc[index].historyDetails.push(curr)
    } else {
      acc.push({ date: dateKey, historyDetails: [curr] })
    }
    return acc
  }, [])
  return HistoriqueRDV
}
export const getHistoriqueLivraison = (historiqueOrder) => {
  const list = historiqueOrder.deliveryHistoryDetails.map(({ date, ...rest }) => {
    const tzDate = moment(date).tz('Europe/Paris', true)
    const theDay = moment(tzDate).startOf('day').format('dddd, D MMMM, YYYY')
    const theHour = moment(tzDate).format('HH:mm')
    return {
      ...rest,
      day: theDay,
      hour: theHour,
    }
  })
  const listFiltered = []
  const dateList = list.map(({ day }) => day)
  const filteredDateList = [...new Set(dateList)]
  filteredDateList.forEach((elem) => {
    listFiltered.push({
      date: elem,
      historyDeliveryDetails: [],
    })
  })
  listFiltered.forEach((elem) => {
    const details = list
      .filter(({ day }) => elem.date === day)
      .map(({ ...rest }) => rest)
    elem.historyDeliveryDetails = details
  })
  historiqueOrder = listFiltered
  return historiqueOrder
}
export const getCourseType = (courseType) => {
  let text = 'N/A'
  if (courseType) {
    if (courseType === 'ClientCollect') {
      text = 'Emporté dépôt par le consommateur'
    } else if (courseType === 'ClientDelivery') {
      text = 'Livraison consommateur'
    } else if (courseType === 'StoreDelivery') {
      text = 'Livraison magasin'
    } else if (courseType === 'ClientPickup') {
      text = 'Reprise consommateur'
    } else if (courseType === 'StorePickUp') {
      text = 'Reprise magasin'
    } else if (courseType === 'HubDelivery') {
      text = "Livraison à l'entrepôt"
    }
  }
  return text
}
export const CollectActivityData = (data) => {
  return data
    .filter(
      ({ columnName, newValue, oldValue }) =>
        (columnName === 'courseType' &&
          newValue !== oldValue &&
          newValue !== '' &&
          oldValue !== '') ||
        columnName === 'date_creation'
    )
    .map(
      ({
        columnName,
        collaborateurId,
        newValue,
        oldValue,
        createdAt,
        client,
        changeSource,
      }) => {
        if (columnName == 'date_creation') {
          return {
            isCreated: true,
            createdAt: createdAt,
            newValue: newValue,
            changeSource: changeSource,
            collaborateurId:
              collaborateurId &&
              collaborateurId?.firstname &&
              collaborateurId?.firstname !== ''
                ? collaborateurId?.firstname
                : client && client?.firstname && client?.firstname !== ''
                ? client?.firstname
                : undefined,
          }
        }
        return {
          isCreated: false,
          collaborateurId:
            collaborateurId &&
            collaborateurId?.firstname &&
            collaborateurId?.firstname !== ''
              ? collaborateurId?.firstname
              : client && client?.firstname && client?.firstname !== ''
              ? client?.firstname
              : 'N/A',
          newValue: getCourseType(newValue),
          oldValue: getCourseType(oldValue),
          createdAt: createdAt,
        }
      }
    )
}
