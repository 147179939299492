import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import FilesCourse from '../../../../../containers/main/FilesCourse/commandeEnCours'
import CardHeaderComponent from './CardHeader'
import CardContentComponent from './CardContent'
import CardActionsComponent from './CardActions'

import 'moment/locale/fr'
import './index.scss'
import { ALPHABETIC_DATE_FORMAT, getCreneau } from '../../../../../utils/utils'
import { getDropOffDate } from '../../../../../utils'
import { getfullName } from '../../../../../utils/values'

export default function ShipmentCell({ shipment, index, modale }) {
  const {
    code,
    adresseArrivee,
    adresseDepart,
    canBePrepared,
    commande: { client, collaborateur, canalVente, etatPaiement, moyenPaiement },
    courseMetadata,
    dropOffEnd,
    dropOffStart,
    contactDepart,
    contactArrivee,
    dateDemarrage,
    montantPrestataireHT,
    lettreDeVoiture,
    manutention,
    manutentionDouble,
    status,
    weight,
    volume,
    chauffeur,
    dateDemarrageMeta,
    assemblies,
    factures,
    noteInterne,
    isPrepared,
    courseSource,
    montantHT,
    pickUpEnd,
    pickUpStart,
    deliveryRelated,
    ldvRelatedCourse,
  } = shipment
  const earliestDeliveryDate = courseMetadata?.earliestDeliveryDate
  const latestDeliveryDate = courseMetadata?.latestDeliveryDate
  const courseType = courseMetadata?.courseType
  const salesChannelName = canalVente ? canalVente.name : null
  const configs = canalVente ? canalVente.configs : null

  const contact =
    courseType != null &&
    courseType === 'ClientPickup' &&
    contactDepart &&
    (contactDepart?.firstname != null || contactDepart?.lastname != null)
      ? getfullName(contactDepart, '')
      : (contactArrivee && contactArrivee?.firstname != null) ||
        contactArrivee?.lastname != null
      ? getfullName(contactArrivee, '')
      : (client && client?.firstname != null) || client?.lastname != null
      ? getfullName(client, '')
      : getfullName(collaborateur, '')
  const deliveryDate = getCreneau(
    dropOffStart,
    dropOffEnd,
    dateDemarrageMeta,
    dateDemarrage,
    ALPHABETIC_DATE_FORMAT
  )
  const pickUpDate = getCreneau(
    pickUpStart,
    pickUpEnd,
    null,
    null,
    ALPHABETIC_DATE_FORMAT
  )
  const [show, setModalState] = React.useState(false)

  const OpenDeliveryDetails = () => {
    setModalState(!show)
  }
  const handleModal = () => setModalState(!show)

  return (
    <>
      <Card className="card-container">
        <FilesCourse
          show={show}
          handleModal={handleModal}
          codeCourse={code}
          assemblies={assemblies}
        />
        <CardHeaderComponent
          status={status}
          shipmentReference={lettreDeVoiture?.code}
          handleModal={OpenDeliveryDetails}
          assemblies={assemblies}
          etatPaiement={etatPaiement}
          moyenPaiement={moyenPaiement}
          index={index}
          modale={modale}
        />
        <CardContentComponent
          contact={contact}
          isVip={client ? client.vip : false}
          deliveryDate={deliveryDate}
          montantPrestataireHT={montantPrestataireHT}
          name={salesChannelName}
          adresseDepart={adresseDepart}
          adresseArrivee={adresseArrivee}
          weight={weight}
          volume={volume}
          manutention={manutention}
          manutentionDouble={manutentionDouble}
          dropOffDate={getDropOffDate(dateDemarrageMeta, dropOffStart, dropOffEnd)}
          earliestDeliveryDate={earliestDeliveryDate}
          latestDeliveryDate={latestDeliveryDate}
          driver={chauffeur}
          salesChannel={salesChannelName}
          montantHT={montantHT}
          factures={factures}
          pickUpDate={pickUpDate}
          courseType={courseType}
          index={index}
        />
        <CardActionsComponent
          deliveryRelated={deliveryRelated}
          chauffeur={chauffeur}
          configs={configs}
          canBePrepared={canBePrepared}
          note={noteInterne}
          shipmentReference={lettreDeVoiture?.code}
          codeCourse={code}
          status={status}
          isPrepared={isPrepared === true}
          courseSource={courseSource}
          isDetailsLivraison={false}
          index={index}
          modale={modale}
          ldvRelatedCourse={ldvRelatedCourse}
        />
      </Card>
    </>
  )
}
ShipmentCell.propTypes = {
  shipment: PropTypes.object.isRequired,
  index: PropTypes.number,
  modale: PropTypes.bool,
}
