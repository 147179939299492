import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Checkbox from '@mui/material/Checkbox'

import 'react-phone-input-2/lib/material.css'
import PropTypes from 'prop-types'
import { Place } from '../../../../../models/place'
import PhoneInput from 'react-phone-input-2'
import AddressAutoComplete from '../../../../../containers/main/AddressAutoComplete'
import FormHelperTextError from '../../../../../components/FormHelperTextError'
import useForm from '../../../../../utils/useForm'
import { clientFormData } from './formClient'
import { clientValidators } from './validators'
import { validateClient } from '../../../../../utils/validator/clientValidator'
import './index.scss'
import { ROLE } from '../../../../../constants/appContants'
import { getfullName, getPhoneInputCountries } from '../../../../../utils/values'
import Switch from '../../../../../components/switch/SwitchComponent'

export default function OldClientForm({
  selectedClient,
  newClient,
  updateClient,
  isSubmited,
  setClientErrors,
}) {
  const clientData = !_.isNil(_.get(selectedClient, 'id'))
    ? _.get(selectedClient)
    : {}

  // const [validForm, setValidForm] = useState(false)
  const [originDefaultAddress, setOriginDefaultAddress] = useState(null)

  const submitRef = useRef()

  const submitForm = (e) => {
    console.log('client form submited:', e)
  }

  const [
    client,
    errors,
    isSubmitted,
    handleClientChange,
    handleClientElChange,
    handleClientSubmit,
    isClientValid,
    isClientInvalid,
    resetForm,
    handleClientSwitchChange,
  ] = useForm(
    clientFormData(clientData),
    submitForm,
    clientValidators,
    validateClient
  )
  useEffect(() => {
    const changeClientTimeOut = setTimeout(() => {
      updateClient(client)
    }, 100)
    return () => clearTimeout(changeClientTimeOut)
  }, [client])

  useEffect(() => {
    const changesErrorsTimeOut = setTimeout(() => {
      setClientErrors(errors)
    }, 100)
    return () => clearTimeout(changesErrorsTimeOut)
  }, [errors])

  useEffect(() => {
    if (isSubmited) {
      submitRef.current.click()
    }
  }, [isSubmited])

  /** Generate generic mail when no email is selected */
  useEffect(() => {
    if (client.noEmail && client.phone) {
      const mail =
        client.phone[0] === '+'
          ? client.phone + '@noemail.com'
          : '+' + client.phone + '@noemail.com'
      handleClientElChange('mail', mail)
    } else {
      handleClientElChange('mail', '')
    }
  }, [client.noEmail])

  useEffect(() => {
    if (client.mail && client.mail[0] === '+') {
      handleClientElChange('noEmail', true)
      handleClientElChange('nonEnvoiMail', true)
    }
  }, [client.mail])

  /** reset form when a client is selected from autosearch */
  useEffect(() => {
    if (selectedClient) {
      const newClient = {
        ...client,
        firstname: selectedClient.firstname,
        lastname: selectedClient.lastname,
        phone: selectedClient.phone,
        mail: selectedClient.mail,
        vip: selectedClient.vip ? selectedClient.vip : false,
        name: selectedClient.societe ? selectedClient.societe.name : '',
        type: selectedClient.societe ? ROLE.PROFESSIONEL : ROLE.PARTICULIER,
        address: selectedClient.societe ? selectedClient.societe.address : '',
        addressQuerry: selectedClient.societe ? selectedClient.societe.address : '',
        selectedAddress: new Place().getFromClientPro(selectedClient.societe),
      }
      resetForm(newClient)
      setOriginDefaultAddress(new Place().getFromClientPro(selectedClient.societe))
    }
  }, [selectedClient])

  return (
    <>
      {selectedClient ? (
        <div className="client-form-container">
          {!newClient && (
            <p className="form-title">
              {' '}
              Information du client: {getfullName(client, 'N/A')} / {client.phone}
            </p>
          )}
          <div className="client-type-section">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="clientType"
                name="type"
                value={client.type}
                onChange={handleClientChange}
              >
                <FormControlLabel
                  value="Particulier"
                  control={<Radio />}
                  label="Particulier"
                />
                <FormControlLabel
                  value="Professionnel"
                  control={<Radio />}
                  label="Professionnel"
                />
              </RadioGroup>
            </FormControl>
            <span>
              <FormControl component="fieldset" className="switcher-container">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        name="vip"
                        value={client.vip || false}
                        defaultChecked={client.vip || false}
                        onChange={handleClientSwitchChange}
                      />
                    }
                    label={'VIP'}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>{' '}
            </span>
          </div>
          <div className="client-informations">
            <form
              className={[
                'form-validation place-shipment-form',
                isSubmitted ? 'was-submitted' : '',
                isClientInvalid ? 'is-invalid' : '',
                isClientValid ? 'is-valid' : '',
              ].join(' ')}
              noValidate
              autoComplete="off"
              onSubmit={($event) => handleClientSubmit($event)}
            >
              <div className="sections">
                <TextField
                  id="firstname"
                  name="firstname"
                  label="Prénom"
                  variant="outlined"
                  error={isSubmited > 0 && !_.isNil(_.get(errors, 'firstname'))}
                  helperText={
                    isSubmited > 0 && !_.isNil(_.get(errors, 'firstname'))
                      ? _.get(errors, 'firstname.required')
                      : ''
                  }
                  value={client.firstname}
                  onChange={handleClientChange}
                  required
                />
                <TextField
                  id="lastname"
                  name="lastname"
                  label="Nom"
                  variant="outlined"
                  error={isSubmited > 0 && !_.isNil(_.get(errors, 'lastname'))}
                  helperText={
                    isSubmited > 0 && !_.isNil(_.get(errors, 'lastname'))
                      ? _.get(errors, 'lastname.required')
                      : ''
                  }
                  value={client.lastname}
                  onChange={handleClientChange}
                  required
                />
              </div>
              <div className="sections">
                {client.type === ROLE.PROFESSIONEL && (
                  <div className="pro-section">
                    <TextField
                      id="nomSociete"
                      name="name"
                      label="Nom Societe"
                      variant="outlined"
                      error={isSubmited > 0 && !_.isNil(_.get(errors, 'name'))}
                      helperText={
                        isSubmited > 0 && !_.isNil(_.get(errors, 'name'))
                          ? _.get(errors, 'name.required')
                          : ''
                      }
                      value={client.name}
                      onChange={handleClientChange}
                      required
                      className="company-name"
                    />
                    <div className="pro-address">
                      <AddressAutoComplete
                        selectedDefaultAddress={originDefaultAddress}
                        handleOnAddressSelect={($event) => {
                          handleClientElChange('address', $event)
                          handleClientElChange('selectedAddress', $event)
                        }}
                        handleQueryChange={(val) =>
                          handleClientElChange('addressQuerry', val)
                        }
                        label="Adresse de societe"
                        hasError={
                          isSubmited > 0 &&
                          (!_.isNil(_.get(errors, 'addressQuerry.required')) ||
                            !_.isNil(_.get(errors, 'selectedAddress.pattern')))
                        }
                        errorText={
                          isSubmited > 0 &&
                          (_.get(errors, 'addressQuerry.required') ||
                            _.get(errors, 'selectedAddress.pattern'))
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="client-type-section">
                <FormControl component="fieldset">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          name="noEmail"
                          value={client.noEmail || false}
                          defaultChecked={client.noEmail || false}
                          onChange={handleClientSwitchChange}
                        />
                      }
                      label={"Pas d'adresse mail"}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div className="sections phone-section">
                <TextField
                  id="mail"
                  name="mail"
                  label="E-mail"
                  variant="outlined"
                  error={
                    isSubmited > 0 &&
                    !client.noEmail &&
                    !_.isNil(_.get(errors, 'mail'))
                  }
                  helperText={
                    isSubmited > 0 &&
                    !client.noEmail &&
                    !_.isNil(_.get(errors, 'mail'))
                      ? !_.isNil(_.get(errors, 'mail.required'))
                        ? _.get(errors, 'mail.required')
                        : _.get(errors, 'mail.pattern', null)
                      : ''
                  }
                  disabled={client.noEmail}
                  value={!client.noEmail ? client.mail : ''}
                  onChange={handleClientChange}
                  required
                />
                <div className="phone">
                  <PhoneInput
                    id="phone"
                    onChange={(newValue, country) => {
                      handleClientElChange('phoneCheck', {
                        phone: newValue,
                        countryCode: country.countryCode,
                        dialcode: country.dialCode,
                      })
                      handleClientElChange('phone', '+' + newValue)
                      if (client.noEmail)
                        handleClientElChange('mail', '+' + newValue + '@noemail.com')
                    }}
                    value={client.phone}
                    onlyCountries={getPhoneInputCountries()}
                    country={'fr'}
                    specialLabel={'Phone *'}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      country: 'fr',
                    }}
                    isValid={() => {
                      return isSubmited > 0 && !_.isNil(_.get(errors, 'phone'))
                        ? !_.isNil(_.get(errors, 'phone.required'))
                          ? _.get(errors, 'phone.required')
                          : _.get(errors, 'phone.pattern', null)
                        : true
                    }}
                  />
                </div>
              </div>
              <div className="switchers-section">
                <FormControl component="fieldset" className="switcher-container">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleClientSwitchChange}
                          value={client.envoiMail || false}
                          checked={client.envoiMail || false}
                          name="envoiMail"
                          disabled={client.noEmail}
                        />
                      }
                      label={'Notifier le destinataire par SMS/mail'}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <input ref={submitRef} type="submit" className="hidden"></input>
            </form>
          </div>
        </div>
      ) : (
        <div className="client-informations">
          <form
            className={[
              'form-validation place-shipment-form',
              isSubmitted ? 'was-submitted' : '',
              isClientInvalid ? 'is-invalid' : '',
              isClientValid ? 'is-valid' : '',
            ].join(' ')}
            noValidate
            autoComplete="off"
            onSubmit={($event) => handleClientSubmit($event)}
          >
            <input ref={submitRef} type="submit" className="hidden"></input>
          </form>
          <div>
            {selectedClient === '' && isSubmited > 0 && (
              <FormHelperTextError content={'Un client doit etre sélectionné'} />
            )}
          </div>
        </div>
      )}
    </>
  )
}
OldClientForm.propTypes = {
  selectedClient: PropTypes.any,
  newClient: PropTypes.bool,
  setValidation: PropTypes.func,
  familiesArticleList: PropTypes.array,
  updateClient: PropTypes.func.isRequired,
  isSubmited: PropTypes.any.isRequired,
  setClientErrors: PropTypes.func.isRequired,
}
