import { connect } from 'react-redux'
import NavFilter from '../../../components/NavFilter'

import { getDisplayGrid } from '../../../redux/filter/selectors'
import { getIsLoadingRefresh } from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  displayGrid: getDisplayGrid(state),
  isLoadingRefresh: getIsLoadingRefresh(state),
})

export default connect(mapStateToProps, null)(NavFilter)
