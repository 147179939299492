import {
  GET_ENTRY_CONVERSATION_LIST,
  GET_ENTRY_CONVERSATION_LIST_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_FAILURE,
  GET_ENTRY_CONVERSATION_LIST_URL,
  GET_NOTIFICATION_CONVERSATION_LIST,
  GET_NOTIFICATION_CONVERSATION_LIST_SUCESS,
  GET_NOTIFICATION_CONVERSATION_LIST_FAILURE,
  GET_ENTRY_CONVERSATION_LIST_BY_SEARCH,
  GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_FAILURE,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_FAILURE,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_FAILURE,
  GET_ENTRY_CONVERSATION_LIST_MORE,
  GET_ENTRY_CONVERSATION_LIST_MORE_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_MORE_FAILURE,
  GET_SUBJECT_CONVERSATION,
  GET_SUBJECT_CONVERSATION_SUCESS,
  GET_SUBJECT_CONVERSATION_FAILURE,
} from './constants'

import { httpPost, httpGet } from '../../http/actions'
import { axiosHTTP } from '../../../utils'
import { CORNER_DISCUSSION } from '../../../utils/values'

import { cleaningMessageList } from '../messages/action'
const api = process.env.REACT_APP_API_URL_CHAT
const apiLegacy = process.env.REACT_APP_API_URL

export const CURRENT_USER = 'currentUser'

let CancelToken = axiosHTTP.CancelToken
let cancel

export default (store) => (next) => (action) => {
  let connectedUser = JSON.parse(localStorage.getItem('currentUser'))
  const getFilterData = () => {
    const {
      filters: { filter, isOpen, ldvs },
    } = store.getState()
    const data = {
      source: CORNER_DISCUSSION,
      isOpened: isOpen,
      warehouses: filter?.warehouse?.length > 0 ? filter.warehouse : undefined,
      salesChannel:
        filter?.codeCanal?.length > 0 && !filter?.codeCanal?.includes('TOUS')
          ? filter.codeCanal
          : undefined,
      max: filter.limit,
      offset: filter.offset,
      replied:
        filter.reply === 'true' ? true : filter.reply == 'false' ? false : undefined,
      subjects: filter?.subject?.length > 0 ? filter.subject : undefined,
      legacyID: `${connectedUser?.id}`,
      serviceTraitant:
        filter?.processingDepartment?.length > 0
          ? filter.processingDepartment
          : undefined,
      ldvs: ldvs ? [ldvs] : undefined,
      direction: filter?.direction ? filter?.direction : undefined,

      nature: filter?.nature?.length > 0 ? filter.nature : undefined,
    }
    return data
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST) {
    store.dispatch(cleaningMessageList())
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    //const data = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}${GET_ENTRY_CONVERSATION_LIST_URL}`,
        data: getFilterData(),
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: GET_ENTRY_CONVERSATION_LIST_SUCESS,
        failure: GET_ENTRY_CONVERSATION_LIST_FAILURE,
      })
    )
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_MORE) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    store.dispatch(
      httpPost({
        endPoint: `${api}${GET_ENTRY_CONVERSATION_LIST_URL}`,
        data: getFilterData(),
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: GET_ENTRY_CONVERSATION_LIST_MORE_SUCESS,
        failure: GET_ENTRY_CONVERSATION_LIST_MORE_FAILURE,
      })
    )
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_BY_SEARCH) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    store.dispatch(
      httpPost({
        endPoint: `${api}${GET_ENTRY_CONVERSATION_LIST_URL}`,
        data: getFilterData(),
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_SUCESS,
        failure: GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_FAILURE,
      })
    )
  }

  if (action.type === GET_NOTIFICATION_CONVERSATION_LIST) {
    const { id } = action.payload
    store.dispatch(
      httpGet({
        endPoint: `${api}Notifications/getNotificationsByUser/${id}`,
        data: action.payload,
        success: GET_NOTIFICATION_CONVERSATION_LIST_SUCESS,
        failure: GET_NOTIFICATION_CONVERSATION_LIST_FAILURE,
      })
    )
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_DELIVERY) {
    const data = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}${GET_ENTRY_CONVERSATION_LIST_URL}`,
        data: data,
        success: GET_ENTRY_CONVERSATION_LIST_DELIVERY_SUCESS,
        failure: GET_ENTRY_CONVERSATION_LIST_DELIVERY_FAILURE,
      })
    )
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE) {
    const data = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}${GET_ENTRY_CONVERSATION_LIST_URL}`,
        data: data,
        success: GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_SUCESS,
        failure: GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_FAILURE,
      })
    )
  }

  if (action.type === GET_SUBJECT_CONVERSATION) {
    store.dispatch(
      httpGet({
        endPoint: `${apiLegacy}subjectConversation`,
        data: action.payload,
        success: GET_SUBJECT_CONVERSATION_SUCESS,
        failure: GET_SUBJECT_CONVERSATION_FAILURE,
      })
    )
  }

  next(action)
}
