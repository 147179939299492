import _ from 'lodash'
import moment from 'moment'
const getAssemblyMinDate = (shipmentDate, shipmentTime, assemblyTimes) => {
  if (!_.isNil(shipmentDate)) {
    if (!_.isNil(shipmentTime) && _.get(assemblyTimes, 'length')) {
      if (assemblyTimes.find((t) => t.start > shipmentTime.start)) {
        return new Date(shipmentDate)
      } else {
        return new Date(moment(new Date(shipmentDate)).add(1, 'day'))
      }
    } else {
      return new Date(shipmentDate)
    }
  } else {
    return null
  }
}
export default getAssemblyMinDate
