import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../ModalDialog'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { CORNER, RDI } from '../../utils/values'

export default function RDVImpossible({
  open,
  handleClose,
  shipmentReference,
  codeCourse,
  rdvImpossible,
  motifsRDVImpo,
  getRDVImpoMOtifs,
  isLoadingMotifsRDVImpo,
  isLoadingActionOrder,
  errorActionCourse,
  codeCanalVente,
  getConfigCanal,
  configCanal,
  isLoadingGetConfig,
  resetConfigCanal,
}) {
  const [value, setValue] = useState('')
  const [comment, setComment] = useState('')

  useEffect(() => {
    getConfigCanal(codeCanalVente, 'customerOnlineAppointment')
    return () => {
      resetConfigCanal()
    }
  }, [])

  useEffect(() => {
    if (configCanal?.length > 0 || configCanal == null) {
      const payload = {
        type: CORNER,
        adminStatus: RDI,
      }
      getRDVImpoMOtifs(payload)
    }
  }, [configCanal])

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const RDVImpossible = () => {
    if (value) {
      rdvImpossible({
        adminstatus: 'RDI',
        adminjustif: value,
        codeCourse,
        comment,
      })
    }
  }

  useEffect(() => {
    if (errorActionCourse) handleClose()
  }, [errorActionCourse])

  return (
    <Dialog
      maxWidthDialog={'sm'}
      open={open}
      title={`Rendez-vous impossible ${shipmentReference}`}
      content={
        <>
          <div
            style={{
              letterSpacing: '0.00938em',
              marginLeft: '8px',
              paddingBottom: '15px',
            }}
          >
            Quelle est la raison?
          </div>

          {isLoadingMotifsRDVImpo || isLoadingGetConfig ? (
            <div
              style={{
                display: 'flex ',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                minWidth: '530px',
                paddingBottom: '16px',
              }}
            >
              <CircularProgress
                color="primary"
                sx={{
                  width: '30px',
                  height: '30px',
                }}
              />
            </div>
          ) : (
            <div
              style={{
                overflowY: 'auto ',
                padding: '2px ',
              }}
            >
              <FormControl component="fieldset" sx={{ p: 'revert' }}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                  id={'Motifs-Group'}
                >
                  {motifsRDVImpo
                    .filter(
                      (motif) =>
                        !(
                          motif.code == 'RDV' &&
                          configCanal &&
                          configCanal?.length > 0 &&
                          configCanal[0]?.infos?.online_appointment == true
                        )
                    )
                    .map((reason, index) => (
                      <FormControlLabel
                        value={reason.code}
                        control={<Radio id={`Motif-${index}`} />}
                        label={`${reason.code}-${reason.motif}`}
                        key={index}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </div>
          )}
          <TextField
            id="Comment-RDV"
            value={comment}
            name="Comment-RDV"
            onChange={(event) => setComment(event.target.value)}
            label="Commentaire "
            variant="outlined"
            multiline
            rows={4}
            sx={{ margin: '16px 0px', paddingRight: '16px' }}
          />
        </>
      }
      handleClose={handleClose}
      handleClickAction={RDVImpossible}
      disabled={
        isLoadingMotifsRDVImpo ||
        isLoadingGetConfig ||
        motifsRDVImpo?.length == 0 ||
        value?.length == 0
      }
      isLoadingButtonAction={isLoadingActionOrder}
    />
  )
}
RDVImpossible.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  rdvImpossible: PropTypes.func,
  reasons: PropTypes.array,
  orderRDVImpossible: PropTypes.object,
  shipmentReference: PropTypes.string,
  codeCourse: PropTypes.string,
  getRDVImpoMOtifs: PropTypes.func,
  motifsRDVImpo: PropTypes.array,
  isLoadingMotifsRDVImpo: PropTypes.bool,
  isLoadingActionOrder: PropTypes.bool,
  errorActionCourse: PropTypes.string,
  codeCanalVente: PropTypes.string,
  getConfigCanal: PropTypes.func,
  configCanal: PropTypes.array,
  isLoadingGetConfig: PropTypes.bool,
  resetConfigCanal: PropTypes.func,
}
