import React, { useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import './index.scss'
import PropTypes from 'prop-types'
import { getfullName } from '../../utils/values'
import _ from 'lodash'
import AutoComplete from '../AutoComplete'

/*
Since we are using this component in filter and in PlaceShipment section of Order shipment,
the api returns array of 'Clients' and array of 'ContactArrivee'
  in filter ==> we will be needing both returned arrays
  in order shipment ==> we will only be needing 'Clients'

So, I have added a boolean 'searchByPhone' to choose what to display
and also added 'selSelectedClient' because in filter we need to returned shipments linked to that client
but in order shipment we will be needing the client itself, so we will recover the selected client and forward it to the parent to be able to display in the form.
 */

export default function ClientsList({
  clients,
  fetchClientWithCriteria,
  disabled,
  searchByPhone,
  setSelectedClient,
  client,
}) {
  const [options, setOptions] = React.useState([])
  const [search, setSearch] = React.useState('')

  const autocompleteClass = {
    '& .MuiAutocomplete-inputRoot': {
      borderBottom: '1px solid #ccc !important',
      width: '96% !important',
      height: '45px !important',
      margin: 'auto !important',
      marginTop: '5px auto !important',
      boxShadow: 'none !important',
      padding: '10 !important',
      borderRadius: '5 !important',
      backgroundColor: 'white !important',
    },
  }
  useEffect(() => {
    setOptions(clients)
  }, [clients, searchByPhone])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 1 && search != '0') {
        fetchClientWithCriteria(search, searchByPhone)
      } else {
        setOptions([])
      }
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChange = (value) => {
    let val = value.trim()
    setSearch(val)
  }
  function isClient(option, value) {
    return (
      option.firstname === value.firstname ||
      option.lastname === value.lastname ||
      option.mail === value.mail ||
      option.phone === value.phone
    )
  }
  function clientLabel(option) {
    return searchByPhone
      ? `${getfullName(option, 'N/A')} ${option.phone}`
      : `${getfullName(option, 'N/A')} ${option.mail}  ${option.phone}`
  }
  return (
    <AutoComplete
      className="custom-auto-complete"
      id="clients-list-auto-complete"
      disabled={disabled}
      classes={autocompleteClass}
      variant="standard"
      isOptionEqualToValue={(option, value) => isClient(option, value)}
      getOptionLabel={(option) => option.label ?? clientLabel(option)}
      onChange={(event, value) => setSelectedClient(value)}
      onInputChange={(event, value) => onChange(value)}
      groupBy={(option) => option.type}
      options={options}
      placeholder={
        searchByPhone ? 'Rechercher un client ' : 'Nom, Prénom, Phone, Email'
      }
      error={client.isSubmitted > 0 && !_.isNil(_.get(client, 'errors.firstname'))}
      helperText={
        client.isSubmitted > 0 && !_.isNil(_.get(client, 'errors.firstname'))
          ? 'Client est obligatoire!'
          : ''
      }
      forcePopupIcon={false}
      startIcon={
        <SearchIcon
          sx={{
            paddingRight: '4px',
            width: '26px !important',
            height: '26px !important',
            color: '#C2C2C2 !important',
          }}
        />
      }
    />
  )
}
ClientsList.propTypes = {
  clients: PropTypes.array,
  fetchClientWithCriteria: PropTypes.func,
  disabled: PropTypes.bool,
  searchByPhone: PropTypes.bool,
  setSelectedClient: PropTypes.func,
  client: PropTypes.object,
}
