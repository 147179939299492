import { connect } from 'react-redux'
import ActivityHistory from '../../../pages/main/Orders/DetailsDelivery/components/details/ActivityHistory'

import {
  getActivityHistoryOrder,
  getIsLoadingActivityHistory,
} from '../../../redux/order/selectors'
import { getActivityHistory } from '../../../redux/order/actions'

const mapStateToProps = (state) => ({
  orderActivityHistory: getActivityHistoryOrder(state),
  isLoadingActivityHistory: getIsLoadingActivityHistory(state),
})
const mapDisptachToProps = (dispatch) => ({
  getActivityHistory: (codeCourse) => dispatch(getActivityHistory(codeCourse)),
})
export default connect(mapStateToProps, mapDisptachToProps)(ActivityHistory)
