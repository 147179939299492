import { connect } from 'react-redux'

import WareHouses from '../../../components/NavFilter/components/Filter/cell/WareHouses'
import {
  getWareHouses,
  getIsWareHouseLoading,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  warehouses: getWareHouses(state),
  isLoading: getIsWareHouseLoading(state),
})

export default connect(mapStateToProps, null)(WareHouses)
