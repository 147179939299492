import { estimatePriceConstants } from './constants'

export const estimateShipmentPriceRequest = ({
  id,
  selectedSalesChannel,
  direction,
  date,
  time,
  purchaseAmount,
  nbItems,
  weight,
  manutention,
  manutentionDouble,
  etage,
  success,
  failure,
}) => ({
  type: estimatePriceConstants.ESTIMATE_SHIPMENT_PRICE_REQUEST,
  payload: {
    id,
    selectedSalesChannel,
    direction,
    date,
    time,
    purchaseAmount,
    nbItems,
    weight,
    manutention,
    manutentionDouble,
    etage,
    success,
    failure,
  },
})

export const estimateShipmentPriceSuccess = (type, id, data) => ({
  type,
  payload: { id, data },
})

export const estimateShipmentPriceError = (type, id, data) => ({
  type,
  payload: { id, data },
})

export const estimateAssemblyPriceRequest = ({
  id,
  selectedSalesChannel,
  direction,
  date,
  time,
  purchaseAmount,
  nbItems,
  weight,
  success,
  failure,
}) => ({
  type: estimatePriceConstants.ESTIMATE_ASSEMBLY_PRICE_REQUEST,
  payload: {
    id,
    selectedSalesChannel,
    direction,
    date,
    time,
    purchaseAmount,
    nbItems,
    weight,
    success,
    failure,
  },
})

export const estimateAssemblyPriceSuccess = (type, id, data) => ({
  type,
  payload: { id, data },
})

export const estimateAssemblyPriceError = (type, id, data) => ({
  type,
  payload: { id, data },
})
