import React from 'react'

import PropTypes from 'prop-types'

import Lightbox from 'react-18-image-lightbox'

export default function Galery({
  lightboxIsopen,
  lightbox,
  onCloseGalery,
  lightboxPhotoIndex,
  setLightboxPhotoIndex,
}) {
  return (
    <>
      {lightboxIsopen && (
        <div style={{ top: '57px' }}>
          <Lightbox
            mainSrc={lightbox?.[lightboxPhotoIndex].url}
            nextSrc={lightbox?.[(lightboxPhotoIndex + 1) % lightbox?.length]}
            prevSrc={
              lightbox?.[
                (lightboxPhotoIndex + lightbox?.length - 1) % lightbox?.length
              ]
            }
            onCloseRequest={onCloseGalery}
            onMovePrevRequest={() => {
              setLightboxPhotoIndex(
                (lightboxPhotoIndex + lightbox?.length - 1) % lightbox?.length
              )
            }}
            onMoveNextRequest={() => {
              setLightboxPhotoIndex((lightboxPhotoIndex + 1) % lightbox?.length)
            }}
          />
        </div>
      )}
    </>
  )
}
Galery.propTypes = {
  lightboxIsopen: PropTypes.bool,
  lightbox: PropTypes.array,
  onCloseGalery: PropTypes.func,
  lightboxPhotoIndex: PropTypes.number,
  setLightboxPhotoIndex: PropTypes.func,
}
