import React, { useState } from 'react'

import Paper from '@mui/material/Paper/Paper'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'

export default function PromoCode({ verifyPromocode, codePromotion }) {
  const [code, setCode] = useState('')
  const onVerifyCode = () => {
    verifyPromocode(code)
  }
  return (
    <div className="promo-code">
      <Paper component="div" className="code-promo">
        <TextField
          id="Code-promo"
          className="code-promo-input"
          value={code}
          style={{ width: '100% !important', border: 'none !important' }}
          onChange={(e) => setCode(e.target.value)}
          error={codePromotion && codePromotion.code > 200}
          variant="standard"
        />
        <Divider className="divider" orientation="vertical" />
        <IconButton
          color="primary"
          className="code-promo-valid"
          aria-label="directions"
          onClick={onVerifyCode}
          disabled={code === ''}
        >
          Valider
        </IconButton>
      </Paper>
      {codePromotion ? (
        <p className={codePromotion.code > 200 ? 'invalid-code' : 'valid-code'}>
          {' '}
          {codePromotion.message}!
        </p>
      ) : (
        <p> </p>
      )}
    </div>
  )
}
PromoCode.propTypes = {
  verifyPromocode: PropTypes.func,
  codePromotion: PropTypes.any,
}
