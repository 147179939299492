import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../../../ModalDialog'
import IconButtonImporter from '../../../../assets/icons/navBar/UiwDownload'

import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'

import Button from '@mui/material/Button'
import IconImportationFile from '../../../../assets/icons/navBar/MingcuteFileImportLine'
import CircularProgress from '@mui/material/CircularProgress'
import { validateUploadFileXLS } from '../../../../utils/validator/UploadFile'

export default function CoursesImportation({
  open,
  handleClose,
  macroCanals,
  isLoadingMacroCanal,
  fetchMacroCanal,
  importerCourses,
  errorImportCourses,
  isActionImportCourses,
  resetActionImportCourses,
  isLoadingActionImporterFile,
}) {
  const [courseSource, setCourseSource] = useState('null')
  const [fileCourse, setFileCourse] = useState(null)
  const [errorUploadFile, setErrorUploadFile] = useState(null)

  useEffect(() => {
    fetchMacroCanal()
  }, [])

  useEffect(() => {
    if (errorImportCourses) {
      handleClose()
    }
  }, [errorImportCourses])

  useEffect(() => {
    if (isActionImportCourses) {
      handleClose()
      resetActionImportCourses()
    }
  }, [isActionImportCourses])

  const onUpload = (event) => {
    const error = validateUploadFileXLS(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
      setFileCourse(null)
    } else {
      setFileCourse(event.target.files[0])
      setErrorUploadFile(null)
    }
    event.target.value = ''
  }

  const onImportCourses = () => {
    const formData = new FormData()
    formData.append('files[]', fileCourse)
    importerCourses(formData, courseSource)
  }

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Importer les courses'}
      iconTitle={
        <IconButtonImporter
          style={{ width: '20px', height: '20px', marginRight: '10px' }}
        />
      }
      content={
        <div
          style={{
            overflow: 'auto',
            width: '600px',
            paddingRight: '16px',
            paddingBottom: '6px',
            '@media (max-width: 680px)': {
              width: 'unset',
            },
          }}
        >
          <div style={{ padding: '15px 11px' }}>
            <div style={{ padding: '10px 0px' }}>
              <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                Course source
              </div>
              <div style={{ display: 'flex' }}>
                <TextField
                  id="Listes-Course-Source"
                  select
                  name="Listes-Course-Source"
                  variant="outlined"
                  onChange={(e) => {
                    setErrorUploadFile(null)
                    setFileCourse(null)
                    setCourseSource(e.target.value)
                  }}
                  value={courseSource}
                  SelectProps={{
                    MenuProps: {
                      style: { height: 288 },
                    },
                  }}
                  disabled={isLoadingMacroCanal || macroCanals.length == 0}
                  sx={isLoadingMacroCanal && { paddingRight: '20px' }}
                >
                  <MenuItem value={'null'} key={0} disabled={true}>
                    {'Sélectionner course source'}
                  </MenuItem>
                  <Divider />
                  {macroCanals.map((h, index) => (
                    <MenuItem
                      value={h.code}
                      key={index + 1}
                      id={`course-source-${index + 1}`}
                    >
                      {h.name}
                    </MenuItem>
                  ))}
                </TextField>
                {isLoadingMacroCanal && (
                  <div
                    style={{
                      display: 'flex',
                      alignSelf: 'center',
                    }}
                  >
                    <CircularProgress
                      sx={{
                        width: '30px !important',
                        height: '30px !important',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div style={{ padding: '10px 0px' }}>
              <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                Télécharger un fichier
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  id="download-file"
                  name="download-file"
                  variant="outlined"
                  endIcon={<IconImportationFile />}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '16.5px 14px',
                    border:
                      courseSource == 'null'
                        ? '1px solid rgb(189 189 189)'
                        : '1px solid rgb(196 196 196)',
                    color: 'rgba(0, 0, 0, 0.87)',
                    '&:hover': {
                      border: '1px solid rgb(0 0 0)',
                      backgroundColor: 'white',
                    },
                    '.MuiButton-endIcon': {
                      marginRight: '-7px',
                      color:
                        courseSource == 'null'
                          ? 'rgba(0, 0, 0, 0.26) '
                          : 'rgba(0, 0, 0, 0.54)',
                      width: '1em',
                      height: '1em',
                      marginTop: '-11px',
                    },
                  }}
                  disabled={courseSource == 'null'}
                >
                  <span style={{ textTransform: 'none' }}>
                    {fileCourse ? fileCourse.name : 'Sélectionner un fichier'}
                  </span>
                </Button>
                <p
                  style={{
                    color: '#d32f2f',
                    fontSize: '0.75rem',
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    margin: '3px 14px 0px',
                    height: '10px',
                  }}
                >
                  {errorUploadFile?.message}
                </p>

                <input
                  id="input-download-file"
                  type="file"
                  style={{
                    width: '91%',
                    cursor: 'pointer',
                    opacity: '0',
                    zIndex: '2',
                    position: 'absolute',
                    height: '55px',
                  }}
                  onChange={onUpload}
                  disabled={courseSource == 'null'}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
            </div>
          </div>
        </div>
      }
      handleClose={handleClose}
      handleClickAction={onImportCourses}
      disabled={courseSource == 'null' || fileCourse == null}
      isLoadingButtonAction={isLoadingActionImporterFile}
    />
  )
}
CoursesImportation.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  fetchMacroCanal: PropTypes.func,
  isLoadingMacroCanal: PropTypes.bool,
  macroCanals: PropTypes.array,
  importerCourses: PropTypes.func,
  errorImportCourses: PropTypes.string,
  isActionImportCourses: PropTypes.bool,
  resetActionImportCourses: PropTypes.func,
  isLoadingActionImporterFile: PropTypes.bool,
}
