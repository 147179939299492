import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import './index.scss'
import TextField from '@mui/material/TextField'
const NoteInterne = ({
  noteInterne,
  handleChange,
  isSubmitted,
  errors,
  isDisabled,
}) => {
  return (
    <div className="note-section">
      <TextField
        id="noteInterne-shipment"
        value={noteInterne}
        defaultValue={noteInterne}
        name="noteInterne"
        onChange={handleChange}
        label="Note Interne (non transmise au client et au livreur)"
        placeholder="Note Interne (non transmise au client et au livreur)"
        variant="outlined"
        disabled={isDisabled}
        multiline
        rows={4}
        error={isSubmitted && !_.isNil(_.get(errors, 'noteInterne'))}
        helperText={
          isSubmitted && !_.isNil(_.get(errors, 'noteInterne'))
            ? !_.isNil(_.get(errors, 'noteInterne.required'))
              ? _.get(errors, 'noteInterne.required')
              : _.get(errors, 'noteInterne.pattern', null)
            : ''
        }
      />
    </div>
  )
}
NoteInterne.propTypes = {
  noteInterne: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isSubmitted: PropTypes.any,
  errors: PropTypes.any,
  isDisabled: PropTypes.bool,
}
export default NoteInterne
