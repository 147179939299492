import AddressAutoComplete from '../../components/AddressAutoComplete'
import { connect } from 'react-redux'
import { getAddressPredictions } from '../../redux/geoCachingAddress/actions'

const mapStateToProps = (state) => ({
  geoCachingAddressPredictions: state.geoCaching.predictions,
})

const mapDisptachToProps = (dispatch) => ({
  getPredictions: (query, country) =>
    dispatch(getAddressPredictions(query, country)),
})

export default connect(mapStateToProps, mapDisptachToProps)(AddressAutoComplete)
