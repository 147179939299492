import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SelectListRecent from '../../../../../ListWithSearch/searchRecentList'
import IconPrestation from '../../../../../../assets/icons/navFilter/BusinessOwner'
export default function BusinessOwnerList({
  disabled,
  filterStateChanged,
  businessOwnerFiltersValues,
  businessOwnerList,
  isLoading,
  onSearch,
}) {
  const [dataList, setDataList] = useState([])

  useEffect(() => {
    let list = businessOwnerList.map(({ email }) => email)
    setDataList(list)
  }, [businessOwnerList])

  const onselectedBusinessOwnerChanged = (selectedBusinessOwner) => {
    filterStateChanged(
      'businessOwner',
      selectedBusinessOwner?.length > 0 ? selectedBusinessOwner : []
    )
  }

  return (
    <SelectListRecent
      disabled={disabled}
      defaultLabel={'Business owner'}
      Icon={IconPrestation}
      id={'BusinessOwner-Filter'}
      filtersValues={businessOwnerFiltersValues}
      onDataChanged={onselectedBusinessOwnerChanged}
      dataList={dataList}
      onSearch={onSearch}
      isLoading={isLoading}
    />
  )
}

BusinessOwnerList.propTypes = {
  disabled: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  businessOwnerFiltersValues: PropTypes.array,
  businessOwnerList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSearch: PropTypes.func,
}
