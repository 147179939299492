import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import { Box, MenuItem, TextField } from '@mui/material'
import AddIcon from '../../../../assets/icons/settingsCorner/addIcon'
import {
  StyledDivContent,
  StyledDivFullWidth,
  StyledDivTopInputs,
  StyledInputModal,
  StyledInputTitle,
  sxInput,
  StyledDivMultipleBlock,
  StyledDivMultipleBlockTextField,
} from '../../styled'
import { useFormik } from 'formik'
import './styleModal.scss'
import AutoComplete from '../../../../components/AutoComplete'
import AddressAutoComplete from '../../../../containers/main/AddressAutoComplete'
import { Place } from '../../../../models/place'
import { DAYS_OF_WEEK_OBJECT } from '../../../../utils/values'

import moment from 'moment'

import DatePickerLocalisation from '../../../../components/DatePickerLocalisation'

// which keys are symmetrical to our values/initialValues
const validate = ({
  driverId,
  vehiculeId,
  name,
  timeWindowStart,
  timeWindowEnd,
  duration,
  breaksTimeWindowStart,
  breaksTimeWindowEnd,
  dayOfWeek,
  repeatTimeWindowStart,
  repeatTimeWindowEnd,
  departureAddress,
  arrivalAddress,
  warehouse,
}) => {
  const errors = {}
  if (!name.trim()) {
    errors.name = 'Véhicule est obligatoire'
  }
  if (!driverId) {
    errors.driverId = 'Chauffeur est obligatoire'
  }

  if (!vehiculeId) {
    errors.vehiculeId = 'Véhicule est obligatoire'
  }

  if (!timeWindowStart) {
    errors.timeWindowStart = 'Horaire de travail est obligatoire'
  }

  if (!timeWindowEnd) {
    errors.timeWindowEnd = 'Horaire de travail est obligatoire'
  }

  if (
    new Date(
      moment(moment().format('YYYY-MM-DD ') + timeWindowStart).format()
    ).getTime() >
      new Date(
        moment(moment().format('YYYY-MM-DD ') + timeWindowEnd).format()
      ).getTime() &&
    timeWindowEnd != '00:00'
  ) {
    errors.timeWindowStart = 'Heure de début doit être inférieure.'
    errors.timeWindowEnd = ' '
  }
  if (String(duration) == '' || Number(duration) < 0) {
    errors.duration = 'Temps de pause est obligatoire'
  }

  if (!breaksTimeWindowStart) {
    errors.timeWindowStart = 'Temps de pause est obligatoire'
  }

  if (!breaksTimeWindowEnd) {
    errors.breaksTimeWindowEnd = 'Temps de pause est obligatoire'
  }

  if (
    new Date(
      moment(moment().format('YYYY-MM-DD ') + breaksTimeWindowStart).format()
    ).getTime() >
      new Date(
        moment(moment().format('YYYY-MM-DD ') + breaksTimeWindowEnd).format()
      ).getTime() &&
    breaksTimeWindowEnd != '00:00'
  ) {
    errors.breaksTimeWindowStart = 'Heure de début doit être inférieure.'
    errors.breaksTimeWindowEnd = ' '
  }

  if (dayOfWeek?.length <= 0) {
    errors.dayOfWeek = 'Jour est obligatoire'
  }

  if (!repeatTimeWindowStart) {
    errors.repeatTimeWindowStart = 'Date de répétition est obligatoire'
  }

  if (!repeatTimeWindowEnd) {
    errors.repeatTimeWindowEnd = 'Date de répétition est obligatoire'
  }

  if (
    new Date(repeatTimeWindowStart).getTime() >
    new Date(repeatTimeWindowEnd).getTime()
  ) {
    errors.repeatTimeWindowStart = 'Date de début doit être inférieure.'
    errors.repeatTimeWindowEnd = ' '
  }

  if (!departureAddress) {
    errors.departureAddress = 'Adresse de départ est obligatoire'
  }

  if (!arrivalAddress) {
    errors.arrivalAddress = "Adresse d'arrivée est obligatoire"
  }

  if (!warehouse) {
    errors.warehouse = 'Entrepôt associés est obligatoire'
  }

  return errors
}

function TourneeModal({
  open,
  isEdit,
  handleClose,
  modalTitle,
  row,

  createTournee,
  updateTournee,
  isLoadingTourneeAction,
  success,
  errorMessage,

  fetchDrivers,
  drivers,
  driversCount,
  isLoadingDrivers,
  searchDrivers,
  fetchMoreDrivers,

  fetchVehicules,
  vehicules,
  vehiculesCount,
  isLoadingVehiculesList,
  searchVehicules,
  fetchMoreVehicules,

  fetchWareHouses,
  warehouses,
  warehousesCount,
  isLoadingWarehouses,
  fetchWareHousesMore,

  resetDriver,
  resetVehicule,
  resetConfigWareHouse,
}) {
  const [select, setSelect] = useState(false)
  const [init, setInit] = useState(false)

  const [vehiculeSearch, setSearchVehicule] = useState('')
  const [offset, setOffset] = useState(25)

  const [searchDriver, setSearchDriver] = useState('')
  const [offsetDriver, setOffsetDriver] = useState(25)

  const [wareHouseSearch, setSearchWareHouse] = useState('')
  const [offsetWareHouse, setOffsetWareHouse] = useState(25)

  const getadresse = (adresse) => {
    return {
      address: adresse.formattedAddress,
      latitude: adresse.latlng.lat,
      longitude: adresse.latlng.lng,
      postalCode: adresse.postalCode,
      city: adresse.city,
    }
  }

  const UserId = String(JSON.parse(localStorage.getItem('currentUser'))?.id)

  const formik = useFormik({
    initialValues: {
      name: '',
      driverId: null,
      vehiculeId: null,
      timeWindowStart: '00:00',
      timeWindowEnd: '00:00',
      duration: 0,
      breaksTimeWindowStart: '00:00',
      breaksTimeWindowEnd: '00:00',
      departureAddress: null,
      arrivalAddress: null,
      warehouse: null,
      dayOfWeek: ['Monday'],
      repeatTimeWindowStart: new Date(),
      repeatTimeWindowEnd: new Date(),
    },
    validate,
    onSubmit: (values) => {
      const tourneeDto = {
        userId: UserId,
        id: isEdit ? row.id : undefined,
        name: values.name,
        participantId: values.driverId.id,
        vehicleId: values.vehiculeId.id,
        timeWindow: { start: values.timeWindowStart, end: values.timeWindowEnd },
        breaks: [
          {
            timeWindows: [
              {
                start: values.breaksTimeWindowStart,
                end: values.breaksTimeWindowEnd,
              },
            ],
            duration: values.duration,
          },
        ],
        repeat: [
          {
            startDate: moment(values.repeatTimeWindowStart).format('DD/MM/YYYY'),
            endDate: moment(values.repeatTimeWindowEnd).format('DD/MM/YYYY'),
            dayOfWeek: values.dayOfWeek,
          },
        ],
        arrivalAddress: getadresse(values.arrivalAddress),
        departureAddress: getadresse(values.departureAddress),
        warehouse: values.warehouse,
      }

      if (isEdit) {
        updateTournee(tourneeDto)
      } else {
        createTournee(tourneeDto)
      }
    },
  })

  useEffect(() => {
    window.addEventListener('beforeunload', resetVehicule)
    window.addEventListener('beforeunload', resetDriver)
    window.addEventListener('beforeunload', resetConfigWareHouse)

    return () => {
      resetVehicule()
      resetDriver()
      resetConfigWareHouse()
      setSelect(false)
      window.removeEventListener('beforeunload', resetVehicule)
      window.removeEventListener('beforeunload', resetDriver)
      window.removeEventListener('beforeunload', resetConfigWareHouse)
    }
  }, [])

  useEffect(() => {
    fetchDrivers()
    fetchVehicules()
    fetchWareHouses()
    setInit(true)
  }, [])

  const onChangeSearchDriver = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchDriver(val)
    } else {
      formik.setFieldValue('driverId', '')
      setSearchDriver('')
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchDriver.length > 0 && !select) {
        searchDrivers(searchDriver)
        setOffsetDriver(25)
      } else if (searchDriver === '') {
        if (init) {
          searchDrivers('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchDriver])

  const onSelectDriver = (value) => {
    if (value) {
      formik.setFieldValue('driverId', value)
      setSelect(true)
    }
  }

  const loadMoreDrivers = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      driversCount >= offsetDriver
    ) {
      setOffsetDriver(offsetDriver + 25)
      fetchMoreDrivers(offsetDriver, searchDriver)
    }
  }

  const onChangeSearchVehicule = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchVehicule(val)
    } else {
      formik.setFieldValue('vehiculeId', '')
      setSearchVehicule('')
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (vehiculeSearch.length > 0 && !select) {
        searchVehicules(vehiculeSearch)
        setOffset(25)
      } else if (vehiculeSearch === '') {
        if (init) {
          searchVehicules('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [vehiculeSearch])

  const onSelectVehicule = (value) => {
    if (value) {
      formik.setFieldValue('vehiculeId', value)
      setSelect(true)
    }
  }

  const loadMoreVehicules = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      vehiculesCount >= offset
    ) {
      setOffset(offset + 25)
      fetchMoreVehicules(offset, vehiculeSearch)
    }
  }

  const onChangeSearchWareHouse = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchWareHouse(val)
    } else {
      formik.setFieldValue('warehouse', '')
      setSearchWareHouse('')
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (wareHouseSearch.length > 0 && !select) {
        fetchWareHouses(wareHouseSearch)
        setOffsetWareHouse(25)
      } else if (wareHouseSearch === '') {
        if (init) {
          fetchWareHouses('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [wareHouseSearch])

  const onSelectWareHouse = (value) => {
    if (value) {
      formik.setFieldValue('warehouse', value)
      setSelect(true)
    }
  }

  const loadMoreWareHouse = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      warehousesCount >= offsetWareHouse
    ) {
      setOffsetWareHouse(offsetWareHouse + 25)
      fetchWareHousesMore(offsetWareHouse)
    }
  }

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        ...formik.values,
        name: row.name,
        driverId: row.participant,
        vehiculeId: row.vehicle,
        timeWindowStart: row.timeWindow.start,
        timeWindowEnd: row.timeWindow.end,
        departureAddress: row?.departureAddress
          ? new Place().getFromOrderAddress(row.departureAddress)
          : null,
        arrivalAddress: row?.arrivalAddress
          ? new Place().getFromOrderAddress(row.arrivalAddress)
          : null,
        duration: row?.breaks ? row.breaks[0].duration : 0,
        breaksTimeWindowStart: row.breaks[0].timeWindows[0].start,
        breaksTimeWindowEnd: row.breaks[0].timeWindows[0].end,
        dayOfWeek: row.repeat[0].dayOfWeek,
        repeatTimeWindowStart: new Date(row.repeat[0].startDate),
        repeatTimeWindowEnd: new Date(row.repeat[0].endDate),
        warehouse: row.warehouse,
      })
    }
  }, [isEdit])

  useEffect(() => {
    if (success) {
      handleClose()
    }
  }, [success])

  const verifEdit = () => {
    return (
      isEdit &&
      formik.values.name == row.timeWindow.name &&
      formik.values.driverId?.id == row.participant.id &&
      formik.values.vehiculeId?.id == row.vehicle.id &&
      formik.values.timeWindowStart == row.timeWindow.start &&
      formik.values.timeWindowEnd == row.timeWindow.end &&
      formik.values.departureAddress.address == row.departureAddress.address &&
      formik.values.arrivalAddress.address == row.arrivalAddress.address &&
      formik.values.duration == row.duration &&
      formik.values.breaksTimeWindowStart == row.breaksTimeWindowStart &&
      formik.values.breaksTimeWindowEnd == row.arrivalAdbreaksTimeWindowEnddress &&
      formik.values.dayOfWeek == row.dayOfWeek &&
      formik.values.repeatTimeWindowStart == row.repeatTimeWindowStart &&
      formik.values.repeatTimeWindowEnd == row.repeatTimeWindowEnd &&
      formik.values.warehouse == row.warehouse
    )
  }

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Dialog
          maxWidthDialog={'md'}
          open={open}
          title={modalTitle}
          iconTitle={<AddIcon />}
          style={errorMessage ? { display: 'none' } : {}}
          content={
            <StyledDivContent>
              <StyledDivFullWidth>
                <StyledInputTitle>Nom *</StyledInputTitle>
                <TextField
                  placeholder="nom"
                  id="Tournee-name"
                  name="name"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    sx: sxInput,
                  }}
                  value={formik.values.name}
                  error={formik.errors.name && formik.touched.name}
                  helperText={
                    formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : null
                  }
                />
              </StyledDivFullWidth>

              <StyledDivFullWidth>
                <StyledInputTitle>Chauffeur *</StyledInputTitle>
                <AutoComplete
                  value={formik.values.driverId}
                  onChange={(event, value) => {
                    onSelectDriver(value)
                  }}
                  onInputChange={(event, value) => onChangeSearchDriver(value)}
                  id="Tournee-Driver"
                  options={drivers.map((driver) => {
                    return {
                      ...driver,
                      name:
                        driver?.firstName && driver?.lastName
                          ? `${driver?.firstName} ${driver?.lastName}`
                          : driver?.firstName
                          ? `${driver?.firstName}`
                          : driver?.lastName
                          ? ` ${driver?.lastName}`
                          : 'N/A',
                    }
                  })}
                  name="driverId"
                  getOptionLabel={(option) => option.name}
                  variant="outlined"
                  placeholder={'Choisir un chauffeur'}
                  sxInputStyle={sxInput}
                  ListboxProps={{
                    onScroll: loadMoreDrivers,
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id} id={option.id}>
                      <div className="AutoCompteFilterOption">{option.name}</div>
                    </li>
                  )}
                  error={formik.errors.driverId && formik.touched.driverId}
                  helperText={
                    formik.errors.driverId && formik.touched.driverId
                      ? formik.errors.driverId
                      : null
                  }
                  loading={isLoadingDrivers}
                />
              </StyledDivFullWidth>
              <StyledDivFullWidth>
                <StyledInputTitle>Véhicule *</StyledInputTitle>
                <AutoComplete
                  value={formik.values.vehiculeId}
                  onChange={(event, value) => {
                    onSelectVehicule(value)
                  }}
                  onInputChange={(event, value) => onChangeSearchVehicule(value)}
                  id="Tournee-Vehicule"
                  options={vehicules}
                  name="vehiculeId"
                  getOptionLabel={(option) => option.immatriculation}
                  variant="outlined"
                  placeholder={'Choisir un véhicule'}
                  sxInputStyle={sxInput}
                  ListboxProps={{
                    onScroll: loadMoreVehicules,
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id} id={option.id}>
                      <div className="AutoCompteFilterOption">
                        {option.immatriculation}
                      </div>
                    </li>
                  )}
                  error={formik.errors.vehiculeId && formik.touched.vehiculeId}
                  helperText={
                    formik.errors.vehiculeId && formik.touched.vehiculeId
                      ? formik.errors.vehiculeId
                      : null
                  }
                  loading={isLoadingVehiculesList}
                />
              </StyledDivFullWidth>
              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Horaires de travail *</StyledInputTitle>
                  <TextField
                    id="Tournee-timeWindowStart"
                    type="time"
                    name="timeWindowStart"
                    value={formik.values.timeWindowStart}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                      sx: sxInput,
                    }}
                    helperText={
                      formik.errors.timeWindowStart && formik.touched.timeWindowStart
                        ? formik.errors.timeWindowStart
                        : null
                    }
                    error={
                      formik.errors.timeWindowStart && formik.touched.timeWindowStart
                    }
                    InputProps={{
                      sx: sxInput,
                    }}
                  />
                </StyledInputModal>
                <div style={{ paddingTop: '17px' }}>
                  <span style={{ margin: '8px 8px' }}>~</span>
                </div>

                <StyledInputModal style={{ paddingTop: '24px' }}>
                  <TextField
                    id="Tournee-timeWindowEnd"
                    type="time"
                    name="timeWindowEnd"
                    value={formik.values.timeWindowEnd}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      formik.errors.timeWindowEnd && formik.touched.timeWindowEnd
                        ? formik.errors.timeWindowEnd
                        : null
                    }
                    error={
                      formik.errors.timeWindowEnd && formik.touched.timeWindowEnd
                    }
                    InputProps={{
                      sx: sxInput,
                    }}
                  />
                </StyledInputModal>
              </StyledDivTopInputs>
              <StyledDivTopInputs style={{ display: 'block' }}>
                <StyledInputTitle>Temps de pause (minute) *</StyledInputTitle>
                <StyledDivMultipleBlock>
                  <StyledDivMultipleBlockTextField
                    id="Tournee-duration"
                    type="number"
                    name="duration"
                    value={formik.values.duration}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      formik.errors.duration && formik.touched.duration
                        ? formik.errors.duration
                        : null
                    }
                    error={formik.errors.duration && formik.touched.duration}
                    InputProps={{
                      sx: sxInput,
                      inputProps: {
                        type: 'number',
                        min: 0,
                      },
                    }}
                  />

                  <TextField
                    id="Tournee-breaksTimeWindowStart"
                    type="time"
                    name="breaksTimeWindowStart"
                    value={formik.values.breaksTimeWindowStart}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      formik.errors.breaksTimeWindowStart &&
                      formik.touched.breaksTimeWindowStart
                        ? formik.errors.breaksTimeWindowStart
                        : null
                    }
                    error={
                      formik.errors.breaksTimeWindowStart &&
                      formik.touched.breaksTimeWindowStart
                    }
                    InputProps={{
                      sx: sxInput,
                    }}
                  />
                  <div style={{ paddingTop: '17px' }}>
                    <span style={{ margin: '8px 8px' }}>~</span>
                  </div>

                  <TextField
                    id="Tournee-breaksTimeWindowEnd"
                    type="time"
                    name="breaksTimeWindowEnd"
                    value={formik.values.breaksTimeWindowEnd}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={
                      formik.errors.breaksTimeWindowEnd &&
                      formik.touched.breaksTimeWindowEnd
                        ? formik.errors.breaksTimeWindowEnd
                        : null
                    }
                    error={
                      formik.errors.breaksTimeWindowEnd &&
                      formik.touched.breaksTimeWindowEnd
                    }
                    InputProps={{
                      sx: sxInput,
                    }}
                  />
                </StyledDivMultipleBlock>
              </StyledDivTopInputs>
              <StyledDivFullWidth>
                <StyledInputTitle>Répétition du plannig *</StyledInputTitle>
                <StyledDivMultipleBlockTextField
                  id="Tournee-dayOfWeek"
                  name="dayOfWeek"
                  variant="outlined"
                  value={formik.values.dayOfWeek}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.dayOfWeek && formik.touched.dayOfWeek
                      ? formik.errors.dayOfWeek
                      : null
                  }
                  error={formik.errors.dayOfWeek && formik.touched.dayOfWeek}
                  InputProps={{
                    sx: sxInput,
                  }}
                  select
                  SelectProps={{
                    multiple: true,
                  }}
                >
                  {DAYS_OF_WEEK_OBJECT.map((day, index) => (
                    <MenuItem id={`Catégorie-${index}`} value={day.code} key={index}>
                      {day.label}
                    </MenuItem>
                  ))}
                </StyledDivMultipleBlockTextField>
              </StyledDivFullWidth>
              <StyledDivTopInputs>
                <StyledInputModal style={{ display: 'flex' }}>
                  <div style={{ paddingTop: '17px' }}>
                    <span style={{ margin: '8px 8px' }}>du</span>
                  </div>

                  <DatePickerLocalisation
                    disabledDays={[]}
                    openedDays={[]}
                    disablePast={false}
                    handleDateChange={(date) =>
                      formik.setFieldValue('repeatTimeWindowStart', date)
                    }
                    withDefaultValue={true}
                    defaultValue={formik.values.repeatTimeWindowStart}
                    date={formik.values.repeatTimeWindowStart}
                    id="Tournee-repeatTimeWindowStart"
                    errorMessage={
                      formik.errors.repeatTimeWindowStart &&
                      formik.touched.repeatTimeWindowStart
                        ? formik.errors.repeatTimeWindowStart
                        : null
                    }
                    hasError={
                      formik.errors.repeatTimeWindowStart &&
                      formik.touched.repeatTimeWindowStart
                    }
                    sxInput={sxInput}
                  />
                </StyledInputModal>
                <StyledInputModal style={{ display: 'flex' }}>
                  <div style={{ paddingTop: '17px' }}>
                    <span style={{ margin: '8px 8px' }}>au</span>
                  </div>

                  <DatePickerLocalisation
                    disabledDays={[]}
                    openedDays={[]}
                    disablePast={false}
                    handleDateChange={(date) =>
                      formik.setFieldValue('repeatTimeWindowEnd', date)
                    }
                    withDefaultValue={true}
                    date={formik.values.repeatTimeWindowEnd}
                    defaultValue={formik.values.repeatTimeWindowEnd}
                    id="Tournee-repeatTimeWindowEnd"
                    errorMessage={
                      formik.errors.repeatTimeWindowEnd &&
                      formik.touched.repeatTimeWindowEnd
                        ? formik.errors.repeatTimeWindowEnd
                        : null
                    }
                    hasError={
                      formik.errors.repeatTimeWindowEnd &&
                      formik.touched.repeatTimeWindowEnd
                    }
                    sxInput={sxInput}
                  />
                </StyledInputModal>
              </StyledDivTopInputs>

              <StyledDivFullWidth>
                <StyledInputTitle>Adresse de départ *</StyledInputTitle>
                <AddressAutoComplete
                  id="Tournee-adresseDepart"
                  placeholder="adresse de départ"
                  selectedDefaultAddress={formik.values.departureAddress}
                  handleQueryChange={(event) => console.log(event)}
                  handleOnAddressSelect={(event) => {
                    init && formik.setFieldValue('departureAddress', event)
                  }}
                  errorText={
                    formik.errors.departureAddress && formik.touched.departureAddress
                      ? formik.errors.departureAddress
                      : null
                  }
                  hasError={
                    formik.errors.departureAddress && formik.touched.departureAddress
                  }
                  sxInput={sxInput}
                />
              </StyledDivFullWidth>
              <StyledDivFullWidth>
                <StyledInputTitle>{"Adresse d'arrivée *"}</StyledInputTitle>
                <AddressAutoComplete
                  id="Tournee-arrivalAddress"
                  placeholder="adresse d'arrivée"
                  selectedDefaultAddress={formik.values.arrivalAddress}
                  handleQueryChange={(event) => console.log(event)}
                  handleOnAddressSelect={(event) => {
                    init && formik.setFieldValue('arrivalAddress', event)
                  }}
                  errorText={
                    formik.errors.arrivalAddress && formik.touched.arrivalAddress
                      ? formik.errors.arrivalAddress
                      : null
                  }
                  hasError={
                    formik.errors.arrivalAddress && formik.touched.arrivalAddress
                  }
                  sxInput={sxInput}
                />
              </StyledDivFullWidth>
              <StyledDivFullWidth>
                <StyledInputTitle>Entrepôt associés *</StyledInputTitle>
                <AutoComplete
                  value={formik.values.warehouse}
                  onChange={(event, value) => {
                    onSelectWareHouse(value)
                  }}
                  onInputChange={(event, value) => onChangeSearchWareHouse(value)}
                  id="Tournee-Warehouse"
                  options={warehouses}
                  name="warehouse"
                  getOptionLabel={(option) => option.label}
                  variant="outlined"
                  placeholder={'Choisir un entrepôt'}
                  sxInputStyle={sxInput}
                  ListboxProps={{
                    onScroll: loadMoreWareHouse,
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id} id={option.id}>
                      <div className="AutoCompteFilterOption">{option.label}</div>
                    </li>
                  )}
                  error={formik.errors.warehouse && formik.touched.warehouse}
                  helperText={
                    formik.errors.warehouse && formik.touched.warehouse
                      ? formik.errors.warehouse
                      : null
                  }
                  loading={isLoadingWarehouses}
                />
              </StyledDivFullWidth>
              {formik?.values?.driverId != null &&
                formik?.values?.driverId != undefined &&
                formik?.values?.driverId != '' && (
                  <StyledDivFullWidth withoutHeight>
                    <StyledInputTitle>Clés chauffeur associés</StyledInputTitle>
                    <BlocCompetences
                      competences={formik?.values?.driverId?.competences || []}
                    />
                  </StyledDivFullWidth>
                )}
              {formik?.values?.vehiculeId != null &&
                formik?.values?.vehiculeId != undefined &&
                formik?.values?.vehiculeId != '' && (
                  <StyledDivFullWidth withoutHeight>
                    <StyledInputTitle>Clés véhicule associés</StyledInputTitle>
                    <BlocCompetences
                      competences={formik?.values?.vehiculeId?.competences || []}
                    />
                  </StyledDivFullWidth>
                )}
            </StyledDivContent>
          }
          handleClose={handleClose}
          handleClickAction={formik.handleSubmit}
          disabled={verifEdit()}
          isLoadingButtonAction={isLoadingTourneeAction}
        />
      </form>
    </>
  )
}

TourneeModal.propTypes = {
  open: PropTypes.bool,
  isEdit: PropTypes.bool,
  handleClose: PropTypes.func,
  modalTitle: PropTypes.string,
  row: PropTypes.object,

  createTournee: PropTypes.func,
  updateTournee: PropTypes.func,
  isLoadingTourneeAction: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,

  fetchDrivers: PropTypes.func,
  drivers: PropTypes.array,
  driversCount: PropTypes.number,
  isLoadingDrivers: PropTypes.bool,
  searchDrivers: PropTypes.func,
  fetchMoreDrivers: PropTypes.func,

  fetchVehicules: PropTypes.func,
  vehicules: PropTypes.array,
  vehiculesCount: PropTypes.number,
  isLoadingVehiculesList: PropTypes.bool,
  searchVehicules: PropTypes.func,
  fetchMoreVehicules: PropTypes.func,

  fetchWareHouses: PropTypes.func,
  warehouses: PropTypes.array,
  warehousesCount: PropTypes.number,
  isLoadingWarehouses: PropTypes.bool,
  fetchWareHousesMore: PropTypes.func,

  resetDriver: PropTypes.func,
  resetVehicule: PropTypes.func,
  resetConfigWareHouse: PropTypes.func,
}

export default TourneeModal

const BlocCompetences = ({ competences }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {competences.length > 0 ? (
        competences.map((competance, index) => (
          <Box
            sx={{
              display: 'inline-flex',
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              borderRadius: '16px',
              height: '32px',
              alignItems: 'center',
              marginRight: '5px',
              marginBottom: '5px',
            }}
            key={index}
          >
            <Box
              component={'span'}
              sx={{ paddingLeft: '12px', paddingRight: '12px' }}
            >
              {competance?.value}
            </Box>
          </Box>
        ))
      ) : (
        <Box>Pas de clés associées</Box>
      )}
    </Box>
  )
}

BlocCompetences.propTypes = {
  competences: PropTypes.array,
}
