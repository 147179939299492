import { getfullName } from '../../utils/values'
import { createSelector } from 'reselect'
import { getStartDate } from '../../utils'
import { PROGRAMMED_STATUS } from '../../utils/values'
import {
  ALPHABETIC_DATE_FORMAT,
  getCreneau,
  getCreneauHours,
} from '../../utils/utils'
const getPreparedOption = ({ status, courseSource, commande }) => {
  if (commande && commande.canalVente && commande.canalVente.configs && status) {
    return (
      commande.canalVente.configs.hasPreparedOption &&
      status.code === PROGRAMMED_STATUS.code &&
      (courseSource === 'corner' || courseSource === 'saas_pro')
    )
  }
}
export const getOrderState = ({ order }) => order

export const getOrders = createSelector(getOrderState, ({ orders }) => {
  return orders.map((order) => {
    return {
      canBePrepared: getPreparedOption(order),
      ...order,
    }
  })
})

export const getDashboardOrders = (state) =>
  getOrderState(state).orders.map(
    ({
      code,
      adresseArrivee,
      adresseDepart,
      commande: { montantGlobalTtc, canalVente, collaborateur, client },
      dateDemarrage,
      lettreDeVoiture,
      status,
      chauffeur,
      dateDemarrageMeta,
      assemblies,
      observation,
      weight,
      volume,
      dropOffStart,
      dropOffEnd,
    }) => {
      return {
        codeCourse: code,
        ldv: lettreDeVoiture?.code ? lettreDeVoiture.code : 'N/A',
        deliveryDate: getCreneau(
          dropOffStart,
          dropOffEnd,
          dateDemarrageMeta,
          dateDemarrage,
          ALPHABETIC_DATE_FORMAT
        ),
        deliveryHour: getCreneauHours(
          dropOffStart,
          dropOffEnd,
          dateDemarrageMeta,
          dateDemarrage,
          '/'
        ),
        prix: `${montantGlobalTtc} €`,
        montage: assemblies,
        observation,
        salesChannel: canalVente ? canalVente.name : null,
        adresseDepart,
        adresseArrivee,
        chauffeur: chauffeur ? `${getfullName(chauffeur, 'N/A')} ` : null,
        societe: client ? (client.societe ? client.societe.name : '') : '',
        collaborateur: collaborateur
          ? `${getfullName(collaborateur, 'N/A')} `
          : null,
        status,
        dateDemarrageMeta,
        dateDemarrageOrigin: dateDemarrage,
        weight,
        volume,
      }
    }
  )
export const getProgramMapOrders = (state) =>
  getOrderState(state).orders.map(
    ({
      code,
      adresseArrivee,
      adresseDepart,
      commande: { montantGlobalTtc, canalVente, collaborateur, client },
      dateDemarrage,
      lettreDeVoiture,
      status,
      chauffeur,
      dateDemarrageMeta,
      assemblies,
      observation,
      weight,
      volume,
      factures,
      courseMetadata,
      dropOffStart,
      dropOffEnd,
    }) => {
      return {
        codeCourse: code,
        ldv: lettreDeVoiture?.code ? lettreDeVoiture.code : 'N/A',
        // TODO : handle new logic
        dateDemarrage: getStartDate(dateDemarrageMeta, dateDemarrage),
        prix: `${montantGlobalTtc} €`,
        montage: assemblies,
        observation,
        salesChannel: canalVente ? canalVente.name : null,
        adresseDepart,
        adresseArrivee,
        chauffeur: chauffeur ? `${getfullName(chauffeur, 'N/A')} ` : null,
        societe: client ? (client.societe ? client.societe.name : '') : '',
        collaborateur: collaborateur
          ? `${getfullName(collaborateur, 'N/A')} `
          : null,
        status,
        dateDemarrageMeta,
        dateDemarrageOrigin: dateDemarrage,
        weight,
        volume,
        factures,
        courseMetadata: courseMetadata,
        dropOffStart,
        dropOffEnd,
      }
    }
  )

export const getMyCommandsIsOn = (state) => getOrderState(state).myCommandsIsOn

export const getIsLoadingSearchCorner = (state) =>
  getOrderState(state).isLoadingSearchCorner

export const getCoursesSearch = (state) => getOrderState(state).coursesSearch

export const getIsLoadingRelatedCourse = (state) =>
  getOrderState(state).isLoadingRelatedCourse
export const getRelatedCourse = (state) => getOrderState(state).relatedCourse

export const getDelayedOrders = (state) =>
  getOrderState(state).delayedOrders.map(
    ({
      code,
      commande: { canalVente },
      dateDemarrage,
      lettreDeVoiture,
      status,
      dateDemarrageMeta,
    }) => {
      return {
        codeCourse: code,
        ldv: lettreDeVoiture?.code,
        dateDemarrage: getStartDate(dateDemarrageMeta, dateDemarrage),
        salesChannel: canalVente ? canalVente.name : 'N/A',
        status,
      }
    }
  )

export const getSelectedCourses = (state) =>
  Object.keys(getOrderState(state).selectedCourses).map((ele) => {
    return ele
  })

export const getIsLoading = (state) => getOrderState(state).isLoading
export const getError = (state) => getOrderState(state).error
export const getOrder = (state) => getOrderState(state).order

export const getOrderSignature = (state) => getOrderState(state).orderSignature
export const getLodingOrderSignature = (state) =>
  getOrderState(state).isLoadingOrderSignature
export const getLoadingUploadSignature = (state) =>
  getOrderState(state).isLoadingUploadSignature
export const getActionOrderSignatureDone = (state) =>
  getOrderState(state).isActionOrderSignatureDone

export const getErrorUploadFile = (state) => getOrderState(state).errorUploadFile

export const getOrderPhoto = (state) => getOrderState(state).orderPhoto
export const getLodingOrderPhoto = (state) =>
  getOrderState(state).isLoadingOrderPhoto
export const getLoadingUploadPhoto = (state) =>
  getOrderState(state).isLoadingUploadPhoto
export const getActionOrderOrderPhotoDone = (state) =>
  getOrderState(state).isActionOrderPhotoDone

export const getOrderAttachments = (state) => getOrderState(state).orderAttachments
export const getLoadingUploadFile = (state) =>
  getOrderState(state).isLoadingUploadFile
export const getActionOrderAttachmentsDone = (state) =>
  getOrderState(state).isActionOrderAttachmentsDone
export const getLodingOrderAttachments = (state) =>
  getOrderState(state).isLoadingOrderAttachments

export const getLoadingOrderNotification = (state) =>
  getOrderState(state).isLoadingOrderNotification
export const getNotificationOrder = (state) => getOrderState(state).orderNotification

export const getIsLoadingHistoriqueDeltaCost = (state) =>
  getOrderState(state).isLoadingHistoriqueDeltaCost
export const getHistoriqueDeltaCostOrder = (state) =>
  getOrderState(state).orderHistoriqueDeltaCost

export const getIsLoadingHistoriqueLivraison = (state) =>
  getOrderState(state).isLoadingHistoriqueLivraison
export const getHistoriqueLivraisonOrder = (state) =>
  getOrderState(state).orderHistoriqueLivraison

export const getHistoriqueRDVOrder = (state) =>
  getOrderState(state).orderHistoriqueRDV
export const getIsLoadingHistoriqueRDV = (state) =>
  getOrderState(state).isLoadingHistoriqueRDV

export const getIsLoadingActivityHistory = (state) =>
  getOrderState(state).isLoadingActivityHistory
export const getActivityHistoryOrder = (state) =>
  getOrderState(state).orderActivityHistory

export const getReasonsOrder = (state) => getOrderState(state).orderReasons
export const getIsLoadingReasonsOrder = (state) =>
  getOrderState(state).isLoadingReasonsOrder

export const getErrorActionCourse = (state) => getOrderState(state).errorActionCourse

export const getisActionOrderDone = (state) => getOrderState(state).isActionOrderDone
export const getIsLoadingActionOrder = (state) =>
  getOrderState(state).isLoadingActionOrder

export const getLoadingGetCollaborateur = (state) =>
  getOrderState(state).isLoadingGetCollaborateur

export const getLoadingGetClientsPro = (state) =>
  getOrderState(state).isLoadingGetClientsPro

const getAgents = (collaborateurs, clients) => {
  let collaborateursSocketSend = []
  let clientssSocketSend = []

  collaborateurs.forEach((item) => {
    collaborateursSocketSend.push({
      legacyID: String(item.id),
      photoIdentity:
        item.photoIdentity ||
        'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown_1-512.png',
      firstName: item.firstname,
      name: item.lastname,
      email: item.login,
      type: 'INTERNAL',
    })
  })

  clients.forEach((item) => {
    clientssSocketSend.push({
      legacyID: String(item.id),
      photoIdentity:
        item.avatarURL ||
        'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown_1-512.png',
      firstName: item.firstname,
      name: item.lastname,
      email: item.mail,
      type: 'EXTERNAL',
    })
  })
  return [...collaborateursSocketSend, ...clientssSocketSend]
}

export const getAgentsDiscussion = (state) =>
  getAgents(getOrderState(state).collaborateurs, getOrderState(state).clientsPro)

export const getIsLoadingRefresh = (state) => getOrderState(state).isLoadingRefresh

export const getMacroCanals = (state) => getOrderState(state).macroCanals
export const getIsLoadingMacroCanal = (state) =>
  getOrderState(state).isLoadingMacroCanal
export const getErrorImportCourses = (state) =>
  getOrderState(state).errorImportCourses
export const getIsActionImportCourses = (state) =>
  getOrderState(state).isActionImportCourses
export const getIsLoadingActionImportCourses = (state) =>
  getOrderState(state).isLoadingActionImportCourses

export const getErrorImportCoursesMessage = (state) =>
  getOrderState(state).errorImportCoursesMessage

export const getDuplicatedCourse = (state) => getOrderState(state).duplicatedCourse

export const getOrderActivity = (state) => getOrderState(state).orderActivity
export const getIsLoadingActivity = (state) =>
  getOrderState(state).isLoadingActitvity

export const getCoursesChecked = (state) => getOrderState(state).coursesChecked
export const getCheckCoursesAll = (state) => getOrderState(state).checkCoursesAll
export const getErrorActionChangeStatusEnMasse = (state) =>
  getOrderState(state).errorActionChangeStatusEnMasse
export const getIsErrorActionWithSuccess = (state) =>
  getOrderState(state).isErrorActionWithSuccess

export const getIsLoadingOrderQuestions = (state) =>
  getOrderState(state).isLoadingOrderQuestions

export const getOrderQuestions = (state) => getOrderState(state).orderQuestions
export const getCanalConfig = (state) => getOrderState(state).configCanal
export const getIsLoadingGetConfig = (state) =>
  getOrderState(state).isLoadingGetConfig
