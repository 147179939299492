import { connect } from 'react-redux'
import LivraisonClient from '../../../components/ShipmentAction/LivraisonClient'
import { LivraisonClientAction } from '../../../redux/order/actions'
import {
  getErrorActionCourse,
  getIsLoadingActionOrder,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  errorActionCourse: getErrorActionCourse(state),
})
const mapDisptachToProps = (dispatch) => ({
  LivraisonClient: (payload) => {
    dispatch(LivraisonClientAction(payload))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(LivraisonClient)
