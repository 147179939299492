import Popover from '@mui/material/Popover'
import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
/** A Popover can be used to display some content on top of another. */
export default function PopHover({
  content,
  actionButtons,
  open,
  anchorEl,
  onClose,
  className,
  id,
  anchorOrigin,
  transformOrigin,
}) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      id={id}
      onClose={onClose}
      className={[className].join(' ')}
      anchorOrigin={
        anchorOrigin
          ? anchorOrigin
          : {
              vertical: 'bottom',
              horizontal: 'center',
            }
      }
      transformOrigin={
        transformOrigin
          ? transformOrigin
          : {
              vertical: 'top',
              horizontal: 'center',
            }
      }
    >
      {content}
      {actionButtons && <div className=" calendar-button">{actionButtons}</div>}
    </Popover>
  )
}
PopHover.propTypes = {
  /** The JSX content of the pop component */
  content: PropTypes.object,
  /** The pop component action buttons */
  actionButtons: PropTypes.object,
  /** If true the pop component will be shown */
  open: PropTypes.bool,
  /** A HTML element, or a function that returns it. It's used to set the position of the popover. */
  anchorEl: PropTypes.object,
  /** The function that is executed on the close of the pop component */
  onClose: PropTypes.func,
  /** The class name of the styling of the pop component */
  className: PropTypes.string,
  /** The component html ID */
  id: PropTypes.string,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
}
