import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import PlanCadencementIcon from '../../../../../../assets/icons/detailsLivraison/planCadencement'
import CircularProgress from '@mui/material/CircularProgress'
import { COURSE_SOURCE_SCHMIDT } from '../../../../../../utils/values'
import { CardSyled, TitleWithIcon } from '../card/style'

const getStoreOpeningTimes = (schedule) => {
  const result = []
  const days = {}
  const filteredSchedule = schedule.filter((entry) => entry.storeOpeningTime)
  for (const item of filteredSchedule) {
    const day = item.dayOfWeek.label
    const hours = `${item.openTime.trim()} - ${item.closeTime.trim()}`

    if (day in days) {
      days[day].horaires.push(hours)
    } else {
      days[day] = {
        label: day,
        horaires: [hours],
      }
    }
  }

  for (const day of Object.values(days)) {
    const horairesString = day.horaires.join('   /  ')
    const horaires = [horairesString]
    result.push({
      label: day.label,
      horaires: horaires,
    })
  }
  return result
}
const PlanCadencement = ({ order, isLoading }) => {
  const [openingTimes, setOpeningTimes] = useState([])

  useEffect(() => {
    if (order) {
      const storeOpeningTime = getStoreOpeningTimes(
        order.commande.canalVente.configs.operationalHours
      )
      setOpeningTimes(storeOpeningTime)
    }
  }, [order])

  const isSchmit = order?.courseSource === COURSE_SOURCE_SCHMIDT

  let courseType = ['StoreDelivery', 'StorePickUp'].includes(
    order?.courseMetadata?.courseType
  )

  return (
    <>
      {isSchmit && courseType && (
        <CardSyled>
          <TitleWithIcon>
            <PlanCadencementIcon
              style={{ marginRight: '4px', width: '20px', height: '20px' }}
            />
            Horaires d’ouverture magasin
          </TitleWithIcon>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px',
              }}
            >
              <CircularProgress color="primary" />
            </div>
          ) : (
            <>
              {openingTimes.length > 0 ? (
                <div style={{ padding: '5px 5px 10px' }}>
                  {openingTimes.map((rdv, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          paddingBottom: '5px',
                          display: 'flex',
                          alignItems: 'end',
                        }}
                      >
                        <div
                          style={{
                            color: '#696969',
                            fontSize: '14px',
                            fontWeight: '400',
                            paddingRight: '25px',
                            paddingLeft: '25px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {rdv.label}
                        </div>
                        {rdv.horaires.map((s, i) => {
                          return <div key={i}>{s}</div>
                        })}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <span
                    style={{
                      overflowWrap: 'break-word',
                      fontSize: '12px',
                      fontWeight: '550',
                    }}
                  >
                    {" Pas d'Horaires d’ouverture magasin"}
                  </span>
                </div>
              )}
            </>
          )}
        </CardSyled>
      )}
    </>
  )
}
PlanCadencement.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
}
export default PlanCadencement
