import React from 'react'
import Dialog from '../../../../../../../components/ModalDialog'
import DialogContentText from '@mui/material/DialogContentText'
import PropTypes from 'prop-types'
export default function DialogLivraisonPartielle({
  open,
  handleClose,
  onCreationCourseLivraisonPartielle,
  isLoadingLivraisonPartielle,
}) {
  return (
    <Dialog
      maxWidthDialog={'sm'}
      open={open}
      title={'Livraison Partielle'}
      content={
        <DialogContentText sx={{ pr: 2, pb: 2 }}>
          <div style={{ width: '400px' }}>
            <span style={{ display: 'flex', placeContent: 'center' }}>
              {'Une course en B sera créée avec le(s) colis sélectionné(s).'}
            </span>
          </div>
        </DialogContentText>
      }
      handleClose={handleClose}
      handleClickAction={onCreationCourseLivraisonPartielle}
      isLoadingButtonAction={isLoadingLivraisonPartielle}
    />
  )
}
DialogLivraisonPartielle.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onCreationCourseLivraisonPartielle: PropTypes.func,
  isLoadingLivraisonPartielle: PropTypes.func,
}
