import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import List from './List'
import CircularProgress from '@mui/material/CircularProgress'

export default function AutoCompleteSearch({
  suggestions,
  handleToggle,
  fetchDriversScroll,
  onChangeSearch,
  search,
  isLoadingDrivers,
}) {
  return (
    <form noValidate autoComplete="off">
      <TextField
        id="standard-basic"
        placeholder="Rechercher .. "
        onChange={(event) => {
          onChangeSearch(event.target.value)
          event.preventDefault()
        }}
        search={search}
        style={{ maxWidth: 400, padding: '15px' }}
        disabled={isLoadingDrivers}
        variant="standard"
      />

      <SuggestionsListComponent
        filteredSuggestions={suggestions}
        handleToggle={handleToggle}
        fetchDriversScroll={fetchDriversScroll}
        isLoadingDrivers={isLoadingDrivers}
      />
    </form>
  )
}
AutoCompleteSearch.propTypes = {
  handleToggle: PropTypes.func,
  suggestions: PropTypes.array,
  fetchDriversScroll: PropTypes.func,
  onChangeSearch: PropTypes.func,
  search: PropTypes.string,
  isLoadingDrivers: PropTypes.bool,
}

export const SuggestionsListComponent = ({
  filteredSuggestions,
  handleToggle,
  fetchDriversScroll,
  isLoadingDrivers,
}) => {
  return (
    <>
      {isLoadingDrivers ? (
        <div style={{ marginLeft: '45%' }}>
          <CircularProgress color="primary" />
        </div>
      ) : filteredSuggestions.length > 0 ? (
        <List
          dataList={filteredSuggestions}
          handleToggle={handleToggle}
          fetchDriversScroll={fetchDriversScroll}
          isLoadingDrivers={isLoadingDrivers}
        />
      ) : (
        <Typography variant="caption" display="block" gutterBottom>
          Pas de données
        </Typography>
      )}
    </>
  )
}

SuggestionsListComponent.propTypes = {
  filteredSuggestions: PropTypes.array,
  handleToggle: PropTypes.func,
  fetchDriversScroll: PropTypes.func,
  isLoadingDrivers: PropTypes.bool,
}
