import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import IconDistribuer from '../../../assets/icons/polaris/iconeDistribuer'

const ListItemsComponent = ({
  data,
  handleToggle,
  handleDoubleClick,
  isSelected,
  isProgrammer,
  customStyle,
  notEmptyTours,
}) => {
  return (
    <>
      {data.map((value, i) => {
        const labelId = `checkbox-list-secondary-label-${i}`
        const isItemSelected = !isProgrammer && isSelected(value.code)
        return (
          <ListItem
            sx={customStyle?.listItem}
            key={i}
            button
            onDoubleClick={!isProgrammer && handleDoubleClick(value.code)}
          >
            <ListItemText
              sx={customStyle?.listItemText}
              id={labelId}
              primary={value.name}
            />
            {notEmptyTours &&
              notEmptyTours.length > 0 &&
              notEmptyTours.includes(value.code) && (
                <IconDistribuer
                  style={{
                    width: '20px',
                    height: '20px',
                    color: '#1976D2',
                  }}
                />
              )}
            <ListItemSecondaryAction>
              <Checkbox
                id={`${labelId}-check-button`}
                edge="end"
                onClick={handleToggle(isProgrammer ? value : value.code)}
                checked={isProgrammer ? value.checked : isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
                style={{ cursor: 'pointer' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )
      })}
    </>
  )
}
ListItemsComponent.propTypes = {
  handleToggle: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  data: PropTypes.array,
  isSelected: PropTypes.func,
  isProgrammer: PropTypes.bool,
  customStyle: PropTypes.object,
  notEmptyTours: PropTypes.array,
}
export default ListItemsComponent
