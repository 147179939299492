import { connect } from 'react-redux'
import DetailsProduits from '../../../../pages/main/Orders/DetailsDelivery/components/dialog/detailsColis/dialogProduits'

import {
  getProduits,
  getLoadingProduits,
} from '../../../../redux/orderPackage/selectors'
import { getDetailsProduits } from '../../../../redux/orderPackage/actions'

const mapStateToProps = (state) => ({
  produitsPackage: getProduits(state),
  isLoadingProduitsPackage: getLoadingProduits(state),
})
const mapDisptachToProps = (dispatch) => ({
  getDetailsProduits: (idPackage) => dispatch(getDetailsProduits(idPackage)),
})

export default connect(mapStateToProps, mapDisptachToProps)(DetailsProduits)
