import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import { getCreneauHoursMinutes } from '../../../../../../utils/utils'
export default function MarkerTextInfo({
  ldv,
  prix,
  salesChannel,
  startDate,
  driver,
  locationName,
  arrival,
  reason,
  weight,
  windowStart,
  windowEnd,
  courseType,
  client,
  numFacture,
  orderGiver,
}) {
  return (
    <span className="info-window">
      <p className=" info-window-title">
        {numFacture && !driver && 'REC: '}
        {ldv || driver}
      </p>{' '}
      <hr />
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {orderGiver && <li>Donneur d&apos;ordre: {orderGiver}</li>}

        {prix && <li>Prix Client: {parseInt(prix).toFixed(2)}€</li>}

        {/* {numFacture && <li>REC: {numFacture}</li>} */}
        {client && <li> Nom du client : {client}</li>}
        {arrival && <li>Arrivé: {arrival}</li>}
        {windowStart && (
          <li>
            Créneau de livraison:{' '}
            {getCreneauHoursMinutes(windowStart, windowEnd, '~') ?? 'N/A'}{' '}
          </li>
        )}
        {courseType && <li>Type: {courseType}</li>}
        {salesChannel && <li>Canal: {salesChannel}</li>}
        {startDate && <li>Date: {startDate}</li>}

        {reason && <li>Raison: {reason}</li>}
        {weight && <li>{`Poids: ${weight} Kg`}</li>}

        {locationName && <li>Adresse: {locationName}</li>}
      </ul>
    </span>
  )
}
MarkerTextInfo.propTypes = {
  ldv: PropTypes.string,
  prix: PropTypes.string,
  salesChannel: PropTypes.string,
  startDate: PropTypes.string,
  driver: PropTypes.string,
  arrival: PropTypes.string,
  locationName: PropTypes.string,
  departure: PropTypes.string,
  type: PropTypes.string,
  reason: PropTypes.string,
  weight: PropTypes.string,
  windowStart: PropTypes.string,
  windowEnd: PropTypes.string,
  courseType: PropTypes.string,
  client: PropTypes.string,
  numFacture: PropTypes.string,
  orderGiver: PropTypes.string,
}
