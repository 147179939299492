import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCompressAlt,
  faExpandAlt,
  faWindowMaximize,
  faWindowMinimize,
} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

export default function DashboardCellHeaderActions({
  onWindowResize,
  windowSize,
  expand,
  onExpandWindow,
}) {
  const max = 'max'
  const min = 'min'
  const cursorPointer = { cursor: 'pointer' }
  return (
    <div className="dashboard-cell-header-actions">
      <FontAwesomeIcon
        style={cursorPointer}
        icon={windowSize === max ? faWindowMinimize : faWindowMaximize}
        onClick={() =>
          windowSize === max ? onWindowResize(min) : onWindowResize(max)
        }
      />
      <FontAwesomeIcon
        style={cursorPointer}
        icon={expand ? faCompressAlt : faExpandAlt}
        onClick={() => onExpandWindow(!expand)}
      />
    </div>
  )
}
DashboardCellHeaderActions.propTypes = {
  onWindowResize: PropTypes.func,
  onExpandWindow: PropTypes.func,
  windowSize: PropTypes.string,
  expand: PropTypes.bool,
}
