export const GET_TOURNEES = 'GET_TOURNEES'
export const GET_TOURNEES_SUCCESS = 'GET_TOURNEES_SUCCESS'
export const GET_TOURNEES_FAILURE = 'GET_TOURNEES_FAILURE'

export const PAGE_CHANGED_TOURNEES = 'PAGE_CHANGED_TOURNEES'
export const PAGE_LIMIT_CHANGED_TOURNEES = 'PAGE_LIMIT_CHANGED_TOURNEES'

export const REMOVE_ERROR_TOURNEES = 'REMOVE_ERROR_TOURNEES'

export const RESET_SUCCESS_ACTION_TOURNEES = 'RESET_SUCCESS_ACTION_TOURNEES'

export const RESET_CONFIG_TOURNEES = 'RESET_CONFIG_TOURNEES'

export const SEARCH_TOURNEES = 'SEARCH_TOURNEES'

export const ADD_TOURNEE = 'ADD_TOURNEE'
export const ADD_TOURNEE_SUCCESS = 'ADD_TOURNEE_SUCCESS'
export const ADD_TOURNEE_FAILURE = 'ADD_TOURNEE_FAILURE'

export const UPDATE_TOURNEE = 'UPDATE_TOURNEE'
export const UPDATE_TOURNEE_SUCCESS = 'UPDATE_TOURNEE_SUCCESS'
export const UPDATE_TOURNEE_FAILURE = 'UPDATE_TOURNEE_FAILURE'
