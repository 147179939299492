import { connect } from 'react-redux'
import HistoriqueDeLivraison from '../../../pages/main/Orders/DetailsDelivery/components/details/HistoriqueLivraison'

import {
  getHistoriqueLivraisonOrder,
  getIsLoadingHistoriqueLivraison,
} from '../../../redux/order/selectors'
import { getOrderHistoriqueLivraison } from '../../../redux/order/actions'

const mapStateToProps = (state) => ({
  orderHistoriqueLivraison: getHistoriqueLivraisonOrder(state),
  isLoadingHistoriqueLivraison: getIsLoadingHistoriqueLivraison(state),
})
const mapDisptachToProps = (dispatch) => ({
  getOrderHistoriqueLivraison: (codeCourse) =>
    dispatch(getOrderHistoriqueLivraison(codeCourse)),
})
export default connect(mapStateToProps, mapDisptachToProps)(HistoriqueDeLivraison)
