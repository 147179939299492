export default () => {
  return {
    filter: {
      limit: 25,
      offset: 0,
      actif: true,
    },
    participants: [],
    participantsCount: 0,
    isLoadingParticipantsList: false,
    errorMessage: null,
    isErrorFetch: false,

    isLoadingParticipantAction: false,
    success: false,
    isSuccessRefresh: false,

    participantsList: [],
    participantsListCount: 0,
    isLoadingParticipants: false,
  }
}
