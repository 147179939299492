import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

export default function PublicRoute({ isLoggedIn, pathRedirect, ...props }) {
  return !isLoggedIn ? <Route {...props} /> : <Redirect to={pathRedirect} />
}
PublicRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  pathRedirect: PropTypes.string.isRequired,
}
