import checkExpirationDate, {
  DEPARTEMENT_EXPIRATION_DATE,
  MACRO_CANAL_EXPIRATION_DATE,
  SALES_CHANNEL_EXPIRATION_DATE,
  SERVICE_EXPIRATION_DATE,
  SUBJECT_EXPIRATION_DATE,
  WAREHOUSE_EXPIRATION_DATE,
} from '../../utils/checkExpirationDate'
import { getValue } from '../../utils'
import moment from 'moment'

import {
  FilterCommande,
  FilterTableauBord,
  FilterPlanification,
  FilterEntrepot,
  FilterFreights,
  FilterTour,
  FilterDiscussion,
} from './defaultFilter'
import { DRIVERS_FILTER } from './actionsConsts'

export default () => {
  checkExpirationDate(SUBJECT_EXPIRATION_DATE)
  checkExpirationDate(SERVICE_EXPIRATION_DATE)
  checkExpirationDate(DEPARTEMENT_EXPIRATION_DATE)
  checkExpirationDate(MACRO_CANAL_EXPIRATION_DATE)
  checkExpirationDate(SALES_CHANNEL_EXPIRATION_DATE)
  checkExpirationDate(WAREHOUSE_EXPIRATION_DATE)

  const salesChannelList = getValue('salesChannels', [])
  const salesChannelListGroup = getValue('salesChannelsGroupe', {})
  const subjects = getValue('subjects', [])
  const driverfilter = getValue(DRIVERS_FILTER, [])
  const services = getValue('services', [])
  const departments = getValue('departments', [])
  const macroCanal = getValue('macroCanal', [])
  const wareHouseList = getValue('wareHouses', [])

  return {
    defaultFilterTours: FilterTour(),
    defaultFilterCommande: FilterCommande(),
    defaultFilterTableauBord: FilterTableauBord(),
    defaultFilterPlanification: FilterPlanification(),
    defaultFilterEntrepot: FilterEntrepot(),
    defaultFilterFreights: FilterFreights(),
    defaultFilterDiscussion: FilterDiscussion(),

    tours: FilterTour(),
    commande: FilterCommande(),
    tableauBord: FilterTableauBord(),
    planification: FilterPlanification(),
    entrepot: FilterEntrepot(),
    freights: FilterFreights(),
    discussion: FilterDiscussion(),

    error: null,
    isLoadingBusinessOwner: false,
    businessOwnerList: [],
    searchCornerOnglet: null,
    searchCorner: null,

    subjects,
    isLoadingSubjects: false,
    filter: {
      codeStatus: [],
      codeCanal: null,
      codeChauffeur: null,
      endTime: moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm'),
      limit: 25,
      offset: 0,
      service: null,
      departement: null,
      startTime: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm'),
      dropOffStart: null,
      warehouse: null,
      sort: null,
      orderBy: null,
    },
    isOpen: true,
    ldvs: undefined,
    count: 0,
    programmerPickedDate: moment(new Date())
      .startOf('day')
      .format('YYYY-MM-DD HH:mm'),
    driverfilter,
    isLoadingDriverFilter: false,
    services,
    isGetServicesListLoading: false,
    departments,
    isGetDepartementsLoading: false,
    macroCanal,
    isGetMacroCanalLoading: false,

    isGetSalesChannelListLoading: false,
    salesChannelList,
    selectedSalesChannel: null,
    salesChannelListGroup,

    isGetTourListLoading: false,
    tourList: [],
    countListTour: 0,
    isGetTourListMoreLoading: false,

    isGetWareHouseListLoading: false,
    wareHouseList,

    fromAppliquer: false,

    isLoadingGetFilters: false,
    filtersCollaborateur: null,

    defaultFilterSave: {},
    filterReducerSave: {},

    displayGrid: true,

    isLoadingActionFilter: false,
    isActionCreationFilter: false,
    isActionDeleteFilter: false,
    isActionFilter: false, //Renommer

    idOngletFilterOpen: null,

    isActionModifierFilter: false,
  }
}
