import Tooltip from '@mui/material/Tooltip/Tooltip'
import assemblyImg from '../../../../../../assets/images/marteau_cle.png'
import Popover from '@mui/material/Popover/Popover'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import { replaceDotWithComma } from '../../../../../../utils/floatFormatting'
import { getfullName } from '../../../../../../utils/values'
import { styled } from '@mui/material/styles'

const ASSEMBLY_ADMIN_FOREST_URL = process.env.REACT_APP_ASSEMBLY_ADMIN_FOREST_URL
export default function Assembly({
  assembler,
  clientPrice,
  assemblyId,
  startAt,
  anchorEl,
  handleClick,
  handleClose,
  open,
  buildIcon,
}) {
  const StyledTypography = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'left',
  }))

  const forestUrl = ASSEMBLY_ADMIN_FOREST_URL.replace('?', assemblyId)
  return (
    <>
      <Tooltip arrow title={<span className="tootltip-content">Montage</span>}>
        <IconButton aria-label="print" className={buildIcon} onClick={handleClick}>
          <img src={assemblyImg} alt="assembly" style={{ width: '20px' }} />{' '}
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {startAt && (
          <StyledTypography>
            Date: {moment(startAt).format('LL')} à {moment(startAt).format('LT')}
          </StyledTypography>
        )}
        {clientPrice != null && clientPrice != undefined && (
          <StyledTypography>
            Prix: {replaceDotWithComma(clientPrice.toFixed(2))}€
          </StyledTypography>
        )}
        {assembler && (
          <StyledTypography>
            Monteur: {getfullName(assembler, 'N/A')}
          </StyledTypography>
        )}
        <StyledTypography>
          <a href={forestUrl} target="_blank" rel="noopener noreferrer">
            {' '}
            Voir plus{' '}
          </a>
        </StyledTypography>
      </Popover>
    </>
  )
}
Assembly.propTypes = {
  assembler: PropTypes.object,
  clientPrice: PropTypes.number,
  assemblyId: PropTypes.number,
  startAt: PropTypes.string,
  anchorEl: PropTypes.object,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  buildIcon: PropTypes.string,
  open: PropTypes.bool,
}
