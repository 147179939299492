import { OrderPackageConstants } from './constants'

import { httpFetch, httpPost, httpPatch } from '../http/actions'
import queryString from 'query-string'
import { setLoading } from '../order/actions'

const api = process.env.REACT_APP_API_URL

const OrderPackageMiddleware = (store) => (next) => (action) => {
  //Fetch order Package
  if (action.type === OrderPackageConstants.GET_PACKAGES_ORDER) {
    const { ldv } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/packagesByLdv?code=${ldv}`,
        success: OrderPackageConstants.GET_PACKAGES_ORDER_SUCCESS,
        failure: OrderPackageConstants.GET_PACKAGES_ORDER_FAILED,
      })
    )
  }

  //Fetch motif Etat package (Pointage)
  if (action.type === OrderPackageConstants.GET_PACKAGES_STATUS) {
    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/getDeliveryStatusList`,
        success: OrderPackageConstants.GET_PACKAGES_STATUS_SUCCESS,
        failure: OrderPackageConstants.GET_PACKAGES_STATUS_FAILED,
      })
    )
  }

  //Action Pointage package
  if (action.type === OrderPackageConstants.POINTAGE_PACKAGES) {
    store.dispatch(
      httpPatch({
        endPoint: `${api}stock/packages/list`,
        data: action.payload,
        success: OrderPackageConstants.POINTAGE_PACKAGES_SUCCESS,
        failure: OrderPackageConstants.POINTAGE_PACKAGES_FAILED,
      })
    )
  }

  //Action Forcage Pointage package
  if (action.type === OrderPackageConstants.POINTAGE_FORCE_PACKAGES) {
    store.dispatch(
      httpPatch({
        endPoint: `${api}stock/packages/list `,
        data: action.payload,
        success: OrderPackageConstants.POINTAGE_FORCE_PACKAGES_SUCCESS,
        failure: OrderPackageConstants.POINTAGE_FORCE_PACKAGES_FAILED,
      })
    )
  }

  //Upload image litige
  if (action.type === OrderPackageConstants.UPLOAD_IMAGE_LITIGE) {
    const { codePackage, imageLitige } = action.payload

    store.dispatch(
      httpPost({
        endPoint: `${api}litigation/pictures/addByCode?code=${codePackage}`,
        data: imageLitige,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: OrderPackageConstants.UPLOAD_IMAGE_LITIGE_SUCCESS,
        failure: OrderPackageConstants.UPLOAD_IMAGE_LITIGE_FAILED,
      })
    )
  }

  //Fetch Motif Litige Packages
  if (action.type === OrderPackageConstants.GET_PACKAGES_LITIGE_MOTIF) {
    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/getAllLitigationStatusList`,
        success: OrderPackageConstants.GET_PACKAGES_LITIGE_MOTIF_SUCCESS,
        failure: OrderPackageConstants.GET_PACKAGES_LITIGE_MOTIF_FAILED,
      })
    )
  }

  //Fetch Motif  Responsabilite Litige Packages
  if (action.type === OrderPackageConstants.GET_PACKAGES_LITIGE_RESPONSABILITE) {
    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/getResponsibleList`,
        success: OrderPackageConstants.GET_PACKAGES_LITIGE_RESPONSABILITE_SUCCESS,
        failure: OrderPackageConstants.GET_PACKAGES_LITIGE_RESPONSABILITE_FAILED,
      })
    )
  }

  //Fetch Litige Historique Package
  if (action.type === OrderPackageConstants.GET_LITIGE_HISTORIQUE_PACKAGE) {
    const { idLitige } = action.payload

    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/getLitigationList?litigation=${idLitige}`,
        success: OrderPackageConstants.GET_LITIGE_HISTORIQUE_PACKAGE_SUCCESS,
        failure: OrderPackageConstants.GET_LITIGE_HISTORIQUE_PACKAGE_FAILED,
      })
    )
  }

  //Fetch Details Produits
  if (action.type === OrderPackageConstants.GET_PRODUITS_PACKAGE) {
    const { idPackage } = action.payload

    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/productsByPackageId?id=${idPackage}`,
        success: OrderPackageConstants.GET_PRODUITS_PACKAGE_SUCCESS,
        failure: OrderPackageConstants.GET_PRODUITS_PACKAGE_FAILED,
      })
    )
  }

  //Fetch Package Historiques
  if (action.type === OrderPackageConstants.GET_HISTORIQUES_PACKAGE) {
    const { idPackage } = action.payload

    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/packageTraceabelitiesByPackageId?id=${idPackage}`,
        success: OrderPackageConstants.GET_HISTORIQUES_PACKAGE_SUCCESS,
        failure: OrderPackageConstants.GET_HISTORIQUES_PACKAGE_FAILED,
      })
    )
  }

  //Fetch Photos Historique Package
  if (action.type === OrderPackageConstants.GET_PHOTOS_HISTORIQUE_PACKAGE) {
    const { packageTraceability } = action.payload
    const sendData = {
      id: packageTraceability.id,
      pictureType: packageTraceability.pictureType,
    }
    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/packageTraceabilityAttachmentByPackageTraceabilityId?${queryString.stringify(
          sendData
        )}`,
        success: OrderPackageConstants.GET_PHOTOS_HISTORIQUE_PACKAGE_SUCCESS,
        failure: OrderPackageConstants.GET_PHOTOS_HISTORIQUE_PACKAGE_FAILED,
      })
    )
  }

  //Fetch Etat Package
  if (action.type === OrderPackageConstants.GET_ETAT_PACKAGE) {
    const { ldv } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}stock/getStatusPackages?code=${ldv}`,
        success: OrderPackageConstants.GET_ETAT_PACKAGE_SUCCESS,
        failure: OrderPackageConstants.GET_ETAT_PACKAGE_FAILED,
      })
    )
  }

  //Livraison Partielle
  if (action.type === OrderPackageConstants.LIVRAISON_PARTIELLE_PACKAGE) {
    const { payload } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}stock/createCoursePartialDelivery`,
        data: payload,
        success: OrderPackageConstants.LIVRAISON_PARTIELLE_PACKAGE_SUCCESS,
        failure: OrderPackageConstants.LIVRAISON_PARTIELLE_PACKAGE_FAILED,
      })
    )
  }

  if (action.type === OrderPackageConstants.LIVRAISON_PARTIELLE_PACKAGE_SUCCESS) {
    const { response } = action.payload.data
    if (response) window.location = `/deliveries/${response}`
    store.dispatch(setLoading())
  }
  //Fetch config MDM  (Pointage)
  if (action.type === OrderPackageConstants.GET_PAKAGE_CONFIG_MDM) {
    const { codeCanal } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}canalVenteConfigMeta?codeCanal=${codeCanal}&metaKey=steps`,
        success: OrderPackageConstants.GET_PAKAGE_CONFIG_MDM_SUCCESS,
        failure: OrderPackageConstants.GET_PAKAGE_CONFIG_MDM_FAILED,
      })
    )
  }

  //Upload image mdm
  if (action.type === OrderPackageConstants.UPLOAD_IMAGE_MDM) {
    const { barCode, file } = action.payload

    store.dispatch(
      httpPost({
        endPoint: `${api}corner/traceability/pictures/add?barcode=${barCode}`,
        data: file,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: OrderPackageConstants.UPLOAD_IMAGE_MDM_SUCCESS,
        failure: OrderPackageConstants.UPLOAD_IMAGE_MDM_FAILED,
      })
    )
  }

  //Edit package
  if (action.type === OrderPackageConstants.EDIT_PACKAGE) {
    const { payload, id } = action.payload

    store.dispatch(
      httpPatch({
        endPoint: `${api}stock/updatePackage/${id}`,
        data: payload,
        success: OrderPackageConstants.EDIT_PACKAGE_SUCCESS,
        failure: OrderPackageConstants.EDIT_PACKAGE_FAILED,
      })
    )
  }
  next(action)
}
export default OrderPackageMiddleware
