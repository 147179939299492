import {
  CALCULATE_DIRECTION,
  DISTANCE_CALCULATED,
  googleMapDirectionConstants,
  LOAD_SCRIPT_SUCCESS,
  LOAD_SCRIPT_ERROR,
} from './constants'

export const getGoogleMapDirectionRequest = (id, origin, destination) => ({
  type: googleMapDirectionConstants.GET_REQUEST,
  payload: { id, origin, destination },
})

export const getGoogleMapDirectionSuccess = (data) => ({
  type: googleMapDirectionConstants.GET_SUCCESS,
  payload: { directionRoute: data },
})
export const getGoogleMapDirectionError = () => ({
  type: googleMapDirectionConstants.GET_ERROR,
  payload: {},
})

export const resetDirections = () => ({
  type: googleMapDirectionConstants.RESET_DIRECTION,
  payload: {},
})

export const findDirectionAction = (id, origin, destination) => ({
  type: googleMapDirectionConstants.FIND_DIRECTION,
  payload: { id, origin, destination },
})

export const findDirectionSuccess = (data) => ({
  type: googleMapDirectionConstants.FIND_DIRECTION_SUCCESS,
  payload: { directionRoute: data },
})

export const findDirectionError = () => ({
  type: googleMapDirectionConstants.FIND_DIRECTION_ERROR,
  payload: {},
})

export const getDirectionRouteList = () => ({
  type: googleMapDirectionConstants.GET_LIST,
  payload: {},
})
export const setDirectionRouteList = (data) => ({
  type: googleMapDirectionConstants.SET_LIST,
  payload: { data },
})
export const calculateDistance = (origin, destination) => ({
  type: CALCULATE_DIRECTION,
  payload: { origin, destination },
})
export const distanceCalculated = (distance) => ({
  type: DISTANCE_CALCULATED,
  payload: { distance },
})

export const loadScriptSuccess = () => ({
  type: LOAD_SCRIPT_SUCCESS,
  payload: {},
})
export const loadScriptError = () => ({
  type: LOAD_SCRIPT_ERROR,
  payload: {},
})
