import React from 'react'

function Horloge(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill={props.color}
        d="M7.992 0A8 8 0 1016 8a8 8 0 00-8.008-8zM8 14.4A6.4 6.4 0 1114.4 8 6.4 6.4 0 018 14.4zM8.4 4H7.2v4.8l4.2 2.52.6-.984L8.4 8.2z"
      ></path>
    </svg>
  )
}

export default Horloge
