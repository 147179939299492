import { connect } from 'react-redux'

import {
  fetchSearchOngletCornerSave,
  getOrderByFilter,
  getOrderReasons,
  initCheckCourse,
} from '../../../redux/order/actions'
import {
  filterChanged,
  getMacroCanalRequest,
  getPrestationsListRequest,
  getSalesChannelListRequest,
  setError,
  getAllSubject,
  fetchtDriversFilter,
  getDepartementsListRequest,
  getTourList,
  getWareHouseListRequest,
  resetFromAppliquer,
} from '../../../redux/filter/actions'

import FilterCourse from '../../../components/NavFilter/components/ButtonFilter'
import { fetchLivraison, fetchFreights } from '../../../redux/documents/actions'

import { getToursFromPolaris } from '../../../redux/simulateTournee/actions'
import { getEntryConversationAction } from '../../../redux/discussion/conversations/action'
import {
  getFromAppliquer,
  getDisplayGrid,
  getIdOngletFilterOpen,
  getSearchCornerOnglet,
  getFiltersCollaborateur,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  fromAppliquer: getFromAppliquer(state),
  displayGrid: getDisplayGrid(state),
  idOngletFilterOpen: getIdOngletFilterOpen(state),
  searchReducer: getSearchCornerOnglet(state),
  filtersCollaborateur: getFiltersCollaborateur(state),
})
const mapDisptachToProps = (dispatch) => ({
  onFilterValuesChanged: (
    filterValues,
    isEntrepot,
    isFreight,
    isTournee,
    isDiscussion,
    search,
    searchCorner
  ) => {
    dispatch(filterChanged(filterValues))
    dispatch(initCheckCourse())
    isTournee
      ? dispatch(getToursFromPolaris())
      : isEntrepot
      ? dispatch(fetchLivraison())
      : isFreight
      ? dispatch(fetchFreights())
      : isDiscussion
      ? dispatch(getEntryConversationAction())
      : search
      ? dispatch(fetchSearchOngletCornerSave(searchCorner))
      : dispatch(getOrderByFilter())
  },
  setError: () => dispatch(setError()),

  getTourList: (filter) => dispatch(getTourList(filter)),
  getWareHouses: () => dispatch(getWareHouseListRequest()),
  getSalesChannels: () => dispatch(getSalesChannelListRequest()),
  getAllPrestations: () => dispatch(getPrestationsListRequest()),
  getMacroCanal: () => dispatch(getMacroCanalRequest()),
  getAllDrivers: () => dispatch(fetchtDriversFilter()),
  getAllDepartements: () => dispatch(getDepartementsListRequest()),
  getAllSubject: () => dispatch(getAllSubject()),
  resetFromAppliquer: () => dispatch(resetFromAppliquer()),
  getRDVImpoMOtifs: (payload) => dispatch(getOrderReasons(payload)),
})

export default connect(mapStateToProps, mapDisptachToProps)(FilterCourse)
