//import axios from 'axios'

import {
  estimateShipmentPriceSuccess,
  estimateShipmentPriceError,
  estimateAssemblyPriceSuccess,
  estimateAssemblyPriceError,
} from './actions'
import { estimatePriceConstants } from './constants'
import {
  estimateAssemblyPrice,
  estimateDeliveryPrice,
  estimatePriceByRule,
} from '../../utils/estimatePrice'

export default (store) => (next) => (action) => {
  if (action.type === estimatePriceConstants.ESTIMATE_SHIPMENT_PRICE_REQUEST) {
    // TODO : change rules list source
    const {
      shipment: { rulesList },
    } = store.getState()
    const {
      id,
      selectedSalesChannel,
      direction,
      date,
      time,
      purchaseAmount,
      nbItems,
      weight,
      manutention,
      manutentionDouble,
      etage,
      success,
      failure,
    } = action.payload
    if (selectedSalesChannel.code === 'CORNER-456') {
      const selectedChannelRule = rulesList.find((rule) =>
        rule.canalVente.includes('CORNER-456')
      )
      estimatePriceByRule(
        selectedSalesChannel,
        direction,
        date,
        time,
        purchaseAmount,
        nbItems,
        weight,
        etage,
        manutention,
        manutentionDouble,
        selectedChannelRule
      )
        .then(({ data }) => {
          const parsedData = {
            response: {
              MontantTotalTTC: data.price,
              MontantTotalHT: data.price * 0.8,
              idTarification: null,
            },
          }
          store.dispatch(estimateShipmentPriceSuccess(success, id, parsedData))
        })
        .catch((err) => {
          store.dispatch(estimateShipmentPriceError(failure, id, err))
        })
    } else {
      estimateDeliveryPrice(
        selectedSalesChannel,
        direction,
        date,
        time,
        purchaseAmount,
        nbItems,
        weight,
        manutention,
        manutentionDouble
      )
        .then(({ data }) => {
          store.dispatch(estimateShipmentPriceSuccess(success, id, data))
        })
        .catch((err) => {
          store.dispatch(estimateShipmentPriceError(failure, id, err))
        })
    }
  }
  if (action.type === estimatePriceConstants.ESTIMATE_ASSEMBLY_PRICE_REQUEST) {
    const {
      id,
      selectedSalesChannel,
      purchaseAmount,
      date,
      time,
      success,
      failure,
    } = action.payload
    estimateAssemblyPrice(selectedSalesChannel, purchaseAmount, date, time)
      .then(({ data }) =>
        store.dispatch(estimateAssemblyPriceSuccess(success, id, data))
      )
      .catch((err) => store.dispatch(estimateAssemblyPriceError(failure, id, err)))
  }

  next(action)
}
