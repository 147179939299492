import queryString from 'query-string'
import {
  FETCH_CLIENT_CRITERIA,
  FETCH_CLIENT_CRITERIA_FAILURE,
  FETCH_CLIENT_CRITERIA_SUCCESS,
  VERIFY_PROMO_CODE,
  VERIFY_PROMO_CODE_SUCCESS,
  VERIFY_PROMO_CODE_FAILURE,
  SHIPMENT_NOTIFY,
  ADD_ASSEMBLY_ATTACHMENTS,
  ADD_ASSEMBLY_ATTACHMENTS_SUCCESS,
  ADD_ASSEMBLY_ATTACHMENTS_FAILURE,
  ADD_DELIVERY_ATTACHMENTS,
  ADD_DELIVERY_ATTACHMENTS_SUCCESS,
  ADD_DELIVERY_ATTACHMENTS_FAILURE,
  NOTIFY_SUCCESS,
  NOTIFY_FAILURE,
  FETCH_ONEWEEK_SHIPMENTS,
  FETCH_ONEWEEK_SHIPMENTS_SUCCESS,
  FETCH_ONEWEEK_SHIPMENTS_FAILURE,
  UPDATE_SHIPMENT,
  UPDATE_SHIPMENT_SUCCESS,
  UPDATE_SHIPMENT_FAILURE,
  UPDATE_SHIPMENT_NOTIFY,
  CREATE_NEW_ORDER,
  CREATE_NEW_ORDER_SUCCESS,
  CREATE_NEW_ORDER_FAILURE,
  VIEW_FILES,
  VIEW_FILES_SUCCESS,
  VIEW_FILES_FAILURE,
  DOWNLOAD_FILES,
  DOWNLOAD_FILES_SUCCESS,
  DOWNLOAD_FILES_FAILURE,
  REMOVE_ORDER,
  REMOVE_ASSEMBLY,
  ESTIMATE_SHIPMENT_PRICE_REQUEST,
  ESTIMATE_SHIPMENT_PRICE_SUCCESS,
  ESTIMATE_SHIPMENT_PRICE_ERROR,
  ESTIMATE_ASSEMBLY_PRICE_REQUEST,
  ESTIMATE_ASSEMBLY_PRICE_SUCCESS,
  ESTIMATE_ASSEMBLY_PRICE_ERROR,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILED,
  GET_CANAL_VENTE,
  GET_CANAL_VENTE_SUCCESS,
  GET_CANAL_VENTE_FAILURE,
  GET_RULES,
  GET_RULES_SUCCESS,
  GET_RULES_FAILURE,
} from './actionsConsts'
import {
  dispatchAssemblyNotify,
  dispatchShipmentNotify,
  dispatchUpdateShipmentNotify,
  dispatchAssemblyShipmentNotify,
  addAssemblyAttachments,
  addDeliveryAttachments,
} from './actions'
import { httpFetch, httpGet, httpPost, httpPostCallbackData } from '../http/actions'
import { axiosHTTP, checkExpirationDate } from '../../utils'
import moment from 'moment'
import { COLLABORATOR_EXPIRATION_DATE } from '../../utils/checkExpirationDate'
import { ORDERS_STATUS } from '../../utils/values'

import { removeMarkerAction, removeDirectionAction } from './actions'
import {
  estimateShipmentPriceRequest,
  estimateAssemblyPriceRequest,
} from '../estimatePrice/actions'

const courseType = {
  COURSE: 'course',
  MONTAGE: 'montage',
  COURSE_MONTAGE: 'course_montage',
}
const api = process.env.REACT_APP_API_URL

export const removeNull = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key]
    }
  }
  return obj
}

let CancelToken = axiosHTTP.CancelToken
let cancel

export default (store) => (next) => (action) => {
  if (action.type === FETCH_CLIENT_CRITERIA) {
    const { criteria } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}rechercheClient?criteria=${criteria}&limit=6`,
        success: FETCH_CLIENT_CRITERIA_SUCCESS,
        failure: FETCH_CLIENT_CRITERIA_FAILURE,
      })
    )
  }

  if (action.type === VERIFY_PROMO_CODE) {
    const { codePromo } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}checkCodePromo?codePromo=${codePromo}`,
        success: VERIFY_PROMO_CODE_SUCCESS,
        failure: VERIFY_PROMO_CODE_FAILURE,
      })
    )
  }
  if (action.type === CREATE_NEW_ORDER) {
    const { order, type, statement } = action.payload
    store.dispatch(
      httpPostCallbackData({
        endPoint: `${api}newOrder?CommandeType=${type}`,
        data: order,
        success: CREATE_NEW_ORDER_SUCCESS,
        failure: CREATE_NEW_ORDER_FAILURE,
        secData: statement,
      })
    )
  }
  if (action.type === CREATE_NEW_ORDER_SUCCESS) {
    const { courses, assemblies } = action.payload.data.response
    let statement = action.payload.data.secData
    statement = statement.map((e, i) => {
      if (e.orderType === courseType.COURSE) {
        const order = courses[i]
        return {
          ...e,
          codeCourse: order.code,
          codeAssembly: null,
        }
      } else if (e.orderType === courseType.COURSE_MONTAGE) {
        const order = courses[i]
        return {
          ...e,
          codeCourse: order.code,
          codeAssembly: order.assembly,
        }
      } else {
        const order = assemblies[i - courses?.length || 0]
        return {
          ...e,
          codeAssembly: order.assembly,
        }
      }
    })
    statement.forEach((order) => {
      const {
        shipment: { devis },
      } = store.getState()
      if (order.orderType === courseType.COURSE && order.codeCourse && !devis) {
        if (order.courseFiles) {
          store.dispatch(addDeliveryAttachments(order.codeCourse, order.courseFiles))
        }
        if (order.notify) {
          store.dispatch(dispatchShipmentNotify(order.codeCourse))
        }
      }
      if (order.orderType === courseType.COURSE_MONTAGE && order.codeCourse) {
        if (order.courseFiles) {
          store.dispatch(addDeliveryAttachments(order.codeCourse, order.courseFiles))
        }
        if (order.assemblyFiles && order.codeAssembly) {
          store.dispatch(
            addAssemblyAttachments(order.codeAssembly, order.assemblyFiles)
          )
        }

        if (order.notify) {
          if (order.codeAssembly) {
            store.dispatch(
              dispatchAssemblyShipmentNotify(order.codeCourse, order.codeAssembly)
            )
          } else {
            store.dispatch(dispatchShipmentNotify(order.codeCourse))
          }
        }
      }
      if (order.orderType === courseType.MONTAGE && order.codeAssembly) {
        if (order.assemblyFiles) {
          store.dispatch(
            addAssemblyAttachments(order.codeAssembly, order.assemblyFiles)
          )
        }

        if (order.notify) {
          store.dispatch(dispatchAssemblyNotify(order.codeAssembly))
        }
      }
    })
  }

  if (action.type === ADD_ASSEMBLY_ATTACHMENTS) {
    const { codeAssembly, files } = action.payload
    let formData = new FormData()
    files.forEach((file, index) => {
      formData.append(`files-${index + 1}`, file)
    })
    store.dispatch(
      httpPost({
        endPoint: `${api}assembly/attachments/add?codeAssembly=${codeAssembly}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: ADD_ASSEMBLY_ATTACHMENTS_SUCCESS,
        failure: ADD_ASSEMBLY_ATTACHMENTS_FAILURE,
      })
    )
  }

  if (action.type === ADD_DELIVERY_ATTACHMENTS) {
    const { codeCourse, files } = action.payload
    let formData = new FormData()
    files.forEach((file, index) => {
      formData.append(`files-${index + 1}`, file)
    })
    store.dispatch(
      httpPost({
        endPoint: `${api}course/attachments/add?codeCourse=${codeCourse}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: ADD_DELIVERY_ATTACHMENTS_SUCCESS,
        failure: ADD_DELIVERY_ATTACHMENTS_FAILURE,
      })
    )
  }

  if (action.type === SHIPMENT_NOTIFY) {
    store.dispatch(
      httpPost({
        endPoint: `${api}commande/notify`,
        data: action.payload,
        success: NOTIFY_SUCCESS,
        failure: NOTIFY_FAILURE,
      })
    )
  }
  if (action.type === UPDATE_SHIPMENT_NOTIFY) {
    console.log('Notify ', action.payload)
    store.dispatch(
      httpPost({
        endPoint: `${api}updateCourse/notify`,
        data: action.payload,
        success: NOTIFY_SUCCESS,
        failure: NOTIFY_FAILURE,
      })
    )
  }
  if (action.type === FETCH_ONEWEEK_SHIPMENTS) {
    const startTime = moment(new Date()).format('YYYY-MM-DD')
    const endTime = moment(new Date()).add(7, 'days').format('YYYY-MM-DD')
    const salesChannelCode = action.payload.salesChannelCode
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    store.dispatch(
      httpPost({
        endPoint: `${api}getCoursesByFilter`,
        data: {
          codeCanal: salesChannelCode,
          startTime,
          endTime,
          codeStatus: ORDERS_STATUS,
        },
        success: FETCH_ONEWEEK_SHIPMENTS_SUCCESS,
        failure: FETCH_ONEWEEK_SHIPMENTS_FAILURE,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
      })
    )
  }

  if (action.type === UPDATE_SHIPMENT) {
    store.dispatch(
      httpPostCallbackData({
        endPoint: `${api}updateCourse`,
        data: {
          ...action.payload.shipment,
          silentEdit: undefined,
        },
        success: UPDATE_SHIPMENT_SUCCESS,
        failure: UPDATE_SHIPMENT_FAILURE,
        secData: {
          silentEdit: action.payload.shipment.silentEdit,
          codeCourse: action.payload.shipment.codeCourse,
        },
      })
    )
  }

  if (action.type === UPDATE_SHIPMENT_SUCCESS) {
    const { codeCourse, silentEdit } = action.payload.data.secData

    if (!silentEdit) store.dispatch(dispatchUpdateShipmentNotify(codeCourse))
  }

  if (action.type === VIEW_FILES) {
    const {
      fileDetail: { generateApi, code, codeType },
    } = action.payload
    checkExpirationDate(COLLABORATOR_EXPIRATION_DATE)
    const token = localStorage.getItem('token')

    store.dispatch(
      httpFetch({
        endPoint: `${api}${generateApi}?${codeType}=${code}&token=${encodeURIComponent(
          token
        )}`,
        success: VIEW_FILES_SUCCESS,
        failure: VIEW_FILES_FAILURE,
      })
    )
  }

  if (action.type === DOWNLOAD_FILES) {
    const {
      fileDetail: { generateApi, code, codeType },
    } = action.payload
    checkExpirationDate(COLLABORATOR_EXPIRATION_DATE)
    const token = localStorage.getItem('token')

    store.dispatch(
      httpFetch({
        endPoint: `${api}${generateApi}?${codeType}=${code}&token=${encodeURIComponent(
          token
        )}`,
        success: DOWNLOAD_FILES_SUCCESS,
        failure: DOWNLOAD_FILES_FAILURE,
      })
    )
  }

  if (action.type === DOWNLOAD_FILES_SUCCESS) {
    const { data } = action.payload

    const token = localStorage.getItem('token')

    const dataSend = {
      url: data.data,
      token,
    }
    window.open(`${api}downloadimage?${queryString.stringify(dataSend)}`)
  }

  if (action.type === REMOVE_ORDER) {
    const order = action.payload.order
    if (order?.assembly) {
      store.dispatch(removeMarkerAction(order.assembly.id))
    }
    if (order?.shipment) {
      store.dispatch(removeDirectionAction(order.shipment.id))
    }
  }
  if (action.type === REMOVE_ASSEMBLY) {
    const order = action.payload.order
    if (order?.ssembly) {
      store.dispatch(removeMarkerAction(order.assembly.id))
    }
  }

  if (action.type === ESTIMATE_SHIPMENT_PRICE_REQUEST) {
    store.dispatch(
      estimateShipmentPriceRequest({
        id: action.payload.orderId,
        selectedSalesChannel: action.payload.selectedSalesChannel,
        direction: action.payload.direction,
        date: action.payload.date,
        time: action.payload.time,
        purchaseAmount: action.payload.purchaseAmount,
        nbItems: action.payload.nbItems,
        weight: action.payload.weight,
        manutention: action.payload.manutention,
        manutentionDouble: action.payload.manutentionDouble,
        etage: action.payload.etage,
        success: ESTIMATE_SHIPMENT_PRICE_SUCCESS,
        failure: ESTIMATE_SHIPMENT_PRICE_ERROR,
      })
    )
  }
  if (action.type === ESTIMATE_ASSEMBLY_PRICE_REQUEST) {
    store.dispatch(
      estimateAssemblyPriceRequest({
        id: action.payload.orderId,
        selectedSalesChannel: action.payload.selectedSalesChannel,
        purchaseAmount: action.payload.purchaseAmount,
        date: action.payload.date,
        time: action.payload.time,
        success: ESTIMATE_ASSEMBLY_PRICE_SUCCESS,
        failure: ESTIMATE_ASSEMBLY_PRICE_ERROR,
      })
    )
  }
  if (action.type === GET_ORDER) {
    const { orderId } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}getCourseDetails?codeCourse=${orderId}`,
        data: {},
        success: GET_ORDER_SUCCESS,
        failure: GET_ORDER_FAILED,
      })
    )
  }

  if (action.type === GET_CANAL_VENTE) {
    const { codeCanal } = action.payload
    const data = {
      codeCanal,
    }
    store.dispatch(
      httpGet({
        endPoint: `${api}getCanalVenteDetailsCorner?${queryString.stringify(data)}`,
        success: GET_CANAL_VENTE_SUCCESS,
        failure: GET_CANAL_VENTE_FAILURE,
      })
    )
  }

  if (action.type === GET_RULES) {
    store.dispatch(
      httpGet({
        endPoint: `${api}canalVente/priceRule`,
        success: GET_RULES_SUCCESS,
        failure: GET_RULES_FAILURE,
      })
    )
  }
  next(action)
}
