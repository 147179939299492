export const FETCH_CLIENT_CRITERIA = 'FETCH_CLIENT_CRITERIA'
export const FETCH_CLIENT_CRITERIA_SUCCESS = 'FETCH_CLIENT_CRITERIA_SUCCESS'
export const FETCH_CLIENT_CRITERIA_FAILURE = 'FETCH_CLIENT_CRITERIA_FAILURE'

export const HIDE_POP_UP = 'HIDE_POP_UP'

export const VERIFY_PROMO_CODE = 'VERIFY_PROMO_CODE'
export const VERIFY_PROMO_CODE_SUCCESS = 'VERIFY_PROMO_CODE_SUCCESS'
export const VERIFY_PROMO_CODE_FAILURE = 'VERIFY_PROMO_CODE_FAILURE'

export const UPDATE_SHIPMENT = 'UPDATE_SHIPMENT'
export const UPDATE_SHIPMENT_SUCCESS = 'UPDATE_SHIPMENT_SUCCESS'
export const UPDATE_SHIPMENT_FAILURE = 'UPDATE_SHIPMENT_FAILURE'

export const CHANGE_SEARCH_CRITERIA_BY_PHONE = 'CHANGE_SEARCH_CRITERIA_BY_PHONE'

export const SHIPMENT_NOTIFY = 'SHIPMENT_NOTIFY'
export const UPDATE_SHIPMENT_NOTIFY = 'UPDATE_SHIPMENT_NOTIFY'

export const ADD_ASSEMBLY_ATTACHMENTS = 'ADD_ASSEMBLY_ATTACHMENTS'
export const ADD_ASSEMBLY_ATTACHMENTS_SUCCESS = 'ADD_ASSEMBLY_ATTACHMENTS_SUCCESS'
export const ADD_ASSEMBLY_ATTACHMENTS_FAILURE = 'ADD_ASSEMBLY_ATTACHMENTS_FAILURE'

export const ADD_DELIVERY_ATTACHMENTS = 'ADD_DELIVERY_ATTACHMENTS'
export const ADD_DELIVERY_ATTACHMENTS_SUCCESS = 'ADD_DELIVERY_ATTACHMENTS_SUCCESS'
export const ADD_DELIVERY_ATTACHMENTS_FAILURE = 'ADD_DELIVERY_ATTACHMENTS_FAILURE'

export const SET_PAYMENT_FORM = 'SET_PAYMENT_FORM'
export const COMMAND_CLICK = 'COMMAND_CLICK'

export const ASSEMBLY_SAVED = 'ASSEMBLY_SAVED'
export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS'
export const NOTIFY_FAILURE = 'NOTIFY_FAILURE'

export const FETCH_ONEWEEK_SHIPMENTS = 'FETCH_ONEWEEK_SHIPMENTS'
export const FETCH_ONEWEEK_SHIPMENTS_SUCCESS = 'FETCH_ONEWEEK_SHIPMENTS_SUCCESS'
export const FETCH_ONEWEEK_SHIPMENTS_FAILURE = 'FETCH_ONEWEEK_SHIPMENTS_FAILURE'

export const REMOVE_ERROR = 'REMOVE_ERROR'

export const CREATE_NEW_ORDER = 'CREATE_ORDER'
export const CREATE_NEW_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_NEW_ORDER_FAILURE = 'CREATE_ORDER_FAILURE'

export const CHANGE_STATUS_DEVIS = 'CHANGE_STATUS_DEVIS'

export const VIEW_FILES = 'VIEW_FILES'
export const VIEW_FILES_SUCCESS = 'VIEW_FILES_SUCCESS'
export const VIEW_FILES_FAILURE = 'VIEW_FILES_FAILURE'

export const DOWNLOAD_FILES = 'DOWNLOAD_FILES'
export const DOWNLOAD_FILES_SUCCESS = 'DOWNLOAD_FILES_SUCCESS'
export const DOWNLOAD_FILES_FAILURE = 'DOWNLOAD_FILES_FAILURE'

export const INIT_ORDER = 'PLACE_ORDER_INIT'
export const RESET_ORDER = 'RESET_ORDER'
export const ADD_ORDER = 'PLACE_ORDER_ADD_ORDER'
export const SET_INFO_COMP = 'SET_INFO_COMP'
export const SET_INFO_COMP_EDIT = 'SET_INFO_COMP_EDIT'
export const UPDATE_SHIPMENT_DATA = 'PLACE_ORDER_UPDATE_SHIPMENT_DATA'
export const UPDATE_ASSEMBLY_DATA = 'PLACE_ORDER_UPDATE_ASSEMBLY_DATA'
export const SET_ACTIVE_STEP = 'PLACE_ORDER_SET_ACTIVE_STEP'
export const SUBMIT_FIRST_STEP = 'PLACE_ORDER_SUBMIT_FIRST_STEP'
export const SET_SHIPMENT_ERRORS = 'PLACE_ORDER_SET_SHIPMENT_ERRORS'
export const SET_ASSEMBLY_ERRORS = 'PLACE_ORDER_SET_ASSEMBLY_ERRORS'
export const SET_SHIPMENT_PRICE = 'PLACE_ORDER_SET_SHIPMENT_PRICE'
export const SET_ASSEMBLY_PRICE = 'PLACE_ORDER_SET_ASSEMBLY_PRICE'
export const REMOVE_MARKER = 'PLACE_ORDER_REMOVE_MARKER'
export const REMOVE_DIRECTION = 'PLACE_ORDER_REMOVE_DIRECTION'
export const UPDATE_DIRECTIONS = 'PLACE_ORDER_UPDATE_DIRECTIONS'
export const UPDATE_MARKERS = 'PLACE_ORDER_UPDATE_MARKERS'
export const ADD_ASSEMBLY = 'PLACE_ORDER_ADD_ASSEMBLY'
export const ADD_SHIPMENT = 'PLACE_ORDER_ADD_SHIPMENT'
export const GET_ORDER = 'PLACE_ORDER_GET_ORDER'
export const GET_ORDER_SUCCESS = 'PLACE_ORDER_GET_ORDER_SUCCESS'
export const GET_ORDER_FAILED = 'PLACE_ORDER_GET_ORDER_FAILED'
export const REMOVE_ORDER = 'PLACE_ORDER_REMOVE_ORDER'
export const REMOVE_ASSEMBLY = 'PLACE_ORDER_REMOVE_ASSEMBLY'
export const REMOVE_SHIPMENT = 'PLACE_ORDER_REMOVE_SHIPMENT'
export const SET_CLIENT = 'PLACE_ORDER_SET_CLIENT'
export const SUBMIT_SECOND_STEP = 'PLACE_ORDER_SUBMIT_SECOND_STEP'
export const SET_SELECTED_CLIENT = 'PLACE_ORDER_SET_SELECTED_CLIENT'
export const SET_IS_NEW_CLIENT = 'PLACE_ORDER_SET_IS_NEW_CLIENT'
export const SET_CLIENT_ERRORS = 'PLACE_ORDER_SET_CLIENT_ERRORS'
export const ESTIMATE_SHIPMENT_PRICE_REQUEST =
  'PLACE_ORDER_ESTIMATE_SHIPMENT_PRICE_REQUEST'
export const ESTIMATE_SHIPMENT_PRICE_SUCCESS =
  'PLACE_ORDER_ESTIMATE_SHIPMENT_PRICE_SUCCESS'
export const ESTIMATE_SHIPMENT_PRICE_ERROR =
  'PLACE_ORDER_ESTIMATE_SHIPMENT_PRICE_ERROR'
export const ESTIMATE_ASSEMBLY_PRICE_REQUEST =
  'PLACE_ORDER_ESTIMATE_ASSEMBLY_PRICE_REQUEST'
export const ESTIMATE_ASSEMBLY_PRICE_SUCCESS =
  'PLACE_ORDER_ESTIMATE_ASSEMBLY_PRICE_SUCCESS'
export const ESTIMATE_ASSEMBLY_PRICE_ERROR =
  'PLACE_ORDER_ESTIMATE_ASSEMBLY_PRICE_ERROR'

export const GET_CANAL_VENTE = 'GET_CANAL_VENTE'
export const GET_CANAL_VENTE_SUCCESS = 'GET_CANAL_VENTE_SUCCESS'
export const GET_CANAL_VENTE_FAILURE = 'GET_CANAL_VENTE_FAILURE'

export const GET_RULES = 'GET_RULES'
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS'
export const GET_RULES_FAILURE = 'GET_RULES_FAILURE'
