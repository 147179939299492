import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import SettingsIcon from '@mui/icons-material/Settings'
import PropTypes from 'prop-types'
import { Box, Divider, Grow, IconButton, Popper, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

export default function ActionCoursetournee({
  index,
  planning,
  tourAction,
  disabledActionMov,
  moveTableTournee,
  deattributeCourse,
  isTableTournee,
  moveTableUnserved,
}) {
  const [dropdown, setDropdown] = useState('')
  const [dropdownEl, setDropdownEl] = useState('')
  const [nestedDropdown, setNestedDropdown] = useState('')
  const [nestedDropdownEl, setNestedDropdownEl] = useState('')
  const [nestedDropdownName, setNestedDropdownName] = useState('')
  // Routes dropdown menu
  const dropdownMenu = (
    <Popper
      anchorEl={dropdown}
      popperRef={null}
      open={Boolean(dropdown)}
      placement="bottom-start"
      transition
      style={{ zIndex: 2500 }}
      onMouseEnter={() => setDropdown(dropdownEl)}
      onMouseLeave={() => {
        if (!nestedDropdown) {
          setDropdown(null)
        }
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: 'left top',
            background: '#ffffff',
            borderRadius: '0.5rem',
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0rem 0.625rem 0.9375rem -0.1875rem, rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.125rem',
          }}
        >
          <Box sx={{ borderRadius: '0.5rem', boxShadow: 'none' }}>
            <Box
              sx={{
                padding: '8px',
                marginTop: '8px',
                borderRadius: '0.5rem',
                boxShadow:
                  'rgba(0, 0, 0, 0.1) 0rem 0.625rem 0.9375rem -0.1875rem, rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.125rem',
              }}
            >
              <Typography
                key={'debut_tournee'}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                variant="button"
                textTransform="unset"
                minWidth={'12rem'}
                color={'text'}
                fontWeight={'500'}
                py={0.625}
                px={2}
                sx={{
                  backgroundColor: 'debut' == nestedDropdownName && '#1976d261',
                  borderRadius: '0.375rem',
                  cursor: 'pointer',
                  transition: 'all 300ms linear',
                  '&:hover': {
                    backgroundColor: '#1976d261',
                    color: '#344767',
                    '& *': {
                      color: '#344767',
                    },
                  },
                }}
                onMouseEnter={({ currentTarget }) => {
                  setNestedDropdown(currentTarget)
                  setNestedDropdownEl(currentTarget)
                  setNestedDropdownName('debut')
                }}
                onMouseLeave={() => {
                  setNestedDropdown(null)
                }}
              >
                {'Placer au début de la tournée'}

                <KeyboardArrowRightIcon
                  fontSize="small"
                  sx={{ fontWeight: 'normal', verticalAlign: 'middle', mr: -0.5 }}
                />
              </Typography>
              <Typography
                key={'debut_tournee'}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                variant="button"
                textTransform="unset"
                minWidth={'12rem'}
                color={'text'}
                fontWeight={'500'}
                py={0.625}
                px={2}
                sx={{
                  backgroundColor: 'fin' === nestedDropdownName && '#1976d261',
                  borderRadius: '0.375rem',
                  cursor: 'pointer',
                  transition: 'all 300ms linear',
                  '&:hover': {
                    backgroundColor: '#1976d261',
                    color: '#344767',
                    '& *': {
                      color: '#344767',
                    },
                  },
                }}
                onMouseEnter={({ currentTarget }) => {
                  setNestedDropdown(currentTarget)
                  setNestedDropdownEl(currentTarget)
                  setNestedDropdownName('fin')
                }}
                onMouseLeave={() => {
                  setNestedDropdown(null)
                }}
              >
                {'Placer à la fin de la tournée'}

                <KeyboardArrowRightIcon
                  fontSize="small"
                  sx={{ fontWeight: 'normal', verticalAlign: 'middle', mr: -0.5 }}
                />
              </Typography>
              {isTableTournee && (
                <Typography
                  key={'debut_tournee'}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  variant="button"
                  textTransform="unset"
                  minWidth={'12rem'}
                  color={'text'}
                  fontWeight={'500'}
                  py={0.625}
                  px={2}
                  sx={{
                    borderRadius: '0.375rem',
                    cursor: 'pointer',
                    transition: 'all 300ms linear',
                    '&:hover': {
                      backgroundColor: '#1976d261',
                      color: '#344767',
                      '& *': {
                        color: '#344767',
                      },
                    },
                  }}
                  onMouseEnter={() => {
                    setNestedDropdownName('')
                  }}
                  onClick={() => deattributeCourse(tourAction)}
                >
                  {'Désattribuer course'}
                </Typography>
              )}
            </Box>
          </Box>
        </Grow>
      )}
    </Popper>
  )

  function moveElement(arr, elementIndexed, toIndex, isTableTournee) {
    if (isTableTournee) {
      const fromIndex = arr
        .map((el) => el.driver.id)
        .indexOf(elementIndexed.driver.id)
      const element = arr.splice(fromIndex, 1)[0] // Retire l'élément de sa position d'origine
      arr.splice(toIndex, 0, element) // Insère l'élément à la nouvelle position
    }
    return arr
  }
  // Dropdown menu for the nested dropdowns
  const nestedDropdownMenu = (
    <Popper
      anchorEl={nestedDropdown}
      popperRef={null}
      open={Boolean(nestedDropdown)}
      placement="right-start"
      transition
      style={{ zIndex: 2500 }}
      onMouseEnter={() => {
        setNestedDropdown(nestedDropdownEl)
      }}
      onMouseLeave={() => {
        setNestedDropdown(null)
        setNestedDropdownName('')
        setDropdown(null)
      }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: 'left top',
            background: '#ffffff',
            borderRadius: '0.5rem',
            boxShadow:
              'rgba(0, 0, 0, 0.1) 0rem 0.625rem 0.9375rem -0.1875rem, rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.125rem',
          }}
        >
          <Box
            sx={{
              borderRadius: '0.5rem',
              boxShadow: 'none',
              marginLeft: '20px',
            }}
          >
            <Box
              sx={{
                boxShadow:
                  'rgba(0, 0, 0, 0.1) 0rem 0.625rem 0.9375rem -0.1875rem, rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.125rem',
                borderRadius: '0.5rem',
                py: 1.5,
                px: 1,
                maxHeight: '500px',
                overflow: 'auto',
              }}
            >
              {'fin' === nestedDropdownName || 'debut' === nestedDropdownName
                ? moveElement(planning, tourAction, 0, isTableTournee).map(
                    (item, index) => {
                      return (
                        <>
                          <Typography
                            key={item.driver.firstName}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            variant="button"
                            textTransform="unset"
                            minWidth={'12rem'}
                            color={'text'}
                            fontWeight={'regular'}
                            py={0.625}
                            px={2}
                            sx={{
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              borderRadius: '0.375rem',
                              cursor: 'pointer',
                              transition: 'all 300ms linear',
                              '&:hover': {
                                backgroundColor: '#1976d261',
                                color: '#42424a',
                                '& *': {
                                  color: '#42424a',
                                },
                              },
                            }}
                            onClick={() => {
                              isTableTournee
                                ? moveTableTournee(
                                    tourAction,
                                    item,
                                    nestedDropdownName
                                  )
                                : moveTableUnserved(item, nestedDropdownName)
                            }}
                          >
                            {`${item.driver.firstName}-${item.driver.name}`}
                          </Typography>
                          {isTableTournee && index == 0 && (
                            <Divider
                              sx={{ margin: '2px', width: '80%', height: '2px' }}
                            />
                          )}
                        </>
                      )
                    }
                  )
                : null}
            </Box>
          </Box>
        </Grow>
      )}
    </Popper>
  )
  return (
    <>
      <TableCell
        key={`column_${index}`}
        align="center"
        sx={{
          backgroundColor: '#F7F8F9',
          color: '#2A304C',
          position: 'sticky',
          top: '0',
          right: '0',
          left: 'auto',
          boxShadow: '-5px 0px 5px -3px #ccc',
        }}
      >
        <IconButton
          aria-label="action"
          disabled={disabledActionMov}
          onClick={({ currentTarget }) => {
            setDropdown(currentTarget)
            setDropdownEl(currentTarget)
          }}
          sx={{
            '&:hover': {
              backgroundColor: 'unset',
            },
            color: disabledActionMov ? 'rgba(0, 0, 0, 0.26)' : '#2A304C',
          }}
        >
          <SettingsIcon
            key={'sections'}
            name={'sections'}
            sx={{
              fontSize: 28,
            }}
          />
        </IconButton>
      </TableCell>

      {dropdownMenu}
      {nestedDropdownMenu}
    </>
  )
}

ActionCoursetournee.propTypes = {
  index: PropTypes.number,
  planning: PropTypes.any,
  tourAction: PropTypes.object,
  disabledActionMov: PropTypes.bool,
  moveTableTournee: PropTypes.func,
  deattributeCourse: PropTypes.func,
  isTableTournee: PropTypes.bool,
  moveTableUnserved: PropTypes.func,
}
