import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from './DatePicker'
import moment from 'moment'
import DrawerFilterDate from '../../../../../Drawer'
import IcOutlineCalendarMonth from '../../../../../../assets/icons/navFilter/IcOutlineCalendarMonth'
import Button from '@mui/material/Button'

export default function DateFilter({
  setSelectedDate,
  deliveryDateFilter,
  filterStateChanged,
  datePickedFiltersValues,
  nameFilter,
  dateFilterLabel,
}) {
  const [opentDrawer, setOpentDrawer] = useState(false)
  const [state, setState] = useState(null)
  const toggleDrawer = (open, event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (!open) {
      if (state) {
        const pickedDate = moment(state).startOf('day').format('YYYY-MM-DD HH:mm')
        if (!deliveryDateFilter) {
          setSelectedDate(pickedDate)
        }
        filterStateChanged(deliveryDateFilter ? 'dropOffDay' : 'datepicked', [
          pickedDate.toString().slice(0, 10),
        ])
      } else {
        filterStateChanged(nameFilter, [])
      }
    }
    setOpentDrawer(open)
  }

  const dateForm = (date) => moment(date).format('DD MMM YYYY')

  useEffect(() => {
    if (datePickedFiltersValues && datePickedFiltersValues.length > 0) {
      const pickedDate = moment(datePickedFiltersValues[0])
        .startOf('day')
        .format('YYYY-MM-DD HH:mm')
      if (!deliveryDateFilter) {
        setSelectedDate(pickedDate)
      }
      setState(new Date(pickedDate))
    } else {
      setState(null)
    }
  }, [datePickedFiltersValues])

  const onCancel = () => {
    setState(null)
  }

  return (
    <DrawerFilterDate
      filterLabel={
        !state
          ? dateFilterLabel
          : dateFilterLabel
          ? dateFilterLabel + ' : ' + dateForm(state)
          : dateForm(state)
      }
      content={<DatePicker state={state} setState={setState} />}
      toggleDrawer={toggleDrawer}
      opentDrawer={opentDrawer}
      Icon={IcOutlineCalendarMonth}
      id={nameFilter}
      actionButtons={
        <div
          style={{
            padding: '10px 20px',
            bottom: '0',
            position: 'fixed',
            width: '280px',
            background: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '18px',
            }}
          >
            <Button
              id={`Annuler-${nameFilter}`}
              variant="contained"
              color="primary"
              onClick={onCancel}
              sx={{
                width: '130px',
                height: '40px',
                color: 'white',
                textTransform: 'none',
                fontSize: '13px',
                fontWeight: '400',
                backgroundColor: '#f61057',
                ':hover': {
                  backgroundColor: '#d32f2f',
                },
              }}
            >
              {'Annuler la date'}
            </Button>
          </div>
        </div>
      }
    />
  )
}
DateFilter.propTypes = {
  setSelectedDate: PropTypes.func,
  deliveryDateFilter: PropTypes.bool,
  dateFilterLabel: PropTypes.string,
  filterStateChanged: PropTypes.func,
  datePickedFiltersValues: PropTypes.array,
  nameFilter: PropTypes.string,
}
