import { connect } from 'react-redux'
import NavBar from '../../../components/NavBar/settings'
import { logout } from '../../../redux/user/actions'
import { getCollaborateur, getUserRole } from '../../../redux/user/selectors'
import { socketDisconnect } from '../../../redux/discussion/socket/action'

const mapStateToProps = (state) => ({
  collaborateur: getCollaborateur(state),
  userRole: getUserRole(state),
})
const mapDisptachToProps = (dispatch) => ({
  userLogout: () => {
    dispatch(socketDisconnect())
    dispatch(logout())
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(NavBar)
