import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import ClotureDialog from '../DetailsDelivery/components/dialog/cloture'

import {
  COURSE_STATUS_CLOTURE,
  COURSE_TYPE_CHANGE_STATUS,
  DEPART_ENTREPOT_CHANGE_STATUS,
  MOBILE_DRIVER,
} from '../../../../utils/values'
import TermineeIcon from '../../../../assets/icons/Cloture/terminee'

import ModalError from '../../../../components/ModalError'
import {
  BoxActions,
  ButtonCloturer,
  ButtonLabel,
  ButtonSpan,
  DividerStyle,
  ListItemTextStyle,
  MenuItemStyle,
  MenuStyle,
} from './style'
import DialogAvertissement from '../../../../components/DialogError/dialogAvertissementClose'

function ActionCourse({
  coursesChecked,
  getMotifsCloture,
  listesMotif,
  isLoadingMotifListes,
  onClotureEnMasse,
  isLoadingActionOrder,
  isActionDone,
  errorChangeStatusEnMasse,
  removeErrorAction,
  isErrorActionWithSuccess,
  setIsActionShipmentDone,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const [clotureObject, setClotureObject] = useState(false)
  const [clotureStatus, setClotureStatus] = useState(null)
  const [clotureDialogOpen, setClotureDialogOpen] = useState(false)

  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [messageError, setMessageError] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getClotureMotif = (status) => {
    const payload = {
      type: MOBILE_DRIVER,
      status: status,
    }
    getMotifsCloture(payload)
  }

  const changeStatusCourse = (status) => {
    if (status.code === 'TERMINEE') {
      getClotureMotif('TERMINEE')
      setClotureStatus('TERMINEE')
      const Cloture = {
        icon: (
          <TermineeIcon
            style={{
              width: '20px',
              height: '20px',
              marginRight: '6px',
              color: 'white',
            }}
          />
        ),
        titre: 'Terminer les courses',
      }
      setClotureObject(Cloture)
      setClotureDialogOpen(true)
    } else if (status.code === 'ENLEVEE') {
      getClotureMotif('ENLEVEE')
      setClotureStatus('ENLEVEE')
      const Cloture = {
        icon: (
          <TermineeIcon
            style={{
              width: '20px',
              height: '20px',
              marginRight: '6px',
              color: 'white',
            }}
          />
        ),
        titre: 'Enlever les courses',
      }
      setClotureObject(Cloture)
      setClotureDialogOpen(true)
    } else {
      handleClose()
    }
  }

  const onClotureCourse = (payloadChangeStatus) => {
    const payload = coursesChecked.map((courseChecked) => {
      if (
        clotureStatus == 'ENLEVEE' &&
        COURSE_TYPE_CHANGE_STATUS.includes(courseChecked?.courseType) &&
        courseChecked?.departureType == DEPART_ENTREPOT_CHANGE_STATUS
      ) {
        courseChecked.state = 'TERMINEE'
      } else {
        courseChecked.state = clotureStatus
      }
      delete courseChecked.departureType
      delete courseChecked.courseType
      return {
        ...courseChecked,
        ...payloadChangeStatus,
      }
    })
    onClotureEnMasse({
      courses: payload,
    })
  }

  useEffect(() => {
    if (isActionDone) {
      setClotureDialogOpen(false)
      setMessageError(null)
    }
  }, [isActionDone])

  const checkCourseSource = (list) => {
    return list.some((item) => item.courseSource !== 'AUTOVER')
  }

  useEffect(() => {
    if (checkCourseSource(coursesChecked)) {
      setOpenErrorDialog(true)
      setMessageError(
        'Vous avez choisis des courses d’autres clients hors Autover, merci de changer la sélection.'
      )
    }
  }, [coursesChecked])

  useEffect(() => {
    if (errorChangeStatusEnMasse) {
      setOpenErrorDialog(true)
      setClotureDialogOpen(false)
      const ldvErrorMessage = errorChangeStatusEnMasse?.failedToTreatCourses
        ?.map((error) => error.course)
        ?.join(', ')
      const messgage =
        ldvErrorMessage && errorChangeStatusEnMasse?.failedToTreatCourses?.length > 1
          ? `Une erreur s'est produite lors de la clôture de les commandes; ${ldvErrorMessage}. Veuillez réessayer plus tard.`
          : ldvErrorMessage &&
            errorChangeStatusEnMasse?.failedToTreatCourses?.length == 1
          ? `Une erreur s'est produite lors de la clôture de la commande; ${ldvErrorMessage}. Veuillez réessayer plus tard.`
          : "Une erreur s'est produite. Veuillez réessayer plus tard."
      setMessageError(messgage)
    }
  }, [errorChangeStatusEnMasse])

  return (
    <>
      {coursesChecked.length !== 0 && (
        <BoxActions>
          <ButtonCloturer
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={handleClick}
            id={'button-Cloturer'}
          >
            <ButtonLabel>
              <ButtonSpan component={'span'}>Clôturer</ButtonSpan>
              <>{anchorEl ? <ExpandMore /> : <ExpandLess />}</>
            </ButtonLabel>
          </ButtonCloturer>
          <MenuStyle
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            {COURSE_STATUS_CLOTURE.map((status, index) => (
              <>
                <MenuItemStyle
                  key={index}
                  onClick={() => {
                    changeStatusCourse(status)
                  }}
                >
                  <ListItemTextStyle
                    id={status.label}
                    primary={status.label}
                    color={status.color}
                    backgroundColor={status.backgroundColor}
                  />
                </MenuItemStyle>
                {index < COURSE_STATUS_CLOTURE.length - 1 && <DividerStyle />}
              </>
            ))}
          </MenuStyle>
        </BoxActions>
      )}
      {clotureDialogOpen && (
        <ClotureDialog
          open={clotureDialogOpen}
          handleClose={() => setClotureDialogOpen(false)}
          clotureObject={clotureObject}
          onClotureCourse={onClotureCourse}
          isLoadingMotifListes={isLoadingMotifListes}
          listesMotif={listesMotif}
          isLoadingActionOrder={isLoadingActionOrder}
        />
      )}

      {openErrorDialog && !errorChangeStatusEnMasse && (
        <DialogAvertissement
          open={openErrorDialog}
          onClose={() => {
            setOpenErrorDialog(false)
          }}
          messageErreur={
            'Vous avez choisis des courses d’autres clients hors Autover, merci de changer la sélection.'
          }
        />
      )}
      {errorChangeStatusEnMasse && (
        <ModalError
          open={openErrorDialog}
          handleClose={() => {
            setOpenErrorDialog(false)
            removeErrorAction()
            setMessageError(null)
            isErrorActionWithSuccess && setIsActionShipmentDone(true)
          }}
          message={messageError}
        />
      )}
    </>
  )
}
ActionCourse.propTypes = {
  coursesChecked: PropTypes.array,
  getMotifsCloture: PropTypes.func,
  listesMotif: PropTypes.array,
  isLoadingMotifListes: PropTypes.bool,
  onClotureEnMasse: PropTypes.func,
  isLoadingActionOrder: PropTypes.bool,
  isActionDone: PropTypes.func,
  errorChangeStatusEnMasse: PropTypes.array,
  removeErrorAction: PropTypes.func,
  isErrorActionWithSuccess: PropTypes.bool,
  setIsActionShipmentDone: PropTypes.func,
}

export default ActionCourse
