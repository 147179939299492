import { connect } from 'react-redux'

import keyModal from '../../../pages/settings/components/Modals/KeyModal'

import {
  getConfigSuccess,
  getErrorMessageConfig,
  getKeysIsLoading,
} from '../../../redux/configuration/selectors'
import {
  CreatNewKey,
  UpdateKey,
  resetConfig,
} from '../../../redux/configuration/actions'

const mapStateToProps = (state) => ({
  isLoadingKeys: getKeysIsLoading(state),
  success: getConfigSuccess(state),
  errorMessage: getErrorMessageConfig(state),
})

const mapDisptachToProps = (dispatch) => ({
  CreatNewKey: (keys) => dispatch(CreatNewKey(keys)),
  UpdateKey: (payload) => dispatch(UpdateKey(payload)),
  resetKey: () => dispatch(resetConfig()),
})

export default connect(mapStateToProps, mapDisptachToProps)(keyModal)
