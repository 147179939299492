import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../../../ModalDialog'

import Button from '@mui/material/Button'
import IconImportationFile from '../../../../assets/icons/navBar/MingcuteFileImportLine'
import { validateUploadFileXLS } from '../../../../utils/validator/UploadFile'
import IconOptimisationCourse from '../../../../assets/icons/navBar/IconOptimisationCourse'
import AutoComplete from '../../../AutoComplete'
import { useHistory } from 'react-router-dom'

export default function CoursesOptimisation({
  open,
  handleClose,

  warehouses,
  warehousesCount,
  isLoadingWarehouses,

  fetchWareHouses,
  fetchWareHousesMore,

  resetConfigWareHouse,

  optimiserCourses,
}) {
  const [warehouse, setWarehouse] = useState(null)
  const [fileCourse, setFileCourse] = useState(null)
  const [errorUploadFile, setErrorUploadFile] = useState(null)
  const [offset, setOffset] = useState(25)
  const [wareHouseSearch, setSearchWareHouse] = useState('')
  const [select, setSelect] = useState(false)

  useEffect(() => {
    return () => resetConfigWareHouse()
  }, [])

  const onUpload = (event) => {
    const error = validateUploadFileXLS(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
      setFileCourse(null)
    } else {
      setFileCourse(event.target.files[0])
      setErrorUploadFile(null)
    }
    event.target.value = ''
  }

  const onOptimiserCourses = () => {
    const formData = new FormData()
    formData.append('file', fileCourse)
    formData.append('warehouseId', warehouse.legacyId)

    optimiserCourses(formData)
    resetConfigWareHouse()
    handleClose()
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (wareHouseSearch.length > 0 && !select) {
        fetchWareHouses(wareHouseSearch)
        setOffset(25)
      } else if (wareHouseSearch === '') {
        fetchWareHouses('')
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [wareHouseSearch])

  const loadMoreWareHouse = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      warehousesCount >= offset
    ) {
      setOffset(offset + 25)
      fetchWareHousesMore(offset)
    }
  }

  const onChangeSearchWareHouse = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchWareHouse(val)
    } else {
      setWarehouse(null)
      setSearchWareHouse('')
    }
  }

  const onSelectWareHouse = (value) => {
    if (value) {
      setWarehouse(value)
      setSelect(true)
    }
  }
  let history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      if (
        !(
          location.pathname.includes('programmer') ||
          location.pathname.includes('planification/tourPolaris')
        )
      )
        handleClose()
    })
  }, [history])

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Optimiser les courses'}
      iconTitle={
        <IconOptimisationCourse
          style={{ width: '23px', height: '23px', marginRight: '10px' }}
        />
      }
      content={
        <div
          style={{
            overflow: 'auto',
            width: '600px',
            paddingRight: '16px',
            paddingBottom: '6px',
            '@media (max-width: 680px)': {
              width: 'unset',
            },
          }}
        >
          <div style={{ padding: '15px 11px' }}>
            <div style={{ padding: '10px 0px' }}>
              <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                Entrepôt
              </div>
              <div style={{ display: 'flex' }}>
                <AutoComplete
                  value={warehouse}
                  name={'warehouses'}
                  onChange={(event, value) => {
                    onSelectWareHouse(value)
                  }}
                  onInputChange={(event, value) => onChangeSearchWareHouse(value)}
                  id="Listes-Warehouse"
                  options={warehouses}
                  getOptionLabel={(option) => option.label}
                  variant="outlined"
                  placeholder={'Sélectionner un entrepôt'}
                  multiple={false}
                  fullWidth={true}
                  ListboxProps={{
                    onScroll: loadMoreWareHouse,
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id} id={`warehouse-${option.id}`}>
                      <div className="AutoCompteFilterOption">{option.label}</div>
                    </li>
                  )}
                  loading={isLoadingWarehouses}
                />
              </div>
            </div>
            <div style={{ padding: '10px 0px' }}>
              <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                Télécharger un fichier
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  id="download-file"
                  name="download-file"
                  variant="outlined"
                  endIcon={<IconImportationFile />}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '16.5px 14px',
                    color: fileCourse ? 'rgba(0, 0, 0, 0.87)' : 'darkgrey',
                    border:
                      warehouse == 'null'
                        ? '1px solid rgb(189 189 189)'
                        : '1px solid rgb(196 196 196)',
                    '&:hover': {
                      border: '1px solid rgb(0 0 0)',
                      backgroundColor: 'white',
                    },
                    '.MuiButton-endIcon': {
                      marginRight: '-7px',
                      color:
                        warehouse == 'null'
                          ? 'rgba(0, 0, 0, 0.26) '
                          : 'rgba(0, 0, 0, 0.54)',
                      width: '1em',
                      height: '1em',
                      marginTop: '-11px',
                    },
                  }}
                  disabled={warehouse == 'null'}
                >
                  <span style={{ textTransform: 'none' }}>
                    {fileCourse ? fileCourse.name : 'Sélectionner un fichier'}
                  </span>
                </Button>
                <p
                  style={{
                    color: '#d32f2f',
                    fontSize: '0.75rem',
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    margin: '3px 14px 0px',
                    height: '10px',
                  }}
                >
                  {errorUploadFile?.message}
                </p>

                <input
                  id="input-download-file"
                  type="file"
                  style={{
                    width: '91%',
                    cursor: 'pointer',
                    opacity: '0',
                    zIndex: '2',
                    position: 'absolute',
                    height: '55px',
                  }}
                  onChange={onUpload}
                  disabled={warehouse == 'null'}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </div>
            </div>
          </div>
        </div>
      }
      handleClose={handleClose}
      handleClickAction={onOptimiserCourses}
      disabled={warehouse == null || fileCourse == null}
    />
  )
}
CoursesOptimisation.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,

  getWareHouses: PropTypes.func,
  isLoadingWarehouses: PropTypes.bool,
  warehouses: PropTypes.array,

  warehousesCount: PropTypes.number,

  fetchWareHouses: PropTypes.func,
  fetchWareHousesMore: PropTypes.func,
  resetConfigWareHouse: PropTypes.func,
  optimiserCourses: PropTypes.func,
}
