export const GET_VEHICULES = 'GET_VEHICULES'
export const GET_VEHICULES_SUCCESS = 'GET_VEHICULES_SUCCESS'
export const GET_VEHICULES_FAILURE = 'GET_VEHICULES_FAILURE'

export const GET_VEHICULES_MORE = 'GET_VEHICULES_MORE'
export const GET_VEHICULES_MORE_SUCCESS = 'GET_VEHICULES_MORE_SUCCESS'
export const GET_VEHICULES_MORE_FAILURE = 'GET_VEHICULES_MORE_FAILURE'

export const PAGE_CHANGED_VEHICULES = 'PAGE_CHANGED_VEHICULES'
export const PAGE_LIMIT_CHANGED_VEHICULES = 'PAGE_LIMIT_CHANGED_VEHICULES'

export const REMOVE_ERROR_VEHICULES = 'REMOVE_ERROR_VEHICULES'

export const RESET_SUCCESS_ACTION_VEHICULES = 'RESET_SUCCESS_ACTION_VEHICULES'

export const RESET_CONFIG_VEHICULES = 'RESET_CONFIG_VEHICULES'

export const SEARCH_VEHICULES = 'SEARCH_VEHICULES'

export const ADD_VEHICULE = 'ADD_VEHICULE'
export const ADD_VEHICULE_SUCCESS = 'ADD_VEHICULE_SUCCESS'
export const ADD_VEHICULE_FAILURE = 'ADD_VEHICULE_FAILURE'

export const UPDATE_VEHICULE = 'UPDATE_VEHICULE'
export const UPDATE_VEHICULE_SUCCESS = 'UPDATE_VEHICULE_SUCCESS'
export const UPDATE_VEHICULE_FAILURE = 'UPDATE_VEHICULE_FAILURE'

export const DELETE_VEHICULE = 'DELETE_VEHICULE'
export const DELETE_VEHICULE_SUCCESS = 'DELETE_VEHICULE_SUCCESS'
export const DELETE_VEHICULE_FAILURE = 'DELETE_VEHICULE_FAILURE'
