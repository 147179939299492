import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import { blue } from '@mui/material/colors'
import LongTextComponent from './LongTextComponent'
import NotAvailable from './NotAvailable'
import Address from './Address'
import Note from './Note'
import Assembly from '../Grid/Cell/CardHeader/Assembly'
import moment from 'moment'
import { red, teal } from '@mui/material/colors'
import SetPrepared from '../../../../containers/main/ActionShipment/SetPreparedDetails'
import EditIcon from '@mui/icons-material/Edit'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import PrintIcon from '@mui/icons-material/Print'
import ClearIcon from '@mui/icons-material/Clear'
import StarIcon from '@mui/icons-material/Star'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import StatusContent from '../Grid/Cell/CardHeader/StatusContent'
import Checkbox from '@mui/material/Checkbox'
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled'
import { replaceDotWithComma } from '../../../../utils/floatFormatting'
import LongTextWithClipboardComponent from './LongTextWithClipboard'
import {
  ASSIGNED_STATUS,
  CHARGEMENT_STATUS,
  DECHARGEMENT_EN_COURS_STATUS,
  ENLEVEE_STATUS,
  getfullName,
  SELECTED_COURSE_ENUM,
  TERMINEE_STATUS,
  VERS_DECHARGEMENT_STATUS,
  VERS_ENLEVEMENT_STATUS,
} from '../../../../utils/values'
import RestoreIcon from '@mui/icons-material/Restore'
import { PROGRAMMED_STATUS } from '../../../../utils/values'
import Deprogram from '../../../../containers/main/ActionShipment/Deprogram'
import Desattribution from '../../../../containers/main/ActionShipment/Desattribution'
import FilesCourse from '../../../../containers/main/FilesCourse/commandeEnCours'
import { ACCURACY_LEVEL } from '../../../../utils/values'
import { COURSE_EDIT_TYPE, getOrderStatus } from '../../../../utils/getOrderStatus'
import {
  CANCELED_STATUS_LIST,
  SUPPLY_COMPLETE_STATUS,
} from '../../../../utils/values'

export default function TableContent({
  deliveryDate,
  getChecked,
  columns,
  code,
  adresseArrivee,
  adresseDepart,
  canBePrepared,
  client,
  name,
  adresses,
  etatPaiement,
  earliestDeliveryDate,
  latestDeliveryDate,
  kitchenInstallationDate,
  courseType,
  moyenPaiement,
  contactArrivee,
  contactDepart,
  lettreDeVoiture,
  manutention,
  status,
  weight,
  volume,
  chauffeur,
  assemblies,
  createdAt,
  vehiculeType,
  nombreColis,
  observation,
  factures,
  noteInterne,
  courseSource,
  isPrepared,
  observationArrivee,
  dateLivraison,
  dateFinChargement,
  handleClose,
  handleClick,
  anchorEl,
  // handleModal,
  handleCancelOpen,
  open,
  montantPrestataireHT,
  tour,
  canalVente,
  deliveryRelated,
  isProgrammerPage,
  isTicked,
  prestation,
  pickUpDate,
  montantHT,
  addSelectedCourses,
  removeSelectedCourses,
  setOpenTourDialog,
  dropOffStart,
  setLdt,
  setDropoffStartCourses,
  setEqualDropoffStartCourses,
  category,
  indexRow,
  ldvRelatedCourse,
  setCheckCourse,
  coursesChecked,
  courseTypeCode,
  departureType,
}) {
  const [tableChecked, setTableChecked] = useState(false)
  const [ticked, setTicked] = useState(false)
  const handleCheck = () => {
    setTicked(!ticked)
  }

  const handleSelection = () => {
    if (ticked && addSelectedCourses)
      addSelectedCourses({ data: [code], source: SELECTED_COURSE_ENUM.TABLE })
    if (!ticked && removeSelectedCourses) {
      removeSelectedCourses({ data: [code], source: SELECTED_COURSE_ENUM.TABLE })
    }
  }
  useEffect(() => {
    handleSelection()
  }, [tableChecked])

  useEffect(() => {
    setTicked(isTicked)
  }, [isTicked])

  const getColor = (adresse) => {
    return ACCURACY_LEVEL[adresse.accuracyLevel]
  }
  const configs = canalVente ? canalVente.configs : null

  const isDeprogram =
    (status.code === PROGRAMMED_STATUS.code ||
      status.code === DECHARGEMENT_EN_COURS_STATUS.code ||
      status.code === VERS_ENLEVEMENT_STATUS.code ||
      status.code === CHARGEMENT_STATUS.code ||
      status.code === ENLEVEE_STATUS.code ||
      status.code === VERS_DECHARGEMENT_STATUS.code) &&
    configs &&
    configs.hasSupplyStep

  const isEditDisabled =
    getOrderStatus(status) !== COURSE_EDIT_TYPE.editable &&
    getOrderStatus(status) !== COURSE_EDIT_TYPE.partialEdit &&
    getOrderStatus(status) !== COURSE_EDIT_TYPE.semiEdit

  const isDissociate = status.code == ASSIGNED_STATUS.code

  const disabled =
    CANCELED_STATUS_LIST.indexOf(status.code) !== -1 ||
    TERMINEE_STATUS.code === status.code

  const [openDeprogram, setOpenDeprogram] = React.useState(false)
  const [openDissociate, setOpenDissociate] = useState(false)
  const [show, setModalState] = React.useState(false)
  const CURRENT_USER = 'currentUser'
  const [connectedUser] = useState(JSON.parse(localStorage.getItem(CURRENT_USER)))
  const handleClickOpenDeprogram = () => {
    setOpenDeprogram(true)
  }
  const handleCloseDeprogram = () => {
    setOpenDeprogram(false)
  }

  const handleModal = () => setModalState(!show)

  const rowContent = {
    vip: (
      <LongTextComponent
        key={0}
        text={
          client && client?.vip ? (
            <StarIcon style={{ color: 'gold', width: '20px' }} />
          ) : (
            <StarBorderIcon style={{ width: '20px' }} />
          )
        }
        style={{ width: '100px' }}
        display={getChecked('VIP')}
      />
    ),
    lettreDeVoiture: (
      <LongTextWithClipboardComponent
        key={1}
        text={lettreDeVoiture ? lettreDeVoiture.code : 'N/A'}
        display={getChecked('LDV')}
        style={{ cursor: 'pointer' }}
        message={'Copier LDV'}
      />
    ),
    pickUpDate: (
      <LongTextComponent
        text={pickUpDate}
        display={getChecked('Créneau de départ')}
        key={2}
      />
    ),
    dropOffDate: (
      <LongTextComponent
        text={deliveryDate}
        display={getChecked('Créneau de livraison')}
        key={3}
      />
    ),
    deliveryDate: (
      <LongTextComponent
        text={deliveryDate}
        display={getChecked('Date de livraison')}
        key={4}
      />
    ),
    dateFinChargement: (
      <LongTextComponent
        text={dateFinChargement}
        display={getChecked('Fin de chargement')}
        key={5}
      />
    ),
    dateLivraison: (
      <LongTextComponent
        text={dateLivraison}
        display={getChecked('Fin de livraison')}
        key={6}
      />
    ),
    earliestDeliveryDate: (
      <LongTextComponent
        text={earliestDeliveryDate ? earliestDeliveryDate : 'N/A'}
        display={getChecked('Date de livraison au plus tot')}
        key={7}
      />
    ),
    latestDeliveryDate: (
      <LongTextComponent
        text={latestDeliveryDate ? latestDeliveryDate : 'N/A'}
        display={getChecked('Date de livraison au plus tard')}
        key={8}
      />
    ),
    kitchenInstallationDate: (
      <LongTextComponent
        text={kitchenInstallationDate ? kitchenInstallationDate : 'N/A'}
        display={getChecked('Date de pose')}
        key={9}
      />
    ),
    courseType: (
      <LongTextComponent
        text={courseType ? courseType : 'N/A'}
        display={getChecked('Type de course')}
        key={10}
      />
    ),
    commandeType: (
      <LongTextComponent
        text={category ? category : 'N/A'}
        display={getChecked('Type de commande')}
        key={11}
      />
    ),
    status: getChecked('Status') && (
      <TableCell key={99}>
        <StatusContent
          status={status}
          etatPaiement={etatPaiement}
          moyenPaiement={moyenPaiement}
          showPayment={false}
        />
      </TableCell>
    ),
    clientPrice: (
      <LongTextComponent
        key={12}
        text={
          montantHT
            ? `${replaceDotWithComma((montantHT + montantHT * 0.2).toFixed(2))}€`
            : 'N/A'
        }
        display={getChecked('Prix Client')}
      />
    ),
    serviceProviderPrice: (
      <LongTextComponent
        key={13}
        text={
          montantPrestataireHT
            ? `${replaceDotWithComma(
                (montantPrestataireHT + montantPrestataireHT * 0.2).toFixed(2)
              )} €`
            : 'N/A'
        }
        display={getChecked('Prix Prestataire')}
      />
    ),
    paymentState: getChecked('Etat de Paiement') && (
      <TableCell key={14}>
        <Tooltip
          title={
            <span className="tootltip-content">
              {moyenPaiement ? moyenPaiement.label : ''}{' '}
            </span>
          }
          arrow
        >
          <p
            className={
              etatPaiement === 'EN_ATTENTE'
                ? 'custom-waiting-label'
                : 'custom-success-label'
            }
          >
            {etatPaiement}
          </p>
        </Tooltip>
      </TableCell>
    ),
    salesChannel: (
      <LongTextComponent
        key={15}
        text={name ? name : 'N/A'}
        display={getChecked('Canal Vente')}
      />
    ),
    pickUpAddress: (
      <Address
        key={16}
        address={adresseDepart ? adresseDepart : null}
        salesChannel={name}
        salesChannelAddresses={adresses}
        display={getChecked('Départ')}
        getColor={getColor}
      />
    ),
    dropOffAddress: (
      <Address
        key={17}
        address={adresseArrivee ? adresseArrivee : null}
        salesChannel={name}
        salesChannelAddresses={adresses}
        display={getChecked('Arrivée')}
        getColor={getColor}
      />
    ),
    manutention: (
      <LongTextComponent
        key={18}
        text={manutention ? 'Oui' : 'Non'}
        display={getChecked('Manutention')}
      />
    ),
    driver: (
      <LongTextComponent
        key={19}
        text={getfullName(chauffeur, 'N/A')}
        display={getChecked('Chauffeur')}
      />
    ),
    weight: (
      <LongTextComponent
        key={20}
        text={weight ? replaceDotWithComma(weight.toString()) : 'N/A'}
        display={getChecked('Poids')}
      />
    ),
    volume: (
      <LongTextComponent
        key={21}
        text={volume ? replaceDotWithComma(volume.toString()) : 'N/A'}
        display={getChecked('Volume')}
      />
    ),
    client: (
      <LongTextComponent
        key={22}
        text={getfullName(client, 'N/A')}
        display={getChecked('Client')}
      />
    ),
    dropOffClient: (
      <LongTextComponent
        key={23}
        text={
          contactArrivee
            ? getfullName(contactArrivee, 'N/A')
            : getfullName(client, 'N/A')
        }
        display={getChecked('Destinataire')}
      />
    ),
    pickUpClient: (
      <LongTextComponent
        key={24}
        text={getfullName(contactDepart, 'N/A')}
        display={getChecked('Expéditeur')}
      />
    ),
    shipmentSource: (
      <LongTextComponent
        key={25}
        text={courseSource ? courseSource : 'N/A'}
        display={getChecked('Course source')}
      />
    ),
    createdAt: (
      <LongTextComponent
        key={26}
        text={createdAt ? moment(createdAt).local('fr').format('LLLL') : 'N/A'}
        display={getChecked('Date de creation')}
      />
    ),
    invoice: (
      <LongTextComponent
        key={27}
        text={factures ? factures : 'N/A'}
        display={getChecked('Référence client')}
      />
    ),
    isPrepared: (
      <LongTextComponent
        key={28}
        text={isPrepared ? 'Oui' : 'Non'}
        display={getChecked('Pret')}
      />
    ),
    packagesNum: (
      <LongTextComponent
        key={29}
        text={nombreColis ? nombreColis.toString() : 'N/A'}
        display={getChecked('Nombre de colis')}
      />
    ),
    observation: (
      <LongTextComponent
        key={30}
        text={observation ? observation : 'N/A'}
        display={getChecked('Observation')}
      />
    ),
    prodOffObservation: (
      <LongTextComponent
        key={31}
        text={observationArrivee ? observationArrivee : 'N/A'}
        display={getChecked('Observation Arrivée')}
      />
    ),
    vehiculeType: (
      <LongTextComponent
        key={32}
        text={vehiculeType ? vehiculeType : 'N/A'}
        display={getChecked('Type de véhicule')}
      />
    ),
    note: (
      <Note
        key={33}
        noteInterne={noteInterne}
        display={getChecked('Note')}
        ldv={lettreDeVoiture}
      />
    ),
    assembly: getChecked('Montage') && (
      <TableCell key={34}>
        {assemblies.length !== 0 ? (
          <Assembly
            assembler={assemblies[0].assembler}
            clientPrice={assemblies[0].clientPrice}
            assemblyId={assemblies[0].id}
            startAt={assemblies[0].startAt}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
            open={open}
          />
        ) : (
          <NotAvailable />
        )}
      </TableCell>
    ),
    prestation: (
      <LongTextComponent
        key={35}
        text={prestation ? prestation : 'N/A'}
        display={getChecked('Prestation')}
      />
    ),
    tour: (
      <LongTextComponent
        key={36}
        text={tour ? tour : 'N/A'}
        display={getChecked('Tournée')}
        style={isProgrammerPage ? { cursor: 'pointer' } : {}}
        onClick={() => {
          if (tour && isProgrammerPage) {
            setOpenTourDialog(true)
            setLdt(tour)
            setEqualDropoffStartCourses(
              dropOffStart !== null && dropOffStart !== undefined
            )
            setDropoffStartCourses(dropOffStart)
          }
        }}
      />
    ),
    CourseLie: getChecked('Course associée') && (
      <TableCell key={37}>
        {deliveryRelated && deliveryRelated !== null ? (
          <Link
            to={'/deliveries/' + ldvRelatedCourse}
            id={`Cours-Associee-${ldvRelatedCourse}`}
            target="_blank"
          >
            {ldvRelatedCourse}
          </Link>
        ) : (
          'N/A'
        )}
      </TableCell>
    ),
  }

  const checkCourse = () => {
    const courseCheck = {
      collaborateurId: connectedUser.id,
      departureType: departureType,
      courseType: courseTypeCode,
      codeCourse: code,
      courseSource: courseSource,
      driver: chauffeur != null && chauffeur?.id ? { id: chauffeur.id } : undefined,
      method: 'corner/clotureEnMasse',
    }
    setCheckCourse(code, courseCheck)
  }

  const verifCheckCourse = (code) => {
    const coursesCheckedProps = coursesChecked.reduce(
      (a, v) => ({ ...a, [v.codeCourse]: v }),
      {}
    )

    return Object.keys(coursesCheckedProps).includes(code)
  }
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={code}
        sx={{ border: '1px solid' }}
      >
        {isProgrammerPage ? (
          <TableCell key={`column_check_${indexRow}`} align="center">
            <Checkbox
              id={`checkbox-orders-table-${indexRow}`}
              sx={{ marginTop: '7px' }}
              checked={ticked}
              onChange={() => {
                handleCheck()
                setTableChecked(!tableChecked)
              }}
              component={'td'}
            />
          </TableCell>
        ) : (
          <TableCell key={`column_check_${indexRow}`} align="center">
            <Checkbox
              id={`checkbox-orders-table-${indexRow}`}
              checked={verifCheckCourse(code)}
              onChange={checkCourse}
              component={'td'}
            />
          </TableCell>
        )}
        {/*start here */}
        {columns.map((el) => {
          return rowContent[el.id]
        })}
        {/*ends here*/}
        {!isProgrammerPage && (
          <>
            <TableCell>
              <PrintIcon
                style={{
                  width: '20px',
                  cursor: 'pointer',
                  color: blue[800],
                }}
                onClick={handleModal}
              />
            </TableCell>

            <TableCell>
              {!disabled && (
                <ClearIcon
                  style={{
                    backgroundColor: 'transparent',
                    color: red[600],
                    cursor: 'pointer',
                    width: '20px',
                  }}
                  onClick={() =>
                    handleCancelOpen(
                      {
                        shipmentReference: lettreDeVoiture.code,
                        codeCourse: code,
                        chauffeur: chauffeur,
                        collaborateurId: connectedUser.id,
                        state: 'ANNULLEE',
                        courseSource: courseSource,
                        driver:
                          chauffeur != null && chauffeur?.id
                            ? { id: chauffeur.id }
                            : undefined,
                        method: 'corner/annulation',
                        datetime: moment(new Date()).format(),
                      },
                      true
                    )
                  }
                />
              )}{' '}
            </TableCell>
            <TableCell>
              {isDeprogram ? (
                <RestoreIcon
                  style={{
                    backgroundColor: 'transparent',

                    cursor: 'pointer',
                    width: '20px',
                  }}
                  onClick={handleClickOpenDeprogram} //deprogramme(code)
                />
              ) : (
                <NotAvailable />
              )}{' '}
            </TableCell>
            <TableCell>
              {isDissociate && (
                <PersonAddDisabledIcon
                  style={{
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    width: '20px',
                  }}
                  onClick={() => {
                    setOpenDissociate(true)
                  }}
                />
              )}{' '}
            </TableCell>
            <TableCell>
              {!isEditDisabled && (
                <Link value={'/edit/' + code} to={'/edit/' + code}>
                  <EditIcon
                    style={{
                      backgroundColor: 'transparent',
                      color: teal[600],
                      cursor: 'pointer',
                      width: '20px',
                    }}
                  />
                </Link>
              )}
            </TableCell>
            <TableCell>
              {canBePrepared ? (
                <SetPrepared
                  defaultChecked={isPrepared}
                  codeCourse={code}
                  label=""
                />
              ) : (
                <NotAvailable />
              )}
            </TableCell>
          </>
        )}
      </TableRow>

      <Deprogram
        open={openDeprogram}
        handleClose={handleCloseDeprogram}
        payload={{
          collaborateurId: connectedUser.id,
          state: SUPPLY_COMPLETE_STATUS.code,
          codeCourse: code,
          courseSource: courseSource,
          method: 'corner/déprogrammer',
          datetime: moment(new Date()).format(),
        }}
      />
      {!isProgrammerPage && (
        <Desattribution
          open={openDissociate}
          handleClose={() => {
            setOpenDissociate(false)
          }}
          payload={{
            collaborateurId: connectedUser.id,
            state: PROGRAMMED_STATUS.code,
            codeCourse: code,
            courseSource: courseSource,
            driver:
              chauffeur != null && chauffeur?.id ? { id: chauffeur.id } : undefined,
            method: 'Corner/DésattribuerChauffeur',
            datetime: moment(new Date()).format(),
          }}
        />
      )}
      {!isProgrammerPage && (
        <FilesCourse
          show={show}
          handleModal={handleModal}
          codeCourse={code}
          assemblies={assemblies}
        />
      )}
    </>
  )
}
TableContent.propTypes = {
  deliveryDate: PropTypes.string,
  getChecked: PropTypes.func,
  columns: PropTypes.array,
  code: PropTypes.string,
  adresseArrivee: PropTypes.object,
  adresseDepart: PropTypes.object,
  canBePrepared: PropTypes.bool,
  client: PropTypes.object,
  name: PropTypes.string,
  adresses: PropTypes.array,
  etatPaiement: PropTypes.string,
  moyenPaiement: PropTypes.object,
  contactArrivee: PropTypes.object,
  contactDepart: PropTypes.object,
  lettreDeVoiture: PropTypes.object,
  manutention: PropTypes.bool,
  status: PropTypes.object,
  weight: PropTypes.number,
  volume: PropTypes.number,
  chauffeur: PropTypes.object,
  assemblies: PropTypes.array,
  createdAt: PropTypes.string,
  vehiculeType: PropTypes.string,
  nombreColis: PropTypes.number,
  observation: PropTypes.string,
  factures: PropTypes.string,
  noteInterne: PropTypes.string,
  courseSource: PropTypes.string,
  isPrepared: PropTypes.bool,
  observationArrivee: PropTypes.string,
  handleClose: PropTypes.func,
  handleClick: PropTypes.func,
  anchorEl: PropTypes.element,
  handleModal: PropTypes.func,
  handleCancelOpen: PropTypes.func,
  open: PropTypes.bool,
  montantHT: PropTypes.number,
  tour: PropTypes.string,
  earliestDeliveryDate: PropTypes.any,
  latestDeliveryDate: PropTypes.any,
  kitchenInstallationDate: PropTypes.any,
  courseType: PropTypes.any,
  canalVente: PropTypes.any,
  isProgrammerPage: PropTypes.bool,
  isTicked: PropTypes.bool,
  montantPrestataireHT: PropTypes.number,
  prestation: PropTypes.any,
  pickUpDate: PropTypes.any,
  addSelectedCourses: PropTypes.func,
  removeSelectedCourses: PropTypes.func,
  setOpenTourDialog: PropTypes.func,
  dropOffStart: PropTypes.any,
  setLdt: PropTypes.func,
  setDropoffStartCourses: PropTypes.func,
  setEqualDropoffStartCourses: PropTypes.func,
  deliveryRelated: PropTypes.any,
  category: PropTypes.string,
  indexRow: PropTypes.number,
  ldvRelatedCourse: PropTypes.string,
  dateLivraison: PropTypes.string,
  dateFinChargement: PropTypes.string,
  setCheckCourse: PropTypes.func,
  coursesChecked: PropTypes.array,
  courseTypeCode: PropTypes.string,
  departureType: PropTypes.string,
}
