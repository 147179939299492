import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import { CardSyled, TitleWithIcon } from '../card/style'

export default function PrisLivraison({ order, isLoading }) {
  const [tva, setTVA] = useState(0)
  const [ttc, setTTC] = useState(0)

  useEffect(() => {
    if (order) {
      setTVA((order.montantHT * 0.2).toFixed(2))
      setTTC((order.montantHT * 0.2 + order.montantHT).toFixed(2))
    }
  }, [order])
  return (
    <CardSyled>
      <TitleWithIcon>
        <AttachMoneyIcon
          style={{
            marginRight: '4px',
            width: '20px',
            height: '20px',
          }}
        />
        Prix
      </TitleWithIcon>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '15px 20px 10px 20px',
              borderBottom: '1px solid #f5f5f5',
              flexWrap: 'wrap',
              boxSizing: 'border-box',
            }}
          >
            <span>HT</span>
            <span>
              {order?.montantHT != undefined ? `${order.montantHT} €` : '0'}
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '15px 20px 10px 20px',
              borderBottom: '1px solid #f5f5f5',
              flexWrap: 'wrap',
              boxSizing: 'border-box',
            }}
          >
            <span>TVA</span>
            <span>{`${tva} €`}</span>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '15px 20px 10px 20px',
              borderBottom: '1px solid #f5f5f5',
              flexWrap: 'wrap',
              boxSizing: 'border-box',
            }}
          >
            <span>TTC</span>
            <span>{`${ttc} €`}</span>
          </Grid>
        </Grid>
      )}
    </CardSyled>
  )
}
PrisLivraison.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
}
