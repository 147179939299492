import {
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_GOOGLE_ACCOUNT,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGIN_MICROSOFT_ACCOUNT,
} from './actions'
import { httpPost } from '../http/actions'
import { saveExpirationDate } from '../../utils'
import { COLLABORATOR_EXPIRATION_DATE } from '../../utils/checkExpirationDate'
import queryString from 'query-string'
import {
  setFilterTableauBord,
  setFilterCommande,
  setFilterPlanification,
  setFilterEntrepot,
  setDefaultFilterTableauBord,
  setDefaultFilterCommande,
  setDefaultFilterPlanification,
  setDefaultFilterEntrepot,
} from '../filter/actions'
import { persistor } from '../store'
const api = process.env.REACT_APP_API_URL

export default (store) => (next) => (action) => {
  if (action.type === USER_LOGIN) {
    const { tokenRecaptcha, login, password } = action.payload

    store.dispatch(
      httpPost({
        endPoint: `${api}loginCollaborateur?${queryString.stringify({
          tokenRecaptcha,
        })}`,
        data: { login, password },
        success: USER_LOGIN_SUCCESS,
        failure: USER_LOGIN_FAILURE,
      })
    )
  }

  if (action.type === USER_LOGIN_SUCCESS) {
    const { value, collaborateur } = action.payload.data.Response
    localStorage.setItem('currentUser', JSON.stringify(collaborateur))
    localStorage.setItem('userRole', collaborateur?.collabProfile)

    localStorage.setItem('token', value)
    localStorage.setItem('OngletOpened', 0)

    //InitFilter
    if (collaborateur?.isBusinessOwner) {
      const {
        filters: {
          commande,
          tableauBord,
          planification,
          defaultFilterTableauBord,
          defaultFilterCommande,
          defaultFilterPlanification,
        },
      } = store.getState()
      store.dispatch(
        setFilterTableauBord(
          {
            ...tableauBord,
            businessOwner: [collaborateur.login],
          },
          false
        )
      )
      store.dispatch(
        setDefaultFilterTableauBord({
          ...defaultFilterTableauBord,
          businessOwner: [collaborateur.login],
        })
      )

      store.dispatch(
        setFilterCommande(
          { ...commande, businessOwner: [collaborateur.login] },
          false
        )
      )

      store.dispatch(
        setDefaultFilterCommande({
          ...defaultFilterCommande,
          businessOwner: [collaborateur.login],
        })
      )
      store.dispatch(
        setFilterPlanification(
          {
            ...planification,
            businessOwner: [collaborateur.login],
          },
          false
        )
      )
      store.dispatch(
        setDefaultFilterPlanification({
          ...defaultFilterPlanification,
          businessOwner: [collaborateur.login],
        })
      )
    }
    if (collaborateur?.prestations) {
      const {
        filters: {
          commande,
          planification,
          defaultFilterPlanification,
          defaultFilterCommande,
        },
      } = store.getState()

      store.dispatch(
        setFilterCommande(
          {
            ...commande,
            prestation: collaborateur.prestations.map(
              (prestation) => prestation.id + ''
            ),
          },
          false
        )
      )

      store.dispatch(
        setDefaultFilterCommande({
          ...defaultFilterCommande,
          prestation: collaborateur.prestations.map(
            (prestation) => prestation.id + ''
          ),
        })
      )
      store.dispatch(
        setFilterPlanification(
          {
            ...planification,
            prestation: collaborateur.prestations.map(
              (prestation) => prestation.id + ''
            ),
          },
          false
        )
      )

      store.dispatch(
        setDefaultFilterPlanification({
          ...defaultFilterPlanification,
          prestation: collaborateur.prestations.map(
            (prestation) => prestation.id + ''
          ),
        })
      )
    }
    if (collaborateur?.warehouses) {
      const {
        filters: {
          commande,
          entrepot,
          planification,
          defaultFilterCommande,
          defaultFilterPlanification,
          defaultFilterEntrepot,
        },
      } = store.getState()

      store.dispatch(
        setFilterCommande(
          {
            ...commande,
            warehouse: collaborateur.warehouses.map((e) => {
              return e.id + ''
            }),
          },
          false
        )
      )
      store.dispatch(
        setDefaultFilterCommande({
          ...defaultFilterCommande,
          warehouse: collaborateur.warehouses.map((e) => {
            return e.id + ''
          }),
        })
      )

      store.dispatch(
        setFilterEntrepot(
          {
            ...entrepot,
            warehouse: collaborateur.warehouses.map((e) => {
              return e.id + ''
            }),
          },
          false
        )
      )

      store.dispatch(
        setDefaultFilterEntrepot({
          ...defaultFilterEntrepot,
          warehouse: collaborateur.warehouses.map((e) => {
            return e.id + ''
          }),
        })
      )

      store.dispatch(
        setFilterPlanification(
          {
            ...planification,
            warehouse: collaborateur.warehouses.map((e) => {
              return e.id + ''
            }),
          },
          false
        )
      )

      store.dispatch(
        setDefaultFilterPlanification({
          ...defaultFilterPlanification,
          warehouse: collaborateur.warehouses.map((e) => {
            return e.id + ''
          }),
        })
      )
    }

    saveExpirationDate(COLLABORATOR_EXPIRATION_DATE, 12)
  }
  if (action.type === USER_LOGIN_GOOGLE_ACCOUNT) {
    const { googleToken } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}loginCollaborateur`,
        data: { googleToken },
        success: USER_LOGIN_SUCCESS,
        failure: USER_LOGIN_FAILURE,
      })
    )
  }
  if (action.type === USER_LOGIN_MICROSOFT_ACCOUNT) {
    const { azureToken } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}azure/loginCollaborateur`,
        data: { azureToken },
        success: USER_LOGIN_SUCCESS,
        failure: USER_LOGIN_FAILURE,
      })
    )
  }

  if (action.type === USER_LOGOUT) {
    localStorage.clear()
    sessionStorage.clear()
    persistor.purge()
  }

  next(action)
}
