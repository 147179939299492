import { styled } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

export const StyledListItemActions = styled(ListItem)({
  flex: '1 1 auto',

  '&:hover': {
    backgroundColor: '#E3EFFA',
    color: '#1876D2',
    '& .IconListItemActionDetails': {
      color: '#1876D2',
    },
  },
})

export const StyledListItemTextActions = styled(ListItemText)({
  display: 'flex',
})
