import React, { useState } from 'react'
import AppsIcon from '@mui/icons-material/Apps'
import ListIcon from '@mui/icons-material/List'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'

export default function DisplaySelection({
  displayModeChanged,
  displayGrid,
  saveDefaultFilter,
  resetDefaultFilter,
}) {
  const matches = useMediaQuery('(max-width:800px)')

  const [on, setOn] = useState(displayGrid)
  const handleClick = () => {
    setOn(!on)
    if (displayGrid) {
      saveDefaultFilter()
    } else {
      resetDefaultFilter()
    }
    displayModeChanged(displayGrid)
  }
  return (
    <>
      {matches && on ? (
        <>
          {!on ? (
            <Button
              variant="contained"
              onClick={() => handleClick()}
              sx={{
                backgroundColor: 'white',
                color: '#1976D2',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },

                '&.MuiButton-contained': {
                  color: '#1976D2',
                  backgroundColor: 'white',
                  height: '40px',
                },
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  color: 'rgba(0, 0, 0, 0.26) ',
                },
              }}
              id="Tab-list"
            >
              <AppsIcon style={{ paddingLeft: 0 }} />
            </Button>
          ) : (
            <Button
              id="Tab-Card"
              variant="contained"
              onClick={() => handleClick()}
              sx={{
                color: '#1976D2',
                backgroundColor: 'white',

                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },

                '&.MuiButton-contained': {
                  color: '#1976D2',
                  backgroundColor: 'white',
                  height: '40px',
                },
                '&.Mui-disabled': {
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                  color: 'rgba(0, 0, 0, 0.26) ',
                },
              }}
            >
              <ListIcon style={{ paddingLeft: 0 }} />
            </Button>
          )}
        </>
      ) : (
        <>
          <Button
            id="Tab-Card"
            variant="contained"
            onClick={() => handleClick()}
            disabled={on}
            sx={{
              ...(!on
                ? {
                    '&.MuiButton-contained': {
                      margin: '0px 8px !important',
                      width: '33px !important',
                      height: '33px',
                      minWidth: '33px !important',
                    },
                  }
                : {
                    '&.MuiButton-contained': {
                      color: 'red',
                      backgroundColor: 'white',
                      height: '40px',
                    },
                  }),

              '&.Mui-disabled': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                color: 'rgba(0, 0, 0, 0.26) ',
              },
              backgroundColor: 'white',

              color: '#1976D2',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
              },
            }}
          >
            <AppsIcon style={{ paddingLeft: 0 }} />
          </Button>
          <Button
            id="Tab-list"
            variant="contained"
            onClick={() => handleClick()}
            disabled={!on}
            sx={{
              ...(!on
                ? {
                    '&.MuiButton-contained': {
                      margin: '0px 8px',
                      width: '33px',
                      height: '33px',
                      minWidth: '33px !important',
                    },
                  }
                : {
                    '&.MuiButton-contained': {
                      color: '#1976D2',
                      backgroundColor: 'white',
                      height: '40px',
                    },
                  }),

              '&.Mui-disabled': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                color: 'rgba(0, 0, 0, 0.26) ',
              },

              backgroundColor: 'white',

              color: '#1976D2',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
              },
            }}
          >
            <ListIcon style={{ paddingLeft: 0 }} />
          </Button>
        </>
      )}
    </>
  )
}
DisplaySelection.propTypes = {
  displayModeChanged: PropTypes.func.isRequired,
  displayGrid: PropTypes.bool.isRequired,
  saveDefaultFilter: PropTypes.func,
  resetDefaultFilter: PropTypes.func,
}
