import {
  DISTANCE_CALCULATED,
  googleMapDirectionConstants,
  LOAD_SCRIPT_SUCCESS,
  LOAD_SCRIPT_ERROR,
} from './constants'
import { googleMapDirectionInitialState as initialState } from './initialState'
export default (state = initialState, action) => {
  if (
    action.type === googleMapDirectionConstants.GET_REQUEST ||
    action.type === googleMapDirectionConstants.FIND_DIRECTION
  ) {
    console.log('Find Directions')
    return {
      ...state,
      isGetDirectionRouteLoading: true,
      directionRoute: null,
    }
  }

  if (action.type === googleMapDirectionConstants.RESET_DIRECTION) {
    console.log('Reset Directions')
    return {
      ...state,
      directionRoute: null,
      directionRouteList: [],
    }
  }
  if (action.type === googleMapDirectionConstants.GET_SUCCESS) {
    let directionList = state.directionRouteList
    console.log('Get Direction success')
    directionList.push(action.payload.directionRoute)
    return {
      ...state,
      isGetDirectionRouteLoading: false,
      directionRoute: action.payload.directionRoute,
      directionRouteList: directionList,
    }
  }
  if (
    action.type === googleMapDirectionConstants.GET_ERROR ||
    action.type === googleMapDirectionConstants.FIND_DIRECTION_ERROR
  ) {
    return {
      ...state,
      isGetDirectionRouteLoading: false,
      directionRoute: null,
    }
  }
  if (action.type === googleMapDirectionConstants.FIND_DIRECTION_SUCCESS) {
    let directionList = state.directionRouteList
    const dirIndex = directionList.findIndex(
      (d) => d.id === action.payload.directionRoute.id
    )
    if (dirIndex >= 0) {
      directionList[dirIndex] = { ...action.payload.directionRoute }
    } else {
      directionList.push({ ...action.payload.directionRoute })
    }
    return {
      ...state,
      isGetDirectionRouteLoading: false,
      directionRoute: action.payload.directionRoute,
      directionRouteList: directionList,
    }
  }
  if (action.type === googleMapDirectionConstants.SET_LIST) {
    return {
      ...state,
      directionRoute: action.payload.directionRouteList,
    }
  }
  if (action.type === DISTANCE_CALCULATED) {
    return {
      ...state,
      calculatedDistance: action.payload.distance.text,
    }
  }

  if (action.type === LOAD_SCRIPT_SUCCESS) {
    return Object.assign({}, state, {
      isScriptLoadedSuccess: true,
    })
  }
  if (action.type === LOAD_SCRIPT_ERROR) {
    return Object.assign({}, state, {
      isScriptLoadedSuccess: false,
    })
  }
  return state
}
