import React from 'react'
import PropTypes from 'prop-types'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'

import Filters from './components/Filters'
import CircularProgress from '@mui/material/CircularProgress'

export default function DriversTab({
  checkActions,
  columns,
  settings,
  handleCheckAll,
  checkedAllDrivers,
  handleColumns,
  localStorageNameKey,
  TableContent,
  TableFooter,
  isLoading,
  colSpan,
  ldt,
}) {
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {[...checkActions, ...columns.concat(settings)].map(
              (column, index) =>
                column.checked &&
                (column.id === 'check' ? (
                  <TableCell
                    sx={{ backgroundColor: '#fafafa' }}
                    key={`column_${index}`}
                    align="left"
                  >
                    {!ldt && (
                      <Checkbox
                        checked={checkedAllDrivers}
                        onChange={handleCheckAll}
                      />
                    )}
                  </TableCell>
                ) : column.id === 'settings' ? (
                  <Filters
                    columns={columns}
                    handleColumns={handleColumns}
                    index={index}
                    localStorageNameKey={localStorageNameKey}
                  />
                ) : (
                  <TableCell
                    key={`column_${index}`}
                    sx={{ backgroundColor: '#fafafa' }}
                  >
                    {column.label}
                  </TableCell>
                ))
            )}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <div style={{ marginLeft: '45%' }}>
                <CircularProgress color="primary" />
              </div>
            </TableCell>
          </TableRow>
        ) : (
          <>
            <TableBody>{TableContent}</TableBody>
            {TableFooter}
          </>
        )}
      </Table>
    </TableContainer>
  )
}
DriversTab.propTypes = {
  classes: PropTypes.object,
  checkActions: PropTypes.array,
  columns: PropTypes.array,
  settings: PropTypes.array,
  handleCheckAll: PropTypes.func,
  handleColumns: PropTypes.func,
  checkedAllDrivers: PropTypes.bool,
  localStorageNameKey: PropTypes.string,
  TableContent: PropTypes.any,
  TableFooter: PropTypes.any,
  isLoading: PropTypes.bool,
  colSpan: PropTypes.number,
  ldt: PropTypes.any,
}
