//TODO LINT
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  PaymentInitial,
  ORDER_TYPE,
  REDUCTION_TYPE,
} from '../../../../constants/appContants'
import PromoCode from '../Client/PromoCode'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { getInfoOrder, getOrderStatement } from '../../../../utils/index'
import Select from '@mui/material/Select/Select'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import './index.scss'
import GenerateFiles from '../../../../containers/main/FilesCourse/CourseFiles'
import { useHistory } from 'react-router-dom'
import AssemblyDialog from '../../../../containers/main/FilesCourse/AssemblyFile'
import DevisDialog from '../../../../containers/main/FilesCourse/DevisFile'
import HttpPopUp from '../../../../components/PopUp/index'
import { replaceDotWithComma } from '../../../../utils/floatFormatting'
import CircularProgress from '@mui/material/CircularProgress'
export default function Payment({
  codePromotion,
  verifyPromocode,
  totalPrice,
  selectedClient,
  orders,
  paymentTypes,
  showPopUp,
  showPopUpDevis,
  hidePopUp,
  newClient,
  client,
  createdNewCourse,
  loading,
  codeAssembly,
  setPaymentFormAction,
  command,
  setCommand,
  assemblySaved,
  errorMessage,
  createNewOrder,
  changeStatusDevis,
  devis,
}) {
  let history = useHistory()
  const [paymentForm, setPaymentForm] = useState(PaymentInitial[0].code)
  const [calculatedPrice, setCalculatedPrice] = useState(totalPrice)
  const [loadDialog, setLoadDialog] = useState(false)
  const handleChangeDevis = () => {
    changeStatusDevis(!devis)
  }
  const handlePaymentFormChange = (event) => {
    setPaymentForm(event.target.value)
    setPaymentFormAction(true)
  }
  useEffect(() => {
    if (codePromotion && codePromotion.code < 400) {
      const { reduction, type_reduction } = codePromotion.response
      const calculatedPrice =
        type_reduction === REDUCTION_TYPE.FIXE
          ? totalPrice - parseInt(reduction)
          : totalPrice - (totalPrice * parseInt(reduction)) / 100
      setCalculatedPrice(calculatedPrice.toFixed(2))
    } else {
      setCalculatedPrice(totalPrice)
    }
  }, [codePromotion])

  useEffect(() => {
    setCalculatedPrice(totalPrice)
  }, [totalPrice])

  const onCreateNewShipment = () => {
    const infoOrder = getInfoOrder(
      orders,
      client,
      codePromotion,
      paymentForm,
      newClient,
      selectedClient
    )
    const statementOrder = getOrderStatement(orders, client)
    console.log(JSON.stringify(infoOrder))
    createNewOrder(infoOrder, devis ? 'devis' : 'commande', statementOrder)
  }
  const hideDialog = () => {
    assemblySaved()
    history.push('/')
    hidePopUp()
  }

  useEffect(() => {
    return () => {
      setPaymentFormAction(false)
      changeStatusDevis(false)
    }
  }, [])

  useEffect(() => {
    if (command) {
      onCreateNewShipment()
      setCommand(false)
    }
  }, [command])
  useEffect(() => {
    if (orders[0]?.type === ORDER_TYPE.MONTAGE) {
      if (codeAssembly) {
        setLoadDialog(true)
      }
    } else if (orders[0]?.type === ORDER_TYPE.COURSE) {
      if (createdNewCourse && showPopUp) {
        setLoadDialog(true)
      }
    } else {
      if (createdNewCourse && showPopUp && codeAssembly) {
        setLoadDialog(true)
      }
    }
  }, [createdNewCourse, codeAssembly, orders])

  if (loading) {
    return (
      <div className="payment-loading">
        <CircularProgress sx={{ color: '#1976D2' }} />
      </div>
    )
  } else {
    return (
      <div className="payment-section">
        <div style={{ height: 'auto' }}>
          <div>
            <p className="total-price-text">
              {/*todo fix prix */}
              Total à payer {replaceDotWithComma(calculatedPrice)} TTC
            </p>
          </div>
          <div className="code-promo-section">
            <p>Ajouter un code promo </p>

            <PromoCode
              verifyPromocode={verifyPromocode}
              codePromotion={codePromotion}
            />
          </div>
          <div className="single-item">
            <FormControl variant="outlined">
              <p className="payment-form-text">Moyen de paiement</p>
              <div className="payment-selection">
                <span className="payment-selection">
                  <p className="price-text">
                    {replaceDotWithComma(calculatedPrice)}&euro;
                  </p>{' '}
                  <p className="price-euro-symbol">TTC en</p>
                </span>
                <Select
                  labelId="paymentForm-select-outlined-label"
                  id="paymentForm-select-outlined"
                  value={paymentForm}
                  onChange={handlePaymentFormChange}
                  label="paymentForm"
                  className="payment-select-list"
                  variant="standard"
                >
                  {paymentTypes.length !== 0 ? (
                    PaymentInitial.concat(paymentTypes).map((payment, index) => (
                      <MenuItem
                        value={payment.code}
                        key={`payment_form_${index}`}
                        disabled={index === 0}
                      >
                        {payment.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                  )}
                </Select>
              </div>
            </FormControl>
          </div>
          {orders[0]?.type === 'course' && (
            <div style={{ paddingTop: '52px' }}>
              <FormControl component="fieldset" className="switcher-container">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChangeDevis}
                      checked={devis}
                      name="devis"
                    />
                  }
                  label={'Faire un devis'}
                  labelPlacement="end"
                />
              </FormControl>
            </div>
          )}
        </div>

        {loadDialog && (
          <>
            {orders[0]?.type === 'montage' ? (
              <>
                {codeAssembly && (
                  <AssemblyDialog
                    hideDialog={hideDialog}
                    open={showPopUp && errorMessage.length === 0}
                    codeAssembly={codeAssembly}
                  />
                )}
              </>
            ) : (
              <>
                {showPopUpDevis &&
                orders[0]?.type === 'course' &&
                createdNewCourse.length > 0 ? (
                  <DevisDialog
                    hideDialog={hideDialog}
                    open={showPopUpDevis && errorMessage.length === 0}
                    shipment={createdNewCourse}
                  />
                ) : (
                  <>
                    {createdNewCourse.length !== 0 && (
                      <GenerateFiles
                        shipment={createdNewCourse}
                        hideDialog={hideDialog}
                        open={showPopUp && errorMessage.length === 0}
                        codeAssembly={codeAssembly}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        <HttpPopUp
          show={(showPopUpDevis || showPopUp) && errorMessage.length !== 0}
          statusText={errorMessage}
          status={''}
          hidePopUp={hidePopUp}
          anError={true}
        />
      </div>
    )
  }
}
Payment.propTypes = {
  verifyPromocode: PropTypes.func,
  setActiveStep: PropTypes.func,
  codePromotion: PropTypes.any,
  totalPrice: PropTypes.number,
  selectedClient: PropTypes.any,
  orders: PropTypes.any,
  paymentTypes: PropTypes.array,
  showPopUp: PropTypes.bool,
  hidePopUp: PropTypes.func,
  client: PropTypes.any,
  newClient: PropTypes.bool,
  loading: PropTypes.bool,
  createdNewCourse: PropTypes.any,
  command: PropTypes.bool,
  setCommand: PropTypes.func,
  setPaymentFormAction: PropTypes.func,
  assemblySaved: PropTypes.func,
  errorMessage: PropTypes.array,
  createNewOrder: PropTypes.func,
  showPopUpDevis: PropTypes.bool,
  codeAssembly: PropTypes.any,
  changeStatusDevis: PropTypes.func,
  devis: PropTypes.bool,
}
