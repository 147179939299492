export const OPTIMISER_DRIVER_GET = 'OPTIMISER_DRIVER_GET'
export const OPTIMISER_DRIVER_GET_SUCCESS = 'OPTIMISER_DRIVER_GET_SUCCESS'
export const OPTIMISER_DRIVER_GET_FAILURE = 'OPTIMISER_DRIVER_GET_FAILURE'

export const FETCH_DRIVERS = 'FETCH_DRIVERS'
export const FETCH_DRIVERS_SUCCESS = 'FETCH_DRIVERS_SUCCESS'
export const FETCH_DRIVERS_FAILURE = 'FETCH_DRIVERS_FAILURE'

export const SEARCH_DRIVER = 'SEARCH_DRIVER'
export const SEARCH_DRIVER_SUCCESS = 'SEARCH_DRIVER_SUCCESS'
export const SEARCH_DRIVER_FAILURE = 'SEARCH_DRIVER_FAILURE'

export const GET_DRIVER_WARRNING = 'GET_DRIVER_WARRNING'
export const GET_DRIVER_WARRNING_SUCCESS = 'GET_DRIVER_WARRNING_SUCCESS'
export const GET_DRIVER_WARRNING_FAILURE = 'GET_DRIVER_WARRNING_FAILURE'

export const GET_DRIVER_WARRNING_MORE = 'GET_DRIVER_WARRNING_MORE'
export const GET_DRIVER_WARRNING_MORE_SUCCESS = 'GET_DRIVER_WARRNING_MORE_SUCCESS'
export const GET_DRIVER_WARRNING_MORE_FAILURE = 'GET_DRIVER_WARRNING_MORE_FAILURE'
