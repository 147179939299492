//TODO LINT
/* eslint-disable */

export const getDisponibilitesState = ({ disponibilites }) => disponibilites

export const getCanalVenteList = (state) =>
  getDisponibilitesState(state).canalVenteList
export const getCanalVentesCount = (state) =>
  getDisponibilitesState(state).canalVentesCount
export const getIsLoadingCanalVenteList = (state) =>
  getDisponibilitesState(state).isLoadingCanalVenteList

export const getErrorMessage = (state) => getDisponibilitesState(state).errorMessage
export const getIsErrorFetchCanalList = (state) =>
  getDisponibilitesState(state).isErrorFetchCanalList
export const getiIsErrorFetchCanalDetails = (state) =>
  getDisponibilitesState(state).isErrorFetchCanalDetails

export const getCanalVenteDetails = (state) =>
  getDisponibilitesState(state).canalVenteDetails
export const getIsLoadingCanalVenteDetails = (state) =>
  getDisponibilitesState(state).isLoadingCanalVenteDetails

export const isLoadingActionDisponibilite = (state) =>
  getDisponibilitesState(state).isLoadingActionDisponibilite
export const getSuccess = (state) => getDisponibilitesState(state).success
