import { connect } from 'react-redux'
import EquipageDialog from '../../../pages/main/Programmer/Optimiser'
import {
  getDriversOptimiserIsLoading,
  getDriversOptimiser,
  getDriversOptimiserCount,
} from '../../../redux/driver/selectors'
import { fetchDriversOptimiser } from '../../../redux/driver/actions'
import {
  getDriversPlannings,
  affectTour,
  removeError,
} from '../../../redux/simulateTournee/actions'
import { getfilterPlanification } from '../../../redux/filter/selectors'
import {
  getSimulationsIsLoading,
  getIsErrorAffectPlanning,
  getSimulationsErrorMessage,
} from '../../../redux/simulateTournee/selectors'

const mapStateToProps = (state) => ({
  isLoadingDriversOptimiser: getDriversOptimiserIsLoading(state),
  driversOptimiser: getDriversOptimiser(state),
  isSimLoading: getSimulationsIsLoading(state),
  filterPlanifier: getfilterPlanification(state),
  driversOptimiserCount: getDriversOptimiserCount(state),
  isErrorAffectPlanning: getIsErrorAffectPlanning(state),
  errorMessageSimulations: getSimulationsErrorMessage(state),
})
const mapDisptachToProps = (dispatch) => ({
  fetchDrivers: (filter) => dispatch(fetchDriversOptimiser(filter)),
  getDriversPlannings: (data) => dispatch(getDriversPlannings(data)),
  affectTour: (data) => dispatch(affectTour(data)),
  removeError: () => dispatch(removeError()),
})
export default connect(mapStateToProps, mapDisptachToProps)(EquipageDialog)
