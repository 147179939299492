import {
  SOCKET_REGISTER,
  SOCKET_REMOVE_LISTENER,
  SOCKET_GET_CONVERSATION,
  SOCKET_GET_CONVERSATION_DELIVERY,
  SOCKET_GET_MESSAGE,
  SOCKET_SEND_MESSAGE,
  SOCKET_CLOSE_OPEN_CONVERSATION,
  SOCKET_SEEN_MESSAGE,
  SOCKET_GET_NOTIFICATION,
  SOCKET_DISCONNECT,
  SOCKET_CREATION_CONVERSATION,
} from './constants'

export const registerSocket = () => ({
  type: SOCKET_REGISTER,
})

export const removeListenerSocket = (event) => ({
  type: SOCKET_REMOVE_LISTENER,
  payload: { event },
})

export const getConversationSocket = (isRecherch) => ({
  type: SOCKET_GET_CONVERSATION,
  payload: { isRecherch },
})

export const getConversationDeliverySocket = (ldv) => ({
  type: SOCKET_GET_CONVERSATION_DELIVERY,
  payload: { ldv },
})

export const getMessageSocket = (idConversation, isDelivery) => ({
  type: SOCKET_GET_MESSAGE,
  payload: { idConversation, isDelivery },
})

export const sendMessage = (entry, connectedUser) => ({
  type: SOCKET_SEND_MESSAGE,
  payload: { entry, connectedUser },
})

export const CloseOpenConversation = (entry, connectedUser) => ({
  type: SOCKET_CLOSE_OPEN_CONVERSATION,
  payload: { entry, connectedUser },
})

export const seenMessage = (entry, connectedUser) => ({
  type: SOCKET_SEEN_MESSAGE,
  payload: { entry, connectedUser },
})

export const getNotificationSocket = () => ({
  type: SOCKET_GET_NOTIFICATION,
})

export const socketDisconnect = () => ({
  type: SOCKET_DISCONNECT,
})

export const createConversationSocket = (
  entryCreateConversation,
  entrySendMessage,
  idConversation
) => ({
  type: SOCKET_CREATION_CONVERSATION,
  payload: { entryCreateConversation, entrySendMessage, idConversation },
})
