import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'

export default function DialogAvertissement({ messageErreur, open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ paddingTop: '70px' }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{'Avertissement'}</DialogTitle>
      <DialogContent>{messageErreur}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  )
}
DialogAvertissement.propTypes = {
  messageErreur: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
