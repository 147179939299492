import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import React from 'react'
export default function CustomButton({
  text,
  icon,
  handleClick,
  style,
  variant,
  disabled,
}) {
  return (
    <Button
      id={text.replaceAll(' ', '-')}
      onClick={handleClick}
      className={style}
      startIcon={icon}
      variant={variant}
      sx={{
        margin: '2px 5px',
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}
CustomButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any,
  handleClick: PropTypes.func,
  style: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
}
