import { connect } from 'react-redux'

import VehiculeList from '../../../pages/settings/Vehicule'
import { removeErrorConfig } from '../../../redux/configuration/actions'
import {
  fetchVehicules,
  onSearchVehicules,
  pageChangedVehicules,
  pageLimitChangedVehicules,
  removeError,
  resetSuccessAction,
  resetVehiculeConfig,
} from '../../../redux/vehicules/actions'
import {
  getErrorMessage,
  getIsErrorFetch,
  getIsLoadingVehiculesList,
  getIsSuccessRefresh,
  getSuccess,
  getVehiculesList,
  getVehiculesListCount,
} from '../../../redux/vehicules/selectors'

const mapStateToProps = (state) => ({
  isLoadingfetchVehicules: getIsLoadingVehiculesList(state),
  success: getSuccess(state),
  vehiculesList: getVehiculesList(state),
  vehiculesListCount: getVehiculesListCount(state),
  errorMessage: getErrorMessage(state),
  isErrorFetch: getIsErrorFetch(state),
  isSuccessRefresh: getIsSuccessRefresh(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchVehicules()),
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChangedVehicules(limit))
    dispatch(fetchVehicules())
  },
  pageChanged: (offset) => {
    dispatch(pageChangedVehicules(offset))
    dispatch(fetchVehicules())
  },
  searchData: (search) => {
    dispatch(onSearchVehicules(search))
    dispatch(fetchVehicules())
  },
  onCloseReset: () => dispatch(resetVehiculeConfig()),
  removeError: () => dispatch(removeError()),
  resetSuccessAction: () => dispatch(resetSuccessAction()),
  removeErrorConfig: () => dispatch(removeErrorConfig()),
})

export default connect(mapStateToProps, mapDisptachToProps)(VehiculeList)
