import React from 'react'
import PropTypes from 'prop-types'
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined'
import Grid from '@mui/material/Grid'
import CardAccordion from '../card/cardWithAccordion'

export default function DetailsLivraison({ order }) {
  return (
    <>
      {order?.options &&
        order?.options?.length > 0 &&
        order?.options?.filter((elem) => elem.quantity > 0)?.length > 0 && (
          <CardAccordion
            icon={
              <FolderSpecialOutlinedIcon
                style={{ marginRight: '4px', width: '20px', height: '20px' }}
              />
            }
            title={'Options'}
            content={
              <Grid container key={'options'}>
                {order.options
                  .filter((elem) => elem.quantity > 0)
                  .map((opt, i) => {
                    return (
                      opt?.option?.label && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '15px 20px 10px 20px',
                            borderBottom: '1px solid #f5f5f5',
                            flexWrap: 'wrap',
                            boxSizing: 'border-box',
                          }}
                          key={`option-${i}`}
                        >
                          <span>{`${opt.option.label} :`}</span>
                          <span>{opt.quantity}</span>
                        </Grid>
                      )
                    )
                  })}
              </Grid>
            }
          />
        )}
    </>
  )
}
DetailsLivraison.propTypes = {
  order: PropTypes.object,
}
