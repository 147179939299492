import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import IconButton from '@mui/material/IconButton'

import Avatar from '@mui/material/Avatar'

import Popover from '@mui/material/Popover'
import Divider from '@mui/material/Divider'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { StyledListItemActions, StyledListItemTextActions } from '../style'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

const ITEM_HEIGHT = 48

export default function ActionsProfile({ userLogout, collaborateur }) {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const data = cookies.get('profile')
    if (data) {
      const { firstname, lastname, photoIdentity, login } = collaborateur
      const googleProfile = data?.email === login
      const user = googleProfile
        ? { name: data.name, photoIdentity: data?.imageUrl, email: login }
        : { name: `${firstname} ${lastname}`, photoIdentity, email: login }
      setCurrentUser(user)
    } else if (collaborateur) {
      const { firstname, lastname, photoIdentity, login } = collaborateur
      const user = { name: `${firstname} ${lastname}`, photoIdentity, email: login }
      setCurrentUser(user)
    }
  }, [])

  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenu, setOpenMenul] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenMenul(!openMenu)
  }
  const handleCloseMenuBarre = () => {
    setAnchorEl(null)
    setOpenMenul(false)
  }

  return (
    <>
      <div style={{ display: 'flex', padding: '8px 8px 20px 0px' }}>
        <IconButton
          id={'button-actions-profile'}
          onClick={handleClick}
          style={{
            color: '#FFFF',
            backgroundColor: openMenu ? '#5E99DC' : '#1976d2',
            width: '40px',
            height: '40px',
          }}
          aria-describedby={'simple-popover'}
        >
          <Avatar
            src={currentUser?.photoIdentity}
            alt="profile"
            sx={{ background: '#FFFFFF', color: '#1976d2' }}
          />
        </IconButton>
      </div>
      <Popover
        id={'popover-actions'}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleCloseMenuBarre}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 3,
            // width: '37ch',
          },
        }}
        sx={{ zIndex: 25001, top: '4px', left: '10px' }}
      >
        <div style={{ flexDirection: 'column' }}>
          <div>
            <div style={{ marginTop: '14px', marginBottom: '6px' }}>
              <span
                style={{
                  color: '#5E6C84',
                  fontSize: '11px',
                  fontWeight: '700',
                  paddingInline: '16px',
                  textTransform: 'uppercase',
                }}
              >
                compte
              </span>
            </div>
            <div
              style={{
                paddingRight: '20px',
                paddingLeft: '20px',
                paddingTop: '8px',
                paddingBottom: '8px',
                flexDirection: 'row',
                display: 'flex',
              }}
            >
              <div
                style={{
                  marginRight: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src={currentUser?.photoIdentity}
                  alt="profile"
                  sx={{ background: '#1976d2', color: '#FFFFFF' }}
                />
              </div>
              <div style={{ display: 'grid' }}>
                <span style={{ lineHeight: '20px', fontSize: '14px' }}>
                  {currentUser?.name}
                </span>
                <small style={{ color: '#707070', fontSize: '12px' }}>
                  {currentUser?.email}
                </small>
              </div>
            </div>
          </div>
          <Divider
            sx={{ borderWidth: '1px', borderColor: 'rgba(9, 30, 66, 0.08)' }}
          />
        </div>
        <StyledListItemActions
          id={'button-importer-courses'}
          button
          onClick={userLogout}
        >
          <ExitToAppIcon className="IconListItemActionDetails" />
          <StyledListItemTextActions primary="Déconnexion" />
        </StyledListItemActions>
      </Popover>
    </>
  )
}
ActionsProfile.propTypes = {
  collaborateur: PropTypes.object.isRequired,
  userLogout: PropTypes.func.isRequired,
}
