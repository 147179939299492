import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'

export const StyledAccordion = styled(Accordion)({
  boxShadow: 'none ',
  width: '100%',
  margin: '0 ',
  '&:before': {
    opacity: '0 ',
  },
})

export const StyledList = styled(List)({
  '&.MuiList-padding': {
    marginLeft: '1px',
  },
})

export const StyledListDetails = styled(List)(({ theme }) => ({
  '&.MuiList-padding': {
    paddingTop: '0',
  },
  marginLeft: '1px',
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  overflow: 'auto',
  overflowX: 'hidden',
  maxHeight: 'calc(100vh - 446px)',
  overflowY: 'overlay',
  '&::-webkit-scrollbar-thumb': {
    background: '#d3d3d33b ',
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#9993933b;',
  },
}))

export const StyledAccordionSummary = styled(AccordionSummary)({
  minHeight: '38px',
  '&.Mui-expanded': {
    minHeight: '38px',
  },
  '&:hover': {
    '& .MuiAccordionSummary-content': {
      transition: 'none',
    },
  },

  '& .MuiAccordionSummary-content': {
    '&.Mui-expanded': {
      margin: '12px 0',
    },
    transition: 'none',
    flexDirection: 'column',
  },
})

export const StyledAccordionDetails = styled(AccordionDetails)({
  '&.MuiAccordionDetails-root': {
    padding: '8px 16px 0px ',
    display: 'flex',
  },
})
