import React from 'react'
import PropTypes from 'prop-types'

import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'

export default function TableContent({
  //   columns,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  page,
  driversOptimiserCount,
}) {
  return (
    <>
      {driversOptimiserCount > 0 && (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                20,
                30,
                50,
                100,
                200,
                300,
                { label: 'All', value: -1 },
              ]}
              count={driversOptimiserCount}
              rowsPerPage={rowsPerPage}
              //   colSpan={columns.filter((column) => column.checked).length + 2}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              sx={{
                color: 'rgba(0, 0, 0, 0.54)',
              }}
              labelRowsPerPage="lignes par page"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      )}
    </>
  )
}
TableContent.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.array,
  handleCheck: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setPage: PropTypes.func,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  driversOptimiserCount: PropTypes.number,
}
