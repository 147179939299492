import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import DeleteFilter from '../../../../containers/main/NavFilter/deleteFilter'
import RennomerFilter from '../../../../containers/main/NavFilter/renommerFilter'
import { Box, CircularProgress, Typography } from '@mui/material'

export default function OngletFilter({
  getFilterByCollaborateur,
  filtersCollaborateur,
  setFilter,
  setDefaultFilter,
  resetDefaultFilter,
  pageFilter,
  isActionCreationFilter,
  resetActionCreationFilter,
  ongletFilterOpened,
  setOngletFilter,
  refreshFetchOrder,
  refreshFetchOrderSearch,
  initRefresh,
  isActionDeleteFilter,
  resetActionDeleteFilter,
  openRechercher,
  setOngletFilterIsOpen,
  isActionModifierFilter,
  saveSearchCornerOnglet,
  searchReducerCornerOnglet,
  initCheckCourse,
  coursesChecked,
  refreshFetchOrderSearchCorner,
  resetFiltersCollaborateur,
  isLoadingGetFilters,
  searchReducerCorner,
}) {
  const [connectedUser] = useState(JSON.parse(localStorage.getItem('currentUser')))
  const [openDialogDeleteFilter, setOpenDialogDeleteFilter] = useState(false)
  const [openDialogRenommerFilter, setOpenDialogRenommerFilter] = useState(false)
  const [idFilter, setIdFilter] = useState(null)
  const [nameFilter, setNameFilter] = useState(null)

  useEffect(() => {
    getFilterByCollaborateur(connectedUser.id, pageFilter)
    window.addEventListener('beforeunload', resetFiltersCollaborateur)
    window.addEventListener('beforeunload', resetDefaultFilter)
    window.addEventListener('beforeunload', () => saveSearchCornerOnglet(null))

    return () => {
      resetDefaultFilter()
      resetFiltersCollaborateur()
      saveSearchCornerOnglet(null)
      window.removeEventListener('beforeunload', resetDefaultFilter)
      window.removeEventListener('beforeunload', resetFiltersCollaborateur)
      window.removeEventListener('beforeunload', () => saveSearchCornerOnglet(null))
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (initRefresh && coursesChecked && coursesChecked?.length == 0) {
        if (
          searchReducerCornerOnglet?.label &&
          searchReducerCornerOnglet?.categorie &&
          Number(localStorage.getItem('OngletOpened')) != 0
        ) {
          const searchCorner = {
            criteria: searchReducerCornerOnglet?.label,
            type: searchReducerCornerOnglet?.categorie.code,
          }
          refreshFetchOrderSearch(searchCorner)
        } else if (
          searchReducerCorner?.label &&
          searchReducerCorner?.categorie &&
          Number(localStorage.getItem('OngletOpened')) == 0
        ) {
          const searchCorner = {
            criteria: searchReducerCorner?.label,
            type: searchReducerCorner?.categorie.code,
          }
          refreshFetchOrderSearchCorner(searchCorner)
        } else {
          refreshFetchOrder()
        }
      }
    }, 30000)
    return () => {
      clearInterval(interval)
    }
  }, [
    ongletFilterOpened,
    searchReducerCornerOnglet,
    searchReducerCorner,
    initRefresh,
    coursesChecked,
  ])

  const handleChange = (event, newValue) => {
    setOngletFilter(newValue)
    initCheckCourse()
    if (newValue > 0) {
      setOngletFilterIsOpen(filtersCollaborateur[newValue - 1].id)
    } else {
      setOngletFilterIsOpen(null)
    }

    localStorage.setItem('OngletOpened', newValue)
    event.stopPropagation()
  }
  useEffect(() => {
    if (filtersCollaborateur?.length > 0) {
      if (ongletFilterOpened == 0) {
        resetDefaultFilter()
        saveSearchCornerOnglet(null)
      } else {
        setFilter(filtersCollaborateur[ongletFilterOpened - 1].criteria, false)
        setDefaultFilter(filtersCollaborateur[ongletFilterOpened - 1].criteria)
        saveSearchCornerOnglet(
          filtersCollaborateur[ongletFilterOpened - 1]?.search
            ? filtersCollaborateur[ongletFilterOpened - 1]?.search
            : null
        )
      }
    }
  }, [ongletFilterOpened, filtersCollaborateur])
  useEffect(() => {
    if (isActionCreationFilter) {
      setOngletFilter(filtersCollaborateur.length)
      setOngletFilterIsOpen(filtersCollaborateur[filtersCollaborateur.length - 1].id)
      localStorage.setItem('OngletOpened', filtersCollaborateur.length)
      resetActionCreationFilter()
    }
  }, [isActionCreationFilter])

  useEffect(() => {
    if (isActionDeleteFilter) {
      if (filtersCollaborateur.length == 0) {
        setOngletFilter(0)
        setOngletFilterIsOpen(null)
        localStorage.setItem('OngletOpened', 0)
      } else if (
        Number(localStorage.getItem('OngletOpened')) > filtersCollaborateur.length
      ) {
        setOngletFilter(filtersCollaborateur.length)
        setOngletFilterIsOpen(
          filtersCollaborateur[filtersCollaborateur.length - 1].id
        )
        localStorage.setItem('OngletOpened', filtersCollaborateur.length)
      } else {
        setOngletFilterIsOpen(filtersCollaborateur[ongletFilterOpened - 1].id)
      }
      resetActionDeleteFilter()
      setOpenDialogDeleteFilter(false)
    }
  }, [isActionDeleteFilter])

  useEffect(() => {
    if (isActionModifierFilter) {
      setFilter(filtersCollaborateur[ongletFilterOpened - 1].criteria, false)
      setDefaultFilter(filtersCollaborateur[ongletFilterOpened - 1].criteria)
      saveSearchCornerOnglet(null)
    }
  }, [isActionModifierFilter])
  return (
    <>
      {isLoadingGetFilters ? (
        <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
          <CircularProgress
            color="primary"
            sx={{
              width: '30px',
              height: '30px',
            }}
          />
        </Box>
      ) : (
        <Tabs
          value={ongletFilterOpened}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            width: openRechercher ? '68%' : '100%',
            '& .MuiTabs-flexContainer': { height: '100%' },
          }}
          id="Ongles-Filter"
        >
          <Tab
            label={'Filtre par défaut'}
            sx={{
              borderRight: '1px solid rgba(237, 237, 237, 1)',
              backgroundColor: ongletFilterOpened == 0 && 'white',
            }}
            key={0}
          />
          {filtersCollaborateur?.map((tab, index) => (
            <Tab
              onDoubleClick={(event) => {
                setOpenDialogRenommerFilter(true)
                setIdFilter(tab.id)
                setNameFilter(tab.name)
                event.stopPropagation()
              }}
              label={
                <Tooltip
                  title={<Typography fontSize={12}>{tab.name}</Typography>}
                  arrow
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {tab.name}
                  </span>
                </Tooltip>
              }
              icon={
                ongletFilterOpened == index + 1 && (
                  <IconButton
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={(event) => {
                      setOpenDialogDeleteFilter(true)
                      setIdFilter(tab.id)
                      setNameFilter(tab.name)
                      event.stopPropagation()
                    }}
                    sx={{
                      '&:hover': {
                        color: '#d32f2f',
                        backgroundColor: 'rgba(211, 47, 47, 0.04)',
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                )
              }
              iconPosition="end"
              sx={{
                borderRight: '1px solid rgba(237, 237, 237, 1)',
                backgroundColor: ongletFilterOpened == index + 1 && 'white',
                width: '134px',

                display: ongletFilterOpened == index + 1 && 'flex',
                justifyContent: ongletFilterOpened == index + 1 && 'space-between',
              }}
              key={index}
              id={`Onglet-Filter-${index}`}
            />
          ))}
        </Tabs>
      )}

      {openDialogDeleteFilter && (
        <DeleteFilter
          open={openDialogDeleteFilter}
          handleClose={() => setOpenDialogDeleteFilter(false)}
          payload={{
            id: idFilter,
            name: nameFilter,
          }}
        />
      )}
      {openDialogRenommerFilter && (
        <RennomerFilter
          open={openDialogRenommerFilter}
          handleClose={() => setOpenDialogRenommerFilter(false)}
          payload={{
            id: idFilter,
            rename: nameFilter,
          }}
        />
      )}
    </>
  )
}
OngletFilter.propTypes = {
  getFilterByCollaborateur: PropTypes.func,
  filtersCollaborateur: PropTypes.array,
  defaultFilter: PropTypes.object,
  setFilter: PropTypes.func,
  setDefaultFilter: PropTypes.func,
  resetDefaultFilter: PropTypes.func,
  pageFilter: PropTypes.string,
  isActionCreationFilter: PropTypes.bool,
  resetActionCreationFilter: PropTypes.func,
  ongletFilterOpened: PropTypes.number,
  setOngletFilter: PropTypes.func,
  refreshFetchOrder: PropTypes.func,
  refreshFetchOrderSearch: PropTypes.func,
  initRefresh: PropTypes.bool,
  isActionDeleteFilter: PropTypes.bool,
  resetActionDeleteFilter: PropTypes.func,
  openRechercher: PropTypes.bool,
  setOngletFilterIsOpen: PropTypes.func,
  isActionModifierFilter: PropTypes.bool,
  saveSearchCornerOnglet: PropTypes.func,
  searchReducerCornerOnglet: PropTypes.any,
  initCheckCourse: PropTypes.func,
  coursesChecked: PropTypes.array,
  refreshFetchOrderSearchCorner: PropTypes.func,
  resetFiltersCollaborateur: PropTypes.func,
  isLoadingGetFilters: PropTypes.bool,
  searchReducerCorner: PropTypes.any,
}
