import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { replaceDotWithComma } from '../../utils/floatFormatting'

export const StyledOptionsCell = styled(TableCell)({
  border: 'none ',
  padding: '0px ',
})

export default function OptionsList({ options, handleQuantityChange, disabled }) {
  return (
    <TableContainer>
      <Table aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell>Option</TableCell>
            <TableCell align="left">Quantité</TableCell>
            <TableCell align="right">Prix</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {options.map((row, index) => (
            <TableRow key={row.id}>
              <StyledOptionsCell>{row.label}</StyledOptionsCell>
              <StyledOptionsCell align="center">
                <FormControl>
                  <TextField
                    type="Number"
                    min={0}
                    margin="dense"
                    variant="outlined"
                    label="Quantité"
                    value={row.quantity}
                    onChange={($event) => {
                      if ($event.target.value > -1) {
                        handleQuantityChange($event, row.id)
                      }
                    }}
                    disabled={disabled}
                    id={`option-${index}`}
                  />
                </FormControl>
              </StyledOptionsCell>
              <StyledOptionsCell align="right">
                {' '}
                {replaceDotWithComma(row.totalPrice)} &euro;
              </StyledOptionsCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
OptionsList.propTypes = {
  options: PropTypes.array,
  handleQuantityChange: PropTypes.func,
  disabled: PropTypes.bool,
}
