import { connect } from 'react-redux'

import {
  getPackagReceptionDate,
  getLoadingPackageOrder,
} from '../../../redux/orderPackage/selectors'
import DateLivraison from '../../../pages/main/Orders/DetailsDelivery/components/details/dateLivraison'

const mapStateToProps = (state) => ({
  receptionDatePackage: getPackagReceptionDate(state),
  isLoadingPackageOrder: getLoadingPackageOrder(state),
})

export default connect(mapStateToProps)(DateLivraison)
