export const GET_PARTICIPANTS = 'GET_PARTICIPANTS'
export const GET_PARTICIPANTS_SUCCESS = 'GET_PARTICIPANTS_SUCCESS'
export const GET_PARTICIPANTS_FAILURE = 'GET_PARTICIPANTS_FAILURE'
export const GET_PARTICIPANTS_LIST_MORE = 'GET_PARTICIPANTS_LIST_MORE'
export const GET_PARTICIPANTS_LIST_MORE_FAILURE =
  'GET_PARTICIPANTS_LIST_MORE_FAILURE'
export const GET_PARTICIPANTS_LIST_MORE_SUCCESS =
  'GET_PARTICIPANTS_LIST_MORE_SUCCESS'

export const ROLE_DRIVER_FILTRE_CHANGED = 'ROLE_DRIVER_FILTRE_CHANGED'

export const PAGE_LIMIT_CHANGED = 'PAGE_LIMIT_CHANGED'
export const PAGE_CHANGED = 'PAGE_CHANGED'

export const REMOVE_ERROR = 'REMOVE_ERROR'
export const RESET_SUCCESS_ACTION = 'RESET_SUCCESS_ACTION'
export const RESET_CONFIG = 'RESET_CONFIG'

export const SEARCH_PARTICIPANTS = 'SEARCH_PARTICIPANTS'

export const ADD_PARTICIPANT = 'ADD_PARTICIPANT'
export const ADD_PARTICIPANT_SUCCESS = 'ADD_PARTICIPANT_SUCCESS'
export const ADD_PARTICIPANT_FAILURE = 'ADD_PARTICIPANT_FAILURE'

export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT'
export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS'
export const UPDATE_PARTICIPANT_FAILURE = 'UPDATE_PARTICIPANT_FAILURE'

export const GET_PARTICIPANTS_CUSTOMIZED = 'GET_PARTICIPANTS_CUSTOMIZED'
export const GET_PARTICIPANTS_CUSTOMIZED_SUCCESS =
  'GET_PARTICIPANTS_CUSTOMIZED_SUCCESS'
export const GET_PARTICIPANTS_CUSTOMIZED_FAILURE =
  'GET_PARTICIPANTS_CUSTOMIZED_FAILURE'

export const GET_PARTICIPANTS_CUSTOMIZED_MORE = 'GET_PARTICIPANTS_CUSTOMIZED_MORE'
export const GET_PARTICIPANTS_CUSTOMIZED_MORE_SUCCESS =
  'GET_PARTICIPANTS_CUSTOMIZED_MORE_SUCCESS'
export const GET_PARTICIPANTS_CUSTOMIZED_MORE_FAILURE =
  'GET_PARTICIPANTS_CUSTOMIZED_MORE_FAILURE'

export const RESET_PARTICIPANTS_LIST = 'RESET_PARTICIPANTS_LIST'

export const CHANGE_PARTICIPANTS_STATUS = 'CHANGE_PARTICIPANTS_STATUS'
export const CHANGE_PARTICIPANTS_STATUS_SUCCESS =
  'CHANGE_PARTICIPANTS_STATUS_SUCCESS'
export const CHANGE_PARTICIPANTS_STATUS_FAILURE =
  'CHANGE_PARTICIPANTS_STATUS_FAILURE'

export const SET_ISPARTICIPANT_FITRE_CHANGED = 'SET_ISPARTICIPANT_FITRE_CHANGED'

export const ANNULATION_PARTICIPATION = 'ANNULATION_PARTICIPATION'
export const ANNULATION_PARTICIPATION_SUCCESS = 'ANNULATION_PARTICIPATION_SUCCESS'
export const ANNULATION_PARTICIPATION_FAILURE = 'ANNULATION_PARTICIPATION_FAILURE'
