import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'
import { IMAGE_SIGNATURE_PHOTO_TYPE } from '../../../../../../utils/values'
import Error from '../../../../../../components/Modal/HttpErrorPopUp'
import { validateUploadImage } from '../../../../../../utils/validator/UploadFileDiscussion'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import MdiCloudUpload from '../../../../../../assets/icons/detailsLivraison/MdiCloudUpload'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useParams } from 'react-router-dom'
import CardAccordion from '../card/cardWithAccordion'
import { Box } from '@mui/material'

export default function SignaturesLivraison({
  isLoadingUploadSignature,
  errorFileUpload,
  onUploadSignature,
  setErrorFileUpload,
  orderCode,
  codeStatus,
  isLoading,
  isActionOrderSignatureDone,
  isLoadingOrderSignature,
  getOrderSignatureByLdv,
  orderSignature,
}) {
  const [errorUploadFile, setErrorUploadFile] = useState(null)
  const [open, setOpen] = useState(false)
  const [signatures, setSignatures] = useState([])
  const [paramsInit, setParams] = useState(null)

  const params = useParams()
  useEffect(() => {
    if (orderCode) {
      getOrderSignatureByLdv(params.ldv)
      setParams(params)
    }
  }, [orderCode])

  useEffect(() => {
    if (paramsInit && paramsInit.ldv != params.ldv && orderCode) {
      setParams(params)
      getOrderSignatureByLdv(params.ldv)
    }
  }, [params])

  useEffect(() => {
    if (isActionOrderSignatureDone) {
      anullerSignature()
      getOrderSignatureByLdv(params.ldv)
    }
  }, [isActionOrderSignatureDone])

  const onChangeFile = (event) => {
    setErrorUploadFile(null)
    const error = validateUploadImage(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
      setOpen(true)
      event.target.value = null
      setSignatures([])
    } else {
      const signaturesUpload = Array.from(event.target.files)

      setSignatures(signaturesUpload)
    }
  }
  const getURLSignature = (image) => URL.createObjectURL(image)
  const anullerSignature = (index) => {
    let filesUploadStateDelete = []

    if (index > -1) {
      filesUploadStateDelete = signatures
      filesUploadStateDelete.splice(index, 1)
      setSignatures([...filesUploadStateDelete])
    }
    if (filesUploadStateDelete.length == 0 || index == -1) {
      setSignatures([])
    }
  }
  const onUpload = () => {
    const formData = new FormData()
    signatures.forEach((signature, i) =>
      formData.append(`signatures[${i}]`, signature)
    )
    formData.append('codeStatus', codeStatus)
    onUploadSignature(formData, orderCode)
  }
  const onErrorClose = () => {
    setOpen(false)
    setErrorUploadFile(null)
    setErrorFileUpload(false)
  }
  return (
    <>
      {(errorUploadFile?.isError || errorFileUpload) && (
        <Error
          statusText={
            errorUploadFile?.message ||
            "une erreur s'est produite merci d'actualiser la page"
          }
          open={open || errorFileUpload}
          setOpen={onErrorClose}
        />
      )}
      <CardAccordion
        icon={
          <LibraryAddCheckOutlinedIcon
            style={{ marginRight: '4px', width: '20px', height: '20px' }}
          />
        }
        title={'Signatures'}
        action={
          <>
            {isLoadingUploadSignature ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '6px 7px 0px 0px',
                }}
              >
                <span>Upload en cours ...</span>
                <CircularProgress
                  sx={{
                    '&.MuiCircularProgress-root': {
                      width: '25px !important',
                      height: '25px !important',
                    },
                  }}
                />
              </div>
            ) : (
              orderSignature &&
              orderSignature?.length === 0 &&
              orderCode && (
                <div style={{ paddingRight: '7px', display: 'flex' }}>
                  <div style={{ display: 'flex', marginTop: '5px' }}>
                    <input
                      type="file"
                      style={{
                        width: '130px',
                        cursor: 'pointer',
                        opacity: '0',
                        zIndex: '2',
                        position: 'static',
                        marginRight: '-20px',
                      }}
                      onChange={onChangeFile}
                      onClick={(event) => event.stopPropagation()}
                    />

                    <AddIcon style={{ cursor: 'pointer' }} />
                  </div>
                  {signatures.length > 0 && (
                    <IconButton
                      aria-label="delete"
                      style={{
                        marginLeft: '15px',
                        padding: '0px',
                        width: '33px',
                        height: '29px',
                      }}
                    >
                      <MdiCloudUpload
                        style={{
                          width: '27px',
                          height: '27px',
                          color: '#1976d2',

                          cursor: 'pointer',
                        }}
                        onClick={(event) => {
                          onUpload()
                          event.stopPropagation()
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              )
            )}
          </>
        }
        content={
          <>
            {!isLoadingUploadSignature && signatures.length > 0 && (
              <div
                style={{
                  maxHeight: '300px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  boxSizing: 'border-box',
                  overflowY: 'auto',
                }}
              >
                {signatures.map((signature, index) => {
                  return (
                    <div
                      style={{
                        position: 'relative',
                        width: '112px',
                        height: '100px',
                      }}
                      key={index}
                    >
                      <IconButton
                        sx={{
                          zIndex: '2',

                          backgroundColor: '#eeeeee !important',
                          position: 'absolute',
                          width: '10px',
                          height: '10px',
                          right: '0',
                          top: '2px',
                        }}
                        onClick={() => {
                          anullerSignature(index)
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <img
                          src={getURLSignature(signature)}
                          style={{
                            objectFit: 'contain',
                            width: '100px',
                            height: '100px',
                            padding: '2%',
                          }}
                        />
                        <Box
                          sx={{
                            width: '100%',
                            cursor: 'pointer',
                            height: '100%',
                            opacity: '0',
                            padding: '4px 4px',
                            position: 'absolute',
                            '&:hover': {
                              opacity: '1',
                              backgroundColor: 'hsla(0,0%,97.6%,.4117647058823529)',
                              transition: '0.5s',
                            },
                          }}
                        >
                          <a
                            href={getURLSignature(signature)}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <VisibilityOutlinedIcon
                              sx={{
                                width: '40px',
                                border: 'none',
                                cursor: 'pointer',
                                height: '28px',
                                opacity: '0.7',
                                borderRadius: '5px',
                                backgroundColor: 'white',
                                position: 'absolute',
                                top: '45%',
                                left: '27%',
                              }}
                            />
                          </a>
                        </Box>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            {isLoadingOrderSignature || isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              orderSignature &&
              orderSignature.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    // flexWrap: 'wrap', boxSizing: 'border-box',
                    justifyContent: 'space-evenly',
                  }}
                >
                  {orderSignature.filter(
                    (image) => image.type == IMAGE_SIGNATURE_PHOTO_TYPE.PickUp
                  ).length > 0 && (
                    <>
                      <div>
                        <div
                          style={{
                            fontSize: '13px',
                            fontWeight: '550',
                            paddingLeft: '5px',
                          }}
                        >
                          Signature de retrait
                        </div>
                        {orderSignature
                          .filter(
                            (image) =>
                              image.type == IMAGE_SIGNATURE_PHOTO_TYPE.PickUp
                          )
                          .map((signature, index) => {
                            return (
                              <div
                                style={{
                                  position: 'relative',
                                  width: '100%',
                                  maxWidth: '150px',
                                  margin: '4px',
                                }}
                                key={index}
                              >
                                <img
                                  key={`signature-${index}`}
                                  alt="photo-b2h"
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                  }}
                                  src={signature.url}
                                />
                              </div>
                            )
                          })}
                      </div>
                      {orderSignature.filter(
                        (image) => image.type == IMAGE_SIGNATURE_PHOTO_TYPE.DropOff
                      ).length > 0 &&
                        orderSignature.filter(
                          (image) => image.type == IMAGE_SIGNATURE_PHOTO_TYPE.PickUp
                        ).length > 0 && (
                          <div
                            style={{ borderRight: '1px solid rgb(245, 245, 245)' }}
                          ></div>
                        )}
                    </>
                  )}
                  {orderSignature.filter(
                    (image) => image.type == IMAGE_SIGNATURE_PHOTO_TYPE.DropOff
                  ).length > 0 && (
                    <div>
                      <div
                        style={{
                          fontSize: '13px',
                          fontWeight: '550',
                          paddingLeft: '5px',
                        }}
                      >
                        Signature de livraison
                      </div>
                      {orderSignature
                        .filter(
                          (image) => image.type == IMAGE_SIGNATURE_PHOTO_TYPE.DropOff
                        )
                        .map((signature, index) => {
                          return (
                            <div
                              style={{
                                position: 'relative',
                                width: '100%',
                                maxWidth: '150px',
                                margin: '4px',
                              }}
                              key={index}
                            >
                              <img
                                key={`signature-${index}`}
                                alt="photo-b2h"
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                }}
                                src={signature.url}
                              />
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              )
            )}
          </>
        }
      />
    </>
  )
}
SignaturesLivraison.propTypes = {
  order: PropTypes.object,
  isLoadingUploadSignature: PropTypes.bool,
  errorFileUpload: PropTypes.bool,
  onUploadSignature: PropTypes.func,
  setErrorFileUpload: PropTypes.func,
  orderCode: PropTypes.string,
  codeStatus: PropTypes.string,
  isLoading: PropTypes.bool,
  isActionOrderSignatureDone: PropTypes.bool,
  isLoadingOrderSignature: PropTypes.bool,
  getOrderSignatureByLdv: PropTypes.func,
  orderSignature: PropTypes.array,
}
