export const OrderInitialState = {
  isLoadingPackageOrder: false,
  isActionPointagePackageDone: false,
  isActionPointagePackageErorr: false,
  orderPackage: [],
  orderPackageOriginal: [],
  warehousePackage: null,
  receptionDatePackage: null,
  errorPackage: null,
  isLoadingPackageStatus: false,
  packagesStatus: [],
  isLoadingPointagePackage: false,
  orderPackageLitigeUploadImage: null,
  isLoadingPointageForcePackage: false,
  isActionPointageForcePackageDone: false,
  isLoadingUploadImageLitige: false,
  errorImageLitigeUpload: false,
  isLoadingPackageLitigeMotif: false,
  packagesLitigeMotif: [],
  isLoadingPackageLitigeResponsabilite: false,
  packagesLitigeResponsabilite: [],
  isLoadingLitigeHistoriquePackage: false,
  litigeHistoriquePackage: null,
  isLoadingProduitsPackage: false,
  produitsPackage: [],
  isLoadingHistoriquesPackage: false,
  historiquesPackage: [],
  isLoadingPhotosHistoriquePackage: false,
  photosHistoriquePackage: null,

  isLoadingEtatPackage: false,
  isVisibiliteEtatPackage: false,
  isVisibiliteEtatPackageDone: false,
  isAnnulationLivraisonPartielleDone: false,
  isLoadingLivraisonPartielle: false,
  canalconfig: [],
  orderPackageMDMUploadImage: null,
  isLoadingUploadImageMDM: false,

  courseSource: null,
  isLoadingEditPackage: false,
  isActionEditPackageDone: false,
}
