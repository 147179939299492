import React from 'react'
import { Marker } from '@react-google-maps/api'
import PropTypes from 'prop-types'

export default function MarkerWithEticket({
  marker,
  index,
  setShow,
  isDrawable,
  disableDraw,
}) {
  return (
    <Marker
      key={`marker_with_eticket_${index}`}
      position={marker.position}
      title={marker.title}
      icon={
        !isDrawable
          ? marker.icon
          : { url: marker.icon, labelOrigin: { x: 10, y: -10 } }
      }
      opacity={marker.opacity}
      animation={marker.animation}
      onClick={disableDraw ? '' : marker.action}
      onMouseOver={() => setShow(index)}
      onMouseOut={() => setTimeout(() => setShow(-1), 100)}
      label={
        marker.ticket
          ? {
              text: marker.ticket,
              color: !isDrawable ? marker.icon.fillColor : marker.colorCode,
              fontSize: !isDrawable ? '12px' : '14px',
              fontWeight: !isDrawable ? '30' : '600',
            }
          : null
      }
      zIndex={marker.zIndex}
      clickable={true}
    />
  )
}
MarkerWithEticket.propTypes = {
  marker: PropTypes.any,
  showIndex: PropTypes.number,
  index: PropTypes.number,
  setShow: PropTypes.func,
  isDrawable: PropTypes.bool,
  disableDraw: PropTypes.bool,
}
