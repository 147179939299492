import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import Tooltip from '@mui/material/Tooltip/Tooltip'
export default function TextComponent({ value, id }) {
  return (
    <span>
      <Tooltip arrow title={<span className="tootltip-content">{value}</span>}>
        <p className="normal-text" id={id}>
          {value}
        </p>
      </Tooltip>
    </span>
  )
}
TextComponent.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
}
