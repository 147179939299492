import React from 'react'

export function StreamlineInterfaceTimeAlarmNotificationAlertBellWakeClockAlarm(
  props
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="7" cy="8" r="5.5"></circle>
        <path d="M5.5.5h3M7 .5v2M5.5 6L7 8h2.5M12 2l1 1M2 2L1 3"></path>
      </g>
    </svg>
  )
}
export default StreamlineInterfaceTimeAlarmNotificationAlertBellWakeClockAlarm
