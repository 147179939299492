import { connect } from 'react-redux'
import HistoriqueLitigePackage from '../../../../pages/main/Orders/DetailsDelivery/components/dialog/detailsColis/historiquePointage/dialogLitige'

import {
  getLitigeHistorique,
  getLoadingLitigeHistorique,
} from '../../../../redux/orderPackage/selectors'
import { getLitigeHistoriquePackage } from '../../../../redux/orderPackage/actions'

const mapStateToProps = (state) => ({
  litigeHistoriquePackage: getLitigeHistorique(state),
  isLoadingLitigeHistoriquePackage: getLoadingLitigeHistorique(state),
})
const mapDisptachToProps = (dispatch) => ({
  getLitigeHistoriquePackage: (idLitige) =>
    dispatch(getLitigeHistoriquePackage(idLitige)),
})

export default connect(mapStateToProps, mapDisptachToProps)(HistoriqueLitigePackage)
