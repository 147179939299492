import _ from 'lodash'

import {
  GET_ADDRESS_PREDICTIONS,
  GET_ADDRESS_PREDICTIONS_FAILURE,
  GET_ADDRESS_PREDICTIONS_SUCCESS,
} from './actions'
import {
  GOOGLE_COUNTRIES,
  GOOGLE_DEFAULT_COUNTRY,
} from '../../constants/appContants'
import { httpFetch } from '../http/actions'

const api = process.env.REACT_APP_GEO_CACHING_API

export default (store) => (next) => (action) => {
  if (action.type === GET_ADDRESS_PREDICTIONS) {
    const { query, country } = action.payload
    const countryParam = !_.isNil(country)
      ? country.toLowerCase()
      : GOOGLE_COUNTRIES.find(
        (c) => c.code === GOOGLE_DEFAULT_COUNTRY.code
      ).code.toLowerCase()
    store.dispatch(
      httpFetch({
        endPoint: `${api}places?query=${query}&country=${countryParam}`,
        data: {},
        success: GET_ADDRESS_PREDICTIONS_SUCCESS,
        failure: GET_ADDRESS_PREDICTIONS_FAILURE,
      })
    )
  }
  next(action)
}
