import { styled } from '@mui/material/styles'
import { Box, Button, Divider, ListItemText, Menu, MenuItem } from '@mui/material'

export const BoxActions = styled(Box)({
  width: '100%',
  height: '64px',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '10px 20px',
  bottom: '0px',
  zIndex: '2',
  position: 'sticky',
  backgroundColor: 'white',
  boxShadow:
    '0 2px 4px -1px rgba(0, 0, 0, 0.2),0 4px 5px 0 rgba(0, 0, 0, 0.14),0 1px 10px 0 rgba(0, 0, 0, 0.12)',
})

export const ButtonCloturer = styled(Button)({
  width: '150px',
  color: '#fff',
  backgroundColor: '#10A549',
  marginRight: '20px',

  '&:hover': {
    backgroundColor: '#158f44',
  },
})

export const ButtonLabel = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  textTransform: 'capitalize',
})

export const ButtonSpan = styled(Box)({
  fontSize: '13px',
  fontWeight: 400,
})

export const MenuStyle = styled(Menu)({
  margin: '-5px 0px',
  '& .MuiMenu-paper': {
    border: '1px solid #d3d4d5 !important',
    width: '150px',
    marginBottom: '-38px',
  },
  '& .MuiList-root': {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
})

export const MenuItemStyle = styled(MenuItem)({
  '&.MuiMenuItem-root': {
    padding: '15px 16px',

    '& .MuiListItemText-root': {
      flex: 'none',
    },
  },
})

export const ListItemTextStyle = styled(ListItemText)(
  ({ color, backgroundColor }) => ({
    color: color,
    padding: ' 0 3px',
    borderRadius: '8px',
    backgroundColor: backgroundColor,
    marginTop: '4px',
    marginBottom: '4px',
  })
)

export const DividerStyle = styled(Divider)({
  width: '85%',
  margin: 'auto !important',
})
