import { ORDER_TYPE } from '../constants/appContants'
import { Place } from '../models/place'
import moment from 'moment-timezone'
import { parseDateString } from '../utils/dateAdapter'
export class Order {
  getShipmentFromResponse(response) {
    console.log('Order date : ', response.dateDemarrage)
    // NOTE: Automize time/// reception
    let dateDemarrage = moment
      .tz(response.dateDemarrage, 'Europe/Paris')
      .format('HH:mm')
    console.log('Moment time ', moment(response.dateDemarrage).format('HH:mm'))
    console.log(
      'Parsed time',
      moment.tz(response.dateDemarrage, 'Europe/Paris').format('HH:mm')
    )
    const factures = response.factures
      ? response.factures.split('|')
      : [response.factures || '']

    //Forced phone to int form
    const originPhone = response?.contactDepart?.phone
      ? response.contactDepart.phone[0] === '0'
        ? '+33' + response.contactDepart.phone.substr(1)
        : response.contactDepart.phone
      : ''
    const destinationPhone = response?.contactArrivee?.phone
      ? response.contactArrivee.phone[0] === '0'
        ? '+33' + response.contactArrivee.phone.substr(1)
        : response.contactArrivee.phone
      : ''
    return {
      active: true,
      code: response.code,
      isPrepared: response.isPrepared,
      courseSource: response.courseSource,
      clientPrice: (response.montantHT + response.montantHT * 0.2).toFixed(2),
      originalclientPrice: (response.montantHT + response.montantHT * 0.2).toFixed(
        2
      ),
      date: new Date(response.dateDemarrage),
      id: response.id,
      dateDemarrageMeta: response.dateDemarrageMeta,
      times: dateDemarrage,
      time: dateDemarrage,
      isSubmitted: 0,
      manutention: response.manutention,
      manutentionDouble: response.manutentionDouble,
      manutentionOptions: 'avecManutention',
      nbItems: response.nombreColis || '',
      number: undefined,
      destinationLevel: response.adresseArrivee.etage,
      originLevel: response.adresseDepart.etage,
      destinationIsElevator: response.adresseArrivee.avecAscenseur,
      originIsElevator: response.adresseDepart.avecAscenseur,
      destinationQuery: response.adresseArrivee.address,
      observation: response.observation,
      originQuery: response.adresseDepart.address,
      providerPrice: (
        response.montantPrestataireHT +
        response.montantPrestataireHT * 0.2
      ).toFixed(2),
      purchaseAmount: response.purchaseAmount || '',
      selectedDestination: new Place().getFromOrderAddress(response.adresseArrivee),
      selectedOrigin: new Place().getFromOrderAddress(response.adresseDepart),
      selectedSalesChannel: response.commande.canalVente,
      lettreDeVoiture: response.lettreDeVoiture,
      vehiculeType: response.vehiculeType,
      weight: response.weight || '',
      volume: response.volume,
      directions: [],
      direction: {},
      collaborator: response.commande.collaborateur,
      status: response.status,
      originLastname: response.contactDepart?.lastname,
      originFirstname: response.contactDepart?.firstname,
      originPhone: originPhone,
      originObservation: response.observation,
      originMail: response.contactDepart?.mail,
      destinationLastname: response.contactArrivee?.lastname,
      destinationFirstname: response.contactArrivee?.firstname,
      destinationPhone: destinationPhone,
      destinationObservation: response.observationArrivee,
      destinationMail: response.contactArrivee?.mail,
      factures: factures,
      noteInterne: response.noteInterne,
      earliestDeliveryDate: parseDateString(
        response.courseMetadata?.earliestDeliveryDate
      ),
      latestDeliveryDate: parseDateString(
        response.courseMetadata?.latestDeliveryDate
      ),
      prestation: response.prestation,
      selectPresCanalService: response.prestationServiceCanal?.id,
      pickUp: {
        start: response.pickUpStart,
        end: response.pickUpEnd,
      },
      pickUpDate: response.pickUpStart ? new Date(response.pickUpStart) : null,
      heurePickUp: response.pickUpStart
        ? moment.tz(response.pickUpStart, 'Europe/Paris').format('HH:mm')
        : null,
      pickUpStart: response.pickUpStart
        ? moment(response.pickUpStart).format('HH:mm')
        : null,
      pickUpEnd: response.pickUpEnd
        ? moment(response.pickUpEnd).format('HH:mm')
        : null,
      dropOff: {
        start: response.dropOffStart,
        end: response.dropOffEnd,
      },
      chauffeur: response.chauffeur,
      adresseArrivee: response.adresseArrivee,
      adresseDepart: response.adresseDepart,
      canBePrepared: response.canBePrepared,
      commande: response.commande,
      courseMetadata: response.courseMetadata,
      montantPrestataireHT: response.montantPrestataireHT,
      montantHT: response.montantHT,
      assemblies: response.assemblies,
      createdAt: response.createdAt,
      options: response.options,
      originalOption: response.options,
      courseType: response.courseMetadata?.courseType || null,
      clientInfos: !(
        response.contactArrivee?.mail || response?.contactArrivee?.phone
      ),
      envoiMailArrivee:
        response.contactArrivee?.mail && response.contactArrivee?.mail[0] === '+',
      orderBalance: response.courseMetadata?.orderBalance || 0,
      plVlAdresseArrivee:
        response?.adresseArrivee?.plVl == null ||
        response?.adresseArrivee?.plVl == undefined
          ? true
          : response?.adresseArrivee?.plVl,
      plVlAdresseDepart:
        response?.adresseDepart?.plVl == null ||
        response?.adresseDepart?.plVl == undefined
          ? true
          : response?.adresseDepart?.plVl,
      nbrMarcheAvantAscenseurAdresseArrivee:
        response.adresseArrivee.nbrMarcheAvantAscenseur || 0,
      nbrMarcheAvantAscenseurAdresseDepart:
        response.adresseDepart.nbrMarcheAvantAscenseur || 0,
      paymentMethod: response.commande?.moyenPaiement?.code,
      serviceTime: response?.serviceTime,
    }
  }
  getClientFromResponse(response) {
    const factures = response.factures ? response.factures.split('|') : ''
    return {
      factures: factures,
      id: 123,
      firstname: response.commande.client
        ? response.commande.client.firstname
        : null,
      lastname: response.commande.client ? response.commande.client.lastname : null,
      isSubmitted: 0,
      mail: response.commande.client ? response.commande.client.mail : null,
      nonEnvoiMail: response.nonEnvoiMail,
      observation: response.observation,
      noteInterne: response.noteInterne,
      phone: response.commande.client
        ? response.commande.client.phone[0] === '0'
          ? '+33' + response.commande.client.phone.substr(1)
          : response.commande.client.phone
        : null,
      vip: response.commande.client ? response.commande.client.vip : null,
      articleFamilies: response.articleFamilies.map((e) => {
        return { code: e.code }
      }),
    }
  }

  getOrderFromResponse(response) {
    return {
      id: response.id,
      status: response.status,
      courseSource: response.courseSource,
      type: ORDER_TYPE.DELIVERY,
      assembly: null,
      totalPrice: (response.montantHT + response.montantHT * 0.2).toFixed(2),
      isGetOperationalhoursLoading: false,
      operationalHoursList: [],
      isEstimateShipmentPriceLoading: false,
      shipmentEstimatedPrice: null,
      shipmentEstimatedPriceError: null,
      isEstimateAssemblyPriceLoading: true,
      assemblyEstimatedPrice: null,
      assemblyEstimatedPriceError: null,
    }
  }
}
