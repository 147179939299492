import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'

const ActionButtons = ({ onCancel, onApply, nameFilter, isProgrammer }) => {
  return (
    <div
      style={
        !isProgrammer
          ? {
              padding: '10px 20px',
              bottom: '0',
              position: 'fixed',
              width: '280px',
              background: 'white',
            }
          : {
              marginBottom: '10px',
              background: 'white',
            }
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          paddingTop: '18px',
        }}
      >
        <Button
          id={`Annuler-${nameFilter}`}
          variant="contained"
          onClick={(event) => onCancel(event)}
          sx={{
            width: '100px',
            height: '40px',
            textTransform: 'none',
            fontSize: '13px',
            fontWeight: '400',
            backgroundColor: '#f61057',
            color: 'white',
            ':hover': {
              backgroundColor: '#d32f2f',
            },
          }}
        >
          {'Annuler'}
        </Button>
        <Button
          id={`Appliquer-${nameFilter}`}
          variant="contained"
          onClick={(event) => onApply(event)}
          sx={{
            width: '100px',
            height: '40px',
            textTransform: 'none',
            fontSize: '13px',
            fontWeight: '400',
            backgroundColor: '#10A549',
            color: 'white',
            ':hover': {
              backgroundColor: '#158f44',
            },
          }}
        >
          {'Appliquer'}
        </Button>
      </div>
    </div>
  )
}

ActionButtons.propTypes = {
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
  nameFilter: PropTypes.string,
  isProgrammer: PropTypes.bool,
}

export default ActionButtons
