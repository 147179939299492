import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import moment from 'moment'
import Dialog from '../../../../../../../../components/ModalDialog'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { getName } from '../../../../../../../../utils/values'
import DialogHistoriqueLitige from '../../../../../../../../containers/main/DetailsDelivery/Colis/historiqueLitigePackage'
import IconLitige from '../../../../../../../../assets/icons/detailsLivraison/litige.svg'
import DialogPhotos from '../../../../../../../../containers/main/DetailsDelivery/Colis/historiquePhotosPackage'
import PictureIcon from '../../../../../../../../assets/icons/detailsLivraison/IconParkOutlinePicture'

import 'react-18-image-lightbox/style.css' // This only needs to be imported once in your app
import CircularProgress from '@mui/material/CircularProgress'
import { getStepLabel } from '../../../../../../../../utils/utils'

export default function DialogHistorique({
  open,
  onClose,
  barCodeLitige,
  historiquesPackage,
  isLoadingHistoriquesPackage,
  getPackageHistoriques,
  idPackage,
}) {
  useEffect(() => {
    if (idPackage) {
      getPackageHistoriques(idPackage)
    }
  }, [idPackage])

  const [openDialogLitige, setOpenDiagLitige] = useState(false)
  const [openDialogPhoto, setOpenDialogPhoto] = useState(false)
  const [idLitige, setIdLitige] = useState(null)

  const [idPackageTraceability, setIdPackageTraceability] = useState(null)
  return (
    <>
      {openDialogLitige ? (
        <DialogHistoriqueLitige
          barCodeLitige={barCodeLitige}
          open={openDialogLitige}
          onClose={() => {
            setOpenDiagLitige(false)
            setIdLitige(null)
          }}
          idLitige={idLitige}
        />
      ) : openDialogPhoto ? (
        <DialogPhotos
          open={openDialogPhoto}
          onClose={() => {
            setOpenDialogPhoto(false)
            setIdPackageTraceability(null)
          }}
          idPackageTraceability={idPackageTraceability}
        />
      ) : (
        <Dialog
          maxWidthDialog={'lg'}
          open={open}
          title={'Historique des colis'}
          iconTitle={
            <InfoOutlinedIcon style={{ marginRight: '10px', marginTop: '3px ' }} />
          }
          content={
            <>
              {isLoadingHistoriquesPackage ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px',
                    width: '300px',
                  }}
                >
                  <CircularProgress
                    style={{
                      color: '#1976d2',
                    }}
                  />
                </div>
              ) : (
                <TableContainer sx={{ paddingBottom: '16px', paddingRight: '16px' }}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    {historiquesPackage.length > 0 ? (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Date de pointage'}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Step'}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Statut'}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Collaborateur'}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Code Agence'}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Chauffeur'}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Source'}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Code Rayon'}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Litige'}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Avant Emballage '}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: '700' }}>
                              {'Après Emballage'}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {historiquesPackage.map((historique, i) => (
                            <TableRow key={i}>
                              <TableCell align="left">
                                {historique?.flashedAt?.date
                                  ? moment(historique?.flashedAt?.date).format(
                                      'D MMM YYYY HH:mm'
                                    )
                                  : 'N/A'}
                              </TableCell>
                              <TableCell align="left">
                                {getStepLabel(historique?.step) || 'N/A'}
                              </TableCell>
                              <TableCell align="left">
                                {historique?.label ? historique.label : 'N/A'}
                              </TableCell>
                              <TableCell align="left">
                                {getName(
                                  historique?.clFirstname,
                                  historique?.clLastname
                                ) || 'N/A'}
                              </TableCell>
                              <TableCell align="left">
                                {historique?.warehouseCode || 'N/A'}
                              </TableCell>
                              <TableCell align="left">
                                {getName(
                                  historique?.chFirstname,
                                  historique?.chLastname
                                ) || 'N/A'}
                              </TableCell>
                              <TableCell align="left">
                                {historique?.source || 'N/A'}
                              </TableCell>
                              <TableCell align="left">
                                {historique?.codeRayon || 'N/A'}
                              </TableCell>
                              <TableCell align="left">
                                {historique?.idLitigation ? (
                                  <img
                                    src={IconLitige}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setOpenDiagLitige(true)
                                      setIdLitige(historique.idLitigation)
                                    }}
                                    id={`Historique-Litige-${i}`}
                                  />
                                ) : (
                                  'N/A'
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <PictureIcon
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                    color: '#1976D2',
                                  }}
                                  onClick={() => {
                                    setOpenDialogPhoto(true)
                                    setIdPackageTraceability({
                                      id: historique.idPackageTraceability,
                                      pictureType: 'picture_before_packing',
                                      title: 'Photos avant emballage',
                                    })
                                  }}
                                  id={`Photo-Avant-${i}`}
                                />
                              </TableCell>
                              <TableCell align="left">
                                <PictureIcon
                                  style={{
                                    cursor: 'pointer',
                                    fontSize: '20px',
                                    color: '#1976D2',
                                  }}
                                  onClick={() => {
                                    setOpenDialogPhoto(true)
                                    setIdPackageTraceability({
                                      id: historique.idPackageTraceability,
                                      pictureType: 'picture_after_packing',
                                      title: 'Photos après emballage',
                                    })
                                  }}
                                  id={`Photo-Après-${i}`}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    ) : (
                      <div
                        style={{
                          padding: '60px 100px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {'Historique des colis est vide'}
                      </div>
                    )}
                  </Table>
                </TableContainer>
              )}
            </>
          }
          handleClose={onClose}
          isClosedIcon={true}
        />
      )}
    </>
  )
}
DialogHistorique.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  historiquesPackage: PropTypes.array,
  barCodeLitige: PropTypes.string,
  idPackage: PropTypes.string,
  getPackageHistoriques: PropTypes.func,
  isLoadingHistoriquesPackage: PropTypes.bool,
}
