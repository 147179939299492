import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import StepperSection from '../../../containers/main/PlaceOrders/Stepper'
import Step from '../../../containers/main/PlaceOrders/Step'
import RecapSection from '../../../containers/main/PlaceOrders/RecapSection'
import PlaceOrderMapContainer from '../../../containers/main/PlaceOrders/MapContainer'
import ValidationSection from '../../../containers/main/PlaceOrders/Validation'
import Loading from '../../../components/Loading'
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'

import './style.scss'
const PlaceOrder = ({ isGetSalesChannelListLoading }) => {
  const [channelListLoading, setIsGetSalesChannelListLoading] = useState(true)

  const matchesView1 = useMediaQuery('(min-width:960px)')

  useEffect(() => {
    setIsGetSalesChannelListLoading(isGetSalesChannelListLoading)
  }, [isGetSalesChannelListLoading])

  return (
    <>
      <div
        id="place-order-page"
        className={['place-order-page', channelListLoading ? 'has-loader' : ''].join(
          ''
        )}
      >
        {channelListLoading ? (
          <Loading />
        ) : (
          <>
            <StepperSection />
            <Grid container>
              {!channelListLoading && (
                <>
                  {matchesView1 ? (
                    <>
                      <Grid item xs={12} sm={12} md={6} className="column-container">
                        <Step />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="right-section-container">
                          <div className="recap-header">
                            <RecapSection />
                          </div>
                          <div className="recap-map">
                            <PlaceOrderMapContainer />
                          </div>
                          <ValidationSection />
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <div style={{ paddingTop: '50px' }}>
                          <RecapSection />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Step />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="right-section-container">
                          <div className="recap-map">
                            <PlaceOrderMapContainer />
                          </div>
                          <ValidationSection />
                        </div>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </>
        )}
      </div>
    </>
  )
}

PlaceOrder.propTypes = {
  isGetSalesChannelListLoading: PropTypes.bool.isRequired,
}
export default PlaceOrder
