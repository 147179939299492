import React from 'react'

export function IcomoonFreeTruck(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        d="m16 9l-2-4h-3V3c0-.55-.45-1-1-1H1c-.55 0-1 .45-1 1v8l1 1h1.268a2 2 0 1 0 3.464 0h5.536a2 2 0 1 0 3.464 0H16V9zm-5 0V6h2.073l1.5 3H11z"
      ></path>
    </svg>
  )
}
export default IcomoonFreeTruck
