import { connect } from 'react-redux'

import {
  fetchLivraison,
  generationBLCourse,
} from '../../../../redux/documents/actions'

import {
  getIsLoadingLivraison,
  getLivraisonList,
  getErrorLivraison,
  getCountLivraison,
} from '../../../../redux/documents/selectors'

import { pageChanged, pageLimitChanged } from '../../../../redux/filter/actions'

import TableLivraison from '../../../../pages/main/Entrepot/documents/TableLivraison'

const mapStateToProps = (state) => ({
  livraisonList: getLivraisonList(state),
  isLoadingLivraison: getIsLoadingLivraison(state),
  errorLivraison: getErrorLivraison(state),
  countLivraison: getCountLivraison(state),
})

const mapDisptachToProps = (dispatch) => ({
  generationBLCourse: (ldv) => {
    dispatch(generationBLCourse(ldv))
  },
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(fetchLivraison())
  },
  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(fetchLivraison())
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(TableLivraison)
