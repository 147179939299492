export const getConversationState = ({ conversations }) => conversations

export const getConversationError = (state) => getConversationState(state).error
export const getConversationLoading = (state) =>
  getConversationState(state).isLoading

export const getConversations = (state) =>
  getConversationState(state)?.data?.sort((a, b) => {
    return new Date(b.lastActionTime) - new Date(a.lastActionTime)
  })
export const getConversationsLivraison = (state) =>
  getConversationState(state).conversationLivraison.sort((a, b) => {
    return new Date(b.lastActionTime) - new Date(a.lastActionTime)
  })

export const getsocketNotRegistred = (state) =>
  getConversationState(state).socketNotRegistred
export const getNotifications = (state) => getConversationState(state).notifications
export const getNbNotif = (state) => getConversationState(state).nbNotif

export const getFocus = (state) => getConversationState(state).focus
export const getCountDelivery = (state) => getConversationState(state).count
export const getCountListConversation = (state) =>
  getConversationState(state).countList
export const getConversationListLoadingMore = (state) =>
  getConversationState(state).isLoadingConvMore

export const getConversationLoadingMore = (state) =>
  getConversationState(state).isLoadingMore

export const getloadingOpenCloseConversation = (state) =>
  getConversationState(state).loadingOpenCloseConversation

export const getIdConversation = (state) =>
  getConversationState(state).idConversation

export const getConversationSubject = (state) =>
  getConversationState(state).subjectConversation
export const getIsLoadingSubjectConversation = (state) =>
  getConversationState(state).isLoadingSubjectConversation
