import { connect } from 'react-redux'
import HistoriqueDeltaCost from '../../../pages/main/Orders/DetailsDelivery/components/details/historiqueDeltaCost'

import {
  getHistoriqueDeltaCostOrder,
  getIsLoadingHistoriqueDeltaCost,
} from '../../../redux/order/selectors'
import { getOrderHistoriqueDeltaCost } from '../../../redux/order/actions'

const mapStateToProps = (state) => ({
  orderHistoriqueDeltaCost: getHistoriqueDeltaCostOrder(state),
  isLoadingHistoriqueDeltaCost: getIsLoadingHistoriqueDeltaCost(state),
})
const mapDisptachToProps = (dispatch) => ({
  getOrderHistoriqueDeltaCost: (ldv) => dispatch(getOrderHistoriqueDeltaCost(ldv)),
})
export default connect(mapStateToProps, mapDisptachToProps)(HistoriqueDeltaCost)
