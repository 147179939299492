import _ from 'lodash'
import { DAYS_OF_WEEK } from '../constants/appContants'
const getDisabledDays = (selectedSalesChannel) => {
  return _.get(selectedSalesChannel, 'configs.operationalHours.length')
    ? selectedSalesChannel.configs.operationalHours
    : null
}

const getDaysIndex = (days) => {
  return DAYS_OF_WEEK.filter(
    (day) =>
      days?.findIndex((el) => {
        return el.dayOfWeek && el.dayOfWeek !== null && el.dayOfWeek.code === day
      }) < 0
  ).map((el) => DAYS_OF_WEEK.indexOf(el))
}

export const getDaysDisabledDays = (selectedSalesChannel) => {
  let days = []

  days = getDisabledDays(selectedSalesChannel)
  const daysIndexes = getDaysIndex(days) || []
  return daysIndexes
}

export const getOpeningDay = (selectedSalesChannel) => {
  let days = []
  days = getDisabledDays(selectedSalesChannel)
  const indices = days
    .filter((day) => day.storeOpeningTime)
    .map((day) => DAYS_OF_WEEK.indexOf(day.dayOfWeek.code))

  return indices
}
