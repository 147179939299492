import _ from 'lodash'

import {
  GOOGLE_ADDRESS_TYPE,
  SALES_CHANNEL_ADDRESS_TYPE,
  COMPANY_ADDRESS_TYPE,
  GOOGLE_COUNTRIES,
  GOOGLE_DEFAULT_COUNTRY,
} from '../constants/appContants'
import { GOOGLE_MAP_CONSTANTS } from '../constants/googleMapConstants'

import { LatLng } from './latlng'
import { Country } from './country'
import uuid from 'react-uuid'

export class Place {
  constructor(
    id,
    type,
    placeId,
    reference,
    placeName,
    postalCode,
    country,
    city,
    addressComponents,
    formattedAddress,
    latlng,
    geometry,
    operationalHours
  ) {
    this.id = id
    this.type = type
    this.placeId = placeId
    this.reference = reference
    this.placeName = placeName
    this.postalCode = postalCode
    this.country = country
    this.city = city
    this.addressComponents = addressComponents
    this.formattedAddress = formattedAddress
    this.latlng = latlng
    this.geometry = geometry
    this.operationalHours = operationalHours
  }

  getFromJson(json) {
    if (json) {
      if (_.get(json, 'geoc.address_components.length')) {
        const postalCodeIndex = json['geoc']['address_components'].findIndex(
          (ad) => ad.types.indexOf('postal_code') >= 0
        )
        const countryIndex = json['geoc']['address_components'].findIndex(
          (ad) => ad.types.indexOf('country') >= 0
        )
        const cityIndex = json['geoc']['address_components'].findIndex(
          (ad) =>
            ad.types.indexOf('locality') >= 0 && ad.types.indexOf('political') >= 0
        )
        const postalCode =
          postalCodeIndex >= 0
            ? _.get(
                json['geoc']['address_components'][postalCodeIndex],
                'long_name',
                null
              )
            : null
        const country =
          countryIndex >= 0 &&
          _.get(
            json['geoc']['address_components'][countryIndex],
            'short_name.length'
          ) &&
          GOOGLE_COUNTRIES.find(
            (c) =>
              c.code ===
              json['geoc']['address_components'][
                countryIndex
              ].short_name.toLowerCase()
          )
            ? GOOGLE_COUNTRIES.find(
                (c) =>
                  c.code ===
                  json['geoc']['address_components'][
                    countryIndex
                  ].short_name.toLowerCase()
              )
            : GOOGLE_COUNTRIES.find(
                (c) => c.code === GOOGLE_DEFAULT_COUNTRY.code.toLowerCase()
              )
        const city =
          cityIndex >= 0
            ? _.get(json['geoc']['address_components'][cityIndex], 'long_name', null)
            : null
        return new Place(
          null,
          GOOGLE_ADDRESS_TYPE,
          json['geoc']['place_id'],
          json['reference'],
          json['structured_formatting']['main_text'],
          postalCode,
          new Country(country.code, country.name),
          city,
          json['geoc']['address_components'],
          json['geoc']['formatted_address'],
          new LatLng(
            json['geoc']['geometry']['location']['lat'],
            json['geoc']['geometry']['location']['lng']
          ),
          json['geoc']['geometry'],
          null
        )
      } else {
        return new Place(
          null,
          GOOGLE_ADDRESS_TYPE,
          json['geoc']['place_id'],
          json['reference'],
          json['structured_formatting']['main_text'],
          null,
          null,
          null,
          json['geoc']['address_components'],
          json['geoc']['formatted_address'],
          new LatLng(
            json['geoc']['geometry']['location']['lat'],
            json['geoc']['geometry']['location']['lng']
          ),
          json['geoc']['geometry'],
          null
        )
      }
    } else {
      return null
    }
  }
  getFromCanalAddress(address) {
    if (address) {
      const country =
        _.get(address, 'countryCode.length') &&
        GOOGLE_COUNTRIES.find((c) => c.code === address.countryCode.toLowerCase())
          ? GOOGLE_COUNTRIES.find(
              (c) => c.code === address.countryCode.toLowerCase()
            )
          : GOOGLE_COUNTRIES.find(
              (c) => c.code === GOOGLE_DEFAULT_COUNTRY.code.toLowerCase()
            )
      return new Place(
        address.id,
        SALES_CHANNEL_ADDRESS_TYPE,
        null,
        null,
        _.get(address, 'label.length') ? address.label : address.address,
        _.get(address, 'postalCode.length')
          ? address.postalCode
          : _.get(address, 'zip.length')
          ? address.zip
          : null,
        new Country(country.code, country.name),
        address.city,
        null,
        address.address,
        new LatLng(address.latitude, address.longitude),
        null,
        address.operationalHours
      )
    } else {
      return null
    }
  }

  getFromClientPro(address) {
    if (address) {
      console.log(address)
      return new Place(
        uuid(),
        COMPANY_ADDRESS_TYPE,
        null,
        null,
        null,
        address.zip,
        null,
        address.city,
        null,
        address.address,
        null,
        null,
        null
      )
    }
  }

  getFromCompanyAddress(address) {
    if (address) {
      const country =
        _.get(address, 'countryCode.length') &&
        GOOGLE_COUNTRIES.find((c) => c.code === address.countryCode.toLowerCase())
          ? GOOGLE_COUNTRIES.find(
              (c) => c.code === address.countryCode.toLowerCase()
            )
          : GOOGLE_COUNTRIES.find(
              (c) => c.code === GOOGLE_DEFAULT_COUNTRY.code.toLowerCase()
            )
      return new Place(
        address.id,
        COMPANY_ADDRESS_TYPE,
        null,
        null,
        _.get(address, 'label.length') ? address.label : address.address,
        _.get(address, 'postalCode.length')
          ? address.postalCode
          : _.get(address, 'zip.length')
          ? address.zip
          : null,
        new Country(country.code, country.name),
        address.city,
        null,
        address.address,
        address.latitude && address.longitude
          ? new LatLng(address.latitude, address.longitude)
          : new LatLng(
              GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lat,
              GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lng
            ),
        null,
        address.operationalHours
      )
    } else {
      return null
    }
  }
  getFromOrderAddress(orderAddress) {
    if (orderAddress) {
      const country =
        _.get(orderAddress, 'countryCode.length') &&
        GOOGLE_COUNTRIES.find(
          (c) => c.code === orderAddress.countryCode.toLowerCase()
        )
          ? GOOGLE_COUNTRIES.find(
              (c) => c.code === orderAddress.countryCode.toLowerCase()
            )
          : GOOGLE_COUNTRIES.find(
              (c) => c.code === GOOGLE_DEFAULT_COUNTRY.code.toLowerCase()
            )

      return new Place(
        orderAddress.id,
        null,
        null,
        null,
        _.get(orderAddress, 'label.length')
          ? orderAddress.label
          : orderAddress.address,
        orderAddress.postalCode,
        new Country(country.code, country.name),
        orderAddress.city,
        null,
        orderAddress.address,
        new LatLng(orderAddress.latitude, orderAddress.longitude),
        null,
        orderAddress.operationalHours
      )
    } else {
      return null
    }
  }
  getFromSelectedAddress(selectedAddress) {
    if (selectedAddress) {
      const country =
        _.get(selectedAddress, 'country.code.length') &&
        GOOGLE_COUNTRIES.find(
          (c) => c.code === selectedAddress.country.code.toLowerCase()
        )
          ? GOOGLE_COUNTRIES.find(
              (c) => c.code === selectedAddress.country.code.toLowerCase()
            )
          : GOOGLE_COUNTRIES.find(
              (c) => c.code === GOOGLE_DEFAULT_COUNTRY.code.toLowerCase()
            )
      return new Place(
        selectedAddress.id,
        selectedAddress.type,
        selectedAddress.placeId,
        selectedAddress.reference,
        selectedAddress.placeName,
        selectedAddress.postalCode,
        new Country(country.code, country.name),
        selectedAddress.city,
        selectedAddress.addressComponents,
        selectedAddress.formattedAddress,
        new LatLng(selectedAddress.latlng.lat, selectedAddress.latlng.lng),
        selectedAddress.geometry,
        selectedAddress.operationalHours
      )
    } else {
      return null
    }
  }
}
