import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
// import HighlightOffSharp from '@mui/icons-material/HighlightOffSharp'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { replaceDotWithComma } from '../../../../utils/floatFormatting'
import _ from 'lodash'
import { ORDER_TYPE } from '../../../../constants/appContants'

import './style.scss'

const Row = ({
  order,
  activeStep,
  handleActiveShipment,
  handleActiveAssembly,
  totalPrice,
}) => {
  const isObjEmpty = (obj) => {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) return false
    }

    return true
  }

  return (
    <>
      <TableRow className="recap-table">
        <TableCell align="left" className="first-cell">
          <div className="cell-item">
            {/* <span className="step-nbr">{orderNumber}</span> */}
            {!(
              order.assembly !== null &&
              _.get(
                order.assembly,
                'selectedSalesChannel.configs.includedServices'
              ) === ORDER_TYPE.ASSEMBLY
            ) ? (
              <OrderFragment
                order={order.shipment}
                typeOrderAutre={order.assembly}
                type={'Livraison'}
                activeStep={activeStep}
                handleActive={handleActiveShipment}
              />
            ) : (
              'Sans livraison'
            )}
          </div>
        </TableCell>

        <TableCell align="left">
          <div className="cell-item">
            {order.shipment == null ||
            (order.shipment !== null &&
              isObjEmpty(_.get(order.shipment, 'selectedSalesChannel'))) ||
            (order.shipment !== null &&
              _.get(
                order.shipment,
                'selectedSalesChannel.configs.includedServices'
              ) === ORDER_TYPE.DELIVERY_ASSEMBLY) ? (
              <OrderFragment
                order={order.assembly}
                typeOrderAutre={order.shipment}
                type={'Montage'}
                activeStep={activeStep}
                handleActive={handleActiveAssembly}
              />
            ) : (
              'Sans montage'
            )}
          </div>
        </TableCell>
        <TableCell align="left">
          <div className="cell-item">
            {order.shipment !== null && (
              <>
                <div>
                  {order.shipment.direction?.selectedDirection?.distance
                    ? replaceDotWithComma(
                        order.shipment.direction?.selectedDirection?.distance
                      )
                    : 0}{' '}
                  KM
                </div>
              </>
            )}
          </div>
        </TableCell>
        {order.shipment == null && order.assembly !== null && (
          <TableCell align="left">
            <div className="cell-item">
              <>
                <div>
                  {order.assembly.direction?.selectedDirection?.distance
                    ? replaceDotWithComma(
                        order.assembly.direction?.selectedDirection?.distance
                      )
                    : 0}{' '}
                  KM
                </div>
              </>
            </div>
          </TableCell>
        )}
        <TableCell align="left" className="last-cell">
          <div className="cell-item">
            {totalPrice !== null ? (
              <span className="price">
                {replaceDotWithComma(totalPrice?.toFixed(2))} €
              </span>
            ) : null}
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}

const OrderTypeLabel = ({ type, price }) => (
  <span className="order-type">
    {type} {price} €{' '}
  </span>
)
const OrderFragment = ({
  order,
  handleActive,
  activeStep,
  type,
  typeOrderAutre,
}) => {
  return (
    <FormControlLabel
      disabled={activeStep !== 0}
      control={
        <Checkbox
          id={type}
          checked={order?.active ? order.active : false}
          onChange={() =>
            !(order?.active == true && typeOrderAutre?.active == undefined) &&
            handleActive()
          }
          name="checkedB"
          className="checkbox-style"
        />
      }
      label={
        <OrderTypeLabel
          type={type}
          price={order?.clientPrice ? order.clientPrice : 0}
        />
      }
    />
  )
}
OrderTypeLabel.propTypes = {
  type: PropTypes.string,
  price: PropTypes.any,
}
OrderFragment.propTypes = {
  order: PropTypes.shape({
    active: PropTypes.bool,
    clientPrice: PropTypes.any,
  }),
  activeStep: PropTypes.number,
  handleActive: PropTypes.func,
  type: PropTypes.string,
  typeOrderAutre: PropTypes.shape({
    active: PropTypes.bool,
    clientPrice: PropTypes.any,
  }),
}
Row.propTypes = {
  order: PropTypes.object,
  activeStep: PropTypes.number,
  handleActiveShipment: PropTypes.func.isRequired,
  handleActiveAssembly: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  totalPrice: PropTypes.any,
}
export default Row
