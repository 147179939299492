import React from 'react'
import PropTypes from 'prop-types'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ContactsIcon from '@mui/icons-material/Contacts'
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol'
import { styled } from '@mui/material/styles'

const ColorlibStepIconRoot = styled('div')(() => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
}))

const ColorlibStepIconActive = styled(ColorlibStepIconRoot)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
}))

const ColorlibStepIconCompleted = styled(ColorlibStepIconRoot)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}))

export default function ColorlibStepIcon(props) {
  const { active, completed } = props

  const icons = {
    1: <LocalShippingIcon fontSize="medium" />,
    2: <ContactsIcon fontSize="small" />,
    3: <EuroSymbolIcon fontSize="small" />,
  }

  const Root = completed
    ? ColorlibStepIconCompleted
    : active
    ? ColorlibStepIconActive
    : ColorlibStepIconRoot

  return <Root>{icons[String(props.icon)]}</Root>
}
ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}
