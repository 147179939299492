import { connect } from 'react-redux'
import SubjectFilter from '../../../components/NavFilter/components/Filter/cell/SubjectFilter'

import { getSubjects, getIsLoadingSubjects } from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  subjects: getSubjects(state),
  isLoadingSubjects: getIsLoadingSubjects(state),
})

export default connect(mapStateToProps)(SubjectFilter)
