import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import IconDepartement from '../../../../../../assets/icons/navFilter/DepartementIcon'
export default function Departements({
  filterStateChanged,
  departmentFiltersValues,
  departements,
  isLoading,
}) {
  const [checked, setChecked] = useState([])

  useEffect(() => {
    let list = departements.map(({ departementName, zipCodeDep }) => {
      return {
        code: zipCodeDep,
        name: zipCodeDep + ' ' + departementName,
      }
    })
    setChecked(list)
  }, [departements])

  const onDepartmentChanged = (selectedDepartment) => {
    filterStateChanged('department', selectedDepartment ? selectedDepartment : [])
  }

  return (
    <SelectListWithSearch
      disabled={isLoading || departements.length <= 0}
      dataList={checked.sort((a, b) => a.code - b.code)}
      defaultLabel={'Départements'}
      onDataChanged={onDepartmentChanged}
      filtersValues={departmentFiltersValues}
      Icon={IconDepartement}
      id={'Departement'}
    />
  )
}
Departements.propTypes = {
  departements: PropTypes.array,
  filterStateChanged: PropTypes.func,
  departmentFiltersValues: PropTypes.array,
  isLoading: PropTypes.bool,
}
