import { connect } from 'react-redux'
import ProgrammerMap from '../../../pages/main/Programmer/Map'

import { getDirectionRoute } from '../../../redux/googleMapDirections/selectors'
import {
  getGoogleMapDirectionRequest,
  resetDirections,
} from '../../../redux/googleMapDirections/actions'

import { getProgrammerPickedDate } from '../../../redux/filter/selectors'

import { getProgramMapOrders } from '../../../redux/order/selectors'
const mapStateToProps = (state) => ({
  shipments: getProgramMapOrders(state),
  pickedDate: getProgrammerPickedDate(state),
  directionRoute: getDirectionRoute(state),
})
const mapDisptachToProps = (dispatch) => ({
  getGoogleMapDirection: (id, origin, destination) =>
    dispatch(getGoogleMapDirectionRequest(id, origin, destination)),
  resetDirections: () => dispatch(resetDirections()),
})

export default connect(mapStateToProps, mapDisptachToProps)(ProgrammerMap)
