import { connect } from 'react-redux'

import ParticipantModal from '../../../pages/settings/components/Modals/ParticipantModal'

import {
  getKeys,
  getKeysCount,
  getKeysListIsLoading,
  getErrorMessageConfig,
  getConfigSuccess,
  getKeyCreatedObject,
} from '../../../redux/configuration/selectors'
import {
  fetchKeys,
  getKeysMore,
  onSearchCleCompetence,
  pageChanged,
  resetConfig,
} from '../../../redux/configuration/actions'

import {
  getErrorMessage,
  getSuccess,
  getisLoadingParticipantAction,
} from '../../../redux/participants/selectors'
import {
  createParticipant,
  resetParticipantConfig,
  updateParticipant,
} from '../../../redux/participants/actions'
const mapStateToProps = (state) => ({
  isLoadingParticipantAction: getisLoadingParticipantAction(state),

  success: getSuccess(state),
  errorMessage: getErrorMessage(state),
  errorMessageConfig: getErrorMessageConfig(state),

  keys: getKeys(state),
  isLoadingKeysList: getKeysListIsLoading(state),
  keysCount: getKeysCount(state),

  successConfig: getConfigSuccess(state),
  keyCreatedObject: getKeyCreatedObject(state),
})

const mapDisptachToProps = (dispatch) => ({
  createParticipant: (participant) => dispatch(createParticipant(participant)),
  updateParticipant: (newParticipant) => dispatch(updateParticipant(newParticipant)),

  fetchKeys: () => {
    dispatch(onSearchCleCompetence(''))
    dispatch(fetchKeys())
  },
  searchKeys: (search) => {
    dispatch(onSearchCleCompetence(search))
    dispatch(pageChanged(0))
    dispatch(fetchKeys())
  },
  fetchMoreKeys: (offset, search) => {
    dispatch(onSearchCleCompetence(search))
    dispatch(pageChanged(offset))
    dispatch(getKeysMore())
  },
  resetKey: () => dispatch(resetConfig()),
  resetParticipantConfig: () => dispatch(resetParticipantConfig()),
})

export default connect(mapStateToProps, mapDisptachToProps)(ParticipantModal)
