import { connect } from 'react-redux'

import SessionModal from '../../../pages/settings/components/Modals/SessionModal'

import {
  getErrorMessage,
  getIsLoadingSession,
  getSessionById,
  getSuccess,
  getisLoadingSessionAction,
} from '../../../redux/sessions/selectors'
import {
  createSession,
  resetSessionConfig,
  updateSession,
  setErrorUploadFile,
} from '../../../redux/sessions/actions'
import {
  fetchParticipants,
  onSearch,
  pageChanged,
  resetParticipantConfig,
  getParticipantsMore,
  setIsParticipantFiltre,
} from '../../../redux/participants/actions'
import {
  getParticipants,
  getParticipantsCount,
  getParticipantsListIsLoading,
} from '../../../redux/participants/selectors'
import {
  fetchFormations,
  fetchFormationsMore,
  onSearchFormation,
  pageChangedFormation,
} from '../../../redux/formations/actions'
import {
  getFormations,
  getFormationsListIsLoading,
} from '../../../redux/formations/selectors'
import { getFormationsCount } from '../../../redux/formations/selectors'

const mapStateToProps = (state) => ({
  isLoadingSessionAction: getisLoadingSessionAction(state),

  success: getSuccess(state),
  errorMessage: getErrorMessage(state),

  participants: getParticipants(state),
  participantsCount: getParticipantsCount(state),
  isLoadingParticipants: getParticipantsListIsLoading(state),
  formations: getFormations(state),
  formationsCount: getFormationsCount(state),

  isLoadingFormationsList: getFormationsListIsLoading(state),
  session: getSessionById(state),
  isLoadingSession: getIsLoadingSession(state),
})

const mapDisptachToProps = (dispatch) => ({
  createSession: (session) => dispatch(createSession(session)),
  updateSession: (newSession) => dispatch(updateSession(newSession)),

  fetchFormations: (filter) => dispatch(fetchFormations(filter)),

  fetchParticipants: () => {
    dispatch(onSearch(''))
    dispatch(setIsParticipantFiltre())
    dispatch(fetchParticipants())
  },

  searchParticipants: (search) => {
    dispatch(onSearch(search))
    dispatch(setIsParticipantFiltre())
    dispatch(pageChanged(0))
    dispatch(fetchParticipants())
  },

  fetchMoreParticipants: (offset, search) => {
    dispatch(pageChanged(offset))
    dispatch(onSearch(search))
    dispatch(getParticipantsMore())
  },

  resetParticipant: () => dispatch(resetParticipantConfig()),
  resetSessionConfig: () => dispatch(resetSessionConfig()),
  setErrorUploadFile: (message) => dispatch(setErrorUploadFile(message)),
  searchFormations: (search) => {
    dispatch(onSearch(search))
    dispatch(pageChangedFormation(0))
    dispatch(fetchFormations())
  },

  fetchMoreFormation: (offset, search) => {
    dispatch(pageChangedFormation(offset))
    dispatch(onSearchFormation(search))
    dispatch(fetchFormationsMore())
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(SessionModal)
