import React from 'react'
import PropTypes from 'prop-types'

import FilterOptimiser from '../../../../../components/FilterOptimiser'

export default function Filter({ setFilters, filters }) {
  const onChangeFilter = (filterValuesDict) => {
    const filter = {}
    Object.keys(filterValuesDict).forEach((val) => {
      switch (val) {
        case 'prestation':
          filter.prestation = filterValuesDict[val]
            .map((el) => {
              switch (el) {
                case 'TOUS':
                  return []
                default:
                  return el
              }
            })
            .flat(1)
          break
        case 'statusDrivers':
          filter.statusDrivers = filterValuesDict[val]
          break
        default:
          break
      }
    })
    return filter
  }
  const filterStateChanged = (filterName, selectedFields) => {
    const filterValuesDict = {}

    if (
      selectedFields?.length > 0 ||
      selectedFields === true ||
      selectedFields === false
    ) {
      Object.assign(filterValuesDict, { [filterName]: selectedFields })
    }

    const filter = onChangeFilter(filterValuesDict)
    setFilters({ ...filters, ...filter })
  }

  return (
    <div
      style={{
        padding: '12px 0px',
        marginBottom: '2px',
        boxShadow:
          'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 0px rgb(60 64 67 / 15%)',
        backgroundColor: 'rgba(248, 248, 248, 0.81)',
      }}
    >
      <FilterOptimiser
        prestationFilter={true}
        statusDriversFilter={true}
        filterStateChanged={filterStateChanged}
        filtersValues={filters}
      />
    </div>
  )
}
Filter.propTypes = {
  setFilters: PropTypes.func,
  filterInit: PropTypes.object,
  filters: PropTypes.object,
}
