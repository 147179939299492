import { connect } from 'react-redux'
import ShipmentSection from '../../../pages/main/EditOrders/Shipment'

import {
  getCreneauRecommendedDisponibilites,
  getRecommendedCreneau,
} from '../../../redux/operationalHours/actions'

import {
  getIsGetDirectionRouteLoading,
  getDirectionRoute,
  getDirectionRouteList,
} from '../../../redux/googleMapDirections/selectors'

import {
  getGoogleMapDirectionRequest,
  findDirectionAction,
  resetDirections,
} from '../../../redux/googleMapDirections/actions'

import {
  getIsOrderDisabled,
  getOldOrder,
  getOrdersState,
} from '../../../redux/shipment/selectors'

import {
  updateOrderShipmentAction,
  setShipmentErrorsAction,
  updateDirectionsAction,
  setOrderShipmentPriceAction,
} from '../../../redux/shipment/actions'
import { COURSE_EDIT_TYPE } from '../../../utils/getOrderStatus'
import { getRecommendedCreneauListState } from '../../../redux/operationalHours/selectors'
import {
  getDayDispo,
  getDayDisponibilites,
  resetRdvDispo,
} from '../../../redux/scheduleOrder/actions'
import { getRdvDispo } from '../../../redux/scheduleOrder/selector'
import { getConfigCanal, resetConfigCanal } from '../../../redux/order/actions'
import { getCanalConfig } from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  order: getOrdersState(state)[0],
  orders: getOrdersState(state),
  isGetDirectionRouteLoading: getIsGetDirectionRouteLoading(state),
  directionRoute: getDirectionRoute(state),
  directionRouteList: getDirectionRouteList(state),
  recommendedCreneauList: getRecommendedCreneauListState(state),
  oldOrder: getOldOrder(state),
  courseEditable: getIsOrderDisabled(state) == COURSE_EDIT_TYPE.editable,
  courseSemiEdit: getIsOrderDisabled(state) == COURSE_EDIT_TYPE.semiEdit,
  coursePartialEdit: getIsOrderDisabled(state) == COURSE_EDIT_TYPE.partialEdit,
  rdvDispo: getRdvDispo(state),
  configCanal: getCanalConfig(state),
})
const mapDisptachToProps = (dispatch) => ({
  getGoogleMapDirection: (id, origin, destination) =>
    dispatch(getGoogleMapDirectionRequest(id, origin, destination)),
  findDirection: (id, origin, destination) =>
    dispatch(findDirectionAction(id, origin, destination)),
  setShipmentErrors: (orId, errors) =>
    dispatch(setShipmentErrorsAction(orId, errors)),
  updateDirections: (dirId, data) => dispatch(updateDirectionsAction(dirId, data)),
  setOrderShipmentPrice: (orId, price) =>
    dispatch(setOrderShipmentPriceAction(orId, price)),
  updateOrderShipment: (orId, shipment) =>
    dispatch(updateOrderShipmentAction(orId, shipment)),
  getRecommendedCreneau: (date, codeCanal) =>
    dispatch(getRecommendedCreneau(date, codeCanal)),
  resetDirections: () => dispatch(resetDirections()),
  getDayDispo: (dateDebut, dateFin, codePostal) =>
    dispatch(getDayDispo(dateDebut, dateFin, codePostal)),
  resetRdvDispo: () => dispatch(resetRdvDispo()),

  getCreneauRecommendedDisponibilites: (date, serviceTime, channelKey, zipCode) =>
    dispatch(
      getCreneauRecommendedDisponibilites(date, serviceTime, channelKey, zipCode)
    ),
  getConfigCanal: (codeCanal, metaKey) =>
    dispatch(getConfigCanal(codeCanal, metaKey)),
  resetConfigCanal: () => dispatch(resetConfigCanal()),

  getDayDisponibilites: (startDate, endDate, channelKey, serviceTime, zipCode) =>
    dispatch(
      getDayDisponibilites(startDate, endDate, channelKey, serviceTime, zipCode)
    ),
})

export default connect(mapStateToProps, mapDisptachToProps)(ShipmentSection)
