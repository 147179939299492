import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'

import DialogTitleComponent from './components/DialogTitle'
import ShimpmentsInfos from './components/ShimpmentsInfos'
import Filter from './components/Filters'
import DialogActionsComponent from './components/DialogActions'
import DriversTab from './components/DriversTab'
import DialogAvertissement from '../../../../components/DialogError/dialogAvertissementClose'
import moment from 'moment'
import {
  DONE_STATUS,
  LIVRAISON_IMPOSSIBLE,
  RETRAIT_IMPOSSIBLE,
  SUPPLY_WAIT_STATUS,
  SUPPLY_IN_PROGRESS_STATUS,
  SUPPLY_COMPLETE_STATUS,
} from '../../../../utils/values'

import { fleetParseObject } from '../../../../utils/tours'
function Equipage({
  open,
  hideDialog,
  selectedCourses,
  shipments,
  fetchDrivers,
  isLoadingDriversOptimiser,
  driversOptimiser,
  dropoffStartCourses,
  equalDropoffStartCourses,
  getDriversPlannings,
  isLoadingAttribuer,
  ldt,
  affectTour,
  filterPlanifier,
  driversOptimiserCount,
  isErrorAffectPlanning,
  errorMessageSimulations,
  removeError,
}) {
  const [filtersValues, setFilters] = useState({})
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [driversOriginal, setDrivers] = useState([])

  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [init, setInit] = useState(false)
  const [driversOriginalSelected, setDriversOriginalSelected] = useState([])

  useEffect(() => {
    setFilters(getFilterInit(filterPlanifier))
  }, [filterPlanifier])

  useEffect(() => {
    const checkErrorCloture = shipments
      .filter((el) => selectedCourses.includes(el.code))
      .some((ship) =>
        [
          RETRAIT_IMPOSSIBLE.code,
          LIVRAISON_IMPOSSIBLE.code,
          DONE_STATUS.code,
        ].includes(ship.status.code)
      )
    const checkErrorSupply = shipments
      .filter((el) => selectedCourses.includes(el.code))
      .some((ship) =>
        [
          SUPPLY_WAIT_STATUS.code,
          SUPPLY_IN_PROGRESS_STATUS.code,
          SUPPLY_COMPLETE_STATUS.code,
        ].includes(ship.status.code)
      )
    if (checkErrorCloture) {
      setErrorMessage('Une des courses est déja cloturé. Veuillez la désélectionner')
      setErrorDialogOpen(true)
    } else if (checkErrorSupply) {
      setErrorMessage(
        "Pas possible d'attribuer des courses en statut d'approvisionnement"
      )
      setErrorDialogOpen(true)
    }
  }, [])
  const getFilterPayload = (limit, offset, search) => {
    return {
      prestations:
        filtersValues.prestation.length > 0 ? filtersValues.prestation : undefined,
      actif: filtersValues.statusDrivers,
      dayOfWeek: moment(dropoffStartCourses)
        .locale('en')
        .format('dddd')
        .toUpperCase(),
      limit: limit,
      offset: offset,
      query: search.length > 0 ? search : undefined,
    }
  }
  useEffect(() => {
    if (equalDropoffStartCourses && Object.keys(filtersValues).length > 0) {
      const filter = getFilterPayload(rowsPerPage, 0, search)
      fetchDrivers(filter)
      setInit(true)
    }
  }, [filtersValues])
  useEffect(() => {
    setDrivers(driversOptimiser)
  }, [driversOptimiser])

  useEffect(() => {
    if (driversOriginalSelected.length > 0) {
      const isDisabledButton = driversOriginalSelected.some((val) => val.checked)
      setIsDisabledButton(isDisabledButton)
    }
  }, [driversOriginalSelected])

  const attribuer = () => {
    const driversAttribuer = driversOriginalSelected.map((driver) => driver.id)

    const payload = {
      deliveryDate: dropoffStartCourses,
      driverId: driversAttribuer,
    }

    getDriversPlannings({
      data: payload,
      fleet: fleetParseObject(driversOriginalSelected, dropoffStartCourses),
    })
    hideDialog()
  }

  const handleTourSwap = () => {
    let selectedDriver = {}
    driversOriginalSelected.map(({ id, firstname, lastname, operationalHours }) => {
      selectedDriver = {
        firstName: firstname,
        name: lastname,
        id: String(id),
        shiftStart: operationalHours.openTime,
        shiftEnd: operationalHours.closeTime,
      }
    })
    affectTour({
      data: {
        driver: selectedDriver,
        ldt: ldt,
        deliveryDate: dropoffStartCourses,
        userId: String(JSON.parse(localStorage.getItem('currentUser'))?.id),
      },
    })

    hideDialog()
  }

  const getFilterInit = (filterPlanifier) => {
    return {
      statusDrivers: true,
      prestation:
        filterPlanifier?.prestation && filterPlanifier?.prestation != 'TOUS'
          ? filterPlanifier.prestation
          : [],
    }
  }
  const onSearch = (searchValue) => {
    const filter = getFilterPayload(rowsPerPage, 0, searchValue)

    fetchDrivers(filter)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    const filter = getFilterPayload(rowsPerPage, newPage * rowsPerPage, search)

    fetchDrivers(filter)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    const filter = getFilterPayload(parseInt(event.target.value, 10), 0, search)

    fetchDrivers(filter)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 2) {
        onSearch(search)
      } else {
        if (init) {
          const filter = getFilterPayload(rowsPerPage, 0, '')

          fetchDrivers(filter)
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    let val = value.trim()
    setSearch(val)
  }

  return (
    <>
      {errorDialogOpen || isErrorAffectPlanning ? (
        <DialogAvertissement
          messageErreur={errorMessage || errorMessageSimulations.message}
          open={errorDialogOpen}
          onClose={() => {
            setErrorDialogOpen(false)
            setErrorMessage(null)
            removeError()
            hideDialog()
          }}
        />
      ) : !equalDropoffStartCourses ? (
        <DialogAvertissement
          messageErreur={
            !equalDropoffStartCourses && (selectedCourses.length === 1 || ldt)
              ? 'Date de livraison manquant                           '
              : 'Choisissez des courses avec la même date de livraison'
          }
          open={open}
          onClose={hideDialog}
        />
      ) : (
        <Dialog
          open={open}
          onClose={hideDialog}
          fullWidth
          maxWidth="md"
          sx={{
            paddingTop: '70px',

            '& .MuiDialog-paperWidthMd': {
              maxWidth: '960px !important',
            },
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitleComponent onChangeSearch={onChangeSearch} />
          <ShimpmentsInfos
            ldt={ldt}
            selectedCourses={selectedCourses}
            shipments={shipments}
            dropOffStart={dropoffStartCourses}
          />

          <Filter setFilters={setFilters} filters={filtersValues} />
          <DriversTab
            ldt={ldt}
            drivers={driversOriginal}
            isLoading={isLoadingDriversOptimiser}
            setDrivers={setDrivers}
            setErrorDialogOpen={setErrorDialogOpen}
            setErrorMessage={setErrorMessage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            driversOptimiserCount={driversOptimiserCount}
            setDriversOriginalSelected={setDriversOriginalSelected}
            driversOriginalSelected={driversOriginalSelected}
          />
          <DialogActionsComponent
            isLoading={
              isLoadingDriversOptimiser || !isDisabledButton || isLoadingAttribuer
            }
            onAttribuer={ldt ? handleTourSwap : attribuer}
          />
        </Dialog>
      )}
    </>
  )
}
Equipage.propTypes = {
  open: PropTypes.bool,
  hideDialog: PropTypes.func,
  selectedCourses: PropTypes.array,
  shipments: PropTypes.array,
  fetchDrivers: PropTypes.func,
  isLoadingDriversOptimiser: PropTypes.bool,
  driversOptimiser: PropTypes.array,
  dropoffStartCourses: PropTypes.any,
  equalDropoffStartCourses: PropTypes.bool,
  getDriversPlannings: PropTypes.func,
  isLoadingAttribuer: PropTypes.bool,
  setEquipageDialogOpen: PropTypes.func,
  affectTour: PropTypes.func,
  ldt: PropTypes.any,
  filterPlanifier: PropTypes.object,
  driversOptimiserCount: PropTypes.number,
  isErrorAffectPlanning: PropTypes.bool,
  errorMessageSimulations: PropTypes.object,
  removeError: PropTypes.func,
}

export default Equipage
