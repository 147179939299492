export default () => {
  return {
    filter: {
      limit: 25,
      offset: 0,
    },
    keys: [],
    keysCount: 0,
    isLoadingkeys: false,
    success: false,
    errorMessage: null,
    isLoadingkeysList: false,
    isLoadingAssign: false,
    keysChannel: [],
    isLoadingUnassign: false,
    salesChannel: [],
    isLoadingChannelList: [],
    salesChannelCount: 0,
    drivers: [],
    driverCount: 0,
    isLoadingDriverList: false,
    isErrorFetch: false,
    isSuccessRefresh: false,
    keyCreatedObject: null,
  }
}
