import { connect } from 'react-redux'
import Discussion from '../../../pages/main/Orders/DetailsDelivery/components/discussion'

import {
  getEntryConversationDeliveryAction,
  getMoreEntryConversationDeliveryAction,
  focus,
  notFocus,
  cleaningConversationList,
  removeIdConversationUUID,
  removeIdConversation,
  getSubjectConversation,
  setLoadingOpenCloseConversation,
} from '../../../redux/discussion/conversations/action'
import {
  getConversationDeliverySocket,
  registerSocket,
  removeListenerSocket,
  getMessageSocket,
  sendMessage,
  seenMessage,
  createConversationSocket,
  CloseOpenConversation,
} from '../../../redux/discussion/socket/action'
import {
  getConversationsLivraison,
  getConversationError,
  getConversationLoading,
  getsocketNotRegistred,
  getFocus,
  getCountDelivery,
  getConversationLoadingMore,
  getIdConversation,
  getConversationSubject,
  getIsLoadingSubjectConversation,
  getloadingOpenCloseConversation,
} from '../../../redux/discussion/conversations/selectors'

import {
  getMessageByConversationIdAction,
  cleaningMessageList,
  onUploadfileDiscussion,
  removeFileDiscussion,
  downloadFileDiscussion,
  setLoadingUploadFile,
  setErrorUploadFile,
  deleteFile,
  removeMessageNewConversation,
} from '../../../redux/discussion/messages/action'

import {
  getMessageListError,
  getMessageListLoading,
  getMessageListentries,
  getLoadingSendMessage,
  getLoadingUploadFile,
  getErrorUploadFile,
  getFilesUpload,
  getErrorMessageUploadFile,
} from '../../../redux/discussion/messages/selectors'

import { getClientsPro, getCollaborateurs } from '../../../redux/order/actions'
import {
  getLoadingGetCollaborateur,
  getLoadingGetClientsPro,
  getAgentsDiscussion,
} from '../../../redux/order/selectors'

import {
  getPackagWarehouse,
  getLoadingPackageOrder,
} from '../../../redux/orderPackage/selectors'

const mapStateToProps = (state) => ({
  conversations: getConversationsLivraison(state),
  isLoadingGetConversations: getConversationLoading(state),
  errorGetConversations: getConversationError(state),
  issocketNotRegistred: getsocketNotRegistred(state),
  varFocus: getFocus(state),
  errorGetMessageList: getMessageListError(state),
  isLoadingGetMessageList: getMessageListLoading(state),
  messages: getMessageListentries(state),
  count: getCountDelivery(state),
  isLoadingConversationsMore: getConversationLoadingMore(state),
  loadingSendMessage: getLoadingSendMessage(state),
  loadingUploadFile: getLoadingUploadFile(state),
  errorUploadFile: getErrorUploadFile(state),
  filesUpload: getFilesUpload(state),
  errorMessageUploadFile: getErrorMessageUploadFile(state),
  idConversation: getIdConversation(state),
  isLoadingGetCollaborateur: getLoadingGetCollaborateur(state),
  isLoadingGetClientsPro: getLoadingGetClientsPro(state),
  agentsDiscussion: getAgentsDiscussion(state),
  subjectConversation: getConversationSubject(state),
  isLoadingSubjectConversation: getIsLoadingSubjectConversation(state),
  loadingOpenCloseConversation: getloadingOpenCloseConversation(state),
  warehousePackage: getPackagWarehouse(state),
  isLoadingPackageOrder: getLoadingPackageOrder(state),
})
const mapDisptachToProps = (dispatch) => ({
  notFocus: () => dispatch(notFocus()),
  focus: () => dispatch(focus()),
  cleaningConversationList: () => dispatch(cleaningConversationList()),

  getEntryConversationDeliveryAction: (data) =>
    dispatch(getEntryConversationDeliveryAction(data)),
  getMessageByConversationIdAction: (conversationId, legacyId) =>
    dispatch(getMessageByConversationIdAction(conversationId, legacyId)),
  cleaningMessageList: () => dispatch(cleaningMessageList()),
  getMoreEntryConversationDeliveryAction: (data) =>
    dispatch(getMoreEntryConversationDeliveryAction(data)),
  registerSocket: () => dispatch(registerSocket()),
  getConversationDeliverySocket: (ldv) =>
    dispatch(getConversationDeliverySocket(ldv)),
  removeListenerSocket: (event) => dispatch(removeListenerSocket(event)),
  getMessageSocket: (idConversation) =>
    dispatch(getMessageSocket(idConversation, true)),
  sendMessage: (entry, connectedUser) => dispatch(sendMessage(entry, connectedUser)),
  seenMessage: (entry, connectedUser) => dispatch(seenMessage(entry, connectedUser)),
  downloadFile: (urlFile) => dispatch(downloadFileDiscussion(urlFile)),
  removeFileDiscussion: (nameFile) => dispatch(removeFileDiscussion(nameFile)),
  onUploadfileDiscussion: (files) => dispatch(onUploadfileDiscussion(files)),
  setLoadingUploadFile: () => dispatch(setLoadingUploadFile()),
  setErrorUploadFile: (error) => dispatch(setErrorUploadFile(error)),
  deleteFile: (filesUpload) => dispatch(deleteFile(filesUpload)),
  createConversationSocket: (
    entryCreateConversation,
    entrySendMessage,
    idConversation
  ) =>
    dispatch(
      createConversationSocket(
        entryCreateConversation,
        entrySendMessage,
        idConversation
      )
    ),
  removeIdConversationUUID: () => dispatch(removeIdConversationUUID()),
  removeMessageNewConversation: () => dispatch(removeMessageNewConversation()),
  removeIdConversation: () => dispatch(removeIdConversation()),
  getCollaborateurs: (codeCourse) => dispatch(getCollaborateurs(codeCourse)),
  getClientsPro: (codeCanal) => dispatch(getClientsPro(codeCanal)),
  getSubjectConversation: () => dispatch(getSubjectConversation()),
  CloseOpenConversation: (entry, connectedUser) =>
    dispatch(CloseOpenConversation(entry, connectedUser)),
  setLoadingOpenCloseConversation: (loading) =>
    dispatch(setLoadingOpenCloseConversation(loading)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Discussion)
