import { connect } from 'react-redux'

import DialogDownloadKilometrageDocument from '../../../../components/NavFilter/components/searchCorner/dialogDownloadKilometrageDocument'

import {
  getTourFilterList,
  getIsTourListLoading,
  getCountListTour,
} from '../../../../redux/filter/selectors'

import { getTourList, getTourListMore } from '../../../../redux/filter/actions'
import { generationKilometrageTournee } from '../../../../redux/documents/actions'

const mapStateToProps = (state) => ({
  options: getTourFilterList(state),
  isLoading: getIsTourListLoading(state),
  countOptions: getCountListTour(state),
})
const mapDisptachToProps = (dispatch) => ({
  onSearch: (filter) => dispatch(getTourList(filter)),
  onSearchMore: (filter) => dispatch(getTourListMore(filter)),
  downloadDocument: (payload) => {
    dispatch(generationKilometrageTournee(payload))
  },
})

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(DialogDownloadKilometrageDocument)
