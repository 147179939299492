import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CardActions from '@mui/material/CardActions/CardActions'
import SetPrepared from '../../../../../../containers/main/ActionShipment/SetPreparedDetails'
import EditIcon from '@mui/icons-material/Edit'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import CancelShipment from '../../../../../../containers/main/ActionShipment/CancelShipment'
import RelatedCourse from '../../../../../../containers/main/RelatedCourse'
import Fab from '@mui/material/Fab'
import ClearIcon from '@mui/icons-material/Clear'
import { red } from '@mui/material/colors'
import NoteIcon from '@mui/icons-material/Note'
import '../fab-button.scss'
import relatedLinkCell from '../../../../../../assets/icons/relatedLinkCell.svg'
import { Link } from 'react-router-dom'
import {
  CANCELED_STATUS_LIST,
  CANCELED_STATUS_LIST_DESATRIBUER,
  TERMINEE_STATUS,
} from '../../../../../../utils/values'
import {
  COURSE_EDIT_TYPE,
  getOrderStatus,
} from '../../../../../../utils/getOrderStatus'
import {
  PROGRAMMED_STATUS,
  DECHARGEMENT_EN_COURS_STATUS,
  VERS_ENLEVEMENT_STATUS,
  CHARGEMENT_STATUS,
  ENLEVEE_STATUS,
  VERS_DECHARGEMENT_STATUS,
  DONE_STATUS,
  LIVRAISON_IMPOSSIBLE,
  RETRAIT_IMPOSSIBLE,
  CREER,
  CANCELED_STATUS,
  SUPPLY_COMPLETE_STATUS,
} from '../../../../../../utils/values'
import { useTheme } from '@mui/material/styles'
import RestoreIcon from '@mui/icons-material/Restore'
import Deprogram from '../../../../../../containers/main/ActionShipment/Deprogram'
import Dialog from '../../../../../../components/ModalDialog'
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled'
import Desattribution from '../../../../../../containers/main/ActionShipment/Desattribution'
import moment from 'moment'

export default function CardActionsComponent({
  canBePrepared,
  note,
  shipmentReference,
  codeCourse,
  status,
  isPrepared,
  courseSource,
  configs,
  chauffeur,
  isDetailsLivraison,
  index,
  deliveryRelated,
  modale,
  ldvRelatedCourse,
}) {
  const theme = useTheme()
  const disabled =
    CANCELED_STATUS_LIST.indexOf(status.code) !== -1 ||
    TERMINEE_STATUS.code === status.code

  const [open, setOpen] = useState(false)
  const [openNoteModal, setOpenNoteModal] = useState(false)
  const [dissociateOpen, setDissociateOpen] = useState(false)
  const CURRENT_USER = 'currentUser'
  const [connectedUser] = useState(JSON.parse(localStorage.getItem(CURRENT_USER)))
  const isDeprogram =
    (status.code === PROGRAMMED_STATUS.code ||
      status.code === DECHARGEMENT_EN_COURS_STATUS.code ||
      status.code === VERS_ENLEVEMENT_STATUS.code ||
      status.code === CHARGEMENT_STATUS.code ||
      status.code === ENLEVEE_STATUS.code ||
      status.code === VERS_DECHARGEMENT_STATUS.code) &&
    configs &&
    configs.hasSupplyStep

  const isDissociate = !(
    status.code === DONE_STATUS.code ||
    status.code === LIVRAISON_IMPOSSIBLE.code ||
    status.code === RETRAIT_IMPOSSIBLE.code ||
    status.code === CANCELED_STATUS.code ||
    CANCELED_STATUS_LIST_DESATRIBUER.includes(status.code) ||
    ((status.code === PROGRAMMED_STATUS.code || status.code === CREER.code) &&
      chauffeur === null)
  )
  const isEditDisabled =
    getOrderStatus(status) !== COURSE_EDIT_TYPE.editable &&
    getOrderStatus(status) !== COURSE_EDIT_TYPE.partialEdit &&
    getOrderStatus(status) !== COURSE_EDIT_TYPE.semiEdit
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const handleCancelOpen = () => {
    setOpenCancelModal(!openCancelModal)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const [openCourseLie, setOpenCourseLie] = useState(false)
  const handleCourseLieOpen = () => {
    setOpenCourseLie(!openCourseLie)
  }

  return (
    <>
      <CardActions disableSpacing sx={{ justifyContent: 'space-around' }}>
        {canBePrepared && (
          <SetPrepared
            defaultChecked={isPrepared}
            codeCourse={codeCourse}
            label={'Préparée'}
          />
        )}
        <>
          {!disabled && (
            <>
              <Tooltip
                title={<span className="tootltip-content">Annuler course</span>}
                arrow
              >
                <Fab
                  style={{ backgroundColor: 'white', color: red[600], zIndex: 0 }}
                  aria-label="close"
                  variant="extended"
                  className="fab-extended-button"
                  onClick={handleCancelOpen}
                  id={
                    !modale
                      ? `Annuler-Cours-${index}`
                      : `Annuler-Cours-Modale-${index}`
                  }
                >
                  <ClearIcon />
                </Fab>
              </Tooltip>
            </>
          )}
          {isDeprogram && (
            <Tooltip
              arrow
              title={<span className="tootltip-content">Déprogrammer course</span>}
            >
              <Fab
                style={{
                  zIndex: 0,
                  backgroundColor: 'white',
                  color: theme.palette.primary.main,
                }}
                aria-label="close"
                className="fab-button"
                id={
                  !modale
                    ? `Deprogrammer-chauffeur-${index}`
                    : `Deprogrammer-chauffeur-Modale-${index}`
                }
              >
                <RestoreIcon onClick={handleClickOpen} style={{ width: 22 }} />
              </Fab>
            </Tooltip>
          )}

          {isDissociate && !isDetailsLivraison && (
            <Tooltip
              arrow
              title={
                <span className="tootltip-content">Désattribuer chauffeur</span>
              }
            >
              <Fab
                style={{
                  zIndex: 0,
                  backgroundColor: 'white',
                  color: theme.palette.primary.main,
                }}
                aria-label="close"
                className="fab-button"
                id={
                  !modale
                    ? `Desattribuer-chauffeur-${index}`
                    : `Desattribuer-chauffeur-Modale-${index}`
                }
              >
                <PersonAddDisabledIcon
                  onClick={() => {
                    setDissociateOpen(true)
                  }}
                  style={{ width: 22 }}
                />
              </Fab>
            </Tooltip>
          )}
          {note && note?.length > 0 && (
            <Fab
              aria-label="close"
              style={{ zIndex: 0 }}
              className="fab-button fab-transparent"
            >
              <NoteIcon
                style={{
                  width: 22,
                  color: theme.palette.primary.main,
                  transform: 'rotate(-90deg)',
                }}
                onClick={() => setOpenNoteModal(true)}
                id={
                  !modale ? `Ouvrire-Note-${index}` : `Ouvrire-Note-Modale-${index}`
                }
              />
            </Fab>
          )}
          {!isEditDisabled && (
            <Tooltip
              id="Modifier-Card"
              arrow
              title={<span className="tootltip-content">Modifier course</span>}
            >
              <Link
                id={
                  !modale
                    ? `Modifier-Card-Link-${index}`
                    : `Modifier-Card-Link-Modale-${index}`
                }
                value={'/edit/' + codeCourse}
                to={'/edit/' + codeCourse}
              >
                <Fab
                  style={{
                    zIndex: 0,
                    backgroundColor: 'white',
                    color: theme.palette.primary.main,
                  }}
                  aria-label="close"
                  className="fab-button"
                >
                  <EditIcon
                    id={
                      !modale
                        ? `Modifier-Card-${index}`
                        : `Modifier-Card-Modale-${index}`
                    }
                    style={{ width: 22 }}
                  />
                </Fab>
              </Link>
            </Tooltip>
          )}
          {deliveryRelated && deliveryRelated !== null && (
            <>
              <Tooltip
                id={'Afficher-Card'}
                title={
                  <span className="tootltip-content">Afficher course associé</span>
                }
              >
                <Fab
                  style={{ backgroundColor: 'white', color: red[600], zIndex: 0 }}
                  aria-label="close"
                  variant="extended"
                  className="fab-extended-button"
                  onClick={handleCourseLieOpen}
                  id={`Afficher-Card-${index}`}
                >
                  <img src={relatedLinkCell} />
                </Fab>
              </Tooltip>
            </>
          )}
        </>
      </CardActions>
      {openCancelModal && (
        <CancelShipment
          open={openCancelModal}
          handleClose={handleCancelOpen}
          orderAnnuler={{ shipmentReference, codeCourse, courseSource }}
          payload={{
            collaborateurId: connectedUser.id,
            state: 'ANNULLEE',
            codeCourse: codeCourse,
            courseSource: courseSource,
            driver:
              chauffeur != null && chauffeur?.id ? { id: chauffeur.id } : undefined,
            method: 'corner/annulation',
            datetime: moment(new Date()).format(),
          }}
        />
      )}
      {openCourseLie && (
        <RelatedCourse
          open={openCourseLie}
          handleClose={handleCourseLieOpen}
          codeCourse={codeCourse}
          ldvRelatedCourse={ldvRelatedCourse}
          index={index}
          modale={true}
        />
      )}
      <Deprogram
        open={open}
        handleClose={handleClose}
        payload={{
          collaborateurId: connectedUser.id,
          state: SUPPLY_COMPLETE_STATUS.code,
          codeCourse: codeCourse,
          courseSource: courseSource,
          method: 'corner/déprogrammer',
          datetime: moment(new Date()).format(),
        }}
      />
      <Desattribution
        open={dissociateOpen}
        handleClose={() => {
          setDissociateOpen(false)
        }}
        payload={{
          collaborateurId: connectedUser.id,
          state: PROGRAMMED_STATUS.code,
          codeCourse: codeCourse,
          courseSource: courseSource,
          driver:
            chauffeur != null && chauffeur?.id ? { id: chauffeur.id } : undefined,
          method: 'Corner/DésattribuerChauffeur',
          datetime: moment(new Date()).format(),
        }}
      />
      <Dialog
        open={openNoteModal}
        title={`Note interne - ${shipmentReference}`}
        content={
          <div style={{ paddingBottom: '16px', paddingRight: '16px' }}>{note}</div>
        }
        handleClose={() => {
          setOpenNoteModal(false)
        }}
        maxWidthDialog={'md'}
        isModalActionFermer={true}
      />
    </>
  )
}
CardActionsComponent.propTypes = {
  status: PropTypes.object,
  canBePrepared: PropTypes.bool,
  note: PropTypes.string,
  shipmentReference: PropTypes.string,
  codeCourse: PropTypes.string,
  isPrepared: PropTypes.bool,
  courseSource: PropTypes.string,
  configs: PropTypes.any,
  chauffeur: PropTypes.object,
  isDetailsLivraison: PropTypes.bool,
  index: PropTypes.number,
  deliveryRelated: PropTypes.string,
  modale: PropTypes.bool,
  ldvRelatedCourse: PropTypes.string,
}
