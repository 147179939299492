import { connect } from 'react-redux'
import EditOrder from '../../../pages/main/EditOrders'
import {
  getIsOrderLoading,
  getOrderErrorMessage,
} from '../../../redux/shipment/selectors'

import { getOrder, resetOrder } from '../../../redux/shipment/actions'

import { resetDirections } from '../../../redux/googleMapDirections/actions'
import { getIsScriptLoadedSuccess } from '../../../redux/googleMapDirections/selectors'
const mapStateToProps = (state) => ({
  isGetSalesChannelListLoading: getIsOrderLoading(state),
  isScriptLoaded: getIsScriptLoadedSuccess(state),
  errorMessage: getOrderErrorMessage(state),
})

const mapDisptachToProps = (dispatch) => ({
  getOrder: (codeCourse) => dispatch(getOrder(codeCourse)),
  resetOrder: () => dispatch(resetOrder()),
  resetDirections: () => dispatch(resetDirections()),
})

export default connect(mapStateToProps, mapDisptachToProps)(EditOrder)
