import { getValue, checkExpirationDate } from '../../utils'
import { COLLABORATOR_EXPIRATION_DATE } from '../../utils/checkExpirationDate'

export default () => {
  checkExpirationDate(COLLABORATOR_EXPIRATION_DATE)
  const collaborateur = getValue('currentUser', {})
  const token = getValue('token', null)
  const userRole = localStorage.getItem('userRole') || null

  return {
    collaborateur,
    token,
    googleProfile: null,
    isLoggedIn: Object.entries(collaborateur).length > 0,
    isLoading: false,
    loginError: null,
    userRole,
  }
}
