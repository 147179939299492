import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMore from '@mui/icons-material/ExpandMore'
import moment from 'moment'

import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import CardAccordion from '../card/cardWithAccordion'

//destinataire : recipent
// date d'envoi : publishedAt
//status d'envoi: echec d'envoi /succes d'envoi
//code d'envoi :  view.codeName

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  minHeight: '48px !important',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderLeft: '4px solid #1976d2',
    '& .MuiAccordionSummary-content': {
      marginLeft: '-4px !important',
      transition: 'none',
    },
  },

  '& .MuiAccordionSummary-content': {
    transition: 'none',
    flexDirection: 'column',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
}))

const Notifications = ({
  order,
  smsNotification,
  emailNotification,
  getOrderNotification,
  isLoadingOrderNotification,
  isLoading,
}) => {
  useEffect(() => {
    if (order?.code) getOrderNotification(order.code)
  }, [order])

  const collectCreatedAtData = (date) => {
    const tzDate = moment(date).tz('Europe/Paris', true)
    const theDay = moment(tzDate).startOf('day').format('dddd, D MMMM, YYYY')
    const theHour = moment(tzDate).format('HH:mm')

    return `${theDay}, ${theHour}`
  }
  const getStatusEnvoi = (error) => {
    return error != null ? "Échec d'envoi" : "Succès d'envoi"
  }

  return (
    <CardAccordion
      icon={
        <NotificationsNoneIcon
          style={{ marginRight: '4px', width: '20px', height: '20px' }}
        />
      }
      title={'Notification de livraison'}
      content={
        <>
          {isLoadingOrderNotification || isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px',
              }}
            >
              <CircularProgress color="primary" />
            </div>
          ) : (
            <>
              {(smsNotification && smsNotification?.length > 0) ||
              (emailNotification && emailNotification?.length > 0) ? (
                <>
                  {emailNotification && emailNotification?.length > 0 && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          paddingTop: '15px',
                          marginBottom: '15px',
                        }}
                      >
                        <MailOutlineIcon style={{ marginRight: '4px' }} />
                        <span
                          style={{
                            fontSize: '13px',
                            fontWeight: '600',
                          }}
                        >
                          {' '}
                          Email
                        </span>
                      </div>
                      <div style={{ wordBreak: 'break-word' }}>
                        {emailNotification.map((email, index) => {
                          return (
                            <>
                              <Accordion
                                sx={{
                                  boxShadow: 'none !important',
                                  width: '100%',
                                  margin: '0 !important',
                                  '&:before': {
                                    opacity: 0,
                                  },
                                }}
                              >
                                <StyledAccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1bh-content"
                                  id={`email-AccordionSummary-${index}`}
                                  expanded={true}
                                >
                                  <div style={{ display: 'flex' }}>
                                    <span
                                      style={{
                                        fontSize: '13px',
                                        fontWeight: '700',
                                        paddingRight: '5px',
                                      }}
                                    >
                                      {'Email destinataire:'}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '13px',
                                        fontWeight: '550',
                                      }}
                                    >
                                      {email.recipient.email}
                                    </span>
                                  </div>
                                  <span
                                    style={{
                                      fontSize: '13px',
                                      fontWeight: '550',
                                      overflowWrap: 'anywhere',
                                    }}
                                  >
                                    {collectCreatedAtData(email.publishedAt)}
                                  </span>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                  <div>
                                    <div style={{ display: 'flex' }}>
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          fontWeight: '700',
                                          paddingRight: '5px',
                                        }}
                                      >
                                        {"Status de l'envoi:"}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          fontWeight: '550',
                                        }}
                                      >
                                        {getStatusEnvoi(email.error)}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        marginBottom: '15px',
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          fontWeight: '700',
                                          paddingRight: '5px',
                                        }}
                                      >
                                        {"Objet de l'email:"}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          fontWeight: '550',
                                        }}
                                      >
                                        {email.extraParams.subject}
                                      </span>
                                    </div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: email.compiledNotificationContent,
                                      }}
                                    ></div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                  {smsNotification && smsNotification?.length > 0 && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          paddingTop: '15px',
                          marginBottom: '15px',
                        }}
                      >
                        <TextsmsOutlinedIcon
                          style={{ marginRight: '4px', marginTop: '3px ' }}
                        />{' '}
                        <span
                          style={{
                            fontSize: '13px',
                            fontWeight: '600',
                          }}
                        >
                          SMS
                        </span>
                      </div>
                      <div style={{ wordBreak: 'break-word' }}>
                        {smsNotification.map((sms, index) => {
                          return (
                            <>
                              <Accordion
                                sx={{
                                  boxShadow: 'none !important',
                                  width: '100%',
                                  margin: '0 !important',
                                  '&:before': {
                                    opacity: 0,
                                  },
                                }}
                              >
                                <StyledAccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1bh-content"
                                  id={`sms-AccordionSummary-${index}`}
                                >
                                  <div style={{ display: 'flex' }}>
                                    <span
                                      style={{
                                        fontSize: '13px',
                                        fontWeight: '700',
                                        paddingRight: '5px',
                                      }}
                                    >
                                      {'Tel destinataire:'}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '13px',
                                        fontWeight: '550',
                                      }}
                                    >
                                      {sms.recipient.phoneNumber}
                                    </span>
                                  </div>
                                  <span
                                    style={{
                                      fontSize: '13px',
                                      fontWeight: '550',
                                      overflowWrap: 'anywhere',
                                    }}
                                  >
                                    {collectCreatedAtData(sms.publishedAt)}
                                  </span>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                  <div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        marginBottom: '15px',
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          fontWeight: '700',
                                          paddingRight: '5px',
                                        }}
                                      >
                                        {"Status de l'envoi:"}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: '13px',
                                          fontWeight: '550',
                                        }}
                                      >
                                        {getStatusEnvoi(sms.error)}
                                      </span>
                                    </div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: sms.compiledNotificationContent,
                                      }}
                                    ></div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <span
                    style={{
                      overflowWrap: 'break-word',
                      fontSize: '12px',
                      fontWeight: '550',
                    }}
                  >
                    {'Historique de Notification est vide'}
                  </span>
                </div>
              )}
            </>
          )}
        </>
      }
    />
  )
}
Notifications.propTypes = {
  order: PropTypes.object,
  smsNotification: PropTypes.array,
  emailNotification: PropTypes.array,
  getOrderNotification: PropTypes.func,
  isLoadingOrderNotification: PropTypes.bool,
  isLoading: PropTypes.bool,
}
export default Notifications
