import React from 'react'
import PropTypes from 'prop-types'

import SelectListGroupWithSearch from '../../../../../ListWithSearch/selectListGroupWithSearch'
import IconSalesChannel from '../../../../../../assets/icons/navFilter/SalesChannelIcon'
export default function SalesChannels({
  salesChannels,
  disabled,
  filterStateChanged,
  channelsFiltersValues,
  isLoading,
}) {
  const onSalesChannelChanged = (selectedchannels) => {
    filterStateChanged('salesChannels', selectedchannels ? selectedchannels : [])
  }
  return (
    <SelectListGroupWithSearch
      disabled={disabled || isLoading || salesChannels.length <= 0}
      dataList={salesChannels}
      defaultLabel={'Canal'}
      onDataChanged={onSalesChannelChanged}
      filtersValues={
        channelsFiltersValues?.length
          ? channelsFiltersValues.map((f) => decodeURI(f))
          : channelsFiltersValues
      }
      Icon={IconSalesChannel}
      id={'Channels'}
    />
  )
}
SalesChannels.propTypes = {
  salesChannels: PropTypes.array,
  disabled: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  channelsFiltersValues: PropTypes.array,
  isLoading: PropTypes.bool,
}
