export const KeyTableHead = [
  { id: 'id', label: 'Id' },
  { id: 'value', label: '#Valeur' },
  {
    id: 'label',
    label: 'Label',
  },
  {
    id: 'createdAt',
    label: 'Date de création',
  },
  {
    id: 'updatedAt',
    label: 'Date de mise à jour',
  },
  {
    id: 'category',
    label: 'Catégorie',
  },
  { id: 'comment', label: 'Commentaire' },
]

export const ChannelTableHead = [
  { id: 'id', label: 'Id' },
  { id: 'name', label: 'Canal de vente' },
  {
    id: 'keys',
    label: 'Clé',
  },
  {
    id: 'createdAt',
    label: 'Date de création',
  },
  {
    id: 'affectDate',
    label: "Date d'association du clé",
  },
]

export const DriverKeyTableHead = [
  { id: 'id', label: 'Id' },
  { id: 'fullName', label: 'Chauffeur' },
  {
    id: 'keys',
    label: 'Clé',
  },
  {
    id: 'createdAt',
    label: 'Date de création',
  },
  {
    id: 'affectDate',
    label: "Date d'association du clé",
  },
]

export const ParticipantTableHead = [
  { id: 'lastName', label: 'Nom' },
  { id: 'firstName', label: 'Prénom' },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'phone',
    label: 'Téléphone',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'competences',
    label: 'Compétences',
  },
  // {
  //   id: 'files',
  //   label: 'Documents',
  // },
]

export const FormationTableHead = [
  { id: 'name', label: 'Nom' },

  { id: 'createdAt', label: 'Date de création' },
  {
    id: 'updatedAt',
    label: 'Date de modification',
  },
  {
    id: 'family',
    label: 'Famille',
  },
  {
    id: 'contractor',
    label: "Donneur d'order",
  },
  {
    id: 'nbrHours',
    label: "Nombre d'heure",
  },
  {
    id: 'competences',
    label: 'Compétences',
  },
  {
    id: 'files',
    label: 'Documents',
  },
]

export const DriverTableHead = [
  { id: 'lastName', label: 'Nom' },
  { id: 'firstName', label: 'Prénom' },
  {
    id: 'phone',
    label: 'Téléphone',
  },
  {
    id: 'email',
    label: 'Adresse mail',
  },
  {
    id: 'adresse',
    label: 'Adresse domicile',
  },
  {
    id: 'role',
    label: 'Rôle',
  },
  {
    id: 'competences',
    label: 'Compétences',
  },
]

export const VehiculeTableHead = [
  { id: 'name', label: 'Nom/Modéle' },
  { id: 'immatriculation', label: 'Immatriculation' },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'emisson',
    label: 'Emisson co2 ',
  },
  {
    id: 'price',
    label: 'Coût',
  },
  {
    id: 'weight',
    label: 'Poids',
  },

  {
    id: 'volume',
    label: 'Volume',
  },
  {
    id: 'competences',
    label: 'Clés associés',
  },
]

export const TourneeTableHead = [
  { id: 'name', label: 'Nom' },
  {
    id: 'timeWindow',
    label: 'Horaires de travail',
  },
  {
    id: 'departureAddress',
    label: 'Adresse départ',
  },

  {
    id: 'arrivalAddress',
    label: "Adresse d'arrivée",
  },
  {
    id: 'participant',
    label: 'Chauffeur',
  },
  {
    id: 'vehicle',
    label: 'Véhicule',
  },
]

export const DisponibiliteTableHead = [
  { id: 'day', label: 'Jour', visible: ['table', 'create', 'modification'] },
  {
    id: 'creneaux',
    label: 'Créneaux',
    visible: ['table', 'create', 'modification'],
  },
  {
    id: 'truckCounts',
    label: 'Nb de camions',
    visible: ['table', 'create', 'modification'],
  },
  {
    id: 'spanCounts',
    label: 'Nb spans',
    visible: ['table', 'create', 'modification'],
  },

  {
    id: 'spanDuration',
    label: 'Durée span',
    visible: ['table', 'create', 'modification'],
  },

  {
    id: 'spanRestants',
    label: 'Nb spans restants',
    visible: ['table', 'modification'],
  },
  {
    id: 'zones',
    label: 'Zones',
    visible: ['table', 'create', 'modification'],
  },
]
