import React, { useState } from 'react'
import {
  IconButton,
  List,
  Popover,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import PropTypes from 'prop-types'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  StyledListItemActions,
  StyledListItemTextActions,
  sxInput,
} from '../../styled'

import ImporterParticipants from '../../../../containers/settings/participants/importationParticipants'
export function EnhancedTableHead({ tableHead, setOpen, globalID, dropMenuCreate }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenu, setOpenMenul] = useState(false)
  const [dialogImporterParticipants, setDialogImporterParticipants] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenMenul(!openMenu)
  }
  const handleCloseMenuBarre = () => {
    setAnchorEl(null)
    setOpenMenul(false)
  }
  return (
    <>
      <TableHead>
        <TableRow>
          {tableHead.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={'normal'}
              sx={{
                fontWeight: '600',
                fontSize: '12px',
                borderColor: '#F0F0F0',
                background: '#E3EFFA',
                '&:first-of-type': {
                  borderTopLeftRadius: '4px',
                  borderBottomLeftRadius: '4px',
                },
              }}
            >
              {headCell.label}
            </TableCell>
          ))}
          <Tooltip title={<span className="tootltip-content">Ajouter</span>} arrow>
            <TableCell
              sx={{
                position: 'sticky',
                right: '0',
                top: '0',
                borderColor: '#F0F0F0',

                background: '#E3EFFA',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
              }}
              align={'right'}
              padding={'normal'}
            >
              {dropMenuCreate ? (
                <>
                  <IconButton
                    id={'button-actions-menu'}
                    onClick={handleClick}
                    aria-describedby={'simple-popover'}
                  >
                    <AddCircleIcon sx={{ fontSize: 25 }} color="primary" />
                  </IconButton>
                  <Popover
                    id={'popover-actions'}
                    open={openMenu}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenuBarre}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: 144,
                        width: '27.5ch',
                      },
                    }}
                    sx={{ zIndex: 25001, top: '4px', left: '10px' }}
                  >
                    <List id={'popover-list'} component="nav">
                      <StyledListItemActions
                        id={'button-Commander'}
                        button
                        onClick={() => {
                          setOpen(true)
                          handleCloseMenuBarre()
                        }}
                      >
                        <StyledListItemTextActions primary="Ajouter nouveau participant" />
                      </StyledListItemActions>
                      <StyledListItemActions
                        id={'button-importer-courses'}
                        button
                        onClick={() => {
                          setDialogImporterParticipants(true)
                          handleCloseMenuBarre()
                        }}
                      >
                        <StyledListItemTextActions primary="Importer participants" />
                      </StyledListItemActions>
                    </List>
                  </Popover>
                </>
              ) : (
                <IconButton id={`Ajoute-${globalID}`} onClick={() => setOpen(true)}>
                  <AddCircleIcon sx={{ fontSize: 25 }} color="primary" />
                </IconButton>
              )}
            </TableCell>
          </Tooltip>
        </TableRow>
      </TableHead>
      {dialogImporterParticipants && (
        <ImporterParticipants
          open={dialogImporterParticipants}
          handleClose={() => setDialogImporterParticipants(false)}
          sxInput={sxInput}
        />
      )}
    </>
  )
}

EnhancedTableHead.propTypes = {
  tableHead: PropTypes.array,
  setOpen: PropTypes.func,
  globalID: PropTypes.string,
  dropMenuCreate: PropTypes.bool,
}
