import { connect } from 'react-redux'

import Departements from '../../../components/NavFilter/components/Filter/cell/Departements'
import {
  getDepartemenets,
  getIsDepartemenetsListLoading,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  departements: getDepartemenets(state),
  isLoading: getIsDepartemenetsListLoading(state),
})

export default connect(mapStateToProps, null)(Departements)
