import React from 'react'
import Conversation from './conversationItem'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import CircularProgress from '@mui/material/CircularProgress'

export default function OpenConversationList({
  conversations,
  getMessageListByConversationId,
  conversationSelect,

  getOpenConversationMore,
  offset,
  count,
  isLoadingConvMore,
}) {
  const loadMoreConversatons = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) - 1 <=
        event.target.clientHeight &&
      offset < count &&
      !isLoadingConvMore
    ) {
      //user is at the end of the list so load more items
      getOpenConversationMore()
    }
  }
  return (
    <List
      disablePadding={true}
      onScroll={loadMoreConversatons}
      sx={{ height: '100%', overflow: 'auto' }}
    >
      {conversations.length > 0 ? (
        conversations
          .filter((conversationItem) => conversationItem.isOpen)
          .map((conversationItem, index) => (
            <Conversation
              key={index}
              conversationItem={conversationItem}
              getMessageListByConversationId={getMessageListByConversationId}
              conversationSelect={conversationSelect}
              index={index}
            />
          ))
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '50%',
          }}
        >
          <div>Aucune discussion</div>
        </div>
      )}
      {isLoadingConvMore && (
        <div style={{ padding: '8% 40%' }}>
          <CircularProgress color="primary" />
        </div>
      )}
    </List>
  )
}

OpenConversationList.propTypes = {
  conversations: PropTypes.array,
  getMessageListByConversationId: PropTypes.func,

  conversationSelect: PropTypes.string,
  getOpenConversationMore: PropTypes.func,
  count: PropTypes.number,
  offset: PropTypes.number,
  isLoadingConvMore: PropTypes.bool,
}
