import moment from 'moment'
import 'moment/locale/fr'
const getDate = (date) => {
  const tzDate = moment.tz(date, 'Europe/Paris')
  const formatedDate = moment(tzDate).isValid() ? moment(date).format('LLLL') : 'N/A'
  return formatedDate
}
const parseDateString = (date) => {
  return date ? moment(date).toDate() : moment().toDate()
}
const getDays = (date) => {
  const tzDate = moment.tz(date, 'Europe/Paris')
  const formatedDate = moment(tzDate).isValid() ? moment(tzDate).format('LL') : 'N/A'
  return formatedDate
}
const getHours = (date) => {
  const tzDate = moment.tz(date, 'Europe/Paris')
  const formatedDate = moment(tzDate).isValid()
    ? moment(tzDate).format('HH:mm')
    : 'N/A'
  return formatedDate
}
const getStartDate = (dateDemarrageMeta, dateDemarrage) => {
  const date = moment.tz(dateDemarrage, 'Europe/Paris')
  const startVal = moment(date).isValid() ? moment(date).format('LLLL') : 'N/A'
  const endVal = moment(date)
    .add(dateDemarrageMeta?.deliveryWindow, 'minutes')
    .isValid()
    ? moment(date).add(dateDemarrageMeta?.deliveryWindow, 'minutes').format('HH:mm')
    : 'N/A'
  return dateDemarrageMeta ? startVal + ' ~ ' + endVal : startVal
}
// TODO nizar : change name
const getDropOffDate = (dateDemarrageMeta, dropOffStart, dropOffEnd) => {
  const start = moment.tz(dropOffStart, 'Europe/Paris')
  const end = moment.tz(dropOffEnd, 'Europe/Paris')
  const startVal = moment(start).isValid() ? moment(start).format('LLLL') : 'N/A'
  const endVal = moment(end).isValid() ? moment(end).format('LLLL') : 'N/A'
  return !dropOffEnd
    ? moment(start).isValid()
      ? moment(start).format('LLLL') +
        ' ~ ' +
        moment(start)
          .add(dateDemarrageMeta?.deliveryWindow, 'minutes')
          .format('HH:mm')
      : 'N/A'
    : startVal + ' ~ ' + endVal
}

const getPinHourLabel = (dateDemarrageMeta, dropOffStart, dropOffEnd) => {
  const start = moment.tz(dropOffStart, 'Europe/Paris')
  const end = moment.tz(dropOffEnd, 'Europe/Paris')
  const startVal = moment(start).isValid() ? moment(start).format('HH:mm') : 'N/A'
  const endVal = moment(end).isValid() ? moment(end).format('HH:mm') : 'N/A'
  return !dropOffEnd
    ? moment(start).isValid()
      ? moment(start).format('dd/MM HH:mm') +
        ' ~ ' +
        moment(start)
          .add(dateDemarrageMeta?.deliveryWindow, 'minutes')
          .format('HH:mm')
      : 'N/A'
    : startVal + ' ~ ' + endVal
}

const getDateStartEnd = (dateDemarrageMeta, dropOffStart, dropOffEnd) => {
  const start = moment.tz(dropOffStart, 'Europe/Paris')
  const end = moment.tz(dropOffEnd, 'Europe/Paris')
  const startVal = moment(start).isValid() ? moment(start).format('LLLL') : 'N/A'
  const endVal = moment(end).isValid() ? moment(end).format('HH:mm') : 'N/A'
  return !dropOffEnd
    ? moment(start).isValid()
      ? moment(start).format('LLLL') +
        ' ~ ' +
        moment(start)
          .add(dateDemarrageMeta?.deliveryWindow, 'minutes')
          .format('HH:mm')
      : 'N/A'
    : startVal + ' ~ ' + endVal
}
export {
  getStartDate,
  getHours,
  getDays,
  getDropOffDate,
  getDateStartEnd,
  getDate,
  getPinHourLabel,
  parseDateString,
}
