import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import AutoCompleteSearch from './AutoCompleteSearch'

import DrawerFilter from '../../Drawer'

export default function SelectListWithSearch({
  dataList,
  disabled,
  onDataChanged,
  defaultLabel,
  filtersValues,
  Icon,
  id,
  getListData,
  getListMoreData,
  countListData,
  isMoreLoading,
}) {
  const [opentDrawer, setOpentDrawer] = useState(false)
  const [index, setIndex] = useState(0)
  const [lastIndex, setLastIndex] = useState(25)
  const [selected, setSelected] = useState([])
  const [checkedAll, setCheckedAll] = useState(true)
  const [search, setSearch] = useState('')
  const [label, setLabel] = useState('Tous')
  const [init, setInit] = useState(false)

  //Init selected List
  useEffect(() => {
    if (dataList) {
      const checkedAll = filtersValues?.includes('TOUS')
      if (filtersValues && filtersValues.length > 0 && !checkedAll) {
        const initFilterTAB = filtersValues ? filtersValues : []
        setSelected(initFilterTAB)
      } else if (
        checkedAll ||
        selected.length === 0 ||
        selected.length === index ||
        selected.length === lastIndex
      ) {
        const selecteds = dataList.map((n) => n.code)
        setSelected(selecteds)
      }
      if (search === '') setLastIndex(dataList.length)
      setInit(true)
    }
  }, [dataList, filtersValues])
  //Change lable
  useEffect(() => {
    handleLabelDisplay()
  }, [selected, lastIndex])

  const handleLabelDisplay = () => {
    let text = ''
    text = selected.join(', ')

    if (text.length > 20) {
      text = text.substring(0, 20).concat('...')
    }
    setLabel(selected.length > 0 && selected.length < lastIndex ? `${text}` : 'Tous')
  }

  //FETCH more data with search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        onSearch(search)
      } else {
        if (init) {
          const filter = {
            limit: 25,
            offset: 0,
          }
          getListData(filter)
          setIndex(0)
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    setSearch(value)
    setIndex(0)
  }

  const onSearch = (search) => {
    const filter = {
      limit: 25,
      offset: 0,
      query: search?.trim(),
    }

    getListData(filter)
  }

  //FETCH more data with scroll
  const fetchMoreData = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) <=
        event.target.clientHeight &&
      index < countListData &&
      !isMoreLoading
    ) {
      setIndex(index + 25)
      const filter = {
        limit: 25,
        offset: index + 25,
        query: search.length > 0 ? search?.trim() : undefined,
      }
      getListMoreData(filter)
    }
  }
  // Click single data
  const handleToggle = (value) => () => {
    let selectedNow =
      search === '' || (search != '' && selected.length < lastIndex) ? selected : []

    const selectedIndex = selectedNow.indexOf(value)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedNow, value)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedNow.slice(1))
    } else if (selectedIndex === selectedNow.length - 1) {
      newSelected = newSelected.concat(selectedNow.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedNow.slice(0, selectedIndex),
        selectedNow.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleDoubleClick = (value) => () => {
    setSelected([value])
  }

  // Click All list DATA
  const handleToggleAll = (event) => {
    setCheckedAll(event.target.checked)
    if (event.target.checked) {
      const newSelecteds = dataList.map((n) => n.code)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }
  const handleDoubleClickAll = () => {
    setCheckedAll(!checkedAll)
    if (!checkedAll) {
      const newSelecteds = dataList.map((n) => n.code)
      console.log('6===>')

      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  //Verif checked element
  const isSelected = (code) => selected.indexOf(code) !== -1

  // open && close drawer
  const toggleDrawer = (open, event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (!open) {
      if (selected.length === 0) {
        setSelected([])
        onDataChanged(null)
      } else {
        onDataChanged(
          selected.length === lastIndex ? ['TOUS'] : [...new Set(selected)]
        )
      }
    }
    setOpentDrawer(open)
    setSearch('')
  }

  return (
    <DrawerFilter
      filterLabel={`${defaultLabel} : ${label}`}
      disabled={disabled}
      content={
        <AutoCompleteSearch
          dataList={dataList}
          handleToggle={handleToggle}
          handleDoubleClick={handleDoubleClick}
          fetchMoreData={fetchMoreData}
          onChangeSearch={onChangeSearch}
          search={search}
          isMoreLoading={isMoreLoading}
          handleDoubleClickAll={handleDoubleClickAll}
          handleToggleAll={handleToggleAll}
          numSelected={selected.length}
          isSelected={isSelected}
        />
      }
      toggleDrawer={toggleDrawer}
      opentDrawer={opentDrawer}
      Icon={Icon}
      id={id}
    />
  )
}
SelectListWithSearch.propTypes = {
  dataList: PropTypes.array,
  disabled: PropTypes.bool,
  onDataChanged: PropTypes.func,
  defaultLabel: PropTypes.string,
  filtersValues: PropTypes.array,
  Icon: PropTypes.any,
  id: PropTypes.string,
  getListMoreData: PropTypes.func,
  countListData: PropTypes.number,
  isMoreLoading: PropTypes.bool,
  getListData: PropTypes.func,
}
