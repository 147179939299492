import React from 'react'

export function MaterialSymbolsAutoModeRounded(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.45 13.55L8 12.45q-.3-.125-.3-.45t.3-.45l2.45-1.125L11.55 8q.125-.3.45-.3t.45.3l1.125 2.425L16 11.55q.3.125.3.45t-.3.45l-2.425 1.1L12.45 16q-.125.3-.45.3t-.45-.3l-1.1-2.45ZM3 18.3V20q0 .425-.288.713T2 21q-.425 0-.713-.288T1 20v-4q0-.425.288-.713T2 15h4q.425 0 .713.288T7 16q0 .425-.288.713T6 17H4.55q1.275 1.875 3.238 2.938T12 21q2.6 0 4.75-1.35t3.3-3.625q.225-.425.588-.675t.837-.15q.45.1.587.512t-.087.888q-1.35 2.9-4.025 4.65T12 23q-2.7 0-5.062-1.238T3 18.3ZM2.075 11q-.425 0-.688-.313T1.2 9.95q.25-1.175.65-2.162T2.925 5.8q.25-.375.65-.425t.725.275q.35.35.35.763t-.275.837q-.425.65-.675 1.3t-.45 1.425q-.1.45-.413.738T2.075 11ZM11 2.05q0 .475-.288.775t-.762.4q-.75.175-1.387.45t-1.288.7q-.4.275-.813.25T5.7 4.25q-.3-.3-.263-.688t.388-.662q.975-.65 1.938-1.062T9.9 1.2q.45-.075.775.175T11 2.05Zm7.35 2.2q-.35.35-.775.363t-.825-.263q-.65-.425-1.3-.675t-1.425-.45q-.45-.1-.738-.412T13 2.05q0-.425.313-.675t.737-.175q1.2.225 2.175.625T18.2 2.9q.35.25.4.65t-.25.7Zm3.6 6.75q-.475 0-.775-.288t-.4-.762q-.2-.775-.462-1.412t-.688-1.313q-.275-.4-.25-.812t.375-.763q.3-.3.688-.25t.662.4q.675 1 1.075 1.975T22.8 9.95q.075.425-.175.738T21.95 11Z"
      ></path>
    </svg>
  )
}
export default MaterialSymbolsAutoModeRounded
