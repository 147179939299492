import { connect } from 'react-redux'
import DatePicker from '../../../components/NavFilter/components/Filter/cell/DateFilter'
import { setSelectedDate } from '../../../redux/filter/actions'
const mapDisptachToProps = (dispatch) => ({
  setSelectedDate: (pickedDate) => {
    dispatch(setSelectedDate(pickedDate))
  },
})

export default connect(null, mapDisptachToProps)(DatePicker)
