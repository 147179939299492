import React from 'react'
import PropTypes from 'prop-types'

import jwt from 'jsonwebtoken'

export default function DashboardEntrepot({ collaborateur }) {
  const getDashboarURL = () => {
    var payload = {
      resource: {
        dashboard: Number(process.env.REACT_APP_METABASE_DASHBOARD_NUMBER),
      },
      params: {
        id: collaborateur.id,
      },
    }
    var token = jwt.sign(payload, process.env.REACT_APP_METABASE_SECRET_KEY)

    var iframeUrl =
      process.env.REACT_APP_METABASE_SITE_URL +
      '/embed/dashboard/' +
      token +
      '#bordered=true&titled=true'
    return iframeUrl
  }

  console.log('urL===>', getDashboarURL(), collaborateur)
  return (
    <div style={{ padding: '10px 0px 10px' }}>
      <iframe
        src={getDashboarURL()}
        frameBorder="0"
        width="100%"
        height="1000"
        style={{ backgroundColor: 'transparent' }}
      ></iframe>
    </div>
  )
}
DashboardEntrepot.propTypes = {
  collaborateur: PropTypes.object,
}
