import React from 'react'
import Button from '@mui/material/Button'

import './index.scss'
import PropTypes from 'prop-types'

/** A no border outline Button Component */
export default function PrimaryOutlineNoBorderButton({
  label,
  onClick,
  disabled,
  style,
  idButton,
}) {
  return (
    <div id={idButton} className="outline-no-border">
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={disabled}
        style={style}
      >
        {label}
      </Button>
    </div>
  )
}
PrimaryOutlineNoBorderButton.propTypes = {
  /** Content of the button */
  label: PropTypes.string,

  /** The function that is executed when clicked */
  onClick: PropTypes.func,

  /** If true the button is no longer clickable */
  disabled: PropTypes.bool,

  /** Style of the button */
  style: PropTypes.any,
  idButton: PropTypes.string,
}
