import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@mui/material/Tooltip'

const TextComponent = ({ content, key, isDisabledAddress }) => {
  return (
    <Tooltip
      arrow
      title={
        <span
          style={{
            fontSize: '10px',
            textTransform: 'none',
            fontWeight: '400',
          }}
        >
          {isDisabledAddress
            ? 'L’adresse est erronée, merci de la modifier pour optimiser'
            : content}
        </span>
      }
    >
      <p
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          margin: 'auto',
          fontSize: '11px',
        }}
        key={key}
      >
        {content}
      </p>
    </Tooltip>
  )
}

TextComponent.propTypes = {
  content: PropTypes.string,
  key: PropTypes.string,
  isDisabledAddress: PropTypes.bool,
}
export default TextComponent
