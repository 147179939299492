import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CategorieRecherche from './categorieRecherche'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import AutoComplete from '../../../AutoComplete'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { CATEGORIE_RECHERCHE } from '../../../../utils/values'
export default function SearchInCorner({
  fetchSearchOngletCorner,
  coursesSearch,
  fetchCourses,
  isLoading,
  getCourse,
  setInitRefresh,
  checked,
  setChecked,
  saveSearchCornerOnglet,
  searchReducer,
  ongletFilterOpened,
  saveSearchOnglet,
}) {
  const classesAutoComplete = {
    '& .MuiAutocomplete-clearIndicator': {
      color: '#4a4646 !important',
      '& .MuiSvgIcon-fontSizeSmall': {
        width: '20px !important',
        height: '20px !important',
      },
      marginRight: '5px !important',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '10px !important',
    },
  }

  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(false)
  const [change, setChange] = useState(false)
  const [changeCategorie, setChangeCategorie] = useState(false)
  const [search, setSearch] = useState('')
  const [categorieSelected, setCategorieSelected] = useState({
    code: 'LDV',
    label: 'Lettre de voiture',
  })

  useEffect(() => {
    if (searchReducer?.label && searchReducer?.categorie) {
      setSearch(searchReducer?.label)
      setCategorieSelected(searchReducer?.categorie)
      setSelected(true)
    } else {
      setSearch('')
    }
  }, [searchReducer])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 3 && change && !selected) {
        const searchCorner = {
          criteria: search,
          type: categorieSelected.code,
        }
        fetchSearchOngletCorner(searchCorner)
      } else {
        setOptions([])
      }
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search])
  useEffect(() => {
    if (coursesSearch.length > 0) {
      if (coursesSearch.length > 1) {
        coursesSearch.unshift({
          label: `Afficher tous les résultats pour ${search}`,
          course: null,
        })
      }
      setOptions(coursesSearch)
    }
  }, [coursesSearch])
  const onSelect = (value) => {
    setInitRefresh(true)
    if (value === null) {
      getCourse()
      saveSearchCornerOnglet(value)
      saveSearchOnglet(value, ongletFilterOpened - 1)
    } else if (value.course != null) {
      fetchCourses([value.course])
      saveSearchCornerOnglet({
        courses: [value.course],
        label: value.label,
        categorie: categorieSelected,
      })
      saveSearchOnglet(
        {
          courses: [value.course],
          label: value.label,
          categorie: categorieSelected,
        },
        ongletFilterOpened - 1
      )
    } else {
      const courses = options.filter((el) => el.course).map((el) => el.course)
      fetchCourses(courses)
      saveSearchCornerOnglet({
        courses: courses,
        label: search,
        categorie: categorieSelected,
      })
      saveSearchOnglet(
        {
          courses: courses,
          label: search,
          categorie: categorieSelected,
        },
        ongletFilterOpened - 1
      )
    }
    setSelected(true)
    setOptions([])
  }
  const onChange = (value) => {
    let val = value.trim()

    if (value.indexOf('Afficher tous les résultats pour ') != -1)
      val = val.substr(val.indexOf(search), val.length)
    if ((!change && val === '') || changeCategorie) {
      setSearch('')
      setSelected(false)
      saveSearchCornerOnglet(null)
      saveSearchOnglet(null, ongletFilterOpened - 1)
      getCourse()
      setChange(false)
      setChangeCategorie(false)
    } else {
      setSelected(false)
      setSearch(val)
      setChange(true)
    }
  }
  const changeCategorieFilter = (codeCategorie) => {
    setCategorieSelected(codeCategorie)
    if (search) {
      setSearch('')
      setChangeCategorie(true)
      setOptions([])
    }
  }
  const matches = useMediaQuery('(max-width:559px)')

  const handleChange = () => {
    setChecked((prev) => !prev)
  }

  return (
    <>
      {!checked ? (
        <div style={{ margin: '15px 2px' }}>
          <Button
            variant="outlined"
            sx={{
              width: '45px',
              height: '41px',
              minWidth: '45px',
              backgroundColor: '#e2e3e6',
              color: '#373D57',
              border: 'none',
              ':hover': {
                backgroundColor: '#dadce07a',
                border: '1px solid #C2C2C2',
              },
            }}
            onClick={handleChange}
          >
            <SearchIcon style={{ width: '20px', height: '20px' }} />
          </Button>
        </div>
      ) : (
        <Collapse
          in={checked}
          orientation="horizontal"
          sx={{
            '& .MuiCollapse-horizontal': { display: 'flex', position: 'relative' },
          }}
        >
          <div
            style={
              !matches
                ? {
                    display: 'flex',
                    width: '450px',
                    margin: '15px 2px',
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    border: '1px solid #C2C2C2',
                  }
                : {
                    display: 'flex',
                    width: '100%',
                    margin: '15px 2px',

                    borderRadius: '4px',
                    backgroundColor: 'white',
                    border: '1px solid #C2C2C2',
                  }
            }
          >
            <div
              style={
                !matches
                  ? {
                      display: 'inline-flex',
                      alignItems: 'center',
                      width: '159px',
                      borderRight: '1px solid #C2C2C2',
                      backgroundColor: '#dadce07a',
                      fontWeight: '500',
                      borderRadius: '4px 0 0 4px',
                    }
                  : {
                      display: 'inline-flex',
                      alignItems: 'center',
                      width: '64px',
                      borderRight: '1px solid #C2C2C2',
                      backgroundColor: '#dadce07a',
                      fontWeight: '500',
                      borderRadius: '4px 0px 0px 4px',
                    }
              }
            >
              <CategorieRecherche
                changeCategorieFilter={changeCategorieFilter}
                categorieSelected={categorieSelected}
                categorieRecherche={CATEGORIE_RECHERCHE}
                widthPaper={'158px'}
              />
            </div>
            <div
              style={{
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                paddingLeft: '7px',
                borderRadius: '0px 4px 4px 0px',
              }}
            >
              <div style={{ width: '100%', margin: 'auto 0' }}>
                <AutoComplete
                  id={'searchInput'}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  onChange={(event, value) => onSelect(value)}
                  onInputChange={(event, value) => onChange(value)}
                  loading={isLoading}
                  options={options}
                  placeholder={'Rechercher dans corner'}
                  classes={classesAutoComplete}
                  value={search}
                  onBlur={() => setInitRefresh(true)}
                  onFocus={() => setInitRefresh(false)}
                  startIcon={
                    <SearchIcon sx={{ paddingRight: '4px', fontSize: '20px' }} />
                  }
                  forcePopupIcon={false}
                  variant="standard"
                />
              </div>
            </div>
            <div
              style={{
                display: 'inline-flex',
                fontWeight: '500',
                borderLeft: '1px solid #C2C2C2',
                backgroundColor: 'rgb(226 227 230);',
                borderRadius: '0px 4px 4px 0px',
              }}
            >
              <Button
                variant="outlined"
                onClick={handleChange}
                sx={{
                  padding: '10px',
                  minWidth: '0px',
                  color: '#373D57',
                  borderRadius: '0px 4px 4px 0px',
                  backgroundColor: '#e2e3e6',
                  border: 'none',
                  ':hover': {
                    border: 'none',
                    backgroundColor: '#dadce07a',
                  },
                }}
              >
                <ArrowForwardIcon style={{ width: '20px', height: '20px' }} />
              </Button>
            </div>
          </div>
        </Collapse>
      )}
    </>
  )
}
SearchInCorner.propTypes = {
  fetchSearchOngletCorner: PropTypes.func,
  coursesSearch: PropTypes.array,
  isLoading: PropTypes.bool,
  getCourse: PropTypes.func,
  fetchCourses: PropTypes.func,
  setInitRefresh: PropTypes.func,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  saveSearchCornerOnglet: PropTypes.func,
  searchReducer: PropTypes.any,
  ongletFilterOpened: PropTypes.number,
  saveSearchOnglet: PropTypes.func,
}
