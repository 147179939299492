import React from 'react'
import Dialog from '../../../../../../../components/ModalDialog'
import PropTypes from 'prop-types'

export default function DialogForcePointage({
  messageErreur,
  open,
  onClose,
  payloadForcage,
  onForcePointage,
  isLoadingPointageForcePackage,
}) {
  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Forcer pointage package'}
      content={
        <div style={{ paddingRight: '16px', paddingBottom: '16px' }}>
          <span style={{ fontSize: '14px' }}>{messageErreur}</span>
        </div>
      }
      handleClose={onClose}
      handleClickAction={() => onForcePointage(payloadForcage)}
      isLoadingButtonAction={isLoadingPointageForcePackage}
    />
  )
}
DialogForcePointage.propTypes = {
  messageErreur: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onForcePointage: PropTypes.func,
  payloadForcage: PropTypes.object,
  isLoadingPointageForcePackage: PropTypes.bool,
}
