import { connect } from 'react-redux'

import {
  fetchFreights,
  clotureReception,
  fetchHistoriqueFreights,
} from '../../../../redux/documents/actions'

import {
  getFreightsList,
  getIsLoadingFreights,
  getErrorFreights,
  getCountFreights,
  getIsActionsFreights,
  getIsLoadingActionsFreights,
  getHistoriquesFreights,
  getIsLoadingHistoriquesFreights,
} from '../../../../redux/documents/selectors'

import { pageChanged, pageLimitChanged } from '../../../../redux/filter/actions'

import TableFreights from '../../../../pages/main/Entrepot/freights/TableFreights'

const mapStateToProps = (state) => ({
  freightsList: getFreightsList(state),
  isLoadingFreights: getIsLoadingFreights(state),
  errorFreights: getErrorFreights(state),
  countFreights: getCountFreights(state),
  isActionsFreights: getIsActionsFreights(state),
  isLoadingActionsFreights: getIsLoadingActionsFreights(state),
  historiquesFreights: getHistoriquesFreights(state),
  isLoadingHistoriquesFreights: getIsLoadingHistoriquesFreights(state),
})

const mapDisptachToProps = (dispatch) => ({
  clotureReception: (ids) => {
    dispatch(clotureReception(ids))
  },
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(fetchFreights())
  },
  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(fetchFreights())
  },
  fetchFreights: () => {
    dispatch(fetchFreights())
  },
  fetchHistoriqueFreights: (id) => {
    dispatch(fetchHistoriqueFreights(id))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(TableFreights)
