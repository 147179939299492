export const labelsCourseTourTable = [
  {
    id: 'LDV',
    label: 'Lettre de Voiture',
    minWidth: 20,
    name: 'Lettre de Voiture',
    checked: true,
  },
  {
    id: 'estimatedArrivalTime',
    label: 'Heure d’arivée',
    minWidth: 20,
    name: 'Heure d’arivée',
    checked: true,
  },
  {
    id: 'estimatedDepartureTime',
    label: 'Heure de départ',
    minWidth: 20,
    name: 'Heure de départ',
    checked: true,
  },

  {
    id: 'courseType',
    label: 'Type de la Course',
    minWidth: 20,
    name: 'Type de la Course',
    checked: true,
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 20,
    name: 'Type',
    checked: true,
  },
  {
    id: 'creneauDepart',
    label: 'Créneau de départ',
    minWidth: 20,
    name: 'Créneau de départ',
    checked: true,
  },
  {
    id: 'creneauLivraison',
    label: 'Créneau de Livraison',
    minWidth: 20,
    name: 'Créneau de Livraison',
    checked: true,
  },

  {
    id: 'addressArrivee',
    label: 'Addresse d’arivée',
    minWidth: 20,
    name: 'Addresse d’arivée',
    checked: true,
  },

  {
    id: 'volume',
    label: 'Volume',
    minWidth: 20,
    name: 'Volume',
    checked: true,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 20,
    name: 'Poids',
    checked: true,
  },
  {
    id: 'prix',
    label: 'Prix',
    minWidth: 20,
    name: 'Prix',
    checked: true,
  },
  {
    id: 'dropOffRank',
    label: 'DropOff Order',
    minWidth: 20,
    name: 'DropOff Order',
    checked: true,
  },
  {
    id: 'deliveryDate',
    label: 'Jour',
    minWidth: 20,
    name: 'Jour',
    checked: true,
  },
]

export const settingsCourseTourTable = [
  {
    id: 'settings',
    label: 'Settings',
    minWidth: 100,
    name: 'Settings',
    checked: true,
  },
]
