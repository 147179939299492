import Checkbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types'
import React from 'react'
import TableCell from '@mui/material/TableCell'
import TourTextComponent from './TextComponent'
import { StyledTableRow } from './style'

export default function TourTableContent({
  labelId,
  tour,
  columns,
  getChecked,
  isItemSelected,
  handleClick,
  index,
}) {
  const rowContent = {
    LDT: (
      <TourTextComponent
        id={`table_tour_cell_${index}-0`}
        key={0}
        text={tour?.ldt ?? 'N/A'}
        display={getChecked('Lettre de Tournee')}
      />
    ),
    chauffeur: (
      <TourTextComponent
        id={`table_tour_cell_${index}-1`}
        key={1}
        text={tour?.driver?.name ?? 'N/A'}
        display={getChecked('Chauffeur')}
      />
    ),
    chauffeurId: (
      <TourTextComponent
        id={`table_tour_cell_${index}-2`}
        key={2}
        text={tour?.driver?.id ?? 'N/A'}
        display={getChecked('Chauffeur ID')}
      />
    ),
    montantPrestataireHT: (
      <TourTextComponent
        id={`table_tour_cell_${index}-3`}
        key={3}
        text={tour?.tourPrice ? tour?.tourPrice : '0'}
        display={getChecked('Prix')}
      />
    ),
    volume: (
      <TourTextComponent
        id={`table_tour_cell_${index}-4`}
        key={4}
        text={tour?.tourVolume ?? '0'}
        display={getChecked('Volume')}
      />
    ),
    weight: (
      <TourTextComponent
        id={`table_tour_cell_${index}-5`}
        key={5}
        text={tour?.tourWeight ?? '0'}
        display={getChecked('Poids')}
      />
    ),
    distance: (
      <TourTextComponent
        id={`table_tour_cell_${index}-6`}
        key={6}
        text={tour.distance}
        display={getChecked('Distance')}
      />
    ),
    tourHours: (
      <TourTextComponent
        id={`table_tour_cell_${index}-7`}
        key={7}
        text={tour?.tourCrenaux ?? 'N/A'}
        display={getChecked('Horaire de Tournée')}
      />
    ),
    tourTime: (
      <TourTextComponent
        id={`table_tour_cell_${index}-8`}
        key={8}
        text={tour?.tourDuration ?? 'N/A'}
        display={getChecked('Durée de la tournée')}
      />
    ),
    tourTravel: (
      <TourTextComponent
        id={`table_tour_cell_${index}-8`}
        key={8}
        text={tour?.tourTravel ?? 'N/A'}
        display={getChecked('Temps de Trajet')}
      />
    ),
    courseNB: (
      <TourTextComponent
        id={`table_tour_cell_${index}-9`}
        key={9}
        text={tour.visits.length > 2 ? (tour.visits.length - 2) / 2 : '0'}
        display={getChecked('Nb des courses')}
      />
    ),
  }
  return (
    <StyledTableRow
      id={`tour_content_row_${index}`}
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell align="left">
        <Checkbox
          id={`tour_row_check_box_${index}`}
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          onClick={(event) => handleClick(event, tour.ldt)}
          disabled={!tour?.ldt}
        />
      </TableCell>
      {/*start here */}
      {columns.map((el) => {
        return rowContent[el.id]
      })}
    </StyledTableRow>
  )
}

TourTableContent.propTypes = {
  labelId: PropTypes.string,
  tour: PropTypes.any,
  columns: PropTypes.array,
  getChecked: PropTypes.func,
  addSelectedTours: PropTypes.any,
  removeSelectedTours: PropTypes.any,
  selectedTours: PropTypes.array,
  isItemSelected: PropTypes.any,
  handleClick: PropTypes.func,
  handleSelectAllClick: PropTypes.func,
  index: PropTypes.number,
}
