import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import Dialog from '../../../../../../../components/ModalDialog'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IonListCircleSharp from '../../../../../../../assets/icons/detailsLivraison/BiListUl'
import CircularProgress from '@mui/material/CircularProgress'

export default function DialogProduis({
  open,
  onClose,
  idPackage,
  getDetailsProduits,
  produitsPackage,
  isLoadingProduitsPackage,
}) {
  useEffect(() => {
    if (idPackage) {
      getDetailsProduits(idPackage)
    }
  }, [idPackage])
  return (
    <Dialog
      maxWidthDialog={'lg'}
      open={open}
      title={'Produit(s)'}
      iconTitle={
        <IonListCircleSharp
          style={{ marginRight: '10px', marginTop: '3px ', fontSize: '1.5rem' }}
        />
      }
      content={
        <>
          {isLoadingProduitsPackage ? (
            <div
              style={{
                margin: '20px',
                display: 'flex',
                justifyContent: 'center',
                width: '300px',
              }}
            >
              <CircularProgress
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              />
            </div>
          ) : (
            <TableContainer sx={{ paddingBottom: '16px', paddingRight: '16px' }}>
              <Table
                sx={{
                  minWidth: 650,
                }}
                size="small"
                aria-label="a dense table"
              >
                {produitsPackage.length > 0 ? (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{ fontWeight: '700' }}>
                          {'Label'}
                        </TableCell>
                        <TableCell align="left" style={{ fontWeight: '700' }}>
                          {' '}
                          {'Poids (kg) '}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {produitsPackage.map((product, i) => (
                        <TableRow key={i}>
                          <TableCell align="left">
                            {product?.label
                              ? product.label
                              : product?.code
                              ? product.code
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="left">
                            {product?.weight != null ? product.weight : 'N/A'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                ) : (
                  <div
                    style={{
                      padding: '60px 100px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {'Pas de Produits'}
                  </div>
                )}
              </Table>
            </TableContainer>
          )}
        </>
      }
      handleClose={onClose}
      isClosedIcon={true}
    />
  )
}
DialogProduis.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  idPackage: PropTypes.string,
  getDetailsProduits: PropTypes.func,
  isLoadingProduitsPackage: PropTypes.bool,
  produitsPackage: PropTypes.array,
}
