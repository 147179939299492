import { MapOptions } from '../../models/mapOptions'
import { GOOGLE_MAP_CONSTANTS } from '../../constants/googleMapConstants'

export const clientInitialState = {
  isSubmitted: 0,
  errors: {},
}

export default {
  clients: [],
  isLoading: false,

  error: {},
  uploadFiles: {
    errors: [],
    response: [],
  },
  calculatedPrice: 0,
  searchByPhone: false,
  codePromoVerification: null,
  showPopUp: false,
  showPopUpDevis: false,
  createdNewCourse: [],
  createdNewAssembly: null,
  assemblyAttachements: [],
  codeAssembly: [],
  paymentForm: false,
  command: false,
  oneWeekShipments: null,
  errorMessage: [],
  message: '',
  showMessage: false,
  displayWithoutDaySelection: false,
  devis: false,
  isLoadingFiles: false,

  orders: [],
  oldClient: {
    ...clientInitialState,
  },
  oldOrders: [],
  markers: [],
  directions: [],
  totalPrice: 0,
  activeStep: 0,
  steps: [
    'Informations de la commande',
    'Informations du client',
    'Informations de paiement',
  ],
  mapOptions: new MapOptions(
    GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lat,
    GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lng,
    GOOGLE_MAP_CONSTANTS.ZOOM
  ),
  isNewClient: false,
  selectedClient: '',
  client: {
    ...clientInitialState,
  },
  isOrderLoading: true,
  ldvCreation: '',

  canalVente: null,
  isGetCanalVenteLoading: false,
  errorGetSalesChannel: null,

  rulesList: [],
  isGetRulesListLoading: false,
}
