import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../../../../../../../components/ModalDialog'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'

import DatePickerLocalisation from '../../../../../../../components/DatePickerLocalisation'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

const StyledDateHeure = styled('div')({
  padding: '10px 0px',
  width: '40%',
  '@media (max-width: 440px)': {
    width: '100% !important',
  },
})
const StyledcontainerContent = styled('div')({
  padding: '15px 40px 15px 24px',
  width: '600px',
  '@media (max-width: 680px)': {
    width: 'unset',
  },
})

function ClotureDialog({
  open,
  handleClose,
  clotureObject,
  onClotureCourse,
  listesMotif,
  isLoadingMotifListes,
  isLoadingActionOrder,
  style,
}) {
  const { icon, titre } = clotureObject
  const [reason, setReason] = useState('null')
  const [date, setDate] = useState(new Date())
  const [commentaire, setCommentaire] = useState('')
  const [time, setTime] = useState(moment(new Date()).format('HH:mm'))
  const onChangeStatusAction = () => {
    const payloadChangeStatus = {
      reasons: reason,
      comment: commentaire,
      datetime: moment(moment(date).format('YYYY-MM-DD ') + time).format(),
    }

    onClotureCourse(payloadChangeStatus)
  }
  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={titre}
      iconTitle={icon}
      style={style}
      content={
        <StyledcontainerContent>
          <div style={{ padding: '10px 0px' }}>
            <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
              Listes des motifs*
            </div>
            <div style={{ display: 'flex' }}>
              <TextField
                id="Listes-motifs-Cloture"
                select
                name="Listes-motifs"
                variant="outlined"
                onChange={(e) => setReason(e.target.value)}
                value={reason}
                SelectProps={{
                  MenuProps: {
                    style: { height: 288 },
                  },
                }}
                disabled={isLoadingMotifListes || isLoadingActionOrder}
                sx={isLoadingMotifListes && { paddingRight: '20px' }}
              >
                <MenuItem value={'null'} key={0} disabled={true}>
                  {'Listes des motifs'}
                </MenuItem>
                <Divider />
                {listesMotif.map((h, index) => (
                  <MenuItem value={h.code} key={index + 1} id={`Motif-${index}`}>
                    {h?.code ? `${h.code}-${h.motif}` : h.motif}
                  </MenuItem>
                ))}
              </TextField>
              {isLoadingMotifListes && (
                <div
                  style={{
                    display: 'flex',
                    alignSelf: 'center',
                  }}
                >
                  <CircularProgress
                    sx={{
                      width: '30px !important',
                      height: '30px !important',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              boxSizing: 'border-box',
            }}
          >
            <StyledDateHeure>
              <div
                style={{
                  paddingBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Date
              </div>
              <div style={{ width: '100%' }}>
                <DatePickerLocalisation
                  disabledDays={[]}
                  openedDays={[]}
                  disablePast={false}
                  handleDateChange={(e) => setDate(e)}
                  withDefaultValue={false}
                  date={date}
                  id="Date-Cloture"
                  disabled={isLoadingActionOrder}
                />
              </div>
            </StyledDateHeure>
            <StyledDateHeure>
              <div
                style={{
                  paddingBottom: '10px',
                  fontWeight: 'bold',
                }}
              >
                Heure
              </div>
              <div style={{ width: '100%' }}>
                <TextField
                  id="Time-Cloture"
                  type="time"
                  variant="outlined"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  disabled={isLoadingActionOrder}
                />
              </div>
            </StyledDateHeure>
          </div>
          <div style={{ padding: '10px 0px' }}>
            <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
              Commentaire
            </div>
            <div>
              <TextField
                id="Commentaire-Cloture"
                name="commentaire"
                placeholder="Commentaire"
                variant="outlined"
                multiline
                rows={4}
                onChange={(e) => setCommentaire(e.target.value)}
                value={commentaire}
                disabled={isLoadingActionOrder}
              />
            </div>
          </div>
        </StyledcontainerContent>
      }
      handleClose={handleClose}
      handleClickAction={onChangeStatusAction}
      disabled={reason == 'null'}
      isLoadingButtonAction={isLoadingActionOrder}
    />
  )
}
ClotureDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  clotureObject: PropTypes.object,
  onClotureCourse: PropTypes.func,
  listesMotif: PropTypes.array,
  isLoadingMotifListes: PropTypes.bool,
  isLoadingActionOrder: PropTypes.bool,
  style: PropTypes.object,
}

export default ClotureDialog
