import { applyMiddleware, combineReducers, createStore } from 'redux'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// reducers imports
import userReducer from './user/reducer'
import shipmentReducer from './shipment/reducer'
import driverReducer from './driver/reducer'
import googleMapDirectionReducer from './googleMapDirections/reducer'
import geoCachingRecuder from './geoCachingAddress/reducer'
import operationalHoursReducer from './operationalHours/reducer'
import OrderReducer from './order/reducer'
import scheduleOrderReducer from './scheduleOrder/reducer'
import simulationTourneeReducer from './simulateTournee/reducer'
import conversationReducer from './discussion/conversations/reducer'
import messageListReducer from './discussion/messages/reducer'
import filterReducer from './filter/reducer'
import documentsEntrepot from './documents/reducer'
import OrderPackage from './orderPackage/reducer'
import configurationReducer from './configuration/reducer'
import participantsReducer from './participants/reducer'
import sessionsReducer from './sessions/reducer'
import formationsReducer from './formations/reducer'
import vehiculesReducer from './vehicules/reducer'
import tourneesReducer from './tournees/reducer'
import disponibilitesReducer from './disponibilites/reducer'

// middlewares import
import userMiddleware from './user/middleware'
import shipmentMiddleware from './shipment/middleware'
import driverMiddleware from './driver/middleware'
import httpMiddleware from './http/middleware'
import googleMapDirectionMiddleware from './googleMapDirections/middleware'
import geoCachingMiddleware from './geoCachingAddress/middleware'
import operationalHoursMiddleware from './operationalHours/middleware'
import OrderMiddleware from './order/middleware'
import estimatePriceMiddleware from './estimatePrice/middleware'
import scheduleOrderMiddleware from './scheduleOrder/middleware'
import simulationTourneeMiddleware from './simulateTournee/middleware'
import conversationsMiddleware from './discussion/conversations/middleware'
import messageListMiddleware from './discussion/messages/middleware'
import socketSiscussionMiddleware from './discussion/socket/middleware'
import documentsEntrepotMiddleware from './documents/middleware'
import filterMiddleware from './filter/middleware'
import orderPackageMiddleware from './orderPackage/middleware'
import configurationMiddleware from './configuration/middleware'
import participantsMiddleware from './participants/middleware'
import sessionsMiddleware from './sessions/middleware'
import formationsMiddleware from './formations/middleware'
import vehiculesMiddleware from './vehicules/middleware'
import tourneesMiddleware from './tournees/middleware'
import disponibilitesMiddleware from './disponibilites/middleware'

const persistConfig = {
  key: 'root',
  blacklist: [
    'user',
    'driver',
    'googleMapDirection',
    'geoCaching',
    'operationalHours',
    'order',
    'scheduleOrder',
    'simulateTournee',
    'conversations',
    'messageList',
    'documentsEntrepot',
    'shipment',
    'orderPackage',
    'configuration',
    'participants',
    'sessions',
    'formations',
    'vehicules',
    'tournees',
    'disponibilites',
  ],

  storage,
}

const reducers = combineReducers({
  user: userReducer,
  shipment: shipmentReducer,
  driver: driverReducer,
  googleMapDirection: googleMapDirectionReducer,
  geoCaching: geoCachingRecuder,
  operationalHours: operationalHoursReducer,
  order: OrderReducer,
  scheduleOrder: scheduleOrderReducer,
  simulateTournee: simulationTourneeReducer,
  conversations: conversationReducer,
  messageList: messageListReducer,
  filters: filterReducer,
  documentsEntrepot: documentsEntrepot,
  orderPackage: OrderPackage,
  configuration: configurationReducer,
  participants: participantsReducer,
  sessions: sessionsReducer,
  formations: formationsReducer,
  vehicules: vehiculesReducer,
  tournees: tourneesReducer,
  disponibilites: disponibilitesReducer,
})

const middlewares = applyMiddleware(
  httpMiddleware,
  userMiddleware,
  shipmentMiddleware,
  googleMapDirectionMiddleware,
  driverMiddleware,
  googleMapDirectionMiddleware,
  geoCachingMiddleware,
  operationalHoursMiddleware,
  OrderMiddleware,
  estimatePriceMiddleware,
  scheduleOrderMiddleware,
  simulationTourneeMiddleware,
  conversationsMiddleware,
  messageListMiddleware,
  socketSiscussionMiddleware,
  documentsEntrepotMiddleware,
  filterMiddleware,
  orderPackageMiddleware,
  configurationMiddleware,
  participantsMiddleware,
  sessionsMiddleware,
  formationsMiddleware,
  vehiculesMiddleware,
  tourneesMiddleware,
  disponibilitesMiddleware
)
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = createStore(persistedReducer, middlewares)
export const persistor = persistStore(store)
