import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import Dialog from '../../../../../../../../components/ModalDialog'

import IconLitige from '../../../../../../../../assets/icons/detailsLivraison/litigeBlanc.svg'
import IconButton from '@mui/material/IconButton'

import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import IconLitigeImageAgrandi from '../../../../../../../../assets/icons/detailsLivraison/agrandir.svg'
import Galery from '../../../../../../../../components/galery'
import { Box } from '@mui/material'

export default function DialogLitige({
  open,
  onClose,
  litigeHistoriquePackage,
  isLoadingLitigeHistoriquePackage,
  barCodeLitige,
  getLitigeHistoriquePackage,
  idLitige,
}) {
  const [lightbox, setLightbox] = useState([])
  const [lightboxIsopen, setLightboxIsopen] = useState(false)
  const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState(0)

  useEffect(() => {
    if (idLitige) {
      getLitigeHistoriquePackage(idLitige)
    }
  }, [idLitige])
  useEffect(() => {
    if (litigeHistoriquePackage?.pictures?.length > 0) {
      setLightbox(litigeHistoriquePackage?.pictures)
    }
  }, [litigeHistoriquePackage])

  const onCloseGalery = () => {
    setLightboxIsopen(false)
  }

  return (
    <>
      {lightboxIsopen ? (
        <Galery
          lightboxIsopen={lightboxIsopen}
          lightbox={lightbox}
          onCloseGalery={onCloseGalery}
          lightboxPhotoIndex={lightboxPhotoIndex}
          setLightboxPhotoIndex={setLightboxPhotoIndex}
        />
      ) : (
        <Dialog
          maxWidthDialog={'xs'}
          open={open}
          title={'Litige'}
          iconTitle={
            <img
              src={IconLitige}
              style={{
                marginRight: '10px',
              }}
            />
          }
          content={
            <>
              {isLoadingLitigeHistoriquePackage ? (
                <div
                  style={{
                    width: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <Grid
                  container
                  style={{ paddingRight: '16px', paddingBottom: '16px' }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      padding: '15px 20px 10px 0px',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span style={{ fontWeight: 'bold', paddingRight: '5px' }}>
                      Barcode :
                    </span>
                    <span style={{ overflowWrap: 'anywhere' }} id="Barcode">
                      {barCodeLitige ? barCodeLitige : 'N/A'}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      padding: '15px 20px 10px 0px',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span style={{ fontWeight: 'bold', paddingRight: '5px' }}>
                      Étape :
                    </span>
                    <span style={{ overflowWrap: 'anywhere' }} id="Etape">
                      {litigeHistoriquePackage?.reason?.label
                        ? litigeHistoriquePackage.reason.label
                        : 'N/A'}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      padding: '15px 20px 10px 0px',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span style={{ fontWeight: 'bold', paddingRight: '5px' }}>
                      Reponsabilité :
                    </span>
                    <span style={{ overflowWrap: 'anywhere' }} id="Reponsabilite">
                      {litigeHistoriquePackage?.type?.label
                        ? litigeHistoriquePackage.type.label
                        : 'N/A'}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      padding: '15px 20px 10px 0px',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span style={{ fontWeight: 'bold', paddingRight: '5px' }}>
                      Observation :
                    </span>
                    <span style={{ overflowWrap: 'anywhere' }} id="Observation">
                      {litigeHistoriquePackage?.comment
                        ? litigeHistoriquePackage?.comment
                        : 'N/A'}
                    </span>
                  </Grid>
                  <div style={{ paddingTop: '15px' }}>
                    <div style={{ paddingBottom: '20px', fontWeight: 'bold' }}>
                      Photos litiges
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        boxSizing: 'border-box',
                      }}
                      id="Photos-litiges"
                    >
                      {litigeHistoriquePackage?.pictures.map((image, index) => (
                        <div
                          key={index}
                          style={{
                            width: '115px',
                            background: '#FAFAFA',
                            borderRadius: '10px',
                            display: 'grid',
                            placeItems: 'center',
                            marginRight: '15px',
                            position: 'relative',
                          }}
                          id={`Photos-litiges-${index}`}
                        >
                          <img
                            src={image.url}
                            style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '10px',
                            }}
                          />
                          <Box
                            sx={{
                              width: '100%',
                              cursor: 'pointer',
                              height: '100%',
                              position: 'absolute',
                              display: 'grid',
                              placeItems: 'end',
                              opacity: '0',
                              padding: '4px',
                              '&:hover': {
                                opacity: '1',
                                backgroundColor:
                                  'hsla(0,0%,97.6%,.4117647058823529)',
                                transition: '0.5s',
                              },
                            }}
                          >
                            <IconButton
                              aria-label="close"
                              style={{
                                width: '37px',
                                cursor: 'pointer',
                                height: '37px',
                                opacity: '0.7',
                                background: '#2B404A 0% 0% no-repeat padding-box',
                                color: 'white',
                              }}
                              onClick={(event) => {
                                setLightboxPhotoIndex(index)
                                setLightboxIsopen(true)
                                event.stopPropagation()
                              }}
                              id={`galery-${index}`}
                            >
                              <img src={IconLitigeImageAgrandi} />
                            </IconButton>
                          </Box>
                        </div>
                      ))}
                    </div>
                  </div>
                </Grid>
              )}
            </>
          }
          handleClose={onClose}
          isClosedIcon={true}
        />
      )}
    </>
  )
}
DialogLitige.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  litigeHistoriquePackage: PropTypes.object,
  isLoadingLitigeHistoriquePackage: PropTypes.bool,
  barCodeLitige: PropTypes.string,
  getLitigeHistoriquePackage: PropTypes.func,
  idLitige: PropTypes.string,
}
