export const COLLABORATOR_EXPIRATION_DATE = 'expiration_date'

export const DRIVERS_FILTER_EXPIRATION_DATE = 'drivers_filter_expiration_date'
export const SALES_CHANNEL_EXPIRATION_DATE = 'sales_channel_expiration_date'
export const SERVICE_EXPIRATION_DATE = 'service_expiration_date'
export const DEPARTEMENT_EXPIRATION_DATE = 'departement_expiration_date'
export const MACRO_CANAL_EXPIRATION_DATE = 'macro_canal_expiration_date'
export const WAREHOUSE_EXPIRATION_DATE = 'wareHouse_expiration_date'
export const SUBJECT_EXPIRATION_DATE = 'subject_expiration_date'

const checkExpirationDate = (key) => {
  try {
    const date = JSON.parse(localStorage.getItem(key))
    if (!date) throw new Error('Missing expiration date')

    if (date < Date.now()) {
      if (key === COLLABORATOR_EXPIRATION_DATE) localStorage.clear()
      else localStorage.removeItem(key)
    }
  } catch (e) {
    localStorage.removeItem(key)
    if (key === COLLABORATOR_EXPIRATION_DATE) localStorage.clear()
    else localStorage.removeItem(key)
  }
}

export default checkExpirationDate
