export const getOrderPackageState = ({ orderPackage }) => orderPackage

export const getLoadingPackageOrder = (state) =>
  getOrderPackageState(state).isLoadingPackageOrder
export const getActionPointagePackageDone = (state) =>
  getOrderPackageState(state).isActionPointagePackageDone
export const getActionPointagePackageErorr = (state) =>
  getOrderPackageState(state).isActionPointagePackageErorr
export const getPackagOrder = (state) => getOrderPackageState(state).orderPackage
export const getPackagWarehouse = (state) =>
  getOrderPackageState(state).warehousePackage
export const getPackagReceptionDate = (state) =>
  getOrderPackageState(state).receptionDatePackage
export const getErrorPackage = (state) => getOrderPackageState(state).errorPackage
export const getLoadingPackageStatus = (state) =>
  getOrderPackageState(state).isLoadingPackageStatus
export const getPackagesStatus = (state) =>
  getOrderPackageState(state).packagesStatus
export const getLoadingPointagePackage = (state) =>
  getOrderPackageState(state).isLoadingPointagePackage
export const getOrderPackageLitigeUploadImage = (state) =>
  getOrderPackageState(state).orderPackageLitigeUploadImage
export const getLoadingPointageForcePackage = (state) =>
  getOrderPackageState(state).isLoadingPointageForcePackage
export const getActionPointageForcePackageDone = (state) =>
  getOrderPackageState(state).isActionPointageForcePackageDone
export const getIsLoadingUploadImageLitige = (state) =>
  getOrderPackageState(state).isLoadingUploadImageLitige
export const getErrorrImageLitigeUpload = (state) =>
  getOrderPackageState(state).errorImageLitigeUpload
export const getIsLoadingPackageLitigeMotif = (state) =>
  getOrderPackageState(state).isLoadingPackageLitigeMotif
export const getPackagesLitigeMotif = (state) =>
  getOrderPackageState(state).packagesLitigeMotif
export const getIsLoadingPackageLitigeResponsabilite = (state) =>
  getOrderPackageState(state).isLoadingPackageLitigeResponsabilite
export const getPackagesLitigeResponsabilite = (state) =>
  getOrderPackageState(state).packagesLitigeResponsabilite
export const getLoadingLitigeHistorique = (state) =>
  getOrderPackageState(state).isLoadingLitigeHistoriquePackage
export const getLitigeHistorique = (state) =>
  getOrderPackageState(state).litigeHistoriquePackage
export const getProduits = (state) => getOrderPackageState(state).produitsPackage
export const getLoadingProduits = (state) =>
  getOrderPackageState(state).isLoadingProduitsPackage
export const getHistoriquesPackage = (state) =>
  getOrderPackageState(state).historiquesPackage
export const getLoadingHistoriquesPackage = (state) =>
  getOrderPackageState(state).isLoadingHistoriquesPackage
export const getPhotosHistorique = (state) =>
  getOrderPackageState(state).photosHistoriquePackage
export const getLoadingPhotosHistorique = (state) =>
  getOrderPackageState(state).isLoadingPhotosHistoriquePackage

export const getLoadingEtatPackage = (state) =>
  getOrderPackageState(state).isLoadingEtatPackage
export const getVisibiliteEtatPackage = (state) =>
  getOrderPackageState(state).isVisibiliteEtatPackage
export const getVisibiliteEtatPackageDone = (state) =>
  getOrderPackageState(state).isVisibiliteEtatPackageDone
export const getIsAnnulationLivraisonPartielleDone = (state) =>
  getOrderPackageState(state).isAnnulationLivraisonPartielleDone
export const getIsLoadingLivraisonPartielle = (state) =>
  getOrderPackageState(state).isLoadingLivraisonPartielle

export const getConfigMDM = (state) => getOrderPackageState(state).canalconfig
export const getIsLoadingUploadImageMDM = (state) =>
  getOrderPackageState(state).isLoadingUploadImageMDM
export const getOrderPackageUploadMDM = (state) =>
  getOrderPackageState(state).orderPackageMDMUploadImage

export const getOrderPackageCourseSource = (state) =>
  getOrderPackageState(state).courseSource
export const getIsLoadingEditPackage = (state) =>
  getOrderPackageState(state).isLoadingEditPackage

export const getIsActionEditPackageDone = (state) =>
  getOrderPackageState(state).isActionEditPackageDone
