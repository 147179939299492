import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import Tooltip from '@mui/material/Tooltip/Tooltip'
export default function BoldTextComponent({ value }) {
  return (
    <span>
      <Tooltip arrow title={<span className="tootltip-content">{value}</span>}>
        <p className="bold-text">{value}</p>
      </Tooltip>
    </span>
  )
}
BoldTextComponent.propTypes = {
  value: PropTypes.string,
}
