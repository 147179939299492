import initialState from './initialState'
import {
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_GOOGLE_ACCOUNT,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGIN_MICROSOFT_ACCOUNT,
} from './actions'

export default (state = initialState(), action) => {
  if (action.type === USER_LOGIN) {
    return {
      ...state,
      collaborateur: {},
      token: null,
      isLoading: true,
    }
  }
  if (action.type === USER_LOGIN_SUCCESS) {
    const { collaborateur, value } = action.payload.data.Response

    return {
      ...state,
      collaborateur,
      token: value,
      isLoading: false,
      isLoggedIn: true,
      loginError: null,
      userRole: collaborateur?.collabProfile || null,
    }
  }
  if (action.type === USER_LOGIN_FAILURE) {
    const data = action.payload.data.data
    return {
      ...state,
      collaborateur: {},
      token: null,
      isLoading: false,
      loginError: data?.message,
    }
  }
  if (action.type === USER_LOGOUT) {
    return {
      ...state,
      collaborateur: {},
      token: null,
      isLoading: false,
      isLoggedIn: false,
    }
  }
  if (
    action.type === USER_LOGIN_GOOGLE_ACCOUNT ||
    action.type === USER_LOGIN_MICROSOFT_ACCOUNT
  ) {
    return {
      ...state,
      isLoading: true,
      isLoggedIn: false,
    }
  }

  return state
}
