import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { COURSE_TYPE } from '../../../../../../utils/values'
import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import IconStatus from '../../../../../../assets/icons/navFilter/StatusIcon'
export default function CourseTypeFilter({
  disabled,
  filterStateChanged,
  courseTypeFiltersValues,
}) {
  const [checked, setChecked] = useState([])

  useEffect(() => {
    let list = Object.entries(COURSE_TYPE).map(([code, label]) => {
      return {
        code: code,
        name: label,
      }
    })
    setChecked(list)
  }, [])
  const onCourseTypeChanged = (value) => {
    filterStateChanged('courseType', value ? value : [])
  }

  return (
    <>
      <SelectListWithSearch
        disabled={disabled}
        defaultLabel={'Type de Course'}
        dataList={checked}
        onDataChanged={onCourseTypeChanged}
        filtersValues={courseTypeFiltersValues}
        Icon={IconStatus}
        id={'Course-type'}
      />
    </>
  )
}
CourseTypeFilter.propTypes = {
  disabled: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  courseTypeFiltersValues: PropTypes.array,
  echangeFiltersValues: PropTypes.array,
}
