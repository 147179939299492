import React from 'react'
import PropTypes from 'prop-types'
import DriveEtaIcon from '@mui/icons-material/DriveEta'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import { CardSyled, TitleWithIcon } from '../card/style'
import { getfullName } from '../../../../../../utils/values'

export default function ChauffeurLivraison({ order, isLoading }) {
  return (
    <>
      {/*
          Livreur : 
          chauffeur :order.chauffeur
          tournee : order?.tour
          Pick up rank : order.pickUpRank
          drop off rank : order.dropOffRank

      */}
      <CardSyled>
        <TitleWithIcon>
          <DriveEtaIcon
            style={{ marginRight: '4px', width: '20px', height: '20px' }}
          />
          Livreur
        </TitleWithIcon>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '20px',
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Grid container>
            <Grid container>
              <Grid item xs={6} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Chauffeur'}
                </span>
                <span>{getfullName(order?.chauffeur, '-')}</span>
              </Grid>
              <Grid item xs={6} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Tournée'}
                </span>
                <span>{order?.tour ? order.tour : '-'}</span>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Pick up rank'}
                </span>
                <span>{order?.pickUpRank ? order.pickUpRank : '-'}</span>
              </Grid>
              <Grid item xs={6} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Drop off rank'}
                </span>
                <span>{order?.dropOffRank ? order.dropOffRank : '-'}</span>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardSyled>
    </>
  )
}
ChauffeurLivraison.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
}
