import { connect } from 'react-redux'
import ActionCourse from '../../../pages/main/Orders/ActionCourse'
import {
  getOrderReasons,
  onChangeStatusEnMasse,
  removeErrorAction,
  setIsActionShipmentDone,
} from '../../../redux/order/actions'

import {
  getCoursesChecked,
  getErrorActionChangeStatusEnMasse,
  getisActionOrderDone,
  getIsErrorActionWithSuccess,
  getIsLoadingActionOrder,
  getIsLoadingReasonsOrder,
  getReasonsOrder,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  coursesChecked: getCoursesChecked(state),

  isLoadingMotifListes: getIsLoadingReasonsOrder(state),
  listesMotif: getReasonsOrder(state),
  isLoadingActionOrder: getIsLoadingActionOrder(state),

  isActionDone: getisActionOrderDone(state),
  errorChangeStatusEnMasse: getErrorActionChangeStatusEnMasse(state),
  isErrorActionWithSuccess: getIsErrorActionWithSuccess(state),
})
const mapDisptachToProps = (dispatch) => ({
  onClotureEnMasse: (payload) => dispatch(onChangeStatusEnMasse(payload)),

  getMotifsCloture: (payload) => {
    dispatch(getOrderReasons(payload))
  },
  removeErrorAction: () => dispatch(removeErrorAction()),
  setIsActionShipmentDone: (actionDone) =>
    dispatch(setIsActionShipmentDone(actionDone)),
})

export default connect(mapStateToProps, mapDisptachToProps)(ActionCourse)
