import _ from 'lodash'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Accordion from '@mui/material/Accordion'
import FormLabel from '@mui/material/FormLabel'
import Checkbox from '@mui/material/Checkbox'

import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import AddressAutoComplete from '../../../../containers/main/AddressAutoComplete'
import DatePickerLocalisation from '../../../../components/DatePickerLocalisation'
import FormHelperTextError from '../../../../components/FormHelperTextError'
import { LatLng } from '../../../../models/latlng'
import { Place } from '../../../../models/place'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'

import { LEVEL_NUMBERS, ORDER_TYPE } from '../../../../constants/appContants'
import { getDaysDisabledDays } from '../../../../utils/getDisabledDays'
import useForm from '../../../../utils/useForm'
import { shipmentFormData } from './formData'
import { shipmentValidators } from './validators'
import { validateShipment } from '../../../../utils/validator/shipmentValidator'
import PhoneInput from 'react-phone-input-2'
import OptionsList from '../../../../components/ShipmentOptionsList'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { DropzoneArea } from 'react-mui-dropzone'
import { parseFloatWithComma } from '../../../../utils/floatFormatting'

import { UploadIcons } from '../Assembly/index'
import uuid from 'react-uuid'
import moment from 'moment'

import './style.scss'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InvoiceInput from '../../../../components/InvoiceInput'
import NoteInterne from '../../../../components/NoteInterne'
import { getPhoneInputCountries } from '../../../../utils/values'
import Prestation from '../../../../components/Prestation'
// import { resizeFile } from '../../../../utils/imageReduire'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { COURSE_TYPE, ClientDelivery, ClientPickup } from '../../../../utils/values'
import CircularProgress from '@mui/material/CircularProgress'
import Error from '../../../../components/Modal/errorAct'
import Switch from '../../../../components/switch/SwitchComponent'
import AutoComplete from '../../../../components/AutoComplete'

const ShipmentSection = ({
  order,
  updateOrderShipment,
  setShipmentErrors,
  directionRoute,
  getGoogleMapDirection,
  isGetDirectionRouteLoading,
  setOrderShipmentPrice,
  //addAssembly,
  shipmentSalesChannelList,
  updateDirections,
  isSubmited,
  getRecommendedCreneau,
  operationalHours,
  estimatePrice,
  estimatedPrice,
  estimatedPriceError,
  recommendedCreneauList,
  getOneWeekShipments,
  removeAssembly,
  handleSalesChannel,
  removeOrder,
  salesChannel,
  familiesArticleList,
  addOrder,
  getRulesList,
  resetDirections,
  getSalesChannels,
  getCanalVente,
  canalVente,
  isGetCanalVenteLoading,
  errorGetSalesChannel,
}) => {
  useEffect(() => {
    getSalesChannels()
  }, [])
  useEffect(() => {
    if (canalVente != null)
      handleShipmentElChange('selectedSalesChannel', canalVente)
  }, [canalVente])
  const shipmentData = !_.isNil(_.get(order, 'shipment.id'))
    ? _.get(order, 'shipment')
    : { id: uuid() }
  const [originDefaultAddress, setOriginDefaultAddress] = useState(null)
  const [destinationDefaultAddress, setdestinationDefaultAddress] = useState(null)
  const [ancienCourseType, setAncienCourseType] = useState(null)

  const [options, setOptions] = useState([])
  const [isVehiculeType, setIsVehiculeType] = useState(false)
  const [isManutentionOptions, setIsManutentionOptions] = useState(false)
  const [isMissingTarifsConfig, setIsMissingTarifsConfig] = useState(false)
  const [isManutentionIncluse, setIsManutentionIncluse] = useState(false)
  const [prestation, setPrestation] = useState([])

  const submitForm = () => {
    // console.log('submitted data :', shipmentFormData(shipmentData))
  }
  // console.log('FakherDirectionRouteList===>', directionRouteList)
  // console.log('FakherDirectionRoute===>', directionRoute)
  const [
    shipment,
    errors,
    isSubmitted,
    handleShipmentChange,
    handleShipmentElChange,
    handleShipmentSubmit,
    isShipmentValid,
    isShipmentInValid, //Empty
    ,
    handleShipmentSwitcherChange,
  ] = useForm(
    shipmentFormData(shipmentData),
    submitForm,
    shipmentValidators,
    validateShipment
  )
  const submitRef = useRef()
  const [disabledDays, setDisabledDays] = useState([])
  const [isDisabledOperationalHours, setIsDisabledOperationalHours] = useState(false)

  const [errorAccordion, setErrorAccordion] = useState(false)
  const [openAccordion, setOpenAccordion] = useState(false)

  useEffect(() => {
    updateOrderShipment(order.id, shipment)
    getRulesList()
  }, [])

  useEffect(() => {
    const changeshipmentTimeOut = setTimeout(() => {
      updateOrderShipment(order.id, shipment)
    }, 100)
    return () => clearTimeout(changeshipmentTimeOut)
  }, [shipment])

  useEffect(() => {
    const changesErrorsTimeOut = setTimeout(() => {
      setShipmentErrors(order.id, errors)
      if (
        !_.isNil(_.get(errors, 'destinationPhone.required')) ||
        !_.isNil(_.get(errors, 'destinationMail'))
      ) {
        setErrorAccordion(true)
        setOpenAccordion(true)
      } else {
        setErrorAccordion(false)
      }
    }, 100)
    return () => clearTimeout(changesErrorsTimeOut)
  }, [errors])

  useEffect(() => {
    handleShipmentElChange('time', undefined)
  }, [operationalHours])
  /** To disable number selection */
  useEffect(() => {
    if (!shipment.isInterphone) {
      handleShipmentElChange('number', undefined)
    }
  }, [shipment.isInterphone])

  const handleFileChange = async (files) => {
    //console.log(files)
    handleShipmentElChange('fileUploaded', files?.length > 0)
    if (files && files?.length > 0) {
      // for (var i = 0; i < files.length; i++) {
      //   var file = files[i]
      //   if (file.type.includes('image')) {
      //     files[i] = await resizeFile(file)
      handleShipmentElChange('files', files)
      //   }
      // }
    }
  }
  // prevent next onClick event
  useEffect(() => {
    //console.log('errrorrs: ', errors)
    if (isSubmited && canalVente != null) {
      submitRef.current.click()
    }
  }, [isSubmited])

  const handleDisabledDays = () => {
    const days = getDaysDisabledDays(shipment.selectedSalesChannel)
    setDisabledDays(days)
    setIsDisabledOperationalHours(days.length == 7)
  }
  const handleNegativeChange = (e) => {
    const value = e.target.value.trim() == '' ? '0' : e.target.value
    if (value >= 0) {
      handleShipmentChange(e)
    }
  }
  const [blurredClientPrice, setblurredClientPrice] = useState(false)
  const [blurredProviderPrice, setBlurredProviderPrice] = useState(false)
  useEffect(() => {
    if (!blurredProviderPrice) {
      handleShipmentElChange(
        'providerPrice',
        parseFloatWithComma(shipment.providerPrice)
      )
    }
    if (!blurredClientPrice) {
      handleShipmentElChange(
        'clientPrice',
        parseFloatWithComma(shipment.clientPrice)
      )
    }
  }, [blurredClientPrice, blurredProviderPrice])
  const handleBlur = (e) => {
    if (e.target.name === 'providerPrice') {
      setBlurredProviderPrice(false)
    } else {
      setblurredClientPrice(false)
    }
  }
  const handleFocus = (e) => {
    if (e.target.name === 'providerPrice') {
      setBlurredProviderPrice(true)
    } else {
      setblurredClientPrice(true)
    }
  }
  const calculateDirection = () => {
    if (
      !_.isNil(_.get(shipment, 'selectedOrigin.latlng')) &&
      !_.isNil(_.get(shipment, 'selectedDestination.latlng'))
    ) {
      getGoogleMapDirection(
        shipment.id,
        new LatLng(
          shipment.selectedOrigin.latlng.lat,
          shipment.selectedOrigin.latlng.lng
        ),
        new LatLng(
          shipment.selectedDestination.latlng.lat,
          shipment.selectedDestination.latlng.lng
        )
      )
      /*if (!directionRouteList.length) {

      } else {
        findDirection(
          shipment.id,
          new LatLng(
            shipment.selectedOrigin.latlng.lat,
            shipment.selectedOrigin.latlng.lng
          ),
          new LatLng(
            shipment.selectedDestination.latlng.lat,
            shipment.selectedDestination.latlng.lng
          )
        )
      }*/
    }
  }
  const resetPrice = () => {
    handleShipmentElChange('estimatedPrice', null)
    handleShipmentElChange('clientPrice', 0)
    handleShipmentElChange('providerPrice', 0)
  }
  const setEstimatedPriceValue = (val) => {
    handleShipmentElChange('estimatedPrice', val)
  }
  useEffect(() => {
    if (!_.isNil(_.get(estimatedPrice, 'MontantTotalTTC'))) {
      setEstimatedPriceValue(estimatedPrice)
    }
  }, [estimatedPrice])

  useEffect(() => {
    if (!_.isNil(estimatedPriceError)) {
      resetPrice()
      if (_.get(estimatedPriceError, 'data.message.length')) {
        setEstimatedPriceValue({ error: estimatedPriceError.data.message })
      } else if (!_.isEqual(estimatedPriceError, new Error('paramater-error'))) {
        // setEstimatedPriceValue({ error: 'Erreur estimation du prix' })
      }
    }
  }, [estimatedPriceError])

  const handleClientPrice = () => {
    let totalPrice = parseFloat(_.get(shipment, 'estimatedPrice.MontantTotalTTC', 0))
    totalPrice = _.get(options, 'length')
      ? options.reduce((total, op) => total + op.totalPrice, totalPrice)
      : totalPrice
    handleShipmentElChange('clientPrice', parseFloatWithComma(totalPrice))
    handleShipmentElChange('providerPrice', parseFloatWithComma(totalPrice))
    setblurredClientPrice(false)
    setBlurredProviderPrice(false)
  }

  useEffect(() => {
    const opt = options.map((e) => {
      return { idOption: e.id, quantity: e.quantity }
    })
    handleShipmentElChange('options', opt)
  }, [options])

  const setDeliveryOptions = () => {
    if (_.get(shipment, 'selectedSalesChannel.optionsDetails.length')) {
      const deliveryOptions = shipment.selectedSalesChannel.optionsDetails.filter(
        (op) => op.orderService === ORDER_TYPE.DELIVERY
      )
      if (deliveryOptions.length) {
        let optionsForm = deliveryOptions.map((el) => {
          return {
            id: el.id,
            label:
              el.label && el.label !== null
                ? `${el.label.charAt(0).toUpperCase()}${el.label.slice(1)}`
                : null,
            quantity: null,
            price: el.price,
            totalPrice: 0,
          }
        })
        setOptions(optionsForm)
      }
    }
  }

  /** Reseting form and handling initial config if exists */
  const resetForm = useCallback(() => {
    if (shipment.selectedSalesChannel && shipment.selectedSalesChannel !== null) {
      setFactures([''])
      if (_.get(shipment, 'selectedSalesChannel.adresses.length')) {
        setOriginDefaultAddress(null)
        setOriginDefaultAddress(
          new Place().getFromCanalAddress(shipment.selectedSalesChannel.adresses[0])
        )
      } else {
        setOriginDefaultAddress(null)
      }
      handleShipmentElChange('time', undefined)
      setDeliveryOptions()
      if (_.isNil(_.get(shipment, 'selectedSalesChannel.configs'))) {
        setIsManutentionOptions(true)
      } else {
        setIsManutentionOptions(false)
      }
      if (
        _.get(shipment, 'selectedSalesChannel.configs.fixedPriceIncludeManutention')
      ) {
        setIsManutentionIncluse(true)
        setIsManutentionOptions(false)
        handleShipmentElChange('manutention', true)
        handleShipmentElChange('manutentionDouble', false)
      } else {
        setIsManutentionIncluse(false)
        setIsManutentionOptions(true)
      }
      if (
        !_.get(shipment, 'selectedSalesChannel.configs.priceBasedOnNBitems') &&
        !_.get(
          shipment,
          'selectedSalesChannel.configs.priceBasedOnPurchaseAmount'
        ) &&
        !_.get(shipment, 'selectedSalesChannel.configs.priceBasedOnWeight') &&
        !_.get(shipment, 'selectedSalesChannel.configs.priceBasedOnVolume')
      ) {
        setIsVehiculeType(true)
      } else {
        setIsVehiculeType(false)
      }
      if (_.isNil(_.get(shipment, 'selectedSalesChannel.tarificationsDetails'))) {
        setIsMissingTarifsConfig(true)
      } else {
        if (
          _.get(shipment, 'selectedSalesChannel.tarificationsDetails').length === 0
        ) {
          setIsMissingTarifsConfig(true)
        }
        setIsMissingTarifsConfig(false)
      }
    }
    setFamilyArticleList(familiesArticleList)
  })

  const handleQuantityChange = (e, id) => {
    const { value } = e.target
    if (options && options.length) {
      const optionsList = [...options]
      const op = optionsList.findIndex((o) => o.id === id)
      if (op >= 0 && value >= 0) {
        optionsList[op].quantity = value
        optionsList[op].totalPrice = optionsList[op].quantity * optionsList[op].price
        setOptions(optionsList)
      }

      setBlurredProviderPrice(true)
      setblurredClientPrice(true)
      handleClientPrice()
    }
  }
  // call recommended creneau
  useEffect(() => {
    if (shipment.date && shipment.selectedSalesChannel) {
      const date = moment(shipment.date).format('YYYY-MM-DD')

      let codeCanal = shipment.selectedSalesChannel.code
      getRecommendedCreneau(date, codeCanal)
    }
  }, [shipment.date, shipment.selectedSalesChannel])
  useEffect(() => {
    switch (shipment.manutentionOptions) {
      case 'sansManutention':
        handleShipmentElChange('manutention', false)
        handleShipmentElChange('manutentionDouble', false)
        break
      case 'avecManutention':
        handleShipmentElChange('manutention', true)
        handleShipmentElChange('manutentionDouble', false)
        break
      case 'avecDoubleManutention':
        handleShipmentElChange('manutention', false)
        handleShipmentElChange('manutentionDouble', true)
        break

      default:
        break
    }
  }, [shipment.manutentionOptions])

  useEffect(() => {
    if (
      !_.get(shipment, 'selectedSalesChannel.configs.fixedPriceIncludeManutention')
    ) {
      const estimatePriceTimeOut = setTimeout(() => {
        handleEstimatePrice()
      }, 500)
      return () => clearTimeout(estimatePriceTimeOut)
    }
  }, [shipment.manutention, shipment.manutentionDouble])

  useEffect(() => {
    handleDisabledDays()
  }, [shipment.selectedOrigin])

  useEffect(() => {
    calculateDirection()
  }, [shipment.selectedOrigin, shipment.selectedDestination])

  useEffect(() => {
    if (!isGetDirectionRouteLoading) {
      if (
        _.get(shipment, 'id') == _.get(directionRoute, 'id') &&
        _.get(shipment, 'selectedOrigin.latlng.lat') ===
          _.get(directionRoute, 'origin.lat') &&
        _.get(shipment, 'selectedOrigin.latlng.lng') ===
          _.get(directionRoute, 'origin.lng')
      ) {
        handleShipmentElChange('direction', directionRoute)
      }
    }
  }, [directionRoute, isGetDirectionRouteLoading])

  useEffect(() => {
    setBlurredProviderPrice(true)
    setblurredClientPrice(true)

    handleClientPrice()
  }, [shipment.estimatedPrice])

  useEffect(() => {
    setOrderShipmentPrice(order.id, shipment.clientPrice)
  }, [shipment.clientPrice])

  useEffect(() => {
    if (_.get(shipment, 'selectedSalesChannel.configs.priceBasedOnPurchaseAmount')) {
      const estimatePriceTimeOut = setTimeout(() => {
        handleEstimatePrice()
      }, 500)
      return () => clearTimeout(estimatePriceTimeOut)
    }
  }, [shipment.purchaseAmount])

  const handleEstimatePrice = () => {
    estimatePrice(
      order.id,
      shipment.selectedSalesChannel,
      shipment.direction,
      shipment.date,
      shipment.time,
      shipment.purchaseAmount,
      shipment.nbItems,
      shipment.weight,
      shipment.manutention,
      shipment.manutentionDouble,
      shipment.destinationLevel
    )
  }

  useEffect(() => {
    if (_.get(shipment, 'selectedSalesChannel.configs.priceBasedOnNBitems')) {
      const estimatePriceTimeOut = setTimeout(() => {
        handleEstimatePrice()
      }, 500)
      return () => clearTimeout(estimatePriceTimeOut)
    }
  }, [shipment.nbItems])
  useEffect(() => {
    const estimatePriceTimeOut = setTimeout(() => {
      handleEstimatePrice()
    }, 500)
    return () => clearTimeout(estimatePriceTimeOut)
  }, [shipment.destinationLevel])

  useEffect(() => {
    if (_.get(shipment, 'selectedSalesChannel.configs.priceBasedOnWeight')) {
      const estimatePriceTimeOut = setTimeout(() => {
        handleEstimatePrice()
      }, 500)
      return () => clearTimeout(estimatePriceTimeOut)
    }
  }, [shipment.weight])

  useEffect(() => {
    const dataChangeTimeOut = setTimeout(() => {
      if (shipment.direction && shipment.direction !== null) {
        // console.log('FakherDirectionShipment ======>', shipment.direction)
        updateDirections(shipment.id, shipment.direction)
      }
      handleEstimatePrice()
    }, 500)
    return () => clearTimeout(dataChangeTimeOut)
  }, [shipment.direction])

  useEffect(() => {
    handleShipmentElChange('time', undefined)
  }, [shipment.date])

  useEffect(() => {
    // console.log('date or time')
    if (_.get(shipment, 'selectedSalesChannel.configs.priceBasedOnDate')) {
      // console.log('selectedSalesChannel.configs.priceBasedOnDate')
      const estimatePriceTimeOut = setTimeout(() => {
        handleEstimatePrice()
      }, 500)
      return () => clearTimeout(estimatePriceTimeOut)
    }
  }, [shipment.date, shipment.time])

  useEffect(() => {
    resetForm()
    if (
      _.get(shipment, 'selectedSalesChannel') &&
      Object.keys(_.get(shipment, 'selectedSalesChannel')).length > 0
    ) {
      // retrieve sales channel one week shipments
      handleSalesChannel(_.get(shipment, 'selectedSalesChannel'))
      getOneWeekShipments([_.get(shipment, 'selectedSalesChannel.code')])
      const prestat = _.get(shipment, 'selectedSalesChannel.canalPrestationService')
      setPrestation(prestat)
    }
  }, [shipment.selectedSalesChannel])
  useEffect(() => {
    if (salesChannel) {
      handleShipmentElChange('selectedSalesChannel', salesChannel)
      if (
        order.type === ORDER_TYPE.DELIVERY_ASSEMBLY &&
        salesChannel.configs?.includedServices === 'course'
      ) {
        removeAssembly(order.id)
      }
      if (salesChannel.configs?.includedServices === 'montage') {
        addOrder(ORDER_TYPE.ASSEMBLY)
        removeOrder(order)
      }
    }
  }, [salesChannel])

  // Articles familles
  const [familyarticleList, setFamilyArticleList] = useState([])
  useEffect(() => {
    if (familiesArticleList.length !== familyarticleList.length) {
      let list = [
        ...familiesArticleList.map((e) =>
          'checked' in e ? { ...e } : { ...e, checked: false }
        ),
      ]
      setFamilyArticleList(list)
      handleShipmentElChange('articleFamilies', list)
    }
  }, [familiesArticleList])
  useEffect(() => {
    if (
      _.get(shipmentData, 'destinationQuery') !=
        _.get(shipmentData, 'selectedDestination.formattedAddress') ||
      _.get(shipmentData, 'originQuery') !=
        _.get(shipmentData, 'selectedOrigin.formattedAddress')
    )
      resetDirections()
  }, [shipmentData])

  const handleCheckChange = (index) => {
    let list = [...familyarticleList]
    list[index].checked = !familyarticleList[index].checked
    setFamilyArticleList(list)
    handleShipmentElChange('articleFamilies', list)
  }
  const [factures, setFactures] = useState([''])
  const handleInvoiceChange = (value, index) => {
    let facturesList = [...factures]
    facturesList[index] = value
    setFactures(facturesList)
    handleShipmentElChange('factures', facturesList)
  }
  const addFacture = () => {
    let facturesList = [...factures]
    facturesList.push(prefix)
    setFactures(facturesList)
    handleShipmentElChange('factures', facturesList)
  }
  const removeFacture = (e, index) => {
    let facturesList = [...factures]
    const filteredList = facturesList.filter((item, i) => i !== index)
    handleShipmentElChange('factures', filteredList)
    setFactures(filteredList)
    e.preventDefault()
  }

  const [prefix, setPrefix] = useState('')

  useEffect(() => {
    if (salesChannel && salesChannel?.configs?.prefixFacture) {
      setPrefix(salesChannel.configs.prefixFacture)
      setFactures([salesChannel.configs.prefixFacture])
      handleShipmentElChange('factures', [salesChannel.configs.prefixFacture])
    }
  }, [salesChannel])

  const handleChangeClientInfos = () => {
    handleShipmentElChange('clientInfos', !shipment.clientInfos)
    if (!shipment.clientInfos) {
      handleShipmentElChange('destinationFirstname', '')
      handleShipmentElChange('destinationLastname', '')
      handleShipmentElChange('destinationPhone', '')
      handleShipmentElChange('destinationMail', '')
      handleShipmentElChange('envoiMailArrivee', false)
    }
  }
  const handleChangeenvoiMailArrivee = () => {
    handleShipmentElChange('envoiMailArrivee', !shipment.envoiMailArrivee)
    handleShipmentElChange('destinationMail', '')

    if (!shipment.envoiMailArrivee) {
      if (shipment?.destinationPhone?.length > 0) {
        const mail =
          shipment.destinationPhone[0] === '+'
            ? shipment.destinationPhone + '@noemail.com'
            : '+' + shipment.destinationPhone + '@noemail.com'
        handleShipmentElChange('destinationMail', mail)
      }
    }
  }

  const [affLivRet, setAffLivRet] = useState(true)

  useEffect(() => {
    if (ClientDelivery.includes(shipment.courseType)) {
      setAffLivRet(true)
    } else {
      setAffLivRet(false)
    }
  }, [shipment.courseType])
  const [isSchmidt, setIsSchmidt] = useState(false)

  useEffect(() => {
    if (
      shipment.selectedSalesChannel?.macroCanal?.code &&
      (shipment.selectedSalesChannel?.macroCanal?.code === 'SCHMIDT-GROUPE' ||
        shipment.selectedSalesChannel?.code === 'SCHMIDT-GROUPE')
    ) {
      setIsSchmidt(true)
    } else {
      setIsSchmidt(false)
    }
  }, [shipment.selectedSalesChannel])

  useEffect(() => {
    let originQuery = shipment.originQuery
    let selectedOrigin = shipment.selectedOrigin
    let destinationQuery = shipment.destinationQuery
    let selectedDestination = shipment.selectedDestination
    setAncienCourseType(shipment.courseType)

    if (
      (ClientDelivery.includes(shipment.courseType) &&
        ClientPickup.includes(ancienCourseType)) ||
      (ClientDelivery.includes(ancienCourseType) &&
        ClientPickup.includes(shipment.courseType))
    ) {
      handleShipmentElChange('originQuery', destinationQuery)
      handleShipmentElChange('selectedOrigin', selectedDestination)
      handleShipmentElChange('destinationQuery', originQuery)
      handleShipmentElChange('selectedDestination', selectedOrigin)
      setOriginDefaultAddress(selectedDestination)
      setdestinationDefaultAddress(selectedOrigin)
    }
  }, [shipment.courseType])
  return (
    <>
      {_.get(shipmentSalesChannelList, 'length') && (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div className="place-shipment-title">
                {' '}
                Livraison
                {isManutentionIncluse && (
                  <span className="manutention-inclus">
                    {' '}
                    La manutention est incluse <CheckCircleIcon />
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
          <div className="place-shipment">
            <form
              className={[
                'form-validation place-shipment-form',
                isSubmitted ? 'was-submitted' : '',
                isShipmentInValid ? 'is-invalid' : '',
                isShipmentValid ? 'is-valid' : '',
              ].join(' ')}
              noValidate
              autoComplete="off"
              onSubmit={($event) => handleShipmentSubmit($event)}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl>
                    <AutoComplete
                      id="selectedSalesChannel-shipment"
                      options={shipmentSalesChannelList}
                      getOptionLabel={(option) => (option.name ? option.name : '')}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.code}>
                            {option.name}
                          </li>
                        )
                      }}
                      value={shipment.selectedSalesChannel}
                      error={
                        (Object.values(shipment?.selectedSalesChannel).length > 0 &&
                          isDisabledOperationalHours) ||
                        (isSubmitted &&
                          !_.isNil(_.get(errors, 'selectedSalesChannel.required')))
                      }
                      helperText={
                        Object.values(shipment?.selectedSalesChannel).length > 0 &&
                        isDisabledOperationalHours
                          ? _.get(errors, 'selectedSalesChannel.pattern', '')
                          : isSubmitted
                          ? _.get(errors, 'selectedSalesChannel.required')
                            ? _.get(errors, 'selectedSalesChannel.required')
                            : _.get(errors, 'selectedSalesChannel.pattern', '')
                          : ''
                      }
                      required={true}
                      variant="standard"
                      // placeholder="Canal de vente"
                      label="Canal de vente"
                      onChange={($event, newValue) => {
                        if (newValue?.code) getCanalVente(newValue?.code)
                        setIsDisabledOperationalHours(false)
                      }}
                    />
                  </FormControl>
                </Grid>
                {prestation.length > 0 && (
                  <Grid item xs={12}>
                    <Prestation
                      salesChannelPrest={prestation}
                      handleChange={handleShipmentElChange}
                      selectedPrestation={shipment.selectPresCanalService}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </div>
          {isGetCanalVenteLoading ? (
            <div style={{ padding: '8% 40%' }}>
              <CircularProgress color="primary" />
            </div>
          ) : (
            canalVente && (
              <>
                <div className="place-shipment">
                  <form
                    className={[
                      'form-validation place-shipment-form',
                      isSubmitted ? 'was-submitted' : '',
                      isShipmentInValid ? 'is-invalid' : '',
                      isShipmentValid ? 'is-valid' : '',
                    ].join(' ')}
                    noValidate
                    autoComplete="off"
                    onSubmit={($event) => handleShipmentSubmit($event)}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div style={{ paddingTop: '8px' }}>
                          <FormControl
                            variant="outlined"
                            className="dropdown-loader"
                          >
                            <InputLabel htmlFor="outlined-age-native-simple">
                              Type de livraison
                            </InputLabel>
                            <Select
                              onChange={handleShipmentChange}
                              label="Type de livraison"
                              variant="outlined"
                              name="courseType"
                              value={shipment.courseType}
                              id={`type-livraison`}
                            >
                              {Object.entries(COURSE_TYPE).map(
                                ([code, label], i) => (
                                  <MenuItem
                                    value={code}
                                    key={i}
                                    id={`type-livraison-${i}`}
                                  >
                                    {label}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                      <div tabIndex="-1" id="date-shipment"></div>
                      <Grid item xs={12} sm={6} id="date-shipment">
                        <FormControl>
                          <DatePickerLocalisation
                            disabledDays={disabledDays}
                            openedDays={[]}
                            disablePast={true}
                            handleDateChange={($event) =>
                              handleShipmentElChange('date', $event)
                            }
                            label={
                              affLivRet ? 'Date de livraison' : 'Date de retrait'
                            }
                            hasError={isSubmitted && !_.isNil(_.get(errors, 'date'))}
                            errorMessage={
                              _.get(errors, 'date.required')
                                ? _.get(errors, 'date.required')
                                : _.get(errors, 'date.pattern', null)
                            }
                            withDefaultValue={false}
                            isOrderPage={true}
                            date={shipment.date}
                            id="shipment-date"
                            isDisabled={isDisabledOperationalHours}
                          />
                        </FormControl>
                      </Grid>
                      <Grid id="time" item xs={12} sm={6}>
                        <FormControl className="dropdown-loader">
                          <TextField
                            id="time-shipment"
                            select
                            label={
                              affLivRet ? 'Heure de livraison' : 'Heure de retrait'
                            }
                            name="time"
                            disabled={!recommendedCreneauList}
                            value={shipment.time || ''}
                            onChange={handleShipmentChange}
                            variant="outlined"
                            error={isSubmitted && !_.isNil(_.get(errors, 'time'))}
                            helperText={
                              isSubmitted && !_.isNil(_.get(errors, 'time'))
                                ? !_.isNil(_.get(errors, 'time.required'))
                                  ? _.get(errors, 'time.required')
                                  : _.get(errors, 'time.pattern', null)
                                : ''
                            }
                            required
                          >
                            {_.get(recommendedCreneauList, 'length') ? (
                              recommendedCreneauList.filter(
                                (h) =>
                                  !(
                                    new Date().toLocaleDateString() ===
                                      new Date(shipment.date).toLocaleDateString() &&
                                    (new Date().getHours() + 2 >=
                                      parseInt(h.end[0] + h.end[1]) ||
                                      new Date().getHours() + 2 >=
                                        parseInt(h.start[0] + h.start[1]))
                                  )
                              ).length !== 0 ? (
                                recommendedCreneauList.map((h, index) => {
                                  if (
                                    !(
                                      new Date().toLocaleDateString() ===
                                        new Date(
                                          shipment.date
                                        ).toLocaleDateString() &&
                                      (new Date().getHours() + 2 >=
                                        parseInt(h.end[0] + h.end[1]) ||
                                        new Date().getHours() + 2 >=
                                          parseInt(h.start[0] + h.start[1]))
                                    )
                                  ) {
                                    return (
                                      <MenuItem
                                        value={h}
                                        key={index}
                                        id={`time-shipment-${index}`}
                                      >
                                        {h.start} - {h.end}
                                      </MenuItem>
                                    )
                                  }
                                })
                              ) : (
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                              )
                            ) : (
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            )}
                          </TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <div className="place-shipment">
                  <form
                    className={[
                      'form-validation place-shipment-form',
                      isSubmitted ? 'was-submitted' : '',
                      isShipmentInValid ? 'is-invalid' : '',
                      isShipmentValid ? 'is-valid' : '',
                    ].join(' ')}
                    noValidate
                    autoComplete="off"
                    onSubmit={($event) => handleShipmentSubmit($event)}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} className="form-label">
                        Départ
                      </Grid>
                      <Grid item xs={12} className="nextAccord">
                        <AddressAutoComplete
                          id="selectedOrigin-shipment"
                          label="Adresse de départ"
                          selectedDefaultAddress={originDefaultAddress}
                          handleQueryChange={($event) =>
                            handleShipmentElChange('originQuery', $event)
                          }
                          handleOnAddressSelect={($event) => {
                            handleShipmentElChange('selectedOrigin', $event)
                            setOriginDefaultAddress($event)
                          }}
                          isRequired
                          hasError={
                            isSubmitted &&
                            (!_.isNil(_.get(errors, 'selectedOrigin.required')) ||
                              !_.isNil(_.get(errors, 'selectedOrigin.pattern')))
                          }
                          errorText={
                            isSubmitted &&
                            (_.get(errors, 'selectedOrigin.required') ||
                              _.get(errors, 'selectedOrigin.pattern'))
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className="dropdown-loader">
                          <TextField
                            id="level-shipment"
                            select
                            label="Etage"
                            name="originLevel"
                            value={shipment.originLevel || 0}
                            onChange={handleShipmentChange}
                            variant="outlined"
                            SelectProps={{
                              MenuProps: {
                                style: { height: 300 },
                              },
                            }}
                            // error={
                            //   isSubmited > 0 && !_.isNil(_.get(errors, 'level'))
                            // }
                            // helperText={
                            //   isSubmited > 0 && !_.isNil(_.get(errors, 'level'))
                            //     ? _.get(errors, 'level.required')
                            //     : ''
                            // }
                          >
                            {LEVEL_NUMBERS.map((h, index) => (
                              <MenuItem value={h} key={index}>
                                {h}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="switchers-section">
                          <FormControl
                            component="fieldset"
                            className="switcher-container"
                          >
                            <FormGroup row>
                              <FormControlLabel
                                className="switcher-label"
                                control={
                                  <Switch
                                    name="originIsElevator"
                                    value={shipment.originIsElevator}
                                    defaultChecked={false}
                                    onChange={handleShipmentSwitcherChange}
                                  />
                                }
                                label={'Présence Ascenseur'}
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="nbrMarcheAvantAscenseurAdresseDepart"
                          value={shipment.nbrMarcheAvantAscenseurAdresseDepart}
                          name="nbrMarcheAvantAscenseurAdresseDepart"
                          onChange={handleNegativeChange}
                          label="Nombre de marches avant l'accès à l'ascenceur"
                          variant="outlined"
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 1,
                              type: 'text',
                              pattern: '[0-9]*',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontSize: '13px',
                            fontWeight: 'bold',
                            paddingTop: '10px',
                            paddingBottom: '8px',
                          }}
                        >
                          Adresse de départ est accessible
                        </div>

                        <FormControl
                          component="fieldset"
                          style={{ paddingBottom: '10px' }}
                        >
                          <RadioGroup
                            id="plVlAdresseDepart"
                            aria-label="clientType"
                            name="plVlAdresseDepart"
                            value={shipment.plVlAdresseDepart}
                            onChange={() =>
                              handleShipmentElChange(
                                'plVlAdresseDepart',
                                !shipment.plVlAdresseDepart
                              )
                            }
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="En poids lourd (Rue à double sens, Rue empruntable par un camion benne...)"
                              style={{ paddingRight: '60px' }}
                              id={'poids-lourds-Arrivee'}
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="Uniquement en VL (Rue étroite, tavaux ...)"
                              style={{ paddingRight: '14px' }}
                              id={'Uniquement-VL-Arrivee'}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="originObservation"
                          value={shipment.originObservation}
                          name="originObservation"
                          onChange={handleShipmentChange}
                          label="Informations complémentaire "
                          variant="outlined"
                          multiline
                          rows={4}
                        />
                      </Grid>

                      <div className="accordion">
                        <Grid item>
                          <Accordion className="accordion-section">
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              // position: absolute;
                              // right: 31%;

                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <div className="client-section-title">
                                Ajouter des informations au départ{' '}
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container justify="center" spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    id="originFirstname"
                                    name="originFirstname"
                                    label="Prénom"
                                    variant="outlined"
                                    value={shipment.originFirstname}
                                    onChange={handleShipmentChange}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    id="originLastname"
                                    name="originLastname"
                                    label="Nom"
                                    variant="outlined"
                                    value={shipment.originLastname}
                                    onChange={handleShipmentChange}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <div
                                    className={
                                      (
                                        isSubmitted &&
                                        !_.isNil(_.get(errors, 'originPhone'))
                                          ? !_.isNil(
                                              _.get(errors, 'originPhone.required')
                                            )
                                            ? _.get(errors, 'originPhone.required')
                                            : _.get(
                                                errors,
                                                'originPhone.pattern',
                                                null
                                              )
                                          : true
                                      )
                                        ? 'error-phone'
                                        : 'phone'
                                    }
                                  >
                                    <PhoneInput
                                      id="phone"
                                      onChange={(newValue) => {
                                        handleShipmentElChange(
                                          'originPhone',
                                          '+' + newValue
                                        )
                                      }}
                                      value={shipment.originPhone}
                                      onlyCountries={getPhoneInputCountries()}
                                      country={'fr'}
                                      inputProps={{
                                        name: 'phone',
                                        required: true,
                                        country: 'fr',
                                      }}
                                      isValid={() => {
                                        return isSubmitted &&
                                          !_.isNil(_.get(errors, 'originPhone'))
                                          ? !_.isNil(
                                              _.get(errors, 'originPhone.required')
                                            )
                                            ? _.get(errors, 'originPhone.required')
                                            : _.get(
                                                errors,
                                                'originPhone.pattern',
                                                null
                                              )
                                          : true
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    id="originMail-shipment"
                                    name="originMail"
                                    label="E-mail"
                                    variant="outlined"
                                    error={
                                      isSubmitted &&
                                      !_.isNil(_.get(errors, 'originMail'))
                                    }
                                    helperText={
                                      isSubmitted &&
                                      !_.isNil(_.get(errors, 'originMail'))
                                        ? _.get(errors, 'originMail.pattern', null)
                                        : ''
                                    }
                                    value={shipment.originMail}
                                    onChange={handleShipmentChange}
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </div>
                    </Grid>
                  </form>
                </div>
                <div className="place-shipment">
                  <form
                    className={[
                      'form-validation place-shipment-form',
                      isSubmitted ? 'was-submitted' : '',
                      isShipmentInValid ? 'is-invalid' : '',
                      isShipmentValid ? 'is-valid' : '',
                    ].join(' ')}
                    noValidate
                    autoComplete="off"
                    onSubmit={($event) => handleShipmentSubmit($event)}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} className="form-label">
                        Arrivée
                      </Grid>
                      <Grid item xs={12}>
                        <AddressAutoComplete
                          id="selectedDestination-shipment"
                          label="Adresse d'arrivée"
                          selectedDefaultAddress={destinationDefaultAddress}
                          handleQueryChange={($event) =>
                            handleShipmentElChange('destinationQuery', $event)
                          }
                          handleOnAddressSelect={($event) => {
                            handleShipmentElChange('selectedDestination', $event)
                            setdestinationDefaultAddress($event)
                          }}
                          hasError={
                            isSubmitted &&
                            (!_.isNil(
                              _.get(errors, 'selectedDestination.required')
                            ) ||
                              !_.isNil(_.get(errors, 'selectedDestination.pattern')))
                          }
                          errorText={
                            isSubmitted &&
                            (_.get(errors, 'selectedDestination.required') ||
                              _.get(errors, 'selectedDestination.pattern'))
                          }
                          isRequired
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className="dropdown-loader">
                          <TextField
                            id="outlined-select-currency-native"
                            select
                            label="Etage"
                            name="destinationLevel"
                            value={shipment.destinationLevel || 0}
                            // required
                            onChange={handleShipmentChange}
                            variant="outlined"
                            SelectProps={{
                              MenuProps: {
                                style: { height: 300 },
                              },
                            }}
                            error={isSubmitted && !_.isNil(_.get(errors, 'level'))}
                            helperText={
                              isSubmitted && !_.isNil(_.get(errors, 'level'))
                                ? _.get(errors, 'level.required')
                                : ''
                            }
                          >
                            {LEVEL_NUMBERS.map((h, index) => (
                              <MenuItem value={h} key={index}>
                                {h}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="switchers-section">
                          <FormControl
                            component="fieldset"
                            className="switcher-container"
                          >
                            <FormGroup row>
                              <FormControlLabel
                                className="switcher-label"
                                control={
                                  <Switch
                                    name="destinationIsElevator"
                                    value={shipment.destinationIsElevator}
                                    defaultChecked={false}
                                    onChange={handleShipmentSwitcherChange}
                                  />
                                }
                                label={'Présence Ascenseur'}
                                labelPlacement="end"
                              />
                            </FormGroup>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="nbrMarcheAvantAscenseurAdresseArrivee"
                          value={shipment.nbrMarcheAvantAscenseurAdresseArrivee}
                          name="nbrMarcheAvantAscenseurAdresseArrivee"
                          onChange={handleNegativeChange}
                          label="Nombre de marches avant l'accès à l'ascenceur"
                          variant="outlined"
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 1,
                              type: 'text',
                              pattern: '[0-9]*',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            fontSize: '13px',
                            fontWeight: 'bold',
                            paddingTop: '10px',
                            paddingBottom: '8px',
                          }}
                        >
                          {"Adresse d'arrivée est accessible"}
                        </div>

                        <FormControl
                          component="fieldset"
                          style={{ paddingBottom: '10px' }}
                        >
                          <RadioGroup
                            id="plVlAdresseArrivee"
                            aria-label="clientType"
                            name="plVlAdresseArrivee"
                            value={shipment.plVlAdresseArrivee}
                            onChange={() =>
                              handleShipmentElChange(
                                'plVlAdresseArrivee',
                                !shipment.plVlAdresseArrivee
                              )
                            }
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="En poids lourd (Rue à double sens, Rue empruntable par un camion benne...)"
                              style={{ paddingRight: '60px' }}
                              id={'poids-lourds-Arrivee'}
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="Uniquement en VL (Rue étroite, tavaux ...)"
                              style={{ paddingRight: '14px' }}
                              id={'Uniquement-VL-Arrivee'}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="observation"
                          value={shipment.observation}
                          name="observation"
                          onChange={handleShipmentChange}
                          label="Informations complémentaire "
                          variant="outlined"
                          multiline
                          // required={true}
                          rows={4}
                        />
                      </Grid>
                      {isSchmidt && (
                        <Grid item xs={12}>
                          <TextField
                            id="orderBalance-shipment"
                            name="orderBalance"
                            label="Contre remboursement"
                            variant="outlined"
                            value={shipment.orderBalance}
                            onChange={handleNegativeChange}
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                type: 'text',
                                pattern: '[0-9]*',
                              },
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <div className="switchers-section">
                          <FormControl
                            component="fieldset"
                            className="switcher-container switcher-content"
                          >
                            <FormGroup row>
                              <FormControlLabel
                                className="switcher-label"
                                control={
                                  <Switch
                                    name="isInterphone"
                                    value={shipment.isInterphone}
                                    defaultChecked={false}
                                    onChange={handleShipmentSwitcherChange}
                                  />
                                }
                                label={<span>Interphone</span>}
                                labelPlacement="start"
                              />
                            </FormGroup>
                          </FormControl>
                          {shipment.isInterphone && (
                            <FormControl className="dropdown-loader">
                              <TextField
                                id="number-shipment"
                                label="Numéro/Nom"
                                name="number"
                                disabled={shipment.isInterphone ? false : true}
                                value={shipment.number || ''}
                                onChange={handleShipmentChange}
                                variant="outlined"
                                error={
                                  isSubmitted && !_.isNil(_.get(errors, 'number'))
                                }
                                helperText={
                                  isSubmitted && !_.isNil(_.get(errors, 'number'))
                                    ? _.get(errors, 'number.required')
                                    : ''
                                }
                              />
                            </FormControl>
                          )}
                        </div>
                      </Grid>
                      <div style={{ padding: '8px' }}>
                        <FormControl
                          component="fieldset"
                          className="switcher-container"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={handleChangeClientInfos}
                                checked={shipment.clientInfos}
                                name="clientInfos"
                              />
                            }
                            label={'Récupérer les infos client'}
                            labelPlacement="end"
                          />
                        </FormControl>
                      </div>
                      <div className="accordion">
                        <Grid item>
                          <Accordion
                            className="accordion-section"
                            expanded={
                              (isSubmitted && errorAccordion) || openAccordion
                            }
                            onChange={() => {
                              setOpenAccordion(!openAccordion)
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <div className="client-section-title">
                                Ajouter des informations d&apos;arrivée
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container justify="center" spacing={3}>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    id="destinationFirstname-shipment"
                                    name="destinationFirstname"
                                    label="Prénom"
                                    variant="outlined"
                                    value={shipment.destinationFirstname}
                                    onChange={handleShipmentChange}
                                    disabled={shipment.clientInfos}
                                    required={!shipment.clientInfos}
                                    error={
                                      isSubmitted &&
                                      !_.isNil(_.get(errors, 'destinationFirstname'))
                                    }
                                    helperText={
                                      isSubmitted &&
                                      !_.isNil(_.get(errors, 'destinationFirstname'))
                                        ? _.get(
                                            errors,
                                            'destinationFirstname.required'
                                          ) ||
                                          _.get(
                                            errors,
                                            'destinationFirstname.pattern'
                                          )
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    id="destinationLastname-shipment"
                                    name="destinationLastname"
                                    label="Nom"
                                    variant="outlined"
                                    value={shipment.destinationLastname}
                                    onChange={handleShipmentChange}
                                    disabled={shipment.clientInfos}
                                    required={!shipment.clientInfos}
                                    error={
                                      isSubmitted &&
                                      !_.isNil(_.get(errors, 'destinationLastname'))
                                    }
                                    helperText={
                                      isSubmitted &&
                                      !_.isNil(_.get(errors, 'destinationLastname'))
                                        ? _.get(
                                            errors,
                                            'destinationLastname.required'
                                          ) ||
                                          _.get(
                                            errors,
                                            'destinationLastname.pattern'
                                          )
                                        : ''
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                  <FormControl component="fieldset">
                                    <FormGroup row>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            name="envoiMailArrivee"
                                            value={shipment.envoiMailArrivee}
                                            defaultChecked={false}
                                            onChange={handleChangeenvoiMailArrivee}
                                          />
                                        }
                                        label="Pas d'adresse mail"
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                                <div id="destinationPhone-shipment"></div>
                                <Grid item xs={12} sm={6}>
                                  <div
                                    className={
                                      (
                                        isSubmitted &&
                                        !_.isNil(_.get(errors, 'destinationPhone'))
                                          ? !_.isNil(
                                              _.get(
                                                errors,
                                                'destinationPhone.required'
                                              )
                                            )
                                            ? _.get(
                                                errors,
                                                'destinationPhone.required'
                                              )
                                            : _.get(
                                                errors,
                                                'destinationPhone.pattern',
                                                null
                                              )
                                          : true
                                      )
                                        ? 'error-phone'
                                        : 'phone'
                                    }
                                  >
                                    {' '}
                                    <PhoneInput
                                      id="destinationPhone-shipment"
                                      onChange={(newValue) => {
                                        handleShipmentElChange(
                                          'destinationPhone',
                                          '+' + newValue
                                        )
                                        if (shipment.envoiMailArrivee)
                                          handleShipmentElChange(
                                            'destinationMail',
                                            '+' + newValue + '@noemail.com'
                                          )
                                      }}
                                      value={shipment.destinationPhone}
                                      onlyCountries={getPhoneInputCountries()}
                                      country={'fr'}
                                      inputProps={{
                                        name: 'phone',
                                        required: true,
                                        country: 'fr',
                                      }}
                                      disabled={shipment.clientInfos}
                                      specialLabel={
                                        !shipment.clientInfos ? 'Phone *' : 'Phone'
                                      }
                                      isValid={() => {
                                        return isSubmitted &&
                                          !_.isNil(_.get(errors, 'destinationPhone'))
                                          ? !_.isNil(
                                              _.get(
                                                errors,
                                                'destinationPhone.required'
                                              )
                                            )
                                            ? _.get(
                                                errors,
                                                'destinationPhone.required'
                                              )
                                            : _.get(
                                                errors,
                                                'destinationPhone.pattern',
                                                null
                                              )
                                          : true
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    id="destinationMail-shipment"
                                    name="destinationMail"
                                    label="E-mail"
                                    variant="outlined"
                                    required={!shipment.clientInfos}
                                    error={
                                      isSubmitted &&
                                      !_.isNil(_.get(errors, 'destinationMail'))
                                    }
                                    helperText={
                                      isSubmitted &&
                                      !_.isNil(_.get(errors, 'destinationMail'))
                                        ? _.get(
                                            errors,
                                            'destinationMail.required'
                                          ) ||
                                          _.get(errors, 'destinationMail.pattern')
                                        : ''
                                    }
                                    value={
                                      !shipment.envoiMailArrivee
                                        ? shipment.destinationMail || ''
                                        : ''
                                    }
                                    onChange={handleShipmentChange}
                                    disabled={
                                      shipment.clientInfos ||
                                      shipment.envoiMailArrivee
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      </div>
                    </Grid>
                  </form>
                </div>
                <div className="place-shipment">
                  <form
                    className={[
                      'form-validation place-shipment-form',
                      isSubmitted ? 'was-submitted' : '',
                      isShipmentInValid ? 'is-invalid' : '',
                      isShipmentValid ? 'is-valid' : '',
                    ].join(' ')}
                    noValidate
                    autoComplete="off"
                    onSubmit={($event) => handleShipmentSubmit($event)}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} className="form-label">
                        Informations
                      </Grid>
                      {isManutentionOptions &&
                      !_.isEqual(shipment.selectedSalesChannel, {}) ? (
                        <Grid item xs={12}>
                          <div>Manutention</div>

                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="clientType"
                              name="manutentionOptions"
                              value={shipment.manutentionOptions}
                              onChange={handleShipmentChange}
                            >
                              <FormControlLabel
                                value="sansManutention"
                                control={<Radio />}
                                label="Sans Manutention"
                                style={{ paddingRight: '14px' }}
                              />
                              <FormControlLabel
                                value="avecManutention"
                                control={<Radio />}
                                label="Avec manutention"
                                style={{ paddingRight: '14px' }}
                              />
                              <FormControlLabel
                                value="avecDoubleManutention"
                                control={<Radio />}
                                label="Avec double manutention"
                                style={{ paddingRight: '14px' }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      ) : null}
                      {isVehiculeType &&
                      !_.isEqual(shipment.selectedSalesChannel, {}) ? (
                        <Grid item xs={12}>
                          <div>Véhicules</div>

                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="clientType"
                              name="vehiculeType"
                              value={shipment.vehiculeType}
                              onChange={handleShipmentChange}
                            >
                              <FormControlLabel
                                value="S"
                                control={<Radio />}
                                label="S (longeur max 2.40m)"
                                style={{ paddingRight: '14px' }}
                              />
                              <FormControlLabel
                                value="M"
                                control={<Radio />}
                                label="M (longeur max 3.30m)"
                                style={{ paddingRight: '14px' }}
                              />
                              <FormControlLabel
                                value="L"
                                control={<Radio />}
                                label="L (longeur max 4m)"
                                style={{ paddingRight: '14px' }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <FormControl>
                          <TextField
                            id="nbItems-shipment"
                            label="Nombre d'articles"
                            variant="outlined"
                            name="nbItems"
                            margin="dense"
                            min="0"
                            value={shipment.nbItems}
                            required
                            onChange={handleNegativeChange}
                            error={isSubmitted && !_.isNil(_.get(errors, 'nbItems'))}
                            helperText={
                              isSubmitted && !_.isNil(_.get(errors, 'nbItems'))
                                ? !_.isNil(_.get(errors, 'nbItems.required'))
                                  ? _.get(errors, 'nbItems.required')
                                  : _.get(errors, 'nbItems.pattern', null)
                                : ''
                            }
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                type: 'text',
                                pattern: '[0-9]*',
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl error={!_.isNil(_.get(errors, 'weight'))}>
                          <TextField
                            id="weight-shipment"
                            label="Poids des articles"
                            variant="outlined"
                            name="weight"
                            margin="dense"
                            min="0"
                            required={true}
                            value={shipment.weight}
                            onChange={handleNegativeChange}
                            error={isSubmitted && !_.isNil(_.get(errors, 'weight'))}
                            helperText={
                              isSubmitted && !_.isNil(_.get(errors, 'weight'))
                                ? !_.isNil(_.get(errors, 'weight.required'))
                                  ? _.get(errors, 'weight.required')
                                  : _.get(errors, 'weight.pattern', null)
                                : ''
                            }
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                type: 'text',
                                pattern: '[0-9]*',
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="volume"
                          name="volume"
                          label="Volume"
                          variant="outlined"
                          value={shipment.volume}
                          onChange={handleNegativeChange}
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 1,
                              type: 'text',
                              pattern: '[0-9]*',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          error={!_.isNil(_.get(errors, 'purchaseAmount'))}
                        >
                          <TextField
                            id="purchaseAmount-shipment"
                            label="Montant d'achat"
                            variant="outlined"
                            margin="dense"
                            name="purchaseAmount"
                            required={
                              shipment.selectedSalesChannel?.configs
                                ?.priceBasedOnPurchaseAmount
                            }
                            value={shipment.purchaseAmount}
                            onChange={handleNegativeChange}
                            error={
                              isSubmitted &&
                              !_.isNil(_.get(errors, 'purchaseAmount'))
                            }
                            helperText={
                              isSubmitted &&
                              !_.isNil(_.get(errors, 'purchaseAmount'))
                                ? !_.isNil(_.get(errors, 'purchaseAmount.required'))
                                  ? _.get(errors, 'purchaseAmount.required')
                                  : _.get(errors, 'purchaseAmount.pattern', null)
                                : ''
                            }
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                type: 'text',
                                pattern: '[0-9]*',
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      {options && options.length ? (
                        <Grid container spacing={3} className="options-list">
                          <OptionsList
                            options={options}
                            handleQuantityChange={handleQuantityChange}
                          />
                        </Grid>
                      ) : null}
                      <div tabIndex="-1" id="articleFamilies-shipment"></div>
                      <Grid item xs={12}>
                        {/* ------------ Articles familles  -------------*/}
                        <div>
                          {familyarticleList.length > 0 && (
                            <FormControl
                              component="fieldset"
                              className="fieldset-container"
                              error={
                                isSubmitted &&
                                !_.isNil(_.get(errors, 'articleFamilies'))
                              }
                            >
                              <FormLabel
                                component="legend"
                                className="legend-text"
                                required
                              >
                                Articles familles
                              </FormLabel>
                              <FormGroup>
                                {familyarticleList.map((article, index) => (
                                  <FormControlLabel
                                    key={`family_article_${index}`}
                                    className="check-box-label"
                                    control={
                                      <Checkbox
                                        checked={article.checked}
                                        onChange={() => handleCheckChange(index)}
                                        name={article.label}
                                      />
                                    }
                                    label={article.label}
                                  />
                                ))}
                              </FormGroup>
                              <FormHelperTextError
                                content={_.get(errors, 'articleFamilies.required')}
                              />
                            </FormControl>
                          )}
                        </div>
                        {/* ---------------- Articles familles  ---------------- */}
                      </Grid>
                      <Grid item xs={12}>
                        <NoteInterne
                          noteInterne={shipment.noteInterne}
                          name="noteInterne"
                          handleChange={handleShipmentChange}
                          isSubmitted={isSubmitted}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InvoiceInput
                          factures={factures}
                          handleInvoiceChange={handleInvoiceChange}
                          removeFacture={removeFacture}
                          addFacture={addFacture}
                          isSubmitted={isSubmitted}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <TextField
                            error={
                              isSubmitted && !_.isNil(_.get(errors, 'clientPrice'))
                            }
                            min="0"
                            margin="dense"
                            name="clientPrice"
                            variant="outlined"
                            required
                            value={shipment.clientPrice}
                            label="Prix client"
                            onChange={handleNegativeChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            id="clientPrice-shipment"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                type: 'text',
                                pattern: '[0-9]*',
                              },
                            }}
                          />

                          <FormHelperText
                            className="text-info"
                            id="estimatedPrice-shipment"
                          >
                            {isSubmitted &&
                              _.get(shipment, 'estimatedPrice.error', '')}
                          </FormHelperText>
                          <FormHelperTextError
                            content={
                              isSubmitted &&
                              (_.get(errors, 'clientPrice.required') ||
                                _.get(errors, 'clientPrice.pattern') ||
                                _.get(errors, 'clientPrice.min', ''))
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <TextField
                            error={
                              isSubmitted && !_.isNil(_.get(errors, 'providerPrice'))
                            }
                            min={0}
                            variant="outlined"
                            margin="dense"
                            name="providerPrice"
                            required
                            value={shipment.providerPrice}
                            label="Prix prestataire"
                            onChange={handleNegativeChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            id="providerPrice-shipment"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                                type: 'text',
                                pattern: '[0-9]*',
                              },
                            }}
                          />
                          <FormHelperText className="text-info">
                            {isSubmitted &&
                              _.get(shipment, 'estimatedPrice.error', '')}
                          </FormHelperText>
                          <FormHelperTextError
                            content={
                              isSubmitted &&
                              (_.get(errors, 'providerPrice.required') ||
                                _.get(errors, 'providerPrice.pattern') ||
                                _.get(errors, 'providerPrice.min', ''))
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item className="drop-zone-container" xs={12}>
                        <div className="assembly-upload-files">
                          <h3 className="upload-title">
                            Joindre la liste exhaustive des fichiers
                          </h3>
                          <div className="drop-zone-container">
                            <DropzoneArea
                              onChange={handleFileChange}
                              dropzoneText=""
                              Icon={UploadIcons}
                              acceptedFiles={[
                                'image/jpeg',
                                'image/png',
                                'application/pdf',
                              ]}
                              showPreviews={true}
                              showPreviewsInDropzone={false}
                              showFileNamesInPreview={true}
                              showFileNames={true}
                              maxFileSize={150994944}
                              id={'upload-file'}
                            />
                          </div>
                        </div>
                      </Grid>
                      <input
                        ref={submitRef}
                        type="submit"
                        className="hidden"
                      ></input>
                    </Grid>
                    {estimatedPriceError?.data?.code > 400 && (
                      <Grid item xs={12} className="error-message">
                        {estimatedPriceError.data.message}
                      </Grid>
                    )}
                  </form>
                  {/* {_.get(order, 'type') === ORDER_TYPE.DELIVERY &&
            _.get(shipment, 'selectedSalesChannel.configs.includedServices') ===
              ORDER_TYPE.DELIVERY_ASSEMBLY ? (
              <div className="place-shipment-footer">
                <Button
                  size="medium"
                  color="primary"
                  onClick={() => addAssembly(order.id)}
                >
                  Avec montage
                </Button>
              </div>
            ) : null} */}
                </div>
              </>
            )
          )}
        </>
      )}
      {errorGetSalesChannel && (
        <Error
          statusText="une erreur s'est produite merci d'actualiser la page"
          open={errorGetSalesChannel}
        />
      )}
      {isMissingTarifsConfig && !_.isEqual(shipment.selectedSalesChannel, {}) && (
        <Grid item xs={6}>
          Les configurations de tarifs sont manquantes
        </Grid>
      )}
    </>
  )
}

ShipmentSection.propTypes = {
  order: PropTypes.object.isRequired,
  isSubmited: PropTypes.number.isRequired,
  updateOrderShipment: PropTypes.func.isRequired,
  setShipmentErrors: PropTypes.func.isRequired,
  shipmentSalesChannelList: PropTypes.array.isRequired,
  getGoogleMapDirection: PropTypes.func.isRequired,
  findDirection: PropTypes.func.isRequired,
  isGetDirectionRouteLoading: PropTypes.bool.isRequired,
  directionRoute: PropTypes.object,
  directionRouteList: PropTypes.array.isRequired,
  setOrderShipmentPrice: PropTypes.func.isRequired,
  // addAssembly: PropTypes.func.isRequired,
  updateDirections: PropTypes.func.isRequired,
  operationalHours: PropTypes.array.isRequired,
  operationalHoursLoading: PropTypes.bool.isRequired,
  estimatePrice: PropTypes.func.isRequired,
  estimatedPrice: PropTypes.object,
  estimatedPriceError: PropTypes.object,
  getRecommendedCreneau: PropTypes.func,
  recommendedCreneauList: PropTypes.array,
  getOneWeekShipments: PropTypes.func,
  salesChannelOneWeekShipments: PropTypes.any,
  removeAssembly: PropTypes.func,
  handleSalesChannel: PropTypes.func,
  salesChannel: PropTypes.any,
  removeOrder: PropTypes.func,
  familiesArticleList: PropTypes.array,
  getRulesList: PropTypes.func,
  resetDirections: PropTypes.func,
  addOrder: PropTypes.func,
  getSalesChannels: PropTypes.func,
  getCanalVente: PropTypes.func,
  canalVente: PropTypes.object,
  isGetCanalVenteLoading: PropTypes.bool,
  errorGetSalesChannel: PropTypes.bool,
}
export default ShipmentSection
