import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../ModalDialog'
import _ from 'lodash'

import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import AddressAutoComplete from '../../containers/main/AddressAutoComplete'
import LivraisonClientIcon from '../../assets/icons/detailsLivraison/LivraisonClient'

import { LEVEL_NUMBERS } from '../../constants/appContants'
import useForm from '../../utils/useForm'
import { clientValidators } from '../../pages/main/PlaceOrders/Client/ClientForm/validators'
import { getPhoneInputCountries } from '../../utils/values'
import PhoneInput from 'react-phone-input-2'
import { validateClientDeliveryForm } from '../../utils/validator/LivraisonClientValidator'
import Switch from '../../components/switch/SwitchComponent'
export const clientFormData = () => {
  return {
    firstname: '',
    lastname: '',
    phone: '',
    mail: '',
    observation: '',
    addressQuerry: '',
    selectedAddress: '',
    noEmail: false,
  }
}
function LivraisonClient({
  open,
  handleClose,
  LivraisonClient,
  codeCourse,
  isLoadingActionOrder,
  errorActionCourse,
}) {
  const [destinationDefaultAddress, setdestinationDefaultAddress] = useState(null)

  useEffect(() => {
    if (errorActionCourse) handleClose()
  }, [errorActionCourse])

  const submitForm = () => {
    // console.log('client form submited:', client)
  }
  const onChangeUpdateCourseType = (e) => {
    if (Object.keys(errors).length > 0) {
      handleClientSubmit(e)
    } else {
      let payload = {
        codeCourse: codeCourse,
        type: 'ClientDelivery',
        adresseArrivee: {
          address: client.selectedAddress.formattedAddress,
          latitude: client.selectedAddress.latlng.lat,
          longitude: client.selectedAddress.latlng.lng,
          postalCode: client.selectedAddress.postalCode,
          city: client.selectedAddress.city,
          avecAscenseur: client.destinationIsElevator,
          etage: client.destinationLevel,
        },
        contactArrivee: {
          firstName: client.firstname,
          lastName: client.lastname,
          phone: client.phone,
          mail: client.mail,
          observation: client.observation,
        },
      }
      LivraisonClient(payload)
    }
  }
  const [
    client,
    errors,
    isSubmitted,
    handleClientChange,
    handleClientElChange,
    handleClientSubmit,
    isClientValid,
    isClientInvalid,
    ,
    handleClientSwitchChange,
  ] = useForm(
    clientFormData(),
    submitForm,
    clientValidators,
    validateClientDeliveryForm
  )

  useEffect(() => {
    if (client.mail && client.mail[0] === '+') {
      handleClientElChange('noEmail', true)
    }
  }, [client.mail])
  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Livraison Client'}
      iconTitle={
        <LivraisonClientIcon
          style={{
            width: '20px',
            height: '20px',
            marginRight: '9px',
          }}
        />
      }
      content={
        <div
          style={{
            paddingRight: '16px',
            paddingBottom: '16px',
          }}
        >
          <form
            className={[
              'form-validation place-shipment-form',
              isSubmitted ? 'was-submitted' : '',
              isClientInvalid ? 'is-invalid' : '',
              isClientValid ? 'is-valid' : '',
            ].join(' ')}
            noValidate
            autoComplete="off"
            onSubmit={($event) => handleClientSubmit($event)}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <AddressAutoComplete
                  selectedDefaultAddress={destinationDefaultAddress}
                  handleOnAddressSelect={($event) => {
                    handleClientElChange('selectedAddress', $event)
                    setdestinationDefaultAddress($event)
                  }}
                  handleQueryChange={(val) =>
                    handleClientElChange('addressQuerry', val)
                  }
                  label="Adresse d'arrivée"
                  hasError={
                    isSubmitted > 0 &&
                    (!_.isNil(_.get(errors, 'addressQuerry.required')) ||
                      !_.isNil(_.get(errors, 'selectedAddress.pattern')))
                  }
                  errorText={
                    isSubmitted > 0 &&
                    (_.get(errors, 'addressQuerry.required') ||
                      _.get(errors, 'selectedAddress.pattern'))
                  }
                  id="adresse-LivraisonClient"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl className="dropdown-loader">
                  <TextField
                    id="etage-LivraisonClient"
                    select
                    label="Etage"
                    name="destinationLevel"
                    value={client.destinationLevel || 0}
                    // required
                    onChange={handleClientChange}
                    variant="outlined"
                    SelectProps={{
                      MenuProps: {
                        style: { height: 300 },
                      },
                    }}
                    error={isSubmitted && !_.isNil(_.get(errors, 'level'))}
                    helperText={
                      isSubmitted && !_.isNil(_.get(errors, 'level'))
                        ? _.get(errors, 'level.required')
                        : ''
                    }
                  >
                    {LEVEL_NUMBERS.map((h, index) => (
                      <MenuItem value={h} key={index}>
                        {h}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <div className="switchers-section">
                  <FormControl component="fieldset" className="switcher-container">
                    <FormGroup row>
                      <FormControlLabel
                        className="switcher-label"
                        control={
                          <Switch
                            name="destinationIsElevator"
                            value={client.destinationIsElevator || false}
                            defaultChecked={client.destinationIsElevator || false}
                            onChange={handleClientSwitchChange}
                            id="elavator-LivraisonClient"
                          />
                        }
                        label={'Présence Ascenseur'}
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="Informations-LivraisonClient"
                  value={client.observation}
                  name="observation"
                  onChange={handleClientChange}
                  label="Informations complémentaire "
                  variant="outlined"
                  multiline
                  // required={true}
                  rows={4}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="firstname"
                  name="firstname"
                  label="Prénom"
                  variant="outlined"
                  error={isSubmitted > 0 && !_.isNil(_.get(errors, 'firstname'))}
                  helperText={
                    isSubmitted > 0 && !_.isNil(_.get(errors, 'firstname'))
                      ? _.get(errors, 'firstname.required')
                      : ''
                  }
                  value={client.firstname || ''}
                  onChange={handleClientChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastname"
                  name="lastname"
                  label="Nom"
                  variant="outlined"
                  value={client.lastname}
                  onChange={handleClientChange}
                  error={isSubmitted && !_.isNil(_.get(errors, 'lastname'))}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl component="fieldset">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          name="noEmail"
                          value={client.noEmail || false}
                          defaultChecked={client.noEmail || false}
                          onChange={handleClientSwitchChange}
                          id="switchEmail-LivraisonClient"
                        />
                      }
                      label="Pas d'adresse mail"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="phone">
                  <PhoneInput
                    id="phone-LivraisonClient"
                    onChange={(newValue, country) => {
                      handleClientElChange('phoneCheck', {
                        phone: newValue,
                        countryCode: country.countryCode,
                        dialcode: country.dialCode,
                      })
                      handleClientElChange('phone', '+' + newValue)
                      if (client.noEmail)
                        handleClientElChange('mail', '+' + newValue + '@noemail.com')
                    }}
                    value={client.phone}
                    onlyCountries={getPhoneInputCountries()}
                    country={'fr'}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      country: 'fr',
                    }}
                    isValid={() => {
                      return isSubmitted > 0 && !_.isNil(_.get(errors, 'phone'))
                        ? !_.isNil(_.get(errors, 'phone.required'))
                          ? _.get(errors, 'phone.required')
                          : _.get(errors, 'phone.pattern', null)
                        : true
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email-LivraisonClient"
                  name="mail"
                  label="E-mail"
                  variant="outlined"
                  error={
                    isSubmitted > 0 &&
                    !client.noEmail &&
                    !_.isNil(_.get(errors, 'mail'))
                  }
                  helperText={
                    isSubmitted > 0 &&
                    !client.noEmail &&
                    !_.isNil(_.get(errors, 'mail'))
                      ? !_.isNil(_.get(errors, 'mail.required'))
                        ? _.get(errors, 'mail.required')
                        : _.get(errors, 'mail.pattern', null)
                      : ''
                  }
                  disabled={client.noEmail}
                  value={!client.noEmail ? client.mail || '' : ''}
                  onChange={handleClientChange}
                  required
                />
              </Grid>
            </Grid>
          </form>
        </div>
      }
      handleClose={handleClose}
      handleClickAction={($event) => onChangeUpdateCourseType($event)}
      isLoadingButtonAction={isLoadingActionOrder}
    />
  )
}

LivraisonClient.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  codeCourse: PropTypes.string,
  LivraisonClient: PropTypes.func,
  isLoadingActionOrder: PropTypes.bool,
  errorActionCourse: PropTypes.string,
}
export default LivraisonClient
