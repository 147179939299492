import React from 'react'
import PropTypes from 'prop-types'

import DriversTableRow from './DriversTabRow'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

export default function TableContent({
  drivers,
  columns,
  handleCheck,
  colSpan,
  driversOriginalSelected,
}) {
  const getChecked = (name) => {
    let isChecked = false
    columns.forEach((column) => {
      if (column.name === name) {
        isChecked = column.checked
      }
    })
    return isChecked
  }
  const getCheckedLigne = (driver) => {
    return driversOriginalSelected.some((el) => el.id === driver.id)
  }

  return (
    <>
      {drivers.length > 0 ? (
        <>
          {drivers.map((driver, index) => {
            const { firstname, lastname, phone, vehiculeId, operationalHours, id } =
              driver
            return (
              <DriversTableRow
                key={`table_${index}`}
                firstname={firstname}
                lastname={lastname}
                phone={phone}
                vehicule={vehiculeId}
                operationalHours={operationalHours}
                checked={getCheckedLigne(driver)}
                columns={columns}
                id={id}
                handleCheck={handleCheck}
                getChecked={getChecked}
                index={index}
              />
            )
          })}
        </>
      ) : (
        <>
          <TableRow>
            <TableCell colSpan={colSpan}>
              <div style={{ marginLeft: '45%' }}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ fontWeight: '500', fontSize: '16px' }}
                >
                  Pas de données
                </Typography>
              </div>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  )
}
TableContent.propTypes = {
  drivers: PropTypes.array,
  columns: PropTypes.array,
  handleCheck: PropTypes.func,
  colSpan: PropTypes.number,
  driversOriginalSelected: PropTypes.array,
}
