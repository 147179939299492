import React from 'react'
import PropTypes from 'prop-types'
import IconDateRealisee from '../../../../../../assets/icons/detailsLivraison/iconDateRealisee'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import { CardSyled, TitleWithIcon } from '../card/style'

export default function DateRealiseesLivraison({ order, isLoading }) {
  return (
    <>
      {/* 
            Date Réalisées:
                Date d'attribution : true
                Date d'acceptation : true
                Arrivée au point de collecte : true
                Fin de chargement : true
                Vers livraison: true
                Arrivée à destination : true
                Fin de livraison : true
        */}
      <CardSyled>
        <TitleWithIcon>
          <IconDateRealisee
            style={{ marginRight: '4px', width: '20px', height: '20px' }}
          />{' '}
          Dates Réalisées
        </TitleWithIcon>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '20px',
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Grid container>
            {order?.scheduledAt && (
              <Grid item xs={12} sm={12} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Date de programmation'}{' '}
                </span>
                <span>
                  {order?.scheduledAt
                    ? moment(order.scheduledAt).format('YYYY-MM-DD HH:mm')
                    : ' - '}
                </span>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {"Date d'attribution"}
                </span>
                <span>
                  {order?.assignedAt
                    ? moment(order.assignedAt).format('YYYY-MM-DD HH:mm')
                    : '-'}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {"Date d'acceptation"}
                </span>
                <span>
                  {order?.dateAcceptation
                    ? moment(order.dateAcceptation).format('YYYY-MM-DD HH:mm')
                    : '-'}
                </span>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Arrivée au point de collecte'}
                </span>
                <span>
                  {order?.dateDebutChargement
                    ? moment(order.dateDebutChargement).format('YYYY-MM-DD HH:mm')
                    : '-'}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Fin de chargement'}
                </span>
                <span>
                  {order?.dateFinChargement
                    ? moment(order.dateFinChargement).format('YYYY-MM-DD HH:mm')
                    : '-'}
                </span>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} sx={{ padding: '8px' }}>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  lineHeight: '30px',
                  fontSize: '13px',
                  fontWeight: 600,
                  textDecoration: 'none',
                  color: '#2d2c2c',
                }}
              >
                {'Vers livraison '}
              </span>
              <span>
                {order?.dateVersLivraison
                  ? moment(order.dateVersLivraison).format('YYYY-MM-DD HH:mm')
                  : '-'}
              </span>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Arrivée à destination '}
                </span>
                <span>
                  {order?.dateDechargement
                    ? moment(order.dateDechargement).format('YYYY-MM-DD HH:mm')
                    : ' - '}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Fin de livraison'}
                </span>
                <span>
                  {order?.dateLivraison
                    ? moment(order.dateLivraison).format('YYYY-MM-DD HH:mm')
                    : ' - '}
                </span>
              </Grid>
            </Grid>
            <Grid container>
              {order?.dateRetraitImpossible && (
                <Grid
                  item
                  xs={
                    order?.dateLivraisonImpossible && order?.dateRetraitImpossible
                      ? 6
                      : 12
                  }
                  sm={
                    order?.dateLivraisonImpossible && order?.dateRetraitImpossible
                      ? 6
                      : 12
                  }
                  sx={{ padding: '8px' }}
                >
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      lineHeight: '30px',
                      fontSize: '13px',
                      fontWeight: 600,
                      textDecoration: 'none',
                      color: '#2d2c2c',
                    }}
                  >
                    {'Date retrait impossible'}
                  </span>
                  <span>
                    {order?.dateRetraitImpossible
                      ? moment(order.dateRetraitImpossible).format(
                          'YYYY-MM-DD HH:mm'
                        )
                      : ' - '}
                  </span>
                </Grid>
              )}
              {order?.dateLivraisonImpossible && (
                <Grid
                  item
                  xs={
                    order?.dateLivraisonImpossible && order?.dateRetraitImpossible
                      ? 6
                      : 12
                  }
                  sm={
                    order?.dateLivraisonImpossible && order?.dateRetraitImpossible
                      ? 6
                      : 12
                  }
                  sx={{ padding: '8px' }}
                >
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      lineHeight: '30px',
                      fontSize: '13px',
                      fontWeight: 600,
                      textDecoration: 'none',
                      color: '#2d2c2c',
                    }}
                  >
                    {'Date livraison impossible'}
                  </span>
                  <span>
                    {order?.dateLivraisonImpossible
                      ? moment(order.dateLivraisonImpossible).format(
                          'YYYY-MM-DD HH:mm'
                        )
                      : ' - '}
                  </span>
                </Grid>
              )}
            </Grid>

            {order?.canceledAt && (
              <Grid item xs={12} sm={12} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Date d’annulation'}
                </span>
                <span>
                  {order?.canceledAt
                    ? moment(order.canceledAt).format('YYYY-MM-DD HH:mm')
                    : ' - '}
                </span>
              </Grid>
            )}
          </Grid>
        )}
      </CardSyled>
    </>
  )
}
DateRealiseesLivraison.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
}
