import Tooltip from '@mui/material/Tooltip/Tooltip'
import NotAvailable from './NotAvailable'
import TableCell from '@mui/material/TableCell/TableCell'
import React, { useEffect, useState } from 'react'
import './index.scss'
import PropTypes from 'prop-types'
export default function Address({
  address,
  salesChannel,
  salesChannelAddresses,
  display,
  getColor,
}) {
  const [displayedaddress, setDisplayaddress] = useState(null)
  const [displayedLink, setDisplayLink] = useState(null)
  useEffect(() => {
    if (
      salesChannelAddresses &&
      salesChannelAddresses?.length !== 0 &&
      address &&
      address.address === salesChannelAddresses[0]?.address
    ) {
      setDisplayaddress(salesChannel)
      setDisplayLink({
        latitude: salesChannelAddresses[0].latitude,
        longitude: salesChannelAddresses[0].longitude,
      })
    } else {
      setDisplayaddress(address?.address)
      setDisplayLink({
        latitude: address?.latitude,
        longitude: address?.longitude,
      })
    }
  }, [address, salesChannelAddresses])
  return (
    display && (
      <TableCell>
        {address ? (
          <Tooltip
            arrow
            title={<span className="tootltip-content">{displayedaddress}</span>}
          >
            {displayedLink ? (
              <a
                className="address-text address-link address-overflow"
                style={{ color: getColor(address) }}
                href={`https://www.google.com/maps?q=${displayedLink.latitude},${displayedLink.longitude}&ll=${displayedLink.latitude},${displayedLink.longitude}&z=17`}
              >
                {displayedaddress}
              </a>
            ) : (
              <p className="address-overflow"> {displayedaddress} </p>
            )}
          </Tooltip>
        ) : (
          <NotAvailable />
        )}
      </TableCell>
    )
  )
}
Address.propTypes = {
  address: PropTypes.object,
  salesChannel: PropTypes.string,
  salesChannelAddresses: PropTypes.array,
  display: PropTypes.bool,
  getColor: PropTypes.func,
}
