import React from 'react'
import PropTypes from 'prop-types'

import { getCreneauHoursMinutes } from '../../../../../../utils/utils'

import { ClientDelivery, ClientPickup } from '../../../../../../utils/values'

import moment from 'moment'

import ColumnSelectedCreneauxComponentEditable from './columnSelectedCreneauxComponentEditable'
import ColumnAddressComponentEditable from './columnAddressComponentEditable'
import ColumnTextComponentEditable from './columnTextComponentEditable'
import ColumnTimeRangeComponentEditable from './columnTimeRangeComponentEditable'
import ColumnTextComponent from './columnTextComponent'
import { parseReceptionNumber } from '../../../../../../utils/tours'

const TableRowsContent = ({
  getCourseType,
  editable,
  row,
  rowNumber,
  codeCourse,
  onChangeContentColumn,
  recommendedCreneauList,
  getGoogleMapDirection,
  onDeleteContentColumn,
  columnsList,
  errorsCourseEditable,
  isOptimisationCoursesFiles,
  disabledColumn,
}) => {
  const getChecked = (id) => {
    let isChecked = false
    columnsList.forEach((column) => {
      if (column.id === id) {
        isChecked = column.checked
      }
    })
    return isChecked
  }
  const onChange = (value, extraPram) => {
    onChangeContentColumn(codeCourse, value, extraPram)
  }

  const onDelete = (value) => {
    onDeleteContentColumn(codeCourse, value)
  }
  const dataRowContent = {
    pickup: {
      name: 'Créneau de pickUP',
      content:
        row.pickUpVisit?.windowStart && row.pickUpVisit?.windowEnd
          ? getCreneauHoursMinutes(
              row.pickUpVisit?.windowStart,
              row.pickUpVisit?.windowEnd,
              '~'
            )
          : '-',
      contentEditable: {
        date: moment(row.pickUpVisit?.windowStart).format('YYYY-MM-DD'),
        heureStart: moment(row.pickUpVisit?.windowStart).format('HH:00'),
        heureEnd: moment(row.pickUpVisit?.windowEnd).format('HH:00'),
      },
      show: getChecked('pickup'),
      listes: recommendedCreneauList,
    },
    dropoff: {
      name: 'Créneau de dropOff',
      content:
        row.dropOffVisit?.windowStart && row.dropOffVisit?.windowEnd
          ? getCreneauHoursMinutes(
              row.dropOffVisit?.windowStart,
              row.dropOffVisit?.windowEnd,
              '~'
            )
          : '-',
      contentEditable: {
        date: moment(row.dropOffVisit?.windowStart).format('YYYY-MM-DD'),
        heureStart: moment(row.dropOffVisit?.windowStart).format('HH:00'),
      },
      show: getChecked('dropoff'),
      listes: recommendedCreneauList,
    },
    courseType: {
      name: 'Type de course',
      contentCourseType: row.dropOffVisit?.courseType,
      content: row.dropOffVisit?.courseType
        ? getCourseType(row.dropOffVisit?.courseType)
        : '-',
      show: getChecked('courseType'),
    },
    endAddress: {
      name: "Adresse d'arrivée",
      content: {
        longitude: row.dropOffVisit.lng,
        latitude: row.dropOffVisit.lat,
        address: row.dropOffVisit?.location_name
          ? row.dropOffVisit?.location_name
          : '-',
      },
      show: getChecked('endAddress'),
      adressDepart: {
        longitude: row.pickUpVisit.lng,
        latitude: row.pickUpVisit.lat,
        address: row.pickUpVisit.location_name,
      },
    },
    volume: {
      name: 'Volume',
      content:
        row.dropOffVisit?.volume >= 0 && row.dropOffVisit?.volume != null
          ? row.dropOffVisit?.volume + ''
          : '-',
      show: getChecked('volume'),
    },
    weight: {
      name: 'Poids',
      content:
        row.dropOffVisit?.weight >= 0 && row.dropOffVisit?.weight != null
          ? row.dropOffVisit?.weight + ''
          : '-',
      show: getChecked('weight'),
    },
    price: {
      name: 'Prix',
      content:
        row.dropOffVisit?.montantPrestaHT >= 0 &&
        row.dropOffVisit?.montantPrestaHT != null
          ? row.dropOffVisit?.montantPrestaHT
          : '-',
      show: getChecked('price'),
    },
    options: {
      name: 'Options',
      content: row.dropOffVisit?.options ? row.dropOffVisit?.options : '-',
      show: getChecked('options'),
    },
    prestation: {
      name: 'Prestation',
      content: row.dropOffVisit?.prestation ? row.dropOffVisit?.prestation : '-',
      show: getChecked('prestation'),
    },
    reason: {
      name: 'Raison',
      content: row.dropOffVisit?.reason ? row.dropOffVisit?.reason : '-',
      show: getChecked('reason'),
    },
    ldv: {
      name: 'LDV',
      content: row.dropOffVisit?.ldv ? row.dropOffVisit?.ldv : '-',
      show: getChecked('ldv'),
    },
    salesChannel: {
      name: 'Canal Vente',
      content: row.dropOffVisit?.salesChanel?.name
        ? row.dropOffVisit?.salesChanel.name
        : '-',
      show: getChecked('salesChannel'),
    },
    receptionNumber: {
      name: 'receptionNumber',
      content: row.dropOffVisit?.location_id
        ? parseReceptionNumber(row.dropOffVisit?.location_id)
        : '-',
      show: getChecked('receptionNumber'),
    },
    type: {
      name: 'type',
      content: row.dropOffVisit?.courseType ? row.dropOffVisit?.courseType : '-',
      show: getChecked('type'),
    },
    creneauArivee1: {
      name: 'Créneau de dropOff',
      content:
        row.dropOffVisit?.windowStart && row.dropOffVisit?.windowEnd
          ? getCreneauHoursMinutes(
              row.dropOffVisit?.windowStart,
              row.dropOffVisit?.windowEnd,
              '~'
            )
          : '-- : --',
      contentEditable: {
        date: moment(row?.pickUpVisit?.windowStart).format('YYYY-MM-DD'),
        heureStart: row.dropOffVisit?.windowStart
          ? moment(row.dropOffVisit?.windowStart).format('HH:00')
          : null,
        heureEnd: row.dropOffVisit?.windowEnd
          ? moment(row.dropOffVisit?.windowEnd).format('HH:00')
          : null,
      },
      show: getChecked('dropoff'),
    },
    creneauArivee2: {
      name: 'Créneau de dropOff',
      content:
        row.dropOffVisit?.secondWindowStart && row.dropOffVisit?.secondWindowEnd
          ? getCreneauHoursMinutes(
              row.dropOffVisit?.secondWindowStart,
              row.dropOffVisit?.secondWindowEnd,
              '~'
            )
          : '-- : --',
      contentEditable: {
        date: moment(row?.pickUpVisit?.windowStart).format('YYYY-MM-DD'),
        heureStart: row.dropOffVisit?.secondWindowStart
          ? moment(row.dropOffVisit?.secondWindowStart).format('HH:00')
          : null,
        heureEnd: row.dropOffVisit?.secondWindowEnd
          ? moment(row.dropOffVisit?.secondWindowEnd).format('HH:00')
          : null,
      },
      show: getChecked('dropoff'),
    },
    nbColis: {
      name: 'nbColis',
      content:
        row.dropOffVisit?.nbColis >= 0 && row.dropOffVisit?.nbColis != null
          ? row.dropOffVisit?.nbColis
          : '-',
      show: getChecked('nbColis'),
    },
    prestaTime: {
      name: 'tempsDechargement',
      content:
        row.dropOffVisit?.prestaTime >= 0 && row.dropOffVisit?.prestaTime != null
          ? row.dropOffVisit?.prestaTime
          : '-',
      show: getChecked('prestaTime'),
    },
    codePostale: {
      name: 'codePostale',
      content: row.dropOffVisit?.codePostale ? row.dropOffVisit?.codePostale : '-',
      show: getChecked('codePostale'),
    },
    keys: {
      name: 'keys',
      content:
        row.dropOffVisit?.keys && Array.isArray(row.dropOffVisit.keys)
          ? row.dropOffVisit?.keys.join(', ')
          : '-',
      show: getChecked('keys'),
    },
    client: {
      name: 'client',
      content: row.dropOffVisit?.client ? row.dropOffVisit?.client : '-',
      show: getChecked('client'),
    },
    orderGiver: {
      name: 'orderGiver',
      content: row.dropOffVisit?.orderGiver ? row.dropOffVisit?.orderGiver : '-',
      show: getChecked('orderGiver'),
    },
  }
  const rowContent = {
    index: (
      <ColumnTextComponent
        content={rowNumber + 1}
        id={`item-Courses-${rowNumber}-options`}
      />
    ),
    pickup: (
      <ColumnSelectedCreneauxComponentEditable
        content={dataRowContent['pickup'].content}
        contentEditable={dataRowContent['pickup'].contentEditable}
        isClientDelivery={ClientDelivery.includes(
          dataRowContent['courseType'].contentCourseType
        )}
        id={`item-Courses-${rowNumber}-pickup`}
        editable={
          editable &&
          ClientPickup.includes(dataRowContent['courseType'].contentCourseType)
        }
        onChange={onChange}
        listes={dataRowContent['pickup'].listes}
        onDelete={onDelete}
        nameAttribut={'pickup'}
      />
    ),
    dropoff: (
      <ColumnSelectedCreneauxComponentEditable
        content={dataRowContent['dropoff'].content}
        contentEditable={dataRowContent['dropoff'].contentEditable}
        isClientDelivery={ClientDelivery.includes(
          dataRowContent['courseType'].contentCourseType
        )}
        id={`item-Courses-${rowNumber}-dropoff`}
        editable={
          editable &&
          ClientDelivery.includes(dataRowContent['courseType'].contentCourseType)
        }
        onChange={onChange}
        listes={dataRowContent['dropoff'].listes}
        onDelete={onDelete}
        nameAttribut={'dropoff'}
      />
    ),
    courseType: (
      <ColumnTextComponent
        content={dataRowContent['courseType'].content}
        id={`item-Courses-${rowNumber}-courseType`}
        disabledColumn={disabledColumn}
      />
    ),
    endAddress: (
      <ColumnAddressComponentEditable
        content={dataRowContent['endAddress'].content}
        id={`item-Courses-${rowNumber}-endAddress`}
        editable={editable}
        onChange={onChange}
        nameAttribut={'adresseArrivee'}
        adressAut={dataRowContent['endAddress'].adressDepart}
        getGoogleMapDirection={getGoogleMapDirection}
        onDelete={onDelete}
        errorsCourseEditable={errorsCourseEditable}
        isOptimisationCoursesFiles={isOptimisationCoursesFiles}
        disabledColumn={disabledColumn}
      />
    ),
    volume: (
      <ColumnTextComponentEditable
        content={dataRowContent['volume'].content}
        id={`item-Courses-${rowNumber}-volume`}
        editable={editable}
        onChange={onChange}
        nameAttribut={'volume'}
        onDelete={onDelete}
        errorsCourseEditable={errorsCourseEditable}
        disabledColumn={disabledColumn}
      />
    ),
    weight: (
      <ColumnTextComponentEditable
        content={dataRowContent['weight'].content}
        id={`item-Courses-${rowNumber}-weight`}
        editable={editable}
        onChange={onChange}
        nameAttribut={'weight'}
        onDelete={onDelete}
        errorsCourseEditable={errorsCourseEditable}
        disabledColumn={disabledColumn}
      />
    ),
    price: isOptimisationCoursesFiles ? (
      <ColumnTextComponentEditable
        content={dataRowContent['price'].content}
        id={`item-Courses-${rowNumber}-price`}
        editable={editable}
        onChange={onChange}
        nameAttribut={'price'}
        onDelete={onDelete}
        errorsCourseEditable={errorsCourseEditable}
        disabledColumn={disabledColumn}
      />
    ) : (
      <ColumnTextComponent
        content={dataRowContent['price'].content}
        id={`item-Courses-${rowNumber}-price`}
      />
    ),
    options: (
      <ColumnTextComponent
        content={dataRowContent['options'].content}
        id={`item-Courses-${rowNumber}-options`}
      />
    ),
    prestation: (
      <ColumnTextComponent
        content={dataRowContent['prestation'].content}
        id={`item-Courses-${rowNumber}-prestation`}
      />
    ),
    reason: (
      <ColumnTextComponent
        content={dataRowContent['reason'].content}
        id={`item-Courses-${rowNumber}-reason`}
      />
    ),
    ldv: (
      <ColumnTextComponent
        content={dataRowContent['ldv'].content}
        id={`item-Courses-${rowNumber}-ldv`}
      />
    ),
    salesChannel: (
      <ColumnTextComponent
        content={dataRowContent['salesChannel'].content}
        id={`item-Courses-${rowNumber}-salesChannel`}
      />
    ),
    receptionNumber: (
      <ColumnTextComponent
        content={dataRowContent['receptionNumber'].content}
        id={`item-Courses-${rowNumber}-receptionNumber`}
        disabledColumn={disabledColumn}
      />
    ),
    type: (
      <ColumnTextComponent
        content={dataRowContent['type'].content}
        id={`item-Courses-${rowNumber}-type`}
        disabledColumn={disabledColumn}
      />
    ),
    creneauArivee1: (
      <ColumnTimeRangeComponentEditable
        content={dataRowContent['creneauArivee1'].content}
        contentEditable={dataRowContent['creneauArivee1'].contentEditable}
        id={`item-Courses-${rowNumber}-creneauArivee1`}
        editable={editable}
        onChange={onChange}
        onDelete={onDelete}
        nameAttribut={'creneauArivee1'}
        errorsCourseEditable={errorsCourseEditable}
      />
    ),
    creneauArivee2: (
      <ColumnTimeRangeComponentEditable
        content={dataRowContent['creneauArivee2'].content}
        contentEditable={dataRowContent['creneauArivee2'].contentEditable}
        id={`item-Courses-${rowNumber}-creneauArivee2`}
        editable={editable}
        onChange={onChange}
        onDelete={onDelete}
        nameAttribut={'creneauArivee2'}
        errorsCourseEditable={errorsCourseEditable}
      />
    ),
    nbColis: (
      <ColumnTextComponentEditable
        content={dataRowContent['nbColis'].content}
        id={`item-Courses-${rowNumber}-nbColis`}
        editable={editable}
        onChange={onChange}
        nameAttribut={'count'}
        onDelete={onDelete}
        errorsCourseEditable={errorsCourseEditable}
        disabledColumn={disabledColumn}
      />
    ),
    prestaTime: (
      <ColumnTextComponentEditable
        content={dataRowContent['prestaTime'].content}
        id={`item-Courses-${rowNumber}-prestaTime`}
        editable={editable}
        onChange={onChange}
        nameAttribut={'prestaTime'}
        onDelete={onDelete}
        errorsCourseEditable={errorsCourseEditable}
        disabledColumn={disabledColumn}
      />
    ),
    codePostale: (
      <ColumnTextComponent
        content={dataRowContent['codePostale'].content}
        id={`item-Courses-${rowNumber}-codePostale`}
        disabledColumn={disabledColumn}
      />
    ),
    keys: (
      <ColumnTextComponent
        content={dataRowContent['keys'].content}
        id={`item-Courses-${rowNumber}-keys`}
        disabledColumn={disabledColumn}
      />
    ),
    client: (
      <ColumnTextComponent
        content={dataRowContent['client'].content}
        id={`item-Courses-${rowNumber}-client`}
        disabledColumn={disabledColumn}
      />
    ),
    orderGiver: (
      <ColumnTextComponent
        content={dataRowContent['orderGiver'].content}
        id={`item-Courses-${rowNumber}-orderGiver`}
        disabledColumn={disabledColumn}
      />
    ),
  }
  return (
    <>
      {columnsList.map((el) => {
        return rowContent[el.id]
      })}
    </>
  )
}
TableRowsContent.propTypes = {
  row: PropTypes.object,
  getCourseType: PropTypes.func,
  editable: PropTypes.bool,
  rowNumber: PropTypes.number,
  codeCourse: PropTypes.string,
  onChangeContentColumn: PropTypes.func,
  recommendedCreneauList: PropTypes.array,
  getGoogleMapDirection: PropTypes.func,
  onDeleteContentColumn: PropTypes.func,
  columnsList: PropTypes.array,
  errorsCourseEditable: PropTypes.array,
  isOptimisationCoursesFiles: PropTypes.bool,
  disabledColumn: PropTypes.bool,
}

export default TableRowsContent
