import { insetObjectTable } from '../../utils/utils'
import {
  GET_TOURNEES,
  GET_TOURNEES_SUCCESS,
  GET_TOURNEES_FAILURE,
  PAGE_CHANGED_TOURNEES,
  PAGE_LIMIT_CHANGED_TOURNEES,
  REMOVE_ERROR_TOURNEES,
  RESET_SUCCESS_ACTION_TOURNEES,
  RESET_CONFIG_TOURNEES,
  SEARCH_TOURNEES,
  ADD_TOURNEE,
  ADD_TOURNEE_FAILURE,
  ADD_TOURNEE_SUCCESS,
  UPDATE_TOURNEE,
  UPDATE_TOURNEE_SUCCESS,
  UPDATE_TOURNEE_FAILURE,
} from './constants'
import initialState from './initialState'

export default (state = initialState(), action) => {
  if (action.type === GET_TOURNEES) {
    return {
      ...state,
      tourneesList: [],
      isLoadingTourneesList: true,
    }
  }

  if (action.type === GET_TOURNEES_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      tourneesList: data,
      isLoadingTourneesList: false,
      tourneesListCount: count,
    }
  }

  if (action.type === GET_TOURNEES_FAILURE) {
    const { response } = action.payload?.data

    return {
      ...state,
      isLoadingTourneesList: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetch: true,
    }
  }

  if (action.type === PAGE_CHANGED_TOURNEES) {
    const { offset } = action.payload
    const filter = { ...state.filter }
    filter.offset = offset
    return {
      ...state,
      filter,
    }
  }

  if (action.type === PAGE_LIMIT_CHANGED_TOURNEES) {
    const { limit } = action.payload
    const filter = { ...state.filter }
    filter.limit = limit
    filter.offset = 0
    return {
      ...state,
      filter,
    }
  }

  if (action.type === REMOVE_ERROR_TOURNEES) {
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
    }
  }

  if (action.type === RESET_SUCCESS_ACTION_TOURNEES) {
    return {
      ...state,
      success: false,
      isSuccessRefresh: false,
    }
  }

  if (action.type === RESET_CONFIG_TOURNEES) {
    //rest
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
      isLoadingTourneeAction: false,
      success: false,
      isSuccessRefresh: false,

      filter: {
        limit: 25,
        offset: 0,
      },
    }
  }

  if (action.type === SEARCH_TOURNEES) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        ...state.filter,
        query: search,
      },
    }
  }

  if (action.type === ADD_TOURNEE) {
    return {
      ...state,
      isLoadingTourneeAction: true,
    }
  }
  if (action.type === ADD_TOURNEE_SUCCESS) {
    return {
      ...state,
      isLoadingTourneeAction: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === ADD_TOURNEE_FAILURE) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingTourneeAction: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === UPDATE_TOURNEE) {
    return {
      ...state,
      isLoadingTourneeAction: true,
    }
  }
  if (action.type === UPDATE_TOURNEE_SUCCESS) {
    const { data } = action.payload
    const tourneeIndex = state.tourneesList.map((el) => el.id).indexOf(data.id)

    return {
      ...state,
      tourneesList: [...insetObjectTable(state.tourneesList, data, tourneeIndex)],
      isLoadingTourneeAction: false,
      success: true,
    }
  }

  if (action.type === UPDATE_TOURNEE_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingTourneeAction: false,
      success: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  return state
}
