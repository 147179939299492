import _ from 'lodash'
import { validatorContants } from './constants'
export const validateAssembly = (values, elements) => {
  let errors = {}

  // validate selectedSalesChannel if exist in elements to validate
  if (!_.isNil(_.get(elements, 'selectedSalesChannel'))) {
    // validate required  if required exist in elements.selectedSalesChannel
    if (
      elements.selectedSalesChannel.find(
        (el) => el === validatorContants.REQUIRED
      ) &&
      _.isEmpty(_.get(values, 'selectedSalesChannel'))
    ) {
      errors['selectedSalesChannel'] = {
        ...errors['selectedSalesChannel'],
        [validatorContants.REQUIRED]: 'Canal de vente est obligatoire!',
      }
    }

    // validate pattern if pattern exist in elements.selectedSalesChannel
    if (
      elements.selectedSalesChannel.find((el) => el === validatorContants.PATTERN) &&
      _.isNil(_.get(values, 'selectedSalesChannel.configs'))
    ) {
      errors['selectedSalesChannel'] = {
        ...errors['selectedSalesChannel'],
        [validatorContants.PATTERN]: 'Canal de vente est invalide!',
      }
    }
  }
  if (!_.isNil(_.get(elements, 'category'))) {
    // validate required  if required exist in elements.category
    if (
      (_.isNil(_.get(values, 'category')) ||
        _.isEqual(_.get(values, 'category'), '')) &&
      elements.category.find((el) => el === validatorContants.REQUIRED)
    ) {
      errors['category'] = {
        ...errors['category'],
        [validatorContants.REQUIRED]: 'La catégorie est obligatoire!',
      }
    }
  }
  if (!_.isNil(_.get(elements, 'observation'))) {
    // validate required  if required exist in elements.companySelectedAddress
    if (
      (_.isNil(_.get(values, 'observation')) ||
        _.isEqual(_.get(values, 'observation'), '')) &&
      elements.observation.find((el) => el === validatorContants.REQUIRED)
    ) {
      errors['observation'] = {
        ...errors['observation'],
        [validatorContants.REQUIRED]:
          'Une Information pour le monteur est obligatoire!',
      }
    }
  }
  if (!_.isNil(_.get(elements, 'fileUploaded'))) {
    // validate required  if required exist in elements.companySelectedAddress
    if (
      (_.isNil(_.get(values, 'fileUploaded')) ||
        _.isEqual(_.get(values, 'fileUploaded'), false)) &&
      elements.observation.find((el) => el === validatorContants.REQUIRED)
    ) {
      errors['fileUploaded'] = {
        ...errors['fileUploaded'],
        [validatorContants.REQUIRED]: 'Un upload de fichier est obligatoire!',
      }
    }
  }

  // validate selectedAddress if exist in elements to validate
  if (
    !_.isNil(_.get(elements, 'selectedAddress')) &&
    !_.isNil(_.get(elements, 'queryaddress'))
  ) {
    // validate required  if required exist in elements.selectedAddress

    if (
      _.isNil(_.get(values, 'selectedAddress')) &&
      elements.selectedAddress.find((el) => el === validatorContants.REQUIRED)
    ) {
      errors['selectedAddress'] = {
        ...errors['selectedAddress'],
        [validatorContants.REQUIRED]: 'Adresse de montage est obligatoire!',
      }
    }

    // validate pattern  if pattern exist in elements.selectedAddress
    if (
      elements.selectedAddress.find((el) => el === validatorContants.PATTERN) &&
      (_.isNil(_.get(values, 'selectedAddress.formattedAddress')) ||
        _.isNil(_.get(values, 'selectedAddress.latlng.lat')) ||
        _.isNil(_.get(values, 'selectedAddress.latlng.lng')))
    ) {
      errors['selectedAddress'] = {
        ...errors['selectedAddress'],
        [validatorContants.PATTERN]: 'Adresse de montage est invalide!',
      }
    }
    if (
      !_.get(values, 'queryaddress.length') &&
      elements.queryaddress.find((el) => el === validatorContants.REQUIRED)
    ) {
      errors['selectedAddress'] = {
        ...errors['selectedAddress'],
        [validatorContants.REQUIRED]: 'Adresse de montage est obligatoire!',
      }
    }
    if (
      _.get(values, 'queryaddress') !=
      _.get(values, 'selectedDestination.formattedAddress')
    ) {
      errors['selectedAddress'] = {
        ...errors['selectedAddress'],
        [validatorContants.PATTERN]: 'Adresse de montage est invalide!',
      }
    }
  }

  // // validate queryAddress if exist in elements to validate
  // if () {
  //   // validate required  if required exist in elements.queryAddress

  // }

  if (_.isNil(_.get(values, 'date'))) {
    errors['date'] = {
      ...errors['date'],
      [validatorContants.REQUIRED]: 'Date est obligatoire!',
    }
  }
  if (!_.isNil(_.get(values, 'date')) && !(_.get(values, 'date') instanceof Date)) {
    errors['date'] = {
      ...errors['date'],
      [validatorContants.PATTERN]: 'date est invalide!',
    }
  }

  if (_.isNil(_.get(values, 'time'))) {
    errors['time'] = {
      ...errors['time'],
      [validatorContants.REQUIRED]: 'Heure est obligatoire!',
    }
  }
  if (
    !_.isNil(_.get(values, 'time')) &&
    (_.isNil(_.get(values, 'time.start')) || _.isNil(_.get(values, 'time.fullHr')))
  ) {
    errors['time'] = {
      ...errors['time'],
      [validatorContants.PATTERN]: 'Heure est invalide!',
    }
  }

  // validate times if exist in elements to validate
  if (!_.isNil(_.get(elements, 'times'))) {
    if (!_.get(values, 'times.length')) {
      errors['times'] = {
        ...errors['times'],
        [validatorContants.REQUIRED]: 'Aucune heure disponible!',
      }
    }
  }

  // validate purchaseAmount if exist in elements to validate
  if (!_.isNil(_.get(elements, 'purchaseAmount'))) {
    // validate required  if required exist in elements.purchaseAmount
    if (
      elements.purchaseAmount.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'purchaseAmount')
    ) {
      errors['purchaseAmount'] = {
        ...errors['purchaseAmount'],
        [validatorContants.REQUIRED]: "montant d'achat est obligatoire!",
      }
    }

    // validate pattern  if pattern exist in elements.purchaseAmount
    if (
      elements.purchaseAmount.find((el) => el === validatorContants.PATTERN) &&
      !_.isNil(_.get(values, 'purchaseAmount')) &&
      !_.isNumber(parseFloat(_.get(values, 'purchaseAmount')))
    ) {
      errors['purchaseAmount'] = {
        ...errors['purchaseAmount'],
        [validatorContants.PATTERN]: "montant d'achat est invalide!",
      }
    }

    // validate pattern  if pattern exist in elements.purchaseAmount
    if (
      elements.purchaseAmount.find((el) => el.code === validatorContants.MIN) &&
      !_.isNil(_.get(values, 'purchaseAmount'))
    ) {
      const minValidator = elements.purchaseAmount.find(
        (el) => el.code === validatorContants.MIN
      )
      if (
        minValidator &&
        parseFloat(_.get(values, 'purchaseAmount')) < minValidator.value
      ) {
        errors['purchaseAmount'] = {
          ...errors['purchaseAmount'],
          [validatorContants.MIN]: `montant d'achat min est ${minValidator.value}`,
        }
      }
    }
  }

  if (!_.isNil(_.get(elements, 'clientPrice'))) {
    // validate required  if required exist in elements.clientPrice
    if (
      elements.clientPrice.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'clientPrice')
    ) {
      errors['clientPrice'] = {
        ...errors['clientPrice'],
        [validatorContants.REQUIRED]: 'le prix est obligatoire!',
      }
    }

    // validate pattern  if pattern exist in elements.clientPrice

    if (
      elements.clientPrice.find((el) => el.code === validatorContants.MIN) &&
      !_.isNil(_.get(values, 'clientPrice'))
    ) {
      const minValidator = elements.clientPrice.find(
        (el) => el.code === validatorContants.MIN
      )
      if (
        minValidator &&
        parseFloat(_.get(values, 'clientPrice')) < minValidator.value &&
        _.get(values, 'selectedSalesChannel.code') === 'B2H_SAV'
      ) {
        errors['clientPrice'] = {
          ...errors['clientPrice'],
          [validatorContants.PATTERN]: `Le prix minimal est ${minValidator.value}`,
        }
      }
    }
  }
  return errors
}
