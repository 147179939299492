import React from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types'

export default function CheckBoxList({ item, handleChange, index }) {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={item.checked}
            onChange={(e) => handleChange(e, index)}
            name={item.name}
          />
        }
        label={item.label}
      />
    </FormGroup>
  )
}
CheckBoxList.propTypes = {
  item: PropTypes.object,
  handleChange: PropTypes.func,
  index: PropTypes.number,
}
