import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import ActionButton from './actionButton'

import DialogHistorique from '../../../../../../containers/main/DetailsDelivery/Colis/historiquePackage'
import DialogPointageCourse from '../../../../../../containers/main/DetailsDelivery/Colis/pointagePackage'
import { useParams } from 'react-router-dom'
import AllInboxSharpIcon from '@mui/icons-material/AllInboxSharp'
import DialogProduits from '../../../../../../containers/main/DetailsDelivery/Colis/detailsProduits'
import DialogForcePointage from '../dialog/detailsColis/dialogForcePointage'
import Error from '../../../../../../components/Modal/HttpErrorPopUp'
import TableDetailsColis from './tabDetailsColis'

import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

import DialogLivraisonPartielle from '../dialog/detailsColis/dialogLivraisonPartielle'
import Dialog from '../../../../../../components/ModalDialog'
import DialogEditPackage from '../dialog/detailsColis/dialogEditPackage'

import { CardSyled, TitleWithIcon } from '../card/style'
import {
  ClientDelivery,
  COURSE_SOURCE_SCHMIDT,
  DONE_STATUS,
} from '../../../../../../utils/values'
import { COURSE_SOURCE_EDIT_PACKAGE } from '../../../../../../utils/checkActionCourseSource'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
  paddingRight: '7px',
  paddingLeft: '7px',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    '& .MuiSvgIcon-root': {
      color: 'black',
      fontSize: '22px',
    },
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0px',
  marginTop: '8px',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const headCells = [
  { id: 'barCode', label: 'BarCode' },
  {
    id: 'label',
    label: 'Label',
  },
  { id: 'weight', label: 'Poids (kg)' },
  { id: 'sku', label: 'Type' },

  { id: 'produit', label: 'Produit(s)' },
  { id: 'historique', label: 'Historique' },
]

const headCellsWithEtat = [
  { id: 'barCode', label: 'BarCode' },
  { id: 'etat', label: 'Etat de colis' },
  {
    id: 'label',
    label: 'Label',
  },
  { id: 'weight', label: 'Poids (kg)' },
  { id: 'sku', label: 'Type' },
  { id: 'produit', label: 'Produit(s)' },
  { id: 'historique', label: 'Historique' },
]

const TablePackages = ({
  //Order
  order,
  isLoading,
  //Packages
  getOrderPackage,
  isLoadingPackageOrder,
  packageOrder,
  errorPackage,
  courseSource,
  //Pointage
  isLoadingPointagePackage,
  isActionPointagePackageDone,
  isActionPointagePackageErorr,
  onPointageForcePackage,
  isLoadingPointageForcePackage,
  isActionPointageForcePackageDone,
  //Litige upload image Error
  setErrorUploadImageLitige,
  errorImageLitigeUpload,
  deleteImageLitigePackages,
  //Livraison Partielle
  getEtatPackage,
  isLoadingEtatPackage,
  isVisibiliteEtatPackage,
  isVisibiliteEtatPackageDone,
  annulerLivraisonPartielle,
  isAnnulationLivraisonPartielleDone,
  onCreationCourseLivraisonPartielle,
  isLoadingLivraisonPartielle,
  //edit Package
  onEditPackage,
  isLoadingEditPackage,
  isActionEditPackageDone,
}) => {
  const theme = useTheme()
  const [selected, setSelected] = useState([])
  const [openDialogHistorique, setOpenDialogHistorique] = useState(false)
  const [barCodeLitige, setBarCode] = useState(null)

  const [paramsInit, setParams] = useState(null)

  const [openDiagPointage, setOpenDiagPointage] = useState(false)
  const [openDiagProduits, setOpenDiagProduits] = useState(false)
  const [idPackage, setIdPackage] = useState(null)
  const [openDiagForcePointage, setOpenDiagForcePointage] = useState(false)
  const [msgPointage, setMsgPointage] = useState('')
  const [payloadForcage, setPayloadForcage] = useState(null)
  const [headTable, setHeadTable] = useState(headCells)
  const [verifErrorPackage, setVerifErrorPackage] = useState(false)
  const [packageForceCode, setcodePackageForce] = useState('')
  const [errorUploadFile, setErrorUploadFile] = useState(null)

  const [openDiagEditePackage, setOpenDiagEditePackage] = useState(false)
  const [payloadEditePackage, setPayloadEditePackage] = useState(null)

  const CURRENT_USER = 'currentUser'
  const [connectedUser] = useState(JSON.parse(localStorage.getItem(CURRENT_USER)))
  const params = useParams()
  //Get List Package && PackageStatus
  useEffect(() => {
    if (order?.code) {
      getOrderPackage(params.ldv)
      setParams(params)
    }
  }, [order?.code])

  useEffect(() => {
    if (paramsInit && paramsInit.ldv != params.ldv && order?.code) {
      setParams(params)
      getOrderPackage(params.ldv)
    }
  }, [params])
  //Actions Tableau
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = packageOrder.map((n) => n.code)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, code) => {
    const selectedIndex = selected.indexOf(code)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, code)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = (code) => selected.indexOf(code) !== -1

  //Action Pointage
  useEffect(() => {
    if (isActionPointagePackageDone) {
      setOpenDiagPointage(false)
      setSelected([])
    }
  }, [isActionPointagePackageDone])

  //Set Head Table
  useEffect(() => {
    let Tablehead = [...headCells]
    if (COURSE_SOURCE_EDIT_PACKAGE.includes(courseSource)) {
      Tablehead = Tablehead.concat([{ id: 'Edit', label: '' }])
    }
    if (isVisibiliteEtatPackage) {
      Tablehead = [...headCellsWithEtat]
    } else {
      const verifErrorPackageOrder = packageOrder.some((el) => el.error)
      setVerifErrorPackage(verifErrorPackageOrder)
      if (verifErrorPackageOrder) {
        setOpenDiagPointage(false)
        setSelected([])
        if (Tablehead.indexOf((el) => el.id === 'warrning') == -1)
          Tablehead = Tablehead.concat([{ id: 'warrning', label: '' }])
      }
    }
    setHeadTable(Tablehead)
  }, [packageOrder, isVisibiliteEtatPackage])

  //Pointage avec Force
  const onPointForce = ({ paylodForce, barcode, code }) => {
    setcodePackageForce(code)
    setSelected([])
    const packagesPayload = {
      code,
      barcode,
      deliveryStep: paylodForce.deliveryStep,
      status: paylodForce.status,
      flashedAt: paylodForce.flashedAt,
      idCollaborateur: connectedUser.id,
      source: 'Corner',
      acceptSameStep: 1,
      addressBarCode: paylodForce.addressBarCode,
      litigation: paylodForce.litigation,
      pictureBeforePacking: paylodForce.pictureBeforePacking,
      pictureAfterPacking: paylodForce.pictureAfterPacking,
    }

    onPointageForcePackage({ packages: [packagesPayload] })
  }
  useEffect(() => {
    if (isActionPointageForcePackageDone) {
      setOpenDiagForcePointage(false)
      setcodePackageForce('')
    }
  }, [isActionPointageForcePackageDone])

  //Litige
  const onErrorClose = () => {
    setErrorUploadImageLitige(false)
    setErrorUploadFile(null)
    deleteImageLitigePackages()
  }
  //Accordion
  const [expanded, setExpanded] = useState(false)
  const handleChange = () => {
    setExpanded(!expanded)
  }

  //Livraison Partielle

  const [
    openDialogLivraisonPartielleAvertissement,
    setOpenDialogLivraisonPartielleAvertissement,
  ] = useState(false)

  useEffect(() => {
    if (isVisibiliteEtatPackageDone) {
      const newSelected = packageOrder
        .filter(({ etatColisLivree }) => !etatColisLivree)
        .map(({ code }) => code)
      setSelected(newSelected)

      if (newSelected.length == 0) setOpenDialogLivraisonPartielleAvertissement(true)
    }
  }, [isVisibiliteEtatPackageDone])

  useEffect(() => {
    if (isAnnulationLivraisonPartielleDone) {
      setHeadTable(headCells)
      setSelected([])
    }
  }, [isAnnulationLivraisonPartielleDone])

  const [openDialogLivraisonPartielle, setOpenDialogLivraisonPartielle] =
    useState(false)

  const onLivraisonPartielle = () => {
    const payload = {
      code: params.ldv,
      packages: packageOrder
        .filter((pack) => selected.includes(pack.code))
        .map(({ code }) => {
          return {
            code,
          }
        }),
    }
    onCreationCourseLivraisonPartielle(payload)
  }

  useEffect(() => {
    if (errorPackage) {
      setOpenDialogLivraisonPartielle(false)
    }
  }, [errorPackage])

  useEffect(() => {
    if (isActionEditPackageDone) {
      setOpenDiagEditePackage(false)
      setPayloadEditePackage(null)
    }
  }, [isActionEditPackageDone])

  return (
    <>
      {(errorUploadFile?.isError || errorImageLitigeUpload || errorPackage) && (
        <Error
          statusText={
            errorUploadFile?.message ||
            errorPackage?.statusText ||
            "une erreur s'est produite merci d'actualiser la page"
          }
          open={errorUploadFile?.isError || errorImageLitigeUpload || errorPackage}
          setOpen={onErrorClose}
        />
      )}
      <CardSyled>
        <Accordion expanded={expanded} onChange={handleChange}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <TitleWithIcon>
              <AllInboxSharpIcon
                style={{ marginRight: '4px', width: '20px', height: '20px' }}
              />{' '}
              {`Détails des colis (${packageOrder?.length})`}
            </TitleWithIcon>

            {expanded && (
              <ActionButton
                onPointe={(event) => {
                  setOpenDiagPointage(true)
                  event.stopPropagation()
                }}
                enabledButtonPointage={
                  !(
                    selected.length == 0 ||
                    isLoadingPointagePackage ||
                    isVisibiliteEtatPackageDone
                  )
                }
                isLoadingPointagePackage={isLoadingPointagePackage}
                getEtatPackage={(event) => {
                  getEtatPackage(params.ldv)
                  event.stopPropagation()
                }}
                enabledButtonlivraisonPartielle={
                  !(
                    (selected.length > 0 && !isVisibiliteEtatPackageDone) ||
                    isVisibiliteEtatPackageDone ||
                    !ClientDelivery.includes(order.courseMetadata.courseType) ||
                    order.courseSource !== COURSE_SOURCE_SCHMIDT ||
                    order.status.code !== DONE_STATUS.code ||
                    packageOrder.length == 0 ||
                    isLoadingPackageOrder
                  )
                }
                isLoadingEtatPackage={isLoadingEtatPackage}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            {isLoadingPackageOrder || isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <CircularProgress
                  style={{
                    color: theme.palette.primary.main,
                  }}
                />
              </div>
            ) : (
              <TableDetailsColis
                packageOrder={packageOrder}
                isLoadingPointagePackage={isLoadingPointagePackage}
                isActionPointagePackageErorr={isActionPointagePackageErorr}
                isLoadingPointageForcePackage={isLoadingPointageForcePackage}
                headTable={headTable}
                withChecked={true}
                setOpenDialogHistorique={setOpenDialogHistorique}
                setIdPackage={setIdPackage}
                setBarCode={setBarCode}
                verifErrorPackage={verifErrorPackage}
                packageForceCode={packageForceCode}
                setOpenDiagForcePointage={setOpenDiagForcePointage}
                setMsgPointage={setMsgPointage}
                setPayloadForcage={setPayloadForcage}
                setOpenDiagProduits={setOpenDiagProduits}
                selected={selected}
                handleSelectAllClick={handleSelectAllClick}
                handleClick={handleClick}
                isSelected={isSelected}
                isVisibiliteEtatPackage={isVisibiliteEtatPackage}
                isLoadingEtatPackage={isLoadingEtatPackage}
                isVisibiliteEtatPackageDone={isVisibiliteEtatPackageDone}
                setOpenDiagEditePackage={setOpenDiagEditePackage}
                setPayloadEditePackage={setPayloadEditePackage}
                isLoadingEditPackage={isLoadingEditPackage}
              />
            )}

            {isVisibiliteEtatPackageDone && selected.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingTop: '18px',
                  paddingRight: '7px',
                  paddingBottom: '10px',
                }}
              >
                <Button
                  id={'button-annuler'}
                  variant={'contained'}
                  onClick={annulerLivraisonPartielle}
                  sx={{
                    width: '120px',
                    marginLeft: '15px',
                    marginRight: '12px',
                    textTransform: 'none ',
                    fontSize: '13px ',
                    fontWeight: '400 ',
                    backgroundColor: '#f61057',
                    color: 'white',
                    ':hover': {
                      backgroundColor: '#d32f2f',
                    },
                  }}
                >
                  Annuler
                </Button>
                <Button
                  id={'button-confirmer'}
                  variant={'contained'}
                  // disabled={disabled}
                  sx={{
                    width: '120px',
                    textTransform: 'none ',
                    fontSize: '13px ',
                    fontWeight: '400 ',
                    backgroundColor: '#10A549',
                    ':hover': {
                      backgroundColor: '#158f44',
                    },
                  }}
                  onClick={() => setOpenDialogLivraisonPartielle(true)}
                >
                  Confirmer
                </Button>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      </CardSyled>

      {openDialogLivraisonPartielleAvertissement && (
        <Dialog
          maxWidthDialog={'sm'}
          open={openDialogLivraisonPartielleAvertissement}
          title={'Avertissement'}
          content={
            <div
              style={{ width: '400px', paddingBottom: '16px', paddingRight: '16px' }}
            >
              {'Tous les colis sont déjà Livrés  avec succès.'}
            </div>
          }
          handleClose={() => {
            annulerLivraisonPartielle()
            setOpenDialogLivraisonPartielleAvertissement(false)
          }}
          isModalActionAnnuler={true}
        />
      )}
      {openDialogLivraisonPartielle && (
        <DialogLivraisonPartielle
          open={openDialogLivraisonPartielle}
          handleClose={() => {
            annulerLivraisonPartielle()
            setOpenDialogLivraisonPartielle(false)
          }}
          onCreationCourseLivraisonPartielle={onLivraisonPartielle}
          isLoadingLivraisonPartielle={isLoadingLivraisonPartielle}
        />
      )}
      {openDiagProduits && (
        <DialogProduits
          idPackage={idPackage}
          open={openDiagProduits}
          onClose={() => {
            setOpenDiagProduits(false)
            setIdPackage(null)
          }}
        />
      )}

      {openDialogHistorique && (
        <DialogHistorique
          idPackage={idPackage}
          barCodeLitige={barCodeLitige}
          open={openDialogHistorique}
          onClose={() => {
            setOpenDialogHistorique(false)
            setIdPackage(null)
            setBarCode(null)
          }}
        />
      )}
      {openDiagPointage && (
        <DialogPointageCourse
          open={openDiagPointage}
          handleClose={() => {
            setOpenDiagPointage(false)
          }}
          isLoadingPointagePackage={isLoadingPointagePackage}
          packageSelected={packageOrder
            .filter((pack) => selected.includes(pack.code))
            .map(({ barcode, code }) => {
              return {
                barcode,
                code,
                litigation: {
                  comment: '',
                  motif: 'null',
                  responsible: 'null',
                  pictures: [],
                },
                pictureBeforePacking: [],
                pictureAfterPacking: [],
              }
            })}
          courseSource={order.courseSource}
          courseType={order.courseMetadata.courseType}
          setErrorUploadFile={setErrorUploadFile}
          connectedUser={connectedUser}
        />
      )}

      {openDiagForcePointage && (
        <DialogForcePointage
          open={openDiagForcePointage}
          onClose={() => {
            setOpenDiagForcePointage(false)
            setMsgPointage('')
            setPayloadForcage(null)
          }}
          messageErreur={msgPointage}
          payloadForcage={payloadForcage}
          onForcePointage={onPointForce}
          isLoadingPointageForcePackage={isLoadingPointageForcePackage}
        />
      )}
      {openDiagEditePackage && (
        <DialogEditPackage
          open={openDiagEditePackage}
          onEditPackage={onEditPackage}
          onClose={() => {
            setOpenDiagEditePackage(false)
            setPayloadEditePackage(null)
          }}
          payloadEditePackage={payloadEditePackage}
          isLoadingEditPackage={isLoadingEditPackage}
          errorMessage={errorPackage}
        />
      )}
    </>
  )
}

TablePackages.propTypes = {
  order: PropTypes.object,
  packageOrder: PropTypes.array,
  getOrderPackage: PropTypes.func,
  isLoadingPackageOrder: PropTypes.bool,
  isLoading: PropTypes.bool,
  isActionPointagePackageDone: PropTypes.bool,
  isLoadingPointagePackage: PropTypes.bool,
  isActionPointagePackageErorr: PropTypes.bool,
  onPointageForcePackage: PropTypes.func,
  isLoadingPointageForcePackage: PropTypes.bool,
  isActionPointageForcePackageDone: PropTypes.bool,
  setErrorUploadImageLitige: PropTypes.func,
  errorPackage: PropTypes.object,
  errorImageLitigeUpload: PropTypes.bool,
  deleteImageLitigePackages: PropTypes.func,
  getEtatPackage: PropTypes.func,
  isLoadingEtatPackage: PropTypes.bool,
  isVisibiliteEtatPackage: PropTypes.bool,
  isVisibiliteEtatPackageDone: PropTypes.bool,
  annulerLivraisonPartielle: PropTypes.func,
  isAnnulationLivraisonPartielleDone: PropTypes.bool,
  onCreationCourseLivraisonPartielle: PropTypes.func,
  isLoadingLivraisonPartielle: PropTypes.bool,
  courseSource: PropTypes.string,
  isLoadingEditPackage: PropTypes.bool,
  onEditPackage: PropTypes.func,
  isActionEditPackageDone: PropTypes.bool,
}
export default TablePackages
