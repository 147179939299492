import { connect } from 'react-redux'
import ValidationSection from '../../../pages/main/PlaceOrders/Validation'

import {
  getShipmentSalesChannelList,
  getAssemblySalesChannelList,
} from '../../../redux/filter/selectors'

import {
  getOrdersState,
  getStepsState,
  getActiveStepState,
  getClientState,
  getTotalPriceState,
  getPaymentForm,
  getDevis,
  isLoading,
} from '../../../redux/shipment/selectors'

import {
  addOrderAction,
  submitFirstStepAction,
  setActiveStepAction,
  submitSecondStepAction,
  initOrderAction,
  setCommand,
} from '../../../redux/shipment/actions'

import {
  getSalesChannelList,
  getIsGetSalesChannelListLoading,
} from '../../../redux/filter/selectors'

import { getSalesChannelListRequest } from '../../../redux/filter/actions'

const mapStateToProps = (state) => ({
  shipmentSalesChannelList: getShipmentSalesChannelList(state),
  assemblySalesChannelList: getAssemblySalesChannelList(state),
  orders: getOrdersState(state),
  steps: getStepsState(state),
  activeStep: getActiveStepState(state),
  client: getClientState(state),
  isGetSalesChannelListLoading: getIsGetSalesChannelListLoading(state),
  salesChannelList: getSalesChannelList(state),
  paymentForm: getPaymentForm(state),
  totalPrice: getTotalPriceState(state),
  devis: getDevis(state),
  isLoading: isLoading(state),
})

const mapDisptachToProps = (dispatch) => ({
  addOrder: (type) => dispatch(addOrderAction(type)),
  submitFirstStep: () => dispatch(submitFirstStepAction()),
  setActiveStep: (step) => dispatch(setActiveStepAction(step)),
  submitSecondStep: () => dispatch(submitSecondStepAction()),
  getSalesChannelList: () => dispatch(getSalesChannelListRequest()),
  initOrder: () => dispatch(initOrderAction()),
  setCommand: (command) => dispatch(setCommand(command)),
})

export default connect(mapStateToProps, mapDisptachToProps)(ValidationSection)
