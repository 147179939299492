import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { replaceDotWithComma } from '../../../../../utils/floatFormatting'

import RoomIcon from '@mui/icons-material/Room'
import EuroIcon from '@mui/icons-material/Euro'
import VolumeIcon from '../../../../../assets/icons/volume.svg'
import PoidsLivraisonIcon from '../../../../../assets/icons/polaris/PoidsLivraison.svg'
import PoidsEnlevementIcon from '../../../../../assets/icons/polaris/PoidsEnlevement.svg'
import { ClientDelivery, ClientPickup } from '../../../../../utils/values'
import TempsPrestationIcon from '../../../../../assets/icons/polaris/TempsPrestation'
import moment from 'moment'

const initialValue = {
  weight: 0,
  volume: 0,
  number: 0,
  price: 0,
  tourDropoffWeight: 0,
  tourPickupWeight: 0,
  tempsPrestation: 0,
}
const SelectedOrdersRecap = ({ unservedTours, isOptimisationCoursesFiles }) => {
  const [dataCounters, setDataCounters] = useState(initialValue)

  useEffect(() => {
    let accumulation = initialValue
    if (Object.keys(unservedTours).length > 0) {
      Object.keys(unservedTours)
        .filter((id) => unservedTours[id].dropOffVisit.checked)
        .forEach((id) => {
          accumulation = {
            tempsPrestation:
              accumulation.tempsPrestation +
                unservedTours[id].dropOffVisit.prestaTime || 0,
            weight: accumulation.weight + unservedTours[id].dropOffVisit.weight || 0,
            volume: accumulation.volume + unservedTours[id].dropOffVisit.volume || 0,
            price:
              accumulation.price + unservedTours[id].dropOffVisit.montantPrestaHT ||
              0,
            tourPickupWeight:
              unservedTours[id].dropOffVisit.courseType == 'Livraison' ||
              ClientDelivery.includes(unservedTours[id].dropOffVisit.courseType)
                ? accumulation.tourPickupWeight +
                  (unservedTours[id].pickUpVisit?.weight || 0)
                : accumulation.tourPickupWeight,
            tourDropoffWeight:
              unservedTours[id].dropOffVisit.courseType == 'Reprise' ||
              ClientPickup.includes(unservedTours[id].dropOffVisit.courseType)
                ? accumulation.tourDropoffWeight +
                  (unservedTours[id].dropOffVisit.weight || 0)
                : accumulation.tourDropoffWeight,
          }
        })
    }

    accumulation = {
      number: Object.keys(unservedTours).filter(
        (id) => unservedTours[id].dropOffVisit.checked
      ).length,
      volumeToDisplay: getDisplayValue(accumulation.volume),
      weightToDisplay: getDisplayValue(accumulation.weight),
      priceToDisplay: getDisplayValue(accumulation.price),
      tourDropoffWeight: getDisplayValue(accumulation.tourDropoffWeight),
      tourPickupWeight: getDisplayValue(accumulation.tourPickupWeight),
      tempsPrestation: moment
        .utc(
          moment.duration(accumulation.tempsPrestation, 'minutes').asMilliseconds()
        )
        .format('HH:mm'),
    }
    setDataCounters(accumulation)
  }, [unservedTours])

  const getDisplayValue = (value) =>
    value === 0 ? 0 : replaceDotWithComma(parseFloat(value).toFixed(2))
  return (
    <>
      <div
        style={{
          overflowX: 'auto',
          minHeight: 'auto',
          position: 'absolute',
          top: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 15px',
          zIndex: 9,
          backgroundColor: '#ffff',
        }}
      >
        <CellItem
          icon={
            <RoomIcon
              style={{ color: '#7f7f7f', fontSize: '2rem', marginRight: '5px' }}
            />
          }
          content={dataCounters.number}
          unit={'Sélectionnées'}
        />
        <CellItem
          icon={
            <img
              src={PoidsEnlevementIcon}
              alt="Poids Livraison"
              style={{
                color: '#7f7f7f',
                width: '30px',
                height: '25px',
                marginRight: '5px',
                opacity: '0.5',
              }}
            />
          }
          content={dataCounters.tourPickupWeight}
          unit={'Kg'}
        />
        <CellItem
          icon={
            <img
              src={PoidsLivraisonIcon}
              alt="Poids Enlèvement"
              style={{
                color: '#7f7f7f',
                width: '30px',
                height: '25px',
                marginRight: '5px',
                opacity: '0.5',
              }}
            />
          }
          content={dataCounters.tourDropoffWeight}
          unit={'Kg'}
        />
        {isOptimisationCoursesFiles && (
          <CellItem
            icon={
              <TempsPrestationIcon
                style={{
                  color: '#7f7f7f',
                  width: '25px',
                  height: '22px',
                  marginRight: '5px',
                  marginTop: '2px',
                }}
              />
            }
            content={dataCounters.tempsPrestation}
            unit={''}
          />
        )}

        <CellItem
          icon={
            <img
              src={VolumeIcon}
              alt="total volume"
              style={{
                color: '#7f7f7f',
                height: '25px',
                width: '23px',
                marginRight: '5px',
              }}
            />
          }
          content={dataCounters.volumeToDisplay}
          unit={'m³'}
        />
        <CellItem
          icon={
            <EuroIcon
              style={{ color: '#7f7f7f', fontSize: '2rem', marginRight: '5px' }}
            />
          }
          content={dataCounters.priceToDisplay}
          unit={'Ht'}
        />
      </div>
    </>
  )
}
SelectedOrdersRecap.propTypes = {
  unservedTours: PropTypes.object,
  isOptimisationCoursesFiles: PropTypes.bool,
}

const CellItem = ({ icon, content, unit }) => (
  <div style={{ display: 'flex', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
    {icon}
    <span
      style={{
        fontSize: '18px',
        color: '#7f7f7f',
        marginTop: '2px',
        fontWeight: 'bold',
      }}
    >
      {content} {unit}
    </span>
  </div>
)

CellItem.propTypes = {
  icon: PropTypes.any,
  content: PropTypes.any,
  unit: PropTypes.string,
}

export default SelectedOrdersRecap
