import { connect } from 'react-redux'

import AssignDialog from '../../../components/ShipmentAction/AttributionWarning'
import {
  getDriversOptimiserIsLoading,
  getDrivers,
  getDriversOptimiserCount,
} from '../../../redux/driver/selectors'

import { assignDriver } from '../../../redux/order/actions'
import {
  fetchDriversWarrning,
  fetchDriversWarrningMoreScroll,
} from '../../../redux/driver/actions'
import {
  getErrorActionCourse,
  getIsLoadingActionOrder,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  drivers: getDrivers(state),
  isLoadingDrivers: getDriversOptimiserIsLoading(state),
  driversCount: getDriversOptimiserCount(state),
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  errorActionCourse: getErrorActionCourse(state),
})

const mapDisptachToProps = (dispatch) => ({
  assignDriver: (data) => dispatch(assignDriver(data)),
  fetchDrivers: (filter) => dispatch(fetchDriversWarrning(filter)),
  fetchDriversMore: (filter) => dispatch(fetchDriversWarrningMoreScroll(filter)),
})

export default connect(mapStateToProps, mapDisptachToProps)(AssignDialog)
