import React, { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import PropTypes from 'prop-types'

const Prestation = ({
  salesChannelPrest,
  handleChange,
  selectedPrestation,
  disabled,
}) => {
  const [prestations, setPrestations] = useState([])
  useEffect(() => {
    if (salesChannelPrest.length > 0) {
      const filteredPresta = salesChannelPrest.map(({ id, label, prestation }) => {
        return { id, label, prestation }
      })
      setPrestations(filteredPresta)
    }
  }, [salesChannelPrest])
  return (
    <FormControl variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">
        Sélectionnez une prestation (facultatif)
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="prestation"
        name="prestation"
        value={
          // selectedPrestation
          //   ? typeof selectedPrestation === 'number'
          //     ? selectedPrestation
          //     : selectedPrestation.id
          //   : null
          selectedPrestation ? selectedPrestation : null
        }
        onChange={($event) => {
          handleChange('selectPresCanalService', $event.target.value)
          const prestationSelect = prestations.filter(
            (pres) => pres.id == $event.target.value
          )
          handleChange('prestation', prestationSelect[0].prestation.id)
        }}
        label="Sélectionnez une prestation (facultatif)"
        disabled={disabled}
      >
        {prestations.map((pres) => (
          <MenuItem value={pres.id} key={pres.id}>
            {pres.label || '___'}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
Prestation.propTypes = {
  salesChannelPrest: PropTypes.array.isRequired,
  handleChange: PropTypes.func,
  selectedPrestation: PropTypes.any,
  disabled: PropTypes.bool,
}
export default Prestation
