import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '../../../../components/switch/SwitchComponent'
import FormControl from '@mui/material/FormControl'

export default function StatusDrivers({ filterStateChanged }) {
  const [checked, setChecked] = useState(true)

  const handleChange = () => {
    setChecked(!checked)
    filterStateChanged('statusDrivers', !checked)
  }
  return (
    <FormControl component="fieldset">
      <FormGroup row style={{ justifyContent: 'flex-end ' }}>
        <FormControlLabel
          control={
            <Switch
              name="vip"
              value={checked}
              defaultChecked={checked}
              onChange={handleChange}
            />
          }
          label={'Actif'}
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  )
}
StatusDrivers.propTypes = {
  filterStateChanged: PropTypes.func,
  isLoading: PropTypes.bool,
}
