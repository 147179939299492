export const etatColor = [
  {
    etat: 'CANCELED',
    label: 'Annulée',
    color: '#B91515',
    backgroundColor: '#f3d6d6',
  },
  {
    etat: 'ON_GOING',
    label: 'En Cours',
    color: '#4B89BF',
    backgroundColor: '#dfeaf4',
  },
  {
    etat: 'ON_HOLD',
    label: 'En Attente',
    color: '#FF9300',
    backgroundColor: '#ffecd2',
  },
  {
    etat: 'DONE',
    label: 'Terminée',
    color: '#2A7917',
    backgroundColor: '#d9e7d6',
  },
]
