import { connect } from 'react-redux'

import {
  getErrorMessage,
  getSuccess,
  getisLoadingFormationAction,
} from '../../../redux/formations/selectors'
import { archiverFormation } from '../../../redux/formations/actions'
import VerificationModal from '../../../pages/settings/components/Modals/VerificationModal'
const mapStateToProps = (state) => ({
  isLoading: getisLoadingFormationAction(state),
  success: getSuccess(state),
  errorMessage: getErrorMessage(state),
})

const mapDisptachToProps = (dispatch) => ({
  verificationActon: (payload) => dispatch(archiverFormation(payload)),
})

export default connect(mapStateToProps, mapDisptachToProps)(VerificationModal)
