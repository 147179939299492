import { insetObjectTable } from '../../utils/utils'
import { getfullNameMaj } from '../../utils/values'
import {
  GET_PARTICIPANTS,
  GET_PARTICIPANTS_SUCCESS,
  GET_PARTICIPANTS_FAILURE,
  ROLE_DRIVER_FILTRE_CHANGED,
  PAGE_CHANGED,
  PAGE_LIMIT_CHANGED,
  REMOVE_ERROR,
  RESET_SUCCESS_ACTION,
  RESET_CONFIG,
  SEARCH_PARTICIPANTS,
  ADD_PARTICIPANT,
  ADD_PARTICIPANT_FAILURE,
  ADD_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT,
  UPDATE_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT_FAILURE,
  GET_PARTICIPANTS_LIST_MORE,
  GET_PARTICIPANTS_LIST_MORE_SUCCESS,
  GET_PARTICIPANTS_LIST_MORE_FAILURE,
  GET_PARTICIPANTS_CUSTOMIZED,
  GET_PARTICIPANTS_CUSTOMIZED_SUCCESS,
  GET_PARTICIPANTS_CUSTOMIZED_MORE_SUCCESS,
  GET_PARTICIPANTS_CUSTOMIZED_FAILURE,
  GET_PARTICIPANTS_CUSTOMIZED_MORE_FAILURE,
  RESET_PARTICIPANTS_LIST,
  CHANGE_PARTICIPANTS_STATUS,
  CHANGE_PARTICIPANTS_STATUS_SUCCESS,
  CHANGE_PARTICIPANTS_STATUS_FAILURE,
  SET_ISPARTICIPANT_FITRE_CHANGED,
  ANNULATION_PARTICIPATION,
  ANNULATION_PARTICIPATION_SUCCESS,
  ANNULATION_PARTICIPATION_FAILURE,
} from './constants'
import initialState from './initialState'

export default (state = initialState(), action) => {
  if (action.type === GET_PARTICIPANTS) {
    return {
      ...state,
      participants: [],
      isLoadingParticipantsList: true,
    }
  }

  if (action.type === GET_PARTICIPANTS_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      participants: data,
      isLoadingParticipantsList: false,
      participantsCount: count,
    }
  }

  if (action.type === GET_PARTICIPANTS_FAILURE) {
    const { response } = action.payload?.data

    return {
      ...state,
      isLoadingParticipantsList: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetch: true,
    }
  }

  if (action.type === GET_PARTICIPANTS_LIST_MORE) {
    return {
      ...state,
    }
  }
  if (action.type === GET_PARTICIPANTS_LIST_MORE_SUCCESS) {
    return {
      ...state,
      participants: [...state.participants, ...action.payload?.data?.data],
      participantsCount: action.payload.data?.count || 0,
    }
  }

  if (action.type === GET_PARTICIPANTS_LIST_MORE_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === ROLE_DRIVER_FILTRE_CHANGED) {
    const filter = { ...state.filter }
    filter.type = 'DRIVER'
    return {
      ...state,
      filter,
    }
  }

  if (action.type === PAGE_CHANGED) {
    const { offset } = action.payload
    const filter = { ...state.filter }
    filter.offset = offset
    return {
      ...state,
      filter,
    }
  }

  if (action.type === PAGE_LIMIT_CHANGED) {
    const { limit } = action.payload
    const filter = { ...state.filter }
    filter.limit = limit
    filter.offset = 0
    return {
      ...state,
      filter,
    }
  }

  if (action.type === REMOVE_ERROR) {
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
    }
  }

  if (action.type === RESET_SUCCESS_ACTION) {
    return {
      ...state,
      success: false,
      isSuccessRefresh: false,
    }
  }

  if (action.type === RESET_CONFIG) {
    //rest
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
      isLoadingParticipantAction: false,
      success: false,
      isSuccessRefresh: false,

      filter: {
        limit: 25,
        offset: 0,
        actif: true,
      },
    }
  }

  if (action.type === SEARCH_PARTICIPANTS) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        ...state.filter,
        actif: true,
        query: search,
      },
    }
  }

  if (action.type === ADD_PARTICIPANT) {
    return {
      ...state,
      isLoadingParticipantAction: true,
    }
  }
  if (action.type === ADD_PARTICIPANT_SUCCESS) {
    return {
      ...state,
      isLoadingParticipantAction: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === ADD_PARTICIPANT_FAILURE) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingParticipantAction: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === UPDATE_PARTICIPANT) {
    return {
      ...state,
      isLoadingParticipantAction: true,
    }
  }
  if (action.type === UPDATE_PARTICIPANT_SUCCESS) {
    const { data } = action.payload
    const participantIndex = state.participants.map((el) => el.id).indexOf(data.id)

    return {
      ...state,
      participants: [
        ...insetObjectTable(state.participants, data, participantIndex),
      ],
      isLoadingParticipantAction: false,
      success: true,
    }
  }

  if (action.type === UPDATE_PARTICIPANT_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingParticipantAction: false,
      success: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === GET_PARTICIPANTS_CUSTOMIZED) {
    return {
      ...state,
      participantsList: [],
      participantsListCount: 0,
      isLoadingParticipants: true,
    }
  }

  if (action.type === GET_PARTICIPANTS_CUSTOMIZED_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      participantsList: data.map((participant) => {
        return {
          id: participant.id,
          label: getfullNameMaj(participant, '-'),
        }
      }),
      participantsListCount: count,
      isLoadingParticipants: false,
    }
  }

  if (action.type === GET_PARTICIPANTS_CUSTOMIZED_MORE_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      participantsList: [
        ...state.participantsList,
        ...data.map((participant) => {
          return {
            id: participant.legacy_id,
            label: getfullNameMaj(participant, '-'),
          }
        }),
      ],
      participantsListCount: count,
      isLoadingParticipants: false,
    }
  }

  if (
    action.type === GET_PARTICIPANTS_CUSTOMIZED_FAILURE ||
    action.type === GET_PARTICIPANTS_CUSTOMIZED_MORE_FAILURE
  ) {
    return {
      ...state,
      isLoadingParticipants: false,
    }
  }

  if (action.type === RESET_PARTICIPANTS_LIST) {
    return {
      ...state,
      participantsList: [],
      participantsListCount: 0,
      isLoadingParticipants: false,
    }
  }

  if (action.type === CHANGE_PARTICIPANTS_STATUS) {
    return {
      ...state,
      isLoadingParticipantAction: true,
    }
  }
  if (action.type === CHANGE_PARTICIPANTS_STATUS_SUCCESS) {
    return {
      ...state,
      isLoadingParticipantAction: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === CHANGE_PARTICIPANTS_STATUS_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingParticipantAction: false,
      success: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === SET_ISPARTICIPANT_FITRE_CHANGED) {
    return {
      ...state,
      filter: {
        ...state.filter,
        isParticipant: true,
      },
    }
  }

  if (action.type === ANNULATION_PARTICIPATION) {
    return {
      ...state,
      isLoadingParticipantAction: true,
    }
  }
  if (action.type === ANNULATION_PARTICIPATION_SUCCESS) {
    return {
      ...state,

      isLoadingParticipantAction: false,
      isSuccessRefresh: true,
      success: true,
    }
  }

  if (action.type === ANNULATION_PARTICIPATION_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingParticipantAction: false,
      success: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  return state
}
