import React from 'react'
import PropTypes from 'prop-types'

import { Route, Switch } from 'react-router-dom'
import NavBar from '../../containers/settings/NavBar'
import Sidebar from '../../components/Sidebar'

import { settingsRoutes } from '../data/configRoutes'
import NotFound from '../../containers/common/PageNotFound'
import { routeCanAccess } from '../../utils/roleManagement'

const basePath = '/settings'

const SettingsRoutes = ({ userRole }) => (
  <>
    <div className="navbar-container">
      <NavBar />
    </div>
    <div className="sidebar-container" style={{ background: '#ffff' }}>
      <Sidebar
        userRole={userRole}
        component={
          <Switch>
            {routeCanAccess(settingsRoutes, userRole).map((route, index) => (
              <Route
                key={index}
                path={basePath + route.path}
                exact
                component={route.component}
              />
            ))}
            {/*When indicationg a route without path,
      it will always be rendered, so we may use it for non valid urls */}
            <Route component={NotFound} />
          </Switch>
        }
      />
    </div>
  </>
)

SettingsRoutes.propTypes = {
  userRole: PropTypes.string,
}

export default SettingsRoutes
