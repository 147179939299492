import {
  HTTP_FETCH,
  HTTP_POST,
  HTTP_POST_CALLBACK_DATA,
  httpError,
  httpSuccess,
  HTTP_PATCH,
  HTTP_PUT,
} from './actions'
import { httpConstants } from './constants'
import { axiosHTTP } from '../../utils'

export default (store) => (next) => (action) => {
  if (action.type === HTTP_POST) {
    const { endPoint, data, headers, success, failure, cancelToken } = action.payload
    axiosHTTP
      .post(endPoint, data, { ...headers, timeout: 30 * 60 * 1000, cancelToken })
      .then(({ data }) => store.dispatch(httpSuccess(success, data)))
      .catch((err) => {
        if (!axiosHTTP.isCancel(err)) {
          store.dispatch(httpError(failure, err))
        }
      })
  }
  if (action.type === HTTP_POST_CALLBACK_DATA) {
    const { endPoint, data, headers, success, failure, secData, cancelToken } =
      action.payload
    axiosHTTP
      .post(endPoint, data, { ...headers, cancelToken })
      .then(({ data }) => {
        store.dispatch(
          httpSuccess(
            success,
            Object.assign(Array.isArray(data) ? { data } : data, { secData })
          )
        )
      })
      .catch((err) => {
        if (!axiosHTTP.isCancel(err)) {
          store.dispatch(httpError(failure, err))
        }
      })
  }

  if (action.type === HTTP_FETCH) {
    const { endPoint, success, failure, cancelToken } = action.payload
    axiosHTTP
      .get(endPoint, { cancelToken })
      .then(({ data }) => {
        store.dispatch(httpSuccess(success, data))
      })
      .catch((err) => {
        if (!axiosHTTP.isCancel(err)) {
          store.dispatch(httpError(failure, err))
        }
      })
  }

  // Axios HTTP Get REQUEST
  if (action.type === httpConstants.GET_REQUEST) {
    const { endPoint, success, failure, cancelToken } = action.payload
    axiosHTTP
      .get(endPoint, { cancelToken })
      .then(({ data }) => {
        store.dispatch(httpSuccess(success, data))
      })
      .catch((err) => {
        if (!axiosHTTP.isCancel(err)) {
          store.dispatch(httpError(failure, err))
        }
      })
  }

  if (action.type === httpConstants.DELETE_REQUEST) {
    const { endPoint, success, failure, cancelToken } = action.payload
    axiosHTTP
      .delete(endPoint, { cancelToken })
      .then(({ data }) => {
        store.dispatch(httpSuccess(success, data))
      })
      .catch((err) => {
        if (!axiosHTTP.isCancel(err)) {
          store.dispatch(httpError(failure, err))
        }
      })
  }

  if (action.type === HTTP_PATCH) {
    const { endPoint, data, headers, success, failure, cancelToken } = action.payload
    axiosHTTP
      .patch(endPoint, data, { ...headers, timeout: 30 * 60 * 1000, cancelToken })
      .then(({ data }) => store.dispatch(httpSuccess(success, data)))
      .catch((err) => {
        if (!axiosHTTP.isCancel(err)) {
          store.dispatch(httpError(failure, err))
        }
      })
  }
  if (action.type === HTTP_PUT) {
    const { endPoint, data, headers, success, failure, cancelToken } = action.payload
    axiosHTTP
      .put(endPoint, data, { ...headers, timeout: 30 * 60 * 1000, cancelToken })
      .then(({ data }) => store.dispatch(httpSuccess(success, data)))
      .catch((err) => {
        if (!axiosHTTP.isCancel(err)) {
          store.dispatch(httpError(failure, err))
        }
      })
  }
  next(action)
}
