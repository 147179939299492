import { connect } from 'react-redux'
import NavBar from '../../../components/NavBar/main'
import { logout } from '../../../redux/user/actions'
import { getCollaborateur, getUserRole } from '../../../redux/user/selectors'
import { getNotificationConversationAction } from '../../../redux/discussion/conversations/action'
import {
  getNotificationSocket,
  registerSocket,
  socketDisconnect,
} from '../../../redux/discussion/socket/action'
import {
  getsocketNotRegistred,
  getNotifications,
  getNbNotif,
} from '../../../redux/discussion/conversations/selectors'
const mapStateToProps = (state) => ({
  issocketNotRegistred: getsocketNotRegistred(state),
  collaborateur: getCollaborateur(state),
  notification: getNotifications(state),
  nbNotif: getNbNotif(state),
  userRole: getUserRole(state),
})
const mapDisptachToProps = (dispatch) => ({
  registerSocket: () => dispatch(registerSocket()),
  getNotificationConversationAction: (id) =>
    dispatch(getNotificationConversationAction(id)),
  getNotificationSocket: () => dispatch(getNotificationSocket()),
  userLogout: () => {
    dispatch(socketDisconnect())
    dispatch(logout())
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(NavBar)
