import { connect } from 'react-redux'
import RecapSection from '../../../pages/main/EditOrders/Recap'

import {
  getOrdersState,
  getActiveStepState,
  getDirectionsState,
} from '../../../redux/shipment/selectors'

const mapStateToProps = (state) => ({
  order: getOrdersState(state)[0],
  activeStep: getActiveStepState(state),
  directions: getDirectionsState(state),
})

export default connect(mapStateToProps)(RecapSection)
