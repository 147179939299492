import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'
import FormHelperTextError from '../../../../../components/FormHelperTextError'
import useForm from '../../../../../utils/useForm'
import { clientFormData } from './formClient'
import { clientValidators } from './validators'
import { validateClient } from '../../../../../utils/validator/clientValidator'
import './index.scss'
import { getfullName, getPhoneInputCountries } from '../../../../../utils/values'
import Switch from '../../../../../components/switch/SwitchComponent'

export default function EditClientForm({
  selectedClient,
  newClient,
  updateClient,
  isSubmited,
  setClientErrors,
  oldClient,
  courseEditable,
  courseSemiEdit,
  coursePartialEdit,
}) {
  const clientData = !_.isNil(_.get(oldClient, 'id')) ? _.get(oldClient) : {}

  const submitRefs = useRef()

  const submitForm = (e) => {
    console.log('client form submited:', e)
  }
  const [enableMail, setEnableMail] = useState(false)
  const [
    client,
    errors,
    isSubmitted,
    handleClientChange,
    handleClientElChange,
    handleClientSubmit,
    isClientValid,
    isClientInvalid,
    resetForm,
    handleClientSwitchChange,
  ] = useForm(
    clientFormData(clientData),
    submitForm,
    clientValidators,
    validateClient
  )

  useEffect(() => {
    updateClient(client)
  }, [])

  useEffect(() => {
    if (client.mail && client.mail[0] === '+') {
      handleClientElChange('noEmail', true)
      handleClientElChange('envoiMail', false)
    }
  }, [client.mail])

  useEffect(() => {
    const changeClientTimeOut = setTimeout(() => {
      updateClient(client)
    }, 100)
    return () => clearTimeout(changeClientTimeOut)
  }, [client])

  useEffect(() => {
    const changesErrorsTimeOut = setTimeout(() => {
      setClientErrors(errors)
    }, 100)
    return () => clearTimeout(changesErrorsTimeOut)
  }, [errors])

  useEffect(() => {
    if (isSubmited) {
      submitRefs.current.click()
    }
  }, [isSubmited])

  useEffect(() => {
    if (client.noEmail && client.phone) {
      const mail =
        client.phone[0] === '+'
          ? client.phone + '@noemail.com'
          : '+' + client.phone + '@noemail.com'
      handleClientElChange('mail', mail)
      //handleClientElChange('envoiMail', true)
    } else {
      handleClientElChange('mail', '')
    }
  }, [client.noEmail])

  useEffect(() => {
    setEnableMail(true)
  }, [client.envoiMail])

  useEffect(() => {
    if (oldClient) {
      const newClient = {
        firstname: oldClient.firstname,
        lastname: oldClient.lastname,
        phone: oldClient.phone,
        mail: oldClient.mail,
        vip: oldClient.vip ? oldClient.vip : false,
        envoiMail: oldClient.envoiMail,
        ...client,
      }
      resetForm(newClient)
    }
  }, [oldClient])

  return (
    <>
      {client ? (
        <div className="client-form-container">
          {!newClient && (
            <p className="form-title">
              {' '}
              Information du client: {getfullName(oldClient, 'N/A')} /{' '}
              {oldClient.phone}
            </p>
          )}
          <div className="client-type-section">
            <span>
              <FormControl component="fieldset" className="switcher-container">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        name="vip"
                        value={client.vip || false}
                        defaultChecked={client.vip || false}
                        onChange={handleClientSwitchChange}
                        disabled={!courseEditable && !courseSemiEdit}
                      />
                    }
                    label={'VIP'}
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>{' '}
            </span>
          </div>
          <div className="client-informations">
            <form
              className={[
                'form-validation place-shipment-form',
                isSubmitted ? 'was-submitted' : '',
                isClientInvalid ? 'is-invalid' : '',
                isClientValid ? 'is-valid' : '',
              ].join(' ')}
              noValidate
              autoComplete="off"
              onSubmit={($event) => handleClientSubmit($event)}
            >
              <div className="sections">
                <TextField
                  id="firstname"
                  name="firstname"
                  label="Prénom"
                  variant="outlined"
                  error={isSubmited > 0 && !_.isNil(_.get(errors, 'firstname'))}
                  helperText={
                    isSubmited > 0 && !_.isNil(_.get(errors, 'firstname'))
                      ? _.get(errors, 'firstname.required')
                      : ''
                  }
                  value={client.firstname || ''}
                  onChange={handleClientChange}
                  required
                  disabled={!courseEditable && !courseSemiEdit}
                />
                <TextField
                  id="lastname"
                  name="lastname"
                  label="Nom"
                  variant="outlined"
                  error={isSubmited > 0 && !_.isNil(_.get(errors, 'lastname'))}
                  helperText={
                    isSubmited > 0 && !_.isNil(_.get(errors, 'lastname'))
                      ? _.get(errors, 'lastname.required')
                      : ''
                  }
                  value={client.lastname || ''}
                  onChange={handleClientChange}
                  required
                  disabled={!courseEditable && !courseSemiEdit}
                />
              </div>

              <div className="client-type-section">
                <FormControl component="fieldset">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          name="noEmail"
                          value={client.noEmail || false}
                          defaultChecked={client.noEmail || false}
                          onChange={handleClientSwitchChange}
                        />
                      }
                      label="Pas d'adresse mail"
                      disabled={!courseEditable && !courseSemiEdit}
                    />
                  </FormGroup>
                </FormControl>
              </div>

              <div className="sections phone-section">
                <TextField
                  id="mail"
                  name="mail"
                  label="E-mail"
                  variant="outlined"
                  error={
                    isSubmited > 0 &&
                    !client.noEmail &&
                    !_.isNil(_.get(errors, 'mail'))
                  }
                  helperText={
                    isSubmited > 0 &&
                    !client.noEmail &&
                    !_.isNil(_.get(errors, 'mail'))
                      ? !_.isNil(_.get(errors, 'mail.required'))
                        ? _.get(errors, 'mail.required')
                        : _.get(errors, 'mail.pattern', null)
                      : ''
                  }
                  disabled={client.noEmail || (!courseEditable && !courseSemiEdit)}
                  value={!client.noEmail ? client.mail || '' : ''}
                  onChange={handleClientChange}
                  required
                />
                <div className="phone">
                  <PhoneInput
                    id="phone"
                    onChange={(newValue, country) => {
                      handleClientElChange('phoneCheck', {
                        phone: newValue,
                        countryCode: country.countryCode,
                        dialcode: country.dialCode,
                      })
                      handleClientElChange('phone', '+' + newValue)
                      if (client.noEmail)
                        handleClientElChange('mail', '+' + newValue + '@noemail.com')
                    }}
                    value={client.phone || '+33'}
                    onlyCountries={getPhoneInputCountries()}
                    country={'fr'}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      country: 'fr',
                    }}
                    isValid={() => {
                      return isSubmited > 0 && !_.isNil(_.get(errors, 'phone'))
                        ? !_.isNil(_.get(errors, 'phone.required'))
                          ? _.get(errors, 'phone.required')
                          : _.get(errors, 'phone.pattern', null)
                        : true
                    }}
                    disabled={
                      !courseEditable && !courseSemiEdit && !coursePartialEdit
                    }
                  />
                </div>
              </div>
              <div></div>

              <div className="switchers-section">
                <FormControl component="fieldset" className="switcher-container">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleClientSwitchChange}
                          sx={{ color: '#3E99F3' }}
                          checked={
                            client.envoiMail || (oldClient.envoiMail && !enableMail)
                          }
                          value={
                            client.envoiMail || (oldClient.envoiMail && !enableMail)
                          }
                          disabled={
                            client.noEmail || (!courseEditable && !courseSemiEdit)
                          }
                          name="envoiMail"
                        />
                      }
                      label={'Notifier le destinataire par SMS/mail'}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl component="fieldset" className="switcher-container">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          name="silentEdit"
                          value={client.silentEdit || false}
                          defaultChecked={client.silentEdit || false}
                          onChange={handleClientSwitchChange}
                          disabled={!courseEditable && !courseSemiEdit}
                        />
                      }
                      label={'Modifier silencieusement'}
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <input ref={submitRefs} type="submit" className="hidden"></input>
            </form>
          </div>
        </div>
      ) : (
        <div className="client-informations">
          <form
            className={[
              'form-validation place-shipment-form',
              isSubmitted ? 'was-submitted' : '',
              isClientInvalid ? 'is-invalid' : '',
              isClientValid ? 'is-valid' : '',
            ].join(' ')}
            noValidate
            autoComplete="off"
            onSubmit={($event) => handleClientSubmit($event)}
          >
            <input ref={submitRefs} type="submit" className="hidden"></input>
          </form>
          <div>
            {selectedClient === '' && isSubmited > 0 && (
              <FormHelperTextError content={'Un client doit etre sélectionné'} />
            )}
          </div>
        </div>
      )}
    </>
  )
}
EditClientForm.propTypes = {
  selectedClient: PropTypes.any,
  newClient: PropTypes.bool,
  setValidation: PropTypes.func,
  familiesArticleList: PropTypes.array,
  updateClient: PropTypes.func.isRequired,
  isSubmited: PropTypes.number.isRequired,
  setClientErrors: PropTypes.func.isRequired,
  oldClient: PropTypes.any,
  courseEditable: PropTypes.bool,
  courseSemiEdit: PropTypes.bool,
  coursePartialEdit: PropTypes.bool,
}
