import {
  GET_MESSAGES_BY_CONVERSATION_ID,
  GET_MESSAGES_BY_CONVERSATION_ID_URL,
  GET_MESSAGES_BY_CONVERSATION_ID_SUCESS,
  GET_MESSAGES_BY_CONVERSATION_ID_FAILURE,
  UPLOAD_FILE_DISCUSSION,
  DOWNLOAD_FILE_DISCUSSION,
  DELETE_FILE_DISCUSSION,
  DELETE_FILE_DISCUSSION_SUCESS,
  DELETE_FILE_DISCUSSION_FAILURE,
} from './constants'
import { axiosHTTP } from '../../../utils'

import { httpGet, httpDelete } from '../../http/actions'
import {
  onUploadfileDiscussionSucess,
  onUploadfileDiscussionFailure,
} from './action'
const api = process.env.REACT_APP_API_URL_CHAT

export default (store) => (next) => (action) => {
  if (action.type === GET_MESSAGES_BY_CONVERSATION_ID) {
    const { conversationId, legacyId } = action.payload
    store.dispatch(
      httpGet({
        endPoint: `${api}${GET_MESSAGES_BY_CONVERSATION_ID_URL}${conversationId}/${legacyId}`,
        data: action.payload,
        success: GET_MESSAGES_BY_CONVERSATION_ID_SUCESS,
        failure: GET_MESSAGES_BY_CONVERSATION_ID_FAILURE,
      })
    )
  }

  if (action.type === UPLOAD_FILE_DISCUSSION) {
    const { files } = action.payload
    Promise.all(
      Array.from(files).map((file) => {
        try {
          const formData = new FormData()
          formData.append('files', file)
          return axiosHTTP.post(`${api}conversation/uploadattachment`, formData)
        } catch (err) {
          return err
        }
      })
    )
      .then((resp) => {
        const files = resp.map((file) => file.data[0])

        store.dispatch(onUploadfileDiscussionSucess(files))
      })
      .catch((err) => {
        console.log(err)
        store.dispatch(onUploadfileDiscussionFailure())
      })
  }

  if (action.type === DOWNLOAD_FILE_DISCUSSION) {
    const url = action.payload.urlFile
    window.open(url)
  }

  if (action.type === DELETE_FILE_DISCUSSION) {
    const { nameFile } = action.payload
    store.dispatch(
      httpDelete({
        endPoint: `${api}conversation/removeAttachment/${nameFile}`,
        success: DELETE_FILE_DISCUSSION_SUCESS,
        failure: DELETE_FILE_DISCUSSION_FAILURE,
      })
    )
  }

  next(action)
}
