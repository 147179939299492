import React, { useState } from 'react'

import _ from 'lodash'

import ShipmentSection from '../../../../containers/main/PlaceOrders/Shipment'
import AssemblySection from '../../../../containers/main/PlaceOrders/Assembly'

import PropTypes from 'prop-types'
// import Badge from '@mui/material/Badge'

import { ORDER_TYPE } from '../../../../constants/appContants'
const ShipmentAssembly = ({ orders }) => {
  // const shp = orders.filter((e) => e.shipment?.selectedSalesChannel?.name)
  // const ind = orders.indexOf(shp[0])
  const [saleChannel, setSaleChannel] = useState('')
  /** In order to affect any sales channel change to evry order */
  const handleSalesChannelChange = (salesChannel) => {
    setSaleChannel(salesChannel)
  }
  return (
    <>
      {_.get(orders, 'length')
        ? orders.map(
            (
              o
              // , index
            ) => (
              <>
                {o.type === ORDER_TYPE.DELIVERY ||
                o.type === ORDER_TYPE.DELIVERY_ASSEMBLY ? (
                  <div key={o.id} id={o.id} className="order-item">
                    <div className={o.shipment.active ? '' : 'not-active'}>
                      <ShipmentSection
                        order={o}
                        type={o.type}
                        isSubmited={o.shipment.isSubmitted}
                        operationalHours={o.operationalHoursList}
                        operationalHoursLoading={o.isGetOperationalhoursLoading}
                        estimatedPrice={o.shipmentEstimatedPrice}
                        estimatedPriceError={o.shipmentEstimatedPriceError}
                        isMain={true}
                        handleSalesChannel={handleSalesChannelChange}
                        salesChannel={saleChannel}
                      />
                    </div>

                    {!_.isNil(_.get(o, 'assembly')) ? (
                      <div className={o.assembly.active ? '' : 'not-active'}>
                        <AssemblySection
                          order={o}
                          isSubmited={o.assembly.isSubmitted}
                          assemblyDefaultAddress={o.shipment.selectedDestination}
                          shipmentSalesChanel={o.shipment.selectedSalesChannel}
                          estimatedPrice={o.assemblyEstimatedPrice}
                          estimatedPriceError={o.assemblyEstimatedPriceError}
                        />
                      </div>
                    ) : null}
                    {/* <Badge
                    color="primary"
                    className="item-nbr"
                    badgeContent={index + 1}
                  /> */}
                  </div>
                ) : null}

                {o.type === ORDER_TYPE.ASSEMBLY ? (
                  <div id={o.id} className="order-item">
                    <div className={o.assembly.active ? '' : 'not-active'}>
                      <AssemblySection
                        order={o}
                        isSubmited={o.assembly.isSubmitted}
                        assemblyDefaultAddress={null}
                        shipmentSalesChanel={null}
                        estimatedPrice={o.assemblyEstimatedPrice}
                        estimatedPriceError={o.assemblyEstimatedPriceError}
                        handleSalesChannel={handleSalesChannelChange}
                        salesChannel={saleChannel}
                      />
                    </div>
                    {/* <Badge
                    color="primary"
                    className="item-nbr"
                    badgeContent={index + 1}
                  /> */}
                  </div>
                ) : null}
              </>
            )
          )
        : null}
    </>
  )
}
ShipmentAssembly.propTypes = {
  orders: PropTypes.array.isRequired,
}
export default ShipmentAssembly
