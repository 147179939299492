import React, { useEffect, useState } from 'react'
import 'react-chat-elements/dist/main.css'
import ConversationContainer from './components/channels'
import ChatComponent from './components/chat'
import Grid from '@mui/material/Grid'
import clickImg from '../../../assets/images/click.png'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import { EVENT_SOCKET } from '../../../utils/discussion/values'
import Error from '../../../components/Modal/HttpErrorPopUp'
import Search from '../../../containers/main/discussion/Search'
import NavFilter from '../../../containers/main/NavFilter'
import { useHistory } from 'react-router-dom'

function DiscussionPage({
  entryConversations,
  isLoadingGetConversationList,
  isLoadingGetMessageList,
  conversationEntries,
  getMessageByConversationIdAction,
  issocketNotRegistred,
  focus,
  notFocus,
  varFocus,
  getConversationSocket,
  loadingOpenCloseConversation,
  loadingSendMessage,
  getMessageSocket,
  CloseOpenConversation,
  sendMessage,
  removeListenerSocket,
  seenMessage,
  onUploadfileDiscussion,
  loadingUploadFile,
  errorUploadFile, //Afficher Message d'erreur (booleen)
  filesUpload,
  removeFileDiscussion,
  countList,
  isLoadingConvMore,
  downloadFile,
  setLoadingUploadFile,
  setErrorUploadFile,
  errorMessageUploadFile,
  deleteFile,
  filterReducer,
  setFilter,
  resetFilterDiscussion,
  isOpenChanged,
  pageChanged,
  errorFilter,
  removeErrorFilter,
  cleaningConversationList,
  cleaningMessageList,
  isOpened,
  defaultFilterDiscussion,
}) {
  const CURRENT_USER = 'currentUser'
  const [connectedUser] = useState(JSON.parse(localStorage.getItem(CURRENT_USER)))
  const [conversationId, setOpenedConversationId] = useState('')
  const [conversation, setOpenedConversation] = useState({})

  const [isRecherch, setRecherch] = useState(false)
  const [offset, setOffset] = useState(25)
  const [open, setOpen] = useState(false)
  const [openErrorFilter, setOpenErrorFilter] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (!issocketNotRegistred) {
      getConversationSocket(isRecherch)
    }
  }, [issocketNotRegistred, isRecherch])

  useEffect(() => {
    return () => {
      removeListenerSocket(conversationId)
    }
  }, [conversationId])

  //we will get the list of messages when the user click on a conversation
  const onClickConversation = (Conversation) => {
    //conversationClicked
    removeListenerSocket(conversationId)
    //save the id of the opned conversation
    setOpenedConversationId(Conversation.id)
    setOpenedConversation(Conversation)

    //get Messages List by id conversation from the server
    getMessageByConversationIdAction(Conversation.id, connectedUser.id)
    //get Message from Event Socket
    getMessageSocket(Conversation.id)
  }

  useEffect(() => {
    return () => {
      //clean the conversation list in the store initialState
      cleaningConversationList()
      //clean the message list in the store initialState
      cleaningMessageList()
      //remove the listner of conversation topic
      removeListenerSocket(conversationId)
      // setOpenedConversationId('')
      setRecherch(false)
      setOffset(25)
    }
  }, [])

  const pushMessage = (message, files) => {
    const entry = {
      type: EVENT_SOCKET.TEXT_EVENT,
      content: message,
      confidentiality: 'PUBLIC',
      conversationID: conversationId,
      attachements: files.length > 0 ? files : null,
    }
    sendMessage(entry, connectedUser)
  }
  //close conversation
  const closeConversation = () => {
    const entry = {
      type: EVENT_SOCKET.CLOSE_EVENT,
      content: 'Cette discussion est clôturée',
      conversationID: conversationId,
    }
    CloseOpenConversation(entry, connectedUser)
  }
  // Open COnversation
  const openConversation = () => {
    const entry = {
      type: EVENT_SOCKET.OPEN_EVENT,
      content: 'Cette discussion est rouverte',
      conversationID: conversationId,
    }
    CloseOpenConversation(entry, connectedUser)
  }
  const desSelectConversation = () => {
    removeListenerSocket(conversationId)
    setOpenedConversationId('')
    cleaningMessageList()
    setOffset(25)
  }
  const getOpenConversationMore = () => {
    setOffset(offset + 25)
    pageChanged(offset)
  }
  const getCloseConversationMore = () => {
    if (!isRecherch) {
      setOffset(offset + 25)
      pageChanged(offset)
    }
  }
  const onErrorClose = () => {
    setOpen(false)
    const error = { isError: false, message: ' ' }
    setErrorUploadFile(error)
  }

  useEffect(() => {
    if (errorUploadFile) setOpen(true)
  }, [errorUploadFile])

  const onErrorFilterClose = () => {
    setOpenErrorFilter(false)
    removeErrorFilter()
  }
  useEffect(() => {
    if (errorFilter) setOpenErrorFilter(true)
  }, [errorFilter])

  return (
    <>
      <NavFilter
        salesChannelfilter={true}
        subjectFilter={true}
        wareHouseFilter={true}
        filterReducer={filterReducer}
        setFilter={setFilter}
        ResponseCheck={true}
        resetFilter={resetFilterDiscussion}
        ProcessingDepartment={true}
        pathnameFilter={history.location.pathname}
        directionFilter={true}
        natureDiscussion={true}
        defaultFilter={defaultFilterDiscussion}
      />
      {errorUploadFile && (
        <Error
          statusText={
            errorMessageUploadFile ||
            "une erreur s'est produite merci d'actualiser la page"
          }
          open={open}
          setOpen={onErrorClose}
        />
      )}

      <div style={{ marginTop: '15px', marginRight: '20px', marginLeft: '20px' }}>
        <Paper elevation={3}>
          <Grid container>
            <Grid item xs={12} xl={4} sm={4} md={4}>
              <div
                style={{
                  paddingTop: '20px',
                  boxShadow: '0 1px 7px 0 rgb(0 0 0 / 10%)',
                }}
              >
                <div
                  style={{
                    height: '70px',
                    fontSize: '30px',
                    marginBottom: '5px',
                    wordBreak: 'break-all',
                    marginLeft: '10px',
                  }}
                >
                  Vos Conversations
                </div>
                <Search
                  setRecherch={setRecherch}
                  setOffset={setOffset}
                  isRecherch={isRecherch}
                />
                <ConversationContainer
                  isLoadingGetConversationList={isLoadingGetConversationList}
                  conversationList={entryConversations}
                  getMessageListByConversationId={onClickConversation}
                  conversationSelect={conversationId}
                  desSelectConversation={desSelectConversation}
                  getCloseConversationMore={getCloseConversationMore}
                  getOpenConversationMore={getOpenConversationMore}
                  count={countList}
                  offset={offset}
                  isLoadingConvMore={isLoadingConvMore}
                  isOpenChanged={isOpenChanged}
                  filterReducer={filterReducer}
                  isOpened={isOpened}
                />
              </div>
            </Grid>
            <Grid item xs={12} xl={8} sm={8} md={8}>
              {conversationEntries.length === 0 && !isLoadingGetMessageList ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '60px',
                    paddingBottom: '50px',
                  }}
                >
                  <img
                    src={clickImg}
                    alt="click"
                    style={{ width: '200px', margin: '10% auto 20px' }}
                  />
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    Aucune discussion séléctionnée
                  </div>
                  {/* //TODO style */}
                </div>
              ) : (
                <ChatComponent
                  isLoadingGetMessageList={isLoadingGetMessageList}
                  messageList={conversationEntries}
                  pushMessage={pushMessage}
                  loadingSendMessage={loadingSendMessage}
                  closeConversation={closeConversation}
                  openConversation={openConversation}
                  conversationOpen={conversation}
                  loadingCloseConversation={loadingOpenCloseConversation}
                  loadingOpenConversation={loadingOpenCloseConversation}
                  connectedUser={connectedUser}
                  focus={focus}
                  notFocus={notFocus}
                  varFocus={varFocus}
                  entryConversations={entryConversations}
                  seenMessage={seenMessage}
                  onUploadfileDiscussion={onUploadfileDiscussion}
                  loadingUploadFile={loadingUploadFile}
                  errorUploadFile={errorUploadFile}
                  filesUpload={filesUpload}
                  removeFileDiscussion={removeFileDiscussion}
                  downloadFile={downloadFile}
                  setLoadingUploadFile={setLoadingUploadFile}
                  setErrorUploadFile={setErrorUploadFile}
                  deleteFile={deleteFile}
                />
              )}
              {errorFilter && (
                <Error
                  statusText={errorFilter}
                  open={openErrorFilter}
                  setOpen={onErrorFilterClose}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  )
}
DiscussionPage.propTypes = {
  entryConversations: PropTypes.array,
  isLoadingGetConversationList: PropTypes.bool,
  isLoadingGetMessageList: PropTypes.bool,
  conversationEntries: PropTypes.array,
  getMessageByConversationIdAction: PropTypes.func,
  socketNotRegistred: PropTypes.func,
  issocketNotRegistred: PropTypes.bool,
  focus: PropTypes.func,
  notFocus: PropTypes.func,
  varFocus: PropTypes.bool,
  getConversationSocket: PropTypes.func,
  loadingOpenCloseConversation: PropTypes.bool,
  loadingSendMessage: PropTypes.bool,
  getMessageSocket: PropTypes.func,
  CloseOpenConversation: PropTypes.func,
  sendMessage: PropTypes.func,
  removeListenerSocket: PropTypes.func,
  seenMessage: PropTypes.func,
  onUploadfileDiscussion: PropTypes.func,
  loadingUploadFile: PropTypes.bool,
  errorUploadFile: PropTypes.any,
  filesUpload: PropTypes.array,
  removeFileDiscussion: PropTypes.func,
  countList: PropTypes.number,
  offset: PropTypes.number,
  isLoadingConvMore: PropTypes.bool,
  downloadFile: PropTypes.func,
  errorMessageUploadFile: PropTypes.string,
  setLoadingUploadFile: PropTypes.func,
  setErrorUploadFile: PropTypes.func,
  deleteFile: PropTypes.func,
  filterReducer: PropTypes.object,
  isOpenChanged: PropTypes.func,
  pageChanged: PropTypes.func,
  errorFilter: PropTypes.string,
  setFilter: PropTypes.func,
  resetFilterDiscussion: PropTypes.func,
  removeErrorFilter: PropTypes.func,
  cleaningConversationList: PropTypes.func,
  cleaningMessageList: PropTypes.func,
  isOpened: PropTypes.bool,
  defaultFilterDiscussion: PropTypes.object,
}
export default DiscussionPage
