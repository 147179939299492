import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import CardAccordion from '../card/cardWithAccordion'

const Historique = ({
  order,
  orderHistoriqueRDV,
  isLoadingHistoriqueRDV,
  getOrderHistoriqueRDV,
  isLoading,
}) => {
  const params = useParams()

  useEffect(() => {
    if (order?.code) {
      getOrderHistoriqueRDV(params.ldv)
    }
  }, [order])

  const sentence = (start) => {
    return start?.adminJustification && start?.client != undefined
      ? `${start.client} a programmé un ${start.label}: ${start.adminJustification}`
      : `Le systéme a programmé un ${start.label}: ${start.adminJustification}`
  }
  return (
    <CardAccordion
      icon={
        <CalendarTodayOutlinedIcon
          style={{ marginRight: '4px', width: '20px', height: '20px' }}
        />
      }
      title={'Historique de Rendez-vous'}
      content={
        <>
          {isLoadingHistoriqueRDV || isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px',
              }}
            >
              <CircularProgress color="primary" />
            </div>
          ) : (
            <>
              {orderHistoriqueRDV.length > 0 ? (
                <div style={{ padding: '5px 5px 10px' }}>
                  {orderHistoriqueRDV.map((rdv, i) => {
                    return (
                      <div key={i} style={{ paddingBottom: '5px' }}>
                        <div
                          style={{
                            color: '#696969',
                            fontSize: '14px',
                            fontWeight: '400',

                            paddingLeft: '25px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {rdv.date}
                        </div>
                        {rdv?.historyDetails?.map((elem, i) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: '1px solid #f5f5f5',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px 40px',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  marginRight: '10px',
                                }}
                              >
                                {elem.hour}
                              </span>
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '450',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {sentence(elem)}
                              </span>
                            </div>
                            {elem.comment && (
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '10px 40px',
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: '13px',
                                    fontWeight: '550',
                                    marginRight: '10px',
                                  }}
                                >
                                  {'Commentaire: '}
                                </span>
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: '450',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {elem.comment}
                                </span>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <span
                    style={{
                      overflowWrap: 'break-word',
                      fontSize: '12px',
                      fontWeight: '550',
                    }}
                  >
                    {'Historique de RDV est vide'}
                  </span>
                </div>
              )}
            </>
          )}
        </>
      }
    />
  )
}
Historique.propTypes = {
  order: PropTypes.object,
  orderHistoriqueRDV: PropTypes.array,
  isLoadingHistoriqueRDV: PropTypes.bool,
  getOrderHistoriqueRDV: PropTypes.func,
  isLoading: PropTypes.bool,
}
export default React.memo(Historique)
