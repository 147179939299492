import initialState from './intialState'
import {
  GET_ADDRESS_PREDICTIONS,
  GET_ADDRESS_PREDICTIONS_FAILURE,
  GET_ADDRESS_PREDICTIONS_SUCCESS,
} from './actions'
import { Place } from '../../models/place'
import _ from 'lodash'

export default (state = initialState, action) => {
  if (action.type === GET_ADDRESS_PREDICTIONS) {
    // do sth
    console.log('loading')
  }
  if (action.type === GET_ADDRESS_PREDICTIONS_SUCCESS) {
    console.log('success', action.payload)
    if (
      action.payload.data &&
      action.payload.data.predictions &&
      action.payload.data.predictions.length !== 0
    ) {
      let addressList = []
      const { predictions } = action.payload.data
      const predictionResult = predictions.map((jsonItem) =>
        new Place().getFromJson(jsonItem)
      )
      return {
        ...state,
        predictions: [
          ...addressList,
          ...predictionResult.filter(
            (a) =>
              !addressList.find(
                (sa) =>
                  _.get(sa, 'latlng.lat') === _.get(a, 'latlng.lat') &&
                  _.get(sa, 'latlng.lng') === _.get(a, 'latlng.lng')
              )
          ),
        ],
      }
    } else {
      return {
        ...state,
        predictions: [],
      }
    }
  }
  if (action.type === GET_ADDRESS_PREDICTIONS_FAILURE) {
    console.log('failure', action.payload)
    return {
      ...state,
      predictions: [],
    }
  }
  return state
}
