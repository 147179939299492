export const getTourneesState = ({ tournees }) => tournees

export const getIsLoadingTourneesList = (state) =>
  getTourneesState(state).isLoadingTourneesList

export const getTourneesList = (state) => getTourneesState(state).tourneesList

export const getTourneesListCount = (state) =>
  getTourneesState(state).tourneesListCount

export const getErrorMessage = (state) => getTourneesState(state).errorMessage

export const getIsErrorFetch = (state) => getTourneesState(state).isErrorFetch

export const getIsSuccessRefresh = (state) =>
  getTourneesState(state).isSuccessRefresh

export const getSuccess = (state) => getTourneesState(state).success

export const getIsLoadingTourneeAction = (state) =>
  getTourneesState(state).isLoadingTourneeAction
