import { connect } from 'react-redux'

import Keys from '../../../pages/settings/Keys'
import {
  fetchKeys,
  onSearchKeys,
  pageChanged,
  pageLimitChanged,
  removeErrorConfig,
  resetConfig,
  resetSuccessAction,
} from '../../../redux/configuration/actions'

import {
  getConfigSuccess,
  getErrorMessageConfig,
  getIsErrorFetch,
  getIsSuccessRefresh,
  getKeys,
  getKeysCount,
  getKeysListIsLoading,
} from '../../../redux/configuration/selectors'

const mapStateToProps = (state) => ({
  keys: getKeys(state),
  isLoadingkeysList: getKeysListIsLoading(state),
  success: getConfigSuccess(state),
  keysCount: getKeysCount(state),
  errorMessage: getErrorMessageConfig(state),
  isErrorFetch: getIsErrorFetch(state),
  isSuccessRefresh: getIsSuccessRefresh(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchKeys()),
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(fetchKeys())
  },

  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(fetchKeys())
  },
  searchKeys: (search) => {
    dispatch(onSearchKeys(search))
    dispatch(fetchKeys())
  },
  onCloseReset: () => dispatch(resetConfig()),
  removeError: () => dispatch(removeErrorConfig()),
  resetSuccessAction: () => dispatch(resetSuccessAction()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Keys)
