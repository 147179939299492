import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'

export const StyledCell = styled(TableCell)(({ disabledColumn }) => ({
  '&.MuiTableCell-root': {
    padding: '0px !important',
    color: disabledColumn && '#8E8E8E',
    fontSize: '11px ! important',
  },
}))
