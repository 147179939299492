import React from 'react'
import PropTypes from 'prop-types'
import IconDatePreveue from '../../../../../../assets/icons/detailsLivraison/iconDatePreveue'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import { CardSyled, TitleWithIcon } from '../card/style'
import { COURSE_SOURCE_DATE_ENLEVEMENT } from '../../../../../../utils/checkActionCourseSource'

export default function DatePrevuesLivraison({ order, isLoading }) {
  return (
    <>
      {/* 
            Date Prévues:
                date de demarrage : true
                Pickup start : true
                Pickup end : true
                dropoff start : true
                dropoff end: true
                ETA pick up : true
                ETA drop off : true
        */}
      <CardSyled>
        <TitleWithIcon>
          <IconDatePreveue
            style={{ marginRight: '4px', width: '20px', height: '20px' }}
          />{' '}
          Dates Prévues
        </TitleWithIcon>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '20px',
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  lineHeight: '30px',
                  fontSize: '13px',
                  fontWeight: 600,
                  textDecoration: 'none',
                  color: '#2d2c2c',
                }}
              >
                Date de démarrage{' '}
              </span>
              <span>
                {order?.dateDemarrage
                  ? moment(order.dateDemarrage).format('YYYY-MM-DD HH:mm')
                  : ' - '}
              </span>
            </Grid>
            {COURSE_SOURCE_DATE_ENLEVEMENT.includes(order?.courseSource) && (
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {"Date d'enlèvement"}
                </span>
                <span>
                  {order?.dateEnlevement
                    ? moment(order.dateEnlevement).format('YYYY-MM-DD HH:mm')
                    : ' - '}
                </span>
              </Grid>
            )}

            <Grid container>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  Pickup start{' '}
                </span>
                <span>
                  {order?.pickUpStart
                    ? moment(order.pickUpStart).format('YYYY-MM-DD HH:mm')
                    : ' - '}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  Pickup end
                </span>
                <span>
                  {order?.pickUpEnd
                    ? moment(order.pickUpEnd).format('YYYY-MM-DD HH:mm')
                    : ' - '}
                </span>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  Dropoff start
                </span>
                <span>
                  {order?.dropOffStart
                    ? moment(order.dropOffStart).format('YYYY-MM-DD HH:mm')
                    : ' - '}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  Dropoff end
                </span>
                <span>
                  {order?.dropOffEnd
                    ? moment(order.dropOffEnd).format('YYYY-MM-DD HH:mm')
                    : ' - '}
                </span>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  ETA pick up{' '}
                </span>
                <span>
                  {order?.estimatedTimeOfArrivalAtPickUp
                    ? moment(order.estimatedTimeOfArrivalAtPickUp).format(
                        'YYYY-MM-DD HH:mm'
                      )
                    : ' - '}
                </span>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  ETA drop off
                </span>
                <span>
                  {order?.estimatedTimeOfArrivalAtDropOff
                    ? moment(order.estimatedTimeOfArrivalAtDropOff).format(
                        'YYYY-MM-DD HH:mm'
                      )
                    : ' - '}
                </span>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardSyled>
    </>
  )
}
DatePrevuesLivraison.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
}
