import { insetObjectTable } from '../../utils/utils'
import {
  GET_SESSIONS,
  GET_SESSIONS_SUCCESS,
  GET_SESSIONS_FAILURE,
  PAGE_CHANGED,
  PAGE_LIMIT_CHANGED,
  REMOVE_ERROR,
  RESET_SUCCESS_ACTION,
  RESET_CONFIG_SESSION,
  SEARCH_SESSIONS,
  ADD_SESSION,
  ADD_SESSION_FAILURE,
  ADD_SESSION_SUCCESS,
  UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILURE,
  SET_ERROR_MESSAGE,
  FETCH_SESSION_DETAILS,
  FETCH_SESSION_DETAILS_SUCCESS,
  FETCH_SESSION_DETAILS_FAILURE,
  FETCH_SESSION_PARTICIPANTS,
  FETCH_SESSION_PARTICIPANTS_SUCCESS,
  FETCH_SESSION_PARTICIPANTS_FAILURE,
  SESSION_PARTICIPANT_UPDATE,
  SESSION_PARTICIPANT_UPDATE_SUCCESS,
  SESSION_PARTICIPANT_UPDATE_FAILURE,
  GET_SESSION_BY_ID_FAILURE,
  GET_SESSION_BY_ID_SUCCESS,
  GET_SESSION_BY_ID,
  SESSION_DELETE,
  SESSION_DELETE_SUCCESS,
  SESSION_DELETE_FAILURE,
} from './constants'
import initialState from './initialState'

export default (state = initialState(), action) => {
  if (action.type === GET_SESSIONS) {
    return {
      ...state,
      sessions: [],
      isLoadingSessionsList: true,
    }
  }

  if (action.type === GET_SESSIONS_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      sessions: data,
      isLoadingSessionsList: false,
      sessionsCount: count,
    }
  }

  if (action.type === GET_SESSIONS_FAILURE) {
    const { response } = action.payload?.data

    return {
      ...state,
      isLoadingSessionsList: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetch: true,
    }
  }

  if (action.type === PAGE_CHANGED) {
    const { offset } = action.payload
    const filter = { ...state.filter }
    filter.offset = offset
    return {
      ...state,
      filter,
    }
  }

  if (action.type === PAGE_LIMIT_CHANGED) {
    const { limit } = action.payload
    const filter = { ...state.filter }
    filter.limit = limit
    filter.offset = 0
    return {
      ...state,
      filter,
    }
  }

  if (action.type === REMOVE_ERROR) {
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
      isErrorFetchDetails: false,
      isErrorFetchParticipant: false,
    }
  }

  if (action.type === RESET_SUCCESS_ACTION) {
    return {
      ...state,
      success: false,
      isSuccessRefresh: false,
    }
  }

  if (action.type === RESET_CONFIG_SESSION) {
    //rest
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
      success: false,
      isSuccessRefresh: false,
      sessionsDetails: null,
      sessionsParticipants: [],
      isErrorFetchDetails: false,
      isErrorFetchParticipant: false,

      filter: {
        limit: 25,
        offset: 0,
      },
    }
  }

  if (action.type === SEARCH_SESSIONS) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        limit: 25,
        offset: 0,
        query: search,
      },
    }
  }

  if (action.type === ADD_SESSION) {
    return {
      ...state,
      isLoadingSessionAction: true,
    }
  }
  if (action.type === ADD_SESSION_SUCCESS) {
    return {
      ...state,
      isLoadingSessionAction: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === ADD_SESSION_FAILURE) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingSessionAction: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === UPDATE_SESSION) {
    return {
      ...state,
      isLoadingSessionAction: true,
    }
  }
  if (action.type === UPDATE_SESSION_SUCCESS) {
    const { data } = action.payload
    const sessionIndex = state.sessions.map((el) => el.id).indexOf(data.id)
    return {
      ...state,
      sessions: [...insetObjectTable(state.sessions, data, sessionIndex)],
      isLoadingSessionAction: false,
      success: true,
    }
  }

  if (action.type === UPDATE_SESSION_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingSessionAction: false,
      success: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === SET_ERROR_MESSAGE) {
    const { message } = action.payload
    return {
      ...state,
      errorMessage: message,
      isErrorFetch: false,
    }
  }

  if (action.type === FETCH_SESSION_DETAILS) {
    return {
      ...state,
      isLoadingSessionsDetails: true,
    }
  }

  if (action.type === FETCH_SESSION_DETAILS_SUCCESS) {
    const { data } = action.payload
    return {
      ...state,
      sessionsDetails: data,
      isLoadingSessionsDetails: false,
    }
  }

  if (action.type === FETCH_SESSION_DETAILS_FAILURE) {
    const { message } = action.payload
    return {
      ...state,
      errorMessage: message || ' Veuillez réessayer plus tard ',
      isLoadingSessionsDetails: false,
      isErrorFetchDetails: true,
    }
  }

  if (action.type === FETCH_SESSION_PARTICIPANTS) {
    return {
      ...state,
      isLoadingSessionsParticipants: true,
    }
  }

  if (action.type === FETCH_SESSION_PARTICIPANTS_SUCCESS) {
    const { data } = action.payload
    return {
      ...state,
      sessionsParticipants: data,
      isLoadingSessionsParticipants: false,
    }
  }

  if (action.type === FETCH_SESSION_PARTICIPANTS_FAILURE) {
    const { message } = action.payload

    return {
      ...state,
      isLoadingSessionsParticipants: false,
      errorMessage: message || ' Veuillez réessayer plus tard',
      isErrorFetchParticipant: true,
    }
  }

  if (action.type === SESSION_PARTICIPANT_UPDATE) {
    return {
      ...state,
      isLoadingSessionAction: true,
    }
  }

  if (action.type === SESSION_PARTICIPANT_UPDATE_SUCCESS) {
    const { data } = action.payload
    const participantIndex = state.sessionsParticipants
      .map((el) => el.participantSessionId)
      .indexOf(data.participantSessionId)
    return {
      ...state,
      sessionsParticipants: [
        ...insetObjectTable(state.sessionsParticipants, data, participantIndex),
      ],
      isLoadingSessionAction: false,
      success: true,
    }
  }

  if (action.type === SESSION_PARTICIPANT_UPDATE_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingSessionAction: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === GET_SESSION_BY_ID) {
    return {
      ...state,
      session: {},
      isLoadingSession: true,
    }
  }

  if (action.type === GET_SESSION_BY_ID_SUCCESS) {
    const { data } = action.payload
    return {
      ...state,
      session: data,
      isLoadingSession: false,
    }
  }

  if (action.type === GET_SESSION_BY_ID_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingSession: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === SESSION_DELETE) {
    return {
      ...state,
      isLoadingSessionAction: true,
    }
  }

  if (action.type === SESSION_DELETE_SUCCESS) {
    return {
      ...state,
      isLoadingSessionAction: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === SESSION_DELETE_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingSessionAction: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }
  return state
}
