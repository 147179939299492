export default () => {
  return {
    filter: {
      limit: 25,
      offset: 0,
    },
    formations: [],
    formationsCount: 0,
    isLoadingFormationsList: false,
    errorMessage: null,
    isErrorFetch: false,

    isLoadingFormationAction: false,
    success: false,
    isSuccessRefresh: false,
    files: [],
    isLoadingFiles: false,

    formation: {},
    isLoadingFormation: false,
  }
}
