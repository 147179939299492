import React from 'react'
import PropTypes from 'prop-types'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import ListItemsComponent from '../listItemComponent'
import { styled } from '@mui/material/styles'

export const StyledList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  overflow: 'auto',
  overflowX: 'hidden',
  maxHeight: 'calc(100vh - 335px)',
  overflowY: 'overlay',
  '& li:last-child': {
    '& .MuiListItem-root': {
      border: 'none',
    },
  },
  '& .MuiList-padding': {
    paddingTop: '0',
  },
}))

export const StyledFirstItemList = styled(List)(() => ({
  '& .MuiList-padding': {
    marginLeft: '1px',
  },
}))

const FilterItemsList = ({
  dataList,
  handleToggle,
  handleDoubleClick,
  fetchMoreData,
  handleDoubleClickAll,
  handleToggleAll,
  numSelected,
  isSelected,
  search,
  isProgrammer,
  customStyle,
  notEmptyTours,
}) => {
  return (
    <>
      {search === '' && (
        <StyledFirstItemList sx={customStyle?.list} dense>
          <ListItem
            sx={customStyle?.listItem}
            key={0}
            button
            onDoubleClick={handleDoubleClickAll}
          >
            <ListItemText
              sx={customStyle?.listItemText}
              id={'checkbox-list-secondary-label-0'}
              primary={'Tous'}
            />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onClick={handleToggleAll}
                checked={dataList.length > 0 && numSelected}
                inputProps={{ 'aria-labelledby': 'checkbox-list-secondary-label-0' }}
                style={{ cursor: 'pointer' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </StyledFirstItemList>
      )}

      <div style={{ marginLeft: '1px' }}>
        <StyledList
          sx={customStyle?.listDrivers}
          dense
          onScroll={fetchMoreData}
          style={{ height: isProgrammer ? '150px' : '' }}
        >
          <ListItemsComponent
            notEmptyTours={notEmptyTours}
            customStyle={customStyle}
            data={dataList}
            handleToggle={handleToggle}
            handleDoubleClick={handleDoubleClick}
            isSelected={isSelected}
          />
        </StyledList>
      </div>
    </>
  )
}
FilterItemsList.propTypes = {
  handleToggle: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  dataList: PropTypes.array,
  fetchMoreData: PropTypes.func,
  handleDoubleClickAll: PropTypes.func,
  handleToggleAll: PropTypes.func,
  numSelected: PropTypes.bool,
  isSelected: PropTypes.func,
  search: PropTypes.string,
  isProgrammer: PropTypes.bool,
  customStyle: PropTypes.object,
  notEmptyTours: PropTypes.array,
}
export default FilterItemsList
