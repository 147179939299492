import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import Details from '../../../../containers/settings/sessions/detailsSession/details'
import Participants from '../../../../containers/settings/sessions/detailsSession/participants'

import { Grid } from '@mui/material'
import { StyledContainerSessionDetails } from './style'
import ModalError from '../../../../components/ModalError'

const GestionSessions = ({
  errorMessage,
  isErrorFetchParticipant,
  isErrorFetchDetails,
  removeError,
  fetchSessionsParticipants,
  fetchSessionDetails,
}) => {
  const params = useParams()
  const [openErrorDialog, setOpenErrorDialog] = useState(false)

  useEffect(() => {
    if (errorMessage) {
      setOpenErrorDialog(true)
    }
  }, [errorMessage])
  return (
    <>
      <StyledContainerSessionDetails>
        <Grid container direction="row" spacing={1} justifyContent="space-between">
          <Grid item xs={12} md={6} sm={12}>
            <Details />
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <Participants />
          </Grid>
        </Grid>
      </StyledContainerSessionDetails>
      {errorMessage && (
        <ModalError
          open={openErrorDialog}
          handleClose={() => {
            setOpenErrorDialog(false)
            removeError()
            isErrorFetchParticipant && fetchSessionsParticipants(params.id)
            isErrorFetchDetails && fetchSessionDetails(params.id)
          }}
          message={errorMessage}
          isModalActionActualiser={isErrorFetchParticipant || isErrorFetchDetails}
        />
      )}
    </>
  )
}
GestionSessions.propTypes = {
  errorMessage: PropTypes.string,
  isErrorFetchParticipant: PropTypes.bool,
  isErrorFetchDetails: PropTypes.bool,
  removeError: PropTypes.func,
  fetchSessionsParticipants: PropTypes.func,
  fetchSessionDetails: PropTypes.func,
}
export default GestionSessions
