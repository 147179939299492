import { connect } from 'react-redux'

import { getOrderByAgents, getOrderByFilter } from '../../../redux/order/actions'

import AgentsOrders from '../../../components/NavFilter/components/Filter/cell/AgentOrders'
import { getIsLoading } from '../../../redux/order/selectors'
import { getDisplayGrid } from '../../../redux/filter/selectors'

const mapDisptachToProps = (dispatch) => ({
  getShipmentByAgents: () => dispatch(getOrderByAgents()),
  getShipmentByFilter: () => dispatch(getOrderByFilter()),
})

const mapStateToProps = (state) => ({
  isLoading: getIsLoading(state),
  displayGrid: getDisplayGrid(state),
})
export default connect(mapStateToProps, mapDisptachToProps)(AgentsOrders)
