import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import { replaceDotWithComma } from '../../../../utils/floatFormatting'
import { getfullName } from '../../../../utils/values'

const RecapSection = ({ order, directions }) => {
  const [distance, SetDistance] = useState(0)
  const [collaborator, setCollaborator] = useState('')
  useEffect(() => {
    if (order?.shipment.direction) {
      const newDistance = order.shipment.direction?.selectedDirection?.distance
      console.log(newDistance)
      if (newDistance) {
        SetDistance(newDistance)
      } else {
        SetDistance(0)
      }
    }
  }, [order?.shipment.direction, directions])
  useEffect(() => {
    setCollaborator(order?.shipment.collaborator)
  }, [order?.shipment])
  const matches = useMediaQuery('(max-width:839px)')
  return (
    <>
      <div className="recap-header-container">
        <div className="recap-text-container">
          {collaborator && !matches && (
            <span className="recap-text">
              <p>
                {'Collaborateur: '}
                <span className="recap-text-content">
                  {getfullName(collaborator, 'N/A')}{' '}
                </span>
              </p>
            </span>
          )}
          <span
            className="recap-text"
            style={matches ? { width: '100%', textAlign: 'right' } : {}}
          >
            <p>
              <span className="recap-text-content">
                {' '}
                {replaceDotWithComma(distance)} KM
              </span>
            </p>
          </span>
        </div>
      </div>
    </>
  )
}
RecapSection.propTypes = {
  order: PropTypes.object.isRequired,
  totalPrice: PropTypes.number.isRequired,
  activeStep: PropTypes.number,
  directions: PropTypes.any,
  ldv: PropTypes.string,
}
export default RecapSection
