import { connect } from 'react-redux'

import {
  fetchClientWithCriteria,
  changeSearchCriteraByPhone,
} from '../../redux/shipment/actions'

import { getClients } from '../../redux/shipment/selectors'
import ClientsList from '../../components/searchClient'

const mapStateToProps = (state) => ({
  clients: getClients(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchClientWithCriteria: (criteria, searchByPhone) => {
    dispatch(changeSearchCriteraByPhone(searchByPhone))
    dispatch(fetchClientWithCriteria(criteria))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(ClientsList)
