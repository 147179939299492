import { connect } from 'react-redux'

import Drivers from '../../../components/NavFilter/components/Filter/cell/Drivers'
import {
  getDriversFilter,
  getDriversIsLoadingFilter,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  driverfilter: getDriversFilter(state),
  isLoadingDrivers: getDriversIsLoadingFilter(state),
})

export default connect(mapStateToProps, null)(Drivers)
