import { connect } from 'react-redux'

import PrestationList from '../../../components/FilterOptimiser/Cell/Prestations'
import { getPrestationsListRequest } from '../../../redux/filter/actions'
import { getServices } from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  prestations: getServices(state),
})

const mapDisptachToProps = (dispatch) => ({
  getAllPrestations: () => dispatch(getPrestationsListRequest()),
})

export default connect(mapStateToProps, mapDisptachToProps)(PrestationList)
