import io from 'socket.io-client'
import { Observable } from 'rxjs'

class SocketDiscussion {
  socket = null

  establishSocketConnection() {
    const serverSocket = process.env.REACT_APP_API_URL_CHAT_WS
    this.socket = io(serverSocket, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 50000,
      randomizationFactor: 5000,
      timeout: 20000,
      autoConnect: true,
      secure: true,
      // Voici la parametre qui doit etre présente. tranport websocket
      transports: ['polling', 'websocket'],
      path: '/api/b2h',
    })
  }

  register() {
    if (this.socket == null) this.establishSocketConnection()
    const CURRENT_USER = 'currentUser'
    const connectedUser = JSON.parse(localStorage.getItem(CURRENT_USER))
    if (connectedUser) {
      this.socket.on('connect', () => {
        this.socket.emit('register', {
          user: {
            legacyID: `${connectedUser.id}`,
            photoIdentity: connectedUser.photoIdentity,
            firstName: connectedUser.firstName,
            name: connectedUser.lastName,
          },
        })
      })
    }
  }

  getSocket() {
    if (this.socket == null) this.establishSocketConnection()

    return this.socket
  }

  removeAllListeners(listener) {
    if (this.socket !== null) this.socket.removeAllListeners(listener)
  }

  socketDisconnect() {
    if (this.socket == null) this.establishSocketConnection()
    this.socket.disconnect()
    this.socket = null
  }

  getConversation() {
    if (this.socket == null) this.register()

    return new Observable((observer) => {
      try {
        this.socket.on('conversation', (conversation) => {
          observer.next(conversation)
        })
      } catch (e) {
        console.log('catched error in conversation: ', e)
      }
    })
  }

  getMessage(idConversation) {
    if (this.socket == null) this.register()

    return new Observable((observer) => {
      try {
        this.socket.on(idConversation, (message) => {
          observer.next(message)
        })
      } catch (e) {
        console.log('catched error in message: ', e)
      }
    })
  }

  sendMessage(entry, connectedUser) {
    if (this.socket == null) this.register()

    this.socket.emit('serverentries', {
      type: entry.type,
      content: entry.content,
      conversationID: entry.conversationID,
      attachements: entry.attachements,
      sender: {
        legacyID: `${connectedUser.id}`,
        photoIdentity: connectedUser.photoIdentity,
        firstName: connectedUser.firstname,
        name: connectedUser.lastname,
      },
    })
  }

  CloseOpenConversation(entry, connectedUser) {
    if (this.socket == null) this.register()

    this.socket.emit('serverentries', {
      type: entry.type,
      content: entry.content,
      conversationID: entry.conversationID,
      sender: {
        legacyID: `${connectedUser.id}`,
        photoIdentity: connectedUser.photoIdentity,
        firstName: connectedUser.firstname,
        name: connectedUser.lastname,
      },
    })
  }

  seenMessage(entry, connectedUser) {
    if (this.socket == null) this.register(entry, connectedUser)
    this.socket.emit('serverevents', {
      type: entry.type,
      entryId: entry.entryId,
      conversationID: entry.conversationID,
      sender: {
        legacyID: `${connectedUser.id}`,
        photoIdentity: connectedUser.photoIdentity,
        firstName: connectedUser.firstname,
        name: connectedUser.lastname,
      },
    })
  }

  getNotification() {
    if (this.socket == null) this.register()

    return new Observable((observer) => {
      try {
        this.socket.on('notification', (message) => {
          observer.next(message)
        })
      } catch (e) {
        console.log('catched error in message: ', e)
      }
    })
  }
  createConversation(entry) {
    if (this.socket == null) this.register()
    this.socket.emit('serverconversations', entry)
  }
}

export default new SocketDiscussion()
