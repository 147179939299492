import { connect } from 'react-redux'
import Login from '../../pages/Login'
import { login, googleLogin, microsoftLogin } from '../../redux/user/actions'
import { getLoginError, getIsLoading } from '../../redux/user/selectors'

const mapStateToProps = (state) => ({
  loginError: getLoginError(state),
  isLoading: getIsLoading(state),
})

const mapDisptachToProps = (dispatch) => ({
  userLogin: (email, password) => dispatch(login(email, password)),
  userLoginGoogleAccount: (googleToken) => dispatch(googleLogin(googleToken)),
  userLoginMicrosoftAccount: (azureToken) => dispatch(microsoftLogin(azureToken)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Login)
