import moment from 'moment'
import checkExpirationDate, {
  DRIVERS_FILTER_EXPIRATION_DATE,
} from '../../utils/checkExpirationDate'

export default () => {
  checkExpirationDate(DRIVERS_FILTER_EXPIRATION_DATE)

  return {
    driversOptimiser: [],
    isLoadingDriversOptimiser: false,

    drivers: [],
    filter: {
      actif: true,
      endTime: moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm'),
      limit: null,
      offset: null,
      startTime: moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm'),
      order: 'DESC',
    },
    isLoading: false,
    error: {},
    driversOptimiserCount: 0,
  }
}
