import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { replaceDotWithComma } from '../../../../utils/floatFormatting'

import './style.scss'
import RoomIcon from '@mui/icons-material/Room'
import EuroIcon from '@mui/icons-material/Euro'
import WeightIcon from '../../../../assets/icons/weight.svg'
import VolumeIcon from '../../../../assets/icons/volume.svg'
const initialValue = {
  weight: 0,
  volume: 0,
  number: 0,
  price: 0,
}
const SelectedOrdersRecap = ({ selectedCourses, shipments }) => {
  const [dataCounters, setDataCounters] = useState(initialValue)
  useEffect(() => {
    let accumulation = initialValue
    if (selectedCourses.length) {
      shipments
        .filter(({ code }) => selectedCourses.includes(code))
        .map(({ weight, volume, montantHT }) => {
          accumulation = {
            weight: accumulation.weight + weight || 0,
            volume: accumulation.volume + volume || 0,
            price: accumulation.price + montantHT || 0,
            number: selectedCourses.length,
          }
        })
    }
    accumulation = {
      ...accumulation,
      volumeToDisplay: getDisplayValue(accumulation.volume),
      weightToDisplay: getDisplayValue(accumulation.weight),
      priceToDisplay: getDisplayValue(accumulation.price),
    }
    setDataCounters(accumulation)
  }, [selectedCourses])
  const getDisplayValue = (value) =>
    value === 0 ? 0 : replaceDotWithComma(parseFloat(value).toFixed(2))
  return (
    <>
      <div
        className="cell-item-container"
        style={{ overflowX: 'auto', minHeight: 'auto' }}
      >
        <CellItem
          icon={<RoomIcon className="cell-icon" />}
          content={dataCounters.number}
          unit={'Sélectionnées'}
        />
        <CellItem
          icon={<img src={WeightIcon} alt="total weight" className="cell-icon" />}
          content={dataCounters.weightToDisplay}
          unit={'Kg'}
        />
        <CellItem
          icon={
            <img
              src={VolumeIcon}
              alt="total volume"
              style={{ height: '25px', width: '23px' }}
              className="cell-icon"
            />
          }
          content={dataCounters.volumeToDisplay}
          unit={'m³'}
        />
        <CellItem
          icon={<EuroIcon className="cell-icon" />}
          content={dataCounters.priceToDisplay}
          unit={'Ht'}
        />
      </div>
    </>
  )
}
SelectedOrdersRecap.propTypes = {
  selectedCourses: PropTypes.array.isRequired,
  shipments: PropTypes.array.isRequired,
}

const CellItem = ({ icon, content, unit }) => (
  <div className="cell-item">
    {icon}
    <span className="cell-text">
      {content} {unit}
    </span>
  </div>
)

CellItem.propTypes = {
  icon: PropTypes.any,
  content: PropTypes.any,
  unit: PropTypes.string,
}

export default SelectedOrdersRecap
