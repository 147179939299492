import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import {
  Box,
  TextField,
  Button,
  FormControlLabel,
  CircularProgress,
} from '@mui/material'
import AddIcon from '../../../../assets/icons/settingsCorner/addIcon'
import {
  StyledDateRangePicker,
  StyledDivContent,
  StyledDivTopInputs,
  StyledInputModal,
  StyledInputTitle,
  sxInput,
} from '../../styled'
import { useFormik } from 'formik'
import './styleModal.scss'
import AutoComplete from '../../../../components/AutoComplete'
import { useTheme } from '@mui/material/styles'
import { locale, localeMap } from '../../../../utils/values'
import moment from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import { validateUploadFile } from '../../../../utils/validator/UploadFileDiscussion'
import { ClickAwayListener } from '@mui/base'
import { StyledSwitch } from '../../../../components/switch/style'
import fileDecoded from '../../../../utils/fileDecoded'
import UploadFileIcon from '../../../../assets/icons/settingsCorner/uploadFileIcon'

// which keys are symmetrical to our values/initialValues
const validate = ({ formationId }) => {
  const errors = {}
  if (!formationId) {
    errors.formationId = 'formation est obligatoire'
  }

  return errors
}

function SessionModal({
  open,
  isEdit,
  handleClose,
  modalTitle,
  createSession,
  updateSession,
  isLoadingSessionAction,
  success,
  errorMessage,
  resetSessionConfig,
  formations,
  fetchFormations,
  session,
  isLoadingSession,
  participants,
  isLoadingParticipants,
  fetchParticipants,
  searchParticipants,
  resetParticipant,
  participantsCount,
  fetchMoreParticipants,
  setErrorUploadFile,
  sessionId,
  fetchMoreFormation,
  formationsCount,
  searchFormations,
}) {
  const [select, setSelect] = useState(false)
  const [init, setInit] = useState(false)
  const [participantSearch, setSearchParticipant] = useState('')
  const [offset, setOffset] = useState(25)
  const [offsetFormation, setOffsetFormation] = useState(25)

  const [openDatePicker, setOpenDatePicker] = useState(false)
  //   Date picker :
  const [selectionState, setSelectionState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const [searchFormation, setSearchFormation] = useState('')
  const UserId = String(JSON.parse(localStorage.getItem('currentUser'))?.id)
  const theme = useTheme()
  const toggleDatePicker = () => {
    setOpenDatePicker(!openDatePicker)
  }
  const formik = useFormik({
    initialValues: {
      formationId: null,
      participants: [],
      place: '',
      former: '',
      startDate: new Date(),
      endDate: new Date(),
      isExpirable: false,
      expiredIn: '',
      files: [],
    },
    validate,
    onSubmit: (values) => {
      const participantsDto = values.participants.map((el) => ({
        participantId: el.id,
        isApte: false,
      }))
      const sessionDto = {
        userId: UserId,
        formationId: values.formationId.id,
        participantSessionDto: participantsDto,
        place: values.place,
        former: values.former,
        startDate: values.startDate,
        endDate: values.endDate,
        isExpirable: values.isExpirable,
        expiredIn: values.isExpirabl ? 0 : values.expiredIn,
        id: isEdit ? sessionId : undefined,
      }
      let formData = new FormData()
      formData.append('sessionDto', JSON.stringify(sessionDto))
      Array.from(values.files).forEach((file) => {
        formData.append('files', file)
      })

      if (isEdit) {
        updateSession(formData)
      } else {
        createSession(formData)
      }
    },
  })
  useEffect(() => {
    if (isEdit && !isLoadingSession) {
      const filesToDecode = fileDecoded(session.files)
      formik.setValues({
        ...formik.values,
        formationId: session.formation,
        participants: session.partipantSessions,
        startDate: session.startDate,
        expiredIn: session.expiredIn,
        place: session.place,
        former: session.former,
        isExpirable: session.isExpirable,
        files: filesToDecode,
      })
    }
  }, [isEdit, isLoadingSession])

  useEffect(() => {
    if (success) {
      handleClose()
      resetSessionConfig()
    }
  }, [success])

  useEffect(() => {
    fetchFormations()
    fetchParticipants()
    setInit(true)
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', resetParticipant)

    return () => {
      resetParticipant()
      window.removeEventListener('beforeunload', resetParticipant)
    }
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (participantSearch.length > 0 && !select) {
        searchParticipants(participantSearch)
        setOffset(25)
      } else if (participantSearch === '') {
        if (init) {
          searchParticipants('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [participantSearch])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFormation.length > 0 && !select) {
        searchFormations(searchFormation)
        setOffsetFormation(25)
      } else if (searchFormation === '') {
        if (init) {
          searchFormations('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchFormation])

  const onSelectParticipant = (value) => {
    if (value) {
      formik.setFieldValue('participants', value)
      setSelect(true)
    }
  }

  const verifEdit = () => {
    return (
      isEdit &&
      !isLoadingSession &&
      formik.values.formationId?.id == session.formation.id &&
      formik.values.participants == session.partipantSessions &&
      formik.values.startDate == session.startDate &&
      formik.values.expiredIn == session.expiredIn &&
      formik.values.place == session.place &&
      formik.values.former == session.former &&
      formik.values.files.length == session.files.length &&
      formik.values.files
        .map((el) => el.name)
        .every((el) => session.files.map((file) => file.fileName).includes(el))
    )
  }
  const handleOnChange = (ranges) => {
    const { selection } = ranges
    formik.setFieldValue('startDate', selection.startDate)
    formik.setFieldValue('endDate', selection.endDate)

    setSelectionState([selection])
  }
  const loadMoreParticipants = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      participantsCount >= offset
    ) {
      setOffset(offset + 25)
      fetchMoreParticipants(offset, participantSearch)
    }
  }
  const loadMoreFormations = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      formationsCount >= offsetFormation
    ) {
      setOffsetFormation(offsetFormation + 25)
      fetchMoreFormation(offsetFormation, searchFormation)
    }
  }

  const onUpload = (event) => {
    const error = validateUploadFile(Array.from(event.target.files))
    if (event.target.files.length > 0) {
      const findFile = formik.values.files
        .map((file) => file.name)
        .indexOf(event.target.files[0].name)

      if (error.isError) {
        setErrorUploadFile(error.message)
      } else if (findFile == -1) {
        formik.setFieldValue('files', [
          ...formik.values.files,
          ...event.target.files,
        ])
      }
    }
    event.target.value = null
  }
  const deleteFile = (nameFile) => {
    const indexFile = formik.values.files.map((file) => file.name).indexOf(nameFile)

    if (indexFile !== -1) {
      formik.values.files.splice(indexFile, 1)
      formik.setFieldValue('files', formik.values.files)
    }
  }
  const onChangeSearchParticipant = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchParticipant(val)
    } else {
      formik.setFieldValue('participants', [])

      setSearchParticipant('')
      setSelect(false)
    }
  }
  const onChangeSearchFormation = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchFormation(val)
    } else {
      formik.setFieldValue('formationId', '')

      setSearchFormation('')
      setSelect(false)
    }
  }
  const onSelectFormation = (value) => {
    if (value) {
      formik.setFieldValue('formationId', value)
      setSelect(true)
    }
  }
  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Dialog
          maxWidthDialog={'md'}
          open={open}
          title={modalTitle}
          iconTitle={<AddIcon />}
          style={errorMessage ? { display: 'none' } : {}}
          content={
            <StyledDivContent>
              {!isLoadingSession ? (
                <>
                  <Box style={{ paddingBottom: '10px' }}>
                    <StyledInputTitle>Formation</StyledInputTitle>
                    <AutoComplete
                      value={formik.values.formationId}
                      onChange={(event, value) => {
                        onSelectFormation(value)
                      }}
                      onInputChange={(event, value) =>
                        onChangeSearchFormation(value)
                      }
                      id="Session-Formation"
                      options={formations}
                      name="formationId"
                      getOptionLabel={(option) => option.name}
                      variant="outlined"
                      placeholder={'Choisir une formation'}
                      sxInputStyle={sxInput}
                      ListboxProps={{
                        onScroll: loadMoreFormations,
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id} id={option.id}>
                          <div className="AutoCompteFilterOption">{option.name}</div>
                        </li>
                      )}
                      error={formik.errors.formationId && formik.touched.formationId}
                      helperText={
                        formik.errors.formationId && formik.touched.formationId
                          ? formik.errors.formationId
                          : null
                      }
                    />
                  </Box>
                  <Box sx={{ marginBottom: '20px' }}>
                    <StyledInputTitle>Participant </StyledInputTitle>
                    <div style={{ display: 'flex' }}>
                      <AutoComplete
                        freeSolo
                        value={formik.values?.participants}
                        name={'participants'}
                        ///fetch participants
                        onChange={(event, value) => {
                          onSelectParticipant(value)
                        }}
                        onInputChange={(event, value) =>
                          onChangeSearchParticipant(value)
                        }
                        id="Sessions-Participants"
                        options={participants}
                        getOptionLabel={(option) => option.firstName}
                        variant="outlined"
                        placeholder={'Choisir un participant'}
                        sxInputStyle={sxInput}
                        multiple={true}
                        isOptionEqualToValue={(option, value) =>
                          option.firstName === value.firstName
                        }
                        error={
                          formik.errors.participants && formik.touched.participants
                        }
                        helperText={
                          formik.errors.participants && formik.touched.participants
                            ? formik.errors.participants
                            : null
                        }
                        fullWidth={true}
                        ListboxProps={{
                          onScroll: loadMoreParticipants,
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            <div className="AutoCompteFilterOption">
                              {option.firstName}
                            </div>
                          </li>
                        )}
                        loading={isLoadingParticipants}
                        inputValue={participantSearch}
                      />
                    </div>
                  </Box>
                  <StyledDivTopInputs>
                    <StyledInputModal>
                      <StyledInputTitle>Date</StyledInputTitle>

                      <ClickAwayListener
                        onClickAway={() => setOpenDatePicker(false)}
                      >
                        <Box>
                          <TextField
                            onClick={toggleDatePicker}
                            placeholder="Date Début "
                            id="end-start-date"
                            name="startDate"
                            variant="outlined"
                            //onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            InputProps={{
                              sx: sxInput,
                            }}
                            value={`${moment(
                              new Date(selectionState[0].startDate)
                            ).format('DD MMM YYYY')} - ${moment(
                              new Date(selectionState[0].endDate)
                            ).format('DD MMM YYYY')}`}
                            error={
                              formik.errors.startDate && formik.touched.startDate
                            }
                            helperText={
                              formik.errors.startDate && formik.touched.startDate
                                ? formik.errors.startDate
                                : null
                            }
                          />

                          {openDatePicker ? (
                            <StyledDateRangePicker
                              locale={localeMap[locale]}
                              staticRanges={[]}
                              rangeColors={[
                                theme.palette.primary.main,
                                '#ffffff',
                                theme.palette.primary.main,
                              ]}
                              onChange={handleOnChange}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              ranges={selectionState}
                              //direction="horizontal"
                              direction="down"
                            />
                          ) : null}
                        </Box>
                      </ClickAwayListener>
                    </StyledInputModal>
                    <Box style={{ position: 'relative', bottom: '8px' }}>
                      <FormControlLabel
                        sx={{ fontWeight: 'bold' }}
                        control={
                          <StyledSwitch
                            size="meduim"
                            checked={formik.values.isExpirable}
                            onChange={() =>
                              formik.setFieldValue(
                                'isExpirable',
                                !formik.values.isExpirable
                              )
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={
                          <StyledInputTitle
                            disabled={!formik.values.isExpirable}
                            noPadding={true}
                          >
                            {' '}
                            Expirable{' '}
                          </StyledInputTitle>
                        }
                      />
                      <TextField
                        type="number"
                        disabled={!formik.values.isExpirable}
                        placeholder="Expire dans:"
                        id="Session-place"
                        name="expiredIn"
                        variant="outlined"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          sx: sxInput,
                        }}
                        value={formik.values.expiredIn}
                        error={formik.errors.expiredIn && formik.touched.expiredIn}
                        helperText={
                          formik.errors.place && formik.touched.expiredIn
                            ? formik.errors.expiredIn
                            : null
                        }
                      />
                    </Box>
                  </StyledDivTopInputs>
                  <StyledDivTopInputs>
                    <StyledInputModal>
                      <StyledInputTitle>Lieu</StyledInputTitle>
                      <TextField
                        placeholder="Lieu"
                        id="Session-place"
                        name="place"
                        variant="outlined"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          sx: sxInput,
                        }}
                        value={formik.values.place}
                        error={formik.errors.place && formik.touched.place}
                        helperText={
                          formik.errors.place && formik.touched.place
                            ? formik.errors.place
                            : null
                        }
                      />
                    </StyledInputModal>
                    <StyledInputModal>
                      <StyledInputTitle>Formateur </StyledInputTitle>
                      <TextField
                        id="Session-former"
                        name="former"
                        variant="outlined"
                        placeholder="Formateur"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          sx: sxInput,
                        }}
                        value={formik.values.former}
                        error={formik.errors.former && formik.touched.former}
                        helperText={
                          formik.errors.former && formik.touched.former
                            ? formik.errors.former
                            : null
                        }
                      />
                    </StyledInputModal>
                  </StyledDivTopInputs>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      marginBottom: '20px',
                    }}
                  >
                    <input
                      type="file"
                      style={{
                        opacity: '0',
                        width: '100%',
                        height: '41px',
                        position: 'absolute',
                        zIndex: '2',
                        cursor: 'pointer',
                      }}
                      onChange={onUpload}
                      //accept="application/pdf, image/*"
                      multiple
                    />
                    <Button
                      variant="contained"
                      sx={{
                        width: '100%',
                        border: '1px dashed #EAEAEA',
                        background: '#F3F3F3',
                        color: '#3C3C3C',
                        boxShadow: 'none',
                        height: '41px',
                        textTransform: 'unset',
                        ':hover': {
                          background: '#dfdfdf',
                          boxShadow: 'none',
                        },
                      }}
                      startIcon={<UploadFileIcon />}
                    >
                      Joindre un fichier / émargement
                    </Button>
                  </Box>
                  {formik.values.files?.length > 0 && (
                    <Box
                      sx={{
                        padding: '10px 0px',
                      }}
                    >
                      {formik.values.files.map((file, index) => (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '7px 15px',
                          }}
                          key={index}
                        >
                          <Box component="span" sx={{ display: 'block' }}>
                            {formik.values.files[index].name}
                          </Box>

                          <DeleteIcon
                            id={'Files-delete'}
                            style={{ cursor: 'pointer', color: '#B70707' }}
                            onClick={() => deleteFile(file.name)}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                </>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress sx={{ color: '#1976D2' }} />
                </Box>
              )}
            </StyledDivContent>
          }
          handleClose={handleClose}
          handleClickAction={formik.handleSubmit}
          disabled={verifEdit() || isLoadingSession}
          isLoadingButtonAction={isLoadingSessionAction}
        />{' '}
      </form>
    </>
  )
}
SessionModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  createSession: PropTypes.func,
  errorMessage: PropTypes.string,
  isEdit: PropTypes.bool,
  modalTitle: PropTypes.string,
  isLoadingSessionAction: PropTypes.bool,
  success: PropTypes.bool,
  updateSession: PropTypes.func,
  resetSessionConfig: PropTypes.func,
  formations: PropTypes.array,
  fetchFormations: PropTypes.func,
  isLoadingFormationsList: PropTypes.bool,
  participants: PropTypes.array,
  isLoadingParticipants: PropTypes.bool,
  fetchParticipants: PropTypes.func,
  searchParticipants: PropTypes.func,
  resetParticipant: PropTypes.func,
  participantsCount: PropTypes.number,
  fetchMoreParticipants: PropTypes.func,
  setErrorUploadFile: PropTypes.func,
  files: PropTypes.array,
  session: PropTypes.object,
  isLoadingSession: PropTypes.bool,
  sessionId: PropTypes.number,
  fetchMoreFormation: PropTypes.func,
  formationsCount: PropTypes.number,
  searchFormations: PropTypes.func,
}

export default SessionModal
