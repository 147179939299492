import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

export default function InfoStep({ step, setCodeRayon, codeRayon }) {
  switch (step) {
    case 5:
      return (
        <div style={{ padding: '10px 0px' }}>
          <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>Code Rayon</div>
          <TextField
            id="code-rayon"
            name="code-rayon"
            variant="outlined"
            onChange={(e) =>
              e.target.value.trim() != ''
                ? setCodeRayon(e.target.value)
                : setCodeRayon(undefined)
            }
            value={codeRayon}
            placeholder="Code Rayon"
          />
        </div>
      )
    default:
      return <></>
  }
}
InfoStep.propTypes = {
  step: PropTypes.string,
  setCodeRayon: PropTypes.func,
  codeRayon: PropTypes.string,
}
