import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import { locale, localeMap } from '../../../../../../../utils/values'
import { StyledDateRangePicker } from './style'
export default function RangePicker({ state, setState, disabled }) {
  const theme = useTheme()
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const dateRangeToday = [{ startDate: today, endDate: today, key: 'selection' }]

  return (
    <StyledDateRangePicker
      locale={localeMap[locale]}
      onChange={(item) => setState([item.selection])}
      moveRangeOnFirstSelection={false}
      ranges={state ? state : dateRangeToday}
      direction="horizontal"
      staticRanges={[]}
      rangeColors={[
        theme.palette.primary.main,
        '#ffffff',
        theme.palette.primary.main,
      ]}
      disabledDay={() => disabled}
    />
  )
}
RangePicker.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  disabled: PropTypes.bool,
}
