import { connect } from 'react-redux'
import ValidationSection from '../../../pages/main/EditOrders/Validation'
import {
  getOrdersState,
  getStepsState,
  getActiveStepState,
  getClientState,
  getIsOrderLoading,
  getTotalPriceState,
} from '../../../redux/shipment/selectors'

import { getPaymentForm, isLoading } from '../../../redux/shipment/selectors'

import { setCommand } from '../../../redux/shipment/actions'

import {
  submitFirstStepAction,
  setActiveStepAction,
  submitSecondStepAction,
} from '../../../redux/shipment/actions'

const mapStateToProps = (state) => ({
  orders: getOrdersState(state)[0],
  steps: getStepsState(state),
  activeStep: getActiveStepState(state),
  client: getClientState(state),
  isGetOrderLoading: getIsOrderLoading(state),
  paymentForm: getPaymentForm(state),
  totalPrice: getTotalPriceState(state),
  isLoading: isLoading(state),
})
const mapDisptachToProps = (dispatch) => ({
  submitFirstStep: () => dispatch(submitFirstStepAction()),
  setActiveStep: (step) => dispatch(setActiveStepAction(step)),
  submitSecondStep: () => dispatch(submitSecondStepAction()),
  setCommand: (command) => dispatch(setCommand(command)),
})

export default connect(mapStateToProps, mapDisptachToProps)(ValidationSection)
