import React from 'react'
import Paper from '@mui/material/Paper'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import PropTypes from 'prop-types'
import AutoComplete from '../../AutoComplete'
import MenuItem from '@mui/material/MenuItem'

export default function AutocompleteFilter({
  dataList,
  isLoading,
  onChange,
  onSelect,
  defaultValue,
}) {
  return (
    <AutoComplete
      id="tags-outlined"
      multiple={true}
      options={dataList}
      value={defaultValue}
      getOptionLabel={(option) => option}
      variant="outlined"
      PaperComponent={({ children, ...other }) => (
        <Paper {...other}>
          {dataList?.length > 0 && (
            <div style={{ color: '#1976d2', padding: '10px 7px 7px' }}>
              Recherches récentes
            </div>
          )}
          {children}
        </Paper>
      )}
      sxInputStyle={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
          borderColor: '#E2F0FE ',
        },
        backgroundColor: 'white',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#E2F0FE',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#E2F0FE',
        },
      }}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          sx={{
            '&.MuiMenuItem-root': {
              '&:hover': {
                borderLeft: '4px solid #1976d2',
                '& .AutoCompteFilterOption': {
                  marginLeft: '-4px',
                },
              },
            },
          }}
        >
          <div className="AutoCompteFilterOption">{option}</div>
        </MenuItem>
      )}
      classes={{
        '&.MuiAutocomplete-root': { padding: '10px' },
        '& .MuiAutocomplete-tag': {
          color: '#202020',
          backgroundColor: '#E2F0FE',
          borderRadius: '3px',
        },
        '& .MuiAutocomplete-paper': {
          boxShadow:
            '0px 1px 2px 0px #e2f0fe, 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 7px 0px #e2f0fe;',
        },
      }}
      ChipProps={{
        deleteIcon: <ClearRoundedIcon style={{ height: '20px', width: '20px' }} />,
      }}
      onInputChange={(event, value) => onChange(value)}
      onChange={(event, value) => onSelect(value)}
      loading={isLoading}
    />
  )
}
AutocompleteFilter.propTypes = {
  dataList: PropTypes.array,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  defaultValue: PropTypes.string,
}
