import { connect } from 'react-redux'
import HistoriquePackage from '../../../../pages/main/Orders/DetailsDelivery/components/dialog/detailsColis/historiquePointage/dialogHistorique'

import {
  getHistoriquesPackage,
  getLoadingHistoriquesPackage,
} from '../../../../redux/orderPackage/selectors'
import { getPackageHistoriques } from '../../../../redux/orderPackage/actions'

const mapStateToProps = (state) => ({
  historiquesPackage: getHistoriquesPackage(state),
  isLoadingHistoriquesPackage: getLoadingHistoriquesPackage(state),
})
const mapDisptachToProps = (dispatch) => ({
  getPackageHistoriques: (idPackage) => dispatch(getPackageHistoriques(idPackage)),
})

export default connect(mapStateToProps, mapDisptachToProps)(HistoriquePackage)
