import { connect } from 'react-redux'

import FormationModal from '../../../pages/settings/components/Modals/FormationModal'

import {
  getConfigSuccess,
  getErrorMessageConfig,
  getKeyCreatedObject,
  getKeys,
  getKeysCount,
  getKeysListIsLoading,
} from '../../../redux/configuration/selectors'

import {
  fetchKeys,
  getKeysMore,
  onSearchCleCompetence,
  pageChanged,
  resetConfig,
} from '../../../redux/configuration/actions'

import {
  getErrorMessage,
  getFormationById,
  getIsLoadingFormation,
  getSuccess,
  getisLoadingFormationAction,
} from '../../../redux/formations/selectors'

import {
  createFormation,
  updateFormation,
  setErrorUploadFile,
  resetConfigFormation,
} from '../../../redux/formations/actions'

const mapStateToProps = (state) => ({
  isLoadingFormationAction: getisLoadingFormationAction(state),

  success: getSuccess(state),
  errorMessage: getErrorMessage(state),
  errorMessageConfig: getErrorMessageConfig(state),

  keys: getKeys(state),
  isLoadingKeysList: getKeysListIsLoading(state),
  keysCount: getKeysCount(state),

  isLoadingFormation: getIsLoadingFormation(state),
  formation: getFormationById(state),

  successConfig: getConfigSuccess(state),
  keyCreatedObject: getKeyCreatedObject(state),
})

const mapDisptachToProps = (dispatch) => ({
  createFormation: (formation) => dispatch(createFormation(formation)),
  updateFormation: (newFormation) => dispatch(updateFormation(newFormation)),

  fetchKeys: () => {
    dispatch(onSearchCleCompetence(''))
    dispatch(fetchKeys())
  },
  searchKeys: (search) => {
    dispatch(onSearchCleCompetence(search))
    dispatch(pageChanged(0))
    dispatch(fetchKeys())
  },
  fetchMoreKeys: (offset, search) => {
    dispatch(onSearchCleCompetence(search))
    dispatch(pageChanged(offset))
    dispatch(getKeysMore(search))
  },
  setErrorUploadFile: (message) => dispatch(setErrorUploadFile(message)),
  resetKey: () => dispatch(resetConfig()),
  resetConfigFormation: () => dispatch(resetConfigFormation()),
})

export default connect(mapStateToProps, mapDisptachToProps)(FormationModal)
