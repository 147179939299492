export const getVehiculesState = ({ vehicules }) => vehicules

export const getIsLoadingVehiculesList = (state) =>
  getVehiculesState(state).isLoadingVehiculesList

export const getVehiculesList = (state) => getVehiculesState(state).vehiculesList

export const getVehiculesListCount = (state) =>
  getVehiculesState(state).vehiculesListCount

export const getErrorMessage = (state) => getVehiculesState(state).errorMessage

export const getIsErrorFetch = (state) => getVehiculesState(state).isErrorFetch

export const getIsSuccessRefresh = (state) =>
  getVehiculesState(state).isSuccessRefresh

export const getSuccess = (state) => getVehiculesState(state).success

export const getIsLoadingVehiculeAction = (state) =>
  getVehiculesState(state).isLoadingVehiculeAction
