import React from 'react'
import { Marker } from '@react-google-maps/api'
import PropTypes from 'prop-types'

export default function MarkerWithTitle({ marker, index }) {
  return (
    <Marker
      key={`marker_with_title_${index}`}
      position={marker.position}
      title={marker.title}
      icon={marker.icon}
      animation={marker.animation}
    />
  )
}
MarkerWithTitle.propTypes = {
  marker: PropTypes.any,
  showIndex: PropTypes.number,
  index: PropTypes.number,
  setShow: PropTypes.func,
}
