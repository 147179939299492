import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import IconCategory from '../../../../assets/icons/category'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import { styled } from '@mui/material/styles'

const StyledMenu = styled(Menu)(({ widthPaper }) => ({
  '& .MuiMenu-paper': {
    border: '1px solid #d3d4d5',
    width: widthPaper,
  },
  '& .MuiMenu-list': {
    width: 280,
  },
}))

const styles = {
  listItem: (selected) => ({
    background: selected ? '#e2e3e6' : '',
  }),
}

export default function CategorieRecherche({
  changeCategorieFilter,
  categorieSelected,
  categorieRecherche,
  widthPaper,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const matches = useMediaQuery('(max-width:559px)')

  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        id="Categorie-button"
        onClick={handleClick}
        sx={{
          ...(!matches
            ? {
                '&.MuiButton-root': {
                  width: '193px',
                  backgroundColor: '#dadce07a !important',
                  boxShadow: 'none !important',
                  borderRadius: '4px 0px 0px 4px !important',
                  height: '100%',
                  color: '#373D57',
                },
              }
            : {
                '&.MuiButton-root': {
                  borderRadius: '4px 0px 0px 4px !important',
                  height: '100%',
                },
              }),
          backgroundColor: '#dadce07a !important',
          color: '#373D57',
          boxShadow: 'none',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {!matches ? (
            <span
              style={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'none' }}
            >
              {categorieSelected?.iconSelect}
              {categorieSelected.label}
            </span>
          ) : (
            <IconCategory />
          )}

          <>{anchorEl ? <ExpandLess /> : <ExpandMore />}</>
        </div>
      </Button>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        widthPaper={widthPaper}
        PaperProps={{
          classes: {
            root: styles.list,
          },
        }}
      >
        {categorieRecherche.map((categorie, index) => (
          <MenuItem
            id={`Categorie-${index}`}
            key={index}
            onClick={() => {
              changeCategorieFilter(categorie)
              handleClose()
            }}
            sx={{
              '&.MuiMenuItem-root': {
                minHeight: '38px !important',
                '& .MuiListItemText-root': {
                  flex: 'none',
                },
              },
            }}
            style={styles.listItem(categorieSelected.code === categorie.code)}
          >
            <ListItemText id={categorie.code} primary={categorie.label} />
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
CategorieRecherche.propTypes = {
  changeCategorieFilter: PropTypes.func,
  categorieSelected: PropTypes.object,
  categorieRecherche: PropTypes.array,
  widthPaper: PropTypes.string,
}
