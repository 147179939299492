//action constants declaration
export const GET_ENTRY_CONVERSATION_LIST = 'GET_ENTRY_CONVERSATION_LIST'
export const GET_ENTRY_CONVERSATION_LIST_SUCESS =
  'GET_ENTRY_CONVERSATION_LIST_SUCESS'
export const GET_ENTRY_CONVERSATION_LIST_FAILURE =
  'GET_ENTRY_CONVERSATION_LIST_FAILURE'

export const GET_ENTRY_CONVERSATION_LIST_MORE = 'GET_ENTRY_CONVERSATION_LIST_MORE'
export const GET_ENTRY_CONVERSATION_LIST_MORE_SUCESS =
  'GET_ENTRY_CONVERSATION_LIST_MORE_SUCESS'
export const GET_ENTRY_CONVERSATION_LIST_MORE_FAILURE =
  'GET_ENTRY_CONVERSATION_LIST_MORE_FAILURE'
export const SUBSCRIBE_TO_CONVERSATIONS_TOPIC = 'SUBSCRIBE_TO_CONVERSATIONS_TOPIC'
export const RECEIVE_DATA_FROM_CONNVERSATION_TOPIC =
  'RECEIVE_DATA_FROM_CONNVERSATION_TOPIC'

export const CLEAN_CONVERSATION_LIST = 'CLEAN_CONVERSATION_LIST'
export const SOCKET_REGISTRED = 'SOCKET_REGISTRED'
export const SOCKET_NOT_REGISTRED = 'SOCKET_NOT_REGISTRED'
export const GET_NOTIFICATION_CONVERSATION_LIST =
  'GET_NOTIFICATION_CONVERSATION_LIST'
export const GET_NOTIFICATION_CONVERSATION_LIST_SUCESS =
  'GET_NOTIFICATION_CONVERSATION_LIST_SUCESS '
export const GET_NOTIFICATION_CONVERSATION_LIST_FAILURE =
  'GET_NOTIFICATION_CONVERSATION_LIST_FAILURE'
export const RECEIVE_REMOVE_NOTIFICATION = 'RECEIVE_REMOVE_NOTIFICATION'
export const RECEIVE_ADD_NOTIFICATION = 'RECEIVE_ADD_NOTIFICATION'
export const NOT_FOCUS_NOTIFICATION = 'NOT_FOCUS_NOTIFICATION'
export const FOCUS_NOTIFICATION = 'FOCUS_NOTIFICATION'
export const GET_ENTRY_CONVERSATION_LIST_BY_SEARCH =
  'GET_ENTRY_CONVERSATION_LIST_BY_SEARCH'
export const GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_SUCESS =
  'GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_SUCESS'
export const GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_FAILURE =
  'GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_FAILURE'

export const GET_ENTRY_CONVERSATION_LIST_DELIVERY =
  'GET_ENTRY_CONVERSATION_LIST_DELIVERY'
export const GET_ENTRY_CONVERSATION_LIST_DELIVERY_SUCESS =
  'GET_ENTRY_CONVERSATION_LIST_DELIVERY_SUCESS '
export const GET_ENTRY_CONVERSATION_LIST_DELIVERY_FAILURE =
  'GET_ENTRY_CONVERSATION_LIST_DELIVERY_FAILURE'

export const RECEIVE_DATA_FROM_CONNVERSATION_DELIVERY_TOPIC =
  'RECEIVE_DATA_FROM_CONNVERSATION_DELIVERY_TOPIC'
//urls declaration
export const GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE =
  'GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE'
export const GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_SUCESS =
  'GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_SUCESS'
export const GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_FAILURE =
  'GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_FAILURE'
export const LOADING_OPEN_CLOSE_CONVERSATION = 'LOADING_OPEN_CLOSE_CONVERSATION'

export const GET_ENTRY_CONVERSATION_LIST_URL =
  'conversation/searchConversationWithTypes'

export const SET_ID_NEW_CONVERSATION = 'SET_ID_NEW_CONVERSATION'
export const REMOVE_ID_NEW_CONVERSATION = 'REMOVE_ID_NEW_CONVERSATION'
export const SET_ID_CONVERSATION = 'SET_ID_CONVERSATION'
export const REMOVE_ID_CONVERSATION = 'REMOVE_ID_CONVERSATION'

export const GET_SUBJECT_CONVERSATION = 'GET_SUBJECT_CONVERSATION'
export const GET_SUBJECT_CONVERSATION_SUCESS = 'GET_SUBJECT_CONVERSATION_SUCESS'
export const GET_SUBJECT_CONVERSATION_FAILURE = 'GET_SUBJECT_CONVERSATION_FAILURE'
