import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import QRCodeComponent from './QRcode'
import CustomButton from './CustomButton'
import { styled } from '@mui/material/styles'

import { ETIQUETTE, LDV, INVOICE, INVOICE_ASSEMBLY } from '../../../utils/values'

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  '&.MuiCircularProgress-root': {
    color: 'rgba(0, 0, 0, 0.54) !important',
    width: '35px !important',
    height: '35px !important',
  },
}))

export default function GenerateFiles({
  shipment,
  open,
  hideDialog,
  codeAssembly,
  downloadFiles,
  isLoadingFiles,
}) {
  const getFileDetail = (type, code) => {
    return {
      code: code,
      generateApi:
        type === INVOICE || type === INVOICE_ASSEMBLY
          ? 'generateFacture'
          : type === LDV
          ? 'generateLettreDeVoiture'
          : 'generateEtiquetteCourse',
      codeType: type === INVOICE_ASSEMBLY ? 'codeMontage' : 'codeCourse',
    }
  }
  const handleDownload = (type, code) => {
    const fileDetail = getFileDetail(type, code)
    downloadFiles(fileDetail)
  }

  const generateQrCode = (codeCourse, ldv) => {
    return JSON.stringify({
      codeCourse,
      ldv,
    })
  }
  const getCodeCourse = () => {
    return shipment.length > 0 && shipment[0].code ? shipment[0].code : null
  }
  return (
    <>
      {shipment && (
        <div className="file-generator-container">
          <Dialog
            open={open}
            onClose={() => hideDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{
              width: '95% !important',
              height: 700,
              margin: 'auto',
              zIndex: 2222,
            }}
          >
            <DialogTitle id="alert-dialog-title" className="dialogTitle">
              <p style={{ margin: 0 }}>{'Commande prise en compte'}</p>
            </DialogTitle>
            <DialogContent
              className="dialogContent"
              style={{ maxHeight: 350, overflow: 'hidden', textAlign: 'center' }}
            >
              {shipment.map(({ code, assembly, lettreDeVoiture }, index) => (
                <div
                  key={`delivery_${index}`}
                  style={
                    shipment.length > 1
                      ? index > 0
                        ? { width: '48%', float: 'right' }
                        : { width: '48%', float: 'left' }
                      : { width: '100%', margin: 'auto' }
                  }
                >
                  <div className="orders-title">
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: 600,
                        marginBottom: 0,
                      }}
                      key={index}
                    >
                      {lettreDeVoiture && codeAssembly.length !== 0
                        ? 'Livraison avec montage'
                        : lettreDeVoiture
                        ? 'Livraison'
                        : codeAssembly
                        ? 'Montage'
                        : ''}
                    </p>
                  </div>
                  <div className="qr-container">
                    <QRCodeComponent
                      qrcode={generateQrCode(code, lettreDeVoiture?.code)}
                      key={code}
                    />
                  </div>
                  <div
                    key={code}
                    className="button-alignment"
                    style={codeAssembly ? { height: 105 } : { height: 45 }}
                  >
                    <div
                      className="button-container"
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                        flexWrap: 'wrap',
                        boxSizing: 'border-box',
                        width: 400,
                        margin: 'auto',
                      }}
                    >
                      <CustomButton
                        text={lettreDeVoiture.code}
                        icon={<CloudDownloadIcon />}
                        handleClick={() => handleDownload(LDV, code)}
                        style={{
                          border: '1px solid rgba(0, 0, 0, 0.23) !important',
                          padding: '5px 15px !important',
                          textTransform: 'initial !important',
                          color: 'rgba(0, 0, 0, 0.87) !important',
                          fontSize: '12px !important',
                        }}
                        variant={'outlined'}
                        disabled={isLoadingFiles}
                      />

                      <CustomButton
                        text={'Etiquette'}
                        icon={<CloudDownloadIcon />}
                        handleClick={() => handleDownload(ETIQUETTE, code)}
                        style={{
                          border: '1px solid rgba(0, 0, 0, 0.23) !important',
                          padding: '5px 15px !important',
                          textTransform: 'initial !important',
                          color: 'rgba(0, 0, 0, 0.87) !important',
                          fontSize: '12px !important',
                        }}
                        variant={'outlined'}
                        disabled={isLoadingFiles}
                      />

                      {codeAssembly.length > 0 &&
                        codeAssembly.map((elem, i) => {
                          if (index === i)
                            return (
                              <CustomButton
                                key={elem}
                                text={`Montage: ${elem}`}
                                icon={<CloudDownloadIcon />}
                                handleClick={() =>
                                  handleDownload(INVOICE_ASSEMBLY, assembly)
                                }
                                style={{
                                  border: '1px solid rgba(0, 0, 0, 0.23) !important',
                                  padding: '5px 15px !important',
                                  textTransform: 'initial !important',
                                  color: 'rgba(0, 0, 0, 0.87) !important',
                                  fontSize: '12px !important',
                                }}
                                variant={'outlined'}
                                disabled={isLoadingFiles}
                              />
                            )
                        })}
                    </div>
                  </div>
                </div>
              ))}
            </DialogContent>
            <DialogActions>
              <CustomButton
                text={'Facture de livraison'}
                icon={<CloudDownloadIcon />}
                handleClick={() => handleDownload(INVOICE, getCodeCourse())}
                style={{
                  color: 'rgba(0, 0, 0, 0.87) !important',
                  boxShadow:
                    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                  backgroundColor: '#e0e0e0  !important',
                  textTransform: 'initial  !important',
                  fontSize: '12px !important',
                }}
                variant={'contained'}
                disabled={isLoadingFiles}
              />
              {isLoadingFiles ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '2px 5px',
                  }}
                >
                  <StyledCircularProgress />
                </div>
              ) : (
                <CustomButton
                  text={'OK'}
                  icon={null}
                  handleClick={() => hideDialog()}
                  style={{
                    color: 'rgba(0, 0, 0, 0.87) !important',
                    boxShadow:
                      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                    backgroundColor: '#e0e0e0  !important',
                    textTransform: 'initial  !important',
                    fontSize: '12px !important',
                  }}
                  variant={'contained'}
                  disabled={isLoadingFiles}
                />
              )}
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  )
}

GenerateFiles.propTypes = {
  shipment: PropTypes.array,
  open: PropTypes.bool,
  hideDialog: PropTypes.func,
  codeAssembly: PropTypes.array,
  downloadFiles: PropTypes.func,
  isLoadingFiles: PropTypes.bool,
}
