import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Checkbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types'
import Filter from '../../../../components/Table/components/Filters'
import TourTableContent from './TourTableContent'
import TourActions from './TourTableActions'

function TourTableDetails({
  tourList,
  countTours,
  checkActions,
  settings,
  columns,
  isSelected,
  selected,
  selectedTours,
  handleSelectAllClick,
  handleColumns,
  getChecked,
  handleClick,
  headTableColumns,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  isLoading,
  deleteTours,
  getDriversPlannings,
}) {
  const isSelectedAll = () => {
    const selectedTourlistEqual = tourList.every((tour) =>
      selected.includes(tour.ldt)
    )
    return (
      selected.length >= tourList.length &&
      tourList.length > 0 &&
      selectedTourlistEqual
    )
  }

  return (
    <>
      <div style={{ display: 'block', position: 'relative', height: '100%' }}>
        <TourActions
          numSelected={selected.length}
          selectedTours={selectedTours}
          isLoading={isLoading}
          deleteTours={deleteTours}
          getDriversPlannings={getDriversPlannings}
        />

        <TableContainer
          style={{
            maxHeight: selected.length == 1 ? '80%' : '86%',
          }}
        >
          <Table
            id={'tour_table'}
            stickyHeader
            aria-label="sticky table"
            style={{ backgroundColor: 'white', height: 'calc(100% - 116px)' }}
          >
            <TableHead id={'tour_table_head'}>
              <TableRow id={'tour_table_head_row'}>
                {[...checkActions, ...columns.concat([...settings])].map(
                  (column, index) =>
                    column.checked &&
                    (column.id === 'check' ? (
                      <TableCell
                        id={`tour_table_head_cell_${index}`}
                        key={`column_${index}`}
                        align="left"
                        style={{
                          color: '#2A304C',
                          backgroundColor: '#F7F8F9',
                        }}
                      >
                        <Checkbox
                          id={'check_all_tours'}
                          inputProps={{ 'aria-label': 'select all tours' }}
                          checked={
                            isSelectedAll() //&&(A vérifier)
                          }
                          onChange={handleSelectAllClick}
                          indeterminate={selected.length > 0}
                        />
                      </TableCell>
                    ) : column.id === 'settings' ? (
                      <Filter
                        idFilter={'filter-tourTable'}
                        columns={columns}
                        handleColumns={handleColumns}
                        localStorageNameKey={'tours_table_columns'}
                      />
                    ) : (
                      <TableCell
                        id={`column_${index}`}
                        key={`column_${index}`}
                        align="left"
                      >
                        {column.label}
                      </TableCell>
                    ))
                )}
              </TableRow>
            </TableHead>
            <TableBody id={'tour_table_body'}>
              {tourList.map((tour, index) => {
                const isItemSelected = isSelected(tour.ldt)
                const labelId = `tour-table-checkbox-${index}`
                return (
                  <TourTableContent
                    selectedTours={selectedTours}
                    index={index}
                    labelId={labelId}
                    tour={tour}
                    key={tour.ldt}
                    getChecked={getChecked}
                    columns={columns}
                    isItemSelected={isItemSelected}
                    handleClick={handleClick}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {countTours > 0 && (
          <TablePagination
            id={'tour_table_pagination'}
            rowsPerPageOptions={[25, 50, 100, 200, { label: 'All', value: -1 }]}
            component="div"
            count={countTours}
            rowsPerPage={rowsPerPage}
            page={page}
            colSpan={headTableColumns.length + 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>
    </>
  )
}
TourTableDetails.propTypes = {
  tourList: PropTypes.array,
  countTours: PropTypes.number,
  checkActions: PropTypes.array,
  settings: PropTypes.array,
  columns: PropTypes.array,
  isSelected: PropTypes.bool,
  selected: PropTypes.number,
  selectedTours: PropTypes.array,
  handleSelectAllClick: PropTypes.func,
  handleColumns: PropTypes.func,
  getChecked: PropTypes.func,
  handleClick: PropTypes.func,
  headTableColumns: PropTypes.array,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  isLoading: PropTypes.bool,
  openPlanification: PropTypes.bool,
  setOpenPlanification: PropTypes.func,
  deleteTours: PropTypes.func,
  getDriversPlannings: PropTypes.func,
}
export default TourTableDetails
