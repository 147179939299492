import React from 'react'
import AccordionItem from '../../containers/main/AccordionItem'
import PropTypes from 'prop-types'

export default function AccordionList({ list }) {
  return (
    <div style={{ width: '100%' }}>
      {list.map((e) => {
        return (
          <AccordionItem
            key={e.index}
            index={e.index}
            id={e.index}
            isFocused={e.isFocused}
            expanded={e.expanded}
            heading={e.heading}
            lock={e.lock}
            summary={e.summary.filter((el) => el.show)}
            details={e.details}
            path={e.path}
            color={e.color}
            handleChange={e.handleChange}
          />
        )
      })}
    </div>
  )
}

AccordionList.propTypes = {
  list: PropTypes.array,
}
