import { getOrderStatus } from '../../utils/getOrderStatus'

export const getShipmentState = ({ shipment }) => shipment
export const getMessage = (state) => getShipmentState(state).message

export const getIsShowMessage = (state) => getShipmentState(state).showMessage

export const getErrorMessage = (state) => getShipmentState(state).errorMessage
export const getCommand = (state) => getShipmentState(state).command
export const getPaymentForm = (state) => getShipmentState(state).paymentForm
export const getShowPopUp = (state) => getShipmentState(state).showPopUp
export const getShowPopUpDevis = (state) => getShipmentState(state).showPopUpDevis
export const getClients = (state) => getShipmentState(state).clients
export const getIsLoading = (state) => getShipmentState(state).isLoading

export const getError = (state) => getShipmentState(state).error
export const getDevis = (state) => getShipmentState(state).devis
export const getCalculatedPrice = (state) => getShipmentState(state).calculatedPrice

export const getCodePromoVerification = (state) =>
  getShipmentState(state).codePromoVerification
export const getNewCreatedCourse = (state) =>
  getShipmentState(state).createdNewCourse
export const isLoading = (state) => getShipmentState(state).isLoading
export const getCodeAssembly = (state) => getShipmentState(state).codeAssembly
export const oneWeekShipments = (state) => getShipmentState(state).oneWeekShipments

export const getIsLoadingFiles = (state) => getShipmentState(state).isLoadingFiles

export const getOrdersState = (state) => getShipmentState(state).orders
export const getShipmentTotalPriceState = (state) =>
  getShipmentState(state).orders[0]?.totalPrice
export const getTotalPriceState = (state) => getShipmentState(state).totalPrice
export const getStepsState = (state) => getShipmentState(state).steps
export const getIsOrderLoading = (state) => getShipmentState(state).isOrderLoading
export const getOrderErrorMessage = (state) => getShipmentState(state).errorMessage
export const getOldClient = (state) => getShipmentState(state).oldClient
export const getOldOrder = (state) => getShipmentState(state).oldOrders
export const getIsOrderDisabled = (state) =>
  getOrderStatus(getShipmentState(state).oldOrders)

export const getActiveStepState = (state) => getShipmentState(state).activeStep
export const getMapOptionsState = (state) => getShipmentState(state).mapOptions
export const getLettreDeVoiture = (state) => getShipmentState(state).ldvCreation
export const getMarkersState = (state) => getShipmentState(state).markers
export const getDirectionsState = (state) => getShipmentState(state).directions
export const getClientState = (state) => getShipmentState(state).client
export const getIsNewClientState = (state) => getShipmentState(state).isNewClient
export const getSelectedlientState = (state) =>
  getShipmentState(state).selectedClient

export const getCanalVenteResponse = (state) => getShipmentState(state).canalVente
export const getCanalVenteLoading = (state) =>
  getShipmentState(state).isGetCanalVenteLoading
export const getErrorGetSalesChannel = (state) =>
  getShipmentState(state).errorGetSalesChannel

export const getFamiliesArticleList = (state) =>
  getShipmentState(state)
    .orders.map(
      (e) =>
        e.shipment?.selectedSalesChannel?.articleFamilies ||
        e.assembly?.selectedSalesChannel?.articleFamilies ||
        []
    )
    .flat()
    .filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i)
export const getPaymentTypes = (state) => {
  const types = getShipmentState(state)
    .orders.map(
      (e) =>
        e.shipment?.selectedSalesChannel?.typesPaiement ||
        e.assembly?.selectedSalesChannel?.typesPaiement ||
        []
    )
    .flat()
  return types.filter((item, index) => types.indexOf(item) === index)
}
