export const SOCKET_REGISTER = 'SOCKET_REGISTER'
export const SOCKET_REMOVE_LISTENER = 'SOCKET_REMOVE_LISTENER'
export const SOCKET_GET_CONVERSATION = 'SOCKET_GET_CONVERSATION'
export const SOCKET_GET_CONVERSATION_DELIVERY = 'SOCKET_GET_CONVERSATION_DELIVERY'
export const SOCKET_GET_MESSAGE = 'SOCKET_GET_MESSAGE'
export const SOCKET_CLOSE_OPEN_CONVERSATION = 'SOCKET_CLOSE_OPEN_CONVERSATION'
export const SOCKET_SEND_MESSAGE = 'SOCKET_SEND_MESSAGE'
export const SOCKET_SEEN_MESSAGE = 'SOCKET_SEEN_MESSAGE'
export const SOCKET_GET_NOTIFICATION = 'SOCKET_GET_NOTIFICATION'
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT'
export const SOCKET_CREATION_CONVERSATION = 'SOCKET_CREATION_CONVERSATION'
