import React from 'react'

export function MdiStairs(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M15 5v4h-4v4H7v4H3v3h7v-4h4v-4h4V8h4V5h-7Z"
      ></path>
    </svg>
  )
}
export default MdiStairs
