import { connect } from 'react-redux'

import {
  unassignToChannel,
  unassignToDriver,
} from '../../../redux/configuration/actions'
import {
  getConfigSuccess,
  getErrorMessageConfig,
  getIsLoadingUnassign,
} from '../../../redux/configuration/selectors'
import VerificationModal from '../../../pages/settings/components/Modals/VerificationModal'

const mapStateToProps = (state) => ({
  isLoading: getIsLoadingUnassign(state),
  success: getConfigSuccess(state),
  errorMessage: getErrorMessageConfig(state),
})

const mapDisptachToProps = (dispatch) => ({
  verificationActon: (data, isDriver) =>
    dispatch(isDriver ? unassignToDriver(data) : unassignToChannel(data)),
})

export default connect(mapStateToProps, mapDisptachToProps)(VerificationModal)
