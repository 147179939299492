import { OrderConstants } from './constants'

import { httpFetch, httpPost, httpPostCallbackData, httpPut } from '../http/actions'
import queryString from 'query-string'
import moment from 'moment'
import { axiosHTTP } from '../../utils'
import { getFilterParams, setFilterCommande } from '../filter/actions'
import { setActionDuplicatedCourse, setIsActionShipmentDone } from './actions'

const api = process.env.REACT_APP_API_URL
const notificationApi = process.env.REACT_APP_NOTIFICATION_MS_URL
const dispatcherApi = process.env.REACT_APP_DISPATCHER_MS_URL
const log = process.env.REACT_APP_LOG_MS_URL

export const removeNull = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key]
    }
  }
  return obj
}

let CancelToken = axiosHTTP.CancelToken
let cancel

const OrderMiddleware = (store) => (next) => (action) => {
  //Fetch orders
  if (action.type === OrderConstants.FETCH_ORDER_BY_FILTER) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      filters: { filter, displayGrid },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${api}getCoursesByFilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: OrderConstants.FETCH_ORDER_BY_FILTER_SUCCESS,
        failure: OrderConstants.FETCH_ORDER_BY_FILTER_FAILURE,
        secData: displayGrid,
      })
    )
  }

  //Fetch orders by Agent
  if (action.type === OrderConstants.FETCH_ORDER_BY_AGENTS) {
    const {
      user: {
        collaborateur: { id },
      },

      filters: {
        filter: { limit, offset },
      },
    } = store.getState()
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const agents = [id]
    store.dispatch(
      httpPost({
        endPoint: `${api}getCoursesByAgents`,
        data: { agents, limit, offset },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: OrderConstants.FETCH_ORDER_BY_AGENTS_SUCCESS,
        failure: OrderConstants.FETCH_ORDER_BY_AGENTS_FAILURE,
      })
    )
  }

  //Search With Categrie in Corner
  if (action.type === OrderConstants.FETCH_COURSES_CORNER_SEARCH) {
    const { search } = action.payload
    const {
      filters: {
        filter: { limit, offset, codeStatus },
      },
    } = store.getState()
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const filterSend = {
      ...search,
      limit,
      offset,
      codeStatus:
        window.location.pathname.includes('programmer') && codeStatus.length > 0
          ? codeStatus.join(',')
          : undefined,
    }
    store.dispatch(
      httpFetch({
        endPoint: `${api}rechercheByType?${queryString.stringify(filterSend)}`,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
        success: OrderConstants.FETCH_COURSES_CORNER_SEARCH_SUCCESS,
        failure: OrderConstants.FETCH_COURSES_CORNER_SEARCH_FAILURE,
      })
    )
  }

  if (action.type === OrderConstants.FETCH_COURSES_CORNER_SEARCH_SAVE) {
    const { search } = action.payload
    const {
      filters: {
        filter: { limit, offset, orderBy, sort, codeStatus },
      },
    } = store.getState()
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const filterSend = {
      ...search,
      limit,
      offset,
      orderBy,
      sort,
      codeStatus:
        window.location.pathname.includes('programmer') && codeStatus.length > 0
          ? codeStatus.join(',')
          : undefined,
    }
    store.dispatch(
      httpFetch({
        endPoint: `${api}rechercheByType?${queryString.stringify(filterSend)}`,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
        success: OrderConstants.FETCH_COURSES_CORNER_SEARCH_SAVE_SUCCESS,
        failure: OrderConstants.FETCH_COURSES_CORNER_SEARCH_SAVE_FAILURE,
      })
    )
  }

  //Fetch related course (commande en cours)
  if (action.type === OrderConstants.FETCH_RELATED_COURSE_INFO) {
    const { code } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}getDetailsCourseLie?code=${code}`,
        success: OrderConstants.FETCH_RELATED_COURSE_INFO_SUCCESS,
        failure: OrderConstants.FETCH_RELATED_COURSE_INFO_FAILURE,
      })
    )
  }

  //Fetch les Arlertes (Dashboard)
  if (action.type === OrderConstants.GET_DELAYED_ORDERS) {
    store.dispatch(
      httpPost({
        endPoint: `${api}getDelayedCourses`,
        data: {},
        success: OrderConstants.GET_DELAYED_ORDERS_SUCCESS,
        failure: OrderConstants.GET_DELAYED_ORDERS_FAILURE,
      })
    )
  }

  //Fetch livraison details
  if (action.type === OrderConstants.GET_ORDER_BY_LDV) {
    const ldv = action.payload.ldv
    store.dispatch(
      httpFetch({
        endPoint: `${api}getCourseByLdv?code=${ldv}`,
        success: OrderConstants.GET_ORDER_BY_LDV_SUCCESS,
        failure: OrderConstants.GET_ORDER_BY_LDV_FAILED,
      })
    )
  }

  //Upload (Photo) Signature
  if (action.type === OrderConstants.UPLOAD_SIGNATURE) {
    const { file, codeCourse } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}course/uploadAttachments?codeCourse=${codeCourse}&clientSide=corner`,
        data: file,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: OrderConstants.UPLOAD_SIGNATURE_SUCCESS,
        failure: OrderConstants.UPLOAD_SIGNATURE_FAILED,
      })
    )
  }

  //Fetch Signature Order
  if (action.type === OrderConstants.GET_ORDER_SIGNATURE_BY_LDV) {
    const ldv = action.payload.ldv
    store.dispatch(
      httpFetch({
        endPoint: `${api}getSignaturePicturesCourseByLettreDeVoiture?code=${ldv}`,
        success: OrderConstants.GET_ORDER_SIGNATURE_BY_LDV_SUCCESS,
        failure: OrderConstants.GET_ORDER_SIGNATURE_BY_LDV_FAILED,
      })
    )
  }

  //Upload  Photos
  if (action.type === OrderConstants.UPLOAD_PHOTO) {
    const { file, codeCourse } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}course/uploadAttachments?codeCourse=${codeCourse}&clientSide=corner`,
        data: file,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: OrderConstants.UPLOAD_PHOTO_SUCCESS,
        failure: OrderConstants.UPLOAD_PHOTO_FAILED,
      })
    )
  }

  //Fetch Photos Order
  if (action.type === OrderConstants.GET_ORDER_PHOTO_BY_LDV) {
    const ldv = action.payload.ldv
    store.dispatch(
      httpFetch({
        endPoint: `${api}getDeliveryPicturesCourseByLettreDeVoiture?code=${ldv}`,
        success: OrderConstants.GET_ORDER_PHOTO_BY_LDV_SUCCESS,
        failure: OrderConstants.GET_ORDER_PHOTO_BY_LDV_FAILED,
      })
    )
  }

  //Download Photos Order
  if (action.type === OrderConstants.DOWNLOAD_IMAGE_ORDER) {
    const url = action.payload.urlImage
    const token = localStorage.getItem('token')

    const dataSend = {
      url,
      token,
    }
    window.open(`${api}downloadimage?${queryString.stringify(dataSend)}`)
  }

  //Upload  Attachment
  if (action.type === OrderConstants.UPLOAD_ATTACHMENT) {
    const { file, codeCourse } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${api}course/uploadAttachments?codeCourse=${codeCourse}`,
        data: file,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: OrderConstants.UPLOAD_ATTACHMENT_SUCCESS,
        failure: OrderConstants.UPLOAD_ATTACHMENT_FAILED,
      })
    )
  }

  //Fetch Attachment Order
  if (action.type === OrderConstants.GET_ORDER_ATTACHMENT_BY_LDV) {
    const ldv = action.payload.ldv
    store.dispatch(
      httpFetch({
        endPoint: `${api}getAttachmentsCourseByLettreDeVoiture?code=${ldv}`,
        success: OrderConstants.GET_ORDER_ATTACHMENT_BY_LDV_SUCCESS,
        failure: OrderConstants.GET_ORDER_ATTACHMENT_BY_LDV_FAILED,
      })
    )
  }

  //Fetch Pieces Jointe URL (S3)
  if (action.type === OrderConstants.GET_PIECE_JOINTE_ORDER) {
    const { url } = action.payload
    const dataSend = {
      url,
    }
    store.dispatch(
      httpFetch({
        endPoint: `${api}getS3FilePath?${queryString.stringify(dataSend)}`,
        success: OrderConstants.GET_PIECE_JOINTE_ORDER_SUCCESS,
        failure: OrderConstants.GET_PIECE_JOINTE_ORDER_FAILED,
      })
    )
  }

  //Visualisation Piece Jointe
  if (action.type === OrderConstants.GET_PIECE_JOINTE_ORDER_SUCCESS) {
    const { data } = action.payload
    let lien = data.path
    const link = document.createElement('a')
    link.target = '_blank'
    link.href = lien
    link.click()
  }

  //Fetch Notification de livraison
  if (action.type === OrderConstants.GET_NOTIFICATION_ORDER) {
    const codeCourse = action.payload.codeCourse
    store.dispatch(
      httpFetch({
        endPoint: `${notificationApi}notifications/${codeCourse}`,
        success: OrderConstants.GET_NOTIFICATION_ORDER_SUCCESS,
        failure: OrderConstants.GET_NOTIFICATION_ORDER_FAILED,
      })
    )
  }

  //Fetch historique delta cost
  if (action.type === OrderConstants.GET_HISTORIQUE_DELTA_COST_ORDER) {
    const ldv = action.payload.ldv
    store.dispatch(
      httpFetch({
        endPoint: `${api}getDeltaCostHistory?code=${ldv}`,
        success: OrderConstants.GET_HISTORIQUE_DELTA_COST_ORDER_SUCCESS,
        failure: OrderConstants.GET_HISTORIQUE_DELTA_COST_ORDER_FAILED,
      })
    )
  }

  //Fetch Historique de livraison
  if (action.type === OrderConstants.GET_HISTORIQUE_LIVRAISON_ORDER) {
    const codeCourse = action.payload.codeCourse
    store.dispatch(
      httpFetch({
        endPoint: `${api}client/getCourseInfosHistory?codeCourse=${codeCourse}`,
        success: OrderConstants.GET_HISTORIQUE_LIVRAISON_ORDER_SUCCESS,
        failure: OrderConstants.GET_HISTORIQUE_LIVRAISON_ORDER_FAILED,
      })
    )
  }

  //Fetch Historique de RDV
  if (action.type === OrderConstants.GET_HISTORIQUE_RDV_ORDER) {
    const { ldv } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}getAdminStatusFlowByLdv?code=${ldv}`,
        success: OrderConstants.GET_HISTORIQUE_RDV_ORDER_SUCCESS,
        failure: OrderConstants.GET_HISTORIQUE_RDV_ORDER_FAILED,
      })
    )
  }

  //Fetch Historique d'activité
  if (action.type === OrderConstants.GET_ACTIVITY_ORDER_HISTORY) {
    const codeCourse = action.payload.codeCourse
    store.dispatch(
      httpPost({
        endPoint: `${api}deliveryhistoryCorner`,
        data: { codeCourse },
        success: OrderConstants.GET_ACTIVITY_ORDER_HISTORY_SUCCESS,
        failure: OrderConstants.GET_ACTIVITY_ORDER_HISTORY_FAILED,
      })
    )
  }

  //Fetch Motifs (Annulation && RDI && Retrait impossible && Livraison impossible && terminee && enlevee)
  if (action.type === OrderConstants.FETCH_ORDER_REASONS) {
    const payload = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}changeReasons?${queryString.stringify(payload)}`,
        success: OrderConstants.FETCH_ORDER_REASONS_SUCCESS,
        failure: OrderConstants.FETCH_ORDER_REASONS_FAILURE,
      })
    )
  }

  //Change Status && (Deprogram Order && Desattribution Order && Anullation Order)
  if (action.type === OrderConstants.CHANGE_STATUS_COURSE_DISPATCH) {
    if (action.payload.duplicatedCourse) {
      store.dispatch(setActionDuplicatedCourse(true))
    }

    store.dispatch(
      httpPost({
        endPoint: `${dispatcherApi}dispatcher/scanlog`,
        data: action.payload,
        success: OrderConstants.CHANGE_STATUS_COURSE_DISPATCH_SUCCESS,
        failure: OrderConstants.CHANGE_STATUS_COURSE_DISPATCH_FAILURE,
      })
    )
  }

  //Rendez-Vous Impossible
  if (action.type === OrderConstants.SET_RDV_IMPOSSIBLE) {
    store.dispatch(
      httpPost({
        endPoint: `${api}course/rdvImpossible`,
        data: action.payload,
        success: OrderConstants.SET_RDV_IMPOSSIBLE_SUCCESS,
        failure: OrderConstants.SET_RDV_IMPOSSIBLE_FAILURE,
      })
    )
  }

  //Set prepared order (Action)
  if (action.type === OrderConstants.SET_PREPARED) {
    store.dispatch(
      httpPost({
        endPoint: `${api}course/prepared/set`,
        data: action.payload,
        success: OrderConstants.SET_PREPARED_SUCCESS,
        failure: OrderConstants.SET_PREPARED_FAILURE,
      })
    )
  }

  //Program Course
  if (action.type === OrderConstants.PROGRAM_COURSE) {
    store.dispatch(
      httpPost({
        endPoint: `${dispatcherApi}dispatcher/scheduleDelivery`,
        data: action.payload,
        success: OrderConstants.PROGRAM_COURSE_SUCESS,
        failure: OrderConstants.PROGRAM_COURSE_FAILURE,
      })
    )
  }

  //Attribution Chauffeur warrning
  if (action.type === OrderConstants.ASSIGN_DRIVER) {
    const data = {
      ...action.payload.data.data,
      state: 'ASSIGNED',
      collaborateurId: JSON.parse(localStorage.getItem('currentUser')).id,
      method: 'corner/attributionWarning',
      datetime: moment(new Date()).format(),
    }

    Promise.all(
      action.payload.data.selectedShipments
        //if you want to filter on info in selected orders insert filter here
        // .filter()
        .map((req) => {
          try {
            return axiosHTTP.post(`${dispatcherApi}dispatcher/scanlog`, {
              ...data,
              codeCourse: req.code,
            })
          } catch (err) {
            return err
          }
        })
    )
      .then((s) => {
        console.log(s)
        console.log(action.payload.data)
        store.dispatch({
          type: OrderConstants.ASSIGN_DRIVER_SUCCESS,
          payload: {
            // See what we need as payload
          },
        })
      })
      .catch((qs) => {
        console.log(qs)
        // const data = qs.data
        store.dispatch({
          type: OrderConstants.ASSIGN_DRIVER_FAILURE,
          payload: {
            //samething
          },
        })
      })
  }

  //Affecter à une autre agence
  if (action.type === OrderConstants.UPDATE_WAREHOUSE) {
    store.dispatch(
      httpPost({
        endPoint: `${api}movePackagesToWarehouse`,
        data: action.payload,
        success: OrderConstants.UPDATE_WAREHOUSE_SUCCESS,
        failure: OrderConstants.UPDATE_WAREHOUSE_FAILURE,
      })
    )
  }

  //Fetch Collaborateur (creation discussion)
  if (action.type === OrderConstants.GET_COLLABORATEURS) {
    const { codeCourse } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}collaborateursWarehouse?codeCourse=${codeCourse}`,
        success: OrderConstants.GET_COLLABORATEURS_SUCCESS,
        failure: OrderConstants.GET_COLLABORATEURS_FAILED,
      })
    )
  }

  //Fetch Clients Pro (creation discussion)
  if (action.type === OrderConstants.GET_CLIENTSPRO) {
    const { codeCanal } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}client/clientsPro?codeCanal=${codeCanal}`,
        success: OrderConstants.GET_CLIENTSPRO_SUCCESS,
        failure: OrderConstants.GET_CLIENTSPRO_FAILED,
      })
    )
  }

  if (
    action.type === OrderConstants.FETCH_ORDER_BY_FILTER_SUCCESS ||
    action.type === OrderConstants.FETCH_ORDER_BY_AGENTS_SUCCESS ||
    action.type === OrderConstants.REFRESH_FETCH_ORDER_BY_FILTER_SUCCESS
  ) {
    console.log(action.payload.data)
    store.dispatch(
      getFilterParams({
        count: action.payload.data.total
          ? action.payload.data.total
          : action.payload.data.count,
        limit: action.payload.data.limit,
        offset: action.payload.data.offset,
      })
    )
  }

  if (action.type === OrderConstants.FETCH_COURSES_CORNER) {
    const {
      order: { coursesSearchLimit, coursesSearchOffset, coursesSearchCount },
    } = store.getState()
    const count =
      action.payload.courses.length > 1 ? parseInt(coursesSearchCount) : 1
    store.dispatch(
      getFilterParams({
        count: count,
        limit: parseInt(coursesSearchLimit),
        offset: parseInt(coursesSearchOffset),
      })
    )
  }

  //Search With Categrie in Corner Onglet
  if (action.type === OrderConstants.FETCH_COURSES_ONGLET_CORNER_SEARCH) {
    const { search } = action.payload
    const {
      filters: { filter },
    } = store.getState()
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const filterSend = {
      ...search,
    }
    store.dispatch(
      httpPost({
        endPoint: `${api}filtre/rechercheByType?${queryString.stringify(
          filterSend
        )}`,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
        data: { filtre: removeNull(filter) },
        success: OrderConstants.FETCH_COURSES_CORNER_SEARCH_SUCCESS,
        failure: OrderConstants.FETCH_COURSES_CORNER_SEARCH_FAILURE,
      })
    )
  }
  if (action.type === OrderConstants.FETCH_COURSES_ONGLET_CORNER_SEARCH_SAVE) {
    const { search } = action.payload
    const {
      filters: { filter, displayGrid },
    } = store.getState()
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const filterSend = {
      ...search,
    }
    store.dispatch(
      httpPost({
        endPoint: `${api}filtre/rechercheByType?${queryString.stringify(
          filterSend
        )}`,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c
        }),
        data: { filtre: removeNull(filter) },
        success: OrderConstants.FETCH_COURSES_CORNER_SEARCH_SAVE_SUCCESS,
        failure: OrderConstants.FETCH_COURSES_CORNER_SEARCH_SAVE_FAILURE,
        secData: displayGrid,
      })
    )
  }

  // refresh courses
  if (action.type === OrderConstants.REFRESH_FETCH_ORDER_BY_FILTER) {
    const {
      filters: { filter },
      order: { isLoading },
    } = store.getState()

    if (!isLoading) {
      // cancel the request
      if (cancel != undefined) {
        cancel()
      }

      store.dispatch(
        httpPost({
          endPoint: `${api}getCoursesByFilter`,
          data: removeNull(filter),
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c
          }),
          success: OrderConstants.REFRESH_FETCH_ORDER_BY_FILTER_SUCCESS,
          failure: OrderConstants.REFRESH_FETCH_ORDER_BY_FILTER_FAILED,
        })
      )
    }
  }

  //refresh courses with search onglet
  if (action.type === OrderConstants.REFRESH_COURSES_ONGLET_CORNER_SEARCH) {
    const { search } = action.payload
    const {
      filters: { filter },
      order: { isLoading },
    } = store.getState()

    const filterSend = {
      ...search,
    }
    if (!isLoading) {
      // cancel the request
      if (cancel != undefined) {
        cancel()
      }
      store.dispatch(
        httpPost({
          endPoint: `${api}filtre/rechercheByType?${queryString.stringify(
            filterSend
          )}`,
          cancelToken: new CancelToken(function executor(c) {
            cancel = c
          }),
          data: { filtre: removeNull(filter) },
          success: OrderConstants.REFRESH_COURSES_ONGLET_CORNER_SEARCH_SUCCESS,
          failure: OrderConstants.REFRESH_COURSES_ONGLET_CORNER_SEARCH_FAILED,
        })
      )
    }
  }

  //refresh courses with search corner
  if (action.type === OrderConstants.REFRESH_COURSES_CORNER_SEARCH) {
    const { search } = action.payload
    const {
      order: { isLoading },
    } = store.getState()

    const filterSend = {
      ...search,
    }
    if (!isLoading) {
      // cancel the request
      if (cancel != undefined) {
        cancel()
      }
      store.dispatch(
        httpFetch({
          endPoint: `${api}rechercheByType?${queryString.stringify(filterSend)}`,
          cancelToken: new CancelToken(function executor(c) {
            cancel = c
          }),
          success: OrderConstants.REFRESH_COURSES_ONGLET_CORNER_SEARCH_SUCCESS,
          failure: OrderConstants.REFRESH_COURSES_ONGLET_CORNER_SEARCH_FAILED,
        })
      )
    }
  }

  //Fetch MacroCanal
  if (action.type === OrderConstants.FETCH_MACRO_CANAL) {
    store.dispatch(
      httpFetch({
        endPoint: `${api}macroCanal/importFile`,
        success: OrderConstants.FETCH_MACRO_CANAL_SUCCESS,
        failure: OrderConstants.FETCH_MACRO_CANAL_FAILED,
      })
    )
  }

  //Importer Courses
  if (action.type === OrderConstants.IMPORT_COURSES) {
    const { payload, macroCanalCode } = action.payload
    store.dispatch(
      httpPostCallbackData({
        endPoint: `${api}carrefour/extract`,
        data: payload,
        success: OrderConstants.IMPORT_COURSES_SUCCESS,
        failure: OrderConstants.IMPORT_COURSES_FAILED,
        secData: macroCanalCode,
      })
    )
  }

  if (action.type === OrderConstants.IMPORT_COURSES_SUCCESS) {
    const macroCanalCode = action.payload.data.secData
    const startDate = moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm')
    const endDate = moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm')

    const filter = {
      createdAt: [
        startDate.toString().slice(0, 10) + '~' + endDate.toString().slice(0, 10),
      ],
      salesChannels: [macroCanalCode],
      status: ['TERMINEE'],
    }

    store.dispatch(setFilterCommande({ ...filter }, false))
  }
  //Ajouter à une navette
  if (action.type === OrderConstants.ADD_TO_NAVETTE) {
    store.dispatch(
      httpPut({
        endPoint: `${api}client/updateTypeLivraison`,
        data: action.payload,
        success: OrderConstants.ADD_TO_NAVETTE_SUCCESS,
        failure: OrderConstants.ADD_TO_NAVETTE_FAILURE,
      })
    )
  }

  //Chnager le type destoredelivery vers clientdelivery
  if (action.type === OrderConstants.TO_CLIENT_DELIVERY) {
    store.dispatch(
      httpPost({
        endPoint: `${api}client/updateTypeClientDelivery`,
        data: action.payload,
        success: OrderConstants.TO_CLIENT_DELIVERY_SUCCESS,
        failure: OrderConstants.TO_CLIENT_DELIVERY_FAILURE,
      })
    )
  }

  //GET NEW LDV
  if (action.type === OrderConstants.GET_DUPLICATED_COURSE) {
    const { id } = action.payload

    const data = {
      id: id,
    }
    store.dispatch(
      httpFetch({
        endPoint: `${api}getDuplicatedCourseByIdCmd?${queryString.stringify(data)}`,
        success: OrderConstants.GET_DUPLICATED_COURSE_SUCCESS,
        failure: OrderConstants.GET_DUPLICATED_COURSE_FAILURE,
      })
    )
  }
  if (action.type === OrderConstants.GET_DUPLICATED_COURSE_SUCCESS) {
    const { response } = action.payload.data
    const {
      order: { duplicatedCourse },
    } = store.getState()
    if (response?.code && duplicatedCourse) {
      window.location = `/deliveries/${response.code}`
      store.dispatch(setActionDuplicatedCourse(false))
    }
  }
  if (action.type === OrderConstants.GET_DUPLICATED_COURSE_FAILURE) {
    store.dispatch(setIsActionShipmentDone(true))
    store.dispatch(setActionDuplicatedCourse(false))
  }

  if (action.type === OrderConstants.GET_ACTIVITY) {
    const data = {
      entityId: action.payload.entityId,
      entityType: action.payload.type,
      entityCategory: action.payload.category,
    }
    store.dispatch(
      httpPost({
        endPoint: `${log}log/getEntityHistory`,
        data: data,
        success: OrderConstants.GET_ACTIVITY_SUCCESS,
        failure: OrderConstants.GET_ACTIVITY_FAILURE,
      })
    )
  }

  //Changement Status en masse
  if (action.type === OrderConstants.CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH) {
    store.dispatch(
      httpPost({
        endPoint: `${dispatcherApi}dispatcher/changeStatusCatalog`,
        data: action.payload,
        success: OrderConstants.CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH_SUCCESS,
        failure: OrderConstants.CHANGE_STATUS_COURSE_EN_MASSE_DISPATCH_FAILURE,
      })
    )
  }

  //Fetch Question By LDV
  if (action.type === OrderConstants.GET_ORDER_QUESTION_BY_LDV) {
    const { ldv } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}getResponseRDVQuestionsByLdv?code=${ldv}`,
        success: OrderConstants.GET_ORDER_QUESTION_BY_LDV_SUCCESS,
        failure: OrderConstants.GET_ORDER_QUESTION_BY_LDV_FAILURE,
      })
    )
  }

  //Fetch config
  if (action.type === OrderConstants.GET_CONFIG_CANAL) {
    const { codeCanal, metaKey } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${api}canalVenteConfigMeta?codeCanal=${codeCanal}&metaKey=${metaKey}`,
        success: OrderConstants.GET_CONFIG_CANAL_SUCCESS,
        failure: OrderConstants.GET_CONFIG_CANAL_FAILED,
      })
    )
  }
  next(action)
}
export default OrderMiddleware
