import { connect } from 'react-redux'
import DocumentsEntrepot from '../../../../pages/main/Entrepot/documents'

import {
  getDefaultFilterEntrepot,
  getfilterEntreprot,
} from '../../../../redux/filter/selectors'
import {
  setFilterEntrepot,
  resetFilterEntrepot,
} from '../../../../redux/filter/actions'
import {
  downloadTransfert,
  downloadPreparation,
  downloadBonLivraison,
} from '../../../../redux/documents/actions'

const mapStateToProps = (state) => ({
  filterReducer: getfilterEntreprot(state),
  defaultFilterEntrepot: getDefaultFilterEntrepot(state),
})
const mapDisptachToProps = (dispatch) => ({
  setFilter: (filter, fromAppliquer) =>
    dispatch(setFilterEntrepot(filter, fromAppliquer)),
  downloadTransfert: () => dispatch(downloadTransfert()),
  downloadPreparation: () => dispatch(downloadPreparation()),
  downloadBonLivraison: () => dispatch(downloadBonLivraison()),
  resetFilterEntrepot: () => dispatch(resetFilterEntrepot()),
})

export default connect(mapStateToProps, mapDisptachToProps)(DocumentsEntrepot)
