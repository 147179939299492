import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import SubjectIcon from '../../../../../../assets/icons/navFilter/subjectFilter'

export default function SubjectFilter({
  subjects,
  filterStateChanged,
  subjectFiltersValues,
  isLoadingSubjects,
}) {
  const [checked, setChecked] = useState([])
  useEffect(() => {
    let list = subjects.map(({ label }) => {
      return {
        code: label + '',
        name: label,
      }
    })
    setChecked(list)
  }, [subjects])

  const onSubjectChanged = (selectedSubject) => {
    filterStateChanged('subject', selectedSubject ? selectedSubject : [])
  }
  return (
    <SelectListWithSearch
      disabled={isLoadingSubjects || subjects?.length <= 0}
      onDataChanged={onSubjectChanged}
      defaultLabel={'Sujet'}
      dataList={checked}
      filtersValues={subjectFiltersValues?.map((e) => decodeURI(e))}
      Icon={SubjectIcon}
      id={'SubjectFilter'}
    />
  )
}
SubjectFilter.propTypes = {
  subjects: PropTypes.array,
  filterStateChanged: PropTypes.func,
  isLoadingSubjects: PropTypes.bool,
  subjectFiltersValues: PropTypes.array,
}
