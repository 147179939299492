import React from 'react'
import PropTypes from 'prop-types'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMore from '@mui/icons-material/ExpandMore'
import CameraIcon from '../../../../../../../../assets/icons/detailsLivraison/MaterialSymbolsAddAPhotoOutline.svg'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const StyledAccordionSummary = styled(AccordionSummary)({
  root: {
    minHeight: '48px !important',
    padding: '0px 9px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      '& .MuiAccordionSummary-content': {
        transition: 'none',
      },
    },

    '& .MuiAccordionSummary-content': {
      transition: 'none',
      flexDirection: 'column',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
})

export default function pointageMdm({
  packages,
  onUploadMDM,
  packageCode,
  isLoadingUploadImageMDM,
  config,
  deleteImageMDMPacking,
}) {
  const verifIsUploadAfter = (code) => {
    const matchingPackages = packages.filter((pack) => pack.code === code)
    if (matchingPackages.length > 0) {
      const maxAfterPacking = config[0].picture_after_packing.max

      const numAfterPacking = matchingPackages[0].pictureAfterPacking?.length
      return numAfterPacking < maxAfterPacking
    }

    return false
  }
  const verifIsUploadBefore = (code) => {
    const matchingPackages = packages.filter((pack) => pack.code === code)
    if (matchingPackages.length > 0) {
      const maxBeforePacking = config[0].picture_before_packing.max

      const numBeforePacking = matchingPackages[0].pictureBeforePacking?.length

      return numBeforePacking < maxBeforePacking
    }

    return false
  }
  const requiredmaxBeforePacking = config[0].picture_before_packing.requiredMax
  const requiredmaxAfterPacking = config[0].picture_after_packing.requiredMax

  return (
    <div style={{ padding: '0px 15px 15px' }}>
      <div
        style={{
          boxShadow: '0px 0px 4px #00000029',
        }}
      >
        {packages.map((pack, index) => (
          <Accordion
            sx={{
              borderBottom: '1px solid #e6e6e6 !important',
              boxShadow: 'none !important',
              width: '100%',
              margin: '0 !important',
              '&:before': {
                opacity: 0,
              },
            }}
            key={index}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id={`package-AccordionSummary-${index}`}
              expanded={true}
            >
              <div style={{ display: 'flex' }}>
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: '700',
                    paddingRight: '5px',
                  }}
                >
                  {'BarCode :'}
                </span>
                <span
                  style={{
                    fontSize: '13px',
                    fontWeight: '550',
                  }}
                >
                  {pack.barcode}
                </span>
              </div>
            </StyledAccordionSummary>
            <AccordionDetails style={{ display: 'block', padding: '8px 9px 16px' }}>
              <div style={{ padding: '10px 0px' }}>
                <div style={{ paddingBottom: '10px' }}>
                  <Tooltip
                    title={
                      <Typography fontSize={12}>
                        minimum {requiredmaxBeforePacking} photo
                      </Typography>
                    }
                    arrow
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      Ajouter des photos Avant Emballage*
                    </span>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    boxSizing: 'border-box',
                  }}
                >
                  {pack.pictureBeforePacking.map((file, index) => (
                    <div
                      style={{ position: 'relative', marginRight: '24px' }}
                      key={index}
                    >
                      <IconButton
                        sx={{
                          color: 'white',
                          backgroundColor: '#eeeeee !important',
                          position: 'absolute',
                          width: '10px',
                          height: '10px',
                          top: '-11px',
                          right: '-12px',
                          zIndex: '2',
                        }}
                        onClick={() => {
                          deleteImageMDMPacking(pack.barcode, file, 'before')
                        }}
                        id="delete-photo-avant"
                      >
                        <CloseIcon />
                      </IconButton>
                      <div
                        style={{
                          width: '114px',
                          height: '115px',
                          background: '#FAFAFA',
                          borderRadius: '10px',
                          display: 'grid',
                          placeItems: 'center',

                          cursor: 'pointer',
                        }}
                      >
                        <img
                          src={file}
                          style={{
                            width: '100%',
                            height: '115px',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  {verifIsUploadBefore(pack.code) && (
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: '114px',
                          height: '115px',
                          background: '#FAFAFA',
                          borderRadius: '10px',
                          display: 'grid',
                          placeItems: 'center',
                          marginRight: '24px',
                          cursor: 'pointer',
                        }}
                      >
                        {isLoadingUploadImageMDM && packageCode === pack.code ? (
                          <CircularProgress
                            color="primary"
                            style={{ width: '30px', height: '30px' }}
                          />
                        ) : (
                          <div>
                            <img src={CameraIcon} />
                          </div>
                        )}
                      </div>
                      {!isLoadingUploadImageMDM && (
                        <input
                          id="add-photo-litige"
                          type="file"
                          style={{
                            cursor: 'pointer',
                            opacity: '0',
                            zIndex: '2',
                            width: '114px',
                            height: '115px',
                            position: 'absolute',
                          }}
                          onChange={(event) =>
                            onUploadMDM(event.target.files, pack.barcode, 'before')
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ padding: '10px 0px' }}>
                <div style={{ paddingBottom: '10px' }}>
                  <Tooltip
                    title={
                      <Typography fontSize={12}>
                        minimum {requiredmaxAfterPacking} photo
                      </Typography>
                    }
                    arrow
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      Ajouter des photos Après Emballage*
                    </span>
                  </Tooltip>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    boxSizing: 'border-box',
                  }}
                >
                  {pack.pictureAfterPacking.map((file, index) => (
                    <div
                      style={{ position: 'relative', marginRight: '24px' }}
                      key={index}
                    >
                      <IconButton
                        sx={{
                          color: 'white',
                          backgroundColor: '#eeeeee !important',
                          position: 'absolute',
                          width: '10px',
                          height: '10px',
                          top: '-11px',
                          right: '-12px',
                          zIndex: '2',
                        }}
                        onClick={() => {
                          deleteImageMDMPacking(pack.code, file, 'after')
                        }}
                        id="delete-photo-apres"
                      >
                        <CloseIcon />
                      </IconButton>
                      <div
                        style={{
                          width: '114px',
                          height: '115px',
                          background: '#FAFAFA',
                          borderRadius: '10px',
                          display: 'grid',
                          placeItems: 'center',

                          cursor: 'pointer',
                        }}
                      >
                        <img
                          src={file}
                          style={{
                            width: '100%',
                            height: '115px',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  {verifIsUploadAfter(pack.code) && (
                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: '114px',
                          height: '115px',
                          background: '#FAFAFA',
                          borderRadius: '10px',
                          display: 'grid',
                          placeItems: 'center',
                          marginRight: '24px',
                          cursor: 'pointer',
                        }}
                      >
                        {isLoadingUploadImageMDM && packageCode === pack.code ? (
                          <CircularProgress
                            color="primary"
                            style={{ width: '30px', height: '30px' }}
                          />
                        ) : (
                          <div>
                            <img src={CameraIcon} />
                          </div>
                        )}
                      </div>
                      {!isLoadingUploadImageMDM && (
                        <input
                          id="add-photo-litige"
                          type="file"
                          style={{
                            cursor: 'pointer',
                            opacity: '0',
                            zIndex: '2',
                            width: '114px',
                            height: '115px',
                            position: 'absolute',
                          }}
                          onChange={(event) =>
                            onUploadMDM(event.target.files, pack.barcode, 'after')
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  )
}
pointageMdm.propTypes = {
  packages: PropTypes.array,
  packageCode: PropTypes.string,
  isLoadingUploadImageMDM: PropTypes.bool,
  onUploadMDM: PropTypes.func,
  config: PropTypes.array,
  deleteImageMDMPacking: PropTypes.func,
}
