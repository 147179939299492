import _ from 'lodash'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'

import AccordionDetails from '@mui/material/AccordionDetails'
import AddressAutoComplete from '../../../../containers/main/AddressAutoComplete'
import DatePickerLocalisation from '../../../../components/DatePickerLocalisation'
import FormHelperTextError from '../../../../components/FormHelperTextError'
import { LatLng } from '../../../../models/latlng'
import {
  getDaysDisabledDays,
  getOpeningDay,
} from '../../../../utils/getDisabledDays'
import useForm from '../../../../utils/useForm'
import { shipmentFormData } from './formData'
import { shipmentValidators } from './validators'
import { validateShipment } from '../../../../utils/validator/shipmentValidator'
import PhoneInput from 'react-phone-input-2'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { parseFloatWithComma } from '../../../../utils/floatFormatting'

import uuid from 'react-uuid'
import moment from 'moment'
import OptionsList from '../../../../components/ShipmentOptionsList'

import './style.scss'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import { LEVEL_NUMBERS, ORDER_TYPE } from '../../../../constants/appContants'
import InvoiceInput from '../../../../components/InvoiceInput'
import NoteInterne from '../../../../components/NoteInterne'
import {
  COURSE_SOURCE_SCHMIDT,
  getfullName,
  getPhoneInputCountries,
} from '../../../../utils/values'
import Prestation from '../../../../components/Prestation'

import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import { COURSE_TYPE, ClientDelivery, ClientPickup } from '../../../../utils/values'
import DatePickerDisabledCouleur from '../../../../components/DatePickerDisabledCouleurs'
import { getOrderStatusMofifiable } from '../../../../utils/getOrderStatus'
import Switch from '../../../../components/switch/SwitchComponent'
import {
  COURSE_SOURCE_CALENDAR,
  COURSE_SOURCE_CALENDAR_DISABLED,
} from '../../../../utils/checkActionCourseSource'

const ShipmentSection = ({
  order,
  oldOrder,
  updateOrderShipment,
  setShipmentErrors,
  getGoogleMapDirection,
  findDirection,
  isGetDirectionRouteLoading,
  directionRouteList,
  directionRoute,
  setOrderShipmentPrice,
  updateDirections,
  getRecommendedCreneau,
  recommendedCreneauList,
  resetDirections,
  getDayDispo,
  rdvDispo,
  courseEditable,
  courseSemiEdit,
  coursePartialEdit,
  resetRdvDispo,
  getCreneauRecommendedDisponibilites,
  getConfigCanal,
  configCanal,
  resetConfigCanal,
  getDayDisponibilites,
}) => {
  const shipmentData = !_.isNil(_.get(order, 'shipment.id'))
    ? _.get(order, 'shipment')
    : { id: uuid() }
  const [originDefaultAddress, setOriginDefaultAddress] = useState(null)
  const [DestinationDefaultAddress, setDestinationDefaultAddress] = useState(null)
  const [ancienCourseType, setAncienCourseType] = useState(null)

  const [isVehiculeType, setIsVehiculeType] = useState(false)
  const [isManutentionOptions, setIsManutentionOptions] = useState(false)
  const [isMissingTarifsConfig, setIsMissingTarifsConfig] = useState(false)
  const [isManutentionIncluse, setIsManutentionIncluse] = useState(false)
  const [isSubmited, setIsSubmited] = useState(0)
  const [collaborator, setCollaborator] = useState(null)
  const [options, setOptions] = useState([])

  const submitForm = (e) => {
    console.log('place shipment form submited:', e)
  }
  const [
    shipment,
    errors,
    isSubmitted,
    handleShipmentChange,
    handleShipmentElChange,
    handleShipmentSubmit,
    isShipmentValid,
    isShipmentInValid, //Empty
    ,
    handleShipmentSwitcherChange,
  ] = useForm(
    shipmentFormData(shipmentData),
    submitForm,
    shipmentValidators,
    validateShipment
  )
  const submitRefs = useRef()
  const [disabledDays, setDisabledDays] = useState([])
  const [openedDays, setOpenedDays] = useState([])
  const [afterInit, setAfterInit] = useState(false)
  const [ListRecommendedCreneau, setRecommendedCreneauList] = useState([])
  const [dateDemarrageMeta, setDateDemarrageMeta] = useState(null)
  const [errorAccordion, setErrorAccordion] = useState(false)
  const [openAccordion, setOpenAccordion] = useState(false)
  useEffect(() => {
    setIsSubmited(shipment.isSubmitted)
  }, [shipment.isSubmitted])

  // To Disable auto setting date to null When we are loading the old information

  useEffect(() => {
    if (shipment?.selectedSalesChannel?.code) {
      let codeCanalVente = shipment.selectedSalesChannel.code
      getConfigCanal(codeCanalVente, 'apiDispo')
    }
    return () => {
      resetConfigCanal()
    }
  }, [])
  useEffect(() => {
    if (
      !afterInit &&
      shipment.direction?.directions?.routes &&
      shipment.date === oldOrder.shipment.date
    ) {
      setAfterInit(true)
    }
  }, [shipment.direction?.directions?.routes])

  useEffect(() => {
    if (!afterInit && shipment.time) {
      handleShipmentElChange('time', oldOrder.shipment.time)
    }
  }, [shipment.date])

  useEffect(() => {
    setRecommendedCreneauList(recommendedCreneauList)
    handleShipmentElChange('dateDemarrageMeta', dateDemarrageMeta)
  }, [recommendedCreneauList])
  // console.log('shipment===>', shipment)
  useEffect(() => {
    if (shipment?.dateDemarrageMeta) {
      setDateDemarrageMeta(shipment.dateDemarrageMeta)
    }
  }, [shipment])

  useEffect(() => {
    console.log(shipment)
    updateOrderShipment(order.id, shipment)
    setFactures(order.shipment.factures)
    return () => {
      resetForm()
      resetDirections()
    }
  }, [])
  useEffect(() => {
    if (
      _.get(shipmentData, 'destinationQuery') !=
        _.get(shipmentData, 'selectedDestination.formattedAddress') ||
      _.get(shipmentData, 'originQuery') !=
        _.get(shipmentData, 'selectedOrigin.formattedAddress')
    )
      resetDirections()
  }, [shipmentData])
  useEffect(() => {
    const changeshipmentTimeOut = setTimeout(() => {
      updateOrderShipment(order.id, shipment)
    }, 100)
    return () => clearTimeout(changeshipmentTimeOut)
  }, [shipment])

  useEffect(() => {
    const changesErrorsTimeOut = setTimeout(() => {
      setShipmentErrors(order.id, errors)
      if (
        !_.isNil(_.get(errors, 'destinationPhone.required')) ||
        !_.isNil(_.get(errors, 'destinationMail'))
      ) {
        setErrorAccordion(true)
        setOpenAccordion(true)
      } else {
        setErrorAccordion(false)
      }
    }, 100)
    return () => clearTimeout(changesErrorsTimeOut)
  }, [errors])

  // prevent next onClick event
  useEffect(() => {
    // console.log('errrorrs: ', errors)
    if (isSubmited) {
      submitRefs.current.click()
    }
  }, [isSubmited])

  const courseSourceExist =
    COURSE_SOURCE_CALENDAR.includes(shipment.courseSource) ||
    shipment.selectedSalesChannel.code?.includes('BUT-')
  const isSchmit = shipment.courseSource === COURSE_SOURCE_SCHMIDT
  let courseType = ['StoreDelivery', 'StorePickUp'].includes(shipment.courseType)

  const handleDisabledDays = () => {
    if (configCanal?.length > 0 || configCanal == null) {
      let days = getDaysDisabledDays(shipment.selectedSalesChannel)
      const adresses = ClientDelivery.includes(shipment.courseType)
        ? shipment?.selectedDestination
        : shipment?.selectedOrigin

      let codePostal = adresses?.postalCode

      if (configCanal?.length > 0) {
        const dateDebut = moment(new Date()).format('YYYY-MM-DD')
        const dateFin = shipment.selectedSalesChannel.code?.includes('BUT-')
          ? moment(dateDebut).add(14, 'd').format('YYYY-MM-DD')
          : moment(dateDebut).add(2, 'M').endOf('month').format('YYYY-MM-DD')
        const idCanal = shipment.selectedSalesChannel.id
        const serviceTime = shipment.serviceTime

        getDayDisponibilites(dateDebut, dateFin, idCanal, serviceTime, codePostal)
        setDisabledDays([])
      } else if (!courseSourceExist || (courseType && isSchmit)) {
        const opened = getOpeningDay(shipment.selectedSalesChannel)
        setOpenedDays(opened)
        setDisabledDays(days)
      } else {
        const dateDebut = moment(new Date()).format('YYYYMMDD')
        const dateFin = moment(dateDebut)
          .add(2, 'M')
          .endOf('month')
          .format('YYYYMMDD')
        getDayDispo(dateDebut, dateFin, codePostal)
        setDisabledDays(days)
      }

      if (afterInit) handleShipmentElChange('date', null)
    }

    console.log('DATE   /::' + shipment.date)
  }
  useEffect(() => {
    if (rdvDispo) {
      const tabRdvDispo = rdvDispo.map(
        (rdv) => new Date(moment(rdv.split('\t')[0]).format('YYYY/MM/DD'))
      )
      setOpenedDays(tabRdvDispo)
    }
  }, [rdvDispo])
  useEffect(() => {
    return () => {
      resetRdvDispo()
    }
  }, [])

  useEffect(() => {
    handleDisabledDays()
  }, [shipment.selectedSalesChannel, configCanal, shipment.courseType])

  const handleNegativeChange = (e) => {
    const value = e.target.value.trim() == '' ? '0' : e.target.value
    if (value >= 0) {
      handleShipmentChange(e)
    }
  }

  const [blurredClientPrice, setblurredClientPrice] = useState(false)
  const [blurredProviderPrice, setBlurredProviderPrice] = useState(false)
  useEffect(() => {
    if (!blurredProviderPrice) {
      handleShipmentElChange(
        'providerPrice',
        parseFloatWithComma(shipment.providerPrice)
      )
    }
    if (!blurredClientPrice) {
      handleShipmentElChange(
        'clientPrice',
        parseFloatWithComma(shipment.clientPrice)
      )
    }
  }, [blurredClientPrice, blurredProviderPrice])
  const handleBlur = (e) => {
    if (e.target.name === 'providerPrice') {
      setBlurredProviderPrice(false)
    } else {
      setblurredClientPrice(false)
    }
  }
  const handleFocus = (e) => {
    if (e.target.name === 'providerPrice') {
      setBlurredProviderPrice(true)
    } else {
      setblurredClientPrice(true)
    }
  }

  const calculateDirection = () => {
    if (
      !_.isNil(_.get(shipment, 'selectedOrigin.latlng')) &&
      !_.isNil(_.get(shipment, 'selectedDestination.latlng')) &&
      !_.isNil(_.get(shipment, 'selectedOrigin.latlng.lat')) &&
      !_.isNil(_.get(shipment, 'selectedOrigin.latlng.lng'))
    ) {
      if (!directionRouteList.length) {
        getGoogleMapDirection(
          shipment.id,
          new LatLng(
            shipment.selectedOrigin.latlng.lat,
            shipment.selectedOrigin.latlng.lng
          ),
          new LatLng(
            shipment.selectedDestination.latlng.lat,
            shipment.selectedDestination.latlng.lng
          )
        )
      } else {
        findDirection(
          shipment.id,
          new LatLng(
            shipment.selectedOrigin.latlng.lat,
            shipment.selectedOrigin.latlng.lng
          ),
          new LatLng(
            shipment.selectedDestination.latlng.lat,
            shipment.selectedDestination.latlng.lng
          )
        )
      }
    }
  }

  const resetForm = useCallback(() => {
    if (shipment.selectedSalesChannel && shipment.selectedSalesChannel !== null) {
      setOriginDefaultAddress(null)
      setDestinationDefaultAddress(null)
      setOriginDefaultAddress(shipment.selectedOrigin)
      setDestinationDefaultAddress(shipment.selectedDestination)
      afterInit
        ? handleShipmentElChange('time', undefined)
        : handleShipmentElChange('time', oldOrder.shipment.time)
      if (_.isNil(_.get(shipment, 'selectedSalesChannel.configs'))) {
        setIsManutentionOptions(true)
      } else {
        setIsManutentionOptions(false)
      }
      if (
        _.get(shipment, 'selectedSalesChannel.configs.fixedPriceIncludeManutention')
      ) {
        setIsManutentionIncluse(true)
        setIsManutentionOptions(false)
        handleShipmentElChange('manutention', true)
        handleShipmentElChange('manutentionDouble', false)
      } else {
        setIsManutentionIncluse(false)
        setIsManutentionOptions(true)
        if (shipment.manutentionDouble) {
          handleShipmentElChange('manutentionOptions', 'avecDoubleManutention')
        } else if (shipment.manutention) {
          handleShipmentElChange('manutentionOptions', 'avecManutention')
        } else {
          handleShipmentElChange('manutentionOptions', 'sansManutention')
        }
      }
      if (
        !_.get(shipment, 'selectedSalesChannel.configs.priceBasedOnNBitems') &&
        !_.get(
          shipment,
          'selectedSalesChannel.configs.priceBasedOnPurchaseAmount'
        ) &&
        !_.get(shipment, 'selectedSalesChannel.configs.priceBasedOnWeight')
      ) {
        setIsVehiculeType(true)
      } else {
        setIsVehiculeType(false)
      }
      if (_.isNil(_.get(shipment, 'selectedSalesChannel.tarificationsDetails'))) {
        setIsMissingTarifsConfig(true)
      } else {
        if (
          _.get(shipment, 'selectedSalesChannel.tarificationsDetails').length === 0
        ) {
          setIsMissingTarifsConfig(true)
        }
        setIsMissingTarifsConfig(false)
      }
    }
  })
  useEffect(() => {
    switch (shipment.manutentionOptions) {
      case 'sansManutention':
        handleShipmentElChange('manutention', false)
        handleShipmentElChange('manutentionDouble', false)
        break
      case 'avecManutention':
        handleShipmentElChange('manutention', true)
        handleShipmentElChange('manutentionDouble', false)
        break
      case 'avecDoubleManutention':
        handleShipmentElChange('manutention', false)
        handleShipmentElChange('manutentionDouble', true)
        break

      default:
        break
    }
  }, [shipment.manutentionOptions])

  useEffect(() => {
    calculateDirection()
    console.log('GetDirections')
    console.log(shipment.selectedOrigin)
    console.log(shipment.selectedDestination)
  }, [shipment.selectedOrigin, shipment.selectedDestination])

  useEffect(() => {
    if (!isGetDirectionRouteLoading) {
      if (
        _.get(shipment, 'id') == _.get(directionRoute, 'id') &&
        _.get(shipment, 'selectedOrigin.latlng.lat') ===
          _.get(directionRoute, 'origin.lat') &&
        _.get(shipment, 'selectedOrigin.latlng.lng') ===
          _.get(directionRoute, 'origin.lng')
      ) {
        handleShipmentElChange('direction', directionRoute)
      }
    }
  }, [directionRoute, isGetDirectionRouteLoading])

  useEffect(() => {
    setOrderShipmentPrice(order.id, shipment.clientPrice)
  }, [shipment.clientPrice])

  useEffect(() => {
    const dataChangeTimeOut = setTimeout(() => {
      if (
        shipment.direction &&
        shipment.direction !== null &&
        Object.keys(shipment.direction).length > 0
      ) {
        updateDirections(shipment.id, shipment.direction)
      }
    }, 500)
    return () => clearTimeout(dataChangeTimeOut)
  }, [shipment.direction])

  useEffect(() => {
    resetForm()
  }, [shipment.selectedSalesChannel])
  useEffect(() => {
    if (order?.shipment?.collaborator) setCollaborator(order?.shipment?.collaborator)
  }, [order])

  const [factures, setFactures] = useState([''])
  const matches = useMediaQuery('(max-width:839px)')
  const handleInvoiceChange = (value, index) => {
    let facturesList = [...factures]
    facturesList[index] = value
    setFactures(facturesList)
    handleShipmentElChange('factures', facturesList)
  }
  const addFacture = () => {
    let facturesList = [...factures]
    facturesList.push(prefix)
    setFactures(facturesList)
    handleShipmentElChange('factures', facturesList)
  }
  const removeFacture = (e, index) => {
    let facturesList = [...factures]
    const filteredList = facturesList.filter((item, i) => i !== index)
    handleShipmentElChange('factures', filteredList)
    setFactures(filteredList)
    e.preventDefault()
  }

  useEffect(() => {
    const opt = options.map((e) => {
      return { idOption: e.id, quantity: e.quantity }
    })
    handleShipmentElChange('options', opt)
  }, [options])
  useEffect(() => {
    if (_.get(shipment, 'selectedSalesChannel.optionsDetails.length')) {
      setDeliveryOptions(
        shipment.selectedSalesChannel.optionsDetails,
        shipment.options
      )
    }
  }, [])

  const setDeliveryOptions = (optionsDetails, options) => {
    const deliveryOptions = optionsDetails.filter(
      (op) => op.orderService === ORDER_TYPE.DELIVERY
    )
    if (deliveryOptions.length) {
      let optionsForm = deliveryOptions.map((el) => {
        return {
          id: el.id,
          label:
            el.label && el.label !== null
              ? `${el.label.charAt(0).toUpperCase()}${el.label.slice(1)}`
              : null,
          quantity: checkQuatitePrix(el, options).quantity,
          price: el.price,
          totalPrice: checkQuatitePrix(el, options).price,
        }
      })
      setOptions(optionsForm)
    }
  }
  const checkQuatitePrix = (optionsDetails, options) => {
    var index = options.map((opt) => opt.option.id).indexOf(optionsDetails.id)

    if (index !== -1)
      return {
        quantity: options[index].quantity,
        price: options[index].quantity * optionsDetails.price,
      }

    return { quantity: 0, price: 0 }
  }

  const handleQuantityChange = (e, id) => {
    const { value } = e.target
    if (options && options.length) {
      const optionsList = [...options]
      const op = optionsList.findIndex((o) => o.id === id)
      if (op >= 0 && value >= 0) {
        optionsList[op].quantity = value
        optionsList[op].totalPrice = optionsList[op].quantity * optionsList[op].price
        setOptions(optionsList)
      }
      handleClientPrice()
    }
  }

  const handleClientPrice = () => {
    const optionsDetails = shipment.selectedSalesChannel.optionsDetails
    const optionsOriginal = shipment.originalOption
    var optionsForm = []
    const deliveryOptions = optionsDetails.filter(
      (op) => op.orderService === ORDER_TYPE.DELIVERY
    )
    if (deliveryOptions.length) {
      optionsForm = deliveryOptions.map((el) => {
        return {
          id: el.id,
          label:
            el.label && el.label !== null
              ? `${el.label.charAt(0).toUpperCase()}${el.label.slice(1)}`
              : null,
          quantity: checkQuatitePrix(el, optionsOriginal).quantity,
          price: el.price,
          totalPrice: checkQuatitePrix(el, optionsOriginal).price,
        }
      })
    }

    const prixClient = _.get(shipment, 'originalclientPrice', 0)
    const prixOriginalOption = optionsForm
      .map((opOrig) => opOrig.totalPrice)
      .reduce((total, opOrig) => total + opOrig)
    const prixOptions = options
      .map((op) => op.totalPrice)
      .reduce((total, op) => total + op)

    const addDifferenceOption = prixOptions - prixOriginalOption
    const totalPriceHT = parseFloat(addDifferenceOption) + parseFloat(prixClient)

    handleShipmentElChange('clientPrice', parseFloatWithComma(totalPriceHT))
    handleShipmentElChange('providerPrice', parseFloatWithComma(totalPriceHT))
  }
  const [prefix, setPrefix] = useState('')

  useEffect(() => {
    if (
      shipment?.selectedSalesChannel &&
      shipment.selectedSalesChannel?.configs?.prefixFacture
    ) {
      setPrefix(shipment.selectedSalesChannel.configs.prefixFacture)
    }
  }, [shipment.selectedSalesChannel])

  const handleChangeClientInfos = () => {
    handleShipmentElChange('clientInfos', !shipment.clientInfos)
    if (!shipment.clientInfos) {
      handleShipmentElChange('destinationFirstname', '')
      handleShipmentElChange('destinationLastname', '')
      handleShipmentElChange('destinationPhone', '')
      handleShipmentElChange('destinationMail', '')
      handleShipmentElChange('envoiMailArrivee', false)
    }
  }
  const handleChangeenvoiMailArrivee = () => {
    handleShipmentElChange('envoiMailArrivee', !shipment.envoiMailArrivee)
    handleShipmentElChange('destinationMail', '')

    if (!shipment.envoiMailArrivee) {
      if (shipment?.destinationPhone?.length > 0) {
        const mail =
          shipment.destinationPhone[0] === '+'
            ? shipment.destinationPhone + '@noemail.com'
            : '+' + shipment.destinationPhone + '@noemail.com'
        handleShipmentElChange('destinationMail', mail)
      }
    }
  }

  // call recommended creneau date de livraison
  useEffect(() => {
    if (
      ClientDelivery.includes(shipment.courseType) &&
      shipment.date &&
      shipment.selectedSalesChannel &&
      !getOrderStatusMofifiable(shipment) &&
      (configCanal?.length > 0 || configCanal == null)
    ) {
      const date = moment(shipment.date).format('YYYY-MM-DD')
      let codeCanal = shipment.selectedSalesChannel.code
      if (configCanal?.length > 0) {
        const idCanal = shipment.selectedSalesChannel.id
        const serviceTime = shipment.serviceTime
        const adresses = ClientDelivery.includes(shipment.courseType)
          ? shipment?.selectedDestination
          : shipment?.selectedOrigin

        const codePostal = adresses?.postalCode
        getCreneauRecommendedDisponibilites(date, serviceTime, idCanal, codePostal)
      } else {
        getRecommendedCreneau(date, codeCanal)
      }
    }
  }, [
    shipment.courseType,
    shipment.date,
    shipment.selectedSalesChannel,
    configCanal,
  ])
  // call recommended creneau date de retrait
  useEffect(() => {
    if (
      ClientPickup.includes(shipment.courseType) &&
      shipment.pickUpDate &&
      shipment.selectedSalesChannel &&
      !getOrderStatusMofifiable(shipment) &&
      (configCanal?.length > 0 || configCanal == null)
    ) {
      const date = moment(shipment.pickUpDate).format('YYYY-MM-DD')
      if (configCanal?.length > 0) {
        const idCanal = shipment.selectedSalesChannel.id
        const serviceTime = shipment.serviceTime
        const adresses = ClientDelivery.includes(shipment.courseType)
          ? shipment?.selectedDestination
          : shipment?.selectedOrigin

        const codePostal = adresses?.postalCode
        getCreneauRecommendedDisponibilites(date, serviceTime, idCanal, codePostal)
      } else {
        const codeCanal = shipment.selectedSalesChannel.code

        getRecommendedCreneau(date, codeCanal)
      }

      if (String(shipment.pickUpDate) != String(oldOrder.shipment.pickUpDate)) {
        handleShipmentElChange('heurePickUp', null)
      } else {
        handleShipmentElChange('heurePickUp', oldOrder.shipment.heurePickUp)
      }
    }
  }, [
    shipment.courseType,
    shipment.pickUpDate,
    shipment.selectedSalesChannel,
    configCanal,
  ])

  const [affLivRet, setAffLivRet] = useState(true)

  useEffect(() => {
    if (ClientDelivery.includes(shipment.courseType)) {
      setAffLivRet(true)
    } else {
      setAffLivRet(false)
    }
  }, [shipment.courseType])

  useEffect(() => {
    if (
      ClientPickup.includes(order.shipment.courseType) &&
      shipment.heurePickUp &&
      ListRecommendedCreneau?.length > 0
    ) {
      const recommandedCreneau = ListRecommendedCreneau.filter(
        (creneau) => creneau.start === shipment.heurePickUp
      )
      if (recommandedCreneau?.length > 0) {
        handleShipmentElChange('pickUpEnd', recommandedCreneau[0].end)
        handleShipmentElChange('pickUpStart', shipment.heurePickUp)
      }
    }
  }, [shipment.heurePickUp, ListRecommendedCreneau])

  const [isSchmidt, setIsSchmidt] = useState(false)

  useEffect(() => {
    if (
      shipment.selectedSalesChannel?.macroCanal?.code &&
      (shipment.selectedSalesChannel?.macroCanal?.code === 'SCHMIDT-GROUPE' ||
        shipment.selectedSalesChannel?.code === 'SCHMIDT-GROUPE')
    ) {
      setIsSchmidt(true)
    } else {
      setIsSchmidt(false)
    }
  }, [shipment.selectedSalesChannel])
  useEffect(() => {
    let originQuery = shipment.originQuery
    let selectedOrigin = shipment.selectedOrigin
    let destinationQuery = shipment.destinationQuery
    let selectedDestination = shipment.selectedDestination
    setAncienCourseType(shipment.courseType)

    if (
      (ClientDelivery.includes(shipment.courseType) &&
        ClientPickup.includes(ancienCourseType)) ||
      (ClientDelivery.includes(ancienCourseType) &&
        ClientPickup.includes(shipment.courseType))
    ) {
      handleShipmentElChange('originQuery', destinationQuery)
      handleShipmentElChange('selectedOrigin', selectedDestination)
      handleShipmentElChange('destinationQuery', originQuery)
      handleShipmentElChange('selectedDestination', selectedOrigin)
      setOriginDefaultAddress(selectedDestination)
      setDestinationDefaultAddress(selectedOrigin)
    }
  }, [shipment.courseType])
  return (
    <>
      {order && (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div className="place-shipment-title">
                {' '}
                Livraison{' '}
                {!courseEditable &&
                  !courseSemiEdit &&
                  !coursePartialEdit &&
                  '(Non Modifiable)'}
                {isManutentionIncluse && (
                  <span className="manutention-inclus">
                    {' '}
                    La manutention est incluse <CheckCircleIcon />
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
          {matches && collaborator && (
            <p className="collaborator">
              Collaborateur: {getfullName(collaborator, 'N/A')}
            </p>
          )}
          <div className="place-shipment">
            <form
              className={[
                'form-validation place-shipment-form',
                isSubmitted ? 'was-submitted' : '',
                isShipmentInValid ? 'is-invalid' : '',
                isShipmentValid ? 'is-valid' : '',
              ].join(' ')}
              noValidate
              autoComplete="off"
              onSubmit={($event) => handleShipmentSubmit($event)}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{ padding: '12px !important' }}>
                  <FormControl>
                    <TextField
                      value={shipment.selectedSalesChannel.name}
                      required
                      variant="standard"
                      label="Canal de vente"
                      disabled={!courseEditable}
                    />
                  </FormControl>
                </Grid>

                {shipment.selectedSalesChannel.canalPrestationService.length > 0 && (
                  <Grid item xs={12} sx={{ padding: '12px !important' }}>
                    <Prestation
                      salesChannelPrest={
                        shipment.selectedSalesChannel.canalPrestationService
                      }
                      handleChange={handleShipmentElChange}
                      selectedPrestation={shipment.selectPresCanalService}
                      disabled={!courseEditable}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </div>
          <div className="place-shipment">
            <form
              className={[
                'form-validation place-shipment-form',
                isSubmitted ? 'was-submitted' : '',
                isShipmentInValid ? 'is-invalid' : '',
                isShipmentValid ? 'is-valid' : '',
              ].join(' ')}
              noValidate
              autoComplete="off"
              onSubmit={($event) => handleShipmentSubmit($event)}
            >
              <Grid container spacing={3} sx={{ padding: '12px' }}>
                <Grid item xs={12}>
                  <div style={{ paddingTop: '8px' }}>
                    <FormControl variant="outlined" className="dropdown-loader">
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Type de livraison
                      </InputLabel>
                      <Select
                        onChange={handleShipmentChange}
                        label="Type de livraison"
                        variant="outlined"
                        name="courseType"
                        disabled={!courseEditable}
                        value={shipment.courseType}
                        id={`type-livraison`}
                      >
                        {Object.entries(COURSE_TYPE).map(([code, label], i) => (
                          <MenuItem value={code} key={i} id={`type-livraison-${i}`}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                {!getOrderStatusMofifiable(shipment) ? (
                  <>
                    {!affLivRet ? (
                      <>
                        <div id="pickUpDate-shipment"></div>
                        <Grid item xs={12} sm={6}>
                          <FormControl>
                            {!courseSourceExist || (courseType && isSchmit) ? (
                              <DatePickerLocalisation
                                disabledDays={disabledDays}
                                disablePast={true}
                                handleDateChange={($event) =>
                                  handleShipmentElChange('pickUpDate', $event)
                                }
                                defaultValue={shipment.pickUpDate}
                                withDefaultValue={true}
                                afterInit={afterInit}
                                label="Date de retrait"
                                isDisabled={!courseEditable}
                                isOrderPage={true}
                                date={shipment.pickUpDate}
                                hasError={!_.isNil(_.get(errors, 'pickUpDate'))}
                                errorMessage={
                                  _.get(errors, 'pickUpDate.required')
                                    ? _.get(errors, 'pickUpDate.required')
                                    : _.get(errors, 'pickUpDate.pattern', null)
                                }
                                openedDays={openedDays}
                                id="shipment-date"
                              />
                            ) : (
                              <DatePickerDisabledCouleur
                                disabledDays={disabledDays}
                                disablePast={true}
                                handleDateChange={($event) =>
                                  handleShipmentElChange('pickUpDate', $event)
                                }
                                defaultValue={shipment.pickUpDate}
                                withDefaultValue={true}
                                afterInit={afterInit}
                                label="Date de retrait"
                                isDisabled={!courseEditable}
                                isOrderPage={true}
                                date={shipment.pickUpDate}
                                hasError={!_.isNil(_.get(errors, 'pickUpDate'))}
                                errorMessage={
                                  _.get(errors, 'pickUpDate.required')
                                    ? _.get(errors, 'pickUpDate.required')
                                    : _.get(errors, 'pickUpDate.pattern', null)
                                }
                                minDate={moment(new Date()).format('YYYY/MM/DD')}
                                maxDate={moment(new Date())
                                  .add(2, 'M')
                                  .endOf('month')
                                  .format('YYYY/MM/DD')}
                                id="shipment-date"
                                openedDays={openedDays}
                                forceDisabledDays={
                                  COURSE_SOURCE_CALENDAR_DISABLED.includes(
                                    shipment.courseSource
                                  ) ||
                                  shipment.selectedSalesChannel.code?.includes(
                                    'BUT-'
                                  )
                                }
                              />
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl className="dropdown-loader">
                            <TextField
                              id="time-shipment"
                              select
                              label="Heure de retrait"
                              name="heurePickUp"
                              disabled={!recommendedCreneauList || !courseEditable}
                              value={shipment.heurePickUp}
                              onChange={handleShipmentChange}
                              variant="outlined"
                              error={!_.isNil(_.get(errors, 'heurePickUp'))}
                              helperText={
                                _.get(errors, 'heurePickUp.required')
                                  ? _.get(errors, 'heurePickUp.required')
                                  : _.get(errors, 'heurePickUp.pattern', null)
                              }
                              required
                            >
                              {_.get(ListRecommendedCreneau, 'length') ? (
                                ListRecommendedCreneau.map((h, index) => {
                                  return (
                                    <MenuItem
                                      value={h.start}
                                      key={index}
                                      id={`time-shipment-${index}`}
                                    >
                                      {h.start} - {h.end}
                                    </MenuItem>
                                  )
                                })
                              ) : (
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                              )}
                            </TextField>
                          </FormControl>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <>
                          <div id="date-shipment"></div>
                          <Grid item xs={12} sm={6}>
                            <FormControl>
                              {!courseSourceExist || (courseType && isSchmit) ? (
                                <DatePickerLocalisation
                                  disabledDays={disabledDays}
                                  disablePast={true}
                                  handleDateChange={($event) =>
                                    handleShipmentElChange('date', $event)
                                  }
                                  defaultValue={shipment.date}
                                  withDefaultValue={true}
                                  afterInit={afterInit}
                                  label="Date de livraison"
                                  isDisabled={!courseEditable}
                                  isOrderPage={true}
                                  date={shipment.date}
                                  hasError={!_.isNil(_.get(errors, 'date'))}
                                  errorMessage={
                                    _.get(errors, 'date.required')
                                      ? _.get(errors, 'date.required')
                                      : _.get(errors, 'date.pattern', null)
                                  }
                                  id="shipment-date"
                                  openedDays={openedDays}
                                />
                              ) : (
                                <DatePickerDisabledCouleur
                                  disabledDays={disabledDays}
                                  disablePast={true}
                                  handleDateChange={($event) =>
                                    handleShipmentElChange('date', $event)
                                  }
                                  defaultValue={shipment.date}
                                  withDefaultValue={true}
                                  afterInit={afterInit}
                                  label="Date de livraison"
                                  isDisabled={!courseEditable}
                                  isOrderPage={true}
                                  date={shipment.date}
                                  hasError={!_.isNil(_.get(errors, 'date'))}
                                  errorMessage={
                                    _.get(errors, 'date.required')
                                      ? _.get(errors, 'date.required')
                                      : _.get(errors, 'date.pattern', null)
                                  }
                                  minDate={moment(new Date()).format('YYYY/MM/DD')}
                                  maxDate={moment(new Date())
                                    .add(2, 'M')
                                    .endOf('month')
                                    .format('YYYY/MM/DD')}
                                  id="shipment-date"
                                  openedDays={openedDays}
                                  forceDisabledDays={
                                    COURSE_SOURCE_CALENDAR_DISABLED.includes(
                                      shipment.courseSource
                                    ) ||
                                    shipment.selectedSalesChannel.code?.includes(
                                      'BUT-'
                                    )
                                  }
                                />
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl className="dropdown-loader">
                              <TextField
                                id="time-shipment"
                                select
                                label="Heure de livraison"
                                name="time"
                                disabled={!recommendedCreneauList || !courseEditable}
                                value={shipment.time || ''}
                                onChange={handleShipmentChange}
                                variant="outlined"
                                error={!_.isNil(_.get(errors, 'time'))}
                                helperText={
                                  !_.isNil(_.get(errors, 'time'))
                                    ? !_.isNil(_.get(errors, 'time.required'))
                                      ? _.get(errors, 'time.required')
                                      : _.get(errors, 'time.pattern', null)
                                    : ''
                                }
                                required
                              >
                                {_.get(recommendedCreneauList, 'length') ? (
                                  recommendedCreneauList.map((h, index) => {
                                    return (
                                      <MenuItem
                                        value={h.start}
                                        key={index}
                                        id={`time-shipment-${index}`}
                                      >
                                        {h.start} - {h.end}
                                      </MenuItem>
                                    )
                                  })
                                ) : (
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                )}
                              </TextField>
                            </FormControl>
                          </Grid>
                        </>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div id="earliestDeliveryDate-shipment"></div>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <DatePickerLocalisation
                          disabledDays={[]}
                          openedDays={[]}
                          disablePast={false}
                          handleDateChange={($event) =>
                            handleShipmentElChange('earliestDeliveryDate', $event)
                          }
                          defaultValue={shipment.earliestDeliveryDate}
                          withDefaultValue={true}
                          afterInit={afterInit}
                          label="Date de livraison au plus tôt"
                          isDisabled={!courseEditable}
                          isOrderPage={true}
                          date={shipment.earliestDeliveryDate}
                          hasError={!_.isNil(_.get(errors, 'earliestDeliveryDate'))}
                          errorMessage={
                            _.get(errors, 'earliestDeliveryDate.required')
                              ? _.get(errors, 'earliestDeliveryDate.required')
                              : _.get(errors, 'earliestDeliveryDate.pattern', null)
                          }
                          id="shipment-date"
                        />
                      </FormControl>
                    </Grid>
                    <div id="latestDeliveryDate-shipment"></div>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <DatePickerLocalisation
                          disabledDays={[]}
                          openedDays={[]}
                          disablePast={false}
                          handleDateChange={($event) =>
                            handleShipmentElChange('latestDeliveryDate', $event)
                          }
                          defaultValue={shipment.latestDeliveryDate}
                          withDefaultValue={true}
                          afterInit={afterInit}
                          label="date de livraison au plus tard"
                          isDisabled={!courseEditable}
                          isOrderPage={true}
                          date={shipment.latestDeliveryDate}
                          hasError={!_.isNil(_.get(errors, 'latestDeliveryDate'))}
                          errorMessage={
                            _.get(errors, 'latestDeliveryDate.required')
                              ? _.get(errors, 'latestDeliveryDate.required')
                              : _.get(errors, 'latestDeliveryDate.pattern', null)
                          }
                          id="shipment-date"
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </div>
          <div className="place-shipment">
            <form
              className={[
                'form-validation place-shipment-form',
                isSubmitted ? 'was-submitted' : '',
                isShipmentInValid ? 'is-invalid' : '',
                isShipmentValid ? 'is-valid' : '',
              ].join(' ')}
              noValidate
              autoComplete="off"
              onSubmit={($event) => handleShipmentSubmit($event)}
            >
              <Grid container spacing={3} sx={{ padding: '12px' }}>
                <Grid item xs={12} className="form-label">
                  Départ
                </Grid>
                <Grid item xs={12} className="nextAccord">
                  <AddressAutoComplete
                    id="selectedOrigin-shipment"
                    label="Adresse de départ"
                    selectedDefaultAddress={originDefaultAddress}
                    handleQueryChange={($event) =>
                      handleShipmentElChange('originQuery', $event)
                    }
                    handleOnAddressSelect={($event) => {
                      handleShipmentElChange('selectedOrigin', $event)
                      setOriginDefaultAddress($event)
                    }}
                    isRequired
                    isDisabled={!courseEditable}
                    hasError={
                      !_.isNil(_.get(errors, 'selectedOrigin.required')) ||
                      !_.isNil(_.get(errors, 'selectedOrigin.pattern'))
                    }
                    errorText={
                      _.get(errors, 'selectedOrigin.pattern') ||
                      _.get(errors, 'selectedOrigin.required')
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl className="dropdown-loader">
                    <TextField
                      id="level-shipment"
                      select
                      label="Etage"
                      name="originLevel"
                      value={shipment.originLevel || 0}
                      defaultValue={oldOrder.shipment.originLevel}
                      onChange={handleShipmentChange}
                      variant="outlined"
                      disabled={!courseEditable}
                      SelectProps={{
                        MenuProps: {
                          style: { height: 300 },
                        },
                      }}
                      error={!_.isNil(_.get(errors, 'level'))}
                      helperText={
                        !_.isNil(_.get(errors, 'level'))
                          ? _.get(errors, 'level.required')
                          : ''
                      }
                    >
                      {LEVEL_NUMBERS.map((h, index) => (
                        <MenuItem value={h} key={index}>
                          {h}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <div className="switchers-section">
                    <FormControl component="fieldset" className="switcher-container">
                      <FormGroup row>
                        <FormControlLabel
                          className="switcher-label"
                          control={
                            <Switch
                              name="originIsElevator"
                              value={shipment.originIsElevator}
                              defaultChecked={oldOrder.shipment.originIsElevator}
                              onChange={handleShipmentSwitcherChange}
                              disabled={!courseEditable}
                            />
                          }
                          label={'Présence Ascenseur'}
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="nbrMarcheAvantAscenseurAdresseDepart"
                    value={shipment.nbrMarcheAvantAscenseurAdresseDepart}
                    name="nbrMarcheAvantAscenseurAdresseDepart"
                    onChange={handleNegativeChange}
                    label="Nombre de marches avant l'accès à l'ascenceur"
                    variant="outlined"
                    disabled={!courseEditable}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        type: 'text',
                        pattern: '[0-9]*',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      paddingTop: '10px',
                      paddingBottom: '8px',
                    }}
                  >
                    Adresse de départ est accessible
                  </div>

                  <FormControl
                    component="fieldset"
                    style={{ paddingBottom: '10px' }}
                    disabled={!courseEditable}
                  >
                    <RadioGroup
                      id="plVlAdresseDepart"
                      aria-label="clientType"
                      name="plVlAdresseDepart"
                      value={shipment.plVlAdresseDepart}
                      onChange={() =>
                        handleShipmentElChange(
                          'plVlAdresseDepart',
                          !shipment.plVlAdresseDepart
                        )
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="En poids lourd (Rue à double sens, Rue empruntable par un camion benne...)"
                        style={{ paddingRight: '60px' }}
                        id={'poids-lourds-Arrivee'}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Uniquement en VL (Rue étroite, tavaux ...)"
                        style={{ paddingRight: '14px' }}
                        id={'Uniquement-VL-Arrivee'}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="originObservation"
                    value={shipment.originObservation}
                    name="originObservation"
                    disabled={!courseEditable}
                    onChange={handleShipmentChange}
                    label="Informations complémentaire "
                    variant="outlined"
                    multiline
                    rows={4}
                  />
                </Grid>

                <div className="accordion">
                  <Grid item>
                    <Accordion className="accordion-section">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="client-section-title">
                          Ajouter des informations au départ{' '}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container justify spacing={3} sx={{ padding: '12px' }}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="originFirstname"
                              name="originFirstname"
                              label="Prénom"
                              variant="outlined"
                              disabled={!courseEditable}
                              value={shipment.originFirstname}
                              onChange={handleShipmentChange}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="originLastname"
                              name="originLastname"
                              label="Nom"
                              variant="outlined"
                              disabled={!courseEditable}
                              value={shipment.originLastname}
                              onChange={handleShipmentChange}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <div
                              className={
                                (
                                  !_.isNil(_.get(errors, 'originPhone'))
                                    ? !_.isNil(_.get(errors, 'originPhone.required'))
                                      ? _.get(errors, 'originPhone.required')
                                      : _.get(errors, 'originPhone.pattern', null)
                                    : true
                                )
                                  ? 'error-phone'
                                  : 'phone'
                              }
                            >
                              {' '}
                              <PhoneInput
                                id="phoneOrigin"
                                onChange={(newValue) => {
                                  handleShipmentElChange(
                                    'originPhone',
                                    '+' + newValue
                                  )
                                }}
                                disabled={!courseEditable}
                                value={shipment.originPhone}
                                onlyCountries={getPhoneInputCountries()}
                                country={'fr'}
                                inputProps={{
                                  name: 'phone',
                                  required: true,
                                  country: 'fr',
                                }}
                                isValid={() => {
                                  return !_.isNil(_.get(errors, 'originPhone'))
                                    ? !_.isNil(_.get(errors, 'originPhone.required'))
                                      ? _.get(errors, 'originPhone.required')
                                      : _.get(errors, 'originPhone.pattern', null)
                                    : true
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="originMail-shipment"
                              name="originMail"
                              label="E-mail"
                              variant="outlined"
                              disabled={!courseEditable}
                              error={!_.isNil(_.get(errors, 'originMail'))}
                              helperText={
                                !_.isNil(_.get(errors, 'originMail'))
                                  ? _.get(errors, 'originMail.pattern', null)
                                  : ''
                              }
                              value={shipment.originMail}
                              onChange={handleShipmentChange}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </div>
              </Grid>
            </form>
          </div>
          <div className="place-shipment">
            <form
              className={[
                'form-validation place-shipment-form',
                isSubmitted ? 'was-submitted' : '',
                isShipmentInValid ? 'is-invalid' : '',
                isShipmentValid ? 'is-valid' : '',
              ].join(' ')}
              noValidate
              autoComplete="off"
              onSubmit={($event) => handleShipmentSubmit($event)}
            >
              <Grid container spacing={3} sx={{ padding: '12px' }}>
                <Grid item xs={12} className="form-label">
                  Arrivée
                </Grid>
                <Grid item xs={12} className="nextAccord">
                  <AddressAutoComplete
                    id="selectedDestination-shipment"
                    label="Adresse d'arrivée"
                    selectedDefaultAddress={DestinationDefaultAddress}
                    handleQueryChange={($event) =>
                      handleShipmentElChange('destinationQuery', $event)
                    }
                    handleOnAddressSelect={($event) => {
                      handleShipmentElChange('selectedDestination', $event)
                      setDestinationDefaultAddress($event)
                    }}
                    isDisabled={!courseEditable}
                    hasError={
                      !_.isNil(_.get(errors, 'selectedDestination.required')) ||
                      !_.isNil(_.get(errors, 'selectedDestination.pattern'))
                    }
                    errorText={
                      _.get(errors, 'selectedDestination.required') ||
                      _.get(errors, 'selectedDestination.pattern')
                    }
                    isRequired
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl className="dropdown-loader">
                    <TextField
                      id="outlined-select-currency-native"
                      select
                      label="Etage"
                      name="destinationLevel"
                      value={shipment.destinationLevel || 0}
                      defaultValue={oldOrder.shipment.destinationLevel}
                      // required
                      disabled={!courseEditable}
                      onChange={handleShipmentChange}
                      variant="outlined"
                      SelectProps={{
                        MenuProps: {
                          style: { height: 300 },
                        },
                      }}
                      error={!_.isNil(_.get(errors, 'level'))}
                      helperText={
                        !_.isNil(_.get(errors, 'level'))
                          ? _.get(errors, 'level.required')
                          : ''
                      }
                    >
                      {LEVEL_NUMBERS.map((h, index) => (
                        <MenuItem value={h} key={index}>
                          {h}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <div className="switchers-section">
                    <FormControl component="fieldset" className="switcher-container">
                      <FormGroup row>
                        <FormControlLabel
                          className=" switcher-label"
                          control={
                            <Switch
                              name="destinationIsElevator"
                              value={shipment.destinationIsElevator}
                              defaultChecked={
                                oldOrder.shipment.destinationIsElevator
                              }
                              onChange={handleShipmentSwitcherChange}
                              disabled={!courseEditable}
                            />
                          }
                          label={'Présence Ascenseur'}
                          labelPlacement="end"
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="nbrMarcheAvantAscenseurAdresseArrivee"
                    value={shipment.nbrMarcheAvantAscenseurAdresseArrivee}
                    name="nbrMarcheAvantAscenseurAdresseArrivee"
                    onChange={handleNegativeChange}
                    label="Nombre de marches avant l'accès à l'ascenceur"
                    variant="outlined"
                    disabled={!courseEditable}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        type: 'text',
                        pattern: '[0-9]*',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                      paddingTop: '10px',
                      paddingBottom: '8px',
                    }}
                  >
                    {"Adresse d'arrivée est accessible"}
                  </div>

                  <FormControl
                    component="fieldset"
                    style={{ paddingBottom: '10px' }}
                    disabled={!courseEditable}
                  >
                    <RadioGroup
                      id="plVlAdresseArrivee"
                      aria-label="clientType"
                      name="plVlAdresseArrivee"
                      value={shipment.plVlAdresseArrivee}
                      onChange={() =>
                        handleShipmentElChange(
                          'plVlAdresseArrivee',
                          !shipment.plVlAdresseArrivee
                        )
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="En poids lourd (Rue à double sens, Rue empruntable par un camion benne...)"
                        style={{ paddingRight: '60px' }}
                        id={'poids-lourds-Arrivee'}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Uniquement en VL (Rue étroite, tavaux ...)"
                        style={{ paddingRight: '14px' }}
                        id={'Uniquement-VL-Arrivee'}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="observation"
                    value={shipment.destinationObservation}
                    name="destinationObservation"
                    onChange={handleShipmentChange}
                    label="Informations complémentaire "
                    variant="outlined"
                    disabled={!courseEditable && !courseSemiEdit}
                    multiline
                    // required={true}
                    rows={4}
                  />
                </Grid>
                {isSchmidt && (
                  <Grid item xs={12}>
                    <TextField
                      id="orderBalance-shipment"
                      name="orderBalance"
                      label="Contre remboursement"
                      variant="outlined"
                      value={shipment.orderBalance}
                      disabled={!courseEditable}
                      onChange={handleNegativeChange}
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          type: 'text',
                          pattern: '[0-9]*',
                        },
                      }}
                    />
                  </Grid>
                )}
                <div style={{ padding: '8px' }}>
                  <FormControl component="fieldset" className="switcher-container">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChangeClientInfos}
                          checked={shipment.clientInfos}
                          name="clientInfos"
                          disabled={!courseEditable}
                        />
                      }
                      label={'Récupérer les infos client'}
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
                <div className="accordion">
                  <Grid item>
                    <Accordion
                      className="accordion-section"
                      expanded={errorAccordion || openAccordion}
                      onChange={() => {
                        setOpenAccordion(!openAccordion)
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="client-section-title">
                          {' '}
                          Ajouter des informations {"d'arrivée "}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3} sx={{ padding: '12px' }}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="destinationFirstname-shipment"
                              name="destinationFirstname"
                              label="Prénom"
                              variant="outlined"
                              disabled={!courseEditable || shipment.clientInfos}
                              value={shipment.destinationFirstname}
                              onChange={handleShipmentChange}
                              required={!shipment.clientInfos}
                              error={!_.isNil(_.get(errors, 'destinationFirstname'))}
                              helperText={
                                !_.isNil(_.get(errors, 'destinationFirstname'))
                                  ? _.get(errors, 'destinationFirstname.required') ||
                                    _.get(errors, 'destinationFirstname.pattern')
                                  : ''
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="destinationLastname-shipment"
                              name="destinationLastname"
                              label="Nom"
                              disabled={!courseEditable || shipment.clientInfos}
                              variant="outlined"
                              value={shipment.destinationLastname}
                              onChange={handleShipmentChange}
                              required={!shipment.clientInfos}
                              error={!_.isNil(_.get(errors, 'destinationLastname'))}
                              helperText={
                                !_.isNil(_.get(errors, 'destinationLastname'))
                                  ? _.get(errors, 'destinationLastname.required') ||
                                    _.get(errors, 'destinationLastname.pattern')
                                  : ''
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <FormControl component="fieldset">
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      name="envoiMailArrivee"
                                      value={shipment.envoiMailArrivee}
                                      defaultChecked={shipment.envoiMailArrivee}
                                      onChange={handleChangeenvoiMailArrivee}
                                      disabled={!courseEditable}
                                    />
                                  }
                                  label={"Pas d'adresse mail"}
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                          <div id="destinationPhone-shipment"></div>
                          <Grid item xs={12} sm={6}>
                            <div
                              className={
                                (
                                  !_.isNil(_.get(errors, 'destinationPhone'))
                                    ? !_.isNil(
                                        _.get(errors, 'destinationPhone.required')
                                      )
                                      ? _.get(errors, 'destinationPhone.required')
                                      : _.get(
                                          errors,
                                          'destinationPhone.pattern',
                                          null
                                        )
                                    : true
                                )
                                  ? 'error-phone'
                                  : 'phone'
                              }
                            >
                              {' '}
                              <PhoneInput
                                id="destinationPhone-shipment"
                                onChange={(newValue) => {
                                  handleShipmentElChange(
                                    'destinationPhone',
                                    '+' + newValue
                                  )
                                  if (shipment.envoiMailArrivee)
                                    handleShipmentElChange(
                                      'destinationMail',
                                      '+' + newValue + '@noemail.com'
                                    )
                                }}
                                value={shipment.destinationPhone}
                                onlyCountries={getPhoneInputCountries()}
                                disabled={!courseEditable || shipment.clientInfos}
                                country={'fr'}
                                specialLabel={
                                  !shipment.clientInfos ? 'Phone *' : 'Phone'
                                }
                                inputProps={{
                                  name: 'phone',
                                  required: true,
                                  country: 'fr',
                                }}
                                isValid={() => {
                                  return !_.isNil(_.get(errors, 'destinationPhone'))
                                    ? !_.isNil(
                                        _.get(errors, 'destinationPhone.required')
                                      )
                                      ? _.get(errors, 'destinationPhone.required')
                                      : _.get(
                                          errors,
                                          'destinationPhone.pattern',
                                          null
                                        )
                                    : true
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="destinationMail-shipment"
                              name="destinationMail"
                              label="E-mail"
                              disabled={
                                !courseEditable ||
                                shipment.clientInfos ||
                                shipment.envoiMailArrivee
                              }
                              variant="outlined"
                              error={!_.isNil(_.get(errors, 'destinationMail'))}
                              helperText={
                                !_.isNil(_.get(errors, 'destinationMail'))
                                  ? _.get(errors, 'destinationMail.required') ||
                                    _.get(errors, 'destinationMail.pattern')
                                  : ''
                              }
                              value={
                                !shipment.envoiMailArrivee
                                  ? shipment.destinationMail || ''
                                  : ''
                              }
                              onChange={handleShipmentChange}
                              required={!shipment.clientInfos}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </div>
              </Grid>
            </form>
          </div>
          <div className="place-shipment">
            <form
              className={[
                'form-validation place-shipment-form',
                isSubmitted ? 'was-submitted' : '',
                isShipmentInValid ? 'is-invalid' : '',
                isShipmentValid ? 'is-valid' : '',
              ].join(' ')}
              noValidate
              autoComplete="off"
              onSubmit={($event) => handleShipmentSubmit($event)}
            >
              <Grid container spacing={3} sx={{ padding: '12px' }}>
                <Grid item xs={12} className="form-label">
                  Informations
                </Grid>

                {isManutentionOptions &&
                !_.isEqual(shipment.selectedSalesChannel, {}) ? (
                  <Grid item xs={12}>
                    <div>Manutention</div>

                    <FormControl disabled={!courseEditable} component="fieldset">
                      <RadioGroup
                        aria-label="clientType"
                        name="manutentionOptions"
                        value={shipment.manutentionOptions}
                        defaultValue={oldOrder.shipment.manutentionOptions}
                        onChange={handleShipmentChange}
                      >
                        <FormControlLabel
                          value="sansManutention"
                          control={<Radio />}
                          label="Sans Manutention"
                          style={{ paddingRight: '14px' }}
                        />
                        <FormControlLabel
                          value="avecManutention"
                          control={<Radio />}
                          label="Avec manutention"
                          style={{ paddingRight: '14px' }}
                        />
                        <FormControlLabel
                          value="avecDoubleManutention"
                          control={<Radio />}
                          label="Avec double manutention"
                          style={{ paddingRight: '14px' }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : null}
                {isVehiculeType && !_.isEqual(shipment.selectedSalesChannel, {}) ? (
                  <Grid item xs={12}>
                    <div>Véhicules</div>
                    <FormControl component="fieldset" disabled={!courseEditable}>
                      <RadioGroup
                        aria-label="clientType"
                        name="vehiculeType"
                        value={shipment.vehiculeType}
                        onChange={handleShipmentChange}
                      >
                        <FormControlLabel
                          value="S"
                          control={<Radio />}
                          label="S (longeur max 2.40m)"
                          style={{ paddingRight: '14px' }}
                        />
                        <FormControlLabel
                          value="M"
                          control={<Radio />}
                          label="M (longeur max 3.30m)"
                          style={{ paddingRight: '14px' }}
                        />
                        <FormControlLabel
                          value="L"
                          control={<Radio />}
                          label="L (longeur max 4m)"
                          style={{ paddingRight: '14px' }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <FormControl>
                    <TextField
                      id="nbItems-shipment"
                      label="Nombre d'articles"
                      variant="outlined"
                      name="nbItems"
                      margin="dense"
                      min="1"
                      max="999"
                      disabled={!courseEditable}
                      value={shipment.nbItems}
                      required
                      onChange={handleNegativeChange}
                      error={!_.isNil(_.get(errors, 'nbItems'))}
                      helperText={
                        !_.isNil(_.get(errors, 'nbItems'))
                          ? !_.isNil(_.get(errors, 'nbItems.required'))
                            ? _.get(errors, 'nbItems.required')
                            : _.get(errors, 'nbItems.pattern', null)
                          : ''
                      }
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          type: 'text',
                          pattern: '[0-9]*',
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl error={!_.isNil(_.get(errors, 'weight'))}>
                    <TextField
                      id="weight-shipment"
                      label="Poids des articles"
                      variant="outlined"
                      name="weight"
                      disabled={!courseEditable}
                      margin="dense"
                      min="0"
                      required={true}
                      value={shipment.weight}
                      onChange={handleNegativeChange}
                      error={!_.isNil(_.get(errors, 'weight'))}
                      helperText={
                        !_.isNil(_.get(errors, 'weight'))
                          ? !_.isNil(_.get(errors, 'weight.required'))
                            ? _.get(errors, 'weight.required')
                            : _.get(errors, 'weight.pattern', null)
                          : ''
                      }
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          type: 'text',
                          pattern: '[0-9]*',
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="volume"
                    name="volume"
                    label="Volume"
                    variant="outlined"
                    value={shipment.volume}
                    onChange={handleNegativeChange}
                    disabled={!courseEditable}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        type: 'text',
                        pattern: '[0-9]*',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl error={!_.isNil(_.get(errors, 'purchaseAmount'))}>
                    <TextField
                      id="purchaseAmount-shipment"
                      label="Montant d'achat"
                      variant="outlined"
                      margin="dense"
                      name="purchaseAmount"
                      disabled={!courseEditable && !courseSemiEdit}
                      required={
                        shipment.selectedSalesChannel?.configs
                          ?.priceBasedOnPurchaseAmount
                      }
                      value={shipment.purchaseAmount}
                      onChange={handleNegativeChange}
                      error={!_.isNil(_.get(errors, 'purchaseAmount'))}
                      helperText={
                        !_.isNil(_.get(errors, 'purchaseAmount'))
                          ? !_.isNil(_.get(errors, 'purchaseAmount.required'))
                            ? _.get(errors, 'purchaseAmount.required')
                            : _.get(errors, 'purchaseAmount.pattern', null)
                          : ''
                      }
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          type: 'text',
                          pattern: '[0-9]*',
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                {options && options.length ? (
                  <Grid container spacing={3} className="options-list">
                    <OptionsList
                      options={options}
                      handleQuantityChange={handleQuantityChange}
                      disabled={
                        !courseEditable && !courseSemiEdit && !coursePartialEdit
                      }
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <NoteInterne
                    noteInterne={shipment.noteInterne}
                    handleChange={handleShipmentChange}
                    isSubmitted={true}
                    errors={errors}
                    isDisabled={!courseEditable}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InvoiceInput
                    factures={factures}
                    handleInvoiceChange={handleInvoiceChange}
                    removeFacture={removeFacture}
                    addFacture={addFacture}
                    isDisabled={!courseEditable && !courseSemiEdit}
                    isSubmitted={true}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <TextField
                      error={!_.isNil(_.get(errors, 'clientPrice'))}
                      margin="dense"
                      name="clientPrice"
                      disabled={!courseEditable && !courseSemiEdit}
                      variant="outlined"
                      value={shipment.clientPrice}
                      label="Prix client"
                      onChange={handleNegativeChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      id="clientPrice-shipment"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          type: 'text',
                          pattern: '[0-9]*',
                        },
                      }}
                    />

                    <FormHelperText className="text-info">
                      {_.get(shipment, 'estimatedPrice.error', '')}
                    </FormHelperText>
                    <FormHelperTextError
                      content={
                        _.get(errors, 'clientPrice.required') ||
                        _.get(errors, 'clientPrice.pattern') ||
                        _.get(errors, 'clientPrice.min', '')
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <TextField
                      error={!_.isNil(_.get(errors, 'providerPrice'))}
                      variant="outlined"
                      disabled={!courseEditable && !courseSemiEdit}
                      margin="dense"
                      name="providerPrice"
                      value={shipment.providerPrice}
                      label="Prix prestataire"
                      onChange={handleNegativeChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      id="providerPrice-shipment"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                          type: 'text',
                          pattern: '[0-9]*',
                        },
                      }}
                    />
                    <FormHelperText className="text-info">
                      {_.get(shipment, 'estimatedPrice.error', '')}
                    </FormHelperText>
                    <FormHelperTextError
                      content={
                        _.get(errors, 'providerPrice.required') ||
                        _.get(errors, 'providerPrice.pattern') ||
                        _.get(errors, 'providerPrice.min', '')
                      }
                    />
                  </FormControl>
                </Grid>
                <input ref={submitRefs} type="submit" className="hidden"></input>
              </Grid>
            </form>
          </div>
        </>
      )}
      {isMissingTarifsConfig && !_.isEqual(shipment.selectedSalesChannel, {}) && (
        <Grid item xs={6}>
          Les configurations de tarifs sont manquantes
        </Grid>
      )}
    </>
  )
}

ShipmentSection.propTypes = {
  order: PropTypes.object.isRequired,
  oldOrder: PropTypes.object.isRequired,
  updateOrderShipment: PropTypes.func.isRequired,
  setShipmentErrors: PropTypes.func.isRequired,
  getGoogleMapDirection: PropTypes.func.isRequired,
  findDirection: PropTypes.func.isRequired,
  isGetDirectionRouteLoading: PropTypes.bool.isRequired,
  directionRoute: PropTypes.object,
  directionRouteList: PropTypes.array.isRequired,
  setOrderShipmentPrice: PropTypes.func.isRequired,
  updateDirections: PropTypes.func.isRequired,
  operationalHours: PropTypes.array.isRequired,
  getRecommendedCreneau: PropTypes.func,
  recommendedCreneauList: PropTypes.array,
  resetDirections: PropTypes.func,
  classes: PropTypes.object,
  getDayDispo: PropTypes.func,
  rdvDispo: PropTypes.array,
  courseEditable: PropTypes.bool,
  courseSemiEdit: PropTypes.bool,
  coursePartialEdit: PropTypes.bool,
  resetRdvDispo: PropTypes.func,
  getCreneauRecommendedDisponibilites: PropTypes.func,
  getConfigCanal: PropTypes.func,
  configCanal: PropTypes.array,
  resetConfigCanal: PropTypes.func,
  getDayDisponibilites: PropTypes.func,
}
export default ShipmentSection
