import React from 'react'

export function PhGitMergeFill(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M188 104a36.1 36.1 0 0 0-35.8 32h-21.7a39.6 39.6 0 0 1-30.7-14.4l-17.3-20.7a35.9 35.9 0 1 0-22.5 2.2v49.8a36 36 0 1 0 16 0v-34.8l11.5 13.7a55.4 55.4 0 0 0 43 20.2h23.6a36 36 0 1 0 33.9-48ZM88 188a20 20 0 1 1-20-20a20.1 20.1 0 0 1 20 20Zm100-28a20 20 0 1 1 20-20a20.1 20.1 0 0 1-20 20Z"
      ></path>
    </svg>
  )
}
export default PhGitMergeFill
