export const leftLinks = [
  { label: 'Commandes en cours', path: ['/commandes'], canAccess: [] },
  { label: 'Tableau de bord', path: ['/tableau-de-bord'], canAccess: [] },
  {
    label: 'Polaris',
    children: [
      { label: 'Planification', path: '/programmer' },
      { label: 'Tournées', path: '/planification/tourPolaris' },
    ],
    canAccess: [],
  },
  { label: 'Discussion', path: ['/discussion'], canAccess: [] },
  {
    label: 'Entrepot',
    children: [
      { label: 'Tableau de bord', path: '/entrepot/tableau-de-bord' },
      { label: 'Documents', path: '/entrepot/documents' },
      { label: 'Tournée de réception', path: '/entrepot/tourneeReception' },
    ],
    canAccess: [],
  },
]
export const rightLinks = [
  {
    label: 'Passer une commande',
    path: ['/Passer-une-commande', '/edit'],
    canAccess: [],
  },
]
