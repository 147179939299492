import { connect } from 'react-redux'

import { setPrepared } from '../../../redux/order/actions'

import SwitcherDetails from '../../../components/ShipmentAction/PreparedSwitch'

const mapDisptachToProps = (dispatch) => ({
  setPreparedFunction: (codeCourse, isPrepared) => {
    dispatch(setPrepared(codeCourse, isPrepared))
  },
})

export default connect(null, mapDisptachToProps)(SwitcherDetails)
