import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SelectListWithSearchApi from '../../../../../ListWithSearch/selectListWithSearchApi'
import IconTournee from '../../../../../../assets/icons/navFilter/TourneeIcon'
export default function Tour({
  disabled,
  filterStateChanged,
  tourListFilterValues,
  tourList,
  tourListLoading,
  getTourList,
  getTourListMore,
  countListTour,
  tourListMoreLoading,
}) {
  const [checked, setChecked] = useState([])

  useEffect(() => {
    let list = tourList.map((e) => {
      return {
        code: e,
        name: e,
      }
    })
    setChecked(list)
  }, [tourList])

  const onTourListChanged = (tourList) => {
    filterStateChanged('tourList', tourList ? tourList : [])
  }
  return (
    <SelectListWithSearchApi
      onDataChanged={onTourListChanged}
      defaultLabel={'Tournée'}
      dataList={checked}
      disabled={disabled || tourListLoading || tourList.length <= 0}
      filtersValues={tourListFilterValues?.map((f) => decodeURI(f))}
      Icon={IconTournee}
      id={'Tours-Filter'}
      getListData={getTourList}
      getListMoreData={getTourListMore}
      countListData={countListTour}
      isMoreLoading={tourListMoreLoading || tourListLoading}
    />
  )
}
Tour.propTypes = {
  disabled: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  tourListFilterValues: PropTypes.array,
  tourList: PropTypes.array,
  tourListLoading: PropTypes.bool,
  getTourList: PropTypes.func,
  getTourListMore: PropTypes.func,
  countListTour: PropTypes.number,
  tourListMoreLoading: PropTypes.bool,
}
