import { connect } from 'react-redux'
import BusinessOwner from '../../../components/NavFilter/components/Filter/cell/BusinessOwner'

import { onSearchBusinessOwner } from '../../../redux/filter/actions'
import {
  getIsLoadingBusinessOwner,
  getBusinessOwnerList,
} from '../../../redux/filter/selectors'
const mapStateToProps = (state) => ({
  isLoading: getIsLoadingBusinessOwner(state),
  businessOwnerList: getBusinessOwnerList(state),
})

const mapDisptachToProps = (dispatch) => ({
  onSearch: (search) => dispatch(onSearchBusinessOwner(search)),
})

export default connect(mapStateToProps, mapDisptachToProps)(BusinessOwner)
