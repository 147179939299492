import React from 'react'

export function SessionsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 15q.425 0 .713-.288T13 14v-2h2q.425 0 .713-.288T16 11q0-.425-.288-.713T15 10h-2V8q0-.425-.288-.713T12 7q-.425 0-.713.288T11 8v2H9q-.425 0-.713.288T8 11q0 .425.288.713T9 12h2v2q0 .425.288.713T12 15Zm-3 6q-.425 0-.713-.288T8 20v-1H4q-.825 0-1.413-.588T2 17V5q0-.825.588-1.413T4 3h16q.825 0 1.413.588T22 5v12q0 .825-.588 1.413T20 19h-4v1q0 .425-.288.713T15 21H9Zm-5-4h16V5H4v12Zm0 0V5v12Z"
      ></path>
    </svg>
  )
}
export default SessionsIcon
