import React, { useEffect } from 'react'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import CheckBoxList from './CheckList'
import PropTypes from 'prop-types'
import PopHover from '../../PopHover'
import PrimaryOutlineNoBorderButton from '../../Buttons/PrimaryOutlineNoBorderButton'
import DragIndicator from '@mui/icons-material/DragIndicator'
import {
  StyledFilterMenuItem,
  StyledFilterCell,
  StyledFilterSettingsIcon,
} from './style'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default function Filter({
  columns,
  handleColumns,
  index,
  localStorageNameKey,
  idFilter,
}) {
  const [checkList, setcheckList] = React.useState(columns)
  const [anchorEl, setAnchorEl] = React.useState(null)

  useEffect(() => {
    const columnList = JSON.parse(localStorage.getItem(localStorageNameKey))
    if (columnList) setcheckList(columnList)
    else setcheckList(columns)
  }, [columns])
  const handlecheckBoxChange = (event, index) => {
    const list = [...checkList]
    list[index].checked = event.target.checked
    handleColumns(list)
    localStorage.setItem(localStorageNameKey, JSON.stringify(list))
    setcheckList(list)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleListOrderChange = (list) => {
    handleColumns(list)

    localStorage.setItem(localStorageNameKey, JSON.stringify(list))
    setcheckList(list)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = reorder(checkList, result.source.index, result.destination.index)
    handleListOrderChange(items)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <StyledFilterCell
        id={idFilter}
        key={`column_${index}`}
        align="center"
        className="shadowed-cell"
      >
        <StyledFilterSettingsIcon
          onClick={(event) => setAnchorEl(event.currentTarget)}
        />
      </StyledFilterCell>
      <PopHover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        content={
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className="drop-down-content"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {checkList.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <StyledFilterMenuItem
                            key={index}
                            id={`${idFilter}-${item.id}`}
                            value={30}
                          >
                            <CheckBoxList
                              item={item}
                              index={index}
                              handleChange={handlecheckBoxChange}
                            />
                            <DragIndicator sx={{ marginLeft: 'auto' }} />
                          </StyledFilterMenuItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        }
        actionButtons={
          <>
            <PrimaryOutlineNoBorderButton
              idButton={`fermer-${idFilter}`}
              onClick={handleClose}
              label="Fermer"
              style={{ textTransform: 'none', fontWeight: 400 }}
            />
          </>
        }
        className="pophover-container"
      />
    </>
  )
}
Filter.propTypes = {
  columns: PropTypes.array,
  handleColumns: PropTypes.func,
  index: PropTypes.any,
  localStorageNameKey: PropTypes.string,
  idFilter: PropTypes.string,
}
