import { httpPost } from '../http/actions'
import {
  ADD_KEYS,
  ADD_KEYS_SUCCESS,
  ADD_KEYS_FAILURE,
  GET_KEYS,
  GET_KEYS_SUCCESS,
  GET_KEYS_FAILURE,
  UPDATE_KEY,
  UPDATE_KEY_SUCCESS,
  UPDATE_KEY_FAILURE,
  ASSIGN_KEYS_DRIVER,
  ASSIGN_KEYS_DRIVER_SUCCESS,
  ASSIGN_KEYS_DRIVER_FAILURE,
  UNASSIGN_KEYS_DRIVER,
  UNASSIGN_KEYS_DRIVER_SUCCESS,
  UNASSIGN_KEYS_DRIVER_FAILURE,
  UNASSIGN_KEYS_CHANNEL,
  UNASSIGN_KEYS_CHANNEL_SUCCESS,
  UNASSIGN_KEYS_CHANNEL_FAILURE,
  ASSIGN_KEYS_CHANNEL_SUCCESS,
  ASSIGN_KEYS_CHANNEL_FAILURE,
  ASSIGN_KEYS_CHANNEL,
  GET_ASSIGNED_CHANNEL,
  GET_ASSIGNED_CHANNEL_SUCCESS,
  GET_ASSIGNED_CHANNEL_FAILURE,
  GET_ASSIGNED_DRIVER,
  GET_ASSIGNED_DRIVER_SUCCESS,
  GET_ASSIGNED_DRIVER_FAILURE,
  GET_KEYS_LIST_MORE,
  GET_KEYS_LIST_MORE_SUCESS,
  GET_KEYS_LIST_MORE_FAILURE,
} from './constants'

import { axiosHTTP } from '../../utils'
import { removeNull } from '../../utils/utils'

const polarisApi = process.env.REACT_APP_POLARIS_MS_URL

let CancelToken = axiosHTTP.CancelToken
let cancelKeyList
let cancel
export default (store) => (next) => (action) => {
  // GET KEYS

  if (action.type === GET_KEYS) {
    // cancel the request
    if (cancelKeyList != undefined) {
      cancelKeyList()
    }

    const {
      configuration: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}keys/getKeysByFilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancelKeyList = b
        }),
        success: GET_KEYS_SUCCESS,
        failure: GET_KEYS_FAILURE,
      })
    )
  }

  if (action.type === GET_KEYS_LIST_MORE) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const {
      configuration: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}keys/getKeysByFilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: GET_KEYS_LIST_MORE_SUCESS,
        failure: GET_KEYS_LIST_MORE_FAILURE,
      })
    )
  }

  if (action.type === GET_ASSIGNED_CHANNEL) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      configuration: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}salesChanel/getAssigned`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: GET_ASSIGNED_CHANNEL_SUCCESS,
        failure: GET_ASSIGNED_CHANNEL_FAILURE,
      })
    )
  }

  if (action.type === GET_ASSIGNED_DRIVER) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      configuration: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}drivers/getAssigned`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: GET_ASSIGNED_DRIVER_SUCCESS,
        failure: GET_ASSIGNED_DRIVER_FAILURE,
      })
    )
  }
  // add KEYS

  if (action.type === ADD_KEYS) {
    // cancel the request

    const { keys } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}keys/createKey`,
        data: keys,
        success: ADD_KEYS_SUCCESS,
        failure: ADD_KEYS_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_KEY) {
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}keys/updateKey`,
        data: action.payload,
        success: UPDATE_KEY_SUCCESS,
        failure: UPDATE_KEY_FAILURE,
      })
    )
  }

  if (action.type === ASSIGN_KEYS_DRIVER) {
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}keys/assignToDriver`,
        data: action.payload,
        success: ASSIGN_KEYS_DRIVER_SUCCESS,
        failure: ASSIGN_KEYS_DRIVER_FAILURE,
      })
    )
  }

  if (action.type === UNASSIGN_KEYS_DRIVER) {
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}keys/unassignFromDriver`,
        data: action.payload,
        success: UNASSIGN_KEYS_DRIVER_SUCCESS,
        failure: UNASSIGN_KEYS_DRIVER_FAILURE,
      })
    )
  }

  if (action.type === ASSIGN_KEYS_CHANNEL) {
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}salesChanel/assignKey`,
        data: action.payload,
        success: ASSIGN_KEYS_CHANNEL_SUCCESS,
        failure: ASSIGN_KEYS_CHANNEL_FAILURE,
      })
    )
  }

  if (action.type === UNASSIGN_KEYS_CHANNEL) {
    store.dispatch(
      httpPost({
        endPoint: `${polarisApi}salesChanel/unassignKey`,
        data: action.payload,
        success: UNASSIGN_KEYS_CHANNEL_SUCCESS,
        failure: UNASSIGN_KEYS_CHANNEL_FAILURE,
      })
    )
  }

  next(action)
}
