import {
  GET_TOURNEES,
  PAGE_CHANGED_TOURNEES,
  PAGE_LIMIT_CHANGED_TOURNEES,
  REMOVE_ERROR_TOURNEES,
  RESET_SUCCESS_ACTION_TOURNEES,
  RESET_CONFIG_TOURNEES,
  SEARCH_TOURNEES,
  ADD_TOURNEE,
  UPDATE_TOURNEE,
} from './constants'

export const fetchTournees = () => ({
  type: GET_TOURNEES,
})

export const pageChanged = (offset) => ({
  type: PAGE_CHANGED_TOURNEES,
  payload: { offset },
})

export const pageLimitChanged = (limit) => ({
  type: PAGE_LIMIT_CHANGED_TOURNEES,
  payload: { limit },
})

export const removeError = () => ({
  type: REMOVE_ERROR_TOURNEES,
})

export const resetSuccessAction = () => ({
  type: RESET_SUCCESS_ACTION_TOURNEES,
})

export const resetTourneeConfig = () => ({
  type: RESET_CONFIG_TOURNEES,
})

export const onSearch = (search) => ({
  type: SEARCH_TOURNEES,
  payload: { search },
})

export const createTournee = (tournee) => ({
  type: ADD_TOURNEE,
  payload: { tournee },
})

export const updateTournee = (newTournee) => ({
  type: UPDATE_TOURNEE,
  payload: { newTournee },
})
