import React from 'react'

export function MdiMapClock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16px"
      height="16px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#373d57"
        d="M15 12h1.5v4.25l2.86 1.69l-.75 1.22L15 17v-5m8 4a7 7 0 0 1-7 7c-3 0-5.6-1.92-6.58-4.6L8 17.9l-5.34 2.07l-.16.03a.5.5 0 0 1-.5-.5V4.38c0-.23.15-.41.36-.48L8 2l6 2.1L19.34 2h.16a.5.5 0 0 1 .5.5v7.75c1.81 1.25 3 3.37 3 5.75M9 16c0-3.17 2.11-5.85 5-6.71V6.11L8 4v11.89l1 .35V16m7-5a5 5 0 0 0-5 5a5 5 0 0 0 5 5a5 5 0 0 0 5-5a5 5 0 0 0-5-5Z"
      ></path>
    </svg>
  )
}
export default MdiMapClock
