import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { isNull } from 'lodash'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import IconsEdit from '../../../../../assets/icons/polaris/EditIcons'
import IconsCancel from '../../../../../assets/icons/polaris/CancelIcons'
import IconsDone from '../../../../../assets/icons/polaris/DoneIcons'
import {
  ACCURATE_ADDRESS,
  ClientDelivery,
  COURSE_PLACEE_MANUELLEMENT,
} from '../../../../../utils/values'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import TableRowsContent from './TableContent'
import _ from 'lodash'
import {
  checkActionsUservedOrders,
  columnsListUservedOrders,
  columnsOptimisationCoursesFileUservedOrders,
  settingsTable,
} from './data'
import TableCellCustomized from '../../../../../components/TableCellCustomized'
import { StyledCell } from './TableContent/style'
import { TableCell, Tooltip } from '@mui/material'
import MoveAction from '../MoveAction'

const UnservedOrders = ({
  getCourseType,
  unservedOrders,
  handleCheckAll,
  handleCheck,
  checkedAllOrder,
  resetCheckedAll,
  fullScreen,
  getRecommendedCreneau,
  recommendedCreneauList,
  getGoogleMapDirection,
  isGetDirectionRouteLoading,
  directionRoute,
  updateCourse,
  isLoadingUpdateCoursePolaris,
  isUpdateCoursePolarisAction,
  updateActionCoursePolaris,
  resetDirections,
  isOptimisationCoursesFiles,
  updateCourseOptimisationFile,
  filterCourseFile,
  filtersValues,
  setFilters,
  moveTableUnserved,
  planningFiltered,
}) => {
  useEffect(() => {
    if (Object.keys(unservedOrders).length === 0) resetCheckedAll()
  }, [unservedOrders])

  const handleCheckAllOrder = () => {
    handleCheckAll(checkedAllOrder)
  }

  const verifDisabledCheckAll = () => {
    return (
      Object.keys(unservedOrders).filter(
        (e) =>
          isOptimisationCoursesFiles &&
          !ACCURATE_ADDRESS.includes(unservedOrders[e].dropOffVisit.locationType)
      ).length == Object.keys(unservedOrders).length
    )
  }

  const handleCheckOrder = (id) => {
    handleCheck(id)
  }
  const getDraggingItemStyle = (checked, disabled, withReason) => {
    let background = 'none'

    if (checked) {
      background = 'rgba(0, 0, 0, 0.20)'
    } else if (disabled) {
      background = '#F1F1F1'
    } else if (withReason) {
      background = '#ffca8a'
    }

    return {
      background,
    }
  }
  const [tableEditableRow, setTableEditableRow] = useState({})
  const [modeEditable, setModeEditable] = useState(false)
  const [disabledButtonUpdate, setDisabledButtonUpdate] = useState(false)
  const [errorsCourseEditable, setErrorsCourseEditable] = useState([])

  const getEditableRow = (codeCourse) => {
    return Object.keys(tableEditableRow).includes(codeCourse)
  }

  const verifEditableRow = () => {
    return Object.keys(tableEditableRow).length > 0
  }

  const handleEdit = (c) => {
    if (tableEditableRow[c.id] !== c.value) {
      setModeEditable(true)
      setTableEditableRow({
        ...tableEditableRow,
        [c.id]: c.value,
      })
    }
  }
  const onToggleCancel = (id) => {
    setModeEditable(false)
    const tabEditable = tableEditableRow
    delete tabEditable[id]
    isDisabledButtonUpdate(tabEditable)
    setTableEditableRow(tabEditable)
    setErrorsCourseEditable([])
  }

  const onChangeContentColumn = (codeCourse, value, extraPram) => {
    const tabEditable = tableEditableRow
    tabEditable[codeCourse] = {
      ...tabEditable[codeCourse],
      content: {
        ...tabEditable[codeCourse].content,
        ...value,
      },
    }
    isDisabledButtonUpdate(tabEditable)
    validationCourseEditable(tabEditable[codeCourse].content, extraPram)
    setTableEditableRow(tabEditable)
  }

  const onDeleteContentColumn = (codeCourse, TabNameAttribut) => {
    const tabEditable = tableEditableRow
    TabNameAttribut.forEach((key) => {
      if (tabEditable[codeCourse]?.content)
        delete tabEditable[codeCourse].content[key]
    })
    isDisabledButtonUpdate(tabEditable)
    setTableEditableRow(tabEditable)
  }

  const onToggleUpdateCourse = () => {
    const codeCourse = Object.keys(tableEditableRow)[0]
    isOptimisationCoursesFiles
      ? updateCourseOptimisationFile({
          receptionNumber: codeCourse,
          ...tableEditableRow[codeCourse].content,

          ...(!tableEditableRow[codeCourse].content && {
            adresseArrivee: { locationType: 'ROOFTOP' },
          }),
        })
      : updateCourse({
          codeCourse: [codeCourse],
          ...tableEditableRow[codeCourse].content,
        })
  }
  const isDisabledButtonUpdate = (tabEditable) => {
    const codeCourse = Object.keys(tabEditable)[0]

    setDisabledButtonUpdate(
      tabEditable[codeCourse]?.content &&
        Object.keys(tabEditable[codeCourse].content).length > 0
    )
  }
  useEffect(() => {
    if (directionRoute) {
      const codeCourse = Object.keys(tableEditableRow)[0]
      const tabEditable = tableEditableRow
      tabEditable[codeCourse] = {
        ...tabEditable[codeCourse],
        content: {
          ...tabEditable[codeCourse].content,
          estimatedKM: directionRoute.selectedDirection.distance,
        },
      }
      isDisabledButtonUpdate(tabEditable)
      setTableEditableRow(tabEditable)
    }
  }, [directionRoute])

  useEffect(() => {
    if (!isOptimisationCoursesFiles && modeEditable) {
      const codeCourse = Object.keys(tableEditableRow)[0]
      const verifCourseType = ClientDelivery.includes(
        unservedOrders[codeCourse].dropOffVisit.courseType
      )

      const date = verifCourseType
        ? moment(unservedOrders[codeCourse].dropOffVisit.windowStart).format(
            'YYYY-MM-DD'
          )
        : moment(unservedOrders[codeCourse].pickUpVisit.windowStart).format(
            'YYYY-MM-DD'
          )
      let codeCanal = unservedOrders[codeCourse].dropOffVisit.salesChanel?.code

      getRecommendedCreneau(date, codeCanal)
    }
  }, [modeEditable])

  useEffect(() => {
    if (isUpdateCoursePolarisAction) {
      setModeEditable(false)
      setDisabledButtonUpdate(false)
      setTableEditableRow({})
      setErrorsCourseEditable([])
      updateActionCoursePolaris()
      resetDirections()
    }
  }, [isUpdateCoursePolarisAction])

  const validationCourseEditable = (course, extraPram) => {
    let errors = []
    if (
      (Object.keys(course).includes('secondDropOffStart') ||
        Object.keys(course).includes('secondDropOffEnd')) &&
      moment(
        _.get(course, 'secondDropOffStart')
          ? _.get(course, 'secondDropOffStart')
          : extraPram?.dropOffStart
      ).diff(
        moment(
          _.get(course, 'secondDropOffEnd')
            ? _.get(course, 'secondDropOffEnd')
            : extraPram?.dropOffEnd
        )
      ) > 0
    ) {
      errors.push('creneau2')
    }

    if (
      (Object.keys(course).includes('dropOffStart') ||
        Object.keys(course).includes('dropOffEnd')) &&
      moment(
        _.get(course, 'dropOffStart')
          ? _.get(course, 'dropOffStart')
          : extraPram?.dropOffStart
      ).diff(
        moment(
          _.get(course, 'dropOffEnd')
            ? _.get(course, 'dropOffEnd')
            : extraPram?.dropOffEnd
        )
      ) > 0
    ) {
      errors.push('creneau1')
    }

    if (
      Object.keys(course).includes('weight') &&
      (_.isNil(_.get(course, 'weight')) ||
        _.get(course, 'weight') === '' ||
        !_.isNumber(parseFloat(_.get(course, 'weight'))))
    ) {
      errors.push('weight')
    }

    if (
      Object.keys(course).includes('volume') &&
      (_.isNil(_.get(course, 'volume')) ||
        _.get(course, 'volume') === '' ||
        !_.isNumber(parseFloat(_.get(course, 'volume'))))
    ) {
      errors.push('volume')
    }

    if (
      isOptimisationCoursesFiles &&
      Object.keys(course).includes('prestaTime') &&
      (_.isNil(_.get(course, 'prestaTime')) ||
        _.get(course, 'prestaTime') === '' ||
        !_.isNumber(parseFloat(_.get(course, 'prestaTime'))))
    ) {
      errors.push('prestaTime')
    }

    if (
      isOptimisationCoursesFiles &&
      Object.keys(course).includes('count') &&
      (_.isNil(_.get(course, 'count')) ||
        _.get(course, 'count') === '' ||
        !_.isNumber(parseFloat(_.get(course, 'count'))))
    ) {
      errors.push('count')
    }

    if (
      isOptimisationCoursesFiles &&
      Object.keys(course).includes('price') &&
      (_.isNil(_.get(course, 'price')) ||
        _.get(course, 'price') === '' ||
        !_.isNumber(parseFloat(_.get(course, 'price'))))
    ) {
      errors.push('price')
    }

    if (
      !isOptimisationCoursesFiles &&
      Object.keys(course).includes('adresseArrivee') &&
      (_.isNil(_.get(course, 'adresseArrivee')) ||
        _.isNil(_.get(course, 'adresseArrivee.address')) ||
        _.isNil(_.get(course, 'adresseArrivee.latitude')) ||
        _.isNil(_.get(course, 'adresseArrivee.longitude')))
    ) {
      errors.push('adresseArrivee')
    }

    if (
      isOptimisationCoursesFiles &&
      Object.keys(course).includes('adresseArrivee') &&
      (_.isNil(_.get(course, 'adresseArrivee')) ||
        _.isNil(_.get(course, 'adresseArrivee.address')) ||
        _.isNil(_.get(course, 'adresseArrivee.latitude')) ||
        _.isNil(_.get(course, 'adresseArrivee.longitude')))
      // ajouter code postale et city (verification Mohamed ElLili)
    ) {
      errors.push('adresseArrivee')
    }
    setErrorsCourseEditable(errors)
  }
  return (
    <>
      {isNull(unservedOrders) ? (
        <></>
      ) : (
        <Paper
          className="order-table-root"
          style={{
            margin: 0,
            width: '100%',
            backgroundColor: 'transparent',
            overflow: 'auto',
            height: !fullScreen ? 'calc(26vh - 40px )' : `calc(55vh -  40px )`,
          }}
        >
          <TableContainer sx={{ overflowX: 'unset' }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ backgroundColor: 'white' }}
            >
              <TableHead
                style={{
                  position: 'sticky',
                  zIndex: 5,
                }}
              >
                <TableRow>
                  {[
                    ...checkActionsUservedOrders,
                    ...(isOptimisationCoursesFiles
                      ? columnsOptimisationCoursesFileUservedOrders
                      : columnsListUservedOrders),
                    // ...buttonActionUservedOrders,
                    ...settingsTable,
                  ].map((column, index) =>
                    column.id === 'check' ? (
                      <StyledCell
                        style={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 5,
                          minWidth: '70px',
                          background: '#ffff',
                        }}
                        key={`column_${index}`}
                        align="left"
                      >
                        <Checkbox
                          checked={checkedAllOrder}
                          onChange={handleCheckAllOrder}
                          disabled={verifDisabledCheckAll()}
                        />
                      </StyledCell>
                    ) : column.id === 'action' ? (
                      <StyledCell
                        style={{
                          position: 'sticky',
                          right: 0,
                          minWidth: '70px',
                          background: '#ffff',
                        }}
                        key={`Action`}
                        align="left"
                      ></StyledCell>
                    ) : column.id === 'reason' ? (
                      <TableCell key={`reason`} align="left">
                        Raison
                      </TableCell>
                    ) : column.id === 'settings' ? (
                      <MoveAction
                        index={index}
                        planning={planningFiltered}
                        disabledActionMov={Object.keys(unservedOrders).every(
                          (e) => !unservedOrders[e].dropOffVisit.checked
                        )}
                        isTableTournee={false}
                        moveTableUnserved={moveTableUnserved}
                      />
                    ) : (
                      <TableCellCustomized
                        key={`column_${index}`}
                        alignCell="center"
                        column={column}
                        filterCourseFile={filterCourseFile}
                        setFilters={setFilters}
                        filtersValues={filtersValues}
                      />
                    )
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {unservedOrders && Object.keys(unservedOrders).length > 0 ? (
                  Object.keys(unservedOrders).map((e, i) => {
                    //  const unservedTourInfo = unservedOrdersWithFilter[e].dropOffVisit
                    return (
                      <TableRow
                        // hover
                        role="checkbox"
                        // tabIndex={-1}
                        id={`Course-${i}`}
                        key={i}
                        style={getDraggingItemStyle(
                          unservedOrders[e].dropOffVisit.checked,
                          isOptimisationCoursesFiles &&
                            !ACCURATE_ADDRESS.includes(
                              unservedOrders[e].dropOffVisit.locationType
                            ),
                          unservedOrders[e].dropOffVisit.reason &&
                            unservedOrders[e].dropOffVisit.reason !=
                              COURSE_PLACEE_MANUELLEMENT &&
                            unservedOrders[e].dropOffVisit.reason != '-'
                        )}
                      >
                        <StyledCell
                          style={{
                            position: 'sticky',
                            left: 0,
                            minWidth: '70px',
                            background: '#ffff',
                          }}
                        >
                          <Checkbox
                            disabled={
                              isOptimisationCoursesFiles &&
                              !ACCURATE_ADDRESS.includes(
                                unservedOrders[e].dropOffVisit.locationType
                              )
                            }
                            checked={unservedOrders[e].dropOffVisit.checked}
                            onChange={() =>
                              handleCheckOrder(
                                unservedOrders[e].dropOffVisit?.location_id
                              )
                            }
                            component={'td'}
                          />
                        </StyledCell>

                        <TableRowsContent
                          row={unservedOrders[e]}
                          getCourseType={getCourseType}
                          rowNumber={i}
                          editable={getEditableRow(e)}
                          codeCourse={e}
                          onChangeContentColumn={onChangeContentColumn}
                          recommendedCreneauList={recommendedCreneauList || []}
                          getGoogleMapDirection={getGoogleMapDirection}
                          onDeleteContentColumn={onDeleteContentColumn}
                          columnsList={
                            isOptimisationCoursesFiles
                              ? columnsOptimisationCoursesFileUservedOrders
                              : columnsListUservedOrders
                          }
                          errorsCourseEditable={errorsCourseEditable}
                          isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                          disabledColumn={
                            isOptimisationCoursesFiles &&
                            !ACCURATE_ADDRESS.includes(
                              unservedOrders[e].dropOffVisit.locationType
                            )
                          }
                        />
                        <StyledCell
                          align="center"
                          style={{
                            position: 'sticky',
                            right: 0,
                            background: '#ffff',
                            borderLeft: '1px solid #E0E0E0',
                            zIndex: 2,
                          }}
                        >
                          {getEditableRow(e) ? (
                            <>
                              {isLoadingUpdateCoursePolaris ? (
                                <CircularProgress
                                  color="primary"
                                  style={{ width: '20px', height: '20px' }}
                                />
                              ) : (
                                <>
                                  <IconButton
                                    aria-label="cancel"
                                    onClick={() => onToggleCancel(e)}
                                  >
                                    <IconsCancel
                                      style={{
                                        color: '#D21818',
                                        width: '17px',
                                        height: '17px',
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton
                                    aria-label="update"
                                    disabled={errorsCourseEditable.length > 0}
                                    onClick={() => {
                                      if (
                                        (!isGetDirectionRouteLoading &&
                                          disabledButtonUpdate) ||
                                        isOptimisationCoursesFiles
                                      )
                                        onToggleUpdateCourse()
                                    }}
                                  >
                                    <IconsDone
                                      style={{
                                        color:
                                          isGetDirectionRouteLoading ||
                                          (!disabledButtonUpdate &&
                                            !isOptimisationCoursesFiles) ||
                                          errorsCourseEditable.length > 0
                                            ? 'rgb(105 110 129)'
                                            : '#297B1D',
                                        width: '17px',
                                        height: '17px',
                                        cursor:
                                          isGetDirectionRouteLoading ||
                                          (!disabledButtonUpdate &&
                                            !isOptimisationCoursesFiles) ||
                                          (errorsCourseEditable.length > 0 &&
                                            'not-allowed'),
                                      }}
                                    />
                                  </IconButton>
                                </>
                              )}
                            </>
                          ) : (
                            <Tooltip title="Modifié" placement="bottom">
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleEdit({
                                    id: `${e}`,
                                    value: true,
                                  })
                                }
                                disabled={verifEditableRow()}
                              >
                                <IconsEdit
                                  style={{
                                    color: verifEditableRow()
                                      ? 'rgb(105 110 129)'
                                      : '#1976d2',
                                    width: '17px',
                                    height: '17px',
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow
                    // hover
                    role="checkbox"
                    // tabIndex={-1}
                    key={-2}
                    style={{
                      background: 'none',
                    }}
                  >
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      style={{ fontWeight: '500', fontSize: '16px' }}
                    >
                      Insérer des courses ici..
                    </Typography>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  )
}
UnservedOrders.propTypes = {
  getCourseType: PropTypes.func,
  unservedOrders: PropTypes.object,
  handleCheckAll: PropTypes.func,
  handleCheck: PropTypes.func,
  checkedAllOrder: PropTypes.bool,
  resetCheckedAll: PropTypes.func,
  heightBlockMap: PropTypes.number,
  fullScreen: PropTypes.bool,
  getRecommendedCreneau: PropTypes.func,
  recommendedCreneauList: PropTypes.array,
  getGoogleMapDirection: PropTypes.func,
  isGetDirectionRouteLoading: PropTypes.bool,
  directionRoute: PropTypes.object,
  updateCourse: PropTypes.func,
  isLoadingUpdateCoursePolaris: PropTypes.bool,
  isUpdateCoursePolarisAction: PropTypes.bool,
  updateActionCoursePolaris: PropTypes.func,
  resetDirections: PropTypes.func,
  isOptimisationCoursesFiles: PropTypes.bool,
  updateCourseOptimisationFile: PropTypes.func,
  filterCourseFile: PropTypes.object,
  filtersValues: PropTypes.object,
  setFilters: PropTypes.object,
  moveTableUnserved: PropTypes.func,
  planningFiltered: PropTypes.array,
}

export default UnservedOrders
