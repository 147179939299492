import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Tableformations from '../components/Table/ConfigurationTable'
import { StyledContainer } from '../styled'
import { FormationTableHead } from '../components/Table/data'

const GestionFormations = ({
  fetchData,
  fetchFiles,
  isLoadingFormationsList,
  formations,
  formationsCount,

  errorMessage,
  removeError,
  isErrorFetch,

  pageLimitChanged,
  pageChanged,

  success,
  isSuccessRefresh,
  resetSuccessAction,

  searchData,

  onCloseReset,
  fetchDataById,

  errorMessageConfig,
  removeErrorConfig,
}) => {
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (success && isSuccessRefresh) {
      fetchData()
    }
    if (success || isSuccessRefresh) {
      resetSuccessAction()
    }
  }, [success])

  return (
    <>
      <StyledContainer>
        <Tableformations
          globalID={'Formations'}
          searchData={searchData}
          searchPlaceholder={'Rechercher par formation'}
          tableHead={FormationTableHead}
          tableRows={formations}
          isFormation={true}
          actionButton={true}
          title={'Gestion formations'}
          loadingData={isLoadingFormationsList}
          pageLimitChanged={pageLimitChanged}
          pageChanged={pageChanged}
          onCloseReset={onCloseReset}
          countData={formationsCount}
          errorMessage={errorMessage || errorMessageConfig}
          removeError={removeError}
          isErrorFetch={isErrorFetch}
          fetchData={fetchData}
          fetchFiles={fetchFiles}
          fetchDataById={fetchDataById}
          removeErrorConfig={removeErrorConfig}
          resetPagination={success && isSuccessRefresh}
        />
      </StyledContainer>
    </>
  )
}
GestionFormations.propTypes = {
  fetchData: PropTypes.func,
  isLoadingFormationsList: PropTypes.bool,
  formations: PropTypes.array,
  formationsCount: PropTypes.number,

  errorMessage: PropTypes.string,
  removeError: PropTypes.func,
  isErrorFetch: PropTypes.bool,

  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,

  success: PropTypes.bool,
  isSuccessRefresh: PropTypes.bool,
  resetSuccessAction: PropTypes.func,

  searchData: PropTypes.func,
  onCloseReset: PropTypes.func,
  fetchFiles: PropTypes.func,

  errorMessageConfig: PropTypes.string,
  removeErrorConfig: PropTypes.func,
  fetchDataById: PropTypes.func,
}
export default GestionFormations
