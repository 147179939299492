import { connect } from 'react-redux'
import AccordionItem from '../../components/AccordionList/accordionItem'
import {
  deVerrouillerTournee,
  updateFocusedTours,
  verrouillerTournee,
} from '../../redux/simulateTournee/actions'

const mapDisptachToProps = (dispatch) => ({
  updateFocusedTours: (del, id) => {
    dispatch(updateFocusedTours(del, id))
  },
  verrouillerTournee: (idDriver) => {
    dispatch(verrouillerTournee(idDriver))
  },
  deVerrouillerTournee: (idDriver) => {
    dispatch(deVerrouillerTournee(idDriver))
  },
})

export default connect(null, mapDisptachToProps)(AccordionItem)
