import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { replaceDotWithComma } from '../../../../../../utils/floatFormatting'
import TableHead from '@mui/material/TableHead'
import LongTextComponent from '../../../../Orders/Table/LongTextComponent'
import Assembly from '../../../../Orders/Grid/Cell/CardHeader/Assembly'
import Filter from '../../../../Orders/Table/Filter'
import './../index.scss'
import Button from '@mui/material/Button'
import EmptyTable from '../../../../../../components/EmptyTable/EmptyTable'
import CircularProgress from '@mui/material/CircularProgress'
import Toolbar from '@mui/material/Toolbar'
import { LimitPaginationList } from '../../../../../../components/Pagination/LimitPaginationList'
import { uuid } from 'uuidv4'
import { styled, useTheme } from '@mui/material/styles'

export const list = [
  { id: 'ldv', label: 'LDV', minWidth: 35, name: 'LDV', checked: true },
  {
    id: 'dateDemarrage',
    label: 'Date Demarrage',
    minWidth: 80,
    name: 'Date Demarrage',
    checked: true,
  },
  { id: 'prix', label: 'Prix', minWidth: 80, name: 'Prix', checked: true },
  { id: 'montage', label: 'Montage', minWidth: 80, name: 'Montage', checked: false },
  {
    id: 'observation',
    label: 'Observation',
    minWidth: 80,
    name: 'Observations',
    checked: false,
  },
  {
    id: 'dropOffDate',
    label: 'Créneau de livraison',
    minWidth: 100,
    name: 'Créneau de livraison',
    checked: true,
    sortName: 'dropOffDate',
  },
  {
    id: 'salesChannel',
    label: 'Canal Vente',
    minWidth: 80,
    name: 'Canal Vente',
    checked: true,
  },
  {
    id: 'adresseDepart',
    label: 'Adresse Départ',
    minWidth: 80,
    name: 'Adresse Départ',
    checked: false,
  },
  {
    id: 'adresseArrivee',
    label: 'Adresse Arrivée',
    minWidth: 80,
    name: 'Adresse Arrivée',
    checked: true,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 20,
    name: 'Poids',
    checked: false,
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 20,
    name: 'Volume',
    checked: false,
  },
  {
    id: 'chauffeur',
    label: 'Chauffeur',
    minWidth: 80,
    name: 'Chauffeur',
    checked: true,
  },
  { id: 'societe', label: 'Societe', minWidth: 80, name: 'Societe', checked: true },
  {
    id: 'collaborateur',
    label: 'Agent Corner',
    minWidth: 80,
    name: 'Agent Corner',
    checked: false,
  },
  { id: 'status', label: 'Status', minWidth: 80, name: 'Status', checked: true },
]

const StyledTable = styled(Table)({
  minWidth: '500px !important',
  border: '1px solid rgba(224, 224, 224, 1) !important',
  borderTop: 'none !important',
})

export default function DashboardOrdersTable({
  shipments,
  handleSelectedShipment,
  count,
  pageLimitChanged,
  pageChanged,
  isLoading,
  searchReducer,
}) {
  const [columns, setColumns] = React.useState(list)
  const [rows, setRows] = React.useState([])
  const [columsOrder, setColumnsOrder] = React.useState(columns.map((c) => c.id))
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(LimitPaginationList[0].value)
  const [limitList, setLimitList] = React.useState([25])
  const theme = useTheme()
  useEffect(() => {
    setColumnsOrder(columns.map((c) => c.id))
  }, [columns])
  const handleColumns = (list) => {
    setColumns(list)
  }
  const getChecked = (name) => {
    let isChecked = false
    columns.filter((item) => {
      if (item.name === name) {
        isChecked = item.checked
      }
    })
    return isChecked
  }
  useEffect(() => {
    let rowsList = []
    if (shipments.length > 0) {
      rowsList = shipments.map(
        ({
          ldv,
          dateDemarrage,
          prix,
          montage,
          observation,
          salesChannel,
          adresseDepart,
          adresseArrivee,
          chauffeur,
          societe,
          collaborateur,
          status,
          weight,
          volume,
          deliveryDate,
        }) => {
          return {
            ldv,
            dateDemarrage,
            prix,
            montage,
            observation,
            salesChannel,
            adresseDepart,
            deliveryDate,
            adresseArrivee,
            chauffeur,
            societe,
            collaborateur,
            status,
            weight,
            volume,
          }
        }
      )
    }
    setRows(rowsList)
    let countingList = []
    const listOptions = [25, 50, 100, 200]
    listOptions.forEach((elem) => {
      if (count >= elem) {
        countingList.push(elem)
      }
    })
    setLimitList(countingList)
  }, [shipments])

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    const search = searchReducer && count > 1
    const searchCorner = {
      criteria: searchReducer?.label,
      type: searchReducer?.categorie.code,
    }
    pageChanged(rowsPerPage * newPage, search, searchCorner)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    const search = searchReducer && count > 1
    const searchCorner = {
      criteria: searchReducer?.label,
      type: searchReducer?.categorie.code,
    }
    pageLimitChanged(parseInt(event.target.value, 10), search, searchCorner)
  }
  // Assembly toggle
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  //
  return (
    <div className="dashboard-orders-table">
      <Toolbar className="toolbar-contant toolbar-orders-contant">
        <Filter columns={columns} handleColumns={handleColumns} isDashboard={true} />
      </Toolbar>
      <TableContainer component={Paper} className="order-table-root ">
        <StyledTable aria-label="custom pagination table" stickyHeader>
          <TableHead>
            <TableRow>
              {columns
                .filter((column) => column.checked)
                .map((column, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    style={{
                      backgroundColor: 'white',
                      color: 'black',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          {count > 0 ? (
            <>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <td colSpan={columns.filter((column) => column.checked).length}>
                      <div
                        className="empty-table-container"
                        style={{ display: 'grid' }}
                      >
                        <CircularProgress
                          style={{
                            color: theme.palette.primary.main,
                            margin: 'auto',
                            width: 35,
                          }}
                        />
                        <h4 className="empty-table">Chargement en cours .. </h4>
                      </div>
                    </td>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  <>
                    {rows.map(
                      (
                        {
                          ldv,
                          dateDemarrage,
                          prix,
                          montage,
                          observation,
                          salesChannel,
                          adresseDepart,
                          adresseArrivee,
                          chauffeur,
                          societe,
                          collaborateur,
                          status,
                          weight,
                          volume,
                          deliveryDate,
                        },
                        index
                      ) => (
                        <TableRow
                          key={index}
                          onClick={() =>
                            handleSelectedShipment({
                              id: uuid(),
                              origin: adresseDepart,
                              destination: adresseArrivee,
                              status,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          {[
                            { name: 'ldv', content: ldv, show: getChecked('LDV') },
                            {
                              name: 'dateDemarrage',
                              content: dateDemarrage,
                              show: getChecked('Date Demarrage'),
                            },
                            {
                              name: 'prix',
                              content: replaceDotWithComma(prix),
                              show: getChecked('Prix'),
                            },
                            {
                              name: 'montage',
                              content: montage,
                              show: getChecked('Montage'),
                            },
                            {
                              name: 'observation',
                              content: observation,
                              show: getChecked('Observations'),
                            },
                            {
                              name: 'dropOffDate',
                              content: deliveryDate,
                              show: getChecked('Créneau de livraison'),
                            },
                            {
                              name: 'salesChannel',
                              content: salesChannel,
                              show: getChecked('Canal Vente'),
                            },
                            {
                              name: 'adresseDepart',
                              content: adresseDepart.address,
                              show: getChecked('Adresse Départ'),
                            },
                            {
                              name: 'adresseArrivee',
                              content: adresseArrivee.address,
                              show: getChecked('Adresse Arrivée'),
                            },
                            {
                              name: 'weight',
                              content: weight
                                ? replaceDotWithComma(weight.toString())
                                : 'N/A',
                              show: getChecked('Poids'),
                            },
                            {
                              name: 'volume',
                              content: volume
                                ? replaceDotWithComma(volume.toString())
                                : 'N/A',
                              show: getChecked('Volume'),
                            },
                            {
                              name: 'chauffeur',
                              content: chauffeur,
                              show: getChecked('Chauffeur'),
                            },
                            {
                              name: 'societe',
                              content: societe,
                              show: getChecked('Societe'),
                            },
                            {
                              name: 'collaborateur',
                              content: collaborateur,
                              show: getChecked('Agent Corner'),
                            },
                            {
                              name: 'status',
                              content: status.label,
                              show: getChecked('Status'),
                            },
                          ]
                            .sort(
                              (a, b) =>
                                columsOrder.indexOf(a.name) -
                                columsOrder.indexOf(b.name)
                            )
                            .filter((element) => element.show)
                            .map((item, index) => {
                              switch (item.name) {
                                case 'montage':
                                  return montage.length !== 0 ? (
                                    <Assembly
                                      assembler={montage[0].assembler}
                                      clientPrice={montage[0].clientPrice}
                                      assemblyId={montage[0].id}
                                      startAt={montage[0].startAt}
                                      anchorEl={anchorEl}
                                      handleClick={(event) =>
                                        setAnchorEl(event.currentTarget)
                                      }
                                      handleClose={handleClose}
                                      open={open}
                                      key={`assembly_found_${index}`}
                                    />
                                  ) : (
                                    <LongTextComponent
                                      display={true}
                                      text={null}
                                      key={`assembly_not_found_${index}`}
                                    />
                                  )
                                case 'status':
                                  return (
                                    <td
                                      className="MuiTableCell-root"
                                      key={`status_${index}`}
                                      style={{
                                        borderBottom:
                                          '1px solid rgba(224, 224, 224, 1)',
                                      }}
                                    >
                                      <Button
                                        className="order-table-button"
                                        disabled={true}
                                        variant="contained"
                                        sx={{
                                          color: `${status.color} !important`,
                                          border: `1px solid ${status.color}`,
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          height: '33px',
                                          width: '88px',
                                        }}
                                      >
                                        {' '}
                                        {status.label}{' '}
                                      </Button>
                                    </td>
                                  )
                                default:
                                  return (
                                    <LongTextComponent
                                      display={true}
                                      text={item.content}
                                      key={`content_${index}`}
                                    />
                                  )
                              }
                            })}
                        </TableRow>
                      )
                    )}

                    {emptyRows > 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={columns.filter((column) => column.checked).length}
                        />
                      </TableRow>
                    )}
                  </>
                </TableBody>
              )}
            </>
          ) : (
            <EmptyTable
              colspan={columns.filter((column) => column.checked).length}
            />
          )}
          {count > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={limitList}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </StyledTable>
      </TableContainer>
    </div>
  )
}
DashboardOrdersTable.propTypes = {
  shipments: PropTypes.array,
  handleSelectedShipment: PropTypes.func,
  count: PropTypes.number,
  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,
  isLoading: PropTypes.bool,
  searchReducer: PropTypes.object,
}
