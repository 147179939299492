import { connect } from 'react-redux'

import Rdi from '../../../components/NavFilter/components/Filter/cell/RDI'
import {
  getIsLoadingReasonsOrder,
  getReasonsOrder,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  rdiFilter: getReasonsOrder(state),
  isLoadingRdi: getIsLoadingReasonsOrder(state),
})

export default connect(mapStateToProps, null)(Rdi)
