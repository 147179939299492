import React from 'react'

export function IconParkOutlinePicture(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      >
        <path d="M39 6H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3Z"></path>
        <path d="M18 23a5 5 0 1 0 0-10a5 5 0 0 0 0 10Zm9.79 3.22a2 2 0 0 1 3.243.053l8.775 12.583c.924 1.326-.025 3.144-1.64 3.144H16l11.79-15.78Z"></path>
      </g>
    </svg>
  )
}
export default IconParkOutlinePicture
