import { connect } from 'react-redux'
import CoursesImportation from '../../../components/NavBar/main/ActionsMenu/coursesImportation'

import {
  fetchMacroCanal,
  importerCourses,
  resetActionImportCourses,
} from '../../../redux/order/actions'
import {
  getMacroCanals,
  getIsLoadingMacroCanal,
  getErrorImportCourses,
  getIsActionImportCourses,
  getIsLoadingActionImportCourses,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  macroCanals: getMacroCanals(state),
  isLoadingMacroCanal: getIsLoadingMacroCanal(state),
  isLoadingActionImporterFile: getIsLoadingActionImportCourses(state),
  errorImportCourses: getErrorImportCourses(state),
  isActionImportCourses: getIsActionImportCourses(state),
})
const mapDisptachToProps = (dispatch) => ({
  fetchMacroCanal: () => dispatch(fetchMacroCanal()),
  importerCourses: (payload, macroCanalCode) =>
    dispatch(importerCourses(payload, macroCanalCode)),
  resetActionImportCourses: () => dispatch(resetActionImportCourses()),
})

export default connect(mapStateToProps, mapDisptachToProps)(CoursesImportation)
