import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import Login from '../containers/common/Login'
import { BrowserRouter as Router } from 'react-router-dom'
import SettingsRoutes from '../containers/settings/routes'
import MainRoutes from '../containers/main/routes'
import { getPathRedirectRole } from '../utils/roleManagement'

export default function Routes({ isLoggedIn, userRole }) {
  return (
    <Router>
      <div className="main-container" style={{ position: 'relative' }}>
        <div className={isLoggedIn ? 'main has-header' : 'main'}>
          <Switch>
            <PublicRoute
              isLoggedIn={isLoggedIn}
              exact
              path="/login"
              pathRedirect={getPathRedirectRole(userRole)}
              component={Login}
            />

            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/settings"
              component={SettingsRoutes}
            />

            <PrivateRoute path="/" isLoggedIn={isLoggedIn} component={MainRoutes} />
          </Switch>
        </div>
      </div>
    </Router>
  )
}
Routes.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
}
