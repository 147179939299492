import React, { useEffect } from 'react'
import Dialog from '../../../../components/ModalDialog'
import DialogContentText from '@mui/material/DialogContentText'
import PropTypes from 'prop-types'

export default function VerificationModal({
  open,
  handleClose,
  isLoading,
  textContent,
  modalTitle,
  verificationActon,
  success,
  isDriver,
  errorMessage,
  actionData,
}) {
  const dissociateClick = () => {
    verificationActon(actionData, isDriver)
  }
  useEffect(() => {
    if (success) {
      handleClose()
    }
  }, [success])

  return (
    <Dialog
      maxWidthDialog={'sm'}
      open={open}
      title={modalTitle}
      style={errorMessage ? { display: 'none' } : {}}
      content={
        <DialogContentText
          sx={{ pr: 2, pb: 2, justifyContent: 'normal !important', width: '450px' }}
        >
          {textContent}
        </DialogContentText>
      }
      handleClose={handleClose}
      handleClickAction={dissociateClick}
      isLoadingButtonAction={isLoading}
    />
  )
}
VerificationModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDissociateClick: PropTypes.func,
  isLoading: PropTypes.bool,
  errorActionCourse: PropTypes.string,
  textContent: PropTypes.string,
  modalTitle: PropTypes.string,
  isDriver: PropTypes.bool,
  success: PropTypes.bool,
  verificationActon: PropTypes.func,
  errorMessage: PropTypes.string,
  actionData: PropTypes.any,
}
