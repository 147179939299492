import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import IconWarehouse from '../../../../../../assets/icons/navFilter/WarehouseIcon'
import { getValue } from '../../../../../../utils'

export default function WareHouse({
  warehouses,
  filterStateChanged,
  wareHouseFiltersValues,
  isLoading,
  disabled,
}) {
  const [checked, setChecked] = useState([])
  const isDiscussion = window.location.pathname.includes('discussion')

  useEffect(() => {
    let list = warehouses.map(({ id, label }) => {
      return {
        code: isDiscussion ? label : id + '',
        name: label,
      }
    })
    setChecked(list)
  }, [warehouses])

  const onWareHouseChanged = (selectedWareHouse) => {
    const warehouse = selectedWareHouse
      ? selectedWareHouse
      : getValue('currentUser', {})?.warehouses.map((e) => {
          return e.id + ''
        })
    filterStateChanged('warehouse', warehouse)
  }
  return (
    <SelectListWithSearch
      disabled={disabled || isLoading || warehouses.length <= 0}
      onDataChanged={onWareHouseChanged}
      defaultLabel={'Entrepôt'}
      dataList={checked}
      filtersValues={
        isDiscussion
          ? wareHouseFiltersValues?.map((e) => decodeURI(e))
          : wareHouseFiltersValues
      }
      Icon={IconWarehouse}
      id={'WareHouseFilter'}
    />
  )
}
WareHouse.propTypes = {
  warehouses: PropTypes.array,
  filterStateChanged: PropTypes.func,
  wareHouseFiltersValues: PropTypes.array,
  collaborateurWareHouse: PropTypes.array,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
}
