import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import DisplaySelection from '../../containers/main/NavFilter/DisplayGridSelection'
import SearchInCorner from '../../containers/main/SearchInCorner/SearchInCorner'
import SearchDownloadDocument from '../../containers/main/Entrepot/documents/searchDownloadDocument'
import SearchInOngletCorner from '../../containers/main/SearchInCorner/SearchInOngletCorner'

import ButtonFilter from '../../containers/main/NavFilter/NewFilter'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import OngletsFilter from '../../containers/main/NavFilter/OngletFilter'

function NavFilter({
  dateFilterToday,
  statusFilter,
  driversFilter,
  salesChannelfilter,
  prestationFilter,
  agentsCommands,
  displaySelection,
  departmentFilter,
  wareHouseFilter,
  programerDateFilter,
  deliveryDateFilter,
  toursFilter,
  tourListFilter,
  dateFilterTomorrow,
  macroCanalFilter,
  filterReducer,
  setFilter,
  courseTypeFilter,
  savFilter,
  dateFilterCreation,
  BusinessOwnerFilter,
  resetFilter,
  deliveryDateFilterRange,
  DateFilterReception,
  ResponseCheck,
  subjectFilter,
  ProcessingDepartment,
  pathnameFilter,
  directionFilter,
  natureDiscussion,
  displayGrid,
  defaultFilter,
  setDefaultFilter,
  saveDefaultFilter,
  resetDefaultFilter,
  pageFilter,
  isLoadingRefresh,
  courseLieeFilter,
  rdiFilter,
}) {
  const [isEntrepotDocument, setIsEntrepotDocument] = useState(
    window.location.pathname.includes('documents')
  )
  const [isFreights, setIsFreights] = useState(
    window.location.pathname.includes('tourneeReception')
  )
  const [isTourne, setIstournee] = useState(
    window.location.pathname.includes('tourPolaris')
  )

  const [isDiscussion, setIsDiscussion] = useState(
    window.location.pathname.includes('discussion')
  )
  const history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      setIsEntrepotDocument(location.pathname.includes('documents'))
      setIsFreights(location.pathname.includes('tourneeReception'))
      setIstournee(location.pathname.includes('tourPolaris'))
      setIsDiscussion(location.pathname.includes('discussion'))
    })
  }, [history])

  const matches = useMediaQuery('(max-width:280px)')
  const matchesTabMode = useMediaQuery('(max-width:579px)')

  const [ongletFilterOpened, setOngletFilter] = useState(
    Number(localStorage.getItem('OngletOpened'))
  )
  const [initRefresh, setInitRefresh] = useState(true)
  const [openRechercher, setOpenRechercher] = useState(false)

  return (
    <div
      style={{
        boxShadow:
          '0px 5px 2px -5px rgb(0 0 0 / 20%), 0px 0px 7px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        backgroundColor: 'rgb(248 248 248)',
        width: '100%',
        top: '55px',
        zIndex: '3',
        position: 'sticky',
      }}
    >
      <div
        style={{
          top: '55px',
          width: '100%',
          zIndex: '3',
          position: 'sticky',
          display: displaySelection && !displayGrid && 'flex',
          justifyContent: 'space-between',
        }}
      >
        {displaySelection && !displayGrid && (
          <OngletsFilter
            defaultFilter={defaultFilter}
            setFilter={setFilter}
            setDefaultFilter={setDefaultFilter}
            resetDefaultFilter={resetDefaultFilter}
            pageFilter={pageFilter}
            setOngletFilter={setOngletFilter}
            ongletFilterOpened={ongletFilterOpened}
            initRefresh={initRefresh}
            openRechercher={openRechercher}
          />
        )}
        <div
          style={
            !displaySelection || displayGrid
              ? {
                  height: '70.75px',
                  justifyContent: 'space-between',
                  display: 'flex',
                  paddingRight: '10px',
                  paddingLeft: '19px',
                }
              : {
                  height: '70.75px',
                  placeContent: 'flex-end',
                  display: 'flex',
                  paddingRight: '10px',
                  paddingLeft: '19px',
                }
          }
        >
          {isEntrepotDocument ? (
            <SearchDownloadDocument />
          ) : (
            !isFreights &&
            !isTourne &&
            !isDiscussion && (
              <div
                style={{
                  display: 'flex',
                  width:
                    displaySelection && displayGrid
                      ? '73%'
                      : matchesTabMode && '100%',
                }}
              >
                {!displaySelection || displayGrid || ongletFilterOpened == 0 ? (
                  <SearchInCorner />
                ) : (
                  <SearchInOngletCorner
                    setInitRefresh={setInitRefresh}
                    checked={openRechercher}
                    setChecked={setOpenRechercher}
                    ongletFilterOpened={ongletFilterOpened}
                  />
                )}

                {displaySelection && displayGrid && !matches && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      maxWidth: '160px',
                      margin: '15px 4px',
                      width: '30%',
                    }}
                  >
                    <DisplaySelection
                      saveDefaultFilter={saveDefaultFilter}
                      resetDefaultFilter={resetDefaultFilter}
                    />
                  </div>
                )}
              </div>
            )
          )}

          <div
            style={{
              padding: '12px 7px',
              margin: '3px',

              width:
                (!displaySelection || displayGrid) &&
                !isFreights &&
                !isTourne &&
                !isDiscussion
                  ? '25%'
                  : '100%',
              textAlign: (!displaySelection || displayGrid) && 'end',
            }}
          >
            <ButtonFilter
              dateFilterToday={dateFilterToday}
              statusFilter={statusFilter}
              driversFilter={driversFilter}
              salesChannelfilter={salesChannelfilter}
              prestationFilter={prestationFilter}
              agentsCommands={agentsCommands}
              departmentFilter={departmentFilter}
              wareHouseFilter={wareHouseFilter}
              programerDateFilter={programerDateFilter}
              deliveryDateFilter={deliveryDateFilter}
              toursFilter={toursFilter}
              tourListFilter={tourListFilter}
              dateFilterTomorrow={dateFilterTomorrow}
              macroCanalFilter={macroCanalFilter}
              filterReducer={filterReducer}
              setFilter={setFilter}
              styleButton={{
                color: 'white',
                backgroundColor: '#1976d2',
              }}
              courseTypeFilter={courseTypeFilter}
              savFilter={savFilter}
              dateFilterCreation={dateFilterCreation}
              BusinessOwnerFilter={BusinessOwnerFilter}
              resetFilter={resetFilter}
              deliveryDateFilterRange={deliveryDateFilterRange}
              DateFilterReception={DateFilterReception}
              ResponseCheck={ResponseCheck}
              subjectFilter={subjectFilter}
              ProcessingDepartment={ProcessingDepartment}
              nature={natureDiscussion}
              pathnameFilter={pathnameFilter}
              directionFilter={directionFilter}
              displaySelection={displaySelection}
              defaultFilter={defaultFilter}
              pageFilter={pageFilter}
              courseLieeFilter={courseLieeFilter}
              saveDefaultFilter={saveDefaultFilter}
              resetDefaultFilter={resetDefaultFilter}
              rdiFilter={rdiFilter}
            />
          </div>
        </div>
      </div>
      {isLoadingRefresh && <div className="gradient-border"></div>}
    </div>
  )
}
NavFilter.propTypes = {
  statusFilter: PropTypes.bool,
  driversFilter: PropTypes.bool,
  salesChannelfilter: PropTypes.bool,
  agentsCommands: PropTypes.bool,
  displaySelection: PropTypes.bool,
  prestationFilter: PropTypes.bool,
  departmentFilter: PropTypes.bool,
  programerDateFilter: PropTypes.bool,
  deliveryDateFilter: PropTypes.bool,
  toursFilter: PropTypes.bool,
  tourListFilter: PropTypes.bool,
  wareHouseFilter: PropTypes.bool,
  macroCanalFilter: PropTypes.bool,
  dateFilterToday: PropTypes.bool,
  dateFilterTomorrow: PropTypes.bool,
  filterReducer: PropTypes.object,
  setFilter: PropTypes.func,
  courseTypeFilter: PropTypes.bool,
  savFilter: PropTypes.bool,
  dateFilterCreation: PropTypes.bool,
  BusinessOwnerFilter: PropTypes.bool,
  resetFilter: PropTypes.func,
  deliveryDateFilterRange: PropTypes.bool,
  DateFilterReception: PropTypes.bool,
  ResponseCheck: PropTypes.bool,
  subjectFilter: PropTypes.bool,
  ProcessingDepartment: PropTypes.bool,
  natureDiscussion: PropTypes.bool,
  pathnameFilter: PropTypes.string,
  directionFilter: PropTypes.bool,
  displayGrid: PropTypes.bool,
  defaultFilter: PropTypes.object,
  setDefaultFilter: PropTypes.func,
  saveDefaultFilter: PropTypes.func,
  resetDefaultFilter: PropTypes.func,
  pageFilter: PropTypes.string,
  isLoadingRefresh: PropTypes.bool,
  courseLieeFilter: PropTypes.bool,
  rdiFilter: PropTypes.bool,
}
export default NavFilter
