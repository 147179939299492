import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import PropTypes from 'prop-types'
import PrimaryOutlineNoBorderButton from '../../Buttons/PrimaryOutlineNoBorderButton'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
/** A pop up component to show error messages */

export default function HttpError({ status, statusText, open, setOpen, link }) {
  const onRefresh = () => {
    setOpen(false)
    window.location.reload()
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div className="error-container">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{status}</DialogTitle>
        <DialogContent>
          <div style={{ margin: 'auto', fontSize: 18, width: 350 }}>
            {statusText}
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          </div>
        </DialogContent>
        <DialogActions>
          <PrimaryOutlineNoBorderButton onClick={onRefresh} label="Actualiser" />
          <PrimaryOutlineNoBorderButton onClick={handleClose} label="Fermer" />
        </DialogActions>
      </Dialog>
    </div>
  )
}
HttpError.propTypes = {
  /** The error status number */
  status: PropTypes.number,
  /** The Error status Text */
  statusText: PropTypes.string,
  /** To indicate if it should appear */
  open: PropTypes.bool,
  /** set The status of the modal */
  setOpen: PropTypes.func,
  /**Adding a link to the modal */
  link: PropTypes.string,
}
