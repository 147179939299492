import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../ModalDialog'

import NavetteIcon from '../../assets/icons/detailsLivraison/navette'

function AddToNAvetteAction({
  open,
  handleClose,
  AddToNavette,
  codeCourse,
  isLoadingActionOrder,
  errorActionCourse,
}) {
  const onChangeUpdateCourseType = () => {
    AddToNavette({ codeCourse: codeCourse, type: 'StoreDelivery' })
  }

  useEffect(() => {
    if (errorActionCourse) handleClose()
  }, [errorActionCourse])

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Ajouter à une navette'}
      iconTitle={
        <NavetteIcon
          style={{
            width: '20px',
            height: '20px',
            marginRight: '9px',
          }}
        />
      }
      content={
        <div style={{ padding: '30px' }}>La course sera ajouté à une navette</div>
      }
      handleClose={handleClose}
      handleClickAction={onChangeUpdateCourseType}
      isLoadingButtonAction={isLoadingActionOrder}
    />
  )
}
AddToNAvetteAction.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  codeCourse: PropTypes.string,
  AddToNavette: PropTypes.func,
  isLoadingActionOrder: PropTypes.bool,
  errorActionCourse: PropTypes.string,
}

export default AddToNAvetteAction
