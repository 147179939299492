import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'

export default function DialogAction({ onAttribuer, isLoading }) {
  return (
    <div
      style={{
        margin: '1px 0 0',
        padding: '12px',
        paddingRight: '12px',
        boxShadow: '3px 0 3px 0 rgb(0 0 0 / 16%)',
      }}
    >
      <div style={{ textAlign: 'right' }}>
        <Button
          onClick={onAttribuer}
          color="secondary"
          variant={'contained'}
          disabled={isLoading}
          sx={{
            '&.MuiButton-root': {
              color: '#fff !important',
              textTransform: 'capitalize !important',
              fontSize: '13px !important',
              fontWeight: 400,
              backgroundColor: '#10A549!important',
              '&:hover': {
                backgroundColor: '#158f44!important',
              },
            },

            '&.Mui-disabled': {
              color: 'white !important',
              backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
            },
          }}
          id={'button-optimiser-dialog-equipages'}
        >
          Optimiser
        </Button>
      </div>
    </div>
  )
}

DialogAction.propTypes = {
  onAttribuer: PropTypes.func,
  isLoading: PropTypes.bool,
}
