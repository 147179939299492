import React from 'react'

export function FluentDocumentTableTruck24Regular(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18 20.5h-5.013a2.37 2.37 0 0 1-.756 1.5H18a2 2 0 0 0 2-2V9.828a2 2 0 0 0-.586-1.414l-5.829-5.828a.491.491 0 0 0-.049-.04a.63.63 0 0 1-.036-.03a2.072 2.072 0 0 0-.219-.18a.652.652 0 0 0-.08-.044l-.048-.024l-.05-.029c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 0 0-.624-.138a.56.56 0 0 1-.059-.007a.605.605 0 0 0-.082-.007H6a2 2 0 0 0-2 2v7h1.5V4a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5v10a.5.5 0 0 1-.5.5m-.622-12H14a.5.5 0 0 1-.5-.5V4.621zM13 17.5V19h2.25A1.75 1.75 0 0 0 17 17.25v-4a1.75 1.75 0 0 0-1.75-1.75H9.18c.466.34.814.83.973 1.399a2.376 2.376 0 0 1 1.347.884V13h3.75a.25.25 0 0 1 .25.25v1.25h-3.599l.75 1.5H15.5v1.25a.25.25 0 0 1-.25.25zM2.604 12C1.718 12 1 12.718 1 13.604v6.417c0 .65.387 1.21.944 1.462a1.834 1.834 0 0 0 3.582.142h1.032a1.834 1.834 0 0 0 3.55 0h.517c.76 0 1.375-.616 1.375-1.375v-2.992c0-.213-.05-.424-.145-.615l-1.025-2.05a1.375 1.375 0 0 0-1.23-.76h-.35v-.229c0-.886-.718-1.604-1.604-1.604zm6.646 4.583V14.75h.35c.174 0 .332.098.41.253l.79 1.58zm-6.417 4.584a.917.917 0 1 1 1.834 0a.917.917 0 0 1-1.834 0m5.5.916a.917.917 0 1 1 0-1.833a.917.917 0 0 1 0 1.833"
      ></path>
    </svg>
  )
}
export default FluentDocumentTableTruck24Regular
