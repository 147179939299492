import { connect } from 'react-redux'
import RenommerFilter from '../../../components/NavFilter/components/OngletFilter/renommerFilter'

import { onRennomerFilter, resetActionFilter } from '../../../redux/filter/actions'
import {
  getIsLoadingActionFilter,
  getErrorActionFilter,
  getIsActionFilter,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionFilter: getIsLoadingActionFilter(state),
  errorActionFilter: getErrorActionFilter(state),
  isActionFilter: getIsActionFilter(state),
})

const mapDisptachToProps = (dispatch) => ({
  onRennomerFilter: (payload) => dispatch(onRennomerFilter(payload)),
  resetActionFilter: () => dispatch(resetActionFilter()),
})

export default connect(mapStateToProps, mapDisptachToProps)(RenommerFilter)
