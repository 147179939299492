import React from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'

import TextComponent from './TextComponent'
import StarIcon from '@mui/icons-material/Star'
import './index.scss'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import './index.scss'
import TextAdresse from './TextAdresseTooltip'
import BoldTextComponent from './BoldTextComponent'
import { replaceDotWithComma } from '../../../../../../utils/floatFormatting'
import { ACCURACY_LEVEL, getfullName } from '../../../../../../utils/values'
export default function CardContentComponent({
  contact,
  montantPrestataireHT,
  name,
  adresseDepart,
  adresseArrivee,
  weight,
  volume,
  manutention,
  manutentionDouble,
  driver,
  isVip,
  earliestDeliveryDate,
  latestDeliveryDate,
  deliveryDate,
  montantHT,
  factures,
  pickUpDate,
  courseType,
  index,
}) {
  const getColor = (adresse) => {
    return ACCURACY_LEVEL[adresse.accuracyLevel]
  }
  return (
    <CardContent
      sx={{
        height: 290,
        minHeight: 290,
        padding: '16px 16px 0 16px !important',
        lineHight: 25,
        overflowY: 'scroll',
      }}
    >
      {contact && (
        <div className="contact" id={`Contact-${index}`}>
          <BoldTextComponent value={contact} />
          {isVip && (
            <Tooltip arrow title={<span className="tootltip-content">VIP</span>}>
              <StarIcon style={{ color: 'gold' }} />
            </Tooltip>
          )}
        </div>
      )}
      {courseType === 'ClientCollect' ||
      courseType === 'ClientPickup' ||
      courseType === 'StorePickUp' ? (
        <>
          {pickUpDate && (
            <TextComponent id={`PickUpDate-${index}`} value={pickUpDate} />
          )}
        </>
      ) : (
        <>
          {deliveryDate && (
            <TextComponent id={`deliveryDate-${index}`} value={deliveryDate} />
          )}
        </>
      )}
      {montantHT != null && montantHT != undefined && (
        <TextComponent
          id={`Prix-Client-${index}`}
          value={`Prix Client: ${replaceDotWithComma(
            (montantHT + montantHT * 0.2).toFixed(2)
          )} €`}
        />
      )}
      {montantPrestataireHT != null && montantPrestataireHT != undefined && (
        <TextComponent
          id={`Prix-Prestataire-${index}`}
          value={`Prix Prestataire: ${replaceDotWithComma(
            (montantPrestataireHT + montantPrestataireHT * 0.2).toFixed(2)
          )} €`}
        />
      )}
      {name && <TextComponent value={name} />}
      {adresseDepart !== undefined && (
        <TextAdresse
          id={`Depart-${index}`}
          label="Départ: "
          value={adresseDepart}
          getColor={getColor}
        />
      )}
      {adresseArrivee !== undefined && (
        <TextAdresse
          id={`Arrivee-${index}`}
          label="Arrivée: "
          value={adresseArrivee}
          getColor={getColor}
        />
      )}
      {weight !== null && weight !== undefined && (
        <TextComponent
          id={`Poids-${index}`}
          value={`Poids: ${replaceDotWithComma(weight)} kg`}
        />
      )}
      {volume !== null && volume !== undefined && (
        <TextComponent
          id={`Volume-${index}`}
          value={`Volume: ${replaceDotWithComma(volume)} m³`}
        />
      )}
      {manutention && (
        <TextComponent
          id={`Option-Manutention-${index}`}
          value={'Option Manutention'}
        />
      )}
      {manutentionDouble && (
        <TextComponent
          id={`Option-Manutention-Double-${index}`}
          value={'Option Manutention Double'}
        />
      )}
      {driver && (
        <TextComponent
          id={`Chauffeur-${index}`}
          value={`Chauffeur: ${getfullName(driver, '')}`}
        />
      )}
      {earliestDeliveryDate && (
        <TextComponent
          id={`Date-au-plus-tot-${index}`}
          value={`Date au plus tot : ${earliestDeliveryDate}`}
        />
      )}
      {latestDeliveryDate && (
        <TextComponent
          id={`Date-au-plus-tard-${index}`}
          value={`Date au plus tard : ${latestDeliveryDate}`}
        />
      )}
      {factures && (
        <TextComponent
          id={`Factures-${index}`}
          value={`Référence client : ${factures}`}
        />
      )}
    </CardContent>
  )
}
CardContentComponent.propTypes = {
  classes: PropTypes.object,
  contact: PropTypes.string,
  deliveryDate: PropTypes.string,
  montantHT: PropTypes.number,
  montantPrestataireHT: PropTypes.number,
  name: PropTypes.string,
  adresseDepart: PropTypes.object,
  adresseArrivee: PropTypes.object,
  weight: PropTypes.number,
  volume: PropTypes.number,
  manutention: PropTypes.bool,
  manutentionDouble: PropTypes.bool,
  driver: PropTypes.object,
  isVip: PropTypes.bool,
  assemblies: PropTypes.array,
  salesChannel: PropTypes.string,
  dropOffDate: PropTypes.any,
  earliestDeliveryDate: PropTypes.any,
  latestDeliveryDate: PropTypes.any,
  factures: PropTypes.any,
  pickUpDate: PropTypes.string,
  courseType: PropTypes.string,
  index: PropTypes.number,
}
