import _ from 'lodash'
import moment from 'moment'
import { DEFAULT_SIZE, ORDER_TYPE } from '../constants/appContants'
import { axiosHTTP } from '../utils'

const apiUrl = process.env.REACT_APP_API_URL
const pedUrl = process.env.REACT_APP_PRICE_ENGINE_MS_URL
export function calculatePrice(data) {
  return axiosHTTP.post(`${apiUrl}calculMontant`, data)
}
export function evalRule(payload) {
  console.log('testing payload', payload)
  return axiosHTTP.post(`${pedUrl}rule/eval`, payload)
}

export async function estimateDeliveryPrice(
  selectedSalesChannel,
  direction,
  date,
  time,
  price,
  nbItems,
  weight,
  manutention,
  manutentionDouble
) {
  if (_.has(direction, 'selectedDirection.distance')) {
    if (
      (!_.get(selectedSalesChannel, 'configs.priceBasedOnDate') ||
        (_.get(selectedSalesChannel, 'configs.priceBasedOnDate') &&
          !_.isNil(date) &&
          !_.isNil(_.get(time, 'start')))) &&
      (!_.get(selectedSalesChannel, 'configs.priceBasedOnPurchaseAmount') ||
        (_.get(selectedSalesChannel, 'configs.priceBasedOnPurchaseAmount') &&
          !_.isNil(price) &&
          _.isNumber(parseFloat(price)) &&
          parseFloat(price) >= 0)) &&
      (!_.get(selectedSalesChannel, 'configs.priceBasedOnWeight') ||
        (_.get(selectedSalesChannel, 'configs.priceBasedOnWeight') &&
          !_.isNil(weight) &&
          _.isNumber(parseFloat(weight)) &&
          parseFloat(weight) >= 0)) &&
      (!_.get(selectedSalesChannel, 'configs.priceBasedOnNBitems') ||
        (_.get(selectedSalesChannel, 'configs.priceBasedOnNBitems') &&
          !_.isNil(nbItems) &&
          _.isNumber(parseFloat(nbItems)) &&
          parseFloat(nbItems) >= 0))
    ) {
      try {
        const dataPost = {
          orderService: ORDER_TYPE.DELIVERY,
          codeCanal: selectedSalesChannel.code,
          km: direction.selectedDirection.distance,
          vehiculeType: DEFAULT_SIZE,
          manutention: manutention,
          manutentionDouble: manutentionDouble,
          ...(_.get(selectedSalesChannel, 'configs.priceBasedOnDate')
            ? {
              serviceDate: `${moment(new Date(date)).format('YYYY-MM-DD')} ${
                time.start
              }:00`,
            }
            : {}),
          ...(_.get(selectedSalesChannel, 'configs.priceBasedOnPurchaseAmount')
            ? { prixAchat: parseFloat(price.replace(',', '.')) }
            : {}),
          ...(_.get(selectedSalesChannel, 'configs.priceBasedOnNBitems')
            ? { nbItems: parseInt(nbItems, 10) }
            : {}),
          ...(_.get(selectedSalesChannel, 'configs.priceBasedOnWeight')
            ? { weight: parseFloat(weight.replace(',', '.')) }
            : {}),
        }
        return calculatePrice(dataPost)
      } catch (error) {
        return Promise.reject(new Error(error))
      }
    }
  }
}
//calculate the price by rule for rule based saleschannels
export async function estimatePriceByRule(
  selectedSalesChannel,
  direction,
  date,
  time,
  price,
  nbItems,
  weight,
  etage,
  manutention,
  manutentionDouble,
  selectedChannelRule
) {
  if (_.has(direction, 'selectedDirection.distance')) {
    if (
      (!_.get(selectedSalesChannel, 'configs.priceBasedOnDate') ||
        (_.get(selectedSalesChannel, 'configs.priceBasedOnDate') &&
          !_.isNil(date) &&
          !_.isNil(_.get(time, 'start')))) &&
      (!_.get(selectedSalesChannel, 'configs.priceBasedOnPurchaseAmount') ||
        (_.get(selectedSalesChannel, 'configs.priceBasedOnPurchaseAmount') &&
          !_.isNil(price) &&
          _.isNumber(parseFloat(price)) &&
          parseFloat(price) >= 0)) &&
      (!_.get(selectedSalesChannel, 'configs.priceBasedOnWeight') ||
        (_.get(selectedSalesChannel, 'configs.priceBasedOnWeight') &&
          !_.isNil(weight) &&
          _.isNumber(parseFloat(weight)) &&
          parseFloat(weight) >= 0)) &&
      (!_.get(selectedSalesChannel, 'configs.priceBasedOnNBitems') ||
        (_.get(selectedSalesChannel, 'configs.priceBasedOnNBitems') &&
          !_.isNil(nbItems) &&
          _.isNumber(parseFloat(nbItems)) &&
          parseFloat(nbItems) >= 0))
    ) {
      try {
        const dataPost = {
          km: direction.selectedDirection.distance,
          manutention: manutention,
          manutentionDouble: manutentionDouble,
          etage: etage ? etage : 0,
          ...(_.get(selectedSalesChannel, 'configs.priceBasedOnDate')
            ? {
              serviceDate: `${moment(new Date(date)).format('YYYY-MM-DD')} ${
                time.start
              }:00`,
            }
            : {}),
          ...(_.get(selectedSalesChannel, 'configs.priceBasedOnPurchaseAmount')
            ? { prixAchat: parseFloat(price.replace(',', '.')) }
            : {}),
          ...(_.get(selectedSalesChannel, 'configs.priceBasedOnNBitems')
            ? { nbItems: parseInt(nbItems, 10) }
            : {}),
          ...(_.get(selectedSalesChannel, 'configs.priceBasedOnWeight')
            ? { weight: parseFloat(weight.replace(',', '.')) }
            : {}),
        }
        const options = {}
        selectedSalesChannel.optionsDetails.forEach((opt) => {
          options[opt.label] = 0
        })
        console.log('testing sales channel', selectedSalesChannel)
        return evalRule({
          data: { ...dataPost, ...options },
          spec: selectedChannelRule,
        })
      } catch (error) {
        return Promise.reject(new Error(error))
      }
    }
  }
}

export async function estimateAssemblyPrice(
  selectedSalesChannel,
  purchaseAmount,
  date,
  time
) {
  if (
    !_.isNil(selectedSalesChannel) &&
    !_.isNil(purchaseAmount) &&
    !_.isNaN(parseFloat(purchaseAmount))
  ) {
    const dataPost = {
      orderService: ORDER_TYPE.ASSEMBLY,
      codeCanal: selectedSalesChannel.code,
      prixAchat: parseFloat(purchaseAmount.replace(',', '.')),
      ...(_.get(selectedSalesChannel, 'configs.priceBasedOnDate') &&
      !_.isNil(date) &&
      !_.isNil(_.get(time, 'fullHr'))
        ? {
          serviceDate: `${moment(new Date(date)).format('YYYY-MM-DD')} ${
            time.fullHr
          }`,
        }
        : {}),
    }
    return calculatePrice(dataPost)
  } else {
    return Promise.reject(new Error('paramater-error'))
  }
}
