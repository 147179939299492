import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import { TextField, Button, Box, CircularProgress } from '@mui/material'
import CreateIcon from '../../../../assets/icons/settingsCorner/iconsCreate'
import EditIcon from '../../../../assets/icons/settingsCorner/iconEdit'

import { FormationDescription, FormationFamily } from '../../../../utils/values'
import {
  StyledDivContent,
  StyledDivTopInputs,
  StyledInputModal,
  StyledInputTitle,
  sxInput,
} from '../../styled'
import { MenuItem } from '@mui/material'
import { useFormik } from 'formik'
import AutoComplete from '../../../../components/AutoComplete'
import KeyModal from '../../../../containers/settings/Modals/keyModal'
import DeleteIcon from '@mui/icons-material/Delete'
import { validateUploadFile } from '../../../../utils/validator/UploadFileDiscussion'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import fileDecoded from '../../../../utils/fileDecoded'
import UploadFileIcon from '../../../../assets/icons/settingsCorner/uploadFileIcon'

const validate = (values) => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Nom est obligatoire'
  }

  return errors
}

function Addkey({
  open,
  isEdit,
  handleClose,
  modalTitle,
  createFormation,
  updateFormation,
  isLoadingFormationAction,
  success,
  errorMessage,
  formation,
  keys,
  fetchKeys,
  searchKeys,
  isLoadingKeysList,
  fetchMoreKeys,
  keysCount,
  setErrorUploadFile,
  resetKey,
  resetConfigFormation,
  formationId,
  isLoadingFormation,
  errorMessageConfig,
  successConfig,
  keyCreatedObject,
}) {
  const UserId = String(JSON.parse(localStorage.getItem('currentUser'))?.id)
  const formik = useFormik({
    initialValues: {
      userId: UserId,
      name: '',
      contractor: '',
      nbrHours: null,
      family: 'ElectricalAccreditation',
      description: 'Formation',
      competences: [],
      comment: '',
      files: [],
    },
    validate,
    onSubmit: (values) => {
      const formationDto = {
        name: values.name,
        contractor: values.contractor,
        nbrHours: values?.nbrHours ? Number(values.nbrHours) : undefined,
        description: values.description,
        family: values.family,
        comment: values.comment,
        userId: values.userId,
        competences: values.competences,
        id: isEdit ? formationId : undefined,
      }
      let formData = new FormData()
      formData.append('formationDto', JSON.stringify(formationDto))
      Array.from(values.files).forEach((file) => {
        formData.append('files', file)
      })

      if (isEdit) {
        updateFormation(formData)
      } else {
        createFormation(formData)
      }
    },
  })

  const [select, setSelect] = useState(false)
  const [init, setInit] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [offset, setOffset] = useState(25)
  const [openKey, setOpenKey] = useState(false)

  useEffect(() => {
    return () => {
      resetKey()
    }
  }, [])

  useEffect(() => {
    if (isEdit && !isLoadingFormation) {
      const filesToDecode = fileDecoded(formation.files)

      formik.setValues({
        ...formik.values,
        name: formation.name,
        contractor: formation.contractor,
        nbrHours: formation.nbrHours,
        description: formation.description,
        family: formation.family,
        competences: formation.competences,
        id: formation.id,
        comment: formation.comment,
        files: filesToDecode,
      })
    }
  }, [isEdit, isLoadingFormation])
  useEffect(() => {
    if (success) {
      handleClose()
      resetConfigFormation()
    }
  }, [success])

  useEffect(() => {
    if (successConfig && keyCreatedObject) {
      formik.setFieldValue('competences', [
        ...formik.values.competences,
        keyCreatedObject,
      ])
      setSearchKey('')
    }
  }, [successConfig])

  const handleCloseKeyModal = () => {
    setOpenKey(false)
  }
  const verifEdit = () => {
    return (
      isEdit &&
      !isLoadingFormation &&
      formik.values.name == formation.name &&
      formik.values.contractor == formation.contractor &&
      formik.values.nbrHours == formation.nbrHours &&
      formik.values.family == formation.family &&
      formik.values.description == formation.description &&
      formik.values.competences == formation.competences &&
      formik.values.comment == formation.comment &&
      formik.values.files.length == formation.files.length &&
      formik.values.files
        .map((el) => el.name)
        .every((el) => formation.files.map((file) => file.fileName).includes(el))
    )
  }
  useEffect(() => {
    fetchKeys()
    setInit(true)
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchKey.length > 0 && !select) {
        searchKeys(searchKey)
        setOffset(25)
      } else if (searchKey == '') {
        if (init) {
          searchKeys('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchKey])

  const onSelectKey = (value) => {
    if (value) {
      formik.setFieldValue('competences', value)
      setSelect(true)
    }
  }

  const onChangeSearchKey = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchKey(val)
    } else {
      setSearchKey('')
      setSelect(false)
    }
  }

  const loadMoreKeys = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      keysCount >= offset
    ) {
      setOffset(offset + 25)
      fetchMoreKeys(offset, searchKey)
    }
  }

  const onUpload = (event) => {
    const error = validateUploadFile(Array.from(event.target.files))
    if (event.target.files.length > 0) {
      const findFile = formik.values.files
        .map((file) => file.name)
        .indexOf(event.target.files[0].name)

      if (error.isError) {
        setErrorUploadFile(error.message)
      } else if (findFile == -1) {
        formik.setFieldValue('files', [
          ...formik.values.files,
          ...event.target.files,
        ])
      }
    }
    event.target.value = null
  }

  const deleteFile = (nameFile) => {
    const indexFile = formik.values.files.map((file) => file.url).indexOf(nameFile)

    if (indexFile !== -1) {
      formik.values.files.splice(indexFile, 1)
      formik.setFieldValue('files', formik.values.files)
    }
  }

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Dialog
          maxWidthDialog={'md'}
          open={open}
          title={modalTitle}
          iconTitle={isEdit ? <EditIcon /> : <CreateIcon />}
          style={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '646px',
              },
              display: (errorMessage || errorMessageConfig) && 'none',
            },
          }}
          content={
            <StyledDivContent>
              {isEdit && isLoadingFormation ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress sx={{ color: '#1976D2' }} />
                </Box>
              ) : (
                <>
                  <Box style={{ paddingBottom: '20px' }}>
                    <StyledInputTitle> Nom de formation</StyledInputTitle>
                    <TextField
                      placeholder="Nom de formation"
                      id="Formation-Nom"
                      name="name"
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputProps={{
                        sx: sxInput,
                      }}
                      value={formik.values.name}
                      error={formik.errors.name && formik.touched.name}
                      helperText={
                        formik.errors.name && formik.touched.name
                          ? formik.errors.name
                          : null
                      }
                    />
                  </Box>

                  <StyledDivTopInputs>
                    <StyledInputModal>
                      <StyledInputTitle>{"Donneur d'ordre"}</StyledInputTitle>
                      <TextField
                        placeholder="Donneur d'ordre"
                        id="Formation-Contractor"
                        name="contractor"
                        variant="outlined"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          sx: sxInput,
                        }}
                        value={formik.values.contractor}
                        error={formik.errors.contractor && formik.touched.contractor}
                        helperText={
                          formik.errors.contractor && formik.touched.contractor
                            ? formik.errors.contractor
                            : null
                        }
                      />
                    </StyledInputModal>
                    <StyledInputModal>
                      <StyledInputTitle>{"Nombre d'heure"}</StyledInputTitle>
                      <TextField
                        id="Formation-nbrHours"
                        placeholder="Nombre d'heure"
                        name="nbrHours"
                        variant="outlined"
                        onKeyPress={(event) => {
                          if (
                            !/^[0-9]/.test(event.key) ||
                            (event.key == 0 && event.target.value <= 0)
                          ) {
                            event.preventDefault()
                          }
                        }}
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: sxInput,
                        }}
                        value={formik.values.nbrHours}
                      />
                    </StyledInputModal>
                  </StyledDivTopInputs>
                  <StyledDivTopInputs>
                    <StyledInputModal>
                      <StyledInputTitle>Description</StyledInputTitle>
                      <TextField
                        onBlur={formik.handleBlur}
                        id="Formation-Description"
                        name="description"
                        variant="outlined"
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: sxInput,
                        }}
                        value={formik.values.description}
                        select
                        error={
                          formik.errors.description && formik.touched.description
                        }
                        helperText={
                          formik.errors.description && formik.touched.description
                            ? formik.errors.description
                            : null
                        }
                      >
                        {FormationDescription.map((key, index) => (
                          <MenuItem value={key.code} key={index}>
                            {key.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </StyledInputModal>
                    <StyledInputModal>
                      <StyledInputTitle>Famille</StyledInputTitle>
                      <TextField
                        id="Formation-Family"
                        name="family"
                        variant="outlined"
                        onChange={formik.handleChange}
                        InputProps={{
                          sx: sxInput,
                        }}
                        value={formik.values.family}
                        select
                        error={formik.errors.family && formik.touched.family}
                        helperText={
                          formik.errors.family && formik.touched.family
                            ? formik.errors.family
                            : null
                        }
                      >
                        {FormationFamily.map((key, index) => (
                          <MenuItem value={key.code} key={index}>
                            {key.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </StyledInputModal>
                  </StyledDivTopInputs>

                  <Box sx={{ marginBottom: '20px' }}>
                    <StyledInputTitle>Compétences à acquérir </StyledInputTitle>
                    <div style={{ display: 'flex' }}>
                      <AutoComplete
                        freeSolo
                        inputValue={searchKey}
                        value={formik.values.competences}
                        name={'competences'}
                        onChange={(event, value) => {
                          onSelectKey(value)
                        }}
                        onInputChange={(event, value) => onChangeSearchKey(value)}
                        id="Participant-Competances"
                        options={keys}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        getOptionLabel={(option) => option.value}
                        variant="outlined"
                        placeholder={'Choisir une compétence'}
                        sxInputStyle={sxInput}
                        multiple={true}
                        error={
                          formik.errors.competences && formik.touched.competences
                        }
                        helperText={
                          formik.errors.competences && formik.touched.competences
                            ? formik.errors.competences
                            : null
                        }
                        fullWidth={true}
                        ListboxProps={{
                          onScroll: loadMoreKeys,
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            <div className="AutoCompteFilterOption">
                              {option.value}
                            </div>
                          </li>
                        )}
                        loading={isLoadingKeysList}
                      />
                      {!isLoadingKeysList && keys.length === 0 && (
                        <Button
                          variant="outlined"
                          style={{ marginLeft: '10px', textTransform: 'none' }}
                          onClick={() => setOpenKey(true)}
                        >
                          Ajouter
                        </Button>
                      )}
                    </div>
                  </Box>
                  <Box sx={{ marginBottom: '20px' }}>
                    <StyledInputTitle>Commentaire </StyledInputTitle>{' '}
                    <TextField
                      id="Formation-Comment"
                      name="comment"
                      variant="outlined"
                      multiline
                      rows={4}
                      onChange={formik.handleChange}
                      InputProps={{
                        sx: sxInput,
                      }}
                      value={formik.values.comment}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative',
                      marginBottom: '20px',
                    }}
                  >
                    <input
                      type="file"
                      style={{
                        opacity: '0',
                        width: '100%',
                        height: '41px',
                        position: 'absolute',
                        zIndex: '2',
                        cursor: 'pointer',
                      }}
                      onChange={onUpload}
                      multiple
                    />
                    <Button
                      variant="contained"
                      sx={{
                        width: '100%',
                        border: '1px dashed #EAEAEA',
                        background: '#F3F3F3',
                        color: '#3C3C3C',
                        boxShadow: 'none',
                        height: '41px',
                        textTransform: 'unset',
                        ':hover': {
                          background: '#dfdfdf',
                          boxShadow: 'none',
                        },
                      }}
                      startIcon={<UploadFileIcon />}
                    >
                      Joindre un fichier / document
                    </Button>
                  </Box>
                  <>
                    {' '}
                    {formik.values.files.length > 0 ? (
                      <Box
                        sx={{
                          padding: '10px 0px',
                        }}
                      >
                        {formik.values.files.map((file, index) => (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '7px 15px',
                            }}
                            key={index}
                          >
                            <Box component="span" sx={{ display: 'block' }}>
                              {formik.values.files[index].name}
                            </Box>

                            <DeleteIcon
                              id={'Files-delete'}
                              style={{ cursor: 'pointer', color: '#B70707' }}
                              onClick={() => deleteFile(file.url)}
                            />
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ErrorOutlineIcon sx={{ marginRight: '10px' }} />
                        <span> Pas de documents</span>
                      </div>
                    )}
                  </>
                </>
              )}
            </StyledDivContent>
          }
          handleClose={handleClose}
          handleClickAction={formik.handleSubmit}
          disabled={verifEdit() || isLoadingFormation}
          isLoadingButtonAction={isLoadingFormationAction}
        />
      </form>
      {openKey && (
        <KeyModal
          handleClose={handleCloseKeyModal}
          modalTitle={'Ajouter une compétence'}
          open={openKey}
          keyValue={searchKey}
        />
      )}
    </>
  )
}
Addkey.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  keys: PropTypes.array,
  createFormation: PropTypes.func,
  errorMessage: PropTypes.string,
  isEdit: PropTypes.bool,
  errorActionCourse: PropTypes.string,
  modalTitle: PropTypes.string,
  isLoadingFormationAction: PropTypes.bool,
  success: PropTypes.bool,
  updateFormation: PropTypes.func,
  fetchKeys: PropTypes.func,
  isLoadingKeysList: PropTypes.bool,
  searchKeys: PropTypes.func,
  fetchMoreKeys: PropTypes.func,
  keysCount: PropTypes.number,
  setErrorUploadFile: PropTypes.func,
  resetKey: PropTypes.func,
  resetConfigFormation: PropTypes.func,
  fetchFiles: PropTypes.func,
  files: PropTypes.array,
  isLoadingFiles: PropTypes.bool,
  isLoadingFormation: PropTypes.bool,
  formation: PropTypes.object,
  formationId: PropTypes.number,
  errorMessageConfig: PropTypes.string,
  keyCreatedObject: PropTypes.object,
  successConfig: PropTypes.bool,
}

export default Addkey
