import { httpGet, httpPatch, httpPost } from '../http/actions'

import {
  GET_FORMATIONS,
  GET_FORMATIONS_SUCCESS,
  GET_FORMATIONS_FAILURE,
  ADD_FORMATION,
  ADD_FORMATION_FAILURE,
  ADD_FORMATION_SUCCESS,
  UPDATE_FORMATION,
  UPDATE_FORMATION_SUCCESS,
  UPDATE_FORMATION_FAILURE,
  ARCHIVAGE_FORMATION,
  ARCHIVAGE_FORMATION_SUCCESS,
  ARCHIVAGE_FORMATION_FAILURE,
  GET_FILES_BY_ID_SUCCESS,
  GET_FILES_BY_ID_FAILURE,
  GET_FILES_BY_ID,
  GET_FORMATION_BY_ID_FAILURE,
  GET_FORMATION_BY_ID_SUCCESS,
  GET_FORMATION_BY_ID,
  GET_FILE_BY_URL,
  GET_FORMATIONS_MORE_SUCCESS,
  GET_FORMATIONS_MORE_FAILURE,
  GET_FORMATIONS_MORE,
} from './constants'

import { axiosHTTP } from '../../utils'
import { removeNull } from '../../utils/utils'

const geodisApi = process.env.REACT_APP_GEODIS_MS_URL

let CancelToken = axiosHTTP.CancelToken
let cancel
let cancelFiles
let cancelFormationByID

export default (store) => (next) => (action) => {
  if (action.type === GET_FORMATIONS) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      formations: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}formation/getFormationsByFilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: GET_FORMATIONS_SUCCESS,
        failure: GET_FORMATIONS_FAILURE,
      })
    )
  }

  if (action.type === GET_FORMATIONS_MORE) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const {
      formations: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}formation/getFormationsByFilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c
        }),
        success: GET_FORMATIONS_MORE_SUCCESS,
        failure: GET_FORMATIONS_MORE_FAILURE,
      })
    )
  }

  if (action.type === ADD_FORMATION) {
    const { formation } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}formation/create`,
        data: formation,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: ADD_FORMATION_SUCCESS,
        failure: ADD_FORMATION_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_FORMATION) {
    store.dispatch(
      httpPatch({
        endPoint: `${geodisApi}formation/update`,
        data: action.payload,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: UPDATE_FORMATION_SUCCESS,
        failure: UPDATE_FORMATION_FAILURE,
      })
    )
  }

  if (action.type === ARCHIVAGE_FORMATION) {
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}formation/softRemove`,
        data: removeNull(action.payload),
        success: ARCHIVAGE_FORMATION_SUCCESS,
        failure: ARCHIVAGE_FORMATION_FAILURE,
      })
    )
  }

  if (action.type === GET_FILES_BY_ID) {
    // cancel the request
    if (cancelFiles != undefined) {
      cancelFiles()
    }
    const { id, path } = action.payload

    store.dispatch(
      httpGet({
        endPoint: `${geodisApi}${path}/files/${id}`,
        data: action.payload,
        cancelToken: new CancelToken(function executor(d) {
          // An executor function receives a cancel function as a parameter
          cancelFiles = d
        }),
        success: GET_FILES_BY_ID_SUCCESS,
        failure: GET_FILES_BY_ID_FAILURE,
      })
    )
  }

  if (action.type === GET_FORMATION_BY_ID) {
    // cancel the request
    if (cancelFormationByID != undefined) {
      cancelFormationByID()
    }
    const { id } = action.payload

    store.dispatch(
      httpGet({
        endPoint: `${geodisApi}formation/getFormationById/${id}`,
        cancelToken: new CancelToken(function executor(d) {
          // An executor function receives a cancel function as a parameter
          cancelFormationByID = d
        }),
        success: GET_FORMATION_BY_ID_SUCCESS,
        failure: GET_FORMATION_BY_ID_FAILURE,
      })
    )
  }

  if (action.type === GET_FILE_BY_URL) {
    const { url } = action.payload

    let lien = url
    const link = document.createElement('a')
    link.target = '_blank'
    link.href = lien
    link.click()
  }

  next(action)
}
