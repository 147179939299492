import { connect } from 'react-redux'
import ClientForm from '../../../pages/main/PlaceOrders/Client/ClientForm/index'

import { getOrdersState } from '../../../redux/shipment/selectors'

const mapStateToProps = (state) => ({
  orders: getOrdersState(state),
})

export default connect(mapStateToProps, null)(ClientForm)
