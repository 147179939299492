import React, { useEffect } from 'react'
import Dialog from '../../../ModalDialog'
import DialogContentText from '@mui/material/DialogContentText'
import PropTypes from 'prop-types'
import IconDeleteFilter from '../../../../assets/icons/navFilter/MdiFilterRemoveOutline'

export default function DeleteFilter({
  open,
  handleClose,
  payload,
  onDeleteFilter,
  isLoadingActionFilter,
  errorActionFilter,
  isActionDeleteFilter,
}) {
  const deleteFilter = () => {
    onDeleteFilter(payload)
  }

  useEffect(() => {
    if (errorActionFilter) handleClose()
  }, [errorActionFilter])

  useEffect(() => {
    if (isActionDeleteFilter) {
      handleClose()
    }
  })
  return (
    <Dialog
      maxWidthDialog={'sm'}
      open={open}
      title={'Confirmation de la suppression'}
      iconTitle={
        <IconDeleteFilter
          style={{
            width: '20px',
            height: '20px',
            marginRight: '10px',
          }}
        />
      }
      content={
        <DialogContentText
          sx={{ pr: 2, pb: 2, justifyContent: 'normal !important', width: '450px' }}
        >
          {`Si vous confirmez, votre filtrer sera définitivement supprimé.`}
        </DialogContentText>
      }
      handleClose={handleClose}
      handleClickAction={deleteFilter}
      isLoadingButtonAction={isLoadingActionFilter}
    />
  )
}
DeleteFilter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onDeleteFilter: PropTypes.func,
  payload: PropTypes.object,
  isLoadingActionFilter: PropTypes.bool,
  errorActionFilter: PropTypes.string,
  isActionDeleteFilter: PropTypes.bool,
}
