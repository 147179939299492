import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import {
  StyledContainerDetails,
  StyledBlocDetailsTitle,
  StyledDetailsTitle,
  StyledCardDetails,
  StyledBlocDetailsElement,
  StyledDetailsElement,
  StyledDetailsSecondElement,
  StyleDivider,
} from '../style'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import VisibilityIcon from '@mui/icons-material/Visibility'
import FilesModal from '../../../../../containers/settings/Modals/FilesModal'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'

const Details = ({
  sessionsDetails,
  isLoadingSessionsDetails,
  fetchSessionDetails,
  fetchFiles,
  resetSessionConfig,
}) => {
  const params = useParams()
  const [paramsInit, setParams] = useState(null)
  const [openDialogFiles, setOpenDialogFiles] = useState(false)

  useEffect(() => {
    fetchSessionDetails(params.id)
    setParams(params)

    window.addEventListener('beforeunload', resetSessionConfig)

    return () => {
      resetSessionConfig()
      window.removeEventListener('beforeunload', resetSessionConfig)
    }
  }, [])

  useEffect(() => {
    if (paramsInit && paramsInit.id != params.id) {
      setParams(params)
      fetchSessionDetails(params.id)
    }
    window.addEventListener('beforeunload', resetSessionConfig)

    return () => {
      resetSessionConfig()
      window.removeEventListener('beforeunload', resetSessionConfig)
    }
  }, [params])

  return (
    <>
      <StyledContainerDetails>
        {isLoadingSessionsDetails ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress sx={{ color: '#1976D2' }} />
          </Box>
        ) : (
          <>
            <StyledBlocDetailsTitle>
              <StyledDetailsTitle component="span">
                {sessionsDetails?.name || 'N/A'}
              </StyledDetailsTitle>
            </StyledBlocDetailsTitle>
            <StyledCardDetails>
              <StyledBlocDetailsElement>
                <StyledDetailsElement component="span">
                  Fichier :
                </StyledDetailsElement>
                <Tooltip
                  title={<span className="tootltip-content">Afficher fichiers</span>}
                  arrow
                >
                  <IconButton
                    id={`afficher-fichier-Session`}
                    onClick={() => {
                      fetchFiles(sessionsDetails.id)
                      setOpenDialogFiles(true)
                    }}
                    color="primary"
                    size="small"
                    sx={{ p: 0 }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </StyledBlocDetailsElement>
              <StyleDivider />
              <StyledBlocDetailsElement>
                <StyledDetailsElement component="span">
                  Formation :
                </StyledDetailsElement>
                <StyledDetailsSecondElement component="span">
                  {sessionsDetails?.formation?.name || 'N/A'}
                </StyledDetailsSecondElement>
              </StyledBlocDetailsElement>
              <StyleDivider />
              <StyledBlocDetailsElement>
                <StyledDetailsElement component="span">
                  Date Fin :
                </StyledDetailsElement>
                <StyledDetailsSecondElement component="span">
                  {sessionsDetails?.startDate
                    ? moment(sessionsDetails.startDate).format('DD/MM/YYYY')
                    : 'N/A'}
                </StyledDetailsSecondElement>
              </StyledBlocDetailsElement>
              <StyleDivider />
              <StyledBlocDetailsElement>
                <StyledDetailsElement component="span">
                  Date Début :
                </StyledDetailsElement>
                <StyledDetailsSecondElement component="span">
                  {sessionsDetails?.endDate
                    ? moment(sessionsDetails.endDate).format('DD/MM/YYYY')
                    : 'N/A'}
                </StyledDetailsSecondElement>
              </StyledBlocDetailsElement>
              <StyleDivider />
              <StyledBlocDetailsElement>
                <StyledDetailsElement component="span">
                  Formatteur :
                </StyledDetailsElement>
                <StyledDetailsSecondElement component="span">
                  {sessionsDetails?.former || 'N/A'}
                </StyledDetailsSecondElement>
              </StyledBlocDetailsElement>
              <StyleDivider />
              <StyledBlocDetailsElement>
                <StyledDetailsElement component="span">
                  Compétences :
                </StyledDetailsElement>
                <StyledDetailsSecondElement component="span">
                  {sessionsDetails?.formation?.competences
                    .map((el) => el.label)
                    .join(', ') || 'N/A'}
                </StyledDetailsSecondElement>
              </StyledBlocDetailsElement>
            </StyledCardDetails>
          </>
        )}
      </StyledContainerDetails>
      {openDialogFiles && (
        <FilesModal
          globalID={'Sessions'}
          open={openDialogFiles}
          onClose={() => {
            setOpenDialogFiles(false)
          }}
        />
      )}
    </>
  )
}
Details.propTypes = {
  sessionsDetails: PropTypes.object,
  isLoadingSessionsDetails: PropTypes.bool,
  fetchSessionDetails: PropTypes.func,
  fetchFiles: PropTypes.func,
  resetSessionConfig: PropTypes.func,
}
export default Details
