import React from 'react'
import PropTypes from 'prop-types'

import img404 from '../../assets/images/illustration-404.svg'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import './PageNotFound.scss'
import { getPathRedirectRole } from '../../utils/roleManagement'

/** A component to that provides a page not found ui */
export default function PageNotFound({ userRole }) {
  return (
    <div className="root">
      <img src={img404} alt="not-found" />
      <label>
        Désolé, la page que vous cherchez n&apos;existe pas. <br />
        Retourner à la page d’accueil
      </label>
      <Button
        color="primary"
        href={getPathRedirectRole(userRole)}
        className="button"
      >
        <ArrowBackIcon /> Retourner
      </Button>
    </div>
  )
}

PageNotFound.propTypes = {
  userRole: PropTypes.string,
}
