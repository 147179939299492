import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import DateRangePicker from './RangePicker'
import DrawerFilterDate from '../../../../../Drawer'
import IcOutlineCalendarMonth from '../../../../../../assets/icons/navFilter/IcOutlineCalendarMonth'
import Button from '@mui/material/Button'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

export default function DateRange({
  disabled,
  filterStateChanged,
  dateRangeFiltersValues,
  dateFilterLabel,
  nameFilter,
  isTodayDate,
  nameFilterTodayDate,
  dateRangeFiltersTodayDate,
}) {
  const [state, setState] = useState(null)
  const [opentDrawer, setOpentDrawer] = useState(false)
  const [checkedTodayDate, setCheckedTodayDate] = useState(false)
  const toggleDrawer = (open, event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (!open) {
      if (checkedTodayDate) {
        filterStateChanged(nameFilterTodayDate, [true])
        filterStateChanged(nameFilter, [])
      } else if (state) {
        const startDate = moment(state[0].startDate)
          .startOf('day')
          .format('YYYY-MM-DD HH:mm')
        const endDate = moment(state[0].endDate)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm')
        filterStateChanged(nameFilter, [
          startDate.toString().slice(0, 10) + '~' + endDate.toString().slice(0, 10),
        ])
        filterStateChanged(nameFilterTodayDate, [])
      } else {
        filterStateChanged(nameFilter, [])
        filterStateChanged(nameFilterTodayDate, [])
      }
    }
    setOpentDrawer(open)
  }

  const dateForm = (date) => moment(date).format('DD MMM YYYY')

  useEffect(() => {
    if (dateRangeFiltersTodayDate && dateRangeFiltersTodayDate.length > 0) {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ])
      setCheckedTodayDate(true)
    } else if (dateRangeFiltersValues && dateRangeFiltersValues.length > 0) {
      const fullRange = dateRangeFiltersValues[0].split('~')
      const startDate = moment(fullRange[0]).startOf('day')
      const endDate = moment(fullRange[1]).endOf('day')
      setState([
        {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          key: 'selection',
        },
      ])
      setCheckedTodayDate(false)
    } else {
      setState(null)
      setCheckedTodayDate(false)
    }
  }, [dateRangeFiltersValues, dateRangeFiltersTodayDate])

  const dateToDisplay = (start, end) => {
    const startDate = dateForm(start)
    const endDate = dateForm(end)
    return startDate === endDate ? `${startDate}` : `${startDate} ~ ${endDate}`
  }

  const onCancel = () => {
    setState(null)
  }

  const handleChange = (event) => {
    setCheckedTodayDate(event.target.checked)
    if (event.target.checked) {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ])
    } else {
      setState(null)
    }
  }

  return (
    <DrawerFilterDate
      filterLabel={
        !state ? (
          dateFilterLabel
        ) : dateFilterLabel ? (
          <div
            style={{ display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box' }}
          >
            <span>{`${dateFilterLabel} : `}</span>
            <span>{dateToDisplay(state[0].startDate, state[0].endDate)}</span>
          </div>
        ) : (
          dateToDisplay(state[0].startDate, state[0].endDate)
        )
      }
      disabled={disabled}
      content={
        <DateRangePicker
          state={state}
          setState={setState}
          disabled={checkedTodayDate}
        />
      }
      toggleDrawer={toggleDrawer}
      opentDrawer={opentDrawer}
      Icon={IcOutlineCalendarMonth}
      id={`Date-Range-${nameFilter}`}
      actionButtons={
        <>
          {isTodayDate && (
            <div style={{ paddingLeft: '12px' }}>
              <FormControlLabel
                label="Date de jour"
                control={
                  <Checkbox checked={checkedTodayDate} onChange={handleChange} />
                }
              />
            </div>
          )}

          <div
            style={{
              padding: '10px 20px',
              bottom: '0',
              position: 'fixed',
              width: '280px',
              background: 'white',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '18px',
              }}
            >
              <Button
                id={`Annuler-${nameFilter}`}
                variant="contained"
                color="primary"
                onClick={onCancel}
                sx={{
                  width: '130px',
                  height: '40px',
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '13px',
                  fontWeight: '400',
                  backgroundColor: '#f61057',
                  ':hover': {
                    backgroundColor: '#d32f2f',
                  },
                }}
                disabled={checkedTodayDate}
              >
                {'Annuler la date'}
              </Button>
            </div>
          </div>
        </>
      }
    />
  )
}
DateRange.propTypes = {
  disabled: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  dateRangeFiltersValues: PropTypes.array,
  dateFilterLabel: PropTypes.string,
  nameFilter: PropTypes.string,
  isTodayDate: PropTypes.bool,
  dateRangeFiltersTodayDate: PropTypes.array,
  nameFilterTodayDate: PropTypes.string,
}
