import { validatorContants } from '../../../../utils/validator/constants'

export const assemblyValidators = {
  selectedSalesChannel: [validatorContants.REQUIRED, validatorContants.PATTERN],
  selectedAddress: [validatorContants.REQUIRED, validatorContants.PATTERN],
  queryaddress: [validatorContants.REQUIRED],
  date: [validatorContants.REQUIRED, validatorContants.PATTERN],
  time: [validatorContants.REQUIRED, validatorContants.PATTERN],
  purchaseAmount: [
    validatorContants.REQUIRED,
    validatorContants.PATTERN,
    { code: validatorContants.MIN, value: 0 },
  ],
  estimatedPrice: [validatorContants.REQUIRED, validatorContants.PATTERN],
  clientPrice: [
    validatorContants.REQUIRED,
    validatorContants.PATTERN,
    { code: validatorContants.MIN, value: 0 },
  ],
  observation: [validatorContants.REQUIRED],
  category: [validatorContants.REQUIRED],
  fileUploaded: [validatorContants.REQUIRED],
}
