import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Loading from '../../../components/Loading'
import Error from '../../../components/Modal/HttpErrorPopUp'
import EquipageDialog from '../../../containers/main/Optimiser'
import AssignDialog from '../../../containers/main/ActionShipment/AssignDialog'
import Plannification from '../../../containers/main/Plannification'

import './index.scss'
import NoDataFound from '../../../components/NoDataFound'
import CustomPagination from '../../../components/Pagination'
import OrdersTable from '../../main/Orders/Table'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import moment from 'moment'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Button from '@mui/material/Button'

import SelectedOrdersRecap from './Recap/selectedOrdersRecap'
import ProgrammerMap from '../../../containers/main/Programmer/programmerMap'
import { parseSelectedCourses } from '../../../utils/tours'
import NavFilter from '../../../containers/main/NavFilter'

import { useHistory } from 'react-router-dom'

function MapProgrammer(props) {
  const {
    removeSelectedCourses,
    addSelectedCourses,
    selectedCourses,
    hideImpossibleCourses,
    setUnselectTrigger,
    unselectTrigger,
    shipments,
    setHideImpossibleCourses,
    removeAllSelected,
  } = props

  return (
    <div style={{ width: '100%', minWidth: '0px' }}>
      <div className="right-section-programmer">
        <div
          className="programmer-map"
          style={{
            height:
              selectedCourses?.length > 0
                ? `calc(100vh - ${190}px)`
                : `calc(100vh - ${133}px)`,
          }}
        >
          <ProgrammerMap
            removeSelectedCourses={removeSelectedCourses}
            addSelectedCourses={addSelectedCourses}
            selectedCourses={selectedCourses}
            hideImpossibleCourses={hideImpossibleCourses}
            setUnselectTrigger={setUnselectTrigger}
            unselectTrigger={unselectTrigger}
            mapContainerStyle={{
              minHeight: '100%',
              minWidth: '0px',
              position: 'absolute',
            }}
            mapId={'programmer-map-container'}
            removeAllSelected={removeAllSelected}
          />
          <SelectedOrdersRecap
            selectedCourses={selectedCourses}
            shipments={shipments}
          />
          <FormControl className="switcher-container impossible-courses">
            <FormGroup row>
              <FormControlLabel
                sx={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    onChange={() => setHideImpossibleCourses(!hideImpossibleCourses)}
                    sx={{ color: '#3E99F3' }}
                    checked={hideImpossibleCourses}
                    value={hideImpossibleCourses}
                    name="hideImpossibleCourses"
                  />
                }
                label={'Masquer les courses impossibles'}
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
    </div>
  )
}

MapProgrammer.propTypes = {
  removeSelectedCourses: PropTypes.func,
  addSelectedCourses: PropTypes.func,
  hideImpossibleCourses: PropTypes.bool,
  setUnselectTrigger: PropTypes.func,
  unselectTrigger: PropTypes.bool,
  shipments: PropTypes.array,
  setHideImpossibleCourses: PropTypes.func,
  selectedCourses: PropTypes.array,
  removeAllSelected: PropTypes.func,
}
function Programmer({
  getShipmentByFilter,
  shipments,
  isLoading,
  error,
  count,
  limit,
  pageCount,
  pageChanged,
  pageLimitChanged,
  removeError,
  filterReducer,
  setFilter,
  removeSelectedCourses,
  addSelectedCourses,
  removeAllSelectedCourses,
  selectedCourses,
  updateOrdersArray,
  errorFilter,
  removeErrorFilter,
  resetFilterPlanification,
  searchReducer,
  isViewPlannification,
  viewPlannification,
  resetShipments,
  errorActionCourse,
  removeErrorAction,
  resetOrderAction,
  isActionDone,
  defaultFilterPlanification,
  sort,
  orderBy,
  fetchSearchCornerSave,
}) {
  const [page, setPage] = React.useState(1)
  const [limitIndex, setLimitIndex] = React.useState(0)
  const [limitLabel, setLimit] = useState(
    JSON.parse(localStorage.getItem('page_limit'))?.value || 50
  )
  const [open, setOpen] = useState(false)
  const [attributionDialogOpen, setAttributionDialogOpen] = useState(false)
  const [selectedShipments, setSelectedshipments] = useState([])
  const [checkedAllCourses, setCheckedAllCourses] = useState(false)
  const [hideImpossibleCourses, setHideImpossibleCourses] = useState(false)
  const [unselectTrigger, setUnselectTrigger] = useState(false)
  const [equipageDialogOpen, setEquipageDialogOpen] = useState(false)
  const [equalDropoffStartCourses, setEqualDropoffStartCourses] = useState(false)
  const [dropoffStartCourses, setDropoffStartCourses] = useState(false)
  const [ldt, setLdt] = useState(undefined)
  const [openErrorFilter, setOpenErrorFilter] = useState(false)
  const history = useHistory()

  const onErrorFilterClose = () => {
    setOpenErrorFilter(false)
    removeErrorFilter()
  }
  useEffect(() => {
    return () => {
      viewPlannification(false)
      resetShipments()
      resetOrderAction()
    }
  }, [])
  useEffect(() => {
    if (errorFilter) setOpenErrorFilter(true)
  }, [errorFilter])

  useEffect(() => {
    if (selectedCourses.length == limitLabel) setCheckedAllCourses(true)
    else {
      setCheckedAllCourses(false)
    }
  }, [selectedCourses])

  const handlePageChange = (value) => {
    setPage(value)
    const search = searchReducer && count > 1
    const searchCorner = {
      criteria: searchReducer?.label,
      type: searchReducer?.categorie.code,
    }
    pageChanged((value - 1) * limit, search, searchCorner)
  }

  const handleAssignDialogOpen = () => {
    const filtered = []
    if (selectedCourses.length) {
      shipments.filter(({ status, code }) => {
        if (selectedCourses.includes(code))
          filtered.push({
            status,
            code,
          })
      })
    }
    setSelectedshipments(filtered)
    setAttributionDialogOpen(true)
  }

  useEffect(() => {
    const pageLimit = JSON.parse(localStorage.getItem('page_limit'))
    if (pageLimit) {
      setLimitIndex(pageLimit.index)
      setLimit(pageLimit.value)
    }
  }, [])

  const handlePageLimitChange = (limit) => {
    setPage(1)
    const search = searchReducer && count > 1
    const searchCorner = {
      criteria: searchReducer?.label,
      type: searchReducer?.categorie.code,
    }
    pageLimitChanged(limit == 0 ? count : limit, search, searchCorner)
  }
  const onLimitChange = (index, value) => {
    const limitValue = value === 'Tous' ? count : value
    localStorage.setItem('page_limit', JSON.stringify({ index, value: limitValue }))
    setLimitIndex(index)
    setLimit(limitValue)
  }
  useEffect(() => {
    setPage(1)
  }, [pageCount])
  const onErrorClose = () => {
    setOpen(false)
    removeError()
  }
  useEffect(() => {
    setOpen(error !== null)
  }, [error])

  const [sortDirection, setOrder] = useState('')
  const [orderByColumn, setOrderBy] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)

  useEffect(() => {
    setOrderBy(sort || '')
  }, [sort])
  useEffect(() => {
    setOrder(orderBy?.toLowerCase() || '')
  }, [orderBy])

  const createSortHandler = (sortName) => {
    let direction
    if (sortName === orderByColumn) {
      if (sortDirection === 'desc') {
        direction = 'asc'
        setOrderBy(sortName)
        setOrder(direction)
        setFilter(
          {
            ...filterReducer,
            sort: [sortName],
            orderBy: [direction.toUpperCase()],
          },
          false
        )
      } else if (sortDirection === 'asc') {
        setOrderBy('')
        setOrder('')
        setFilter(
          {
            ...filterReducer,
            sort: null,
            orderBy: null,
          },
          false
        )
      } else {
        direction = 'desc'
        setOrderBy(sortName)
        setOrder(direction)
        setFilter(
          {
            ...filterReducer,
            sort: [sortName],
            orderBy: [direction.toUpperCase()],
          },
          false
        )
      }
    } else {
      direction = 'desc'
      setOrderBy(sortName)
      setOrder(direction)
      setFilter(
        {
          ...filterReducer,
          sort: [sortName],
          orderBy: [direction.toUpperCase()],
        },
        false
      )
    }
  }

  const handleEquipageDialogOpen = () => {
    const filtered = []
    if (selectedCourses.length) {
      shipments.filter(({ code, dropOffStart }) => {
        if (selectedCourses.includes(code))
          filtered.push({
            dropOffStart,
          })
      })
      const allEqual = filtered.every(
        (val) =>
          moment(val.dropOffStart).format('DD/MM/YYYY') ===
            moment(filtered[0].dropOffStart).format('DD/MM/YYYY') && val.dropOffStart
      )
      setEqualDropoffStartCourses(allEqual)
      setDropoffStartCourses(filtered[0].dropOffStart)
      updateOrdersArray(parseSelectedCourses(selectedCourses, shipments))
    }

    setEquipageDialogOpen(true)
  }
  const handleCloseErrorAction = () => {
    removeErrorAction()
  }

  //Action order
  useEffect(() => {
    if (isActionDone) {
      setAttributionDialogOpen(false)
      resetOrderAction()
      resetShipments()
      if (searchReducer && count > 0) {
        const searchCorner = {
          criteria: searchReducer?.label,
          type: searchReducer?.categorie.code,
        }
        fetchSearchCornerSave(searchCorner)
      } else {
        getShipmentByFilter()
      }
    }
  }, [isActionDone])

  const removeAllSelected = () => {
    setUnselectTrigger(true)
    removeAllSelectedCourses()
  }

  return (
    <>
      <div className="container">
        {!isViewPlannification && (
          <NavFilter
            statusFilter={true}
            salesChannelfilter={true}
            prestationFilter={true}
            departmentFilter={true}
            wareHouseFilter={true}
            programerDateFilter={true}
            deliveryDateFilterRange={true}
            toursFilter={true}
            tourListFilter={true}
            filterReducer={filterReducer}
            setFilter={setFilter}
            dateFilterCreation={true}
            BusinessOwnerFilter={true}
            resetFilter={resetFilterPlanification}
            courseTypeFilter={true}
            pathnameFilter={history.location.pathname}
            defaultFilter={defaultFilterPlanification}
            courseLieeFilter={true}
          />
        )}
        {isViewPlannification ? (
          <Plannification />
        ) : isLoading ? (
          <Loading />
        ) : (
          <>
            {error && (
              <Error
                status={error?.status || 500}
                statusText={error.statusText}
                open={open}
                setOpen={() => {
                  onErrorClose()
                }}
              />
            )}

            {shipments?.length > 0 ? (
              <>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',

                    boxSizing: 'border-box',
                    overflow: 'hidden',
                    '@media (max-width: 960px)': {
                      flexWrap: 'wrap',
                    },
                  }}
                >
                  <div style={{ flexGrow: '1', minWidth: '0px' }}>
                    <OrdersTable
                      removeSelectedCourses={removeSelectedCourses}
                      setDropoffStartCourses={setDropoffStartCourses}
                      setEqualDropoffStartCourses={setEqualDropoffStartCourses}
                      addSelectedCourses={addSelectedCourses}
                      removeAllSelectedCourses={removeAllSelectedCourses}
                      selectedCourses={selectedCourses}
                      shipments={shipments}
                      isProgrammerPage={true}
                      checkedAllCourses={checkedAllCourses}
                      setCheckedAllCourses={setCheckedAllCourses}
                      createSortHandler={createSortHandler}
                      openSnackbar={openSnackbar}
                      handleSnackbar={setOpenSnackbar}
                      orderBy={orderByColumn}
                      sortDirection={sortDirection}
                      setOpenTourDialog={setEquipageDialogOpen}
                      setLdt={setLdt}
                    />
                    <CustomPagination
                      selectedPage={page}
                      onPageChange={handlePageChange}
                      pageTotal={pageCount}
                      itemsTotal={count}
                      handlePageLimitChange={handlePageLimitChange}
                      limitIndex={limitIndex}
                      onLimitChange={onLimitChange}
                      limit={limitLabel}
                    />
                  </div>

                  <MapProgrammer
                    removeSelectedCourses={removeSelectedCourses}
                    addSelectedCourses={addSelectedCourses}
                    selectedCourses={selectedCourses}
                    hideImpossibleCourses={hideImpossibleCourses}
                    setUnselectTrigger={setUnselectTrigger}
                    unselectTrigger={unselectTrigger}
                    shipments={shipments}
                    setHideImpossibleCourses={setHideImpossibleCourses}
                    removeAllSelected={removeAllSelected}
                  />
                </div>

                {selectedCourses.length !== 0 && (
                  <div className="table-buttons-container">
                    <Button
                      onClick={() => {
                        setSelectedshipments([])
                        removeAllSelectedCourses()
                        setCheckedAllCourses(false)
                        setUnselectTrigger(true)
                      }}
                      className="table-button"
                      variant="outlined"
                      color="primary"
                      sx={{
                        textTransform: 'none',
                        fontSize: '13px',
                        fontWeight: 400,
                      }}
                      id={'button-tout-deselctionner-page-programmer'}
                    >
                      Tout déselectionner
                    </Button>
                    <Button
                      onClick={handleAssignDialogOpen}
                      //   className="table-button"
                      variant="contained"
                      color="primary"
                      disabled={selectedCourses.length === 0}
                      sx={{
                        width: '160px',
                        textTransform: 'capitalize',
                        fontSize: '13px',
                        fontWeight: 400,
                        color: 'rgba(0, 0, 0, 0.87)',
                        boxShadow:
                          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
                        backgroundColor: '#e0e0e0',
                        '&:hover': {
                          boxShadow:
                            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
                          backgroundColor: '#d5d5d5',
                        },
                      }}
                      id={'button-attribution-warning-page-programmer'}
                    >
                      Attribution Warning
                    </Button>
                    <Button
                      onClick={handleEquipageDialogOpen}
                      className="table-button"
                      variant="contained"
                      color="secondary"
                      disabled={selectedCourses.length === 0}
                      sx={{
                        width: '130px',
                        textTransform: 'capitalize',
                        fontSize: '13px',
                        fontWeight: 400,
                        color: '#fff',
                        backgroundColor: '#10A549',
                        '&:hover': {
                          backgroundColor: '#158f44',
                        },
                      }}
                      id={'button-optimiser-page-programmer'}
                    >
                      Optimiser
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  marginTop: '40px',
                }}
              >
                <NoDataFound />
              </div>
            )}
          </>
        )}
        {errorFilter && (
          <Error
            statusText={errorFilter}
            open={openErrorFilter}
            setOpen={onErrorFilterClose}
          />
        )}
      </div>

      {equipageDialogOpen && (
        <EquipageDialog
          ldt={ldt}
          open={equipageDialogOpen}
          hideDialog={() => {
            setEquipageDialogOpen(false)
            setLdt(undefined)
          }}
          selectedCourses={selectedCourses}
          shipments={shipments}
          dropoffStartCourses={dropoffStartCourses}
          equalDropoffStartCourses={
            equalDropoffStartCourses || (Boolean(ldt) && equalDropoffStartCourses)
          }
        />
      )}

      {attributionDialogOpen && (
        <AssignDialog
          hideDialog={() => setAttributionDialogOpen(false)}
          open={attributionDialogOpen}
          selectedCourses={selectedCourses}
          selectedShipments={selectedShipments}
        />
      )}

      <Snackbar
        open={errorActionCourse != null}
        autoHideDuration={6000}
        onClose={handleCloseErrorAction}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error" onClose={handleCloseErrorAction} elevation={6}>
          {errorActionCourse}
        </Alert>
      </Snackbar>
    </>
  )
}
Programmer.propTypes = {
  shipments: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getShipmentByFilter: PropTypes.func.isRequired,
  error: PropTypes.shape({
    status: PropTypes.number,
    statusText: PropTypes.string,
  }),
  count: PropTypes.number,
  limit: PropTypes.number,
  pageCount: PropTypes.number,
  pageChanged: PropTypes.func,
  pageLimitChanged: PropTypes.func,
  removeError: PropTypes.func,
  resetFilters: PropTypes.func,
  showMessage: PropTypes.bool,
  message: PropTypes.string,
  filterReducer: PropTypes.object,
  setFilter: PropTypes.func,
  addSelectedCourses: PropTypes.func,
  removeSelectedCourses: PropTypes.func,
  removeAllSelectedCourses: PropTypes.func,
  selectedCourses: PropTypes.array,
  updateOrdersArray: PropTypes.any,
  removeErrorFilter: PropTypes.func,
  errorFilter: PropTypes.string,
  resetFilterPlanification: PropTypes.func,
  searchReducer: PropTypes.object,
  isViewPlannification: PropTypes.bool,
  viewPlannification: PropTypes.func,
  resetShipments: PropTypes.func,
  errorActionCourse: PropTypes.string,
  removeErrorAction: PropTypes.func,
  resetOrderAction: PropTypes.func,
  isActionDone: PropTypes.bool,
  defaultFilterPlanification: PropTypes.object,
  sort: PropTypes.string,
  orderBy: PropTypes.string,
  fetchSearchCornerSave: PropTypes.func,
}
export default Programmer
