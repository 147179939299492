import moment from 'moment'
import {
  ACCEPTEE_CHARGEMENT_DECHARGEMENT_STATUS,
  CANCELED_STATUS,
  IMPOSSIBLE_DELIVERY_PICKUP_STATUS,
  PENDING_STATUS,
} from './values'
import { LimitPaginationList } from '../components/Pagination/LimitPaginationList'
import { getValue } from './index'
import { programStatusList } from './values'

const parserFilterCanalVente = (salesChannels) => {
  const salesChannelListGroup = getValue('salesChannelsGroupe', {})
  let regroupement = []
  Object.keys(salesChannelListGroup).map((key) =>
    regroupement.push(salesChannelListGroup[key].radioGroupe)
  )
  let newSelected = []
  salesChannels.forEach((el) => {
    if (regroupement.map((reg) => reg.code).includes(el)) {
      newSelected.push(...salesChannelListGroup[el].radios.map((el) => el.code))
    } else {
      newSelected.push(el)
    }
  })
  return newSelected
}

export const filterParser = (urlFilterValues) => {
  const pageLimit = JSON.parse(localStorage.getItem('page_limit'))
  const filter = {
    client: [],
    codeStatus: window.location.pathname.includes('programmer')
      ? programStatusList.map((el) => el.code)
      : null,
    codeCanal: parserFilterCanalVente(urlFilterValues['salesChannels'] || []),
    codeChauffeur: null,
    endTime: null,
    limit: window.location.pathname.includes('programmer')
      ? pageLimit
        ? parseInt(pageLimit.value)
        : 50
      : LimitPaginationList[0].value,
    offset: 0,
    service: null,
    departement: null,
    startTime: null,
    dropOffStart: undefined,
    warehouse: [],
    tourList: [],
    courseType: null,
    courseSAV: undefined,
    businessOwner: undefined,
    receptionDate: null,
    reply: undefined,
    subject: undefined,
    processingDepartment: undefined,
    direction: undefined,
    nature: undefined,
    deliveryRelated: undefined,
    rdi: undefined,
  }
  Object.keys(urlFilterValues).forEach((val) => {
    switch (val) {
      case 'status':
        {
          filter.codeStatus = urlFilterValues[val]
            .map((el) => {
              switch (el) {
                case ACCEPTEE_CHARGEMENT_DECHARGEMENT_STATUS.code:
                  return ACCEPTEE_CHARGEMENT_DECHARGEMENT_STATUS.codes
                case CANCELED_STATUS.code:
                  return CANCELED_STATUS.codes
                case PENDING_STATUS.code:
                  return PENDING_STATUS.codes
                case IMPOSSIBLE_DELIVERY_PICKUP_STATUS.code:
                  return IMPOSSIBLE_DELIVERY_PICKUP_STATUS.codes
                case 'TOUS':
                  return window.location.pathname.includes('programmer')
                    ? programStatusList.map((el) => el.code)
                    : []
                default:
                  return el
              }
            })
            .flat(1)
        }
        break
      case 'daterange':
        {
          const dateRange = urlFilterValues[val][0].split('~')
          filter.startTime = moment(dateRange[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm')
          filter.endTime = moment(dateRange[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm')
        }
        break
      case 'createdAtTodayDate':
        {
          if (urlFilterValues[val][0] == true) {
            filter.createdAtStart = moment(new Date())
              .startOf('day')
              .format('YYYY-MM-DD HH:mm')
            filter.createdAtEnd = moment(new Date())
              .endOf('day')
              .format('YYYY-MM-DD HH:mm')
          }
        }
        break
      case 'createdAt':
        {
          const createdAt = urlFilterValues[val][0].split('~')
          filter.createdAtStart = moment(createdAt[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm')
          filter.createdAtEnd = moment(createdAt[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm')
        }
        break
      case 'drivers':
        filter.codeChauffeur = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return el
            }
          })
          .flat(1)
        break
      case 'salesChannels':
        filter.codeCanal
          .map((s) => {
            return decodeURI(s)
          })
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return el
            }
          })
          .flat(1)
        break
      case 'prestation':
        filter.service = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return el
            }
          })
          .flat(1)
        break
      case 'warehouse':
        filter.warehouse = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return decodeURI(el)
            }
          })
          .flat(1)
        break
      case 'subject':
        filter.subject = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return decodeURI(el)
            }
          })
          .flat(1)
        break
      case 'processingDepartment':
        filter.processingDepartment = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return decodeURI(el)
            }
          })
          .flat(1)
        break

      case 'client':
        filter.client = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return el
            }
          })
          .flat(1)
        break
      case 'tourList':
        filter.tourList = urlFilterValues[val]
          .map((s) => decodeURI(s))
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return el
            }
          })
          .flat(1)
        break
      case 'department':
        filter.departement = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return el
            }
          })
          .flat(1)
        break
      case 'dropOffDay':
        filter.dropOffStart = urlFilterValues[val][0]
        break
      case 'tours':
        if (urlFilterValues[val][0] == true) {
          filter.tour = !(urlFilterValues[val][0] == true)
        } else {
          delete filter.tour
        }
        break
      case 'courseType':
        filter.courseType = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return el
            }
          })
          .flat(1)
        break
      case 'sav':
        if (urlFilterValues[val][0] == true) {
          filter.courseSAV = ['sav']
        } else {
          filter.courseSAV = undefined
        }
        break
      case 'direction':
        if (urlFilterValues[val][0]) {
          filter.direction = decodeURI(urlFilterValues[val][0])
        } else {
          filter.direction = undefined
        }
        break

      case 'reply':
        if (urlFilterValues[val][0] === 'true') {
          filter.reply = 'true'
        } else if (urlFilterValues[val][0] === 'false') {
          filter.reply = 'false'
        } else filter.reply = null
        break
      case 'businessOwner':
        filter.businessOwner = urlFilterValues[val]
        break
      case 'dropOffDayRange':
        {
          const dropOffDayRange = urlFilterValues[val][0].split('~')
          filter.dropOffStart = moment(dropOffDayRange[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm')
          filter.dropOffEnd = moment(dropOffDayRange[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm')
        }
        break
      case 'receptionDate':
        {
          const receptionDate = urlFilterValues[val][0].split('~')
          filter.receptionDateStart = moment(receptionDate[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm')
          filter.receptionDateEnd = moment(receptionDate[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm')
        }
        break
      case 'nature':
        filter.nature = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              case 'TOUS':
                return []
              default:
                return decodeURI(el)
            }
          })
          .flat(1)
        break
      case 'deliveryRelated':
        if (urlFilterValues[val][0] == 'true') {
          filter.deliveryRelated = true
        } else if (urlFilterValues[val][0] == 'false') {
          filter.deliveryRelated = false
        } else {
          filter.deliveryRelated = undefined
        }
        break
      case 'sort':
        if (urlFilterValues[val]) {
          filter.sort = urlFilterValues[val][0]
        } else {
          filter.sort = null
        }
        break
      case 'orderBy':
        if (urlFilterValues[val]) {
          filter.orderBy = urlFilterValues[val][0]
        } else {
          filter.orderBy = null
        }
        break
      case 'rdi':
        filter.rdi = urlFilterValues[val]
          .map((el) => {
            switch (el) {
              default:
                return el
            }
          })
          .flat(1)
        break
      default:
        break
    }
  })

  Object.keys(filter).forEach((key) => {
    if (Array.isArray(filter[key]) && filter[key].length === 0) {
      delete filter[key]
    }
  })

  return filter
}
