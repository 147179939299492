import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../../../ModalDialog'
import IconRenommerFilter from '../../../../assets/icons/navFilter/MaterialSymbolsDriveFileRenameOutlineOutlineSharp'
import TextField from '@mui/material/TextField'

function RenommerFilter({
  open,
  handleClose,
  payload,
  onRennomerFilter,
  isLoadingActionFilter,
  errorActionFilter,
  isActionFilter,
  resetActionFilter,
}) {
  const [nameFilter, setNameFilter] = useState(payload?.rename)

  const onConfirme = () => {
    const newPayload = {
      ...payload,
      rename: nameFilter,
    }
    onRennomerFilter(newPayload)
  }

  useEffect(() => {
    if (errorActionFilter) {
      handleClose()
    }
  }, [errorActionFilter])

  useEffect(() => {
    if (isActionFilter) {
      resetActionFilter()
      handleClose()
    }
  }, [isActionFilter])

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Modifier nom filter'}
      iconTitle={
        <IconRenommerFilter
          style={{
            width: '20px',
            height: '20px',
            marginRight: '10px',
          }}
        />
      }
      content={
        <div style={{ paddingRight: '16px', paddingBottom: '16px', width: '530px' }}>
          <div style={{ paddingBottom: '15px' }}>
            <span style={{ color: '#3C3C3C', fontSize: '13px' }}>Nom du filter</span>
          </div>
          <div style={{ paddingBottom: '5px' }}>
            <TextField
              id="nom-filter"
              variant="outlined"
              placeholder="Nom du filter"
              onChange={(event) => {
                setNameFilter(event.target.value)
              }}
              value={nameFilter}
            />
          </div>
        </div>
      }
      handleClose={handleClose}
      handleClickAction={onConfirme}
      disabled={nameFilter == payload?.rename || nameFilter.length == 0}
      isLoadingButtonAction={isLoadingActionFilter}
    />
  )
}
RenommerFilter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  payload: PropTypes.object,
  onRennomerFilter: PropTypes.func,
  isLoadingActionFilter: PropTypes.bool,
  errorActionFilter: PropTypes.string,
  isActionFilter: PropTypes.bool,
  resetActionFilter: PropTypes.func,
}

export default RenommerFilter
