import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '../../../../../../components/switch/SwitchComponent'
import { Divider } from '@mui/material'
import DirectionFilterIcon from '../../../../../../assets/icons/navFilter/DirectionFilterIcon'
export default function DiscussionDirectionFilter({
  filterStateChanged,
  directionFiltersValues,
  disabled,
}) {
  const [switches, setSwitches] = useState(
    directionFiltersValues ? directionFiltersValues?.map((e) => decodeURI(e)) : []
  )
  const onChecked = (event) => {
    const label = event.target.name
    const isChecked = event.target.checked
    if (isChecked) {
      setSwitches([label])
    } else {
      setSwitches(switches.filter((item) => item !== label))
    }
  }
  useEffect(() => {
    filterStateChanged('direction', [...switches])
  }, [switches])

  return (
    <div
      style={{
        paddingLeft: '16px',
        paddingRight: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#6D7B8E',
          fontSize: '12px',
          marginBottom: '5px',
        }}
      >
        <DirectionFilterIcon />
        {'Sens de discussion '}
      </div>
      <FormGroup colum sx={{ marginLeft: '5px' }}>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={switches?.includes('ENTREPOT')}
              onChange={onChecked}
              disabled={disabled}
              id={'Entrepôt'}
              name={'ENTREPOT'}
              value={switches.includes('ENTREPOT')}
            />
          }
          label={<span style={{ color: '#6D7B8E' }}>{'Entrepôt vers Magasin'}</span>}
        />
        <FormControlLabel
          control={
            <Switch
              defaultChecked={switches.includes('MAGASIN')}
              onChange={onChecked}
              disabled={disabled}
              id={'Magasin'}
              name={'MAGASIN'}
              value={switches.includes('MAGASIN')}
            />
          }
          label={<span style={{ color: '#6D7B8E' }}>{'Magasin vers Entrepôt'}</span>}
        />
      </FormGroup>
      <Divider sx={{ margin: '10px !important', width: '80%', height: '1px' }} />
    </div>
  )
}
DiscussionDirectionFilter.propTypes = {
  filterStateChanged: PropTypes.func,
  directionFiltersValues: PropTypes.array,
  disabled: PropTypes.bool,
}
