import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import TextField from '@mui/material/TextField'
import CreateIcon from '../../../../assets/icons/settingsCorner/iconsCreate'
import EditIcon from '../../../../assets/icons/settingsCorner/iconEdit'
import { keyCategory } from '../../../../utils/values'
import {
  StyledDivContent,
  StyledDivTopInputs,
  StyledInputModal,
  StyledInputTitle,
  sxInput,
} from '../../styled'
import { InputAdornment, MenuItem } from '@mui/material'

function Addkey({
  open,
  isEdit,
  handleClose,
  modalTitle,
  CreatNewKey,
  isLoadingKeys,
  success,
  errorMessage,
  UpdateKey,
  row,
  keyValue,
  resetKey,
}) {
  const UserId = String(JSON.parse(localStorage.getItem('currentUser'))?.id)
  const initalState = {
    value: keyValue ? keyValue : '',
    label: '',
    comment: '',
    userId: UserId,
    category: keyValue ? 'Competence' : 'SalesChanel',
  }
  const [key, setKey] = useState(initalState)

  useEffect(() => {
    if (isEdit) {
      setKey({
        ...key,
        value: row.value.substr(1),
        label: row.label,
        category: row.category,
        comment: row.comment,
        id: row.id,
      })
    } else {
      setKey(initalState)
    }
  }, [isEdit])

  const handleChange = (event) => {
    const { name, value } = event.target
    setKey((prevValues) => ({
      ...prevValues,
      [name]: name == 'value' ? value.toUpperCase() : value,
    }))
  }
  useEffect(() => {
    if (success) {
      handleClose()
      resetKey()
    }
  }, [success])

  const CreatNewKeyHandler = () => {
    if (isEdit) {
      UpdateKey(key)
    } else {
      CreatNewKey(key)
    }
  }

  const verifEdit = () => {
    if (
      isEdit &&
      key.value == row.value?.substr(1) &&
      key.label == row.label &&
      key.category == row.category &&
      key.comment == row.comment
    ) {
      return true
    }
    return false
  }

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={modalTitle}
      iconTitle={isEdit ? <EditIcon /> : <CreateIcon />}
      style={errorMessage ? { display: 'none' } : {}}
      content={
        <StyledDivContent>
          <StyledDivTopInputs>
            <StyledInputModal>
              <StyledInputTitle>Clé</StyledInputTitle>
              <TextField
                placeholder="#Clé"
                id="Cle-Value"
                name="value"
                variant="outlined"
                onChange={handleChange}
                value={key.value}
                InputProps={{
                  sx: sxInput,
                  startAdornment: key.value && (
                    <InputAdornment disabled position="end">
                      #
                    </InputAdornment>
                  ),
                }}
              />
            </StyledInputModal>
            <StyledInputModal>
              <StyledInputTitle>Label </StyledInputTitle>
              <TextField
                classes="Input"
                id="Cle-Label"
                name="label"
                variant="outlined"
                placeholder="label"
                onChange={handleChange}
                InputProps={{
                  sx: sxInput,
                }}
                value={key.label}
              />
            </StyledInputModal>
          </StyledDivTopInputs>
          <StyledInputModal style={{ marginBottom: '20px' }}>
            <StyledInputTitle disabled={keyValue}>Catégorie</StyledInputTitle>
            <TextField
              classes="Input"
              id="Cle-Categorie"
              name="category"
              variant="outlined"
              onChange={handleChange}
              InputProps={{
                sx: sxInput,
              }}
              value={key.category}
              select
              disabled={keyValue !== undefined}
            >
              {keyCategory.map((key, index) => (
                <MenuItem id={`Catégorie-${index}`} value={key.code} key={index}>
                  {key.label}
                </MenuItem>
              ))}
            </TextField>
          </StyledInputModal>
          <div>
            <StyledInputTitle>Commantaire </StyledInputTitle>{' '}
            <TextField
              id="Cle-Commantaire"
              name="comment"
              variant="outlined"
              multiline
              rows={4}
              onChange={handleChange}
              value={key.comment}
              InputProps={{
                sx: sxInput,
              }}
            />
          </div>
        </StyledDivContent>
      }
      handleClose={handleClose}
      handleClickAction={CreatNewKeyHandler}
      disabled={!key.value || !key.label || verifEdit()}
      isLoadingButtonAction={isLoadingKeys}
    />
  )
}
Addkey.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  keys: PropTypes.array,
  CreatNewKey: PropTypes.func,
  errorMessage: PropTypes.string,
  isEdit: PropTypes.bool,
  modalTitle: PropTypes.string,
  isLoadingKeys: PropTypes.bool,
  success: PropTypes.bool,
  UpdateKey: PropTypes.func,
  row: PropTypes.object,
  keyValue: PropTypes.string,
  resetKey: PropTypes.func,
  resetParticipantConfig: PropTypes.func,
}

export default Addkey
