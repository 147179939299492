import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Badge from '@mui/material/Badge'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import { NATURE_DISCUSSION_OBJECT } from '../../../../../utils/values'

export default function Conversation({
  conversationItem,
  getMessageListByConversationId,
  conversationSelect,
  index,
}) {
  let history = useHistory()

  const onClickOnConversation = (conversationItem) => {
    getMessageListByConversationId(conversationItem)
  }
  const getNotification = (conversationItem) => {
    return conversationItem.notSeen
  }
  const [showText, setShowText] = useState(false)
  const handleMouseEnter = () => {
    setShowText(true)
  }
  const handleMouseLeave = () => {
    setShowText(false)
  }

  const customFunction = (link) => {
    history.push(link)
  }
  return (
    <>
      <ListItem
        id={`${conversationItem.id}-${index}`}
        button
        onClick={(event) => {
          onClickOnConversation(conversationItem)
          event.preventDefault()
        }}
        sx={
          conversationSelect == conversationItem.id || showText
            ? {
                flexDirection: 'column',
                alignItems: 'flex-start',
                backgroundColor: '#1976D2 !important',
                color: 'white !important',
              }
            : {
                flexDirection: 'column',
                alignItems: 'flex-start',
              }
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            paddingTop: '8px',
            display: 'flex',
            alignSelf: 'flex-start',
            justifyContent: 'space-between',
            width: '100% ',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex' }}>
            <Link
              value={'/deliveries/' + conversationItem.ldv}
              to={'/deliveries/' + conversationItem.ldv}
              style={{
                textDecoration: 'unset',
                color: 'unset',
              }}
              onClick={(event) => {
                customFunction(`/deliveries/${conversationItem.ldv}`)
                event.stopPropagation()
              }}
            >
              <div
                style={{
                  fontSize: '16px',
                  paddingBottom: '4px',
                  paddingRight: '14px',
                  fontWeight: '700',
                  '&:hover': {
                    color: 'black ',
                  },
                }}
              >
                {conversationItem?.salesChannel
                  ? conversationItem?.ldv
                  : 'Discussion'}
              </div>
            </Link>
            <Badge
              badgeContent={getNotification(conversationItem)}
              color="primary"
              sx={
                conversationSelect == conversationItem.id || showText
                  ? {
                      '& .MuiBadge-colorPrimary': {
                        backgroundColor: 'white !important',
                        color: '#1976D2 !important',
                        fontWeight: 'bold !important',
                      },
                    }
                  : {
                      '& .MuiBadge-colorPrimary': {
                        backgroundColor: '#1976D2 !important',
                      },
                    }
              }
            />
          </div>
          {conversationItem.nature && (
            <div
              style={{
                color: '#1976d2',
                backgroundColor: '#D2EAFE',
                borderRadius: '8px',
                alignSelf: 'center',
                padding: '3px',
              }}
            >
              {NATURE_DISCUSSION_OBJECT[conversationItem.nature]}
            </div>
          )}
        </div>
        <div
          style={{
            fontSize: '14px',
            paddingBottom: '4px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            whiteSpace: 'nowrap',
          }}
        >
          {conversationItem?.salesChannel
            ? `Sujet: ${conversationItem.subject}`
            : 'Discussion'}
        </div>
        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
        >
          <div
            style={
              conversationSelect == conversationItem.id || showText
                ? {
                    color: 'white',
                    fontSize: '12px',
                    paddingBottom: '4px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '45%',
                  }
                : {
                    color: 'grey',
                    fontSize: '12px',
                    paddingBottom: '4px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '45%',
                  }
            }
          >
            {!conversationItem?.lastMessage
              ? 'discussion vide'
              : conversationItem.lastMessage.content}
          </div>
          <div
            style={
              conversationSelect == conversationItem.id || showText
                ? { color: 'white', paddingBottom: '4px', textAlign: 'right' }
                : { color: 'grey', paddingBottom: '4px', textAlign: 'right' }
            }
          >
            {moment(conversationItem.lastActionTime).format(
              ' Do MMMM YYYY, h:mm:ss a'
            )}
          </div>
        </div>
      </ListItem>
      <Divider />
    </>
  )
}

Conversation.propTypes = {
  conversationItem: PropTypes.object,
  getMessageListByConversationId: PropTypes.func,
  conversationSelect: PropTypes.string,
  index: PropTypes.number,
}
