import React, { useEffect } from 'react'
import Dialog from '../../../../../../../components/ModalDialog'
import PropTypes from 'prop-types'
import EditIcon from '../../../../../../../assets/icons/settingsCorner/editIcom'
import { TextField, Box } from '@mui/material'
import { useFormik } from 'formik'

const validate = (values) => {
  const errors = {}
  if (values.label == '' || values.label == null) {
    errors.label = 'Label est obligatoire'
  }

  if (values.poid == '' || values.poid == null) {
    errors.poid = 'Poid est obligatoire'
  }
  return errors
}

export default function DialogEditPackage({
  open,
  onEditPackage,
  onClose,
  payloadEditePackage,
  isLoadingEditPackage,
  errorMessage,
}) {
  const formik = useFormik({
    initialValues: {
      label: '',
      poid: 0,
    },
    validate,
    onSubmit: (values) => {
      const data = {
        label: values.label,
        poid: values.poid,
      }

      onEditPackage(data, payloadEditePackage.packageId)
    },
  })
  console.log('errors===>', formik.values, formik.errors)

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      label: payloadEditePackage.label,
      poid: payloadEditePackage.weight,
    })
  }, [payloadEditePackage])

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Dialog
        maxWidthDialog={'md'}
        open={open}
        title={`Modifier le colis ${payloadEditePackage.barcode}`}
        style={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '646px',
            },
            display: errorMessage && 'none',
          },
        }}
        iconTitle={
          <EditIcon
            style={{
              alignSelf: 'center',
              marginRight: '5px',
              width: '1.7em',
              height: '1.7em',
            }}
          />
        }
        content={
          <Box
            sx={{
              width: '600px',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              margin: '20px 20px 20px 5px',

              '@media (max-width: 710px)': {
                width: 'unset',
              },
            }}
          >
            <Box sx={{ marginBottom: '10px' }}>
              <Box sx={{ marginBottom: '10px', fontWeight: '600' }}>Label</Box>
              <TextField
                placeholder="Label"
                id="Package-Label"
                name="label"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.label}
                error={formik.errors.label && formik.touched.label}
                helperText={
                  formik?.errors?.label && formik.touched.label
                    ? formik.errors.label
                    : null
                }
              />
            </Box>
            <Box sx={{ marginBottom: '10px' }}>
              <Box sx={{ paddingBottom: '10px', fontWeight: '600' }}>Poid (Kg)</Box>
              <TextField
                id="Package-Poids"
                placeholder="Poids"
                name="poid"
                variant="outlined"
                onKeyPress={(event) => {
                  if (
                    !/^[0-9]/.test(event.key) ||
                    (event.key == 0 && event.target.value <= 0)
                  ) {
                    event.preventDefault()
                  }
                }}
                onChange={formik.handleChange}
                value={formik.values.poid}
                error={formik.errors.poid && formik.touched.poid}
                helperText={
                  formik?.errors?.poid && formik.touched.poid
                    ? formik.errors.poid
                    : null
                }
              />
            </Box>
          </Box>
        }
        handleClose={onClose}
        handleClickAction={formik.handleSubmit}
        disabled={isLoadingEditPackage}
        isLoadingButtonAction={isLoadingEditPackage}
      />
    </form>
  )
}
DialogEditPackage.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onEditPackage: PropTypes.func,
  payloadEditePackage: PropTypes.object,
  isLoadingEditPackage: PropTypes.bool,
  errorMessage: PropTypes.string,
}
