import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SOURCE } from '../../constants/appContants'
import Dialog from '../ModalDialog'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import { CANCELED_STATUS } from '../../utils/values'
export default function CancelShipment({
  open,
  handleClose,
  getShipmentCancelReasons,
  reasons,
  cancelShipment,
  orderAnnuler: { shipmentReference, codeCourse, courseSource },
  payload,
  isLoadingReasonsCancel,
  isLoadingActionOrder,
  errorActionCourse,
}) {
  const [codeAnnulation, setValue] = useState('')

  useEffect(() => {
    const payload = {
      type:
        courseSource === SOURCE.MELTING_POINT ? SOURCE.MELTING_POINT : SOURCE.CORNER,
      status: CANCELED_STATUS.code,
    }
    getShipmentCancelReasons(payload)
  }, [])

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const cancelshipment = () => {
    const motifAnnulation = reasons.find(
      (element) => element.code === codeAnnulation
    ).motif

    if (motifAnnulation && codeCourse) {
      cancelShipment({
        ...payload,
        codeAnnulation,
        reasons: codeAnnulation,
        motifAnnulation,
      })
    }
  }

  useEffect(() => {
    if (errorActionCourse) handleClose()
  }, [errorActionCourse])

  return (
    <Dialog
      maxWidthDialog={'sm'}
      open={open}
      title={`Annulation de la course ${shipmentReference}`}
      content={
        <>
          <div
            style={{
              letterSpacing: '0.00938em',
              marginLeft: '8px',
              paddingBottom: '15px',
            }}
          >
            Quelle est la raison?
          </div>
          {isLoadingReasonsCancel ? (
            <div
              style={{
                display: 'flex ',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                minWidth: '530px',
                paddingBottom: '16px',
              }}
            >
              <CircularProgress
                color="primary"
                sx={{
                  width: '30px',
                  height: '30px',
                }}
              />
            </div>
          ) : (
            <div
              style={{
                overflowY: 'auto ',
                padding: '2px ',
              }}
            >
              <FormControl component="fieldset" sx={{ p: 'revert' }}>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={codeAnnulation}
                  onChange={handleChange}
                  id={'Motifs-Group'}
                >
                  {reasons.map((reason, index) => (
                    <FormControlLabel
                      value={reason.code}
                      control={<Radio id={`Motif-${index}`} />}
                      label={`${reason.code}-${reason.motif}`}
                      key={index}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          )}
        </>
      }
      handleClose={handleClose}
      handleClickAction={cancelshipment}
      disabled={
        isLoadingReasonsCancel || reasons?.length == 0 || codeAnnulation?.length == 0
      }
      isLoadingButtonAction={isLoadingActionOrder}
    />
  )
}
CancelShipment.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  getShipmentCancelReasons: PropTypes.func,
  cancelShipment: PropTypes.func,
  reasons: PropTypes.array,
  orderAnnuler: PropTypes.object,
  payload: PropTypes.object,
  isLoadingReasonsCancel: PropTypes.bool,
  isLoadingActionOrder: PropTypes.bool,
  errorActionCourse: PropTypes.string,
}
