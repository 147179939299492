import { connect } from 'react-redux'
import DeleteFilter from '../../../components/NavFilter/components/OngletFilter/deleteFilter'

import { onDeleteFilter } from '../../../redux/filter/actions'
import {
  getIsLoadingActionFilter,
  getErrorActionFilter,
  getIsActionDeleteFilter,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionFilter: getIsLoadingActionFilter(state),
  errorActionFilter: getErrorActionFilter(state),
  isActionDeleteFilter: getIsActionDeleteFilter(state),
})

const mapDisptachToProps = (dispatch) => ({
  onDeleteFilter: (payload) => dispatch(onDeleteFilter(payload)),
})

export default connect(mapStateToProps, mapDisptachToProps)(DeleteFilter)
