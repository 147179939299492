import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../components/ModalDialog'

import FilesList from './FilesList'

export default function FilesCourse({
  show,
  handleModal,
  viewFiles,
  downloadFiles,
  isLoadingFiles,
  ...shipment
}) {
  return (
    <Dialog
      open={show}
      title={'Consulter / Télécharger'}
      content={
        <div style={{ paddingBottom: '16px', paddingRight: '16px' }}>
          <FilesList
            viewFiles={viewFiles}
            downloadFiles={downloadFiles}
            shipment={shipment}
            isLoadingFiles={isLoadingFiles}
          />
        </div>
      }
      handleClose={handleModal}
      maxWidthDialog={'xs'}
      isClosedIcon={true}
      isClosedIconLoading={isLoadingFiles}
    />
  )
}
FilesCourse.propTypes = {
  handleModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
  shipment: PropTypes.object,
  viewFiles: PropTypes.func,
  downloadFiles: PropTypes.func,
  isLoadingFiles: PropTypes.bool,
}
