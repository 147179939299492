import React from 'react'

export function GridiconsCreate(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ alignSelf: 'center', marginRight: '5px' }}
      width="1.7em"
      height="1.7em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#ffffff"
        d="M21 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5v2H5v14h14v-5h2z"
      ></path>
      <path fill="#ffffff" d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4z"></path>
    </svg>
  )
}
export default GridiconsCreate
