import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import PrestationPopHover from './PrestationPopHover'

export default function PrestationsList({
  getAllPrestations,
  prestations,
  filterStateChanged,
  prestationFiltersValues,
}) {
  const [checked, setChecked] = React.useState([])
  useEffect(() => {
    getAllPrestations()
  }, [getAllPrestations])
  useEffect(() => {
    let list = prestations.map(({ id, label }) => {
      return {
        code: id + '',
        name: label,
      }
    })
    setChecked(list)
  }, [prestations])

  const onPrestationChanged = (selectedPrestation) => {
    filterStateChanged(
      'prestation',
      selectedPrestation?.length < checked.length ? selectedPrestation : []
    )
  }

  return (
    <PrestationPopHover
      onDataChanged={onPrestationChanged}
      defaultLabel={'Prestations'}
      dataList={checked}
      withExtraWidth={true}
      filtersValues={prestationFiltersValues}
      isDefaultSpecial={true}
      nameFilter={'prestation'}
    />
  )
}
PrestationsList.propTypes = {
  getAllPrestations: PropTypes.func,
  prestations: PropTypes.array,
  filterStateChanged: PropTypes.func,
  prestationFiltersValues: PropTypes.array,
}
