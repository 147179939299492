import React from 'react'
import ErrorPage from './../../assets/images/error-page.svg'
import PropTypes from 'prop-types'
import { axiosHTTP } from '../../utils'
const style = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  position: 'absolute',
}
const api = process.env.REACT_APP_API_URL

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log(`${error}`)
    axiosHTTP.post(`${api}log`, { text: `${error}` })
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service

    console.log(`${error}`)

    axiosHTTP.post(`${api}log`, {
      text: ` Error:  ${error}  Trace: ${JSON.stringify(errorInfo)}  `,
    })
    this.props.resetAllFilter()

    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <img src={ErrorPage} style={style} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
ErrorBoundary.propTypes = {
  children: PropTypes.any,
  resetAllFilter: PropTypes.func,
}
