import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import Dialog from '../../../../../../../../components/ModalDialog'

import PictureIcon from '../../../../../../../../assets/icons/detailsLivraison/IconParkOutlinePicture'

import IconButton from '@mui/material/IconButton'
import IconLitigeImageAgrandi from '../../../../../../../../assets/icons/detailsLivraison/agrandir.svg'
import CircularProgress from '@mui/material/CircularProgress'
import Galery from '../../../../../../../../components/galery'
import { Box } from '@mui/material'

export default function DialogPhotos({
  open,
  onClose,
  idPackageTraceability,
  photosHistoriquePackage,
  isLoadingPhotosHistoriquePackage,
  getPhotosHistoriquePackage,
}) {
  const [lightbox, setLightbox] = useState([])
  const [lightboxIsopen, setLightboxIsopen] = useState(false)
  const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState(0)
  useEffect(() => {
    if (idPackageTraceability) {
      getPhotosHistoriquePackage(idPackageTraceability)
    }
  }, [idPackageTraceability])
  useEffect(() => {
    if (photosHistoriquePackage?.length > 0) {
      setLightbox(photosHistoriquePackage.filter(({ url }) => url))
    }
  }, [photosHistoriquePackage])

  const onCloseGalery = () => {
    setLightboxIsopen(false)
  }

  return (
    <>
      {lightboxIsopen ? (
        <Galery
          lightboxIsopen={lightboxIsopen}
          lightbox={lightbox}
          onCloseGalery={onCloseGalery}
          lightboxPhotoIndex={lightboxPhotoIndex}
          setLightboxPhotoIndex={setLightboxPhotoIndex}
        />
      ) : (
        <Dialog
          maxWidthDialog={'xs'}
          open={open}
          title={idPackageTraceability.title}
          iconTitle={
            <PictureIcon
              style={{ fontSize: '20px', marginRight: '10px', color: '#ffff' }}
            />
          }
          content={
            <>
              {isLoadingPhotosHistoriquePackage ? (
                <Box
                  sx={{
                    width: '250px',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <CircularProgress color="primary" />
                </Box>
              ) : (
                <Box sx={{ paddingBottom: '16px', minWidth: '250px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    {photosHistoriquePackage &&
                    photosHistoriquePackage.length > 0 ? (
                      photosHistoriquePackage.map((image, index) => (
                        <Box
                          key={index}
                          sx={
                            photosHistoriquePackage.length == 1
                              ? {
                                  background: '#FAFAFA',
                                  borderRadius: '10px',
                                  display: 'grid',
                                  placeItems: 'center',
                                  marginRight: '15px',
                                  position: 'relative',
                                }
                              : {
                                  width: '115px',
                                  background: '#FAFAFA',
                                  borderRadius: '10px',
                                  display: 'grid',
                                  placeItems: 'center',
                                  marginRight: '15px',
                                  position: 'relative',
                                  marginBottom: '15px',
                                }
                          }
                        >
                          <img
                            src={image.url}
                            style={{
                              width: '115px',
                              height: '100%',
                              borderRadius: '10px',
                            }}
                          />
                          <Box
                            sx={{
                              width: '100%',
                              cursor: 'pointer',
                              height: '100%',
                              position: 'absolute',
                              display: 'grid',
                              placeItems: 'end',
                              opacity: '0',
                              padding: '4px',
                              '&:hover': {
                                opacity: '1',
                                backgroundColor:
                                  'hsla(0,0%,97.6%,.4117647058823529)',
                                transition: '0.5s',
                              },
                            }}
                          >
                            <IconButton
                              aria-label="close"
                              sx={{
                                width: '37px',
                                cursor: 'pointer',
                                height: '37px',
                                opacity: '0.7',
                                background: '#2B404A 0% 0% no-repeat padding-box',
                                color: 'white',
                              }}
                              onClick={(event) => {
                                setLightboxPhotoIndex(index)
                                setLightboxIsopen(true)
                                event.stopPropagation()
                              }}
                              id={`galery-${index}`}
                            >
                              <img src={IconLitigeImageAgrandi} />
                            </IconButton>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box
                        sx={{
                          padding: '60px 100px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {'Pas de Photos'}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </>
          }
          handleClose={onClose}
          isClosedIcon={true}
        />
      )}
    </>
  )
}
DialogPhotos.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  photos: PropTypes.array,
  isPhotoAvant: PropTypes.bool,
  idPackageTraceability: PropTypes.object,
  photosHistoriquePackage: PropTypes.array,
  isLoadingPhotosHistoriquePackage: PropTypes.bool,
  getPhotosHistoriquePackage: PropTypes.func,
}
