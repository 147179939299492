import React from 'react'
import PropTypes from 'prop-types'

import TableRow from '@mui/material/TableRow'
import LongTextComponent from './LongTextComponent'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell/TableCell'

export default function TableContent({
  firstname,
  lastname,
  phone,
  vehicule,
  operationalHours,
  columns,
  getChecked,
  checked,
  id,
  handleCheck,
  index,
}) {
  const getoperationalHours = () => {
    return operationalHours?.closeTime && operationalHours?.openTime
      ? `${operationalHours.openTime} ~ ${operationalHours.closeTime}`
      : 'N/A'
  }
  const rowContent = {
    id: <LongTextComponent text={id} key={1} display={getChecked('id')} />,
    prenom: (
      <LongTextComponent text={firstname} key={31} display={getChecked('prenom')} />
    ),
    nom: <LongTextComponent text={lastname} key={2} display={getChecked('nom')} />,
    phone: <LongTextComponent text={phone} key={3} display={getChecked('phone')} />,
    volume: (
      <LongTextComponent
        text={
          vehicule?.vehicule_category?.volumeMax !== null &&
          vehicule?.vehicule_category?.volumeMax !== undefined
            ? vehicule.vehicule_category.volumeMax
            : 'N/A'
        }
        key={4}
        display={getChecked('volume')}
      />
    ),
    poids: (
      <LongTextComponent
        text={
          vehicule?.vehicule_category?.weight !== null &&
          vehicule?.vehicule_category?.weight !== undefined
            ? vehicule.vehicule_category.weight
            : 'N/A'
        }
        key={5}
        display={getChecked('poids')}
      />
    ),
    tempsTravail: (
      <LongTextComponent
        text={getoperationalHours()}
        key={5}
        display={getChecked('tempsTravail')}
      />
    ),
  }

  return (
    <TableRow hover role="checkbox" tabIndex={-1}>
      <TableCell>
        <Checkbox
          checked={checked}
          onChange={() => handleCheck(id, checked)}
          component={'td'}
          id={`checkbox-drivers-table-${index}`}
        />
      </TableCell>
      {columns.map((el) => {
        return rowContent[el.id]
      })}
      <TableCell />
    </TableRow>
  )
}
TableContent.propTypes = {
  drivers: PropTypes.array,
  key: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  phone: PropTypes.string,
  vehicule: PropTypes.object,
  getChecked: PropTypes.func,
  columns: PropTypes.array,
  checked: PropTypes.bool,
  id: PropTypes.number,
  handleCheck: PropTypes.func,
  operationalHours: PropTypes.object,
  index: PropTypes.number,
}
