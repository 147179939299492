import { connect } from 'react-redux'
import RecapSection from '../../../pages/main/PlaceOrders/Recap'

import {
  getOrdersState,
  getActiveStepState,
  getShipmentTotalPriceState,
} from '../../../redux/shipment/selectors'
import {
  addAssemblyAction,
  removeOrderAssemblyAction,
  addShipment,
  removeShipment,
} from '../../../redux/shipment/actions'
import { resetDirections } from '../../../redux/googleMapDirections/actions'

const mapStateToProps = (state) => ({
  orders: getOrdersState(state),
  activeStep: getActiveStepState(state),
  shipmentTotalPrice: getShipmentTotalPriceState(state),
})

const mapDisptachToProps = (dispatch) => ({
  setIsAssemblyActive: (orderId, isActive) => {
    isActive
      ? dispatch(addAssemblyAction(orderId, isActive))
      : dispatch(removeOrderAssemblyAction(orderId))
  },

  setIsShipmentyActive: (orderId, isActive) => {
    isActive
      ? dispatch(addShipment(orderId, isActive))
      : dispatch(removeShipment(orderId))
    dispatch(resetDirections())
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(RecapSection)
