import {
  GET_VEHICULES,
  PAGE_CHANGED_VEHICULES,
  PAGE_LIMIT_CHANGED_VEHICULES,
  GET_VEHICULES_MORE,
  REMOVE_ERROR_VEHICULES,
  RESET_SUCCESS_ACTION_VEHICULES,
  RESET_CONFIG_VEHICULES,
  SEARCH_VEHICULES,
  ADD_VEHICULE,
  UPDATE_VEHICULE,
  DELETE_VEHICULE,
} from './constants'

export const fetchVehicules = () => ({
  type: GET_VEHICULES,
})

export const getVehiculesMore = () => ({
  type: GET_VEHICULES_MORE,
})

export const pageChangedVehicules = (offset) => ({
  type: PAGE_CHANGED_VEHICULES,
  payload: { offset },
})

export const pageLimitChangedVehicules = (limit) => ({
  type: PAGE_LIMIT_CHANGED_VEHICULES,
  payload: { limit },
})

export const removeError = () => ({
  type: REMOVE_ERROR_VEHICULES,
})

export const resetSuccessAction = () => ({
  type: RESET_SUCCESS_ACTION_VEHICULES,
})

export const resetVehiculeConfig = () => ({
  type: RESET_CONFIG_VEHICULES,
})

export const onSearchVehicules = (search) => ({
  type: SEARCH_VEHICULES,
  payload: { search },
})

export const createVehicule = (vehicule) => ({
  type: ADD_VEHICULE,
  payload: { vehicule },
})

export const updateVehicule = (newVehicule) => ({
  type: UPDATE_VEHICULE,
  payload: { newVehicule },
})

export const deleteVehicule = (id) => ({
  type: DELETE_VEHICULE,
  payload: { id },
})
