import { connect } from 'react-redux'

import GestionSessions from '../../../pages/settings/sessions'
import {
  fetchSessions,
  onSearch,
  pageChanged,
  pageLimitChanged,
  resetSessionConfig,
  updateSession,
  removeError,
  resetSuccessAction,
  fetchSessionById,
} from '../../../redux/sessions/actions'
import {
  getSessions,
  getSessionsCount,
  getSessionsListIsLoading,
  getErrorMessage,
  getIsErrorFetch,
  getIsSuccessRefresh,
  getSuccess,
} from '../../../redux/sessions/selectors'

const mapStateToProps = (state) => ({
  isLoadingSessionsList: getSessionsListIsLoading(state),
  sessions: getSessions(state),
  sessionsCount: getSessionsCount(state),
  errorMessage: getErrorMessage(state),
  isErrorFetch: getIsErrorFetch(state),
  isSuccessRefresh: getIsSuccessRefresh(state),
  success: getSuccess(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchSessions()),
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(fetchSessions())
  },

  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(fetchSessions())
  },
  removeError: () => dispatch(removeError()),
  resetSuccessAction: () => dispatch(resetSuccessAction()),
  onCloseReset: () => dispatch(resetSessionConfig()),

  searchData: (search) => {
    dispatch(onSearch(search))
    dispatch(fetchSessions())
  },
  updateSession: (data) => {
    dispatch(updateSession(data))
  },
  fetchSessionById: (id) => {
    dispatch(fetchSessionById(id))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(GestionSessions)
