import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const StyledPaginationContainer = styled('div')({
  width: '100%',
  bottom: 0,
  position: 'sticky',
  '& > * + *': {
    marginTop: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  backgroundColor: '#F5F5F5',
  padding: 20,
  flexWrap: 'wrap',
  overflowY: 'scroll',
  maxHeight: '90px',
})

export const StyledText = styled(Typography)({
  margin: 'auto 0 !important',
  paddingLeft: 5,
  fontSize: 12,
  paddingTop: 0,
})
