export const getFormationsState = ({ formations }) => formations

export const getFormationsListIsLoading = (state) =>
  getFormationsState(state).isLoadingFormationsList
export const getFormations = (state) => getFormationsState(state).formations
export const getFormationsCount = (state) =>
  getFormationsState(state).formationsCount
export const getErrorMessage = (state) => getFormationsState(state).errorMessage
export const getIsErrorFetch = (state) => getFormationsState(state).isErrorFetch

export const getIsSuccessRefresh = (state) =>
  getFormationsState(state).isSuccessRefresh

export const getSuccess = (state) => getFormationsState(state).success

export const getisLoadingFormationAction = (state) =>
  getFormationsState(state).isLoadingFormationAction

export const getFiles = (state) => getFormationsState(state).files

export const getIsLoadingFiles = (state) => getFormationsState(state).isLoadingFiles

export const getFormationById = (state) => getFormationsState(state).formation

export const getIsLoadingFormation = (state) =>
  getFormationsState(state).isLoadingFormation
