export default () => {
  return {
    filter: {
      limit: 25,
      offset: 0,
    },
    sessions: [],
    session: {},
    isLoadingSession: false,
    sessionsCount: 0,
    isLoadingSessionsList: false,
    errorMessage: null,
    isErrorFetch: false,

    isLoadingSessionsAction: false,
    success: false,
    isSuccessRefresh: false,

    sessionsDetails: null,
    isLoadingSessionsDetails: false,

    sessionsParticipants: [],
    isLoadingSessionsParticipants: false,

    isErrorFetchDetails: false,
    isErrorFetchParticipant: false,
  }
}
