import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../ModalDialog'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import UpdateWarehouseIcon from '../../assets/icons/detailsLivraison/updateWarehouse'
import EntrepotIcon from '../../assets/icons/detailsLivraison/EntropotIcon'
import ArrowIcon from '../../assets/icons/detailsLivraison/ArrowRight'
import InputAdornment from '@mui/material/InputAdornment'

function UpdateWarehouse({
  open,
  handleClose,
  warehouses,
  getWareHouses,
  warehousePackage,
  onUpdateWarehouse,
  codeCourse,
  isLoadingActionOrder,
  errorActionCourse,
}) {
  const [warehouse, setWarehouse] = useState({ label: 'null' })

  const handleWareHouseChnage = (warehouse) => {
    setWarehouse(warehouse)
  }
  useEffect(() => {
    getWareHouses()
  }, [])
  const onChangeUpdateWarehouse = () => {
    onUpdateWarehouse({ code: codeCourse, warehouse: warehouse?.id })
  }

  useEffect(() => {
    if (errorActionCourse) handleClose()
  }, [errorActionCourse])

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Affecter à une autre agence'}
      iconTitle={<UpdateWarehouseIcon />}
      content={
        <div
          style={{
            width: '600px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            padding: '15px 24px',
            flexWrap: 'wrap',
            '@media (max-width: 680px)': {
              width: 'unset',
            },
          }}
        >
          <div
            style={{
              width: '45%',
              padding: '10px 0px',
            }}
          >
            <div
              style={{
                paddingBottom: '10px',
                fontWeight: 'bold',
              }}
            >
              <span>
                <EntrepotIcon />
              </span>{' '}
              Entrepôt Actuel
            </div>
            <div>
              <TextField
                id="Listes-actuel-Entrepot"
                disabled={true}
                name="Listes-Entrepot"
                variant="outlined"
                value={warehousePackage}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#1876D2',
                    backgroundColor: 'rgba(24, 118, 210, 0.03)',
                  },
                }}
                InputProps={{
                  sx: {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E2F0FE !important',
                    },
                  },
                }}
              />
            </div>
          </div>
          <div
            style={{
              paddingTop: '60px',
              paddingLeft: '10px',
              paddingRight: '10px',
              fontWeight: 'bold',
            }}
          >
            <ArrowIcon />
          </div>
          <div
            style={{
              width: '45%',
              padding: '10px 0px',
            }}
          >
            <div
              style={{
                paddingBottom: '10px',
                fontWeight: 'bold',
              }}
            >
              <span>
                <EntrepotIcon />
              </span>{' '}
              Nouveau Entrepôt
            </div>
            <div>
              <TextField
                id="Listes-nouveau-entrepot"
                select
                name="Listes-wareHouses"
                variant="outlined"
                placeholder="Entrepôt"
                onChange={(e) => handleWareHouseChnage(e.target.value)}
                value={warehouse}
                SelectProps={{
                  MenuProps: {
                    style: { height: 288 },
                  },
                }}
                InputProps={{
                  sx: {
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E2F0FE !important',
                    },
                  },

                  startAdornment: warehouse?.label == 'null' && (
                    <InputAdornment position="start">Entrepôt</InputAdornment>
                  ),
                }}
              >
                {warehouses.map((h, index) => (
                  <MenuItem value={h} key={index + 1}>
                    {h.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
      }
      handleClose={handleClose}
      handleClickAction={onChangeUpdateWarehouse}
      disabled={warehouse?.label == 'null'}
      isLoadingButtonAction={isLoadingActionOrder}
    />
  )
}
UpdateWarehouse.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  codeCourse: PropTypes.string,
  warehouses: PropTypes.array,
  getWareHouses: PropTypes.func,
  warehousePackage: PropTypes.string,
  onUpdateWarehouse: PropTypes.func,
  isLoadingActionOrder: PropTypes.bool,
  errorActionCourse: PropTypes.string,
}

export default UpdateWarehouse
