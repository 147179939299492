import { connect } from 'react-redux'
import DetailsDelivery from '../../../pages/main/Orders/DetailsDelivery'

import {
  getError,
  getOrder,
  getIsLoading,
  getErrorActionCourse,
  getisActionOrderDone,
  getIsLoadingActionOrder,
  getDuplicatedCourse,
} from '../../../redux/order/selectors'
import {
  getOrderByLdv,
  resetOrderDetails,
  removeErrorAction,
  resetOrderAction,
  getCourseDuplicated,
} from '../../../redux/order/actions'
import {
  getIsViewPlannification,
  isTourActionsSimulate,
} from '../../../redux/simulateTournee/selectors'
import {
  resetTourAction,
  viewPlannification,
} from '../../../redux/simulateTournee/actions'

import { resetOrderPackage } from '../../../redux/orderPackage/actions'

const mapStateToProps = (state) => ({
  order: getOrder(state),
  error: getError(state),
  isLoading: getIsLoading(state),
  isActionShipmentDone: getisActionOrderDone(state),
  isViewPlannification: getIsViewPlannification(state),
  errorActionCourse: getErrorActionCourse(state),
  tourAction: isTourActionsSimulate(state),
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  duplicatedCourse: getDuplicatedCourse(state),
})
const mapDisptachToProps = (dispatch) => ({
  getOrderByLdv: (ldv) => dispatch(getOrderByLdv(ldv)),
  resetOrderDetails: () => dispatch(resetOrderDetails()),
  viewPlannification: (isViewPlannif) => dispatch(viewPlannification(isViewPlannif)),
  removeErrorAction: () => dispatch(removeErrorAction()),
  resetOrderPackage: () => dispatch(resetOrderPackage()),
  resetOrderAction: () => dispatch(resetOrderAction()),
  resetTourAction: () => dispatch(resetTourAction()),
  getCourseDuplicated: (payload) => dispatch(getCourseDuplicated(payload)),
})

export default connect(mapStateToProps, mapDisptachToProps)(DetailsDelivery)
