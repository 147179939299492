import React, { useState } from 'react'

import PropTypes from 'prop-types'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '../../../../../../components/switch/SwitchComponent'

export default function ToursFilter({ filterStateChanged, toursFiltersValues }) {
  const [checked, setChecked] = useState(
    toursFiltersValues?.length > 0 ? toursFiltersValues[0] == true : false
  )
  const onChecked = (event) => {
    setChecked(event.target.checked)
    filterStateChanged('tours', [
      !(toursFiltersValues ? toursFiltersValues[0] == true : false),
    ])
  }
  return (
    <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              value={checked}
              defaultChecked={
                toursFiltersValues ? toursFiltersValues[0] == true : false
              }
              onChange={onChecked}
              id={'Tours'}
            />
          }
          label={<span style={{ color: '#6D7B8E' }}>{'Sans tournée'}</span>}
        />
      </FormGroup>
    </div>
  )
}
ToursFilter.propTypes = {
  filterStateChanged: PropTypes.func,
  toursFiltersValues: PropTypes.array,
}
