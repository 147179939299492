import { connect } from 'react-redux'
import Step from '../../../pages/main/EditOrders/Step'

import {
  getActiveStepState,
  getTotalPriceState,
} from '../../../redux/shipment/selectors'
import { getCodePromoVerification } from '../../../redux/shipment/selectors'

const mapStateToProps = (state) => ({
  activeStep: getActiveStepState(state),
  totalPrice: getTotalPriceState(state),
  codePromotion: getCodePromoVerification(state),
})

export default connect(mapStateToProps, null)(Step)
