import React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItemsComponent from '../listItemComponent'
import { styled } from '@mui/material/styles'

export const StyledList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  overflow: 'auto',
  overflowX: 'hidden',
  maxHeight: 'calc(100vh - 335px)',
}))
export const StyledFilterListContainer = styled('div')(() => ({
  marginLeft: '1px',
  '& .MuiList-padding': {
    paddingTop: '0',
  },
}))

const FilterItemsList = ({
  dataList,
  handleToggle,
  handleDoubleClick,
  fetchMoreData,
  isSelected,
}) => {
  return (
    <StyledFilterListContainer>
      <StyledList dense onScroll={fetchMoreData}>
        <ListItemsComponent
          data={dataList}
          handleToggle={handleToggle}
          handleDoubleClick={handleDoubleClick}
          isSelected={isSelected}
        />
      </StyledList>
    </StyledFilterListContainer>
  )
}
FilterItemsList.propTypes = {
  handleToggle: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  dataList: PropTypes.array,
  fetchMoreData: PropTypes.func,
  numSelected: PropTypes.bool,
  isSelected: PropTypes.func,
}
export default FilterItemsList
