import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { DatePickerWeek } from '../../../components/DatePickerWeek'
import AutoComplete from '../../../components/AutoComplete'
import ModalError from '../../../components/ModalError'
import { StyledContainer, sxInput } from '../styled'

import { Box, Typography } from '@mui/material'

import TableDisponibilite from './table'
import DisponibilitesModal from '../../../containers/settings/disponibilites/disponibilitesModal'

const GestionDisponibilites = ({
  fetchCanalVentes,
  canalVenteList,
  canalVentesCount,
  isLoadingCanalVenteList,
  fetchMoreCanalVentes,

  fetchCanalVenteDetails,
  canalVenteDetails,
  isLoadingCanalVenteDetails,

  errorMessage,
  isErrorFetchCanalList,
  isErrorFetchCanalDetails,
  removeErrorDisponibilites,

  success,
  resetSuccessAction,
}) => {
  const [week, setWeek] = useState({
    firstDay: new Date(),
  })
  const [searchCanalVente, setSearchCanalVente] = useState('')
  const [canalVente, setCanalVente] = useState(null)
  const [offset, setOffset] = useState(1)
  const [select, setSelect] = useState(false)

  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [init, setInit] = useState(false)

  //Change week
  const onChange = (week) => {
    setWeek(week)
  }

  //Fetch Canal Ventes
  useEffect(() => {
    if (!init && week?.firstDay && week?.lastDay) {
      const filtre = {
        channelLabel: '',
        start: moment(week.firstDay).format('YYYY-MM-DD'),
        end: moment(week.lastDay).format('YYYY-MM-DD'),
        offset: 1,
        limit: 15,
      }
      fetchCanalVentes(filtre)
      setOffset(2)
      setInit(true)
    }
  }, [week])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchCanalVente.length > 3 && !select) {
        const filtre = {
          channelLabel: searchCanalVente,
          start: moment(week.firstDay).format('YYYY-MM-DD'),
          end: moment(week.lastDay).format('YYYY-MM-DD'),
          offset: 1,
          limit: 15,
        }
        fetchCanalVentes(filtre)
        setOffset(2)
      } else if (searchCanalVente == '' && init) {
        const filtre = {
          channelLabel: '',
          start: moment(week.firstDay).format('YYYY-MM-DD'),
          end: moment(week.lastDay).format('YYYY-MM-DD'),
          offset: 1,
          limit: 15,
        }
        fetchCanalVentes(filtre)
        setOffset(2)
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchCanalVente])

  const onSelectCanalVente = (value) => {
    if (value) {
      setCanalVente(value)
      setSelect(true)
    }
  }

  const onChangeSearchCanalVente = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchCanalVente(val)
    } else {
      setCanalVente(null)
      setSearchCanalVente('')
      setSelect(false)
    }
  }

  const loadMoreCanalVentes = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      canalVentesCount > canalVenteList.length
    ) {
      const filtre = {
        channelLabel: '',
        offset: offset,
        limit: 15,
      }
      fetchMoreCanalVentes(filtre)
      setOffset(offset + 1)
    }
  }

  //Fetch Canal Vente details
  useEffect(() => {
    if (canalVente && week?.firstDay && week?.lastDay) {
      const data = {
        start: moment(week.firstDay).format('YYYY-MM-DD'),
        end: moment(week.lastDay).format('YYYY-MM-DD'),
        channelKey: canalVente.channelKey,
        fullDetails: true,
        forCorner: true,
      }
      fetchCanalVenteDetails(data)
    }
  }, [week, canalVente])

  //Open dialog error
  useEffect(() => {
    if (errorMessage) {
      setOpenErrorDialog(true)
    }
  }, [errorMessage])

  //Dialog Creation/Modification
  const [open, setOpen] = useState(false)
  const handleCloseDialog = () => {
    setOpen(false)
  }

  //Creation/Modification
  useEffect(() => {
    if (success) {
      resetSuccessAction()
      const data = {
        start: moment(week.firstDay).format('YYYY-MM-DD'),
        end: moment(week.lastDay).format('YYYY-MM-DD'),
        channelKey: canalVente.channelKey,
        fullDetails: true,
        forCorner: true,
      }
      fetchCanalVenteDetails(data)
    }
  }, [success])

  return (
    <StyledContainer>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '24px',
            paddingTop: '22px',
          }}
        >
          <Box sx={{ margin: 0 }}>
            <Typography
              sx={{
                lineHeight: '1.5',
                letterSpacing: '0.00938em',
                fontSize: '18px',
              }}
              variant="h1"
            >
              Gestion des disponibilités
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '100%',
            overflow: 'auto',
            height: 'calc(100vh - 172px)',
            padding: '24px',
          }}
        >
          <Box
            sx={{
              border: '1px solid #EBEDF0',
              borderRadius: '4px',
              width: '100%',
              height: 'fit-content',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                padding: '15px 20px 0px 20px',
                alignItems: 'center',
              }}
            >
              <Box>
                <DatePickerWeek onChange={onChange} />
              </Box>
              <Box sx={{ width: '30%' }}>
                <AutoComplete
                  value={canalVente}
                  onChange={(event, value) => {
                    onSelectCanalVente(value)
                  }}
                  onInputChange={(event, value) => onChangeSearchCanalVente(value)}
                  id="Canal-list"
                  options={canalVenteList}
                  getOptionLabel={(option) => option.channelLabel}
                  variant="outlined"
                  placeholder={'Choisir canal de vente'}
                  sxInputStyle={sxInput}
                  ListboxProps={{
                    onScroll: loadMoreCanalVentes,
                  }}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      id={`canal-${option.channelKey}`}
                      key={option.channelKey}
                    >
                      <div className="AutoCompteFilterOption">
                        {option.channelLabel}
                      </div>
                    </li>
                  )}
                  loading={isLoadingCanalVenteList}
                />
              </Box>
            </Box>
            <Box sx={{ paddingTop: '15px' }}>
              {!canalVente ? (
                <Box
                  sx={{
                    display: 'flex',
                    margin: 'auto',
                    alignItems: 'center',
                    width: 'fit-content',
                    padding: '15px',
                  }}
                >
                  <ErrorOutlineIcon sx={{ margin: 'auto 5px' }} />
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '12px',
                      color: 'dimgrey',
                    }}
                    variant="h4"
                  >
                    {!canalVente && 'Merci de sélectionner un canal de vente'}
                  </Typography>
                </Box>
              ) : (
                <TableDisponibilite
                  canalVenteDetails={canalVenteDetails}
                  isLoadingCanalVenteDetails={isLoadingCanalVenteDetails}
                  setOpenDialog={setOpen}
                  isCreate={canalVenteDetails?.length == 0}
                  canalVenteZones={canalVente?.zones}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {errorMessage && (
        <ModalError
          open={openErrorDialog}
          handleClose={() => {
            setOpenErrorDialog(false)

            removeErrorDisponibilites()

            if (isErrorFetchCanalList) {
              const filtre = {
                channelLabel: searchCanalVente,
                start: moment(week.firstDay).format('YYYY-MM-DD'),
                end: moment(week.lastDay).format('YYYY-MM-DD'),
                offset: 1,
                limit: 15,
              }
              fetchCanalVentes(filtre)
            }

            if (isErrorFetchCanalDetails && canalVente?.channelKey) {
              const data = {
                start: moment(week.firstDay).format('YYYY-MM-DD'),
                end: moment(week.lastDay).format('YYYY-MM-DD'),
                channelKey: canalVente.channelKey,
                fullDetails: true,
                forCorner: true,
              }
              fetchCanalVenteDetails(data)
            }
          }}
          message={errorMessage}
          isModalActionActualiser={isErrorFetchCanalList || isErrorFetchCanalDetails}
        />
      )}
      {open && (
        <DisponibilitesModal
          open={open}
          handleClose={handleCloseDialog}
          modalTitle={
            canalVenteDetails?.length > 0
              ? ' Modifier les disponibilités'
              : 'Ajouter les disponibilités'
          }
          isEdit={canalVenteDetails?.length > 0}
          canalVenteDetailsConfig={canalVente}
          canalVenteDetails={canalVenteDetails}
          week={week}
        />
      )}
    </StyledContainer>
  )
}
GestionDisponibilites.propTypes = {
  fetchCanalVentes: PropTypes.func,
  canalVenteList: PropTypes.array,
  canalVentesCount: PropTypes.number,
  isLoadingCanalVenteList: PropTypes.bool,
  fetchMoreCanalVentes: PropTypes.func,

  fetchCanalVenteDetails: PropTypes.func,
  canalVenteDetails: PropTypes.array,
  isLoadingCanalVenteDetails: PropTypes.bool,

  errorMessage: PropTypes.string,
  isErrorFetchCanalList: PropTypes.bool,
  isErrorFetchCanalDetails: PropTypes.bool,
  removeErrorDisponibilites: PropTypes.func,

  success: PropTypes.bool,
  resetSuccessAction: PropTypes.func,
}
export default GestionDisponibilites
