import React, { useState } from 'react'
import PropTypes from 'prop-types'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '../switch/SwitchComponent'

export default function SwitcherDetails({
  defaultChecked,
  codeCourse,
  label,
  setPreparedFunction,
}) {
  const [checked, setChecked] = useState(defaultChecked)
  const handleChange = (event) => {
    setChecked(!checked)
    setPreparedFunction(codeCourse, event.target.checked)
  }

  return (
    <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              value={checked}
              defaultChecked={defaultChecked}
              onChange={handleChange}
            />
          }
          label={<span style={{ color: '#6D7B8E' }}>{label}</span>}
        />
      </FormGroup>
    </div>
  )
}
SwitcherDetails.propTypes = {
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  setPreparedFunction: PropTypes.func,
  codeCourse: PropTypes.string,
}
