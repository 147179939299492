import React from 'react'
import PropTypes from 'prop-types'
import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import ServiceTraitantIcon from '../../../../../../assets/icons/navFilter/ServiceTraitant'
import { SERVICES_TRAITANT } from '../../../../../../utils/values'
export default function ProcessingDepartmentFilter({
  filterStateChanged,
  processingDepartmentFiltersValues,
}) {
  const onSubjectChanged = (processingDepartment) => {
    filterStateChanged(
      'processingDepartment',
      processingDepartment ? processingDepartment : []
    )
  }
  return (
    <SelectListWithSearch
      onDataChanged={onSubjectChanged}
      defaultLabel={'Service traitant'}
      dataList={SERVICES_TRAITANT}
      filtersValues={processingDepartmentFiltersValues?.map((e) => decodeURI(e))}
      Icon={ServiceTraitantIcon}
      id={'ProcessingDepartmentFilter'}
    />
  )
}
ProcessingDepartmentFilter.propTypes = {
  filterStateChanged: PropTypes.func,
  isLoadingSubjectConversation: PropTypes.bool,
  processingDepartmentFiltersValues: PropTypes.array,
}
