import { styled } from '@mui/material/styles'
import { Calendar } from 'react-date-range'

export const StyledCalendar = styled(Calendar)({
  width: '100%',
  '& .rdrNextPrevButton': {
    background: 'transparent ',
  },
  '& .rdrMonthAndYearPickers select': {
    fontWeight: '600',
  },
  '& .rdrMonthAndYearPickers select:hover': {
    background: 'transparent ',
  },
})
