import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GetAppIcon from '@mui/icons-material/GetApp'
import AutoComplete from '../../../AutoComplete'
import SearchIcon from '@mui/icons-material/Search'

export default function SearchDownloadSimpleDocument({
  classes,
  placeholderInput,
  onSearch,
  isLoading,
  options,
  countOptions,
  onSearchMore,
  downloadDocument,
  setSearch,
  search,
  setSelect,
  setButton,
  button,
}) {
  const [offset, setOffset] = useState(25)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 3) {
        const filter = {
          query: search,
          limit: 25,
          offset: 0,
        }
        onSearch(filter)
        setOffset(25)
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChange = (value) => {
    setButton(false)

    let val = value.trim()
    setSearch(val)
  }

  const onSelect = (value) => {
    if (value) {
      setSearch(value.label)
      setButton(true)
      setSelect(value)
    }
  }

  const loadMore = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      countOptions >= offset
    ) {
      setOffset(offset + 25)
      const filter = {
        query: search.length > 0 ? search : undefined,
        limit: 25,
        offset: offset,
      }
      onSearchMore(filter)
    }
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '7px',
        borderRadius: '5px',
      }}
    >
      <div style={{ width: button ? '91%' : '100%' }}>
        <AutoComplete
          id="Search-Simple"
          name={'search-Simple'}
          placeholder={placeholderInput}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onInputChange={(event, value) => onChange(value)}
          onChange={(event, value) => onSelect(value)}
          options={options}
          loading={isLoading}
          classes={classes}
          value={search}
          variant="standard"
          startIcon={
            <SearchIcon
              sx={{
                paddingRight: '4px',
                width: '26px !important',
                height: '26px !important',
                color: '#C2C2C2 !important',
              }}
            />
          }
          forcePopupIcon={false}
          fullWidth={true}
          ListboxProps={{
            onScroll: loadMore,
          }}
        />
      </div>
      {button && (
        <GetAppIcon
          onClick={downloadDocument}
          style={{ cursor: 'pointer', marginTop: '3px' }}
        />
      )}
    </div>
  )
}

SearchDownloadSimpleDocument.propTypes = {
  classes: PropTypes.object,
  placeholderInput: PropTypes.string,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  countOptions: PropTypes.number,
  onSearchMore: PropTypes.func,
  downloadDocument: PropTypes.func,
  setSearch: PropTypes.func,
  search: PropTypes.string,
  setSelect: PropTypes.func,
  setButton: PropTypes.func,
  button: PropTypes.bool,
}
