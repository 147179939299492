import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import AddressAutoComplete from '../../../../../../containers/main/AddressAutoComplete'
import { Place } from '../../../../../../models/place'
import { LatLng } from '../../../../../../models/latlng'
import TextComponent from './texComponent'
import { StyledCell } from './style'

const ColumnAddressComponentEditable = ({
  content,
  key,
  id,
  editable,
  onChange,
  nameAttribut,
  adressAut,
  getGoogleMapDirection,
  onDelete,
  errorsCourseEditable,
  isOptimisationCoursesFiles,
  disabledColumn,
}) => {
  const address = new Place().getFromOrderAddress(content)
  const [valueContent, setValueContent] = useState(address)

  useEffect(() => {
    if (editable) setValueContent(address)
  }, [editable])

  const onChangeAddress = (value) => {
    if (
      address?.latlng?.lat === value?.latlng?.lat &&
      address?.latlng?.lng === value?.latlng?.lng &&
      address?.formattedAddress === value?.formattedAddress
    ) {
      onDelete([nameAttribut, 'estimatedKM'])
      return
    }

    setValueContent(value)
    onChange({
      [`${nameAttribut}`]: isOptimisationCoursesFiles
        ? getDestinationAdressOptimisationFile(value)
        : getDestinationAdress(value),
    })

    !isOptimisationCoursesFiles && calculateDirection(value)
  }

  const getDestinationAdress = (addressValues) => {
    return {
      address: addressValues?.formattedAddress,
      latitude: addressValues?.latlng?.lat,
      longitude: addressValues?.latlng?.lng,
      postalCode: addressValues?.postalCode,
      countryCode: addressValues?.country?.code,
      codePays: addressValues?.country?.code,
      city: addressValues?.city,
    }
  }
  const getDestinationAdressOptimisationFile = (addressValues) => {
    return {
      address: addressValues?.formattedAddress,
      latitude: addressValues?.latlng?.lat,
      longitude: addressValues?.latlng?.lng,
      postalCode: addressValues?.postalCode,
      city: addressValues?.city,
      locationType: 'ROOFTOP',
    }
  }

  const calculateDirection = (addressValues) => {
    if (addressValues?.latlng?.lat && addressValues?.latlng?.lng) {
      getGoogleMapDirection(
        '',
        new LatLng(adressAut.latitude, adressAut.longitude),
        new LatLng(addressValues.latlng.lat, addressValues.latlng.lng)
      )
    }
  }
  return (
    <StyledCell id={id} sx={{ color: !editable && disabledColumn && '#D50000' }}>
      {editable ? (
        <AddressAutoComplete
          id="selectedDestination-shipment"
          selectedDefaultAddress={valueContent}
          handleQueryChange={(value) => console.log('destinationQuery', value)}
          handleOnAddressSelect={(value) => {
            value && onChangeAddress(value)
          }}
          hasError={errorsCourseEditable.includes(nameAttribut)}
        />
      ) : (
        <TextComponent
          content={content.address}
          key={key}
          isDisabledAddress={disabledColumn}
        />
      )}
    </StyledCell>
  )
}
ColumnAddressComponentEditable.propTypes = {
  content: PropTypes.object,
  key: PropTypes.string,
  id: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  nameAttribut: PropTypes.string,
  adressAut: PropTypes.object,
  getGoogleMapDirection: PropTypes.func,
  onDelete: PropTypes.func,
  errorsCourseEditable: PropTypes.array,
  isOptimisationCoursesFiles: PropTypes.bool,
  disabledColumn: PropTypes.bool,
}

export default ColumnAddressComponentEditable
