import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Row from './tableRow'
import './style.scss'

const RecapSection = ({
  orders,
  setIsAssemblyActive,
  setIsShipmentyActive,
  activeStep,
}) => {
  return (
    <>
      {_.get(orders, 'length') ? (
        <div className="recap-header-container">
          <div className="items-list">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table" size="small">
                <TableBody>
                  {orders.map((o) => {
                    const { totalPrice } = o
                    return (
                      <Row
                        key={o.id}
                        orders={orders}
                        order={o}
                        totalPrice={totalPrice}
                        activeStep={activeStep}
                        handleActiveShipment={() =>
                          setIsShipmentyActive(o.id, !o.shipment?.active)
                        }
                        handleActiveAssembly={() =>
                          setIsAssemblyActive(o.id, !o.assembly?.active)
                        }
                      />
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      ) : null}
    </>
  )
}
RecapSection.propTypes = {
  orders: PropTypes.array.isRequired,
  setIsAssemblyActive: PropTypes.func.isRequired,
  setIsShipmentyActive: PropTypes.func.isRequired,
  activeStep: PropTypes.number,
  shipmentTotalPrice: PropTypes.number,
}
export default RecapSection
