import { connect } from 'react-redux'

import {
  fetchSearchCorner,
  fetchCourses,
  fetchSearchCornerSave,
  getOrderByFilter,
  getOrderByAgents,
} from '../../../redux/order/actions'

import SearchInCorner from '../../../components/NavFilter/components/searchCorner/searchInCorner'
import {
  getIsLoadingSearchCorner,
  getCoursesSearch,
  getMyCommandsIsOn,
} from '../../../redux/order/selectors'
import { getSearchCorner } from '../../../redux/filter/selectors'
import {
  pageChanged,
  pageLimitChanged,
  saveSearchCorner,
} from '../../../redux/filter/actions'
import { LimitPaginationList } from '../../../components/Pagination/LimitPaginationList'

const mapStateToProps = (state) => ({
  isLoading: getIsLoadingSearchCorner(state),
  coursesSearch: getCoursesSearch(state),
  myCommandsIsOn: getMyCommandsIsOn(state),
  searchReducer: getSearchCorner(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchSearchCorner: (search) => dispatch(fetchSearchCorner(search)),
  fetchCourses: (courses) => dispatch(fetchCourses(courses)),
  fetchSearchCornerSave: (search) => dispatch(fetchSearchCornerSave(search)),

  getCourse: (myCommandsIsOn) => {
    dispatch(pageLimitChanged(LimitPaginationList[0].value))
    dispatch(pageChanged(0))

    myCommandsIsOn ? dispatch(getOrderByAgents()) : dispatch(getOrderByFilter())
  },
  saveSearch: (search) => dispatch(saveSearchCorner(search)),
})

export default connect(mapStateToProps, mapDisptachToProps)(SearchInCorner)
