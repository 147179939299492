import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@mui/material/CircularProgress'
import HistoryIcon from '@mui/icons-material/History'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import CardAccordion from '../card/cardWithAccordion'

const HistoriqueDeLivraison = ({
  orderCode,
  orderHistoriqueDeltaCost,
  isLoadingHistoriqueDeltaCost,
  getOrderHistoriqueDeltaCost,
  isLoading,
}) => {
  const [paramsInit, setParams] = useState(null)

  const params = useParams()

  useEffect(() => {
    if (orderCode) {
      getOrderHistoriqueDeltaCost(params.ldv)
      setParams(params)
    }
  }, [orderCode])

  useEffect(() => {
    if (paramsInit && paramsInit.ldv != params.ldv && orderCode) {
      setParams(params)
      getOrderHistoriqueDeltaCost(params.ldv)
    }
  }, [params])

  const parseDeltaCost = () => {
    const deltaCostParser = {}
    deltaCostParser.date = moment(orderHistoriqueDeltaCost.createdAt)
      .startOf('day')
      .format('dddd, D MMMM, YYYY')
    deltaCostParser.hour = moment(orderHistoriqueDeltaCost?.createdAt).format(
      'HH:mm'
    )
    deltaCostParser.message = `${orderHistoriqueDeltaCost?.collaborateur?.firstname} ${orderHistoriqueDeltaCost?.collaborateur?.lastname} a choisi un motif “${orderHistoriqueDeltaCost?.reason?.motif}"`
    deltaCostParser.comment = orderHistoriqueDeltaCost?.commentaire
    deltaCostParser.montant = orderHistoriqueDeltaCost?.montant
    return deltaCostParser
  }
  return (
    <CardAccordion
      icon={
        <HistoryIcon style={{ marginRight: '4px', width: '20px', height: '20px' }} />
      }
      title={'Historique de Delta Cost'}
      content={
        <>
          {isLoadingHistoriqueDeltaCost || isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px',
              }}
            >
              <CircularProgress color="primary" />
            </div>
          ) : (
            <>
              {orderHistoriqueDeltaCost ? (
                <div style={{ paddingBottom: '5px' }}>
                  <div
                    style={{
                      color: '#696969',
                      fontSize: '14px',
                      fontWeight: '400',

                      paddingLeft: '25px',
                      textTransform: 'capitalize',
                    }}
                  >
                    {parseDeltaCost().date}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      padding: '10px 40px',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        marginRight: '10px',
                      }}
                    >
                      {parseDeltaCost().hour}
                    </span>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '450',
                        wordBreak: 'break-word',
                      }}
                    >
                      {parseDeltaCost().message}
                    </span>
                  </div>
                  {orderHistoriqueDeltaCost?.commentaire && (
                    <div
                      style={{
                        display: 'flex',
                        padding: '10px 40px',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '13px',
                          fontWeight: '550',
                          marginRight: '10px',
                        }}
                      >
                        {'Commentaire: '}
                      </span>
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: '450',
                          wordBreak: 'break-word',
                        }}
                      >
                        {parseDeltaCost().comment}
                      </span>
                    </div>
                  )}

                  {orderHistoriqueDeltaCost?.montant && (
                    <div
                      style={{
                        display: 'flex',
                        padding: '10px 40px',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '13px',
                          fontWeight: '550',
                          marginRight: '10px',
                        }}
                      >
                        {'Montant: '}
                      </span>
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: '450',
                          wordBreak: 'break-word',
                        }}
                      >
                        {`${parseDeltaCost().montant} HT`}
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '20px',
                  }}
                >
                  <span
                    style={{
                      overflowWrap: 'break-word',
                      fontSize: '12px',
                      fontWeight: '550',
                    }}
                  >
                    {'Pas de Delta Cost appliqué'}
                  </span>
                </div>
              )}
            </>
          )}
        </>
      }
    />
  )
}
HistoriqueDeLivraison.propTypes = {
  orderCode: PropTypes.string,
  orderHistoriqueDeltaCost: PropTypes.array,
  isLoadingHistoriqueDeltaCost: PropTypes.bool,
  getOrderHistoriqueDeltaCost: PropTypes.func,
  isLoading: PropTypes.bool,
}
export default HistoriqueDeLivraison
