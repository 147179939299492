import React from 'react'

import SettingsIcon from '../../assets/icons/settingsCorner/settingsIcon'
import SessionsIcon from '../../assets/icons/settingsCorner/sessionsIcon'
import GestionRaIcon from '../../assets/icons/settingsCorner/GestionRaIcon'
import GestionCleIcon from '../../assets/icons/settingsCorner/GestionCleIcon'
import GestionDisponibilitesIcon from '../../assets/icons/settingsCorner/gestionDisponibilites'
import { ROLE_PLANIFICATEUR } from '../../utils/roleManagement/roleValues'

export const settingsSidebarLinks = [
  {
    label: 'Configuration Attribution auto',
    icon: <SettingsIcon style={{ fontSize: '21px' }} />,
    children: [
      {
        label: 'Association clé-canal',
        path: '/settings/affectation-cles-canal',
      },
      {
        label: 'Association clé-chauffeur',
        path: '/settings/affectation-cles-chauffeur',
      },
    ],
    canAccess: [],
  },
  {
    label: 'Gestion des clés',
    icon: <GestionCleIcon style={{ fontSize: '21px' }} />,
    path: '/settings/configuration-cle',
    children: [],
    canAccess: [],
  },
  {
    label: 'Gestion des ressources',
    icon: <GestionRaIcon style={{ fontSize: '21px' }} />,
    children: [
      {
        label: 'Chauffeurs',
        path: '/settings/chauffeurs',
      },
      {
        label: 'Véhicules',
        path: '/settings/vehicule',
      },
      {
        label: 'Configuration RA',
        path: '/settings/configuration-RA',
      },
    ],
    canAccess: [],
  },
  {
    label: 'Gestion des compétences',
    icon: <SessionsIcon style={{ fontSize: '21px' }} />,
    children: [
      {
        label: 'Formations',
        path: '/settings/formations',
      },
      {
        label: 'Participants',
        path: '/settings/participants',
      },

      {
        label: 'Sessions de formations',
        path: '/settings/sessions',
        linkRelated: [
          {
            path: '/session',
          },
        ],
      },
    ],
    canAccess: [],
  },
  {
    label: 'Gestion des disponibilités',
    icon: <GestionDisponibilitesIcon style={{ fontSize: '21px' }} />,
    path: '/settings/disponibilites',
    children: [],
    canAccess: [ROLE_PLANIFICATEUR.code],
  },
]
