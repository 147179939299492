import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Button from '@mui/material/Button'
import './style.scss'
import { replaceDotWithComma } from '../../../../utils/floatFormatting'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'

export const StyledButton = styled(Button)({
  textTransform: 'initial !important',
  fontSize: '13px !important',
  fontWeight: '400 !important',
  boxShadow:
    '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important',
})

const EditOrderFooter = ({
  steps,
  activeStep,
  orders,
  submitFirstStep,
  setActiveStep,
  client,
  submitSecondStep,
  setCommand,
  isGetOrderLoading,
  paymentForm,
  totalPrice,
  isLoading,
}) => {
  const errorFocus = (errors, factures) => {
    const ErrorValuesShipment = [
      'selectedOrigin',
      'originMail',
      'level',
      'selectedDestination',
      'destinationMail',
      'date',
      'pickUpDate',
      'time',
      'earliestDeliveryDate',
      'latestDeliveryDate',
      'nbItems',
      'weight',
      'purchaseAmount',
      'noteIntern',
      'factures',
      'clientPrice',
      'providerPrice',
      'destinationFirstname',
      'destinationLastname',
      'destinationPhone',
    ]

    var i
    if (!_.isNil(errors)) {
      for (i = 0; i < ErrorValuesShipment.length; i++) {
        if (
          ErrorValuesShipment[i] === 'factures' &&
          errors.includes(ErrorValuesShipment[i])
        ) {
          return `facture-${factures[0]}-shipment`
        } else if (errors.includes(ErrorValuesShipment[i])) {
          return ErrorValuesShipment[i] + '-shipment'
        }
      }
    }
    return null
  }
  const handleInvalidActiveStep = () => {
    const errors = Object.keys(_.get(orders, 'shipment.errors'))
    // TODO make this part generic
    if (errors.includes('factures')) {
      var factures = _.get(orders, 'shipment.errors.factures.required.index')
    }
    const idFocus = errorFocus(errors, factures)
    if (idFocus != null) {
      if (
        idFocus === 'latestDeliveryDate-shipment' ||
        idFocus === 'earliestDeliveryDate-shipment' ||
        idFocus === 'date-shipment' ||
        idFocus === 'pickUpDate-shipment' ||
        idFocus === 'destinationPhone-shipment'
      )
        window.location.hash = `#${idFocus}`
      document.getElementById(idFocus).focus()
    }
  }

  let history = useHistory()
  useEffect(() => {
    return history.listen((location) => {
      switch (parseInt(location.hash[1])) {
        case 0:
          setActiveStep(0)

          break

        case 1:
          setActiveStep(1)

          break

        case 2:
          setActiveStep(2)

          break

        default:
          break
      }
    })
  }, [history])
  useEffect(() => {
    history.push(window.location.pathname + '#0')
  }, [])
  const isStepValid = () => {
    if (activeStep === 0) {
      console.log(orders)

      submitFirstStep()
      console.log(orders.shipment.errors)
      return _.isEqual(_.get(orders, 'shipment.errors'), {})
    } else if (activeStep === 1) {
      submitSecondStep()
      console.log(client.errors)
      return _.isEqual(_.get(client, 'errors'), {})
    } else {
      return true
    }
  }
  const isCommandValid = () => {
    const isStep1Valid = _.isEqual(_.get(orders, 'shipment.errors'), {})

    const isStep2Valid = _.isEqual(_.get(client, 'errors'), {})
    const isStep3Valid = paymentForm === true

    console.log(isStep1Valid, isStep2Valid, isStep3Valid)
    return !isStep1Valid || !isStep2Valid || !isStep3Valid || isLoading
  }

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      if (isStepValid()) {
        window.scrollTo(0, 0)
        const next = activeStep + 1
        history.push(window.location.pathname + '#' + next)

        setActiveStep(next)
      } else {
        handleInvalidActiveStep()
      }
    }
  }
  const handleBackStep = () => {
    window.scrollTo(0, 0)
    if (activeStep > 0) {
      const before = activeStep - 1
      history.push(window.location.pathname + '#' + before)
      setActiveStep(before)
    } else {
      history.push('/commandes')
    }
  }

  return (
    <div className="recap-footer" style={{ height: 40 }}>
      {!isGetOrderLoading && (
        <>
          <div
            className="appbarFooter"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <StyledButton
              variant="contained"
              color="primary"
              onClick={() => handleBackStep()}
              sx={{
                marginLeft: '20px !important',
                float: 'left',
                width: '100px',
              }}
            >
              Retour
            </StyledButton>
            <div className="right-buttons">
              <span className="recap-title">
                <p className="bold-style-text">
                  {' '}
                  Prix à payer : {replaceDotWithComma(totalPrice.toFixed(2))} €
                </p>
              </span>

              {activeStep < 2 ? (
                <StyledButton
                  color="primary"
                  variant="contained"
                  onClick={() => handleNextStep()}
                  sx={{
                    backgroundColor: 'white',
                    color: '#10A549',
                    border: '1px solid #10A549',
                    ':hover': {
                      backgroundColor: 'white',
                      border: '1px solid #158f44',
                      color: '#158f44',
                    },
                  }}
                >
                  Suivant
                </StyledButton>
              ) : (
                <StyledButton
                  color="secondary"
                  variant="contained"
                  onClick={() => setCommand(true)}
                  disabled={isCommandValid()}
                  sx={{
                    backgroundColor: '#10A549',
                    ':hover': {
                      backgroundColor: '#158f44',
                    },
                  }}
                >
                  Modifier
                </StyledButton>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
EditOrderFooter.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  orders: PropTypes.object.isRequired,
  submitFirstStep: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  submitSecondStep: PropTypes.func.isRequired,
  isGetOrderLoading: PropTypes.bool.isRequired,
  setCommand: PropTypes.func,
  paymentForm: PropTypes.bool,
  totalPrice: PropTypes.any,
  isLoading: PropTypes.bool,
}
export default EditOrderFooter
