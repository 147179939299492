import React from 'react'
import PropTypes from 'prop-types'
import AgentsCommands from '../../../../../containers/main/NavFilter/AgentCommands'
import ToursFilter from './TourFilter'
import SAV from './SAV'
import ReplyFilter from './Reply'
import DiscussionDirectionFilter from './DiscussionDirection'
import CourseLieeFilter from './CourseLiee'

export default function Cell({
  index,
  onMyCommandsChanged,
  filterStateChanged,
  filtersValues,
  disableFilter,
}) {
  switch (index) {
    case 1:
      return (
        <CourseLieeFilter
          filterStateChanged={filterStateChanged}
          courseLieeFiltersValues={filtersValues['deliveryRelated']}
          disabled={disableFilter}
        />
      )
    case 2:
      return (
        <AgentsCommands onMyChange={onMyCommandsChanged} checked={disableFilter} />
      )

    case 3:
      return (
        <ToursFilter
          filterStateChanged={filterStateChanged}
          toursFiltersValues={filtersValues['tours']}
        />
      )
    case 4:
      return (
        <SAV
          filterStateChanged={filterStateChanged}
          savFiltersValues={filtersValues['sav']}
          disabled={disableFilter}
        />
      )
    case 5:
      return (
        <DiscussionDirectionFilter
          filterStateChanged={filterStateChanged}
          directionFiltersValues={filtersValues['direction']}
          disabled={disableFilter}
        />
      )
    case 6:
      return (
        <ReplyFilter
          filterStateChanged={filterStateChanged}
          replyFiltersValues={filtersValues['reply']}
          disabled={disableFilter}
        />
      )

    default:
      return null
  }
}
Cell.propTypes = {
  index: PropTypes.number,
  onMyCommandsChanged: PropTypes.func,
  filterStateChanged: PropTypes.func,
  filtersValues: PropTypes.any,
  disableFilter: PropTypes.bool,
}
