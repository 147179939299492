export const ROLES = {
  Planificateur: {
    name: 'planificateur',
    pathRedirect: '/settings/disponibilites',
    actions: [],
  },
}

export const ROLE_PLANIFICATEUR = {
  name: 'planificateur',
  code: 'Planificateur',
}
