import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import TableCell from '@mui/material/TableCell'

export default function TourTextComponent({ text, index, display, id }) {
  return (
    display && (
      <TableCell id={id} align="left">
        {text && (
          <Tooltip
            arrow
            title={
              <span
                style={{
                  fontSize: '10px',
                  textTransform: 'capitalize',
                  fontWeight: '400',
                }}
              >
                {text}
              </span>
            }
          >
            <p
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                margin: 'auto',
              }}
              key={index}
            >
              {text}
            </p>
          </Tooltip>
        )}
      </TableCell>
    )
  )
}
TourTextComponent.propTypes = {
  text: PropTypes.any,
  index: PropTypes.string,
  id: PropTypes.string,
  display: PropTypes.bool,
}
