export const GET_MESSAGES_BY_CONVERSATION_ID = 'GET_MESSAGES_BY_CONVERSATION_ID'
export const GET_MESSAGES_BY_CONVERSATION_ID_SUCESS =
  'GET_MESSAGES_BY_CONVERSATION_ID_SUCESS'
export const GET_MESSAGES_BY_CONVERSATION_ID_FAILURE =
  'GET_MESSAGES_BY_CONVERSATION_ID_FAILURE'

export const SUBSCRIBE_TO_MESSAGES_TOPIC = 'SUBSCRIBE_TO_MESSAGES_TOPIC'
export const RECEIVE_DATA_FROM_MESSAGES_TOPIC = 'RECEIVE_DATA_FROM_MESSAGES_TOPIC'

export const CLEAN_MESSAGE_LIST = 'CLEAN_MESSAGE_LIST'
export const LOADING_SEND_MESSAGE = 'LOADING_SEND_MESSAGE'

export const UPLOAD_FILE_DISCUSSION = 'UPLOAD_FILE_DISCUSSION'
export const UPLOAD_FILE_DISCUSSION_SUCESS = 'UPLOAD_FILE_DISCUSSION_SUCESS'
export const UPLOAD_FILE_DISCUSSION_FAILURE = 'UPLOAD_FILE_DISCUSSION_FAILURE'
export const DOWNLOAD_FILE_DISCUSSION = 'DOWNLOAD_FILE_DISCUSSION'
export const SET_LOADING_FILE = 'SET_LOADING_FILE'
export const SET_ERROR_FILE = 'SET_ERROR_FILE'
export const DELETE_FILE_DISCUSSION = 'DELETE_FILE_DISCUSSION'
export const DELETE_FILE_DISCUSSION_SUCESS = 'DELETE_FILE_DISCUSSION_SUCESS'
export const DELETE_FILE_DISCUSSION_FAILURE = 'DELETE_FILE_DISCUSSION_FAILURE'
//urls declaration

export const GET_MESSAGES_BY_CONVERSATION_ID_URL = 'conversation/'

export const SET_MESSAGE_NEW_CONVERSATION = 'SET_MESSAGE_NEW_CONVERSATION'
export const REMOVE_MESSAGE_NEW_CONVERSATION = 'REMOVE_MESSAGE_NEW_CONVERSATION'

export const DELETE_FILE_DISCUSSION_SIMPLE = 'DELETE_FILE_DISCUSSION_SIMPLE'
