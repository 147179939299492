import { connect } from 'react-redux'
import DiscussionPage from '../../../pages/main/Discussion'
import {
  getEntryConversationAction,
  subscribeToConversationTopicAction,
  cleaningConversationList,
  focus,
  notFocus,
  getMoreEntryConversationAction,
} from '../../../redux/discussion/conversations/action'

import {
  registerSocket,
  getConversationSocket,
  getMessageSocket,
  sendMessage,
  CloseOpenConversation,
  removeListenerSocket,
  seenMessage,
} from '../../../redux/discussion/socket/action'

import {
  getConversationError,
  getConversations,
  getConversationLoading,
  getsocketNotRegistred,
  getFocus,
  getloadingOpenCloseConversation,
  getCountListConversation,
  getConversationListLoadingMore,
} from '../../../redux/discussion/conversations/selectors'

import {
  getMessageByConversationIdAction,
  cleaningMessageList,
  onUploadfileDiscussion,
  removeFileDiscussion,
  downloadFileDiscussion,
  setLoadingUploadFile,
  setErrorUploadFile,
  deleteFile,
} from '../../../redux/discussion/messages/action'

import {
  getMessageListError,
  getMessageListLoading,
  getMessageListId,
  getMessageListSubject,
  getMessageListSalesChannel,
  getMessageListentries,
  getMessageListConversationToUser,
  getLoadingSendMessage,
  getLoadingUploadFile,
  getErrorUploadFile,
  getFilesUpload,
  getErrorMessageUploadFile,
} from '../../../redux/discussion/messages/selectors'
import {
  resetFilterDiscussion,
  setFilterDiscussion,
  removeErrorFilter,
} from '../../../redux/filter/actions'
import {
  getDefaultFilterDiscussion,
  getfilterDiscussion,
  getfilterError,
  getIsOpened,
} from '../../../redux/filter/selectors'
import { isOpenChanged, pageChanged } from '../../../redux/filter/actions'

const mapStateToProps = (state) => ({
  //conversation list variables
  errorGetConversationList: getConversationError(state),
  entryConversations: getConversations(state),
  isLoadingGetConversationList: getConversationLoading(state),
  issocketNotRegistred: getsocketNotRegistred(state),
  loadingOpenCloseConversation: getloadingOpenCloseConversation(state),
  //message variables
  errorGetMessageList: getMessageListError(state),
  isLoadingGetMessageList: getMessageListLoading(state),
  idConversation: getMessageListId(state),
  conversationSubject: getMessageListSubject(state),
  salesChannelConversation: getMessageListSalesChannel(state),
  conversationEntries: getMessageListentries(state),
  conversationToUser: getMessageListConversationToUser(state),
  varFocus: getFocus(state),
  loadingSendMessage: getLoadingSendMessage(state),
  loadingUploadFile: getLoadingUploadFile(state),
  errorUploadFile: getErrorUploadFile(state),
  filesUpload: getFilesUpload(state),
  countList: getCountListConversation(state),
  isLoadingConvMore: getConversationListLoadingMore(state),
  errorMessageUploadFile: getErrorMessageUploadFile(state),
  filterReducer: getfilterDiscussion(state),
  errorFilter: getfilterError(state),
  isOpened: getIsOpened(state),
  defaultFilterDiscussion: getDefaultFilterDiscussion(state),
})

const mapDisptachToProps = (dispatch) => ({
  //conversation list action
  notFocus: () => dispatch(notFocus()),
  focus: () => dispatch(focus()),
  getMoreEntryConversationAction: (data) =>
    dispatch(getMoreEntryConversationAction(data)),

  subscribeToConversationTopicAction: () =>
    dispatch(subscribeToConversationTopicAction()),
  cleaningConversationList: () => dispatch(cleaningConversationList()),
  registerSocket: () => dispatch(registerSocket()),
  getConversationSocket: (isRecherch) => dispatch(getConversationSocket(isRecherch)),
  // messages action
  getMessageByConversationIdAction: (conversationId, legacyId) =>
    dispatch(getMessageByConversationIdAction(conversationId, legacyId)),

  cleaningMessageList: () => dispatch(cleaningMessageList()),
  getMessageSocket: (idConversation) =>
    dispatch(getMessageSocket(idConversation, false)),
  sendMessage: (entry, connectedUser) => dispatch(sendMessage(entry, connectedUser)),
  CloseOpenConversation: (entry, connectedUser) =>
    dispatch(CloseOpenConversation(entry, connectedUser)),
  seenMessage: (entry, connectedUser) => dispatch(seenMessage(entry, connectedUser)),
  removeListenerSocket: (event) => dispatch(removeListenerSocket(event)),
  downloadFile: (urlFile) => dispatch(downloadFileDiscussion(urlFile)),
  removeFileDiscussion: (nameFile) => dispatch(removeFileDiscussion(nameFile)),
  onUploadfileDiscussion: (files) => dispatch(onUploadfileDiscussion(files)),
  setLoadingUploadFile: () => dispatch(setLoadingUploadFile()),
  setErrorUploadFile: (error) => dispatch(setErrorUploadFile(error)),
  deleteFile: (filesUpload) => dispatch(deleteFile(filesUpload)),
  resetFilterDiscussion: () => dispatch(resetFilterDiscussion()),
  setFilter: (filter, fromAppliquer) =>
    dispatch(setFilterDiscussion(filter, fromAppliquer)),
  isOpenChanged: (isOpen) => {
    dispatch(isOpenChanged(isOpen))
    dispatch(getEntryConversationAction())
  },
  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(getMoreEntryConversationAction())
  },
  removeErrorFilter: () => dispatch(removeErrorFilter()),
})

export default connect(mapStateToProps, mapDisptachToProps)(DiscussionPage)
