import React, { useEffect } from 'react'
import Dialog from '../ModalDialog'
import DialogContentText from '@mui/material/DialogContentText'
import PropTypes from 'prop-types'
export default function Desattribution({
  open,
  handleClose,
  payload,
  handleDissociateClick,
  isLoadingActionOrder,
  errorActionCourse,
}) {
  const dissociateClick = () => {
    handleDissociateClick(payload)
  }

  useEffect(() => {
    if (errorActionCourse) handleClose()
  }, [errorActionCourse])

  return (
    <Dialog
      maxWidthDialog={'sm'}
      open={open}
      title={'Désattribution de chauffeur'}
      content={
        <DialogContentText sx={{ pr: 2, pb: 2 }}>
          {
            'La course va passer vers le status programmée , le chauffeur va être enlevé.'
          }
        </DialogContentText>
      }
      handleClose={handleClose}
      handleClickAction={dissociateClick}
      isLoadingButtonAction={isLoadingActionOrder}
    />
  )
}
Desattribution.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  payload: PropTypes.object,
  handleDissociateClick: PropTypes.func,
  isLoadingActionOrder: PropTypes.bool,
  errorActionCourse: PropTypes.string,
}
