import { connect } from 'react-redux'
import statusChange from '../../../pages/main/Orders/DetailsDelivery/components/details/statusChange'

import { onChangeStatus, getOrderReasons } from '../../../redux/order/actions'

import { updateOrdersArray } from '../../../redux/simulateTournee/actions'

import {
  getReasonsOrder,
  getIsLoadingReasonsOrder,
  getIsLoadingActionOrder,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingMotifListes: getIsLoadingReasonsOrder(state),
  listesMotif: getReasonsOrder(state),
  isLoadingActionOrder: getIsLoadingActionOrder(state),
})
const mapDisptachToProps = (dispatch) => ({
  onChangeStatus: (payload) => dispatch(onChangeStatus(payload)),
  updateOrdersArray: (orders) => {
    dispatch(updateOrdersArray(orders))
  },
  getMotifsCloture: (payload) => {
    dispatch(getOrderReasons(payload))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(statusChange)
