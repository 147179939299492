import { insetObjectTable } from '../../utils/utils'
import {
  GET_VEHICULES,
  GET_VEHICULES_SUCCESS,
  GET_VEHICULES_FAILURE,
  GET_VEHICULES_MORE,
  GET_VEHICULES_MORE_SUCCESS,
  GET_VEHICULES_MORE_FAILURE,
  PAGE_CHANGED_VEHICULES,
  PAGE_LIMIT_CHANGED_VEHICULES,
  REMOVE_ERROR_VEHICULES,
  RESET_SUCCESS_ACTION_VEHICULES,
  RESET_CONFIG_VEHICULES,
  SEARCH_VEHICULES,
  ADD_VEHICULE,
  ADD_VEHICULE_FAILURE,
  ADD_VEHICULE_SUCCESS,
  UPDATE_VEHICULE,
  UPDATE_VEHICULE_SUCCESS,
  UPDATE_VEHICULE_FAILURE,
  DELETE_VEHICULE,
  DELETE_VEHICULE_SUCCESS,
  DELETE_VEHICULE_FAILURE,
} from './constants'
import initialState from './initialState'

export default (state = initialState(), action) => {
  if (action.type === GET_VEHICULES) {
    return {
      ...state,
      vehiculesList: [],
      isLoadingVehiculesList: true,
    }
  }

  if (action.type === GET_VEHICULES_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      vehiculesList: data,
      isLoadingVehiculesList: false,
      vehiculesListCount: count,
    }
  }

  if (action.type === GET_VEHICULES_FAILURE) {
    const { response } = action.payload?.data

    return {
      ...state,
      isLoadingVehiculesList: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetch: true,
    }
  }

  if (action.type === GET_VEHICULES_MORE) {
    return {
      ...state,
    }
  }
  if (action.type === GET_VEHICULES_MORE_SUCCESS) {
    return {
      ...state,
      vehiculesList: [...state.vehiculesList, ...action.payload?.data?.data],
      vehiculesListCount: action.payload.data?.count || 0,
    }
  }

  if (action.type === GET_VEHICULES_MORE_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === PAGE_CHANGED_VEHICULES) {
    const { offset } = action.payload
    const filter = { ...state.filter }
    filter.offset = offset
    return {
      ...state,
      filter,
    }
  }

  if (action.type === PAGE_LIMIT_CHANGED_VEHICULES) {
    const { limit } = action.payload
    const filter = { ...state.filter }
    filter.limit = limit
    filter.offset = 0
    return {
      ...state,
      filter,
    }
  }

  if (action.type === REMOVE_ERROR_VEHICULES) {
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
    }
  }

  if (action.type === RESET_SUCCESS_ACTION_VEHICULES) {
    return {
      ...state,
      success: false,
      isSuccessRefresh: false,
    }
  }

  if (action.type === RESET_CONFIG_VEHICULES) {
    //rest
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
      isLoadingVehiculeAction: false,
      success: false,
      isSuccessRefresh: false,

      filter: {
        limit: 25,
        offset: 0,
      },
    }
  }

  if (action.type === SEARCH_VEHICULES) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        ...state.filter,
        query: search,
      },
    }
  }

  if (action.type === ADD_VEHICULE) {
    return {
      ...state,
      isLoadingVehiculeAction: true,
    }
  }
  if (action.type === ADD_VEHICULE_SUCCESS) {
    return {
      ...state,
      isLoadingVehiculeAction: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === ADD_VEHICULE_FAILURE) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingVehiculeAction: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === UPDATE_VEHICULE) {
    return {
      ...state,
      isLoadingVehiculeAction: true,
    }
  }
  if (action.type === UPDATE_VEHICULE_SUCCESS) {
    const { data } = action.payload.data
    const vehiculeIndex = state.vehiculesList.map((el) => el.id).indexOf(data.id)

    return {
      ...state,
      vehiculesList: [...insetObjectTable(state.vehiculesList, data, vehiculeIndex)],
      isLoadingVehiculeAction: false,
      success: true,
    }
  }

  if (action.type === UPDATE_VEHICULE_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingVehiculeAction: false,
      success: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === DELETE_VEHICULE) {
    return {
      ...state,
      isLoadingVehiculeAction: true,
    }
  }
  if (action.type === DELETE_VEHICULE_SUCCESS) {
    return {
      ...state,
      isLoadingVehiculeAction: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === DELETE_VEHICULE_FAILURE) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingVehiculeAction: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  return state
}
