import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import RDVImpossible from '../../containers/main/ActionShipment/RDVImpossible'
import UpdateWarehouseModal from '../../containers/main/ActionShipment/UpdateWarehouse'
import Navette from '../../containers/main/ActionShipment/Navette'

import Program from '../../containers/main/ActionShipment/Program'
import CancelShipment from '../../containers/main/ActionShipment/CancelShipment'
import Deprogram from '../../containers/main/ActionShipment/Deprogram'
import Desattribution from '../../containers/main/ActionShipment/Desattribution'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Link } from 'react-router-dom'
import {
  CANCELED_STATUS_LIST,
  CANCELED_STATUS_LIST_DESATRIBUER,
  ClientDelivery,
  COURSE_SOURCE_SCHMIDT,
  SCHEDULE_WAIT_STATUS,
} from '../../utils/values'
import { COURSE_EDIT_TYPE, getOrderStatus } from '../../utils/getOrderStatus'
import {
  PROGRAMMED_STATUS,
  DECHARGEMENT_EN_COURS_STATUS,
  VERS_ENLEVEMENT_STATUS,
  CHARGEMENT_STATUS,
  ENLEVEE_STATUS,
  VERS_DECHARGEMENT_STATUS,
  DONE_STATUS,
  LIVRAISON_IMPOSSIBLE,
  RETRAIT_IMPOSSIBLE,
  CREER,
  CANCELED_STATUS,
  SUPPLY_COMPLETE_STATUS,
  SUPPLY_IN_PROGRESS_STATUS,
  SUPPLY_WAIT_STATUS,
  TERMINEE_STATUS,
} from '../../utils/values'
import AssignDialog from '../../containers/main/ActionShipment/AssignDialog'
import Attribution from '../../assets/icons/detailsLivraison/MdiTruckCheck'
import RdvImpossibles from '../../assets/icons/detailsLivraison/CarbonPhoneOffFilled'
import DesChauffeur from '../../assets/icons/detailsLivraison/IcRoundPersonOff'
import UpdateWarehouse from '../../assets/icons/detailsLivraison/updateWarehouse'
import NavetteIcon from '../../assets/icons/detailsLivraison/navette'
import LivraisonClientIcon from '../../assets/icons/detailsLivraison/LivraisonClient'
import Annulation from '../../assets/icons/detailsLivraison/IcOutlineCancel'
import Modification from '../../assets/icons/detailsLivraison/IcRoundCreate'
import Deprogrammation from '../../assets/icons/detailsLivraison/IcOutlineRestore'
import Programmation from '../../assets/icons/detailsLivraison/MdiClockTimeNineOutline'
import Divider from '@mui/material/Divider'
import SetPreparedDetails from '../../containers/main/ActionShipment/SetPreparedDetails'
import moment from 'moment'
import Dialog from '../ModalDialog'
import { styled } from '@mui/material/styles'
import LivraisonClient from '../../containers/main/ActionShipment/LivraisonClient'
import { COURSE_SOURCE_BLOQUAGE_PROGRAMMATION } from '../../utils/checkActionCourseSource'

export const StyledListItemText = styled(ListItemText)(() => ({
  display: 'flex',
}))

const ITEM_HEIGHT = 48

export default function LongMenu({
  canBePrepared,
  shipmentReference,
  codeCourse,
  status,
  isPrepared,
  courseSource,
  configs,
  chauffeur,
  isDetailsLivraison,
  adresseDepart,
  options,
  canalVente,
  isPCHRecieved,
  courseType,
  category,
  adresseArrivee,
  ldvRelatedCourse,
  deliveryRelated,
  serviceTime,
}) {
  const [selectedCourses, setSelectedCourses] = useState([])
  const [attributionDialogOpen, setAttributionDialogOpen] = useState(false)
  const [programDialogOpen, setProgramDialogOpen] = useState(false)
  const [selectedShipments, setSelectedshipments] = useState([])
  const disabled =
    CANCELED_STATUS_LIST.indexOf(status.code) !== -1 ||
    TERMINEE_STATUS.code === status.code
  const [open, setOpen] = useState(false)
  const [dissociateOpen, setDissociateOpen] = useState(false)
  const [alertDialogueOpen, setAlertDialogueOpen] = useState(false)
  const CURRENT_USER = 'currentUser'
  const [connectedUser] = useState(JSON.parse(localStorage.getItem(CURRENT_USER)))
  let bloquerprogrammation =
    COURSE_SOURCE_BLOQUAGE_PROGRAMMATION.includes(courseSource) &&
    deliveryRelated !== '' &&
    deliveryRelated !== null &&
    ldvRelatedCourse == null
  let livraisonSimple =
    COURSE_SOURCE_BLOQUAGE_PROGRAMMATION.includes(courseSource) &&
    deliveryRelated === '' &&
    deliveryRelated !== null
  const handleProgramDialogOpen = () => {
    const filtered = []

    filtered.push({
      idCanalVente: canalVente.id,
      codeCanalVente: canalVente.code,
      adresses: canalVente.adresses,
      configs: canalVente.configs,
      optionsDetails: canalVente.optionsDetails,
      adresseDepart,
      courseSource,
      options,
      courseType,
      adresseArrivee,
      serviceTime,
    })

    setSelectedCourses([codeCourse])

    setSelectedshipments(filtered)
    if (
      (bloquerprogrammation ||
        (courseSource == 'MDM' && !isPCHRecieved) ||
        livraisonSimple) &&
      ClientDelivery.includes(courseType)
    ) {
      setAlertDialogueOpen(true)
    } else {
      setProgramDialogOpen(true)
    }

    handleCloseMenuBarre()
  }
  const handleAssignDialogOpen = () => {
    const filtered = []

    filtered.push({
      status,
      code: codeCourse,
    })

    setSelectedshipments(filtered)
    setAttributionDialogOpen(true)
    handleCloseMenuBarre()
  }
  const canBeSetRDVImpProgram =
    (status.code === SUPPLY_WAIT_STATUS.code ||
      status.code === SUPPLY_IN_PROGRESS_STATUS.code ||
      status.code === SUPPLY_COMPLETE_STATUS.code) &&
    configs &&
    configs.hasSupplyStep
  const isDeprogram =
    (status.code === PROGRAMMED_STATUS.code ||
      status.code === DECHARGEMENT_EN_COURS_STATUS.code ||
      status.code === VERS_ENLEVEMENT_STATUS.code ||
      status.code === CHARGEMENT_STATUS.code ||
      status.code === ENLEVEE_STATUS.code ||
      status.code === VERS_DECHARGEMENT_STATUS.code) &&
    configs &&
    configs.hasSupplyStep
  const isAttributed = status.code === PROGRAMMED_STATUS.code
  const isDissociate = !(
    status.code === DONE_STATUS.code ||
    status.code === LIVRAISON_IMPOSSIBLE.code ||
    status.code === RETRAIT_IMPOSSIBLE.code ||
    status.code === CANCELED_STATUS.code ||
    CANCELED_STATUS_LIST_DESATRIBUER.includes(status.code) ||
    ((status.code === PROGRAMMED_STATUS.code || status.code === CREER.code) &&
      chauffeur === null)
  )
  const isWarehouseImmutable =
    status.code === LIVRAISON_IMPOSSIBLE.code ||
    status.code === DONE_STATUS.code ||
    status.code === RETRAIT_IMPOSSIBLE.code
  const isEditDisabled =
    getOrderStatus(status) !== COURSE_EDIT_TYPE.editable &&
    getOrderStatus(status) !== COURSE_EDIT_TYPE.partialEdit &&
    getOrderStatus(status) !== COURSE_EDIT_TYPE.semiEdit

  const isSchmidValid =
    (status.code === SUPPLY_WAIT_STATUS.code ||
      status.code === SUPPLY_IN_PROGRESS_STATUS.code ||
      status.code === SUPPLY_COMPLETE_STATUS.code ||
      status.code === SCHEDULE_WAIT_STATUS.code) &&
    category?.toLowerCase() === 'sav'

  const isNavette = courseType !== 'StoreDelivery'
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [openRDvModal, setopenRDvModal] = useState(false)
  const [openUpdateWareHouse, setOpenUpdateWareHousel] = useState(false)
  const [openNavetteAction, setOpenNavetteAction] = useState(false)
  const [openClientDeliveryAction, setOpenClientDeliveryAction] = useState(false)
  const handleCancelOpen = () => {
    setOpenCancelModal(!openCancelModal)
    handleCloseMenuBarre()
  }
  const handleRDvOpen = () => {
    setopenRDvModal(!openRDvModal)
    handleCloseMenuBarre()
  }
  const handleClickOpen = () => {
    setOpen(true)
    handleCloseMenuBarre()
  }
  const handleClose = () => {
    setOpen(false)
    handleCloseMenuBarre()
  }
  const handleUpdateWarehouse = () => {
    setOpenUpdateWareHousel(!openUpdateWareHouse)
    handleCloseMenuBarre()
  }
  const handleNavetteAction = () => {
    setOpenNavetteAction(!openNavetteAction)
    handleCloseMenuBarre()
  }
  const handleClientDelivery = () => {
    setOpenClientDeliveryAction(!openClientDeliveryAction)
    handleCloseMenuBarre()
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const openD = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenuBarre = () => {
    setAnchorEl(null)
  }
  const handleAlertDialogueClose = () => {
    setAlertDialogueOpen(false)
    setProgramDialogOpen(false)
  }
  const isSchmit = courseSource === COURSE_SOURCE_SCHMIDT
  return (
    <>
      <IconButton
        id={'button-popover'}
        onClick={handleClick}
        style={{ padding: '0px', color: '#373D57' }}
        aria-describedby={'simple-popover'}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={'popover-actions'}
        open={openD}
        anchorEl={anchorEl}
        onClose={handleCloseMenuBarre}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 7,
            width: '32ch',
          },
        }}
      >
        <List id={'popover-list'} component="nav">
          {!disabled && (
            <ListItem id={'cancel-course'} button onClick={handleCancelOpen}>
              <Annulation className="IconListItemActionDetails" />
              <StyledListItemText primary="Annuler la course" />
            </ListItem>
          )}

          {canBeSetRDVImpProgram && (
            <>
              <ListItem
                id={'course-programme'}
                button
                onClick={handleProgramDialogOpen}
              >
                <Programmation className="IconListItemActionDetails" />
                <StyledListItemText primary="Programmer la course" />
              </ListItem>

              <ListItem id={'rdv-impossible'} button onClick={handleRDvOpen}>
                <RdvImpossibles className="IconListItemActionDetails" />
                <StyledListItemText primary="Rendez-vous Impossible" />
              </ListItem>
            </>
          )}

          {isDeprogram && (
            <ListItem id={'course-deprogramme'} button onClick={handleClickOpen}>
              <Deprogrammation className="IconListItemActionDetails" />
              <StyledListItemText primary="Déprogrammer course" />
            </ListItem>
          )}

          {!isEditDisabled && (
            <ListItem
              id={'edit-course'}
              button
              value={'/edit/' + codeCourse}
              to={'/edit/' + codeCourse}
              component={Link}
            >
              <Modification className="IconListItemActionDetails" />
              <StyledListItemText primary="Modifier course" />
            </ListItem>
          )}

          {isAttributed && (
            <ListItem
              id={'attribution-warning'}
              button
              onClick={handleAssignDialogOpen}
            >
              <Attribution className="IconListItemActionDetails" />
              <StyledListItemText primary="Attribution Warning" />
            </ListItem>
          )}

          {isDissociate && !isDetailsLivraison && (
            <ListItem
              id={'desattribute-driver'}
              button
              onClick={() => {
                setDissociateOpen(true)
                handleCloseMenuBarre()
              }}
            >
              <DesChauffeur className="IconListItemActionDetails" />
              <StyledListItemText primary="Désattribuer chauffeur" />
            </ListItem>
          )}
          {isSchmit && !isWarehouseImmutable && (
            <ListItem id={'update-warehouse'} button onClick={handleUpdateWarehouse}>
              <UpdateWarehouse className="IconListItemActionDetails" />
              <StyledListItemText primary="Affecter à une autre agence" />
            </ListItem>
          )}
          {isSchmit && isNavette && isSchmidValid && (
            <ListItem id={'update-coursetype'} button onClick={handleNavetteAction}>
              <NavetteIcon className="IconListItemActionDetails" />
              <StyledListItemText primary="Navette" />
            </ListItem>
          )}
          {isSchmit && !isNavette && isSchmidValid && (
            <ListItem id={'update-coursetype'} button onClick={handleClientDelivery}>
              <LivraisonClientIcon className="IconListItemActionDetails" />
              <StyledListItemText primary="Livrasion Client" />
            </ListItem>
          )}
          {canBePrepared && (
            <>
              <Divider />
              <SetPreparedDetails
                label={'Préparée'}
                defaultChecked={isPrepared}
                codeCourse={codeCourse}
              />
            </>
          )}
        </List>
      </Popover>

      {openCancelModal && (
        <CancelShipment
          open={openCancelModal}
          handleClose={handleCancelOpen}
          orderAnnuler={{ shipmentReference, codeCourse, courseSource }}
          payload={{
            collaborateurId: connectedUser.id,
            state: 'ANNULLEE',
            codeCourse: codeCourse,
            courseSource: courseSource,
            driver:
              chauffeur != null && chauffeur?.id ? { id: chauffeur.id } : undefined,
            method: 'corner/annulation',
            datetime: moment(new Date()).format(),
          }}
        />
      )}
      {open && (
        <Deprogram
          open={open}
          handleClose={handleClose}
          payload={{
            collaborateurId: connectedUser.id,
            state: SUPPLY_COMPLETE_STATUS.code,
            codeCourse: codeCourse,
            courseSource: courseSource,
            method: 'corner/déprogrammer',
            datetime: moment(new Date()).format(),
          }}
        />
      )}
      {attributionDialogOpen && (
        <AssignDialog
          hideDialog={() => {
            setAttributionDialogOpen(false)
          }}
          open={attributionDialogOpen}
          selectedShipments={selectedShipments}
        />
      )}
      {programDialogOpen && (
        <Program
          userId={connectedUser.id}
          courseNumber={selectedCourses.length}
          hideDialog={handleAlertDialogueClose}
          open={programDialogOpen}
          selectedCourses={selectedCourses}
          selectedShipments={selectedShipments}
          salesChannelCourses={true}
        />
      )}
      {alertDialogueOpen && (
        <Dialog
          maxWidthDialog={'md'}
          open={alertDialogueOpen}
          title={'Alertes'}
          content={
            <div
              style={{
                paddingRight: '16px',
                paddingBottom: '16px',
                minWidth: '600px',
              }}
            >
              {bloquerprogrammation ? (
                <span>La commande n’est pas encore disponible dans Corner</span>
              ) : (
                <span>
                  {
                    'Attention vous allez programmer une course livraison pas encore réceptionnée'
                  }
                </span>
              )}
            </div>
          }
          handleClose={() => setAlertDialogueOpen(false)}
          handleClickAction={() => {
            setProgramDialogOpen(true) && setAlertDialogueOpen(false)
          }}
          isModalActionFermer={bloquerprogrammation}
        />
      )}
      {dissociateOpen && (
        <Desattribution
          open={dissociateOpen}
          handleClose={() => {
            setDissociateOpen(false)
            handleCloseMenuBarre()
          }}
          payload={{
            collaborateurId: connectedUser.id,
            state: PROGRAMMED_STATUS.code,
            codeCourse: codeCourse,
            courseSource: courseSource,
            driver:
              chauffeur != null && chauffeur?.id ? { id: chauffeur.id } : undefined,
            method: 'Corner/DésattribuerChauffeur',
            datetime: moment(new Date()).format(),
          }}
        />
      )}
      {openRDvModal && (
        <RDVImpossible
          open={openRDvModal}
          handleClose={handleRDvOpen}
          shipmentReference={shipmentReference}
          codeCourse={codeCourse}
          codeCanalVente={canalVente?.code}
        />
      )}
      {openUpdateWareHouse && (
        <UpdateWarehouseModal
          open={openUpdateWareHouse}
          handleClose={handleUpdateWarehouse}
          codeCourse={codeCourse}
        />
      )}
      {openNavetteAction && (
        <Navette
          open={openNavetteAction}
          handleClose={handleNavetteAction}
          codeCourse={codeCourse}
        />
      )}
      {openClientDeliveryAction && (
        <LivraisonClient
          open={openClientDeliveryAction}
          handleClose={handleClientDelivery}
          codeCourse={codeCourse}
        />
      )}
    </>
  )
}
LongMenu.propTypes = {
  canBePrepared: PropTypes.bool,
  isDetailsLivraison: PropTypes.bool,
  shipmentReference: PropTypes.string,
  codeCourse: PropTypes.string,
  status: PropTypes.object,
  isPrepared: PropTypes.bool,
  courseSource: PropTypes.string,
  canalVente: PropTypes.any,
  chauffeur: PropTypes.object,
  configs: PropTypes.any,
  adresseDepart: PropTypes.string,
  options: PropTypes.any,
  isPCHRecieved: PropTypes.bool,
  courseType: PropTypes.string,
  category: PropTypes.string,
  adresseArrivee: PropTypes.string,
  ldvRelatedCourse: PropTypes.string,
  deliveryRelated: PropTypes.string,
  serviceTime: PropTypes.number,
}
