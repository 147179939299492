import { connect } from 'react-redux'
import PlaceAssembly from '../../../pages/main/PlaceOrders/Assembly'
import { getAssemblySalesChannelList } from '../../../redux/filter/selectors'
import {
  updateOrderAssemblyAction,
  setAssemblyErrorsAction,
  setOrderAssemblyPriceAction,
  updateMarkersAction,
  removeOrderAssemblyAction,
  estimateAssemblyPriceAction,
  removeAssemblyAction,
  updateDirectionsAction,
  addOrderAction,
} from '../../../redux/shipment/actions'

import {
  getIsGetDirectionRouteLoading,
  getDirectionRoute,
  getDirectionRouteList,
} from '../../../redux/googleMapDirections/selectors'

import {
  getGoogleMapDirectionRequest,
  findDirectionAction,
} from '../../../redux/googleMapDirections/actions'

import { getOneWeekShipments, getCanalVente } from '../../../redux/shipment/actions'

import {
  getCanalVenteLoading,
  getErrorGetSalesChannel,
  getCanalVenteResponse,
} from '../../../redux/shipment/selectors'

import { getSalesChannelListRequest } from '../../../redux/filter/actions'

const mapStateToProps = (state) => ({
  assemblySalesChannelList: getAssemblySalesChannelList(state),
  directionRouteList: getDirectionRouteList(state),
  directionRoute: getDirectionRoute(state),
  isGetDirectionRouteLoading: getIsGetDirectionRouteLoading(state),
  canalVente: getCanalVenteResponse(state),
  isGetCanalVenteLoading: getCanalVenteLoading(state),
  errorGetSalesChannel: getErrorGetSalesChannel(state),
})

const mapDisptachToProps = (dispatch) => ({
  removeAssembly: (orId) => dispatch(removeAssemblyAction(orId)),
  setOrderAssemblyPrice: (orId, price) =>
    dispatch(setOrderAssemblyPriceAction(orId, price)),
  setAssemblyErrors: (orId, errors) =>
    dispatch(setAssemblyErrorsAction(orId, errors)),
  removeOrderAssembly: (order) => dispatch(removeOrderAssemblyAction(order)),
  updateOrderAssembly: (orId, assembly) =>
    dispatch(updateOrderAssemblyAction(orId, assembly)),
  updateMarkers: (mrId, data) => dispatch(updateMarkersAction(mrId, data)),
  estimatePrice: (orId, selectedSalesChannel, purchaseAmount, date, time) =>
    dispatch(
      estimateAssemblyPriceAction(
        orId,
        selectedSalesChannel,
        purchaseAmount,
        date,
        time
      )
    ),
  addOrder: (type) => dispatch(addOrderAction(type)),

  getGoogleMapDirection: (id, origin, destination) =>
    dispatch(getGoogleMapDirectionRequest(id, origin, destination)),
  findDirection: (id, origin, destination) =>
    dispatch(findDirectionAction(id, origin, destination)),
  getOneWeekShipments: (salesChannelCode) =>
    dispatch(getOneWeekShipments(salesChannelCode)),
  updateDirections: (dirId, data) => dispatch(updateDirectionsAction(dirId, data)),
  getSalesChannels: () => dispatch(getSalesChannelListRequest()),
  getCanalVente: (codeCanal) => dispatch(getCanalVente(codeCanal)),
})

export default connect(mapStateToProps, mapDisptachToProps)(PlaceAssembly)
