import React, { useState } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Popover from '@mui/material/Popover'
import Attribution from '../../../../assets/icons/detailsLivraison/MdiTruckCheck'
import Modification from '../../../../assets/icons/detailsLivraison/IcRoundCreate'
import Annulation from '../../../../assets/icons/detailsLivraison/IcOutlineCancel'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Dialog from '../../../../components/ModalDialog'
import EquipageDialog from '../../../../containers/main/Optimiser'
import { getDeliveryDate } from '../../../../utils/utils'
import DialogAvertissement from '../../../../components/DialogError/dialogAvertissementClose'
import { StyledListItemActions, StyledBlocButton, StyledRootToolbar } from './style'

export default function TourActions({
  numSelected,
  selectedTours,
  deleteTours,
  getDriversPlannings,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteDialogOpen, setDeleteDialog] = useState(false)
  const [affectDialogOpen, setAffectDialog] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [selectedCourses, setSelectedCourses] = useState([])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDeleteDialog = () => {
    setDeleteDialog(true)
  }

  const handleOpenEquipageDialog = () => {
    setSelectedCourses(
      selectedTours.map((tour) => [
        ...new Set(
          tour.visits
            .map((visit) => visit.course.codeCourse)
            .filter((codeCourse) => codeCourse.includes('COURSE-'))
        ),
      ])
    )
    setAffectDialog(true)
  }

  const handleDeleteTours = () => {
    const payload = {
      ldt: selectedTours.map((tour) => tour.ldt),
      userId: String(JSON.parse(localStorage.getItem('currentUser'))?.id),
    }

    deleteTours(payload)
  }
  const attribuer = () => {
    const isSameDeliveryDate = getDeliveryDate(selectedTours)

    if (!isSameDeliveryDate) {
      setOpenError(true)
    } else {
      const driversAttribuer = selectedTours.map((tour) => tour.driver.id)
      const drivers = selectedTours.map((tour) => {
        return tour.driverDetails
      })
      const objectFleet = {
        drivers,
        maxVisitLatenes: 1,
        maxOvertime: 1,
        minVehicule: false,
        shortestDistance: false,
        // verify options cause Routific VRP doesn't accept balence with balenceCoef
        balence: undefined,
        balenceCoef: 1,
        deliveryDate: selectedTours[0].deliveryDate,
      }
      const payload = {
        deliveryDate: selectedTours[0].deliveryDate,
        driverId: driversAttribuer,
      }

      getDriversPlannings({ data: payload, fleet: objectFleet })
    }
  }

  const open = Boolean(anchorEl)
  return (
    <StyledRootToolbar>
      {numSelected == 1 ? (
        <Typography
          sx={{ flex: '100%' }}
          variant="h6"
          color="inherit"
          component="div"
          id="tourTableTitle-one-selected"
        >
          {numSelected} Tournée est déja sélectionnée
        </Typography>
      ) : numSelected > 1 ? (
        <Typography
          sx={{ flex: '100%' }}
          variant="h6"
          color="inherit"
          component="div"
          id="tourTableTitle-moreThanOne-selected"
        >
          {numSelected} Tournées sont déja sélectionnées
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '100%' }}
          variant="h6"
          id="tourTableTitle"
          component="div"
        >
          Tournées
        </Typography>
      )}

      {numSelected > 0 && (
        <div>
          <StyledBlocButton
            id={'tour_button_popover'}
            aria-controls="simple-menu"
            aria-haspopup="true"
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Actions
            {open ? <ExpandLess /> : <ExpandMore />}
          </StyledBlocButton>
          <Popover
            id={'tour_popover-actions'}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {
                maxHeight: 48 * 3,
                width: '30ch',
              },
            }}
          >
            <div>
              <List id={'popover-list'} component="nav">
                <StyledListItemActions
                  id={'delete-tour'}
                  button
                  onClick={() => {
                    handleOpenDeleteDialog()
                    handleClose()
                  }}
                >
                  <Annulation className="IconListItemActionDetails" />
                  <ListItemText primary="Supprimer" />
                </StyledListItemActions>
                <StyledListItemActions
                  id={'update-tour'}
                  button
                  onClick={() => {
                    attribuer()
                    handleClose()
                  }}
                >
                  <Modification className="IconListItemActionDetails" />
                  <ListItemText primary="Modifier" />
                </StyledListItemActions>
                {numSelected == 1 && (
                  <StyledListItemActions
                    id={'affect-tour'}
                    button
                    onClick={() => {
                      handleOpenEquipageDialog()
                      handleClose()
                    }}
                  >
                    <Attribution className="IconListItemActionDetails" />
                    <ListItemText primary="Affecter Tournées" />
                  </StyledListItemActions>
                )}
              </List>
            </div>
          </Popover>
          {deleteDialogOpen && (
            <Dialog
              open={deleteDialogOpen}
              title={'Confirmation'}
              content={
                <div style={{ paddingBottom: '16px', paddingRight: '16px' }}>
                  {'Voulez vous confirmer la suppression?'}
                </div>
              }
              handleClose={() => setDeleteDialog(false)}
              handleClickAction={() => handleDeleteTours()}
              maxWidthDialog={'xs'}
              onConfirm={() => handleDeleteTours()}
            />
          )}
          {affectDialogOpen && (
            <EquipageDialog
              ldt={selectedTours[0].ldt}
              open={affectDialogOpen}
              hideDialog={() => {
                setAffectDialog(false)
              }}
              selectedCourses={selectedCourses}
              shipments={selectedTours}
              dropoffStartCourses={selectedTours[0].deliveryDate}
              equalDropoffStartCourses={true}
            />
          )}
          {openError && (
            <DialogAvertissement
              messageErreur={
                'Vous avez sélectionner des tournées avec différents date de livraison'
              }
              open={openError}
              onClose={() => {
                setOpenError(false)
              }}
            />
          )}
        </div>
      )}
    </StyledRootToolbar>
  )
}

TourActions.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selectedTours: PropTypes.array,
  deleteTours: PropTypes.func,
  getDriversPlannings: PropTypes.func,
}
