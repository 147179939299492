import React from 'react'

export function FluentPersonSettings16Filled(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9.626 5.07a5.493 5.493 0 0 0-3.299 1.847A2.751 2.751 0 1 1 9.626 5.07ZM5.6 8c-.384.75-.6 1.6-.6 2.5c0 1.31.458 2.512 1.222 3.457C3.555 13.653 2 11.803 2 10v-.5A1.5 1.5 0 0 1 3.5 8h2.1Zm1.035 1.92a2 2 0 0 0 1.43-2.478l-.156-.557c.254-.195.53-.362.822-.497l.337.358a2 2 0 0 0 2.91.001l.325-.344c.297.14.577.315.834.518l-.126.423a2 2 0 0 0 1.456 2.519l.35.082a4.699 4.699 0 0 1 .01 1.017l-.461.118a2 2 0 0 0-1.43 2.477l.155.557a4.35 4.35 0 0 1-.822.498l-.337-.358a2 2 0 0 0-2.91-.002l-.324.344a4.322 4.322 0 0 1-.835-.518l.126-.422a2 2 0 0 0-1.456-2.52l-.35-.082a4.7 4.7 0 0 1-.01-1.016l.462-.118Zm4.865.58a1 1 0 1 0-2 0a1 1 0 0 0 2 0Z"
      ></path>
    </svg>
  )
}
export default FluentPersonSettings16Filled
