import { connect } from 'react-redux'

import TourneeList from '../../../pages/settings/tournee'

import {
  fetchTournees,
  onSearch,
  pageChanged,
  pageLimitChanged,
  removeError,
  resetSuccessAction,
  resetTourneeConfig,
} from '../../../redux/tournees/actions'
import {
  getErrorMessage,
  getIsErrorFetch,
  getIsLoadingTourneesList,
  getIsSuccessRefresh,
  getSuccess,
  getTourneesList,
  getTourneesListCount,
} from '../../../redux/tournees/selectors'

const mapStateToProps = (state) => ({
  isLoadingfetchTournees: getIsLoadingTourneesList(state),
  success: getSuccess(state),
  tourneesList: getTourneesList(state),
  tourneesListCount: getTourneesListCount(state),
  errorMessage: getErrorMessage(state),
  isErrorFetch: getIsErrorFetch(state),
  isSuccessRefresh: getIsSuccessRefresh(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchTournees()),
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(fetchTournees())
  },
  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(fetchTournees())
  },
  searchData: (search) => {
    dispatch(onSearch(search))
    dispatch(fetchTournees())
  },
  onCloseReset: () => dispatch(resetTourneeConfig()),
  removeError: () => dispatch(removeError()),
  resetSuccessAction: () => dispatch(resetSuccessAction()),
})

export default connect(mapStateToProps, mapDisptachToProps)(TourneeList)
