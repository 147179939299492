import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'

export default function TextAdresse({ classStyle, value }) {
  const handleClick = () => {
    window.open(
      `https://www.google.com/maps?q=${value.latitude},${value.longitude}&ll=${value.latitude},${value.longitude}&z=17`,
      '_blank'
    )
  }
  return (
    <Tooltip arrow title={<span>{value.address}</span>}>
      <a style={classStyle} onClick={() => handleClick()}>
        {value.address}
      </a>
    </Tooltip>
  )
}
TextAdresse.propTypes = {
  value: PropTypes.shape({
    address: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  classStyle: PropTypes.object,
}
