import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@mui/material/FormHelperText'

import './style.scss'

/** An error text helper for forms  */
export default function FormHelperTextError({ content, ishidden }) {
  return (
    <FormHelperText
      error={true}
      className={['form-text-error', ishidden ? 'hidden' : ''].join(' ')}
    >
      {content}
    </FormHelperText>
  )
}
FormHelperTextError.propTypes = {
  /** If True this component will be hidden */
  ishidden: PropTypes.bool,
  /** The text content of the error form helper */

  content: PropTypes.any,
}
