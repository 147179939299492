export const OrderInitialState = {
  orders: [],
  myCommandsIsOn: false,
  count: 0,

  isLoadingSearchCorner: false,
  coursesSearch: [],
  coursesSearchLimit: 0,
  coursesSearchOffset: 0,
  coursesSearchCount: 0,

  isLoadingRelatedCourse: false,
  relatedCourse: null,

  delayedOrders: [],

  selectedCourses: {},

  order: null,
  isLoading: false,
  error: null,

  orderSignature: [],
  isActionOrderSignatureDone: false,
  isLoadingOrderSignature: false,
  isLoadingUploadSignature: false,

  errorUploadFile: false,

  isLoadingUploadFile: false,
  orderAttachments: [],
  isLoadingOrderAttachments: false,
  isActionOrderAttachmentsDone: false,

  orderNotification: [],
  isLoadingOrderNotification: false,

  isLoadingHistoriqueDeltaCost: false,
  orderHistoriqueDeltaCost: null,

  isLoadingHistoriqueLivraison: false,
  orderHistoriqueLivraison: [],

  isLoadingActivityHistory: false,
  orderActivityHistory: [],

  isLoadingReasonsOrder: false,
  orderReasons: [],

  errorActionCourse: null,

  isActionOrderDone: false,
  isLoadingActionOrder: false,

  isLoadingGetCollaborateurs: false,
  isLoadingGetClientsPro: false,
  collaborateurs: [],
  clientsPro: [],

  orderHistoriqueRDV: [],
  isLoadingHistoriqueRDV: false,

  orderPhoto: [],
  isLoadingOrderPhoto: false,
  isLoadingUploadPhoto: false,
  isActionOrderPhotoDone: false,

  isLoadingRefresh: false,

  macroCanals: [],
  isLoadingMacroCanal: false,
  errorImportCourses: null,
  isLoadingActionImportCourses: false,
  isActionImportCourses: false,
  errorImportCoursesMessage: [],

  duplicatedCourse: false,

  isLoadingActitvity: false,
  orderActivity: [],
  errorActivity: null,

  coursesChecked: [],
  checkCoursesAll: false,

  errorActionChangeStatusEnMasse: null,
  isErrorActionWithSuccess: false,

  orderQuestions: [],
  isLoadingOrderQuestions: false,

  isLoadingGetConfig: false,
  configCanal: [],
}
