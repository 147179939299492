import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DashboardCellHeaderActions from './DashboardCellHeaderActions'
import PropTypes from 'prop-types'

export default function DashboardCellHeader({
  icon,
  title,
  onWindowResize,
  windowSize,
  expand,
  onExpandWindow,
}) {
  return (
    <div className="dashboard-cell-header">
      <div className="dashboard-cell-header-title">
        <FontAwesomeIcon icon={icon} /> {title}
      </div>
      <DashboardCellHeaderActions
        onWindowResize={onWindowResize}
        windowSize={windowSize}
        expand={expand}
        onExpandWindow={onExpandWindow}
      />
    </div>
  )
}
DashboardCellHeader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  onWindowResize: PropTypes.func,
  onExpandWindow: PropTypes.func,
  windowSize: PropTypes.string,
  expand: PropTypes.bool,
}
