import Tooltip from '@mui/material/Tooltip/Tooltip'
import NotAvailable from './NotAvailable'
import TableCell from '@mui/material/TableCell/TableCell'
import React, { useState } from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import Clipbloard from '../../../../components/Clipboard'
import { Link } from 'react-router-dom'
export default function LongTextWithClipboardComponent({
  message,
  text,
  display,
  ...props
}) {
  const [show, setShow] = useState(false)
  const onCopy = () => {
    let content = document.getElementById('ldv-reference')
    let textArea = document.createElement('INPUT')
    textArea.value = text
    content.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.execCommand('copy')
    content.removeChild(textArea)
  }
  return (
    display && (
      <TableCell>
        {text ? (
          <div
            style={{ display: 'flex', padding: '0 15px', cursor: 'pointer' }}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          >
            <Tooltip arrow title={<span className="tootltip-content">{text}</span>}>
              <Link
                value={'/deliveries/' + text}
                to={'/deliveries/' + text}
                style={{
                  color: 'unset',
                  textDecoration: 'none',
                  margin: 'auto',
                }}
              >
                <p
                  className="long-text-overflow"
                  {...props}
                  id="ldv-reference"
                  style={{ width: 100 }}
                >
                  {text}
                </p>
              </Link>
            </Tooltip>
            <div style={{ width: 20 }}>
              <Clipbloard onCopy={onCopy} show={show} message={message} />
            </div>
          </div>
        ) : (
          <NotAvailable />
        )}
      </TableCell>
    )
  )
}
LongTextWithClipboardComponent.propTypes = {
  text: PropTypes.string,
  display: PropTypes.bool,
  message: PropTypes.string,
}
