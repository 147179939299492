import { ROLE } from '../../../../../constants/appContants'

export const clientFormData = (others) => {
  return {
    ...others,
    firstname: '',
    lastname: '',
    phone: '',
    mail: '',
    nonEnvoiMail: false,
    noteInterne: '',
    type: ROLE.PARTICULIER,
    factures: [''],
    articleFamilies: [],
    vip: false,
    addressQuerry: '',
    selectedAddress: '',
    noEmail: false,
  }
}
