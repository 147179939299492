import React from 'react'
import PropTypes from 'prop-types'
import LongMenu from '../../../../../../components/ShipmentAction'
import {
  COURSE_EDIT_TYPE,
  getOrderStatus,
} from '../../../../../../utils/getOrderStatus'
import {
  PROGRAMMED_STATUS,
  DECHARGEMENT_EN_COURS_STATUS,
  VERS_ENLEVEMENT_STATUS,
  CHARGEMENT_STATUS,
  ENLEVEE_STATUS,
  ASSIGNED_STATUS,
  VERS_DECHARGEMENT_STATUS,
  CANCELED_STATUS_LIST,
  TERMINEE_STATUS,
  SUPPLY_WAIT_STATUS,
  SUPPLY_IN_PROGRESS_STATUS,
  SUPPLY_COMPLETE_STATUS,
  DONE_STATUS,
  LIVRAISON_IMPOSSIBLE,
  RETRAIT_IMPOSSIBLE,
  CANCELED_STATUS,
  CANCELED_STATUS_LIST_DESATRIBUER,
  CREER,
  COURSE_SOURCE_SCHMIDT,
  SCHEDULE_WAIT_STATUS,
} from '../../../../../../utils/values'
import Actions from '../../../../../../assets/icons/detailsLivraison/RiListSettingsLine'
import { CardSyled, TitleWithIcon } from '../card/style'
export default function CardActions({ order, isLoading }) {
  if (order != null) {
    const {
      code,
      commande: { canalVente },
      lettreDeVoiture,
      status,
      isPrepared,
      courseSource,
      chauffeur,
      adresseDepart,
      options,
      isPCHRecieved,
      adresseArrivee,
      courseMetadata,
      deliveryRelated,
      ldvRelatedCourse,
      serviceTime,
    } = order

    const configs = canalVente ? canalVente.configs : null
    const disabled =
      CANCELED_STATUS_LIST.indexOf(status.code) !== -1 ||
      TERMINEE_STATUS.code === status.code

    const isDeprogram =
      (status.code === PROGRAMMED_STATUS.code ||
        status.code === DECHARGEMENT_EN_COURS_STATUS.code ||
        status.code === ASSIGNED_STATUS.code ||
        status.code === VERS_ENLEVEMENT_STATUS.code ||
        status.code === CHARGEMENT_STATUS.code ||
        status.code === ENLEVEE_STATUS.code ||
        status.code === VERS_DECHARGEMENT_STATUS.code) &&
      configs &&
      configs.hasSupplyStep

    const isEditDisabled =
      getOrderStatus(status) !== COURSE_EDIT_TYPE.editable &&
      getOrderStatus(status) !== COURSE_EDIT_TYPE.partialEdit &&
      getOrderStatus(status) !== COURSE_EDIT_TYPE.semiEdit
    const canBePrepared =
      configs?.hasPreparedOption &&
      status.code === PROGRAMMED_STATUS.code &&
      (courseSource === 'corner' || courseSource === 'saas_pro')

    const canBeSetRDVImpProgram =
      (status.code === SUPPLY_WAIT_STATUS.code ||
        status.code === SUPPLY_IN_PROGRESS_STATUS.code ||
        status.code === SUPPLY_COMPLETE_STATUS.code) &&
      configs &&
      configs.hasSupplyStep
    const isAttributed = status.code === PROGRAMMED_STATUS.code
    const isDissociate = !(
      status.code === DONE_STATUS.code ||
      status.code === LIVRAISON_IMPOSSIBLE.code ||
      status.code === RETRAIT_IMPOSSIBLE.code ||
      status.code === CANCELED_STATUS.code ||
      CANCELED_STATUS_LIST_DESATRIBUER.includes(status.code) ||
      ((status.code === PROGRAMMED_STATUS.code || status.code === CREER.code) &&
        chauffeur === null)
    )
    const isSchmit = courseSource === COURSE_SOURCE_SCHMIDT

    const isWarehouseImmutable =
      status.code === LIVRAISON_IMPOSSIBLE.code ||
      status.code === DONE_STATUS.code ||
      status.code === RETRAIT_IMPOSSIBLE.code

    const isNavette = courseMetadata?.courseType !== 'StoreDelivery'

    const isSchmidValid =
      (status.code === SUPPLY_WAIT_STATUS.code ||
        status.code === SUPPLY_IN_PROGRESS_STATUS.code ||
        status.code === SUPPLY_COMPLETE_STATUS.code ||
        status.code === SCHEDULE_WAIT_STATUS.code) &&
      courseMetadata?.category?.toLowerCase() === 'sav'
    return (
      <>
        {(!isEditDisabled ||
          isDeprogram ||
          !disabled ||
          canBePrepared ||
          canBeSetRDVImpProgram ||
          isAttributed ||
          isDissociate ||
          (isSchmit && !isWarehouseImmutable) ||
          (isSchmit && isNavette && isSchmidValid) ||
          (isSchmit && !isNavette && isSchmidValid)) &&
          !isLoading && (
            <CardSyled>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '10px 20px 10px 1px',
                }}
              >
                <TitleWithIcon>
                  <Actions
                    style={{ marginRight: '4px', width: '20px', height: '20px' }}
                  />
                  Actions
                </TitleWithIcon>
                <div>
                  <LongMenu
                    configs={configs}
                    canBePrepared={canBePrepared}
                    shipmentReference={lettreDeVoiture?.code}
                    codeCourse={code}
                    status={status}
                    isPrepared={isPrepared === true}
                    courseSource={courseSource}
                    isDetailsLivraison={true}
                    chauffeur={chauffeur}
                    adresseDepart={adresseDepart}
                    options={options}
                    canalVente={canalVente}
                    isPCHRecieved={isPCHRecieved}
                    courseType={courseMetadata?.courseType}
                    category={courseMetadata?.category}
                    adresseArrivee={adresseArrivee}
                    deliveryRelated={deliveryRelated}
                    ldvRelatedCourse={ldvRelatedCourse}
                    serviceTime={serviceTime}
                  />
                </div>
              </div>
            </CardSyled>
          )}
      </>
    )
  }
  return null
}
CardActions.propTypes = {
  order: PropTypes.object,
  isLoadingDriversWarnnig: PropTypes.bool,
  isLoading: PropTypes.bool,
}
