import Fab from '@mui/material/Fab'
import noteImg from '../../../../assets/images/note.png'
// import NotAvailable from './NotAvailable'
import TableCell from '@mui/material/TableCell/TableCell'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
export default function Note({ noteInterne, display, ldv }) {
  const [show, setShow] = useState(false)
  return (
    display && (
      <TableCell>
        {noteInterne && noteInterne?.length > 0 && (
          <>
            <Fab
              sx={{ zIndex: 0 }}
              aria-label="close"
              className="fab-button fab-transparent"
              onClick={() => setShow(true)}
            >
              <img src={noteImg} alt="note" style={{ width: '25px' }} />
            </Fab>
            <Dialog
              open={show}
              title={`Note interne - ${ldv?.code}`}
              content={
                <div style={{ paddingBottom: '16px', paddingRight: '16px' }}>
                  {noteInterne}
                </div>
              }
              handleClose={() => {
                setShow(false)
              }}
              maxWidthDialog={'md'}
              isModalActionFermer={true}
            />
          </>
        )}
      </TableCell>
    )
  )
}
Note.propTypes = {
  noteInterne: PropTypes.string,
  display: PropTypes.bool,
  ldv: PropTypes.shape({
    code: PropTypes.string,
  }),
}
