import React from 'react'
import PropTypes from 'prop-types'
import DetailsIcon from '../../../../../../assets/icons/detailsLivraison/iconDetails'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { getCourseType } from '../../../../../../utils/utils'
import CircularProgress from '@mui/material/CircularProgress'
import CardAccordion from '../card/cardWithAccordion'
export default function DetailsLivraison({
  order,
  isLoading,
  isLoadingPackageOrder,
  warehousePackage,
}) {
  return (
    <>
      {/* 
            Détails:
                Date de commande : order.createdAt
                Manutention : order.manutention
                Nombre de colis : order.packages.length
                Véhicule: order.vehicule.vehicule_category.code
                Références : order.factures 
                Type de course : order.courseMetadata.courseType
                Type de paiement : order.commande.moyenPaiement.label
        */}
      <CardAccordion
        icon={
          <DetailsIcon
            style={{ marginRight: '4px', width: '20px', height: '20px' }}
          />
        }
        title={'Détails'}
        content={
          <>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <Grid container>
                {order?.commande?.canalVente?.name && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Canal de Vente :</span>
                    <span>{order.commande.canalVente.name}</span>
                  </Grid>
                )}
                {order?.commande?.canalVente?.configs?.emergencyPhone && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Numéro d&apos;urgence :</span>
                    <span>{order?.commande.canalVente.configs.emergencyPhone}</span>
                  </Grid>
                )}

                {(warehousePackage || isLoadingPackageOrder) && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Nom d&apos;agence :</span>
                    {isLoadingPackageOrder ? (
                      <div
                        style={{
                          display: 'flex',
                          marginTop: '9px',
                          justifyContent: 'center',
                        }}
                      >
                        <CircularProgress
                          style={{ width: '30px', height: '30px' }}
                        />
                      </div>
                    ) : (
                      <span>{warehousePackage}</span>
                    )}
                  </Grid>
                )}

                {order?.createdAt && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Date de commande :</span>
                    <span>{moment(order.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                  </Grid>
                )}
                {order?.manutention != undefined && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Manutention :</span>
                    <span>
                      {order?.manutentionDouble != undefined &&
                      order.manutentionDouble
                        ? 'double'
                        : order.manutention
                        ? 'oui'
                        : 'non'}
                    </span>
                  </Grid>
                )}
                {order?.nombreColis != null && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Nombre de colis :</span>
                    <span>{order?.nombreColis}</span>
                  </Grid>
                )}
                {order?.vehicule?.vehicule_category?.code && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Véhicule:</span>
                    <span>{order.vehicule.vehicule_category.code}</span>
                  </Grid>
                )}
                {order?.commande?.partnerOrderNumber && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Numéro de commande du partenaire :</span>
                    <span style={{ overflowWrap: 'anywhere' }}>
                      {order.commande.partnerOrderNumber}
                    </span>
                  </Grid>
                )}
                {order?.code && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Code Course :</span>
                    <span>{order.code}</span>
                  </Grid>
                )}
                {order?.factures && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Référence client :</span>
                    <span>{order.factures}</span>
                  </Grid>
                )}
                {order?.courseMetadata?.courseType && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Type de course :</span>
                    <span>{getCourseType(order.courseMetadata.courseType)}</span>
                  </Grid>
                )}
                {order?.serviceTime != null &&
                  order?.serviceTime != undefined &&
                  order?.serviceTime > 0 && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '15px 20px 10px 20px',
                        borderBottom: '1px solid #f5f5f5',
                        flexWrap: 'wrap',
                        boxSizing: 'border-box',
                      }}
                    >
                      <span>Temps de service :</span>
                      <span>{`${order?.serviceTime} min`}</span>
                    </Grid>
                  )}

                {order?.commande?.moyenPaiement?.label && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Type de paiement :</span>
                    <span>{order.commande.moyenPaiement.label}</span>
                  </Grid>
                )}
                {order?.volume != null && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Volume :</span>
                    <span>{`${order?.volume} m³`}</span>
                  </Grid>
                )}
                {order?.weight != null && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Poids :</span>
                    <span>{`${order?.weight} Kg`}</span>
                  </Grid>
                )}
                {order?.estimatedKM != null && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Distance :</span>
                    <span>{`${order?.estimatedKM} KM`}</span>
                  </Grid>
                )}
                {order?.courseMetadata?.centiroDeliveryMethod != null && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Méthode de livraison :</span>
                    <span>{`${order?.courseMetadata?.centiroDeliveryMethod} KM`}</span>
                  </Grid>
                )}
                {order?.isPrepared != null && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Commande préparée :</span>
                    <span>{order?.isPrepared ? 'oui' : 'non'}</span>
                  </Grid>
                )}
                {order?.commande?.canalVente?.businessUnit && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Business Unit :</span>
                    <span>{order.commande.canalVente.businessUnit}</span>
                  </Grid>
                )}
                {order?.commande?.etatPaiement && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Etat de paiement :</span>
                    <span>{order.commande.etatPaiement}</span>
                  </Grid>
                )}
                {order?.commande?.stripeInvoicePaymentLink && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '15px 20px 10px 20px',
                      borderBottom: '1px solid #f5f5f5',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>Lien de facture Stripe :</span>
                    <span>
                      <a
                        style={{
                          color: '#104687',
                          textDecoration: 'none',
                          overflowWrap: 'anywhere',
                        }}
                        href={order.commande.stripeInvoicePaymentLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {order.commande.stripeInvoicePaymentLink}
                      </a>
                    </span>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        }
      />
    </>
  )
}
DetailsLivraison.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  warehousePackage: PropTypes.string,
  isLoadingPackageOrder: PropTypes.bool,
}
