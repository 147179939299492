import { OrderPackageConstants } from './constants'

//Reset order Package
export const resetOrderPackage = () => ({
  type: OrderPackageConstants.RESET_ORDER_PACKAGE,
})

//Fetch order Package
export const getOrderPackage = (ldv) => ({
  type: OrderPackageConstants.GET_PACKAGES_ORDER,
  payload: { ldv },
})

//Fetch motif Etat package (Pointage)
export const getPackageStatus = () => ({
  type: OrderPackageConstants.GET_PACKAGES_STATUS,
})

//Action Pointage package
export const onPointagePackages = (payload) => ({
  type: OrderPackageConstants.POINTAGE_PACKAGES,
  payload,
})

//Action Forcage Pointage package
export const onPointageForcePackage = (payload) => ({
  type: OrderPackageConstants.POINTAGE_FORCE_PACKAGES,
  payload,
})

//Set error upload image litige
export const setErrorUploadImageLitige = (isError) => ({
  type: OrderPackageConstants.SET_ERROR_UPLOAD_IMAGE_LITIGE,
  payload: { isError },
})

//Upload image litige
export const onUploadImageLitige = (codePackage, imageLitige) => ({
  type: OrderPackageConstants.UPLOAD_IMAGE_LITIGE,
  payload: { codePackage, imageLitige },
})

//Set Loading Upload Image Litige
export const setLoadingUploadImageLitige = (loadingUploadImageLitige) => ({
  type: OrderPackageConstants.SET_LOADING_UPLOAD_IMAGE_LITIGE,
  payload: { loadingUploadImageLitige },
})

//Delete Image Litige Packages
export const deleteImageLitigePackages = () => ({
  type: OrderPackageConstants.DELETE_IMAGE_LITIGE,
})

//Fetch Motif Litige Packages
export const getPackageLitigeMotif = () => ({
  type: OrderPackageConstants.GET_PACKAGES_LITIGE_MOTIF,
})

//Fetch Motif  Responsabilite Litige Packages
export const getPackageLitigeResponsabilite = () => ({
  type: OrderPackageConstants.GET_PACKAGES_LITIGE_RESPONSABILITE,
})

//Fetch Litige Historique Package
export const getLitigeHistoriquePackage = (idLitige) => ({
  type: OrderPackageConstants.GET_LITIGE_HISTORIQUE_PACKAGE,
  payload: { idLitige },
})

//Fetch Details Produits
export const getDetailsProduits = (idPackage) => ({
  type: OrderPackageConstants.GET_PRODUITS_PACKAGE,
  payload: { idPackage },
})

//Fetch Package Historiques
export const getPackageHistoriques = (idPackage) => ({
  type: OrderPackageConstants.GET_HISTORIQUES_PACKAGE,
  payload: { idPackage },
})

//Fetch Photos Historique Package
export const getPhotosHistoriquePackage = (packageTraceability) => ({
  type: OrderPackageConstants.GET_PHOTOS_HISTORIQUE_PACKAGE,
  payload: { packageTraceability },
})

//Fetch Etat Package
export const getEtatPackage = (ldv) => ({
  type: OrderPackageConstants.GET_ETAT_PACKAGE,
  payload: { ldv },
})

//Annulation Livraison Partielle
export const annulerLivraisonPartielle = () => ({
  type: OrderPackageConstants.ANNULATION_LIVRAISON_PARTIELLE_PACKAGE,
})

//Livraison Partielle
export const onCreationCourseLivraisonPartielle = (payload) => ({
  type: OrderPackageConstants.LIVRAISON_PARTIELLE_PACKAGE,
  payload: { payload },
})

//Fetch config MDM canalonfig
export const getCanalConfigMDM = (codeCanal) => ({
  type: OrderPackageConstants.GET_PAKAGE_CONFIG_MDM,
  payload: { codeCanal },
})
//Upload image mdm
export const onUploadImageMDM = (barCode, file) => ({
  type: OrderPackageConstants.UPLOAD_IMAGE_MDM,
  payload: { barCode, file },
})

//Set Loading Upload Image MDM
export const setLoadingUploadImageMDM = (loadingUploadImageMDM) => ({
  type: OrderPackageConstants.SET_LOADING_UPLOAD_IMAGE_MDM,
  payload: { loadingUploadImageMDM },
})

//Edit Package
export const onEditPackage = (payload, id) => ({
  type: OrderPackageConstants.EDIT_PACKAGE,
  payload: { payload, id },
})
