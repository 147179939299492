import { connect } from 'react-redux'

import ConfigurationDriver from '../../../pages/settings/DriversKeys'
import {
  fetchAssignedDriver,
  onSearch,
  pageChanged,
  pageLimitChanged,
  removeErrorConfig,
  resetConfig,
  resetSuccessAction,
} from '../../../redux/configuration/actions'

import {
  getAssignedDriverCount,
  getAssignedDriverIsloading,
  getAssignedDrivers,
  getConfigSuccess,
  getErrorMessageConfig,
  getIsErrorFetch,
  getIsSuccessRefresh,
} from '../../../redux/configuration/selectors'

const mapStateToProps = (state) => ({
  isLoadingDriverList: getAssignedDriverIsloading(state),
  success: getConfigSuccess(state),
  drivers: getAssignedDrivers(state),
  driverCount: getAssignedDriverCount(state),
  errorMessage: getErrorMessageConfig(state),
  isErrorFetch: getIsErrorFetch(state),
  isSuccessRefresh: getIsSuccessRefresh(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchAssignedDriver()),
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(fetchAssignedDriver())
  },

  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(fetchAssignedDriver())
  },
  searchData: (search) => {
    dispatch(onSearch(search))
    dispatch(fetchAssignedDriver())
  },
  onCloseReset: () => dispatch(resetConfig()),
  removeError: () => dispatch(removeErrorConfig()),
  resetSuccessAction: () => dispatch(resetSuccessAction()),
})

export default connect(mapStateToProps, mapDisptachToProps)(ConfigurationDriver)
