import { connect } from 'react-redux'
import Payment from '../../../pages/main/EditOrders/Payment/index'

import {
  getTotalPriceState,
  getSelectedlientState,
  getOrdersState,
  getPaymentTypes,
  getIsNewClientState,
  getPaymentForm,
  getLettreDeVoiture,
  getOldOrder,
  getIsOrderDisabled,
  getClientState,
} from '../../../redux/shipment/selectors'

import {
  hidePopUp,
  setCommand,
  setPaymentForm,
  updateShipment,
} from '../../../redux/shipment/actions'
import {
  getCodeAssembly,
  getCodePromoVerification,
  getNewCreatedCourse,
  getShowPopUp,
  isLoading,
  getErrorMessage,
  getCommand,
} from '../../../redux/shipment/selectors'
import { COURSE_EDIT_TYPE } from '../../../utils/getOrderStatus'

const mapStateToProps = (state) => ({
  totalPrice: getTotalPriceState(state),
  codePromotion: getCodePromoVerification(state),
  selectedClient: getSelectedlientState(state),
  order: getOrdersState(state)[0],
  paymentTypes: getPaymentTypes(state),
  showPopUp: getShowPopUp(state),
  client: getClientState(state),
  newClient: getIsNewClientState(state),
  createdNewCourse: getNewCreatedCourse(state),
  loading: isLoading(state),
  codeAssembly: getCodeAssembly(state),
  command: getCommand(state),
  errorMessage: getErrorMessage(state),
  paymentFormInitial: getPaymentForm(state),
  lettreDeVoiture: getLettreDeVoiture(state),
  oldOrder: getOldOrder(state),
  courseEditable: getIsOrderDisabled(state) == COURSE_EDIT_TYPE.editable,
  courseSemiEdit: getIsOrderDisabled(state) === COURSE_EDIT_TYPE.semiEdit,
  coursePartialEdit: getIsOrderDisabled(state) === COURSE_EDIT_TYPE.partialEdit,
})
const mapDisptachToProps = (dispatch) => ({
  hidePopUp: () => dispatch(hidePopUp()),
  setPaymentFormAction: (paymentForm) => dispatch(setPaymentForm(paymentForm)),
  setCommand: (command) => dispatch(setCommand(command)),
  updateShipment: (shipment) => dispatch(updateShipment(shipment)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Payment)
