import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import SolarFileDownloadOutline from '../../../../assets/icons/detailsLivraison/SolarFileDownloadOutline'
import { StyledDivContent, StyledInputTitle } from '../../styled'
import { CircularProgress, Divider, IconButton, Tooltip } from '@mui/material'
import { IconFile } from '../../../../assets/icons/detailsLivraison/IconFile'
import IconDownloadFile from '../../../../assets/icons/detailsLivraison/IconDownloadFile'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box } from '@mui/system'

export default function FilesModal({
  open,
  onClose,
  globalID,
  files,
  isLoadingFiles,
  fetchFileByUrl,
}) {
  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Liste des documents'}
      iconTitle={
        <SolarFileDownloadOutline
          style={{ fontSize: '20px', marginRight: '10px', color: '#ffff' }}
        />
      }
      content={
        <StyledDivContent>
          {isLoadingFiles ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress sx={{ color: '#1976D2' }} />
            </Box>
          ) : (
            <>
              {files.length > 0 ? (
                <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                  <StyledInputTitle>Pièces jointes</StyledInputTitle>

                  {files.map((file, index) => {
                    return (
                      <div key={index} style={{ marginTop: '10px' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                padding: '5px',
                                background: '#E3EFFA',
                                borderRadius: '9px',
                              }}
                            >
                              <IconFile />
                            </div>
                            <div
                              style={{
                                padding: '5px',
                                verticalAlign: 'sub',
                                fontWeight: '500',
                              }}
                            >
                              {file.fileName}
                            </div>
                          </div>
                          <Tooltip arrow title={'Télecharger'}>
                            <IconButton
                              sx={{
                                width: '35px',
                                height: '35px',
                                background: '#1876D2',
                                '&:hover': {
                                  backgroundColor: '#1876D3',
                                  opacity: '90%',
                                },
                              }}
                              variant="raised"
                              id={`downoload-file-${globalID}`}
                              onClick={() => fetchFileByUrl(file.url)}
                            >
                              <IconDownloadFile />
                            </IconButton>
                          </Tooltip>
                        </div>
                        {index !== files.length - 1 && (
                          <Divider
                            variant="middle"
                            sx={{ margin: '15px 0px 15px 0px' }}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ErrorOutlineIcon sx={{ marginRight: '10px' }} />
                  <span> Pas de documents</span>
                </div>
              )}
            </>
          )}
        </StyledDivContent>
      }
      handleClose={onClose}
      isClosedIcon={true}
    />
  )
}
FilesModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  files: PropTypes.array,
  globalID: PropTypes.string,
  isLoadingFiles: PropTypes.bool,
  fetchFileByUrl: PropTypes.func,
}
