import React, { useEffect, useState } from 'react'
import {
  faTicketAlt,
  faMapMarkerAlt,
  faAddressCard,
  faBell,
} from '@fortawesome/free-solid-svg-icons'

import './index.scss'
import DashboardCell from './Cell/index.js'
import PropTypes from 'prop-types'
import Loading from '../../../components/Loading'
import Error from '../../../components/Modal/HttpErrorPopUp'
import NavFilter from '../../../containers/main/NavFilter'
import { useHistory } from 'react-router-dom'

const dashboardList = [
  {
    title: 'Commandes',
    icon: faTicketAlt,
  },
  {
    title: 'Dashboard',
    icon: faMapMarkerAlt,
  },
  {
    title: 'Chauffeurs',
    icon: faAddressCard,
  },
  {
    title: 'Alertes / Total: 0',
    icon: faBell,
  },
]
export default function Dashboard({
  shipments,
  getDriversByFilter,
  drivers,
  searchDriver,
  getDelayedShipments,
  delayedShipments,
  salesChannels,
  getSalesChannels,
  getGoogleMapDirection,
  directionRoute,
  driversIsLoading,
  resetDirections,
  count,
  limit,
  pageCount,
  isLoading,
  isLoadingSalesChannel,
  pageChanged,
  pageLimitChanged,
  filterReducer,
  setFilter,
  errorFilter,
  removeErrorFilter,
  resetFilterTableauBord,
  searchReducer,
  resetShipments,
  defaultFilterTableauBord,
}) {
  const [selectedShipment, setSelectedShipment] = useState({})
  const [driversData, setDrivers] = useState([])
  const [delayedShipmentsData, setDelayedShipments] = useState([])
  const history = useHistory()

  const handleSelectedShipment = (value) => setSelectedShipment(value)

  useEffect(() => {
    getDriversByFilter(true)
    getDelayedShipments()
    getSalesChannels()
    return () => resetShipments()
  }, [])

  useEffect(() => {
    if (drivers) {
      setDrivers(drivers)
    }
  }, [drivers])
  useEffect(() => {
    if (delayedShipments) {
      setDelayedShipments(delayedShipments)
    }
  }, [delayedShipments])

  const [openErrorFilter, setOpenErrorFilter] = useState(false)
  const onErrorFilterClose = () => {
    setOpenErrorFilter(false)
    removeErrorFilter()
  }
  useEffect(() => {
    if (errorFilter) setOpenErrorFilter(true)
  }, [errorFilter])

  return (
    <>
      <NavFilter
        dateFilterToday={true}
        statusFilter={true}
        salesChannelfilter={true}
        filterReducer={filterReducer}
        setFilter={setFilter}
        dateFilterCreation={true}
        BusinessOwnerFilter={true}
        resetFilter={resetFilterTableauBord}
        pathnameFilter={history.location.pathname}
        defaultFilter={defaultFilterTableauBord}
      />
      <div className=" dashboard-container " id="dashboard-drivers">
        {isLoadingSalesChannel ? (
          <Loading />
        ) : (
          <>
            {dashboardList.map(({ title, icon }, index) => (
              <DashboardCell
                index={index}
                key={index}
                title={title}
                icon={icon}
                shipments={shipments}
                handleSelectedShipment={handleSelectedShipment}
                selectedShipment={selectedShipment}
                salesChannels={salesChannels}
                getGoogleMapDirection={getGoogleMapDirection}
                directionRoute={directionRoute}
                resetDirections={resetDirections}
                delayedShipments={delayedShipmentsData}
                drivers={driversData}
                getDriversByFilter={getDriversByFilter}
                searchDriver={searchDriver}
                driversIsLoading={driversIsLoading}
                count={count}
                limit={limit}
                pageCount={pageCount}
                isLoading={isLoading}
                pageChanged={pageChanged}
                pageLimitChanged={pageLimitChanged}
                searchReducer={searchReducer}
              />
            ))}
            {errorFilter && (
              <Error
                statusText={errorFilter}
                open={openErrorFilter}
                setOpen={onErrorFilterClose}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}
Dashboard.propTypes = {
  shipments: PropTypes.array,
  getDriversByFilter: PropTypes.func,
  searchDriver: PropTypes.func,
  getDelayedShipments: PropTypes.func,
  drivers: PropTypes.array,
  delayedShipments: PropTypes.array,
  salesChannels: PropTypes.array,
  getSalesChannels: PropTypes.func,
  getGoogleMapDirection: PropTypes.func,
  resetDirections: PropTypes.func,
  directionRoute: PropTypes.any,
  count: PropTypes.number,
  limit: PropTypes.number,
  pageCount: PropTypes.number,
  isLoading: PropTypes.bool,
  isLoadingSalesChannel: PropTypes.bool,
  pageChanged: PropTypes.func,
  pageLimitChanged: PropTypes.func,
  driversIsLoading: PropTypes.bool,
  filterReducer: PropTypes.object,
  setFilter: PropTypes.func,
  removeErrorFilter: PropTypes.func,
  errorFilter: PropTypes.string,
  resetFilterTableauBord: PropTypes.func,
  searchReducer: PropTypes.object,
  resetShipments: PropTypes.func,
  defaultFilterTableauBord: PropTypes.object,
}
