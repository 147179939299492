import {
  GET_CRENEAU_RECOMMENDED_DISPONIBILITES,
  GET_CRENEAU_RECOMMENDED_DISPONIBILITES_SUCCESS,
  GET_RECOMMENDED_CRENEAU,
  GET_RECOMMENDED_CRENEAU_FAILURE,
  GET_RECOMMENDED_CRENEAU_SUCCESS,
} from './actions'
import { httpPost } from '../http/actions'
const api = process.env.REACT_APP_API_URL
const apiDisponibilites = process.env.REACT_APP_AVAILABILITY_MS_URL

export default (store) => (next) => (action) => {
  if (action.type === GET_RECOMMENDED_CRENEAU) {
    store.dispatch(
      httpPost({
        endPoint: `${api}getRecommendedCreneau`,
        data: action.payload,
        success: GET_RECOMMENDED_CRENEAU_SUCCESS,
        failure: GET_RECOMMENDED_CRENEAU_FAILURE,
      })
    )
  }

  if (action.type === GET_CRENEAU_RECOMMENDED_DISPONIBILITES) {
    store.dispatch(
      httpPost({
        endPoint: `${apiDisponibilites}api/v1/time-slots/get-day-schedule`,
        data: action.payload,
        success: GET_CRENEAU_RECOMMENDED_DISPONIBILITES_SUCCESS,
        failure: GET_RECOMMENDED_CRENEAU_FAILURE,
      })
    )
  }

  next(action)
}
