import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PICKER_DATE_FORMAT } from '../../constants/appContants'
import { locale, localeMap } from '../../utils/values'
import moment from 'moment'
import format from 'date-fns/format'
import IconButton from '@mui/material/IconButton'
import { ThemeProvider, Typography, createTheme } from '@mui/material'
import { styled } from '@mui/system'

const renderPickerDay = (
  date,
  selectedDate,
  openedDays,
  disabledDays,
  disablePast,
  forceDisabledDays,
  handleDateChange
) => {
  const stylesColorDate = {
    dayColor: (openedDays, selected, disabledbydate) => ({
      color: disabledbydate || selected || openedDays ? 'white' : '#000',
      backgroundColor: disabledbydate
        ? '#CCCCCC'
        : selected
        ? '#1976D2'
        : openedDays
        ? '#10A549'
        : 'white',
    }),

    selectedDay: () => ({
      borderRadius: '50%',
      backgroundColor: '#1976D2',
    }),
  }
  return (
    <div
      style={{
        margin: '2px 3px',
        ...(moment(selectedDate[0]).format('DD/MM/YYYY') ==
        moment(date).format('DD/MM/YYYY')
          ? stylesColorDate.selectedDay()
          : {}),
      }}
    >
      <IconButton
        onClick={() => {
          handleDateChange(date)
        }}
        disabled={
          (forceDisabledDays &&
            openedDays.findIndex((d) => {
              return (
                moment(d).format('DD/MM/YYYY') == moment(date).format('DD/MM/YYYY')
              )
            }) < 0) ||
          disabledDays.findIndex((d) => d === date.getDay()) >= 0 ||
          (disablePast &&
            new Date(date) < new Date().setDate(new Date().getDate() - 1))
        }
        style={{
          width: 36,
          height: 36,
          fontSize: 14,
          ...stylesColorDate.dayColor(
            openedDays.findIndex((d) => {
              return (
                moment(d).format('DD/MM/YYYY') == moment(date).format('DD/MM/YYYY')
              )
            }) >= 0,
            moment(selectedDate[0]).format('DD/MM/YYYY') ==
              moment(date).format('DD/MM/YYYY'),
            (forceDisabledDays &&
              openedDays.findIndex((d) => {
                return (
                  moment(d).format('DD/MM/YYYY') == moment(date).format('DD/MM/YYYY')
                )
              }) < 0) ||
              disabledDays.findIndex((d) => {
                return d === date.getDay()
              }) >= 0 ||
              (disablePast &&
                new Date(date) < new Date().setDate(new Date().getDate() - 1))
          ),
        }}
      >
        <span> {format(date, 'd')} </span>
      </IconButton>
    </div>
  )
}

const TextFieldComponent = (props) => {
  return <TextField {...props} />
}
export default function DatePickerLocalisation({
  label,
  minDate,
  maxDate,
  disablePast,
  isDisabled,
  openedDays,
  hasError,
  errorMessage,
  handleDateChange,
  withDefaultValue,
  defaultValue,
  afterInit,
  isOrderPage,
  date,
  id,
  disabledDays,
  forceDisabledDays,
}) {
  const dv = isOrderPage ? null : new Date()
  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(
    withDefaultValue ? defaultValue : dv
  )
  const StyledTypography = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
  })
  const StyledRoundedDiv = styled(Typography)(({ bg }) => ({
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: bg,
    marginRight: '10px',
  }))
  const StyledContainer = styled('div')({
    position: 'absolute',
    bottom: '13px',
    width: '100%',
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  })
  const theme = createTheme({
    components: {
      MuiCalendarOrClockPicker: {
        styleOverrides: {
          root: {
            minHeight: disablePast && '420px',
          },
        },
      },
      MuiPickersSlideTransition: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
          },
        },
      },
      MuiDayPicker: {
        styleOverrides: {
          slideTransition: {
            overflow: 'hidden',
            minHeight: '260px',
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          labelContainer: {
            fontWeight: 'bold',
          },
        },
      },
    },
  })
  const CustomToolbar = () => {
    return (
      <StyledContainer>
        <StyledTypography>
          <StyledRoundedDiv bg={'#000000'} /> Les dates hors cadencement
        </StyledTypography>
        <StyledTypography>
          <StyledRoundedDiv bg={'#10A549'} /> Les dates disponibles
        </StyledTypography>
        <StyledTypography>
          <StyledRoundedDiv bg={'#cccccc'} /> Les dates non sélectionnables
        </StyledTypography>
      </StyledContainer>
    )
  }

  const onDateChange = (date) => {
    setSelectedDate(date)
    handleDateChange(date)
    setOpen(false)
  }

  useEffect(() => {
    if (afterInit) {
      onDateChange(null)
      console.log('Setting date to null')
    }
  }, [openedDays, disabledDays])
  useEffect(() => {
    setSelectedDate(withDefaultValue ? defaultValue : dv)
  }, [defaultValue])
  useEffect(() => {
    if (!date) setSelectedDate(null)
  }, [date])
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
        <DatePicker
          open={open}
          onChange={onDateChange}
          variant="inline"
          inputVariant="outlined"
          onClose={() => setOpen(false)}
          disabled={isDisabled}
          value={
            withDefaultValue && !selectedDate
              ? defaultValue
                ? defaultValue
                : dv
              : selectedDate
          }
          label={label}
          minDate={!_.isNil(minDate) ? new Date(minDate) : undefined}
          maxDate={!_.isNil(maxDate) ? new Date(maxDate) : undefined}
          minDateMessage="La date ne doit pas être antérieure à la date minimale!"
          maxDateMessage="La date ne doit pas être postérieure à la date maximale!"
          invalidDateMessage="Date est invalide!"
          inputFormat={PICKER_DATE_FORMAT}
          disablePast={disablePast}
          renderInput={(props) => (
            <TextFieldComponent
              onClick={() => !isDisabled && setOpen(!open)}
              {...props}
              id={id}
              label={label}
              error={hasError}
              helperText={
                isDisabled
                  ? "Canal de vente n'a pas des heures d'ouverture"
                  : hasError
                  ? errorMessage
                  : ''
              }
              required
              onKeyDown={(e) => {
                e.preventDefault()
              }}
            />
          )}
          showToolbar={disablePast}
          ToolbarComponent={disablePast && CustomToolbar}
          renderDay={(date, selectedDate) =>
            renderPickerDay(
              date,
              selectedDate,
              openedDays,
              disabledDays,
              disablePast,
              forceDisabledDays,
              onDateChange
            )
          }
        />
      </LocalizationProvider>
    </ThemeProvider>
  )
}
DatePickerLocalisation.propTypes = {
  /** The short hint displayed in the field before the user enters a value. */
  label: PropTypes.string.isRequired,
  /** The minimum date that can be selected by the user */
  minDate: PropTypes.instanceOf(Date),
  /** The maximum date that can be selected by the user */
  maxDate: PropTypes.instanceOf(Date),
  /** Disable past dates */
  disablePast: PropTypes.bool,
  /** The days that should be disabled */
  openedDays: PropTypes.arrayOf(Number),
  /** If true, the label will be displayed in an error state */
  hasError: PropTypes.bool,
  /** The error message that should be displayed in the helper text */
  errorMessage: PropTypes.string,
  /** The function that is executed on the date change */
  handleDateChange: PropTypes.func.isRequired,
  withDefaultValue: PropTypes.bool,
  /** Define prop type */
  defaultValue: PropTypes.any,
  afterInit: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isOrderPage: PropTypes.bool,
  date: PropTypes.any,
  id: PropTypes.string,
  disabledDays: PropTypes.arrayOf(Number),
  forceDisabledDays: PropTypes.bool,
}
