import { styled } from '@mui/material/styles'
import { Box, Card, CardContent, Container, Typography } from '@mui/material'

export const StyledCard = styled(Card)(() => ({
  boxShadow: '0px 3px 6px #00000029',
  border: '1px solid #F0F1F3',
  borderRadius: '10px',
  width: '251px',
  minHeight: '317px',
  ':hover': {
    backgroundColor: '#e3effa94',
  },
}))

export const StyledContainerCards = styled(Container)(() => ({
  '&.MuiContainer-root': {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '100%',
    height: 'calc(100vh - 250px)',
    overflow: 'auto',
    marginLeft: '24px',
    '@media (max-width: 1200px)': {
      justifyContent: 'space-evenly',
    },
  },
}))
export const StyledBoxCards = styled(Box)(() => ({
  margin: '20px 40px 20px 0',
}))

export const StyledCardContent = styled(CardContent)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: '23px 0 22px 41px',
}))

export const StyledContainerHeader = styled(Container)(() => ({
  '&.MuiContainer-root': {
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%',
    paddingLeft: '48px',
    paddingRight: '5%',
    '@media (max-width: 1200px)': {
      paddingRight: '5%',
    },
  },
}))

export const StyledBoxDetail = styled(Box)(() => ({
  '&.MuiBox-root': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '15px',
  },
}))

export const StyledTypographyTitleDetail = styled(Typography)(() => ({
  '&.MuiTypography-h2': {
    fontFamily: `'Roboto','Helvetica','Arial',sans-serif`,
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: 600,
    marginRight: '7px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

export const StyledTypographyInfoDetail = styled(Typography)(
  ({ isEtat, color, backgroundColor }) => ({
    ...(!isEtat
      ? {
          '&.MuiTypography-body1': {
            display: 'block !important',
            color: '#6D7B8E',
            fontSize: '13px',
            lineHeight: '16px',
            fontWeight: 600,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }
      : {
          '&.MuiTypography-body1': {
            height: '21px',
            width: '77px',
            color: color,
            background: backgroundColor,
            borderRadius: '8px',
            alignItems: 'center',
          },
        }),
  })
)

export const StyledActionButtonConatiner = styled('div')(() => ({
  display: 'felx',
  justifyContent: 'center',
}))
