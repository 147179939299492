import React from 'react'

function ActivityIcon() {
  return (
    <svg
      style={{ marginRight: '4px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      <path
        fill="#1976D2"
        d="M17.934 3.005L17.75 3H6.25l-.184.005A3.25 3.25 0 003 6.25v11.5l.005.184A3.25 3.25 0 006.25 21h5.772a6.463 6.463 0 01-.848-2H6.25l-.128-.006A1.25 1.25 0 015 17.75L5.001 8H19v3.174c.721.17 1.395.46 2.001.848V6.25l-.005-.184a3.25 3.25 0 00-3.062-3.06zm-6.94 7.143a.75.75 0 00-.744-.648h-3.5l-.102.007A.75.75 0 006 10.25v7l.007.102A.75.75 0 006.75 18h3.5l.102-.007A.75.75 0 0011 17.25v-7l-.007-.102zM7.5 11h2v5.5h-2V11zm10.5-.75a.75.75 0 00-.75-.75h-4.496l-.101.007A.75.75 0 0012.754 11h4.496l.102-.007A.75.75 0 0018 10.25zm-3.721 3.725a2 2 0 01-1.441 2.497l-.585.144a5.729 5.729 0 00.007 1.807l.54.13a2 2 0 011.45 2.51l-.187.632c.439.386.94.699 1.484.921l.493-.518a2 2 0 012.9 0l.498.525a5.28 5.28 0 001.483-.913l-.198-.686a2 2 0 011.441-2.496l.584-.144a5.729 5.729 0 00-.007-1.808l-.539-.13a2 2 0 01-1.45-2.51l.187-.63a5.278 5.278 0 00-1.485-.923l-.493.519a2 2 0 01-2.899 0l-.499-.525a5.28 5.28 0 00-1.482.912l.198.686zM17.5 19c-.8 0-1.45-.672-1.45-1.5 0-.829.65-1.5 1.45-1.5.801 0 1.45.671 1.45 1.5 0 .828-.649 1.5-1.45 1.5z"
      ></path>
    </svg>
  )
}

export default ActivityIcon
