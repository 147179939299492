import _ from 'lodash'
import { validatorContants } from './constants'
import * as EmailValidator from 'email-validator'
import { getPhoneInputCountries } from '../values'
import parsePhoneNumber from 'libphonenumber-js'
export const validateClientDeliveryForm = (values, elements) => {
  let errors = {}

  // validate firstName if exist in elements to validate
  if (!_.isNil(_.get(elements, 'firstname'))) {
    // validate required  if required exist in elements.firstName
    if (
      elements.firstname.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'firstname.length')
    ) {
      errors['firstname'] = {
        ...errors['firstname'],
        [validatorContants.REQUIRED]: 'Prénom est obligatoire!',
      }
    }
  }

  // validate lastName if exist in elements to validate
  if (!_.isNil(_.get(elements, 'lastname.length'))) {
    // validate required  if required exist in elements.lastName
    if (
      elements.lastname.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'lastname.length')
    ) {
      errors['lastname'] = {
        ...errors['lastname'],
        [validatorContants.REQUIRED]: 'Nom est obligatoire!',
      }
    }
  }

  if (!_.isNil(_.get(elements, 'phone'))) {
    // validate required if required exists in elements.phone
    if (
      elements.phone.includes(validatorContants.REQUIRED) &&
      _.get(values, 'phone', '').trim() === ''
    ) {
      errors['phone'] = {
        ...errors['phone'],
        [validatorContants.REQUIRED]: 'numéro de téléphone est obligatoire!',
      }
    } else if (
      _.get(values, 'phone', '').trim() !== '' &&
      _.get(values, 'phoneCheck') !== undefined
    ) {
      const {
        phone: phoneNumber,
        countryCode,
        dialcode,
      } = _.get(values, 'phoneCheck')

      let parsedPhoneNumber = null

      try {
        const Country =
          getPhoneInputCountries().indexOf(countryCode) !== -1
            ? countryCode.toUpperCase()
            : 'FR'
        let newPhone = phoneNumber.replace(dialcode, '')
        parsedPhoneNumber = parsePhoneNumber(newPhone, Country)
      } catch (err) {
        console.error('Error parsing phone number:', err)
      }

      if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
        errors['phone'] = {
          ...errors['phone'],
          [validatorContants.PATTERN]: 'numéro de téléphone est invalide!',
        }
      }
    } else {
      const countryCode = getPhoneInputCountries()
      const phoneNumber = _.get(values, 'phone', '').replace(/^\+/, '')

      let parsedPhoneNumber = null

      try {
        parsedPhoneNumber = parsePhoneNumber(phoneNumber)
      } catch (err) {
        console.error('Error parsing phone number:', err)
      }

      if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
        try {
          parsedPhoneNumber = parsePhoneNumber(`+${phoneNumber}`, countryCode)
        } catch (err) {
          console.error('Error parsing phone number:', err)
        }

        if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
          errors['phone'] = {
            ...errors['phone'],
            [validatorContants.PATTERN]: 'numéro de téléphone est invalide!',
          }
        }
      }
    }
  }

  if (!_.get(values, 'NoEmail')) {
    // validate email if exist in elements to validate
    if (!_.isNil(_.get(elements, 'mail'))) {
      // validate required  if required exist in elements.email
      if (
        elements.mail.find((el) => el === validatorContants.REQUIRED) &&
        !_.get(values, 'mail')
      ) {
        errors['mail'] = {
          ...errors['mail'],
          [validatorContants.REQUIRED]: 'email est obligatoire!',
        }
      }

      // validate pattern  if pattern exist in elements.email
      if (
        elements.mail.find((el) => el === validatorContants.PATTERN) &&
        !_.isNil(_.get(values, 'mail')) &&
        !EmailValidator.validate(_.get(values, 'mail'))
      ) {
        errors['mail'] = {
          ...errors['mail'],
          [validatorContants.PATTERN]: 'email est invalide!',
        }
      }
    }
  }

  if (
    !_.isNil(_.get(elements, 'selectedAddress')) &&
    !_.isNil(_.get(elements, 'addressQuerry'))
  ) {
    if (
      (elements.selectedAddress.find((el) => el === validatorContants.PATTERN) &&
        _.isNil(_.get(values, 'selectedAddress.formattedAddress'))) ||
      _.isNil(_.get(values, 'selectedAddress.latlng.lat')) ||
      _.isNil(_.get(values, 'selectedAddress.latlng.lng'))
    ) {
      errors['selectedAddress'] = {
        ...errors['selectedAddress'],
        [validatorContants.PATTERN]: "Adresse d'arrivée est invalide!",
      }
    }
    if (
      !_.get(values, 'addressQuerry.length') &&
      elements.addressQuerry.find((el) => el === validatorContants.REQUIRED)
    ) {
      errors['selectedAddress'] = {
        ...errors['selectedAddress'],
        [validatorContants.REQUIRED]: "Adresse d'arrivée est obligatoire!",
      }
    }
    if (
      _.get(values, 'addressQuerry') !=
      _.get(values, 'selectedAddress.formattedAddress')
    ) {
      errors['selectedAddress'] = {
        ...errors['selectedAddress'],
        [validatorContants.PATTERN]: "Adresse d'arrivée est invalide!",
      }
    }
  }
  return errors
}
