import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TableTournee from '../components/Table/ConfigurationTable'
import { StyledContainer } from '../styled'
import { TourneeTableHead } from '../components/Table/data'

const Tournee = ({
  fetchData,
  success,
  pageLimitChanged,
  pageChanged,
  onCloseReset,
  isLoadingfetchTournees,
  tourneesList,
  tourneesListCount,
  searchData,
  errorMessage,
  removeError,
  isErrorFetch,
  isSuccessRefresh,
  resetSuccessAction,
}) => {
  useEffect(() => {
    fetchData()
  }, [])
  useEffect(() => {
    if (success && isSuccessRefresh) {
      onCloseReset()
      fetchData()
    }
    if (success || isSuccessRefresh) {
      resetSuccessAction()
    }
  }, [success])

  return (
    <StyledContainer>
      <TableTournee
        globalID={'Tournee'}
        searchData={searchData}
        searchPlaceholder={'Rechercher par Tournée'}
        tableHead={TourneeTableHead}
        tableRows={tourneesList}
        isTournee={true}
        actionButton={true}
        title={'Tournées'}
        loadingData={isLoadingfetchTournees}
        pageLimitChanged={pageLimitChanged}
        pageChanged={pageChanged}
        onCloseReset={onCloseReset}
        countData={tourneesListCount}
        errorMessage={errorMessage}
        removeError={removeError}
        isErrorFetch={isErrorFetch}
        fetchData={fetchData}
        removeErrorConfig={() => {}}
        resetPagination={success && isSuccessRefresh}
      />
    </StyledContainer>
  )
}
Tournee.propTypes = {
  fetchData: PropTypes.func,
  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,
  onCloseReset: PropTypes.func,
  searchData: PropTypes.func,
  success: PropTypes.bool,
  tourneesListCount: PropTypes.number,
  errorMessage: PropTypes.string,
  removeError: PropTypes.func,
  isErrorFetch: PropTypes.bool,
  isSuccessRefresh: PropTypes.bool,
  resetSuccessAction: PropTypes.func,
  isLoadingfetchTournees: PropTypes.bool,
  tourneesList: PropTypes.array,
}
export default Tournee
