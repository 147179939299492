import { connect } from 'react-redux'

import {
  fetchSearchOngletCorner,
  fetchCourses,
  getOrderByFilter,
  initCheckCourse,
} from '../../../redux/order/actions'

import SearchInOngletCorner from '../../../components/NavFilter/components/searchCorner/SearchInOngletCorner'
import {
  getIsLoadingSearchCorner,
  getCoursesSearch,
} from '../../../redux/order/selectors'
import {
  pageChanged,
  pageLimitChanged,
  saveSearchCornerOnglet,
  saveSearchOnglet,
} from '../../../redux/filter/actions'
import { LimitPaginationList } from '../../../components/Pagination/LimitPaginationList'
import { getSearchCornerOnglet } from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  isLoading: getIsLoadingSearchCorner(state),
  coursesSearch: getCoursesSearch(state),
  searchReducer: getSearchCornerOnglet(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchSearchOngletCorner: (search) => {
    dispatch(initCheckCourse())
    dispatch(fetchSearchOngletCorner(search))
  },
  fetchCourses: (courses) => {
    dispatch(initCheckCourse())
    dispatch(fetchCourses(courses))
  },
  getCourse: () => {
    dispatch(initCheckCourse())
    dispatch(pageLimitChanged(LimitPaginationList[0].value))
    dispatch(pageChanged(0))
    dispatch(getOrderByFilter())
  },
  saveSearchCornerOnglet: (search) => dispatch(saveSearchCornerOnglet(search)),

  saveSearchOnglet: (search, posCollabFilter) =>
    dispatch(saveSearchOnglet(search, posCollabFilter)),
})

export default connect(mapStateToProps, mapDisptachToProps)(SearchInOngletCorner)
