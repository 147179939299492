import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TableParticipants from '../components/Table/ConfigurationTable'

import { StyledContainer } from '../styled'
import { DriverTableHead } from '../components/Table/data'

const DriverList = ({
  fetchData,
  isLoadingDriverList,
  drivers,
  driverCount,
  errorMessage,
  removeError,
  isErrorFetch,
  pageLimitChanged,
  pageChanged,
  success,
  isSuccessRefresh,
  resetSuccessAction,
  searchData,
  onCloseReset,
  errorMessageConfig,
  removeErrorConfig,
}) => {
  useEffect(() => {
    fetchData()
  }, [])
  useEffect(() => {
    if (success && isSuccessRefresh) {
      fetchData()
    }
    if (success || isSuccessRefresh) {
      resetSuccessAction()
    }
  }, [success])
  return (
    <StyledContainer>
      <TableParticipants
        globalID={'Driver'}
        searchData={searchData}
        searchPlaceholder={'Rechercher par chauffeur'}
        tableHead={DriverTableHead}
        tableRows={drivers}
        isDriver={true}
        actionButton={true}
        title={'Chauffeur'}
        loadingData={isLoadingDriverList}
        pageLimitChanged={pageLimitChanged}
        pageChanged={pageChanged}
        onCloseReset={onCloseReset}
        countData={driverCount}
        errorMessage={errorMessage || errorMessageConfig}
        removeError={removeError}
        isErrorFetch={isErrorFetch}
        fetchData={fetchData}
        removeErrorConfig={removeErrorConfig}
        resetPagination={success && isSuccessRefresh}
      />
    </StyledContainer>
  )
}
DriverList.propTypes = {
  drivers: PropTypes.array,
  fetchData: PropTypes.func,
  isLoadingDriverList: PropTypes.bool,
  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,
  searchKeys: PropTypes.func,
  onCloseReset: PropTypes.func,
  searchData: PropTypes.func,
  success: PropTypes.bool,
  driverCount: PropTypes.number,
  errorMessage: PropTypes.string,
  removeError: PropTypes.func,
  isErrorFetch: PropTypes.bool,
  isSuccessRefresh: PropTypes.bool,
  resetSuccessAction: PropTypes.func,
  errorMessageConfig: PropTypes.string,
  removeErrorConfig: PropTypes.func,
}
export default DriverList
