import React, { useState } from 'react'

import DashboardCellHeader from './Header'
import PropTypes from 'prop-types'
import DashboardOrdersTable from './Body/Orders'
import DashboardDriversTable from './Body/Drivers'
import DashboardAlertsTable from './Body/Alerts'
import Dashboard from './Body/Dashboard'

import '../index.scss'

export default function DashboardCell({
  title,
  icon,
  shipments,
  drivers,
  getDriversByFilter,
  searchDriver,
  delayedShipments,
  salesChannels,
  getGoogleMapDirection,
  handleSelectedShipment,
  selectedShipment,
  directionRoute,
  driversIsLoading,
  resetDirections,
  index,
  count,
  limit,
  pageCount,
  isLoading,
  pageChanged,
  pageLimitChanged,
  searchReducer,
}) {
  const maxSize = 'max'
  const [windowSize, setWindowSize] = useState(maxSize)
  const [expand, setExpand] = useState(false)
  const onWindowResize = (value) => setWindowSize(value)
  const onExpandWindow = (value) => setExpand(value)
  return (
    <div className="dashboard-cell" style={expand ? { width: '100%' } : {}}>
      <DashboardCellHeader
        title={title}
        icon={icon}
        onWindowResize={onWindowResize}
        windowSize={windowSize}
        expand={expand}
        onExpandWindow={onExpandWindow}
      />
      {windowSize === maxSize && (
        <div className="dashboard-cell-body">
          {index === 0 && shipments && (
            <DashboardOrdersTable
              shipments={shipments}
              handleSelectedShipment={handleSelectedShipment}
              count={count}
              limit={limit}
              pageCount={pageCount}
              isLoading={isLoading}
              pageChanged={pageChanged}
              pageLimitChanged={pageLimitChanged}
              searchReducer={searchReducer}
            />
          )}
          {index === 1 && salesChannels && (
            <Dashboard
              salesChannels={salesChannels}
              selectedShipment={selectedShipment}
              getGoogleMapDirection={getGoogleMapDirection}
              shipments={shipments}
              directionRoute={directionRoute}
              resetDirections={resetDirections}
            />
          )}
          {index === 2 && drivers && (
            <DashboardDriversTable
              drivers={drivers}
              getDriversByFilter={getDriversByFilter}
              searchDriver={searchDriver}
              driversIsLoading={driversIsLoading}
            />
          )}
          {index === 3 && delayedShipments && (
            <DashboardAlertsTable delayedShipments={delayedShipments} />
          )}
        </div>
      )}
    </div>
  )
}
DashboardCell.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  shipments: PropTypes.array,
  drivers: PropTypes.array,
  delayedShipments: PropTypes.array,
  getDriversByFilter: PropTypes.func,
  searchDriver: PropTypes.func,
  salesChannels: PropTypes.array,
  getGoogleMapDirection: PropTypes.func,
  handleSelectedShipment: PropTypes.func,
  resetDirections: PropTypes.func,
  selectedShipment: PropTypes.any,
  directionRoute: PropTypes.any,
  driversIsLoading: PropTypes.bool,
  index: PropTypes.number,
  count: PropTypes.number,
  limit: PropTypes.number,
  pageCount: PropTypes.number,
  isLoading: PropTypes.bool,
  pageChanged: PropTypes.func,
  pageLimitChanged: PropTypes.func,
  searchReducer: PropTypes.object,
}
