import Keys from '../../containers/settings/key'
import ConfigurationCanal from '../../containers/settings/keySalesChannel'
import ConfigurationDriver from '../../containers/settings/keyDriver'
import GestionParticipants from '../../containers/settings/participants'
import GestionSessions from '../../containers/settings/sessions'
import GestionFormations from '../../containers/settings/formations'
import DetailsSession from '../../containers/settings/sessions/detailsSession'
import drivres from '../../containers/settings/drivres'
import VehiculeList from '../../containers/settings/Vehicule'
import GestionTournee from '../../containers/settings/tournee'
import GestionDisponibilites from '../../containers/settings/disponibilites'

import { ROLE_PLANIFICATEUR } from '../../utils/roleManagement/roleValues'

export const settingsRoutes = [
  {
    path: '/configuration-cle',
    component: Keys,
    canAccess: [],
  },
  {
    path: '/affectation-cles-canal',
    component: ConfigurationCanal,
    canAccess: [],
  },
  {
    path: '/affectation-cles-chauffeur',
    component: ConfigurationDriver,
    canAccess: [],
  },
  {
    path: '/participants',
    component: GestionParticipants,
    canAccess: [],
  },
  {
    path: '/sessions',
    component: GestionSessions,
    canAccess: [],
  },
  {
    path: '/chauffeurs',
    component: drivres,
    canAccess: [],
  },
  {
    path: '/vehicule',
    component: VehiculeList,
    canAccess: [],
  },
  {
    path: '/formations',
    component: GestionFormations,
    canAccess: [],
  },
  {
    path: '/session/:id',
    component: DetailsSession,
    canAccess: [],
  },
  {
    path: '/configuration-RA',
    component: GestionTournee,
    canAccess: [],
  },
  {
    path: '/disponibilites',
    component: GestionDisponibilites,
    canAccess: [ROLE_PLANIFICATEUR.code],
  },
]
