export const GET_FORMATIONS = 'GET_FORMATIONS'
export const GET_FORMATIONS_SUCCESS = 'GET_FORMATIONS_SUCCESS'
export const GET_FORMATIONS_FAILURE = 'GET_FORMATIONS_FAILURE'

export const PAGE_LIMIT_CHANGED = 'PAGE_LIMIT_CHANGED'
export const PAGE_CHANGED = 'PAGE_CHANGED'

export const REMOVE_ERROR = 'REMOVE_ERROR'
export const RESET_SUCCESS_ACTION = 'RESET_SUCCESS_ACTION'
export const RESET_CONFIG = 'RESET_CONFIG'

export const SEARCH_FORMATIONS = 'SEARCH_FORMATIONS'

export const ADD_FORMATION = 'ADD_FORMATION'
export const ADD_FORMATION_SUCCESS = 'ADD_FORMATION_SUCCESS'
export const ADD_FORMATION_FAILURE = 'ADD_FORMATION_FAILURE'

export const UPDATE_FORMATION = 'UPDATE_FORMATION'
export const UPDATE_FORMATION_SUCCESS = 'UPDATE_FORMATION_SUCCESS'
export const UPDATE_FORMATION_FAILURE = 'UPDATE_FORMATION_FAILURE'

export const ARCHIVAGE_FORMATION = 'ARCHIVAGE_FORMATION'
export const ARCHIVAGE_FORMATION_SUCCESS = 'ARCHIVAGE_FORMATION_SUCCESS'
export const ARCHIVAGE_FORMATION_FAILURE = 'ARCHIVAGE_FORMATION_FAILURE'

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'

export const GET_FILES_BY_ID = 'GET_FILES_BY_ID'
export const GET_FILES_BY_ID_SUCCESS = 'GET_FILES_BY_ID_SUCCESS'
export const GET_FILES_BY_ID_FAILURE = 'GET_FILES_BY_ID_FAILURE'

export const GET_FORMATION_BY_ID = 'GET_FORMATION_BY_ID'
export const GET_FORMATION_BY_ID_SUCCESS = 'GET_FORMATION_BY_ID_SUCCESS'
export const GET_FORMATION_BY_ID_FAILURE = 'GET_FORMATION_BY_ID_FAILURE'

export const GET_FILE_BY_URL = 'GET_FILE_BY_URL'

export const GET_FORMATIONS_MORE = 'GET_FORMATIONS_MORE'
export const GET_FORMATIONS_MORE_SUCCESS = 'GET_FORMATIONS_MORE_SUCCESS'
export const GET_FORMATIONS_MORE_FAILURE = 'GET_FORMATIONS_MORE_FAILURE'
