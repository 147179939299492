import React from 'react'

import './Loading.scss'

import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
/** A  circular progress loading ui element */

export default function Loading() {
  const theme = useTheme()
  return (
    <div className="Loading">
      <div className="spinner-border" role="status">
        <CircularProgress style={{ color: theme.palette.primary.main }} />
      </div>
    </div>
  )
}
