import { connect } from 'react-redux'

import VerificationModal from '../../../pages/settings/components/Modals/VerificationModal'
import { deleteVehicule } from '../../../redux/vehicules/actions'
import {
  getErrorMessage,
  getIsLoadingVehiculeAction,
  getSuccess,
} from '../../../redux/vehicules/selectors'

const mapStateToProps = (state) => ({
  isLoading: getIsLoadingVehiculeAction(state),
  success: getSuccess(state),
  errorMessage: getErrorMessage(state),
})

const mapDisptachToProps = (dispatch) => ({
  verificationActon: (id) => dispatch(deleteVehicule(id)),
})

export default connect(mapStateToProps, mapDisptachToProps)(VerificationModal)
