import {
  GET_MESSAGES_BY_CONVERSATION_ID,
  SUBSCRIBE_TO_MESSAGES_TOPIC,
  RECEIVE_DATA_FROM_MESSAGES_TOPIC,
  CLEAN_MESSAGE_LIST,
  LOADING_SEND_MESSAGE,
  UPLOAD_FILE_DISCUSSION,
  DOWNLOAD_FILE_DISCUSSION,
  SET_LOADING_FILE,
  SET_ERROR_FILE,
  DELETE_FILE_DISCUSSION,
  DELETE_FILE_DISCUSSION_SIMPLE,
  SET_MESSAGE_NEW_CONVERSATION,
  REMOVE_MESSAGE_NEW_CONVERSATION,
  UPLOAD_FILE_DISCUSSION_SUCESS,
  UPLOAD_FILE_DISCUSSION_FAILURE,
} from './constants'

export const getMessageByConversationIdAction = (conversationId, legacyId) => ({
  type: GET_MESSAGES_BY_CONVERSATION_ID,
  payload: { conversationId, legacyId },
})

export const subscribeToMessagesTopicAction = (message) => ({
  type: SUBSCRIBE_TO_MESSAGES_TOPIC,
  payload: { message },
})

export const receiveDataFromMessagesTopicAction = (data) => ({
  type: RECEIVE_DATA_FROM_MESSAGES_TOPIC,
  payload: { data: data },
})

export const cleaningMessageList = () => ({
  type: CLEAN_MESSAGE_LIST,
  payload: {},
})

export const setLoadingSendMessage = (loadingSendMessage) => ({
  type: LOADING_SEND_MESSAGE,
  payload: { loadingSendMessage },
})

export const onUploadfileDiscussion = (files) => ({
  type: UPLOAD_FILE_DISCUSSION,
  payload: { files },
})

export const removeFileDiscussion = (nameFile) => ({
  type: DELETE_FILE_DISCUSSION,
  payload: { nameFile },
})

export const downloadFileDiscussion = (urlFile) => ({
  type: DOWNLOAD_FILE_DISCUSSION,
  payload: { urlFile },
})

export const setLoadingUploadFile = () => ({
  type: SET_LOADING_FILE,
})

export const setErrorUploadFile = (error) => ({
  type: SET_ERROR_FILE,
  payload: { error },
})

export const deleteFile = (filesUpload) => ({
  type: DELETE_FILE_DISCUSSION_SIMPLE,
  payload: { filesUpload },
})

export const setMessageNewConversation = (entrySendMessage) => ({
  type: SET_MESSAGE_NEW_CONVERSATION,
  payload: { entrySendMessage },
})

export const removeMessageNewConversation = () => ({
  type: REMOVE_MESSAGE_NEW_CONVERSATION,
})

export const onUploadfileDiscussionSucess = (files) => ({
  type: UPLOAD_FILE_DISCUSSION_SUCESS,
  payload: { files },
})

export const onUploadfileDiscussionFailure = () => ({
  type: UPLOAD_FILE_DISCUSSION_FAILURE,
})
