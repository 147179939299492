import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'

import MenuItem from '@mui/material/MenuItem'

import moment from 'moment'
import TextComponent from './texComponent'
import { StyledCell } from './style'

const ColumnSelectedCreneauxComponentEditable = ({
  content,
  key,
  id,
  editable,
  onChange,
  listes,
  contentEditable,
  isClientDelivery,
  onDelete,
}) => {
  const [creneauStart, setCreneauStart] = useState(contentEditable.heureStart)
  useEffect(() => {
    if (editable) setCreneauStart(contentEditable.heureStart)
  }, [editable])

  const getDate = (date, time) => {
    return moment
      .tz(moment(new Date(date)).format('YYYY-MM-DD ') + time, 'Europe/Paris')
      .isValid()
      ? moment
          .tz(moment(new Date(date)).format('YYYY-MM-DD ') + time, 'Europe/Paris')
          .format()
      : undefined
  }

  const onChnageCreneau = (value) => {
    if (value) {
      let objectChange = {}
      if (!isClientDelivery) {
        const recommandedCreneau = listes.filter(
          (creneau) => creneau.start === value
        )
        if (recommandedCreneau?.length > 0) {
          const pickUpEnd = recommandedCreneau[0].end
          const pickUpStart = value
          if (
            contentEditable.heureStart === pickUpStart &&
            contentEditable.heureEnd === pickUpEnd
          ) {
            onDelete(['pickupStart', 'pickupEnd', 'dateDemarrage'])
            return
          }
          objectChange = {
            pickupStart: getDate(contentEditable.date, pickUpStart),
            pickupEnd: getDate(contentEditable.date, pickUpEnd),
            dateDemarrage: getDate(contentEditable.date, pickUpStart),
          }
        }
      } else {
        if (contentEditable.heureStart === value) {
          onDelete(['dateDemarrage'])
          return
        }
        objectChange = {
          dateDemarrage: getDate(contentEditable.date, value),
        }
      }

      setCreneauStart(value)
      onChange(objectChange)
    }
  }
  return (
    <StyledCell id={id}>
      {editable ? (
        <TextField
          id="time-shipment"
          select
          name="heurePickUp"
          value={creneauStart}
          onChange={(event) => {
            onChnageCreneau(event.target.value)
          }}
          variant="outlined"
        >
          {listes.length > 0 ? (
            listes.map((h, index) => {
              return (
                <MenuItem value={h.start} key={index}>
                  {h.start} - {h.end}
                </MenuItem>
              )
            })
          ) : (
            <MenuItem key={1} value={creneauStart} disabled={true}>
              {content}
            </MenuItem>
          )}
        </TextField>
      ) : (
        <TextComponent content={content} key={key} />
      )}
    </StyledCell>
  )
}
ColumnSelectedCreneauxComponentEditable.propTypes = {
  content: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  listes: PropTypes.array,
  contentEditable: PropTypes.object,
  isClientDelivery: PropTypes.bool,
  onDelete: PropTypes.func,
  nameAttribut: PropTypes.string,
}

export default ColumnSelectedCreneauxComponentEditable
