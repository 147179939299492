import React from 'react'
import PropTypes from 'prop-types'
import { StyledSwitch } from './style'

export default function SwitchComponent({
  name,
  value,
  defaultChecked,
  onChange,
  disabled,
  id,
}) {
  return (
    <StyledSwitch
      name={name}
      checked={value}
      defaultChecked={defaultChecked}
      onChange={onChange}
      disabled={disabled}
      id={id}
    />
  )
}
SwitchComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
}
