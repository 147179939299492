import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import TextAdresse from './TextAdresseTooltip'
import EtageIcon from '../../../../../../assets/icons/etage.svg'
import AscenseurIcon from '../../../../../../assets/icons/ascenseur.svg'
import InfoExpIcon from '../../../../../../assets/icons/detailsLivraison/infoExp'
import InfoLivIcon from '../../../../../../assets/icons/detailsLivraison/infoLiv'
import PersonIcon from '@mui/icons-material/Person'
import FlagIcon from '@mui/icons-material/Flag'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import NoteIcon from '@mui/icons-material/Description'
import CircularProgress from '@mui/material/CircularProgress'
import EscalierIcon from '../../../../../../assets/icons/detailsLivraison/escalierIcon'
import PoidLourdIcon from '../../../../../../assets/icons/detailsLivraison/poidLourd'
import VLICon from '../../../../../../assets/icons/detailsLivraison/VLicon'

import { CardSyled, TitleWithIcon } from '../card/style'
import { getfullName } from '../../../../../../utils/values'
import Questions from '../../../../../../containers/main/DetailsDelivery/questions'
import Tooltip from '@mui/material/Tooltip'

export default function InformationLivraison({ order, isLoading }) {
  return (
    <>
      <CardSyled>
        <Grid container>
          <div style={{ width: '100%' }}>
            <TitleWithIcon>
              <InfoExpIcon
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '6px',
                }}
              />
              Informations expéditeur
            </TitleWithIcon>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <>
                {order?.adresseDepart && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      fontSize: '14px',
                      padding: '10px 9px 10px',
                      display: 'flex',
                    }}
                    style={{ paddingLeft: '5px' }}
                  >
                    <LocationOnIcon
                      sx={{
                        marginRight: '12px',
                        color: '#373D57',
                      }}
                    />
                    <TextAdresse
                      classStyle={{
                        marginLeft: '0',
                        color: '#104687',
                        fontSize: '13px',
                        textTransform: 'capitalize',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        paddingTop: '2px',
                      }}
                      value={order.adresseDepart}
                    />
                  </Grid>
                )}

                {order?.contactDepart === null ? (
                  <>
                    {order?.commande?.canalVente?.name && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ paddingLeft: '5px' }}
                      >
                        <FlagIcon
                          sx={{
                            marginRight: '12px',
                            color: '#373D57',
                          }}
                        />
                        {order.commande.canalVente.name}
                      </Grid>
                    )}
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                      >
                        <Tooltip
                          arrow
                          title={<span style={{ fontSize: '14px' }}>{'Etage'}</span>}
                        >
                          <img
                            src={EtageIcon}
                            style={{
                              width: '15px',
                              height: ' 15px',
                              marginRight: '6px',
                              marginTop: '1px',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {order.adresseDepart?.etage >= 0 &&
                            order.adresseDepart?.etage != null
                              ? order.adresseDepart.etage
                              : '0'}
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ padding: '10px 7px 10px' }}
                      >
                        <Tooltip
                          arrow
                          title={
                            <span style={{ fontSize: '14px' }}>
                              {'Nombre de marche avant ascenseur'}
                            </span>
                          }
                        >
                          <EscalierIcon
                            style={{
                              marginRight: '5px',
                              width: '20px',
                              height: '20px',
                              color: '#373d57',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {order.adresseDepart?.nbrMarcheAvantAscenseur >= 0 &&
                            order.adresseDepart?.nbrMarcheAvantAscenseur != null
                              ? order.adresseDepart.nbrMarcheAvantAscenseur
                              : '0'}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                      >
                        <Tooltip
                          arrow
                          title={
                            <span style={{ fontSize: '14px' }}>{'Ascenseur'}</span>
                          }
                        >
                          <img
                            src={AscenseurIcon}
                            style={{
                              width: '15px',
                              height: ' 15px',
                              marginRight: '11px',
                              marginTop: '1px',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {order?.adresseDepart?.avecAscenseur ? 'oui' : 'non'}
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ padding: '10px 7px 10px' }}
                      >
                        {order?.adresseDepart?.plVl ? (
                          <>
                            <PoidLourdIcon
                              style={{
                                marginRight: '5px',
                                width: '20px',
                                height: '20px',
                                color: '#373d57',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              {'Accessible en poids lourds'}
                            </span>
                          </>
                        ) : (
                          <>
                            <VLICon
                              style={{
                                marginRight: '5px',
                                width: '20px',
                                height: '20px',
                                color: '#373d57',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              {'Accessible uniquement en VL'}
                            </span>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {order?.observation && order?.observation.length > 0 && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ paddingLeft: '5px' }}
                      >
                        <NoteIcon
                          sx={{
                            marginRight: '12px',
                            color: '#373D57',
                          }}
                        />

                        <span
                          style={{
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {order.observation}
                        </span>
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    <Grid container>
                      {order?.contactDepart?.firstname && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '5px' }}
                        >
                          <PersonIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                          />
                          {getfullName(order?.contactDepart, 'N/A')}
                        </Grid>
                      )}
                      {order?.contactDepart?.phone && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '6px' }}
                        >
                          <PhoneIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                            style={{ marginRight: '5px' }}
                          />

                          {order.contactDepart.phone}
                        </Grid>
                      )}
                      {order?.contactDepart?.nomSocieteDepart && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '5px' }}
                        >
                          <FlagIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                          />

                          {order.contactDepart.nomSocieteDepart}
                        </Grid>
                      )}
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                      >
                        <Tooltip
                          arrow
                          title={<span style={{ fontSize: '14px' }}>{'Etage'}</span>}
                        >
                          <img
                            src={EtageIcon}
                            style={{
                              width: '15px',
                              height: ' 15px',
                              marginRight: '6px',
                              marginTop: '1px',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {order?.adresseDepart?.etage >= 0 &&
                            order?.adresseDepart?.etage != null
                              ? order.adresseDepart.etage
                              : '0'}
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ padding: '10px 7px 10px' }}
                      >
                        <Tooltip
                          arrow
                          title={
                            <span style={{ fontSize: '14px' }}>
                              {'Nombre de marche avant ascenseur'}
                            </span>
                          }
                        >
                          <EscalierIcon
                            style={{
                              marginRight: '5px',
                              width: '20px',
                              height: '20px',
                              color: '#373d57',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {order?.adresseDepart?.nbrMarcheAvantAscenseur >= 0 &&
                            order?.adresseDepart?.nbrMarcheAvantAscenseur != null
                              ? order.adresseDepart.nbrMarcheAvantAscenseur
                              : '0'}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                      >
                        <Tooltip
                          arrow
                          title={
                            <span style={{ fontSize: '14px' }}>{'Ascenseur'}</span>
                          }
                        >
                          <img
                            src={AscenseurIcon}
                            style={{
                              width: '15px',
                              height: ' 15px',
                              marginRight: '11px',
                              marginTop: '1px',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {order?.adresseDepart?.avecAscenseur ? 'oui' : 'non'}
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ padding: '10px 7px 10px' }}
                      >
                        {order?.adresseDepart?.plVl ? (
                          <>
                            <PoidLourdIcon
                              style={{
                                marginRight: '5px',
                                width: '20px',
                                height: '20px',
                                color: '#373d57',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              {'Accessible en poids lourds'}
                            </span>
                          </>
                        ) : (
                          <>
                            <VLICon
                              style={{
                                marginRight: '5px',
                                width: '20px',
                                height: '20px',
                                color: '#373d57',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              {'Accessible uniquement en VL'}
                            </span>
                          </>
                        )}
                      </Grid>
                    </Grid>

                    {order?.observation && order?.observation.length > 0 && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ paddingLeft: '5px' }}
                      >
                        <NoteIcon
                          sx={{
                            marginRight: '12px',
                            color: '#373D57',
                          }}
                        />

                        <span
                          style={{
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {order.observation}
                        </span>
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Grid>
      </CardSyled>
      <CardSyled>
        <Grid container>
          <div style={{ width: '100%' }}>
            <TitleWithIcon>
              <InfoLivIcon
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '4px',
                }}
              />
              Informations de livraison
            </TitleWithIcon>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <>
                {order?.adresseArrivee && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      fontSize: '14px',
                      padding: '10px 9px 10px',
                      display: 'flex',
                    }}
                    style={{ paddingLeft: '5px' }}
                  >
                    <LocationOnIcon
                      sx={{
                        marginRight: '12px',
                        color: '#373D57',
                      }}
                    />

                    <TextAdresse
                      classStyle={{
                        marginLeft: '0',
                        color: '#104687',
                        fontSize: '13px',
                        textTransform: 'capitalize',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        paddingTop: '2px',
                      }}
                      value={order.adresseArrivee}
                    />
                  </Grid>
                )}

                {order?.contactArrivee === null ? (
                  <>
                    <Grid container>
                      {order?.commande?.client && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '5px' }}
                        >
                          <PersonIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                          />

                          {getfullName(order.commande.client, 'N/A')}
                        </Grid>
                      )}
                      {order?.commande?.client?.phone && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '6px' }}
                        >
                          <PhoneIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                            style={{ marginRight: '5px' }}
                          />
                          {order.commande.client.phone}
                        </Grid>
                      )}
                      {order?.commande?.client?.societe && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '5px' }}
                        >
                          <FlagIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                          />

                          {order.commande.client.societe?.name}
                        </Grid>
                      )}
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                      >
                        <Tooltip
                          arrow
                          title={<span style={{ fontSize: '14px' }}>{'Etage'}</span>}
                        >
                          <img
                            src={EtageIcon}
                            style={{
                              width: '15px',
                              height: ' 15px',
                              marginRight: '6px',
                              marginTop: '1px',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {' '}
                            {order.adresseArrivee?.etage >= 0 &&
                            order.adresseArrivee?.etage != null
                              ? order.adresseArrivee?.etage
                              : '0'}
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ padding: '10px 7px 10px' }}
                      >
                        <Tooltip
                          arrow
                          title={
                            <span style={{ fontSize: '14px' }}>
                              {'Nombre de marche avant ascenseur'}
                            </span>
                          }
                        >
                          <EscalierIcon
                            style={{
                              marginRight: '5px',
                              width: '20px',
                              height: '20px',
                              color: '#373d57',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {' '}
                            {order.adresseArrivee?.nbrMarcheAvantAscenseur >= 0 &&
                            order.adresseArrivee?.nbrMarcheAvantAscenseur != null
                              ? order.adresseArrivee?.nbrMarcheAvantAscenseur
                              : '0'}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                      >
                        <Tooltip
                          arrow
                          title={
                            <span style={{ fontSize: '14px' }}>{'Ascenseur'}</span>
                          }
                        >
                          <img
                            src={AscenseurIcon}
                            style={{
                              width: '15px',
                              height: ' 15px',
                              marginRight: '11px',
                              marginTop: '1px',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {order?.adresseArrivee?.avecAscenseur ? 'oui' : 'non'}
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ padding: '10px 7px 10px' }}
                      >
                        {order?.adresseArrivee?.plVl ? (
                          <>
                            <PoidLourdIcon
                              style={{
                                marginRight: '5px',
                                width: '20px',
                                height: '20px',
                                color: '#373d57',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              {'Accessible en poids lourds'}
                            </span>
                          </>
                        ) : (
                          <>
                            <VLICon
                              style={{
                                marginRight: '5px',
                                width: '20px',
                                height: '20px',
                                color: '#373d57',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              {'Accessible uniquement en VL'}
                            </span>
                          </>
                        )}
                      </Grid>
                    </Grid>

                    {order?.observationArrivee &&
                      order?.observationArrivee.length > 0 && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '5px' }}
                        >
                          <NoteIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                          />

                          <span
                            style={{
                              wordBreak: 'break-word',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {order.observationArrivee}
                          </span>
                        </Grid>
                      )}
                  </>
                ) : (
                  <>
                    <Grid container>
                      {order?.contactArrivee && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '5px' }}
                        >
                          <PersonIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                          />

                          {getfullName(order.contactArrivee, 'N/A')}
                        </Grid>
                      )}
                      {order?.contactArrivee?.phone && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '6px' }}
                        >
                          <PhoneIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                            style={{ marginRight: '5px' }}
                          />

                          {order.contactArrivee.phone}
                        </Grid>
                      )}
                      {order?.contactArrivee?.nomSocieteArrivee && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '5px' }}
                        >
                          <FlagIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                          />

                          {order.contactArrivee.nomSocieteArrivee}
                        </Grid>
                      )}
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                      >
                        <Tooltip
                          arrow
                          title={<span style={{ fontSize: '14px' }}>{'Etage'}</span>}
                        >
                          <img
                            src={EtageIcon}
                            style={{
                              width: '15px',
                              height: ' 15px',
                              marginRight: '6px',
                              marginTop: '1px',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {' '}
                            {order?.adresseArrivee?.etage >= 0 &&
                            order?.adresseArrivee?.etage != null
                              ? order.adresseArrivee?.etage
                              : '0'}
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ padding: '10px 7px 10px' }}
                      >
                        <Tooltip
                          arrow
                          title={
                            <span style={{ fontSize: '14px' }}>
                              {'Nombre de marche avant ascenseur'}
                            </span>
                          }
                        >
                          <EscalierIcon
                            style={{
                              marginRight: '5px',
                              width: '20px',
                              height: '20px',
                              color: '#373d57',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {' '}
                            {order?.adresseArrivee?.nbrMarcheAvantAscenseur >= 0 &&
                            order?.adresseArrivee?.nbrMarcheAvantAscenseur != null
                              ? order.adresseArrivee?.nbrMarcheAvantAscenseur
                              : '0'}
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                      >
                        <Tooltip
                          arrow
                          title={
                            <span style={{ fontSize: '14px' }}>{'Ascenseur'}</span>
                          }
                        >
                          <img
                            src={AscenseurIcon}
                            style={{
                              width: '15px',
                              height: ' 15px',
                              marginRight: '11px',
                              marginTop: '1px',
                            }}
                          />
                          <span style={{ fontSize: '14px' }}>
                            {order?.adresseArrivee?.avecAscenseur ? 'oui' : 'non'}
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          fontSize: '14px',
                          padding: '10px 9px 10px',
                          display: 'flex',
                        }}
                        style={{ padding: '10px 7px 10px' }}
                      >
                        {order?.adresseArrivee?.plVl ? (
                          <>
                            <PoidLourdIcon
                              style={{
                                marginRight: '5px',
                                width: '20px',
                                height: '20px',
                                color: '#373d57',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              {'Accessible en poids lourds'}
                            </span>
                          </>
                        ) : (
                          <>
                            <VLICon
                              style={{
                                marginRight: '5px',
                                width: '20px',
                                height: '20px',
                                color: '#373d57',
                              }}
                            />
                            <span style={{ fontSize: '14px' }}>
                              {'Accessible uniquement en VL'}
                            </span>
                          </>
                        )}
                      </Grid>
                    </Grid>

                    {order?.observationArrivee &&
                      order?.observationArrivee.length > 0 && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            fontSize: '14px',
                            padding: '10px 9px 10px',
                            display: 'flex',
                          }}
                          style={{ paddingLeft: '5px' }}
                        >
                          <NoteIcon
                            sx={{
                              marginRight: '12px',
                              color: '#373D57',
                            }}
                          />

                          <span
                            style={{
                              wordBreak: 'break-word',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {order.observationArrivee}
                          </span>
                        </Grid>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </Grid>
        <Questions order={order} isLoading={isLoading} />
      </CardSyled>
    </>
  )
}
InformationLivraison.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
}
