import initialState from './initialState'
import {
  GET_MESSAGES_BY_CONVERSATION_ID,
  GET_MESSAGES_BY_CONVERSATION_ID_SUCESS,
  GET_MESSAGES_BY_CONVERSATION_ID_FAILURE,
  RECEIVE_DATA_FROM_MESSAGES_TOPIC,
  CLEAN_MESSAGE_LIST,
  LOADING_SEND_MESSAGE,
  UPLOAD_FILE_DISCUSSION,
  UPLOAD_FILE_DISCUSSION_SUCESS,
  UPLOAD_FILE_DISCUSSION_FAILURE,
  SET_LOADING_FILE,
  SET_ERROR_FILE,
  DELETE_FILE_DISCUSSION,
  DELETE_FILE_DISCUSSION_SUCESS,
  DELETE_FILE_DISCUSSION_FAILURE,
  SET_MESSAGE_NEW_CONVERSATION,
  REMOVE_MESSAGE_NEW_CONVERSATION,
  DELETE_FILE_DISCUSSION_SIMPLE,
} from './constants'

export default (state = initialState(), action) => {
  if (action.type === GET_MESSAGES_BY_CONVERSATION_ID) {
    return {
      ...state,
      isLoading: true,
    }
  }
  if (action.type === GET_MESSAGES_BY_CONVERSATION_ID_SUCESS) {
    const { id, subject, salesChannel, entries, conversationToUser } =
      action.payload.data
    return {
      ...state,
      id,
      subject,
      salesChannel,
      entries,
      conversationToUser,
      isLoading: false,
      loadingSendMessage: false,
    }
  }
  if (action.type === GET_MESSAGES_BY_CONVERSATION_ID_FAILURE) {
    const data = action.payload.data.data
    return {
      ...state,
      isLoading: false,
      error: data?.message,
    }
  }
  if (action.type === RECEIVE_DATA_FROM_MESSAGES_TOPIC) {
    const data = action.payload.data

    const entries = [...state.entries, data]
    return {
      ...state,
      entries,
    }
  }
  if (action.type === CLEAN_MESSAGE_LIST) {
    return {
      id: null,
      subject: null,
      salesChannel: {},
      entries: [],
      conversationToUser: [],
      isLoading: false,
      error: null,
    }
  }

  if (action.type === LOADING_SEND_MESSAGE) {
    const { loadingSendMessage } = action.payload

    return {
      ...state,
      loadingSendMessage,
    }
  }

  //Upload File

  if (action.type === UPLOAD_FILE_DISCUSSION || action.type === SET_LOADING_FILE) {
    return {
      ...state,
      loadingUploadFile: true,
      errorUploadFile: false,
      errorMessageUploadFile: '',
    }
  }

  if (action.type === UPLOAD_FILE_DISCUSSION_SUCESS) {
    const { files } = action.payload

    return {
      ...state,
      loadingUploadFile: false,
      filesUpload: [...state.filesUpload, ...files],
      errorUploadFile: false,
      errorMessageUploadFile: '',
    }
  }

  if (action.type === UPLOAD_FILE_DISCUSSION_FAILURE) {
    return {
      ...state,
      loadingUploadFile: false,
      errorUploadFile: true,
    }
  }
  // delete file discussion

  if (action.type === DELETE_FILE_DISCUSSION) {
    return {
      ...state,
      loadingUploadFile: true,
    }
  }

  if (action.type === DELETE_FILE_DISCUSSION_SUCESS) {
    return {
      ...state,
      loadingUploadFile: false,
      errorUploadFile: false,
      errorMessageUploadFile: '',
    }
  }

  if (action.type === DELETE_FILE_DISCUSSION_FAILURE) {
    return {
      ...state,
      loadingUploadFile: false,
      errorUploadFile: true,
      errorMessageUploadFile: "une erreur s'est produite merci d'actualiser la page",
    }
  }

  if (action.type === SET_ERROR_FILE) {
    const error = action.payload.error

    return {
      ...state,
      loadingUploadFile: false,
      errorUploadFile: error.isError,
      errorMessageUploadFile: error.message,
    }
  }

  if (action.type === SET_MESSAGE_NEW_CONVERSATION) {
    const { entrySendMessage } = action.payload

    return {
      ...state,
      entrySendMessage,
    }
  }

  if (action.type === REMOVE_MESSAGE_NEW_CONVERSATION) {
    return {
      ...state,
      entrySendMessage: null,
    }
  }

  if (action.type === DELETE_FILE_DISCUSSION_SIMPLE) {
    const { filesUpload } = action.payload
    return {
      ...state,
      loadingUploadFile: false,
      filesUpload: [...filesUpload],
      errorUploadFile: false,
      errorMessageUploadFile: '',
    }
  }

  return state
}
