import { connect } from 'react-redux'
import GoogleMapComponent from '../../components/GoogleMap'

import {
  loadScriptSuccess,
  loadScriptError,
} from '../../redux/googleMapDirections/actions'
import { getFocusedTours } from '../../redux/simulateTournee/selectors'

const mapStateToProps = (state) => ({
  focusedTours: getFocusedTours(state),
})

const mapDisptachToProps = (dispatch) => ({
  setScriptLoadedSuccess: () => dispatch(loadScriptSuccess()),
  setScriptLoadedError: () => dispatch(loadScriptError()),
})

export default connect(mapStateToProps, mapDisptachToProps)(GoogleMapComponent)
