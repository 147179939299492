import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import TableHead from '@mui/material/TableHead'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'
import '../index.scss'
import Filter from '../../../../Orders/Table/Filter'
import LongTextComponent from '../../../../Orders/Table/LongTextComponent'
import Switch from '../../../../../../components/switch/SwitchComponent'
import SearchField from './../Drivers/SearchField'
import EmptyTable from '../../../../../../components/EmptyTable/EmptyTable'
import Loading from '../../../../../../components/Loading'
import Toolbar from '@mui/material/Toolbar'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

export const list = [
  { id: 'firstname', label: 'Prénom', minWidth: 80, name: 'Prénom', checked: true },
  { id: 'lastname', label: 'Nom', minWidth: 80, name: 'Nom', checked: true },
  { id: 'role', label: 'Role', minWidth: 80, name: 'Role', checked: true },
  { id: 'phone', label: 'Phone', minWidth: 80, name: 'Phone', checked: true },
  { id: 'ca', label: 'CA', minWidth: 80, name: 'CA', checked: true },
  { id: 'code', label: 'Code', minWidth: 80, name: 'Code', checked: false },
]

export default function DashboardDriversTable({
  drivers,
  getDriversByFilter,
  searchDriver,
  driversIsLoading,
}) {
  const [checked, setChecked] = useState(true)
  const [columns, setColumns] = useState(list)
  const [columsOrder, setColumnsOrder] = useState(columns.map((c) => c.name))

  useEffect(() => {
    setColumnsOrder(columns.map((c) => c.name))
  }, [columns])

  const handleCheck = () => {
    setChecked(!checked)
    getDriversByFilter(!checked)
  }
  const handleColumns = (list) => {
    setColumns(list)
  }
  const getChecked = (name) => {
    let isChecked = false
    columns.filter((item) => {
      if (item.name === name) {
        isChecked = item.checked
      }
    })
    return isChecked
  }

  const [page, setPage] = useState(2)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, drivers.length - page * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div className="dashboard-orders-table">
      <>
        <Toolbar className="toolbar-contant toolbar-drivers-contant">
          <SearchField
            onSearch={searchDriver}
            activeOnly={checked}
            getDriversByFilter={getDriversByFilter}
          />
          <FormGroup row style={{ justifyContent: 'flex-end ' }}>
            <FormControlLabel
              control={
                <Switch
                  id="vip"
                  value={checked}
                  defaultChecked={checked}
                  onChange={handleCheck}
                />
              }
              label={'Actif'}
              labelPlacement="end"
            />
          </FormGroup>
          <Filter
            columns={columns}
            handleColumns={handleColumns}
            isDashboard={true}
          />
        </Toolbar>
        {driversIsLoading ? (
          <Loading />
        ) : (
          <TableContainer component={Paper} className="order-table-root ">
            <Table
              sx={{
                minWidth: 500,
                border: '1px solid rgba(224, 224, 224, 1)',
              }}
              aria-label="custom pagination table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {columns
                    .filter((column) => column.checked)
                    .map((column, index) => (
                      <TableCell
                        key={index}
                        align="center"
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              {drivers.length > 0 ? (
                <TableBody>
                  {(rowsPerPage > 0
                    ? drivers.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : drivers
                  ).map(({ firstname, lastname, role, phone, code }, index) => (
                    <TableRow key={index}>
                      {[
                        {
                          name: 'Prénom',
                          content: firstname,
                          show: getChecked('Prénom'),
                        },
                        {
                          name: 'Nom',
                          content: lastname,
                          show: getChecked('Nom'),
                        },
                        {
                          name: 'Role',
                          content: role,
                          show: getChecked('Role'),
                        },
                        {
                          name: 'Phone',
                          content: phone,
                          show: getChecked('Phone'),
                        },

                        {
                          name: 'CA',
                          content: 'CA',
                          show: getChecked('CA'),
                        },

                        {
                          name: 'Code',
                          content: code,
                          show: getChecked('Code'),
                        },
                      ]
                        .sort(
                          (a, b) =>
                            columsOrder.indexOf(a.name) - columsOrder.indexOf(b.name)
                        )
                        .filter((element) => element.show)
                        .map((item, index) => (
                          <LongTextComponent
                            display={true}
                            text={item.content}
                            key={index}
                          />
                        ))}
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={columns.filter((column) => column.checked).length}
                      />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <EmptyTable
                  colspan={columns.filter((column) => column.checked).length}
                />
              )}
              {drivers.length > 0 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        20,
                        30,
                        50,
                        100,
                        200,
                        300,
                        { label: 'All', value: -1 },
                      ]}
                      count={drivers.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage="lignes par page"
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}-${to} sur ${count}`
                      }
                      ActionsComponent={TablePaginationActions}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        )}
      </>
    </div>
  )
}
DashboardDriversTable.propTypes = {
  drivers: PropTypes.array,
  getDriversByFilter: PropTypes.func,
  searchDriver: PropTypes.func,
  driversIsLoading: PropTypes.bool,
}
