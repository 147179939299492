import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import CameraIcon from '../../../../../../../../assets/icons/detailsLivraison/MaterialSymbolsAddAPhotoOutline.svg'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const StyledAccordionSummary = styled(AccordionSummary)({
  root: {
    minHeight: '48px !important',
    padding: '0px 9px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      '& .MuiAccordionSummary-content': {
        transition: 'none',
      },
    },

    '& .MuiAccordionSummary-content': {
      transition: 'none',
      flexDirection: 'column',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
})

export default function InfoStatus({
  packages,
  status,
  matches,
  packagesLitigeMotif,
  packagesLitigeResponsabilite,
  deleteImageLitigePackages,
  isLoadingUploadImageLitige,
  packageCode,
  onUpload,
  onChangePackageLitigeObservation,
  onChangePackageLitigeMotif,
  onChangePackageLitigeResponsabilite,
  isLoadingPackageLitigeResponsabilite,
  isLoadingPackageLitigeMotif,
}) {
  const verifIsUpload = (code) => {
    return packages.filter((pack) => pack.code === code).length > 0
      ? packages.filter((pack) => pack.code === code)[0].litigation.pictures.length <
          3
      : false
  }

  switch (status) {
    //refusé, accepté avec reserve ou litige sur livraison
    case 'LR':
    case 'LA':
    case 'LI':
      return (
        <div style={{ padding: '0px 15px 15px' }}>
          <div
            style={{
              boxShadow: '0px 0px 4px #00000029',
            }}
          >
            {packages.map((pack, index) => (
              <Accordion
                sx={{
                  borderBottom: '1px solid #e6e6e6 !important',
                  boxShadow: 'none !important',
                  width: '100%',
                  margin: '0 !important',
                  '&:before': {
                    opacity: 0,
                  },
                }}
                key={index}
              >
                <StyledAccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1bh-content"
                  id={`package-AccordionSummary-${index}`}
                  expanded={true}
                >
                  <div style={{ display: 'flex' }}>
                    <span
                      style={{
                        fontSize: '13px',
                        fontWeight: '700',
                        paddingRight: '5px',
                      }}
                    >
                      {'BarCode :'}
                    </span>
                    <span
                      style={{
                        fontSize: '13px',
                        fontWeight: '550',
                      }}
                    >
                      {pack.barcode}
                    </span>
                  </div>
                </StyledAccordionSummary>
                <AccordionDetails
                  style={{ display: 'block', padding: '8px 9px 16px' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                      boxSizing: 'border-box',
                    }}
                  >
                    <div style={{ padding: '10px 0px', width: matches && '100%' }}>
                      <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                        Motif*
                      </div>
                      <div style={{ width: matches ? '100%' : '234px' }}>
                        <TextField
                          id="Listes-motifs-litige"
                          select
                          name="Listes-motifs"
                          variant="outlined"
                          onChange={(e) =>
                            onChangePackageLitigeMotif(e.target.value, pack.code)
                          }
                          value={pack.litigation.motif}
                          SelectProps={{
                            MenuProps: {
                              style: { height: 288 },
                            },
                          }}
                          disabled={isLoadingPackageLitigeMotif}
                        >
                          <MenuItem value={'null'} key={0} disabled={true}>
                            {'Listes des Motifs'}
                          </MenuItem>
                          <Divider />
                          {packagesLitigeMotif.map((h, index) => (
                            <MenuItem
                              value={h.code}
                              key={index + 1}
                              id={`motif-litige-${index + 1}`}
                            >
                              {h.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div style={{ padding: '10px 0px', width: matches && '100%' }}>
                      <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                        Responsabilité*
                      </div>
                      <div style={{ width: matches ? '100%' : '234px' }}>
                        <TextField
                          id="Listes-responsabilite-litige"
                          select
                          name="Listes-responsabilite"
                          variant="outlined"
                          onChange={(e) =>
                            onChangePackageLitigeResponsabilite(
                              e.target.value,
                              pack.code
                            )
                          }
                          value={pack.litigation.responsible}
                          SelectProps={{
                            MenuProps: {
                              style: { height: 288 },
                            },
                          }}
                          disabled={isLoadingPackageLitigeResponsabilite}
                        >
                          <MenuItem value={'null'} key={0} disabled={true}>
                            {'Listes des Responsabilité'}
                          </MenuItem>
                          <Divider />
                          {packagesLitigeResponsabilite.map((h, index) => (
                            <MenuItem
                              value={h.code}
                              key={index + 1}
                              id={`responsabilite-litige-${index + 1}`}
                            >
                              {h.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </div>
                  <div style={{ padding: '10px 0px' }}>
                    <div style={{ paddingBottom: '10px', fontWeight: 'bold' }}>
                      Observation
                    </div>
                    <TextField
                      id="observation-litige"
                      name="observation-litige"
                      variant="outlined"
                      placeholder="observation"
                      onChange={(e) =>
                        onChangePackageLitigeObservation(e.target.value, pack.code)
                      }
                      value={pack.litigation.comment}
                      multiline
                      rows={4}
                    />
                  </div>
                  <div style={{ padding: '10px 0px' }}>
                    <div style={{ paddingBottom: '10px' }}>
                      <Tooltip
                        title={
                          <Typography fontSize={12}>minimum une photo</Typography>
                        }
                        arrow
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Ajouter des photos*
                        </span>
                      </Tooltip>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        boxSizing: 'border-box',
                      }}
                    >
                      {pack.litigation.pictures.map((file, index) => (
                        <div
                          style={{ position: 'relative', marginRight: '24px' }}
                          key={index}
                        >
                          <IconButton
                            sx={{
                              color: 'white',
                              backgroundColor: '#eeeeee !important',
                              position: 'absolute',
                              width: '10px',
                              height: '10px',
                              top: '-11px',
                              right: '-12px',
                              zIndex: '2',
                            }}
                            onClick={() => {
                              deleteImageLitigePackages(pack.code, file)
                            }}
                            id="delete-photo-litige"
                          >
                            <CloseIcon />
                          </IconButton>
                          <div
                            style={{
                              width: '114px',
                              height: '115px',
                              background: '#FAFAFA',
                              borderRadius: '10px',
                              display: 'grid',
                              placeItems: 'center',

                              cursor: 'pointer',
                            }}
                          >
                            <img
                              src={file}
                              style={{
                                width: '100%',
                                height: '115px',
                                objectFit: 'contain',
                              }}
                            />
                          </div>
                        </div>
                      ))}
                      {verifIsUpload(pack.code) && (
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              width: '114px',
                              height: '115px',
                              background: '#FAFAFA',
                              borderRadius: '10px',
                              display: 'grid',
                              placeItems: 'center',
                              marginRight: '24px',
                              cursor: 'pointer',
                            }}
                          >
                            {isLoadingUploadImageLitige &&
                            packageCode === pack.code ? (
                              <CircularProgress
                                color="primary"
                                style={{ width: '30px', height: '30px' }}
                              />
                            ) : (
                              <div>
                                <img src={CameraIcon} />
                              </div>
                            )}
                          </div>
                          {!isLoadingUploadImageLitige && (
                            <input
                              id="add-photo-litige"
                              type="file"
                              style={{
                                cursor: 'pointer',
                                opacity: '0',
                                zIndex: '2',
                                width: '114px',
                                height: '115px',
                                position: 'absolute',
                              }}
                              onChange={(event) =>
                                onUpload(event.target.files, pack.code)
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      )
    default:
      return <></>
  }
}
InfoStatus.propTypes = {
  packages: PropTypes.array,
  matches: PropTypes.bool,
  status: PropTypes.string,
  packagesLitigeMotif: PropTypes.array,
  packagesLitigeResponsabilite: PropTypes.array,
  packageCode: PropTypes.string,
  isLoadingUploadImageLitige: PropTypes.bool,
  onUpload: PropTypes.func,
  deleteImageLitigePackages: PropTypes.func,
  onChangePackageLitigeObservation: PropTypes.func,
  onChangePackageLitigeMotif: PropTypes.func,
  onChangePackageLitigeResponsabilite: PropTypes.func,
  isLoadingPackageLitigeResponsabilite: PropTypes.bool,
  isLoadingPackageLitigeMotif: PropTypes.bool,
}
