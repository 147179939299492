export const GET_KEYS = 'GET_KEYS'
export const GET_KEYS_SUCCESS = 'GET_KEYS_SUCCESS'
export const GET_KEYS_FAILURE = 'GET_KEYS_FAILURE'

export const GET_ASSIGNED_CHANNEL = 'GET_ASSIGNED_CHANNEL'
export const GET_ASSIGNED_CHANNEL_SUCCESS = 'GET_ASSIGNED_CHANNEL_SUCCESS'
export const GET_ASSIGNED_CHANNEL_FAILURE = 'GET_ASSIGNED_CHANNEL_FAILURE'

export const GET_KEYS_DRIVER = 'GET_KEYS_DRIVER'
export const GET_KEYS_DRIVER__SUCCESS = 'GET_KEYS_DRIVER_SUCCESS'
export const GET_KEYS_DRIVER_FAILURE = 'GET_KEYS_DRIVER_FAILURE'

export const GET_KEYS_CHANNEL = 'GET_KEYS_CHANNEL'
export const GET_KEYS_CHANNEL__SUCCESS = 'GET_KEYS_CHANNEL_SUCCESS'
export const GET_KEYS_CHANNEL_FAILURE = 'GET_KEYS_CHANNEL_FAILURE'

export const ADD_KEYS = 'ADD_KEYS'
export const ADD_KEYS_SUCCESS = 'ADD_KEYS_SUCCESS'
export const ADD_KEYS_FAILURE = 'ADD_KEYS_FAILURE'

export const RESET_CONFIG = 'RESET_CONFIG'

export const UPDATE_KEY = 'UPDATE_KEY'
export const UPDATE_KEY_SUCCESS = 'UPDATE_KEY_SUCCESS'
export const UPDATE_KEY_FAILURE = 'UPDATE_KEY_FAILURE'

export const PAGE_LIMIT_CHANGED = 'PAGE_LIMIT_CHANGED'
export const PAGE_CHANGED = 'PAGE_CHANGED'
export const SEARCH_KEYS = 'SEARCH_KEYS'

export const ASSIGN_KEYS_DRIVER = 'ASSIGN_KEYS_DRIVER'
export const ASSIGN_KEYS_DRIVER_SUCCESS = 'ASSIGN_KEYS_DRIVER_SUCCESS'
export const ASSIGN_KEYS_DRIVER_FAILURE = 'ASSIGN_KEYS_DRIVER_FAILURE'

export const UNASSIGN_KEYS_DRIVER = 'UNASSIGN_KEYS_DRIVER'
export const UNASSIGN_KEYS_DRIVER_SUCCESS = 'UNASSIGN_KEYS_DRIVER_SUCCESS'
export const UNASSIGN_KEYS_DRIVER_FAILURE = 'UNASSIGN_KEYS_DRIVER_FAILURE'

export const ASSIGN_KEYS_CHANNEL = 'ASSIGN_KEYS_CHANNEL'
export const ASSIGN_KEYS_CHANNEL_SUCCESS = 'ASSIGN_KEYS_CHANNEL_SUCCESS'
export const ASSIGN_KEYS_CHANNEL_FAILURE = 'ASSIGN_KEYS_CHANNEL_FAILURE'

export const UNASSIGN_KEYS_CHANNEL = 'UNASSIGN_KEYS_CHANNEL'
export const UNASSIGN_KEYS_CHANNEL_SUCCESS = 'UNASSIGN_KEYS_CHANNEL_SUCCESS'
export const UNASSIGN_KEYS_CHANNEL_FAILURE = 'UNASSIGN_KEYS_CHANNEL_FAILURE'

export const GET_ASSIGNED_DRIVER = 'GET_ASSIGNED_DRIVER'
export const GET_ASSIGNED_DRIVER_SUCCESS = 'GET_ASSIGNED_DRIVER_SUCCESS'
export const GET_ASSIGNED_DRIVER_FAILURE = 'GET_ASSIGNED_DRIVER_FAILURE'

export const ON_SEARCH = 'ON_SEARCH'

export const REMOVE_ERROR = 'REMOVE_ERROR'

export const RESET_SUCCESS_ACTION = 'RESET_SUCCESS_ACTION'

export const GET_KEYS_LIST_MORE = 'GET_KEYS_LIST_MORE'
export const GET_KEYS_LIST_MORE_SUCESS = 'GET_KEYS_LIST_MORE_SUCESS'
export const GET_KEYS_LIST_MORE_FAILURE = 'GET_KEYS_LIST_MORE_FAILURE'

export const SEARCH_KEYS_COMPETENCE = 'SEARCH_KEYS_COMPETENCE'
export const SEARCH_KEYS_RESSOURCES = 'SEARCH_KEYS_RESSOURCES'
