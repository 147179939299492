import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import IconMacroCanal from '../../../../../../assets/icons/navFilter/MacroCanalIcon'
export default function PrestationsList({
  filterStateChanged,
  macroCanalFiltersValues,
  macroCanalFilter,
  isLoading,
}) {
  const [checked, setChecked] = useState([])

  useEffect(() => {
    let list = macroCanalFilter.map(({ code, name }) => {
      return { code, name }
    })
    setChecked(list)
  }, [macroCanalFilter])

  const onMacroCanalChanged = (selectedMacroCanal) => {
    filterStateChanged('client', selectedMacroCanal ? selectedMacroCanal : [])
  }

  return (
    <SelectListWithSearch
      onDataChanged={onMacroCanalChanged}
      defaultLabel={'Regroupement'}
      dataList={checked}
      disabled={isLoading || macroCanalFilter.length <= 0}
      filtersValues={macroCanalFiltersValues}
      nameFilter={'client'}
      Icon={IconMacroCanal}
    />
  )
}
PrestationsList.propTypes = {
  filterStateChanged: PropTypes.func,
  macroCanalFiltersValues: PropTypes.array,
  macroCanalFilter: PropTypes.array,
  isLoading: PropTypes.bool,
}
