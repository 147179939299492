export const getSessionsState = ({ sessions }) => sessions

export const getSessionsListIsLoading = (state) =>
  getSessionsState(state).isLoadingSessionsList
export const getSessions = (state) => getSessionsState(state).sessions
export const getSessionsCount = (state) => getSessionsState(state).sessionsCount
export const getErrorMessage = (state) => getSessionsState(state).errorMessage
export const getIsErrorFetch = (state) => getSessionsState(state).isErrorFetch

export const getIsSuccessRefresh = (state) =>
  getSessionsState(state).isSuccessRefresh

export const getSuccess = (state) => getSessionsState(state).success

export const getisLoadingSessionAction = (state) =>
  getSessionsState(state).isLoadingSessionAction

export const getSessionsDetails = (state) => getSessionsState(state).sessionsDetails
export const getIsLoadingSessionsDetails = (state) =>
  getSessionsState(state).isLoadingSessionsDetails

export const getSessionsParticipants = (state) =>
  getSessionsState(state).sessionsParticipants
export const getIsLoadingSessionsParticipants = (state) =>
  getSessionsState(state).isLoadingSessionsParticipants

export const getIsErrorFetchDetails = (state) =>
  getSessionsState(state).isErrorFetchDetails
export const getIsErrorFetchParticipant = (state) =>
  getSessionsState(state).isErrorFetchParticipant

export const getSessionById = (state) => getSessionsState(state).session
export const getIsLoadingSession = (state) =>
  getSessionsState(state).isLoadingSession
