import { connect } from 'react-redux'
import DashboardEntrepot from '../../../../pages/main/Entrepot/dashboard'

import { getCollaborateur } from '../../../../redux/user/selectors'

const mapStateToProps = (state) => ({
  collaborateur: getCollaborateur(state),
})

export default connect(mapStateToProps)(DashboardEntrepot)
