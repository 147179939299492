import { useState, useEffect } from 'react'

const useForm = (initialState = {}, submitCallBack, elements, validate) => {
  const [state, setState] = useState(initialState)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errors, setErrors] = useState(validate(state, elements))
  const handleChange = (e) => {
    setState((state) => ({ ...state, [e.target.name]: e.target.value }))
  }
  const handleElementChange = (name, value) => {
    console.log(`value ${name}`, value)
    // console.log('name: ', name, '  value:', value)
    setState((state) => ({ ...state, [name]: value }))
  }

  const handleFormReset = (value) => {
    setState(value)
    setIsSubmitted(false)
  }

  const handleSwitcherChange = (e) => {
    e.persist()
    setState((state) => ({ ...state, [e.target.name]: e.target.checked }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    submitCallBack()
    setIsSubmitted(true)
  }
  const isValid = () => {
    return Object.keys(errors).length === 0
  }

  const isInValid = () => {
    return Object.keys(errors).length > 0
  }
  useEffect(() => {
    setErrors(validate(state, elements))
  }, [state, elements])

  return [
    state,
    errors,
    isSubmitted,
    handleChange,
    handleElementChange,
    handleSubmit,
    isValid,
    isInValid,
    handleFormReset,
    handleSwitcherChange,
  ]
}
export default useForm
