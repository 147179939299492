import React from 'react'
import PropTypes from 'prop-types'

import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector from '@mui/material/StepConnector'
import { styled } from '@mui/material/styles'

import ColorlibStepIcon from './colorLibStepIcon'

const StyledColorlibConnector = styled(StepConnector)(({ theme }) => ({
  top: '22px !important',
  '&.Mui-active': {
    '& .MuiStepConnector-line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '&.Mui-completed': {
    '& .MuiStepConnector-line': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '& .MuiStepConnector-line': {
    height: '3px !important',
    border: '0px !important',
    backgroundColor: 'rgb(234, 234, 240)',
    borderRadius: '1px !important',
  },
}))

/** Steppers display progress through a sequence of logical and numbered steps. It is used for page navigation . Steppers may display a transient feedback message after a step is saved.  */
export default function PageStepper({ steps, activeStep, handleStep }) {
  return (
    <div style={{ width: '100%' }}>
      {steps && steps.length ? (
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<StyledColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={index}>
              {typeof handleStep === 'function' ? (
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  onClick={(index) => handleStep(index)}
                >
                  {label}
                </StepLabel>
              ) : (
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              )}
            </Step>
          ))}
        </Stepper>
      ) : null}
    </div>
  )
}

PageStepper.propTypes = {
  /** An array of the steps text label */
  steps: PropTypes.array.isRequired,
  /** The number of active steps that would be colored  */
  activeStep: PropTypes.number.isRequired,
  /** The executed function when a step is clicked (Takes the index of the step as arg) */
  handleStep: PropTypes.func,
}
