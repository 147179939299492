import { connect } from 'react-redux'
import Historique from '../../../pages/main/Orders/DetailsDelivery/components/details/historique'

import {
  getHistoriqueRDVOrder,
  getIsLoadingHistoriqueRDV,
} from '../../../redux/order/selectors'
import { getOrderHistoriqueRDV } from '../../../redux/order/actions'

const mapStateToProps = (state) => ({
  orderHistoriqueRDV: getHistoriqueRDVOrder(state),
  isLoadingHistoriqueRDV: getIsLoadingHistoriqueRDV(state),
})
const mapDisptachToProps = (dispatch) => ({
  getOrderHistoriqueRDV: (ldv) => dispatch(getOrderHistoriqueRDV(ldv)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Historique)
