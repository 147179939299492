import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import IconDriver from '../../../../../../assets/icons/navFilter/DriverIcon'

export default function DriverFilter({
  disabled,
  filterStateChanged,
  driversFiltersValues,
  driverfilter,
  isLoadingDrivers,
}) {
  const [checked, setChecked] = useState([])

  useEffect(() => {
    const list = driverfilter
    setChecked(list)
  }, [driverfilter])

  const onDriversChanged = (selectedDrivers) => {
    filterStateChanged('drivers', selectedDrivers ? selectedDrivers : [])
  }

  return (
    <SelectListWithSearch
      onDataChanged={onDriversChanged}
      defaultLabel={'Chauffeur'}
      dataList={checked}
      disabled={disabled || isLoadingDrivers || driverfilter.length <= 0}
      filtersValues={driversFiltersValues}
      nameFilter={'drivers'}
      Icon={IconDriver}
      id={'Drivers'}
    />
  )
}
DriverFilter.propTypes = {
  disabled: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  driversFiltersValues: PropTypes.array,
  driverfilter: PropTypes.array,
  isLoadingDrivers: PropTypes.bool,
}
