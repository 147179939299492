import React from 'react'

export function GestionCleIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h3v-1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4v3.5A1.5 1.5 0 0 0 11.5 8H15v1.135A4.34 4.34 0 0 1 15.974 9H16V7.414a1.5 1.5 0 0 0-.44-1.06l-3.914-3.915A1.5 1.5 0 0 0 10.586 2zm8.793 5H11.5a.5.5 0 0 1-.5-.5V3.207zm-4.645 11.852A.48.48 0 0 0 10.5 19h2a.569.569 0 0 0 .273-.07a.368.368 0 0 0 .149-.172a.864.864 0 0 0 .062-.235a2.15 2.15 0 0 0 .016-.257V18c.24-.005.427-.013.563-.023a.468.468 0 0 0 .304-.118a.457.457 0 0 0 .117-.297c.01-.13.016-.317.016-.562c.5 0 .634.02.766-.07a.492.492 0 0 0 .156-.172a.65.65 0 0 0 .07-.235c.027-.229.008-.464.008-.695c.172.063.346.107.523.133c.178.026.357.039.54.039a2.866 2.866 0 0 0 2.078-.898c.27-.282.481-.602.632-.961c.151-.36.227-.74.227-1.141c0-.417-.078-.807-.234-1.172a3.009 3.009 0 0 0-.641-.953a3.009 3.009 0 0 0-.953-.64A2.942 2.942 0 0 0 16 10a3.23 3.23 0 0 0-1.148.234a2.91 2.91 0 0 0-.954.625a3.124 3.124 0 0 0-.656.938a2.716 2.716 0 0 0-.242 1.14c0 .245.029.503.086.774l-2.938 2.937A.48.48 0 0 0 10 17v1.5c0 .135.05.253.148.352M17.5 12.25c0 .412-.338.75-.75.75a.752.752 0 0 1-.75-.75c0-.412.338-.75.75-.75s.75.338.75.75"
      ></path>
    </svg>
  )
}
export default GestionCleIcon
