import { connect } from 'react-redux'

import Details from '../../../../pages/settings/sessions/detailsSession/components/details'
import { fetchFilesById } from '../../../../redux/formations/actions'
import {
  fetchSessionDetails,
  resetSessionConfig,
} from '../../../../redux/sessions/actions'

import {
  getSessionsDetails,
  getIsLoadingSessionsDetails,
} from '../../../../redux/sessions/selectors'

const mapStateToProps = (state) => ({
  sessionsDetails: getSessionsDetails(state),
  isLoadingSessionsDetails: getIsLoadingSessionsDetails(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchSessionDetails: (id) => dispatch(fetchSessionDetails(id)),

  fetchFiles: (id) => {
    dispatch(fetchFilesById(id, 'session'))
  },
  resetSessionConfig: () => dispatch(resetSessionConfig()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Details)
