import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../components/ModalDialog'
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import CreateIcon from '../../../assets/icons/settingsCorner/iconsCreate'
import EditIcon from '../../../assets/icons/settingsCorner/iconEdit'

import moment from 'moment'
import { TabConatainer } from './styled'
import { DisponibiliteTableHead } from '../components/Table/data'
import { DAYS_OF_WEEK_INDEX } from '../../../utils/values'
import { sxInput } from '../styled'
import _ from 'lodash'

function DisponibilitesModal({
  open,
  handleClose,
  modalTitle,
  isEdit,
  canalVenteDetailsConfig,
  canalVenteDetails,
  week,

  createDisponibilite,
  updateDisponibilite,
  isLoadingActionDisponibilite,
  success,
  errorMessage,
}) {
  const [initialValues, setInitialValues] = useState([])
  const [extendedPeriod, setExtendedPeriod] = useState(true)

  const getDateFromDayOfWeek = (dayNumber, referenceDate) => {
    let date = new Date(referenceDate)
    date.setDate(date.getDate() + dayNumber)
    let formattedDate = moment(date).format('YYYY-MM-DD ')
    return formattedDate
  }

  //Init DATA
  useEffect(() => {
    if (isEdit) {
      let valuesInitial = []
      canalVenteDetails.map((slot) => {
        valuesInitial.push({
          slotReference: slot.slotReference,
          start: slot.start,
          end: slot.end,
          channelKey: canalVenteDetailsConfig.channelKey,
          day: slot.day,
          spanCounts: slot.spanCounts,
          spanDuration: slot.spanDuration,
          zones: slot.zones,
          spanRestants: slot.spanCounts - slot.spanReserved,
          spanReserved: slot.spanReserved,
          _id: slot._id,
          truckCounts: slot.truckCounts,
        })
      })
      setInitialValues([...valuesInitial])
    } else {
      let valuesInitial = []
      canalVenteDetailsConfig.schedule.map((day) => {
        return day.slots.map((slot) => {
          valuesInitial.push({
            slotReference: slot.slotReference,
            start: moment(
              getDateFromDayOfWeek(day.day, week.firstDay) +
                moment(slot.start).format('HH:mm')
            )
              .utc()
              .format(),
            end: moment(
              getDateFromDayOfWeek(day.day, week.firstDay) +
                moment(slot.end).format('HH:mm')
            )
              .utc()
              .format(),
            channelKey: canalVenteDetailsConfig.channelKey,
            day: day.day,
            spanCounts: 0,
            spanDuration: 0,
            zones: [],
            truckCounts: 1,
          })
        })
      })

      setInitialValues([...valuesInitial])
    }
  }, [isEdit])

  const getData = (day, slotReference, valueName) => {
    const foundDetails = initialValues.find(
      (details) => details.day == day && details?.slotReference == slotReference
    )

    if (!foundDetails) {
      return 0
    }

    if (valueName in foundDetails) {
      return foundDetails[valueName]
    } else {
      return 0
    }
  }

  const getDataOriginal = (day, slotReference, valueName) => {
    const foundDetails = canalVenteDetails.find(
      (details) => details.day == day && details?.slotReference == slotReference
    )

    if (!foundDetails) {
      return 0
    }

    if (valueName in foundDetails) {
      return foundDetails[valueName]
    } else {
      return 0
    }
  }
  const getZoneChecked = (day, slotReference, keyReference) => {
    return (
      initialValues
        .find(
          (details) => details.day == day && details?.slotReference == slotReference
        )
        ?.zones?.includes(keyReference) || false
    )
  }
  //Data change
  const handleChangeData = (event, day, slotReference) => {
    let valuesInitial = _.cloneDeep(initialValues)

    valuesInitial.map((details) => {
      if (details.day == day && details.slotReference == slotReference) {
        details[event.target.name] = event.target.value
      }
      return details
    })

    setInitialValues([...valuesInitial])
  }

  const handleCheckedzone = (event, keyZone, day, slotReference) => {
    let valuesInitial = _.cloneDeep(initialValues)
    valuesInitial.map((details) => {
      if (details.day == day && details.slotReference == slotReference) {
        if (event.target.checked) {
          details.zones.push(keyZone)
        } else {
          const IndexNameZone = details.zones.findIndex((zone) => zone == keyZone)
          details.zones.splice(IndexNameZone, 1)
        }
      }
      return details
    })

    setInitialValues([...valuesInitial])
  }
  const handleChangeExtendedPeriod = (event) => {
    setExtendedPeriod(event.target.checked)
  }

  //Creation || modification Action
  const handleConfirmation = () => {
    isEdit
      ? updateDisponibilite(
          initialValues.map(
            ({ spanCounts, spanDuration, zones, _id, end, start, truckCounts }) => ({
              spanCounts,
              spanDuration,
              zones,
              _id,
              end,
              start,
              truckCounts,
            })
          )
        )
      : createDisponibilite({
          extendedPeriod,
          values: initialValues,
        })
  }

  useEffect(() => {
    if (success) {
      handleClose()
    }
  }, [success])

  return (
    <Dialog
      maxWidthDialog={'lg'}
      open={open}
      title={modalTitle}
      iconTitle={isEdit ? <EditIcon /> : <CreateIcon />}
      style={{ display: errorMessage && 'none' }}
      notPadding={true}
      content={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '16px 0px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0px 16px 16px',
              fontSize: '13px',
              fontWeight: '600',
            }}
          >
            <Box component={'span'}>
              {`${moment(week?.firstDay).format('DD/MM/YYYY')} -
                ${moment(week?.lastDay).format('DD/MM/YYYY')}`}
            </Box>
            <Box component={'span'}> {canalVenteDetailsConfig?.channelLabel}</Box>
          </Box>
          <TabConatainer component={'div'}>
            <Table
              sx={{ backgroundColor: 'white' }}
              stickyHeader
              aria-label="sticky table"
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                tableHead={DisponibiliteTableHead.filter((colonne) =>
                  colonne.visible.includes(isEdit ? 'modification' : 'create')
                )}
              />

              <TableBody
                sx={{
                  '& tr': {
                    '& th:first-of-type': {
                      borderLeft: '1px solid #EBEDF0',
                    },
                  },
                  '& tr:nth-of-type(2)': {
                    '& th': {
                      borderTop: '1px solid #EBEDF0',
                    },
                  },
                  '& tr:last-child': {
                    '& th:first-of-type': {
                      borderBottomLeftRadius: '4px',
                    },
                  },
                }}
              >
                {canalVenteDetailsConfig.schedule.map((day, dayIndex) => {
                  return day.slots.map((slot, slotIndex) => {
                    const labelId = `enhanced-table-${dayIndex}-${slotIndex}`
                    return (
                      <TableRow hover tabIndex={-1} key={`${dayIndex}-${slotIndex}`}>
                        <TableCell
                          key={'day'}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normale"
                          sx={{ color: '#6D7B8E' }}
                        >
                          {DAYS_OF_WEEK_INDEX[day.day].label}
                        </TableCell>
                        <TableCell
                          key={'creneaux'}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normale"
                          sx={{ color: '#6D7B8E' }}
                        >
                          {`${moment(slot?.start).format('HH:mm')}-${moment(
                            slot?.end
                          ).format('HH:mm')}`}
                        </TableCell>
                        <TableCell
                          key={'nb_camion'}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normale"
                          sx={{ color: '#6D7B8E' }}
                        >
                          <TextField
                            placeholder="Nb de camion"
                            id={`truckCounts-${dayIndex}-${slotIndex}`}
                            name="truckCounts"
                            variant="outlined"
                            onChange={(event) => {
                              const inputValue = event.target.value
                              let newValue
                              // Remplacer par '1' si l'inputValue est une chaîne vide
                              if (
                                inputValue.trim() === '' ||
                                inputValue.trim() === '0'
                              ) {
                                newValue = '1'
                              } else {
                                // Supprimer les zéros non significatifs en tête de nombre
                                newValue = inputValue.replace(/^0+/, '')
                              }
                              if (/^\d*\.?\d*$/.test(newValue)) {
                                const updatedEvent = {
                                  ...event,
                                  target: {
                                    name: event.target.name,
                                    value: newValue,
                                  },
                                }
                                handleChangeData(
                                  updatedEvent,
                                  day.day,
                                  slot.slotReference
                                )
                              }
                            }}
                            value={getData(
                              day.day,
                              slot.slotReference,
                              'truckCounts'
                            )}
                            InputProps={{
                              sx: sxInput,
                              inputProps: {
                                type: 'number',
                                pattern: '[0-9]*',
                              },
                              inputMode: 'numeric',
                            }}
                          />
                        </TableCell>
                        <TableCell
                          key={'nb_spans'}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normale"
                          sx={{ color: '#6D7B8E' }}
                        >
                          <TextField
                            placeholder="Nb spans"
                            id={`spanCounts-${dayIndex}-${slotIndex}`}
                            name="spanCounts"
                            variant="outlined"
                            onChange={(event) => {
                              const inputValue = event.target.value
                              let newValue
                              const nbSpan = getDataOriginal(
                                day.day,
                                slot.slotReference,
                                'spanCounts'
                              )
                              // Remplacer par 'nb span orginal si l'inputValue est inferieur
                              if (isEdit && inputValue <= nbSpan) {
                                newValue = nbSpan
                                // Remplacer par '0' si l'inputValue est une chaîne vide
                              } else if (
                                inputValue.trim() === '' ||
                                inputValue.trim() === '0' ||
                                inputValue.trim() === '00'
                              ) {
                                newValue = '0'
                              } else {
                                // Supprimer les zéros non significatifs en tête de nombre
                                newValue = inputValue.replace(/^0+/, '')
                              }
                              if (/^\d*\.?\d*$/.test(newValue)) {
                                const updatedEvent = {
                                  ...event,
                                  target: {
                                    name: event.target.name,
                                    value: newValue,
                                  },
                                }
                                handleChangeData(
                                  updatedEvent,
                                  day.day,
                                  slot.slotReference
                                )
                              }
                            }}
                            value={getData(
                              day.day,
                              slot.slotReference,
                              'spanCounts'
                            )}
                            InputProps={{
                              sx: sxInput,
                              inputProps: {
                                type: 'number',
                                pattern: '[0-9]*',
                              },
                              inputMode: 'numeric',
                            }}
                          />
                        </TableCell>
                        <TableCell
                          key={'duree_span'}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normale"
                          sx={{ color: '#6D7B8E' }}
                        >
                          <TextField
                            placeholder="Durée span"
                            id={`spanDuration-${dayIndex}-${slotIndex}`}
                            name="spanDuration"
                            variant="outlined"
                            onChange={(event) => {
                              const inputValue = event.target.value
                              let newValue
                              // Remplacer par '0' si l'inputValue est une chaîne vide
                              if (
                                inputValue.trim() === '' ||
                                inputValue.trim() === '0' ||
                                inputValue.trim() === '00'
                              ) {
                                newValue = '0'
                              } else {
                                // Supprimer les zéros non significatifs en tête de nombre
                                newValue = inputValue.replace(/^0+/, '')
                              }
                              if (/^\d*\.?\d*$/.test(newValue)) {
                                const updatedEvent = {
                                  ...event,
                                  target: {
                                    name: event.target.name,
                                    value: newValue,
                                  },
                                }
                                handleChangeData(
                                  updatedEvent,
                                  day.day,
                                  slot.slotReference
                                )
                              }
                            }}
                            InputProps={{
                              sx: sxInput,
                              inputProps: {
                                type: 'number',
                                pattern: '[0-9]*',
                              },
                              inputMode: 'numeric',
                            }}
                            value={getData(
                              day.day,
                              slot.slotReference,
                              'spanDuration'
                            )}
                            disabled={
                              isEdit &&
                              getData(day.day, slot.slotReference, 'spanReserved') >
                                0
                            }
                          />
                        </TableCell>
                        {isEdit && (
                          <TableCell
                            key={'nb_spans_restants'}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normale"
                            sx={{ color: '#6D7B8E' }}
                            disabled
                          >
                            {getData(day.day, slot.slotReference, 'spanRestants')}
                          </TableCell>
                        )}
                        <TableCell
                          key={'zone'}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normale"
                          sx={{ color: '#6D7B8E' }}
                        >
                          {canalVenteDetailsConfig?.zones?.length > 0 && (
                            <Box sx={{ display: 'flex' }}>
                              {canalVenteDetailsConfig.zones.map((zone, i) => {
                                return (
                                  <Box
                                    key={i}
                                    sx={{
                                      display: 'flex',
                                      paddingRight: '10px',
                                      alignItems: 'center',
                                      minWidth: '78px',
                                    }}
                                  >
                                    <Checkbox
                                      checked={getZoneChecked(
                                        day.day,
                                        slot.slotReference,
                                        zone.keyReference
                                      )}
                                      onChange={(event) => {
                                        handleCheckedzone(
                                          event,
                                          zone.keyReference,
                                          day.day,
                                          slot.slotReference
                                        )
                                      }}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Box
                                      sx={{
                                        color: '#6D7B8E',
                                        paddingTop: '3px',
                                      }}
                                    >
                                      {zone.zoneLabel}
                                    </Box>
                                  </Box>
                                )
                              })}
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })
                })}
              </TableBody>
            </Table>
          </TabConatainer>
          {!isEdit && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', padding: '16px 5px 0px' }}
            >
              <Checkbox
                checked={extendedPeriod}
                onChange={handleChangeExtendedPeriod}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              {'Appliquer sur le mois prochain'}
            </Box>
          )}
        </Box>
      }
      handleClose={handleClose}
      handleClickAction={handleConfirmation}
      isLoadingButtonAction={isLoadingActionDisponibilite}
    />
  )
}
DisponibilitesModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  modalTitle: PropTypes.string,
  isEdit: PropTypes.bool,
  canalVenteDetailsConfig: PropTypes.object,
  canalVenteDetails: PropTypes.object,
  week: PropTypes.object,

  createDisponibilite: PropTypes.func,
  updateDisponibilite: PropTypes.func,
  isLoadingActionDisponibilite: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default DisponibilitesModal

const EnhancedTableHead = ({ tableHead }) => {
  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sx={{
              fontWeight: '600',
              fontSize: '12px',
              borderColor: '#F0F0F0',
              background: '#E3EFFA',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  tableHead: PropTypes.array,
  setOpen: PropTypes.func,
  globalID: PropTypes.string,
  isCreate: PropTypes.bool,
}
