import { connect } from 'react-redux'
import Notification from '../../../pages/main/Orders/DetailsDelivery/components/details/notifications'

import {
  getNotificationOrder,
  getLoadingOrderNotification,
} from '../../../redux/order/selectors'
import { getOrderNotification } from '../../../redux/order/actions'

const mapStateToProps = (state) => ({
  smsNotification: getNotificationOrder(state).filter(
    (notif) => notif.channel.codeName === 'sms'
  ),
  emailNotification: getNotificationOrder(state).filter(
    (notif) => notif.channel.codeName === 'email'
  ),
  isLoadingOrderNotification: getLoadingOrderNotification(state),
})
const mapDisptachToProps = (dispatch) => ({
  getOrderNotification: (codeCourse) => dispatch(getOrderNotification(codeCourse)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Notification)
