import { connect } from 'react-redux'
import MapSection from '../../../pages/main/PlaceOrders/Map'

import {
  getMarkersState,
  getDirectionsState,
  getMapOptionsState,
  getOrdersState,
} from '../../../redux/shipment/selectors'
import { oneWeekShipments } from '../../../redux/shipment/selectors'
import {
  getGoogleMapDirectionRequest,
  resetDirections,
} from '../../../redux/googleMapDirections/actions'
import { getDirectionRoute } from '../../../redux/googleMapDirections/selectors'

const mapStateToProps = (state) => ({
  markers: getMarkersState(state),
  directions: getDirectionsState(state),
  mapOptions: getMapOptionsState(state),
  salesChannelOneWeekShipments: oneWeekShipments(state),
  directionRoute: getDirectionRoute(state),
  orders: getOrdersState(state),
})
const mapDisptachToProps = (dispatch) => ({
  getGoogleMapDirection: (id, origin, destination) =>
    dispatch(getGoogleMapDirectionRequest(id, origin, destination)),
  resetDirections: () => dispatch(resetDirections()),
})
export default connect(mapStateToProps, mapDisptachToProps)(MapSection)
