import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AutoComplete from '../../../../components/AutoComplete'
import SearchIcon from '@mui/icons-material/Search'

import Dialog from '../../../../components/ModalDialog'
import { CircularProgress, IconButton } from '@mui/material'

export default function ImportationParticipants({
  open,
  sxInput,
  handleClose,
  onSearch,
  isLoading,
  options,
  countOptions,
  onSearchMore,
  changeParticipantsStatus,
  isLoadingParticipantAction,
  success,
  errorMessage,
  resetParticipantConfig,
}) {
  const [select, setSelect] = useState(false)
  const [valueSelect, setValueSelect] = useState([])
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(25)

  useEffect(() => {
    if (success) {
      handleClose()
      resetParticipantConfig()
    }
  }, [success])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0 && !select) {
        const filter = {
          actif: true,
          isParticipant: false,
          limit: 25,
          offset: 0,
          query: search.trim().toLowerCase(),
        }
        onSearch(filter)
      } else if (search == '') {
        const filter = {
          actif: true,
          isParticipant: false,
          limit: 25,
          offset: 0,
        }
        onSearch(filter)
      }
      setOffset(25)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    setSelect(false)
    if (value) {
      let val = value
      setSearch(val)
    } else {
      setSearch('')
      setSelect(false)
    }
  }

  const onSelect = (value) => {
    if (value) {
      setValueSelect(value)
      setSelect(true)
    }
  }

  const loadMore = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      countOptions >= offset
    ) {
      setOffset(offset + 25)
      const filter = {
        actif: true,
        isParticipant: false,
        limit: 25,
        offset: offset,
        query: search.length > 0 ? search.trim().toLowerCase() : undefined,
      }
      onSearchMore(filter)
    }
  }
  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Importation Participants'}
      fullWidth={true}
      style={{ display: errorMessage && 'none' }}
      content={
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '0px 16px 16px 0px',
            borderRadius: '5px',
          }}
        >
          <div style={{ width: select ? '91%' : '100%' }}>
            <AutoComplete
              freeSolo
              value={valueSelect}
              name={'search-multiple'}
              onChange={(event, value) => {
                onSelect(value)
              }}
              onInputChange={(event, value) => onChangeSearch(value)}
              id="Search-Multiple"
              options={options}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              variant="outlined"
              placeholder={'Rechercher'}
              sxInputStyle={sxInput}
              multiple={true}
              fullWidth={true}
              ListboxProps={{
                onScroll: loadMore,
              }}
              loading={isLoading}
              inputValue={search}
              startIcon={
                <SearchIcon
                  sx={{
                    paddingRight: '4px',
                    width: '26px !important',
                    height: '26px !important',
                    color: '#C2C2C2 !important',
                  }}
                />
              }
            />
          </div>
          {select && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isLoadingParticipantAction ? (
                <CircularProgress
                  color="primary"
                  style={{ width: '30px', height: '30px' }}
                />
              ) : (
                <IconButton
                  id={`update-Participant`}
                  onClick={() =>
                    changeParticipantsStatus(valueSelect.map((el) => el.id))
                  }
                >
                  <AddCircleIcon
                    sx={{ fontSize: 30, cursor: 'pointer' }}
                    color="primary"
                  />
                </IconButton>
              )}
            </div>
          )}
        </div>
      }
      handleClose={() => {
        handleClose()
      }}
      isClosedIcon={true}
    />
  )
}

ImportationParticipants.propTypes = {
  open: PropTypes.bool,
  sxInput: PropTypes.object,
  handleClose: PropTypes.func,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  countOptions: PropTypes.number,
  onSearchMore: PropTypes.func,
  changeParticipantsStatus: PropTypes.func,
  isLoadingParticipantAction: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  resetParticipantConfig: PropTypes.func,
}
