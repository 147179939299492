import moment from 'moment'
import { defaultUncheckedStatus, STATUS_LABEL_LIST } from '../../utils/values'

export const FilterCommande = () => {
  const connectedUser = JSON.parse(localStorage.getItem('currentUser'))
  const startDate = moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm')
  const endDate = moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm')

  return {
    daterange: [
      startDate.toString().slice(0, 10) + '~' + endDate.toString().slice(0, 10),
    ],
    status: STATUS_LABEL_LIST.filter((c) => !defaultUncheckedStatus.includes(c.code))
      .map((e) => e.code)
      .flat(1),
    drivers: [],
    salesChannels: [],
    prestation:
      connectedUser?.prestations.map((e) => {
        return e.id + ''
      }) || [],
    createdAt: [],
    businessOwner: connectedUser?.isBusinessOwner ? [connectedUser.login] : [],
    client: [],
    warehouse:
      connectedUser?.warehouses.map((e) => {
        return e.id + ''
      }) || [],
    rdi: [],
  }
}

export const FilterTableauBord = () => {
  const connectedUser = JSON.parse(localStorage.getItem('currentUser'))
  const startDate = moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm')
  const endDate = moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm')

  return {
    daterange: [
      startDate.toString().slice(0, 10) + '~' + endDate.toString().slice(0, 10),
    ],
    status: STATUS_LABEL_LIST.filter((c) => !defaultUncheckedStatus.includes(c.code))
      .map((e) => e.code)
      .flat(1),
    salesChannels: [],
    createdAt: [],
    businessOwner: connectedUser?.isBusinessOwner ? [connectedUser.login] : [],
  }
}

export const FilterPlanification = () => {
  const connectedUser = JSON.parse(localStorage.getItem('currentUser'))

  const avantJour = new Date()
  avantJour.setDate(avantJour.getDate() - 1)
  const apresJour = new Date()
  apresJour.setDate(apresJour.getDate() + 1)
  const startDate = moment(avantJour).startOf('day').format('YYYY-MM-DD HH:mm')
  const endDate = moment(apresJour).endOf('day').format('YYYY-MM-DD HH:mm')
  return {
    status: [],

    salesChannels: [],
    prestation:
      connectedUser?.prestations.map((e) => {
        return e.id + ''
      }) || [],
    department: [],
    warehouse:
      connectedUser?.warehouses.map((e) => {
        return e.id + ''
      }) || [],
    dropOffDay: [],
    datepicked: [],
    tourList: [],
    createdAt: [],
    businessOwner: connectedUser?.isBusinessOwner ? [connectedUser.login] : [],
    dropOffDayRange: [
      startDate.toString().slice(0, 10) + '~' + endDate.toString().slice(0, 10),
    ],
    client: [],
  }
}

export const FilterEntrepot = () => {
  const connectedUser = JSON.parse(localStorage.getItem('currentUser'))
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const dateTomorrow = moment(tomorrow).startOf('day').format('YYYY-MM-DD HH:mm')

  return {
    dropOffDay: [dateTomorrow.toString().slice(0, 10)],
    warehouse:
      connectedUser?.warehouses.map((e) => {
        return e.id + ''
      }) || [],
    client: [],
  }
}

export const FilterDiscussion = () => {
  return {
    warehouse: [],
    subjects: [],
    salesChannels: [],
    reply: [],
    processingDepartment: [],
    direction: [],
    nature: [],
  }
}

export const FilterFreights = () => {
  const startDate = moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm')
  const endDate = moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm')

  return {
    receptionDate: [
      startDate.toString().slice(0, 10) + '~' + endDate.toString().slice(0, 10),
    ],
  }
}

export const FilterTour = () => {
  const startDate = moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm')
  const endDate = moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm')
  const dropOffDayRange = [
    startDate.toString().slice(0, 10) + '~' + endDate.toString().slice(0, 10),
  ]
  return {
    dropOffDayRange: dropOffDayRange,
  }
}
