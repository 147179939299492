import React from 'react'
import PropTypes from 'prop-types'
import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import NatureFilterIcon from '../../../../../../assets/icons/navFilter/natureFilter'
import { NATURE_DISCUSSION } from '../../../../../../utils/values'
export default function NatureDiscussionFilter({
  filterStateChanged,
  natureDiscussion,
}) {
  const onNatureChange = (selectedNature) => {
    filterStateChanged('nature', selectedNature ? selectedNature : [])
  }
  return (
    <SelectListWithSearch
      onDataChanged={onNatureChange}
      defaultLabel={'Nature'}
      dataList={NATURE_DISCUSSION}
      filtersValues={natureDiscussion?.map((e) => decodeURI(e))}
      Icon={NatureFilterIcon}
      id={'natureFilter'}
    />
  )
}
NatureDiscussionFilter.propTypes = {
  filterStateChanged: PropTypes.func,
  natureDiscussion: PropTypes.array,
}
