import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AutoCompleteSearch from '../ListWithSearch/searchListWithSingleCheck/AutoCompleteSearch'
import { useState } from 'react'

import Dialog from '../ModalDialog'

import {
  DONE_STATUS,
  getfullName,
  LIVRAISON_IMPOSSIBLE,
  RETRAIT_IMPOSSIBLE,
} from '../../utils/values'
import DialogAvertissement from '../DialogError/dialogAvertissementClose'

export default function AssignDialog({
  drivers,
  open,
  hideDialog,
  assignDriver,
  selectedShipments,
  isLoadingDrivers,
  driversCount,
  fetchDrivers,
  fetchDriversMore,
  isLoadingActionOrder,
  errorActionCourse,
}) {
  const [checked, setChecked] = useState([])
  const [errorMessage, setErrorMessage] = useState()
  const [init, setInit] = useState(false)
  const [search, setSearch] = useState('')
  const [index, setIndex] = useState(0)

  useEffect(() => {
    setErrorMessage(
      selectedShipments.some((ship) =>
        [
          RETRAIT_IMPOSSIBLE.code,
          LIVRAISON_IMPOSSIBLE.code,
          DONE_STATUS.code,
        ].includes(ship.status.code)
      )
        ? 'Une des courses est déja cloturé. Veuillez la désélectionner'
        : undefined
    )
  }, [])

  useEffect(() => {
    const filter = {
      actif: true,
      limit: 10,
      offset: 0,
    }

    fetchDrivers(filter)
    setInit(true)
    return () => {
      setIndex(0)
    }
  }, [])

  useEffect(() => {
    const list = drivers.map(({ id, ...driver }) => {
      return {
        name: getfullName(driver, 'N/A'),
        id,
        checked: false,
      }
    })
    setChecked(list)
  }, [drivers])

  const handleAssign = () => {
    const checkedDriverId = checked.filter((e) => e.checked == true)[0]?.id
    const data = {
      driver: { id: `${checkedDriverId}` },
    }
    assignDriver({ data, selectedShipments })
  }

  const handleToggle = (value) => () => {
    const list = checked.map((elem) => {
      return {
        name: elem.name,
        id: elem.id,
        checked: elem.id === value?.id,
      }
    })

    setChecked(list)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        onSearch(search)
      } else {
        if (init) {
          const filter = {
            actif: true,
            limit: 10,
            offset: 0,
          }
          fetchDrivers(filter)
          setIndex(0)
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    let val = value.trim()
    setSearch(val)
    setIndex(0)
  }

  const onSearch = (search) => {
    const filter = {
      actif: true,
      limit: 10,
      offset: 0,
      query: search,
    }

    fetchDrivers(filter)
  }

  const fetchData = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) <=
        event.target.clientHeight &&
      index < driversCount &&
      !isLoadingDrivers
    ) {
      setIndex(index + 10)
      const filter = {
        actif: true,
        limit: 10,
        offset: index + 10,
        query: search.length > 0 ? search : undefined,
      }
      fetchDriversMore(filter)
    }
  }

  useEffect(() => {
    if (errorActionCourse) hideDialog()
  }, [errorActionCourse])

  return (
    <>
      {errorMessage ? (
        <DialogAvertissement
          messageErreur={errorMessage}
          open={errorMessage}
          onClose={() => {
            hideDialog()
          }}
        />
      ) : (
        <Dialog
          maxWidthDialog={'md'}
          open={open}
          title={'Choisir un chauffeur'}
          content={
            <div style={{ paddingBottom: '16px' }}>
              <AutoCompleteSearch
                suggestions={checked}
                checked={checked}
                handleToggle={handleToggle}
                onChangeSearch={onChangeSearch}
                search={search}
                fetchDriversScroll={fetchData}
                isLoadingDrivers={isLoadingDrivers}
              />
            </div>
          }
          handleClose={hideDialog}
          handleClickAction={handleAssign}
          disabled={isLoadingDrivers || !checked.some((el) => el.checked)}
          isLoadingButtonAction={isLoadingActionOrder}
        />
      )}
    </>
  )
}
AssignDialog.propTypes = {
  getAllDrivers: PropTypes.func,
  drivers: PropTypes.array,
  open: PropTypes.bool,
  hideDialog: PropTypes.bool,
  assignDriver: PropTypes.func,
  selectedShipments: PropTypes.array,
  isLoadingDrivers: PropTypes.bool,
  driversCount: PropTypes.number,
  fetchDrivers: PropTypes.func,
  fetchDriversMore: PropTypes.func,
  isLoadingActionOrder: PropTypes.bool,
  errorActionCourse: PropTypes.string,
}
