import { connect } from 'react-redux'

import VerificationModal from '../../../pages/settings/components/Modals/VerificationModal'
import { deleteSession } from '../../../redux/sessions/actions'
import {
  getisLoadingSessionAction,
  getSuccess,
  getErrorMessage,
} from '../../../redux/sessions/selectors'
const mapStateToProps = (state) => ({
  isLoading: getisLoadingSessionAction(state),
  success: getSuccess(state),
  errorMessage: getErrorMessage(state),
})

const mapDisptachToProps = (dispatch) => ({
  verificationActon: (status) => dispatch(deleteSession(status)),
})

export default connect(mapStateToProps, mapDisptachToProps)(VerificationModal)
