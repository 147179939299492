import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import FilesCourse from '../../../../../../containers/main/FilesCourse/commandeEnCours'

const DetailsColis = ({ order, isLoading }) => {
  const [open, setModalState] = React.useState(false)
  const handlePrintInvoiceModal = () => {
    setModalState(!open)
  }

  return (
    <>
      <Button
        variant="contained"
        sx={{
          width: '100%',
          backgroundColor: 'white !important',
          color: '#1976D2 !important',
          padding: '15px 0 15px !important',
          textTransform: 'capitalize !important',
          fontSize: '13px !important',
          fontWeight: '600 !important',
        }}
        startIcon={<CloudDownloadIcon />}
        onClick={() => {
          handlePrintInvoiceModal()
        }}
        disabled={isLoading}
      >
        Télécharger BL / Facture
      </Button>
      {order && (
        <FilesCourse
          show={open}
          handleModal={handlePrintInvoiceModal}
          codeCourse={order.code}
          assemblies={order.assemblies}
        />
      )}
    </>
  )
}
DetailsColis.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.object,
}
export default DetailsColis
