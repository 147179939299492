import { AVAILABLE_EXTENSION } from '../values'

export const getExtension = (fileName) => {
  const extension = fileName.split('.').pop() || ''
  return extension.toLowerCase()
}

const validateFileExtension = (files) => {
  return files
    .map((file) => getExtension(file?.name))
    .every((extension) => AVAILABLE_EXTENSION.FILES_XLS.includes(extension))
}

const validateFileName = (files) => {
  return files.every((file) => file.name == null || file.name == undefined)
}

export const validateUploadFileXLS = (files) => {
  if (
    files == null ||
    files == undefined ||
    files.length <= 0 ||
    validateFileName(files)
  ) {
    return {
      isError: true,
      message: 'Fichier non valide!',
    }
  }

  const validExtension = validateFileExtension(files)

  if (!validExtension) {
    return {
      isError: true,
      message: 'Extension de fichier non valide!',
    }
  }

  return {
    isError: false,
    message: 'Uploade avec succès',
  }
}
