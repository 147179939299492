import { connect } from 'react-redux'
import ProgramDialogDetails from '../../../components/ShipmentAction/Program'
import {
  getConfigCanal,
  programCourse,
  resetConfigCanal,
} from '../../../redux/order/actions'
import {
  getIsLoadingRecommendedCreneau,
  getRecommendedCreneauListState,
} from '../../../redux/operationalHours/selectors'
import {
  getDayDispo,
  getDayDisponibilites,
  resetRdvDispo,
} from '../../../redux/scheduleOrder/actions'
import {
  getIsLoadingDisponibilite,
  getRdvDispo,
} from '../../../redux/scheduleOrder/selector'
import {
  getCreneauRecommendedDisponibilites,
  getRecommendedCreneau,
} from '../../../redux/operationalHours/actions'
import {
  getCanalConfig,
  getErrorActionCourse,
  getIsLoadingActionOrder,
  getIsLoadingGetConfig,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  recommendedCreneauList: getRecommendedCreneauListState(state),
  rdvDispo: getRdvDispo(state),
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  errorActionCourse: getErrorActionCourse(state),
  isLoadingRecommendedCreneau: getIsLoadingRecommendedCreneau(state),
  configCanal: getCanalConfig(state),
  isLoadingGetConfig: getIsLoadingGetConfig(state),
  isLoadingDisponibilite: getIsLoadingDisponibilite(state),
})
const mapDisptachToProps = (dispatch) => ({
  programmCourses: (data) => dispatch(programCourse(data)),
  getDayDispo: (dateDebut, dateFin, codePostal) =>
    dispatch(getDayDispo(dateDebut, dateFin, codePostal)),

  getRecommendedCreneau: (date, codeCanal) =>
    dispatch(getRecommendedCreneau(date, codeCanal)),
  resetRdvDispo: () => dispatch(resetRdvDispo()),
  getCreneauRecommendedDisponibilites: (date, serviceTime, idCanal, zipCode) =>
    dispatch(
      getCreneauRecommendedDisponibilites(date, serviceTime, idCanal, zipCode)
    ),
  getConfigCanal: (codeCanal, metaKey) =>
    dispatch(getConfigCanal(codeCanal, metaKey)),
  resetConfigCanal: () => dispatch(resetConfigCanal()),

  getDayDisponibilites: (startDate, endDate, channelKey, serviceTime, zipCode) =>
    dispatch(
      getDayDisponibilites(startDate, endDate, channelKey, serviceTime, zipCode)
    ),
})

export default connect(mapStateToProps, mapDisptachToProps)(ProgramDialogDetails)
