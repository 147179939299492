import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import AutoCompleteSearch from './AutoCompleteSearch'

import DrawerFilter from '../../Drawer'

export default function SelectListWithSearch({
  dataList,
  disabled,
  onDataChanged,
  defaultLabel,
  filtersValues,
  Icon,
  id,
}) {
  const [opentDrawer, setOpentDrawer] = useState(false)
  const [selected, setSelected] = useState([])
  const [checkedAll, setCheckedAll] = useState(true)
  const [search, setSearch] = useState('')
  const [label, setLabel] = useState('Tous')
  const [init, setInit] = useState(false)
  const [data, setData] = useState(dataList)
  const [dataSearch, setDataSearch] = useState([])
  const [isMoreLoading, setIsMoreLoading] = useState(false)
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)

  //Init selected List
  const initializationDataList = () => {
    Object.keys(dataList).map(
      (key) =>
        (dataList[key] = {
          ...dataList[key],
          index: 25,
          radiosLimit: dataList[key].radios.slice(0, 25),
        })
    )
    setData(dataList)
    setDataSearch([])
    setIsSearchLoading(false)
  }
  useEffect(() => {
    if (dataList) {
      const checkedAll = filtersValues?.includes('TOUS')
      if (filtersValues && filtersValues.length > 0 && !checkedAll) {
        let regroupement = []

        Object.keys(dataList).forEach((key) =>
          regroupement.push(dataList[key].radioGroupe)
        )
        let newSelected = []
        filtersValues.forEach((el) => {
          if (regroupement.map((reg) => reg.code).includes(el)) {
            newSelected.push(...dataList[el].radios.map((el) => el.code))
          } else {
            newSelected.push(el)
          }
        })
        setSelected([...new Set([...selected, ...newSelected])])
      } else if (selected.length === 0 || checkedAll) {
        const radios = []
        Object.keys(dataList).forEach((key) => radios.push(...dataList[key].radios))
        setSelected(radios.map((n) => n.code))
      }
      initializationDataList()
      setInit(true)
    }
  }, [dataList, filtersValues])
  //Change lable
  useEffect(() => {
    handleLabelDisplay()
  }, [selected])

  //label Filter
  const handleLabelDisplay = () => {
    let selectedM = selected
    Object.keys(dataList).forEach((key) => {
      if (ischeckedAccordion(key)) {
        selectedM = selectedM.filter(
          (el) => !dataList[key].radios.map((el) => el.code).includes(el)
        )
        selectedM.push(dataList[key].radioGroupe.code)
      }
    })

    const radios = []
    Object.keys(dataList).forEach((key) => radios.push(...dataList[key].radios))
    const radiosGroupe = []
    Object.keys(dataList).forEach((key) =>
      radiosGroupe.push(dataList[key].radioGroupe)
    )
    let textRadio = []
    textRadio = radios
      .filter((el) => selectedM.includes(el.code))
      .map((el) => el.name)
      .join(', ')
    let textRadioGroupe = []
    textRadioGroupe = radiosGroupe
      .filter((el) => selectedM.includes(el.code))
      .map((el) => el.name)

    let text = textRadioGroupe.concat(textRadio).join(', ')
    if (text.length > 20) {
      text = text.substring(0, 20).concat('...')
    }
    setLabel(
      selected.length > 0 && selected.length < radios.length ? `${text}` : 'Tous'
    )
    return text
  }

  //FETCH more data with search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        onSearch(search.trim())
      } else {
        if (init) {
          initializationDataList()
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    setIsSearchLoading(true)
    setSearch(value)
  }

  const onSearch = (search) => {
    const radios = []
    Object.keys(dataList).forEach((key) => radios.push(...dataList[key].radios))
    const filteredSuggestions = radios.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(search.toLowerCase())
    )
    setDataSearch(filteredSuggestions)
    setIsSearchLoading(false)
  }

  //FETCH more data with scroll
  const fetchMoreData = (event, key) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) <=
        event.target.clientHeight &&
      data[key].index < data[key].countRadios &&
      !isMoreLoading
    ) {
      const dataM = data
      setIsMoreLoading(true)
      setTimeout(() => {
        dataM[key] = {
          ...dataM[key],
          index: dataM[key].index + 25,
          radiosLimit: dataM[key].radios.slice(0, dataM[key].index + 25),
        }
        setData(dataM)
        setIsMoreLoading(false)
      }, 500)
    }
  }

  // Click single data
  const handleToggle = (value) => () => {
    const selectedIndex = selected.indexOf(value)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleDoubleClick = (value) => () => {
    setSelected([value])
  }
  // Click All list DATA
  const handleToggleAll = (event) => {
    setCheckedAll(event.target.checked)
    if (event.target.checked) {
      const radios = []
      Object.keys(dataList).forEach((key) => radios.push(...dataList[key].radios))
      setSelected(radios.map((n) => n.code))
      return
    }
    setSelected([])
  }
  const handleDoubleClickAll = () => {
    setCheckedAll(!checkedAll)
    if (!checkedAll) {
      const radios = []
      Object.keys(dataList).forEach((key) => radios.push(...dataList[key].radios))
      setSelected(radios.map((n) => n.code))
      return
    }
    setSelected([])
  }

  //checked Acordion
  const handleToggleAccordion = (key) => {
    if (!ischeckedAccordion(key)) {
      setSelected([
        ...new Set([...selected, ...dataList[key].radios.map((el) => el.code)]),
      ])
    } else {
      const newSelected = selected.filter(
        (el) => !dataList[key].radios.map((el) => el.code).includes(el)
      )

      setSelected(newSelected)
    }
  }

  //Verif checked element
  const isSelected = (code) => selected.indexOf(code) !== -1
  const isSelectedAll = () => {
    const radios = []
    Object.keys(dataList).forEach((key) => radios.push(...dataList[key].radios))
    return radios.every((el) => selected.indexOf(el.code) !== -1)
  }
  const ischeckedAccordion = (key) => {
    const listAcordion = dataList[key].radios
    return listAcordion.every((el) => selected.indexOf(el.code) !== -1)
  }

  // open && close drawer
  const toggleDrawer = (open, event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (!open) {
      const radios = []
      Object.keys(dataList).forEach((key) => radios.push(...dataList[key].radios))
      let selectedM = selected
      Object.keys(dataList).forEach((key) => {
        if (ischeckedAccordion(key)) {
          selectedM = selectedM.filter(
            (el) => !dataList[key].radios.map((el) => el.code).includes(el)
          )
          selectedM.push(dataList[key].radioGroupe.code)
        }
      })
      if (selected.length === 0) {
        setSelected(radios.map((n) => n.code))
        onDataChanged(null)
      } else {
        onDataChanged(
          selected.length === radios.length ? ['TOUS'] : [...new Set(selectedM)]
        )
      }
    }
    setOpentDrawer(open)
    setSearch('')
    initializationDataList()
  }

  // OPEN && Close Accordion
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    initializationDataList()
    event.preventDefault()
  }
  return (
    <>
      <DrawerFilter
        filterLabel={`${defaultLabel} : ${label}`}
        disabled={disabled}
        content={
          <AutoCompleteSearch
            dataList={data}
            handleToggle={handleToggle}
            handleDoubleClick={handleDoubleClick}
            fetchMoreData={fetchMoreData}
            onChangeSearch={onChangeSearch}
            search={search}
            handleDoubleClickAll={handleDoubleClickAll}
            handleToggleAll={handleToggleAll}
            numSelected={isSelectedAll()}
            isSelected={isSelected}
            dataSearch={dataSearch}
            isSearchLoading={isSearchLoading}
            handleChangeAccordion={handleChangeAccordion}
            expanded={expanded}
            handleToggleAccordion={handleToggleAccordion}
            checkedAccordion={ischeckedAccordion}
          />
        }
        toggleDrawer={toggleDrawer}
        opentDrawer={opentDrawer}
        Icon={Icon}
        id={id}
      />
    </>
  )
}
SelectListWithSearch.propTypes = {
  dataList: PropTypes.array,
  disabled: PropTypes.bool,
  onDataChanged: PropTypes.func,
  defaultLabel: PropTypes.string,
  filtersValues: PropTypes.array,
  Icon: PropTypes.any,
  id: PropTypes.string,
}
