import {
  GET_FORMATIONS,
  PAGE_CHANGED,
  PAGE_LIMIT_CHANGED,
  REMOVE_ERROR,
  RESET_SUCCESS_ACTION,
  RESET_CONFIG,
  SEARCH_FORMATIONS,
  ADD_FORMATION,
  UPDATE_FORMATION,
  ARCHIVAGE_FORMATION,
  SET_ERROR_MESSAGE,
  GET_FILES_BY_ID,
  GET_FORMATION_BY_ID,
  GET_FILE_BY_URL,
  GET_FORMATIONS_MORE,
} from './constants'

export const fetchFormations = (filter) => ({
  type: GET_FORMATIONS,
  payload: filter,
})
export const fetchFormationsMore = () => ({
  type: GET_FORMATIONS_MORE,
})

export const pageChangedFormation = (offset) => ({
  type: PAGE_CHANGED,
  payload: { offset },
})

export const pageLimitChanged = (limit) => ({
  type: PAGE_LIMIT_CHANGED,
  payload: { limit },
})

export const removeError = () => ({
  type: REMOVE_ERROR,
})

export const resetSuccessAction = () => ({
  type: RESET_SUCCESS_ACTION,
})

export const resetConfigFormation = () => ({
  type: RESET_CONFIG,
})

export const onSearchFormation = (search) => ({
  type: SEARCH_FORMATIONS,
  payload: { search },
})

export const createFormation = (formation) => ({
  type: ADD_FORMATION,
  payload: { formation },
})

export const updateFormation = (payload) => ({
  type: UPDATE_FORMATION,
  payload,
})

export const archiverFormation = (payload) => ({
  type: ARCHIVAGE_FORMATION,
  payload,
})

export const setErrorUploadFile = (message) => ({
  type: SET_ERROR_MESSAGE,
  payload: { message },
})

export const fetchFilesById = (id, path) => ({
  type: GET_FILES_BY_ID,
  payload: { id: id, path: path },
})

export const fetchFormationById = (id) => ({
  type: GET_FORMATION_BY_ID,
  payload: { id: id },
})

export const fetchFileByUrl = (url) => ({
  type: GET_FILE_BY_URL,
  payload: { url },
})
