import { httpDelete, httpFetch, httpGet, httpPatch, httpPost } from '../http/actions'
import queryString from 'query-string'
import { axiosHTTP, getValue } from '../../utils'
import {
  GET_BUSINESS_OWNER,
  GET_BUSINESS_OWNER_SUCCESS,
  GET_BUSINESS_OWNER_FAILURE,
  GET_ALL_SUBJECT,
  GET_ALL_SUBJECT_SUCCESS,
  GET_ALL_SUBJECT_FAILURE,
  FETCH_DRIVERS_FILTER,
  FETCH_DRIVERS_FILTER_SUCCESS,
  FETCH_DRIVERS_FILTER_FAILURE,
  DRIVERS_FILTER,
  GET_PRESTATIONS,
  GET_PRESTATIONS_SUCCESS,
  GET_PRESTATIONS_FAILURE,
  GET_DEPARTEMENTS,
  GET_DEPARTEMENTS_SUCCESS,
  GET_DEPARTEMENTS_FAILURE,
  GET_MACRO_CANAL,
  GET_MACRO_CANAL_SUCCESS,
  GET_MACRO_CANAL_FAILURE,
  GET_SALES_CHANNEL,
  GET_SALES_CHANNEL_SUCCESS,
  GET_SALES_CHANNEL_FAILURE,
  GET_TOUR_LIST,
  GET_TOUR_LIST_SUCCESS,
  GET_TOUR_LIST_FAILURE,
  GET_TOUR_LIST_MORE,
  GET_TOUR_LIST_MORE_SUCCESS,
  GET_TOUR_LIST_MORE_FAILURE,
  GET_WAREHOUSE,
  GET_WAREHOUSE_SUCCESS,
  GET_WAREHOUSE_FAILURE,
  GET_FILTER_COLLABORATEUR,
  GET_FILTER_COLLABORATEUR_SUCCESS,
  GET_FILTER_COLLABORATEUR_FAILURE,
  CREATE_FILTER_COLLABORATEUR,
  CREATE_FILTER_COLLABORATEUR_SUCCESS,
  CREATE_FILTER_COLLABORATEUR_FAILURE,
  DELETE_FILTER_COLLABORATEUR,
  DELETE_FILTER_COLLABORATEUR_SUCCESS,
  DELETE_FILTER_COLLABORATEUR_FAILURE,
  RENOMMER_FILTER_COLLABORATEUR,
  RENOMMER_FILTER_COLLABORATEUR_SUCCESS,
  RENOMMER_FILTER_COLLABORATEUR_FAILURE,
  MODIFICATION_FILTER_COLLABORATEUR,
  MODIFICATION_FILTER_COLLABORATEUR_SUCCESS,
  MODIFICATION_FILTER_COLLABORATEUR_FAILURE,
} from './actionsConsts'

const api = process.env.REACT_APP_API_URL

let CancelToken = axiosHTTP.CancelToken

let cancelSalesChannels
let cancelTour
let cancelWarehouse
let cancelMacroCanal
let cancelDepartments
let cancelServices
let cancelChauffeurs
let cancelSubjects
let cancelBusinessOwner
let cancelFilterCollaborateur
export default (store) => (next) => (action) => {
  if (action.type === GET_BUSINESS_OWNER) {
    if (cancelBusinessOwner != undefined) {
      cancelBusinessOwner()
    }
    const { search } = action.payload
    const filter = {
      businessOwner: search,
    }
    store.dispatch(
      httpFetch({
        endPoint: `${api}getCourseByBusinessOwner?${queryString.stringify(filter)}`,
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancelBusinessOwner = b
        }),
        success: GET_BUSINESS_OWNER_SUCCESS,
        failure: GET_BUSINESS_OWNER_FAILURE,
      })
    )
  }
  if (action.type === GET_ALL_SUBJECT) {
    if (cancelSubjects != undefined) {
      cancelSubjects()
    }
    const subjects = getValue('subjects', [])
    if (Object.entries(subjects).length === 0) {
      store.dispatch(
        httpGet({
          endPoint: `${api}allSubject`,
          cancelToken: new CancelToken(function executor(b) {
            // An executor function receives a cancel function as a parameter
            cancelSubjects = b
          }),
          success: GET_ALL_SUBJECT_SUCCESS,
          failure: GET_ALL_SUBJECT_FAILURE,
        })
      )
    }
  }
  //Get Drivers for Filter
  if (action.type === FETCH_DRIVERS_FILTER) {
    if (cancelChauffeurs != undefined) {
      cancelChauffeurs()
    }
    const driversFilter = getValue(DRIVERS_FILTER, [])
    if (Object.entries(driversFilter).length === 0) {
      store.dispatch(
        httpGet({
          endPoint: `${api}chauffeurs/all`,
          cancelToken: new CancelToken(function executor(b) {
            // An executor function receives a cancel function as a parameter
            cancelChauffeurs = b
          }),
          success: FETCH_DRIVERS_FILTER_SUCCESS,
          failure: FETCH_DRIVERS_FILTER_FAILURE,
        })
      )
    }
  }

  if (action.type === GET_PRESTATIONS) {
    // cancel the request
    if (cancelServices != undefined) {
      cancelServices()
    }
    const prestations = getValue('services', [])
    if (Object.entries(prestations).length === 0) {
      store.dispatch(
        httpGet({
          endPoint: `${api}service/all`,
          cancelToken: new CancelToken(function executor(b) {
            // An executor function receives a cancel function as a parameter
            cancelServices = b
          }),
          success: GET_PRESTATIONS_SUCCESS,
          failure: GET_PRESTATIONS_FAILURE,
        })
      )
    }
  }
  if (action.type === GET_DEPARTEMENTS) {
    // cancel the request
    if (cancelDepartments != undefined) {
      cancelDepartments()
    }
    const departments = getValue('departments', [])
    if (Object.entries(departments).length === 0) {
      store.dispatch(
        httpGet({
          endPoint: `${api}departement/all`,
          cancelToken: new CancelToken(function executor(b) {
            // An executor function receives a cancel function as a parameter
            cancelDepartments = b
          }),
          success: GET_DEPARTEMENTS_SUCCESS,
          failure: GET_DEPARTEMENTS_FAILURE,
        })
      )
    }
  }

  if (action.type === GET_MACRO_CANAL) {
    // cancel the request
    if (cancelMacroCanal != undefined) {
      cancelMacroCanal()
    }
    const macroCanalList = getValue('macroCanal', [])
    if (Object.entries(macroCanalList).length === 0) {
      store.dispatch(
        httpGet({
          endPoint: `${api}macroCanal/all`,
          cancelToken: new CancelToken(function executor(b) {
            // An executor function receives a cancel function as a parameter
            cancelMacroCanal = b
          }),
          success: GET_MACRO_CANAL_SUCCESS,
          failure: GET_MACRO_CANAL_FAILURE,
        })
      )
    }
  }

  if (action.type === GET_SALES_CHANNEL) {
    // cancel the request
    if (cancelSalesChannels != undefined) {
      cancelSalesChannels()
    }
    const salesChannelsList = getValue('salesChannels', [])
    if (Object.entries(salesChannelsList).length === 0) {
      store.dispatch(
        httpGet({
          endPoint: `${api}canalVenteCorner/all`,
          cancelToken: new CancelToken(function executor(b) {
            // An executor function receives a cancel function as a parameter
            cancelSalesChannels = b
          }),
          success: GET_SALES_CHANNEL_SUCCESS,
          failure: GET_SALES_CHANNEL_FAILURE,
        })
      )
    }
  }

  if (action.type === GET_TOUR_LIST) {
    // cancel the request
    if (cancelTour != undefined) {
      cancelTour()
    }
    const filter = action.payload
    store.dispatch(
      httpGet({
        endPoint: `${api}tour/all?${queryString.stringify(filter)}`,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelTour = c
        }),
        success: GET_TOUR_LIST_SUCCESS,
        failure: GET_TOUR_LIST_FAILURE,
      })
    )
  }
  //Get List tournée (scroll)
  if (action.type === GET_TOUR_LIST_MORE) {
    // cancel the request
    if (cancelTour != undefined) {
      cancelTour()
    }
    const filter = action.payload
    store.dispatch(
      httpGet({
        endPoint: `${api}tour/all?${queryString.stringify(filter)}`,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelTour = c
        }),
        success: GET_TOUR_LIST_MORE_SUCCESS,
        failure: GET_TOUR_LIST_MORE_FAILURE,
      })
    )
  }

  if (action.type === GET_WAREHOUSE) {
    const wareHouseList = getValue('wareHouses', [])
    if (Object.entries(wareHouseList)?.length === 0) {
      // cancel the request
      if (cancelWarehouse != undefined) {
        cancelWarehouse()
      }
      store.dispatch(
        httpGet({
          endPoint: `${api}warehouse/all`,
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelWarehouse = c
          }),
          success: GET_WAREHOUSE_SUCCESS,
          failure: GET_WAREHOUSE_FAILURE,
        })
      )
    }
  }

  if (action.type === GET_FILTER_COLLABORATEUR) {
    // cancel the request
    if (cancelFilterCollaborateur != undefined) {
      cancelFilterCollaborateur()
    }
    const { collaborateurId, pageFilter } = action.payload
    const data = {
      id: collaborateurId,
      tab: pageFilter,
    }
    store.dispatch(
      httpGet({
        endPoint: `${api}filtre/bycollaborateur?${queryString.stringify(data)}`,
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancelFilterCollaborateur = c
        }),
        success: GET_FILTER_COLLABORATEUR_SUCCESS,
        failure: GET_FILTER_COLLABORATEUR_FAILURE,
      })
    )
  }
  //Creation new Filter
  if (action.type === CREATE_FILTER_COLLABORATEUR) {
    const { payload } = action
    store.dispatch(
      httpPost({
        endPoint: `${api}filtre/create`,
        data: payload,
        success: CREATE_FILTER_COLLABORATEUR_SUCCESS,
        failure: CREATE_FILTER_COLLABORATEUR_FAILURE,
      })
    )
  }

  //Delete Filter
  if (action.type === DELETE_FILTER_COLLABORATEUR) {
    const { id } = action.payload
    store.dispatch(
      httpDelete({
        endPoint: `${api}filtre/delete/${id}`,
        success: DELETE_FILTER_COLLABORATEUR_SUCCESS,
        failure: DELETE_FILTER_COLLABORATEUR_FAILURE,
      })
    )
  }

  //Renommer Filter
  if (action.type === RENOMMER_FILTER_COLLABORATEUR) {
    const { payload } = action
    store.dispatch(
      httpPatch({
        endPoint: `${api}filtre/name`,
        data: payload,
        success: RENOMMER_FILTER_COLLABORATEUR_SUCCESS,
        failure: RENOMMER_FILTER_COLLABORATEUR_FAILURE,
      })
    )
  }

  //Modification Filter
  if (action.type === MODIFICATION_FILTER_COLLABORATEUR) {
    const { payload } = action
    store.dispatch(
      httpPatch({
        endPoint: `${api}filtre/criteria`,
        data: payload,
        success: MODIFICATION_FILTER_COLLABORATEUR_SUCCESS,
        failure: MODIFICATION_FILTER_COLLABORATEUR_FAILURE,
      })
    )
  }

  next(action)
}
