/* eslint-disable no-undef */
import { InfoWindow } from '@react-google-maps/api'
import React from 'react'
import PropTypes from 'prop-types'

export default function CustomInfoWindow({ index, position, content }) {
  return (
    <InfoWindow
      key={`info_window_${index}`}
      position={position}
      options={{ pixelOffset: new google.maps.Size(0, -20), maxWidth: 200 }}
    >
      {content}
    </InfoWindow>
  )
}
CustomInfoWindow.propTypes = {
  index: PropTypes.number,
  position: PropTypes.any,
  content: PropTypes.any,
}
