export const fileDecoded = (files) => {
  const decodeFile = files?.map((file) => {
    if (file?.data) {
      const binaryData = atob(file?.data)
      const dataArray = new Uint8Array(binaryData.length)
      for (let i = 0; i < binaryData.length; i++) {
        dataArray[i] = binaryData.charCodeAt(i)
      }
      // Create a new File object with the binary data
      return new File([dataArray], file.fileName, {
        type: file.mimeType,
        ...file,
      })
    } else return file
  })
  return decodeFile
}
export default fileDecoded
