import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CardHeader from '@mui/material/CardHeader'

import ActionIconsComponent from './ActionIconsComponent'
import StatusContent from './StatusContent'
import Tooltip from '@mui/material/Tooltip/Tooltip'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import Chip from '@mui/material/Chip'
import Clipbloard from '../../../../../../components/Clipboard'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
export default function CardHeaderComponent({
  handleModal,
  status,
  shipmentReference,
  assemblies,
  etatPaiement,
  moyenPaiement,
  index,
  modale,
}) {
  const theme = useTheme()
  const [show, setShow] = useState(false)

  const onCopy = () => {
    let content = document.getElementById('card-header')
    let textArea = document.createElement('INPUT')
    textArea.value = shipmentReference
    //textArea.value = shipmentReference
    content.appendChild(textArea)
    textArea.select()
    document.execCommand('copy')
    content.removeChild(textArea)
  }

  return (
    <>
      <CardHeader
        id="card-header"
        action={
          <ActionIconsComponent
            onClick={handleModal}
            assemblies={assemblies}
            index={index}
            modale={modale}
          />
        }
        title={
          <div
            className="card-header-title"
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          >
            <Tooltip
              title={<span className="tootltip-content">{shipmentReference}</span>}
              arrow
            >
              <Chip
                icon={
                  <FiberManualRecordIcon
                    style={{ color: theme.palette.statusColors[status.code] }}
                  />
                }
                id="LDV"
                label={shipmentReference}
                clickable
                style={{ border: 'none' }}
                variant="outlined"
                component={Link}
                value={'/deliveries/' + shipmentReference}
                to={'/deliveries/' + shipmentReference}
              />
            </Tooltip>

            <Clipbloard onCopy={onCopy} show={show} message={'Copier LDV'} />
          </div>
        }
        subheader={
          <StatusContent
            status={status}
            etatPaiement={etatPaiement}
            moyenPaiement={moyenPaiement}
            showPayment={true}
          />
        }
        sx={{
          padding: '16px 16px 0 16px !important',
          minHeight: 78,
          maxHeight: 78,
          height: 78,
        }}
      />
    </>
  )
}
CardHeaderComponent.propTypes = {
  handleModal: PropTypes.func.isRequired,
  status: PropTypes.object,
  assemblies: PropTypes.array,
  shipmentReference: PropTypes.string,
  etatPaiement: PropTypes.string,
  moyenPaiement: PropTypes.object,
  index: PropTypes.number,
  modale: PropTypes.bool,
}
