import { connect } from 'react-redux'
import {
  getLoadingPackageOrder,
  getPackagWarehouse,
} from '../../../redux/orderPackage/selectors'
import DetailsLivraison from '../../../pages/main/Orders/DetailsDelivery/components/details/details'

const mapStateToProps = (state) => ({
  warehousePackage: getPackagWarehouse(state),
  isLoadingPackageOrder: getLoadingPackageOrder(state),
})

export default connect(mapStateToProps, null)(DetailsLivraison)
