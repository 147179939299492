import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TableParticipants from '../components/Table/ConfigurationTable'
import { StyledContainer } from '../styled'
import { ParticipantTableHead } from '../components/Table/data'

const GestionParticipants = ({
  fetchData,
  isLoadingParticipantsList,
  participants,
  participantsCount,
  errorMessage,
  removeError,
  isErrorFetch,
  pageLimitChanged,
  pageChanged,
  success,
  isSuccessRefresh,
  resetSuccessAction,
  searchData,
  onCloseReset,
  fetchFiles,
  errorMessageConfig,
  removeErrorConfig,
}) => {
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (success && isSuccessRefresh) {
      fetchData()
    }
    if (success || isSuccessRefresh) {
      resetSuccessAction()
    }
  }, [success])

  return (
    <>
      <StyledContainer>
        <TableParticipants
          globalID={'Participant'}
          searchData={searchData}
          searchPlaceholder={'Rechercher par participant'}
          tableHead={ParticipantTableHead}
          tableRows={participants}
          isParticipant={true}
          actionButton={true}
          title={'Gestion participants'}
          loadingData={isLoadingParticipantsList}
          pageLimitChanged={pageLimitChanged}
          pageChanged={pageChanged}
          onCloseReset={onCloseReset}
          countData={participantsCount}
          errorMessage={errorMessage || errorMessageConfig}
          removeError={removeError}
          isErrorFetch={isErrorFetch}
          fetchData={fetchData}
          fetchFiles={fetchFiles}
          removeErrorConfig={removeErrorConfig}
          resetPagination={success && isSuccessRefresh}
        />
      </StyledContainer>
    </>
  )
}
GestionParticipants.propTypes = {
  fetchData: PropTypes.func,
  isLoadingParticipantsList: PropTypes.bool,
  participants: PropTypes.array,
  participantsCount: PropTypes.number,

  errorMessage: PropTypes.string,
  removeError: PropTypes.func,
  isErrorFetch: PropTypes.bool,

  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,

  success: PropTypes.bool,
  isSuccessRefresh: PropTypes.bool,
  resetSuccessAction: PropTypes.func,

  searchData: PropTypes.func,
  onCloseReset: PropTypes.func,
  fetchFiles: PropTypes.func,

  errorMessageConfig: PropTypes.string,
  removeErrorConfig: PropTypes.func,
}
export default GestionParticipants
