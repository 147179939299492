import { connect } from 'react-redux'
import PointagePackage from '../../../../pages/main/Orders/DetailsDelivery/components/dialog/detailsColis/pointagePackage/dialogPointageCourse'

import {
  getPackagesStatus,
  getLoadingPackageStatus,
  getPackagesLitigeMotif,
  getIsLoadingPackageLitigeMotif,
  getPackagesLitigeResponsabilite,
  getIsLoadingPackageLitigeResponsabilite,
  getIsLoadingUploadImageLitige,
  getOrderPackageLitigeUploadImage,
  getConfigMDM,
  getIsLoadingUploadImageMDM,
  getOrderPackageUploadMDM,
} from '../../../../redux/orderPackage/selectors'
import {
  getPackageStatus,
  getPackageLitigeMotif,
  getPackageLitigeResponsabilite,
  onUploadImageLitige,
  setLoadingUploadImageLitige,
  onPointagePackages,
  getCanalConfigMDM,
  onUploadImageMDM,
  setLoadingUploadImageMDM,
} from '../../../../redux/orderPackage/actions'

const mapStateToProps = (state) => ({
  packagesStatus: getPackagesStatus(state),
  isLoadingPackageStatus: getLoadingPackageStatus(state),
  packagesLitigeMotif: getPackagesLitigeMotif(state),
  isLoadingPackageLitigeMotif: getIsLoadingPackageLitigeMotif(state),
  packagesLitigeResponsabilite: getPackagesLitigeResponsabilite(state),
  isLoadingPackageLitigeResponsabilite:
    getIsLoadingPackageLitigeResponsabilite(state),
  isLoadingUploadImageMDM: getIsLoadingUploadImageMDM(state),
  isLoadingUploadImageLitige: getIsLoadingUploadImageLitige(state),
  orderPackageLitigeUploadImage: getOrderPackageLitigeUploadImage(state),
  canalconfig: getConfigMDM(state),
  orderPackageMDMUploadImage: getOrderPackageUploadMDM(state),
})
const mapDisptachToProps = (dispatch) => ({
  getPackageStatus: () => dispatch(getPackageStatus()),
  getPackageLitigeMotif: () => dispatch(getPackageLitigeMotif()),
  getPackageLitigeResponsabilite: () => dispatch(getPackageLitigeResponsabilite()),
  getCanalConfigMDM: (codeCanal) => dispatch(getCanalConfigMDM(codeCanal)),
  onUploadImageLitige: (codePackage, imageLitige) =>
    dispatch(onUploadImageLitige(codePackage, imageLitige)),
  setLoadingUploadImageLitige: (loadingUploadImageLitige) =>
    dispatch(setLoadingUploadImageLitige(loadingUploadImageLitige)),
  onUploadImageMDM: (codePackage, file) =>
    dispatch(onUploadImageMDM(codePackage, file)),
  setLoadingUploadImageMDM: (loadingUploadImageMDM) =>
    dispatch(setLoadingUploadImageMDM(loadingUploadImageMDM)),
  onPoint: (payload) => dispatch(onPointagePackages(payload)),
})

export default connect(mapStateToProps, mapDisptachToProps)(PointagePackage)
