import React from 'react'
import List from '@mui/material/List'
import PropTypes from 'prop-types'
import ListItemsComponent from '../listItemComponent'
import { styled } from '@mui/material/styles'

const StyledList = styled(List)(({ theme }) => ({
  width: 320,
  maxWidth: 400,
  backgroundColor: theme.palette.background.paper,
  position: 'relative',
  overflow: 'auto',
  overflowX: 'hidden',
  maxHeight: 280,
}))

const FilterItemsList = ({ dataList, handleToggle, fetchDriversScroll }) => {
  return (
    <StyledList dense onScroll={fetchDriversScroll}>
      <ListItemsComponent
        isProgrammer={true}
        data={dataList}
        handleToggle={handleToggle}
      />
    </StyledList>
  )
}

FilterItemsList.propTypes = {
  handleToggle: PropTypes.func,
  dataList: PropTypes.array,
  checked: PropTypes.array,
  fetchDriversScroll: PropTypes.func,
}
export default FilterItemsList
