import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import CardAccordion from '../card/cardWithAccordion'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TabContext from '@mui/lab/TabContext'
import styled from '@emotion/styled'
import { formatDatesWithBlueColor } from '../../../../../../utils/utils'
import { HistoriqueTab } from '../../../../../../utils/values'
import { MenuItem, Select } from '@mui/material'
import ActivityIcon from '../../../../../../assets/icons/detailsLivraison/activity'

const Activity = ({ order, orderActivity, getActitvity, isLoading }) => {
  const [value, setValue] = useState(0)
  const [data, setData] = useState([])
  const [activityWidth, setActivityWidth] = useState(0)

  const activityRef = useRef(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setActivityWidth(entry.contentRect.width)
      }
    })
    if (activityRef.current) {
      resizeObserver.observe(activityRef.current)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const isSmallScreen = () => {
    if (activityWidth) {
      return activityWidth < 500
    }
    return false
  }
  const handleChange = (newValue) => {
    setValue(newValue)
  }
  useEffect(() => {
    if (HistoriqueTab[value].code != null) {
      const filterdData = orderActivity.filter(
        (item) => item.category === HistoriqueTab[value].code
      )
      setData(filterdData)
    } else {
      setData(orderActivity)
    }
  }, [value, orderActivity])

  useEffect(() => {
    if (order?.id) {
      getActitvity(order.id, 'Course')
    }
  }, [order?.id])
  return (
    <div ref={activityRef}>
      <CardAccordion
        icon={<ActivityIcon />}
        title={'Activités'}
        content={
          <>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                typography: 'body1',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginLeft: '20px',
              }}
            >
              <TabContext value={value}>
                <span style={{ color: '#696969' }}> Afficher :</span>
                {isSmallScreen() ? (
                  <TabSelect
                    value={value}
                    onChange={(event) => handleChange(event.target.value)}
                    style={{ marginLeft: '10px' }}
                    disabled={orderActivity.length === 0}
                    variant="standard"
                  >
                    {HistoriqueTab.map((el, index) => (
                      <MenuItem key={index} value={index}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </TabSelect>
                ) : (
                  HistoriqueTab.map((el, index) => (
                    <TabButton
                      key={index}
                      selected={value === index}
                      variant={'text'}
                      onClick={() => handleChange(index)}
                      disabled={orderActivity.length === 0}
                    >
                      {el.label}
                    </TabButton>
                  ))
                )}
              </TabContext>
            </Box>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <>
                {data.length > 0 ? (
                  <div
                    style={{
                      marginTop: '20px',
                      padding: '5px 5px 10px',
                      overflow: 'auto',
                      maxHeight: '300px',
                    }}
                  >
                    {data.map((item, i) => {
                      return (
                        <div key={i} style={{ paddingBottom: '5px' }}>
                          {item.briefing.includes('<div') ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: item.briefing }}
                            />
                          ) : (
                            <div
                              style={{
                                color: '#696969',
                                fontSize: '14px',
                                fontWeight: '400',
                                marginBottom: '20px',
                                paddingLeft: '25px',
                              }}
                            >
                              {formatDatesWithBlueColor(item.briefing)}
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '20px',
                    }}
                  >
                    <span
                      style={{
                        overflowWrap: 'break-word',
                        fontSize: '12px',
                        fontWeight: '550',
                      }}
                    >
                      {`Historique de ${HistoriqueTab[value].label} est vide`}
                    </span>
                  </div>
                )}
              </>
            )}
          </>
        }
      />
    </div>
  )
}
Activity.propTypes = {
  order: PropTypes.object,
  orderActivity: PropTypes.array,
  isLoadingHistoriqueRDV: PropTypes.bool,
  getActitvity: PropTypes.func,
  isLoading: PropTypes.bool,
}
export default Activity

const TabButton = styled(Button)(({ selected }) => ({
  textTransform: 'none',
  background: !selected ? '#ebecf0' : '#E9F2FF',
  color: !selected ? '#42526E' : '#0C66E4',
  fontSize: '12px',
  '&:hover': {
    background: '#f7f8f900',
  },
  marginLeft: '5px',
  padding: '3px',
}))
const TabSelect = styled(Select)(() => ({
  textTransform: 'none',
  background: '#E9F2FF',
  color: '#0C66E4',
  fontSize: '12px',
  marginLeft: '5px',
  paddingLeft: '10px',
  height: '25px',
  '& .MuiSelect-select.MuiSelect-select': {
    backgroundColor: 'transparent !important', // Set the background color to transparent
  },
}))
