import {
  Paper,
  TableContainer,
  Toolbar,
  Typography,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { DateRangePicker } from 'react-date-range'

export const StyledContainer = styled('div')({
  paddingBottom: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: '70vh',
  '@media (max-width:1200px)': {
    marginTop: 0,
  },
})

export const StyledToolbar = styled(Toolbar)(({ bgcolor, color }) => ({
  paddingLeft: '0 !important',
  color: color,
  background: bgcolor,
  position: 'sticky',
  top: '0',
  zIndex: '0',
  fontWeight: 600,
}))

export const StyledTypography = styled(Typography)({
  fontSize: '18px',
})

export const ContainerDiv = styled('div')({
  top: '55px',
  zIndex: '3',

  position: 'initial',
  minHeight: '70.75px',
  display: 'flex',
  alignItems: 'center',
  //   padding: '10px',
})

export const TabConatainer = styled(TableContainer)({
  overflow: 'scroll',
  maxHeight: 'calc(100vh - 250px)',

  '&::-webkit-scrollbar': {
    width: '0.4em',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#E3EFFA',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
})

export const sxInput = {
  backgroundColor: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E2F0FE',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E2F0FE',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E2F0FE',
  },
}

export const StyledDivContent = styled('div')({
  width: '600px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '20px 20px 15px 5px',

  '@media (max-width: 710px)': {
    width: 'unset',
  },
})

export const StyledDivTopInputs = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '20px',
  '@media (max-width: 710px)': {
    flexDirection: 'column',
    height: 'unset',
  },
})

export const StyledInputModal = styled('div')({
  width: '48%',
  height: '80px',
  '@media (max-width: 710px)': {
    width: '100%',
    height: '100px',
  },
})

export const StyledInputTitle = styled('div')(({ disabled, noPadding }) => ({
  paddingBottom: noPadding ? '0px' : '10px',
  fontWeight: '600',
  color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'black',
}))

export const StyledPaper = styled(Paper)({
  display: 'flex',
  justifyContent: 'center',
  padding: '25px',
  justifyItems: 'center',
  height: '100px',
})

export const StyledSearchContainer = styled('div')({
  display: 'flex',
  boxSizing: 'border-box',
  overflowY: 'auto',
  flex: 'wrap !important',

  '@media (min-width: 500px) ': {
    width: '350px',
  },
})

export const StyledDivFullWidth = styled('div')(({ withoutHeight }) => ({
  marginBottom: '20px',
  height: !withoutHeight && '80px',
  width: '100%',
}))

export const StyledListItemActions = styled(ListItem)({
  flex: '1 1 auto',

  '&:hover': {
    backgroundColor: '#E3EFFA',
    color: '#1876D2',
    '& .IconListItemActionDetails': {
      color: '#1876D2',
    },
  },
})

export const StyledListItemTextActions = styled(ListItemText)({
  display: 'flex',
})

export const StyledDivMultipleBlock = styled('div')({
  display: 'flex',
  '@media (max-width: 710px)': {
    flexWrap: 'wrap',
  },
})

export const StyledDivMultipleBlockTextField = styled(TextField)({
  marginRight: '20px',
  '@media (max-width: 710px)': {
    marginRight: 'unset',
  },
})

export const StyledDateRangePicker = styled(DateRangePicker)({
  zIndex: 1300,
  position: 'relative',
  boxShadow:
    '0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 5%), 0 12.5px 10px rgb(0 0 0 / 6%), 0 22.3px 17.9px rgb(0 0 0 / 7%), 0 41.8px 33.4px rgb(0 0 0 / 9%), 0 100px 80px rgb(0 0 0 / 12%)',
  '& .rdrDefinedRangesWrapper': {
    display: 'none',
  },
  '& .rdrDateDisplayWrapper': {
    display: 'none',
  },
  '& .rdrCalendarWrapper': {
    width: '100%',
  },
  '& .rdrNextPrevButton': {
    background: 'transparent',
  },
  '& .rdrMonthAndYearPickers select:hover': {
    background: 'transparent',
  },
  '& .rdrMonthAndYearPickers select ': {
    fontWeight: '600',
  },
})
