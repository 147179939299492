import { connect } from 'react-redux'
import Client from '../../../pages/main/EditOrders/Client/index'

import {
  getSelectedlientState,
  getClientState,
  getOrdersState,
  getIsOrderDisabled,
} from '../../../redux/shipment/selectors'

import {
  setClientErrorsAction,
  setOrderClientAction,
  setInfoComplementaireEdit,
} from '../../../redux/shipment/actions'
import { COURSE_EDIT_TYPE } from '../../../utils/getOrderStatus'

const mapStateToProps = (state) => ({
  selectedClient: getSelectedlientState(state),
  client: getClientState(state),
  order: getOrdersState(state),
  courseEditable: getIsOrderDisabled(state) == COURSE_EDIT_TYPE.editable,
  courseSemiEdit: getIsOrderDisabled(state) === COURSE_EDIT_TYPE.semiEdit,
})
const mapDisptachToProps = (dispatch) => ({
  updateClient: (client) => dispatch(setOrderClientAction(client)),
  setClientErrors: (errors) => dispatch(setClientErrorsAction(errors)),
  // TODO : use the update order action

  setShipmentComplInfo: (id, info) => dispatch(setInfoComplementaireEdit(id, info)),
})

export default connect(mapStateToProps, mapDisptachToProps)(Client)
