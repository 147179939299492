import React, { useEffect, useState } from 'react'
import { ContainerDiv, StyledSearchContainer, sxInput } from '../styled'
import { TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'

function SearchBarre({ placeholder, onSearch, globalID }) {
  const [valueInput, setValueInput] = useState(null)
  const [isFocused, setIsFocused] = useState(false)
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (valueInput?.length > 0) {
        onSearch(valueInput.trim().toLowerCase())
      } else {
        isFocused && onSearch('')
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [valueInput])

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <ContainerDiv>
      <StyledSearchContainer>
        <TextField
          size="small"
          id={`${globalID}-search-input`}
          variant="outlined"
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          InputProps={{
            endAdornment: <SearchIcon sx={{ color: 'gray' }} />,
            sx: sxInput,
          }}
          value={valueInput}
          onChange={(e) => {
            setValueInput(e.target.value)
          }}
        />
      </StyledSearchContainer>
    </ContainerDiv>
  )
}
SearchBarre.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  globalID: PropTypes.string,
}
export default SearchBarre
