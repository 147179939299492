import { connect } from 'react-redux'

import { displayModeChanged } from '../../../redux/filter/actions'

import { getDisplayGrid } from '../../../redux/filter/selectors'
import DisplaySelection from '../../../components/NavFilter/components/DisplaySelection'

const mapStateToProps = (state) => ({
  displayGrid: getDisplayGrid(state),
})

const mapDisptachToProps = (dispatch) => ({
  displayModeChanged: () => dispatch(displayModeChanged()),
})

export default connect(mapStateToProps, mapDisptachToProps)(DisplaySelection)
