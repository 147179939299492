import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TableVehicule from '../components/Table/ConfigurationTable'
import { StyledContainer } from '../styled'
import { VehiculeTableHead } from '../components/Table/data'

const VehicleList = ({
  fetchData,
  success,
  pageLimitChanged,
  pageChanged,
  onCloseReset,
  isLoadingfetchVehicules,
  vehiculesList,
  vehiculesListCount,
  searchData,
  errorMessage,
  removeError,
  isErrorFetch,
  isSuccessRefresh,
  resetSuccessAction,
  removeErrorConfig,
}) => {
  useEffect(() => {
    fetchData()
  }, [])
  useEffect(() => {
    if (success && isSuccessRefresh) {
      onCloseReset()
      fetchData()
    }
    if (success || isSuccessRefresh) {
      resetSuccessAction()
    }
  }, [success])

  return (
    <StyledContainer>
      <TableVehicule
        globalID={'Vehicule'}
        searchData={searchData}
        searchPlaceholder={'Rechercher par Véhicule'}
        tableHead={VehiculeTableHead}
        tableRows={vehiculesList}
        isVehicle={true}
        actionButton={true}
        title={'Véhicules'}
        loadingData={isLoadingfetchVehicules}
        pageLimitChanged={pageLimitChanged}
        pageChanged={pageChanged}
        onCloseReset={onCloseReset}
        countData={vehiculesListCount}
        errorMessage={errorMessage}
        removeError={removeError}
        isErrorFetch={isErrorFetch}
        fetchData={fetchData}
        removeErrorConfig={removeErrorConfig}
        resetPagination={success && isSuccessRefresh}
      />
    </StyledContainer>
  )
}
VehicleList.propTypes = {
  fetchData: PropTypes.func,
  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,
  onCloseReset: PropTypes.func,
  searchData: PropTypes.func,
  success: PropTypes.bool,
  vehiculesListCount: PropTypes.number,
  errorMessage: PropTypes.string,
  removeError: PropTypes.func,
  isErrorFetch: PropTypes.bool,
  isSuccessRefresh: PropTypes.bool,
  resetSuccessAction: PropTypes.func,
  isLoadingfetchVehicules: PropTypes.bool,
  vehiculesList: PropTypes.array,
  removeErrorConfig: PropTypes.func,
}
export default VehicleList
