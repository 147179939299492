import React from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import './index.scss'
import Tooltip from '@mui/material/Tooltip/Tooltip'

const EN_ATTENTE = 'EN_ATTENTE'

export default function ChangeOrderStatus({
  status,
  etatPaiement,
  moyenPaiement,
  showPayment,
}) {
  return (
    <div style={{ width: '100%' }}>
      <div className=" card-header-status">
        <Tooltip
          arrow
          title={<span className="tootltip-content">{status.label} </span>}
        >
          <span className="text-padding" id="Status-Commande">
            {status.label}
          </span>
        </Tooltip>
      </div>

      {etatPaiement === EN_ATTENTE &&
        moyenPaiement &&
        showPayment &&
        (moyenPaiement?.code === 'ESPECE_AU_CHAUFFEUR' ||
          moyenPaiement?.code === 'STRIPE_INVOICE') && (
          <Tooltip
            arrow
            title={<span className="tootltip-content">{moyenPaiement.label}</span>}
          >
            <div className="payment-text text-padding">En attente de paiement</div>
          </Tooltip>
        )}
    </div>
  )
}
ChangeOrderStatus.propTypes = {
  status: PropTypes.object,
  etatPaiement: PropTypes.string,
  moyenPaiement: PropTypes.object,
  showPayment: PropTypes.bool,
}
