import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Loading from '../../components/Loading'
import * as EmailValidator from 'email-validator'
import GoogleLogin from '@leecheuk/react-google-login'
import './Login.scss'
import '../../assets/styles/index.scss'
import img from '../../assets/icons/corner-logo.png'
import { isIOS } from 'react-device-detect'
import MicrosoftLogin from 'react-microsoft-login'
import imgMicrosft from '../../assets/icons/MICROSOFT_ICON.png'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
const microsoftClientId = process.env.REACT_APP_MICROSOFT_CLIENT_ID
const cornerURL = process.env.CORNER_URL
const tenantUrl = process.env.REACT_APP_TENANT_URL
const microsoftAnnuaireId = process.env.REACT_APP_MICROSOFT_ANNUAIRE_ID

const Login = ({
  loginError,
  userLogin,
  userLoginGoogleAccount,
  isLoading,
  userLoginMicrosoftAccount,
}) => {
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [password, setPassword] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const history = useHistory()

  useEffect(() => {
    const url = history.location.hash

    if (url !== '') {
      const splitedUrl = url.split('&')
      for (const i of splitedUrl) {
        const paraObj = i.split('=')
        if (paraObj[0] === 'id_token') {
          console.log(paraObj[1])
          userLoginGoogleAccount(paraObj[1])
        }
      }
    }
  }, [isIOS])
  useEffect(() => {
    if (accessToken !== '') {
      userLoginMicrosoftAccount(accessToken)
    }
  }, [accessToken])

  const onChangeEmail = (e) => {
    const email = e.target.value
    setEmail(email)
    setIsValidEmail(EmailValidator.validate(email))
  }

  const onChangePassword = (e) => {
    const password = e.target.value
    setPassword(password)
    setIsValidPassword(password.length > 3)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    userLogin(email, password)
  }

  const responseGoogleOnSuccess = (response) => {
    const { profileObj } = response

    if (profileObj) {
      cookies.set('profile', JSON.stringify(profileObj), { path: '/' })
    }
    const url = history.location.hash
    try {
      if (url !== '' && loginError === null) {
        const splitedUrl = url.split('&')
        for (const i of splitedUrl) {
          const paraObj = i.split('=')
          if (paraObj[0] === 'id_token') {
            userLoginGoogleAccount(paraObj[1])
          }
        }
      }
    } catch (err) {
      throw ` Failed in response google on success url : ${url}`
    }
  }

  const authHandler = (err, data) => {
    if (!err && data) {
      setAccessToken(data.accessToken)
    }
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="login-page form-container">
          <img src={img} alt="corner logo" className="b2h-logo" />
          <form>
            <>
              <TextField
                label="Adresse email"
                variant="outlined"
                type={'text'}
                id="email-input"
                aria-describedby="email-helper-text"
                name={'email'}
                onChange={onChangeEmail}
                error={email && !isValidEmail}
                helperText={
                  email && !isValidEmail
                    ? 'Veuillez entrer une adresse e-mail valide'
                    : ''
                }
              />
              <TextField
                label="Mot de passe"
                variant="outlined"
                type={'password'}
                id="password-input"
                aria-describedby="password-helper-text"
                name={'password'}
                onChange={onChangePassword}
              />
              <br />
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  height: '40px',
                  textTransform: 'none !important',
                  fontSize: '13px !important',
                  fontWeight: '400',
                }}
                disabled={!isValidEmail || !isValidPassword || isLoading}
                onClick={onSubmit}
              >
                Se Connecter
              </Button>
              <br />
            </>
            <GoogleLogin
              className="btn btn-primary btn-block google-button"
              clientId={googleClientId}
              buttonText="Se connecter avec compte google"
              onSuccess={responseGoogleOnSuccess}
              scope="profile email"
              uxMode="redirect"
              responseType="ID Token"
              redirectUri={cornerURL}
              fetchBasicProfile={true}
              isSignedIn={true}
              disabled={isLoading}
              cookiePolicy={'single_host_origin'}
            />

            <div style={{ marginTop: '15px' }}>
              <MicrosoftLogin
                clientId={microsoftClientId}
                authCallback={(err, data) => authHandler(err, data)}
                forceRedirectStrategy={true}
                tenantUrl={`${tenantUrl}${microsoftAnnuaireId}`}
              >
                <Button
                  sx={{
                    backgroundColor: 'rgb(255, 255, 255) !important',
                    display: 'inline-flex !important',
                    alignItems: 'center !important',
                    color: 'rgba(0, 0, 0, 0.54) !important',
                    boxShadow:
                      'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px',
                    borderRadius: '2px !important',
                    border: '1px solid transparent !important',
                    fontSize: '14px !important',
                    fontWeight: '500',
                    fontFamily: 'Roboto, sans-serif !important',
                    width: '100%',
                    textTransform: 'none !important',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      padding: '3px 10px 3px 0px',
                    }}
                  >
                    <img
                      src={imgMicrosft}
                      style={{
                        width: '18px',
                        height: '18px',
                        marginRight: '10px',
                        marginLeft: '2px',
                      }}
                    />
                    <span style={{ fontWeight: ' 500' }}>
                      Se connecter avec un compte microsoft
                    </span>
                  </div>
                </Button>
              </MicrosoftLogin>
            </div>
            {loginError && (
              <label
                id="loginError"
                className="form-text error-text login-error-text "
              >
                {' '}
                {'Adresse non enregistrée dans le système'}{' '}
              </label>
            )}
          </form>
        </div>
      )}
    </>
  )
}
Login.propTypes = {
  loginError: PropTypes.string,
  userLogin: PropTypes.func.isRequired,
  userLoginGoogleAccount: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.any,
  userLoginMicrosoftAccount: PropTypes.func,
}
export default Login
