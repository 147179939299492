import { connect } from 'react-redux'
import UnservedOrders from '../../../pages/main/Programmer/Plannification/UnservedOrders'

import { getRecommendedCreneauListState } from '../../../redux/operationalHours/selectors'
import { getRecommendedCreneau } from '../../../redux/operationalHours/actions'
import {
  getGoogleMapDirectionRequest,
  resetDirections,
} from '../../../redux/googleMapDirections/actions'
import {
  getDirectionRoute,
  getIsGetDirectionRouteLoading,
} from '../../../redux/googleMapDirections/selectors'
import {
  updateCoursePolaris,
  updateActionCoursePolaris,
  updateCourseOptimisationFile,
} from '../../../redux/simulateTournee/actions'
import {
  getIsLoadingUpdateCoursePolaris,
  getisUpdateCoursePolarisAction,
  getFilterCourseFile,
} from '../../../redux/simulateTournee/selectors'

const mapStateToProps = (state) => ({
  recommendedCreneauList: getRecommendedCreneauListState(state),
  isGetDirectionRouteLoading: getIsGetDirectionRouteLoading(state),
  directionRoute: getDirectionRoute(state),
  isLoadingUpdateCoursePolaris: getIsLoadingUpdateCoursePolaris(state),
  isUpdateCoursePolarisAction: getisUpdateCoursePolarisAction(state),
  filterCourseFile: getFilterCourseFile(state),
})

const mapDisptachToProps = (dispatch) => ({
  getRecommendedCreneau: (date, codeCanal) =>
    dispatch(getRecommendedCreneau(date, codeCanal)),
  getGoogleMapDirection: (id, origin, destination) =>
    dispatch(getGoogleMapDirectionRequest(id, origin, destination)),
  updateCourse: (course) => dispatch(updateCoursePolaris(course)),
  updateActionCoursePolaris: () => dispatch(updateActionCoursePolaris()),
  resetDirections: () => dispatch(resetDirections()),
  updateCourseOptimisationFile: (course) =>
    dispatch(updateCourseOptimisationFile(course)),
})

export default connect(mapStateToProps, mapDisptachToProps)(UnservedOrders)
