import assemblyMarker from '../assets/icons/flag.svg'

import redMarker from '../assets/icons/markers/redMarker.svg'
import redOpacityMarker from '../assets/icons/markers/redOpacityMarker.svg'

import blueMarker from '../assets/icons/markers/blueMarker.svg'
import bleuMoreOpacityMarker from '../assets/icons/markers/bleuMoreOpacityMarker.svg'

import blackMarker from '../assets/icons/markers/blackMarker.svg'
import blackOpacityMarker from '../assets/icons/markers/blackOpacityMarker.svg'

import orangeMarker from '../assets/icons/markers/orangeMarker.svg'
import orangeOpacityMarker from '../assets/icons/markers/orangeOpacityMarker.svg'

import greyMarker from '../assets/icons/markers/greyMarker.svg'
import greyOpacityMarker from '../assets/icons/markers/greyOpacityMarker.svg'

import {
  BOX2HOME_PRIMARY_COLOR,
  PROGRAMMED_STATUS,
  ASSIGNED_STATUS,
  DECHARGEMENT_EN_COURS_STATUS,
} from '../utils/values'

import moment from 'moment'
import 'moment/locale/fr'

export const GOOGLE_MAP_CONSTANTS = {
  DEFAULT_LATLNG: { lat: 48.8667, lng: 2.3333 },
  PROGRAMMER_LATLNG: { lat: 46.5, lng: 2.0 },
  ZOOM: 15,
  MAP_OPTIONS: {
    fullscreenControl: true,
    zoomControl: true,
    disableDefaultUI: true,
    clickableIcons: false,
  },
  ASSEMBLY_MARKER_ICON: assemblyMarker,
  CANAL_ICON: {
    path: 'M 13 13 L 3 13 C 1.894531 13 1 12.105469 1 11 L 1 4 C 1 2.894531 1.894531 2 3 2 L 13 2 C 14.105469 2 15 2.894531 15 4 L 15 11 C 15 12.105469 14.105469 13 13 13 Z',
    strokeColor: BOX2HOME_PRIMARY_COLOR,
    fillOpacity: 1,
    strokeWeight: 1,
    fillColor: BOX2HOME_PRIMARY_COLOR,
  },
  ADDRESS_ICON: {
    scale: 0.4,
    path: `M43.233,38.083c-5.824,3.358-13.441,1.344-16.802-4.481c-3.358-5.824-1.344-13.439,4.48-16.801
        c5.824-3.359,13.438-1.345,16.8,4.479C51.073,27.105,49.056,34.721,43.233,38.083z M58.241,50.4c0,0,3.584,5.824-2.466,9.406
        c-0.896,0.672-2.239,0.896-3.358,0.896c-2.016,0-3.809-0.895-5.152-2.238c-5.6,8.289-10.752,14.559-10.752,14.559
        S10.304,40.768,10.304,26.208C10.304,11.872,21.952,0,36.512,0C51.073,0,62.72,11.873,62.72,26.208
        c0,4.929-3.137,12.097-7.168,19.265l1.344,2.465L58.241,50.4z M45.472,41.889c7.84-4.703,10.752-14.784,6.048-22.849
        c-4.702-7.84-14.783-10.751-22.848-6.048c-7.84,4.703-10.752,14.784-6.048,22.849C27.329,43.68,37.409,46.593,45.472,41.889z
         M47.936,54.432l7.168-4.031l-5.601-9.633l-7.168,4.256L47.936,54.432z M56.001,51.52l-7.168,4.256
        c1.119,2.017,3.584,2.689,5.6,1.568C56.448,56.225,57.12,53.761,56.001,51.52z`,
    strokeColor: BOX2HOME_PRIMARY_COLOR,
    fillOpacity: 1,
    strokeWeight: 1,
    fillColor: '#fff',
  },
  RED_DOT_ICON: {
    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
  },
  DEFAULT_UI_OPTIONS: {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  },
}
export const getMarkercolor = (status) => {
  const color =
    status.code === PROGRAMMED_STATUS.code
      ? PROGRAMMED_STATUS.markerColor
      : status.code === ASSIGNED_STATUS.code
      ? ASSIGNED_STATUS.markerColor
      : status.code === DECHARGEMENT_EN_COURS_STATUS.code
      ? DECHARGEMENT_EN_COURS_STATUS.markerColor
      : 'orange'
  return {
    path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
    strokeColor: color,
    fillOpacity: 0.7,
    scale: 0.4,
    strokeWeight: 0,
    fillColor: color,
    labelOrigin: { x: 70, y: 0 },
  }
}

const dayDiff = (pickedDate, metaDate) => {
  return pickedDate
    ? moment(pickedDate).startOf('day').diff(moment(metaDate).startOf('day'))
    : -1
}

//find out the pin color
export const findPinColor = (pickedDate, courseMetadata, selected) => {
  let color = ''
  if (
    !(
      courseMetadata &&
      courseMetadata.earliestDeliveryDate &&
      courseMetadata.latestDeliveryDate
    )
  ) {
    color = selected ? '#1876D2' : '#6aa0d4'
  } else {
    //console.log('theres one',courseMetadata)
    const earliestDate = moment(new Date(courseMetadata?.earliestDeliveryDate))
    const latestDate = moment(new Date(courseMetadata?.latestDeliveryDate))
    const date = moment(new Date(pickedDate))
    //const days = pickedDate ? date.diff(today, 'days') : -1
    if (dayDiff(date, earliestDate) < 0) {
      color = selected ? '#757575' : '#C0C0C0'
    } else {
      const days = dayDiff(latestDate, date)

      if (days === 0) {
        color = selected ? '#B7383B' : '#D5AAAB'
      } else if (days >= 86400000 && days <= 259200000) {
        color = selected ? '#FFA93E' : '#EDD0AC'
      } else if (days >= 345600000) {
        color = selected ? '#1876D2' : '#6aa0d4'
      } else {
        color = selected ? '#000000' : '#989898'
      }
    }
  }
  return color
}

const getMarkerIconWithColor = (color, selected) => {
  switch (color) {
    case '#1876D2':
      return selected ? blueMarker : bleuMoreOpacityMarker
    case '#757575':
      return selected ? greyMarker : greyOpacityMarker
    case '#B7383B':
      return selected ? redMarker : redOpacityMarker
    case '#FFA93E':
      return selected ? orangeMarker : orangeOpacityMarker

    default:
      return selected ? blackMarker : blackOpacityMarker
  }
}

export const getProgrammerMarkerIcon = (pickedDate, courseMetadata, selected) => {
  const color = findPinColor(pickedDate, courseMetadata, true)
  return getMarkerIconWithColor(color, selected)
}
export const getSimulationMarkerIcon = () => {
  return {
    path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
    strokeColor: 'black',
    fillOpacity: 0.7,
    scale: 0.4,
    strokeWeight: 0,
    fillColor: '#2da963',
    labelOrigin: { x: 70, y: 0 },
  }
}
