import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const StyledPaper = styled(Paper)({
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  width: 220,
  marginRight: 4,
  height: 45,
})

const StyledInput = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
  borderBottom: '1px solid #ccc',
  width: '100%',
}))

export default function SearchField({ onSearch, activeOnly, getDriversByFilter }) {
  const [value, setValue] = useState('')
  const onValueChange = (event) => {
    setValue(event.target.value)
    if (event.target.value.length === 0) {
      onSearch(null, activeOnly)
      getDriversByFilter(activeOnly)
    }
  }
  const searchField = (e) => {
    e.preventDefault()
    onSearch(value, activeOnly)
  }
  return (
    <StyledPaper component="form">
      <IconButton
        type="submit"
        sx={{ padding: '10px' }}
        aria-label="search"
        onClick={(e) => searchField(e)}
      >
        <SearchIcon />
      </IconButton>
      <StyledInput
        placeholder="Recherche"
        inputProps={{ 'aria-label': 'search driver' }}
        onChange={(e) => onValueChange(e)}
      />
    </StyledPaper>
  )
}
SearchField.propTypes = {
  onSearch: PropTypes.func,
  getDriversByFilter: PropTypes.func,
  activeOnly: PropTypes.bool,
}
