import { insetObjectTable } from '../../utils/utils'
import {
  GET_KEYS,
  GET_KEYS_SUCCESS,
  GET_KEYS_FAILURE,
  ADD_KEYS,
  ADD_KEYS_FAILURE,
  ADD_KEYS_SUCCESS,
  UPDATE_KEY,
  UPDATE_KEY_SUCCESS,
  UPDATE_KEY_FAILURE,
  PAGE_LIMIT_CHANGED,
  PAGE_CHANGED,
  SEARCH_KEYS,
  ASSIGN_KEYS_DRIVER,
  ASSIGN_KEYS_DRIVER_SUCCESS,
  ASSIGN_KEYS_DRIVER_FAILURE,
  UNASSIGN_KEYS_DRIVER_FAILURE,
  UNASSIGN_KEYS_DRIVER_SUCCESS,
  UNASSIGN_KEYS_DRIVER,
  UNASSIGN_KEYS_CHANNEL,
  UNASSIGN_KEYS_CHANNEL_SUCCESS,
  UNASSIGN_KEYS_CHANNEL_FAILURE,
  ASSIGN_KEYS_CHANNEL,
  ASSIGN_KEYS_CHANNEL_SUCCESS,
  ASSIGN_KEYS_CHANNEL_FAILURE,
  RESET_CONFIG,
  GET_ASSIGNED_CHANNEL_FAILURE,
  GET_ASSIGNED_CHANNEL_SUCCESS,
  GET_ASSIGNED_CHANNEL,
  GET_ASSIGNED_DRIVER,
  GET_ASSIGNED_DRIVER_SUCCESS,
  GET_ASSIGNED_DRIVER_FAILURE,
  ON_SEARCH,
  REMOVE_ERROR,
  RESET_SUCCESS_ACTION,
  GET_KEYS_LIST_MORE,
  GET_KEYS_LIST_MORE_SUCESS,
  GET_KEYS_LIST_MORE_FAILURE,
  SEARCH_KEYS_COMPETENCE,
  SEARCH_KEYS_RESSOURCES,
} from './constants'
import initialState from './initialState'

export default (state = initialState(), action) => {
  if (action.type === GET_KEYS) {
    return {
      ...state,
      keys: [],
      isLoadingkeysList: true,
    }
  }

  if (action.type === GET_KEYS_SUCCESS) {
    const { data, count } = action.payload.data

    return {
      ...state,
      keys: data,
      isLoadingkeysList: false,
      keysCount: count,
    }
  }

  if (action.type === GET_KEYS_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingkeysList: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetch: true,
    }
  }

  if (action.type === GET_ASSIGNED_CHANNEL) {
    return {
      ...state,
      salesChannel: [],
      isLoadingChannelList: true,
    }
  }

  if (action.type === GET_ASSIGNED_CHANNEL_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      salesChannel: data,
      salesChannelCount: count,
      isLoadingChannelList: false,
    }
  }

  if (action.type === GET_ASSIGNED_CHANNEL_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingChannelList: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetch: true,
    }
  }

  if (action.type === GET_ASSIGNED_DRIVER) {
    return {
      ...state,
      drivers: [],
      isLoadingDriverList: true,
    }
  }

  if (action.type === GET_ASSIGNED_DRIVER_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      drivers: data,
      driverCount: count,
      isLoadingDriverList: false,
    }
  }

  if (action.type === GET_ASSIGNED_DRIVER_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingDriverList: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetch: true,
    }
  }

  if (action.type === ADD_KEYS) {
    return {
      ...state,
      isLoadingkeys: true,
    }
  }
  if (action.type === ADD_KEYS_SUCCESS) {
    const { data } = action.payload

    return {
      ...state,
      isLoadingkeys: false,
      keyCreatedObject: data,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === ADD_KEYS_FAILURE) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingkeys: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === UPDATE_KEY) {
    return {
      ...state,
      isLoadingkeys: true,
    }
  }
  if (action.type === UPDATE_KEY_SUCCESS) {
    const { data } = action.payload
    const keyIndex = state.keys.map((el) => el.id).indexOf(data.id)
    return {
      ...state,
      keys: [...insetObjectTable(state.keys, data, keyIndex)],
      isLoadingkeys: false,
      success: true,
    }
  }

  if (action.type === UPDATE_KEY_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingkeys: false,
      success: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === SEARCH_KEYS) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        limit: 25,
        offset: 0,
        query: search,
      },
    }
  }

  if (action.type === PAGE_CHANGED) {
    const { offset } = action.payload
    const filter = { ...state.filter }
    filter.offset = offset
    return {
      ...state,
      filter,
    }
  }

  if (action.type === PAGE_LIMIT_CHANGED) {
    const { limit } = action.payload
    const filter = { ...state.filter }
    filter.limit = limit
    filter.offset = 0
    return {
      ...state,
      filter,
    }
  }

  if (action.type === GET_KEYS_LIST_MORE) {
    return {
      ...state,
    }
  }
  if (action.type === GET_KEYS_LIST_MORE_SUCESS) {
    return {
      ...state,
      keys: [...state.keys, ...action.payload?.data?.data],
      countKeys: action.payload.data?.count || 0,
    }
  }

  if (action.type === GET_KEYS_LIST_MORE_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === RESET_CONFIG) {
    //rest
    return {
      ...state,
      isLoadingkeys: false,
      success: false,
      errorMessage: null,
      isLoadingAssign: false,
      isLoadingUnassign: false,
      isErrorFetch: false,
      isSuccessRefresh: false,
      keyCreatedObject: null,
      filter: {
        limit: 25,
        offset: 0,
      },
    }
  }

  if (action.type === ASSIGN_KEYS_DRIVER) {
    return {
      ...state,
      isLoadingAssign: true,
    }
  }
  if (action.type === ASSIGN_KEYS_DRIVER_SUCCESS) {
    return {
      ...state,
      isLoadingAssign: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === ASSIGN_KEYS_DRIVER_FAILURE) {
    const { response } = action.payload?.data

    return {
      ...state,
      isLoadingAssign: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === UNASSIGN_KEYS_DRIVER) {
    return {
      ...state,
      isLoadingUnassign: true,
    }
  }
  if (action.type === UNASSIGN_KEYS_DRIVER_SUCCESS) {
    return {
      ...state,
      isLoadingUnassign: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === UNASSIGN_KEYS_DRIVER_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingUnassign: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === ASSIGN_KEYS_CHANNEL) {
    return {
      ...state,
      isLoadingAssign: true,
    }
  }
  if (action.type === ASSIGN_KEYS_CHANNEL_SUCCESS) {
    return {
      ...state,
      isLoadingAssign: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === ASSIGN_KEYS_CHANNEL_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingAssign: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === UNASSIGN_KEYS_CHANNEL) {
    return {
      ...state,
      isLoadingUnassign: true,
    }
  }
  if (action.type === UNASSIGN_KEYS_CHANNEL_SUCCESS) {
    return {
      ...state,
      isLoadingUnassign: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === UNASSIGN_KEYS_CHANNEL_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingUnassign: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === ON_SEARCH) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        limit: 25,
        offset: 0,
        name: search,
        firstName: search,
      },
    }
  }

  if (action.type === REMOVE_ERROR) {
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
    }
  }

  if (action.type === RESET_SUCCESS_ACTION) {
    return {
      ...state,
      success: false,
      isSuccessRefresh: false,
    }
  }

  if (action.type === SEARCH_KEYS_COMPETENCE) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        value: search,
        limit: 25,
        offset: 0,
        category: 'Competence',
      },
    }
  }

  if (action.type === SEARCH_KEYS_RESSOURCES) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        value: search,
        limit: 25,
        offset: 0,
      },
    }
  }

  return state
}
