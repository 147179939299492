import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import DrawerFilter from '../../Drawer'
import AutoCompleteSearch from './AutoComplete'
export default function SelectListWithSearch({
  disabled,
  defaultLabel,
  Icon,
  id,
  filtersValues,
  onDataChanged,
  dataList,
  isLoading,
  onSearch,
}) {
  const [opentDrawer, setOpentDrawer] = useState(false)
  const [defaultValue, setDefaultValue] = useState([])
  const [search, setSearch] = useState('')
  const [options, setOptions] = useState([])

  const toggleDrawer = (open, event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    if (!open) {
      onDataChanged([...new Set(defaultValue)])
    }
    setOpentDrawer(open)
  }

  useEffect(() => {
    if (dataList) setOptions(dataList)
  }, [dataList])
  useEffect(() => {
    if (filtersValues) setDefaultValue(filtersValues)
  }, [filtersValues])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 1) {
        onSearch(search)
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onSelect = (value) => {
    if (value) {
      setDefaultValue(value)
    }
  }

  const onChange = (value) => {
    let val = value.trim()
    setSearch(val)
  }

  return (
    <>
      <DrawerFilter
        filterLabel={defaultLabel}
        disabled={disabled}
        content={
          <AutoCompleteSearch
            defaultValue={defaultValue}
            dataList={options}
            onChange={onChange}
            onSelect={onSelect}
            isLoading={isLoading}
          />
        }
        toggleDrawer={toggleDrawer}
        opentDrawer={opentDrawer}
        Icon={Icon}
        id={id}
      />
    </>
  )
}
SelectListWithSearch.propTypes = {
  dataList: PropTypes.array,
  disabled: PropTypes.bool,
  onDataChanged: PropTypes.func,
  defaultLabel: PropTypes.string,
  filtersValues: PropTypes.array,
  Icon: PropTypes.any,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  onSearch: PropTypes.func,
}
