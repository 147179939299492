import { Marker } from '@react-google-maps/api'
import React from 'react'
import PropTypes from 'prop-types'
import { getCourseLatLng } from '../../utils/utils'
export default function MarkerInfo({ onMapLoaded, tours, index }) {
  return (
    <>
      {getCourseLatLng(tours).map((position, i) => {
        return (
          <Marker
            id={`marker_${index}`}
            key={i}
            onLoad={onMapLoaded}
            position={position}
            icon={{
              path: 'M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703  C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z',
              strokeColor: '#000000',
              fillColor: '#FFFFFF',
              fillOpacity: 1,
              anchor: new window.google.maps.Point(7, 13),
              strokeWeight: 1.75,
              scale: 2,
              labelOrigin: new window.google.maps.Point(7.5, 6),
            }}
            label={{
              text: i.toString(),
              color: '#000000',
              fontSize: '14px',
              fontWeight: '600',
            }}
          />
        )
      })}
    </>
  )
}

MarkerInfo.propTypes = {
  onMapLoaded: PropTypes.func,
  tours: PropTypes.object,
  index: PropTypes.string,
}
