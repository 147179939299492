import { styled } from '@mui/material/styles'

export const MapContainer = styled('div')({
  width: '100%',
  position: 'relative',
  maxHeight: '87vh',
  '@media (max-width: 600px)': {
    width: '100%',
    height: '100%',
  },
})

export const TopContainer = styled('div')({
  display: 'flex',
  overflow: 'hidden',
  height: '100%',
  flexWrap: 'nowrap', // Default no wrap

  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    overflow: 'auto',
  },
})

export const DetailTounerContainer = styled('div')(({ expandVertical }) => ({
  overflow: 'auto',

  transition: 'all 0.1s',
  display: 'inline-block',
  minWidth: expandVertical ? '80%' : '50%',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '7px !important', // hauteur de la scrollbar horizontale
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#1976d2 !important',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '@media (max-width: 600px)': {
    width: '100% !important',
  },
}))
