import { connect } from 'react-redux'
import Search from '../../../pages/main/Discussion/components/Search'
import { getEntryConversationAction } from '../../../redux/discussion/conversations/action'

import {
  getConversationSubject,
  getIsLoadingSubjectConversation,
} from '../../../redux/discussion/conversations/selectors'
import { SearchChanged } from '../../../redux/filter/actions'

import {
  getWareHouses,
  getIsWareHouseLoading,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  subjectConversation: getConversationSubject(state),
  isLoadingSubjectConversation: getIsLoadingSubjectConversation(state),
  wareHouseList: getWareHouses(state),
  isloadingWarehouse: getIsWareHouseLoading(state),
})

const mapDisptachToProps = (dispatch) => ({
  SearchChanged: (ldv) => {
    dispatch(SearchChanged(ldv))
    dispatch(getEntryConversationAction())
  },
  resetSearch: () => {
    dispatch(SearchChanged(''))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(Search)
