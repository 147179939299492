import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import DoneIcon from '@mui/icons-material/Done'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
import { validateUploadFile } from '../../../../../../utils/validator/UploadFileDiscussion'
import Error from '../../../../../../components/Modal/HttpErrorPopUp'
import { useParams } from 'react-router-dom'
import CardAccordion from '../card/cardWithAccordion'

export default function PiecesJointesLivraison({
  orderCode,
  getPiecesJointeURL,
  onUploadPiecesJointes,
  isLoadingUploadFile,
  errorFileUpload,
  setErrorFileUpload,
  orderAttachments,
  getOrderAttachmentsByLdv,
  isLoadingOrderAttachments,
  isActionOrderAttachmentsDone,
  isLoading,
  codeStatus,
}) {
  const [urlImage, setUrlImage] = useState('')
  const [image, setImage] = useState(null)
  const [errorUploadFile, setErrorUploadFile] = useState(null)
  const [open, setOpen] = useState(false)
  const [paramsInit, setParams] = useState(null)

  const params = useParams()

  useEffect(() => {
    if (orderCode) {
      getOrderAttachmentsByLdv(params.ldv)
      setParams(params)
    }
  }, [orderCode])

  useEffect(() => {
    if (paramsInit && paramsInit.ldv != params.ldv && orderCode) {
      setParams(params)
      getOrderAttachmentsByLdv(params.ldv)
    }
  }, [params])

  useEffect(() => {
    if (isActionOrderAttachmentsDone) {
      anullerImage()
      getOrderAttachmentsByLdv(params.ldv)
    }
  }, [isActionOrderAttachmentsDone])

  const onChangeFile = (event) => {
    setErrorUploadFile(null)

    const error = validateUploadFile(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
      setOpen(true)
      event.target.value = null
      setImage(null)
      setUrlImage('')
    } else {
      setImage(event.target.files[0])
      setUrlImage(URL.createObjectURL(event.target.files[0]))
    }
  }

  const onUpload = () => {
    const formData = new FormData()
    formData.append('attachments[]', image)
    formData.append('codeStatus', codeStatus)
    onUploadPiecesJointes(formData, orderCode)
  }
  const anullerImage = () => {
    setImage(null)
    setUrlImage('')
  }

  const onErrorClose = () => {
    setOpen(false)
    setErrorUploadFile(null)
    setErrorFileUpload(false)
  }

  useEffect(() => {
    if (errorFileUpload) {
      setImage(null)
      setUrlImage('')
    }
  }, [errorFileUpload])
  return (
    <>
      {(errorUploadFile?.isError || errorFileUpload) && (
        <Error
          statusText={
            errorUploadFile?.message ||
            "une erreur s'est produite merci d'actualiser la page"
          }
          open={open || errorFileUpload}
          setOpen={onErrorClose}
        />
      )}
      <CardAccordion
        icon={
          <AttachFileIcon
            style={{ marginRight: '4px', width: '20px', height: '20px' }}
          />
        }
        title={'Pièces-jointes'}
        action={
          <>
            {orderCode && (
              <div
                style={{
                  placeItems: 'center',
                  paddingRight: '7px',
                  display: 'flex',
                }}
              >
                <input
                  type="file"
                  style={{
                    width: '130px',
                    cursor: 'pointer',
                    opacity: '0',
                    zIndex: '2',
                    position: 'static',
                    marginRight: '-25px',
                  }}
                  onChange={onChangeFile}
                  onClick={(event) => event.stopPropagation()}
                />

                <AddIcon style={{ cursor: 'pointer' }} />
              </div>
            )}
          </>
        }
        content={
          <>
            {isLoadingUploadFile ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '15px 10px 7px 20px',
                }}
              >
                <span>Upload de fichier en cours ...</span>
                <CircularProgress
                  sx={{
                    '&.MuiCircularProgress-root': {
                      width: '25px !important',
                      height: '25px !important',
                    },
                  }}
                />
              </div>
            ) : (
              image && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: 'flex',
                    padding: '15px 10px 10px 20px',
                    borderBottom: '1px solid #f5f5f5',
                    justifyContent: 'space-between',
                  }}
                  key={0}
                >
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >{`Document_${image.name}`}</span>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <DoneIcon
                      style={{
                        cursor: 'pointer',
                        color: '#427C1D',
                        marginRight: '5px',
                      }}
                      onClick={() => onUpload()}
                    />

                    <ClearIcon
                      style={{
                        cursor: 'pointer',
                        color: '#B90707',
                        marginRight: '5px',
                      }}
                      onClick={() => anullerImage()}
                    />

                    <a
                      href={urlImage}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <VisibilityIcon
                        style={{ cursor: 'pointer', color: '#373D57' }}
                      />
                    </a>
                  </div>
                </Grid>
              )
            )}
            {isLoadingOrderAttachments || isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <>
                {orderAttachments && orderAttachments.length > 0 && (
                  <Grid container>
                    {orderAttachments.map((attachment, index) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          style={{
                            display: 'flex',
                            padding: '15px 10px 10px 20px',
                            borderBottom: '1px solid #f5f5f5',
                            justifyContent: 'space-between',
                          }}
                          key={index}
                        >
                          <span>{`Document_${index + 1}`}</span>
                          <VisibilityIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => getPiecesJointeURL(attachment.url)}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                )}
              </>
            )}
          </>
        }
      />
    </>
  )
}
PiecesJointesLivraison.propTypes = {
  orderCode: PropTypes.string,
  getPiecesJointeURL: PropTypes.func,
  onUploadPiecesJointes: PropTypes.func,
  isLoadingUploadFile: PropTypes.bool,
  errorFileUpload: PropTypes.bool,
  setErrorFileUpload: PropTypes.func,
  orderAttachments: PropTypes.array,
  getOrderAttachmentsByLdv: PropTypes.func,
  isLoadingOrderAttachments: PropTypes.bool,
  isActionOrderAttachmentsDone: PropTypes.bool,
  isLoading: PropTypes.bool,
  codeStatus: PropTypes.string,
}
