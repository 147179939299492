import {
  FETCH_CLIENT_CRITERIA,
  CHANGE_SEARCH_CRITERIA_BY_PHONE,
  VERIFY_PROMO_CODE,
  SHIPMENT_NOTIFY,
  UPDATE_SHIPMENT_NOTIFY,
  HIDE_POP_UP,
  ADD_ASSEMBLY_ATTACHMENTS,
  ASSEMBLY_SAVED,
  SET_PAYMENT_FORM,
  COMMAND_CLICK,
  FETCH_ONEWEEK_SHIPMENTS,
  UPDATE_SHIPMENT,
  REMOVE_ERROR,
  CREATE_NEW_ORDER,
  ADD_DELIVERY_ATTACHMENTS,
  CHANGE_STATUS_DEVIS,
  VIEW_FILES,
  DOWNLOAD_FILES,
  INIT_ORDER,
  RESET_ORDER,
  ADD_ORDER,
  SET_INFO_COMP,
  SET_INFO_COMP_EDIT,
  UPDATE_SHIPMENT_DATA,
  UPDATE_ASSEMBLY_DATA,
  SET_ACTIVE_STEP,
  SUBMIT_FIRST_STEP,
  SET_SHIPMENT_ERRORS,
  SET_ASSEMBLY_ERRORS,
  SET_SHIPMENT_PRICE,
  SET_ASSEMBLY_PRICE,
  REMOVE_MARKER,
  REMOVE_DIRECTION,
  UPDATE_DIRECTIONS,
  UPDATE_MARKERS,
  ADD_ASSEMBLY,
  ADD_SHIPMENT,
  GET_ORDER,
  REMOVE_ORDER,
  REMOVE_ASSEMBLY,
  REMOVE_SHIPMENT,
  SET_CLIENT,
  SUBMIT_SECOND_STEP,
  SET_SELECTED_CLIENT,
  SET_IS_NEW_CLIENT,
  SET_CLIENT_ERRORS,
  ESTIMATE_SHIPMENT_PRICE_REQUEST,
  ESTIMATE_ASSEMBLY_PRICE_REQUEST,
  GET_CANAL_VENTE,
  GET_RULES,
} from './actionsConsts'

export const fetchClientWithCriteria = (criteria) => ({
  type: FETCH_CLIENT_CRITERIA,
  payload: { criteria },
})

export const changeSearchCriteraByPhone = (searchByPhone) => ({
  type: CHANGE_SEARCH_CRITERIA_BY_PHONE,
  payload: { searchByPhone },
})

export const verifyPromocode = (codePromo) => ({
  type: VERIFY_PROMO_CODE,
  payload: { codePromo },
})

export const dispatchShipmentNotify = (codeCourse) => ({
  type: SHIPMENT_NOTIFY,
  payload: { codeCourse },
})

export const dispatchUpdateShipmentNotify = (codeCourse) => ({
  type: UPDATE_SHIPMENT_NOTIFY,
  payload: { codeCourse },
})

export const dispatchAssemblyNotify = (codeAssembly) => ({
  type: SHIPMENT_NOTIFY,
  payload: { codeAssembly },
})

export const dispatchAssemblyShipmentNotify = (codeCourse, codeAssembly) => ({
  type: SHIPMENT_NOTIFY,
  payload: { codeCourse: codeCourse, codeAssembly: codeAssembly },
})

export const hidePopUp = () => ({
  type: HIDE_POP_UP,
})

export const addAssemblyAttachments = (codeAssembly, files) => ({
  type: ADD_ASSEMBLY_ATTACHMENTS,
  payload: { codeAssembly, files },
})

export const addDeliveryAttachments = (codeCourse, files) => ({
  type: ADD_DELIVERY_ATTACHMENTS,
  payload: { codeCourse, files },
})

export const setPaymentForm = (paymentForm) => ({
  type: SET_PAYMENT_FORM,
  payload: { paymentForm },
})

export const setCommand = (command) => ({
  type: COMMAND_CLICK,
  payload: { command },
})

export const assemblySaved = () => ({
  type: ASSEMBLY_SAVED,
})

export const getOneWeekShipments = (salesChannelCode) => ({
  type: FETCH_ONEWEEK_SHIPMENTS,
  payload: { salesChannelCode },
})

export const removeError = () => ({
  type: REMOVE_ERROR,
})

export const updateShipment = (shipment) => ({
  type: UPDATE_SHIPMENT,
  payload: { shipment },
})

export const createNewOrder = (order, type, statement) => ({
  type: CREATE_NEW_ORDER,
  payload: { order, type, statement },
})

export const changeStatusDevis = (status) => ({
  type: CHANGE_STATUS_DEVIS,
  payload: { status },
})

export const viewFiles = (fileDetail) => ({
  type: VIEW_FILES,
  payload: { fileDetail },
})

export const downloadFiles = (fileDetail) => ({
  type: DOWNLOAD_FILES,
  payload: { fileDetail },
})

export const initOrderAction = () => ({
  type: INIT_ORDER,
  payload: {},
})

export const addOrderAction = (type) => ({
  type: ADD_ORDER,
  payload: { type },
})
export const setInfoComplementaire = (id, info) => ({
  type: SET_INFO_COMP,
  payload: { id, info },
})

export const setInfoComplementaireEdit = (id, info) => ({
  type: SET_INFO_COMP_EDIT,
  payload: { id, info },
})
export const updateOrderShipmentAction = (orderId, data) => ({
  type: UPDATE_SHIPMENT_DATA,
  payload: { orderId, data },
})
export const updateOrderAssemblyAction = (orderId, data) => ({
  type: UPDATE_ASSEMBLY_DATA,
  payload: { orderId, data },
})

export const resetOrder = () => ({
  type: RESET_ORDER,
  payload: {},
})

export const setActiveStepAction = (step) => ({
  type: SET_ACTIVE_STEP,
  payload: { step },
})

export const submitFirstStepAction = () => ({
  type: SUBMIT_FIRST_STEP,
  payload: {},
})

export const setShipmentErrorsAction = (orderId, errors) => ({
  type: SET_SHIPMENT_ERRORS,
  payload: { orderId, errors },
})
export const setAssemblyErrorsAction = (orderId, errors) => ({
  type: SET_ASSEMBLY_ERRORS,
  payload: { orderId, errors },
})

export const setOrderShipmentPriceAction = (orderId, price) => ({
  type: SET_SHIPMENT_PRICE,
  payload: { orderId, price },
})

export const setOrderAssemblyPriceAction = (orderId, price) => ({
  type: SET_ASSEMBLY_PRICE,
  payload: { orderId, price },
})

export const addAssemblyAction = (orderId) => ({
  type: ADD_ASSEMBLY,
  payload: { orderId },
})
export const removeAssemblyAction = (orderId) => ({
  type: REMOVE_ASSEMBLY,
  payload: { orderId },
})

export const addShipment = (orderId) => ({
  type: ADD_SHIPMENT,
  payload: { orderId },
})

export const getOrder = (orderId) => ({
  type: GET_ORDER,
  payload: { orderId },
})

export const removeOrderAction = (order) => ({
  type: REMOVE_ORDER,
  payload: { order },
})

export const removeMarkerAction = (markerId) => ({
  type: REMOVE_MARKER,
  payload: { markerId },
})

export const removeDirectionAction = (directionId) => ({
  type: REMOVE_DIRECTION,
  payload: { directionId },
})
export const updateDirectionsAction = (directionId, data) => ({
  type: UPDATE_DIRECTIONS,
  payload: { directionId, data },
})

export const updateMarkersAction = (markerId, data) => ({
  type: UPDATE_MARKERS,
  payload: { markerId, data },
})

export const removeOrderAssemblyAction = (orderId) => ({
  type: REMOVE_ASSEMBLY,
  payload: { orderId },
})

export const removeShipment = (orderId) => ({
  type: REMOVE_SHIPMENT,
  payload: { orderId },
})

export const setOrderClientAction = (data) => ({
  type: SET_CLIENT,
  payload: { data },
})
export const submitSecondStepAction = () => ({
  type: SUBMIT_SECOND_STEP,
  payload: {},
})

export const setSelectedClientAction = (data) => ({
  type: SET_SELECTED_CLIENT,
  payload: { data },
})
export const setIsNewClientAction = (isNew) => ({
  type: SET_IS_NEW_CLIENT,
  payload: { isNew },
})

export const setClientErrorsAction = (errors) => ({
  type: SET_CLIENT_ERRORS,
  payload: { errors },
})

export const estimateShipmentPriceAction = (
  orderId,
  selectedSalesChannel,
  direction,
  date,
  time,
  purchaseAmount,
  nbItems,
  weight,
  manutention,
  manutentionDouble,
  etage
) => ({
  type: ESTIMATE_SHIPMENT_PRICE_REQUEST,
  payload: {
    orderId,
    selectedSalesChannel,
    direction,
    date,
    time,
    purchaseAmount,
    nbItems,
    weight,
    manutention,
    manutentionDouble,
    etage,
  },
})

export const estimateAssemblyPriceAction = (
  orderId,
  selectedSalesChannel,
  purchaseAmount,
  date,
  time
) => ({
  type: ESTIMATE_ASSEMBLY_PRICE_REQUEST,
  payload: {
    orderId,
    selectedSalesChannel,
    purchaseAmount,
    date,
    time,
  },
})
export const getCanalVente = (codeCanal) => ({
  type: GET_CANAL_VENTE,
  payload: { codeCanal },
})

export const getRulesListRequest = () => ({
  type: GET_RULES,
  payload: {},
})
