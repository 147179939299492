export const replaceDotWithComma = (value) => {
  return value.toString().replace('.', ',')
}

export const parseFloatWithComma = (value) => {
  if (!value || value === '') {
    value = '0'
  }

  const reverseFormatValue =
    parseFloat(value) === value ? value : value.replace(',', '.')
  const parsed = parseFloat(reverseFormatValue).toFixed(2)
  return parsed.toString().replace('.', ',')
}

export const parseFloatWithDot = (value) => {
  if (!value || value === '') {
    return null
  } else {
    const reverseFormatValue =
      parseFloat(value) === value ? value : value.replace(',', '.')
    const parsed = parseFloat(reverseFormatValue).toFixed(2)
    //console.log('paaarsed',parsed)
    return parseFloat(parsed)
  }
}
