const courseType = {
  COURSE: 'course',
  MONTAGE: 'montage',
  COURSE_MONTAGE: 'course_montage',
}
/** Fill initial order values with additional info to send to api */
const getOrderStatement = (orders, client) => {
  const infosCourse = orders
    .filter((e) => e.type !== courseType.MONTAGE)
    .map((order) => {
      return {
        orderType: order.type,
        courseFiles: order.shipment?.files,
        assemblyFiles: order.assembly?.files,
        notify: client.envoiMail || client.nonEnvoiMail,
      }
    })

  const infosAssembly = orders
    .filter((e) => e.type === courseType.MONTAGE)
    .map((order) => {
      return {
        orderType: order.type,
        assemblyFiles: order.assembly?.files,
        notify: client.envoiMail || client.nonEnvoiMail,
      }
    })
  const orderDetails = [...infosCourse, ...infosAssembly]
  return orderDetails.filter((e) => e !== undefined)
}

export default getOrderStatement
