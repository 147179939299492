import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GoogleMapContainer from '../../../../containers/main/GoogleMapContainer'
import MarkerTextInfo from '../../Dashboard/Cell/Body/Dashboard/MarkerTextInfo'
import { LatLng } from '../../../../models/latlng'
import {
  getMarkercolor,
  GOOGLE_MAP_CONSTANTS,
} from '../../../../constants/googleMapConstants'
const MapSection = ({
  markers,
  directions,
  mapOptions,
  salesChannelOneWeekShipments,
  getGoogleMapDirection,
  directionRoute,
  orders,
  resetDirections,
}) => {
  // console.log('1-1SHIPMENTmarkers================>', markers)
  // console.log('1-1SHIPMENTdirections================>', directions)
  // console.log('1-1SHIPMENTdirectionRoute===========>', directionRoute)
  const [markersList, setMarkers] = useState(markers)
  const [customMapOptions, setMapOptions] = useState(mapOptions)
  const [directionsList, setDirections] = useState([])
  const [retrievedDirections, setRetrievedDirections] = useState([])
  const [mapLoaded, setMapLoaded] = useState(false)
  const calculateDirection = (selecetedRoute, id) => {
    if (selecetedRoute) {
      if (selecetedRoute.origin && selecetedRoute.destination) {
        const { origin, destination } = selecetedRoute
        getGoogleMapDirection(
          id,
          { lat: origin.latitude, lng: origin.longitude },
          { lat: destination.latitude, lng: destination.longitude }
        )
      }
    }
  }
  useEffect(() => {
    let options = { ...mapOptions }
    let list = []
    if (salesChannelOneWeekShipments && mapLoaded) {
      list = salesChannelOneWeekShipments.map(
        (
          {
            shipmentReference,
            price,
            salesChannelName,
            status,
            dropOffAddress,
            pickUpAddress,
            deliveryDate,
            deliveryTime,
          },
          index
        ) => {
          return {
            icon: getMarkercolor(status, mapLoaded),
            infoText: (
              <MarkerTextInfo
                ldv={shipmentReference}
                prix={price.toString()}
                salesChannel={salesChannelName}
                startDate={deliveryDate}
              />
            ),
            action: () =>
              calculateDirection(
                {
                  origin: pickUpAddress,
                  destination: dropOffAddress,
                  status,
                },
                index + 1
              ),
            position: {
              lat: dropOffAddress.latitude,
              lng: dropOffAddress.longitude,
            },
            ticket: deliveryTime,
          }
        }
      )
      // Note: When selecte Channel zoom
      options.zoom = salesChannelOneWeekShipments.length > 0 ? 15 : 11
    }
    if (
      orders &&
      orders.length > 0 &&
      orders[0]?.shipment?.selectedOrigin?.latlng?.lat &&
      orders[0]?.shipment?.selectedOrigin?.latlng?.lng &&
      orders[0]?.shipment?.selectedDestination?.latlng?.lat &&
      orders[0]?.shipment?.selectedDestination?.latlng?.lng &&
      orders[0]?.shipment?.selectedOrigin?.formattedAddress &&
      orders[0]?.shipment?.selectedSalesChannel?.name
    ) {
      list.push({
        position: orders[0].shipment.selectedOrigin.latlng,
        title: `${orders[0].shipment.selectedOrigin.formattedAddress}`,
        infoText: orders[0].shipment.selectedOrigin.formattedAddress,
        icon: GOOGLE_MAP_CONSTANTS.CANAL_ICON,
      })
      options.center = new LatLng(
        orders[0].shipment.selectedOrigin.latlng.lat,
        orders[0].shipment.selectedOrigin.latlng.lng
      )
    } else if (
      orders &&
      orders.length > 0 &&
      orders[0]?.shipment == null &&
      orders[0]?.assembly?.selectedOrigin?.latlng?.lat &&
      orders[0]?.assembly?.selectedOrigin?.latlng?.lng &&
      orders[0]?.assembly?.selectedOrigin?.formattedAddress &&
      orders[0]?.assembly?.selectedSalesChannel?.name
    ) {
      list.push({
        position: orders[0].assembly.selectedOrigin.latlng,
        title: `${orders[0].assembly.selectedOrigin.formattedAddress}`,
        infoText: orders[0].assembly.selectedOrigin.formattedAddress,
        icon: GOOGLE_MAP_CONSTANTS.CANAL_ICON,
      })
      options.center = new LatLng(
        orders[0].assembly.selectedOrigin.latlng.lat,
        orders[0].assembly.selectedOrigin.latlng.lng
      )
    }
    setMarkers(list)
    setMapOptions(options)
  }, [salesChannelOneWeekShipments])
  const handleMapLoad = () => {
    console.log('map is loaded')
    setMapLoaded(true)
  }
  useEffect(() => {
    if (directionRoute) {
      if (typeof directionRoute.id === 'number') {
        setRetrievedDirections([directionRoute])
      }
    }
    if (directionRoute === null) {
      console.log('test', directionRoute)

      setRetrievedDirections([])
    }
  }, [directionRoute])
  useEffect(() => {
    if (directions) {
      setDirections(directions)
    }
    if (directionRoute === null) {
      setDirections([])
    }
  }, [directionRoute])
  useEffect(() => {
    return () => {
      //removeDirection()
      resetDirections()
    }
  }, [])
  // console.log('1-2SHIPMENTmarkersList================>', markersList)
  // console.log('1-2SHIPMENTdirectionsList================>', directionsList)
  // console.log('1-2SHIPMENTretrievedDirections================>', retrievedDirections)

  return (
    <GoogleMapContainer
      markers={markersList}
      directions={directionsList.concat(retrievedDirections)}
      mapOptions={customMapOptions}
      handleMapLoad={handleMapLoad}
      containerHeight={'100%'}
      mapContainerStyle={{
        minHeight: '500px',
        inWidth: 280,
        position: 'relative',
      }}
      mapId={'map-container'}
    />
  )
}
MapSection.propTypes = {
  markers: PropTypes.array.isRequired,
  directions: PropTypes.array.isRequired,
  mapOptions: PropTypes.object.isRequired,
  salesChannelOneWeekShipments: PropTypes.array,
  getGoogleMapDirection: PropTypes.func,
  directionRoute: PropTypes.any,
  orders: PropTypes.array,
  resetDirections: PropTypes.func,
  /** Used for adding style to the container */
  mapContainerStyle: PropTypes.any,
  /** the className of the mapContainer */
  mapId: PropTypes.any,
}
export default MapSection
