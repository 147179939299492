import React from 'react'
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { leftLinks, rightLinks } from '../../../../../routes/NavRoutes'
import './index.scss'
import Divider from '@mui/material/Divider'

import { Link } from 'react-router-dom'
import Badge from '@mui/material/Badge'
import SubNavListItem from '../SubNavDrawer/subNavListItem'
import { routeCanAccess } from '../../../../../utils/roleManagement'

export default function TopMenuDrawer({
  open,
  handleDrawerClose,
  userLogout,
  nbNotif,
  userRole,
}) {
  function ListItemLink(props) {
    const handleClick = (event) => {
      if (props.to === window.location.pathname) {
        event.preventDefault() // prevent reloading if the link points to the current page
      }
    }
    return (
      <ListItem
        onClick={(e) => {
          handleClick(e)
          handleDrawerClose()
        }}
        component={Link}
        {...props}
      />
    )
  }
  ListItemLink.propTypes = {
    to: PropTypes.string,
  }

  return (
    <Drawer anchor="left" open={open} classes={{ paperAnchorLeft: 'paperAnchor' }}>
      <List className="drawer" classes={{ root: 'drawerList' }}>
        {routeCanAccess([...leftLinks, ...rightLinks], userRole).map((tab, index) =>
          tab.label === 'Discussion' ? (
            <Badge key={index} badgeContent={nbNotif} color="error">
              <ListItemLink
                button
                key={index}
                className="drawerPaper"
                style={{ margin: '0px 50px' }}
                value={tab.path[0]}
                to={tab.path[0]}
              >
                {tab.label}
              </ListItemLink>
            </Badge>
          ) : (
            <>
              {tab.children ? (
                <SubNavListItem tab={tab} key={index} onClick={handleDrawerClose} />
              ) : (
                <ListItemLink
                  button
                  key={index}
                  className="drawerPaper"
                  value={tab.path[0]}
                  to={tab.path[0]}
                >
                  {tab.label}
                </ListItemLink>
              )}
            </>
          )
        )}
        <Divider />
        <ListItem
          button
          className="drawerPaper"
          onClick={userLogout}
          style={{ marginBottom: 50 }}
        >
          <span id="logout" className="logout-button">
            {' '}
            Déconnexion
          </span>
        </ListItem>
      </List>
    </Drawer>
  )
}
TopMenuDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  userLogout: PropTypes.func.isRequired,
  nbNotif: PropTypes.number,
  userRole: PropTypes.string,
}
