import _ from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Place } from '../../models/place'
import TextField from '@mui/material/TextField'

import RoomIcon from '@mui/icons-material/Room'
import HistoryIcon from '@mui/icons-material/History'
import StoreIcon from '@mui/icons-material/Store'
import BusinessIcon from '@mui/icons-material/Business'

import './style.scss'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import {
  SALES_CHANNEL_ADDRESS_TYPE,
  HITORY_ADDRESS_TYPE,
  COMPANY_ADDRESS_TYPE,
  GOOGLE_COUNTRIES,
  GOOGLE_DEFAULT_COUNTRY,
} from '../../constants/appContants'
import { Country } from '../../models/country'

export default function AddressAutoComplete({
  label,
  salesChannelAddresses,
  companyAddresses,
  handleOnAddressSelect,
  handleQueryChange,
  selectedDefaultAddress,
  getPredictions,
  geoCachingAddressPredictions,
  hasError,
  isRequired,
  errorText,
  isDisabled,
  id,
  placeholder,
  sxInput,
}) {
  const inputRef = useRef()
  const [isLoaded, setIsLoaded] = useState(false)
  const [query, setQuery] = useState('')
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [predictions, setPredictions] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(null)
  const [addressList, setAddressList] = useState([])
  const countries = GOOGLE_COUNTRIES.map((c) => {
    c.code = c.code.toLowerCase()
    return c
  }).sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((c) => c.code === GOOGLE_DEFAULT_COUNTRY.code)
  )

  const useKeyPress = function (targetKey) {
    const [keyPressed, setKeyPressed] = useState(false)

    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true)
      }
    }

    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false)
      }
    }

    React.useEffect(() => {
      window.addEventListener('keydown', downHandler)
      window.addEventListener('keyup', upHandler)

      return () => {
        window.removeEventListener('keydown', downHandler)
        window.removeEventListener('keyup', upHandler)
      }
    })

    return keyPressed
  }
  const downPress = useKeyPress('ArrowDown')
  const upPress = useKeyPress('ArrowUp')
  const enterPress = useKeyPress('Enter')
  const [cursor, setCursor] = useState(0)

  useEffect(() => {
    if (isMenuOpen && predictions?.length && downPress) {
      setCursor((prevState) =>
        prevState < predictions?.length - 1 ? prevState + 1 : prevState
      )
    }
  }, [downPress, isMenuOpen, predictions])
  useEffect(() => {
    if (isMenuOpen && predictions?.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState))
    }
  }, [upPress, isMenuOpen, predictions])

  useEffect(() => {
    if (isMenuOpen && predictions?.length && enterPress) {
      handleClose(null, predictions[cursor])
    }
  }, [cursor, enterPress])
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    if (_.get(geoCachingAddressPredictions, 'length')) {
      setPredictions([...geoCachingAddressPredictions])
    } else {
      setPredictions([])
    }
  }, [geoCachingAddressPredictions])
  useEffect(() => {
    if (_.get(salesChannelAddresses, 'length')) {
      let addressList = salesChannelAddresses.map((ad) =>
        new Place().getFromCanalAddress(ad)
      )
      setAddressList([...addressList])
    } else {
      setAddressList([])
    }
  }, [salesChannelAddresses])

  useEffect(() => {
    if (_.get(companyAddresses, 'length')) {
      let addressList = companyAddresses.map((ad) =>
        new Place().getFromCompanyAddress(ad)
      )
      setAddressList([...addressList])
    } else {
      setAddressList([])
    }
  }, [companyAddresses])

  const onInputChange = (ev) => {
    setQuery(ev.target.value)
    if (!isMenuOpen) {
      setIsMenuOpen(true)
    }
  }

  const handleClose = (e, address) => {
    setSelectedAddress(address)
    let queryAddress = ''
    if (e !== null) {
      e.preventDefault()
    }
    if (address) {
      handleOnAddressSelect(address)
    } else {
      handleOnAddressSelect(null)
    }
    if (address && address !== null && address.type !== COMPANY_ADDRESS_TYPE) {
      queryAddress = new Place().getFromSelectedAddress(address).formattedAddress
      setQuery(queryAddress)
      if (
        _.get(address, 'country.code') &&
        _.get(address, 'country.code') !== selectedCountry.code
      ) {
        setSelectedCountry(address.country)
      }
    } else {
      if (address && address !== null && address.type !== COMPANY_ADDRESS_TYPE) {
        queryAddress = new Place().getFromClientPro(address).formattedAddress
        setQuery(queryAddress)
        if (
          _.get(address, 'country.code') &&
          _.get(address, 'country.code') !== selectedCountry.code
        ) {
          setSelectedCountry(address.country)
        }
      }
    }

    setIsMenuOpen(false)
  }
  useEffect(() => {
    if (!_.isNil(selectedDefaultAddress)) {
      setQuery(selectedDefaultAddress.formattedAddress)
      if (_.get(selectedDefaultAddress, 'country.code')) {
        const selectedCountry = countries.find(
          (c) => c.code === selectedDefaultAddress.country.code.toLowerCase()
        )
        setSelectedCountry(selectedCountry)
      } else {
        setSelectedCountry(
          new Country(GOOGLE_DEFAULT_COUNTRY.code, GOOGLE_DEFAULT_COUNTRY.name)
        )
      }
    } else {
      setQuery('')
    }
    handleClose(null, selectedDefaultAddress)
  }, [selectedDefaultAddress])

  useEffect(() => {
    handleQueryChange(query)
  }, [query])

  useEffect(() => {
    if (!isMenuOpen && isLoaded) {
      inputRef.current.blur()
    }
  }, [isMenuOpen])

  const handleSelectedCountry = (c) => {
    if (selectedCountry.code !== c.code) {
      setSelectedCountry(new Country(c.code, c.name))
      if (_.get(selectedAddress, 'code') !== c.code) {
        handleClose(null, null)
      }
    }
  }

  const getAutoCompleteAddress = () => {
    if (query && query !== null && query.trim().length && isMenuOpen) {
      getPredictions(query, selectedCountry.code)
    } else {
      setPredictions([...addressList])
    }
  }

  useEffect(() => {
    const inputchangeTimer = setTimeout(() => {
      getAutoCompleteAddress()
    }, 400)
    return () => {
      clearTimeout(inputchangeTimer)
    }
  }, [query])

  const handleBlur = (e) => {
    if (isMenuOpen) {
      const { value } = e.target
      setTimeout(() => {
        if (_.get(geoCachingAddressPredictions, 'length')) {
          const address = geoCachingAddressPredictions.find(
            (a) => a.formattedAddress === value
          )
          address ? handleClose(null, address) : handleClose(null, null)
        } else {
          handleClose(null, null)
        }
      }, 2)
    }
  }
  return (
    <>
      {isLoaded ? (
        <div className="auto-complete-address">
          {countries && countries.length && (
            <Select
              variant="standard"
              labelId="img-label"
              id="select-address"
              className="flag-list-select"
              disabled={isDisabled}
              value={selectedCountry}
              onChange={($event) => handleSelectedCountry($event.target.value)}
              renderValue={(selected) => (
                <span className={`flag-icon flag-icon-${selected.code}`}></span>
              )}
              IconComponent={() => null}
            >
              {countries.length === 0 ? (
                <MenuItem value={''} disabled={true}>
                  no option
                </MenuItem>
              ) : (
                countries.map((c, i) => (
                  <MenuItem value={c} key={i} className="flag-menu-item">
                    <span className={`flag-icon flag-icon-${c.code}`}></span>
                    {c.name}
                  </MenuItem>
                ))
              )}
            </Select>
          )}

          <TextField
            id={id}
            placeholder={placeholder}
            label={label}
            variant="outlined"
            fullWidth={true}
            ref={inputRef}
            className="auto-complete-input"
            onChange={onInputChange}
            onFocus={() => setIsMenuOpen(true)}
            onClick={() => setIsMenuOpen(true)}
            value={query}
            onBlur={($event) => handleBlur($event)}
            error={hasError}
            required={isRequired}
            disabled={isDisabled}
            // autoComplete="none"
            helperText={errorText}
            autoComplete="off"
            InputProps={{
              sx: sxInput,
            }}
          />
          <ul className="auto-complete-address-list">
            {predictions && isMenuOpen
              ? predictions.map((a, index) => (
                  <li
                    className={`${index === cursor ? 'active' : ''}`}
                    onMouseDown={($event) => handleClose($event, a)}
                    key={index}
                  >
                    {a.type === SALES_CHANNEL_ADDRESS_TYPE ? (
                      <StoreIcon color="primary" />
                    ) : a.type === HITORY_ADDRESS_TYPE ? (
                      <HistoryIcon color="primary" />
                    ) : a.type === COMPANY_ADDRESS_TYPE ? (
                      <BusinessIcon color="primary" />
                    ) : (
                      <RoomIcon color="primary" />
                    )}
                    {/* <strong className="place-name">{a.placeName}</strong>
                    <br />*/}
                    <span className="address">{a.formattedAddress}</span>
                  </li>
                ))
              : null}
          </ul>
        </div>
      ) : null}
    </>
  )
}
AddressAutoComplete.propTypes = {
  /** The short hint displayed in the field before the user enters a value. */
  label: PropTypes.string.isRequired,

  /** The default selected Adreess*/
  selectedDefaultAddress: PropTypes.instanceOf(Place),
  /** The function that is executed when an adress item is selected */
  handleOnAddressSelect: PropTypes.func.isRequired,
  /** The function that is executed when the field is changed*/
  handleQueryChange: PropTypes.func.isRequired,
  /** An array of sales channel adresses that will be added to the prediction adresses */
  salesChannelAddresses: PropTypes.array,
  /** An array of company adresses that will be added to the prediction adresses */
  companyAddresses: PropTypes.array,
  /** *********************** */
  error: PropTypes.string,
  /** The function that is used to recover the predicted adresses */
  getPredictions: PropTypes.func,

  /** The array of generated predictions */
  geoCachingAddressPredictions: PropTypes.array,

  /** If true, the label will be displayed in an error state with its helper test */
  hasError: PropTypes.bool,

  /** If true, the label is displayed as required and the input element` will be required. */
  isRequired: PropTypes.bool,
  errorText: PropTypes.any,
  isDisabled: PropTypes.bool,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  sxInput: PropTypes.object,
}
