import { connect } from 'react-redux'
import FilesCourse from '../../../components/FilesCourse/commandeEnCours'
import { viewFiles, downloadFiles } from '../../../redux/shipment/actions'
import { getIsLoadingFiles } from '../../../redux/shipment/selectors'

const mapStateToProps = (state) => ({
  isLoadingFiles: getIsLoadingFiles(state),
})
const mapDisptachToProps = (dispatch) => ({
  viewFiles: (fileDetail) => dispatch(viewFiles(fileDetail)),
  downloadFiles: (fileDetail) => dispatch(downloadFiles(fileDetail)),
})

export default connect(mapStateToProps, mapDisptachToProps)(FilesCourse)
