import { httpFetch } from '../http/actions'
import { getDayDispoSuccess, getDayDispoFailure } from './actions'
import {
  GET_DAY_DISPONIBLE,
  GET_DAY_DISPONIBILITES,
  GET_DAY_DISPONIBILITES_SUCCESS,
  GET_DAY_DISPONIBILITES_FAILURE,
} from './constants'
import queryString from 'query-string'

const euromaticApi = process.env.REACT_APP_EUROMATIC_URL
const tokenEuromatic = process.env.REACT_APP_EUROMATIC_TOKEN
const apiDisponibilites = process.env.REACT_APP_AVAILABILITY_MS_URL

const scheduleOrderMiddleware = (store) => (next) => (action) => {
  if (action.type === GET_DAY_DISPONIBLE) {
    const { dateDebut, dateFin, codePostal } = action.payload

    const endPoint = `${euromaticApi}RDVDispo/${dateDebut}/${dateFin}/${codePostal}`

    fetch(endPoint, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: tokenEuromatic,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        store.dispatch(getDayDispoSuccess(response))
      })
      .catch((err) => store.dispatch(getDayDispoFailure(err)))
  }

  if (action.type === GET_DAY_DISPONIBILITES) {
    store.dispatch(
      httpFetch({
        endPoint: `${apiDisponibilites}api/v1/time-slots/all/available/appointments?${queryString.stringify(
          action.payload
        )}`,
        success: GET_DAY_DISPONIBILITES_SUCCESS,
        failure: GET_DAY_DISPONIBILITES_FAILURE,
      })
    )
  }

  next(action)
}

export default scheduleOrderMiddleware
