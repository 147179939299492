import React from 'react'

import { styled } from '@mui/material/styles'

import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
  paddingRight: '7px',
  paddingLeft: '0px',
  minHeight: '42px',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    '& .MuiSvgIcon-root': {
      color: 'black',
      fontSize: '22px',
    },
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0px',
  marginTop: '8px',
}))

export const CardSyled = styled('div')(() => ({
  backgroundColor: '#fff',
  boxShadow: '0 1px 7px 0 rgb(0 0 0 / 10%)',
  borderRadius: '5px',
  padding: '14px 8px 8px 8px',
  margin: '10px 0',
}))

export const TitleWithIcon = styled('div')(() => ({
  display: 'flex ',
  fontSize: '16px',
  fontWeight: '600',
  color: 'rgb(24, 118, 210)',
  alignSelf: 'center',
}))
