import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import EmptyTable from '../../../../components/EmptyTable/EmptyTable'
import {
  Box,
  CircularProgress,
  IconButton,
  TablePagination,
  Tooltip,
} from '@mui/material'
import { EnhancedTableToolbar } from './toolbar'
import { EnhancedTableHead } from './TableHead'
import { TabConatainer } from '../../styled'
import DriverKeyModal from '../../../../containers/settings/Modals/DriverKeyModal'
import EditIcon from '../../../../assets/icons/settingsCorner/editIcom'
import DissociationIcon from '../../../../assets/icons/settingsCorner/dissociationIcon'
import UnassignModal from '../../../../containers/settings/Modals/UnassignModal'
import KeyModal from '../../../../containers/settings/Modals/keyModal'
import moment from 'moment'
import SalesChannelModal from '../../../../containers/settings/Modals/SalesChannelModal'
import SearchBarre from '../searchBarre'
import ModalError from '../../../../components/ModalError'
import ParticipantModal from '../../../../containers/settings/Modals/PartcipantModal'
import ArchiveeFormation from '../../../../assets/icons/settingsCorner/archiveeFormation'
import {
  FormationFamilyObject,
  ParticipantRoleObject,
  ParticipantTypeObject,
  VehicleTypeObject,
} from '../../../../utils/values'
import FormationModal from '../../../../containers/settings/Modals/FormationModal'
import FormationArchivageModal from '../../../../containers/settings/Modals/FormationArchivageModal'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FilesModal from '../../../../containers/settings/Modals/FilesModal'
import DriverModal from '../../../../containers/settings/Modals/DriverModal'
import VehiculeModal from '../../../../containers/settings/Modals/VehiculeModal'
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled'
import AnnulationParticipation from '../../../../containers/settings/Modals/AnnulationParticipation'
import DeleteVehicule from '../../../../containers/settings/Modals/DeleteVehicule'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import TourneeModal from '../../../../containers/settings/Modals/TourneeModal'

const TableKeys = ({
  actionButton,
  title,
  tableHead,
  tableRows,
  isKey,
  isDriverKey,
  isChannel,
  countData,
  pageChanged,
  pageLimitChanged,
  loadingData,
  onCloseReset,
  searchData,
  searchPlaceholder,
  errorMessage,
  removeError,
  isErrorFetch,
  fetchData,
  isParticipant,
  isFormation,
  globalID,
  fetchFiles,
  fetchDataById,
  removeErrorConfig,
  resetPagination,
  isDriver,
  isVehicle,
  isTournee,
}) => {
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [openDissociation, setOpenDissociation] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [row, setRow] = useState({})
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [openDialogPhoto, setOpenDialogPhoto] = useState(false)
  const [openArchiveFormation, setOpenArchiveFormation] = useState(false)
  const [openAnnulationParticipant, setOpenAnnulationParticipant] = useState(false)
  const [openDeleteVehicule, setOpenDeleteVehicule] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    pageChanged(newPage * rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageLimitChanged(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClose = () => {
    setOpen(false)
    setIsEdit(false)
  }
  const handleCloseDissociation = () => {
    setOpenDissociation(false)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onCloseReset)

    return () => {
      onCloseReset()
      window.removeEventListener('beforeunload', onCloseReset)
    }
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setOpenErrorDialog(true)
    }
  }, [errorMessage])
  useEffect(() => {
    if (resetPagination) {
      setPage(0)
      setRowsPerPage(25)
    }
  }, [resetPagination])

  const formatDataPayload = () => {
    let formData = new FormData()
    formData.append(
      'participantDto',
      JSON.stringify({ id: row.id, isParticipant: false, method: 'Corner' })
    )
    return formData
  }
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            paddingRight: '5px',
          }}
        >
          <EnhancedTableToolbar title={title} />
          <SearchBarre
            globalID={globalID}
            onSearch={searchData}
            placeholder={searchPlaceholder}
          />
        </div>

        <TabConatainer component={'div'}>
          <Table
            sx={{ backgroundColor: 'white' }}
            stickyHeader
            aria-label="sticky table"
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              tableHead={tableHead}
              setOpen={setOpen}
              globalID={globalID}
              dropMenuCreate={isParticipant}
            />

            {loadingData ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={tableHead.length + 1}
                    sx={{ padding: '8px', border: 'none' }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress sx={{ color: '#1976D2' }} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : tableRows?.length > 0 ? (
              <TableBody
                sx={{
                  '& tr': {
                    '& th:first-of-type': {
                      borderLeft: '1px solid #EBEDF0',
                    },
                  },
                  '& tr:nth-of-type(2)': {
                    '& th': {
                      borderTop: '1px solid #EBEDF0',
                    },

                    '& th:first-of-type': {
                      borderTopLeftRadius: '4px',
                    },
                  },
                  '& tr:last-child': {
                    '& th:first-of-type': {
                      borderBottomLeftRadius: '4px',
                    },
                  },
                }}
              >
                <TableRow>
                  <TableCell
                    colSpan={tableHead.length + 1}
                    sx={{ padding: '8px', border: 'none' }}
                  >
                    <span></span>
                  </TableCell>
                </TableRow>
                {tableRows.map((row, rowIndex) => {
                  const labelId = `enhanced-table-checkbox-${rowIndex}`

                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      {tableHead.map((col, index) => (
                        <TableCell
                          key={index}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normale"
                          sx={
                            col.id == 'competences'
                              ? {
                                  color: '#6D7B8E',
                                  maxWidth: '50px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }
                              : {
                                  color: '#6D7B8E',
                                }
                          }
                        >
                          {col.label?.includes('Date') ? (
                            moment(new Date(row[col.id])).format('YYYY-MM-DD')
                          ) : col.id == 'competences' &&
                            Array.isArray(row[col.id]) ? (
                            row[col.id]?.length > 0 ? (
                              <Tooltip
                                arrow
                                title={
                                  <span className="tootltip-content">
                                    {row[col.id].map((el) => el.value).join(', ')}
                                  </span>
                                }
                              >
                                <span>
                                  {row[col.id] &&
                                    row[col.id].map((el) => el.value).join(', ')}
                                </span>
                              </Tooltip>
                            ) : (
                              'N/A'
                            )
                          ) : col.id == 'files' ? (
                            <Tooltip
                              title={
                                <span className="tootltip-content">
                                  Afficher fichiers
                                </span>
                              }
                              arrow
                            >
                              <IconButton
                                id={`afficher-fichier-${globalID}`}
                                onClick={() => {
                                  fetchFiles(row.id)
                                  setOpenDialogPhoto(true)
                                }}
                                color="primary"
                                size="small"
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          ) : Array.isArray(row[col.id]) ? (
                            row[col.id][0].value
                          ) : col.id === 'family' ? (
                            FormationFamilyObject[row[col.id]] || 'N/A'
                          ) : col.id === 'type' && isParticipant ? (
                            ParticipantTypeObject[row[col.id]] || 'N/A'
                          ) : col.id === 'type' && isVehicle ? (
                            VehicleTypeObject[row[col.id]] || 'N/A'
                          ) : col.id === 'role' && isDriver ? (
                            ParticipantRoleObject[row[col.id]] || 'N/A'
                          ) : col.id === 'adresse' ||
                            col.id === 'departureAddress' ||
                            col.id === 'arrivalAddress' ? (
                            row[col.id]?.address || 'N/A'
                          ) : col.id === 'fullName' ? (
                            `${row.firstName} ${row.name}`
                          ) : col.id === 'timeWindow' ? (
                            `${row[col.id].start} - ${row[col.id].end}`
                          ) : col.id === 'vehicle' ? (
                            row[col.id]?.immatriculation && row[col.id]?.type ? (
                              `${row[col.id]?.immatriculation} - ${
                                VehicleTypeObject[row[col.id]?.type]
                              }`
                            ) : row[col.id]?.immatriculation ? (
                              `${row[col.id].immatriculation}`
                            ) : row[col.id]?.type ? (
                              ` ${VehicleTypeObject[row[col.id]?.type]}`
                            ) : (
                              'N/A'
                            )
                          ) : col.id === 'participant' ? (
                            row[col.id]?.firstName && row[col.id]?.lastName ? (
                              `${row[col.id]?.firstName} ${row[col.id]?.lastName}`
                            ) : row[col.id]?.firstName ? (
                              `${row[col.id]?.firstName}`
                            ) : row[col.id]?.lastName ? (
                              ` ${row[col.id]?.lastName}`
                            ) : (
                              'N/A'
                            )
                          ) : (
                            row[col.id] || 'N/A'
                          )}
                        </TableCell>
                      ))}
                      {actionButton ? (
                        <TableCell
                          align="right"
                          sx={{
                            borderRight: '1px solid #EBEDF0',

                            ...(rowIndex === 0 && {
                              borderTopRightRadius: '4px',
                              borderTop: '1px solid #EBEDF0',
                            }),
                            ...(rowIndex === tableRows?.length - 1 && {
                              borderBottomRightRadius: '4px',
                            }),
                          }}
                        >
                          {isFormation ? (
                            <Box sx={{ display: 'flex', placeContent: 'flex-end' }}>
                              <Tooltip
                                title={
                                  <span className="tootltip-content">Archiver</span>
                                }
                                arrow
                              >
                                <IconButton
                                  onClick={() => {
                                    setOpenArchiveFormation(true)
                                    setRow(row)
                                  }}
                                  color="primary"
                                  size="small"
                                  sx={{
                                    color: '#B70707',
                                    marginRight: '12px',
                                  }}
                                >
                                  <ArchiveeFormation />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={
                                  <span className="tootltip-content">Modifier</span>
                                }
                                arrow
                              >
                                <IconButton
                                  onClick={() => {
                                    setIsEdit(true)
                                    setOpen(true)
                                    setRow(row)
                                    fetchDataById(row.id)
                                  }}
                                  color="primary"
                                  size="small"
                                  sx={{ color: '#1876D2' }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : isParticipant ? (
                            <Box
                              sx={{ display: 'flex', justifyContent: 'flex-end' }}
                            >
                              <Tooltip
                                title={
                                  <span className="tootltip-content">
                                    Annuler la participation
                                  </span>
                                }
                                arrow
                              >
                                <IconButton
                                  onClick={() => {
                                    setOpenAnnulationParticipant(true)
                                    setRow(row)
                                  }}
                                  color="primary"
                                  size="small"
                                  sx={{
                                    color: '#B70707',
                                    marginRight: '12px',
                                  }}
                                >
                                  <PersonAddDisabledIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={
                                  <span className="tootltip-content">Modifier</span>
                                }
                                arrow
                              >
                                <IconButton
                                  id={`Modifier-${globalID}`}
                                  onClick={() => {
                                    setIsEdit(true)
                                    setOpen(true)
                                    setRow(row)
                                  }}
                                  color="primary"
                                  size="small"
                                  sx={{ color: '#1876D2' }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : isKey ? (
                            <Tooltip
                              title={
                                <span className="tootltip-content">Modifier</span>
                              }
                              arrow
                            >
                              <IconButton
                                id={`Modifier-${globalID}`}
                                onClick={() => {
                                  setIsEdit(true)
                                  setOpen(true)
                                  setRow(row)
                                }}
                                color="primary"
                                size="small"
                              >
                                <EditIcon style={{ color: 'color: #1876D2' }} />
                              </IconButton>
                            </Tooltip>
                          ) : isChannel || isDriverKey ? (
                            <Tooltip
                              title={
                                <span className="tootltip-content">
                                  dissocier une clé
                                </span>
                              }
                              arrow
                            >
                              <IconButton
                                id={`dissocier-${globalID}`}
                                onClick={() => {
                                  setOpenDissociation(true)
                                  setRow(row)
                                }}
                                color="primary"
                                size="small"
                              >
                                <DissociationIcon style={{ color: '#B70707' }} />
                              </IconButton>
                            </Tooltip>
                          ) : isDriver ? (
                            <Tooltip
                              title={
                                <span className="tootltip-content">Modifier</span>
                              }
                              arrow
                            >
                              <IconButton
                                id={`Modifier-${globalID}`}
                                onClick={() => {
                                  setIsEdit(true)
                                  setOpen(true)
                                  setRow(row)
                                }}
                                color="primary"
                                size="small"
                              >
                                <EditIcon style={{ color: 'color: #1876D2' }} />
                              </IconButton>
                            </Tooltip>
                          ) : isVehicle ? (
                            <Box
                              sx={{ display: 'flex', justifyContent: 'flex-end' }}
                            >
                              <Tooltip
                                title={
                                  <span className="tootltip-content">
                                    Supprission
                                  </span>
                                }
                                arrow
                              >
                                <IconButton
                                  onClick={() => {
                                    setOpenDeleteVehicule(true)
                                    setRow(row)
                                  }}
                                  color="primary"
                                  size="small"
                                  sx={{
                                    color: '#B70707',
                                    marginRight: '12px',
                                  }}
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={
                                  <span className="tootltip-content">Modifier</span>
                                }
                                arrow
                              >
                                <IconButton
                                  id={`Modifier-${globalID}`}
                                  onClick={() => {
                                    setIsEdit(true)
                                    setOpen(true)
                                    setRow(row)
                                  }}
                                  color="primary"
                                  size="small"
                                >
                                  <EditIcon style={{ color: 'color: #1876D2' }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : (
                            isTournee && (
                              <Tooltip
                                title={
                                  <span className="tootltip-content">Modifier</span>
                                }
                                arrow
                              >
                                <IconButton
                                  id={`Modifier-${globalID}`}
                                  onClick={() => {
                                    setIsEdit(true)
                                    setOpen(true)
                                    setRow(row)
                                  }}
                                  color="primary"
                                  size="small"
                                >
                                  <EditIcon style={{ color: 'color: #1876D2' }} />
                                </IconButton>
                              </Tooltip>
                            )
                          )}
                        </TableCell>
                      ) : (
                        <TableCell align="center"></TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            ) : (
              <EmptyTable colspan={tableHead.length + 1} />
            )}
          </Table>
        </TabConatainer>
        <Box
          sx={{
            display: 'flex',
            placeContent: 'end',
          }}
        >
          <TableCell
            size="small"
            colSpan={tableHead.length + 1}
            sx={{
              borderBottom: 'none',
            }}
          >
            <TablePagination
              component="div"
              rowsPerPageOptions={[25, 50, 100, 200]}
              count={countData}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="lignes par page"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableCell>
        </Box>
      </Box>

      {errorMessage && (
        <ModalError
          open={openErrorDialog}
          handleClose={() => {
            setOpenErrorDialog(false)
            removeError()
            removeErrorConfig()
            isErrorFetch && fetchData()
          }}
          message={errorMessage}
          isModalActionActualiser={isErrorFetch}
        />
      )}

      {isKey && open && (
        <KeyModal
          isEdit={isEdit}
          handleClose={handleClose}
          modalTitle={isEdit ? ' Modifier une clé ' : 'Ajouter une clé'}
          open={open}
          row={row}
        />
      )}

      {isDriverKey && open && (
        <DriverKeyModal
          isEdit={isEdit}
          handleClose={handleClose}
          modalTitle={'Affecter une clé à un chauffeur'}
          open={open}
        />
      )}

      {isChannel && open && (
        <SalesChannelModal
          isEdit={isEdit}
          handleClose={handleClose}
          modalTitle={'Affecter une clé à un canal'}
          open={open}
        />
      )}

      {isParticipant && open && (
        <ParticipantModal
          isEdit={isEdit}
          handleClose={handleClose}
          modalTitle={isEdit ? ' Modifier Participant ' : 'Ajouter Participant'}
          open={open}
          row={row}
        />
      )}

      {isDriver && open && (
        <DriverModal
          isEdit={isEdit}
          handleClose={handleClose}
          modalTitle={isEdit ? ' Modifier Chauffeur ' : 'Ajouter Chauffeur'}
          open={open}
          row={row}
        />
      )}
      {isVehicle && open && (
        <VehiculeModal
          isEdit={isEdit}
          handleClose={handleClose}
          modalTitle={isEdit ? ' Modifier Véhicule ' : 'Ajouter Véhicule'}
          open={open}
          row={row}
        />
      )}
      {isFormation && open && (
        <FormationModal
          isEdit={isEdit}
          handleClose={handleClose}
          modalTitle={isEdit ? ' Modifier Formation ' : 'Ajouter Formation'}
          open={open}
          formationId={row.id}
        />
      )}
      {isTournee && open && (
        <TourneeModal
          isEdit={isEdit}
          handleClose={handleClose}
          modalTitle={isEdit ? ' Modifier Ressource ' : 'Ajouter nouvelle ressource'}
          open={open}
          row={row}
        />
      )}
      {openDissociation && (
        <UnassignModal
          isDriver={isDriverKey}
          handleClose={handleCloseDissociation}
          textContent={'Vous voulez vraiment dissocier cette clé.'}
          modalTitle={
            isDriverKey ? 'Dissociation Clé-chauffeur' : 'Dissociation Clé-canal'
          }
          open={openDissociation}
          row={row}
          actionData={
            isDriverKey
              ? { keyId: row.keys[0].id, driverId: row.id }
              : { keyId: row.keys[0].id, salesChanelCode: row.code }
          }
        />
      )}
      {openArchiveFormation && (
        <FormationArchivageModal
          handleClose={() => setOpenArchiveFormation(false)}
          textContent={'Vous voulez vraiment archiver ce formation.'}
          modalTitle={'Archivage Formation'}
          open={openArchiveFormation}
          row={row}
          actionData={{ ids: [row.id] }}
        />
      )}

      {openDialogPhoto && (
        <FilesModal
          globalID={globalID}
          open={openDialogPhoto}
          onClose={() => {
            setOpenDialogPhoto(false)
          }}
        />
      )}
      {openAnnulationParticipant && (
        <AnnulationParticipation
          handleClose={() => setOpenAnnulationParticipant(false)}
          textContent={'Vous voulez vraiment annuler la participation.'}
          modalTitle={'Annulation la participation'}
          open={openAnnulationParticipant}
          actionData={formatDataPayload()}
        />
      )}
      {openDeleteVehicule && (
        <DeleteVehicule
          handleClose={() => setOpenDeleteVehicule(false)}
          textContent={'Vous voulez vraiment supprimer ce véhicule.'}
          modalTitle={'Supprission véhicule'}
          open={openDeleteVehicule}
          actionData={row.id}
        />
      )}
    </>
  )
}

TableKeys.propTypes = {
  title: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  tableHead: PropTypes.array,
  actionButton: PropTypes.bool,
  tableRows: PropTypes.array,
  isKey: PropTypes.bool,
  isDriverKey: PropTypes.bool,
  isChannel: PropTypes.bool,
  countData: PropTypes.number,
  pageChanged: PropTypes.func,
  pageLimitChanged: PropTypes.func,
  loadingData: PropTypes.bool,
  onCloseReset: PropTypes.func,
  searchData: PropTypes.func,
  errorMessage: PropTypes.string,
  removeError: PropTypes.func,
  isErrorFetch: PropTypes.bool,
  fetchData: PropTypes.func,
  isParticipant: PropTypes.bool,
  isFormation: PropTypes.bool,
  globalID: PropTypes.string,
  fetchFiles: PropTypes.func,
  removeErrorConfig: PropTypes.func,
  fetchDataById: PropTypes.func,
  resetPagination: PropTypes.bool,
  isDriver: PropTypes.bool,
  isVehicle: PropTypes.bool,
  isTournee: PropTypes.bool,
}

export default TableKeys
