import { connect } from 'react-redux'

import GestionDisponibilites from '../../../pages/settings/disponibilites'
import {
  fetchCanalVenteDetailsDisponibilites,
  fetchCanalVentes,
  fetchMoreCanalVentes,
  removeErrorDisponibilites,
  resetSuccessAction,
} from '../../../redux/disponibilites/actions'
import {
  getCanalVenteDetails,
  getCanalVenteList,
  getCanalVentesCount,
  getErrorMessage,
  getiIsErrorFetchCanalDetails,
  getIsErrorFetchCanalList,
  getIsLoadingCanalVenteDetails,
  getIsLoadingCanalVenteList,
  getSuccess,
} from '../../../redux/disponibilites/selectors'

const mapStateToProps = (state) => ({
  canalVenteList: getCanalVenteList(state),
  canalVentesCount: getCanalVentesCount(state),
  isLoadingCanalVenteList: getIsLoadingCanalVenteList(state),

  errorMessage: getErrorMessage(state),
  isErrorFetchCanalList: getIsErrorFetchCanalList(state),
  isErrorFetchCanalDetails: getiIsErrorFetchCanalDetails(state),
  canalVenteDetails: getCanalVenteDetails(state),
  isLoadingCanalVenteDetails: getIsLoadingCanalVenteDetails(state),

  success: getSuccess(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchCanalVentes: (filter) => {
    dispatch(fetchCanalVentes(filter))
  },
  fetchMoreCanalVentes: (filter) => {
    dispatch(fetchMoreCanalVentes(filter))
  },
  fetchCanalVenteDetails: (dataSend) => {
    dispatch(fetchCanalVenteDetailsDisponibilites(dataSend))
  },
  removeErrorDisponibilites: () => {
    dispatch(removeErrorDisponibilites())
  },
  resetSuccessAction: () => {
    dispatch(resetSuccessAction())
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(GestionDisponibilites)
