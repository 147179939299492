export const checkActions = [
  {
    id: 'check',
    label: 'Selectionner',
    name: 'Selectionner',
    checked: true,
  },
]
export const headTableColumns = [
  {
    id: 'LDT',
    label: 'Lettre de Tournee',
    minWidth: 20,
    name: 'Lettre de Tournee',
    checked: true,
  },
  {
    id: 'chauffeur',
    label: 'Chauffeur',
    minWidth: 20,
    name: 'Chauffeur',
    checked: true,
  },
  {
    id: 'chauffeurId',
    label: 'Chauffeur ID',
    minWidth: 20,
    name: 'Chauffeur ID',
    checked: true,
  },
  {
    id: 'montantPrestataireHT',
    label: 'Prix',
    minWidth: 20,
    name: 'Prix',
    checked: true,
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 20,
    name: 'Volume',
    checked: true,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 20,
    name: 'Poids',
    checked: true,
  },
  {
    id: 'distance',
    label: 'Distance',
    minWidth: 20,
    name: 'Distance',
    checked: true,
  },
  {
    id: 'tourHours',
    label: 'Horaire de Tournée',
    minWidth: 20,
    name: 'Horaire de Tournée',
    checked: true,
  },
  {
    id: 'tourTime',
    label: 'Durée de la tournée',
    minWidth: 20,
    name: 'Durée de la tournée',
    checked: true,
  },
  {
    id: 'tourTravel',
    label: 'Temps de Trajet',
    minWidth: 20,
    name: 'Temps de Trajet',
    checked: true,
  },
  {
    id: 'courseNB',
    label: 'Nb des courses',
    minWidth: 20,
    name: 'Nb des courses',
    checked: true,
  },
]

export const settings = [
  {
    id: 'settings',
    label: 'Settings',
    minWidth: 100,
    name: 'Settings',
    checked: true,
  },
]
