import { connect } from 'react-redux'

import Session from '../../../../pages/settings/sessions/detailsSession'
import {
  fetchSessionsParticipants,
  removeError,
  fetchSessionDetails,
} from '../../../../redux/sessions/actions'
import {
  getErrorMessage,
  getIsErrorFetchParticipant,
  getIsErrorFetchDetails,
} from '../../../../redux/sessions/selectors'

const mapStateToProps = (state) => ({
  errorMessage: getErrorMessage(state),
  isErrorFetchDetails: getIsErrorFetchDetails(state),
  isErrorFetchParticipant: getIsErrorFetchParticipant(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchSessionsParticipants: (id) => dispatch(fetchSessionsParticipants(id)),
  fetchSessionDetails: (id) => dispatch(fetchSessionDetails(id)),
  removeError: () => dispatch(removeError()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Session)
