import { connect } from 'react-redux'
import Freigths from '../../../../pages/main/Entrepot/freights'

import {
  getDefaultFilterFreights,
  getfilterFreights,
} from '../../../../redux/filter/selectors'
import {
  setFilterFreights,
  resetFilterFreights,
} from '../../../../redux/filter/actions'

const mapStateToProps = (state) => ({
  filterReducer: getfilterFreights(state),
  defaultFilterFreights: getDefaultFilterFreights(state),
})
const mapDisptachToProps = (dispatch) => ({
  setFilter: (filter, fromAppliquer) =>
    dispatch(setFilterFreights(filter, fromAppliquer)),
  resetFilterFreights: () => dispatch(resetFilterFreights()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Freigths)
