import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SelectListWithSearch from '../../../../../ListWithSearch/selectListWithSearch'
import IconRdi from '@mui/icons-material/EventBusy'

export default function RdiFilter({
  disabled,
  filterStateChanged,
  rdiFiltersValues,
  rdiFilter,
  isLoadingRdi,
}) {
  const [checked, setChecked] = useState([])

  useEffect(() => {
    let list = rdiFilter.map(({ code, motif }) => {
      return {
        code: code,
        name: code + '-' + motif,
      }
    })
    setChecked(list)
  }, [rdiFilter])

  const onDriversChanged = (selectedRdi) => {
    filterStateChanged('rdi', selectedRdi ? selectedRdi : [])
  }

  return (
    <SelectListWithSearch
      onDataChanged={onDriversChanged}
      defaultLabel={'RDI'}
      dataList={checked}
      disabled={disabled || isLoadingRdi || rdiFilter.length <= 0}
      filtersValues={rdiFiltersValues}
      nameFilter={'rdi'}
      Icon={IconRdi}
      id={'Rdi'}
      isImportantTous={true}
      isTousSelected={rdiFiltersValues?.length == checked?.length}
    />
  )
}
RdiFilter.propTypes = {
  disabled: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  rdiFiltersValues: PropTypes.array,
  rdiFilter: PropTypes.array,
  isLoadingRdi: PropTypes.bool,
}
