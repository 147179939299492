import React, { useEffect, useState, forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import FilterListIcon from '@mui/icons-material/FilterList'
import Drawer from '@mui/material/Drawer'

import { useHistory } from 'react-router-dom'
import { filterParser } from '../../../../utils/filterParser'

import Filter from '../Filter'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { CORNER, RDI } from '../../../../utils/values'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function ButtonFilter({
  dateFilterToday,
  statusFilter,
  driversFilter,
  salesChannelfilter,
  prestationFilter,
  agentsCommands,
  departmentFilter,
  wareHouseFilter,
  programerDateFilter,
  deliveryDateFilter,
  toursFilter,
  tourListFilter,
  dateFilterTomorrow,
  macroCanalFilter,
  filterReducer,
  setFilter,
  styleButton,
  onFilterValuesChanged,
  setError,
  getWareHouses,
  getTourList,
  getSalesChannels,
  getAllPrestations,
  getMacroCanal,
  getAllDrivers,
  getAllDepartements,
  courseTypeFilter,
  savFilter,
  dateFilterCreation,
  BusinessOwnerFilter,
  resetFilter,
  deliveryDateFilterRange,
  DateFilterReception,
  ResponseCheck,
  subjectFilter,
  getAllSubject,
  ProcessingDepartment,
  pathnameFilter,
  directionFilter,
  nature,
  fromAppliquer,
  resetFromAppliquer,
  displaySelection,
  displayGrid,
  defaultFilter,
  pageFilter,
  idOngletFilterOpen,
  courseLieeFilter,
  searchReducer,
  saveDefaultFilter,
  resetDefaultFilter,
  filtersCollaborateur,
  rdiFilter,
  getRDVImpoMOtifs,
}) {
  const [opentDrawer, setOpentDrawer] = useState(false)

  const toggleDrawer = (open, event) => {
    if (
      event?.type === 'keydown' &&
      (event?.key === 'Tab' || event?.key === 'Shift')
    ) {
      return
    }
    if (open != null) setOpentDrawer(open)
  }
  const history = useHistory()
  const matches = useMediaQuery('(max-width:600px)')

  const [disableFilter, setDisableFilter] = useState(false)
  const onMyCommandsChanged = (isChecked) => {
    setDisableFilter(isChecked)
  }
  const [hashValues, setHashValues] = useState({})
  const [verifFilterEqual, setVerifEqualFilter] = useState(true)
  const [affResetButton, setAffResetButton] = useState(false)
  const [modeCreation, setModeCreation] = useState(false)
  const [affCreationFilterEnregistrer, setAffCreationFilterEnregistrer] =
    useState(true)
  const [creationInfo, setCreationInfo] = useState(false)

  const isEntrepot = window.location.pathname.includes('documents')
  const isFreight = window.location.pathname.includes('tourneeReception')
  const isTournee = window.location.pathname.includes('tourPolaris')
  const isDiscussion = window.location.pathname.includes('discussion')
  const isCommandes =
    window.location.pathname.includes('commandes') ||
    window.location.pathname === '/'

  useEffect(() => {
    wareHouseFilter && getWareHouses()
  }, [])
  useEffect(() => {
    if (tourListFilter) {
      const filter = {
        limit: 25,
        offset: 0,
      }
      getTourList(filter)
    }
  }, [])
  useEffect(() => {
    salesChannelfilter && getSalesChannels()
  }, [])
  useEffect(() => {
    prestationFilter && getAllPrestations()
  }, [])
  useEffect(() => {
    macroCanalFilter && getMacroCanal()
  }, [])
  useEffect(() => {
    driversFilter && getAllDrivers()
  }, [])
  useEffect(() => {
    departmentFilter && getAllDepartements()
  }, [])

  useEffect(() => {
    subjectFilter && getAllSubject()
  }, [])

  useEffect(() => {
    if (rdiFilter) {
      const payload = {
        type: CORNER,
        adminStatus: RDI,
      }
      getRDVImpoMOtifs(payload)
    }
  }, [])

  const parseFilterReducer = (filterReducer) => {
    let dict = {}
    // If we have some defualt values to put when url empty we should put theme HERE !
    if (filterReducer?.warehouse) {
      dict['warehouse'] = filterReducer.warehouse
    }
    if (filterReducer?.processingDepartement) {
      dict['processingDepartement'] = filterReducer.processingDepartement
    }
    if (filterReducer?.subject) {
      dict['subject'] = filterReducer.subject
    }
    if (filterReducer?.status) {
      dict['status'] = filterReducer.status
    }

    if (filterReducer?.salesChannels) {
      dict['salesChannels'] = filterReducer.salesChannels
    }
    if (filterReducer?.client) {
      dict['client'] = filterReducer.client
    }

    if (filterReducer?.prestation) {
      dict['prestation'] = filterReducer.prestation
    }

    if (filterReducer?.drivers) {
      dict['drivers'] = filterReducer.drivers
    }
    if (filterReducer?.tourList) {
      dict['tourList'] = filterReducer.tourList
    }

    if (filterReducer?.daterange) {
      dict['daterange'] = filterReducer.daterange
    }

    if (filterReducer?.department) {
      dict['department'] = filterReducer.department
    }

    if (filterReducer?.dropOffDay) {
      dict['dropOffDay'] = filterReducer.dropOffDay
    }

    if (filterReducer?.datepicked) {
      dict['datepicked'] = filterReducer.datepicked
    }

    if (filterReducer?.courseType) {
      dict['courseType'] = filterReducer.courseType
    }

    if (filterReducer?.createdAt) {
      dict['createdAt'] = filterReducer.createdAt
    }

    if (filterReducer?.businessOwner) {
      dict['businessOwner'] = filterReducer.businessOwner
    }

    if (filterReducer?.dropOffDayRange) {
      dict['dropOffDayRange'] = filterReducer.dropOffDayRange
    }

    if (filterReducer?.receptionDate) {
      dict['receptionDate'] = filterReducer.receptionDate
    }

    if (filterReducer?.processingDepartment) {
      dict['processingDepartment'] = filterReducer.processingDepartment
    }

    if (filterReducer?.direction) {
      dict['direction'] = filterReducer.direction
    }

    if (filterReducer?.reply) {
      dict['reply'] = filterReducer.reply
    }
    if (filterReducer?.nature) {
      dict['nature'] = filterReducer.nature
    }
    //SwitchFilter

    if (filterReducer?.tours) {
      dict['tours'] = filterReducer.tours
    }

    if (filterReducer?.sav) {
      dict['sav'] = filterReducer.sav
    }

    if (filterReducer?.createdAtTodayDate) {
      dict['createdAtTodayDate'] = filterReducer.createdAtTodayDate
    }

    if (filterReducer?.deliveryRelated) {
      dict['deliveryRelated'] = filterReducer.deliveryRelated
    }

    if (filterReducer?.sort) {
      dict['sort'] = filterReducer.sort
    }
    if (filterReducer?.orderBy) {
      dict['orderBy'] = filterReducer.orderBy
    }
    if (filterReducer?.rdi) {
      dict['rdi'] = filterReducer.rdi
    }

    Object.keys(dict).forEach((key) => {
      if (
        dict[key].length === 0 ||
        (dict[key].length > 0 && (dict[key][0] == 'TOUS' || dict[key][0] == false))
      ) {
        return delete dict[key]
      }
    })
    return dict
  }
  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      const dict = parseFilterReducer(value)
      ref.current = dict
    }, [value])
    return ref.current
  }
  const ancienFilter = usePrevious(filterReducer)

  useEffect(() => {
    const dict = parseFilterReducer(filterReducer)
    window.location.hash = Object.keys(dict)
      .map((key) => key + '=' + dict[key].join(','))
      .join('&')

    if (
      !verifEqualFilter(dict, ancienFilter) &&
      ((!displayGrid && Array.isArray(filtersCollaborateur)) ||
        displayGrid ||
        Number(localStorage.getItem('OngletOpened')) == 0)
    ) {
      setHashValues(dict)
      const search =
        searchReducer &&
        isCommandes &&
        !displayGrid &&
        Number(localStorage.getItem('OngletOpened')) != 0
      const searchCorner = {
        criteria: searchReducer?.label,
        type: searchReducer?.categorie.code,
      }
      onFilterValuesChanged(
        filterParser(dict),
        isEntrepot,
        isFreight,
        isTournee,
        isDiscussion,
        search,
        searchCorner
      )
    }
  }, [filterReducer])
  useEffect(() => {
    if (fromAppliquer) resetFromAppliquer()

    return history.listen((location) => {
      const dict = {}
      location.hash
        .slice(1)
        .split('&')
        .forEach((e) => {
          if (e.length > 0) dict[e.split('=')[0]] = e.split('=')[1].split(',')
        })

      if (
        pathnameFilter === history.location.pathname &&
        Object.keys(dict).length >= 0 &&
        !fromAppliquer
      ) {
        // If we have some defualt values to put when url empty we should put theme HERE with their conditions !
        Object.keys(dict).forEach((key) => {
          if (
            dict[key].length === 0 ||
            (dict[key].length > 0 &&
              (dict[key][0] == 'TOUS' || dict[key][0] == false))
          ) {
            return delete dict[key]
          }
        })

        //Decoder
        if (dict?.salesChannels) {
          dict['salesChannels'] = dict.salesChannels.map((s) => {
            return decodeURI(s)
          })
        }

        if (dict?.tourList) {
          dict['tourList'] = dict.tourList.map((s) => {
            return decodeURI(s)
          })
        }

        //SwitchFilter
        if (dict?.tours) {
          dict['tours'] = [dict.tours[0] === 'true']
        }

        if (dict?.sav) {
          dict['sav'] = [dict.sav[0] === 'true']
        }

        if (dict?.createdAtTodayDate) {
          dict['createdAtTodayDate'] = [dict.createdAtTodayDate[0] === 'true']
        }

        setFilter(dict, false)
        toggleDrawer(false, null)
      }
    })
  }, [history, fromAppliquer])

  const arrayEquals = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val) => b.includes(val))
    )
  }

  const verifEqualFilter = (filterFromReducer, filterFromState) => {
    if (filterFromReducer && filterFromState) {
      Object.keys(filterFromReducer).forEach((key) => {
        if (
          filterFromReducer[key] === null ||
          filterFromReducer[key].length === 0 ||
          (filterFromReducer[key].length > 0 &&
            (filterFromReducer[key][0] == 'TOUS' ||
              filterFromReducer[key][0] == false))
        ) {
          return delete filterFromReducer[key]
        }
      })
      Object.keys(filterFromState).forEach((key) => {
        if (
          filterFromState[key] === null ||
          filterFromState[key].length === 0 ||
          (filterFromState[key].length > 0 &&
            (filterFromState[key][0] == 'TOUS' || filterFromState[key][0] == false))
        ) {
          return delete filterFromState[key]
        }
      })

      const keysFilterFromReducer = Object.keys(filterFromReducer)
      const keysFilterFromState = Object.keys(filterFromState)

      if (keysFilterFromReducer?.length !== keysFilterFromState?.length) {
        return false
      }

      for (let key of keysFilterFromReducer) {
        if (
          !keysFilterFromState.includes(key) ||
          !arrayEquals(filterFromReducer[key], filterFromState[key])
        ) {
          return false
        }
      }
      return true
    }
    return false
  }

  useEffect(() => {
    setVerifEqualFilter(verifEqualFilter(filterReducer, hashValues))
    setAffResetButton(verifEqualFilter(defaultFilter, hashValues))
  }, [hashValues, filterReducer, defaultFilter])

  useEffect(() => {
    if (modeCreation) {
      setAffCreationFilterEnregistrer(verifEqualFilter({}, hashValues))
    }
  }, [hashValues, modeCreation])

  useEffect(() => {
    if (modeCreation) {
      setCreationInfo(true)
    }
  }, [modeCreation])

  const filterStateChanged = (filterName, selectedFields) => {
    const filterValuesDict = hashValues
    Object.assign(filterValuesDict, { [filterName]: selectedFields })
    setHashValues({ ...filterValuesDict })
  }

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FilterListIcon />}
        onClick={(event) => toggleDrawer(!opentDrawer, event)}
        style={styleButton}
        size="large"
        id="Filtrer"
        sx={{
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '13px',
          height: '40px',
          '& .MuiButton-startIcon': {
            marginRight: '0px',
          },
        }}
      >
        {!matches && 'Filtrer'}
      </Button>
      <Drawer
        anchor={'right'}
        open={opentDrawer}
        onClose={(event) => toggleDrawer(false, event)}
        PaperProps={{
          sx: {
            top: 120,
            boxShadow:
              'inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 0px rgb(60 64 67 / 15%)',
          },
        }}
        BackdropProps={{ invisible: true }}
        style={{ zIndex: 2 }}
      >
        <Filter
          toggleDrawer={toggleDrawer}
          dateFilterToday={dateFilterToday}
          statusFilter={statusFilter}
          driversFilter={driversFilter}
          salesChannelfilter={salesChannelfilter}
          prestationFilter={prestationFilter}
          agentsCommands={agentsCommands}
          departmentFilter={departmentFilter}
          wareHouseFilter={wareHouseFilter}
          programerDateFilter={programerDateFilter}
          deliveryDateFilter={deliveryDateFilter}
          toursFilter={toursFilter}
          tourListFilter={tourListFilter}
          dateFilterTomorrow={dateFilterTomorrow}
          macroCanalFilter={macroCanalFilter}
          filterReducer={filterReducer}
          setFilter={setFilter}
          setError={setError}
          onMyCommandsChanged={onMyCommandsChanged}
          disableFilter={disableFilter && displayGrid}
          filterStateChanged={filterStateChanged}
          filtersValues={hashValues}
          courseTypeFilter={courseTypeFilter}
          savFilter={savFilter}
          dateFilterCreation={dateFilterCreation}
          BusinessOwnerFilter={BusinessOwnerFilter}
          resetFilter={resetFilter}
          deliveryDateFilterRange={deliveryDateFilterRange}
          DateFilterReception={DateFilterReception}
          ResponseCheck={ResponseCheck}
          ProcessingDepartment={ProcessingDepartment}
          nature={nature}
          subjectFilter={subjectFilter}
          directionFilter={directionFilter}
          verifFilterEqual={verifFilterEqual}
          setHashValues={setHashValues}
          displaySelection={displaySelection}
          displayGrid={displayGrid}
          affResetButton={affResetButton}
          setVerifEqualFilter={setVerifEqualFilter}
          setAffResetButton={setAffResetButton}
          modeCreation={modeCreation}
          setModeCreation={setModeCreation}
          affCreationFilterEnregistrer={affCreationFilterEnregistrer}
          setAffCreationFilterEnregistrer={setAffCreationFilterEnregistrer}
          pageFilter={pageFilter}
          idOngletFilterOpen={idOngletFilterOpen}
          courseLieeFilter={courseLieeFilter}
          saveDefaultFilter={saveDefaultFilter}
          resetDefaultFilter={resetDefaultFilter}
          rdiFilter={rdiFilter}
        />
      </Drawer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={creationInfo}
        autoHideDuration={5000}
        onClose={() => {
          setCreationInfo(false)
        }}
      >
        <Alert
          severity="info"
          sx={{
            backgroundColor: '#1976d2',
            '& .MuiAlert-icon': {
              marginRight: '5px',
              padding: '4px 0',
            },
          }}
        >
          Sélectionner les champs à rechercher
        </Alert>
      </Snackbar>
    </>
  )
}
ButtonFilter.propTypes = {
  dateFilterToday: PropTypes.bool,
  statusFilter: PropTypes.bool,
  driversFilter: PropTypes.bool,
  salesChannelfilter: PropTypes.bool,
  prestationFilter: PropTypes.bool,
  agentsCommands: PropTypes.bool,
  departmentFilter: PropTypes.bool,
  wareHouseFilter: PropTypes.bool,
  programerDateFilter: PropTypes.bool,
  deliveryDateFilter: PropTypes.bool,
  toursFilter: PropTypes.bool,
  tourListFilter: PropTypes.bool,
  dateFilterTomorrow: PropTypes.bool,
  macroCanalFilter: PropTypes.bool,
  filterReducer: PropTypes.object,
  setFilter: PropTypes.func,
  styleButton: PropTypes.object,
  onFilterValuesChanged: PropTypes.func,
  setError: PropTypes.func,
  getWareHouses: PropTypes.func,
  getTourList: PropTypes.func,
  getSalesChannels: PropTypes.func,
  getAllPrestations: PropTypes.func,
  getMacroCanal: PropTypes.func,
  getAllDrivers: PropTypes.func,
  getAllDepartements: PropTypes.func,
  courseTypeFilter: PropTypes.bool,
  savFilter: PropTypes.bool,
  dateFilterCreation: PropTypes.bool,
  BusinessOwnerFilter: PropTypes.bool,
  resetFilter: PropTypes.func,
  isReset: PropTypes.bool,
  initResetFilter: PropTypes.func,
  deliveryDateFilterRange: PropTypes.bool,
  DateFilterReception: PropTypes.bool,
  ResponseCheck: PropTypes.bool,
  getAllSubject: PropTypes.func,
  subjectFilter: PropTypes.array,
  ProcessingDepartment: PropTypes.bool,
  nature: PropTypes.bool,
  pathnameFilter: PropTypes.string,
  directionFilter: PropTypes.bool,
  fromAppliquer: PropTypes.bool,
  resetFromAppliquer: PropTypes.func,
  displaySelection: PropTypes.bool,
  displayGrid: PropTypes.bool,
  defaultFilter: PropTypes.object,
  pageFilter: PropTypes.string,
  idOngletFilterOpen: PropTypes.string,
  courseLieeFilter: PropTypes.bool,
  searchReducer: PropTypes.any,
  saveDefaultFilter: PropTypes.func,
  resetDefaultFilter: PropTypes.func,
  filtersCollaborateur: PropTypes.array,
  rdiFilter: PropTypes.bool,
  getRDVImpoMOtifs: PropTypes.func,
}
