import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

import TableLivraison from '../../../../containers/main/Entrepot/documents/tableLivraison'
import NavFilter from '../../../../containers/main/NavFilter'
import { useHistory } from 'react-router-dom'

const DocumentsEntrepot = ({
  filterReducer,
  setFilter,
  downloadTransfert,
  downloadPreparation,
  downloadBonLivraison,
  resetFilterEntrepot,
  defaultFilterEntrepot,
}) => {
  const history = useHistory()

  return (
    <>
      <NavFilter
        dateFilterTomorrow={true}
        wareHouseFilter={true}
        macroCanalFilter={true}
        filterReducer={filterReducer}
        setFilter={setFilter}
        courseTypeFilter={true}
        resetFilter={resetFilterEntrepot}
        pathnameFilter={history.location.pathname}
        defaultFilter={defaultFilterEntrepot}
        courseLieeFilter={true}
      />
      <div style={{ paddingBottom: '20px' }}>
        <Grid container>
          {/* Tableau Livraison && Buttons */}
          <Grid item xs={12} md={12} sm={12}>
            <TableLivraison
              downloadTransfert={downloadTransfert}
              downloadPreparation={downloadPreparation}
              downloadBonLivraison={downloadBonLivraison}
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}
DocumentsEntrepot.propTypes = {
  filterReducer: PropTypes.object,
  setFilter: PropTypes.func,
  downloadTransfert: PropTypes.func,
  downloadPreparation: PropTypes.func,
  downloadBonLivraison: PropTypes.func,
  resetFilterEntrepot: PropTypes.func,
  defaultFilterEntrepot: PropTypes.object,
}
export default DocumentsEntrepot
