export const getListOrdersTableAutover = () => [
  {
    id: 'createdAt',
    label: 'Date de creation',
    minWidth: 100,
    name: 'Date de creation',
    checked: true,
    sortName: 'dateDeCreation',
    availableInSort: true,
  },
  {
    id: 'pickUpDate',
    label: 'Créneau de départ',
    minWidth: 100,
    name: 'Créneau de départ',
    checked: true,
    sortName: 'pickUpDate',
    availableInSort: false,
  },
  {
    id: 'lettreDeVoiture',
    label: 'LDV',
    minWidth: 35,
    name: 'LDV',
    checked: true,
    sortName: 'lettreDeVoiture',
    availableInSort: false,
  },
  {
    id: 'pickUpAddress',
    label: 'Départ',
    minWidth: 100,
    name: 'Départ',
    checked: true,
    sortName: 'adresseDepart',
    availableInSort: false,
  },
  {
    id: 'deliveryDate',
    label: 'Date de livraison',
    minWidth: 100,
    name: 'Date de livraison',
    checked: true,
    sortName: 'dateDemarrage',
    availableInSort: true,
  },
  {
    id: 'invoice',
    label: 'Référence client',
    minWidth: 30,
    name: 'Référence client',
    checked: true,
    sortName: 'facture',
    availableInSort: false,
  },
  {
    id: 'tour',
    label: 'Tournée',
    minWidth: 20,
    name: 'Tournée',
    checked: true,
    sortName: 'tour',
    availableInSort: false,
  },
  {
    id: 'dropOffClient',
    label: 'Destinataire',
    minWidth: 100,
    name: 'Destinataire',
    checked: true,
    sortName: 'contactArrivee',
    availableInSort: false,
  },
  {
    id: 'dropOffAddress',
    label: 'Arrivée',
    minWidth: 100,
    name: 'Arrivée',
    checked: true,
    sortName: 'adresseArrivee',
    availableInSort: false,
  },
  {
    id: 'driver',
    label: 'Chauffeur',
    minWidth: 100,
    name: 'Chauffeur',
    checked: true,
    sortName: 'chauffeur',
    availableInSort: false,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    name: 'Status',
    checked: true,
    sortName: 'status',
    availableInSort: false,
  },
  {
    id: 'courseType',
    label: 'Type de course',
    minWidth: 100,
    name: 'Type de course',
    checked: true,
    sortName: 'courseType',
    availableInSort: false,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 20,
    name: 'Poids',
    checked: true,
    sortName: 'weight',
    availableInSort: false,
  },
  {
    id: 'packagesNum',
    label: 'Nombre de colis',
    minWidth: 30,
    name: 'Nombre de colis',
    checked: true,
    sortName: 'nombreColis',
    availableInSort: false,
  },
  {
    id: 'vip',
    label: 'VIP',
    minWidth: 35,
    name: 'VIP',
    checked: false,
    sortName: 'vip',
    availableInSort: false,
  },
  {
    id: 'CourseLie',
    label: 'Course associée',
    minWidth: 35,
    name: 'Course associée',
    checked: false,
    availableInSort: false,
  },

  {
    id: 'dropOffDate',
    label: 'Créneau de livraison',
    minWidth: 100,
    name: 'Créneau de livraison',
    checked: false,
    sortName: 'dropOffDate',
    availableInSort: false,
  },

  {
    id: 'dateFinChargement',
    label: 'Fin de chargement',
    minWidth: 100,
    name: 'Fin de chargement',
    checked: false,
    sortName: 'dateFinChargement',
    availableInSort: false,
  },
  {
    id: 'dateLivraison',
    label: 'Fin de livraison',
    minWidth: 100,
    name: 'Fin de livraison',
    checked: false,
    sortName: 'dateLivraison',
    availableInSort: false,
  },
  {
    id: 'earliestDeliveryDate',
    label: 'Date de livraison au plus tot',
    minWidth: 100,
    name: 'Date de livraison au plus tot',
    checked: false,
    sortName: 'earliestDeliveryDate',
    availableInSort: false,
  },
  {
    id: 'latestDeliveryDate',
    label: 'Date de livraison au plus tard',
    minWidth: 100,
    name: 'Date de livraison au plus tard',
    checked: false,
    sortName: 'latestDeliveryDate',
    availableInSort: false,
  },
  {
    id: 'kitchenInstallationDate',
    label: 'Date de pose',
    minWidth: 100,
    name: 'Date de pose',
    checked: false,
    sortName: 'kitchenInstallationDate',
    availableInSort: false,
  },

  {
    id: 'commandeType',
    label: 'Type de commande',
    minWidth: 100,
    name: 'Type de commande',
    checked: false,
    sortName: 'category',
    availableInSort: false,
  },
  {
    id: 'clientPrice',
    label: 'Prix Client',
    minWidth: 50,
    name: 'Prix Client',
    checked: false,
    sortName: 'montantHT',
    availableInSort: false,
  },
  {
    id: 'serviceProviderPrice',
    label: 'Prix Prestataire',
    minWidth: 50,
    name: 'Prix Prestataire',
    checked: false,
    sortName: 'montantPrestataireHT',
    availableInSort: false,
  },
  {
    id: 'paymentState',
    label: 'Etat de Paiement',
    minWidth: 30,
    name: 'Etat de Paiement',
    checked: false,
    sortName: 'etatPaiement',
    availableInSort: false,
  },
  {
    id: 'salesChannel',
    label: 'Canal Vente',
    minWidth: 70,
    name: 'Canal Vente',
    checked: false,
    sortName: 'canalVente',
    availableInSort: false,
  },
  {
    id: 'manutention',
    label: 'Manutention',
    minWidth: 30,
    name: 'Manutention',
    checked: false,
    sortName: 'manutention',
    availableInSort: false,
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 20,
    name: 'Volume',
    checked: false,
    sortName: 'volume',
    availableInSort: false,
  },
  {
    id: 'client',
    label: 'Client',
    minWidth: 50,
    name: 'Client',
    checked: false,
    sortName: 'client',
    availableInSort: false,
  },

  {
    id: 'pickUpClient',
    label: 'Expéditeur',
    minWidth: 100,
    name: 'Expéditeur',
    checked: false,
    sortName: 'contactDepart',
    availableInSort: false,
  },
  {
    id: 'shipmentSource',
    label: 'Course source',
    minWidth: 30,
    name: 'Course source',
    checked: false,
    sortName: 'courseSource',
    availableInSort: false,
  },
  {
    id: 'isPrepared',
    label: 'Pret',
    minWidth: 20,
    name: 'Pret',
    checked: false,
    sortName: 'isPrepared',
    availableInSort: false,
  },

  {
    id: 'observation',
    label: 'Observation',
    minWidth: 100,
    name: 'Observation',
    checked: false,
    sortName: 'observation',
    availableInSort: false,
  },
  {
    id: 'prodOffObservation',
    label: 'Observation Arrivée',
    minWidth: 100,
    name: 'Observation Arrivée',
    checked: false,
    sortName: 'observationArrivee',
    availableInSort: false,
  },
  {
    id: 'vehiculeType',
    label: 'Type de véhicule',
    minWidth: 30,
    name: 'Type de véhicule',
    checked: false,
    sortName: 'vehiculeType',
    availableInSort: false,
  },
  {
    id: 'note',
    label: 'Note',
    minWidth: 100,
    name: 'Note',
    checked: false,
    sortName: 'noteInterne',
    availableInSort: false,
  },
  {
    id: 'assembly',
    label: 'Montage',
    minWidth: 30,
    name: 'Montage',
    checked: false,
    sortName: 'assembly',
    availableInSort: false,
  },
  {
    id: 'prestation',
    label: 'Prestation',
    minWidth: 30,
    name: 'Prestation',
    checked: false,
    sortName: 'prestation',
    availableInSort: false,
  },
]
export const getListOrdersTablePlaris = () => [
  {
    id: 'vip',
    label: 'VIP',
    minWidth: 35,
    name: 'VIP',
    checked: true,
    sortName: 'vip',
    availableInSort: false,
  },
  {
    id: 'lettreDeVoiture',
    label: 'LDV',
    minWidth: 35,
    name: 'LDV',
    checked: true,
    sortName: 'lettreDeVoiture',
    availableInSort: false,
  },
  {
    id: 'CourseLie',
    label: 'Course associée',
    minWidth: 35,
    name: 'Course associée',
    checked: true,
    availableInSort: false,
  },
  {
    id: 'invoice',
    label: 'Référence client',
    minWidth: 30,
    name: 'Référence client',
    checked: true,
    sortName: 'facture',
    availableInSort: false,
  },
  {
    id: 'dropOffClient',
    label: 'Destinataire',
    minWidth: 100,
    name: 'Destinataire',
    checked: true,
    sortName: 'contactArrivee',
    availableInSort: false,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 80,
    name: 'Status',
    checked: true,
    sortName: 'status',
    availableInSort: false,
  },
  {
    id: 'pickUpDate',
    label: 'Créneau de départ',
    minWidth: 100,
    name: 'Créneau de départ',
    checked: true,
    sortName: 'pickUpDate',
    availableInSort: false,
  },
  {
    id: 'dropOffDate',
    label: 'Créneau de livraison',
    minWidth: 100,
    name: 'Créneau de livraison',
    checked: true,
    sortName: 'dropOffDate',
    availableInSort: false,
  },
  {
    id: 'deliveryDate',
    label: 'Date de livraison',
    minWidth: 100,
    name: 'Date de livraison',
    checked: false,
    sortName: 'dateDemarrage',
    availableInSort: true,
  },
  {
    id: 'dateFinChargement',
    label: 'Fin de chargement',
    minWidth: 100,
    name: 'Fin de chargement',
    checked: false,
    sortName: 'dateFinChargement',
    availableInSort: false,
  },
  {
    id: 'dateLivraison',
    label: 'Fin de livraison',
    minWidth: 100,
    name: 'Fin de livraison',
    checked: false,
    sortName: 'dateLivraison',
    availableInSort: false,
  },
  {
    id: 'earliestDeliveryDate',
    label: 'Date de livraison au plus tot',
    minWidth: 100,
    name: 'Date de livraison au plus tot',
    checked: true,
    sortName: 'earliestDeliveryDate',
    availableInSort: false,
  },
  {
    id: 'latestDeliveryDate',
    label: 'Date de livraison au plus tard',
    minWidth: 100,
    name: 'Date de livraison au plus tard',
    checked: true,
    sortName: 'latestDeliveryDate',
    availableInSort: false,
  },
  {
    id: 'kitchenInstallationDate',
    label: 'Date de pose',
    minWidth: 100,
    name: 'Date de pose',
    checked: true,
    sortName: 'kitchenInstallationDate',
    availableInSort: false,
  },
  {
    id: 'courseType',
    label: 'Type de course',
    minWidth: 100,
    name: 'Type de course',
    checked: true,
    sortName: 'courseType',
    availableInSort: false,
  },
  {
    id: 'commandeType',
    label: 'Type de commande',
    minWidth: 100,
    name: 'Type de commande',
    checked: false,
    sortName: 'category',
    availableInSort: false,
  },
  {
    id: 'clientPrice',
    label: 'Prix Client',
    minWidth: 50,
    name: 'Prix Client',
    checked: true,
    sortName: 'montantHT',
    availableInSort: false,
  },
  {
    id: 'serviceProviderPrice',
    label: 'Prix Prestataire',
    minWidth: 50,
    name: 'Prix Prestataire',
    checked: true,
    sortName: 'montantPrestataireHT',
    availableInSort: false,
  },
  {
    id: 'paymentState',
    label: 'Etat de Paiement',
    minWidth: 30,
    name: 'Etat de Paiement',
    checked: true,
    sortName: 'etatPaiement',
    availableInSort: false,
  },
  {
    id: 'salesChannel',
    label: 'Canal Vente',
    minWidth: 70,
    name: 'Canal Vente',
    checked: true,
    sortName: 'canalVente',
    availableInSort: false,
  },
  {
    id: 'pickUpAddress',
    label: 'Départ',
    minWidth: 100,
    name: 'Départ',
    checked: true,
    sortName: 'adresseDepart',
    availableInSort: false,
  },
  {
    id: 'dropOffAddress',
    label: 'Arrivée',
    minWidth: 100,
    name: 'Arrivée',
    checked: true,
    sortName: 'adresseArrivee',
    availableInSort: false,
  },
  {
    id: 'manutention',
    label: 'Manutention',
    minWidth: 30,
    name: 'Manutention',
    checked: true,
    sortName: 'manutention',
    availableInSort: false,
  },
  {
    id: 'driver',
    label: 'Chauffeur',
    minWidth: 100,
    name: 'Chauffeur',
    checked: true,
    sortName: 'chauffeur',
    availableInSort: false,
  },
  {
    id: 'weight',
    label: 'Poids',
    minWidth: 20,
    name: 'Poids',
    checked: true,
    sortName: 'weight',
    availableInSort: false,
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 20,
    name: 'Volume',
    checked: true,
    sortName: 'volume',
    availableInSort: false,
  },
  {
    id: 'client',
    label: 'Client',
    minWidth: 50,
    name: 'Client',
    checked: false,
    sortName: 'client',
    availableInSort: false,
  },

  {
    id: 'pickUpClient',
    label: 'Expéditeur',
    minWidth: 100,
    name: 'Expéditeur',
    checked: false,
    sortName: 'contactDepart',
    availableInSort: false,
  },
  {
    id: 'shipmentSource',
    label: 'Course source',
    minWidth: 30,
    name: 'Course source',
    checked: false,
    sortName: 'courseSource',
    availableInSort: false,
  },
  {
    id: 'createdAt',
    label: 'Date de creation',
    minWidth: 100,
    name: 'Date de creation',
    checked: true,
    sortName: 'dateDeCreation',
    availableInSort: true,
  },

  {
    id: 'isPrepared',
    label: 'Pret',
    minWidth: 20,
    name: 'Pret',
    checked: false,
    sortName: 'isPrepared',
    availableInSort: false,
  },
  {
    id: 'packagesNum',
    label: 'Nombre de colis',
    minWidth: 30,
    name: 'Nombre de colis',
    checked: false,
    sortName: 'nombreColis',
    availableInSort: false,
  },
  {
    id: 'observation',
    label: 'Observation',
    minWidth: 100,
    name: 'Observation',
    checked: false,
    sortName: 'observation',
    availableInSort: false,
  },
  {
    id: 'prodOffObservation',
    label: 'Observation Arrivée',
    minWidth: 100,
    name: 'Observation Arrivée',
    checked: false,
    sortName: 'observationArrivee',
    availableInSort: false,
  },
  {
    id: 'vehiculeType',
    label: 'Type de véhicule',
    minWidth: 30,
    name: 'Type de véhicule',
    checked: false,
    sortName: 'vehiculeType',
    availableInSort: false,
  },
  {
    id: 'note',
    label: 'Note',
    minWidth: 100,
    name: 'Note',
    checked: true,
    sortName: 'noteInterne',
    availableInSort: false,
  },
  {
    id: 'assembly',
    label: 'Montage',
    minWidth: 30,
    name: 'Montage',
    checked: true,
    sortName: 'assembly',
    availableInSort: false,
  },
  {
    id: 'prestation',
    label: 'Prestation',
    minWidth: 30,
    name: 'Prestation',
    checked: false,
    sortName: 'prestation',
    availableInSort: false,
  },
  {
    id: 'tour',
    label: 'Tournée',
    minWidth: 20,
    name: 'Tournée',
    checked: true,
    sortName: 'tour',
    availableInSort: false,
  },
]

export const progActionsOrdersTable = [
  {
    id: 'check',
    label: 'Selectionner',
    minWidth: 100,
    name: 'Selectionner',
    checked: true,
  },
]

export const checkOrdersTable = [
  {
    id: 'checkOrder',
    label: 'Selectionner',
    minWidth: 100,
    name: 'Selectionner',
    checked: true,
  },
]

export const settingsOrdersTable = [
  {
    id: 'settings',
    label: 'Settings',
    minWidth: 100,
    name: 'Settings',
    checked: true,
  },
]

export const actionsOrdersTable = [
  { id: 'print', label: 'Imprimer', minWidth: 30, name: 'Imprimer', checked: true },
  { id: 'cancel', label: 'Annuler', minWidth: 50, name: 'Annuler', checked: true },
  {
    id: 'deprogrammer',
    label: 'Déprogrammer',
    minWidth: 50,
    name: 'Déprogrammer',
    checked: true,
  },
  {
    id: 'dissocier',
    label: 'Dissocier',
    minWidth: 50,
    name: 'Dissocier',
    checked: true,
  },
  { id: 'edit', label: 'Modifier', minWidth: 30, name: 'Modifier', checked: true },
  {
    id: 'prepare',
    label: 'Préparée',
    minWidth: 50,
    name: 'Préparée',
    checked: true,
  },
]
