import { connect } from 'react-redux'
import ErrorBoundary from '../../../components/ErrorBoundary'

import { resetAllFilter } from '../../../redux/filter/actions'

const mapDisptachToProps = (dispatch) => ({
  resetAllFilter: () => dispatch(resetAllFilter()),
})

export default connect(null, mapDisptachToProps)(ErrorBoundary)
