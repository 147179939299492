import React from 'react'
import PropTypes from 'prop-types'
import {
  RETRAIT_IMPOSSIBLE,
  LIVRAISON_IMPOSSIBLE,
  DONE_STATUS,
  ClientDelivery,
} from '../../../../../../utils/values'

import StatusIcon from '../../../../../../assets/icons/detailsLivraison/PhGitMergeFill'
import Grid from '@mui/material/Grid'
import StatusChange from '../../../../../../containers/main/DetailsDelivery/statusChange'
import CircularProgress from '@mui/material/CircularProgress'
import { CardSyled, TitleWithIcon } from '../card/style'

export default function StatusLivraison({ order, isLoading, isLoadingActionOrder }) {
  const getTitre = (label) => {
    return label ? `Motif de ${label}` : 'Motif'
  }
  let isDelivery = ClientDelivery.includes(order?.courseMetadata?.courseType)

  return (
    <>
      {/* 
            status:
                status : true
                Lien traking  : true
                Motif annulation : false
                Tracking url : true
        */}
      <CardSyled>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TitleWithIcon>
            <StatusIcon
              style={{ marginRight: '4px', width: '20px', height: '20px' }}
            />{' '}
            Status
          </TitleWithIcon>
          {isLoadingActionOrder ? (
            <div
              style={{
                marginRight: '15px',
                marginTop: '4px',
              }}
            >
              <CircularProgress
                color="primary"
                style={{ width: '30px', height: '30px' }}
              />
            </div>
          ) : (
            <StatusChange order={order} isLoading={isLoading} />
          )}
        </div>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '20px',
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <Grid container>
            {order?.motifAnnulation && (
              <Grid item xs={12} sm={12} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {"Motif d'annulation"}
                </span>
                <span>{order?.motifAnnulation ? order.motifAnnulation : ' - '}</span>
              </Grid>
            )}
            {order?.logisticStatusFlow &&
              order?.logisticStatusFlow?.length > 0 &&
              order?.logisticStatusFlow.map(
                (cloturerStatus, i) =>
                  cloturerStatus?.codeSitu?.code &&
                  (cloturerStatus?.codeSitu?.code === RETRAIT_IMPOSSIBLE.code ||
                    cloturerStatus?.codeSitu?.code === LIVRAISON_IMPOSSIBLE.code ||
                    cloturerStatus?.codeSitu?.code === DONE_STATUS.code) &&
                  cloturerStatus?.codeJusti?.motif && (
                    <Grid item xs={12} sm={12} sx={{ padding: '8px' }} key={i}>
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          lineHeight: '30px',
                          fontSize: '13px',
                          fontWeight: 600,
                          textDecoration: 'none',
                          color: '#2d2c2c',
                        }}
                      >
                        {getTitre(cloturerStatus?.codeSitu?.label)}
                      </span>
                      <span>{cloturerStatus.codeJusti.motif}</span>

                      {cloturerStatus?.reason && (
                        <div
                          style={{
                            display: 'flex',
                            padding: '9px',
                          }}
                        >
                          <span
                            style={{
                              fontSize: '12px',
                              fontWeight: '550',
                              marginRight: '10px',
                            }}
                          >
                            {'Commentaire: '}
                          </span>
                          <span
                            style={{
                              fontSize: '12px',
                              fontWeight: '450',
                              wordBreak: 'break-word',
                            }}
                          >
                            {cloturerStatus.reason}
                          </span>
                        </div>
                      )}
                    </Grid>
                  )
              )}

            <Grid item xs={12} sm={12} sx={{ padding: '8px' }}>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  lineHeight: '30px',
                  fontSize: '13px',
                  fontWeight: 600,
                  textDecoration: 'none',
                  color: '#2d2c2c',
                }}
              >
                {'Lien de suivi'}{' '}
              </span>
              <span>
                {order?.trackingNumber ? (
                  <a
                    style={{
                      color: '#104687',
                      textDecoration: 'none',
                      overflowWrap: 'break-word',
                    }}
                    href={
                      process.env.REACT_APP_TRACKING_URL +
                      'suivi-de-commande?tracking=b2h_tacking&number=' +
                      order.trackingNumber
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {process.env.REACT_APP_TRACKING_URL +
                      'suivi-de-commande?tracking=b2h_tacking&number=' +
                      order.trackingNumber}
                  </a>
                ) : (
                  ' - '
                )}
              </span>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ padding: '8px' }}>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  lineHeight: '30px',
                  fontSize: '13px',
                  fontWeight: 600,
                  textDecoration: 'none',
                  color: '#2d2c2c',
                }}
              >
                {'Lien tracking externe'}{' '}
              </span>
              <span style={{ wordBreak: 'break-word' }}>
                {order?.externalTrackingUrl ? (
                  <a
                    style={{
                      color: '#104687',
                      textDecoration: 'none',
                      overflowWrap: 'break-word',
                    }}
                    href={order.externalTrackingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {order.externalTrackingUrl}
                  </a>
                ) : (
                  '-'
                )}
              </span>
            </Grid>
            {order?.catalogueURL && !isDelivery && order.courseSource == 'MDM' && (
              <Grid item xs={12} sm={12} sx={{ padding: '8px' }}>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: '30px',
                    fontSize: '13px',
                    fontWeight: 600,
                    textDecoration: 'none',
                    color: '#2d2c2c',
                  }}
                >
                  {'Lien de Catalogue'}{' '}
                </span>
                <span>
                  <a
                    style={{
                      color: '#104687',
                      textDecoration: 'none',
                      overflowWrap: 'break-word',
                    }}
                    href={order.catalogueURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {order.catalogueURL}
                  </a>
                </span>
              </Grid>
            )}
          </Grid>
        )}
      </CardSyled>
    </>
  )
}
StatusLivraison.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  isLoadingActionOrder: PropTypes.bool,
}
