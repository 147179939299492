import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBarcode,
  faQrcode,
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons'

import { LANGUAGE } from '../constants/appContants'
import frLocale from 'date-fns/locale/fr'

export const DAYS_OF_WEEK = Object.freeze([
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
])

export const SELECTED_COURSE_ENUM = {
  MAP: 'MAP',
  TABLE: 'TABLE',
}
export const getPhoneInputCountries = () => {
  const isProd = process.env.REACT_APP_ENV === 'production'
  return isProd ? PHONE_INPUT_COUNTRIES_PROD : PHONE_INPUT_COUNTRIES_PREPROD
}

export const getPhoneInputCountriesCode = () => {
  const isProd = process.env.REACT_APP_ENV === 'production'
  return isProd
    ? PHONE_INPUT_COUNTRIES_CODE_PROD
    : PHONE_INPUT_COUNTRIES_CODE_PREPROD
}

export const PHONE_INPUT_COUNTRIES_PROD = [
  'fr',
  'ch',
  'be',
  'de',
  'es',
  'it',
  'lu',
  'nl',
  'pt',
]
export const PHONE_INPUT_COUNTRIES_PREPROD = [
  'fr',
  'ch',
  'be',
  'de',
  'es',
  'it',
  'lu',
  'nl',
  'pt',
  'tn',
]

export const PHONE_INPUT_COUNTRIES_CODE_PROD = [
  '33',
  '41',
  '32',
  '49',
  '34',
  '39',
  '352',
  '31',
  '351',
]

export const PHONE_INPUT_COUNTRIES_CODE_PREPROD = [
  '33',
  '41',
  '32',
  '49',
  '34',
  '39',
  '352',
  '31',
  '351',
  '216',
]
export const BOX2HOME_PRIMARY_COLOR = '#1976D2'
export const CANCELED_STATUS_LIST = [
  'ABANDONNEE',
  'ANNULEE_JOURJ',
  'ANNULLEE',
  'CANCELED_MP',
  'LIVREUR_NON_TROUVE',
  'ANNULEE_B2H',
  'CANCELED_CENTIRO',
]
export const CANCELED_STATUS_LIST_DESATRIBUER = [
  'ABANDONNEE',
  'ANNULEE_JOURJ',
  'CANCELED_MP',
  'ANNULEE_B2H',
  'CANCELED_CENTIRO',
]
export const TERMINEE_STATUS = {
  code: 'TERMINEE',
  label: 'Terminer',
  markerColor: '#2E8E40',
}
export const RETRAIT_IMPOSSIBLE = {
  code: 'RETRAIT_IMPOSSIBLE',
  label: 'Retrait impossible',
}
export const LIVRAISON_IMPOSSIBLE = {
  code: 'LIVRAISON_IMPOSSIBLE',
  label: 'Livraison impossible',
}
export const CANCELED_STATUS = {
  code: 'ANNULLEE',
  label: 'Annulée',
  codes: CANCELED_STATUS_LIST,
}
export const DONE_STATUS = {
  code: 'TERMINEE',
  label: 'Terminée',
}
export const PROGRAMMED_STATUS = {
  code: 'PROGRAMMEE',
  label: 'Programmée',
  markerColor: '#ce0e0e',
}
export const CREER = {
  code: 'CREER',
  label: 'crée',
  markerColor: '#ce0e0e',
}
export const ASSIGNED_STATUS = {
  code: 'ASSIGNED',
  label: 'Attribué',
  markerColor: '#1E90FF',
}
export const ACCEPTED_STATUS = {
  code: 'ACCEPTEE',
  label: 'Acceptée',
  markerColor: '#FF9400',
}
export const SCHEDULE_WAIT_STATUS = {
  code: 'SCHEDULE_WAIT',
  label: 'Programmation client',
}
export const DECHARGEMENT_EN_COURS_STATUS = {
  code: 'DECHARGEMENT_EN_COURS',
  label: 'Déchargement',
  markerColor: '#006400',
}

export const VERS_DECHARGEMENT_STATUS = {
  code: 'VERS_DECHARGEMENT',
  label: 'En route vers le point de livraison',
  markerColor: '#ff9300',
}
export const ENLEVEE_STATUS = {
  code: 'ENLEVEE',
  label: 'Enlevée',
  markerColor: '#FF9400',
}
export const CHARGEMENT_STATUS = {
  code: 'CHARGEMENT',
  label: 'Enlèvement',
  markerColor: '#ff9300',
}
export const VERS_ENLEVEMENT_STATUS = {
  code: 'VERS_ENLEVEMENT',
  label: 'En route vers le point de retrait ',
  markerColor: '#ff9300',
}

export const SUPPLY_WAIT_STATUS = {
  code: 'SUPPLY_WAIT',
  label: 'Approvisionnement en attente',
}
export const SUPPLY_IN_PROGRESS_STATUS = {
  code: 'SUPPLY_IN_PROGRESS',
  label: 'Approvisionnement en cours',
}
export const SUPPLY_COMPLETE_STATUS = {
  code: 'SUPPLY_COMPLETE',
  label: 'Approvisionnement terminé',
}
export const PENDING_STATUS = {
  code: 'EN_ATTENTE',
  label: 'En attente',
  codes: ['EN_ATTENTE', 'EN_INSTANCE', 'CREER', SCHEDULE_WAIT_STATUS.code],
}

export const ACCEPTEE_CHARGEMENT_DECHARGEMENT_STATUS = {
  code: 'DELIVERY_IN_PROGRESS',
  codes: [
    DECHARGEMENT_EN_COURS_STATUS.code,
    'ACCEPTEE',
    'VERS_ENLEVEMENT',
    'CHARGEMENT',
    'ENLEVEE',
    'VERS_DECHARGEMENT',
  ],
  label: 'En cours de livraison',
}

export const IMPOSSIBLE_DELIVERY_PICKUP_STATUS = {
  code: 'DELIVERY_PICKUP_IMPOSSIBLE',
  codes: ['RETRAIT_IMPOSSIBLE', 'LIVRAISON_IMPOSSIBLE'],
  label: 'Livraison/Retrait impossible',
}

export const STATUS_LABEL_LIST = [
  PROGRAMMED_STATUS,
  ASSIGNED_STATUS,
  ACCEPTEE_CHARGEMENT_DECHARGEMENT_STATUS,
  DONE_STATUS,
  SUPPLY_WAIT_STATUS,
  SUPPLY_IN_PROGRESS_STATUS,
  SUPPLY_COMPLETE_STATUS,
  PENDING_STATUS,
  CANCELED_STATUS,
  IMPOSSIBLE_DELIVERY_PICKUP_STATUS,
]
export const defaultUncheckedStatus = [
  PENDING_STATUS.code,
  CANCELED_STATUS.code,
  IMPOSSIBLE_DELIVERY_PICKUP_STATUS.code,
  SUPPLY_WAIT_STATUS.code,
  SUPPLY_IN_PROGRESS_STATUS.code,
  SUPPLY_COMPLETE_STATUS.code,
]

export const programStatusList = [
  SUPPLY_WAIT_STATUS,
  SUPPLY_IN_PROGRESS_STATUS,
  SUPPLY_COMPLETE_STATUS,
  SCHEDULE_WAIT_STATUS,
  PROGRAMMED_STATUS,
  ASSIGNED_STATUS,
  ACCEPTED_STATUS,
]

export const ORDERS_STATUS = [PROGRAMMED_STATUS.code, ASSIGNED_STATUS.code].concat(
  ACCEPTEE_CHARGEMENT_DECHARGEMENT_STATUS.codes
)
export const THEME_COLORS = {
  main: BOX2HOME_PRIMARY_COLOR,
  RETRAIT_IMPOSSIBLE: '#da281e',
  DECHARGEMENT_EN_COURS: '#ff9350',
  EN_INSTANCE: '#000000',
  CREER: '#000000',
  CANCELED_CENTIRO: '#da281e',
  LIVREUR_NON_TROUVE: '#FF0000',
  ANNULLEE: '#998B74',
  ABANDONNEE: '#0c1e69',
  ANNULEE_B2H: '#5e0a2d',
  CANCELED_MP: '#da281f',
  TERMINEE: '#35BF1D',
  ANNULEE_JOURJ: '#da281f',
  PROGRAMMEE: '#4F4F4F',
  ASSIGNED: '#B0A1BA',
  SCHEDULE_WAIT: '#5fb2eeff',
  ACCEPTEE: '#FF9400',
  VERS_ENLEVEMENT: '#ff9300',
  ENLEVEE: '#FF9400',
  CHARGEMENT: '#FF9400',
  VERS_DECHARGEMENT: '#ff9300',
  SUPPLY_WAIT: '#c6c6c6',
  SUPPLY_IN_PROGRESS: '#ff9600',
  SUPPLY_COMPLETE: '#81ea56',
}
export const getfullName = (person, symbole) =>
  person && person?.firstname && person?.lastname
    ? `${person.firstname} ${person.lastname}`
    : person && person?.firstname
    ? person.firstname
    : person && person?.lastname
    ? person.lastname
    : symbole

export const getfullNameMaj = (person, symbole) =>
  person && person?.firstName && person?.lastName
    ? `${person.firstName} ${person.lastName}`
    : person && person?.firstName
    ? person.firstName
    : person && person?.lastName
    ? person.lastName
    : symbole
export const getName = (firstname, lastname) => {
  return firstname != null && lastname != null
    ? `${firstname} ${lastname}`
    : firstname != null && lastname == null
    ? `${firstname}`
    : firstname == null && lastname != null
    ? `${lastname}`
    : ''
}
export const locale = LANGUAGE ? LANGUAGE.toLowerCase() : 'fr'
export const localeMap = {
  fr: frLocale,
}
export const ASSEMBLY_DEFAULT_SOURCE = {
  label: 'Corner',
  code: 'corner',
}
export const ASSEMBLY_SOURCE = [
  ASSEMBLY_DEFAULT_SOURCE,
  {
    label: 'Service Client',
    code: 'SAV',
  },
  {
    label: 'Equipe montage',
    code: 'assembly-team',
  },
  {
    label: 'Intercom',
    code: 'intercom',
  },
  {
    label: 'Mail',
    code: 'mail',
  },
  {
    label: 'API',
    code: 'api',
  },
  {
    label: 'Autre',
    code: 'other',
  },
]

export const ACCURACY_LEVEL = {
  ROOFTOP: '#104687',
  RANGE_INTERPOLATED: '#f6c342',
  GEOMETRIC_CENTER: '#f79232',
  APPROXIMATE: '#ff0000',
}

export const ACCURATE_ADDRESS = ['ROOFTOP', 'RANGE_INTERPOLATED', 'GEOMETRIC_CENTER']

export const COLLAB_FORM_TYPE_ENUM = {
  SIMULATION: 'Simulation',
  //Insert more types here ...
}

export const ROUTIFIC_ERROR_ENUM = {
  ERR_MISSING_LAT_LNG: {
    code: 'ERR_MISSING_LAT_LNG',
    message: 'Couple latitude/longitude non disponible',
  },
  ERR_MISSING_LAT_LNG_ADDR: {
    code: 'ERR_MISSING_LAT_LNG_ADDR',
    message: 'Couple latitude/longitude non disponible',
  },
  ERR_ORDER_MISSING_DROPOFF_LOC: {
    code: 'ERR_ORDER_MISSING_DROPOFF_LOC',
    message: 'Adresse de dropOff non disponible',
  },
  ERR_ORDER_MISSING_PICKUP_LOC: {
    code: 'ERR_ORDER_MISSING_PICKUP_LOC',
    message: 'Adresse de pickUp non disponible',
  },
  ERR_DROPOFF_EARLIER_PICKUP: {
    code: 'ERR_DROPOFF_EARLIER_PICKUP',
    message: 'Créneau incorrect',
  },
  ERR_INVALID_LAT_LNG_NETWORK: {
    code: 'ERR_INVALID_LAT_LNG_NETWORK',
    message: 'Couple latitude/longitude invalide',
  },
  ERR_MISSING_DRIVER_LAT_LNG: {
    code: 'ERR_MISSING_DRIVER_LAT_LNG',
    message: 'Addresse de chauffeur invalide',
  },
  ERR_NO_TIME_FOR_ORDER: {
    code: 'ERR_NO_TIME_FOR_ORDER',
    message: 'Créneau insuffisant',
  },

  ERR_MULTIPLE_LOCATION_COURSES: {
    code: 'ERR_MULTIPLE_LOCATION_COURSES',
    message: "Les courses fournis n'ont pas le même point de départ",
  },

  ERR_OUT_OF_INTERVALL_COURSES: {
    code: 'ERR_OUT_OF_INTERVALL_COURSES',
    message: 'Tous les courses sont hors de la date prévue',
  },

  ERR_UNKNOWN: {
    code: 'ERR_UNKNOWN',
    message: 'Une erreur s’est produite',
  },
}

export const COURSE_STATUS = {
  ASSIGNED: [
    {
      code: 'PROGRAMMEE',
      label: 'Programmer',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'ACCEPTEE',
      label: 'Accepter',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'ANNULLEE',
      label: 'Annuler',
      color: '#F58311',
      backgroundColor: '#FDE2CB',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
  ],

  PROGRAMMEE: [
    {
      code: 'ASSIGNED',
      label: 'Attribuer',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'ANNULLEE',
      label: 'Annuler',
      color: '#F58311',
      backgroundColor: '#FDE2CB',
    },
  ],

  ACCEPTEE: [
    {
      code: 'VERS_ENLEVEMENT',
      label: 'Vers retrait',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'ANNULLEE',
      label: 'Annuler',
      color: '#F58311',
      backgroundColor: '#FDE2CB',
    },
  ],

  VERS_ENLEVEMENT: [
    {
      code: 'CHARGEMENT',
      label: 'Enlèvement',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'PROGRAMMEE',
      label: 'Programmer',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'ANNULLEE',
      label: 'Annuler',
      color: '#F58311',
      backgroundColor: '#FDE2CB',
    },
  ],
  CHARGEMENT: [
    {
      code: 'ENLEVEE',
      label: 'Enlever',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'PROGRAMMEE',
      label: 'Programmer',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'ANNULLEE',
      label: 'Annuler',
      color: '#F58311',
      backgroundColor: '#FDE2CB',
    },
  ],
  ENLEVEE: [
    {
      code: 'VERS_DECHARGEMENT',
      label: 'Vers livraison',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'PROGRAMMEE',
      label: 'Programmer',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'ANNULLEE',
      label: 'Annuler',
      color: '#F58311',
      backgroundColor: '#FDE2CB',
    },
  ],
  VERS_DECHARGEMENT: [
    {
      code: 'DECHARGEMENT_EN_COURS',
      label: 'Déchargement',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'PROGRAMMEE',
      label: 'Programmer',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'ANNULLEE',
      label: 'Annuler',
      color: '#F58311',
      backgroundColor: '#FDE2CB',
    },
  ],
  DECHARGEMENT_EN_COURS: [
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'PROGRAMMEE',
      label: 'Programmer',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'ANNULLEE',
      label: 'Annuler',
      color: '#F58311',
      backgroundColor: '#FDE2CB',
    },
  ],
  TERMINEE: [
    {
      code: 'ACCEPTEE',
      label: 'Accepter',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'CHARGEMENT',
      label: 'Enlèvement',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'ENLEVEE',
      label: 'Enlever',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'VERS_DECHARGEMENT',
      label: 'Vers livraison',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'DECHARGEMENT_EN_COURS',
      label: 'Déchargement',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
  ],
  LIVRAISON_IMPOSSIBLE: [
    {
      code: 'ACCEPTEE',
      label: 'Accepter',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'CHARGEMENT',
      label: 'Enlèvement',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'ENLEVEE',
      label: 'Enlever',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'VERS_DECHARGEMENT',
      label: 'Vers livraison',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'DECHARGEMENT_EN_COURS',
      label: 'Déchargement',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
  ],
  RETRAIT_IMPOSSIBLE: [
    {
      code: 'ACCEPTEE',
      label: 'Accepter',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'CHARGEMENT',
      label: 'Enlèvement',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'ENLEVEE',
      label: 'Enlever',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'VERS_DECHARGEMENT',
      label: 'Vers livraison',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'DECHARGEMENT_EN_COURS',
      label: 'Déchargement',
      color: '#1876d2',
      backgroundColor: '#d2eafe',
    },
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
  ],
  SUPPLY_COMPLETE: [
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
  ],
  ANNULLEE: [
    {
      code: 'TERMINEE',
      label: 'Terminer',
      color: '#2E8E40',
      backgroundColor: '#CDE4D2',
    },
    {
      code: 'RETRAIT_IMPOSSIBLE',
      label: 'Retrait impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
    {
      code: 'LIVRAISON_IMPOSSIBLE',
      label: 'Livraison impossible',
      color: '#d21818',
      backgroundColor: '#F8DADA',
    },
  ],
}
export const COURSE_STATUS_TERMINEE = [
  {
    code: 'ACCEPTEE',
    label: 'Accepter',
    color: '#1876d2',
    backgroundColor: '#d2eafe',
  },
  {
    code: 'CHARGEMENT',
    label: 'Enlèvement',
    color: '#1876d2',
    backgroundColor: '#d2eafe',
  },
  {
    code: 'RETRAIT_IMPOSSIBLE',
    label: 'Retrait impossible',
    color: '#d21818',
    backgroundColor: '#F8DADA',
  },
  {
    code: 'LIVRAISON_IMPOSSIBLE',
    label: 'Livraison impossible',
    color: '#d21818',
    backgroundColor: '#F8DADA',
  },
]

export const AVAILABLE_EXTENSION = {
  IMAGE: ['jpg', 'jpeg', 'png', 'gif'],
  DOCUMENTS: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
  FILES_XLS: ['xlsx', 'xls', 'csv'],
}
export const SIZE_FILE = 80000000

export const COURSE_TYPE = {
  ClientCollect: 'Emporté dépôt par le consommateur',
  ClientDelivery: 'Livraison chez consommateur',
  StoreDelivery: 'Livraison au magasin',
  ClientPickup: 'Reprise chez le consommateur',
  StorePickUp: 'Reprise au magasin',
  HubDelivery: "Livraison à l'entrepôt",
}

export const ClientDelivery = ['ClientDelivery', 'StoreDelivery', 'HubDelivery']
export const ClientPickup = ['ClientCollect', 'ClientPickup', 'StorePickUp']

export const SUPPLY = [
  'SUPPLY_WAIT',
  'SUPPLY_IN_PROGRESS',
  'SUPPLY_COMPLETE',
  'SCHEDULE_WAIT',
]

export const tourType = {
  MULTI_PICK_UP: 'MULTI_PICK_UP',
  ONE_PICK_UP: 'ONE_PICK_UP',
}
export const IMAGE_SIGNATURE_PHOTO_TYPE = {
  PickUp: 'PickUp',
  DropOff: 'DropOff',
}

export const ETIQUETTE = 'etiquette'
export const LDV = 'ldv'
export const INVOICE = 'facture'
export const INVOICE_ASSEMBLY = 'facture_montage'
export const CATEGORIE_RECHERCHE = [
  { code: 'LDV', label: 'Lettre de voiture' },
  { code: 'FACTURE', label: 'Référence client' },
  { code: 'PartnerOrderNumber', label: 'N° cmd partenaire' },
  { code: 'ContactArrivee', label: 'Destinataire' },
  { code: 'Client', label: 'Donneur d’ordre' },
]

export const STEP_PACKAGE_POINTAGE = [
  { code: 1, label: 'Réception' },
  { code: 2, label: 'Préparation' },
  { code: 3, label: 'Chargement' },
  { code: 4, label: 'Livraison' },
  { code: 5, label: 'Mise en rayon' },
  { code: 6, label: 'Dégroupement réception' },
  { code: 7, label: 'Dégroupement livraisons' },
  { code: 8, label: 'Traction planifié' },
  { code: 9, label: 'Mise en traction' },
  { code: 10, label: 'Retour' },
  { code: 11, label: 'Enlèvement planifié' },
  { code: 12, label: 'Arrivée agence de retour' },
]

export const STATUS_PACKAGE_POINTAGE = [
  { code: 'AR', label: 'Accepté' },
  { code: 'LA', label: 'Accepté avec réserve' },
  { code: 'LR', label: 'Refusé' },
]

export const COMMANDE_TYPE = {
  sav: 'Sav',
  std: 'Standard',
}

export const COURSE_SOURCE_SCHMIDT = 'SCHMIDT'

export const SERVICES_TRAITANT = [
  { code: 'CALL_CENTER', name: 'CALL CENTER' },
  { code: 'EXPLOITATION', name: 'EXPLOITATION' },
  { code: 'SCN', name: 'SCN' },
]
export const NATURE_DISCUSSION = [
  { code: 'QR', name: 'Questions / Réponses' },
  { code: 'RC', name: 'Réclamations' },
  { code: 'FC', name: 'Félicitation' },
]

export const NATURE_DISCUSSION_OBJECT = {
  QR: 'Questions / Réponses',
  RC: 'Réclamations',
  FC: 'Félicitation',
}
export const CORNER_DISCUSSION = 'CORNER'

export const CORNER = 'corner'
export const RDI = 'RDI'
export const MOBILE_DRIVER = 'MOBILE_DRIVER'

export const ENTREPOT = 'ENTREPOT'

export const keyCategory = [
  {
    label: 'Course',
    code: 'Course',
  },
  { label: 'Compétence', code: 'Competence' },
  { label: 'Canal de vente', code: 'SalesChanel' },
  { label: 'Prestation', code: 'Prestation' },
  { label: 'Option', code: 'Option' },
]
export const keyCategoryObject = {
  Course: 'Course',
  Competence: 'Compétence',
  SalesChanel: 'Canal de vente',
  Prestation: 'Prestation',
}

export const ParticipantType = [
  { code: 'COLLAB', label: 'Collaborateur' },
  { code: 'DOCK_AGENT', label: 'Agent de quai' },
]

export const ParticipantTypeObject = {
  DRIVER: 'Chauffeur',
  COLLAB: 'Collaborateur',
  DOCK_AGENT: 'Agent de quai',
}

export const ParticipantRoleObject = {
  DRIVER: 'Chauffeur',
  COLLAB: 'Collaborateur',
  DOCK_AGENT: 'Agent de quai',
}
export const ParticipantRoleType = [
  { code: 'MANAGER', label: 'Responsable' },
  { code: 'DRIVER', label: 'Chauffeur' },
  { code: 'RIPPER', label: 'Rippeur' },
  { code: 'INTERIM', label: 'Intérim' },
  { code: 'STT', label: 'STT' },
]

export const FormationFamily = [
  { code: 'ElectricalAccreditation', label: 'Habilitation électrique' },
  { code: 'WhiteBrown', label: 'Blanc Brun' },
  { code: 'Fitness', label: 'Fitness' },
  { code: 'Reprographie', label: 'Reprographie' },
  { code: 'BeautyMedical', label: 'Beauté/médical' },
  { code: 'BricoGarden', label: 'Brico/jardin' },
  { code: 'Furniture', label: 'Mobilier' },
  { code: 'HeavyHandling', label: 'Manutention lourde' },
]
export const FormationFamilyObject = {
  ElectricalAccreditation: 'Habilitation électrique',
  WhiteBrown: 'Blanc Brun',
  Fitness: 'Fitness',
  Reprographie: 'Reprographie',
  BeautyMedical: 'Beauté/médical',
  BricoGarden: 'Brico/jardin',
  Furniture: 'Mobilier',
  HeavyHandling: 'Manutention lourde',
}

export const FormationDescription = [
  { code: 'Formation', label: 'Formation' },
  { code: 'InformationDissemination', label: 'Diffusion d’information' },
  { code: 'FieldAudit', label: 'Audit terrain' },
  { code: 'SedentaryMonitoring', label: 'Suivi sédentaire' },
]

export const COURSE_TYPE_CHANGE_STATUS = ['ClientPickup', 'StorePickUp']
export const DEPART_ENTREPOT_CHANGE_STATUS = 'DEPART_ENTREPOT'
export const HistoriqueTab = [
  { label: 'Tous', code: null },
  { label: 'Course', code: 'Create/Update Course' },
  { label: 'RDV/RDI', code: 'RDV/RDI' },
  { label: 'Changement statut', code: 'StatusChange' },
  { label: 'Delta cost', code: 'DeltaCost' },
]

export const EmailList = [
  'amani.said@box2home.fr',
  'fakher.mabrouk@box2home.fr',
  'aida.khalfallah@box2home.fr',
  'mohamed.ellili@box2home.fr',
  'sarah.regaieg@box2home.fr',
  'moez.fazzi@box2home.fr',
  'khaoula.miled@box2home.fr',
  'amanisaid@gmail.com',
  'othmen.bedoui@box2home.fr',
  'fatima.bouzidi@box2home.fr',
]

export const filterTab = [
  { label: 'Toutes les courses' },
  { label: 'Courses sélectionnées' },
  { label: 'Erreur adresses' },
]

export const COURSE_STATUS_CLOTURE = [
  {
    code: 'TERMINEE',
    label: 'Terminer',
    color: '#2E8E40',
    backgroundColor: '#CDE4D2',
  },
  {
    code: 'ENLEVEE',
    label: 'Enlever',
    color: '#1876d2',
    backgroundColor: '#d2eafe',
  },
]

export const CATEGORIE_RECHERCHE_DOCUMENT = [
  {
    code: 'ETIQUETTE',
    label: 'Générer étiquette',
    iconSelect: <FontAwesomeIcon icon={faBarcode} />,
    iconDialog: (
      <FontAwesomeIcon
        icon={faBarcode}
        style={{ width: '1.7em', height: '1.7em' }}
      />
    ),
    placeholder: 'Recherche code barre',
  },
  {
    code: 'TOURNEE',
    label: 'Générer tournée',
    iconSelect: <FontAwesomeIcon icon={faFileDownload} />,
    iconDialog: (
      <FontAwesomeIcon
        icon={faFileDownload}
        style={{ width: '1.7em', height: '1.7em' }}
      />
    ),
    placeholder: 'Recherche tournée',
  },
  {
    code: 'QRCODE',
    label: 'Générer QR code',
    iconSelect: <FontAwesomeIcon icon={faQrcode} />,
    iconDialog: (
      <FontAwesomeIcon icon={faQrcode} style={{ width: '1.7em', height: '1.7em' }} />
    ),
    placeholder: 'Recherche QR code',
  },
  {
    code: 'KILOMETRAGE',
    label: 'Générer kilométrage',
    iconSelect: <FontAwesomeIcon icon={faFileDownload} />,
    iconDialog: (
      <FontAwesomeIcon
        icon={faFileDownload}
        style={{ width: '1.7em', height: '1.7em' }}
      />
    ),
    placeholder: 'Recherche tournée',
  },
]

export const VehicleType = [
  { code: 'PL', label: 'Poids lourd' },
  { code: 'VL', label: 'Vehicule leger' },
  { code: 'UL', label: 'Utilitaire leger' },
  { code: 'ELEC', label: 'Electric' },
  { code: 'SR', label: 'Semi-remorque' },
]
export const VehicleTypeObject = {
  PL: 'Poids lourd',
  VL: 'Vehicule leger',
  UL: 'Utilitaire leger',
  ELEC: 'Electric',
  SR: 'Semi-remorque',
}

export const defaultImageParticipant =
  'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown_1-512.png'

export const DAYS_OF_WEEK_OBJECT = [
  { code: 'Monday', label: 'Lundi', day: 0 },
  { code: 'Tuesday', label: 'Mardi', day: 1 },
  { code: 'Wednesday', label: 'Mercredi', day: 2 },
  { code: 'Thursday', label: 'Jeudi', day: 3 },
  { code: 'Friday', label: 'Vendredi', day: 4 },
  { code: 'Saturday', label: 'Samedi', day: 5 },
  { code: 'Sunday', label: 'Dimanche', day: 6 },
]

export const DAYS_OF_WEEK_INDEX = {
  0: { code: 'Monday', label: 'Lundi', day: 0 },
  1: { code: 'Tuesday', label: 'Mardi', day: 1 },
  2: { code: 'Wednesday', label: 'Mercredi', day: 2 },
  3: { code: 'Thursday', label: 'Jeudi', day: 3 },
  4: { code: 'Friday', label: 'Vendredi', day: 4 },
  5: { code: 'Saturday', label: 'Samedi', day: 5 },
  6: { code: 'Sunday', label: 'Dimanche', day: 6 },
}

export const COURSE_PLACEE_MANUELLEMENT = 'course placée manuellement'

export const filterTournee = [
  {
    label: 'Toutes les tournées',
    title: 'Tournées',
    code: 'tournees',
    filterAff: [
      'tourneesSelect',
      'tourneesVide',
      'tourneesNonVide',
      'tourneesDecroissant',
    ],
  },
  {
    label: 'Sélectionnées',
    title: 'Tournées sélectionnées',
    code: 'tourneesSelect',
    filterAff: [
      'tournees',
      'tourneesVide',
      'tourneesNonVide',
      'tourneesCroissant',
      'tourneesDecroissant',
    ],
  },
  {
    label: 'Non plannifié',
    title: 'Tournées vide',
    code: 'tourneesVide',
    filterAff: [
      'tournees',
      'tourneesSelect',
      'tourneesNonVide',
      'tourneesCroissant',
      'tourneesDecroissant',
    ],
  },
  {
    label: 'Plannifié',
    title: 'Tournées non vide',
    code: 'tourneesNonVide',
    filterAff: [
      'tournees',
      'tourneesSelect',
      'tourneesVide',
      'tourneesCroissant',
      'tourneesDecroissant',
    ],
  },
  {
    label: 'Ordre croissant',
    title: 'Tournées croissant',
    code: 'tourneesCroissant',
    filterAff: [
      'tournees',
      'tourneesSelect',
      'tourneesVide',
      'tourneesNonVide',
      'tourneesDecroissant',
    ],
  },
  {
    label: 'Ordre décroissant',
    title: 'Tournées décroissant',
    code: 'tourneesDecroissant',
    filterAff: [
      'tournees',
      'tourneesSelect',
      'tourneesVide',
      'tourneesNonVide',
      'tourneesCroissant',
    ],
  },
]
