import {
  FETCH_DRIVERS,
  SEARCH_DRIVER,
  OPTIMISER_DRIVER_GET,
  GET_DRIVER_WARRNING,
  GET_DRIVER_WARRNING_MORE,
} from './actionsConsts'

export const fetchDriversOptimiser = (filter) => ({
  type: OPTIMISER_DRIVER_GET,
  payload: filter,
})

export const fetchtDrivers = (activeOnly) => ({
  type: FETCH_DRIVERS,
  payload: { activeOnly },
})

export const searchDriver = (query, actif) => ({
  type: SEARCH_DRIVER,
  payload: { query, actif },
})

export const fetchDriversWarrning = (filter) => ({
  type: GET_DRIVER_WARRNING,
  payload: filter,
})

export const fetchDriversWarrningMoreScroll = (filter) => ({
  type: GET_DRIVER_WARRNING_MORE,
  payload: filter,
})
