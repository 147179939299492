import {
  FETCH_CANAL_VENTES,
  FETCH_CANAL_VENTES_MORE,
  FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES,
  REMOVE_ERROR_DISPONIBILITES,
  CREATE_DISPONIBILITES,
  UPDATE_DISPONIBILITES,
  RESET_SUCCESS_ACTION,
} from './constants'

export const fetchCanalVentes = (filter) => ({
  type: FETCH_CANAL_VENTES,
  payload: filter,
})

export const fetchMoreCanalVentes = (filter) => ({
  type: FETCH_CANAL_VENTES_MORE,
  payload: filter,
})

export const fetchCanalVenteDetailsDisponibilites = (dataSend) => ({
  type: FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES,
  payload: dataSend,
})

export const removeErrorDisponibilites = () => ({
  type: REMOVE_ERROR_DISPONIBILITES,
})

export const createDisponibilite = (disponibilite) => ({
  type: CREATE_DISPONIBILITES,
  payload: disponibilite,
})

export const updateDisponibilite = (newDisponibilite) => ({
  type: UPDATE_DISPONIBILITES,
  payload: newDisponibilite,
})

export const resetSuccessAction = () => ({
  type: RESET_SUCCESS_ACTION,
})
