import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import ListConverstion from './listConversation'
import Chatroom from './chatroom'

import { EVENT_SOCKET } from '../../../../../../utils/discussion/values'
import Error from '../../../../../../components/Modal/HttpErrorPopUp'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import { uuid } from 'uuidv4'
import { CORNER_DISCUSSION, ENTREPOT } from '../../../../../../utils/values'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

const FilDiscussion = ({
  conversations,
  isLoadingGetConversations,
  getEntryConversationDeliveryAction,
  getMessageByConversationIdAction,
  isLoadingGetMessageList,
  messages,
  cleaningMessageList,
  issocketNotRegistred,
  focus,
  notFocus,
  varFocus,
  getMoreEntryConversationDeliveryAction,
  count,
  isLoadingConversationsMore,
  getConversationDeliverySocket,
  removeListenerSocket,
  getMessageSocket,
  sendMessage,
  loadingSendMessage,
  seenMessage,
  onUploadfileDiscussion,
  loadingUploadFile,
  errorUploadFile, //Afficher Message d'erreur (booleen)
  filesUpload,
  removeFileDiscussion,
  downloadFile,
  setLoadingUploadFile,
  setErrorUploadFile,
  errorMessageUploadFile,
  order,
  deleteFile,
  createConversationSocket,
  removeIdConversationUUID,
  removeMessageNewConversation,
  removeIdConversation,
  idConversation,
  getClientsPro,
  getCollaborateurs,
  isLoadingGetCollaborateur,
  isLoadingGetClientsPro,
  agentsDiscussion,
  isLoading,
  getSubjectConversation,
  subjectConversation,
  isLoadingSubjectConversation,
  CloseOpenConversation,
  loadingOpenCloseConversation,
  setLoadingOpenCloseConversation,
  isLoadingPackageOrder,
  warehousePackage,
}) => {
  const params = useParams()
  const [connectedUser] = useState(JSON.parse(localStorage.getItem('currentUser')))
  const [conversation, setOpenedConversation] = useState({})
  const [offset, setOffset] = useState(0)
  const [open, setOpen] = useState(false)
  const [newConversation, setNewConversation] = useState(false)
  const [value, setValueNature] = useState('')

  const handleChange = (event) => {
    setValueNature(event.target.value)
  }

  useEffect(() => {
    if (conversation && Object.keys(conversation)?.length > 0) {
      const newConversation = conversations.find(
        (conv) => conv.id === conversation.id
      )
      if (Object.keys(newConversation)?.length > 0)
        setOpenedConversation({
          ...conversation,
          isOpen: newConversation.isOpen,
        })
    }
  }, [conversations])

  useEffect(() => {
    if (!issocketNotRegistred) {
      getConversationDeliverySocket(params.ldv)
    }
  }, [issocketNotRegistred])

  useEffect(() => {
    if (
      idConversation != null &&
      conversation?.id &&
      conversation.id === 'NewConversation'
    ) {
      setOpenedConversation({
        ...conversation,
        id: idConversation,
      })
    }
  }, [idConversation])
  useEffect(() => {
    if (order?.code) {
      const data = {
        codeCourse: [order.code],
        isInternal: true,
        offset: 0,
        max: 4,
        source: CORNER_DISCUSSION,
        legacyID: `${connectedUser?.id}`,
      }
      setOffset(4)
      getEntryConversationDeliveryAction(data)
    }
    return () => {
      cleaningMessageList()
      removeListenerSocket(conversation.id)
      setOpenedConversation({})
      removeIdConversationUUID()
      removeMessageNewConversation()
      removeIdConversation()
      setLoadingOpenCloseConversation(false)
    }
  }, [order])
  useEffect(() => {
    if (order?.code) {
      getClientsPro(order?.commande?.canalVente?.code)
      getCollaborateurs(order?.code)
      getSubjectConversation()
    }
  }, [order])

  const getMoreConversation = () => {
    const data = {
      codeCourse: [order.code],
      isInternal: true,
      offset: offset,
      max: 4,
      source: CORNER_DISCUSSION,
      legacyID: `${connectedUser?.id}`,
    }
    setOffset(offset + 4)

    getMoreEntryConversationDeliveryAction(data)
  }
  const onClickConversation = (Conversation) => {
    removeListenerSocket(conversation.id)
    setOpenedConversation(Conversation)

    getMessageByConversationIdAction(Conversation.id, connectedUser.id)
    getMessageSocket(Conversation.id)
  }

  const onRetour = () => {
    cleaningMessageList()
    setNewConversation(false)
    removeListenerSocket(conversation.id)
    removeIdConversationUUID()
    removeMessageNewConversation()
    removeIdConversation()
    setLoadingOpenCloseConversation(false)
    setValueNature('')
  }

  const pushMessage = (message, files) => {
    const entry = {
      type: EVENT_SOCKET.TEXT_EVENT,
      content: message,
      confidentiality: 'PUBLIC',
      conversationID: conversation.id,
      attachements: files.length > 0 ? files : null,
    }
    sendMessage(entry, connectedUser)
  }

  const onErrorClose = () => {
    setOpen(false)
    const error = { isError: false, message: ' ' }
    setErrorUploadFile(error)
  }

  useEffect(() => {
    if (errorUploadFile) setOpen(true)
  }, [errorUploadFile])

  const createConversation = (sujet, nature) => {
    const conversation = {
      subject: sujet.label,
      direction: ENTREPOT,
      serviceTraitant: sujet.serviceTraitant,
      salesChannel: {
        codeCanal: order.commande.canalVente.code,
        name: order.commande.canalVente.name,
      },
      ldv: order.lettreDeVoiture.code,
      codeCourse: order.code,
      id: 'NewConversation',
      key: uuid(),
      warehouse: warehousePackage,
      nature: nature,
    }
    setOpenedConversation(conversation)
    setNewConversation(true)
  }
  const createConversationEvent = (message, files) => {
    const entrySendMessage = {
      entry: {
        type: EVENT_SOCKET.TEXT_EVENT,
        content: message,
        confidentiality: 'PUBLIC',
        conversationID: '',
        attachements: files.length > 0 ? files : null,
      },
      connectedUser,
    }
    const entryCreateConversation = {
      ldv: order?.lettreDeVoiture?.code,
      subject: conversation?.subject,
      serviceTraitant: conversation?.serviceTraitant,
      direction: ENTREPOT,
      codeCourse: order?.code,
      salesChannel: conversation?.salesChannel,
      key: conversation.key,
      from: {
        legacyID: String(connectedUser.id),
        photoIdentity:
          connectedUser.photoIdentity ||
          'https://cdn0.iconfinder.com/data/icons/user-pictures/100/unknown_1-512.png',
        firstName: connectedUser.firstname,
        name: connectedUser.lastname,
        email: connectedUser.login,
        type: 'INTERNAL',
      },
      to: agentsDiscussion,
      warehouse: conversation?.warehouse,
      nature: conversation?.nature,
    }
    createConversationSocket(
      entryCreateConversation,
      entrySendMessage,
      conversation.key
    )
  }

  //close conversation
  const closeConversation = () => {
    const entry = {
      type: EVENT_SOCKET.CLOSE_EVENT,
      content: 'Cette discussion est clôturée',
      conversationID: conversation.id,
    }
    CloseOpenConversation(entry, connectedUser)
  }
  // Open COnversation
  const openConversation = () => {
    const entry = {
      type: EVENT_SOCKET.OPEN_EVENT,
      content: 'Cette discussion est rouverte',
      conversationID: conversation.id,
    }
    CloseOpenConversation(entry, connectedUser)
  }
  // remove Listener get message from socket
  useEffect(() => {
    return () => {
      removeListenerSocket(conversation.id)
    }
  }, [conversation?.id])
  return (
    <>
      {errorUploadFile && (
        <Error
          statusText={
            errorMessageUploadFile ||
            "une erreur s'est produite merci d'actualiser la page"
          }
          open={open}
          setOpen={onErrorClose}
        />
      )}
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0 1px 7px 0 rgb(0 0 0 / 10%)',
          borderRadius: '5px',
          padding: '14px 0px 8px 8px',
          margin: '10px 0',
          overflow: 'hidden',
        }}
      >
        {isLoadingGetMessageList ||
        (messages.length > 0 && conversation?.id) ||
        newConversation ? (
          <Chatroom
            isLoadingGetMessageList={isLoadingGetMessageList}
            loadingSendMessage={loadingSendMessage}
            messages={messages}
            conversations={conversations}
            conversationOpen={conversation}
            subject={conversation?.subject ? conversation.subject : 'Discussion'}
            cleaningMessageList={onRetour}
            connectedUser={connectedUser}
            pushMessage={pushMessage}
            focus={focus}
            notFocus={notFocus}
            varFocus={varFocus}
            seenMessage={seenMessage}
            onUploadfileDiscussion={onUploadfileDiscussion}
            loadingUploadFile={loadingUploadFile}
            errorUploadFile={errorUploadFile}
            filesUpload={filesUpload}
            removeFileDiscussion={removeFileDiscussion}
            downloadFile={downloadFile}
            setLoadingUploadFile={setLoadingUploadFile}
            setErrorUploadFile={setErrorUploadFile}
            deleteFile={deleteFile}
            newConversation={newConversation}
            createConversationEvent={createConversationEvent}
            setNewConversation={setNewConversation}
            closeConversation={closeConversation}
            openConversation={openConversation}
            loadingCloseConversation={loadingOpenCloseConversation}
            loadingOpenConversation={loadingOpenCloseConversation}
            nature={conversation?.nature}
          />
        ) : (
          <>
            <ListConverstion
              isLoadingGetConversations={isLoadingGetConversations || isLoading}
              conversations={conversations}
              onClickConversation={onClickConversation}
              count={count}
              offset={offset}
              isLoadingConversationsMore={isLoadingConversationsMore}
              getMoreConversation={getMoreConversation}
            />
            <div
              style={{
                height: '200px',
                borderRadius: '5px',
                padding: '20px',
                margin: '40px 10px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 3px #00000029',
                opacity: 1,
              }}
            >
              <div
                style={{
                  fontSize: '16px',
                }}
              >
                Démarrer une conversation
              </div>
              <div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                    row={true}
                  >
                    <FormControlLabel
                      value="QR"
                      control={<Radio color="primary" />}
                      label="Questions / Réponses"
                    />
                    <FormControlLabel
                      value="RC"
                      control={<Radio color="primary" />}
                      label="Réclamations"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div
                style={{
                  background: ' #FFFFFF 0% 0% no-repeat padding-box',
                  border: '1px solid #F2F2F2',
                  borderRadius: '5px',
                  margin: '10px 0px',
                }}
              >
                <TextField
                  id="level-shipment"
                  select
                  name="originLevel"
                  label="Séléctionnez un sujet"
                  onChange={(e) => createConversation(e.target.value, value)}
                  disabled={
                    isLoadingGetCollaborateur ||
                    isLoadingPackageOrder ||
                    isLoadingGetClientsPro ||
                    isLoading ||
                    isLoadingSubjectConversation ||
                    subjectConversation.length == 0 ||
                    value == ''
                  }
                  SelectProps={{
                    MenuProps: {
                      style: { height: 288 },
                    },
                  }}
                >
                  {subjectConversation
                    .filter((subject) => subject.nature === value)
                    .map((h, index) => (
                      <MenuItem value={h} key={index}>
                        {h.label}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

FilDiscussion.propTypes = {
  conversations: PropTypes.array,
  isLoadingGetConversations: PropTypes.bool,
  getEntryConversationDeliveryAction: PropTypes.func,
  getMessageByConversationIdAction: PropTypes.func,
  messages: PropTypes.array,
  isLoadingGetMessageList: PropTypes.bool,
  cleaningMessageList: PropTypes.func,
  socketNotRegistred: PropTypes.func,
  issocketNotRegistred: PropTypes.bool,
  receiveDataFromMessagesTopicAction: PropTypes.func,
  focus: PropTypes.func,
  notFocus: PropTypes.func,
  varFocus: PropTypes.bool,
  getMoreEntryConversationDeliveryAction: PropTypes.func,
  count: PropTypes.number,
  isLoadingConversationsMore: PropTypes.bool,
  registerSocket: PropTypes.func,
  getConversationDeliverySocket: PropTypes.func,
  removeListenerSocket: PropTypes.func,
  getMessageSocket: PropTypes.func,
  sendMessage: PropTypes.func,
  loadingSendMessage: PropTypes.bool,
  seenMessage: PropTypes.func,
  onUploadfileDiscussion: PropTypes.func,
  loadingUploadFile: PropTypes.bool,
  errorUploadFile: PropTypes.any,
  filesUpload: PropTypes.any,
  removeFileDiscussion: PropTypes.func,
  downloadFile: PropTypes.func,
  errorMessageUploadFile: PropTypes.string,
  setLoadingUploadFile: PropTypes.func,
  setErrorUploadFile: PropTypes.func,
  order: PropTypes.object,
  deleteFile: PropTypes.func,
  createConversationSocket: PropTypes.func,
  removeIdConversationUUID: PropTypes.func,
  removeMessageNewConversation: PropTypes.func,
  removeIdConversation: PropTypes.func,
  idConversation: PropTypes.string,
  getClientsPro: PropTypes.func,
  getCollaborateurs: PropTypes.func,
  isLoadingGetCollaborateur: PropTypes.bool,
  isLoadingGetClientsPro: PropTypes.bool,
  agentsDiscussion: PropTypes.array,
  isLoading: PropTypes.bool,
  getSubjectConversation: PropTypes.func,
  subjectConversation: PropTypes.array,
  isLoadingSubjectConversation: PropTypes.bool,
  CloseOpenConversation: PropTypes.func,
  loadingOpenCloseConversation: PropTypes.bool,
  setLoadingOpenCloseConversation: PropTypes.func,
  warehousePackage: PropTypes.array,
  isLoadingPackageOrder: PropTypes.bool,
}

export default FilDiscussion
