import { insetObjectTable } from '../../utils/utils'
import {
  GET_FORMATIONS,
  GET_FORMATIONS_SUCCESS,
  GET_FORMATIONS_FAILURE,
  PAGE_CHANGED,
  PAGE_LIMIT_CHANGED,
  REMOVE_ERROR,
  RESET_SUCCESS_ACTION,
  RESET_CONFIG,
  SEARCH_FORMATIONS,
  ADD_FORMATION,
  ADD_FORMATION_FAILURE,
  ADD_FORMATION_SUCCESS,
  UPDATE_FORMATION,
  UPDATE_FORMATION_SUCCESS,
  UPDATE_FORMATION_FAILURE,
  ARCHIVAGE_FORMATION,
  ARCHIVAGE_FORMATION_SUCCESS,
  ARCHIVAGE_FORMATION_FAILURE,
  SET_ERROR_MESSAGE,
  GET_FILES_BY_ID_SUCCESS,
  GET_FILES_BY_ID,
  GET_FILES_BY_ID_FAILURE,
  GET_FORMATION_BY_ID_FAILURE,
  GET_FORMATION_BY_ID_SUCCESS,
  GET_FORMATION_BY_ID,
  GET_FILE_BY_URL,
  GET_FORMATIONS_MORE_FAILURE,
  GET_FORMATIONS_MORE_SUCCESS,
  GET_FORMATIONS_MORE,
} from './constants'
import initialState from './initialState'

export default (state = initialState(), action) => {
  if (action.type === GET_FORMATIONS) {
    return {
      ...state,
      formations: [],
      isLoadingFormationsList: true,
    }
  }

  if (action.type === GET_FORMATIONS_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      formations: data,
      isLoadingFormationsList: false,
      formationsCount: count,
    }
  }

  if (action.type === GET_FORMATIONS_FAILURE) {
    const { response } = action.payload?.data

    return {
      ...state,
      isLoadingFormationsList: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetch: true,
    }
  }

  if (action.type === GET_FORMATIONS_MORE) {
    return {
      ...state,
      isLoadingFormationsList: true,
    }
  }
  if (action.type === GET_FORMATIONS_MORE_SUCCESS) {
    return {
      ...state,
      formations: [...state.formations, ...action.payload?.data?.data],
      formationsCount: action.payload.data?.count || 0,
    }
  }

  if (action.type === GET_FORMATIONS_MORE_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === PAGE_CHANGED) {
    const { offset } = action.payload
    const filter = { ...state.filter }
    filter.offset = offset
    return {
      ...state,
      filter,
    }
  }

  if (action.type === PAGE_LIMIT_CHANGED) {
    const { limit } = action.payload
    const filter = { ...state.filter }
    filter.limit = limit
    filter.offset = 0
    return {
      ...state,
      filter,
    }
  }

  if (action.type === REMOVE_ERROR) {
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
    }
  }

  if (action.type === RESET_SUCCESS_ACTION) {
    return {
      ...state,
      success: false,
      isSuccessRefresh: false,
    }
  }

  if (action.type === RESET_CONFIG) {
    //rest
    return {
      ...state,
      errorMessage: null,
      isErrorFetch: false,
      isLoadingFormationAction: false,
      success: false,
      isSuccessRefresh: false,
      files: [],
      filter: {
        limit: 25,
        offset: 0,
      },
    }
  }

  if (action.type === SEARCH_FORMATIONS) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        ...state.filter,
        query: search,
      },
    }
  }

  if (action.type === ADD_FORMATION) {
    return {
      ...state,
      isLoadingFormationAction: true,
    }
  }
  if (action.type === ADD_FORMATION_SUCCESS) {
    return {
      ...state,
      isLoadingFormationAction: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === ADD_FORMATION_FAILURE) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoadingFormationAction: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === UPDATE_FORMATION) {
    return {
      ...state,
      isLoadingFormationAction: true,
    }
  }
  if (action.type === UPDATE_FORMATION_SUCCESS) {
    const { data } = action.payload
    const formationIndex = state.formations.map((el) => el.id).indexOf(data.id)
    return {
      ...state,
      formations: [...insetObjectTable(state.formations, data, formationIndex)],
      isLoadingFormationAction: false,
      success: true,
    }
  }

  if (action.type === UPDATE_FORMATION_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingFormationAction: false,
      success: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === ARCHIVAGE_FORMATION) {
    return {
      ...state,
      isLoadingFormationAction: true,
    }
  }
  if (action.type === ARCHIVAGE_FORMATION_SUCCESS) {
    return {
      ...state,
      isLoadingFormationAction: false,
      success: true,
      isSuccessRefresh: true,
    }
  }

  if (action.type === ARCHIVAGE_FORMATION_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingFormationAction: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === SET_ERROR_MESSAGE) {
    const { message } = action.payload
    return {
      ...state,
      errorMessage: message,
      isErrorFetch: false,
    }
  }
  if (action.type === GET_FILES_BY_ID) {
    return {
      ...state,
      files: [],
      isLoadingFiles: true,
    }
  }

  if (action.type === GET_FILES_BY_ID_SUCCESS) {
    const { data } = action.payload
    return {
      ...state,
      files: data,
      isLoadingFiles: false,
    }
  }

  if (action.type === GET_FILES_BY_ID_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingFiles: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === GET_FORMATION_BY_ID) {
    return {
      ...state,
      formation: {},
      isLoadingFormation: true,
    }
  }

  if (action.type === GET_FORMATION_BY_ID_SUCCESS) {
    const { data } = action.payload
    return {
      ...state,
      formation: data,
      isLoadingFormation: false,
    }
  }

  if (action.type === GET_FORMATION_BY_ID_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingFormation: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === GET_FILE_BY_URL) {
    return {
      ...state,
      fileByUrl: null,
      isLoadingFilesByUrl: true,
    }
  }

  return state
}
