import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import ListItem from '@mui/material/ListItem'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'

export const StyledTableRow = styled(TableRow)({
  '&.Mui-selected': {
    backgroundColor: '#d6e5f9',
  },
})

export const StyledRootToolbar = styled(Toolbar)({
  paddingLeft: '16px',
  paddingRight: '20px',
  background: '#1976d2',
  color: '#fff',
  height: '64px',
  maxHeight: '10%',
})
export const StyledBlocButton = styled(Button)({
  '&.MuiButton-root': {
    width: '90px',
    backgroundColor: 'white',
    color: '#1976D2',
    '&:hover': {
      width: '90px',
      backgroundColor: 'white',
      color: '#1976D2',
    },
  },
})
export const StyledListItemActions = styled(ListItem)({
  flex: '1 1 auto',

  '&:hover': {
    backgroundColor: '#E3EFFA',
    color: '#1876D2',
    '& .IconListItemActionDetails': {
      color: '#1876D2',
    },
  },
})
