export const GET_RECOMMENDED_CRENEAU = 'GET_RECOMMENDED_CRENEAU'
export const GET_RECOMMENDED_CRENEAU_SUCCESS = 'GET_RECOMMENDED_CRENEAU_SUCCESS'
export const GET_RECOMMENDED_CRENEAU_FAILURE = 'GET_RECOMMENDED_CRENEAU_FAILURE'

export const GET_CRENEAU_RECOMMENDED_DISPONIBILITES =
  'GET_CRENEAU_RECOMMENDED_DISPONIBILITES'

export const GET_CRENEAU_RECOMMENDED_DISPONIBILITES_SUCCESS =
  'GET_CRENEAU_RECOMMENDED_DISPONIBILITES_SUCCESS'
export const getRecommendedCreneau = (date, codeCanal) => ({
  type: GET_RECOMMENDED_CRENEAU,
  payload: { date, codeCanal },
})

export const getCreneauRecommendedDisponibilites = (
  date,
  serviceTime,
  channelKey,
  zipCode
) => ({
  type: GET_CRENEAU_RECOMMENDED_DISPONIBILITES,
  payload: { date, serviceTime, channelKey, zipCode },
})
