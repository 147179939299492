import { connect } from 'react-redux'
import ModificationFilter from '../../../components/NavFilter/components/Filter/modificationFilter'

import {
  onModifierFilter,
  resetActionModifierFilter,
} from '../../../redux/filter/actions'
import {
  getIsLoadingActionFilter,
  getErrorActionFilter,
  getIsActionModifierFilter,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionFilter: getIsLoadingActionFilter(state),
  errorActionFilter: getErrorActionFilter(state),
  isActionModifierFilter: getIsActionModifierFilter(state),
})

const mapDisptachToProps = (dispatch) => ({
  onModifierFilter: (payload) => dispatch(onModifierFilter(payload)),
  resetActionModifierFilter: () => dispatch(resetActionModifierFilter()),
})

export default connect(mapStateToProps, mapDisptachToProps)(ModificationFilter)
