export const googleMapDirectionConstants = {
  GET_REQUEST: 'GOOLE_MAP_DIRECTION_GET_REQUEST',
  GET_SUCCESS: 'GOOLE_MAP_DIRECTION_GET_SUCCESS',
  GET_ERROR: 'GOOLE_MAP_DIRECTION_GET_ERROR',
  FIND_DIRECTION: 'GOOLE_MAP_DIRECTION_FIND_DIRECTION',
  FIND_DIRECTION_SUCCESS: 'GOOLE_MAP_DIRECTION_FIND_DIRECTION_SUCCESS',
  FIND_DIRECTION_ERROR: 'GOOLE_MAP_DIRECTION_FIND_DIRECTION_ERROR',
  SET_LIST: 'GOOLE_MAP_DIRECTION_SET_LIST',
  GET_LIST: 'GOOLE_MAP_DIRECTION_GET_LIST',
  RESET_DIRECTION: 'RESET_DIRECTION',
}
export const CALCULATE_DIRECTION = 'CALCULATE_DIRECTION'
export const DISTANCE_CALCULATED = 'DISTANCE_CALCULATED'
export const LOAD_SCRIPT_SUCCESS = 'GOOLE_MAP_API_LOAD_SCRIPT_SUCCESS'
export const LOAD_SCRIPT_ERROR = 'GOOLE_MAP_API_LOAD_SCRIPT_ERROR'
