/* eslint-disable no-undef */
import { CALCULATE_DIRECTION, googleMapDirectionConstants } from './constants'
import {
  getGoogleMapDirectionSuccess,
  getGoogleMapDirectionError,
  findDirectionSuccess,
  getGoogleMapDirectionRequest,
  distanceCalculated,
} from './actions'

const googleMapDirectionMiddleware = (store) => (next) => (action) => {
  if (action.type === googleMapDirectionConstants.GET_REQUEST) {
    const DirectionsService = new google.maps.DirectionsService()
    console.log('action.payload', action.payload)
    DirectionsService.route(
      {
        origin: action.payload.origin,
        destination: action.payload.destination,
        travelMode: google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          if (result && result.routes && result.routes.length > 0) {
            const routesData = result.routes.map((r, i) => {
              r['index'] = i
              return r
            })
            const routes = routesData.sort(
              (r1, r2) => r1.legs[0].distance.value - r2.legs[0].distance.value
            )
            const directionDate = {
              id: action.payload.id,
              origin: action.payload.origin,
              destination: action.payload.destination,
              selectedDirection: {
                distance: routes[0].legs[0].distance.value / 1000,
                duration: routes[0].legs[0].duration.value,
                index: routes[0].index,
              },
              directions: result,
            }
            store.dispatch(getGoogleMapDirectionSuccess(directionDate))
          } else {
            store.dispatch(getGoogleMapDirectionError())
          }
        } else {
          store.dispatch(getGoogleMapDirectionError())
          console.error(`*** error DirectionsService *** ${result}`)
        }
      }
    )
  }
  if (action.type === googleMapDirectionConstants.FIND_DIRECTION) {
    const {
      googleMapDirection: { directionRouteList },
    } = store.getState()

    let direction = directionRouteList.find(
      (d) =>
        _.isEqual(d.origin, action.payload.origin) &&
        _.isEqual(d.destination, action.payload.destination)
    )
    if (direction) {
      const directionData = {
        id: action.payload.id,
        origin: action.payload.origin,
        destination: action.payload.destination,
        selectedDirection: { ...direction.selectedDirection },
        directions: { ...direction.directions },
      }
      store.dispatch(findDirectionSuccess(directionData))
    } else {
      store.dispatch(
        getGoogleMapDirectionRequest(
          action.payload.id,
          action.payload.origin,
          action.payload.destination
        )
      )
    }
  }
  if (action.type === CALCULATE_DIRECTION) {
    console.log('action.payload', action.payload)
    const { origin, destination } = action.payload
    const origin1 = new google.maps.LatLng(origin.latlng.lat, origin.latlng.lng)
    const origin2 = origin.formattedAddress
    const destinationA = destination.formattedAddress
    const destinationB = new google.maps.LatLng(
      destination.latlng.lat,
      destination.latlng.lng
    )

    const service = new google.maps.DistanceMatrixService()
    service.getDistanceMatrix(
      {
        origins: [origin1, origin2],
        destinations: [destinationA, destinationB],
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          if (
            response.rows[0] &&
            response.rows[0].elements[0] &&
            response.rows[0].elements[0].distance.text
          ) {
            store.dispatch(distanceCalculated(response.rows[0].elements[0].distance))
          }
        }
      }
    )
  }
  next(action)
}
export default googleMapDirectionMiddleware
