import { connect } from 'react-redux'
import ClientForm from '../../../pages/main/EditOrders/Client/ClientForm/index'

import {
  getOldClient,
  getOrdersState,
  getIsOrderDisabled,
} from '../../../redux/shipment/selectors'
import { COURSE_EDIT_TYPE } from '../../../utils/getOrderStatus'

const mapStateToProps = (state) => ({
  orders: getOrdersState(state),
  oldClient: getOldClient(state),
  courseEditable: getIsOrderDisabled(state) == COURSE_EDIT_TYPE.editable,
  courseSemiEdit: getIsOrderDisabled(state) === COURSE_EDIT_TYPE.semiEdit,
  coursePartialEdit: getIsOrderDisabled(state) === COURSE_EDIT_TYPE.partialEdit,
})

export default connect(mapStateToProps, null)(ClientForm)
