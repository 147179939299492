//TODO LINT
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select/Select'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import './index.scss'
import Loading from '../../../../components/Loading'
import HttpPopUp from '../../../../components/PopUp/index'
import Dialog from '../../../../components/ModalDialog'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import { useParams } from 'react-router-dom'
import { REDUCTION_TYPE, PaymentInitial } from '../../../../constants/appContants'
import { getOrderStatusMofifiable } from '../../../../utils/getOrderStatus'
import {
  replaceDotWithComma,
  parseFloatWithDot,
} from '../../../../utils/floatFormatting'
import { ClientDelivery, SUPPLY } from '../../../../utils/values'

export default function Payment({
  codePromotion,
  totalPrice,
  updateShipment,
  order,
  paymentTypes,
  showPopUp,
  hidePopUp,
  client,
  loading,
  oldOrder,
  setPaymentFormAction,
  command,
  setCommand,
  paymentFormInitial,
  lettreDeVoiture,
  errorMessage,
  courseEditable,
  courseSemiEdit,
  coursePartialEdit,
}) {
  const [paymentForm, setPaymentForm] = useState(PaymentInitial[0].code)
  const [calculatedPrice, setCalculatedPrice] = useState(totalPrice)
  const [reasonPopUpShow, setReasonPopUpShow] = useState(false)
  const [editReason, setEditReason] = useState('')
  const [editMessage, setEditMessage] = useState(null)
  const handlePaymentFormChange = (event) => {
    setPaymentForm(event.target.value)
    setPaymentFormAction(true)
  }

  const params = useParams()

  useEffect(() => {
    return () => {
      setPaymentFormAction(false)
    }
  }, [])

  useEffect(() => {
    if (codePromotion && codePromotion.code < 400) {
      const { reduction, type_reduction } = codePromotion.response
      const calculatedPrice =
        type_reduction === REDUCTION_TYPE.FIXE
          ? totalPrice - parseInt(reduction)
          : totalPrice - (totalPrice * parseInt(reduction)) / 100
      setCalculatedPrice(calculatedPrice.toFixed(2))
    } else {
      setCalculatedPrice(totalPrice)
    }
  })

  useEffect(() => {
    if (order?.shipment?.paymentMethod) setPaymentForm(order.shipment.paymentMethod)
  }, [order?.shipment?.paymentMethod])

  useEffect(() => {
    if (command) {
      onEditShipment()
      setCommand(false)
    }
  }, [command])
  const filterOptions = (options) => {
    try {
      return options.filter((elem) => elem.quantity > 0)
    } catch (e) {
      return []
    }
  }
  const handleEditReasonChange = (e) => {
    e.persist()
    setEditReason(e.target.value)
  }

  useEffect(() => {
    let messageEdit
    if (oldOrder.shipment.clientPrice !== order.shipment.clientPrice) {
      if ((paymentFormInitial === paymentForm) === 'STRIPE_CB') {
        messageEdit =
          oldOrder.shipment.clientPrice < order.shipment.clientPrice
            ? "Une fois le nouveau montant autorisé l'ancien sera remboursé"
            : 'La difference de prix sera remboursée au client'
      }
      if (paymentFormInitial === 'STRIPE_INVOICE') {
        messageEdit =
          paymentForm === 'STRIPE_INVOICE'
            ? 'Une nouvelle facture sera générée'
            : 'La facture sera annulée'
      }
    } else {
      messageEdit = null
    }
    setEditMessage(messageEdit)
  }, [paymentForm, order?.shipment.clientPrice])

  const handleCancel = () => {
    setReasonPopUpShow(false)
  }
  // When pop up is needed and we have and edit reason calls edit after chosing edit reason
  const getInvoicesList = () => {
    let invoicesList = []
    if (order.shipment.factures) {
      invoicesList =
        typeof order.shipment.factures === 'string'
          ? order.shipment.factures.length > 0 &&
            order.shipment.factures.indexOf('|') > -1
            ? order.shipment.factures.split('|')
            : [order.shipment.factures]
          : order.shipment.factures
    }
    return invoicesList
  }
  const getDate = (date, time) => {
    return moment
      .tz(moment(new Date(date)).format('YYYY-MM-DD ') + time, 'Europe/Paris')
      .isValid()
      ? moment
          .tz(moment(new Date(date)).format('YYYY-MM-DD ') + time, 'Europe/Paris')
          .format()
      : undefined
  }
  const handleConfirm = () => {
    setReasonPopUpShow(false)
    const oldAdresseDepart = getOriginadress(oldOrder)
    const oldAdresseArrive = getDestinationAdress(oldOrder)
    const adresseDepart = getOriginadress(order)
    const adresseArrivee = getDestinationAdress(order)

    console.log(
      'phone,',
      order.shipment.originPhone && order.shipment.originPhone.length > 4
    )
    console.log('testing order', order.shipment)
    const contactDepart = {
      id: order.shipment.originId,
      firstname: order.shipment.originFirstname,
      lastname: order.shipment.originLastname,
      phone:
        order.shipment.originPhone && order.shipment.originPhone.length > 4
          ? order.shipment.originPhone
          : null,
      email: order.shipment.originMail,
      observation: order.shipment.originObservation,
    }
    const contactArrivee = {
      id: order.shipment.destinationId,
      firstname: order.shipment.destinationFirstname,
      lastname: order.shipment.destinationLastname,
      phone:
        order.shipment.destinationPhone && order.shipment.destinationPhone.length > 4
          ? order.shipment.destinationPhone
          : null,
      email: order.shipment.destinationMail,
      observation: order.shipment.destinationObservation,
    }
    const courseMetadata =
      order.shipment.earliestDeliveryDate && order.shipment.latestDeliveryDate
        ? {
            earliestDeliveryDate: moment(order.shipment.earliestDeliveryDate).format(
              'YYYY-MM-DD HH:MM:SS'
            ),
            latestDeliveryDate: moment(order.shipment.latestDeliveryDate).format(
              'YYYY-MM-DD HH:MM:SS'
            ),
          }
        : {}
    const courseType =
      order.shipment.courseType !== oldOrder.shipment.courseType
        ? { courseType: order.shipment.courseType }
        : undefined
    const orderBalance =
      order.shipment.orderBalance !== oldOrder.shipment.orderBalance
        ? {
            orderBalance: parseFloatWithDot(order.shipment.orderBalance) || 0,
          }
        : undefined
    const envoiMailArrivee = !order.shipment.clientInfos
      ? !order.shipment.envoiMailArrivee
      : undefined
    const updatedCourse = {
      contactArrivee: contactArrivee,
      contactDepart: contactDepart,
      adresseDepart: adresseDepart,
      adresseArrivee: adresseArrivee,
      client: {
        firstname: client.firstname,
        lastname: client.lastname,
        vip: client.vip,
        email: client.mail,
        phone: client.phone,
      },
      codeCourse: [params.codeCourse],
      courseMetadata: getOrderStatusMofifiable(order)
        ? { ...courseMetadata, ...courseType, ...orderBalance }
        : { ...courseType, ...orderBalance },
      // We dont send date when supply status so the status doesnt get updated
      dateDemarrage: SUPPLY.includes(order?.status?.code)
        ? undefined
        : ClientDelivery.includes(order.shipment.courseType)
        ? getDate(order.shipment.date, order.shipment.time)
        : getDate(order.shipment.pickUpDate, order.shipment.pickUpStart),
      pickupStart: getPicup('pickUpStart', 'pickUpEnd'),
      pickupEnd: getPicup('pickUpEnd', 'pickUpStart'),

      nbrColis: parseInt(parseFloatWithDot(order.shipment.nbItems)),
      weight: parseFloatWithDot(order.shipment.weight),
      volume: parseFloatWithDot(order.shipment.volume),
      observation: client.observation || '',
      paymentMethod: paymentForm,
      facture: getInvoicesList(),
      montantHT: (parseFloatWithDot(totalPrice) / 1.2).toFixed(2),
      montantPrestataireHT:
        '' + (parseFloatWithDot(order.shipment.providerPrice) / 1.2).toFixed(2),
      noteInterne:
        order.shipment.noteInterne && order.shipment.noteInterne !== ''
          ? order.shipment.noteInterne
          : '',
      nonEnvoiMail: !client.envoiMail,
      nonEnvoiSms: !client.envoiMail,
      estimatedKM:
        JSON.stringify(oldAdresseDepart) !== JSON.stringify(adresseDepart) ||
        JSON.stringify(oldAdresseArrive) !== JSON.stringify(adresseArrivee)
          ? order.shipment.direction.selectedDirection.distance
          : undefined,
      dateDemarrageMeta: order.shipment.dateDemarrageMeta,
      idAdresseDepart:
        JSON.stringify(oldAdresseDepart) === JSON.stringify(adresseDepart)
          ? order.shipment.selectedOrigin.id
          : undefined,
      idAdresseArrivee:
        JSON.stringify(oldAdresseArrive) === JSON.stringify(adresseArrivee)
          ? order.shipment.selectedDestination.id
          : undefined,
      articleFamilies: order.shipment.articleFamilies,
      options: filterOptions(order.shipment?.options),
      updatedDeliveryReason: editReason,
      silentEdit: client.silentEdit,
      prestation: order.shipment.prestation,
      selectPresCanalService: order.shipment.selectPresCanalService,
      isClientInfo: order.shipment.clientInfos,
      envoiMailArrivee: envoiMailArrivee,
      manutention: order.shipment.manutention,
      manutentionDouble: order.shipment.manutentionDouble,
    }
    console.log(updatedCourse)
    console.log(updatedCourse.dateDemarrage)
    console.log(moment(updatedCourse.dateDemarrage).format())
    updateShipment(updatedCourse)
  }
  const getOriginadress = (order) => {
    return {
      address: order.shipment.selectedOrigin.formattedAddress,
      latitude: order.shipment.selectedOrigin.latlng.lat,
      longitude: order.shipment.selectedOrigin.latlng.lng,
      postalCode: order.shipment.selectedOrigin.postalCode,
      countryCode: order.shipment.selectedOrigin.country.code,
      codePays: order.shipment.selectedOrigin.country.code,
      etage: order.shipment.originLevel || null,
      avecAscenseur: order.shipment.originIsElevator,
      city: order.shipment.selectedOrigin.city,
      plVl: order.shipment.plVlAdresseDepart,
      nbrMarcheAvantAscenseur: order.shipment.nbrMarcheAvantAscenseurAdresseDepart,
    }
  }
  const getDestinationAdress = (order) => {
    return {
      address: order.shipment.selectedDestination.formattedAddress,
      latitude: order.shipment.selectedDestination.latlng.lat,
      longitude: order.shipment.selectedDestination.latlng.lng,
      postalCode: order.shipment.selectedDestination.postalCode,
      countryCode: order.shipment.selectedDestination.country.code,
      codePays: order.shipment.selectedDestination.country.code,
      etage: order.shipment.destinationLevel || null,
      avecAscenseur: order.shipment.destinationIsElevator,
      city: order.shipment.selectedDestination.city,
      plVl: order.shipment.plVlAdresseArrivee,
      nbrMarcheAvantAscenseur: order.shipment.nbrMarcheAvantAscenseurAdresseArrivee,
    }
  }
  const getPicup = (namePickUp, namePickUpOther) => {
    return getDate(order.shipment.pickUpDate, order.shipment[namePickUp]) ===
      getDate(oldOrder.shipment.pickUpDate, oldOrder.shipment[namePickUp]) &&
      getDate(order.shipment.pickUpDate, order.shipment[namePickUpOther]) ===
        getDate(oldOrder.shipment.pickUpDate, oldOrder.shipment[namePickUpOther])
      ? undefined
      : getDate(order.shipment.pickUpDate, order.shipment[namePickUp])
  }
  // When there is no edit reason calls edit directely
  const onEditShipment = () => {
    const newDate =
      moment(new Date(order.shipment.date)).format('YYYY-MM-DD ') +
      order.shipment.time
    const oldDate =
      moment(new Date(oldOrder.shipment.date)).format('YYYY-MM-DD ') +
      oldOrder.shipment.time

    const oldAdresseDepart = getOriginadress(oldOrder)
    const oldAdresseArrive = getDestinationAdress(oldOrder)
    const adresseDepart = getOriginadress(order)
    const adresseArrivee = getDestinationAdress(order)

    if (oldDate !== newDate) {
      setReasonPopUpShow(true)
    } else {
      const contactDepart = {
        id: order.shipment.originId,
        firstname: order.shipment.originFirstname,
        lastname: order.shipment.originLastname,
        phone:
          order.shipment.originPhone && order.shipment.originPhone.length > 4
            ? order.shipment.originPhone
            : null,
        email: order.shipment.originMail,
        observation: order.shipment.originObservation,
      }
      const contactArrivee = {
        id: order.shipment.destinationId,
        firstname: order.shipment.destinationFirstname,
        lastname: order.shipment.destinationLastname,
        phone:
          order.shipment.destinationPhone &&
          order.shipment.destinationPhone.length > 4
            ? order.shipment.destinationPhone
            : null,
        email: order.shipment.destinationMail,
        observation: order.shipment.destinationObservation,
      }
      const courseMetadata =
        order.shipment.earliestDeliveryDate && order.shipment.latestDeliveryDate
          ? {
              earliestDeliveryDate: moment(
                order.shipment.earliestDeliveryDate
              ).format('YYYY-MM-DD HH:MM:SS'),
              latestDeliveryDate: moment(order.shipment.latestDeliveryDate).format(
                'YYYY-MM-DD HH:MM:SS'
              ),
            }
          : {}
      const courseType =
        order.shipment.courseType !== oldOrder.shipment.courseType
          ? { courseType: order.shipment.courseType }
          : undefined
      const orderBalance =
        order.shipment.orderBalance !== oldOrder.shipment.orderBalance
          ? { orderBalance: parseFloatWithDot(order.shipment.orderBalance) || 0 }
          : undefined
      const envoiMailArrivee = !order.shipment.clientInfos
        ? !order.shipment.envoiMailArrivee
        : undefined
      const updatedCourse = {
        contactArrivee: contactArrivee,
        contactDepart: contactDepart,
        adresseDepart: adresseDepart,
        adresseArrivee: adresseArrivee,
        client: {
          firstname: client.firstname,
          lastname: client.lastname,
          vip: client.vip,
          email: client.mail,
          phone: client.phone,
        },
        codeCourse: [params.codeCourse],
        courseMetadata: getOrderStatusMofifiable(order)
          ? { ...courseMetadata, ...courseType, ...orderBalance }
          : { ...courseType, ...orderBalance },
        // We dont send date when supply status so the status doesnt get updated
        dateDemarrage: SUPPLY.includes(order?.status?.code)
          ? undefined
          : ClientDelivery.includes(order.shipment.courseType)
          ? getDate(order.shipment.date, order.shipment.time)
          : getDate(order.shipment.pickUpDate, order.shipment.pickUpStart),
        pickupStart: getPicup('pickUpStart', 'pickUpEnd'),
        pickupEnd: getPicup('pickUpEnd', 'pickUpStart'),
        nbrColis: parseInt(parseFloatWithDot(order.shipment.nbItems)) || null,
        weight: parseFloatWithDot(order.shipment.weight) || null,
        volume: parseFloatWithDot(order.shipment.volume) || null,
        observation: client.observation || '',
        paymentMethod: paymentForm,
        facture: getInvoicesList(),
        montantHT: parseFloat(totalPrice / 1.2).toFixed(2),
        montantPrestataireHT:
          '' + (parseFloatWithDot(order.shipment.providerPrice) / 1.2).toFixed(2),
        noteInterne:
          order.shipment.noteInterne && order.shipment.noteInterne !== ''
            ? order.shipment.noteInterne
            : '',
        nonEnvoiMail: !client.envoiMail,
        nonEnvoiSms: !client.envoiMail,
        estimatedKM:
          JSON.stringify(oldAdresseDepart) !== JSON.stringify(adresseDepart) ||
          JSON.stringify(oldAdresseArrive) !== JSON.stringify(adresseArrivee)
            ? order.shipment.direction.selectedDirection.distance
            : undefined,
        dateDemarrageMeta: order.shipment.dateDemarrageMeta,
        idAdresseDepart:
          JSON.stringify(oldAdresseDepart) === JSON.stringify(adresseDepart)
            ? order.shipment.selectedOrigin.id
            : undefined,
        idAdresseArrivee:
          JSON.stringify(oldAdresseArrive) === JSON.stringify(adresseArrivee)
            ? order.shipment.selectedDestination.id
            : undefined,
        articleFamilies: order.shipment.articleFamilies,
        options: filterOptions(order.shipment?.options),
        silentEdit: client.silentEdit,
        prestation: order.shipment.prestation,
        selectPresCanalService: order.shipment.selectPresCanalService,
        isClientInfo: order.shipment.clientInfos,
        envoiMailArrivee: envoiMailArrivee,
        manutention: order.shipment.manutention,
        manutentionDouble: order.shipment.manutentionDouble,
      }
      console.log(JSON.stringify(updatedCourse))
      console.log('testing datedemarrage', order.shipment.date)
      updateShipment(updatedCourse)
    }
  }

  if (loading) {
    return <Loading />
  } else {
    return (
      <div className="payment-section">
        <div style={{ height: 'auto' }}>
          <div>
            <p className="total-price-text">
              Total à payer {replaceDotWithComma(calculatedPrice)} TTC
            </p>
          </div>
          <div className="single-item">
            <FormControl variant="outlined">
              <p className="payment-form-text">Moyen de paiement</p>
              <div className="payment-selection">
                <span className="payment-selection">
                  <p className="price-text">
                    {replaceDotWithComma(calculatedPrice)}&euro;
                  </p>{' '}
                  <p className="price-euro-symbol">TTC en</p>
                </span>
                <Select
                  labelId="paymentForm-select-outlined-label"
                  id="paymentForm-select-outlined"
                  value={paymentForm}
                  onChange={handlePaymentFormChange}
                  label="paymentForm"
                  disabled={
                    paymentFormInitial === 'STRIPE_CB' ||
                    (!courseEditable && !courseSemiEdit && !coursePartialEdit)
                  }
                  className="payment-select-list"
                  variant="standard"
                >
                  {paymentTypes.length !== 0 ? (
                    PaymentInitial.concat(paymentTypes).map((payment, index) => (
                      <MenuItem
                        value={payment.code}
                        key={`payment_form_${index}`}
                        disabled={index === 0}
                      >
                        {payment.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                  )}
                </Select>
              </div>
              {paymentFormInitial === 'STRIPE_CB' && (
                <FormHelperText className="text-info">
                  On ne peut pas changer la méthode de payment puisque le moyen
                  payment indiqué est stripe CB
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div>{editMessage}</div>
        </div>
        <Dialog
          open={reasonPopUpShow}
          title={`Modifier la course ${lettreDeVoiture}`}
          content={
            <div style={{ paddingBottom: '16px', paddingRight: '16px' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="clientType"
                  name="manutentionOptions"
                  value={editReason}
                  onChange={handleEditReasonChange}
                >
                  <div style={{ paddingBottom: '15px' }}>Quelle est la raison? </div>
                  <FormControlLabel
                    value="à la demande des ops / du dispatch"
                    control={<Radio />}
                    label="à la demande des ops / du dispatch"
                  />
                  <FormControlLabel
                    value="à la demande du client"
                    control={<Radio />}
                    label="à la demande du client"
                  />
                  <FormControlLabel
                    value="à la demande du magasin / de l'expéditeur"
                    control={<Radio />}
                    label="à la demande du magasin / de l'expéditeur"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          }
          handleClose={handleCancel}
          handleClickAction={handleConfirm}
          maxWidthDialog={'xs'}
          isLoadingButtonAction={loading}
        />

        <HttpPopUp
          show={showPopUp && !loading}
          statusText={
            errorMessage.length !== 0
              ? errorMessage[0]
              : 'La course ' + lettreDeVoiture + ' a été modifiée avec succés'
          }
          status={''}
          hidePopUp={hidePopUp}
          anError={errorMessage.length !== 0}
          fromEditOrder={true}
        />
      </div>
    )
  }
}
Payment.propTypes = {
  setActiveStep: PropTypes.func,
  codePromotion: PropTypes.any,
  totalPrice: PropTypes.number,
  createNewShipment: PropTypes.func,
  createNewAssembly: PropTypes.func,
  selectedClient: PropTypes.any,
  order: PropTypes.object,
  oldOrder: PropTypes.object,
  paymentTypes: PropTypes.array,
  showPopUp: PropTypes.bool,
  hidePopUp: PropTypes.func,
  client: PropTypes.any,
  newClient: PropTypes.bool,
  createNewShipmentAssembly: PropTypes.func,
  loading: PropTypes.bool,
  createdNewCourse: PropTypes.any,
  command: PropTypes.bool,
  setCommand: PropTypes.func,
  setPaymentFormAction: PropTypes.func,
  errorMessage: PropTypes.array,
  paymentFormInitial: PropTypes.string,
  courseEditable: PropTypes.bool,
  courseSemiEdit: PropTypes.bool,
  coursePartialEdit: PropTypes.bool,
  updateShipment: PropTypes.func,
  lettreDeVoiture: PropTypes.string,
}
