import React from 'react'
import PropTypes from 'prop-types'
import ShipmentAssembly from '../../../../containers/main/PlaceOrders/ShipmentAssembly'
import Client from '../../../../containers/main/PlaceOrders/Client'
import Payment from '../../../../containers/main/PlaceOrders/Payment'
const StepSection = ({ activeStep }) => {
  return (
    <>
      <div
        className={[
          'col-container',
          'col-left-container',
          activeStep === 0 ? 'active' : '',
        ].join(' ')}
      >
        <ShipmentAssembly />
      </div>
      <div
        className={[
          'col-container',
          'col-left-container',
          activeStep === 1 ? 'active' : '',
        ].join(' ')}
      >
        <Client />
      </div>

      <div
        className={[
          'col-container',
          'col-left-container',
          activeStep === 2 ? 'active' : '',
        ].join(' ')}
      >
        <Payment />
      </div>
    </>
  )
}
StepSection.propTypes = {
  activeStep: PropTypes.number,
}
export default StepSection
