import { connect } from 'react-redux'
import PiecesJointes from '../../../pages/main/Orders/DetailsDelivery/components/details/piecesJointes'

import {
  getPiecesJointeURL,
  onUploadAttachment,
  setErrorFileUpload,
  getOrderAttachmentsByLdv,
} from '../../../redux/order/actions'
import {
  getLoadingUploadFile,
  getErrorUploadFile,
  getOrderAttachments,
  getLodingOrderAttachments,
  getActionOrderAttachmentsDone,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingUploadFile: getLoadingUploadFile(state),
  errorFileUpload: getErrorUploadFile(state),
  orderAttachments: getOrderAttachments(state),
  isLoadingOrderAttachments: getLodingOrderAttachments(state),
  isActionOrderAttachmentsDone: getActionOrderAttachmentsDone(state),
})
const mapDisptachToProps = (dispatch) => ({
  getPiecesJointeURL: (url) => dispatch(getPiecesJointeURL(url)),
  onUploadPiecesJointes: (file, codeCourse) =>
    dispatch(onUploadAttachment(file, codeCourse)),
  setErrorFileUpload: (isError) => dispatch(setErrorFileUpload(isError)),
  getOrderAttachmentsByLdv: (ldv) => dispatch(getOrderAttachmentsByLdv(ldv)),
})

export default connect(mapStateToProps, mapDisptachToProps)(PiecesJointes)
