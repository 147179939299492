import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'

import './index.scss'

/** An Outlined button Component */
export default function PrimaryOutlineButton({
  onClick,
  content,
  icon,
  disabled,
  style,
  className,
  id,
}) {
  return (
    <div id={id} className="primary-outline-button">
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={disabled}
        style={style}
        sx={{
          textTransform: 'capitalize !important',
          fontSize: '13px !important',
          fontWeight: 400,
        }}
        className={className}
      >
        {content} {icon}
      </Button>
    </div>
  )
}
PrimaryOutlineButton.propTypes = {
  /** If true the button is not clickable */

  isEnabled: PropTypes.bool,
  /** The content of the button*/

  content: PropTypes.any,
  /** The icon of the button */

  disabled: PropTypes.bool,
  icon: PropTypes.object,
  /** The function that is executed when the button is clicked */

  onClick: PropTypes.func,
  style: PropTypes.any,
  className: PropTypes.any,
  id: PropTypes.string,
}
