import React from 'react'
import PropTypes from 'prop-types'

import MuiButton from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { styled } from '@mui/material/styles'

const Button = styled((props) => <MuiButton {...props} />)(() => ({
  color: 'white',
  backgroundColor: '#1976d2',
  height: '40px',
  textTransform: 'none !important',
  fontSize: '13px !important',
  fontWeight: '400',
  marginRight: '15px',
}))
export default function LongMenu({
  onPointe,
  enabledButtonPointage,
  isLoadingPointagePackage,
  getEtatPackage,
  enabledButtonlivraisonPartielle,
  isLoadingEtatPackage,
}) {
  return (
    <>
      {isLoadingPointagePackage || isLoadingEtatPackage ? (
        <div
          style={{
            display: 'inline-flex',
            marginRight: '15px',
            height: '35px',
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : enabledButtonPointage ? (
        <Button
          variant="contained"
          onClick={onPointe}
          size="large"
          id={'Package-Pointage'}
          sx={{
            backgroundColor: '#10A549',
            ':hover': {
              backgroundColor: '#158f44',
            },
          }}
        >
          {'Pointer'}
        </Button>
      ) : (
        enabledButtonlivraisonPartielle && (
          <Button
            variant="contained"
            onClick={getEtatPackage}
            size="large"
            id={'Package-Livraison-Partielle'}
            sx={{
              backgroundColor: '#10A549',
              ':hover': {
                backgroundColor: '#158f44',
              },
            }}
          >
            {'Livraison Partielle'}
          </Button>
        )
      )}
    </>
  )
}
LongMenu.propTypes = {
  onPointe: PropTypes.func,
  enabledButtonPointage: PropTypes.bool,
  isLoadingPointagePackage: PropTypes.bool,
  getEtatPackage: PropTypes.func,
  enabledButtonlivraisonPartielle: PropTypes.bool,
  isLoadingEtatPackage: PropTypes.bool,
}
