import {
  SIMULATE_TOURS,
  CREATE_TOURS,
  UPDATE_FOCUSED_TOURS,
  RECALCULATE_TOURS,
  RECALCULATE_UNSERVED,
  UPDATE_ORDERS_ARRAY,
  GET_DRIVERS_PLANNINGS,
  REMOVE_ERROR_PLANNIFICATION,
  AFFECT_TOUR,
  CHECKED_ALL_ORDERS,
  CHECKED_ORDER,
  RESET_CHECKED_ORDER,
  CHECKED_PLANNING,
  CHECKED_VISIT,
  CHANGE_TOURS_TYPE_STATE,
  VIEW_PLANNIFICATION,
  UPDATE_COURSE_POLARIS,
  ACTION_UPDATE_COURSE_POLARIS,
  RESET_SIMULATE_ACTION,
  GET_TOURS_FROM_POLARIS,
  DELETE_TOUR_FROM_POLARIS,
  RESET_TOUR_ACTION,
  ACTIVE_TOUR_ACTION,
  OPTIMISATION_COURSES,
  GET_WAREHOUSES,
  GET_WAREHOUSES_MORE,
  SEARCH_WAHREHOUSES,
  RESET_WAHREHOUSES,
  CHANGE_FILTER_OFFSET,
  UPDATE_COURSE_OPTIMISATION_FILE,
  EXPORT_TOURS,
  FETCH_COURSES,
  MAP_ADD_SELECTED_COURSES,
  MAP_REMOVE_SELECTED_COURSES,
  MAP_REMOVE_ALL_SELECTED_COURSES,
  VERROUILLE_TOURNEE,
  DEVERROUILLE_TOURNEE,
} from './actionsConsts'

export const getDriversPlannings = (data) => ({
  type: GET_DRIVERS_PLANNINGS,
  payload: data,
})

export const affectTour = (data) => ({
  type: AFFECT_TOUR,
  payload: data,
})

export const recalculateTours = (data, deliveryDate, isOnePickUp, isFile) => ({
  type: RECALCULATE_TOURS,
  payload: { data, deliveryDate, isOnePickUp, isFile },
})

export const simulateTours = (data, isOnePickUp) => ({
  type: SIMULATE_TOURS,
  payload: { data, isOnePickUp },
})

export const createTours = (data) => ({
  type: CREATE_TOURS,
  payload: { data },
})

export const removeError = (isViewPlannification) => ({
  type: REMOVE_ERROR_PLANNIFICATION,
  payload: { isViewPlannification },
})

export const viewPlannification = (viewPlannification) => ({
  type: VIEW_PLANNIFICATION,
  payload: { viewPlannification },
})

export const updateFocusedTours = (del, id) => ({
  type: UPDATE_FOCUSED_TOURS,
  payload: { del, id },
})

export const changeToursTypeState = (isOnePickUp) => ({
  type: CHANGE_TOURS_TYPE_STATE,
  payload: { isOnePickUp },
})

export const recalculateUnserved = (data) => ({
  type: RECALCULATE_UNSERVED,
  payload: { data },
})

export const updateOrdersArray = (data) => ({
  type: UPDATE_ORDERS_ARRAY,
  payload: { data },
})

export const handleCheckAll = (checkedAll) => ({
  type: CHECKED_ALL_ORDERS,
  payload: checkedAll,
})

export const handleCheck = (id) => ({
  type: CHECKED_ORDER,
  payload: id,
})

export const resetCheckedAll = () => ({
  type: RESET_CHECKED_ORDER,
})

export const handleCheckAllVisit = (indexPlanning) => ({
  type: CHECKED_PLANNING,
  payload: { indexPlanning },
})

export const handleCheckVisit = (indexPlanning, indexVisit, RecList) => ({
  type: CHECKED_VISIT,
  payload: { indexPlanning, indexVisit, RecList },
})

export const updateCoursePolaris = (course) => ({
  type: UPDATE_COURSE_POLARIS,
  payload: { course },
})

export const updateActionCoursePolaris = () => ({
  type: ACTION_UPDATE_COURSE_POLARIS,
})

export const resetSimulateAction = () => ({
  type: RESET_SIMULATE_ACTION,
})

export const getToursFromPolaris = () => {
  return {
    type: GET_TOURS_FROM_POLARIS,
  }
}

export const deleteTours = (data) => ({
  type: DELETE_TOUR_FROM_POLARIS,
  payload: { data },
})

export const resetTourAction = () => ({
  type: RESET_TOUR_ACTION,
})

export const activeTourAction = () => ({
  type: ACTIVE_TOUR_ACTION,
})

//Optimisation course Euromatic
export const optimiserCourses = (payload) => ({
  type: OPTIMISATION_COURSES,
  payload,
})

//Get WareHouse
export const fetchWareHouses = () => ({
  type: GET_WAREHOUSES,
})

//Get WareHouse More
export const fetchWareHousesMore = () => ({
  type: GET_WAREHOUSES_MORE,
})

//search WareHouse
export const searchWareHouses = (search) => ({
  type: SEARCH_WAHREHOUSES,
  payload: { search },
})

export const changeOffsetWareHouse = (offset) => ({
  type: CHANGE_FILTER_OFFSET,
  payload: { offset },
})
//Reset congif wareHouse
export const resetConfigWareHouse = () => ({
  type: RESET_WAHREHOUSES,
})

//update Course Optimisation course Euromatic
export const updateCourseOptimisationFile = (course) => ({
  type: UPDATE_COURSE_OPTIMISATION_FILE,
  payload: { course },
})

export const exportTours = (data) => ({
  type: EXPORT_TOURS,
  payload: { data },
})

export const fetchCourses = (filter) => ({
  type: FETCH_COURSES,
  payload: { filter },
})

//Selected Course (MAP)
export const addSelectedCourses = (data) => ({
  type: MAP_ADD_SELECTED_COURSES,
  payload: { data },
})

export const removeSelectedCourses = (data) => ({
  type: MAP_REMOVE_SELECTED_COURSES,
  payload: { data },
})

export const removeAllSelectedCourses = () => ({
  type: MAP_REMOVE_ALL_SELECTED_COURSES,
})

export const verrouillerTournee = (positionsChauffeur) => ({
  type: VERROUILLE_TOURNEE,
  payload: { positionsChauffeur },
})

export const deVerrouillerTournee = (positionsChauffeur) => ({
  type: DEVERROUILLE_TOURNEE,
  payload: { positionsChauffeur },
})
