import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function HttpPopUp({
  buttonText,
  hidePopUp,
  status,
  statusText,
  show,
  anError,
  fromEditOrder,
}) {
  let history = useHistory()
  const handleClose = () => {
    hidePopUp()
    if (!anError && !fromEditOrder) {
      history.push('/')
    }
    if (!anError && fromEditOrder) {
      history.push('/commandes')
    }
  }
  return (
    <div className="error-container">
      <Dialog
        open={show}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" className="error-dialog-title">
          {anError ? "Une erreur s'est produite" : status}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <span style={anError ? { color: 'red' } : {}}>{statusText}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {buttonText ? buttonText : anError ? 'Réessayer' : 'Fermer'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
HttpPopUp.propTypes = {
  status: PropTypes.any,
  statusText: PropTypes.any,
  show: PropTypes.bool,
  hidePopUp: PropTypes.func,
  buttonText: PropTypes.string,
  anError: PropTypes.bool,
  fromEditOrder: PropTypes.bool,
}
