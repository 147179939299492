import React, { useState } from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'

const Clipbloard = ({ onCopy, show, message }) => {
  const [copyDone, setCopyDone] = useState(false)
  const onCopyDone = () => {
    onCopy()
    setCopyDone(true)
    setTimeout(() => {
      setCopyDone(false)
    }, 2000)
  }
  return (
    <Tooltip
      title={<span style={{ fontSize: 12 }}>{copyDone ? 'Copié !' : message}</span>}
      placement="right"
    >
      <span
        className="clipbloard-container eUOUHa"
        role="img"
        aria-label="Copier le lien vers le ticket"
        onClick={() => onCopyDone()}
      >
        {!copyDone && show && (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            focusable="false"
            role="presentation"
          >
            <path
              fill="currentColor"
              d="M7 18V2h13v16Zm2-2h9V4H9Zm-6 6V6h2v14h11v2Zm6-6V4v12Z"
            />
          </svg>
        )}
        {copyDone && (
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        )}
      </span>
    </Tooltip>
  )
}
Clipbloard.propTypes = {
  onCopy: PropTypes.func,
  show: PropTypes.bool,
  message: PropTypes.string,
}
export default Clipbloard
