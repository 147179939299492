import { connect } from 'react-redux'

import GestionParticipants from '../../../pages/settings/participants'
import {
  resetSuccessAction,
  fetchParticipants,
  onSearch,
  pageChanged,
  pageLimitChanged,
  removeError,
  resetParticipantConfig,
  setIsParticipantFiltre,
} from '../../../redux/participants/actions'

import {
  getParticipants,
  getParticipantsCount,
  getParticipantsListIsLoading,
  getErrorMessage,
  getIsErrorFetch,
  getIsSuccessRefresh,
  getSuccess,
} from '../../../redux/participants/selectors'
import { fetchFilesById } from '../../../redux/formations/actions'
import { getErrorMessageConfig } from '../../../redux/configuration/selectors'
import { removeErrorConfig } from '../../../redux/configuration/actions'

const mapStateToProps = (state) => ({
  isLoadingParticipantsList: getParticipantsListIsLoading(state),
  participants: getParticipants(state),
  participantsCount: getParticipantsCount(state),
  errorMessage: getErrorMessage(state),
  isErrorFetch: getIsErrorFetch(state),
  isSuccessRefresh: getIsSuccessRefresh(state),
  success: getSuccess(state),
  errorMessageConfig: getErrorMessageConfig(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchData: () => {
    dispatch(setIsParticipantFiltre())
    dispatch(fetchParticipants())
  },
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(setIsParticipantFiltre())
    dispatch(fetchParticipants())
  },
  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(setIsParticipantFiltre())
    dispatch(fetchParticipants())
  },
  removeError: () => dispatch(removeError()),
  removeErrorConfig: () => dispatch(removeErrorConfig()),
  resetSuccessAction: () => dispatch(resetSuccessAction()),
  onCloseReset: () => dispatch(resetParticipantConfig()),

  searchData: (search) => {
    dispatch(onSearch(search))
    dispatch(setIsParticipantFiltre())
    dispatch(fetchParticipants())
  },
  fetchFiles: (id) => {
    dispatch(fetchFilesById(id, 'participant'))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(GestionParticipants)
