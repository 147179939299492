import {
  SOCKET_REGISTER,
  SOCKET_REMOVE_LISTENER,
  SOCKET_GET_CONVERSATION,
  SOCKET_GET_CONVERSATION_DELIVERY,
  SOCKET_GET_MESSAGE,
  SOCKET_SEND_MESSAGE,
  SOCKET_CLOSE_OPEN_CONVERSATION,
  SOCKET_SEEN_MESSAGE,
  SOCKET_GET_NOTIFICATION,
  SOCKET_DISCONNECT,
  SOCKET_CREATION_CONVERSATION,
} from './constants'

import SocketDiscussion from '../../../utils/discussion/socketDiscussion'

import {
  socketNotRegistred,
  receiveDataFromConversationTopicAction,
  receiveDataFromConversationBYLDVTopicAction,
  setLoadingOpenCloseConversation,
  notFocus,
  receiveAddNotification,
  receiveRemoveNotification,
  initsocketNotRegistred,
  setIdConversationUUID,
  removeIdConversationUUID,
  setIdConversation,
} from '../conversations/action'
import { removeListenerSocket, getMessageSocket, sendMessage } from './action'

import { EVENT_SOCKET } from '../../../utils/discussion/values'

import {
  cleaningMessageList,
  setLoadingSendMessage,
  receiveDataFromMessagesTopicAction,
  setMessageNewConversation,
  removeMessageNewConversation,
} from '../messages/action'

export default (store) => (next) => (action) => {
  if (action.type === SOCKET_REGISTER) {
    SocketDiscussion.register()
    store.dispatch(socketNotRegistred())
  }

  if (action.type === SOCKET_REMOVE_LISTENER) {
    const { event } = action.payload
    if (event !== undefined && event?.length > 0) {
      SocketDiscussion.removeAllListeners(event)
    }
  }

  if (action.type === SOCKET_GET_CONVERSATION) {
    const { isRecherch } = action.payload
    store.dispatch(removeListenerSocket('conversation'))
    SocketDiscussion.getConversation().subscribe((conversation) => {
      //when we received entrie we dispatch an action with them
      if (!isRecherch) {
        store.dispatch(receiveDataFromConversationTopicAction(conversation))
      }
    })
  }

  if (action.type === SOCKET_GET_CONVERSATION_DELIVERY) {
    const { ldv } = action.payload

    store.dispatch(removeListenerSocket('conversation'))
    SocketDiscussion.getConversation().subscribe((conversation) => {
      const {
        conversations: { idConversationNew },
        messageList: { entrySendMessage },
      } = store.getState()

      if (
        idConversationNew != null &&
        entrySendMessage != null &&
        conversation.key != undefined &&
        conversation.key == idConversationNew
      ) {
        store.dispatch(getMessageSocket(conversation.id, true))
        entrySendMessage.entry.conversationID = conversation.id
        store.dispatch(
          sendMessage(entrySendMessage.entry, entrySendMessage.connectedUser)
        )
        store.dispatch(setIdConversation(conversation.id))
      }
      //when we received entrie we dispatch an action with them
      store.dispatch(receiveDataFromConversationBYLDVTopicAction(conversation, ldv))
    })
  }

  if (action.type === SOCKET_GET_MESSAGE) {
    const { idConversation, isDelivery } = action.payload

    SocketDiscussion.getMessage(idConversation).subscribe((message) => {
      const {
        conversations: { idConversationNew },
        messageList: { entrySendMessage },
      } = store.getState()
      if (idConversationNew != null && entrySendMessage != null) {
        store.dispatch(removeIdConversationUUID())
        store.dispatch(removeMessageNewConversation())
      }

      if (
        (message.type === EVENT_SOCKET.CLOSE_EVENT ||
          message.type === EVENT_SOCKET.OPEN_EVENT) &&
        isDelivery
      ) {
        store.dispatch(receiveDataFromMessagesTopicAction(message))
        store.dispatch(setLoadingOpenCloseConversation(false))
      } else if (message.type === EVENT_SOCKET.TEXT_EVENT || isDelivery) {
        //add the new messaga to the message list in the state
        store.dispatch(receiveDataFromMessagesTopicAction(message))
        store.dispatch(setLoadingSendMessage(false))
      } else if (
        message.type === EVENT_SOCKET.CLOSE_EVENT ||
        message.type === EVENT_SOCKET.OPEN_EVENT
      ) {
        store.dispatch(setLoadingOpenCloseConversation(false))
        store.dispatch(cleaningMessageList())
        store.dispatch(removeListenerSocket(idConversation))
      }
    })
  }

  if (action.type === SOCKET_SEND_MESSAGE) {
    const { entry, connectedUser } = action.payload

    store.dispatch(setLoadingSendMessage(true))
    SocketDiscussion.sendMessage(entry, connectedUser)
  }

  if (action.type === SOCKET_CLOSE_OPEN_CONVERSATION) {
    const { entry, connectedUser } = action.payload

    store.dispatch(setLoadingOpenCloseConversation(true))
    SocketDiscussion.CloseOpenConversation(entry, connectedUser)
  }

  if (action.type === SOCKET_SEEN_MESSAGE) {
    const { entry, connectedUser } = action.payload

    SocketDiscussion.seenMessage(entry, connectedUser)
    store.dispatch(notFocus())
  }

  if (action.type === SOCKET_GET_NOTIFICATION) {
    store.dispatch(removeListenerSocket('notification'))
    SocketDiscussion.getNotification().subscribe((notification) => {
      if (notification.type === EVENT_SOCKET.ADD_NOTIFICATION) {
        store.dispatch(receiveAddNotification(notification))
      } else if (notification.type === EVENT_SOCKET.REMOVE_NOTIFICATION) {
        store.dispatch(receiveRemoveNotification(notification))
      }
    })
  }

  if (action.type === SOCKET_DISCONNECT) {
    SocketDiscussion.socketDisconnect()
    store.dispatch(initsocketNotRegistred())
  }

  if (action.type === SOCKET_CREATION_CONVERSATION) {
    const { entryCreateConversation, entrySendMessage, idConversation } =
      action.payload
    store.dispatch(setLoadingSendMessage(true))

    store.dispatch(setIdConversationUUID(idConversation))
    store.dispatch(setMessageNewConversation(entrySendMessage))
    SocketDiscussion.createConversation(entryCreateConversation)
  }

  next(action)
}
