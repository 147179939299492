import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import GetAppIcon from '@mui/icons-material/GetApp'
import PropTypes from 'prop-types'
import VisibilityIcon from '@mui/icons-material/Visibility'
import Button from '@mui/material/Button'
import { ETIQUETTE, LDV, INVOICE, INVOICE_ASSEMBLY } from '../../../utils/values'
import { styled } from '@mui/material/styles'

const StyledTableContainer = styled(TableContainer)(() => ({
  '&.MuiTableContainer-root': {
    marginBottom: '20px',
    width: '400px',
    overflow: 'hidden',
  },
}))
const StyledCell = styled(TableCell)(() => ({
  '&.MuiTableCell-root': {
    borderBottom: 'none !important',
  },
}))

const getRows = (codeAssembly) => {
  return [
    { file: 'BL', type: LDV, display: true },
    { file: 'Facture Course', type: INVOICE, display: true },
    {
      file: 'Facture Montage',
      type: INVOICE_ASSEMBLY,
      display: codeAssembly !== null,
    },
    { file: 'Etiquette', type: ETIQUETTE, display: true },
  ]
}
export default function FilesList({
  viewFiles,
  downloadFiles,
  isLoadingFiles,
  shipment,
}) {
  const [codeAssembly, setCodeAssembly] = useState(null)

  useEffect(() => {
    const assemblyCode =
      shipment?.assemblies && shipment?.assemblies?.length
        ? shipment?.assemblies[0].code
        : null
    setCodeAssembly(assemblyCode)
  }, [shipment])

  const getFileDetail = (type) => {
    return {
      code: type === INVOICE_ASSEMBLY ? codeAssembly : shipment.codeCourse,
      generateApi:
        type === INVOICE || type === INVOICE_ASSEMBLY
          ? 'generateFacture'
          : type === LDV
          ? 'generateLettreDeVoiture'
          : 'generateEtiquetteCourse',
      codeType: type === INVOICE_ASSEMBLY ? 'codeMontage' : 'codeCourse',
    }
  }
  const handleDownload = (type) => {
    const fileDetail = getFileDetail(type)
    downloadFiles(fileDetail)
  }

  const handleView = (type) => {
    const fileDetail = getFileDetail(type)
    viewFiles(fileDetail)
  }
  return (
    <StyledTableContainer>
      <Table aria-label="simple table">
        <TableBody>
          {getRows(codeAssembly)
            .filter((file) => file.display)
            .map((row) => (
              <TableRow key={row.file}>
                <StyledCell component="th" scope="row">
                  {row.file}
                </StyledCell>
                <StyledCell align="right">
                  <Button
                    disabled={isLoadingFiles}
                    style={{ background: 'none' }}
                    onClick={() => handleView(row.type)}
                  >
                    <VisibilityIcon style={{ cursor: 'pointer' }} />
                  </Button>
                </StyledCell>
                <StyledCell align="right" disabled={isLoadingFiles}>
                  <Button
                    disabled={isLoadingFiles}
                    style={{ background: 'none' }}
                    onClick={() => handleDownload(row.type)}
                  >
                    <GetAppIcon style={{ cursor: 'pointer' }} />
                  </Button>
                </StyledCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  )
}

FilesList.propTypes = {
  print: PropTypes.func,
  shipment: PropTypes.any,
  viewFiles: PropTypes.func,
  downloadFiles: PropTypes.func,
  isLoadingFiles: PropTypes.bool,
}
