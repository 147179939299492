import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { CourseTableContent } from './CourseTableContent'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Filter from '../../../../components/Table/components/Filters'
import { labelsCourseTourTable, settingsCourseTourTable } from './data'

export default function CourseTourTable({ visits, deliveryDate }) {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const columnsKey = 'courseTour_table_columns'
    const list = JSON.parse(localStorage.getItem(columnsKey))
    if (list) setColumns(list)
    else {
      localStorage.setItem(columnsKey, JSON.stringify(labelsCourseTourTable))
      setColumns(labelsCourseTourTable)
    }
  }, [])

  const handleColumns = (list) => {
    setColumns(list)
  }
  const getChecked = (name) => {
    let isChecked = false
    columns.forEach((column) => {
      if (column.name === name) {
        isChecked = column.checked
      }
    })
    return isChecked
  }
  return (
    <>
      <div
        style={{
          color: '#fff',
          display: 'inline-flex',
          alignItems: 'center',
          background: '#1976d2',
          height: '45px',
          width: '100%',
          padding: '0 10px',
          justifyContent: 'space-between',
        }}
        id="coursesTourTableTitle"
      >
        Courses
      </div>
      <TableContainer sx={{ height: '100%' }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ backgroundColor: 'white' }}
          sx={{
            boxShadow:
              ' rgb(51 51 51 / 10%) 0px 5px 5px -5px, rgb(51 51 51) 5px 0px 5px -5px',
          }}
        >
          <TableHead>
            <TableRow>
              {[...columns.concat([...settingsCourseTourTable])].map(
                (column, index) =>
                  column.checked &&
                  (column.id === 'settings' ? (
                    <Filter
                      idFilter={'filter-courseToursTable'}
                      columns={columns}
                      handleColumns={handleColumns}
                      localStorageNameKey={'courseTour_table_columns'}
                    />
                  ) : (
                    <TableCell
                      id={`column_courseTable${index}`}
                      key={`column_courseTable${index}`}
                      align="left"
                    >
                      {column.label}
                    </TableCell>
                  ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {visits
              .filter((visit) => visit.type === 'dropoff')
              .map((visit, index) => {
                return (
                  <CourseTableContent
                    deliveryDate={deliveryDate}
                    key={index}
                    visit={visit}
                    index={index}
                    labels={columns}
                    getChecked={getChecked}
                  />
                )
              })}
            {visits.length == 2 && (
              <TableRow>
                <TableCell colSpan={labelsCourseTourTable.length}>
                  <div
                    style={{ display: 'flex', margin: 'auto', width: 'fit-content' }}
                  >
                    <ErrorOutlineIcon style={{ margin: 'auto' }} />
                    <h4>Pas de courses</h4>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
CourseTourTable.propTypes = {
  visits: PropTypes.array,
  deliveryDate: PropTypes.string,
}
