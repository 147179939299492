export default () => {
  return {
    data: [],
    conversationLivraison: [],
    isLoading: false,
    isLoadingConvMore: false,
    error: null,
    socketNotRegistred: true,
    nbNotif: 0,
    notifications: [],
    focus: false,
    openConversation: '',
    count: 0,
    countList: 0,
    isLoadingMore: false,
    loadingOpenCloseConversation: false,
    idConversationNew: null,
    idConversation: null,
    subjectConversation: [],
    isLoadingSubjectConversation: false,
  }
}
