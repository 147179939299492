import React from 'react'

export function RiListSettingsLine(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="#1876D2"
        d="M2 18h7v2H2v-2zm0-7h9v2H2v-2zm0-7h20v2H2V4zm18.674 9.025l1.156-.391l1 1.732l-.916.805a4.017 4.017 0 0 1 0 1.658l.916.805l-1 1.732l-1.156-.391c-.41.37-.898.655-1.435.83L19 21h-2l-.24-1.196a3.996 3.996 0 0 1-1.434-.83l-1.156.392l-1-1.732l.916-.805a4.017 4.017 0 0 1 0-1.658l-.916-.805l1-1.732l1.156.391c.41-.37.898-.655 1.435-.83L17 11h2l.24 1.196c.536.174 1.024.46 1.434.83zM18 18a2 2 0 1 0 0-4a2 2 0 0 0 0 4z"
      ></path>
    </svg>
  )
}
export default RiListSettingsLine
