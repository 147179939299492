//TODO LINT
/* eslint-disable */

export const getDriverState = ({ driver }) => driver

export const getDriversOptimiserIsLoading = (state) =>
  getDriverState(state).isLoadingDriversOptimiser

export const getDriversOptimiser = (state) =>
  getDriverState(state).driversOptimiser.map(
    ({
      firstname,
      lastname,
      phone,
      vehiculeId,
      id,
      adresseDepart,
      adresseArrivee,
      operationalHours,
    }) => {
      const vehicule = vehiculeId?.id
        ? vehiculeId
        : { vehicule_category: { volumeMax: 20, weight: 2000 } }
      return {
        firstname,
        lastname,
        phone,
        vehiculeId: vehicule,
        id,
        checked: false,
        adresseDepart,
        adresseArrivee,
        operationalHours,
      }
    }
  )

export const getDrivers = (state) => getDriverState(state).drivers
export const getDriversIsLoading = (state) => getDriverState(state).isLoading

export const getDriversOptimiserCount = (state) =>
  getDriverState(state).driversOptimiserCount
