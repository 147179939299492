import { styled } from '@mui/material/styles'

export const StyledTableTourneeContainer = styled('div')({
  height: '100%',
  width: '50%',
  overflow: 'auto',
  '@media (max-width: 600px)': {
    width: '100%',
  },
})
export const StyledMapContainer = styled('div')({
  height: '100%',
  width: '50%',
  '@media (max-width: 600px)': {
    width: '100%',
    height: '50vh',
  },
})

export const StyledMapTableContainer = styled('div')({
  display: 'flex',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
    height: 'initial',
  },
})
