import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import List from './List'
import ListSearch from './ListSearch'
import CircularProgress from '@mui/material/CircularProgress'

export default function AutoCompleteSearch({
  handleToggle,
  handleDoubleClick,
  dataList,
  fetchMoreData,
  onChangeSearch,
  search,
  handleDoubleClickAll,
  handleToggleAll,
  numSelected,
  isSelected,
  dataSearch,
  isSearchLoading,
  handleChangeAccordion,
  expanded,
  handleToggleAccordion,
  checkedAccordion,
}) {
  return (
    <form noValidate autoComplete="off">
      <TextField
        id="standard-basic"
        variant="standard"
        placeholder="Rechercher .. "
        style={{ height: '45px', padding: '5px 10px 5px 30px', marginTop: '20px' }}
        onChange={(event) => {
          onChangeSearch(event.target.value)
        }}
        value={search}
      />
      <SuggestionsListComponent
        dataList={dataList}
        handleToggle={handleToggle}
        handleDoubleClick={handleDoubleClick}
        fetchMoreData={fetchMoreData}
        handleDoubleClickAll={handleDoubleClickAll}
        handleToggleAll={handleToggleAll}
        numSelected={numSelected}
        isSelected={isSelected}
        search={search}
        dataSearch={dataSearch}
        isSearchLoading={isSearchLoading}
        handleChangeAccordion={handleChangeAccordion}
        expanded={expanded}
        handleToggleAccordion={handleToggleAccordion}
        checkedAccordion={checkedAccordion}
      />
    </form>
  )
}
AutoCompleteSearch.propTypes = {
  handleToggle: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  dataList: PropTypes.object,
  fetchMoreData: PropTypes.func,
  onChangeSearch: PropTypes.func,
  search: PropTypes.string,
  handleDoubleClickAll: PropTypes.func,
  handleToggleAll: PropTypes.func,
  numSelected: PropTypes.bool,
  isSelected: PropTypes.func,
  getData: PropTypes.func,
  dataSearch: PropTypes.array,
  isSearchLoading: PropTypes.bool,
  handleChangeAccordion: PropTypes.func,
  expanded: PropTypes.string,
  handleToggleAccordion: PropTypes.func,
  checkedAccordion: PropTypes.func,
}

export const SuggestionsListComponent = ({
  handleToggle,
  dataList,
  handleDoubleClick,
  fetchMoreData,
  handleDoubleClickAll,
  handleToggleAll,
  numSelected,
  isSelected,
  search,
  dataSearch,
  isSearchLoading,
  handleChangeAccordion,
  expanded,
  handleToggleAccordion,
  checkedAccordion,
}) => {
  return (
    <>
      {isSearchLoading ? (
        <div style={{ marginLeft: '45%' }}>
          <CircularProgress color="primary" />
        </div>
      ) : search.trim() !== '' && dataSearch.length > 0 ? (
        <ListSearch
          dataList={dataSearch}
          handleToggle={handleToggle}
          handleDoubleClick={handleDoubleClick}
          fetchMoreData={fetchMoreData}
          numSelected={numSelected}
          isSelected={isSelected}
        />
      ) : Object.keys(dataList).length > 0 && search.trim() === '' ? (
        <List
          dataList={dataList}
          handleToggle={handleToggle}
          handleDoubleClick={handleDoubleClick}
          fetchMoreData={fetchMoreData}
          handleDoubleClickAll={handleDoubleClickAll}
          handleToggleAll={handleToggleAll}
          numSelected={numSelected}
          isSelected={isSelected}
          search={search}
          handleChangeAccordion={handleChangeAccordion}
          expanded={expanded}
          handleToggleAccordion={handleToggleAccordion}
          checkedAccordion={checkedAccordion}
        />
      ) : (
        <Typography variant="caption" display="block" gutterBottom>
          Pas de données
        </Typography>
      )}
    </>
  )
}

SuggestionsListComponent.propTypes = {
  handleToggle: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  dataList: PropTypes.object,
  fetchMoreData: PropTypes.func,
  handleDoubleClickAll: PropTypes.func,
  handleToggleAll: PropTypes.func,
  numSelected: PropTypes.bool,
  isSelected: PropTypes.func,
  search: PropTypes.string,
  dataSearch: PropTypes.array,
  isSearchLoading: PropTypes.bool,
  handleChangeAccordion: PropTypes.func,
  expanded: PropTypes.string,
  handleToggleAccordion: PropTypes.func,
  checkedAccordion: PropTypes.func,
}
