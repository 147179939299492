import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import Tooltip from '@mui/material/Tooltip/Tooltip'

export default function TextAdresse({ label, value, getColor, id }) {
  const handleClick = () => {
    window.open(
      `https://www.google.com/maps?q=${value.latitude},${value.longitude}&ll=${value.latitude},${value.longitude}&z=17`,
      '_blank'
    )
  }
  return (
    <dl>
      <dt>{label}</dt>
      {value?.address && (
        <Tooltip
          arrow
          title={<span className="tootltip-content">{value.address}</span>}
        >
          <dd className="address-text">
            <a
              id={id}
              className="address-link"
              onClick={() => handleClick()}
              style={{ color: getColor(value) }}
            >
              {value.address}
            </a>
          </dd>
        </Tooltip>
      )}
    </dl>
  )
}
TextAdresse.propTypes = {
  label: PropTypes.string,
  value: PropTypes.shape({
    address: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  getColor: PropTypes.func,
  id: PropTypes.string,
}
