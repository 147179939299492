import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'

import Button from '@mui/material/Button'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Error from '../../../../components/Modal/HttpErrorPopUp'
import EmptyTable from '../../../../components/EmptyTable/EmptyTable'
import moment from 'moment'
import DialogHistorique from './dialogHistorique'
import { styled } from '@mui/material/styles'

const headCells = [
  { id: 'id', label: 'Id' },
  { id: 'receptionNumber', label: 'Numéro de réception' },
  {
    id: 'createdAt',
    label: 'Date de création',
  },
  { id: 'receptionDate', label: 'Date de réception' },
  { id: 'historique', label: 'Historique' },
]

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {rowCount > 0 && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            style={{
              fontWeight: '600',
              fontSize: '12px',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export const StyledButton = styled(Button)(() => ({
  '&.MuiButton-root': {
    width: '90px !important',
    backgroundColor: 'white !important',
    color: '#1976D2 !important',
    textTransform: 'initial!important',
    '&:hover': {
      width: '90px !important',
      backgroundColor: 'white !important',
      color: '#1976D2 !important',
    },
  },
}))

const EnhancedTableToolbar = (props) => {
  const { numSelected, onClotureReception, isLoadingActionsFreights } = props

  return (
    <Toolbar
      sx={{
        paddingLeft: '16px !important',
        paddingRight: '20px !important',
        background: '#1976d2 !important',
        color: '#fff !important',
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100% !important' }}
          variant="h6"
          color="inherit"
          component="div"
        >
          {numSelected} sélectionné
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100% !important' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Tournée de réception
        </Typography>
      )}

      {numSelected > 0 && (
        <>
          {isLoadingActionsFreights ? (
            <div
              style={{
                display: 'inline-flex',
                marginRight: '35px',
                verticalAlign: 'middle',
              }}
            >
              <CircularProgress
                style={{ width: '30px', height: '30px', color: 'white' }}
              />
            </div>
          ) : (
            <StyledButton
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={onClotureReception}
            >
              Clôturer
            </StyledButton>
          )}
        </>
      )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onClotureReception: PropTypes.func,
  isLoadingActionsFreights: PropTypes.bool,
}

export const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
}))
export const StyledTableRow = styled(TableRow)(() => ({
  '&.Mui-selected': { backgroundColor: '#d6e5f9 !important' },
}))
const TableFreights = ({
  freightsList,
  isLoadingFreights,
  errorFreights,
  countFreights,
  pageLimitChanged,
  pageChanged,
  clotureReception,
  isActionsFreights,
  isLoadingActionsFreights,
  fetchFreights,
  fetchHistoriqueFreights,
  historiquesFreights,
  isLoadingHistoriquesFreights,
}) => {
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [openError, setOpenError] = useState(false)
  const [open, setOpen] = useState(false)
  console.log('freightsList', freightsList)
  const theme = useTheme()

  const onErrorClose = () => {
    setOpenError(false)
  }

  useEffect(() => {
    if (errorFreights) setOpenError(true)
  }, [errorFreights])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = freightsList.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    pageChanged(newPage * rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageLimitChanged(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const onClotureReception = () => {
    clotureReception(selected)
  }

  useEffect(() => {
    if (isActionsFreights) {
      fetchFreights()
      setSelected([])
    }
  }, [isActionsFreights])

  return (
    <div style={{ width: '100%' }}>
      <StyledPaper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onClotureReception={onClotureReception}
          isLoadingActionsFreights={isLoadingActionsFreights}
        />
        {isLoadingFreights ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '25px',
            }}
          >
            <CircularProgress
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          </div>
        ) : (
          <>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={freightsList.length}
                />
                {freightsList.length > 0 ? (
                  <TableBody>
                    {freightsList.map((row, index) => {
                      const isItemSelected = isSelected(row.id)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                              disabled={!row?.id}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="normale"
                          >
                            {row?.id || 'N/A'}
                          </TableCell>
                          <TableCell align="left">
                            {row?.receptionNumber || 'N/A'}
                          </TableCell>
                          <TableCell align="left">
                            {row?.createdAt
                              ? moment(row.createdAt).format('YYYY-MM-DD HH:mm')
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="left">
                            {row?.receptionDate
                              ? moment(row.receptionDate).format('YYYY-MM-DD HH:mm')
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="cen">
                            <InfoOutlinedIcon
                              style={{ cursor: 'pointer', marginLeft: '18px' }}
                              onClick={() => {
                                fetchHistoriqueFreights(row?.id)
                                setOpen(true)
                              }}
                              disabled={!row?.id}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                ) : (
                  <EmptyTable colspan={headCells.length + 1} />
                )}
              </Table>
            </TableContainer>
            {countFreights > 0 && (
              <TablePagination
                rowsPerPageOptions={[25, 50, 100, 200, { label: 'All', value: -1 }]}
                component="div"
                count={countFreights}
                rowsPerPage={rowsPerPage}
                page={page}
                // colSpan={headCells.length + 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </>
        )}
      </StyledPaper>
      {open && (
        <DialogHistorique
          historiques={historiquesFreights}
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          isLoadingHistoriquesFreights={isLoadingHistoriquesFreights}
        />
      )}

      {errorFreights && (
        <Error
          statusText={errorFreights?.statusText || errorFreights}
          open={openError}
          setOpen={onErrorClose}
        />
      )}
    </div>
  )
}

TableFreights.propTypes = {
  freightsList: PropTypes.array,
  isLoadingFreights: PropTypes.bool,
  errorFreights: PropTypes.string,
  countFreights: PropTypes.number,
  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,
  clotureReception: PropTypes.func,
  isActionsFreights: PropTypes.bool,
  isLoadingActionsFreights: PropTypes.bool,
  fetchFreights: PropTypes.func,
  fetchHistoriqueFreights: PropTypes.func,
  historiquesFreights: PropTypes.array,
  isLoadingHistoriquesFreights: PropTypes.bool,
}
export default TableFreights
