import { connect } from 'react-redux'
import SignautreLivraison from '../../../pages/main/Orders/DetailsDelivery/components/details/signatures'

import {
  onUploadSignature,
  setErrorFileUpload,
  getOrderSignatureByLdv,
} from '../../../redux/order/actions'
import {
  getLoadingUploadSignature,
  getErrorUploadFile,
  getOrderSignature,
  getLodingOrderSignature,
  getActionOrderSignatureDone,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingUploadSignature: getLoadingUploadSignature(state),
  errorFileUpload: getErrorUploadFile(state),
  orderSignature: getOrderSignature(state),
  isLoadingOrderSignature: getLodingOrderSignature(state),
  isActionOrderSignatureDone: getActionOrderSignatureDone(state),
})

const mapDisptachToProps = (dispatch) => ({
  onUploadSignature: (file, codeCourse) =>
    dispatch(onUploadSignature(file, codeCourse)),
  setErrorFileUpload: (isError) => dispatch(setErrorFileUpload(isError)),
  getOrderSignatureByLdv: (ldv) => dispatch(getOrderSignatureByLdv(ldv)),
})

export default connect(mapStateToProps, mapDisptachToProps)(SignautreLivraison)
