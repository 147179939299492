import { connect } from 'react-redux'
import StepperSection from '../../../pages/main/PlaceOrders/Stepper'

import { getStepsState, getActiveStepState } from '../../../redux/shipment/selectors'

const mapStateToProps = (state) => ({
  steps: getStepsState(state),
  activeStep: getActiveStepState(state),
})

export default connect(mapStateToProps)(StepperSection)
