import React from 'react'

import PropTypes from 'prop-types'

import ToggelButton from '../../../../../ToggelButton'

export default function ReplyFilter({
  filterStateChanged,
  replyFiltersValues,
  disabled,
}) {
  const handleChange = (event, newAlignment) => {
    filterStateChanged('reply', newAlignment ? [newAlignment] : [])
  }

  const items = [
    { label: 'Oui', value: 'true' },
    { label: 'Non', value: 'false' },
  ]

  return (
    <div
      style={{
        paddingLeft: '16px',
        paddingRight: '16px',
      }}
    >
      <span style={{ color: '#6D7B8E', verticalAlign: 'middle' }}>
        {'Répondue: '}
      </span>
      <ToggelButton
        value={replyFiltersValues && replyFiltersValues[0]}
        onChange={handleChange}
        items={items}
        disabled={disabled}
        labelId={'Réponse'}
      />
    </div>
  )
}
ReplyFilter.propTypes = {
  filterStateChanged: PropTypes.func,
  replyFiltersValues: PropTypes.array,
  disabled: PropTypes.bool,
}
