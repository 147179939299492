export default () => {
  return {
    id: null,
    subject: null,
    salesChannel: {},
    entries: [],
    conversationToUser: [],
    isLoading: false,
    error: null,
    loadingSendMessage: false,
    loadingUploadFile: false,
    errorUploadFile: false,
    filesUpload: [],
    errorMessageUploadFile: '',
    entrySendMessage: null,
  }
}
