import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'

export default function Search({
  setRecherch,
  setOffset,
  SearchChanged,
  resetSearch,
}) {
  const [valueInput, setValueInput] = useState(null)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (valueInput && valueInput?.length > 2) {
        setRecherch(true)
        SearchChanged(valueInput)
      } else if (valueInput?.length == 0) {
        SearchChanged('')
        setRecherch(false)
        setOffset(25)
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [valueInput])

  useEffect(() => {
    window.addEventListener('beforeunload', resetSearch)
    return () => {
      window.removeEventListener('beforeunload', resetSearch)
    }
  }, [])

  const searchMethode = (event) => {
    let val = event.target.value.trim()
    setValueInput(val)
  }
  return (
    <div
      style={{
        display: 'flex',
        padding: '0px 10px 5px',
        boxSizing: 'border-box',
        overflowY: 'auto',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          width: '100%',
          '@media (max-width: 1311px)': {
            width: '100%',
          },
          '@media (max-width: 600px)': {
            width: '70%',
          },
        }}
      >
        <TextField
          id={'discussion-search-input'}
          variant="outlined"
          placeholder={'Rechercher par LDV'}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
                borderColor: '#E2F0FE !important',
              },
            },
          }}
          value={valueInput}
          onChange={(e) => {
            searchMethode(e)
          }}
        />
      </div>
    </div>
  )
}
Search.propTypes = {
  setRecherch: PropTypes.func,
  setOffset: PropTypes.func,
  SearchChanged: PropTypes.func,
  resetSearch: PropTypes.func,
}
