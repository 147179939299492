import React from 'react'
import PropTypes from 'prop-types'

import Stepper from '../../../../components/Stepper'

import './style.scss'
//trigger fix
const StepperSection = ({ steps, activeStep }) => {
  return (
    <>
      {steps && steps.length && (
        <div className="place-order-stepper">
          <div className="stepper-containers">
            <Stepper steps={steps} activeStep={activeStep} />
          </div>
        </div>
      )}
    </>
  )
}
StepperSection.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number,
}
export default StepperSection
