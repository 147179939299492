import initialState from '../geoCachingAddress/intialState'
import {
  GET_CRENEAU_RECOMMENDED_DISPONIBILITES,
  GET_CRENEAU_RECOMMENDED_DISPONIBILITES_SUCCESS,
  GET_RECOMMENDED_CRENEAU,
  GET_RECOMMENDED_CRENEAU_FAILURE,
  GET_RECOMMENDED_CRENEAU_SUCCESS,
} from './actions'
import moment from 'moment-timezone'

export default (state = initialState, action) => {
  if (
    action.type === GET_RECOMMENDED_CRENEAU ||
    action.type === GET_CRENEAU_RECOMMENDED_DISPONIBILITES
  ) {
    return {
      ...state,
      recommendedCreneauList: [],
      isLoadingRecommendedCreneau: true,
    }
  }
  if (action.type === GET_RECOMMENDED_CRENEAU_SUCCESS) {
    return {
      ...state,
      recommendedCreneauList: action.payload.data.response,
      isLoadingRecommendedCreneau: false,
    }
  }

  if (action.type === GET_CRENEAU_RECOMMENDED_DISPONIBILITES_SUCCESS) {
    const { response } = action.payload.data

    const recommendedCreneauList = response.map((slot) => {
      return {
        timeSlotId: slot.timeSlotId,
        start: moment(moment.utc(slot.start, 'HH:mmZ').format()).format('HH:mm'),
        end: moment(moment.utc(slot.end, 'HH:mmZ').format()).format('HH:mm'),
        dateDemarrageMeta: {
          ...slot.dateDemarrageMeta,
          openTime: moment(
            moment.utc(slot.dateDemarrageMeta.openTime, 'HH:mmZ').format()
          ).format('HH:mm'),
          closeTime: moment(
            moment.utc(slot.dateDemarrageMeta.closeTime, 'HH:mmZ').format()
          ).format('HH:mm'),
        },
      }
    })

    return {
      ...state,
      recommendedCreneauList: recommendedCreneauList,
      isLoadingRecommendedCreneau: false,
    }
  }

  if (action.type === GET_RECOMMENDED_CRENEAU_FAILURE) {
    return {
      ...state,
      recommendedCreneauList: [],
      isLoadingRecommendedCreneau: false,
    }
  }
  return state
}
