import { connect } from 'react-redux'

import SalesChannelModal from '../../../pages/settings/components/Modals/CanalModal'
import {
  getDriversOptimiserIsLoading,
  getDriversOptimiserCount,
} from '../../../redux/driver/selectors'

import {
  assignToChannel,
  fetchKeys,
  getKeysMore,
  onSearchKeys,
  pageChanged,
} from '../../../redux/configuration/actions'
import {
  getConfigSuccess,
  getErrorMessageConfig,
  getIsLoadingAssign,
  getKeys,
  getKeysCount,
  getKeysListIsLoading,
} from '../../../redux/configuration/selectors'
import { getSalesChannelListRequest } from '../../../redux/filter/actions'
import { getSalesChannelList } from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  salesChannels: getSalesChannelList(state),
  isLoadingDrivers: getDriversOptimiserIsLoading(state),
  salesChannelsCount: getDriversOptimiserCount(state),
  keys: getKeys(state),
  isLoadingAssign: getIsLoadingAssign(state),
  success: getConfigSuccess(state),
  errorMessage: getErrorMessageConfig(state),
  isLoadingKeysList: getKeysListIsLoading(state),
  keysCount: getKeysCount(state),
})

const mapDisptachToProps = (dispatch) => ({
  getSalesChannels: () => dispatch(getSalesChannelListRequest()),
  fetchKeys: () => dispatch(fetchKeys()),
  searchKeys: (search) => {
    dispatch(onSearchKeys(search))
    dispatch(pageChanged(0))
    dispatch(fetchKeys())
  },
  fetchMoreKeys: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(getKeysMore())
  },
  assign: (data) => dispatch(assignToChannel(data)),
})

export default connect(mapStateToProps, mapDisptachToProps)(SalesChannelModal)
