import { connect } from 'react-redux'
import CreationFilter from '../../../components/NavFilter/components/Filter/creationFilter'

import { onCreateFilter } from '../../../redux/filter/actions'
import {
  getIsLoadingActionFilter,
  getErrorActionFilter,
  getIsActionCreationFilter,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionFilter: getIsLoadingActionFilter(state),
  errorActionFilter: getErrorActionFilter(state),
  isActionCreationFilter: getIsActionCreationFilter(state),
})

const mapDisptachToProps = (dispatch) => ({
  onCreateFilter: (payload) => dispatch(onCreateFilter(payload)),
})

export default connect(mapStateToProps, mapDisptachToProps)(CreationFilter)
