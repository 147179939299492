import { AVAILABLE_EXTENSION, SIZE_FILE } from '../values'

export const getExtension = (fileName) => {
  const extension = fileName.split('.').pop() || ''
  return extension.toLowerCase()
}

const getSize = (size) => {
  return size
}

const validateFileName = (files) => {
  return files.every((file) => file.name == null || file.name == undefined)
}
const validateFileExtension = (files) => {
  return files
    .map((file) => getExtension(file?.name))
    .every(
      (extension) =>
        AVAILABLE_EXTENSION.IMAGE.includes(extension) ||
        AVAILABLE_EXTENSION.DOCUMENTS.includes(extension)
    )
}
const validateImageExtension = (files) => {
  return files
    .map((file) => getExtension(file?.name))
    .every((extension) => AVAILABLE_EXTENSION.IMAGE.includes(extension))
}
const validateFileSize = (files) => {
  return files
    .map((file) => getSize(file?.size))
    .every((size) => (!size && size != 0) || size > SIZE_FILE)
}
export const validateUploadFile = (files) => {
  if (
    files == null ||
    files == undefined ||
    files.length <= 0 ||
    validateFileName(files)
  ) {
    return {
      isError: true,
      message: 'Fichier non valide',
    }
  }

  const validExtension = validateFileExtension(files)
  const valideSize = validateFileSize(files)

  if (valideSize) {
    return {
      isError: true,
      message: "Taille maximale d'upload a été dépassée",
    }
  }
  if (!validExtension) {
    return {
      isError: true,
      message: 'Extension de fichier non valide',
    }
  }

  return {
    isError: false,
    message: 'Uploade avec succès',
  }
}

export const validateUploadImage = (files) => {
  if (
    files == null ||
    files == undefined ||
    files.length <= 0 ||
    validateFileName(files)
  ) {
    return {
      isError: true,
      message: 'Fichier non valide',
    }
  }

  const validExtension = validateImageExtension(files)
  const valideSize = validateFileSize(files)

  if (valideSize) {
    return {
      isError: true,
      message: "Taille maximale d'upload a été dépassée",
    }
  }
  if (!validExtension) {
    return {
      isError: true,
      message: 'Extension de fichier non valide',
    }
  }

  return {
    isError: false,
    message: 'Uploade avec succès',
  }
}
