export const getConfigrationState = ({ configuration }) => configuration

export const getKeysIsLoading = (state) => getConfigrationState(state).isLoadingkeys
export const getConfigSuccess = (state) => getConfigrationState(state).success
export const getErrorMessageConfig = (state) =>
  getConfigrationState(state).errorMessage
export const getKeys = (state) => getConfigrationState(state).keys
export const getKeysCount = (state) => getConfigrationState(state).keysCount

export const getKeysListIsLoading = (state) =>
  getConfigrationState(state).isLoadingkeysList
export const getIsLoadingAssign = (state) =>
  getConfigrationState(state).isLoadingAssign

export const getIsLoadingUnassign = (state) =>
  getConfigrationState(state).isLoadingUnassign

export const getAssignedChannel = (state) => getConfigrationState(state).salesChannel
export const getAssignedChannelCount = (state) =>
  getConfigrationState(state).salesChannelCount

export const getAssignedChannelIsloading = (state) =>
  getConfigrationState(state).isLoadingChannelList
export const getAssignedDrivers = (state) => getConfigrationState(state).drivers
export const getAssignedDriverCount = (state) =>
  getConfigrationState(state).driverCount

export const getAssignedDriverIsloading = (state) =>
  getConfigrationState(state).isLoadingDriverList

export const getIsErrorFetch = (state) => getConfigrationState(state).isErrorFetch

export const getIsSuccessRefresh = (state) =>
  getConfigrationState(state).isSuccessRefresh

export const getKeyCreatedObject = (state) =>
  getConfigrationState(state).keyCreatedObject
