import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'

import ListMenu from '../../containers/settings/sidebar/listMenu'
import { useLocation } from 'react-router-dom'
import { settingsSidebarLinks } from './sidebarItemsData'
import { isPathInRoutes } from '../../utils/roleManagement'

const drawerWidth = 273

export default function Sidebar({ component, userRole }) {
  const location = useLocation()
  return (
    <Box
      sx={{
        height: 'calc(100vh - 55px)',
        '@media (min-width: 1200px)': {
          display: 'flex',
        },
      }}
    >
      {isPathInRoutes(settingsSidebarLinks, location.pathname, userRole) && (
        <Box
          component="nav"
          sx={{
            position: 'sticky',
            background: '#ffff',
            top: 0,
            display: 'flex',
            alignItems: 'flex-start',
            width: { lg: drawerWidth },
            flexShrink: { lg: 0 },
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', lg: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundColor: '#fff !important',
              },
            }}
            open
          >
            <ListMenu />
          </Drawer>
        </Box>
      )}

      <Box
        component="main"
        sx={{
          background: '#ffff',
          padding: '24px',
          flexGrow: 1,
          p: 3,
          width: { lg: `calc(80% - ${drawerWidth}px)` },
        }}
      >
        {component}{' '}
      </Box>
    </Box>
  )
}
Sidebar.propTypes = {
  window: PropTypes.func,
  component: PropTypes.func,
  userRole: PropTypes.string,
}
