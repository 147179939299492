import initialState from './initialState'
import {
  GET_ENTRY_CONVERSATION_LIST,
  GET_ENTRY_CONVERSATION_LIST_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_FAILURE,
  RECEIVE_DATA_FROM_CONNVERSATION_TOPIC,
  RECEIVE_DATA_FROM_CONNVERSATION_DELIVERY_TOPIC,
  CLEAN_CONVERSATION_LIST,
  SOCKET_REGISTRED,
  SOCKET_NOT_REGISTRED,
  GET_NOTIFICATION_CONVERSATION_LIST_SUCESS,
  GET_NOTIFICATION_CONVERSATION_LIST_FAILURE,
  RECEIVE_REMOVE_NOTIFICATION,
  RECEIVE_ADD_NOTIFICATION,
  NOT_FOCUS_NOTIFICATION,
  FOCUS_NOTIFICATION,
  GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_FAILURE,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_FAILURE,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_FAILURE,
  LOADING_OPEN_CLOSE_CONVERSATION,
  GET_ENTRY_CONVERSATION_LIST_MORE,
  GET_ENTRY_CONVERSATION_LIST_MORE_SUCESS,
  GET_ENTRY_CONVERSATION_LIST_MORE_FAILURE,
  SET_ID_NEW_CONVERSATION,
  REMOVE_ID_NEW_CONVERSATION,
  SET_ID_CONVERSATION,
  REMOVE_ID_CONVERSATION,
  GET_SUBJECT_CONVERSATION,
  GET_SUBJECT_CONVERSATION_SUCESS,
  GET_SUBJECT_CONVERSATION_FAILURE,
  GET_ENTRY_CONVERSATION_LIST_BY_SEARCH,
} from './constants'

export default (state = initialState(), action) => {
  if (action.type === GET_ENTRY_CONVERSATION_LIST) {
    return {
      ...state,
      isLoading: true,
      countList: 0,
      loadingOpenCloseConversation: false,
    }
  }
  if (action.type === GET_ENTRY_CONVERSATION_LIST_DELIVERY) {
    return {
      ...state,
      isLoading: true,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_SUCESS) {
    let { data } = action.payload.data
    return {
      ...state,
      data,
      countList: action.payload.data?.count || 0,
      isLoading: false,
    }
  }
  if (action.type === GET_ENTRY_CONVERSATION_LIST_FAILURE) {
    const data = action.payload.data?.data
    return {
      ...state,
      isLoading: false,
      error: data?.message,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_MORE) {
    return {
      ...state,
      isLoadingConvMore: true,
    }
  }
  if (action.type === GET_ENTRY_CONVERSATION_LIST_MORE_SUCESS) {
    let { data } = action.payload.data

    return {
      ...state,
      data: [...state.data, ...data],
      countList: action.payload.data?.count || 0,
      isLoadingConvMore: false,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_MORE_FAILURE) {
    const data = action.payload.data?.data
    return {
      ...state,
      isLoadingConvMore: false,
      error: data?.message,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_DELIVERY_SUCESS) {
    let data = action.payload.data?.data

    return {
      ...state,
      conversationLivraison: data,
      count: action.payload.data?.count || 0,
      isLoading: false,
    }
  }
  if (action.type === GET_ENTRY_CONVERSATION_LIST_DELIVERY_FAILURE) {
    const data = action.payload.data?.data
    return {
      ...state,
      isLoading: false,
      error: data?.message,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE) {
    return {
      ...state,
      isLoadingMore: true,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_SUCESS) {
    let data = action.payload.data?.data

    return {
      ...state,
      conversationLivraison: [...state.conversationLivraison, ...data],
      count: action.payload.data?.count || 0,
      isLoadingMore: false,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE_FAILURE) {
    const data = action.payload.data?.data
    return {
      ...state,
      isLoadingMore: false,
      error: data?.message,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_BY_SEARCH) {
    return {
      ...state,
      isLoading: false,
      loadingOpenCloseConversation: false,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_SUCESS) {
    let data = action.payload.data
    return {
      ...state,
      data,
      isLoading: false,
    }
  }

  if (action.type === GET_ENTRY_CONVERSATION_LIST_BY_SEARCH_FAILURE) {
    const data = action.payload.data?.data
    return {
      ...state,
      isLoading: false,
      error: data?.message,
    }
  }

  if (action.type === RECEIVE_DATA_FROM_CONNVERSATION_TOPIC) {
    const dataReceived = action.payload.data
    let connectedUser = JSON.parse(localStorage.getItem('currentUser'))

    if (
      dataReceived?.lastMessage != null &&
      dataReceived?.lastMessage != undefined
    ) {
      let conversationState = state.data.reduce((a, v) => ({ ...a, [v.id]: v }), {})
      let keysID = Object.keys(conversationState)

      if (keysID.includes(dataReceived.id)) {
        conversationState[dataReceived.id] = {
          ...conversationState[dataReceived.id],
          lastMessage: dataReceived.lastMessage,
          lastActionTime: dataReceived.lastActionTime,
          updatedAt: dataReceived.updatedAt,
          isOpen: dataReceived.isOpen,
        }
      } else {
        const userNotif = dataReceived.conversationToUser.filter(
          (conver) => conver.user.legacyID == connectedUser.id
        )
        dataReceived.notSeen = userNotif[0].notSeen
        conversationState[dataReceived.id] = dataReceived
      }

      let data = Object.values(conversationState)
      return {
        ...state,
        data,
      }
    }
  }

  if (action.type === RECEIVE_DATA_FROM_CONNVERSATION_DELIVERY_TOPIC) {
    const dataReceived = action.payload.data
    const ldv = action.payload.ldv
    let connectedUser = JSON.parse(localStorage.getItem('currentUser'))

    if (
      ldv &&
      dataReceived.ldv === ldv &&
      dataReceived.lastMessage != null &&
      dataReceived.lastMessage != undefined
    ) {
      let conversationState = state.conversationLivraison.reduce(
        (a, v) => ({ ...a, [v.id]: v }),
        {}
      )
      let keysID = Object.keys(conversationState)

      if (keysID.includes(dataReceived.id)) {
        conversationState[dataReceived.id] = {
          ...conversationState[dataReceived.id],
          lastMessage: dataReceived.lastMessage,
          lastActionTime: dataReceived.lastActionTime,
          updatedAt: dataReceived.updatedAt,
          isOpen: dataReceived.isOpen,
        }
      } else {
        const userNotif = dataReceived.conversationToUser.filter(
          (conver) => conver.user.legacyID == connectedUser.id
        )
        dataReceived.notSeen = userNotif[0].notSeen
        conversationState[dataReceived.id] = dataReceived
      }

      let data = Object.values(conversationState)

      return {
        ...state,
        conversationLivraison: [...data],
      }
    }
  }

  if (action.type === CLEAN_CONVERSATION_LIST) {
    return {
      ...state,
      data: [],
      isLoading: false,
      error: null,
    }
  }
  if (action.type === SOCKET_REGISTRED) {
    return {
      ...state,
      socketNotRegistred: false,
    }
  }
  if (action.type === SOCKET_NOT_REGISTRED) {
    return {
      ...state,
      socketNotRegistred: true,
    }
  }
  if (action.type === GET_NOTIFICATION_CONVERSATION_LIST_SUCESS) {
    return {
      ...state,
      notifications: action.payload.data,
      nbNotif: action.payload.data.length,
    }
  }
  if (action.type === GET_NOTIFICATION_CONVERSATION_LIST_FAILURE) {
    const data = action.payload.data.data

    return {
      ...state,
      error: data?.message,
    }
  }

  if (action.type === RECEIVE_ADD_NOTIFICATION) {
    // Add notification to table
    const notification = action.payload.notification

    let notificationState = state.notifications.reduce(
      (a, v) => ({ ...a, [v.conversationID]: v }),
      {}
    )
    notificationState[notification.conversationID] = notification
    let notifications = Object.values(notificationState)

    //AllConversation
    let data = state.data
    if (state.data.length > 0) {
      let conversationsState = state.data.reduce((a, v) => ({ ...a, [v.id]: v }), {})
      let keysID = Object.keys(conversationsState)

      if (keysID.includes(notification.conversationID)) {
        conversationsState[notification.conversationID].notSeen =
          conversationsState[notification.conversationID].notSeen + 1
        data = Object.values(conversationsState)
      }
    }
    //ConversationByLDV
    let conversationsByLDV = state.conversationLivraison
    if (state.conversationLivraison.length > 0) {
      if (state.conversationLivraison[0].ldv === notification.ldv) {
        let conversationsLDV = state.conversationLivraison.reduce(
          (a, v) => ({ ...a, [v.id]: v }),
          {}
        )
        conversationsLDV[notification.conversationID].notSeen =
          conversationsLDV[notification.conversationID].notSeen + 1
        conversationsByLDV = Object.values(conversationsLDV)
      }
    }

    return {
      ...state,
      notifications: notifications,
      nbNotif: notifications.length,
      data: data,
      conversationLivraison: conversationsByLDV,
    }
  }

  if (action.type === RECEIVE_REMOVE_NOTIFICATION) {
    const notification = action.payload.notification
    //notifications
    let notificationState = state.notifications.reduce(
      (a, v) => ({ ...a, [v.conversationID]: v }),
      {}
    )
    delete notificationState[notification.conversationID]
    let notifications = Object.values(notificationState)

    //AllConversation
    let conversations = state.data
    if (state.data.length > 0) {
      let conversationsState = state.data.reduce((a, v) => ({ ...a, [v.id]: v }), {})
      conversationsState[notification.conversationID].notSeen = 0
      conversations = Object.values(conversationsState)
    }
    //ConversationByLDV
    let conversationsByLDV = state.conversationLivraison
    if (state.conversationLivraison.length > 0) {
      if (state.conversationLivraison[0].ldv === notification.ldv) {
        let conversationsByLDVState = state.conversationLivraison.reduce(
          (a, v) => ({ ...a, [v.id]: v }),
          {}
        )
        conversationsByLDVState[notification.conversationID].notSeen = 0
        conversationsByLDV = Object.values(conversationsByLDVState)
      }
    }
    return {
      ...state,
      notifications: notifications,
      nbNotif: notifications.length,
      data: [...conversations],
      conversationLivraison: [...conversationsByLDV],
    }
  }

  if (action.type === FOCUS_NOTIFICATION) {
    return {
      ...state,
      focus: true,
    }
  }

  if (action.type === NOT_FOCUS_NOTIFICATION) {
    return {
      ...state,
      focus: false,
    }
  }

  if (action.type === LOADING_OPEN_CLOSE_CONVERSATION) {
    const { loadingOpenCloseConversation } = action.payload

    return {
      ...state,
      loadingOpenCloseConversation,
    }
  }

  if (action.type === SET_ID_NEW_CONVERSATION) {
    const { idConversation } = action.payload

    return {
      ...state,
      idConversationNew: idConversation,
    }
  }

  if (action.type === REMOVE_ID_NEW_CONVERSATION) {
    return {
      ...state,
      idConversationNew: null,
    }
  }

  if (action.type === SET_ID_CONVERSATION) {
    const { idConversation } = action.payload

    return {
      ...state,
      idConversation: idConversation,
    }
  }

  if (action.type === REMOVE_ID_CONVERSATION) {
    return {
      ...state,
      idConversation: null,
    }
  }

  if (action.type === GET_SUBJECT_CONVERSATION) {
    return {
      ...state,
      isLoadingSubjectConversation: true,
    }
  }

  if (action.type === GET_SUBJECT_CONVERSATION_SUCESS) {
    const { response } = action.payload.data
    return {
      ...state,
      subjectConversation: response,
      isLoadingSubjectConversation: false,
    }
  }

  if (action.type === GET_SUBJECT_CONVERSATION_FAILURE) {
    return {
      ...state,
      isLoadingSubjectConversation: false,
    }
  }
  return state
}
