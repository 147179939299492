import React from 'react'

export function MdiCalendarMultiselectOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7 11h2v2H7v-2m12-8h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m0 2v2H5V5h14M5 19V9h14v10H5m6-4h2v2h-2v-2m4 0h2v2h-2v-2m0-4h2v2h-2v-2Z"
      ></path>
    </svg>
  )
}
export default MdiCalendarMultiselectOutline
