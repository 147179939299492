import { connect } from 'react-redux'
import Plannification from '../../../pages/main/Programmer/Plannification'
import {
  changeToursTypeState,
  createTours,
  handleCheck,
  handleCheckAll,
  recalculateTours,
  resetCheckedAll,
  simulateTours,
  viewPlannification,
  removeError,
  activeTourAction,
  exportTours,
  fetchCourses,
  recalculateUnserved,
} from '../../../redux/simulateTournee/actions'
import {
  getCheckedAllOrder,
  getFleet,
  getNbOrderChecked,
  getSimulationTours,
  getSimulationUnserved,
  getTourType,
  getSimulationsIsLoading,
  getSimulationIsError,
  getSimulationsErrorMessage,
  getIsErrorAffectPlanning,
  getIsOptimisationCoursesFiles,
  getfilterCourses,
  getSelectedCourses,
  getFocusedTours,
} from '../../../redux/simulateTournee/selectors'

const mapStateToProps = (state) => ({
  unservedTours: getSimulationUnserved(state),
  planning: getSimulationTours(state),
  fleet: getFleet(state),
  checkedAllOrder: getCheckedAllOrder(state),
  nbOrderChecked: getNbOrderChecked(state),
  isOnePickUp: getTourType(state),
  isSimLoading: getSimulationsIsLoading(state),
  isSimError: getSimulationIsError(state),
  simulationErrorMessage: getSimulationsErrorMessage(state),
  isErrorAffectPlanning: getIsErrorAffectPlanning(state),
  isOptimisationCoursesFiles: getIsOptimisationCoursesFiles(state),
  filtersValues: getfilterCourses(state),
  selectedCourses: getSelectedCourses(state),
  focusedTours: getFocusedTours(state),
})

const mapDisptachToProps = (dispatch) => ({
  createTours: (data) => dispatch(createTours(data)),
  handleToursReOrder: (simTours, deliveryDate, isOnePickUp, isFile) => {
    dispatch(recalculateTours(simTours, deliveryDate, isOnePickUp, isFile))
  },
  changeToursTypeState: (updatedTours) => {
    dispatch(changeToursTypeState(updatedTours))
  },
  simulateTours: (data, isOnePickUp) => dispatch(simulateTours(data, isOnePickUp)),
  handleCheckAll: (checkedAll) => dispatch(handleCheckAll(checkedAll)),
  handleCheck: (id) => dispatch(handleCheck(id)),

  resetCheckedAll: () => dispatch(resetCheckedAll()),
  viewPlannification: (viewPlannif) => dispatch(viewPlannification(viewPlannif)),
  removeError: (isViewPlannification) => dispatch(removeError(isViewPlannification)),
  activeTourAction: () => dispatch(activeTourAction()),
  exportTours: (data) => dispatch(exportTours(data)),
  fetchCourses: (courses) => dispatch(fetchCourses(courses)),
  recalculateUnserved: (courses) => dispatch(recalculateUnserved(courses)),
})
export default connect(mapStateToProps, mapDisptachToProps)(Plannification)
