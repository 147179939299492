import _ from 'lodash'

import initialState from './initialState'
import {
  SIMULATE_TOURS,
  SIMULATE_TOURS_FAILURE,
  SIMULATE_TOURS_SUCCESS,
  CREATE_TOURS,
  CREATE_TOURS_FAILURE,
  CREATE_TOURS_SUCCESS,
  UPDATE_FOCUSED_TOURS,
  RECALCULATE_TOURS_FAILURE,
  RECALCULATE_TOURS_SUCCESS,
  RECALCULATE_TOURS,
  RECALCULATE_UNSERVED,
  UPDATE_ORDERS_ARRAY,
  GET_DRIVERS_PLANNINGS,
  GET_DRIVERS_PLANNINGS_SUCCESS,
  GET_DRIVERS_PLANNINGS_FAILURE,
  AFFECT_TOUR,
  AFFECT_TOUR_SUCCESS,
  AFFECT_TOUR_FAILURE,
  REMOVE_ERROR_PLANNIFICATION,
  CHECKED_ALL_ORDERS,
  CHECKED_ORDER,
  RESET_CHECKED_ORDER,
  CHECKED_PLANNING,
  CHECKED_VISIT,
  CHANGE_TOURS_TYPE_STATE,
  VIEW_PLANNIFICATION,
  UPDATE_COURSE_POLARIS,
  UPDATE_COURSE_POLARIS_SUCCESS,
  UPDATE_COURSE_POLARIS_FAILURE,
  ACTION_UPDATE_COURSE_POLARIS,
  RESET_SIMULATE_ACTION,
  GET_TOURS_FROM_POLARIS,
  GET_TOURS_FROM_POLARIS_SUCCESS,
  GET_TOURS_FROM_POLARIS_FAILURE,
  DELETE_TOUR_FROM_POLARIS,
  DELETE_TOUR_FROM_POLARIS_SUCCESS,
  DELETE_TOUR_FROM_POLARIS_FAILURE,
  RESET_TOUR_ACTION,
  ACTIVE_TOUR_ACTION,
  OPTIMISATION_COURSES,
  OPTIMISATION_COURSES_SUCCESS,
  OPTIMISATION_COURSES_FAILURE,
  GET_WAREHOUSES,
  GET_WAREHOUSES_SUCCESS,
  GET_WAREHOUSES_FAILURE,
  GET_WAREHOUSES_MORE_SUCCESS,
  GET_WAREHOUSES_MORE_FAILURE,
  CHANGE_FILTER_OFFSET,
  SEARCH_WAHREHOUSES,
  RESET_WAHREHOUSES,
  UPDATE_COURSE_OPTIMISATION_FILE,
  UPDATE_COURSE_OPTIMISATION_FILE_SUCCESS,
  UPDATE_COURSE_OPTIMISATION_FILE_FAILURE,
  EXPORT_TOURS,
  EXPORT_TOURS_SUCCESS,
  EXPORT_TOURS_FAILURE,
  FETCH_COURSES,
  MAP_ADD_SELECTED_COURSES,
  MAP_REMOVE_SELECTED_COURSES,
  MAP_REMOVE_ALL_SELECTED_COURSES,
  VERROUILLE_TOURNEE,
  DEVERROUILLE_TOURNEE,
} from './actionsConsts'

import {
  getRoutificMessageError,
  //fillEmptyDriverPlanning,
  getPlanningData,
  getToursData,
  newPlannings,
  setChecked,
  fleetParseObject,
  parseCoursesOptimisationObject,
  parseFilterTournee,
} from '../../utils/tours'
import { getFilterfromCourse, removeNull } from '../../utils/utils'
import { ACCURATE_ADDRESS } from '../../utils/values'
export const LDV_TYPE = 'LDV'

export default (state = initialState, action) => {
  //Get Tours by drivers
  if (action.type === GET_DRIVERS_PLANNINGS) {
    return {
      ...state,
      isLoading: true,
      tours: [],
      errorMessage: '',
      isErrorAffectPlanning: false,
      isViewPlannification: true,
      tourAction: false,
    }
  }

  if (action.type === GET_DRIVERS_PLANNINGS_SUCCESS) {
    const drivers = action.payload.data.secData.drivers
    const tours = newPlannings(
      getPlanningData(
        action.payload.data.data,
        action.payload.data.secData.deliveryDate
      ),
      drivers,
      action.payload.data.secData.deliveryDate
    )
    const newUnservedOrders = state.unservedOrders
    tours.forEach((tour) => {
      tour.markers.forEach((marker) => {
        if (Object.keys(newUnservedOrders).includes(marker.location_id)) {
          delete newUnservedOrders[marker.location_id]
        }
      })
    })
    const tourType = []
    tours.forEach((tour) => tourType.push(tour.tourType))
    // check if driver havn't empty tours
    // check if tourTypes is all the same
    if (tourType.filter((obj) => obj).some((v, i, arr) => v != arr[0])) {
      return {
        ...state,
        isLoading: false,
        tours: [],
        errorMessage: {
          message:
            'Vous avez choisis des tournées avec différent type, Merci de vérifier',
        },
        isErrorAffectPlanning: true,
      }
    }
    return {
      ...state,
      isLoading: false,
      tours: tours,
      focusedTours: [],
      fleet: action.payload.data.secData,
      deliveryDate: action.payload.data.secData.deliveryDate,
      errorMessage: '',
      unservedOrders: newUnservedOrders,
      unservedOrdersOriginal: newUnservedOrders,
      isErrorAffectPlanning: false,
      isOnePickUp:
        tours.length > 0
          ? tours[0]?.tourType === 'ONE_PICK_UP'
            ? true
            : false
          : false,
    }
  }

  if (action.type === GET_DRIVERS_PLANNINGS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      tours: [],
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isErrorAffectPlanning: true,
    }
  }

  //Affect Tous to Drivers
  if (action.type === AFFECT_TOUR) {
    return {
      ...state,
      isLoading: true,
      errorMessage: '',
      isErrorAffectPlanning: false,
      isViewPlannification: true,
      tourAction: false,
    }
  }

  if (action.type === AFFECT_TOUR_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      isErrorAffectPlanning: false,
      isViewPlannification: false,
      isOptimisationCoursesFiles: false,
      filterCourseFile: {},
      tourAction: true,
      fileNameCoursesOptimisation: '',
    }
  }

  if (action.type === AFFECT_TOUR_FAILURE) {
    return {
      ...state,
      isLoading: false,
      isErrorAffectPlanning: true,
      errorMessage: { message: "une erreur s'est produite" },
      tourAction: false,
    }
  }

  // recalculate tours
  if (action.type === RECALCULATE_TOURS) {
    return {
      ...state,
      isLoading: true,
    }
  }

  if (action.type === RECALCULATE_TOURS_SUCCESS) {
    // send tables planning to recalculate and return result and parse it via getTourData
    const changedTours = Object.assign([{ tours: action.payload.data }])
    const driverTours = [...state.tours.flat()].map((s) => {
      return { driverId: s.driver.id, ldt: s.ldt }
    })

    const changedToursParsed = getToursData(
      changedTours,
      state.deliveryDate,
      driverTours
    )

    let newTours = _.cloneDeep(state.tours)

    changedToursParsed.flat(1).map((changedtour) => {
      const tourChangedIndex = state.tours
        .map((el) => el.driver.id)
        .indexOf(changedtour.driver.id)
      newTours[tourChangedIndex] = changedtour
    })

    const newUnservedOrdersOriginal = state.unservedOrdersOriginal
    newTours.forEach((tour) => {
      tour.markers.forEach((marker) => {
        if (Object.keys(newUnservedOrdersOriginal).includes(marker.location_id)) {
          delete newUnservedOrdersOriginal[marker.location_id]
        }
      })
    })

    let newUnservedOrders = {}
    let nbChecked = 0
    let checkedAll = state.checkedAllOrder

    if (Object.keys(removeNull(state.filterCourses)).length != 0) {
      newUnservedOrdersOriginal &&
        Object.keys(newUnservedOrdersOriginal).length > 0 &&
        Object.keys(newUnservedOrdersOriginal).map((e) => {
          let matchingKeys = newUnservedOrdersOriginal[e].dropOffVisit.keys.filter(
            (val) =>
              state.filterCourses?.keys
                ? state.filterCourses.keys.includes(val)
                : true
          )
          let matchingCodePostal = state.filterCourses?.codePostale
            ? state.filterCourses.codePostale.includes(
                newUnservedOrdersOriginal[e].dropOffVisit.codePostale
              )
            : true

          if (matchingKeys.length > 0 && matchingCodePostal)
            newUnservedOrders[e] = newUnservedOrdersOriginal[e]
        })
      Object.keys(newUnservedOrders).map((code) => {
        if (newUnservedOrders[code].pickUpVisit.checked) nbChecked++
      })
      if (nbChecked === Object.keys(newUnservedOrders).length) checkedAll = true
    } else {
      newUnservedOrders = newUnservedOrdersOriginal
    }
    return {
      ...state,
      tours: newTours,
      unservedOrdersOriginal: newUnservedOrdersOriginal,
      unservedOrders: newUnservedOrders,
      isLoading: false,
      checkedAllOrder: checkedAll,
      nbOrderChecked: nbChecked,
    }
  }

  if (action.type === RECALCULATE_TOURS_FAILURE) {
    return {
      ...state,
      errorMessage: {
        message: action.payload.data.response?.data.message
          ? action.payload.data.response?.data.message
          : 'Probléme Serveur, veuillez réessayer plus tard',
      },
      isError: true,
      isLoading: false,
    }
  }

  //optimiser tours
  if (action.type === SIMULATE_TOURS) {
    return {
      ...state,
      isLoading: true,
      oldTours: [...state.tours],
      tours: [],
      errorMessage: '',
      isError: false,
    }
  }

  if (action.type === SIMULATE_TOURS_SUCCESS) {
    // weight
    // montantPrestaHT
    const driverTours = [...state.oldTours].map((s) => {
      return { driverId: s.driver.id, ldt: s.ldt }
    })

    const tours = getToursData(action.payload.data, state.deliveryDate, driverTours)
    const newUnservedOrders = action.payload.data.map((e) =>
      e.unservedOrders ? setChecked(e.unservedOrders) : {}
    )
    let unservedOrdersNotChecked = state.unservedOrders
    Object.keys(state.unservedOrders).map((code) => {
      if (state.unservedOrders[code].dropOffVisit.checked)
        delete unservedOrdersNotChecked[code]
    })

    let unservedOrdersOriginalNotChecked = state.unservedOrdersOriginal
    Object.keys(state.unservedOrdersOriginal).map((code) => {
      if (state.unservedOrdersOriginal[code].dropOffVisit.checked)
        delete unservedOrdersOriginalNotChecked[code]
    })
    // V2 : the optimisation is applied on the chosen drivers
    let toursV2 = state.oldTours

    // we process to merge optimised tours on the chosen drivers with non optimised (unchosen drivers)
    tours[0].forEach((tour) => {
      const indexTourToChange = state.oldTours.findIndex(
        (oldTour) => oldTour.driver.id === tour.driver.id
      )

      if (indexTourToChange !== -1) {
        toursV2[indexTourToChange] = tour
      }
    })

    return {
      ...state,
      isLoading: false,
      tours: toursV2,
      oldTours: [],
      unservedOrders: {
        ...newUnservedOrders[0],
        ...unservedOrdersNotChecked,
      },
      unservedOrdersOriginal: {
        ...newUnservedOrders[0],
        ...unservedOrdersOriginalNotChecked,
      },
      checkedAllOrder: false,
      nbOrderChecked: 0,
      selectedCourses: {},
    }
  }
  if (action.type === SIMULATE_TOURS_FAILURE) {
    const helperCode = action.payload.data?.response?.data?.errorHelperCode
    const helperVar = action.payload.data?.response?.data?.errorHelperVar

    return {
      ...state,
      isLoading: false,
      tours: state.oldTours,
      errorMessage: action.payload.data.response?.data?.errorHelperCode
        ? getRoutificMessageError(helperCode, helperVar)
        : {
            message: action.payload.data.response?.data.message
              ? action.payload.data.response?.data.message
              : 'Probléme serveur, veuillez réessayer plus tard',
          },
      isError: true,
    }
  }

  // Sauvegarder tours
  if (action.type === CREATE_TOURS) {
    return {
      ...state,
      isLoading: true,
      tourAction: false,
    }
  }
  if (action.type === CREATE_TOURS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isError: true,
      tourAction: false,
    }
  }
  if (action.type === CREATE_TOURS_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      tours: [],
      errorMessage: '',
      isError: false,
      unservedOrders: {},
      unservedOrdersOriginal: {},
      isViewPlannification: false,
      isOptimisationCoursesFiles: false,
      filterCourseFile: {},
      tourAction: true,
      fileNameCoursesOptimisation: '',
    }
  }

  // view plannfication
  if (action.type === VIEW_PLANNIFICATION) {
    const { viewPlannification } = action.payload
    if (!viewPlannification) {
      return {
        ...state,
        isViewPlannification: false,
        isOptimisationCoursesFiles: false,
        filterCourseFile: {},
        fileNameCoursesOptimisation: '',
      }
    }
    return {
      ...state,
      isViewPlannification: viewPlannification,
    }
  }

  //Remove Error
  if (action.type === REMOVE_ERROR_PLANNIFICATION) {
    const { isViewPlannification } = action.payload
    if (!isViewPlannification) {
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        isError: false,
        isErrorAffectPlanning: false,
        isViewPlannification: false,
        isOptimisationCoursesFiles: false,
        filterCourseFile: {},
        fileNameCoursesOptimisation: '',
      }
    }
    return {
      ...state,
      isLoading: false,
      errorMessage: '',
      isError: false,
      isErrorAffectPlanning: false,
      isViewPlannification,
    }
  }

  if (action.type === UPDATE_FOCUSED_TOURS) {
    const { del, id } = action.payload
    const focused = state.focusedTours
    var newList = focused
    if (id.length > 0 && id[0] !== null) {
      if (!del) {
        newList = [...id, ...focused]
      } else {
        newList = focused.filter((e) => !id.includes(e))
      }
    } else {
      newList = []
    }
    return {
      ...state,
      focusedTours: newList,
    }
  }
  if (action.type === CHANGE_TOURS_TYPE_STATE) {
    const { isOnePickUp } = action.payload
    return {
      ...state,
      isOnePickUp,
    }
  }

  if (action.type === RECALCULATE_UNSERVED) {
    const newUnservedOrdersOriginal =
      action.payload.data.length > 0
        ? { ...state.unservedOrdersOriginal, ...action.payload.data[0] }
        : { ...state.unservedOrdersOriginal }

    const newUnservedOrders =
      action.payload.data.length > 0 ? { ...action.payload.data[0] } : {}

    return {
      ...state,
      unservedOrdersOriginal: newUnservedOrdersOriginal,
      unservedOrders: newUnservedOrders,
      tourRecChecked: [],
    }
  }

  if (action.type === UPDATE_ORDERS_ARRAY) {
    return {
      ...state,
      unservedOrders: action.payload.data,
      tourRecChecked: [],
    }
  }

  if (action.type === CHECKED_ALL_ORDERS) {
    const checkedAll = action.payload
    const newUnservedOrders = state.unservedOrders
    let selectedCourses = { ...state.selectedCourses }

    Object.keys(newUnservedOrders).map((code) => {
      if (
        !(
          state.isOptimisationCoursesFiles &&
          !ACCURATE_ADDRESS.includes(
            newUnservedOrders[code].dropOffVisit.locationType
          )
        )
      ) {
        newUnservedOrders[code].pickUpVisit.checked = !checkedAll
        newUnservedOrders[code].dropOffVisit.checked = !checkedAll
        if (!checkedAll) {
          selectedCourses[code] = {
            ...selectedCourses[code],
            code: code,
          }
        } else {
          selectedCourses = {}
        }
      }
    })
    let nbChecked = 0
    Object.keys(newUnservedOrders).map((code) => {
      if (newUnservedOrders[code].pickUpVisit.checked) nbChecked++
    })
    let nbOrderChecked = false
    if (nbChecked === Object.keys(newUnservedOrders).length) nbOrderChecked = true

    return {
      ...state,
      unservedOrders: { ...newUnservedOrders },
      checkedAllOrder: nbOrderChecked,
      nbOrderChecked: nbChecked,
      selectedCourses: selectedCourses,
    }
  }

  if (action.type === CHECKED_ORDER) {
    const id = action.payload
    let nbChecked = 0
    let checkedAll = state.checkedAllOrder
    const selectedCourses = { ...state.selectedCourses }

    let newUnservedOrders = state.unservedOrders
    if (Object.keys(newUnservedOrders).includes(id)) {
      if (newUnservedOrders.length !== 0) {
        newUnservedOrders[id].pickUpVisit.checked =
          !newUnservedOrders[id].pickUpVisit.checked
        newUnservedOrders[id].dropOffVisit.checked =
          !newUnservedOrders[id].dropOffVisit.checked
        if (state.unservedOrders[id].dropOffVisit.checked) {
          selectedCourses[id] = {
            ...selectedCourses[id],
            code: id,
          }
        } else {
          delete selectedCourses[id]
        }
      }
      if (!newUnservedOrders[id].dropOffVisit.checked) {
        checkedAll = false
      }

      Object.keys(newUnservedOrders).map((code) => {
        if (newUnservedOrders[code].dropOffVisit.checked) nbChecked++
      })
      if (nbChecked === Object.keys(newUnservedOrders).length) checkedAll = true
    }

    return {
      ...state,
      unservedOrders: { ...newUnservedOrders },
      checkedAllOrder: checkedAll,
      nbOrderChecked: nbChecked,
      selectedCourses: selectedCourses,
    }
  }

  if (action.type === RESET_CHECKED_ORDER) {
    return {
      ...state,
      checkedAllOrder: false,
      nbOrderChecked: 0,
    }
  }

  if (action.type === CHECKED_PLANNING) {
    const { indexPlanning } = action.payload
    let recList = state.tourRecChecked
    state.tours[indexPlanning].checkedAll = !state.tours[indexPlanning].checkedAll

    state.tours[indexPlanning].markers.map((visit) => {
      if (state.tours[indexPlanning].checkedAll) {
        // If the item is not in the list, add it
        if (visit.location_id) recList.push(visit.location_id)
      } else {
        recList = recList.filter((item) => item !== visit.location_id)
      }
    })
    state.tours[indexPlanning].markers.map((visit) => {
      if (visit.type != 'depotStart' && visit.type != 'depotEnd') {
        visit.checked = state.tours[indexPlanning].checkedAll
      }
    })
    let nbChecked = 0
    state.tours[indexPlanning].markers.map((visit) => {
      if (visit.checked) nbChecked++
    })
    state.tours[indexPlanning].nbVisitChecked = nbChecked / 2
    return {
      ...state,
      tours: [...state.tours],
      tourRecChecked: recList,
    }
  }

  if (action.type === CHECKED_VISIT) {
    const { indexPlanning, indexVisit, RecList } = action.payload
    let filtredMarkers = []
    let result = {}
    let recList = state.tourRecChecked
    if (
      !state.isOptimisationCoursesFiles &&
      state.tours[indexPlanning].markers[indexVisit].type != 'pickup'
    ) {
      if (recList.includes(RecList)) {
        recList = recList.filter((item) => item !== RecList)
      } else {
        recList.push(RecList)
      }
    }
    if (state.isOptimisationCoursesFiles) {
      filtredMarkers = state.tours[indexPlanning].markers.filter(
        (item) => item.type != 'pickup'
      )
      if (recList.includes(RecList)) {
        recList = recList.filter((item) => item !== RecList)
      } else {
        recList.push(RecList)
      }
      filtredMarkers[indexVisit].checked = !filtredMarkers[indexVisit].checked

      const TabChecked = filtredMarkers
      TabChecked.filter((visit) => visit.checked).forEach((elem) => {
        if (elem.location_id in result) {
          result[elem.location_id] = ++result[elem.location_id]
        } else {
          result[elem.location_id] = 1
        }
      })
      state.tours[indexPlanning].nbVisitChecked = Object.keys(result).length
      if (!filtredMarkers[indexVisit].checked) {
        state.tours[indexPlanning].checkedAll = false
      }
      let nbChecked = 0
      Object.keys(result).map((ele) => {
        nbChecked = result[ele] + nbChecked
      })

      if (nbChecked === filtredMarkers.length - 2) {
        state.tours[indexPlanning].checkedAll = true
      }
    } else {
      state.tours[indexPlanning].markers[indexVisit].checked =
        !state.tours[indexPlanning].markers[indexVisit].checked

      const TabChecked = state.tours[indexPlanning].markers
      TabChecked.filter((visit) => visit.checked).forEach((elem) => {
        if (elem.location_id in result) {
          result[elem.location_id] = ++result[elem.location_id]
        } else {
          result[elem.location_id] = 1
        }
      })
      state.tours[indexPlanning].nbVisitChecked = Object.keys(result).length

      if (!state.tours[indexPlanning].markers[indexVisit].checked) {
        state.tours[indexPlanning].checkedAll = false
      }
      let nbChecked = 0
      Object.keys(result).map((ele) => {
        nbChecked = result[ele] + nbChecked
      })

      if (nbChecked === state.tours[indexPlanning].markers.length - 2) {
        state.tours[indexPlanning].checkedAll = true
      }
    }
    //nombre de tours checked

    //change status de checkAll

    return {
      ...state,
      tourRecChecked: recList,
      tours: [...state.tours],
    }
  }

  if (action.type === UPDATE_COURSE_POLARIS) {
    return {
      ...state,
      isLoadingUpdateCoursePolaris: true,
      isUpdateCoursePolarisAction: false,
      errorMessage: '',
      isError: false,
    }
  }
  if (action.type === UPDATE_COURSE_POLARIS_SUCCESS) {
    const { response } = action.payload.data

    let newUnservedOrders = state.unservedOrders

    if (response.length > 0 && response[0]?.codeCourse) {
      newUnservedOrders[response[0].codeCourse].dropOffVisit = {
        ...newUnservedOrders[response[0].codeCourse].dropOffVisit,
        // eslint-disable-next-line camelcase
        location_name: response[0].adresseArrivee.address,
        lat: response[0].adresseArrivee.latitude,
        lng: response[0].adresseArrivee.longitude,
        windowEnd: response[0].dropOffEnd,
        windowStart: response[0].dropOffStart,
        secondWindowEnd: response[0].secondDropOffEnd,
        secondWindowStart: response[0].secondDropOffStart,
        weight: parseInt(response[0].weight),
        volume: parseFloat(response[0].volume),
      }
      newUnservedOrders[response[0].codeCourse].pickUpVisit = {
        ...newUnservedOrders[response[0].codeCourse].pickUpVisit,
        windowEnd: response[0].pickUpEnd,
        windowStart: response[0].pickUpStart,
        weight: parseInt(response[0].weight),
        volume: parseFloat(response[0].volume),
      }
    }
    return {
      ...state,
      isLoadingUpdateCoursePolaris: false,
      isUpdateCoursePolarisAction: true,
      unservedOrders: { ...newUnservedOrders },
    }
  }
  if (action.type === UPDATE_COURSE_POLARIS_FAILURE) {
    return {
      ...state,
      isLoadingUpdateCoursePolaris: false,
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isError: true,
    }
  }
  if (action.type === ACTION_UPDATE_COURSE_POLARIS) {
    return {
      ...state,
      isUpdateCoursePolarisAction: false,
    }
  }
  if (action.type === RESET_SIMULATE_ACTION) {
    return {
      ...state,
      tourAction: false,
    }
  }

  if (action.type === GET_TOURS_FROM_POLARIS) {
    return {
      ...state,
      isError: false,
      isLoading: true,
      tourAction: false,
    }
  }

  if (action.type === GET_TOURS_FROM_POLARIS_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      tourList: data,
      countTours: count,
      isError: false,
      isLoading: false,
    }
  }

  if (action.type === GET_TOURS_FROM_POLARIS_FAILURE) {
    const message = action.payload.data?.response.message
    return {
      ...state,
      isError: true,
      errorMessage: message || 'Probléme serveur, veuillez réessayer plus tard',
      isLoading: false,
    }
  }

  if (action.type === DELETE_TOUR_FROM_POLARIS) {
    return {
      ...state,
      isLoading: true,
      tourAction: false,
      errorMessage: '',
      isViewPlannification: true,
      isErrorAffectPlanning: false,
    }
  }

  if (action.type === DELETE_TOUR_FROM_POLARIS_SUCCESS) {
    return {
      ...state,
      tourAction: true,
      isViewPlannification: false,
      isOptimisationCoursesFiles: false,
      filterCourseFile: {},
      fileNameCoursesOptimisation: '',
    }
  }

  if (action.type === DELETE_TOUR_FROM_POLARIS_FAILURE) {
    const message = action.payload.data?.response?.message
    return {
      ...state,
      isErrorAffectPlanning: true,
      errorMessage: { message: message || "une erreur s'est produite" },
      isLoading: false,
      tourAction: false,
    }
  }

  if (action.type === ACTIVE_TOUR_ACTION) {
    return {
      ...state,
      tourAction: true,
    }
  }
  if (action.type === RESET_TOUR_ACTION) {
    return {
      ...state,
      tourAction: false,
    }
  }

  //optimisation course Euromatic
  if (action.type === OPTIMISATION_COURSES) {
    return {
      ...state,
      isLoading: true,
      tours: [],
      errorMessage: '',
      isErrorAffectPlanning: false,
      isViewPlannification: true,
      isOptimisationCoursesFiles: true,
      tourAction: false,
    }
  }

  if (action.type === OPTIMISATION_COURSES_SUCCESS) {
    const { courses, tours, drivers, deliveryDate, filter, fileName } =
      action.payload.data
    const Filters = getFilterfromCourse(courses)
    const filterCourseFile = parseFilterTournee({ ...filter, ...Filters })
    const objectFleet = fleetParseObject(drivers, deliveryDate)
    const toursPlanning = newPlannings(
      getPlanningData(tours, deliveryDate),
      objectFleet.drivers,
      deliveryDate
    )
    const newUnservedOrdersParse = parseCoursesOptimisationObject(courses)

    return {
      ...state,
      isLoading: false,
      tours: toursPlanning,
      fleet: objectFleet,
      deliveryDate: deliveryDate,
      errorMessage: '',
      unservedOrders: newUnservedOrdersParse,
      unservedOrdersOriginal: newUnservedOrdersParse,
      isErrorAffectPlanning: false,
      isOnePickUp: true,
      filterCourseFile: filterCourseFile,
      fileNameCoursesOptimisation: fileName,
      originalCouses: courses,
      existingFiler: filter,
    }
  }

  if (action.type === OPTIMISATION_COURSES_FAILURE) {
    return {
      ...state,
      isLoading: false,
      tours: [],
      errorMessage: {
        message:
          action.payload?.data?.response?.data?.message ||
          'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isErrorAffectPlanning: true,
    }
  }

  // Get Wahrehouse ALL
  if (action.type === GET_WAREHOUSES) {
    return {
      ...state,
      isLoadingWarehouses: true,
    }
  }

  if (action.type === GET_WAREHOUSES_SUCCESS) {
    const { data, count } = action.payload.data
    return {
      ...state,
      isLoadingWarehouses: false,
      warehouses: data,
      warehousesCount: count,
    }
  }

  if (action.type === GET_WAREHOUSES_MORE_SUCCESS) {
    return {
      ...state,
      warehouses: [...state.warehouses, ...action.payload?.data?.data],
      warehousesCount: action.payload.data?.count || 0,
    }
  }

  if (
    action.type === GET_WAREHOUSES_FAILURE ||
    action.type === GET_WAREHOUSES_MORE_FAILURE
  ) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingWarehouses: false,
      errorMessage: response.data?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === CHANGE_FILTER_OFFSET) {
    const { offset } = action.payload
    const filter = { ...state.filter }
    filter.offset = offset
    return {
      ...state,
      filter,
    }
  }

  if (action.type === SEARCH_WAHREHOUSES) {
    const { search } = action.payload
    return {
      ...state,
      filter: {
        limit: 25,
        offset: 0,
        query: search,
      },
    }
  }

  if (action.type === RESET_WAHREHOUSES) {
    //rest
    return {
      ...state,
      isLoadingWarehouses: false,
      warehouses: [],
      warehousesCount: 0,
      filter: {
        limit: 25,
        offset: 0,
      },
    }
  }

  if (action.type === UPDATE_COURSE_OPTIMISATION_FILE) {
    return {
      ...state,
      isLoadingUpdateCoursePolaris: true,
      isUpdateCoursePolarisAction: false,
      errorMessage: '',
      isError: false,
    }
  }
  if (action.type === UPDATE_COURSE_OPTIMISATION_FILE_SUCCESS) {
    const { courses, filter } = action.payload.data
    let newUnservedOrders = state.unservedOrders
    const filterCourseFile = parseFilterTournee(filter)
    if (courses) {
      newUnservedOrders[courses.receptionNumber].dropOffVisit = {
        ...newUnservedOrders[courses.receptionNumber].dropOffVisit,
        // eslint-disable-next-line camelcase
        location_name: courses.adresseArrivee.address,
        lat: courses.adresseArrivee.latitude,
        lng: courses.adresseArrivee.longitude,
        weight: parseInt(courses.weight),
        volume: parseFloat(courses.volume),
        nbColis: parseFloat(courses.count),
        prestaTime: parseFloat(courses.prestaTime),
        codePostale: parseInt(courses.adresseArrivee.postalCode),
        montantPrestaHT: parseFloat(courses.price),
        locationType: courses.adresseArrivee.locationType,
        windowEnd: courses.dropOffEnd,
        secondWindowEnd: courses.secondDropOffEnd,
        windowStart: courses.dropOffStart,
        secondWindowStart: courses.secondDropOffStart,
      }
      newUnservedOrders[courses.receptionNumber].pickUpVisit = {
        ...newUnservedOrders[courses.receptionNumber].pickUpVisit,
        weight: parseInt(courses.weight),
        volume: parseFloat(courses.volume),
      }
    }
    return {
      ...state,
      isLoadingUpdateCoursePolaris: false,
      isUpdateCoursePolarisAction: true,
      unservedOrders: { ...newUnservedOrders },
      filterCourseFile: { ...state.filterCourseFile, ...filterCourseFile },
    }
  }
  if (action.type === UPDATE_COURSE_OPTIMISATION_FILE_FAILURE) {
    return {
      ...state,
      isLoadingUpdateCoursePolaris: false,
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isError: true,
    }
  }

  // export tours
  if (action.type === EXPORT_TOURS) {
    return {
      ...state,
      isLoading: true,
      tourAction: false,
    }
  }
  if (action.type === EXPORT_TOURS_SUCCESS) {
    const { data } = action.payload
    const { buffer, fileName, contentType } = data
    const parsedBuffer = new Uint8Array(buffer.data)
    const fileData = new Blob([parsedBuffer], { type: contentType })
    // Create a download link and trigger the download
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(fileData)
    downloadLink.download = fileName // Set the desired filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    return {
      ...state,
      isLoading: false,
      tours: [],
      errorMessage: '',
      isError: false,
      unservedOrders: {},
      unservedOrdersOriginal: {},
      isViewPlannification: false,
      isOptimisationCoursesFiles: false,
      tourAction: true,
    }
  }
  if (action.type === EXPORT_TOURS_FAILURE) {
    return {
      ...state,
      isLoading: false,
      errorMessage: {
        message: 'Une erreur s’est produite, veuillez réessayer plus tard',
      },
      isError: true,
      tourAction: false,
    }
  }

  if (action.type === FETCH_COURSES) {
    const { filter } = action.payload
    let newUnservedOrders = {}
    let nbChecked = 0
    let checkedAll = state.checkedAllOrder
    let courses = state?.originalCouses

    if (Object.keys(removeNull(filter)).length != 0) {
      state.unservedOrdersOriginal &&
        Object.keys(state.unservedOrdersOriginal).length > 0 &&
        Object.keys(state.unservedOrdersOriginal).map((e) => {
          let matchingNF = filter?.receptionNumber
            ? filter.receptionNumber.includes(
                state.unservedOrdersOriginal[e].dropOffVisit.location_id
              )
            : true

          let matchingCient = filter?.client
            ? filter.client.includes(
                state.unservedOrdersOriginal[e].dropOffVisit.client
              )
            : true
          let matchingWeight = filter?.weight
            ? filter.weight.includes(
                state.unservedOrdersOriginal[e].dropOffVisit.weight
              )
            : true
          let matchingOrderGiver = filter?.orderGiver
            ? filter.orderGiver.includes(
                state.unservedOrdersOriginal[e].dropOffVisit.orderGiver
              )
            : true
          let matchingAddress = filter?.endAddress
            ? filter.endAddress.includes(
                state.unservedOrdersOriginal[e].dropOffVisit.location_name
              )
            : true
          let matchingType = filter?.type
            ? filter.type.includes(
                state.unservedOrdersOriginal[e].dropOffVisit.courseType
              )
            : true

          let matchingKeys = state.unservedOrdersOriginal[
            e
          ].dropOffVisit.keys.filter((val) =>
            filter?.keys ? filter.keys.includes(val) : true
          )
          let matchingCodePostal = filter?.codePostale
            ? filter.codePostale.includes(
                state.unservedOrdersOriginal[e].dropOffVisit.codePostale
              )
            : true

          if (
            matchingKeys.length > 0 &&
            matchingCodePostal &&
            matchingNF &&
            matchingCient &&
            matchingAddress &&
            matchingType &&
            matchingOrderGiver &&
            matchingWeight
          )
            newUnservedOrders[e] = state.unservedOrdersOriginal[e]
        })
      Object.keys(newUnservedOrders).map((code) => {
        if (newUnservedOrders[code].pickUpVisit.checked) nbChecked++
      })
      if (nbChecked === Object.keys(newUnservedOrders).length) checkedAll = true
    } else {
      newUnservedOrders = state.unservedOrdersOriginal
    }
    let newCourses = courses.filter((item) =>
      Object.keys(newUnservedOrders).includes(item.receptionNumber)
    )
    const Filters = getFilterfromCourse(newCourses)
    const filterCourseFile = parseFilterTournee({
      ...state.existingFiler,
      ...Filters,
    })

    return {
      ...state,
      unservedOrders: newUnservedOrders,
      filterCourses: filter,
      checkedAllOrder: checkedAll,
      nbOrderChecked: nbChecked,
      filterCourseFile: filterCourseFile,
    }
  }
  //Selected Course (MAP)
  if (action.type === MAP_ADD_SELECTED_COURSES) {
    const courses = action.payload.data.data
    const selectedCourses = { ...state.selectedCourses }
    const affected = {}

    let nbChecked = 0
    let checkedAll = state.checkedAllOrder

    courses.forEach((ele) => {
      if (
        !(
          state.isOptimisationCoursesFiles &&
          !ACCURATE_ADDRESS.includes(
            state.unservedOrders[ele]?.dropOffVisit?.locationType
          )
        )
      )
        affected[ele] = {
          ...selectedCourses[ele],
          code: ele,
        }
    })

    const coursesSelected = {
      ...selectedCourses,
      ...affected,
    }

    Object.keys(state.unservedOrders).map((codeCourse) => {
      if (Object.keys(coursesSelected).includes(codeCourse)) {
        state.unservedOrders[codeCourse].pickUpVisit.checked = true
        state.unservedOrders[codeCourse].dropOffVisit.checked = true
      } else {
        state.unservedOrders[codeCourse].pickUpVisit.checked = false
        state.unservedOrders[codeCourse].dropOffVisit.checked = false
      }
    })
    Object.keys(state.unservedOrders).map((code) => {
      if (state.unservedOrders[code].dropOffVisit.checked) nbChecked++
    })
    if (nbChecked === Object.keys(state.unservedOrders).length) checkedAll = true
    return {
      ...state,
      selectedCourses: coursesSelected,
      unservedOrders: { ...state.unservedOrders },
      checkedAllOrder: checkedAll,
      nbOrderChecked: nbChecked,
    }
  }

  if (action.type === MAP_REMOVE_SELECTED_COURSES) {
    const courses = action.payload.data.data

    let nbChecked = 0
    let checkedAll = state.checkedAllOrder

    const selectedCourses = Object.assign(state.selectedCourses)
    courses.forEach((ele) => {
      delete selectedCourses[ele]
    })
    Object.keys(state.unservedOrders).map((codeCourse) => {
      if (Object.keys(selectedCourses).includes(codeCourse)) {
        state.unservedOrders[codeCourse].pickUpVisit.checked = true
        state.unservedOrders[codeCourse].dropOffVisit.checked = true
      } else {
        state.unservedOrders[codeCourse].pickUpVisit.checked = false
        state.unservedOrders[codeCourse].dropOffVisit.checked = false
      }
    })
    Object.keys(state.unservedOrders).map((code) => {
      if (state.unservedOrders[code].dropOffVisit.checked) nbChecked++
    })
    if (nbChecked === Object.keys(state.unservedOrders).length) checkedAll = true
    return {
      ...state,
      selectedCourses: selectedCourses,
      unservedOrders: { ...state.unservedOrders },
      checkedAllOrder: checkedAll,
      nbOrderChecked: nbChecked,
    }
  }

  if (action.type === MAP_REMOVE_ALL_SELECTED_COURSES) {
    const newUnservedOrders = state.unservedOrders

    Object.keys(newUnservedOrders).map((code) => {
      newUnservedOrders[code].pickUpVisit.checked = false
      newUnservedOrders[code].dropOffVisit.checked = false
    })
    return {
      ...state,
      selectedCourses: [],
      unservedOrders: { ...newUnservedOrders },
    }
  }

  if (action.type === VERROUILLE_TOURNEE) {
    const { positionsChauffeur } = action.payload

    const newTours = state.tours
    positionsChauffeur.map((position) => {
      newTours[position].lock = true
    })
    return {
      ...state,
      tours: [...newTours],
    }
  }

  if (action.type === DEVERROUILLE_TOURNEE) {
    const { positionsChauffeur } = action.payload

    const newTours = state.tours
    positionsChauffeur.map((position) => {
      newTours[position].lock = false
    })
    return {
      ...state,
      tours: [...newTours],
    }
  }
  return state
}
