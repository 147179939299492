import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'
import { CATEGORIE_RECHERCHE_DOCUMENT, getfullName } from '../../../../utils/values'
import CategorieRecherche from './categorieRecherche'
import SearchDownloadMultipleDocument from './searchDownloadMultipleDocument'
import SearchDownloadSimpleDocument from './searchDownloadSimpleDocument'
import DialogDownloadKilometrageDocument from '../../../../containers/main/Entrepot/documents/dialogDownloadKilometrageDocument'

export default function SearchDownloadDocument({
  classes,
  //Fetch Etiquette && download
  fetchEtiquetteList,
  fetchEtiquetteListMore,
  isLoadingEtiquette,
  etiquettesList,
  etiquettesCount,
  generationEtiquette,
  //Fetch Drivers && download
  fetchDrivers,
  fetchDriversMore,
  drivers,
  isLoadingDrivers,
  driversCount,
  generationQRcode,
  //Fetch Tournée && download
  getTourList,
  getTourListMore,
  tourList,
  tourListLoading,
  countListTour,
  generationTournee,
}) {
  const [search, setSearch] = useState('')
  const [options, setOptions] = useState([])
  const [select, setSelect] = useState(null)
  const [button, setButton] = useState(false)
  const [categorieSelected, setCategorieSelected] = useState({
    code: 'ETIQUETTE',
    label: 'Générer étiquette',
    iconSelect: <FontAwesomeIcon icon={faBarcode} />,
    iconDialog: (
      <FontAwesomeIcon
        icon={faBarcode}
        style={{ width: '1.7em', height: '1.7em' }}
      />
    ),
    placeholder: 'Recherche code barre',
  })
  const [countOptionsSearchSimple, setCountOptionsSearchSimple] = useState(0)

  const [dialogRechercheMultiple, setDialogRechercheMultiple] = useState(false)
  const [optionsMultiple, setOptionsMultiple] = useState([])

  const [dialogDownloadKilometrage, setDialogdialogDownloadKilometrage] =
    useState(false)

  //Search && download simple
  useEffect(() => {
    if (categorieSelected.code == 'ETIQUETTE' && etiquettesList.length > 0) {
      const etiquettes = etiquettesList.map((etiquette) => {
        return {
          ...etiquette,
          label: `${etiquette.LettreDeVoiture} ${etiquette.barCode}`,
        }
      })
      setOptions(etiquettes)
      setCountOptionsSearchSimple(etiquettesCount)
    }
  }, [etiquettesList])

  useEffect(() => {
    if (categorieSelected.code == 'TOURNEE' && tourList.length > 0) {
      const tours = tourList.map((tour) => {
        return {
          id: tour,
          label: tour,
        }
      })
      setOptions(tours)
      setCountOptionsSearchSimple(countListTour)
    }
  }, [tourList])

  const downloadDocumentSearchSimple = () => {
    setSearch('')
    categorieSelected.code == 'ETIQUETTE' && generationEtiquette(select)
    categorieSelected.code == 'TOURNEE' && generationTournee(select.id)
    setButton(false)
  }

  //Changement categorie Document() =>
  const changeCategorieFilter = (codeCategorie) => {
    setCategorieSelected(codeCategorie)
    setSearch('')
    setOptions([])
    setButton(false)
    setSelect(null)
    if (codeCategorie.code == 'QRCODE') {
      setDialogRechercheMultiple(true)
    }
    if (codeCategorie.code == 'KILOMETRAGE') {
      setDialogdialogDownloadKilometrage(true)
    }
  }

  //Search && download multiple
  useEffect(() => {
    if (categorieSelected.code == 'QRCODE' && drivers.length > 0) {
      const driversParse = drivers.map((driver) => {
        return {
          id: driver.id,
          label: getfullName(driver, '-'),
        }
      })
      setOptionsMultiple(driversParse)
    }
  }, [drivers])

  const downloadDocumentSearchMultimle = (ids) => {
    categorieSelected.code == 'QRCODE' && generationQRcode(ids)
  }

  const handleCloseDialog = () => {
    setDialogRechercheMultiple(false)
    setDialogdialogDownloadKilometrage(false)

    setCategorieSelected({
      code: 'ETIQUETTE',
      label: 'Générer étiquette',
      iconSelect: <FontAwesomeIcon icon={faBarcode} />,
      iconDialog: (
        <FontAwesomeIcon
          icon={faBarcode}
          style={{ width: '1.7em', height: '1.7em' }}
        />
      ),
      placeholder: 'Recherche code barre',
    })
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '371px',
          margin: '15px 4px',
          borderRadius: '5px',
          border: '1px solid #C2C2C2',
        }}
      >
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            borderRight: '1px solid #C2C2C2',
            backgroundColor: '#dadce07a',
            fontWeight: '500',
          }}
        >
          <CategorieRecherche
            changeCategorieFilter={changeCategorieFilter}
            categorieSelected={categorieSelected}
            categorieRecherche={CATEGORIE_RECHERCHE_DOCUMENT}
            widthPaper={'193px'}
          />
        </div>
        <SearchDownloadSimpleDocument
          classes={classes}
          placeholderInput={categorieSelected.placeholder}
          onSearch={(filter) => {
            categorieSelected.code == 'ETIQUETTE' && fetchEtiquetteList(filter)
            categorieSelected.code == 'TOURNEE' && getTourList(filter)
          }}
          isLoading={isLoadingEtiquette || tourListLoading}
          options={options}
          countOptions={countOptionsSearchSimple}
          onSearchMore={(filter) => {
            categorieSelected.code == 'ETIQUETTE' && fetchEtiquetteListMore(filter)
            categorieSelected.code == 'TOURNEE' && getTourListMore(filter)
          }}
          downloadDocument={downloadDocumentSearchSimple}
          setSearch={setSearch}
          search={search}
          setSelect={setSelect}
          setButton={setButton}
          button={button}
        />
      </div>
      {dialogRechercheMultiple && (
        <SearchDownloadMultipleDocument
          open={dialogRechercheMultiple}
          title={categorieSelected.label}
          iconTitle={categorieSelected.iconDialog}
          placeholderInput={categorieSelected.placeholder}
          onSearch={fetchDrivers}
          isLoading={isLoadingDrivers}
          options={optionsMultiple}
          countOptions={driversCount}
          onSearchMore={fetchDriversMore}
          downloadDocument={downloadDocumentSearchMultimle}
          handleClose={handleCloseDialog}
        />
      )}

      {dialogDownloadKilometrage && (
        <DialogDownloadKilometrageDocument
          open={dialogDownloadKilometrage}
          iconTitle={categorieSelected.iconDialog}
          placeholderInput={categorieSelected.placeholder}
          handleClose={handleCloseDialog}
        />
      )}
    </>
  )
}

SearchDownloadDocument.propTypes = {
  classes: PropTypes.object,
  fetchEtiquetteList: PropTypes.func,
  fetchEtiquetteListMore: PropTypes.func,
  isLoadingEtiquette: PropTypes.bool,
  etiquettesList: PropTypes.array,
  etiquettesCount: PropTypes.number,
  generationEtiquette: PropTypes.func,
  fetchDrivers: PropTypes.func,
  fetchDriversMore: PropTypes.func,
  drivers: PropTypes.array,
  isLoadingDrivers: PropTypes.bool,
  driversCount: PropTypes.number,
  generationQRcode: PropTypes.func,
  getTourList: PropTypes.func,
  getTourListMore: PropTypes.func,
  tourList: PropTypes.array,
  tourListLoading: PropTypes.bool,
  countListTour: PropTypes.number,
  generationTournee: PropTypes.func,
}
