export default () => {
  return {
    filter: {
      limit: 25,
      offset: 0,
    },
    tourneesList: [],
    tourneesListCount: 0,
    isLoadingTourneesList: false,

    errorMessage: null,
    isErrorFetch: false,

    isLoadingTourneeAction: false,
    success: false,
    isSuccessRefresh: false,
  }
}
