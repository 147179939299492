import React from 'react'
import PropTypes from 'prop-types'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import ListItemsComponent from '../listItemComponent'
import {
  StyledList,
  StyledAccordion,
  StyledListDetails,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './style'

const FilterItemsList = ({
  dataList,
  handleToggle,
  handleDoubleClick,
  fetchMoreData,
  handleDoubleClickAll,
  handleToggleAll,
  numSelected,
  isSelected,
  handleChangeAccordion,
  expanded,
  handleToggleAccordion,
  checkedAccordion,
}) => {
  return (
    <div>
      <StyledList dense>
        <ListItem key={0} button onDoubleClick={handleDoubleClickAll}>
          <ListItemText id={'checkbox-list-secondary-label-0'} primary={'Tous'} />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              onClick={handleToggleAll}
              checked={numSelected}
              inputProps={{ 'aria-labelledby': 'checkbox-list-secondary-label-0' }}
              style={{ cursor: 'pointer' }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </StyledList>

      <div
        style={{
          maxHeight: 'calc(100vh - 335px)',
          overflowX: 'hidden',
          overflowY: 'overlay',
        }}
      >
        {Object.keys(dataList).map((key, index) => (
          <StyledAccordion
            key={index}
            expanded={expanded === dataList[key].radioGroupe.code}
            onChange={handleChangeAccordion(dataList[key].radioGroupe.code)}
          >
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '1px',
                paddingRight: '16px',
              }}
            >
              <StyledAccordionSummary
                aria-controls="panel1bh-content"
                id={`goupe-${index}`}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '1px',
                    paddingRight: '5px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {expanded === dataList[key].radioGroupe.code ? (
                      <RemoveIcon
                        style={{
                          width: '15px',
                          height: '15px',
                          border: '1px solid #292c2e',
                          borderRadius: '3px',
                        }}
                      />
                    ) : (
                      <AddIcon
                        style={{
                          width: '15px',
                          height: '15px',
                          border: '1px solid #292c2e',
                          borderRadius: '3px',
                        }}
                      />
                    )}
                    <span style={{ paddingLeft: '10px' }}>
                      {dataList[key].radioGroupe.name}
                    </span>
                  </div>
                </div>
              </StyledAccordionSummary>
              <Checkbox
                edge="end"
                onClick={(e) => {
                  handleToggleAccordion(key)
                  e.preventDefault()
                }}
                checked={checkedAccordion(key)}
                inputProps={{
                  'aria-labelledby': 'checkbox-list-secondary-label-0',
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <StyledAccordionDetails>
              <StyledListDetails dense onScroll={(e) => fetchMoreData(e, key)}>
                <ListItemsComponent
                  data={dataList[key].radiosLimit}
                  handleToggle={handleToggle}
                  handleDoubleClick={handleDoubleClick}
                  isSelected={isSelected}
                />
              </StyledListDetails>
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
      </div>
    </div>
  )
}
FilterItemsList.propTypes = {
  handleToggle: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  dataList: PropTypes.object,
  fetchMoreData: PropTypes.func,
  handleDoubleClickAll: PropTypes.func,
  handleToggleAll: PropTypes.func,
  numSelected: PropTypes.bool,
  isSelected: PropTypes.func,
  handleChangeAccordion: PropTypes.func,
  expanded: PropTypes.string,
  handleToggleAccordion: PropTypes.func,
  checkedAccordion: PropTypes.func,
}
export default FilterItemsList
