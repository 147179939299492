import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import { TextField, Button, Box, Avatar } from '@mui/material'
import CreateIcon from '../../../../assets/icons/settingsCorner/iconsCreate'
import EditIcon from '../../../../assets/icons/settingsCorner/iconEdit'
import {
  ParticipantType,
  getPhoneInputCountries,
  defaultImageParticipant,
} from '../../../../utils/values'
import {
  StyledDivContent,
  StyledDivTopInputs,
  StyledInputModal,
  StyledInputTitle,
  sxInput,
} from '../../styled'
import { MenuItem } from '@mui/material'
import { useFormik } from 'formik'
import AutoComplete from '../../../../components/AutoComplete'
import PhoneInput from 'react-phone-input-2'
import KeyModal from '../../../../containers/settings/Modals/keyModal'
import './styleModal.scss'
import Error from '../../../../components/Modal/HttpErrorPopUp'
import fileDecoded from '../../../../utils/fileDecoded'
import { validateUploadImage } from '../../../../utils/validator/UploadFileDiscussion'
// which keys are symmetrical to our values/initialValues
const validate = ({ firstName, lastName, email, phone }) => {
  const errors = {}
  if (!firstName) {
    errors.firstName = 'Prénom est obligatoire'
  }

  if (!lastName) {
    errors.lastName = 'Nom est obligatoire'
  }

  if (!email) {
    errors.email = 'Email est obligatoire'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    errors.email = 'Email est invalide'
  }
  if (!phone) {
    errors.phone = 'Téléphone est obligatoire'
  }

  return errors
}

function Addkey({
  open,
  isEdit,
  handleClose,
  modalTitle,
  createParticipant,
  updateParticipant,
  isLoadingParticipantAction,
  success,
  errorMessage,
  row,
  keys,
  fetchKeys,
  searchKeys,
  isLoadingKeysList,
  fetchMoreKeys,
  keysCount,
  resetKey,
  resetParticipantConfig,
  errorMessageConfig,
  successConfig,
  keyCreatedObject,
}) {
  const [select, setSelect] = useState(false)
  const [init, setInit] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [offset, setOffset] = useState(25)
  const [openKey, setOpenKey] = useState(false)

  const [errorUploadFile, setErrorUploadFile] = useState(null)
  const [openDialogError, setOpenDialogError] = useState(false)

  const parsePayload = (changedValues) => {
    let formData = new FormData()
    formData.append('participantDto', JSON.stringify(changedValues))
    changedValues.photo != null
      ? Array.from(changedValues.photo).forEach((file) => {
          formData.append('photo', file)
        })
      : formData.append('photo', {})
    return formData
  }

  const formik = useFormik({
    initialValues: {
      //TEST with Mohamed REMPLACE LEGACY_ID
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      type: 'COLLAB',
      competences: [],
      photo: null,
      isParticipant: true,
    },
    validate,
    onSubmit: (values) => {
      if (isEdit) {
        const changedValues = {}
        Object.keys(formik.values).forEach((key) => {
          if (values[key] !== row[key]) {
            changedValues[key] = values[key]
          }
        })
        const formData = parsePayload({
          ...changedValues,
          id: row.id,
          method: 'Corner',
        })
        updateParticipant(formData)
      } else {
        const formData = parsePayload({ ...values, method: 'Corner' })
        createParticipant(formData)
      }
    },
  })
  const phoneInputStyles = {
    height: '3.12em',
    width: '100%',
    borderRadius: '4px',
    border:
      formik.touched.phone && formik.errors.phone
        ? '1px solid red'
        : '1px solid #E2F0FE',
  }
  useEffect(() => {
    if (isEdit) {
      const filesToDecode = fileDecoded([row.photo])

      formik.setValues({
        ...formik.values,
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        phone: row.phone,
        type: row.type,
        competences: row.competences,
        id: row.id,
        photo: row?.photo ? filesToDecode : null,
      })
    }
  }, [isEdit])

  useEffect(() => {
    if (success) {
      handleClose()
      resetParticipantConfig()
    }
  }, [success])

  useEffect(() => {
    if (successConfig && keyCreatedObject) {
      formik.setFieldValue('competences', [
        ...formik.values.competences,
        keyCreatedObject,
      ])
      setSearchKey('')
    }
  }, [successConfig])

  const handleCloseKeyModal = () => {
    setOpenKey(false)
  }
  const verifEdit = () => {
    return (
      isEdit &&
      formik.values.firstName == row.firstName &&
      formik.values.lastName == row.lastName &&
      formik.values.email == row.email &&
      formik.values.phone == row.phone &&
      formik.values.type == row.type &&
      (formik?.values?.photo == undefined ||
        formik?.values?.photo == null ||
        (formik?.values?.photo != undefined &&
          formik?.values?.photo != null &&
          Array.from(formik?.values?.photo)
            ?.map((el) => el.name)
            ?.every((el) => row?.photo?.fileName == el)))
    )
  }
  useEffect(() => {
    fetchKeys()
    setInit(true)
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', resetKey)

    return () => {
      resetKey()
      window.removeEventListener('beforeunload', resetKey)
    }
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchKey.length > 0 && !select) {
        searchKeys(searchKey)
        setOffset(25)
      } else if (searchKey === '') {
        if (init) {
          searchKeys('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchKey])

  const onSelectKey = (value) => {
    if (value) {
      formik.setFieldValue('competences', value)
      setSelect(true)
    }
  }

  const onChangeSearchKey = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchKey(val)
    } else {
      setSearchKey('')
      setSelect(false)
    }
  }

  const loadMoreKeys = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      keysCount >= offset
    ) {
      setOffset(offset + 25)
      fetchMoreKeys(offset, searchKey)
    }
  }

  const onChangeImage = (event) => {
    const error = validateUploadImage(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
      setOpenDialogError(true)
      event.target.value = null
    } else {
      formik.setFieldValue('photo', event.target.files)
    }
  }

  const getURLImage = () => {
    return formik.values.photo != null &&
      isEdit &&
      formik.values.photo?.length > 0 &&
      formik.values.photo[0]?.url
      ? formik.values.photo[0]?.url
      : formik.values.photo != null
      ? URL.createObjectURL(formik.values.photo[0])
      : defaultImageParticipant
  }

  const onErrorClose = () => {
    setOpenDialogError(false)
    setErrorUploadFile(null)
  }

  return (
    <>
      {errorUploadFile?.isError && (
        <Error
          statusText={
            errorUploadFile?.message ||
            "une erreur s'est produite merci d'actualiser la page"
          }
          open={openDialogError}
          setOpen={onErrorClose}
        />
      )}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Dialog
          maxWidthDialog={'md'}
          open={open}
          title={modalTitle}
          iconTitle={isEdit ? <EditIcon /> : <CreateIcon />}
          style={{ display: (errorMessage || errorMessageConfig) && 'none' }}
          content={
            <StyledDivContent>
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  paddingBottom: '20px',
                }}
              >
                <input
                  type="file"
                  style={{
                    width: '120px',
                    cursor: 'pointer',
                    zIndex: '2',
                    position: 'absolute',
                    height: '120px',
                    opacity: '0',
                  }}
                  accept="image/*"
                  onChange={onChangeImage}
                />

                <Avatar
                  src={getURLImage()}
                  alt="profile"
                  sx={{
                    width: '120px',
                    height: '120px',
                  }}
                />
              </Box>
              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Nom</StyledInputTitle>
                  <TextField
                    placeholder="Nom"
                    id="Participant-Nom"
                    name="lastName"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.lastName}
                    error={formik.errors.lastName && formik.touched.lastName}
                    helperText={
                      formik.errors.lastName && formik.touched.lastName
                        ? formik.errors.lastName
                        : null
                    }
                  />
                </StyledInputModal>
                <StyledInputModal>
                  <StyledInputTitle>Prénom </StyledInputTitle>
                  <TextField
                    id="Participant-Prenom"
                    name="firstName"
                    variant="outlined"
                    placeholder="Prénom"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.firstName}
                    error={formik.errors.firstName && formik.touched.firstName}
                    helperText={
                      formik.errors.firstName && formik.touched.firstName
                        ? formik.errors.firstName
                        : null
                    }
                  />
                </StyledInputModal>
              </StyledDivTopInputs>
              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Email</StyledInputTitle>
                  <TextField
                    placeholder="Email"
                    id="Participant-Email"
                    name="email"
                    variant="outlined"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.email}
                    error={formik.errors.email && formik.touched.email}
                    helperText={
                      formik.errors.email && formik.touched.email
                        ? formik.errors.email
                        : null
                    }
                  />
                </StyledInputModal>
                <StyledInputModal>
                  <StyledInputTitle>Téléphone </StyledInputTitle>
                  <PhoneInput
                    inputClass="phoneInput"
                    containerClass="phoneInputContainer"
                    onBlur={formik.handleBlur}
                    id="Participant-phone"
                    onChange={(newValue) => {
                      formik.setFieldValue('phone', newValue)
                    }}
                    value={formik.values.phone}
                    onlyCountries={getPhoneInputCountries()}
                    country={'fr'}
                    specialLabel={''}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      country: 'fr',
                    }}
                    inputStyle={phoneInputStyles}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div
                      style={{
                        color: 'red',
                        fontWeight: '400',
                        fontSize: '0.75rem',
                        lineHeight: '1.66',
                        letterSpacing: '0.03333em',
                        textAlign: 'left',
                        marginTop: '3px',
                        marginRight: '14px',
                        marginBottom: '0',
                        marginLeft: '14px',
                      }}
                    >
                      {formik.errors.phone}
                    </div>
                  )}
                </StyledInputModal>
              </StyledDivTopInputs>
              <StyledDivTopInputs>
                <StyledInputModal>
                  <StyledInputTitle>Type</StyledInputTitle>
                  <TextField
                    onBlur={formik.handleBlur}
                    id="Participant-Type"
                    name="type"
                    variant="outlined"
                    onChange={(event) => {
                      formik.setFieldValue('type', event.target.value)
                    }}
                    InputProps={{
                      sx: sxInput,
                    }}
                    value={formik.values.type}
                    select
                    error={formik.errors.type && formik.touched.type}
                    helperText={
                      formik.errors.type && formik.touched.type
                        ? formik.errors.type
                        : null
                    }
                  >
                    {ParticipantType.map((key, index) => (
                      <MenuItem value={key.code} key={index}>
                        {key.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </StyledInputModal>
              </StyledDivTopInputs>

              <StyledInputTitle>Compétences </StyledInputTitle>
              <div style={{ display: 'flex' }}>
                <AutoComplete
                  freeSolo
                  value={formik.values.competences}
                  name={'competences'}
                  onChange={(event, value) => {
                    onSelectKey(value)
                  }}
                  onInputChange={(event, value) => onChangeSearchKey(value)}
                  id="Participant-Competances"
                  options={keys}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  getOptionLabel={(option) => option.value}
                  variant="outlined"
                  placeholder={'Choisir une compétence'}
                  sxInputStyle={sxInput}
                  multiple={true}
                  error={formik.errors.competences && formik.touched.competences}
                  helperText={
                    formik.errors.competences && formik.touched.competences
                      ? formik.errors.competences
                      : null
                  }
                  fullWidth={true}
                  ListboxProps={{
                    onScroll: loadMoreKeys,
                  }}
                  renderOption={(props, option) => (
                    <li {...props} id={`Compétences-${option.id}`} key={option.id}>
                      <div className="AutoCompteFilterOption">{option.value}</div>
                    </li>
                  )}
                  loading={isLoadingKeysList}
                  inputValue={searchKey}
                />
                {!isLoadingKeysList && keys.length === 0 && (
                  <Button
                    variant="outlined"
                    style={{ marginLeft: '10px', textTransform: 'none' }}
                    onClick={() => setOpenKey(true)}
                  >
                    Ajouter
                  </Button>
                )}
              </div>
            </StyledDivContent>
          }
          handleClose={handleClose}
          handleClickAction={formik.handleSubmit}
          disabled={verifEdit()}
          isLoadingButtonAction={isLoadingParticipantAction}
        />{' '}
      </form>
      {openKey && (
        <KeyModal
          handleClose={handleCloseKeyModal}
          modalTitle={'Ajouter une compétence'}
          open={openKey}
          keyValue={searchKey}
        />
      )}
    </>
  )
}
Addkey.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  keys: PropTypes.array,
  createParticipant: PropTypes.func,
  errorMessage: PropTypes.string,
  isEdit: PropTypes.bool,
  errorActionCourse: PropTypes.string,
  modalTitle: PropTypes.string,
  isLoadingParticipantAction: PropTypes.bool,
  success: PropTypes.bool,
  updateParticipant: PropTypes.func,
  row: PropTypes.object,
  fetchKeys: PropTypes.func,
  isLoadingKeysList: PropTypes.bool,
  searchKeys: PropTypes.func,
  fetchMoreKeys: PropTypes.func,
  keysCount: PropTypes.number,
  resetKey: PropTypes.func,
  resetParticipantConfig: PropTypes.func,
  errorMessageConfig: PropTypes.string,
  successConfig: PropTypes.bool,
  keyCreatedObject: PropTypes.object,
}

export default Addkey
