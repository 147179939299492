import React from 'react'
import PropTypes from 'prop-types'

import { StyledToolbar, StyledTypography } from '../../styled'

export const EnhancedTableToolbar = (props) => {
  const { title } = props

  return (
    <StyledToolbar bgcolor="#fff" color="black">
      <StyledTypography id="tableTitle" component="div">
        {title}
      </StyledTypography>
    </StyledToolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  title: PropTypes.string,
}
