import { connect } from 'react-redux'

import Desattribution from '../../../components/ShipmentAction/Desattribution'
import { handleDissociateClick } from '../../../redux/order/actions'
import {
  getErrorActionCourse,
  getIsLoadingActionOrder,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  errorActionCourse: getErrorActionCourse(state),
})

const mapDisptachToProps = (dispatch) => ({
  handleDissociateClick: (payload) => {
    dispatch(handleDissociateClick(payload))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(Desattribution)
