import React from 'react'
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import _ from 'lodash'
import FormLabel from '@mui/material/FormLabel'

import { styled } from '@mui/material/styles'

export const StyledInvoiceGrid = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'start',
}))

export const StyledPaper = styled(Paper)(({ isFactureContainerErr }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '49%',
  margin: '2% 1% 2% 0',
  ...(isFactureContainerErr
    ? {
        border: '1px solid red !important',
      }
    : {
        border: '1px solid lightgrey !important',
      }),
}))

const InvoiceInput = ({
  factures,
  handleInvoiceChange,
  removeFacture,
  addFacture,
  isDisabled,
  errors,
  isSubmitted,
}) => {
  return (
    <StyledInvoiceGrid>
      <FormControl>
        <FormLabel style={{ color: 'black', fontSize: '0.9rem' }}>
          Référence client *{' '}
        </FormLabel>

        {factures.map((facture, index) => (
          <>
            <StyledPaper
              key={`facture__${index}`}
              component="div"
              isFactureContainerErr={
                isSubmitted &&
                !_.isNil(_.get(errors, 'factures')) &&
                _.get(errors, 'factures.required.index').includes(index)
              }
            >
              <InputBase
                inputProps={{
                  'aria-label': 'id no.',
                }}
                sx={{ marginLeft: '8px', flex: 1 }}
                placeholder="XXXXXX"
                id={`facture-${index}-shipment`}
                value={facture}
                label="Facture "
                style={{ width: '100% !important' }}
                variant="outlined"
                disabled={isDisabled}
                onChange={(e) => handleInvoiceChange(e.target.value, index)}
                error={
                  isSubmitted &&
                  !_.isNil(_.get(errors, 'factures')) &&
                  _.get(errors, 'factures.required.index').includes(index)
                }
                required
              />

              {factures.length > 1 && (
                <IconButton
                  type="submit"
                  sx={{ padding: '10px' }}
                  aria-label="search"
                  disabled={isDisabled}
                  onClick={(e) => removeFacture(e, index)}
                  variant="outlined"
                >
                  <RemoveCircleIcon
                    sx={{
                      cursor: 'pointer !important',
                      color: '#ef9a9a !important',
                      width: '20px !important',
                      padding: 'initial !important',
                    }}
                  />
                </IconButton>
              )}

              {factures.length - 1 === index && (
                <>
                  <Divider orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ padding: '10px' }}
                    disabled={isDisabled}
                    aria-label="directions"
                    onClick={
                      factures.length - 1 === index ? addFacture : console.log('')
                    }
                    variant="outlined"
                  >
                    <AddCircleIcon
                      sx={
                        factures.length - 1 === index
                          ? {
                              cursor: 'pointer',
                              float: 'right',
                              color: '#4db6ac',
                              width: '20px',
                              padding: 'initial',
                            }
                          : {
                              float: 'right',
                              color: 'transparent',
                            }
                      }
                    />
                  </IconButton>
                </>
              )}
            </StyledPaper>
            <FormHelperText className="text-info-error" error={true}>
              {isSubmitted &&
                !_.isNil(_.get(errors, 'factures')) &&
                _.get(errors, 'factures.required.index').includes(index) &&
                _.get(errors, 'factures.required.message')}
            </FormHelperText>
          </>
        ))}
      </FormControl>
    </StyledInvoiceGrid>
  )
}
InvoiceInput.propTypes = {
  factures: PropTypes.array.isRequired,
  handleInvoiceChange: PropTypes.func.isRequired,
  removeFacture: PropTypes.func.isRequired,
  addFacture: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  errors: PropTypes.object,
  isSubmitted: PropTypes.bool,
}
export default InvoiceInput
