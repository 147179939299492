import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IonListCircleSharp from '../../../../../../assets/icons/detailsLivraison/BiListUl'
import UisExclamationTriangle from '../../../../../../assets/icons/detailsLivraison/UisExclamationTriangle'

import Tooltip from '@mui/material/Tooltip'
import IconEtatPackageLivree from '../../../../../../assets/icons/detailsLivraison/etatPackageLivree.svg'
import IconEtatPackageNonLivree from '../../../../../../assets/icons/detailsLivraison/etatPackageNonLivree.svg'
import EditIcon from '../../../../../../assets/icons/settingsCorner/editIcom'

import { styled } from '@mui/material/styles'

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    headCells,
    disabled,
    withChecked,
  } = props

  return (
    <TableHead>
      <TableRow>
        {withChecked && (
          <TableCell padding="checkbox">
            {rowCount > 0 && (
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
                disabled={disabled}
                id={'Check-All'}
              />
            )}
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            style={{
              fontWeight: '600',
              fontSize: '12px',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  withChecked: PropTypes.bool,
}

const StyledTableRow = styled(TableRow)(() => ({
  '&.Mui-selected': {
    backgroundColor: '#d6e5f9 !important',
  },
}))

const TablePackages = ({
  packageOrder,
  isLoadingPointagePackage,
  isActionPointagePackageErorr,
  isLoadingPointageForcePackage,
  headTable,
  setOpenDialogHistorique,
  setIdPackage,
  setBarCode,
  verifErrorPackage,
  packageForceCode,
  setOpenDiagForcePointage,
  setMsgPointage,
  setPayloadForcage,
  setOpenDiagProduits,
  selected,
  handleSelectAllClick,
  handleClick,
  isSelected,
  withChecked,
  isVisibiliteEtatPackage,
  isLoadingEtatPackage,
  isVisibiliteEtatPackageDone,
  setOpenDiagEditePackage,
  setPayloadEditePackage,
  isLoadingEditPackage,
}) => {
  return (
    <TableContainer sx={{ maxHeight: '600px' }}>
      <Table
        sx={{ minWidth: '100%' }}
        aria-labelledby="tableTitle"
        size={'medium'}
        stickyHeader
        aria-label="sticky table"
      >
        {packageOrder.length > 0 ? (
          <>
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={packageOrder.length}
              headCells={headTable}
              withChecked={withChecked}
              disabled={
                packageOrder.some((pack) => pack.code == '' || !pack.code) ||
                isLoadingPointagePackage ||
                isLoadingPointageForcePackage ||
                isVisibiliteEtatPackageDone ||
                isLoadingEditPackage
              }
            />
            <TableBody>
              {packageOrder.map((row, index) => {
                const isItemSelected = isSelected(row.code)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.code}
                    selected={isItemSelected}
                  >
                    {withChecked && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          id={`checkbox-${index}`}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => handleClick(event, row.code)}
                          disabled={
                            !row?.code ||
                            isLoadingPointagePackage ||
                            isLoadingPointageForcePackage ||
                            isVisibiliteEtatPackageDone ||
                            isLoadingEditPackage
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell
                      component="th"
                      scope="row"
                      padding="normale"
                      id={`BarCode-${index}`}
                    >
                      {row?.barcode || 'N/A'}
                    </TableCell>
                    {(isVisibiliteEtatPackage || isLoadingEtatPackage) && (
                      <TableCell
                        component="th"
                        scope="row"
                        padding="normale"
                        id={`etatColis-${index}`}
                        align="left"
                      >
                        {isLoadingEtatPackage ? (
                          <CircularProgress
                            color="primary"
                            style={{
                              width: '18px',
                              height: '18px',
                            }}
                          />
                        ) : row?.etatColisLivree ? (
                          <Tooltip title="Colis livré">
                            <img
                              src={IconEtatPackageLivree}
                              style={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Colis non livré">
                            <img
                              src={IconEtatPackageNonLivree}
                              style={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                    )}
                    <TableCell align="left" id={`Label-${index}`}>
                      {row?.label || 'N/A'}
                    </TableCell>
                    <TableCell align="left" id={`Poids-${index}`}>
                      {row?.weight ? row.weight : row?.poids ? row.poids : 'N/A'}
                    </TableCell>
                    <TableCell align="left" id={`sku-${index}`}>
                      {row?.sku ? row.sku : 'N/A'}
                    </TableCell>
                    <TableCell align="left" id={`Produit-${index}`}>
                      <IonListCircleSharp
                        style={{ cursor: 'pointer', fontSize: '1.5rem' }}
                        onClick={() => {
                          setOpenDiagProduits(true)
                          setIdPackage(row?.packageId)
                        }}
                        id={`Produit-details-${index}`}
                      />
                    </TableCell>
                    <TableCell align="left" id={`historique-${index}`}>
                      <InfoOutlinedIcon
                        style={{
                          cursor: !(
                            row?.code === packageForceCode &&
                            isLoadingPointageForcePackage
                          )
                            ? 'pointer'
                            : '',
                        }}
                        onClick={() => {
                          if (
                            !(
                              row?.code === packageForceCode &&
                              isLoadingPointageForcePackage
                            )
                          ) {
                            setOpenDialogHistorique(true)
                            setIdPackage(row?.packageId)
                            setBarCode(row.barcode)
                          }
                        }}
                        id={`historique-details-${index}`}
                      />
                    </TableCell>
                    <TableCell align="left" id={`warning-${index}`}>
                      <EditIcon
                        style={{
                          cursor: 'pointer',
                          color: '#51A160',
                        }}
                        onClick={() => {
                          setOpenDiagEditePackage(true)
                          setPayloadEditePackage(row)
                        }}
                        id={`Package-Edit-${index}`}
                      />
                    </TableCell>
                    {isActionPointagePackageErorr && verifErrorPackage && (
                      <TableCell align="left" id={`warning-${index}`}>
                        {row?.error &&
                          (row?.code === packageForceCode &&
                          isLoadingPointageForcePackage ? (
                            <CircularProgress
                              color="primary"
                              style={{
                                width: '18px',
                                height: '18px',
                              }}
                            />
                          ) : (
                            <UisExclamationTriangle
                              style={{
                                cursor: !isLoadingPointageForcePackage
                                  ? 'pointer'
                                  : '',
                                fontSize: '1.5rem',
                              }}
                              onClick={() => {
                                if (!isLoadingPointageForcePackage) {
                                  setOpenDiagForcePointage(true)
                                  setMsgPointage(row?.error.message)
                                  setPayloadForcage(row)
                                }
                              }}
                              id={`Package-Forcage-${index}`}
                            />
                          ))}
                      </TableCell>
                    )}
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </>
        ) : (
          <TableBody>
            <TableRow>
              <td colSpan={2}>
                <div className="empty-table-container">
                  <h4 className="empty-table">Pas de Colis</h4>
                </div>
              </td>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

TablePackages.propTypes = {
  packageOrder: PropTypes.array,
  isLoadingPointagePackage: PropTypes.bool,
  isLoadingPointageForcePackage: PropTypes.bool,
  isActionPointagePackageErorr: PropTypes.bool,
  headTable: PropTypes.array,
  setOpenDialogHistorique: PropTypes.func,
  setIdPackage: PropTypes.func,
  setBarCode: PropTypes.func,
  verifErrorPackage: PropTypes.bool,
  packageForceCode: PropTypes.string,
  setOpenDiagForcePointage: PropTypes.func,
  setMsgPointage: PropTypes.func,
  setPayloadForcage: PropTypes.func,
  setOpenDiagProduits: PropTypes.func,
  selected: PropTypes.array,
  handleSelectAllClick: PropTypes.func,
  handleClick: PropTypes.func,
  isSelected: PropTypes.bool,
  withChecked: PropTypes.bool,
  isVisibiliteEtatPackage: PropTypes.bool,
  isLoadingEtatPackage: PropTypes.bool,
  isVisibiliteEtatPackageDone: PropTypes.bool,
  setOpenDiagEditePackage: PropTypes.func,
  setPayloadEditePackage: PropTypes.func,
  isLoadingEditPackage: PropTypes.bool,
}
export default TablePackages
