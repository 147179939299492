import React from 'react'

import './index.scss'
import ClientSelection from './ClientSelection'
import ClientForm from '../../../../containers/main/PlaceOrders/ClientForm'
import PropTypes from 'prop-types'
import { PROGRAMMED_STATUS } from '../../../../utils/values'
import TextField from '@mui/material/TextField'

const INITIAL_CLIENT = {
  firstname: '',
  lastname: '',
  phone: '',
  mail: '',
  nbColis: 0,
  nonEnvoiMail: false,
  noteInterne: '',
  observation: '',
  type: '',
  typeCourse: PROGRAMMED_STATUS.code,
  vehiculeType: 'M',
  weight: 0,
  vip: false,
  name: '',
  addressQuerry: '',
}

export default function Client({
  newClient,
  handleNewClient,
  setSelectedClient,
  selectedClient,
  setClientErrors,
  updateClient,
  client,
  orders,
  setShipmentComplInfo,
}) {
  return (
    <>
      <div className="client-selection">
        <ClientSelection
          handleNewClient={handleNewClient}
          setSelectedClient={setSelectedClient}
          newClient={newClient}
          client={client}
        />
      </div>
      <div className="client-form-container">
        <ClientForm
          selectedClient={newClient ? INITIAL_CLIENT : selectedClient}
          newClient={newClient}
          setClientErrors={setClientErrors}
          updateClient={updateClient}
          isSubmited={client.isSubmitted || 0}
        />
      </div>
      {Array.isArray(orders) && orders.length > 0 && orders[0].shipment != null && (
        <div className="client-Information">
          <TextField
            id="observation"
            value={orders[0]?.shipment?.observation || ''}
            name="destinationObservation"
            onChange={($event) => {
              setShipmentComplInfo(orders[0]?.id, $event.target.value)
            }}
            label="Informations complémentaire d'arrivée"
            variant="outlined"
            multiline
            rows={4}
            sx={{ background: 'white' }}
          />
        </div>
      )}
    </>
  )
}
Client.propTypes = {
  newClient: PropTypes.bool,
  handleNewClient: PropTypes.func,
  setSelectedClient: PropTypes.func,
  selectedClient: PropTypes.any,
  setClientErrors: PropTypes.func,
  updateClient: PropTypes.func,
  client: PropTypes.object,
  orders: PropTypes.array,
  setShipmentComplInfo: PropTypes.func,
}
