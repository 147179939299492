import React from 'react'

import Typography from '@mui/material/Typography'
import WarningIcon from '@mui/icons-material/Warning'

/** A component that provides a no data found ui  */
export default function NoDataFound() {
  const style = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  }
  return (
    <Typography variant="h5" style={style}>
      <WarningIcon />
      {"Pas d'enregistrements"}
    </Typography>
  )
}
