import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import StepperSection from '../../../containers/main/PlaceOrders/Stepper'
import Step from '../../../containers/main/EditOrders/Step'
import RecapSection from '../../../containers/main/EditOrders/RecapSection'
import PlaceOrderMapContainer from '../../../containers/main/PlaceOrders/MapContainer'
import ValidationSection from '../../../containers/main/EditOrders/Validation'
import Loading from '../../../components/Loading'
import Grid from '@mui/material/Grid'
import HttpPopUp from '../../../components/PopUp/index'
import './style.scss'
import { useEffect } from 'react'
const EditOrder = ({
  isGetSalesChannelListLoading,
  getOrder,
  isScriptLoaded,
  resetOrder,
  resetDirections,
  errorMessage,
}) => {
  const params = useParams()
  let history = useHistory()

  useEffect(() => {
    getOrder(params.codeCourse)
    return () => {
      resetOrder()
      resetDirections()
    }
  }, [])
  const hidePopUp = () => {
    history.push('/')
  }

  return (
    <>
      <Helmet
        titleTemplate="Corner | Modifier commande"
        defaultTitle="Corner | Modifier commande"
      />
      <div
        id="edit-order-page"
        className={[
          'edit-order-page',
          isGetSalesChannelListLoading ? 'has-loader' : '',
        ].join('')}
      >
        <StepperSection />
        {isGetSalesChannelListLoading && <Loading />}

        <Grid container>
          {!isGetSalesChannelListLoading && errorMessage.length === 0 ? (
            <>
              {isScriptLoaded && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  justify="center"
                  className="column-container"
                >
                  <Step />{' '}
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={6} justify="center">
                <div className="right-section-container">
                  <div className="recap-header">
                    <RecapSection />
                  </div>
                  <div className="recap-map">
                    <PlaceOrderMapContainer />
                  </div>
                  <ValidationSection />
                </div>
              </Grid>
            </>
          ) : null}
          {!isGetSalesChannelListLoading && errorMessage.length !== 0 && (
            <HttpPopUp
              buttonText={'Fermer'}
              hidePopUp={hidePopUp}
              anError={true}
              show={true}
              status={'Erreur'}
              statusText={errorMessage}
            />
          )}
        </Grid>
      </div>
    </>
  )
}

EditOrder.propTypes = {
  isGetSalesChannelListLoading: PropTypes.bool.isRequired,
  getOrder: PropTypes.func,
  isScriptLoaded: PropTypes.any,
  codeCourse: PropTypes.any,
  resetOrder: PropTypes.func,
  resetDirections: PropTypes.func,
  errorMessage: PropTypes.string,
}
export default EditOrder
