import { connect } from 'react-redux'
import AddToNAvetteAction from '../../../components/ShipmentAction/AddToNAvette'
import { AddToNavette } from '../../../redux/order/actions'
import {
  getErrorActionCourse,
  getIsLoadingActionOrder,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  errorActionCourse: getErrorActionCourse(state),
})
const mapDisptachToProps = (dispatch) => ({
  AddToNavette: (payload) => {
    dispatch(AddToNavette(payload))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(AddToNAvetteAction)
