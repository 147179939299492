import { httpFetch, httpGet, httpPatch, httpPost } from '../http/actions'
import {
  GET_SESSIONS,
  GET_SESSIONS_SUCCESS,
  GET_SESSIONS_FAILURE,
  ADD_SESSION,
  ADD_SESSION_FAILURE,
  ADD_SESSION_SUCCESS,
  UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILURE,
  GET_SESSION_BY_ID,
  GET_SESSION_BY_ID_SUCCESS,
  GET_SESSION_BY_ID_FAILURE,
  FETCH_SESSION_DETAILS,
  FETCH_SESSION_DETAILS_SUCCESS,
  FETCH_SESSION_DETAILS_FAILURE,
  FETCH_SESSION_PARTICIPANTS,
  FETCH_SESSION_PARTICIPANTS_SUCCESS,
  FETCH_SESSION_PARTICIPANTS_FAILURE,
  SESSION_PARTICIPANT_UPDATE,
  SESSION_PARTICIPANT_UPDATE_SUCCESS,
  SESSION_PARTICIPANT_UPDATE_FAILURE,
  SESSION_DELETE,
  SESSION_DELETE_SUCCESS,
  SESSION_DELETE_FAILURE,
} from './constants'

import { axiosHTTP } from '../../utils'
import { removeNull } from '../../utils/utils'

const geodisApi = process.env.REACT_APP_GEODIS_MS_URL

let CancelToken = axiosHTTP.CancelToken
let cancel
let cancelSessionByID
export default (store) => (next) => (action) => {
  // GET SESSIONS
  if (action.type === GET_SESSIONS) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      sessions: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}session/getSessionsByfilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: GET_SESSIONS_SUCCESS,
        failure: GET_SESSIONS_FAILURE,
      })
    )
  }

  if (action.type === ADD_SESSION) {
    const { session } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}session/create`,
        data: removeNull(session),
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: ADD_SESSION_SUCCESS,
        failure: ADD_SESSION_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_SESSION) {
    store.dispatch(
      httpPatch({
        endPoint: `${geodisApi}session/update`,
        data: removeNull(action.payload),
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        success: UPDATE_SESSION_SUCCESS,
        failure: UPDATE_SESSION_FAILURE,
      })
    )
  }

  if (action.type === GET_SESSION_BY_ID) {
    // cancel the request
    if (cancelSessionByID != undefined) {
      cancelSessionByID()
    }
    const { id } = action.payload

    store.dispatch(
      httpGet({
        endPoint: `${geodisApi}session/getSessionById/${id}`,
        cancelToken: new CancelToken(function executor(d) {
          // An executor function receives a cancel function as a parameter
          cancelSessionByID = d
        }),
        success: GET_SESSION_BY_ID_SUCCESS,
        failure: GET_SESSION_BY_ID_FAILURE,
      })
    )
  }
  if (action.type === FETCH_SESSION_DETAILS) {
    const { id } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${geodisApi}session/details/${id}`,
        success: FETCH_SESSION_DETAILS_SUCCESS,
        failure: FETCH_SESSION_DETAILS_FAILURE,
      })
    )
  }

  if (action.type === FETCH_SESSION_PARTICIPANTS) {
    const { id } = action.payload
    store.dispatch(
      httpFetch({
        endPoint: `${geodisApi}participantSession/${id}`,
        success: FETCH_SESSION_PARTICIPANTS_SUCCESS,
        failure: FETCH_SESSION_PARTICIPANTS_FAILURE,
      })
    )
  }

  if (action.type === SESSION_PARTICIPANT_UPDATE) {
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}participantSession/update`,
        data: removeNull(action.payload),
        success: SESSION_PARTICIPANT_UPDATE_SUCCESS,
        failure: SESSION_PARTICIPANT_UPDATE_FAILURE,
      })
    )
  }

  if (action.type === SESSION_DELETE) {
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}session/softRemove`,
        data: action.payload,
        success: SESSION_DELETE_SUCCESS,
        failure: SESSION_DELETE_FAILURE,
      })
    )
  }

  next(action)
}
