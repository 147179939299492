import { httpPatch, httpPost } from '../http/actions'
import {
  GET_TOURNEES,
  GET_TOURNEES_SUCCESS,
  GET_TOURNEES_FAILURE,
  ADD_TOURNEE,
  ADD_TOURNEE_FAILURE,
  ADD_TOURNEE_SUCCESS,
  UPDATE_TOURNEE,
  UPDATE_TOURNEE_SUCCESS,
  UPDATE_TOURNEE_FAILURE,
} from './constants'

import { axiosHTTP } from '../../utils'
import { removeNull } from '../../utils/utils'

const geodisApi = process.env.REACT_APP_GEODIS_MS_URL

let CancelToken = axiosHTTP.CancelToken
let cancel
export default (store) => (next) => (action) => {
  // GET TOURNEES
  if (action.type === GET_TOURNEES) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const {
      tournees: { filter },
    } = store.getState()

    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}ressourceAvailibility/getRessourceAvailibilityByfilter`,
        data: removeNull(filter),
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: GET_TOURNEES_SUCCESS,
        failure: GET_TOURNEES_FAILURE,
      })
    )
  }

  if (action.type === ADD_TOURNEE) {
    const { tournee } = action.payload
    store.dispatch(
      httpPost({
        endPoint: `${geodisApi}ressourceAvailibility/create`,
        data: tournee,
        success: ADD_TOURNEE_SUCCESS,
        failure: ADD_TOURNEE_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_TOURNEE) {
    const { newTournee } = action.payload

    store.dispatch(
      httpPatch({
        endPoint: `${geodisApi}ressourceAvailibility/update`,
        data: newTournee,
        success: UPDATE_TOURNEE_SUCCESS,
        failure: UPDATE_TOURNEE_FAILURE,
      })
    )
  }

  next(action)
}
