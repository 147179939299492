import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NavFilter from '../../../containers/main/NavFilter'
import TourTableDetails from './TourTable/TourTable'
import CourseTourTable from './TourTable/CourseTourTable'
import Loading from '../../../components/Loading'
import Error from '../../../components/Modal/HttpErrorPopUp'
import TourMapComponent from '../../../components/TourMap'
import Planification from '../../../containers/main/Plannification'
import NoDataFound from '../../../components/NoDataFound'
import {
  StyledTableTourneeContainer,
  StyledMapContainer,
  StyledMapTableContainer,
} from './style'
import { settings, headTableColumns, checkActions } from './data'
import { useHistory } from 'react-router-dom'

function Tournee({
  tourList,
  countTours,
  isLoading,
  isError,
  errorMessage,
  getDriversPlannings,
  pageLimitChanged,
  pageChanged,
  deleteTours,
  isTourActionSimulate,
  isViewPlannification,
  filterReducer,
  resetFilterTour,
  setFilter,
  viewPlannification,
  defaultFilterTours,
}) {
  const [openError, setOpenError] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [columns, setColumns] = useState([])
  const [selected, setSelected] = useState([])
  const [courses, setCourses] = useState([])
  const [selectedTours, setSelectedTours] = useState([])
  const history = useHistory()

  useEffect(() => {
    return () => viewPlannification(false)
  }, [])

  useEffect(() => {
    if (isTourActionSimulate) {
      setSelected([])
      setSelectedTours([])
    }
  }, [isTourActionSimulate])

  const onErrorClose = () => {
    setOpenError(false)
  }
  useEffect(() => {
    if (isError) setOpenError(true)
  }, [isError])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    pageChanged(newPage * rowsPerPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setSelectedTours([])
    setSelected([])
    setRowsPerPage(parseInt(event.target.value, 10))
    pageLimitChanged(parseInt(event.target.value, 10))
  }

  useEffect(() => {
    const columnsKey = 'tours_table_columns'
    const columnsList = headTableColumns
    const list = JSON.parse(localStorage.getItem(columnsKey))
    if (list) setColumns(list)
    else {
      localStorage.setItem(columnsKey, JSON.stringify(columnsList))
      setColumns(columnsList)
    }
  }, [])

  const handleColumns = (list) => {
    setColumns(list)
    const columnsKey = 'tours_table_columns'
    localStorage.setItem(columnsKey, JSON.stringify(list))
  }
  const getChecked = (name) => {
    let isChecked = false
    columns.forEach((column) => {
      if (column.name === name) {
        isChecked = column.checked
      }
    })
    return isChecked
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tourList.map((n) => n.ldt)
      setSelectedTours([...new Set([...tourList, ...selectedTours])])
      setSelected([...new Set([...newSelecteds, ...selected])])
      return
    }
    setSelectedTours([])
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)

    let newSelected = []
    let newSelectedTours = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
      const tour = tourList.find((tour) => tour.ldt === id)
      newSelectedTours = newSelectedTours.concat(selectedTours, tour)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
      newSelectedTours = newSelectedTours.concat(selectedTours.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
      newSelectedTours = newSelectedTours.concat(selectedTours.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
      newSelectedTours = newSelectedTours.concat(
        selectedTours.slice(0, selectedIndex),
        selectedTours.slice(selectedIndex + 1)
      )
    }
    if (newSelected.length === 1) {
      const visits = newSelectedTours[0].visits
      setCourses(visits)
    } else {
      setCourses([])
    }
    setSelectedTours(newSelectedTours)
    setSelected(newSelected)
  }
  const isSelected = (id) => selected.indexOf(id) !== -1
  const getDriversPlanningsFromActions = (payload) => {
    getDriversPlannings(payload)
    setSelected([])
    setSelectedTours([])
  }

  return (
    <>
      {isViewPlannification ? (
        <Planification />
      ) : (
        <>
          <NavFilter
            deliveryDateFilterRange={true}
            filterReducer={filterReducer}
            resetFilter={resetFilterTour}
            setFilter={setFilter}
            pathnameFilter={history.location.pathname}
            defaultFilter={defaultFilterTours}
          />
          {isLoading || isTourActionSimulate ? (
            <Loading />
          ) : isError ? (
            <Error
              statusText={isError?.statusText || errorMessage}
              open={openError}
              setOpen={onErrorClose}
            />
          ) : (
            <>
              {tourList.length == 0 ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    marginTop: '40px',
                  }}
                >
                  <NoDataFound />
                </div>
              ) : (
                <div>
                  <StyledMapTableContainer
                    style={{
                      height:
                        selected.length == 1
                          ? 'calc(100vh - 377px)'
                          : 'calc(100vh - 126px)',
                    }}
                  >
                    <StyledTableTourneeContainer>
                      <TourTableDetails
                        tourList={tourList}
                        countTours={countTours}
                        isSelected={isSelected}
                        headTableColumns={headTableColumns}
                        selected={selected}
                        selectedTours={selectedTours}
                        columns={columns}
                        checkActions={checkActions}
                        settings={settings}
                        getChecked={getChecked}
                        handleClick={handleClick}
                        handleSelectAllClick={handleSelectAllClick}
                        handleColumns={handleColumns}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        isLoading={isLoading}
                        deleteTours={deleteTours}
                        getDriversPlannings={getDriversPlanningsFromActions}
                      />
                    </StyledTableTourneeContainer>
                    <StyledMapContainer>
                      <TourMapComponent
                        tours={selectedTours}
                        polyLines={selectedTours
                          .map((e) => {
                            return e.path
                          })
                          .filter((e) => e !== undefined)}
                      />
                    </StyledMapContainer>
                  </StyledMapTableContainer>
                  {selected.length == 1 && (
                    <div style={{ height: '206px' }}>
                      <CourseTourTable
                        deliveryDate={selectedTours[0]?.deliveryDate}
                        visits={courses}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
Tournee.propTypes = {
  tourList: PropTypes.array,
  countTours: PropTypes.number,
  isLoading: PropTypes.bool,
  isError: PropTypes.any,
  errorMessage: PropTypes.any,
  isViewPlannification: PropTypes.bool,
  viewPlannification: PropTypes.func,
  getDriversPlannings: PropTypes.func,
  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,
  deleteTours: PropTypes.func,
  isTourActionSimulate: PropTypes.bool,
  filterReducer: PropTypes.object,
  setFilter: PropTypes.func,
  resetFilterTour: PropTypes.func,
  defaultFilterTours: PropTypes.object,
}
export default Tournee
