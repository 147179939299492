import { connect } from 'react-redux'

import SalesChannels from '../../../components/NavFilter/components/Filter/cell/SalesChannels'
import {
  getIsGetSalesChannelListLoading,
  getSalesChannelListGroup,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  salesChannels: getSalesChannelListGroup(state),
  isLoading: getIsGetSalesChannelListLoading(state),
})

export default connect(mapStateToProps, null)(SalesChannels)
