import React from 'react'
import PropTypes from 'prop-types'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Checkbox from '@mui/material/Checkbox'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import { StyledTextLabel } from './style'
import { Box, IconButton, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: '0px 16px 0px 0px',
  '& .MuiAccordionSummary-content': { margin: 'unset!important' },
  '&.Mui-expanded': {
    minHeight: '48px',
  },
}))
export default function AccordionItem({
  id,
  index,
  heading,
  summary,
  details,
  //path,
  color,
  updateFocusedTours,
  isFocused,
  expanded,
  handleChange,
  lock,
  verrouillerTournee,
  deVerrouillerTournee,
}) {
  const handleCheckboxChange = (event) => {
    updateFocusedTours(!event.target.checked, [index])
  }

  return (
    <Accordion
      key={id}
      expanded={expanded}
      onChange={(e) => {
        handleChange(index)
        e.stopPropagation()
      }}
      sx={{
        margin: '0 !important',
      }}
    >
      <StyledAccordionSummary expandIcon={<ExpandMore />} aria-controls={id} id={id}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            paddingRight: '20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip
              title={<span style={{ fontSize: 12 }}>Itineraire du tournée</span>}
              placement="right"
            >
              <Checkbox
                checked={isFocused}
                onChange={handleCheckboxChange}
                onClick={(e) => e.stopPropagation()}
              />
            </Tooltip>
            {lock ? (
              <IconButton
                aria-label="lock"
                onClick={(e) => {
                  deVerrouillerTournee([index])
                  e.stopPropagation()
                }}
              >
                <LockIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="lock"
                onClick={(e) => {
                  verrouillerTournee([index])
                  e.stopPropagation()
                }}
              >
                <LockOpenIcon />
              </IconButton>
            )}
            <Tooltip
              title={<span style={{ fontSize: 12 }}>{heading.name}</span>}
              placement="bottom"
            >
              <Box sx={{ color: color, fontSize: '1rem' }}>{heading.firstName}</Box>
            </Tooltip>
          </Box>

          {summary.map((e, i) => {
            return (
              <Tooltip
                title={<span style={{ fontSize: 12 }}>{e.unit}</span>}
                placement="bottom"
                key={i}
              >
                <div style={{ color: e.color }}>
                  <StyledTextLabel>
                    <div style={{ cursor: 'pointer' }}>{e.label}</div>
                  </StyledTextLabel>
                  {e.content}
                </div>
              </Tooltip>
            )
          })}
        </Box>
      </StyledAccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>{details}</AccordionDetails>
    </Accordion>
  )
}

AccordionItem.propTypes = {
  id: PropTypes.any,
  summary: PropTypes.any,
  heading: PropTypes.any,
  details: PropTypes.any,
  updateFocusedTours: PropTypes.func,
  index: PropTypes.any,
  color: PropTypes.string,
  isFocused: PropTypes.any,
  expanded: PropTypes.bool,
  handleChange: PropTypes.func,
  lock: PropTypes.bool,
  verrouillerTournee: PropTypes.func,
  deVerrouillerTournee: PropTypes.func,
}
