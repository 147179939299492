import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CategorieRecherche from './categorieRecherche'
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery'
import AutoComplete from '../../../AutoComplete'
import SearchIcon from '@mui/icons-material/Search'
import { CATEGORIE_RECHERCHE } from '../../../../utils/values'
export default function SearchInCorner({
  fetchSearchCorner,
  coursesSearch,
  fetchCourses,
  fetchSearchCornerSave,
  isLoading,
  myCommandsIsOn,
  getCourse,
  saveSearch,
  searchReducer,
}) {
  const classesAutoComplete = {
    '& .MuiAutocomplete-clearIndicator': {
      color: '#4a4646 !important',
      '& .MuiSvgIcon-fontSizeSmall': {
        width: '20px !important',
        height: '20px !important',
      },
      marginRight: '5px !important',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '10px !important',
    },
  }
  const [options, setOptions] = useState([])
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(false)
  const [change, setChange] = useState(false)
  const [categorieSelected, setCategorieSelected] = useState({
    code: 'LDV',
    label: 'Lettre de voiture',
  })
  const [changeCategorie, setChangeCategorie] = useState(false)
  useEffect(() => {
    if (searchReducer?.label && searchReducer?.categorie && !change && !selected) {
      setSearch(searchReducer?.label)
      setCategorieSelected(searchReducer?.categorie)
      setSelected(true)
      setTimeout(() => {
        const searchCorner = {
          criteria: searchReducer?.label,
          type: searchReducer?.categorie.code,
        }
        fetchSearchCornerSave(searchCorner)
      }, 50)
    }
  }, [searchReducer])
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 3 && change && !selected) {
        const searchCorner = {
          criteria: search,
          type: categorieSelected.code,
        }
        fetchSearchCorner(searchCorner)
      } else {
        setOptions([])
      }
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [search])
  useEffect(() => {
    if (coursesSearch.length > 0) {
      if (coursesSearch.length > 1) {
        coursesSearch.unshift({
          label: `Afficher tous les résultats pour ${search}`,
          course: null,
        })
      }
      setOptions(coursesSearch)
    }
  }, [coursesSearch])
  const onSelect = (value) => {
    if (value === null) {
      getCourse(myCommandsIsOn)
      saveSearch(value)
    } else if (value.course != null) {
      fetchCourses([value.course])
      saveSearch({
        courses: [value.course],
        label: value.label,
        categorie: categorieSelected,
      })
    } else {
      const courses = options.filter((el) => el.course).map((el) => el.course)
      fetchCourses(courses)
      saveSearch({ courses: courses, label: search, categorie: categorieSelected })
    }
    setSelected(true)
  }
  const onChange = (value) => {
    let val = value.trim()
    if (value.indexOf('Afficher tous les résultats pour ') != -1)
      val = val.substr(val.indexOf(search), val.length)
    if ((searchReducer?.label && !change && val === '') || changeCategorie) {
      setSearch('')
      saveSearch(null)
      setSelected(false)
      getCourse(myCommandsIsOn)
      setChange(false)
      setChangeCategorie(false)
    } else {
      setSelected(false)
      setSearch(val)
      setChange(true)
    }
  }
  const changeCategorieFilter = (codeCategorie) => {
    setCategorieSelected(codeCategorie)
    if (search) {
      setSearch('')
      setChangeCategorie(true)
      setOptions([])
    }
  }
  const matches = useMediaQuery('(max-width:538px)')
  return (
    <div
      style={
        !matches
          ? {
              display: 'flex',
              width: '450px',
              margin: '15px 2px',
              borderRadius: '4px',
              backgroundColor: 'white',
              border: '1px solid #C2C2C2',
            }
          : {
              display: 'flex',
              width: '100%',
              margin: '15px 2px',
              borderRadius: '4px',
              backgroundColor: 'white',
              border: '1px solid #C2C2C2',
            }
      }
    >
      <div
        style={
          !matches
            ? {
                display: 'inline-flex',
                alignItems: 'center',
                width: '159px',
                borderRight: '1px solid #C2C2C2',
                backgroundColor: '#dadce07a',
                fontWeight: '500',
                borderRadius: '4px 0 0 4px',
              }
            : {
                display: 'inline-flex',
                alignItems: 'center',
                width: '64px',
                borderRight: '1px solid #C2C2C2',
                backgroundColor: '#dadce07a',
                fontWeight: '500',
                borderRadius: '4px 0px 0px 4px',
              }
        }
      >
        <CategorieRecherche
          changeCategorieFilter={changeCategorieFilter}
          categorieSelected={categorieSelected}
          categorieRecherche={CATEGORIE_RECHERCHE}
          widthPaper={'158px'}
        />
      </div>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          paddingLeft: '7px',
          borderRadius: '0px 4px 4px 0px',
        }}
      >
        <div style={{ width: '100%', margin: 'auto 0' }}>
          <AutoComplete
            id={'searchInput'}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            onChange={(event, value) => onSelect(value)}
            onInputChange={(event, value) => onChange(value)}
            loading={isLoading}
            options={options}
            placeholder={'Rechercher dans corner'}
            classes={classesAutoComplete}
            value={search}
            startIcon={<SearchIcon sx={{ paddingRight: '4px', fontSize: '20px' }} />}
            forcePopupIcon={false}
            variant="standard"
          />
        </div>
      </div>
    </div>
  )
}
SearchInCorner.propTypes = {
  fetchSearchCorner: PropTypes.func,
  coursesSearch: PropTypes.array,
  disabled: PropTypes.bool,
  handleDrawer: PropTypes.func,
  isLoading: PropTypes.bool,
  myCommandsIsOn: PropTypes.bool,
  getCourse: PropTypes.func,
  saveSearch: PropTypes.func,
  searchReducer: PropTypes.any,
  fetchCourses: PropTypes.func,
  fetchSearchCornerSave: PropTypes.func,
}
