import { connect } from 'react-redux'

import VerificationModal from '../../../pages/settings/components/Modals/VerificationModal'

import { annulationParticipation } from '../../../redux/participants/actions'
import {
  getErrorMessage,
  getisLoadingParticipantAction,
  getSuccess,
} from '../../../redux/participants/selectors'

const mapStateToProps = (state) => ({
  isLoading: getisLoadingParticipantAction(state),
  success: getSuccess(state),
  errorMessage: getErrorMessage(state),
})

const mapDisptachToProps = (dispatch) => ({
  verificationActon: (payload) => dispatch(annulationParticipation(payload)),
})

export default connect(mapStateToProps, mapDisptachToProps)(VerificationModal)
