import { connect } from 'react-redux'

import Deprogram from '../../../components/ShipmentAction/Deprogram'
import { handleDeprogramClick } from '../../../redux/order/actions'
import {
  getErrorActionCourse,
  getIsLoadingActionOrder,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  errorActionCourse: getErrorActionCourse(state),
})
const mapDisptachToProps = (dispatch) => ({
  handleDeprogramClick: (payload) => {
    dispatch(handleDeprogramClick(payload))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(Deprogram)
