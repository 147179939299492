import { connect } from 'react-redux'

import Prestations from '../../../components/NavFilter/components/Filter/cell/Prestations'
import {
  getServices,
  getIsServicesListLoading,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  prestations: getServices(state),
  isLoading: getIsServicesListLoading(state),
})

export default connect(mapStateToProps, null)(Prestations)
