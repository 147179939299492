export const getGoogleMapDirectionState = ({ googleMapDirection }) =>
  googleMapDirection
export const getIsGetDirectionRouteLoading = (state) =>
  getGoogleMapDirectionState(state).isGetDirectionRouteLoading
export const getDirectionRoute = (state) =>
  getGoogleMapDirectionState(state).directionRoute
export const getDirectionRouteList = (state) =>
  getGoogleMapDirectionState(state).directionRouteList

export const getIsScriptLoadedSuccess = (state) =>
  getGoogleMapDirectionState(state).isScriptLoadedSuccess
