import React from 'react'

export function IconParkOutlineCommunication(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="4">
        <path
          strokeLinejoin="round"
          d="M33 38H22v-8h14v-8h8v16h-5l-3 3l-3-3Z"
        ></path>
        <path strokeLinejoin="round" d="M4 6h32v24H17l-4 4l-4-4H4V6Z"></path>
        <path d="M19 18h1m6 0h1m-15 0h1"></path>
      </g>
    </svg>
  )
}
export default IconParkOutlineCommunication
