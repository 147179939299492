import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import moment from 'moment'
import 'moment/locale/fr'
import { getCourseType } from '../../utils/utils'

//import { findPinColor } from '../../constants/googleMapConstants'
// TODO :  create a custom reusable component
export default function MarkerToolTip({
  ldv,
  prix,
  ticket,
  salesChannel,
  startDate,
  programmerShipmentData,
}) {
  //console.log('marker rendered ')
  const {
    weight,
    volume,
    factures,
    courseMetadata,
    status,
    //pickedDate,
    adresse,
  } = programmerShipmentData || {}

  const earliestDelivery =
    courseMetadata && courseMetadata?.earliestDeliveryDate
      ? moment(courseMetadata?.earliestDeliveryDate).format('LLLL')
      : null
  const latestDelivery =
    courseMetadata && courseMetadata?.latestDeliveryDate
      ? moment(courseMetadata?.latestDeliveryDate).format('LLLL')
      : null

  return (
    <span className="info-window">
      <p className=" info-window-title">{ldv}</p> <hr />
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        <li>Prix Client: {parseInt(prix).toFixed(2)}€</li>
        <li>Canal: {salesChannel}</li>

        {factures && <li>Ref: {factures}</li>}

        {!programmerShipmentData && startDate && <li>Date: {startDate}</li>}
        {programmerShipmentData && status && status.code && (
          <li>Status: {status.code}</li>
        )}
        {programmerShipmentData && courseMetadata && courseMetadata?.courseType && (
          <li>Type de course: {getCourseType(courseMetadata.courseType)}</li>
        )}

        {programmerShipmentData && adresse && <li>Addresse: {adresse.address}</li>}
        {ticket && <li>Créneau de livraison: {ticket}</li>}
        {programmerShipmentData && earliestDelivery && (
          <li>Livraison au plus tôt: {earliestDelivery}</li>
        )}
        {programmerShipmentData && latestDelivery && (
          <li
            style={{
              color: '#2da963' /*color: findPinColor(pickedDate, courseMetadata) */,
            }}
          >
            Livraison au plus tard: {latestDelivery}
          </li>
        )}
        {programmerShipmentData && weight != null && <li>Poids: {weight}Kg</li>}
        {programmerShipmentData && volume != null && <li>Volume: {volume}m3</li>}
      </ul>
    </span>
  )
}
MarkerToolTip.propTypes = {
  ldv: PropTypes.string,
  prix: PropTypes.string,
  ticket: PropTypes.string,
  salesChannel: PropTypes.string,
  startDate: PropTypes.string,
  programmerShipmentData: PropTypes.object,
}
