import { connect } from 'react-redux'
import ShipmentAssembly from '../../../pages/main/PlaceOrders/ShipmentAssembly'

import { getOrdersState } from '../../../redux/shipment/selectors'

const mapStateToProps = (state) => ({
  orders: getOrdersState(state),
})

export default connect(mapStateToProps)(ShipmentAssembly)
