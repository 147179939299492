import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import TableKeys from '../components/Table/ConfigurationTable'
import { StyledContainer } from '../styled'
import { ChannelTableHead } from '../components/Table/data'

const ConfigurationCanal = ({
  fetchData,
  success,
  isLoadingChannelList,
  pageLimitChanged,
  pageChanged,
  searchData,
  onCloseReset,
  salesChannel,
  salesChannelCount,
  errorMessage,
  removeError,
  isErrorFetch,
  isSuccessRefresh,
  resetSuccessAction,
}) => {
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (success && isSuccessRefresh) {
      fetchData()
    }
    if (success || isSuccessRefresh) {
      resetSuccessAction()
    }
  }, [success])

  return (
    <>
      <StyledContainer>
        <TableKeys
          globalID={'salesChannel'}
          searchData={searchData}
          searchPlaceholder={'Rechercher par canal de vente'}
          tableHead={ChannelTableHead}
          tableRows={salesChannel}
          isChannel={true}
          actionButton={true}
          title={'Association Clés-Canal'}
          loadingData={isLoadingChannelList}
          pageLimitChanged={pageLimitChanged}
          pageChanged={pageChanged}
          onCloseReset={onCloseReset}
          countData={salesChannelCount}
          errorMessage={errorMessage}
          removeError={removeError}
          isErrorFetch={isErrorFetch}
          fetchData={fetchData}
          resetPagination={success && isSuccessRefresh}
        />
      </StyledContainer>
    </>
  )
}
ConfigurationCanal.propTypes = {
  fetchData: PropTypes.func,
  isLoadingChannelList: PropTypes.bool,
  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,
  searchData: PropTypes.func,
  onCloseReset: PropTypes.func,
  success: PropTypes.bool,
  salesChannel: PropTypes.array,
  salesChannelCount: PropTypes.number,
  errorMessage: PropTypes.string,
  removeError: PropTypes.func,
  isErrorFetch: PropTypes.bool,
  isSuccessRefresh: PropTypes.bool,
  resetSuccessAction: PropTypes.func,
}
export default ConfigurationCanal
