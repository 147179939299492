import React from 'react'

export function IcBaselineOutlinedFlag(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m14 6l-1-2H5v17h2v-7h5l1 2h7V6h-6zm4 8h-4l-1-2H7V6h5l1 2h5v6z"
      ></path>
    </svg>
  )
}
export default IcBaselineOutlinedFlag
