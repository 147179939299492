import initialState from './initialState'
import {
  FETCH_CLIENT_CRITERIA_SUCCESS,
  FETCH_CLIENT_CRITERIA_FAILURE,
  CHANGE_SEARCH_CRITERIA_BY_PHONE,
  VERIFY_PROMO_CODE_SUCCESS,
  VERIFY_PROMO_CODE_FAILURE,
  HIDE_POP_UP,
  ADD_ASSEMBLY_ATTACHMENTS,
  SET_PAYMENT_FORM,
  COMMAND_CLICK,
  ASSEMBLY_SAVED,
  FETCH_ONEWEEK_SHIPMENTS_SUCCESS,
  FETCH_ONEWEEK_SHIPMENTS_FAILURE,
  UPDATE_SHIPMENT,
  UPDATE_SHIPMENT_SUCCESS,
  UPDATE_SHIPMENT_FAILURE,
  REMOVE_ERROR,
  CREATE_NEW_ORDER,
  CREATE_NEW_ORDER_FAILURE,
  CREATE_NEW_ORDER_SUCCESS,
  CHANGE_STATUS_DEVIS,
  VIEW_FILES,
  VIEW_FILES_SUCCESS,
  VIEW_FILES_FAILURE,
  DOWNLOAD_FILES,
  DOWNLOAD_FILES_SUCCESS,
  DOWNLOAD_FILES_FAILURE,
  INIT_ORDER,
  RESET_ORDER,
  ADD_ORDER,
  SET_INFO_COMP,
  SET_INFO_COMP_EDIT,
  UPDATE_SHIPMENT_DATA,
  UPDATE_ASSEMBLY_DATA,
  SET_ACTIVE_STEP,
  SUBMIT_FIRST_STEP,
  SET_SHIPMENT_ERRORS,
  SET_ASSEMBLY_ERRORS,
  SET_SHIPMENT_PRICE,
  SET_ASSEMBLY_PRICE,
  REMOVE_MARKER,
  REMOVE_DIRECTION,
  UPDATE_DIRECTIONS,
  UPDATE_MARKERS,
  ADD_ASSEMBLY,
  ADD_SHIPMENT,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILED,
  REMOVE_ORDER,
  REMOVE_ASSEMBLY,
  REMOVE_SHIPMENT,
  SET_CLIENT,
  SUBMIT_SECOND_STEP,
  SET_SELECTED_CLIENT,
  SET_IS_NEW_CLIENT,
  SET_CLIENT_ERRORS,
  ESTIMATE_SHIPMENT_PRICE_REQUEST,
  ESTIMATE_SHIPMENT_PRICE_SUCCESS,
  ESTIMATE_SHIPMENT_PRICE_ERROR,
  ESTIMATE_ASSEMBLY_PRICE_REQUEST,
  ESTIMATE_ASSEMBLY_PRICE_SUCCESS,
  ESTIMATE_ASSEMBLY_PRICE_ERROR,
  GET_CANAL_VENTE,
  GET_CANAL_VENTE_SUCCESS,
  GET_CANAL_VENTE_FAILURE,
  GET_RULES,
  GET_RULES_SUCCESS,
  GET_RULES_FAILURE,
} from './actionsConsts'
import onFailure from '../OnFailure'
import {
  ALPHABETIC_DATE_FORMAT,
  getCreneau,
  getCreneauHours,
} from '../../utils/utils'
import { clientInitialState } from './initialState'
import uuid from 'react-uuid'
import _ from 'lodash'
import { ORDER_TYPE } from '../../constants/appContants'
import { GOOGLE_MAP_CONSTANTS } from '../../constants/googleMapConstants'
import { MapMarker } from '../../models/mapMarker'
import { Order } from '../../models/order'
import { parseFloatWithDot } from '../../utils/floatFormatting'

function getTotalPrice(or) {
  return (
    (_.get(or, 'shipment.active') && _.get(or, 'shipment.clientPrice')
      ? parseFloat(parseFloatWithDot(or.shipment.clientPrice))
      : 0) +
    (_.get(or, 'assembly.active') && _.get(or, 'assembly.clientPrice')
      ? parseFloat(parseFloatWithDot(or.assembly.clientPrice))
      : 0)
  )
}

export default (state = initialState, action) => {
  const subOrder = {
    id: uuid(),
    active: true,
    isSubmitted: 0,
    errors: {},
  }

  //Fetch Client
  // TODO : add action type initialisation
  if (action.type === FETCH_CLIENT_CRITERIA_SUCCESS) {
    const searchByPhone = state.searchByPhone
    const { Clients, ContactArrivee } = action.payload.data
    let filteredClients = []
    let filteredContacts = []
    filteredClients = Clients.map((client) => {
      return {
        ...client,
        type: 'Clients',
      }
    })
    if (!searchByPhone) {
      filteredContacts = ContactArrivee.map((client) => {
        return {
          ...client,
          type: 'ContactArrivee',
        }
      })
    }

    const clients = filteredClients.concat(filteredContacts)
    return {
      ...state,
      clients: clients,
      isLoading: false,
    }
  }

  if (action.type === FETCH_CLIENT_CRITERIA_FAILURE) {
    let error = onFailure(action.payload)
    return {
      ...state,
      isLoading: false,
      error,
    }
  }

  if (action.type === CHANGE_SEARCH_CRITERIA_BY_PHONE) {
    const { searchByPhone } = action.payload
    return {
      ...state,
      searchByPhone,
    }
  }

  // Methode Filter Date
  // TODO : (A verifier)

  // Verify Promo Code
  // TODO : add action type initialisation (A verifier)

  if (action.type === VERIFY_PROMO_CODE_SUCCESS) {
    const { code, message, response } = action.payload.data
    console.log(code, message, response)
    return {
      ...state,
      codePromoVerification: { code, message, response },
    }
  }

  if (action.type === VERIFY_PROMO_CODE_FAILURE) {
    const { code, message, response } = action.payload.data.data
    console.log(code, message, response)
    return {
      ...state,
      codePromoVerification: { code, message, response },
    }
  }

  // Creation new course
  // TODO :  (A verifier)
  if (action.type === CREATE_NEW_ORDER) {
    return {
      ...state,
      showPopUp: false,
      createdNewCourse: [],
      createdNewAssembly: [],
      isLoading: true,
      codePromoVerification: null,
    }
  }
  if (action.type === CREATE_NEW_ORDER_SUCCESS) {
    let { courses, assemblies } = action.payload.data.response
    assemblies =
      assemblies !== undefined
        ? assemblies.map(
            (e) =>
              e.assembly ||
              (e.code !== 500
                ? 'Erreur : ' + e.message
                : 'Erreur :Il y avait une erreur')
          )
        : []
    courses = courses !== undefined ? courses : []
    const courseAssemblyCodes =
      courses
        .filter((e) => e.assembly !== null && e.assembly !== undefined)
        .map((e) => e.assembly) !== undefined
        ? courses
            .filter((e) => e.assembly !== null && e.assembly !== undefined)
            .map(
              (e) =>
                e.assembly ||
                (e.code !== 500
                  ? 'Erreur : ' + e.message
                  : 'Erreur :Il y avait une erreur')
            )
        : []
    const assemblyCode = [...assemblies, ...courseAssemblyCodes]
    // const errorMessage = assemblyCode.filter((a) => a.includes('Erreur'))
    const popup = {
      showPopUp: true,
      showPopUpDevis: false,
    }

    if (state.devis) {
      popup.showPopUp = false
      popup.showPopUpDevis = true
    }

    return {
      ...state,
      ...popup,
      isLoading: false,
      createdNewCourse: courses,
      codeAssembly: assemblyCode.filter((e) => e !== undefined),
      //  errorMessage,
    }
  }

  if (action.type === CREATE_NEW_ORDER_FAILURE) {
    const { response } = action.payload.data
    //Error Message
    return {
      ...state,
      showPopUp: true,
      isLoading: false,
      errorMessage: [
        ...state.errorMessage,
        'Erreur Ordre   :   ' +
          (response.data?.message || ' Veuillez réessayer plus tard '),
      ],
    }
  }
  // TODO : onclick sur button modifier ou creer Course  (A verifier)
  if (action.type === COMMAND_CLICK) {
    const command = action.payload.command
    return {
      ...state,
      command,
    }
  }

  // TODO :  (A verifier)
  if (action.type === SET_PAYMENT_FORM) {
    const paymentForm = action.payload.paymentForm
    return {
      ...state,
      paymentForm,
    }
  }

  // Update course
  if (action.type === UPDATE_SHIPMENT) {
    return {
      ...state,
      isLoading: true,
      errorMessage: [],
    }
  }

  if (action.type === UPDATE_SHIPMENT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showPopUp: true,
    }
  }

  if (action.type === UPDATE_SHIPMENT_FAILURE) {
    const { response } = action.payload.data
    return {
      ...state,
      isLoading: false,
      showPopUp: true,
      errorMessage: [
        ...state.errorMessage,
        'Erreur Livraison   :' + response.data?.message
          ? response.data?.message
          : 'Essayer plus tard',
      ],
    }
  }
  // Get Course With Filter Canal Vente lors Creation de course (Map)
  if (action.type === FETCH_ONEWEEK_SHIPMENTS_SUCCESS) {
    const oneWeekShipments = action.payload.data.data.map(
      ({
        adresseArrivee,
        adresseDepart,
        dateDemarrage,
        lettreDeVoiture: { code },
        status,
        commande: {
          canalVente: { name },
          montantGlobalTtc,
        },
        dateDemarrageMeta,
        dropOffStart,
        dropOffEnd,
      }) => {
        return {
          pickUpAddress: adresseDepart,
          dropOffAddress: adresseArrivee,
          deliveryDate: getCreneau(
            dropOffStart,
            dropOffEnd,
            dateDemarrageMeta,
            dateDemarrage,
            ALPHABETIC_DATE_FORMAT
          ),
          deliveryTime: getCreneauHours(
            dropOffStart,
            dropOffEnd,
            dateDemarrageMeta,
            dateDemarrage,
            '/'
          ),
          shipmentReference: code,
          status,
          salesChannelName: name,
          price: montantGlobalTtc,
        }
      }
    )
    return {
      ...state,
      oneWeekShipments,
    }
  }
  if (action.type === FETCH_ONEWEEK_SHIPMENTS_FAILURE) {
    return {
      ...state,
      oneWeekShipments: null,
    }
  }

  // Creation Course (DEVIS)
  if (action.type === CHANGE_STATUS_DEVIS) {
    const { status } = action.payload
    return {
      ...state,
      devis: status,
    }
  }
  // Consultation && Download Files
  if (action.type === VIEW_FILES) {
    return {
      ...state,
      isLoadingFiles: true,
    }
  }

  if (action.type === VIEW_FILES_SUCCESS) {
    const { data } = action.payload
    const lien = data.data
    const link = document.createElement('a')
    link.target = '_blank'
    link.href = lien
    link.click()

    return {
      ...state,
      isLoadingFiles: false,
    }
  }

  if (action.type === VIEW_FILES_FAILURE) {
    return {
      ...state,
      isLoadingFiles: false,
    }
  }

  if (action.type === DOWNLOAD_FILES) {
    return {
      ...state,
      isLoadingFiles: true,
    }
  }

  if (action.type === DOWNLOAD_FILES_SUCCESS) {
    return {
      ...state,
      isLoadingFiles: false,
    }
  }

  if (action.type === DOWNLOAD_FILES_FAILURE) {
    return {
      ...state,
      isLoadingFiles: false,
    }
  }

  //Action Error

  if (action.type === REMOVE_ERROR) {
    return {
      ...state,
      isLoading: false,
      error: null,
    }
  }

  if (action.type === HIDE_POP_UP) {
    return {
      ...state,
      showPopUp: false,
      showPopUpDevis: false,
      errorMessage: [],
    }
  }
  //Autre
  // TODO : add action type initialisation (A verifier)

  if (action.type === ADD_ASSEMBLY_ATTACHMENTS) {
    const { assemblyAttachements, index } = action.payload
    let assemblyAttachementsArray = { ...state.assemblyAttachements }
    assemblyAttachementsArray[index] = assemblyAttachements
    return {
      ...state,
      assemblyAttachements: assemblyAttachementsArray,
    }
  }
  if (action.type === ASSEMBLY_SAVED) {
    return {
      ...state,
      assemblyAttachements: [],
      codeAssembly: [],
    }
  }

  if (action.type === INIT_ORDER) {
    console.log('Init order')
    return Object.assign({}, state, {
      orders: [],
      markers: [],
      directions: [],
      totalPrice: 0,
      activeStep: 0,
      isNewClient: false,
      selectedClient: '',
      client: {
        ...clientInitialState,
      },
    })
  }
  if (action.type === ADD_ORDER) {
    let orders = state.orders
    const type = action.payload.type
    console.log(type)
    let newOrder = {
      id: uuid(),
      type: type,
      shipment: type === ORDER_TYPE.DELIVERY ? subOrder : null,
      assembly: type === ORDER_TYPE.ASSEMBLY ? subOrder : null,
      totalPrice: 0,
      isGetOperationalhoursLoading: true,
      operationalHoursList: [],
      isEstimateShipmentPriceLoading: true,
      shipmentEstimatedPrice: null,
      shipmentEstimatedPriceError: null,
      isEstimateAssemblyPriceLoading: true,
      assemblyEstimatedPrice: null,
      assemblyEstimatedPriceError: null,
    }
    orders.push(newOrder)
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }

  if (action.type === RESET_ORDER) {
    console.log('Order State Cleaning')
    return {
      ...state,
      isOrderLoading: true,
      directions: [],
      direction: {},
      orders: [],
      client: {},
      oldClient: {},
      oldOrders: [],
      paymentForm: '',
      ldvCreation: '',
      activeStep: 0,
      showPopUp: false,
    }
  }

  if (action.type === GET_ORDER) {
    console.log('Order state Getting')
    // console.log(state)

    return {
      ...state,
      isOrderLoading: true,
      directions: [],
      orders: [],
      client: {},
      direction: {},
      oldClient: {},
      oldOrders: [],
      paymentForm: '',
      ldvCreation: '',
      activeStep: 0,
      errorMessage: '',
      showPopUp: false,
    }
  }

  if (action.type === GET_ORDER_SUCCESS) {
    const { data } = action.payload
    // console.log(data.response)
    const orderToEdit = {
      ...new Order().getOrderFromResponse(data.response),
      shipment: new Order().getShipmentFromResponse(data.response),
    }
    // In order to prevent the double edit effect
    const oldOrder = {
      ...new Order().getOrderFromResponse(data.response),
      shipment: new Order().getShipmentFromResponse(data.response),
    }
    const clientToEdit = {
      ...new Order().getClientFromResponse(data.response),
    }
    let orders = state.orders
    // console.log(new Order().getClientFromResponse(data.response))
    // console.log(clientToEdit)

    orders.push(orderToEdit)
    return {
      ...state,
      isOrderLoading: false,
      orders: [...orders],
      client: { ...state.client, ...clientToEdit },
      oldClient: { ...clientToEdit },
      oldOrders: { ...oldOrder },
      paymentForm: data.response.commande?.moyenPaiement?.code ? true : false,
      ldvCreation: data.response.lettreDeVoiture.code,
    }
  }
  if (action.type === GET_ORDER_FAILED) {
    const message =
      action.payload?.data?.data?.message || "Une erreur s'est produite"
    return {
      ...state,
      errorMessage: message,
      isOrderLoading: false,
    }
  }

  if (action.type === SET_ACTIVE_STEP) {
    return Object.assign({}, state, {
      activeStep: action.payload.step,
    })
  }
  if (action.type === UPDATE_SHIPMENT_DATA) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.orderId)
    if (orderIndex >= 0) {
      orders[orderIndex].shipment = action.payload.data
    }
    return {
      ...state,
      orders: [...orders],
    }
  }
  if (action.type === SET_INFO_COMP) {
    // TODO : fix mutability
    const { id, info } = action.payload
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === id)
    if (orderIndex >= 0) {
      orders[orderIndex].shipment.observation = info
    }
    return {
      ...state,
      orders: [...orders],
    }
  }
  if (action.type === SET_INFO_COMP_EDIT) {
    //TODO : fix mutability
    const { id, info } = action.payload
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === id)
    if (orderIndex >= 0) {
      orders[orderIndex].shipment.destinationObservation = info
    }
    return {
      ...state,
      orders: [...orders],
    }
  }

  if (action.type === UPDATE_ASSEMBLY_DATA) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.orderId)
    if (orderIndex >= 0) {
      orders[orderIndex].assembly = action.payload.data
    }
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }

  if (action.type === SUBMIT_FIRST_STEP) {
    let orders = state.orders
    orders = orders.map((o) => {
      if (_.get(o, 'shipment.id')) {
        o.shipment.isSubmitted = o.shipment.isSubmitted + 1
      }
      if (_.get(o, 'assembly.id')) {
        o.assembly.isSubmitted = o.assembly.isSubmitted + 1
      }
      return o
    })
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }
  if (action.type === SET_SHIPMENT_ERRORS) {
    let orders = state.orders
    const index = orders.findIndex((o) => o.id === action.payload.orderId)
    if (index >= 0 && !_.isNil(_.get(orders[index], 'shipment'))) {
      orders[index].shipment.errors = action.payload.errors
    }
    return Object.assign({}, state, {
      orders: orders,
    })
  }
  if (action.type === SET_ASSEMBLY_ERRORS) {
    let orders = state.orders
    const index = orders.findIndex((o) => o.id === action.payload.orderId)
    if (index >= 0 && !_.isNil(_.get(orders[index], 'assembly'))) {
      orders[index].assembly.errors = action.payload.errors
    }
    return Object.assign({}, state, {
      orders: orders,
    })
  }
  if (action.type === SET_SHIPMENT_PRICE) {
    let orders = state.orders
    let totalPrice = state.totalPrice
    const index = orders.findIndex((o) => o.id === action.payload.orderId)
    if (index >= 0 && !_.isNil(_.get(orders[index], 'shipment'))) {
      orders[index].shipment.clientPrice = action.payload.price
      orders[index].totalPrice = getTotalPrice(orders[index])
      totalPrice = orders.reduce((a, b) => a + b.totalPrice, 0)
    }
    return Object.assign({}, state, {
      orders: orders,
      totalPrice: totalPrice,
    })
  }
  if (action.type === SET_ASSEMBLY_PRICE) {
    let orders = state.orders
    let totalPrice = state.totalPrice
    const index = orders.findIndex((o) => o.id === action.payload.orderId)
    if (index >= 0 && !_.isNil(_.get(orders[index], 'assembly'))) {
      orders[index].assembly.clientPrice = action.payload.price
      orders[index].totalPrice = getTotalPrice(orders[index])
      totalPrice = orders.reduce((a, b) => a + b.totalPrice, 0)
    }
    return Object.assign({}, state, {
      orders: orders,
      totalPrice: totalPrice,
    })
  }
  if (action.type === REMOVE_ORDER) {
    let orders = state.orders
    let totalPrice = state.totalPrice
    let markers = state.markers
    let directions = state.directions
    const orderIndex = orders.findIndex((o) => o.id === action.payload.order.id)
    if (orderIndex >= 0) {
      orders.splice(orderIndex, 1)
      totalPrice = orders.reduce((a, b) => a + b.totalPrice, 0)
      const markerIndex = markers.findIndex((m) => m.id === action.payload.orderId)
      if (markerIndex >= 0) {
        markers.splice(markerIndex, 1)
      }
      const directionIndex = directions.findIndex(
        (d) => d.id === action.payload.orderId
      )
      if (directionIndex >= 0) {
        directions.splice(directionIndex, 1)
      }
    }

    return Object.assign({}, state, {
      orders: [...orders],
      totalPrice: totalPrice,
      directions: [...directions],
      markers: [...markers],
    })
  }
  if (action.type === REMOVE_MARKER) {
    let markers = state.markers
    const markerIndex = markers.findIndex((o) => o.id === action.payload.markerId)
    if (markerIndex >= 0) {
      markers.splice(markerIndex, 1)
    }
    return Object.assign({}, state, {
      markers: [...markers],
    })
  }
  if (action.type === REMOVE_DIRECTION) {
    let directions = state.directions
    const directionIndex = directions.findIndex(
      (o) => o.id === action.payload.directionId
    )
    if (directionIndex >= 0) {
      directions.splice(directionIndex, 1)
    }
    return Object.assign({}, state, {
      directions: [...directions],
    })
  }
  if (action.type === UPDATE_DIRECTIONS) {
    let directions = state.directions
    const directionIndex = directions.findIndex(
      (o) => o.id === action.payload.directionId
    )
    if (directionIndex >= 0) {
      directions[directionIndex] = action.payload.data
    } else {
      directions.push(action.payload.data)
    }
    return Object.assign({}, state, {
      directions: [...directions],
    })
  }
  if (action.type === UPDATE_MARKERS) {
    const { markerId, data } = action.payload
    let markers = state.markers
    const markerIndex = markers.findIndex((m) => m.id === markerId)
    if (markerIndex >= 0) {
      if (data && data !== null && data.latlng) {
        markers[markerIndex] = new MapMarker().getFromMarker(markers[markerIndex])
        markers[markerIndex].setPosition(data.latlng.lat, data.latlng.lng)
        markers[markerIndex].settitle(data.formattedAddress)
      } else {
        markers.splice(markerIndex, 1)
      }
    } else if (data && data !== null && data.latlng) {
      markers.push(
        new MapMarker(
          markerId,
          data.latlng.lat,
          data.latlng.lng,
          data.formattedAddress,
          window.google.maps.Animation.BOUNCE,
          GOOGLE_MAP_CONSTANTS.ASSEMBLY_MARKER_ICON
        )
      )
    }
    return Object.assign({}, state, {
      markers: [...markers],
    })
  }

  if (action.type === ADD_ASSEMBLY) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.orderId)
    if (orderIndex >= 0) {
      orders[orderIndex].assembly = subOrder
      if (orders[orderIndex].type === ORDER_TYPE.DELIVERY) {
        orders[orderIndex].type = ORDER_TYPE.DELIVERY_ASSEMBLY
      }
    }
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }
  if (action.type === REMOVE_ASSEMBLY) {
    console.log('remove asebly')
    let orders = state.orders
    let markers = state.markers
    const orderIndex = orders.findIndex((o) => o.id === action.payload.orderId)
    console.log(orderIndex >= 0)
    if (orderIndex >= 0) {
      console.log('remove asebly')

      orders[orderIndex].assembly = null
      orders[orderIndex].isEstimateAssemblyPriceLoading = true
      orders[orderIndex].assemblyEstimatedPriceError = null
      const markerIndex = markers.findIndex((m) => m.id === action.payload.orderId)
      if (markerIndex >= 0) {
        markers.splice(markerIndex, 1)
      }

      if (orders[orderIndex].type === ORDER_TYPE.DELIVERY_ASSEMBLY) {
        orders[orderIndex].type = ORDER_TYPE.DELIVERY
      }
      if (orders[orderIndex].shipment === null) {
        orders.splice(orderIndex, 1)
      }
    }
    return Object.assign({}, state, {
      orders: [...orders],
      markers: [...markers],
    })
  }

  if (action.type === REMOVE_SHIPMENT) {
    let orders = state.orders
    let markers = state.markers
    const orderIndex = orders.findIndex((o) => o.id === action.payload.orderId)
    if (orderIndex >= 0 && orders[orderIndex].assembly !== null) {
      orders[orderIndex].shipment = null
      orders[orderIndex].shipmentEstimatedPriceError = null
      const markerIndex = markers.findIndex((m) => m.id === action.payload.orderId)
      if (markerIndex >= 0) {
        markers.splice(markerIndex, 1)
      }

      if (orders[orderIndex].type === ORDER_TYPE.DELIVERY_ASSEMBLY) {
        orders[orderIndex].type = ORDER_TYPE.ASSEMBLY
      }
      if (orders[orderIndex].assembly === null) {
        orders.splice(orderIndex, 1)
      }
    }
    return Object.assign({}, state, {
      orders: [...orders],
      markers: [...markers],
      directions: [],
    })
  }

  if (action.type === ADD_SHIPMENT) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.orderId)
    if (orderIndex >= 0) {
      orders[orderIndex].shipment = subOrder

      if (orders[orderIndex].type === ORDER_TYPE.ASSEMBLY) {
        orders[orderIndex].type = ORDER_TYPE.DELIVERY_ASSEMBLY
      }
    }

    return Object.assign({}, state, {
      ...state,
      orders: [...orders],
      directions: [],
    })
  }

  if (action.type === SET_CLIENT) {
    return Object.assign({}, state, {
      client: Object.assign(state.client, action.payload.data),
    })
  }
  if (action.type === SET_SELECTED_CLIENT) {
    return Object.assign({}, state, {
      isNewClient: false,
      selectedClient: action.payload.data,
    })
  }

  if (action.type === SET_IS_NEW_CLIENT) {
    return Object.assign({}, state, {
      isNewClient: action.payload.isNew,
      selectedClient: '',
    })
  }
  if (action.type === SUBMIT_SECOND_STEP) {
    let client = state.client

    client.isSubmitted = client?.isSubmitted ? client.isSubmitted + 1 : 1
    return Object.assign({}, state, {
      client: { ...client },
    })
  }
  if (action.type === SET_CLIENT_ERRORS) {
    let client = state.client
    client.errors = action.payload.errors
    return Object.assign({}, state, {
      client: { ...client },
    })
  }

  // TODO :  (A verifier)
  if (action.type === ESTIMATE_SHIPMENT_PRICE_REQUEST) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.id)
    if (orderIndex >= 0) {
      orders[orderIndex].isEstimateShipmentPriceLoading = true
      orders[orderIndex].shipmentEstimatedPrice = null
      orders[orderIndex].shipmentEstimatedPriceError = null
    }
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }
  if (action.type === ESTIMATE_SHIPMENT_PRICE_SUCCESS) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.id)
    if (orderIndex >= 0) {
      orders[orderIndex].isEstimateShipmentPriceLoading = false
      orders[orderIndex].shipmentEstimatedPrice = _.get(
        action,
        'payload.data.response'
      )
        ? {
            ...action.payload.data.response,
            MontantTotalHT: action.payload.data.response.MontantTotalHT.toFixed(2),
            MontantTotalTTC: action.payload.data.response.MontantTotalTTC.toFixed(2),
          }
        : null
      orders[orderIndex].shipmentEstimatedPriceError = null
    }
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }
  if (action.type === ESTIMATE_SHIPMENT_PRICE_ERROR) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.id)
    if (orderIndex >= 0) {
      orders[orderIndex].isEstimateShipmentPriceLoading = false
      orders[orderIndex].shipmentEstimatedPrice = null
      orders[orderIndex].shipmentEstimatedPriceError = _.get(action, 'payload.data')
        ? action.payload.data
        : null
    }
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }
  // TODO :  (A verifier)
  if (action.type === ESTIMATE_ASSEMBLY_PRICE_REQUEST) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.id)
    if (orderIndex >= 0) {
      orders[orderIndex].isEstimateAssemblyPriceLoading = true
      orders[orderIndex].assemblyEstimatedPrice = null
      orders[orderIndex].assemblyEstimatedPriceError = null
    }
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }
  if (action.type === ESTIMATE_ASSEMBLY_PRICE_SUCCESS) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.id)
    if (orderIndex >= 0) {
      orders[orderIndex].isEstimateAssemblyPriceLoading = false
      orders[orderIndex].assemblyEstimatedPrice = _.get(
        action,
        'payload.data.response'
      )
        ? { ...action.payload.data.response }
        : null
      orders[orderIndex].assemblyEstimatedPriceError = null
    }
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }
  if (action.type === ESTIMATE_ASSEMBLY_PRICE_ERROR) {
    let orders = state.orders
    const orderIndex = orders.findIndex((o) => o.id === action.payload.id)
    if (orderIndex >= 0) {
      orders[orderIndex].isEstimateAssemblyPriceLoading = false
      orders[orderIndex].assemblyEstimatedPrice = null
      orders[orderIndex].assemblyEstimatedPriceError = _.get(action, 'payload.data')
        ? action.payload.data
        : null
    }
    return Object.assign({}, state, {
      orders: [...orders],
    })
  }

  if (action.type === GET_CANAL_VENTE) {
    return {
      ...state,
      isGetCanalVenteLoading: true,
      errorGetSalesChannel: false,
    }
  }

  if (action.type === GET_CANAL_VENTE_SUCCESS) {
    const { response } = action.payload.data
    return {
      ...state,
      canalVente: response,
      isGetCanalVenteLoading: false,
      errorGetSalesChannel: false,
    }
  }

  if (action.type === GET_CANAL_VENTE_FAILURE) {
    return {
      ...state,
      isGetCanalVenteLoading: false,
      errorGetSalesChannel: true,
    }
  }

  if (action.type === GET_RULES) {
    return {
      ...state,
      isGetRulesListLoading: true,
    }
  }
  if (action.type === GET_RULES_SUCCESS) {
    const rules = action.payload.data
    return {
      ...state,
      rulesList: rules,
      isGetRulesListLoading: false,
    }
  }
  if (action.type === GET_RULES_FAILURE) {
    return {
      ...state,
      isGetRulesListLoading: false,
    }
  }
  return state
}
