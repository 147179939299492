import _ from 'lodash'
const checkArticleFamilies = (articleFamilies) => {
  if (!_.isNil(articleFamilies)) {
    for (let e of articleFamilies) {
      if (e.checked) {
        return false
      }
    }
    return true
  } else {
    return false
  }
}
export default checkArticleFamilies
