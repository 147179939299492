import { connect } from 'react-redux'

import ConfigurationCanal from '../../../pages/settings/SalesChannel'
import {
  fetchAssignedSalesChannel,
  onSearch,
  pageChanged,
  pageLimitChanged,
  removeErrorConfig,
  resetConfig,
  resetSuccessAction,
} from '../../../redux/configuration/actions'

import {
  getAssignedChannel,
  getAssignedChannelCount,
  getAssignedChannelIsloading,
  getConfigSuccess,
  getErrorMessageConfig,
  getIsErrorFetch,
  getIsSuccessRefresh,
} from '../../../redux/configuration/selectors'

const mapStateToProps = (state) => ({
  isLoadingChannelList: getAssignedChannelIsloading(state),
  success: getConfigSuccess(state),
  salesChannel: getAssignedChannel(state),
  salesChannelCount: getAssignedChannelCount(state),
  errorMessage: getErrorMessageConfig(state),
  isErrorFetch: getIsErrorFetch(state),
  isSuccessRefresh: getIsSuccessRefresh(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchAssignedSalesChannel()),
  pageLimitChanged: (limit) => {
    dispatch(pageLimitChanged(limit))
    dispatch(fetchAssignedSalesChannel())
  },

  pageChanged: (offset) => {
    dispatch(pageChanged(offset))
    dispatch(fetchAssignedSalesChannel())
  },
  searchData: (search) => {
    dispatch(onSearch(search))
    dispatch(fetchAssignedSalesChannel())
  },
  onCloseReset: () => dispatch(resetConfig()),
  removeError: () => dispatch(removeErrorConfig()),
  resetSuccessAction: () => dispatch(resetSuccessAction()),
})

export default connect(mapStateToProps, mapDisptachToProps)(ConfigurationCanal)
