export default () => {
  return {
    etiquetteList: [],
    etiquettesCount: 0,
    isLoadingEtiquette: false,

    livraisonList: [],
    countLivraison: 0,
    isLoadingLivraison: false,
    errorLivraison: null,

    freightsList: [],
    countFreights: 0,
    isLoadingFreights: false,
    errorFreights: null,
    isActionsFreights: false,
    isLoadingActionsFreights: false,
    historiquesFreights: [],
    isLoadingHistoriquesFreights: false,
  }
}
