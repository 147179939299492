import React from 'react'
import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import Cell from './Cell'
import { styled } from '@mui/material/styles'

export const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  margin: 'auto',
  width: 270,
  justifyContent: 'space-around',
}))

export default function FilterGrid({
  statusDriversFilter,
  prestationFilter,
  filterStateChanged,
  filtersValues,
}) {
  const filterArray = [prestationFilter, statusDriversFilter]

  return (
    <div style={{ flexGrow: 1 }}>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {filterArray.map(
          (item, index) =>
            item && (
              <StyledGrid item key={index}>
                <Cell
                  index={index + 1}
                  filterArray={filterArray}
                  filterStateChanged={filterStateChanged}
                  filtersValues={filtersValues}
                />
              </StyledGrid>
            )
        )}
      </Grid>
    </div>
  )
}
FilterGrid.propTypes = {
  statusDriversFilter: PropTypes.bool,
  prestationFilter: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  filtersValues: PropTypes.object,
}
