import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import Avatar from '@mui/material/Avatar'

import moment from 'moment'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send'
import $ from 'jquery'
import { EVENT_SOCKET } from '../../../../../../utils/discussion/values'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ImageFile from '../../../../../../assets/images/file.png'
import CloseIcon from '@mui/icons-material/Close'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import {
  validateUploadFile,
  getExtension,
} from '../../../../../../utils/validator/UploadFileDiscussion'
import { AVAILABLE_EXTENSION } from '../../../../../../utils/values'
import Button from '@mui/material/Button'
import { NATURE_DISCUSSION_OBJECT } from '../../../../../../utils/values'
import { TitleWithIcon } from '../card/style'

function NewlineText(props) {
  const text = props.text
  return text.split('\n').map((str, i) => (
    <p
      style={{
        display: 'block',
        boxSizing: 'border-box',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        whitespace: 'break-spaces',
        textOverflow: 'unset',
        textAlign: 'left',
        fontSize: '14px',
      }}
      key={i}
    >
      {str}
    </p>
  ))
}
const styles = {
  listItem: (isOwnMessage) => ({
    flexDirection: isOwnMessage ? 'row-reverse' : 'row',
    width: '100%',
  }),
  container: (isOwnMessage) => ({
    maxWidth: '100%',
    fontSize: 12,
    backgroundColor: isOwnMessage ? 'rgba(102,94,184,.1)' : 'rgba(237,222,204,.3)',
    overflowWrap: 'break-word',
    borderRadius: isOwnMessage ? '10px 0 10px 10px' : '0 10px 10px 10px',
    display: 'grid',
    margin: '2px 10px 20px',
    padding: '10px',
    paddingRight: '12px',
  }),
  conversationStatus: (success) => ({
    color: success ? '#26a69a' : '#9e9e9e',
  }),
  author: (meTest) => ({
    textAlign: meTest ? 'right' : 'left',
    paddingRight: '12px',
    paddingLeft: '12px',
    fontSize: '11px',
    color: 'grey',
  }),
}
export default function Chatroom({
  messages,
  isLoadingGetMessageList,
  subject,
  cleaningMessageList,
  connectedUser,
  pushMessage,
  conversations,
  conversationOpen,
  focus,
  notFocus,
  varFocus,
  loadingSendMessage,
  seenMessage,
  onUploadfileDiscussion,
  loadingUploadFile,
  errorUploadFile,
  filesUpload,
  removeFileDiscussion,
  downloadFile,
  setLoadingUploadFile,
  setErrorUploadFile,
  deleteFile,
  newConversation,
  createConversationEvent,
  setNewConversation,
  closeConversation,
  openConversation,
  loadingCloseConversation,
  loadingOpenConversation,
  nature,
}) {
  const theme = useTheme()
  const [messageValueInput, setMessageValueInoput] = useState('')
  const [DerMessages, setDerMessages] = useState({})
  const [disabledInput, setDisabledInput] = useState(true)
  const [isSelected, setIsSelected] = useState(false)

  const [sendButtonBool, setsendButtonBool] = useState(false)
  const [files, setFiles] = useState([])
  const [filesUploadState, setFilesUpload] = useState([])

  useEffect(() => {
    if (messages.length > 0) {
      if (messages[messages.length - 1].type === EVENT_SOCKET.CLOSE_EVENT) {
        setDisabledInput(false)
      } else {
        setDisabledInput(true)
      }
      setDerMessages(messages[messages.length - 1])
      setNewConversation(false)
    }
  }, [messages])

  const getMessageFromInput = (event) => {
    setMessageValueInoput(event.target.value)
  }
  //after a click of enter keyBoard or the send button we make the input empty
  const clearInput = () => {
    setMessageValueInoput('')
  }

  const sendMessage = () => {
    if (newConversation) {
      createConversationEvent(messageValueInput, filesUploadState)
    } else if (messageValueInput.trim() != '' || filesUploadState.length > 0) {
      pushMessage(messageValueInput, filesUploadState)
    }
  }

  useEffect(() => {
    if (!loadingSendMessage) {
      clearInput()
      anullerImage(-1)
      setsendButtonBool(false)
    }
  }, [loadingSendMessage])

  useEffect(() => {
    if (messageValueInput.trim() != '') {
      setsendButtonBool(true)
    } else if (messageValueInput.trim() === '' && filesUploadState.length > 0) {
      setsendButtonBool(false)
    }
  }, [messageValueInput])

  useEffect(() => {
    return () => {
      clearInput()
      anullerImage(-1)
      setsendButtonBool(false)
    }
  }, [])

  useEffect(() => {
    if (errorUploadFile) {
      setFilesUpload([])
      setFiles([])
      setIsSelected(false)
      if (messageValueInput.trim() === '') {
        setsendButtonBool(false)
      }
    }
  }, [errorUploadFile])
  useEffect(() => {
    if (filesUpload?.length > 0) {
      setFilesUpload(filesUpload)
      const files = []

      filesUpload.forEach((file) =>
        files.push({
          isImage: AVAILABLE_EXTENSION.IMAGE.includes(getExtension(file.filename)),
          url: file.location,
          filename: file.filename,
        })
      )
      setFiles(files)
    }
  }, [filesUpload])

  const onUpload = (event) => {
    setLoadingUploadFile()
    const error = validateUploadFile(Array.from(event.target.files))
    if (error.isError) {
      setErrorUploadFile(error)
    } else {
      setIsSelected(true)
      setsendButtonBool(true)
      onUploadfileDiscussion(event.target.files)
    }
    event.target.value = null
  }

  const anullerImage = (index) => {
    let filesUploadStateDelete = []
    if (index > -1) {
      filesUploadStateDelete = filesUploadState
      filesUploadStateDelete.splice(index, 1)
    }
    deleteFile(filesUploadStateDelete)

    if (filesUploadStateDelete.length == 0 || index == -1) {
      setFiles([])
      setFilesUpload([])
      setIsSelected(false)
      if (messageValueInput.trim() === '') {
        setsendButtonBool(false)
      }
    }
  }

  useEffect(() => {
    if (messages.length > 0) scrollSmoothToBottom()
  }, [messages])
  //make the scroll bar scroll automatically
  const scrollSmoothToBottom = () => {
    var div = document.getElementById('scroll-bottom')
    $('#' + 'scroll-bottom').animate(
      {
        scrollTop: div?.scrollHeight - div?.clientHeight,
      },
      10
    )
  }

  let conversation = conversations.filter((conv) => conv.id == conversationOpen.id)
  if (
    connectedUser.id.toString() != DerMessages?.sender?.legacyID &&
    varFocus &&
    conversation[0]?.notSeen > 0
  ) {
    const entry = {
      type: EVENT_SOCKET.SEEN_EVENT,
      entryId: DerMessages.id,
      conversationID: conversationOpen.id,
    }
    seenMessage(entry, connectedUser)
    conversation[0].notSeen = 0
    notFocus()
  }

  const IsImage = (filename) => {
    return filename?.match(/.(jpg|jpeg|png|gif)$/i) ? true : false
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          boxShadow: '0 1px 0px 0 rgb(0 0 0 / 10%)',
          padding: '5px',
        }}
      >
        <TitleWithIcon>
          <KeyboardArrowLeftOutlinedIcon
            sx={{
              cursor: 'pointer',
              alignSelf: 'start',
            }}
            onClick={cleaningMessageList}
          />{' '}
          <span>
            <div
              style={{
                display: 'flex',
                fontSize: '16px',
                alignSelf: 'center',
                fontWeight: '500',

                alignItems: 'center',
              }}
            >
              {subject}
            </div>
            {nature && (
              <div
                style={{
                  textAlign: 'left',
                  font: 'normal normal medium 12px/15px Helvetica Neue',
                  letterSpacing: '0px',
                  color: ' #1876D2',
                  opacity: 1,
                  backgroundColor: '#D2EAFE',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '120px',
                  marginTop: '5px',
                  padding: '3px 0 3px',
                }}
              >
                {NATURE_DISCUSSION_OBJECT[nature]}
              </div>
            )}
          </span>
        </TitleWithIcon>

        {conversationOpen?.isOpen === true ? (
          <>
            {loadingCloseConversation ? (
              <CircularProgress
                color="primary"
                sx={{
                  marginRight: '20px',
                  height: '30px !important',
                  width: '30px !important',
                }}
              />
            ) : (
              <Button
                variant="text"
                sx={{
                  '&.MuiButton-root': {
                    fontSize: '12px !important',
                    color: '#196dd4',
                    '&:hover': {
                      color: 'white',
                      backgroundColor: '#196dd4 !important',
                    },
                  },
                  '&.MuiButton-text': {
                    padding: '5px 8px 4px !important',
                    textTransform: 'none !important',
                    fontSize: '13px !important',
                    fontWeight: '400!important',
                  },
                }}
                onClick={closeConversation}
              >
                Clôturer la discussion
              </Button>
            )}
          </>
        ) : (
          conversationOpen?.isOpen === false && (
            <>
              {loadingOpenConversation ? (
                <CircularProgress
                  color="primary"
                  sx={{
                    marginRight: '20px',
                    height: '30px !important',
                    width: '30px !important',
                  }}
                />
              ) : (
                <Button
                  variant="text"
                  sx={{
                    '&.MuiButton-root': {
                      fontSize: '12px !important',

                      color: '#196dd4',
                      '&:hover': {
                        color: 'white',
                        backgroundColor: '#196dd4 !important',
                      },
                    },
                    '&.MuiButton-text': {
                      padding: '5px 8px 4px !important',
                      textTransform: 'none !important',
                      fontSize: '13px !important',
                      fontWeight: '400!important',
                    },
                  }}
                  onClick={openConversation}
                >
                  Rouvrir la discussion
                </Button>
              )}
            </>
          )
        )}
      </div>

      {isLoadingGetMessageList ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <div className="spinner-border" role="status">
            <CircularProgress style={{ color: theme.palette.primary.main }} />
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              height: '600px',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <div
              style={
                isSelected
                  ? {
                      scrollBehavior: 'auto',
                      overflow: 'scroll',
                      overflowY: 'auto',
                      height: '67%',
                      width: '100%',
                    }
                  : {
                      scrollBehavior: 'auto',
                      overflow: 'scroll',
                      overflowY: 'auto',
                      height: '83%',
                      width: '100%',
                    }
              }
              id="scroll-bottom"
            >
              {messages.map((message, index) => (
                <>
                  {message.type === EVENT_SOCKET.CLOSE_EVENT ||
                  message.type === EVENT_SOCKET.OPEN_EVENT ? (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <h1
                        style={styles.conversationStatus(
                          message.type === EVENT_SOCKET.OPEN_EVENT
                        )}
                      >
                        {message.content}
                      </h1>
                    </div>
                  ) : (
                    <ListItem
                      style={styles.listItem(
                        connectedUser.id.toString() === message.sender.legacyID
                      )}
                    >
                      <Avatar alt="Remy Sharp" src={message.sender.photoIdentity} />

                      <div>
                        <div
                          style={styles.author(
                            connectedUser.id.toString() === message.sender.legacyID
                          )}
                        >
                          {message.sender.firstName} {message.sender.name}
                        </div>
                        <div
                          style={styles.container(
                            connectedUser.id.toString() === message.sender.legacyID
                          )}
                        >
                          <div
                            style={{
                              display: 'flex',
                              boxSizing: 'border-box',
                              flexWrap: 'wrap',
                            }}
                          >
                            {message?.attachements &&
                              message.attachements.length > 0 && (
                                <>
                                  {message.attachements.map((attachement, index) => {
                                    return (
                                      <>
                                        {attachement?.filename && (
                                          <div
                                            style={{
                                              position: 'relative',
                                              width: '100px',
                                              height: '100px',
                                            }}
                                            key={index}
                                          >
                                            {IsImage(attachement.filename) ? (
                                              <img
                                                src={attachement.location}
                                                style={{
                                                  objectFit: 'contain',
                                                  width: '100px',
                                                  height: '100px',
                                                  padding: '2%',
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={ImageFile}
                                                style={{
                                                  objectFit: 'contain',
                                                  width: '100px',
                                                  height: '100px',
                                                  padding: '2%',
                                                }}
                                              />
                                            )}

                                            <div
                                              style={{
                                                position: 'absolute',
                                                top: '0',
                                                opacity: '0',
                                                height: '100%',
                                                width: '100%',
                                                cursor: 'pointer',
                                                textAlign: 'end',
                                                padding: '4px 4px',
                                                '&:hover': {
                                                  opacity: '1',
                                                  backgroundColor:
                                                    'hsla(0,0%,97.6%,.4117647058823529)',
                                                  transition: '0.5s',
                                                },
                                              }}
                                            >
                                              <CloudDownloadOutlinedIcon
                                                sx={{
                                                  width: '40px !important',
                                                  height: '28px !important',
                                                  cursor: 'pointer',
                                                  border: 'none',
                                                  opacity: '0.7',
                                                  verticalAlign: 'middle',
                                                  marginLeft: '4px',
                                                  backgroundColor: 'white',
                                                  borderRadius: '5px',
                                                  '&:hover': {
                                                    backgroundColor: '#8e8888',
                                                    transition: '0.5s',
                                                  },
                                                }}
                                                onClick={() => {
                                                  downloadFile(attachement.location)
                                                }}
                                                download
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )
                                  })}
                                </>
                              )}
                          </div>
                          <NewlineText text={message.content.trim()} />
                          <div
                            style={{
                              textAlign: 'right',
                              color: 'grey',
                              fontSize: '12px',
                            }}
                          >
                            {moment(message.createdAt).format(
                              'Do MMMM YYYY, h:mm:ss a'
                            )}
                          </div>
                        </div>
                      </div>
                    </ListItem>
                  )}
                </>
              ))}
            </div>
            {disabledInput && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: '10px',
                }}
              >
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                  }}
                >
                  <input
                    type="file"
                    style={
                      files.length > 0 || loadingUploadFile
                        ? {
                            opacity: '0',
                            width: '42px',
                            height: '41px',
                            position: 'absolute',
                            marginTop: '111px',
                            zIndex: '2',
                            cursor: 'pointer',
                          }
                        : {
                            opacity: '0',
                            width: '42px',
                            height: '41px',
                            position: 'absolute',
                            marginTop: '11px',
                            zIndex: '2',
                            cursor: 'pointer',
                          }
                    }
                    onChange={onUpload}
                    multiple
                  />
                  <IconButton
                    sx={
                      files.length > 0 || loadingUploadFile
                        ? {
                            backgroundColor: '#3f51b5 !important',
                            marginTop: '10px !important',
                            marginRight: '10px !important',
                            top: '100px !important',
                            height: '40px !important',
                            width: '40px !important',
                          }
                        : {
                            backgroundColor: '#3f51b5 !important',
                            marginTop: '10px !important',
                            marginRight: '10px !important',
                            height: '40px !important',
                            width: '40px !important',
                          }
                    }
                  >
                    <AttachFileIcon
                      sx={{
                        color: 'white',
                        transform: 'rotate(45deg)',
                      }}
                    />
                  </IconButton>
                </div>
                <div
                  style={
                    sendButtonBool && !loadingUploadFile
                      ? {
                          width: 'calc(100% - 100px)',
                          boxShadow: '0 1px 7px 0 rgb(0 0 0 / 10%)',
                          borderRadius: '5px',
                        }
                      : {
                          width: 'calc(100% - 60px)',
                          boxShadow: '0 1px 7px 0 rgb(0 0 0 / 10%)',
                          borderRadius: '5px',
                        }
                  }
                >
                  {isSelected && (
                    <div
                      style={{
                        display: 'flex',
                        overflow: 'auto hidden',
                        height: '100px',
                      }}
                    >
                      {loadingUploadFile ? (
                        <div
                          style={{
                            padding: '30px',
                          }}
                        >
                          <CircularProgress color="primary" />
                        </div>
                      ) : (
                        <>
                          {!errorUploadFile && (
                            <>
                              {files.length > 0 && (
                                <>
                                  {files.map((file, index) => {
                                    return (
                                      <div
                                        style={{
                                          position: 'relative',
                                          width: '112px',
                                          height: '100px',
                                          marginRight: '10px',
                                        }}
                                        key={index}
                                      >
                                        <IconButton
                                          sx={{
                                            backgroundColor: '#eeeeee !important',
                                            position: 'absolute',
                                            width: '10px',
                                            height: '10px',
                                            right: '0',
                                            top: '2px',
                                          }}
                                          onClick={() => {
                                            anullerImage(index)
                                            removeFileDiscussion(file.filename)
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                        {file.isImage ? (
                                          <img
                                            src={file.url}
                                            style={{
                                              objectFit: 'contain',
                                              width: '100px',
                                              height: '100px',
                                              padding: '2%',
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={ImageFile}
                                            style={{
                                              objectFit: 'contain',
                                              width: '100px',
                                              height: '100px',
                                              padding: '2%',
                                            }}
                                          />
                                        )}
                                      </div>
                                    )
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  <textarea
                    value={messageValueInput}
                    maxLength="500"
                    name="message"
                    placeholder="Enter message"
                    style={{
                      width: 'calc(100% - 10px)',
                      border: 'none',
                      resize: 'none',
                      height: '67px',
                      margin: '10px 10px 2px',
                      outline: 'none',
                    }}
                    onFocus={focus}
                    onBlur={notFocus}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter' && (ev.ctrlKey || ev.metaKey)) {
                        sendMessage()
                        ev.preventDefault()
                      }
                    }}
                    disabled={loadingSendMessage}
                    onChange={(e) => getMessageFromInput(e)}
                  />
                </div>
                {sendButtonBool && !loadingUploadFile && (
                  <div>
                    <IconButton
                      sx={
                        isSelected
                          ? {
                              backgroundColor: '#3f51b5!important',
                              marginTop: '16px !important',
                              marginLeft: '10px !important',
                              top: '100px !important',
                              height: '42px !important',
                              width: '42px !important',
                            }
                          : {
                              backgroundColor: '#3f51b5!important',
                              marginTop: '20px !important',
                              marginLeft: '10px !important',
                              width: '42px',
                              height: '42px',
                            }
                      }
                      onClick={sendMessage}
                      disabled={loadingSendMessage}
                    >
                      {loadingSendMessage ? (
                        <CircularProgress
                          sx={{
                            color: 'white !important',
                            width: '18px!important',
                            height: '18px!important',
                          }}
                        />
                      ) : (
                        <SendIcon sx={{ color: 'white' }} />
                      )}
                    </IconButton>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

Chatroom.propTypes = {
  messages: PropTypes.array,
  isLoadingGetMessageList: PropTypes.bool,
  subject: PropTypes.string,
  cleaningMessageList: PropTypes.func,
  connectedUser: PropTypes.object,
  pushMessage: PropTypes.func,
  conversations: PropTypes.array,
  conversationOpen: PropTypes.object,
  focus: PropTypes.func,
  notFocus: PropTypes.func,
  varFocus: PropTypes.bool,
  loadingSendMessage: PropTypes.bool,
  seenMessage: PropTypes.func,
  onUploadfileDiscussion: PropTypes.func,
  loadingUploadFile: PropTypes.bool,
  errorUploadFile: PropTypes.any,
  filesUpload: PropTypes.array,
  removeFileDiscussion: PropTypes.func,
  downloadFile: PropTypes.func,
  setLoadingUploadFile: PropTypes.func,
  setErrorUploadFile: PropTypes.func,
  deleteFile: PropTypes.func,
  newConversation: PropTypes.bool,
  createConversationEvent: PropTypes.func,
  setNewConversation: PropTypes.func,
  closeConversation: PropTypes.func,
  openConversation: PropTypes.func,
  loadingCloseConversation: PropTypes.bool,
  loadingOpenConversation: PropTypes.bool,
  nature: PropTypes.string,
}
