import _ from 'lodash'
import uuid from 'react-uuid'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'

import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
  parseFloatWithComma,
  parseFloatWithDot,
} from '../../../../utils/floatFormatting'
import AddressAutoComplete from '../../../../containers/main/AddressAutoComplete'
import DatePickerLocalisation from '../../../../components/DatePickerLocalisation'
import FormHelperTextError from '../../../../components/FormHelperTextError'
import { getAssemblyTimes, getAssemblyMinDate } from '../../../../utils'
import { validateAssembly } from '../../../../utils/validator/assemblyValidator'
import useForm from '../../../../utils/useForm'
import { ORDER_TYPE } from '../../../../constants/appContants'
import { assemblyFormData } from './formData'
import { assemblyValidators } from './validators'
import OptionsList from '../../../../components/ShipmentOptionsList'
import { DropzoneArea } from 'react-mui-dropzone'
import './style.scss'
import { ASSEMBLY_DEFAULT_SOURCE, ASSEMBLY_SOURCE } from '../../../../utils/values'
import { Place } from '../../../../models/place'
import { LatLng } from '../../../../models/latlng'
// import { resizeFile } from '../../../../utils/imageReduire'
import Error from '../../../../components/Modal/errorAct'
import CircularProgress from '@mui/material/CircularProgress'
import AutoComplete from '../../../../components/AutoComplete'

export default function AssemblySection({
  order,
  shipmentSalesChanel,
  assemblySalesChannelList,
  assemblyDefaultAddress,
  setOrderAssemblyPrice,
  updateMarkers,
  setAssemblyErrors,
  removeOrderAssembly,
  updateOrderAssembly,
  isSubmited,
  estimatedPrice,
  estimatedPriceError,
  estimatePrice,
  removeAssembly,
  handleSalesChannel,
  salesChannel,
  //Map
  directionRouteList,
  directionRoute,
  isGetDirectionRouteLoading,
  addOrder,

  getGoogleMapDirection,
  findDirection,
  getOneWeekShipments,
  updateDirections,
  getSalesChannels,
  getCanalVente,
  canalVente,
  isGetCanalVenteLoading,
  errorGetSalesChannel,
}) {
  const assemblyData = !_.isNil(_.get(order, 'assembly.id'))
    ? _.get(order, 'assembly')
    : { id: uuid() }
  const [options, setOptions] = useState([])
  const [assemblySource, setAssemblySource] = useState(ASSEMBLY_DEFAULT_SOURCE.code)
  const submitForm = () => {
    console.log('Form submited', assemblyFormData(assemblyData))
  }
  const [originDefaultAddress, setOriginDefaultAddress] = useState(null)

  const [
    assembly,
    errors,
    isSubmitted,
    handleAssemblyChange,
    handleAssemblyElChange,
    handleAssemblySubmit,
    isAssemblyValid,
    isAssemblyInValid,
  ] = useForm(
    assemblyFormData(assemblyData),
    submitForm,
    assemblyValidators,
    validateAssembly
  )

  useEffect(() => {
    getSalesChannels()
  }, [])

  useEffect(() => {
    if (canalVente != null && _.isNil(shipmentSalesChanel))
      handleAssemblyElChange('selectedSalesChannel', canalVente)
  }, [canalVente])

  const submitRef = useRef()

  const handleNegativeChange = (e) => {
    const value = e.target.value.trim() == '' ? '0' : e.target.value
    if (value >= 0) {
      handleAssemblyChange(e)
    }
  }
  useEffect(() => {
    const opt = options.map((e) => {
      return { idOption: e.id, quantity: e.quantity }
    })
    handleAssemblyElChange('options', opt)
  }, [options])
  useEffect(() => {
    updateOrderAssembly(order.id, assemblyData)
  }, [])
  useEffect(() => {
    const changeAssemblyTimeOut = setTimeout(() => {
      updateOrderAssembly(order.id, assembly)
    }, 100)
    return () => clearTimeout(changeAssemblyTimeOut)
  }, [assembly])

  useEffect(() => {
    updateMarkers(assembly.id, assembly.selectedAddress)
  }, [assembly.selectedAddress])

  useEffect(() => {
    setClientPrice()
  }, [assembly.estimatedPrice])

  useEffect(() => {
    setOrderAssemblyPrice(order.id, assembly.clientPrice)
  }, [assembly.clientPrice])

  useEffect(() => {
    estimatePrice(
      order.id,
      assembly.selectedSalesChannel,
      assembly.purchaseAmount,
      assembly.date,
      assembly.time
    )
  }, [assembly.purchaseAmount])

  useEffect(() => {
    if (_.get(assembly, 'selectedSalesChannel.configs.priceBasedOnDate')) {
      estimatePrice(
        order.id,
        assembly.selectedSalesChannel,
        assembly.purchaseAmount,
        assembly.date,
        assembly.time
      )
    }
  }, [assembly.date, assembly.time])

  const handleAssemblyTimes = () => {
    const times = getAssemblyTimes(
      _.get(order, 'shipment.date', null),
      assembly.date,
      _.get(order, 'shipment.time', null)
    )
    handleAssemblyElChange('times', times)
  }
  useEffect(() => {
    handleAssemblyTimes()
  }, [assembly.date])

  useEffect(() => {
    if (_.get(order, 'shipment.date')) {
      handleAssemblyTimes()
    }
  }, [order.shipment])

  useEffect(() => {
    handleAssemblyElChange('time', null)
  }, [assembly.times])

  useEffect(() => {
    resetForm()
    setAssemblySource(ASSEMBLY_DEFAULT_SOURCE.code)
    if (order.type !== ORDER_TYPE.DELIVERY_ASSEMBLY) {
      handleSalesChannel(_.get(assembly, 'selectedSalesChannel'))
      getOneWeekShipments([_.get(assembly, 'selectedSalesChannel.code')])
    }
  }, [assembly.selectedSalesChannel])

  useEffect(() => {
    if (salesChannel) {
      if (salesChannel.configs?.includedServices !== 'course') {
        handleAssemblyElChange('selectedSalesChannel', salesChannel)
      } else {
        removeAssembly(order.id)
      }
    }
  }, [salesChannel])

  useEffect(() => {
    if (!_.isNil(shipmentSalesChanel)) {
      handleAssemblyElChange('selectedSalesChannel', shipmentSalesChanel)
    }
  }, [shipmentSalesChanel])

  useEffect(() => {
    if (isSubmited) {
      submitRef.current.click()
    }
  }, [isSubmited])
  const [blurredClientPrice, setblurredClientPrice] = useState(false)
  useEffect(() => {
    if (!blurredClientPrice) {
      console.log(
        'is it a number',
        Number(parseFloatWithDot(assembly.clientPrice)) ===
          parseFloatWithDot(assembly.clientPrice)
      )
      handleAssemblyElChange(
        'clientPrice',
        Number(parseFloatWithDot(assembly.clientPrice)) ===
          parseFloatWithDot(assembly.clientPrice)
          ? parseFloatWithComma(assembly.clientPrice)
          : null
      )
    }
  }, [blurredClientPrice])
  const handleBlur = () => {
    setblurredClientPrice(false)
  }
  const handleFocus = () => {
    setblurredClientPrice(true)
  }
  useEffect(() => {
    const changesErrorsTimeOut = setTimeout(() => {
      setAssemblyErrors(order.id, errors)
    }, 100)
    return () => clearTimeout(changesErrorsTimeOut)
  }, [errors])

  const setClientPrice = () => {
    let totalPrice = !_.isNil(_.get(assembly, 'estimatedPrice.MontantTotalTTC'))
      ? assembly.estimatedPrice.MontantTotalTTC
      : 0
    if (options.length) {
      options.forEach((op) => {
        if (op.totalPrice && op.totalPrice !== null) {
          totalPrice = totalPrice + op.totalPrice
        }
      })
    }
    setblurredClientPrice(true)
    handleAssemblyElChange('clientPrice', parseFloatWithComma(totalPrice))
    setblurredClientPrice(false)
  }
  const resetPrice = () => {
    handleAssemblyElChange('estimatedPrice', null)
    handleAssemblyElChange('clientPrice', 0)
  }
  const setEstimatedPriceValue = (val) => {
    handleAssemblyElChange('estimatedPrice', val)
  }

  useEffect(() => {
    if (!_.isNil(_.get(estimatedPrice, 'MontantTotalTTC'))) {
      setEstimatedPriceValue(estimatedPrice)
    }
  }, [estimatedPrice])
  useEffect(() => {
    if (!_.isNil(estimatedPriceError)) {
      resetPrice()
      if (_.get(estimatedPriceError, 'data.message.length')) {
        setEstimatedPriceValue({ error: estimatedPriceError.data.message })
      } else if (!_.isEqual(estimatedPriceError, new Error('paramater-error'))) {
        // setEstimatedPriceValue({ error: 'Erreur estimation du prix' })
      }
    }
  }, [estimatedPriceError])

  const handleQuntityChange = (e, id) => {
    const { value } = e.target
    if (options && options.length) {
      const optionsList = [...options]
      const op = optionsList.findIndex((o) => o.id === id)
      if (op >= 0) {
        optionsList[op].quantity = !isNaN(value) ? value : 0
        optionsList[op].totalPrice = optionsList[op].quantity * optionsList[op].price
        setOptions(optionsList)
      }
      setClientPrice()
    }
  }

  const setAssemblyOptions = () => {
    if (!_.isNil(_.get(assembly, 'selectedSalesChannel.optionsDetails'))) {
      const assemblyOptions = assembly.selectedSalesChannel.optionsDetails.filter(
        (op) => op.orderService === ORDER_TYPE.ASSEMBLY
      )

      if (assemblyOptions && assemblyOptions.length) {
        let optionsForm = assemblyOptions.map((el) => {
          return {
            id: el.id,
            label:
              el.label && el.label !== null
                ? `${el.label.charAt(0).toUpperCase()}${el.label.slice(1)}`
                : null,
            quantity: 0,
            price: el.price,
            totalPrice: 0,
          }
        })
        setOptions(optionsForm)
      }
    }
  }
  const resetForm = () => {
    if (!_.isNil(_.get(assembly, 'selectedSalesChannel.configs'))) {
      setAssemblyOptions()
      if (
        assembly.selectedSalesChannel.configs.includedServices ===
        ORDER_TYPE.DELIVERY
      ) {
        addOrder(ORDER_TYPE.DELIVERY)

        removeOrderAssembly(order.id)
      }
    }
    if (assembly.selectedSalesChannel && assembly.selectedSalesChannel !== null) {
      if (_.get(assembly, 'selectedSalesChannel.adresses.length')) {
        setOriginDefaultAddress(null)
        setOriginDefaultAddress(
          new Place().getFromCanalAddress(assembly.selectedSalesChannel.adresses[0])
        )
      } else {
        setOriginDefaultAddress(null)
      }
    }
  }
  const getMinDate = (o) => {
    return getAssemblyMinDate(_.get(o, 'date'), _.get(o, 'time'), assembly.times)
  }
  const handleFileChange = async (files) => {
    handleAssemblyElChange('fileUploaded', files?.length > 0)
    if (files && files?.length > 0) {
      // for (var i = 0; i < files.length; i++) {
      //   var file = files[i]
      //   if (file.type.includes('image')) {
      //     files[i] = await resizeFile(file)
      //   }
      // }
      handleAssemblyElChange('files', files)
    }
  }

  const handleAssemblySourceChange = (value) => {
    setAssemblySource(value.target.value)
    handleAssemblyElChange('assembly_source', value.target.value)
  }

  //Map Fakher
  useEffect(() => {
    const dataChangeTimeOut = setTimeout(() => {
      if (assembly.direction && assembly.direction !== null) {
        updateDirections(assembly.id, assembly.direction)
      }
    }, 500)
    return () => {
      clearTimeout(dataChangeTimeOut)
    }
  }, [assembly.direction])

  useEffect(() => {
    if (
      _.get(order, 'type') === ORDER_TYPE.ASSEMBLY &&
      _.isNil(shipmentSalesChanel) &&
      !isGetDirectionRouteLoading
    ) {
      if (
        _.get(assembly, 'id') == _.get(directionRoute, 'id') &&
        _.get(assembly, 'selectedOrigin.latlng.lat') ===
          _.get(directionRoute, 'origin.lat') &&
        _.get(assembly, 'selectedOrigin.latlng.lng') ===
          _.get(directionRoute, 'origin.lng')
      ) {
        handleAssemblyElChange('direction', directionRoute)
      }
    }
  }, [directionRoute, isGetDirectionRouteLoading])

  useEffect(() => {
    calculateDirection()
  }, [assembly.selectedOrigin, assembly.selectedDestination])

  const calculateDirection = () => {
    if (
      _.get(order, 'type') === ORDER_TYPE.ASSEMBLY &&
      _.isNil(shipmentSalesChanel) &&
      !_.isNil(_.get(assembly, 'selectedOrigin.latlng')) &&
      !_.isNil(_.get(assembly, 'selectedDestination.latlng'))
    ) {
      if (!directionRouteList.length) {
        getGoogleMapDirection(
          assembly.id,
          new LatLng(
            assembly.selectedOrigin.latlng.lat,
            assembly.selectedOrigin.latlng.lng
          ),
          new LatLng(
            assembly.selectedDestination.latlng.lat,
            assembly.selectedDestination.latlng.lng
          )
        )
      } else {
        findDirection(
          assembly.id,
          new LatLng(
            assembly.selectedOrigin.latlng.lat,
            assembly.selectedOrigin.latlng.lng
          ),
          new LatLng(
            assembly.selectedDestination.latlng.lat,
            assembly.selectedDestination.latlng.lng
          )
        )
      }
    }
  }
  return (
    <>
      <div className="place-asembly">
        <div className="place-assembly-title">
          Informations de montage
          {/* {_.get(order, 'type') === ORDER_TYPE.DELIVERY_ASSEMBLY &&
          _.get(order, 'assembly.active') ? (
            <DeleteForeverIcon
              className="remove-icon"
              color="secondary"
              fontSize="small"
              onClick={() => removeOrderAssembly(order.id)}
            />
          ) : null} */}
        </div>
        <form
          className={[
            'form-validation place-assembly-form',
            isSubmitted ? 'was-submitted' : '',
            isAssemblyInValid ? 'is-invalid' : '',
            isAssemblyValid ? 'is-valid' : '',
          ].join(' ')}
          noValidate
          autoComplete="off"
          onSubmit={($event) => handleAssemblySubmit($event)}
        >
          {assemblySalesChannelList ? (
            <Grid container spacing={3}>
              {_.get(order, 'type') === ORDER_TYPE.ASSEMBLY &&
              _.isNil(shipmentSalesChanel) ? (
                <Grid item xs={12}>
                  <FormControl>
                    <AutoComplete
                      id="selectedSalesChannel-assembly"
                      options={assemblySalesChannelList}
                      getOptionLabel={(option) => (option.name ? option.name : '')}
                      value={assembly.selectedSalesChannel || null}
                      label="Canal de vente"
                      required={true}
                      variant="outlined"
                      error={
                        isSubmitted &&
                        !_.isNil(_.get(errors, 'selectedSalesChannel.required'))
                      }
                      helperText={
                        isSubmitted
                          ? _.get(errors, 'selectedSalesChannel.required')
                            ? _.get(errors, 'selectedSalesChannel.required')
                            : _.get(errors, 'selectedSalesChannel.pattern', '')
                          : ''
                      }
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.code}>
                            {option.name}
                          </li>
                        )
                      }}
                      onChange={($event, newValue) => {
                        if (newValue?.code) getCanalVente(newValue?.code)
                      }}
                    />
                    <Grid
                      container
                      spacing={3}
                      style={{ visibility: 'hidden', position: 'absolute' }}
                    >
                      <Grid item xs={12} className="nextAccord">
                        <AddressAutoComplete
                          id="adresse-de-depart"
                          label="Adresse de départ"
                          selectedDefaultAddress={originDefaultAddress}
                          salesChannelAddresses={
                            _.get(assembly, 'selectedSalesChannel.adresses.length')
                              ? assembly.selectedSalesChannel.adresses
                              : []
                          }
                          handleQueryChange={($event) =>
                            handleAssemblyElChange('originQuery', $event)
                          }
                          handleOnAddressSelect={($event) =>
                            handleAssemblyElChange('selectedOrigin', $event)
                          }
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              ) : null}
              {isGetCanalVenteLoading ? (
                <div style={{ padding: '8% 40%' }}>
                  <CircularProgress color="primary" />
                </div>
              ) : (
                (canalVente || !_.isNil(shipmentSalesChanel)) && (
                  <>
                    <Grid item xs={12}>
                      <AddressAutoComplete
                        id="selectedAddress-assembly"
                        label="Adresse de montage"
                        selectedDefaultAddress={assemblyDefaultAddress}
                        handleQueryChange={($event) => {
                          handleAssemblyElChange('queryaddress', $event)
                          handleAssemblyElChange('destinationQuery', $event)
                        }}
                        isRequired
                        handleOnAddressSelect={($event) => {
                          handleAssemblyElChange('selectedAddress', $event)
                          handleAssemblyElChange('selectedDestination', $event)
                        }}
                        hasError={
                          isSubmitted &&
                          (!_.isNil(_.get(errors, 'selectedAddress.required')) ||
                            !_.isNil(_.get(errors, 'selectedAddress.pattern')))
                        }
                        errorText={
                          isSubmitted &&
                          (_.get(errors, 'selectedAddress.required') ||
                            _.get(errors, 'selectedAddress.pattern'))
                        }
                      />
                    </Grid>
                    <div tabIndex="-1" id="date-assembly"></div>
                    <Grid item xs={6}>
                      <FormControl>
                        <DatePickerLocalisation
                          handleDateChange={($event) =>
                            handleAssemblyElChange('date', $event)
                          }
                          id="date-de-montage"
                          label="Date de montage"
                          minDate={
                            _.get(order, 'type') === ORDER_TYPE.DELIVERY_ASSEMBLY &&
                            !_.isNil(_.get(order, 'shipment'))
                              ? getMinDate(order.shipment)
                              : new Date()
                          }
                          hasError={isSubmitted && !_.isNil(_.get(errors, 'date'))}
                          errorMessage={
                            _.get(errors, 'date.required')
                              ? _.get(errors, 'date.required')
                              : _.get(errors, 'date.pattern', null)
                          }
                          disablePast={true}
                          isOrderPage={true}
                          date={assembly.date}
                          disabledDays={[]}
                          openedDays={[]}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        className="dropdown-loader"
                        error={!_.isNil(_.get(errors, 'time'))}
                      >
                        <TextField
                          id="time-assembly"
                          select
                          label="Heure de montage"
                          name="time"
                          value={assembly.time || ''}
                          required
                          onChange={handleAssemblyChange}
                          variant="outlined"
                          error={isSubmitted && !_.isNil(_.get(errors, 'time'))}
                          helperText={
                            isSubmitted && !_.isNil(_.get(errors, 'time'))
                              ? !_.isNil(_.get(errors, 'time.required'))
                                ? _.get(errors, 'time.required')
                                : _.get(errors, 'time.pattern', null)
                              : ''
                          }
                        >
                          {_.get(assembly, 'times.length') ? (
                            assembly.times.filter(
                              (h) =>
                                !(
                                  new Date().toLocaleDateString() ===
                                    new Date(assembly.date).toLocaleDateString() &&
                                  new Date().getHours() + 2 >= parseInt(h.name)
                                )
                            ).length !== 0 ? (
                              assembly.times.map((h, index) => {
                                if (
                                  !(
                                    new Date().toLocaleDateString() ===
                                      new Date(assembly.date).toLocaleDateString() &&
                                    new Date().getHours() + 2 >= parseInt(h.name)
                                  )
                                ) {
                                  return (
                                    <MenuItem
                                      value={h}
                                      key={index}
                                      id={`time-assembly-${index}`}
                                    >
                                      {h.name}
                                    </MenuItem>
                                  )
                                }
                              })
                            ) : (
                              <MenuItem value={''}>
                                <em>Aucune heure</em>
                              </MenuItem>
                            )
                          ) : (
                            <MenuItem value={''}>
                              <em>Aucune heure</em>
                            </MenuItem>
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className="dropdown-loader">
                        <TextField
                          id="category-assembly"
                          select
                          label="Categorie"
                          name="category"
                          value={assembly.category || ''}
                          onChange={handleAssemblyChange}
                          required
                          variant="outlined"
                          error={isSubmitted && !_.isNil(_.get(errors, 'category'))}
                          helperText={
                            isSubmitted && !_.isNil(_.get(errors, 'category'))
                              ? _.get(errors, 'category.required')
                              : ''
                          }
                        >
                          {['Montage de meubles', 'Salle de bain', 'Cuisine'].map(
                            (h, index) => (
                              <MenuItem value={h} key={index} id={h}>
                                {h}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl error={!_.isNil(_.get(errors, 'purchaseAmount'))}>
                        <TextField
                          id="purchaseAmount-assembly"
                          label="Montant d'achat"
                          variant="outlined"
                          name="purchaseAmount"
                          required
                          value={assembly.purchaseAmount}
                          onChange={handleNegativeChange}
                          error={
                            isSubmitted && !_.isNil(_.get(errors, 'purchaseAmount'))
                          }
                          helperText={
                            isSubmitted && !_.isNil(_.get(errors, 'purchaseAmount'))
                              ? !_.isNil(_.get(errors, 'purchaseAmount.required'))
                                ? _.get(errors, 'purchaseAmount.required')
                                : _.get(errors, 'purchaseAmount.pattern', null)
                              : ''
                          }
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 1,
                              type: 'text',
                              pattern: '[0-9]*',
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="observation-assembly"
                        value={assembly.observation}
                        name="observation"
                        onChange={handleAssemblyChange}
                        label="Informations pour le monteur "
                        variant="outlined"
                        multiline
                        required={true}
                        rows={4}
                        error={isSubmitted && !_.isNil(_.get(errors, 'observation'))}
                        helperText={
                          isSubmitted && !_.isNil(_.get(errors, 'observation'))
                            ? !_.isNil(_.get(errors, 'observation.required'))
                              ? _.get(errors, 'observation.required')
                              : _.get(errors, 'observation.pattern', null)
                            : ''
                        }
                      />
                    </Grid>

                    {options && options.length ? (
                      <Grid container spacing={3} className="options-list">
                        <OptionsList
                          options={options}
                          handleQuantityChange={handleQuntityChange}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <FormControl error={!_.isNil(_.get(errors, 'clientPrice'))}>
                        <TextField
                          id="clientPrice-assembly"
                          error={!_.isNil(_.get(errors, 'clientPrice'))}
                          min={0}
                          name="clientPrice"
                          variant="outlined"
                          value={assembly.clientPrice}
                          label="Prix client"
                          onChange={handleNegativeChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                          disabled={_.isNil(_.get(assembly, 'estimatedPrice'))}
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 1,
                              type: 'text',
                              pattern: '[0-9]*',
                            },
                          }}
                        />
                        <FormHelperText className="text-info">
                          {_.get(assembly, 'estimatedPrice.error', '')}
                        </FormHelperText>

                        <FormHelperTextError
                          content={
                            _.get(errors, 'clientPrice.required')
                              ? _.get(errors, 'clientPrice.required')
                              : _.get(errors, 'clientPrice.pattern')
                              ? _.get(errors, 'clientPrice.pattern')
                              : _.get(errors, 'clientPrice.min', '')
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className="dropdown-loader">
                        <TextField
                          id="outlined-select-currency-native"
                          select
                          label="Source de montage"
                          name="assemblySource"
                          value={assemblySource}
                          onChange={handleAssemblySourceChange}
                          variant="outlined"
                        >
                          {ASSEMBLY_SOURCE.map(({ code, label }) => (
                            <MenuItem value={code} key={code} id={label}>
                              {label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </>
                )
              )}
            </Grid>
          ) : null}
          <input ref={submitRef} type="submit" className="hidden"></input>
        </form>
        {!isGetCanalVenteLoading &&
          (canalVente || !_.isNil(shipmentSalesChanel)) && (
            <>
              <div tabIndex="-1" id="fileUploaded-assembly"></div>
              <div className="assembly-upload-files">
                <h3 className="upload-title">
                  Joindre la liste exhaustive des meubles à monter
                  <span className="required-text">*</span>
                </h3>
                <p className="simple-text">
                  Les codes PAX, les quantités, références, doivent être bien
                  visibles
                </p>

                <div className="drop-zone-container">
                  <DropzoneArea
                    onChange={handleFileChange}
                    dropzoneText=""
                    Icon={UploadIcons}
                    acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    showFileNamesInPreview={true}
                    showFileNames={true}
                    maxFileSize={150994944}
                    id={'upload-file'}
                  />
                  <FormHelperTextError
                    content={
                      isSubmitted > 0 &&
                      _.get(errors, 'fileUploaded.required') &&
                      isSubmitted
                        ? _.get(errors, 'fileUploaded.required')
                        : ''
                    }
                  />
                </div>
              </div>
            </>
          )}
      </div>
      {errorGetSalesChannel && (
        <Error
          statusText="une erreur s'est produite merci d'actualiser la page"
          open={errorGetSalesChannel}
        />
      )}
    </>
  )
}
export const UploadIcons = () => (
  <div className="upload-icons-container">
    <PhotoLibraryIcon />
    <PictureAsPdfIcon />
  </div>
)

AssemblySection.propTypes = {
  order: PropTypes.object.isRequired,
  shipmentSalesChanel: PropTypes.object,
  assemblyDefaultAddress: PropTypes.object,
  isSubmited: PropTypes.number.isRequired,
  estimatedPrice: PropTypes.object,
  estimatedPriceError: PropTypes.object,
  assemblySalesChannelList: PropTypes.array.isRequired,
  setOrderAssemblyPrice: PropTypes.func.isRequired,
  updateMarkers: PropTypes.func.isRequired,
  setAssemblyErrors: PropTypes.func.isRequired,
  removeOrderAssembly: PropTypes.func.isRequired,
  updateOrderAssembly: PropTypes.func.isRequired,
  estimatePrice: PropTypes.func.isRequired,
  removeAssembly: PropTypes.func,
  handleSalesChannel: PropTypes.func,
  salesChannel: PropTypes.object,
  directionRouteList: PropTypes.array.isRequired,
  getGoogleMapDirection: PropTypes.func.isRequired,
  findDirection: PropTypes.func.isRequired,
  isGetDirectionRouteLoading: PropTypes.bool.isRequired,
  directionRoute: PropTypes.object,
  getOneWeekShipments: PropTypes.func,
  updateDirections: PropTypes.func.isRequired,
  addOrder: PropTypes.func,
  getSalesChannels: PropTypes.func,
  getCanalVente: PropTypes.func,
  canalVente: PropTypes.object,
  isGetCanalVenteLoading: PropTypes.bool,
  errorGetSalesChannel: PropTypes.bool,
}
