import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import ShipmentCell from './Cell'
import { styled } from '@mui/material/styles'

export const StyledGrid = styled(Grid)(() => ({
  flexGrow: 1,
  justifyContent: 'left',
  margin: '10px 20px 0 20px !important',
}))

export default function ShipmentGrid({ shipments }) {
  return (
    <StyledGrid
      container
      className="Grid-container"
      spacing={2}
      sx={{ width: 'calc(100% - 20px)', height: '100%' }}
    >
      <Grid item xs={12} sx={{ paddingLeft: '8px' }}>
        <Grid container spacing={2} sx={{ justifyContent: 'flex-start' }}>
          {shipments.map((shipment, index) => (
            <Grid key={index} item sx={{ padding: '8px !important' }}>
              <ShipmentCell index={index} shipment={shipment} modale={false} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </StyledGrid>
  )
}
ShipmentGrid.propTypes = {
  shipments: PropTypes.array.isRequired,
}
