import _ from 'lodash'
import { validatorContants } from './constants'
import { ROLE } from '../../constants/appContants'
import * as EmailValidator from 'email-validator'
import { getPhoneInputCountries } from '../values'
import parsePhoneNumber from 'libphonenumber-js'
export const validateClient = (values, elements) => {
  let errors = {}

  // validate firstName if exist in elements to validate
  if (!_.isNil(_.get(elements, 'firstname'))) {
    // validate required  if required exist in elements.firstName
    if (
      elements.firstname.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'firstname.length')
    ) {
      errors['firstname'] = {
        ...errors['firstname'],
        [validatorContants.REQUIRED]: 'Prénom est obligatoire!',
      }
    }
  }

  // validate lastName if exist in elements to validate
  if (!_.isNil(_.get(elements, 'lastname.length'))) {
    // validate required  if required exist in elements.lastName
    if (
      elements.lastname.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'lastname.length')
    ) {
      errors['lastname'] = {
        ...errors['lastname'],
        [validatorContants.REQUIRED]: 'Nom est obligatoire!',
      }
    }
  }

  if (_.get(values, 'type') === ROLE.PROFESSIONEL) {
    // validate companyName if exist in elements to validate
    if (!_.isNil(_.get(elements, 'name'))) {
      // validate required  if required exist in elements.companyName
      if (
        !_.get(values, 'name.length') &&
        elements.name.find((el) => el === validatorContants.REQUIRED)
      ) {
        errors['name'] = {
          ...errors['name'],
          [validatorContants.REQUIRED]: 'Nom société est obligatoire!',
        }
      }
    }
    // validate companySelectedAddress if exist in elements to validate
    if (!_.isNil(_.get(elements, 'address'))) {
      // validate required  if required exist in elements.companySelectedAddress
      if (
        _.isNil(_.get(values, 'address')) &&
        elements.address.find((el) => el === validatorContants.REQUIRED)
      ) {
        errors['address'] = {
          ...errors['address'],
          [validatorContants.REQUIRED]: 'Adresse société est obligatoire!',
        }
      }

      // validate pattern  if pattern exist in elements.companySelectedAddress
      // if (
      //   elements.companySelectedAddress.find(
      //     (el) => el === validatorContants.PATTERN
      //   ) &&
      //   (_.isNil(_.get(values, 'companySelectedAddress.formattedAddress')) ||
      //     _.isNil(_.get(values, 'companySelectedAddress.latlng.lat')) ||
      //     _.isNil(_.get(values, 'companySelectedAddress.latlng.lng')))
      // ) {
      //   errors['companySelectedAddress'] = {
      //     ...errors['companySelectedAddress'],
      //     [validatorContants.PATTERN]: 'Adresse société est invalide!',
      //   }
      // }
    }
    if (!_.isNil(_.get(elements, 'selectedAddress'))) {
      // validate required  if required exist in elements.selectedAddress
      if (
        elements.selectedAddress.find((el) => el === validatorContants.REQUIRED) &&
        _.isNil(_.get(values, 'selectedAddress'))
      ) {
        errors['selectedAddress'] = {
          ...errors['selectedAddress'],
          [validatorContants.REQUIRED]: 'Adresse de la société est obligatoire!',
        }
      }

      // validate pattern  if pattern exist in elements.selectedAddress
      if (
        elements.selectedAddress.find((el) => el === validatorContants.PATTERN) &&
        _.isNil(_.get(values, 'selectedAddress.formattedAddress')) /* ||
          _.isNil(_.get(values, 'selectedAddress.latlng.lat')) ||
          _.isNil(_.get(values, 'selectedAddress.latlng.lng'))*/
      ) {
        errors['selectedAddress'] = {
          ...errors['selectedAddress'],
          [validatorContants.PATTERN]: 'Adresse de la société est invalide!',
        }
      }
    }

    // validate companyQueryAddress if exist in elements to validate
    if (!_.isNil(_.get(elements, 'addressQuerry'))) {
      // validate required  if required exist in elements.companyQueryAddress
      if (
        !_.get(values, 'addressQuerry.length') &&
        elements.addressQuerry.find((el) => el === validatorContants.REQUIRED)
      ) {
        errors['addressQuerry'] = {
          ...errors['addressQuerry'],
          [validatorContants.REQUIRED]: 'Adresse de la société obligatoire!',
        }
      }
    }
  }

  // validate phone if exist in elements to validate
  if (!_.isNil(_.get(elements, 'phone'))) {
    // validate required if required exists in elements.phone
    if (
      elements.phone.includes(validatorContants.REQUIRED) &&
      !_.isNil(_.get(values, 'phone')) &&
      _.get(values, 'phone', '').trim() === ''
    ) {
      errors['phone'] = {
        ...errors['phone'],
        [validatorContants.REQUIRED]: 'numéro de téléphone est obligatoire!',
      }
    } else if (
      !_.isNil(_.get(values, 'phone')) &&
      _.get(values, 'phone', '').trim() !== '' &&
      _.get(values, 'phoneCheck') !== undefined
    ) {
      const {
        phone: phoneNumber,
        countryCode,
        dialcode,
      } = _.get(values, 'phoneCheck')

      let parsedPhoneNumber = null

      try {
        const Country =
          getPhoneInputCountries().indexOf(countryCode) !== -1
            ? countryCode.toUpperCase()
            : 'FR'
        let newPhone = phoneNumber.replace(dialcode, '')
        parsedPhoneNumber = parsePhoneNumber(newPhone, Country)
      } catch (err) {
        console.error('Error parsing phone number:', err)
      }

      if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
        errors['phone'] = {
          ...errors['phone'],
          [validatorContants.PATTERN]: 'numéro de téléphone est invalide!',
        }
      }
    } else {
      let countryCode = getPhoneInputCountries()
      let phoneNumber = '33'
      try {
        phoneNumber = _.get(values, 'phone', '').replace(/^\+/, '')
      } catch (err) {
        errors['phone'] = {
          ...errors['phone'],
          [validatorContants.PATTERN]: 'numéro de téléphone est invalide!',
        }
      }
      let parsedPhoneNumber = null

      try {
        parsedPhoneNumber = parsePhoneNumber(phoneNumber)
      } catch (err) {
        console.error('Error parsing phone number:', err)
      }

      if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
        try {
          parsedPhoneNumber = parsePhoneNumber(`+${phoneNumber}`, countryCode)
        } catch (err) {
          console.error('Error parsing phone number:', err)
        }

        if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
          errors['phone'] = {
            ...errors['phone'],
            [validatorContants.PATTERN]: 'numéro de téléphone est invalide!',
          }
        }
      }
    }
  }
  if (!_.get(values, 'NoEmail')) {
    // validate email if exist in elements to validate
    if (!_.isNil(_.get(elements, 'mail'))) {
      // validate required  if required exist in elements.email
      if (
        elements.mail.find((el) => el === validatorContants.REQUIRED) &&
        !_.get(values, 'mail')
      ) {
        errors['mail'] = {
          ...errors['mail'],
          [validatorContants.REQUIRED]: 'email est obligatoire!',
        }
      }

      // validate pattern  if pattern exist in elements.email
      if (
        elements.mail.find((el) => el === validatorContants.PATTERN) &&
        !_.isNil(_.get(values, 'mail')) &&
        !EmailValidator.validate(_.get(values, 'mail'))
      ) {
        errors['mail'] = {
          ...errors['mail'],
          [validatorContants.PATTERN]: 'email est invalide!',
        }
      }
    }
  }

  // validate nbItems if exist in elements to validate
  if (!_.isNil(_.get(elements, 'noteInterne'))) {
    // validate required  if required exist in elements.noteInterne
    if (
      elements.noteInterne.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'noteInterne')
    ) {
      errors['noteInterne'] = {
        ...errors['noteInterne'],
        [validatorContants.REQUIRED]: 'La note interne est obligatoire!',
      }
    }
  }

  return errors
}
