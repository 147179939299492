import { connect } from 'react-redux'
import Questions from '../../../pages/main/Orders/DetailsDelivery/components/details/Questions'

import {
  getOrderQuestions,
  getIsLoadingOrderQuestions,
} from '../../../redux/order/selectors'
import { getOrderQuestionsByLdv } from '../../../redux/order/actions'

const mapStateToProps = (state) => ({
  orderQuestions: getOrderQuestions(state),
  isLoadingOrderQuestions: getIsLoadingOrderQuestions(state),
})
const mapDisptachToProps = (dispatch) => ({
  getOrderQuestionsByLdv: (ldv) => dispatch(getOrderQuestionsByLdv(ldv)),
})
export default connect(mapStateToProps, mapDisptachToProps)(Questions)
