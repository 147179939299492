import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@mui/material/styles'

import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'

import GetAppIcon from '@mui/icons-material/GetApp'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions'

import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import EmptyTable from '../../../../components/EmptyTable/EmptyTable'
import LongTextComponent from '../../../main/Orders/Table/LongTextComponent'
import Error from '../../../../components/Modal/HttpErrorPopUp'
import Telechargement from './telechargement'

export const columns = [
  { id: 'telecharger', label: '', minWidth: 80, name: '' },
  { id: 'ldv', label: 'LDV', minWidth: 80, name: 'LDV' },
  { id: 'lastname', label: 'Nom', minWidth: 80, name: 'Nom' },
  { id: 'client', label: 'Client', minWidth: 80, name: 'Client' },
  { id: 'canalVente', label: 'Canal Vente', minWidth: 80, name: 'Canal Vente' },
]

const TableLivraisonDocument = ({
  isLoadingLivraison,
  livraisonList,
  countLivraison,
  generationBLCourse,
  errorLivraison,
  pageLimitChanged,
  pageChanged,
  downloadTransfert,
  downloadPreparation,
  downloadBonLivraison,
}) => {
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [openError, setOpenError] = useState(false)

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, countLivraison - page * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    pageChanged(newPage * rowsPerPage)
  }
  const onErrorClose = () => {
    setOpenError(false)
  }
  useEffect(() => {
    if (errorLivraison) setOpenError(true)
  }, [errorLivraison])

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    pageLimitChanged(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getNom = (contactArrivee) =>
    contactArrivee != null &&
    contactArrivee?.firstname != null &&
    contactArrivee?.firstname != undefined &&
    contactArrivee?.lastname != null &&
    contactArrivee?.lastname != undefined
      ? `${contactArrivee.firstname} ${contactArrivee.lastname}`
      : 'N/A'
  const getClient = (canalVente) =>
    canalVente?.macroCanal != null && canalVente?.macroCanal != undefined
      ? canalVente.macroCanal.name
      : 'N/A'
  const getName = (canalVente) =>
    canalVente?.name != null && canalVente?.name != undefined
      ? canalVente.name
      : 'N/A'
  return (
    <div
      style={{
        transition: 'all 0.3s',
        display: 'inline-block',
        // padding: '0px 14px 0px 26px',
        position: 'relative',
        float: 'left',
        width: '100%',
      }}
    >
      <div
        style={{
          color: '#fff',
          display: 'inline-flex',
          alignItems: 'center',
          background: '#1976d2',
          height: '50px',
          width: '100%',
          padding: '0 10px',
          justifyContent: 'space-between',
        }}
      >
        <span
          style={{
            paddingLeft: '12px',
            fontSize: '18px',
          }}
        >
          {'Livraison'}
        </span>
        <Telechargement
          downloadTransfert={downloadTransfert}
          downloadPreparation={downloadPreparation}
          downloadBonLivraison={downloadBonLivraison}
        />
      </div>
      <div
        style={{
          padding: '7px',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {isLoadingLivraison ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100px',
              margin: 'auto',
            }}
          >
            <div className="spinner-border" role="status">
              <CircularProgress style={{ color: theme.palette.primary.main }} />
            </div>
          </div>
        ) : (
          <TableContainer component={Paper} className="order-table-root ">
            <Table
              sx={{
                minWidth: 500,
                border: '1px solid rgba(224, 224, 224, 1)',
              }}
              aria-label="custom pagination table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align="center"
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {livraisonList.length > 0 ? (
                <TableBody>
                  {livraisonList.map(
                    (
                      {
                        lettreDeVoiture: { code },
                        contactArrivee,
                        commande: { canalVente },
                      },
                      index
                    ) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Tooltip
                            arrow
                            title={
                              <span className="tootltip-content">
                                {'Télécharger BL'}
                              </span>
                            }
                          >
                            <GetAppIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => generationBLCourse(code)}
                            />
                          </Tooltip>
                        </TableCell>
                        {[
                          {
                            name: 'LDV',
                            content: code || 'N/A',
                          },
                          {
                            name: 'Nom',
                            content: getNom(contactArrivee),
                          },
                          {
                            name: 'Client',
                            content: getClient(canalVente),
                          },
                          {
                            name: 'Canal Vente',
                            content: getName(canalVente),
                          },
                        ].map((item, index) => (
                          <LongTextComponent
                            display={true}
                            text={item.content}
                            key={index}
                          />
                        ))}
                      </TableRow>
                    )
                  )}

                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <EmptyTable colspan={columns.length} />
              )}
              {countLivraison > 0 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        25,
                        50,
                        100,
                        200,
                        { label: 'All', value: -1 },
                      ]}
                      count={countLivraison}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      sx={{
                        color: 'rgba(0, 0, 0, 0.54)',
                      }}
                      labelRowsPerPage="lignes par page"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        )}
      </div>
      {errorLivraison && (
        <Error statusText={errorLivraison} open={openError} setOpen={onErrorClose} />
      )}
    </div>
  )
}

TableLivraisonDocument.propTypes = {
  generationBLCourse: PropTypes.func,
  isLoadingLivraison: PropTypes.bool,
  livraisonList: PropTypes.array,
  countLivraison: PropTypes.any,
  errorLivraison: PropTypes.string,
  pageLimitChanged: PropTypes.func,
  pageChanged: PropTypes.func,
  downloadTransfert: PropTypes.func,
  downloadPreparation: PropTypes.func,
  downloadBonLivraison: PropTypes.func,
}

export default TableLivraisonDocument
