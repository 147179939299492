import Tooltip from '@mui/material/Tooltip/Tooltip'
import NotAvailable from './NotAvailable'
import TableCell from '@mui/material/TableCell/TableCell'
import React from 'react'
import './index.scss'
import PropTypes from 'prop-types'
export default function LongTextComponent({ text, display, id, ...props }) {
  return (
    display && (
      <TableCell id={id}>
        {text ? (
          <Tooltip arrow title={<span className="tootltip-content">{text}</span>}>
            <p className="long-text-overflow" {...props}>
              {' '}
              {text}
            </p>
          </Tooltip>
        ) : (
          <NotAvailable />
        )}
      </TableCell>
    )
  )
}
LongTextComponent.propTypes = {
  text: PropTypes.any,
  display: PropTypes.bool,
  id: PropTypes.string,
}
