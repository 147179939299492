import React from 'react'
import ClientsList from '../../../../../containers/main/ClientsList'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import './index.scss'
import '../index.scss'

export default function ClientSelection({
  handleNewClient,
  setSelectedClient,
  newClient,
  client,
}) {
  return (
    <>
      {' '}
      <div className="client-selection-container">
        <div>
          <Paper square>
            <Tabs
              value={newClient ? 1 : 0}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, value) => handleNewClient(value === 1)}
              aria-label="tabs"
            >
              <Tab label="Ancien Client !" />
              <Tab label="Nouveau Client !" />
            </Tabs>
          </Paper>
        </div>
        <div>
          {!newClient && (
            <ClientsList
              searchByPhone={true}
              enabled={false}
              setSelectedClient={setSelectedClient}
              client={client}
            />
          )}
        </div>
      </div>
    </>
  )
}
ClientSelection.propTypes = {
  handleNewClient: PropTypes.func,
  setSelectedClient: PropTypes.func,
  newClient: PropTypes.bool,
  selectedClient: PropTypes.any,
  client: PropTypes.object,
}
