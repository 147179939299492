import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import AutoComplete from '../../../AutoComplete'
import SearchIcon from '@mui/icons-material/Search'

import Dialog from '../../../../components/ModalDialog'
import DatePickerLocalisation from '../../../DatePickerLocalisation'
import moment from 'moment'
import { useFormik } from 'formik'

const validate = ({ ldt, startDeliveryDate, endDeliveryDate }) => {
  const errors = {}

  if (!ldt) {
    errors.ldt = 'Tournée est obligatoire'
  }

  if (!startDeliveryDate) {
    errors.startDeliveryDate = 'Date de début est obligatoire'
  }

  if (!endDeliveryDate) {
    errors.endDeliveryDate = 'Date de fin est obligatoire'
  }

  if (new Date(startDeliveryDate).getTime() > new Date(endDeliveryDate).getTime()) {
    errors.startDeliveryDate = 'Date de début doit être inférieure.'
    errors.endDeliveryDate = ' '
  }
  return errors
}

export default function SearchDownloadMultipleDocument({
  open,
  iconTitle,
  placeholderInput,
  handleClose,

  onSearch,
  isLoading,
  options,
  countOptions,
  onSearchMore,

  downloadDocument,
}) {
  const [select, setSelect] = useState(false)
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(25)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0 && !select) {
        const filter = {
          actif: true,
          limit: 25,
          offset: 0,
          query: search.trim(),
        }
        onSearch(filter)
      } else if (search == '') {
        const filter = {
          actif: true,
          limit: 25,
          offset: 0,
        }
        onSearch(filter)
      }
      setOffset(25)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const onChangeSearch = (value) => {
    setSelect(false)
    if (value) {
      let val = value
      setSearch(val)
    } else {
      formik.setFieldValue('ldt', '')
      setSearch('')
      setSelect(false)
    }
  }

  const onSelect = (value) => {
    if (value) {
      formik.setFieldValue('ldt', value)
      setSelect(true)
    }
  }

  const loadMore = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      countOptions >= offset
    ) {
      setOffset(offset + 25)
      const filter = {
        actif: true,
        limit: 25,
        offset: offset,
        query: search.length > 0 ? search.trim() : undefined,
      }
      onSearchMore(filter)
    }
  }

  const formik = useFormik({
    initialValues: {
      ldt: '',
      startDeliveryDate: new Date(),
      endDeliveryDate: new Date(),
    },
    validate,
    onSubmit: (values) => {
      const payload = {
        ldt: values.ldt,
        startDeliveryDate: moment(values.startDeliveryDate).format('YYYY-MM-DD'),
        endDeliveryDate: moment(values.endDeliveryDate).format('YYYY-MM-DD'),
      }
      downloadDocument(payload)
      handleClose()
    },
  })

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Dialog
        maxWidthDialog={'md'}
        open={open}
        title={'Générer kilométrage tournée'}
        iconTitle={iconTitle}
        content={
          <div style={{ width: '400px', padding: '0px 16px 16px 0px' }}>
            <div
              style={{
                display: 'flex',
                padding: '5px',
                marginBottom: '15px',
                height: '75px',
                marginTop: '5px',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  paddingTop: '18px',
                  paddingRight: '26px',
                }}
              >
                Tournée
              </div>
              <div style={{ width: '100%' }}>
                <AutoComplete
                  value={formik.values.ldt}
                  name={'search-tournee'}
                  onChange={(event, value) => {
                    onSelect(value)
                  }}
                  onInputChange={(event, value) => onChangeSearch(value)}
                  id="Search"
                  options={options}
                  getOptionLabel={(option) => option}
                  variant="outlined"
                  placeholder={placeholderInput}
                  ListboxProps={{
                    onScroll: loadMore,
                  }}
                  loading={isLoading}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id} id={option.id}>
                      <div className="AutoCompteFilterOption">{option}</div>
                    </li>
                  )}
                  startIcon={
                    <SearchIcon
                      sx={{
                        paddingRight: '4px',
                        width: '26px !important',
                        height: '26px !important',
                        color: '#C2C2C2 !important',
                      }}
                    />
                  }
                  error={formik.errors.ldt && formik.touched.ldt}
                  helperText={
                    formik.errors.ldt && formik.touched.ldt
                      ? formik.errors.ldt
                      : null
                  }
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '5px',
                marginBottom: '15px',
                height: '75px',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  paddingTop: '18px',
                  paddingRight: '55px',
                }}
              >
                DU
              </div>
              <DatePickerLocalisation
                disabledDays={[]}
                openedDays={[]}
                disablePast={false}
                handleDateChange={(date) =>
                  formik.setFieldValue('startDeliveryDate', date)
                }
                withDefaultValue={true}
                defaultValue={formik.values.startDeliveryDate}
                date={formik.values.startDeliveryDate}
                id="dateStart"
                errorMessage={
                  formik.errors.startDeliveryDate && formik.touched.startDeliveryDate
                    ? formik.errors.startDeliveryDate
                    : null
                }
                hasError={
                  formik.errors.startDeliveryDate && formik.touched.startDeliveryDate
                }
              />
            </div>
            <div
              style={{
                display: 'flex',
                padding: '5px',
                height: '75px',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                  display: 'flex',
                  paddingTop: '18px',
                  paddingRight: '55px',
                }}
              >
                AU
              </div>
              <DatePickerLocalisation
                disabledDays={[]}
                openedDays={[]}
                disablePast={false}
                handleDateChange={(date) =>
                  formik.setFieldValue('endDeliveryDate', date)
                }
                withDefaultValue={true}
                date={formik.values.endDeliveryDate}
                defaultValue={formik.values.endDeliveryDate}
                id="dateEnd"
                errorMessage={
                  formik.errors.endDeliveryDate && formik.touched.endDeliveryDate
                    ? formik.errors.endDeliveryDate
                    : null
                }
                hasError={
                  formik.errors.endDeliveryDate && formik.touched.endDeliveryDate
                }
              />
            </div>
          </div>
        }
        handleClose={handleClose}
        isClosedIcon={true}
        handleClickAction={formik.handleSubmit}
      />
    </form>
  )
}

SearchDownloadMultipleDocument.propTypes = {
  open: PropTypes.bool,
  iconTitle: PropTypes.any,
  placeholderInput: PropTypes.string,
  handleClose: PropTypes.func,

  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  countOptions: PropTypes.number,
  onSearchMore: PropTypes.func,

  downloadDocument: PropTypes.func,
}
