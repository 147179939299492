import {
  GET_ENTRY_CONVERSATION_LIST,
  SUBSCRIBE_TO_CONVERSATIONS_TOPIC,
  RECEIVE_DATA_FROM_CONNVERSATION_TOPIC,
  RECEIVE_DATA_FROM_CONNVERSATION_DELIVERY_TOPIC,
  CLEAN_CONVERSATION_LIST,
  SOCKET_REGISTRED,
  SOCKET_NOT_REGISTRED,
  GET_NOTIFICATION_CONVERSATION_LIST,
  RECEIVE_REMOVE_NOTIFICATION,
  RECEIVE_ADD_NOTIFICATION,
  NOT_FOCUS_NOTIFICATION,
  FOCUS_NOTIFICATION,
  GET_ENTRY_CONVERSATION_LIST_BY_SEARCH,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY,
  GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE,
  LOADING_OPEN_CLOSE_CONVERSATION,
  GET_ENTRY_CONVERSATION_LIST_MORE,
  SET_ID_NEW_CONVERSATION,
  REMOVE_ID_NEW_CONVERSATION,
  SET_ID_CONVERSATION,
  REMOVE_ID_CONVERSATION,
  GET_SUBJECT_CONVERSATION,
} from './constants'

export const getEntryConversationAction = (data) => ({
  type: GET_ENTRY_CONVERSATION_LIST,
  payload: data,
})

export const getMoreEntryConversationAction = (data) => ({
  type: GET_ENTRY_CONVERSATION_LIST_MORE,
  payload: data,
})

export const getEntryConversationSearchAction = (data) => ({
  type: GET_ENTRY_CONVERSATION_LIST_BY_SEARCH,
  payload: data,
})

export const getNotificationConversationAction = (id) => ({
  type: GET_NOTIFICATION_CONVERSATION_LIST,
  payload: { id },
})

export const subscribeToConversationTopicAction = () => ({
  type: SUBSCRIBE_TO_CONVERSATIONS_TOPIC,
  payload: {},
})

export const receiveDataFromConversationTopicAction = (data) => ({
  type: RECEIVE_DATA_FROM_CONNVERSATION_TOPIC,
  payload: { data: data },
})

export const cleaningConversationList = () => ({
  type: CLEAN_CONVERSATION_LIST,
  payload: {},
})

export const socketNotRegistred = () => ({
  type: SOCKET_REGISTRED,
  payload: {},
})

export const initsocketNotRegistred = () => ({
  type: SOCKET_NOT_REGISTRED,
  payload: {},
})

export const receiveAddNotification = (notification) => ({
  type: RECEIVE_ADD_NOTIFICATION,
  payload: { notification },
})

export const receiveRemoveNotification = (notification) => ({
  type: RECEIVE_REMOVE_NOTIFICATION,
  payload: { notification },
})

export const focus = () => ({
  type: FOCUS_NOTIFICATION,
  payload: {},
})

export const notFocus = () => ({
  type: NOT_FOCUS_NOTIFICATION,
  payload: {},
})

export const getEntryConversationDeliveryAction = (data) => ({
  type: GET_ENTRY_CONVERSATION_LIST_DELIVERY,
  payload: data,
})

export const getMoreEntryConversationDeliveryAction = (data) => ({
  type: GET_ENTRY_CONVERSATION_LIST_DELIVERY_MORE,
  payload: data,
})

export const receiveDataFromConversationBYLDVTopicAction = (data, ldv) => ({
  type: RECEIVE_DATA_FROM_CONNVERSATION_DELIVERY_TOPIC,
  payload: { data: data, ldv: ldv },
})

export const setLoadingOpenCloseConversation = (loadingOpenCloseConversation) => ({
  type: LOADING_OPEN_CLOSE_CONVERSATION,
  payload: { loadingOpenCloseConversation },
})

export const setIdConversationUUID = (idConversation) => ({
  type: SET_ID_NEW_CONVERSATION,
  payload: { idConversation },
})

export const removeIdConversationUUID = () => ({
  type: REMOVE_ID_NEW_CONVERSATION,
})

export const setIdConversation = (idConversation) => ({
  type: SET_ID_CONVERSATION,
  payload: { idConversation },
})

export const removeIdConversation = () => ({
  type: REMOVE_ID_CONVERSATION,
})

export const getSubjectConversation = () => ({
  type: GET_SUBJECT_CONVERSATION,
})
