import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '../../../../../../components/switch/SwitchComponent'
export default function AgentsOrders({
  getShipmentByAgents,
  getShipmentByFilter,
  onMyChange,
  isLoading,
  checked,
  displayGrid,
}) {
  const [init, setInit] = useState(false)
  const onChecked = () => {
    onMyChange(!checked)
  }

  useEffect(() => {
    if (checked) {
      if (init) {
        getShipmentByAgents()
      }
    } else {
      if (init) {
        getShipmentByFilter()
      }
    }
    setInit(true)
  }, [checked])
  return (
    <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              value={checked}
              defaultChecked={checked}
              onChange={onChecked}
              disabled={isLoading || !displayGrid}
              id={'Mes-Commandes'}
            />
          }
          label={<span style={{ color: '#6D7B8E' }}>{'Mes Commandes'}</span>}
        />
      </FormGroup>
    </div>
  )
}
AgentsOrders.propTypes = {
  getShipmentByAgents: PropTypes.func,
  getShipmentByFilter: PropTypes.func,
  onMyChange: PropTypes.func,
  isLoading: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  mycommandeFiltersValues: PropTypes.any,
  checked: PropTypes.bool,
  displayGrid: PropTypes.bool,
}
