import { connect } from 'react-redux'
import HistoriquePhotosPackage from '../../../../pages/main/Orders/DetailsDelivery/components/dialog/detailsColis/historiquePointage/dialogPhotos'

import {
  getPhotosHistorique,
  getLoadingPhotosHistorique,
} from '../../../../redux/orderPackage/selectors'
import { getPhotosHistoriquePackage } from '../../../../redux/orderPackage/actions'

const mapStateToProps = (state) => ({
  photosHistoriquePackage: getPhotosHistorique(state),
  isLoadingPhotosHistoriquePackage: getLoadingPhotosHistorique(state),
})
const mapDisptachToProps = (dispatch) => ({
  getPhotosHistoriquePackage: (packageTraceability) =>
    dispatch(getPhotosHistoriquePackage(packageTraceability)),
})

export default connect(mapStateToProps, mapDisptachToProps)(HistoriquePhotosPackage)
