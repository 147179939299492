import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import {
  StyledContainerParticipant,
  StyledBlocTitleParticipant,
  StyledTitleParticipant,
  StyleblocTableParticipant,
} from '../style'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import EmptyTable from '../../../../../components/EmptyTable/EmptyTable'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import moment from 'moment'

const columns = [
  { id: 'fullName', label: 'Participant', align: 'left' },
  {
    id: 'createdAt',
    label: "Date d'inscription",
    align: 'left',
  },
  {
    id: 'isApte',
    label: 'Apte',
    align: 'left',
  },
]

const Participants = ({
  sessionsParticipants,
  isLoadingSessionsParticipants,
  isLoadingSessionAction,
  fetchSessionsParticipants,
  updateSessionParticipant,
  resetSuccessAction,
  success,
  resetSessionConfig,
}) => {
  const params = useParams()
  const [paramsInit, setParams] = useState(null)
  const [idParticipantUpdate, setIdParticipantUpdate] = useState(null)
  useEffect(() => {
    fetchSessionsParticipants(params.id)
    setParams(params)
    window.addEventListener('beforeunload', resetSessionConfig)
    return () => {
      resetSessionConfig()
      window.removeEventListener('beforeunload', resetSessionConfig)
    }
  }, [])

  useEffect(() => {
    if (paramsInit && paramsInit.id != params.id) {
      resetSessionConfig()
      setParams(params)
      fetchSessionsParticipants(params.id)
    }
    window.addEventListener('beforeunload', resetSessionConfig)

    return () => {
      resetSessionConfig()
      window.removeEventListener('beforeunload', resetSessionConfig)
    }
  }, [params])

  useEffect(() => {
    if (success) {
      resetSuccessAction()
      setIdParticipantUpdate(null)
    }
  }, [success])

  return (
    <StyledContainerParticipant>
      <StyledBlocTitleParticipant>
        <StyledTitleParticipant component="span">
          Liste des participants
        </StyledTitleParticipant>
      </StyledBlocTitleParticipant>
      <StyleblocTableParticipant>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sx={{
                      borderTop: '1px solid #EBEDF0',
                      borderLeft: '1px solid #EBEDF0',
                      color: '#3D444D',
                      fontWeight: '600',
                      '&:first-of-type': {
                        borderTopLeftRadius: '4px',
                      },
                      '&:last-of-type': {
                        borderTopRightRadius: '4px',
                        borderRight: '1px solid #EBEDF0',
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoadingSessionsParticipants ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={columns.length + 1}
                    sx={{ padding: '8px', border: 'none' }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress sx={{ color: '#1976D2' }} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : sessionsParticipants?.length > 0 ? (
              <TableBody>
                {sessionsParticipants.map((row, rowIndex) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                      id={rowIndex}
                    >
                      {columns.map((col, index) => (
                        <TableCell
                          key={`${rowIndex}-${index}`}
                          id={`${rowIndex}-${index}`}
                          align="left"
                          sx={{
                            color: '#6D7B8E',
                            borderLeft: '1px solid #EBEDF0',
                            '&:first-of-type': {
                              borderBottomLeftRadius: '4px',
                            },
                            '&:last-of-type': {
                              borderBottomRightRadius: '4px',
                              borderRight: '1px solid #EBEDF0',
                              color: '#6D7B8E',
                            },
                          }}
                        >
                          {col.id === 'isApte' ? (
                            isLoadingSessionAction &&
                            idParticipantUpdate == row.participantSessionId ? (
                              <CircularProgress
                                sx={{
                                  color: '#1976D2',
                                  width: '25px !important',
                                  height: '25px !important',
                                  padding: '1px',
                                  marginLeft: '5px',
                                  marginBottom: '1px',
                                }}
                              />
                            ) : (
                              <Checkbox
                                checked={row[col.id]}
                                checkedIcon={<CheckBoxOutlinedIcon />}
                                sx={{
                                  color: '#6D7B8E',
                                  width: '30px ',
                                  height: '30px',
                                  padding: 0,
                                  '&.Mui-checked': {
                                    '.MuiSvgIcon-root': {
                                      color: '#6D7B8E',
                                    },
                                  },
                                }}
                                onChange={() => {
                                  const data = {
                                    sessionId: Number(params.id),
                                    participantSessionDto: {
                                      participantId: row.participant.id,
                                      isApte: !row[col.id],
                                    },
                                  }
                                  setIdParticipantUpdate(row.participantSessionId)
                                  updateSessionParticipant(data)
                                }}
                              />
                            )
                          ) : col.label?.includes('Date') ? (
                            moment(row.createdAt).format('DD/MM/YYYY')
                          ) : col.id === 'fullName' ? (
                            `${row?.participant.firstName} ${row?.participant.lastName}`
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            ) : (
              <EmptyTable colspan={columns.length + 1} />
            )}
          </Table>
        </TableContainer>
      </StyleblocTableParticipant>
    </StyledContainerParticipant>
  )
}
Participants.propTypes = {
  sessionsParticipants: PropTypes.array,
  isLoadingSessionsParticipants: PropTypes.bool,
  isLoadingSessionAction: PropTypes.bool,
  fetchSessionsParticipants: PropTypes.func,
  updateSessionParticipant: PropTypes.func,
  resetSuccessAction: PropTypes.func,
  success: PropTypes.bool,
  resetSessionConfig: PropTypes.func,
}
export default Participants
