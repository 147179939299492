import { validatorContants } from '../../../../../utils/validator/constants'

export const clientValidators = {
  firstname: [validatorContants.REQUIRED],
  lastname: [validatorContants.REQUIRED],
  phone: [validatorContants.REQUIRED, validatorContants.PATTERN],
  //phone: [validatorContants.REQUIRED],
  mail: [validatorContants.REQUIRED, validatorContants.PATTERN],
  address: [validatorContants.REQUIRED],
  name: [validatorContants.REQUIRED],
  addressQuerry: [validatorContants.REQUIRED],
  selectedAddress: [validatorContants.REQUIRED, validatorContants.PATTERN],
}
