import { connect } from 'react-redux'

import CancelShipment from '../../../components/ShipmentAction/CancelShipment'
import { getOrderReasons, onChangeStatusAnnuler } from '../../../redux/order/actions'

import {
  getReasonsOrder,
  getIsLoadingReasonsOrder,
  getIsLoadingActionOrder,
  getErrorActionCourse,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  reasons: getReasonsOrder(state),
  isLoadingReasonsCancel: getIsLoadingReasonsOrder(state),
  isLoadingActionOrder: getIsLoadingActionOrder(state),
  errorActionCourse: getErrorActionCourse(state),
})

const mapDisptachToProps = (dispatch) => ({
  getShipmentCancelReasons: (payload) => dispatch(getOrderReasons(payload)),
  cancelShipment: (payload) => {
    dispatch(onChangeStatusAnnuler(payload))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(CancelShipment)
