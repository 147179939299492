import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import TextComponent from './texComponent'
import { StyledCell } from './style'

import InputMask from 'react-input-mask'
import moment from 'moment'

const ColumnTimeRangeComponentEditable = ({
  content,
  key,
  id,
  editable,
  onChange,
  errorsCourseEditable,
  contentEditable,
  nameAttribut,
}) => {
  const [startTime, setStartTime] = useState(
    contentEditable.heureStart ? contentEditable.heureStart : null
  )
  const [endTime, setEndTime] = useState(
    contentEditable.heureEnd ? contentEditable.heureEnd : null
  )
  const [date, setDate] = useState(contentEditable.date)
  useEffect(() => {
    if (editable) {
      setStartTime(contentEditable.heureStart)
      setEndTime(contentEditable.heureEnd)
    }
  }, [editable])

  useEffect(() => {
    if (contentEditable.date && editable) {
      setDate(contentEditable.date)
    }
  }, [editable, contentEditable.date])
  const handleTimeChange = (event) => {
    const { name, value } = event.target
    if (name === 'dropOffStart') {
      setStartTime(value)
    } else if (name === 'dropOffEnd') {
      setEndTime(value)
    }
  }
  const formatTime = (value) => {
    const formattedValue = value.replace(/[^0-9:]/g, '').slice(0, 5)
    const [hours, minutes] = formattedValue.split(':')

    const validHours = Math.min(Math.max(parseInt(hours, 10) || 0, 0), 23)
    const validMinutes = Math.min(Math.max(parseInt(minutes, 10) || 0, 0), 59)

    return `${String(validHours).padStart(2, '0')}:${String(validMinutes).padStart(
      2,
      '0'
    )}`
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target
    const formattedValue = formatTime(value)
    handleTimeChange({
      target: {
        name,
        value: formattedValue,
      },
    })

    const timeKey = name === 'dropOffStart' ? 'dropOffStart' : 'dropOffEnd'
    const prefix = nameAttribut === 'creneauArivee1' ? '' : 'second'

    const key = prefix
      ? `${prefix}${timeKey.charAt(0).toUpperCase()}${timeKey.slice(1)}`
      : timeKey

    onChange(
      {
        [key]: moment(`${date} ${formattedValue}`).format(),
      },
      {
        dropOffStart: moment(`${date} ${startTime}`).format(),
        dropOffEnd: moment(`${date} ${endTime}`).format(),
      }
    )
  }
  return (
    <StyledCell id={id}>
      {editable ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputMask
            mask="99:99"
            maskChar="0"
            value={startTime}
            onChange={handleInputChange}
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                error={
                  nameAttribut == 'creneauArivee1'
                    ? errorsCourseEditable?.includes('creneau1')
                    : errorsCourseEditable?.includes('creneau2')
                }
                type="text"
                name="dropOffStart"
                InputLabelProps={{
                  shrink: true,
                }}
                InputAdornmentProps={{
                  position: 'end',
                }}
              />
            )}
          </InputMask>

          <span style={{ margin: '8px 8px' }}>~</span>

          <InputMask
            mask="99:99"
            maskChar="0"
            value={endTime}
            onChange={handleInputChange}
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                type="text"
                error={
                  nameAttribut == 'creneauArivee1'
                    ? errorsCourseEditable?.includes('creneau1')
                    : errorsCourseEditable?.includes('creneau2')
                }
                name="dropOffEnd"
                InputLabelProps={{
                  shrink: true,
                }}
                InputAdornmentProps={{
                  position: 'end',
                }}
              />
            )}
          </InputMask>
        </div>
      ) : (
        <TextComponent content={content} key={key} />
      )}
    </StyledCell>
  )
}
ColumnTimeRangeComponentEditable.propTypes = {
  content: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  contentEditable: PropTypes.object,
  nameAttribut: PropTypes.string,
  errorsCourseEditable: PropTypes.array,
}

export default ColumnTimeRangeComponentEditable
