import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../../../../ModalDialog'
import IconCreationFilter from '../../../../../assets/icons/navFilter/MdiFilterPlusOutline'
import TextField from '@mui/material/TextField'

function CreationFilter({
  open,
  handleClose,
  filtersValues,
  pageFilter,
  onCreateFilter,
  isLoadingActionFilter,
  errorActionFilter,
  isActionCreationFilter,
}) {
  const [connectedUser] = useState(JSON.parse(localStorage.getItem('currentUser')))

  const [nameFilter, setNameFilter] = useState('')

  const onConfirme = () => {
    const payload = {
      idCollab: connectedUser.id,
      name: nameFilter,
      criteria: filtersValues,
      tab: pageFilter,
    }
    onCreateFilter(payload)
  }

  useEffect(() => {
    if (errorActionFilter) {
      handleClose()
    }
  }, [errorActionFilter])

  useEffect(() => {
    if (isActionCreationFilter) {
      handleClose()
    }
  }, [isActionCreationFilter])

  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={'Enregistrer filter'}
      iconTitle={
        <IconCreationFilter
          style={{
            width: '20px',
            height: '20px',
            marginRight: '10px',
          }}
        />
      }
      content={
        <div style={{ paddingRight: '16px', paddingBottom: '16px', width: '530px' }}>
          <div style={{ paddingBottom: '15px' }}>
            <span style={{ color: '#3C3C3C', fontSize: '13px' }}>Nom du filter</span>
          </div>
          <div style={{ paddingBottom: '5px' }}>
            <TextField
              id="nom-filter"
              variant="outlined"
              placeholder="Nom du filter"
              onChange={(event) => {
                setNameFilter(event.target.value)
              }}
              value={nameFilter}
            />
          </div>
        </div>
      }
      handleClose={handleClose}
      handleClickAction={onConfirme}
      disabled={nameFilter == ''}
      isLoadingButtonAction={isLoadingActionFilter}
    />
  )
}
CreationFilter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  filtersValues: PropTypes.object,
  pageFilter: PropTypes.string,
  onCreateFilter: PropTypes.func,
  isLoadingActionFilter: PropTypes.bool,
  errorActionFilter: PropTypes.string,
  isActionCreationFilter: PropTypes.bool,
}

export default CreationFilter
