import { LatLng } from './latlng'

export class MapMarker {
  constructor(id, lat, lng, title, animation, icon) {
    this.id = id
    this.position = new LatLng(lat, lng)
    this.title = title
    this.animation = animation
    this.icon = icon
  }
  getFromMarker(marker) {
    return new MapMarker(
      marker.id,
      marker.position.lat,
      marker.position.lng,
      marker.title,
      marker.animation,
      marker.icon
    )
  }

  setPosition(lat, lng) {
    this.position = new LatLng(lat, lng)
  }
  settitle(title) {
    this.title = title
  }
  setIcon(icon) {
    this.icon = icon
  }
}
