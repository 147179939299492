import { connect } from 'react-redux'

import MacroCanal from '../../../components/NavFilter/components/Filter/cell/MacroCanal'
import {
  getMacroCanal,
  getIsGetMacroCanalLoading,
} from '../../../redux/filter/selectors'

const mapStateToProps = (state) => ({
  macroCanalFilter: getMacroCanal(state),
  isLoading: getIsGetMacroCanalLoading(state),
})

export default connect(mapStateToProps, null)(MacroCanal)
