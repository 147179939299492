export const FETCH_CANAL_VENTES = 'FETCH_CANAL_VENTES'
export const FETCH_CANAL_VENTES_SUCCESS = 'FETCH_CANAL_VENTES_SUCCESS'
export const FETCH_CANAL_VENTES_FAILURE = 'FETCH_CANAL_VENTES_FAILURE'

export const FETCH_CANAL_VENTES_MORE = 'FETCH_CANAL_VENTES_MORE'
export const FETCH_CANAL_VENTES_MORE_SUCCESS = 'FETCH_CANAL_VENTES_MORE_SUCCESS'
export const FETCH_CANAL_VENTES_MORE_FAILURE = 'FETCH_CANAL_VENTES_MORE_FAILURE'

export const FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES =
  'FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES'
export const FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_SUCCESS =
  'FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_SUCCESS'
export const FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_FAILURE =
  'FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_FAILURE'

export const REMOVE_ERROR_DISPONIBILITES = 'REMOVE_ERROR_DISPONIBILITES'

export const CREATE_DISPONIBILITES = 'CREATE_DISPONIBILITES'
export const CREATE_DISPONIBILITES_SUCCESS = 'CREATE_DISPONIBILITES_SUCCESS'
export const CREATE_DISPONIBILITES_FAILURE = 'CREATE_DISPONIBILITES_FAILURE'

export const UPDATE_DISPONIBILITES = 'UPDATE_DISPONIBILITES'
export const UPDATE_DISPONIBILITES_SUCCESS = 'UPDATE_DISPONIBILITES_SUCCESS'
export const UPDATE_DISPONIBILITES_FAILURE = 'UPDATE_DISPONIBILITES_FAILURE'

export const RESET_SUCCESS_ACTION = 'RESET_SUCCESS_ACTION'
