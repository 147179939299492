import { connect } from 'react-redux'
import DetailsColis from '../../../../pages/main/Orders/DetailsDelivery/components/detailsColis'

import {
  getPackagOrder,
  getLoadingPackageOrder,
  getActionPointagePackageDone,
  getLoadingPointagePackage,
  getActionPointagePackageErorr,
  getLoadingPointageForcePackage,
  getActionPointageForcePackageDone,
  getErrorPackage,
  getErrorrImageLitigeUpload,
  getLoadingEtatPackage,
  getVisibiliteEtatPackage,
  getVisibiliteEtatPackageDone,
  getIsAnnulationLivraisonPartielleDone,
  getIsLoadingLivraisonPartielle,
  getOrderPackageCourseSource,
  getIsLoadingEditPackage,
  getIsActionEditPackageDone,
} from '../../../../redux/orderPackage/selectors'
import {
  getOrderPackage,
  onPointageForcePackage,
  setErrorUploadImageLitige,
  deleteImageLitigePackages,
  getEtatPackage,
  annulerLivraisonPartielle,
  onCreationCourseLivraisonPartielle,
  onEditPackage,
} from '../../../../redux/orderPackage/actions'

const mapStateToProps = (state) => ({
  packageOrder: getPackagOrder(state),
  isLoadingPackageOrder: getLoadingPackageOrder(state),
  isActionPointagePackageDone: getActionPointagePackageDone(state),
  isLoadingPointagePackage: getLoadingPointagePackage(state),
  isActionPointagePackageErorr: getActionPointagePackageErorr(state),
  isLoadingPointageForcePackage: getLoadingPointageForcePackage(state),
  isActionPointageForcePackageDone: getActionPointageForcePackageDone(state),
  errorPackage: getErrorPackage(state),
  errorImageLitigeUpload: getErrorrImageLitigeUpload(state),
  isLoadingEtatPackage: getLoadingEtatPackage(state),
  isVisibiliteEtatPackage: getVisibiliteEtatPackage(state),
  isVisibiliteEtatPackageDone: getVisibiliteEtatPackageDone(state),
  isAnnulationLivraisonPartielleDone: getIsAnnulationLivraisonPartielleDone(state),
  isLoadingLivraisonPartielle: getIsLoadingLivraisonPartielle(state),
  courseSource: getOrderPackageCourseSource(state),
  isLoadingEditPackage: getIsLoadingEditPackage(state),
  isActionEditPackageDone: getIsActionEditPackageDone(state),
})
const mapDisptachToProps = (dispatch) => ({
  getOrderPackage: (ldv) => dispatch(getOrderPackage(ldv)),
  onPointageForcePackage: (payload) => dispatch(onPointageForcePackage(payload)),
  setErrorUploadImageLitige: (isError) =>
    dispatch(setErrorUploadImageLitige(isError)),
  deleteImageLitigePackages: () => dispatch(deleteImageLitigePackages()),
  getEtatPackage: (ldv) => dispatch(getEtatPackage(ldv)),
  annulerLivraisonPartielle: () => dispatch(annulerLivraisonPartielle()),
  onCreationCourseLivraisonPartielle: (payload) =>
    dispatch(onCreationCourseLivraisonPartielle(payload)),
  onEditPackage: (payload, id) => dispatch(onEditPackage(payload, id)),
})

export default connect(mapStateToProps, mapDisptachToProps)(DetailsColis)
