import _ from 'lodash'
import { validatorContants } from './constants'
import * as EmailValidator from 'email-validator'
import checkArticleFamilies from '../checkArticleFamilies'
import Factures from '../checkFacture'
import { getOrderStatusMofifiable } from '../../utils/getOrderStatus'

export const validateShipment = (values, elements) => {
  let errors = {}
  // validate selectedSalesChannel if exist in elements to validate
  if (!_.isNil(_.get(elements, 'selectedSalesChannel'))) {
    // validate required  if required exist in elements.selectedSalesChannel
    if (
      elements.selectedSalesChannel.find(
        (el) => el === validatorContants.REQUIRED
      ) &&
      _.isEmpty(_.get(values, 'selectedSalesChannel'))
    ) {
      errors['selectedSalesChannel'] = {
        ...errors['selectedSalesChannel'],
        [validatorContants.REQUIRED]: 'Canal de vente est obligatoire!',
      }
    }

    // validate pattern if pattern exist in elements.selectedSalesChannel
    if (
      elements.selectedSalesChannel.find((el) => el === validatorContants.PATTERN) &&
      _.isNil(_.get(values, 'selectedSalesChannel.configs'))
    ) {
      errors['selectedSalesChannel'] = {
        ...errors['selectedSalesChannel'],
        [validatorContants.PATTERN]: 'Canal de vente est invalide!',
      }
    }
  }

  // validate originQuery if exist in elements to validate
  if (!_.isNil(_.get(elements, 'originQuery'))) {
    // validate required  if required exist in elements.originQuery
    if (
      !_.get(values, 'originQuery.length') &&
      elements.originQuery.find((el) => el === validatorContants.REQUIRED)
    ) {
      errors['selectedOrigin'] = {
        ...errors['selectedOrigin'],
        [validatorContants.REQUIRED]: 'Adresse de départ est obligatoire!',
      }
    }
    if (
      _.get(values, 'originQuery') !=
      _.get(values, 'selectedOrigin.formattedAddress')
    ) {
      errors['selectedOrigin'] = {
        ...errors['selectedOrigin'],
        [validatorContants.PATTERN]: 'Adresse de départ est invalide!',
      }
    }
  }
  // validate selectedOrigin if exist in elements to validate
  if (!_.isNil(_.get(elements, 'selectedOrigin'))) {
    // validate required  if required exist in elements.selectedOrigin
    if (
      _.isNil(_.get(values, 'selectedOrigin')) &&
      elements.selectedOrigin.find((el) => el === validatorContants.REQUIRED)
    ) {
      errors['selectedOrigin'] = {
        ...errors['selectedOrigin'],
        [validatorContants.REQUIRED]: 'Adresse de départ est obligatoire!',
      }
    }

    // validate pattern  if pattern exist in elements.selectedOrigin
    if (
      (elements.selectedOrigin.find((el) => el === validatorContants.PATTERN) &&
        _.isNil(_.get(values, 'selectedOrigin.formattedAddress'))) ||
      _.isNil(_.get(values, 'selectedOrigin.latlng.lat')) ||
      _.isNil(_.get(values, 'selectedOrigin.latlng.lng'))
    ) {
      errors['selectedOrigin'] = {
        ...errors['selectedOrigin'],
        [validatorContants.PATTERN]: 'Adresse de départ est invalide!',
      }
    }
  }
  if (
    _.isNil(_.get(values, 'direction.selectedDirection.distance')) ||
    _.isNil(_.get(values, 'direction.selectedDirection.duration')) ||
    _.isNil(_.get(values, 'direction.selectedDirection.index'))
  ) {
    errors['selectedOrigin'] = {
      ...errors['selectedOrigin'],
      [validatorContants.PATTERN]: 'distance est invalide!',
    }
  }
  // validate selectedDestination if exist in elements to validate
  if (
    !_.isNil(_.get(elements, 'selectedDestination')) &&
    !_.isNil(_.get(elements, 'destinationQuery'))
  ) {
    // validate required  if required exist in elements.selectedDestination
    if (
      elements.selectedDestination.find((el) => el === validatorContants.REQUIRED) &&
      _.isNil(_.get(values, 'selectedDestination'))
    ) {
      errors['selectedDestination'] = {
        ...errors['selectedDestination'],
        [validatorContants.REQUIRED]: "Adresse d'arrivée est obligatoire!",
      }
    }

    // validate pattern  if pattern exist in elements.selectedDestination
    if (
      (elements.selectedDestination.find((el) => el === validatorContants.PATTERN) &&
        _.isNil(_.get(values, 'selectedDestination.formattedAddress'))) ||
      _.isNil(_.get(values, 'selectedDestination.latlng.lat')) ||
      _.isNil(_.get(values, 'selectedDestination.latlng.lng'))
    ) {
      errors['selectedDestination'] = {
        ...errors['selectedDestination'],
        [validatorContants.PATTERN]: "Adresse d'arrivée est invalide!",
      }
    }
    if (
      !_.get(values, 'destinationQuery.length') &&
      elements.destinationQuery.find((el) => el === validatorContants.REQUIRED)
    ) {
      errors['selectedDestination'] = {
        ...errors['selectedDestination'],
        [validatorContants.REQUIRED]: "Adresse d'arrivée est obligatoire!",
      }
    }
    if (
      _.get(values, 'destinationQuery') !=
      _.get(values, 'selectedDestination.formattedAddress')
    ) {
      errors['selectedDestination'] = {
        ...errors['selectedDestination'],
        [validatorContants.PATTERN]: "Adresse d'arrivée est invalide!",
      }
    }
  }

  // // validate destinationQuery if exist in elements to validate
  // if () {
  //   // validate required  if required exist in elements.destinationQuery

  // }

  if (!_.isNil(_.get(elements, 'level'))) {
    // validate required  if required exist in elements.destinationQuery
    if (_.isNil(_.get(values, 'level')) || _.get(values, 'level') === undefined) {
      errors['level'] = {
        ...errors['level'],
        [validatorContants.REQUIRED]: "L'étage est obligatoire!",
      }
    }
  }
  if (!_.isNil(_.get(elements, 'number'))) {
    // validate required  if required exist in elements.destinationQuery
    if (
      (_.isNil(_.get(values, 'number')) || _.get(values, 'number') === '') &&
      _.get(values, 'isInterphone') === true
    ) {
      errors['number'] = {
        ...errors['number'],
        [validatorContants.REQUIRED]: 'Un numéro ou un nom est obligatoire!',
      }
    }
  }

  if (_.isNil(_.get(values, 'direction'))) {
    errors['selectedDestination'] = {
      ...errors['selectedDestination'],
      [validatorContants.REQUIRED]: 'distance est obligatoire!',
    }
  }
  if (
    _.isNil(_.get(values, 'direction.selectedDirection.distance')) ||
    _.isNil(_.get(values, 'direction.selectedDirection.duration')) ||
    _.isNil(_.get(values, 'direction.selectedDirection.index'))
  ) {
    errors['selectedDestination'] = {
      ...errors['selectedDestination'],
      [validatorContants.PATTERN]: 'distance est invalide!',
    }
  }

  if (_.isNil(_.get(values, 'date'))) {
    errors['date'] = {
      ...errors['date'],
      [validatorContants.REQUIRED]: 'Date est obligatoire!',
    }
  }
  if (!_.isNil(_.get(values, 'date')) && !(_.get(values, 'date') instanceof Date)) {
    errors['date'] = {
      ...errors['date'],
      [validatorContants.PATTERN]: 'date est invalide!',
    }
  }
  if (
    _.isNil(_.get(values, 'earliestDeliveryDate')) &&
    getOrderStatusMofifiable(values)
  ) {
    errors['earliestDeliveryDate'] = {
      ...errors['earliestDeliveryDate'],
      [validatorContants.REQUIRED]: 'Date de livraison au plus tôt est obligatoire!',
    }
  }

  if (
    _.isNil(_.get(values, 'latestDeliveryDate')) &&
    getOrderStatusMofifiable(values)
  ) {
    errors['latestDeliveryDate'] = {
      ...errors['latestDeliveryDate'],
      [validatorContants.REQUIRED]:
        'Date de livraison au plus tard est obligatoire!',
    }
  }

  if (_.isNil(_.get(values, 'time'))) {
    errors['time'] = {
      ...errors['time'],
      [validatorContants.REQUIRED]: 'Heure est obligatoire!',
    }
  }
  if (
    elements.time.find((el) => el === validatorContants.PATTERN) &&
    !_.isNil(_.get(values, 'time')) &&
    (_.isNil(_.get(values, 'time.start')) || _.isNil(_.get(values, 'time.end')))
  ) {
    errors['time'] = {
      ...errors['time'],
      [validatorContants.PATTERN]: 'Heure est invalide!',
    }
  }

  // validate weight if exist in elements to validate
  if (!_.isNil(_.get(elements, 'weight'))) {
    // validate required  if required exist in elements.weight
    if (
      elements.weight.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'weight')
      // &&
      // _.get(values, 'selectedSalesChannel.configs.priceBasedOnWeight')
    ) {
      errors['weight'] = {
        ...errors['weight'],
        [validatorContants.REQUIRED]: 'Poids est obligatoire!',
      }
    }

    // validate pattern  if pattern exist in elements.weight
    if (
      elements.weight.find((el) => el === validatorContants.PATTERN) &&
      !_.isNil(_.get(values, 'weight')) &&
      !_.isNumber(parseFloat(_.get(values, 'weight')))
    ) {
      errors['weight'] = {
        ...errors['weight'],
        [validatorContants.PATTERN]: 'Poids est invalide!',
      }
    }
    if (
      elements.weight.find((el) => el.code === validatorContants.MIN) &&
      !_.isNil(_.get(values, 'weight'))
    ) {
      const minValidator = elements.weight.find(
        (el) => el.code === validatorContants.MIN
      )
      if (minValidator && parseFloat(_.get(values, 'weight')) < minValidator.value) {
        errors['weight'] = {
          ...errors['weight'],
          [validatorContants.PATTERN]: `Poids des articles min est ${minValidator.value}`,
        }
      }
    }
  }

  // validate pattern  if pattern exist in elements.weight

  // validate nbItems if exist in elements to validate
  if (!_.isNil(_.get(elements, 'nbItems'))) {
    // validate required  if required exist in elements.nbItems
    if (
      elements.nbItems.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'nbItems')
    ) {
      errors['nbItems'] = {
        ...errors['nbItems'],
        [validatorContants.REQUIRED]: "nombre d'articles est obligatoire!",
      }
    }

    // validate pattern  if pattern exist in elements.nbItems
    if (
      elements.nbItems.find((el) => el === validatorContants.PATTERN) &&
      !_.isNil(_.get(values, 'nbItems')) &&
      !_.isEqual(_.get(values, 'nbItems'), '') &&
      !_.isInteger(parseFloat(_.get(values, 'nbItems')))
    ) {
      errors['nbItems'] = {
        ...errors['nbItems'],
        [validatorContants.PATTERN]: "nombre d'articles est invalide!",
      }
    }
    if (
      elements.nbItems.find((el) => el.code === validatorContants.MAX) &&
      !_.isNil(_.get(values, 'nbItems'))
    ) {
      const maxValidator = elements.nbItems.find(
        (el) => el.code === validatorContants.MAX
      )
      if (
        maxValidator &&
        parseFloat(_.get(values, 'nbItems')) > maxValidator.value
      ) {
        errors['nbItems'] = {
          ...errors['nbItems'],
          [validatorContants.PATTERN]: `Le nombres d'articles maximal est ${maxValidator.value}`,
        }
      }
    }
    if (
      elements.nbItems.find((el) => el.code === validatorContants.MIN) &&
      !_.isNil(_.get(values, 'nbItems'))
    ) {
      const minValidator = elements.nbItems.find(
        (el) => el.code === validatorContants.MIN
      )
      if (
        minValidator &&
        parseFloat(_.get(values, 'nbItems')) < minValidator.value
      ) {
        errors['nbItems'] = {
          ...errors['nbItems'],
          [validatorContants.PATTERN]: `Le nombres d'articles minimal est ${minValidator.value}`,
        }
      }
    }
  }

  // validate purchaseAmount if exist in elements to validate
  if (!_.isNil(_.get(elements, 'purchaseAmount'))) {
    // validate required  if required exist in elements.purchaseAmount
    if (
      elements.purchaseAmount.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'purchaseAmount') &&
      _.get(values, 'selectedSalesChannel.configs.priceBasedOnPurchaseAmount')
    ) {
      errors['purchaseAmount'] = {
        ...errors['purchaseAmount'],
        [validatorContants.REQUIRED]: "montant d'achat est obligatoire!",
      }
    }

    // validate pattern  if pattern exist in elements.purchaseAmount
    if (
      elements.purchaseAmount.find((el) => el === validatorContants.PATTERN) &&
      !_.isNil(_.get(values, 'purchaseAmount')) &&
      !_.isNumber(parseFloat(_.get(values, 'purchaseAmount')))
    ) {
      errors['purchaseAmount'] = {
        ...errors['purchaseAmount'],
        [validatorContants.PATTERN]: "montant d'achat est invalide!",
      }
    }

    // validate pattern  if pattern exist in elements.purchaseAmount
    if (
      elements.purchaseAmount.find((el) => el.code === validatorContants.MIN) &&
      !_.isNil(_.get(values, 'purchaseAmount'))
    ) {
      const minValidator = elements.purchaseAmount.find(
        (el) => el.code === validatorContants.MIN
      )
      if (
        minValidator &&
        parseFloat(_.get(values, 'purchaseAmount')) < minValidator.value
      ) {
        errors['purchaseAmount'] = {
          ...errors['purchaseAmount'],
          [validatorContants.MIN]: `montant d'achat min est ${minValidator.value}`,
        }
      }
    }
  }

  // validate phone if exist in elements to validate
  if (!_.isNil(_.get(elements, 'originPhone'))) {
    // validate required  if required exist in elements.originPhone
    if (
      elements.originPhone.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'originPhone.length')
    ) {
      errors['originPhone'] = {
        ...errors['originPhone'],
        [validatorContants.REQUIRED]: 'numéro de téléphone est obligatoire!',
      }
    }

    // validate pattern  if pattern exist in elements.originPhone
    if (
      elements.originPhone.find((el) => el === validatorContants.PATTERN) &&
      _.get(values, 'originPhone.length') < 3
    ) {
      errors['originPhone'] = {
        ...errors['originPhone'],
        [validatorContants.PATTERN]: 'numéro de téléphone est invalide!',
      }
    }
  }

  if (!_.isNil(_.get(elements, 'originMail'))) {
    // validate required  if required exist in elements.email
    if (
      elements.originMail.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'originMail')
    ) {
      errors['originMail'] = {
        ...errors['originMail'],
        [validatorContants.REQUIRED]: 'email est obligatoire!',
      }
    }

    // validate pattern  if pattern exist in elements.email
    if (
      elements.originMail.find((el) => el === validatorContants.PATTERN) &&
      !_.isNil(_.get(values, 'originMail')) &&
      !EmailValidator.validate(_.get(values, 'originMail')) &&
      _.get(values, 'originMail') !== ''
    ) {
      errors['originMail'] = {
        ...errors['originMail'],
        [validatorContants.PATTERN]: 'email est invalide!',
      }
    }
  }
  if (!_.get(values, 'clientInfos')) {
    if (!_.isNil(_.get(elements, 'destinationFirstname'))) {
      // validate required  if required exist in elements.destinationFirstname
      if (
        elements.destinationFirstname.find(
          (el) => el === validatorContants.REQUIRED
        ) &&
        !_.get(values, 'destinationFirstname')
      ) {
        errors['destinationFirstname'] = {
          ...errors['destinationFirstname'],
          [validatorContants.REQUIRED]: 'Prénon est obligatoire!',
        }
      }
    }
    if (!_.isNil(_.get(elements, 'destinationLastname'))) {
      // validate required  if required exist in elements.destinationFirstname
      if (
        elements.destinationLastname.find(
          (el) => el === validatorContants.REQUIRED
        ) &&
        !_.get(values, 'destinationLastname')
      ) {
        errors['destinationLastname'] = {
          ...errors['destinationLastname'],
          [validatorContants.REQUIRED]: 'Nom est obligatoire!',
        }
      }
    }
    // validate phone if exist in elements to validate
    if (!_.isNil(_.get(elements, 'destinationPhone'))) {
      // validate required  if required exist in elements.destinationPhone
      if (
        elements.destinationPhone.find((el) => el === validatorContants.REQUIRED) &&
        !_.get(values, 'destinationPhone.length')
      ) {
        errors['destinationPhone'] = {
          ...errors['destinationPhone'],
          [validatorContants.REQUIRED]: 'numéro de téléphone est obligatoire!',
        }
      }

      // validate pattern  if pattern exist in elements.destinationPhone
      if (
        elements.destinationPhone.find((el) => el === validatorContants.PATTERN) &&
        _.get(values, 'destinationPhone.length') < 3
      ) {
        errors['destinationPhone'] = {
          ...errors['destinationPhone'],
          [validatorContants.PATTERN]: 'numéro de téléphone est invalide!',
        }
      }
    }
    if (
      !_.isNil(_.get(elements, 'destinationMail')) &&
      !_.get(values, 'envoiMailArrivee')
    ) {
      // validate required  if required exist in elements.email
      if (
        elements.destinationMail.find((el) => el === validatorContants.REQUIRED) &&
        !_.get(values, 'destinationMail')
      ) {
        errors['destinationMail'] = {
          ...errors['destinationMail'],
          [validatorContants.REQUIRED]: 'email est obligatoire!',
        }
      }

      // validate pattern  if pattern exist in elements.email
      if (
        elements.destinationMail.find((el) => el === validatorContants.PATTERN) &&
        !_.isNil(_.get(values, 'destinationMail')) &&
        !EmailValidator.validate(_.get(values, 'destinationMail')) &&
        _.get(values, 'destinationMail') !== ''
      ) {
        errors['destinationMail'] = {
          ...errors['destinationMail'],
          [validatorContants.PATTERN]: 'email est invalide!',
        }
      }
    }
  }
  // validate nbItems if exist in elements to validate
  if (!_.isNil(_.get(elements, 'providerPrice'))) {
    // validate required  if required exist in elements.providerPrice
    if (
      elements.providerPrice.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'providerPrice')
    ) {
      errors['providerPrice'] = {
        ...errors['providerPrice'],
        [validatorContants.REQUIRED]: 'le prix est obligatoire!',
      }
    }

    // validate pattern  if pattern exist in elements.providerPrice

    if (
      elements.providerPrice.find((el) => el.code === validatorContants.MIN) &&
      !_.isNil(_.get(values, 'providerPrice'))
    ) {
      const minValidator = elements.providerPrice.find(
        (el) => el.code === validatorContants.MIN
      )
      if (
        minValidator &&
        parseFloat(_.get(values, 'providerPrice')) < minValidator.value &&
        _.get(values, 'selectedSalesChannel.code') === 'B2H_SAV'
      ) {
        errors['providerPrice'] = {
          ...errors['providerPrice'],
          [validatorContants.PATTERN]: `Le prix minimal est ${minValidator.value}`,
        }
      }
    }
  }

  if (!_.isNil(_.get(elements, 'clientPrice'))) {
    // validate required  if required exist in elements.clientPrice
    if (
      elements.clientPrice.find((el) => el === validatorContants.REQUIRED) &&
      !_.get(values, 'clientPrice')
    ) {
      errors['clientPrice'] = {
        ...errors['clientPrice'],
        [validatorContants.REQUIRED]: 'le prix est obligatoire!',
      }
    }

    // validate pattern  if pattern exist in elements.clientPrice

    if (
      elements.clientPrice.find((el) => el.code === validatorContants.MIN) &&
      !_.isNil(_.get(values, 'clientPrice'))
    ) {
      const minValidator = elements.clientPrice.find(
        (el) => el.code === validatorContants.MIN
      )
      if (
        minValidator &&
        parseFloat(_.get(values, 'clientPrice')) < minValidator.value &&
        _.get(values, 'selectedSalesChannel.code') === 'B2H_SAV'
      ) {
        errors['clientPrice'] = {
          ...errors['clientPrice'],
          [validatorContants.PATTERN]: `Le prix minimal est ${minValidator.value}`,
        }
      }
    }
    // validate articleFamilies if exist in elements to validate
    if (!_.isNil(_.get(elements, 'articleFamilies'))) {
      // validate required  if required exist in elements.articleFamilies
      if (
        elements.articleFamilies.find((el) => el === validatorContants.REQUIRED) &&
        !_.isEqual(_.get(values, 'articleFamilies'), []) &&
        checkArticleFamilies(_.get(values, 'articleFamilies'))
      ) {
        errors['articleFamilies'] = {
          ...errors['articleFamilies'],
          [validatorContants.REQUIRED]:
            "Veuillez choisir au moins une famille d'articles.!",
        }
      }
    }

    if (!_.isNil(_.get(elements, 'factures'))) {
      // validate required  if required exist in elements.articleFamilies

      if (
        elements.factures.find((el) => el === validatorContants.REQUIRED) &&
        !_.isEqual(_.get(values, 'factures'), []) &&
        Factures.checkFacture(_.get(values, 'factures'))
      ) {
        const indexErr = Factures.IndexErr(_.get(values, 'factures'))
        errors['factures'] = {
          ...errors['factures'],
          [validatorContants.REQUIRED]: {
            index: indexErr,
            message: 'Référence client est obligatoire!',
          },
        }
      }
    }
    // validate pattern  if pattern exist in elements.noteInterne

    /*    if (
      elements.noteInterne.find((el) => el.code === validatorContants.MAX) &&
      !_.isNil(_.get(values, 'noteInterne'))
    ) {
      const maxValidator = elements.noteInterne.find(
        (el) => el.code === validatorContants.MAX
      )
      if (maxValidator && _.get(values, 'noteInterne').length > maxValidator.value) {
        errors['noteInterne'] = {
          ...errors['noteInterne'],
          [validatorContants.PATTERN]: `Le nombres de charactere maximal est ${maxValidator.value}`,
        }
      }
    }*/
  }

  // validate PICKUP if exist in elements to validate
  if (
    (!_.isNil(_.get(elements, 'pickUpDate')) ||
      !_.isNil(_.get(elements, 'heurePickUp'))) &&
    !getOrderStatusMofifiable(values)
  ) {
    if (_.isNil(_.get(values, 'pickUpDate'))) {
      errors['pickUpDate'] = {
        ...errors['pickUpDate'],
        [validatorContants.REQUIRED]: 'Date de retrait est obligatoire!',
      }
    }
    if (
      !_.isNil(_.get(values, 'pickUpDate')) &&
      !(_.get(values, 'pickUpDate') instanceof Date)
    ) {
      errors['pickUpDate'] = {
        ...errors['pickUpDate'],
        [validatorContants.PATTERN]: 'Date de retrait est invalide!',
      }
    }

    if (_.isNil(_.get(values, 'heurePickUp'))) {
      errors['heurePickUp'] = {
        ...errors['heurePickUp'],
        [validatorContants.REQUIRED]: 'Heure de retrait est obligatoire!',
      }
    }
  }
  return errors
}
