import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import './index.scss'
import '../Grid/Cell/fab-button.scss'
import PropTypes from 'prop-types'
import CancelShipment from '../../../../containers/main/ActionShipment/CancelShipment'
import Filter from './Filter'
import TableContent from './TableContent'
import Checkbox from '@mui/material/Checkbox'
import Snackbar from '@mui/material/Snackbar'
import TableSortLabel from '@mui/material/TableSortLabel'

import Alert from '@mui/material/Alert'
import { getDate } from '../../../../utils/dateAdapter'
import {
  ALPHABETIC_DATE_FORMAT,
  getCourseType,
  getCreneau,
} from '../../../../utils/utils'
import { SELECTED_COURSE_ENUM, COMMANDE_TYPE } from '../../../../utils/values'
import { styled } from '@mui/material/styles'
import {
  actionsOrdersTable,
  checkOrdersTable,
  getListOrdersTableAutover,
  getListOrdersTablePlaris,
  progActionsOrdersTable,
  settingsOrdersTable,
} from './data'

const StyledTableSortLabel = styled(TableSortLabel)`
  &.Mui-active {
    color: #00e676 !important;
  }
`
export default function OrdersTable({
  shipments,
  isProgrammerPage,
  //checkedCourses,
  checkedAllCourses,
  setCheckedAllCourses,
  createSortHandler,
  openSnackbar,
  handleSnackbar,
  orderBy,
  sortDirection,
  removeSelectedCourses,
  addSelectedCourses,
  selectedCourses,
  setOpenTourDialog,
  setLdt,
  setDropoffStartCourses,
  setEqualDropoffStartCourses,
  setAllCheckCourse,
  setCheckCourse,
  coursesChecked,
  checkCoursesAll,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [cancelModalContent, setCancelModalContent] = React.useState({})
  const [columns, setColumns] = React.useState([])

  useEffect(() => {
    const columnsKey = isProgrammerPage ? 'columns_list_prog' : 'columns_list'
    const columnsList = isProgrammerPage
      ? getListOrdersTablePlaris()
      : getListOrdersTableAutover()
    const list = JSON.parse(localStorage.getItem(columnsKey))
    if (list) setColumns(list)
    else setColumns(columnsList)
  }, [])
  const handleColumns = (list) => {
    setColumns(list)
    const columnsKey = isProgrammerPage ? 'columns_list_prog' : 'columns_list'

    localStorage.setItem(columnsKey, JSON.stringify(list))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [openCancelModal, setOpenCancelModal] = React.useState(false)
  const handleCancelOpen = (body, showModal) => {
    if (showModal === true) {
      setCancelModalContent(body)
    }
    setOpenCancelModal(!openCancelModal)
  }
  const open = Boolean(anchorEl)
  const checkedLabels = isProgrammerPage ? progActionsOrdersTable : checkOrdersTable
  const getChecked = (name) => {
    let isChecked = false
    columns.forEach((column) => {
      if (column.name === name) {
        isChecked = column.checked
      }
    })
    return isChecked
  }
  const handleCheckAll = () => {
    if (!checkedAllCourses) {
      setCheckedAllCourses(true)
      console.log(checkedAllCourses)
      const allCodes = shipments.map((e) => {
        return e.code
      })
      addSelectedCourses({ data: [...allCodes], source: SELECTED_COURSE_ENUM.TABLE })
      // setCheckedCourses([...checkedCourses, ...allCodes])
    }
  }

  return (
    <>
      <Paper
        className="order-table-root"
        style={{
          width: '100%',
          marginLeft: '0px',
          marginRight: '0px',
        }}
      >
        <TableContainer
          className="order-table-container"
          style={{
            height: coursesChecked?.length > 0 && `calc(100vh - 277px)`,
            maxHeight:
              selectedCourses?.length > 0
                ? `calc(100vh - ${263}px)`
                : `calc(100vh - ${!isProgrammerPage ? 213 : 200}px)`,
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ backgroundColor: 'white' }}
          >
            <TableHead>
              <TableRow>
                {[
                  ...checkedLabels,
                  ...columns.concat(
                    isProgrammerPage
                      ? [...settingsOrdersTable]
                      : [...actionsOrdersTable, ...settingsOrdersTable]
                  ),
                ].map(
                  (column, index) =>
                    column.checked &&
                    (column.id === 'check' ? (
                      <TableCell
                        key={`column_${index}`}
                        align="center"
                        style={{
                          color: '#2A304C',
                          backgroundColor: '#F7F8F9',
                        }}
                      >
                        <Checkbox
                          id={'checkbox-selectionner-tous-orders-table'}
                          checked={checkedAllCourses}
                          onChange={handleCheckAll}
                        />
                      </TableCell>
                    ) : column.id === 'checkOrder' ? (
                      <TableCell
                        key={`column_${index}`}
                        align="center"
                        style={{
                          color: '#2A304C',
                          backgroundColor: '#F7F8F9',
                        }}
                      >
                        <Checkbox
                          id={'checkbox-selectionner-tous-orders-table'}
                          checked={checkCoursesAll}
                          onChange={setAllCheckCourse}
                        />
                      </TableCell>
                    ) : column.id === 'settings' ? (
                      <Filter
                        columns={columns}
                        handleColumns={handleColumns}
                        isProgrammerPage={isProgrammerPage}
                        index={index}
                        key={`filter_${index}`}
                      />
                    ) : (
                      <TableCell
                        key={`column_${index}`}
                        align="center"
                        style={
                          orderBy === column.id
                            ? {
                                borderBottom: '2px solid #00e676',
                                color: '#2A304C',
                                backgroundColor: '#F7F8F9',
                              }
                            : {
                                color: '#2A304C',
                                backgroundColor: '#F7F8F9',
                              }
                        }
                        sortDirection={
                          orderBy === column.sortName ? sortDirection : false
                        }
                      >
                        {column.availableInSort ? (
                          <StyledTableSortLabel
                            active={orderBy === column.sortName}
                            direction={sortDirection || 'desc'}
                            onClick={() => createSortHandler(column.sortName)}
                          >
                            {column.label}
                          </StyledTableSortLabel>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    ))
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {shipments
                .map((e) => {
                  return {
                    ...e,
                    courseMetadata: e.courseMetadata
                      ? e.courseMetadata
                      : {
                          earliestDeliveryDate: null,
                          latestDeliveryDate: null,
                          courseType: null,
                          kitchenInstallationDate: null,
                          category: null,
                        },
                  }
                })
                .map((shipment, index) => {
                  const {
                    code,
                    adresseArrivee,
                    adresseDepart,
                    canBePrepared,
                    commande: { client, canalVente, etatPaiement, moyenPaiement },
                    courseMetadata: {
                      earliestDeliveryDate,
                      latestDeliveryDate,
                      courseType,
                      kitchenInstallationDate,
                      category,
                    },
                    dropOffEnd,
                    dropOffStart,
                    pickUpEnd,
                    pickUpStart,
                    contactArrivee,
                    contactDepart,
                    dateDemarrage,
                    lettreDeVoiture,
                    manutention,
                    status,
                    montantPrestataireHT,
                    weight,
                    volume,
                    chauffeur,
                    dateDemarrageMeta,
                    assemblies,
                    createdAt,
                    vehiculeType,
                    nombreColis,
                    observation,
                    factures,
                    noteInterne,
                    courseSource,
                    isPrepared,
                    observationArrivee,
                    montantHT,
                    prestation,
                    tour,
                    deliveryRelated,
                    ldvRelatedCourse,
                    dateLivraison,
                    dateFinChargement,
                    departureType,
                  } = shipment
                  const name = canalVente ? canalVente.name : null
                  const adresses = canalVente ? canalVente.adresses : null
                  return (
                    <TableContent
                      indexRow={index}
                      setOpenTourDialog={setOpenTourDialog}
                      setLdt={setLdt}
                      setDropoffStartCourses={setDropoffStartCourses}
                      setEqualDropoffStartCourses={setEqualDropoffStartCourses}
                      key={`table_${index}`}
                      deliveryDate={getCreneau(
                        dropOffStart,
                        dropOffEnd,
                        dateDemarrageMeta,
                        dateDemarrage,
                        ALPHABETIC_DATE_FORMAT
                      )}
                      pickUpDate={getCreneau(
                        pickUpStart,
                        pickUpEnd,
                        dateDemarrageMeta,
                        dateDemarrage,
                        ALPHABETIC_DATE_FORMAT
                      )}
                      dropOffStart={dropOffStart}
                      getChecked={getChecked}
                      columns={columns}
                      code={code}
                      adresseArrivee={adresseArrivee}
                      adresseDepart={adresseDepart}
                      earliestDeliveryDate={getDate(earliestDeliveryDate)}
                      latestDeliveryDate={getDate(latestDeliveryDate)}
                      kitchenInstallationDate={getDate(kitchenInstallationDate)}
                      courseType={getCourseType(courseType)}
                      canBePrepared={canBePrepared}
                      client={client}
                      name={name}
                      adresses={adresses}
                      montantPrestataireHT={montantPrestataireHT}
                      etatPaiement={etatPaiement}
                      moyenPaiement={moyenPaiement}
                      contactArrivee={contactArrivee}
                      contactDepart={contactDepart}
                      handleClose={handleClose}
                      handleClick={(event) => setAnchorEl(event.currentTarget)}
                      anchorEl={anchorEl}
                      handleCancelOpen={handleCancelOpen}
                      lettreDeVoiture={lettreDeVoiture}
                      manutention={manutention}
                      status={status}
                      weight={weight}
                      volume={volume}
                      prestation={prestation?.label}
                      chauffeur={chauffeur}
                      assemblies={assemblies}
                      createdAt={createdAt}
                      vehiculeType={vehiculeType}
                      nombreColis={nombreColis}
                      observation={observation}
                      factures={factures}
                      noteInterne={noteInterne}
                      courseSource={courseSource}
                      isPrepared={isPrepared}
                      observationArrivee={observationArrivee}
                      dateLivraison={getDate(dateLivraison)}
                      dateFinChargement={getDate(dateFinChargement)}
                      open={open}
                      montantHT={montantHT}
                      tour={tour}
                      canalVente={canalVente}
                      deliveryRelated={deliveryRelated}
                      isProgrammerPage={isProgrammerPage}
                      addSelectedCourses={addSelectedCourses}
                      removeSelectedCourses={removeSelectedCourses}
                      isTicked={
                        isProgrammerPage
                          ? selectedCourses.indexOf(code) !== -1
                          : false
                      }
                      category={COMMANDE_TYPE[category]}
                      ldvRelatedCourse={ldvRelatedCourse}
                      setCheckCourse={setCheckCourse}
                      coursesChecked={coursesChecked}
                      courseTypeCode={courseType}
                      departureType={departureType}
                    />
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <>
        {openCancelModal && (
          <CancelShipment
            open={openCancelModal}
            handleClose={handleCancelOpen}
            orderAnnuler={cancelModalContent}
            payload={cancelModalContent}
          />
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => handleSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => handleSnackbar(false)} severity="info">
            {"l n'y a pas de tri disponible pour cette colonne!"}
          </Alert>
        </Snackbar>
      </>
    </>
  )
}
OrdersTable.propTypes = {
  shipments: PropTypes.array,
  isProgrammerPage: PropTypes.bool,
  setCheckedCourses: PropTypes.func,
  checkedCourses: PropTypes.array,
  checkedAllCourses: PropTypes.bool,
  setCheckedAllCourses: PropTypes.func,
  createSortHandler: PropTypes.func,
  handleSnackbar: PropTypes.func,
  orderBy: PropTypes.any,
  sortDirection: PropTypes.string,
  openSnackbar: PropTypes.bool,
  addSelectedCourses: PropTypes.func,
  removeSelectedCourses: PropTypes.func,
  selectedCourses: PropTypes.func,
  setOpenTourDialog: PropTypes.func,
  setLdt: PropTypes.func,
  setDropoffStartCourses: PropTypes.func,
  setEqualDropoffStartCourses: PropTypes.func,
  setAllCheckCourse: PropTypes.func,
  setCheckCourse: PropTypes.func,
  coursesChecked: PropTypes.array,
  checkCoursesAll: PropTypes.bool,
}
