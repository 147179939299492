import React, { useState, useEffect } from 'react'
import InputMessage from './inputMessage'
import Chip from '@mui/material/Chip'

import PropTypes from 'prop-types'
import ChatItem from './chatItem'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import $ from 'jquery'
import { EVENT_SOCKET } from '../../../../../utils/discussion/values'
import { Link } from 'react-router-dom'

export default function ChatComponent({
  messageList,
  isLoadingGetMessageList,
  pushMessage,
  loadingSendMessage,
  closeConversation,
  openConversation,
  loadingCloseConversation,
  loadingOpenConversation,
  connectedUser,
  focus,
  notFocus,
  varFocus,
  conversationOpen,
  entryConversations,
  seenMessage,
  onUploadfileDiscussion,
  loadingUploadFile,
  errorUploadFile,
  filesUpload,
  removeFileDiscussion,
  downloadFile,
  setLoadingUploadFile,
  setErrorUploadFile,
  deleteFile,
}) {
  const [messages, setMessage] = useState([])
  const [DerMessages, setDerMessages] = useState({})
  const [salesChannelName, setSalesChannelName] = useState()
  const [ldv, setldv] = useState()
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    setMessage(messageList)
    setDerMessages(messageList[messageList.length - 1])
  }, [messageList])

  useEffect(() => {
    setSalesChannelName(conversationOpen?.salesChannel)
  }, [conversationOpen])

  useEffect(() => {
    setldv(conversationOpen?.ldv)
  }, [conversationOpen])

  useEffect(() => {
    if (!isLoadingGetMessageList) {
      scrollSmoothToBottom()
    }
  })
  //make the scroll bar scroll automatically
  const scrollSmoothToBottom = () => {
    var div = document.getElementById('scroll-bottom')
    $('#' + 'scroll-bottom').animate(
      {
        scrollTop: div?.scrollHeight - div?.clientHeight,
      },
      10
    )
  }

  return (
    <>
      <div
        style={{
          padding: '9px',
          display: 'flex',
          justifyContent: 'space-between',
          boxShadow: '0 1px 0px 0 rgb(0 0 0 / 10%)',
        }}
      >
        <div style={{ padding: '9px' }}>
          <div
            style={{
              color: '#196dd4',
              fontSize: '20px',
              marginBottom: '10px',
            }}
          >
            {salesChannelName}
          </div>
          <Chip
            sx={{
              '&.MuiChip-root': {
                color: '#fdfdff !important',
                backgroundColor: '#196dd4 !important',
                textDecoration: 'unset',
                cursor: 'pointer !important',
                '&:hover': {
                  color: 'black !important',
                  backgroundColor: '#196dd4 !important',
                },
              },
            }}
            label={ldv}
            value={'/deliveries/' + ldv}
            to={'/deliveries/' + ldv}
            component={Link}
          />
        </div>

        {!isLoadingGetMessageList && (
          <div style={{ padding: '10px' }}>
            {conversationOpen?.isOpen ? (
              <>
                {loadingCloseConversation ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Button
                    sx={{
                      '&.MuiButton-root': {
                        fontSize: '12px !important',
                        color: '#196dd4 !important',
                        textTransform: 'initial !important',
                        '&:hover': {
                          color: 'white !important',
                          backgroundColor: '#196dd4 !important',
                        },
                      },
                    }}
                    onClick={closeConversation}
                  >
                    Clôturer la discussion
                  </Button>
                )}
              </>
            ) : (
              <>
                {loadingOpenConversation ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Button
                    sx={{
                      '&.MuiButton-root': {
                        fontSize: '12px !important',
                        color: '#196dd4 !important',
                        textTransform: 'initial !important',
                        '&:hover': {
                          color: 'white !important',
                          backgroundColor: '#196dd4 !important',
                        },
                      },
                    }}
                    onClick={openConversation}
                  >
                    Rouvrir la discussion
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {isLoadingGetMessageList ? (
        <div style={{ height: '150px', padding: '14% 45%' }}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div style={{ height: 'calc(100vh - 262px)', width: '100%' }}>
          <div
            style={
              isSelected
                ? {
                    scrollBehavior: 'auto',
                    overflow: 'scroll',
                    overflowY: 'auto',
                    height: 'calc(100vh - 450px)',
                    width: '100%',
                  }
                : {
                    scrollBehavior: 'auto',
                    overflow: 'scroll',
                    overflowY: 'auto',
                    height: 'calc(100vh - 350px)',
                    width: '100%',
                  }
            }
            id="scroll-bottom"
          >
            {messages.map((message, index) => (
              <ChatItem
                key={index}
                message={message}
                connectedUser={connectedUser}
                varFocus={varFocus}
                conversationOpen={conversationOpen}
                entryConversations={entryConversations}
                DerMessages={DerMessages}
                seenMessage={seenMessage}
                downloadFile={downloadFile}
              />
            ))}
          </div>
          <InputMessage
            pushMessage={pushMessage}
            focus={focus}
            notFocus={notFocus}
            disabled={DerMessages?.type === EVENT_SOCKET.CLOSE_EVENT}
            loadingSendMessage={loadingSendMessage}
            setIsSelected={setIsSelected}
            isSelected={isSelected}
            onUploadfileDiscussion={onUploadfileDiscussion}
            loadingUploadFile={loadingUploadFile}
            errorUploadFile={errorUploadFile}
            filesUpload={filesUpload}
            removeFileDiscussion={removeFileDiscussion}
            setLoadingUploadFile={setLoadingUploadFile}
            setErrorUploadFile={setErrorUploadFile}
            deleteFile={deleteFile}
          />
        </div>
      )}
    </>
  )
}

ChatComponent.propTypes = {
  messageList: PropTypes.array,
  isLoadingGetMessageList: PropTypes.bool,
  pushMessage: PropTypes.func,
  loadingSendMessage: PropTypes.bool,
  closeConversation: PropTypes.func,
  openConversation: PropTypes.func,
  loadingCloseConversation: PropTypes.bool,
  loadingOpenConversation: PropTypes.bool,
  connectedUser: PropTypes.object,
  focus: PropTypes.func,
  notFocus: PropTypes.func,
  varFocus: PropTypes.bool,
  entryConversations: PropTypes.array,
  conversationOpen: PropTypes.string,
  seenMessage: PropTypes.func,
  onUploadfileDiscussion: PropTypes.func,
  loadingUploadFile: PropTypes.bool,
  errorUploadFile: PropTypes.any,
  filesUpload: PropTypes.array,
  removeFileDiscussion: PropTypes.func,
  downloadFile: PropTypes.func,
  setLoadingUploadFile: PropTypes.func,
  setErrorUploadFile: PropTypes.func,
  deleteFile: PropTypes.func,
}
