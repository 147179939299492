import VisibilityIcon from '@mui/icons-material/Visibility'
import React from 'react'
import Fab from '@mui/material/Fab'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import '../fab-button.scss'
import Assembly from './Assembly'
import { useTheme } from '@mui/material/styles'

export default function ActionIconsComponent({
  onClick,
  assemblies,
  index,
  modale,
}) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      {assemblies.length !== 0 && (
        <Assembly
          assembler={assemblies[0].assembler}
          clientPrice={assemblies[0].clientPrice}
          assemblyId={assemblies[0].id}
          startAt={assemblies[0].startAt}
          anchorEl={anchorEl}
          handleClick={(event) => setAnchorEl(event.currentTarget)}
          handleClose={handleClose}
          open={open}
        />
      )}
      <Tooltip
        title={<span className="tootltip-content">Consulter / Télécharger</span>}
        arrow
      >
        <Fab
          style={{ backgroundColor: 'transparent' }}
          aria-label="close"
          className="fab-button"
          onClick={onClick}
          id={
            !modale ? `Consulter-Cours-${index}` : `Consulter-Cours-Modale-${index}`
          }
        >
          <VisibilityIcon style={{ color: theme.palette.primary.main, width: 24 }} />
        </Fab>
      </Tooltip>
    </>
  )
}

ActionIconsComponent.propTypes = {
  onClick: PropTypes.func,
  assemblies: PropTypes.array,
  index: PropTypes.number,
  modale: PropTypes.bool,
}
