export const getMessageListState = ({ messageList }) => messageList

export const getMessageListError = (state) => getMessageListState(state).error
export const getMessageListLoading = (state) => getMessageListState(state).isLoading
export const getMessageListId = (state) => getMessageListState(state).id
export const getMessageListSubject = (state) => getMessageListState(state).subject
export const getMessageListSalesChannel = (state) =>
  getMessageListState(state).salesChannel
export const getMessageListConversationToUser = (state) =>
  getMessageListState(state).conversationToUser
export const getMessageListentries = (state) => getMessageListState(state).entries
export const getMessageLists = (state) => getMessageListState(state).messageList
export const getLoadingSendMessage = (state) =>
  getMessageListState(state).loadingSendMessage
export const getLoadingUploadFile = (state) =>
  getMessageListState(state).loadingUploadFile
export const getErrorUploadFile = (state) =>
  getMessageListState(state).errorUploadFile
export const getFilesUpload = (state) => getMessageListState(state).filesUpload
export const getErrorMessageUploadFile = (state) =>
  getMessageListState(state).errorMessageUploadFile
