import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      // fill="none"
      viewBox="0 0 20 20"
      style={{ marginRight: '10px' }}
    >
      <path
        fill="currentColor"
        d="M.227 0L0 .787l4.311 1.25.246.07 2.335 12.219c.158-.055.32-.101.493-.133a4.03 4.03 0 01.232-.032L5.176 1.39.227 0zm12.77 2.712L6.51 3.917 7.46 8.86l6.487-1.195-.949-4.953zm4.027 5.218l-2.102.39v.004L8.947 9.428l-1.335.248.856 4.49c.99.137 1.874.7 2.409 1.525l7.37-1.365-1.223-6.396zm2.841 6.932l-8.62 1.599a3.706 3.706 0 01.149.72l8.606-1.596-.135-.723zm-11.81.096c-.168 0-.34.014-.517.046-1.39.256-2.302 1.571-2.037 2.94.26 1.375 1.59 2.269 2.986 2.012 1.395-.256 2.301-1.567 2.041-2.941a2.545 2.545 0 00-2.474-2.057zm-.075.976a1.545 1.545 0 011.488 1.232c.153.811-.4 1.608-1.228 1.764-.827.151-1.632-.39-1.79-1.205a1.525 1.525 0 011.53-1.791zm-.01.82a.725.725 0 00-.139.013.68.68 0 00-.558.802c.07.38.432.623.814.55a.684.684 0 00.558-.802.691.691 0 00-.674-.563z"
      ></path>
    </svg>
  )
}

export default Icon
