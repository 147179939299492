import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'

export default function DialogTitle({ onChangeSearch }) {
  return (
    <div
      style={{
        display: 'flex',
        background: '#1976d2',
        padding: '12px',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          padding: '10px',
          color: 'white',
          fontSize: '16px',
          width: '60%',
          fontWeight: 'bold',
        }}
      >
        Choisir les equipages
      </div>
      <div
        id="recherche-choisir-les-equipages"
        style={{
          background: 'white',
          padding: '8px',
          borderRadius: '5px',
          width: '40%',
        }}
      >
        <TextField
          variant="standard"
          placeholder="Rechercher"
          onChange={(event) => {
            onChangeSearch(event.target.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  )
}
DialogTitle.propTypes = {
  onChangeSearch: PropTypes.string,
}
