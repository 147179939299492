import React from 'react'
import PropTypes from 'prop-types'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import moment from 'moment'
import Table from '@mui/material/Table'
import CircularProgress from '@mui/material/CircularProgress'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Dialog from '../../../../components/ModalDialog'
import { getfullName } from '../../../../utils/values'

export default function DialogHistorique({
  open,
  onClose,
  historiques,
  isLoadingHistoriquesFreights,
}) {
  return (
    <Dialog
      maxWidthDialog={'lg'}
      open={open}
      title={'Historique tournée de réception'}
      iconTitle={
        <InfoOutlinedIcon style={{ marginRight: '10px', marginTop: '3px ' }} />
      }
      content={
        <div style={{ paddingRight: '16px' }}>
          {isLoadingHistoriquesFreights ? (
            <div
              style={{
                padding: '20px',
                display: 'flex',
                verticalAlign: 'middle',
                minWidth: '300px',
                justifyContent: 'center',
              }}
            >
              <CircularProgress style={{ width: '30px', height: '30px' }} />
            </div>
          ) : (
            <>
              {historiques.length > 0 ? (
                <TableContainer>
                  <Table
                    sx={{
                      minWidth: 650,
                      marginBottom: '25px',
                    }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{ fontWeight: '700' }}>
                          {' '}
                          {'Date de création'}
                        </TableCell>
                        <TableCell align="left" style={{ fontWeight: '700' }}>
                          {'Collaborateur'}
                        </TableCell>

                        <TableCell align="left" style={{ fontWeight: '700' }}>
                          {'Phase'}
                        </TableCell>
                        <TableCell align="left" style={{ fontWeight: '700' }}>
                          {'Statut'}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historiques.map((historique, i) => (
                        <TableRow key={i}>
                          <TableCell align="left">
                            {historique?.createdAt
                              ? moment(historique.createdAt).format(
                                  'D MMM YYYY HH:mm'
                                )
                              : 'N/A'}
                          </TableCell>
                          <TableCell align="left">
                            {getfullName(historique?.collaborateur, 'N/A')}
                          </TableCell>

                          <TableCell align="left">
                            {' '}
                            {historique?.phase ? historique.phase : 'N/A'}
                          </TableCell>

                          <TableCell align="left">
                            {' '}
                            {historique?.status ? historique.status : 'N/A'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div
                  style={{
                    padding: '60px 100px',
                  }}
                >
                  {'Historique tournée de réception est vide'}
                </div>
              )}
            </>
          )}
        </div>
      }
      handleClose={onClose}
      isClosedIcon={true}
    />
  )
}
DialogHistorique.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  historiques: PropTypes.array,
  isLoadingHistoriquesFreights: PropTypes.bool,
}
