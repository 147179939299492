import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

export const StyledSwitch = styled(Switch)({
  '& .MuiSwitch-thumb': {
    color: '#F1F1F1',
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#C5C5C5',
  },
  '& .Mui-checked': {
    '& +.MuiSwitch-track': {
      backgroundColor: '#10A549 !important',
    },
  },
})
