import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store'
import * as serviceWorker from './serviceWorker'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { frFR } from '@mui/material/locale'
import { PersistGate } from 'redux-persist/integration/react'

// DateRangePicker range picker imports
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'flag-icon-css/css/flag-icon.min.css' // all country flags
// Test

import './index.css'
import App from './containers/common/App'
import ErrorBoundary from './containers/common/ErrorBoundary'
import { BOX2HOME_PRIMARY_COLOR, THEME_COLORS } from './utils/values'

const theme = createTheme(
  {
    palette: {
      primary: {
        main: BOX2HOME_PRIMARY_COLOR,
        secondary: '#00e676',
        background: '#f5f5f5',
      },
      statusColors: THEME_COLORS,
    },
  },
  frFR
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </ThemeProvider>
    </PersistGate>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
