import { connect } from 'react-redux'
import ShipmentSection from '../../../pages/main/PlaceOrders/Shipment'

import { getShipmentSalesChannelList } from '../../../redux/filter/selectors'

import { getRecommendedCreneau } from '../../../redux/operationalHours/actions'

import {
  getIsGetDirectionRouteLoading,
  getDirectionRoute,
  getDirectionRouteList,
} from '../../../redux/googleMapDirections/selectors'

import {
  getGoogleMapDirectionRequest,
  findDirectionAction,
  resetDirections,
} from '../../../redux/googleMapDirections/actions'

import {
  updateOrderShipmentAction,
  setShipmentErrorsAction,
  updateDirectionsAction,
  setOrderShipmentPriceAction,
  addAssemblyAction,
  removeAssemblyAction,
  removeOrderAction,
  estimateShipmentPriceAction,
  addOrderAction,
  getOneWeekShipments,
  getCanalVente,
  getRulesListRequest,
} from '../../../redux/shipment/actions'
import {
  getFamiliesArticleList,
  getCanalVenteLoading,
  getErrorGetSalesChannel,
  getCanalVenteResponse,
} from '../../../redux/shipment/selectors'
import { getRecommendedCreneauListState } from '../../../redux/operationalHours/selectors'
import { getSalesChannelListRequest } from '../../../redux/filter/actions'

const mapStateToProps = (state) => ({
  shipmentSalesChannelList: getShipmentSalesChannelList(state),
  isGetDirectionRouteLoading: getIsGetDirectionRouteLoading(state),
  directionRoute: getDirectionRoute(state),
  directionRouteList: getDirectionRouteList(state),
  recommendedCreneauList: getRecommendedCreneauListState(state),
  familiesArticleList: getFamiliesArticleList(state),
  canalVente: getCanalVenteResponse(state),
  isGetCanalVenteLoading: getCanalVenteLoading(state),
  errorGetSalesChannel: getErrorGetSalesChannel(state),
})
const mapDisptachToProps = (dispatch) => ({
  getGoogleMapDirection: (id, origin, destination) =>
    dispatch(getGoogleMapDirectionRequest(id, origin, destination)),
  resetDirections: () => dispatch(resetDirections()),
  addOrder: (type) => dispatch(addOrderAction(type)),

  findDirection: (id, origin, destination) =>
    dispatch(findDirectionAction(id, origin, destination)),
  setShipmentErrors: (orId, errors) =>
    dispatch(setShipmentErrorsAction(orId, errors)),
  removeOrder: (order) => dispatch(removeOrderAction(order)),
  updateDirections: (dirId, data) => dispatch(updateDirectionsAction(dirId, data)),
  setOrderShipmentPrice: (orId, price) =>
    dispatch(setOrderShipmentPriceAction(orId, price)),
  addAssembly: (orId) => dispatch(addAssemblyAction(orId)),
  removeAssembly: (orId) => dispatch(removeAssemblyAction(orId)),
  updateOrderShipment: (orId, shipment) =>
    dispatch(updateOrderShipmentAction(orId, shipment)),
  estimatePrice: (
    orId,
    selectedSalesChannel,
    direction,
    date,
    time,
    purchaseAmount,
    nbItems,
    weight,
    manutention,
    manutentionDouble,
    etage
  ) =>
    dispatch(
      estimateShipmentPriceAction(
        orId,
        selectedSalesChannel,
        direction,
        date,
        time,
        purchaseAmount,
        nbItems,
        weight,
        manutention,
        manutentionDouble,
        etage
      )
    ),

  getRecommendedCreneau: (date, codeCanal) =>
    dispatch(getRecommendedCreneau(date, codeCanal)),
  getOneWeekShipments: (salesChannelCode) =>
    dispatch(getOneWeekShipments(salesChannelCode)),
  getRulesList: () => dispatch(getRulesListRequest()),
  getSalesChannels: () => dispatch(getSalesChannelListRequest()),
  getCanalVente: (codeCanal) => dispatch(getCanalVente(codeCanal)),
})

export default connect(mapStateToProps, mapDisptachToProps)(ShipmentSection)
