import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="#1876D2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.813 9h12.374M10.125 3.938L15.188 9l-5.063 5.063"
      ></path>
    </svg>
  )
}

export default Icon
