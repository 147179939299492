import queryString from 'query-string'

import { httpFetch, httpPost, httpPut } from '../http/actions'
import {
  FETCH_CANAL_VENTES,
  FETCH_CANAL_VENTES_SUCCESS,
  FETCH_CANAL_VENTES_FAILURE,
  FETCH_CANAL_VENTES_MORE,
  FETCH_CANAL_VENTES_MORE_SUCCESS,
  FETCH_CANAL_VENTES_MORE_FAILURE,
  FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES,
  FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_SUCCESS,
  FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_FAILURE,
  CREATE_DISPONIBILITES,
  CREATE_DISPONIBILITES_SUCCESS,
  CREATE_DISPONIBILITES_FAILURE,
  UPDATE_DISPONIBILITES,
  UPDATE_DISPONIBILITES_SUCCESS,
  UPDATE_DISPONIBILITES_FAILURE,
} from './constants'

import { axiosHTTP } from '../../utils'
import { removeNull } from '../../utils/utils'

const api = process.env.REACT_APP_AVAILABILITY_MS_URL

let CancelToken = axiosHTTP.CancelToken
let cancel

export default (store) => (next) => (action) => {
  if (action.type === FETCH_CANAL_VENTES) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }

    const filter = removeNull(action.payload)
    store.dispatch(
      httpFetch({
        endPoint: `${api}api/v1/sales-channels/all?${queryString.stringify(filter)}`,
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: FETCH_CANAL_VENTES_SUCCESS,
        failure: FETCH_CANAL_VENTES_FAILURE,
      })
    )
  }

  if (action.type === FETCH_CANAL_VENTES_MORE) {
    // cancel the request
    if (cancel != undefined) {
      cancel()
    }
    const filter = removeNull(action.payload)
    store.dispatch(
      httpFetch({
        endPoint: `${api}api/v1/sales-channels/all?${queryString.stringify(filter)}`,
        cancelToken: new CancelToken(function executor(b) {
          // An executor function receives a cancel function as a parameter
          cancel = b
        }),
        success: FETCH_CANAL_VENTES_MORE_SUCCESS,
        failure: FETCH_CANAL_VENTES_MORE_FAILURE,
      })
    )
  }

  if (action.type === FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES) {
    const dataSend = removeNull(action.payload)
    store.dispatch(
      httpFetch({
        endPoint: `${api}api/v1/time-slots/all?${queryString.stringify(dataSend)}`,
        success: FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_SUCCESS,
        failure: FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_FAILURE,
      })
    )
  }

  if (action.type === CREATE_DISPONIBILITES) {
    const dataSend = { extendedPeriod: action.payload.extendedPeriod }

    store.dispatch(
      httpPost({
        endPoint: `${api}api/v1/time-slots/new?${queryString.stringify(dataSend)}`,
        data: action.payload.values,
        success: CREATE_DISPONIBILITES_SUCCESS,
        failure: CREATE_DISPONIBILITES_FAILURE,
      })
    )
  }

  if (action.type === UPDATE_DISPONIBILITES) {
    store.dispatch(
      httpPut({
        endPoint: `${api}api/v1/time-slots/list`,
        data: action.payload,
        success: UPDATE_DISPONIBILITES_SUCCESS,
        failure: UPDATE_DISPONIBILITES_FAILURE,
      })
    )
  }

  next(action)
}
