import { connect } from 'react-redux'
import OngletFilter from '../../../components/NavFilter/components/OngletFilter'
import {
  getFilterByCollaborateur,
  resetActionCreationFilter,
  resetActionDeleteFilter,
  setOngletFilterIsOpen,
  resetFiltersCollaborateur,
  saveSearchCornerOnglet,
} from '../../../redux/filter/actions'
import {
  getFiltersCollaborateur,
  getIsActionCreationFilter,
  getIsActionDeleteFilter,
  getIsActionModifierFilter,
  getIsLoadingGetFilters,
  getSearchCorner,
  getSearchCornerOnglet,
} from '../../../redux/filter/selectors'
import {
  initCheckCourse,
  refreshFetchOrder,
  refreshFetchOrderSearch,
  refreshFetchOrderSearchCorner,
} from '../../../redux/order/actions'
import { getCoursesChecked } from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  filtersCollaborateur: getFiltersCollaborateur(state),
  isActionCreationFilter: getIsActionCreationFilter(state),
  isActionDeleteFilter: getIsActionDeleteFilter(state),
  isActionModifierFilter: getIsActionModifierFilter(state),
  searchReducerCornerOnglet: getSearchCornerOnglet(state),
  searchReducerCorner: getSearchCorner(state),
  coursesChecked: getCoursesChecked(state),
  isLoadingGetFilters: getIsLoadingGetFilters(state),
})

const mapDisptachToProps = (dispatch) => ({
  getFilterByCollaborateur: (collaborateurId, pageFilter) =>
    dispatch(getFilterByCollaborateur(collaborateurId, pageFilter)),
  resetActionCreationFilter: () => dispatch(resetActionCreationFilter()),
  refreshFetchOrder: () => dispatch(refreshFetchOrder()),
  refreshFetchOrderSearch: (search) => dispatch(refreshFetchOrderSearch(search)),
  resetActionDeleteFilter: () => dispatch(resetActionDeleteFilter()),
  setOngletFilterIsOpen: (id) => dispatch(setOngletFilterIsOpen(id)),
  saveSearchCornerOnglet: (search) => dispatch(saveSearchCornerOnglet(search)),
  initCheckCourse: () => dispatch(initCheckCourse()),
  refreshFetchOrderSearchCorner: (search) =>
    dispatch(refreshFetchOrderSearchCorner(search)),
  resetFiltersCollaborateur: () => dispatch(resetFiltersCollaborateur()),
})

export default connect(mapStateToProps, mapDisptachToProps)(OngletFilter)
