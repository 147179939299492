import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

export const StyledListContainer = styled('div')(({ theme }) => ({
  width: 64,
  maxWidth: 360,
  backgroundColor: theme.palette.background.paper,
}))

export const LimitPaginationList = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '200',
    value: 200,
  },

  {
    label: '300',
    value: 300,
  },
  {
    label: '350',
    value: 350,
  },
]
const allOrders = [
  {
    label: 'Tous',
    value: 0,
  },
]

/** A component that contains a list of items to reproduce a list of number selection list */
export default function PageLimitList({ onSelect, selectedIndex, itemsTotal }) {
  const handleListItemClick = (event, index, value, label) => {
    onSelect(value, index, label)
  }
  const paginationLimitList =
    itemsTotal < 200 ? LimitPaginationList.concat(allOrders) : LimitPaginationList
  return (
    <StyledListContainer>
      <List component="nav" aria-label="secondary mailbox folder">
        {paginationLimitList.map((item, index) => (
          <ListItem
            button
            selected={selectedIndex === index}
            onClick={(event) =>
              handleListItemClick(event, index, item.value, item.label)
            }
            key={index}
            sx={{ paddingLeft: 0 }}
            disabled={item.value > itemsTotal}
          >
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </StyledListContainer>
  )
}
PageLimitList.propTypes = {
  /** The executed function when a list item is selected */
  onSelect: PropTypes.func,
  /** The actual selected index of the list item */
  selectedIndex: PropTypes.number,
  itemsTotal: PropTypes.number,
}
