import { LatLng } from './latlng'

export class MapOptions {
  constructor(centerLat, centerLng, zoom) {
    this.center = new LatLng(centerLat, centerLng)
    this.zoom = zoom
  }
  getCenter() {
    return this.center
  }
  getZoom() {
    return this.zoom
  }
}
