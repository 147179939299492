import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ArrayFilter from './cell/arrayFilter'
import SwitchFilter from './cell/switchFilter'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ActionButtons from '../../../ActionButton'
import DisplaySelection from '../../../../containers/main/NavFilter/DisplayGridSelection'
import CreationFilter from '../../../../containers/main/NavFilter/creationFilter'
import ModificationFilter from '../../../../containers/main/NavFilter/modificationFilter'
import { styled } from '@mui/material/styles'

export const StyledDivider = styled(Divider)(() => ({
  '&.MuiDivider-root': {
    margin: 'auto !important',
    width: '80%',
    height: '1px',
  },
}))

function Filter({
  toggleDrawer,
  dateFilterToday,
  statusFilter,
  driversFilter,
  salesChannelfilter,
  prestationFilter,
  agentsCommands,
  departmentFilter,
  wareHouseFilter,
  programerDateFilter,
  deliveryDateFilter,
  toursFilter,
  tourListFilter,
  dateFilterTomorrow,
  macroCanalFilter,
  filterReducer,
  setFilter,
  setError,
  onMyCommandsChanged,
  disableFilter,
  filterStateChanged,
  filtersValues,
  courseTypeFilter,
  savFilter,
  dateFilterCreation,
  BusinessOwnerFilter,
  resetFilter,
  deliveryDateFilterRange,
  DateFilterReception,
  ResponseCheck,
  ProcessingDepartment,
  subjectFilter,
  directionFilter,
  nature,
  verifFilterEqual,
  setHashValues,
  displaySelection,
  displayGrid,
  affResetButton,
  setVerifEqualFilter,
  setAffResetButton,
  modeCreation,
  setModeCreation,
  affCreationFilterEnregistrer,
  setAffCreationFilterEnregistrer,
  pageFilter,
  idOngletFilterOpen,
  courseLieeFilter,
  saveDefaultFilter,
  resetDefaultFilter,
  rdiFilter,
}) {
  const filterArray = [
    dateFilterToday,
    dateFilterTomorrow,
    deliveryDateFilter,
    deliveryDateFilterRange,
    dateFilterCreation,
    programerDateFilter,
    DateFilterReception,
    statusFilter,
    driversFilter,
    macroCanalFilter,
    salesChannelfilter,
    BusinessOwnerFilter,
    courseTypeFilter,
    rdiFilter,
    prestationFilter,
    departmentFilter,
    wareHouseFilter,
    tourListFilter,
    subjectFilter,
    ProcessingDepartment,
    nature,
  ]
  const filterSwitch = [
    courseLieeFilter,
    agentsCommands && displayGrid,
    toursFilter,
    savFilter,
    directionFilter,
    ResponseCheck,
  ]
  const filterContent = (filterTab) => {
    const notUndifined = filterTab.every((val) => val === undefined)
    return notUndifined
  }

  const [openDialogCreateFilter, setOpenDialogCreateFilter] = useState(false)
  const [openDialogModifierFilter, setOpenDialogModifierFilter] = useState(false)
  const handleAnnuleCreationFilter = () => {
    setHashValues({ ...filterReducer })

    setOpenDialogCreateFilter(false)
    setVerifEqualFilter(true)
    setAffResetButton(true)
    setAffCreationFilterEnregistrer(true)
    setModeCreation(false)
  }
  const onCancel = (event) => {
    handleAnnuleCreationFilter()
    toggleDrawer(false, event)
  }
  const onApply = (event) => {
    const filterUrlSring = Object.keys(filtersValues)
      .map((key) => key + '=' + filtersValues[key].join(','))
      .join('&')

    if (filterUrlSring.length > 2000) {
      setError()
    } else {
      setFilter(filtersValues, true)
      toggleDrawer(false, event)
    }
  }

  useEffect(() => {
    if (displayGrid) {
      setOpenDialogCreateFilter(false)
      setHashValues({ ...filterReducer })
      setVerifEqualFilter(true)
      setAffResetButton(true)
      setAffCreationFilterEnregistrer(true)
      setModeCreation(false)
    }
  }, [displayGrid])

  const onModifierFilter = () => {
    const filterUrlSring = Object.keys(filtersValues)
      .map((key) => key + '=' + filtersValues[key].join(','))
      .join('&')

    if (filterUrlSring.length > 2000) {
      setError()
    } else {
      setOpenDialogModifierFilter(true)
    }
  }
  return (
    <>
      <div
        style={{ width: 280 }}
        role="presentation"
        onKeyDown={(event) => toggleDrawer(null, event)}
        id="presentation"
      >
        <div style={{ paddingLeft: '16px', paddingTop: '16px' }}>
          <span style={{ fontSize: '13px', fontWeight: '700' }}>Filter par</span>
        </div>
        <div style={{ height: 'calc(100vh - 215px)', overflowY: 'auto' }}>
          <List>
            {filterArray.map(
              (item, index) =>
                item && (
                  <ArrayFilter
                    key={index}
                    index={index + 1}
                    disableFilter={disableFilter}
                    filterStateChanged={filterStateChanged}
                    filtersValues={filtersValues}
                  />
                )
            )}
          </List>
          {!filterContent(filterSwitch) && <StyledDivider />}
          <List>
            {filterSwitch.map(
              (item, index) =>
                item && (
                  <SwitchFilter
                    key={index}
                    index={index + 1}
                    onMyCommandsChanged={onMyCommandsChanged}
                    disableFilter={disableFilter}
                    filterStateChanged={filterStateChanged}
                    filtersValues={filtersValues}
                  />
                )
            )}
          </List>
          {displaySelection && !displayGrid && (
            <>
              <StyledDivider />
              <div style={{ padding: '15px 0px' }}>
                <div
                  style={{
                    padding: '0px 16px',
                    display: 'flex',
                  }}
                >
                  <span style={{ color: '#6D7B8E', alignSelf: 'center' }}>
                    {"Mode d'affichage: "}
                  </span>
                  <DisplaySelection
                    saveDefaultFilter={saveDefaultFilter}
                    resetDefaultFilter={resetDefaultFilter}
                  />
                </div>
              </div>
              <StyledDivider />
              {(verifFilterEqual || modeCreation) && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '15px',
                  }}
                >
                  {affCreationFilterEnregistrer &&
                    (!modeCreation ? (
                      <Button
                        id="Creation-Filter"
                        variant="contained"
                        onClick={() => {
                          setModeCreation(!modeCreation)

                          setHashValues({
                            status: ['TOUS'],
                            drivers: ['TOUS'],
                            salesChannels: ['TOUS'],
                            businessOwner: [],
                            courseType: ['TOUS'],
                            prestation: ['TOUS'],
                            warehouse: ['TOUS'],
                            tourList: ['TOUS'],
                            sort: ['dateDeCreation'],
                            orderBy: ['DESC'],
                            rdi: [],
                          })
                        }}
                        sx={{
                          height: '40px',
                          textTransform: 'none',
                          fontSize: '13px',
                          fontWeight: '400',
                          width: '153px',
                          backgroundColor: '#10A549',
                          ':hover': {
                            backgroundColor: '#158f44',
                          },
                        }}
                      >
                        {'Crée un filter'}
                      </Button>
                    ) : (
                      <Button
                        id="Annuler-Creation-Filter"
                        variant="contained"
                        onClick={handleAnnuleCreationFilter}
                        sx={{
                          height: '40px',
                          textTransform: 'none',
                          fontSize: '13px',
                          fontWeight: '400',
                          width: '153px',
                          backgroundColor: '#f61057',
                          ':hover': {
                            backgroundColor: '#d32f2f',
                          },
                        }}
                      >
                        {'Annuler la création'}
                      </Button>
                    ))}
                </div>
              )}
            </>
          )}
        </div>
        {modeCreation ? (
          !affCreationFilterEnregistrer && (
            <div
              style={{
                padding: '10px 20px',
                bottom: '0',
                position: 'fixed',
                width: '280px',
                background: 'white',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  paddingTop: '18px',
                }}
              >
                <Button
                  id={`Annuler-Filter-Corner`}
                  variant="contained"
                  onClick={handleAnnuleCreationFilter}
                  sx={{
                    width: '100px',
                    height: '40px',
                    textTransform: 'none',
                    fontSize: '13px',
                    fontWeight: '400',
                    backgroundColor: '#f61057',
                    ':hover': {
                      backgroundColor: '#d32f2f',
                    },
                  }}
                >
                  {'Annuler'}
                </Button>
                <Button
                  id={`Enregister-Filter-Corner`}
                  variant="contained"
                  onClick={() => {
                    setOpenDialogCreateFilter(true)
                  }}
                  sx={{
                    width: '100px',
                    height: '40px',
                    color: 'white',
                    textTransform: 'none',
                    fontSize: '13px',
                    fontWeight: '400',
                    backgroundColor: '#10A549',
                    '&:hover': {
                      backgroundColor: '#158f44',
                    },
                  }}
                >
                  {'Enregistrer'}
                </Button>
              </div>
            </div>
          )
        ) : !verifFilterEqual &&
          Number(localStorage.getItem('OngletOpened')) > 0 &&
          displaySelection &&
          !displayGrid ? (
          <div
            style={{
              padding: '10px 20px',
              bottom: '0',
              position: 'fixed',
              width: '280px',
              background: 'white',
            }}
          >
            <div
              style={{
                padding: '0px 13px',
              }}
            >
              <Button
                id="Annuler-Creation-Filter"
                variant="contained"
                onClick={onModifierFilter}
                sx={{
                  height: '40px',
                  textTransform: 'none',
                  fontSize: '13px',
                  fontWeight: '400',
                  width: '100%',
                  backgroundColor: 'white',
                  color: '#10A549',
                  boxShadow: 'none',
                  border: '1px solid #10A549',
                  ':hover': {
                    backgroundColor: 'white',
                    border: '1px solid #158f44',
                    color: '#158f44',
                    boxShadow: 'none',
                  },
                }}
              >
                {'Appliquer et enregistrer'}
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                paddingTop: '18px',
              }}
            >
              <Button
                id={`Annuler-Filter-Corner`}
                variant="contained"
                onClick={onCancel}
                sx={{
                  width: '100px',
                  height: '40px',
                  textTransform: 'none',
                  fontSize: '13px',
                  fontWeight: '400',
                  backgroundColor: '#f61057',
                  ':hover': {
                    backgroundColor: '#d32f2f',
                  },
                }}
              >
                {'Annuler'}
              </Button>
              <Button
                id={`Enregister-Filter-Corner`}
                variant="contained"
                onClick={onApply}
                sx={{
                  width: '100px',
                  height: '40px',
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '13px',
                  fontWeight: '400',
                  backgroundColor: '#10A549',
                  ':hover': {
                    backgroundColor: '#158f44',
                  },
                }}
              >
                {'Appliquer'}
              </Button>
            </div>
          </div>
        ) : !verifFilterEqual ? (
          <ActionButtons
            onApply={onApply}
            onCancel={onCancel}
            nameFilter={'Filter-Corner'}
          />
        ) : (
          !affResetButton && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '10px 20px',
                bottom: '0px',
                position: 'fixed',
                width: '280px',
                background: 'white',
              }}
            >
              <Button
                id="Reset"
                variant="contained"
                onClick={(event) => {
                  toggleDrawer(false, event)
                  resetFilter()
                  onMyCommandsChanged(false)
                }}
                sx={{
                  width: '110px',
                  height: '40px',
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '13px',
                  fontWeight: '400',
                  backgroundColor: '#f61057',
                  ':hover': {
                    backgroundColor: '#d32f2f',
                  },
                }}
              >
                {'Reset'}
              </Button>
            </div>
          )
        )}
      </div>
      {openDialogCreateFilter && (
        <CreationFilter
          open={openDialogCreateFilter}
          handleClose={handleAnnuleCreationFilter}
          filtersValues={filtersValues}
          pageFilter={pageFilter}
        />
      )}
      {openDialogModifierFilter && (
        <ModificationFilter
          open={openDialogModifierFilter}
          handleClose={() => {
            setOpenDialogModifierFilter(false)
          }}
          payload={{
            criteria: filtersValues,
            id: idOngletFilterOpen,
          }}
        />
      )}
    </>
  )
}
Filter.propTypes = {
  dateFilterToday: PropTypes.bool,
  dateFilterTomorrow: PropTypes.bool,
  statusFilter: PropTypes.bool,
  driversFilter: PropTypes.bool,
  salesChannelfilter: PropTypes.bool,
  agentsCommands: PropTypes.bool,
  prestationFilter: PropTypes.bool,
  wareHouseFilter: PropTypes.bool,
  macroCanalFilter: PropTypes.bool,
  departmentFilter: PropTypes.bool,
  programerDateFilter: PropTypes.bool,
  deliveryDateFilter: PropTypes.bool,
  toursFilter: PropTypes.any,
  tourListFilter: PropTypes.any,
  filterReducer: PropTypes.object,
  setFilter: PropTypes.func,
  setError: PropTypes.func,
  onMyCommandsChanged: PropTypes.func,
  disableFilter: PropTypes.bool,
  filterStateChanged: PropTypes.func,
  filtersValues: PropTypes.any,
  toggleDrawer: PropTypes.func,
  courseTypeFilter: PropTypes.bool,
  savFilter: PropTypes.bool,
  dateFilterCreation: PropTypes.bool,
  BusinessOwnerFilter: PropTypes.bool,
  resetFilter: PropTypes.func,
  deliveryDateFilterRange: PropTypes.bool,
  DateFilterReception: PropTypes.bool,
  ResponseCheck: PropTypes.bool,
  ProcessingDepartment: PropTypes.bool,
  nature: PropTypes.bool,
  subjectFilter: PropTypes.bool,
  directionFilter: PropTypes.bool,
  verifFilterEqual: PropTypes.bool,
  setHashValues: PropTypes.func,
  displaySelection: PropTypes.bool,
  displayGrid: PropTypes.bool,
  affResetButton: PropTypes.bool,
  setVerifEqualFilter: PropTypes.func,
  setAffResetButton: PropTypes.func,
  modeCreation: PropTypes.bool,
  setModeCreation: PropTypes.func,
  affCreationFilterEnregistrer: PropTypes.bool,
  setAffCreationFilterEnregistrer: PropTypes.func,
  pageFilter: PropTypes.string,
  idOngletFilterOpen: PropTypes.string,
  courseLieeFilter: PropTypes.bool,
  saveDefaultFilter: PropTypes.func,
  resetDefaultFilter: PropTypes.func,
  rdiFilter: PropTypes.bool,
}
export default Filter
