import React, { useEffect, useState } from 'react'
import GoogleMapContainer from '../../../../../../containers/main/GoogleMapContainer'
import PropTypes from 'prop-types'
import {
  getMarkercolor,
  GOOGLE_MAP_CONSTANTS,
} from '../../../../../../constants/googleMapConstants'
import './index.scss'
import AddressAutoComplete from '../../../../../../containers/main/AddressAutoComplete'
import PrimaryOutlineNoBorderButton from '../../../../../../components/Buttons/PrimaryOutlineNoBorderButton'
import MarkerTextInfo from './MarkerTextInfo'
import { useTheme } from '@mui/material/styles'
import { calculateCenter } from '../../../../../../utils/googleMapUtils'

export default function Dashboard({
  salesChannels,
  selectedShipment,
  getGoogleMapDirection,
  directionRoute,
  shipments,
  resetDirections,
}) {
  const theme = useTheme()
  const [markers, setMarkers] = useState([])
  const [selectedDirectionRoute, setDirectionRoute] = useState([])
  const [mapLoaded, setMapLoaded] = useState(false)
  const [mapOptions, setMapOption] = useState({
    center: {
      lat: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lat,
      lng: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lng,
    },
    zoom: 10,
  })
  useEffect(() => {
    return () => {
      removeDirection()
      resetDirections()
    }
  }, [])
  useEffect(() => {
    let directRoute = []
    if (directionRoute) {
      directRoute.push(directionRoute)
      setDirectionRoute(directRoute)
    } else {
      setDirectionRoute(null)
    }
  }, [directionRoute])
  useEffect(() => {
    calculateDirection(selectedShipment)
  }, [selectedShipment.origin, selectedShipment.destination, selectedShipment.id])
  useEffect(() => {
    initMarkers(10)
  }, [salesChannels, shipments])
  // map loaded
  const onMapLoaded = () => {
    setMapLoaded(true)
  }
  const initMarkers = (zoom) => {
    const canalsMarker = defaultCanalsMarker()
    const shipmentsMarker = defaultShipmentsMarker()
    if (shipments.length > 0) {
      setMapOption(calculateCenter(shipmentsMarker, 10, 0))
    } else {
      setMapOption({
        center: {
          lat: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lat,
          lng: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lng,
        },
        zoom,
      })
    }
    setMarkers(canalsMarker.concat(shipmentsMarker))
  }
  const defaultCanalsMarker = () => {
    let markersList = []
    if (salesChannels) {
      salesChannels.forEach(({ adresses }) => {
        if (adresses.length !== 0) {
          adresses.forEach(({ label, latitude, longitude }) => {
            if (latitude && longitude) {
              markersList.push({
                position: {
                  lat: latitude,
                  lng: longitude,
                },
                title: `Canal: ${label}`,
                infoText: (
                  <div
                    style={{ color: theme.palette.primary.main, fontWeight: 600 }}
                  >
                    Canal: {label}
                  </div>
                ),
                icon: GOOGLE_MAP_CONSTANTS.CANAL_ICON,
              })
            }
          })
        }
      })
    }

    return markersList
  }
  const defaultShipmentsMarker = () => {
    let markersList = []
    if (shipments) {
      shipments.forEach(
        ({
          adresseArrivee,
          adresseDepart,
          ldv,
          prix,
          salesChannel,
          status,
          deliveryHour,
          deliveryDate,
        }) => {
          if (adresseArrivee) {
            markersList.push({
              position: {
                lat: adresseArrivee.latitude,
                lng: adresseArrivee.longitude,
              },
              infoText: (
                <MarkerTextInfo
                  ldv={ldv}
                  prix={prix}
                  salesChannel={salesChannel}
                  startDate={deliveryDate}
                />
              ),
              ticket: deliveryHour,
              action: () =>
                calculateDirection({
                  origin: adresseDepart,
                  destination: adresseArrivee,
                  status,
                }),
              icon: getMarkercolor(status, mapLoaded),
            })
          }
        }
      )
    }

    return markersList
  }
  const handleAddAddress = (address) => {
    if (address) {
      let newMarker = [...markers]
      setMapOption({
        center: { lat: address.latlng.lat, lng: address.latlng.lng },
        zoom: 15,
      })
      setDirectionRoute([])
      const defaultMarkers = defaultCanalsMarker()
      if (newMarker.length !== defaultMarkers.length) {
        newMarker.pop()
      }
      newMarker.push({
        icon: GOOGLE_MAP_CONSTANTS.ADDRESS_ICON,
        position: {
          lat: address.latlng.lat,
          lng: address.latlng.lng,
        },
      })
      setMarkers(newMarker)
    }
  }
  const calculateDirection = (selecetedRoute) => {
    if (selecetedRoute) {
      if (selecetedRoute.origin && selecetedRoute.destination) {
        const { origin, destination, id } = selecetedRoute
        getGoogleMapDirection(
          id,
          { lat: origin.latitude, lng: origin.longitude },
          { lat: destination.latitude, lng: destination.longitude }
        )
      }
    }
  }
  const removeDirection = () => {
    initMarkers(9)
    setDirectionRoute([])
  }
  return (
    <div>
      <div className="dashboard-map-input">
        <AddressAutoComplete
          label="Chercher une adresse"
          handleQueryChange={(e) => console.log('address queryChange ', e)}
          handleOnAddressSelect={(address) => handleAddAddress(address)}
        />
        <PrimaryOutlineNoBorderButton
          onClick={() => removeDirection()}
          label="enlever la direction"
          disabled={!selectedDirectionRoute || selectedDirectionRoute.length === 0}
          style={{
            float: 'right',
            textTransform: 'none',
            fontWeight: 400,
            letterSpacing: '0.06em',
          }}
        />
      </div>
      <div className="dashboard-map-container">
        <GoogleMapContainer
          mapContainerStyle={{
            // minHeight: '500px',
            inWidth: 280,
            position: 'relative',
          }}
          mapId={'map-container'}
          handleMapLoad={onMapLoaded}
          mapOptions={mapOptions}
          markers={markers}
          directions={selectedDirectionRoute ? selectedDirectionRoute : []}
        />
      </div>
    </div>
  )
}
Dashboard.propTypes = {
  salesChannels: PropTypes.array,
  shipments: PropTypes.array,
  selectedShipment: PropTypes.any,
  getGoogleMapDirection: PropTypes.func,
  resetDirections: PropTypes.func,
  directionRoute: PropTypes.any,
}
