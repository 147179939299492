import {
  GET_PARTICIPANTS,
  ROLE_DRIVER_FILTRE_CHANGED,
  PAGE_CHANGED,
  PAGE_LIMIT_CHANGED,
  REMOVE_ERROR,
  RESET_SUCCESS_ACTION,
  RESET_CONFIG,
  SEARCH_PARTICIPANTS,
  ADD_PARTICIPANT,
  UPDATE_PARTICIPANT,
  GET_PARTICIPANTS_LIST_MORE,
  GET_PARTICIPANTS_CUSTOMIZED,
  GET_PARTICIPANTS_CUSTOMIZED_MORE,
  RESET_PARTICIPANTS_LIST,
  CHANGE_PARTICIPANTS_STATUS,
  SET_ISPARTICIPANT_FITRE_CHANGED,
  ANNULATION_PARTICIPATION,
} from './constants'

export const fetchParticipants = () => ({
  type: GET_PARTICIPANTS,
})

export const setIsParticipantFiltre = () => ({
  type: SET_ISPARTICIPANT_FITRE_CHANGED,
})

export const roleDriverFiltreChange = () => ({
  type: ROLE_DRIVER_FILTRE_CHANGED,
})

export const pageChanged = (offset) => ({
  type: PAGE_CHANGED,
  payload: { offset },
})

export const pageLimitChanged = (limit) => ({
  type: PAGE_LIMIT_CHANGED,
  payload: { limit },
})

export const getParticipantsMore = () => ({
  type: GET_PARTICIPANTS_LIST_MORE,
})

export const removeError = () => ({
  type: REMOVE_ERROR,
})

export const resetSuccessAction = () => ({
  type: RESET_SUCCESS_ACTION,
})

export const resetParticipantConfig = () => ({
  type: RESET_CONFIG,
})

export const onSearch = (search) => ({
  type: SEARCH_PARTICIPANTS,
  payload: { search },
})

export const createParticipant = (participant) => ({
  type: ADD_PARTICIPANT,
  payload: { participant },
})

export const updateParticipant = (payload) => ({
  type: UPDATE_PARTICIPANT,
  payload,
})

export const fetchParticipantsCustomizedFilter = (filter) => ({
  type: GET_PARTICIPANTS_CUSTOMIZED,
  payload: filter,
})

export const fetchParticipantsCustomizedFilterMore = (filter) => ({
  type: GET_PARTICIPANTS_CUSTOMIZED_MORE,
  payload: filter,
})

export const resetParticipantList = () => ({
  type: RESET_PARTICIPANTS_LIST,
})

export const changeParticipantsStatus = (ids) => ({
  type: CHANGE_PARTICIPANTS_STATUS,
  payload: { ids },
})

export const annulationParticipation = (payload) => ({
  type: ANNULATION_PARTICIPATION,
  payload,
})
