export default () => {
  return {
    canalVenteList: [],
    canalVentesCount: 0,
    isLoadingCanalVenteList: false,

    canalVenteDetails: null,
    isLoadingCanalVenteDetails: false,

    errorMessage: null,
    isErrorFetchCanalDetails: false,
    isErrorFetchCanalList: false,

    isLoadingActionDisponibilite: false,
    success: false,
  }
}
