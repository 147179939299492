export class Country {
  constructor(code, name) {
    this.code = code
    this.name = name
  }
  getCountry() {
    return new Country()
  }
  getCode() {
    return this.code
  }
  getName() {
    return this.name
  }
}
