import { connect } from 'react-redux'

import Participants from '../../../../pages/settings/sessions/detailsSession/components/participants'
import {
  fetchSessionsParticipants,
  updateSessionParticipant,
  resetSuccessAction,
  resetSessionConfig,
} from '../../../../redux/sessions/actions'
import {
  getSessionsParticipants,
  getIsLoadingSessionsParticipants,
  getisLoadingSessionAction,
  getSuccess,
} from '../../../../redux/sessions/selectors'

const mapStateToProps = (state) => ({
  sessionsParticipants: getSessionsParticipants(state),
  isLoadingSessionsParticipants: getIsLoadingSessionsParticipants(state),
  isLoadingSessionAction: getisLoadingSessionAction(state),
  success: getSuccess(state),
})

const mapDisptachToProps = (dispatch) => ({
  fetchSessionsParticipants: (id) => dispatch(fetchSessionsParticipants(id)),
  updateSessionParticipant: (sessionParticipant) =>
    dispatch(updateSessionParticipant(sessionParticipant)),
  resetSuccessAction: () => dispatch(resetSuccessAction()),
  resetSessionConfig: () => dispatch(resetSessionConfig()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Participants)
