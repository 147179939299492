import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      style={{ verticalAlign: 'sub' }}
    >
      <path
        stroke="#373D57"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.537 18.537V7.062L10.593 3.53 2.648 7.06v11.476"
      ></path>
      <path
        stroke="#373D57"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.71 11.476H6.179v7.061h8.827v-5.296H9.71"
      ></path>
      <path
        stroke="#373D57"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.71 18.537v-7.944a.883.883 0 01.883-.883h1.765a.883.883 0 01.883.883v2.648"
      ></path>
    </svg>
  )
}

export default Icon
