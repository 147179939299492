import React from 'react'

import PropTypes from 'prop-types'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

export default function ToggelButton({ value, disabled, onChange, items, labelId }) {
  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={onChange}
      aria-label="Platform"
      sx={{
        height: 20,
        marginLeft: '10px',
      }}
      disabled={disabled}
    >
      {items.map((item, index) => {
        return (
          <ToggleButton
            key={index}
            id={`${labelId}-${item.label}`}
            value={item.value}
          >
            {item.label}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}
ToggelButton.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  items: PropTypes.array,
  labelId: PropTypes.string,
}
