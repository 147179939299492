import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfosIcon from '../../../../../../assets/icons/InfosIcon'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row',
  padding: '0px 10px 0px 0',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: '9px',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0px 15px 0px 9px',
}))

export default function InformationLivraison({
  order,
  isLoading,
  getOrderQuestionsByLdv,
  orderQuestions,
  isLoadingOrderQuestions,
}) {
  const params = useParams()
  const [paramsInit, setParams] = useState(null)

  useEffect(() => {
    if (order?.code) {
      getOrderQuestionsByLdv(params.ldv)
      setParams(params)
    }
  }, [order?.code])

  useEffect(() => {
    if (paramsInit && paramsInit.ldv != params.ldv && order?.code) {
      setParams(params)
      getOrderQuestionsByLdv(params.ldv)
    }
  }, [params])

  return (
    <>
      {isLoading || isLoadingOrderQuestions ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        orderQuestions?.length > 0 && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#000000' }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <InfosIcon
                style={{
                  width: '18px',
                  height: '18px',
                  color: '#373D57',
                  marginRight: '5px',
                }}
              />
              <Typography sx={{ fontSize: '13px', fontWeight: '550' }}>
                Information d’accessibilité
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {orderQuestions.map((blockQuestion, i) => (
                <Box
                  key={i}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '15px 0px 10px 0px',
                    borderBottom: '1px solid #f5f5f5',
                    boxSizing: 'border-box',
                    '&:last-child': {
                      borderBottom: 0,
                    },
                  }}
                >
                  <Box component={'span'}>{blockQuestion.question}</Box>
                  <Box
                    component={'span'}
                    sx={{
                      marginLeft: '10px',
                      width: '140px',
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    {blockQuestion.response}
                  </Box>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        )
      )}
    </>
  )
}
InformationLivraison.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  getOrderQuestionsByLdv: PropTypes.func,
  orderQuestions: PropTypes.array,
  isLoadingOrderQuestions: PropTypes.bool,
}
