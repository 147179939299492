import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CardSyled,
  Accordion,
  AccordionSummary,
  TitleWithIcon,
  AccordionDetails,
} from './style'

const CardWithAccordion = ({ icon, title, action, content }) => {
  //Accordion
  const [expanded, setExpanded] = useState(false)
  const handleChange = (event) => {
    setExpanded(!expanded)
    event.stopPropagation()
  }

  return (
    <CardSyled>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <TitleWithIcon>
            {icon}
            {title}
          </TitleWithIcon>

          {expanded && action}
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    </CardSyled>
  )
}

CardWithAccordion.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  action: PropTypes.any,
  content: PropTypes.any,
}
export default CardWithAccordion
