import { connect } from 'react-redux'

import DisponibilitesModal from '../../../pages/settings/disponibilites/disponibilitesModal'
import {
  createDisponibilite,
  updateDisponibilite,
} from '../../../redux/disponibilites/actions'
import {
  getErrorMessage,
  getSuccess,
  isLoadingActionDisponibilite,
} from '../../../redux/disponibilites/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionDisponibilite: isLoadingActionDisponibilite(state),
  success: getSuccess(state),
  errorMessage: getErrorMessage(state),
})

const mapDisptachToProps = (dispatch) => ({
  createDisponibilite: (disponibilite) =>
    dispatch(createDisponibilite(disponibilite)),
  updateDisponibilite: (newDisponibilite) =>
    dispatch(updateDisponibilite(newDisponibilite)),
})

export default connect(mapStateToProps, mapDisptachToProps)(DisponibilitesModal)
