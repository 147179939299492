import React from 'react'

import './index.scss'
import ClientForm from '../../../../containers/main/EditOrders/ClientForm'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

export default function Client({
  updateClient,
  client,
  setClientErrors,
  order,
  setShipmentComplInfo,
  courseEditable,
  courseSemiEdit,
}) {
  return (
    <>
      <div className="client-form-container">
        <ClientForm
          selectedClient={client}
          newClient={false}
          updateClient={updateClient}
          isSubmited={client.isSubmitted}
          setClientErrors={setClientErrors}
        />
      </div>
      <div className="client-Information">
        <TextField
          id="observation"
          value={order[0]?.shipment?.destinationObservation || ''}
          name="destinationObservation"
          onChange={($event) => {
            setShipmentComplInfo(order[0]?.id, $event.target.value)
          }}
          label="Informations complémentaire d'arrivée"
          variant="outlined"
          multiline
          rows={4}
          disabled={!courseEditable && !courseSemiEdit}
          sx={{ background: 'white' }}
        />
      </div>
    </>
  )
}
Client.propTypes = {
  updateClient: PropTypes.func,
  client: PropTypes.object,
  setClientErrors: PropTypes.func,
  order: PropTypes.array,
  setShipmentComplInfo: PropTypes.func,
  courseEditable: PropTypes.bool,
  courseSemiEdit: PropTypes.bool,
}
