import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Badge from '@mui/material/Badge'
import InboxIcon from '@mui/icons-material/Inbox'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import emtyConversationIMG from '../../../../../../assets/icons/detailsLivraison/emptyconversations.svg'
import { NATURE_DISCUSSION_OBJECT } from '../../../../../../utils/values'
import { TitleWithIcon } from '../card/style'
export default function Converstion({
  conversations,
  onClickConversation,
  isLoadingGetConversations,
  count,
  offset,
  getMoreConversation,
  isLoadingConversationsMore,
}) {
  const theme = useTheme()

  const getNotification = (conversationItem) => {
    return conversationItem.notSeen
  }

  return (
    <>
      <TitleWithIcon>
        <InboxIcon
          style={{
            width: '20px',
            height: '20px',
            marginRight: '4px',
          }}
        />
        Fil de discussion
      </TitleWithIcon>
      {isLoadingGetConversations ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '20px',
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <div
          style={{
            height: '412px',
            width: '100%',
            marginTop: '10px',
          }}
        >
          {conversations.length > 0 ? (
            <>
              <List
                component="nav"
                sx={
                  offset < count
                    ? {
                        scrollBehavior: 'auto',
                        overflow: 'scroll',
                        overflowY: 'auto',
                        maxHeight: '93%',
                        width: '100%',
                      }
                    : {
                        scrollBehavior: 'auto',
                        overflow: 'scroll',
                        overflowY: 'auto',
                        maxHeight: '100%',
                        width: '100%',
                      }
                }
              >
                {conversations.length > 0 &&
                  conversations.map((conversation, index) => {
                    return (
                      <>
                        <ListItem
                          key={index}
                          button
                          onClick={() => onClickConversation(conversation)}
                          sx={{
                            flexDirection: 'column',
                            alignItems: 'flex-start !important',
                          }}
                        >
                          <div
                            style={{
                              paddingTop: '8px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100% ',
                              flexWrap: 'wrap',
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              <div
                                style={{
                                  fontSize: '16px',
                                  paddingBottom: '4px',
                                  paddingRight: '14px',
                                }}
                              >
                                {conversation?.salesChannel
                                  ? conversation.subject
                                  : 'Discussion'}
                              </div>
                              <Badge
                                badgeContent={getNotification(conversation)}
                                color="primary"
                              />
                            </div>
                            {conversation.nature && (
                              <div
                                style={{
                                  color: '#1976d2',
                                  backgroundColor: '#D2EAFE',
                                  borderRadius: '8px',
                                  alignSelf: 'center',
                                  padding: '3px',
                                }}
                              >
                                {NATURE_DISCUSSION_OBJECT[conversation.nature]}
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              color: 'grey',
                              fontSize: '12px',
                              paddingBottom: '4px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '100%',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {!conversation?.lastMessage
                              ? 'discussion vide'
                              : conversation.lastMessage.content}
                          </div>
                          <span
                            style={{
                              color: 'grey',
                              fontSize: '12px',
                              paddingBottom: '4px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              width: '100%',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {moment(conversation.lastActionTime).format(
                              'Do MMMM YYYY, h:mm:ss a'
                            )}
                          </span>
                        </ListItem>
                        <Divider />
                      </>
                    )
                  })}
              </List>
              {offset < count && (
                <div
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '15px',
                    fontSize: '15px',
                    color: '#1473e6',
                    cursor: 'pointer',
                    display: 'flex',
                  }}
                  onClick={getMoreConversation}
                >
                  {' '}
                  Voir plus{' '}
                  {isLoadingConversationsMore && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '20px',
                      }}
                    >
                      <div className="spinner-border" role="status">
                        <CircularProgress
                          style={{ color: theme.palette.primary.main }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div style={{ display: 'flex', height: '100%' }}>
              <img
                src={emtyConversationIMG}
                style={{ margin: 'auto', width: '100%', height: '100%' }}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}
Converstion.propTypes = {
  conversations: PropTypes.array,
  onClickConversation: PropTypes.func,
  isLoadingGetConversations: PropTypes.bool,
  count: PropTypes.number,
  offset: PropTypes.number,
  getMoreConversation: PropTypes.func,
  isLoadingConversationsMore: PropTypes.bool,
}
