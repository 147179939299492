import { validatorContants } from '../../../../utils/validator/constants'

export const shipmentValidators = {
  selectedSalesChannel: [validatorContants.REQUIRED, validatorContants.PATTERN],
  operationalHours: [validatorContants.REQUIRED],
  selectedOrigin: [validatorContants.REQUIRED, validatorContants.PATTERN],
  originQuery: [validatorContants.REQUIRED],
  selectedDestination: [validatorContants.REQUIRED, validatorContants.PATTERN],
  destinationQuery: [validatorContants.REQUIRED],
  direction: [validatorContants.REQUIRED, validatorContants.PATTERN],
  date: [validatorContants.REQUIRED, validatorContants.PATTERN],
  time: [validatorContants.REQUIRED, validatorContants.PATTERN],
  weight: [
    validatorContants.REQUIRED,
    validatorContants.PATTERN,
    { code: validatorContants.MIN, value: 0 },
  ],
  nbItems: [
    validatorContants.REQUIRED,
    validatorContants.PATTERN,
    { code: validatorContants.MIN, value: 1 },
    { code: validatorContants.MAX, value: 999 },
  ],
  purchaseAmount: [
    validatorContants.REQUIRED,
    validatorContants.PATTERN,
    { code: validatorContants.MIN, value: 0 },
  ],
  estimatedPrice: [validatorContants.REQUIRED, validatorContants.PATTERN],
  clientPrice: [
    validatorContants.REQUIRED,
    validatorContants.PATTERN,
    { code: validatorContants.MIN, value: 0 },
  ],
  providerPrice: [
    validatorContants.REQUIRED,
    validatorContants.PATTERN,
    { code: validatorContants.MIN, value: 0 },
  ],
  level: [validatorContants.REQUIRED],
  number: [validatorContants.REQUIRED],
  originMail: [validatorContants.PATTERN],
  // originPhone: [validatorContants.PATTERN],
  destinationMail: [validatorContants.PATTERN, validatorContants.REQUIRED],
  destinationPhone: [validatorContants.PATTERN, validatorContants.REQUIRED],
  /*  noteInterne: [{ code: validatorContants.MAX, value: 500 }],*/
  articleFamilies: [validatorContants.REQUIRED],
  factures: [validatorContants.REQUIRED],
  destinationFirstname: [validatorContants.REQUIRED],
  destinationLastname: [validatorContants.REQUIRED],
}
