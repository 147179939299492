import React from 'react'

export function MdiCubeOffOutline(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M22.11 21.46L2.39 1.73L1.11 3L4.3 6.19l-.77.43c-.32.17-.53.5-.53.88v9c0 .38.21.71.53.88l7.9 4.44c.16.12.36.18.57.18s.41-.06.57-.18l4.71-2.65l3.56 3.56l1.27-1.27M5 15.91v-6.7l5.29 2.97l.71.71v6.4l-6-3.38m8 3.38v-4.4l2.82 2.81L13 19.29M9 5.82L7.56 4.36l3.87-2.18c.16-.12.36-.18.57-.18s.41.06.57.18l7.9 4.44c.32.17.53.5.53.88v9c0 .35-.18.66-.47.83L19 15.8V9.21l-4.22 2.37l-1.47-1.47l4.65-2.61L12 4.15L9 5.82Z"
      ></path>
    </svg>
  )
}
export default MdiCubeOffOutline
