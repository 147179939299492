import { TableContainer } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TabConatainer = styled(TableContainer)({
  maxHeight: 'calc(100vh - 172px)',

  '&::-webkit-scrollbar': {
    width: '0.4em',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#E3EFFA',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
})
