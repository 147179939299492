import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
import { Link, useLocation } from 'react-router-dom'

import { styled } from '@mui/material/styles'

import ActionsProfile from '../ActionsProfile'
import b2hBlueIcon from '../../../assets/icons/settingsCorner/b2hBlue.png'
import b2hBlancIcon from '../../../assets/icons/settingsCorner/b2hBlanc.png'

import { Box, IconButton } from '@mui/material'
import MenuDrawer from './drawer'
import { getActionRole, isPathInRoutes } from '../../../utils/roleManagement'
import { settingsSidebarLinks } from '../../Sidebar/sidebarItemsData'

const StyledPaper = styled(Paper)(({ theme }) => ({
  '&.MuiPaper-root': {
    color: 'white',
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,

    height: '55px',
    placeContent: 'flex-end',
  },
}))

export default function NavBar({ userLogout, collaborateur, userRole }) {
  const [colorButtonCorner, setColorButtonCorner] = useState(false)
  const location = useLocation()

  return (
    <>
      {isPathInRoutes(settingsSidebarLinks, location.pathname, userRole) && (
        <MenuDrawer userLogout={userLogout} />
      )}
      <StyledPaper>
        <Box sx={{ display: 'flex' }}>
          {getActionRole(userRole, 'redirectSettingsMain') && (
            <Link
              to={'/commandes'}
              style={{ color: 'inherit', textDecoration: 'inherit' }}
            >
              <Box sx={{ padding: '8px 14px' }}>
                <IconButton
                  id={'button-settings-navbar'}
                  sx={{
                    color: '#1976d2',
                    backgroundColor: '#8dbbe7',
                    width: '40px',
                    height: '40px',
                    '&:hover': {
                      color: '#ffff',
                    },
                  }}
                  onMouseEnter={() => setColorButtonCorner(true)}
                  onMouseLeave={() => setColorButtonCorner(false)}
                >
                  {colorButtonCorner ? (
                    <img src={b2hBlancIcon} />
                  ) : (
                    <img src={b2hBlueIcon} />
                  )}
                </IconButton>
              </Box>
            </Link>
          )}
          <ActionsProfile userLogout={userLogout} collaborateur={collaborateur} />
        </Box>
      </StyledPaper>
    </>
  )
}
NavBar.propTypes = {
  collaborateur: PropTypes.object.isRequired,
  userLogout: PropTypes.func.isRequired,
  userRole: PropTypes.string,
}

function LinkTab(props) {
  const handleClick = (event) => {
    if (props.to === window.location.pathname) {
      event.preventDefault() // prevent reloading if the link points to the current page
    }
  }

  return <Tab {...props} onClick={handleClick} component={Link} />
}

LinkTab.propTypes = {
  to: PropTypes.string,
}
