import { connect } from 'react-redux'
import { getFiles, getIsLoadingFiles } from '../../../redux/formations/selectors'
import FilesModal from '../../../pages/settings/components/Modals/FilesModal'
import { fetchFileByUrl } from '../../../redux/formations/actions'

const mapStateToProps = (state) => ({
  isLoadingFiles: getIsLoadingFiles(state),
  files: getFiles(state),
})
const mapDisptachToProps = (dispatch) => ({
  fetchFileByUrl: (url) => {
    dispatch(fetchFileByUrl(url))
  },
})
export default connect(mapStateToProps, mapDisptachToProps)(FilesModal)
