import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import CircularProgress from '@mui/material/CircularProgress'
import modalRelatedLink from '../../../../assets/icons/modalRelatedLink.svg'
import ShipmentCell from '../Grid/Cell'

export default function RelatedCourseModal({
  open,
  handleClose,
  codeCourse,
  courseInfos,
  isLoadingRelatedCourse,
  getRelatedCourseInfo,
  index,
  modale,
  ldvRelatedCourse,
}) {
  useEffect(() => {
    if (codeCourse && ldvRelatedCourse !== null) {
      getRelatedCourseInfo(codeCourse)
    }
  }, [codeCourse])

  const renderContent = () => {
    if (ldvRelatedCourse !== null) {
      if (isLoadingRelatedCourse) {
        return (
          <div
            style={{
              height: '150px',
              width: '250px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              color="primary"
              style={{ width: '30px', height: '30px' }}
            />
          </div>
        )
      }

      return <ShipmentCell shipment={courseInfos} index={index} modale={modale} />
    }

    return (
      <div
        style={{
          height: '100px',
          width: '250px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span>La commande n’est pas encore disponible dans Corner</span>
      </div>
    )
  }

  return (
    <Dialog
      maxWidthDialog={'lg'}
      open={open}
      title={'Course associé'}
      iconTitle={
        <img
          src={modalRelatedLink}
          style={{ marginRight: '10px', marginTop: '3px' }}
        />
      }
      content={
        <div style={{ paddingRight: '16px', paddingBottom: '16px' }}>
          {renderContent()}
        </div>
      }
      handleClose={handleClose}
      isClosedIcon={true}
    />
  )
}
RelatedCourseModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  codeCourse: PropTypes.string,
  courseInfos: PropTypes.object,
  isLoadingRelatedCourse: PropTypes.bool,
  getRelatedCourseInfo: PropTypes.func,
  index: PropTypes.number,
  modale: PropTypes.bool,
  ldvRelatedCourse: PropTypes.string,
}
