import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ALPHABETIC_DATE_FORMAT,
  getCreneauDetails,
} from '../../../../../../utils/utils'
import { ClientPickup } from '../../../../../../utils/values'
import moment from 'moment'
import DateLivraisonIcon from '../../../../../../assets/icons/detailsLivraison/iconeDateLivraison'
import DateButoirIcon from '../../../../../../assets/icons/detailsLivraison/iconeDateButoir'
import DatePoseIcon from '../../../../../../assets/icons/detailsLivraison/iconeDatepose'
import CircularProgress from '@mui/material/CircularProgress'
import { CardSyled, TitleWithIcon } from '../card/style'
import DateUsineIcon from '../../../../../../assets/icons/detailsLivraison/dateUsineIcon'
export default function DateLivraison({
  order,
  isLoadingPackageOrder,
  receptionDatePackage,
}) {
  const [Date, setDate] = useState(null)
  const [title, setTitle] = useState('Date')

  useEffect(() => {
    if (order) {
      const {
        dropOffEnd,
        dropOffStart,
        dateDemarrage,
        dateDemarrageMeta,
        courseMetadata,
        pickUpEnd,
        pickUpStart,
      } = order
      const courseType = courseMetadata?.courseType

      if (ClientPickup.includes(courseType)) {
        const pickupDate = getCreneauDetails(
          pickUpStart,
          pickUpEnd,
          null,
          null,
          ALPHABETIC_DATE_FORMAT
        )
        setDate(pickupDate)
        setTitle('Date de retrait :')
      } else {
        const deliveryDate = getCreneauDetails(
          dropOffStart,
          dropOffEnd,
          dateDemarrageMeta,
          dateDemarrage,
          ALPHABETIC_DATE_FORMAT
        )
        setDate(deliveryDate)
        setTitle('Date de livraison : ')
      }
    }
  }, [order])

  return (
    <>
      {(Date ||
        order?.courseMetadata?.latestDeliveryDate ||
        order?.courseMetadata?.kitchenInstallationDate) && (
        <CardSyled>
          {Date && (
            <>
              <TitleWithIcon>
                <DateLivraisonIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '4px',
                  }}
                />
                {title}
              </TitleWithIcon>
              <div
                style={{
                  backgroundColor: '#EAF4FD',
                  borderRadius: '8px',
                  width: '60%',
                  marginLeft: '18%',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                <div
                  style={{
                    padding: '15px',
                    fontSize: '25px',
                  }}
                >{`Le ${Date.date}`}</div>
                <div
                  style={{ fontSize: '25px', paddingBottom: '15px' }}
                >{`${Date.heure}`}</div>
              </div>
            </>
          )}

          {order?.courseMetadata?.latestDeliveryDate && (
            <div style={{ display: 'flex', marginTop: '5px' }}>
              <TitleWithIcon>
                <DateButoirIcon
                  style={{ marginRight: '4px', width: '20px', height: '20px' }}
                />
                {'Date Butoir : '}
              </TitleWithIcon>
              <span style={{ padding: '11px' }}>
                {moment(order.courseMetadata.latestDeliveryDate).format(
                  'YYYY-MM-DD HH:mm'
                )}
              </span>
            </div>
          )}
          {order?.courseMetadata?.kitchenInstallationDate && (
            <div style={{ display: 'flex', marginTop: '5px' }}>
              <TitleWithIcon>
                <DatePoseIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    marginRight: '4px',
                  }}
                />
                {'Date de pose : '}
              </TitleWithIcon>
              <span style={{ padding: '11px' }}>
                {moment(order.courseMetadata.kitchenInstallationDate).format(
                  'YYYY-MM-DD HH:mm'
                )}
              </span>
            </div>
          )}

          {isLoadingPackageOrder ? (
            <div
              style={{ display: 'flex', marginTop: '9px', justifyContent: 'center' }}
            >
              <CircularProgress style={{ width: '30px', height: '30px' }} />
            </div>
          ) : (
            (order?.courseMetadata?.receptionDate || receptionDatePackage) && (
              <div style={{ display: 'flex', marginTop: '5px', flexFlow: 'wrap' }}>
                <TitleWithIcon>
                  <DateUsineIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '4px',
                    }}
                  />
                  {'Date départ usine : '}
                </TitleWithIcon>
                <span style={{ padding: '11px' }}>
                  {moment(
                    order?.courseMetadata?.receptionDate
                      ? order.courseMetadata.receptionDate
                      : receptionDatePackage
                  ).format('YYYY-MM-DD HH:mm')}
                </span>
              </div>
            )
          )}
        </CardSyled>
      )}
    </>
  )
}
DateLivraison.propTypes = {
  order: PropTypes.object,
  receptionDatePackage: PropTypes.array,
  isLoadingPackageOrder: PropTypes.bool,
}
