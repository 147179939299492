import React, { useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { TabConatainer } from './styled'

import {
  Box,
  CircularProgress,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  TableCell,
  Table,
  TableBody,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditIcon from '@mui/icons-material/Edit'
import PlaceIcon from '@mui/icons-material/Place'

import { DisponibiliteTableHead } from '../components/Table/data'
import EmptyTable from '../../../components/EmptyTable/EmptyTable'
import { DAYS_OF_WEEK_INDEX } from '../../../utils/values'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Dialog from '../../../components/ModalDialog'

const TableDisponibilites = ({
  canalVenteDetails,
  isLoadingCanalVenteDetails,
  setOpenDialog,
  isCreate,
  canalVenteZones,
}) => {
  const [openDialogInfoZones, setOpenDialogInfoZones] = useState(false)

  return (
    <>
      <TabConatainer component={'div'}>
        <Table
          sx={{ backgroundColor: 'white' }}
          stickyHeader
          aria-label="sticky table"
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <EnhancedTableHead
            tableHead={DisponibiliteTableHead.filter((colonne) =>
              colonne.visible.includes('table')
            )}
            setOpen={setOpenDialog}
            globalID={'disponibilite'}
            isCreate={isCreate}
            isLoadingCanalVenteDetails={isLoadingCanalVenteDetails}
            setOpenDialogInfoZones={setOpenDialogInfoZones}
          />

          {isLoadingCanalVenteDetails ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={
                    DisponibiliteTableHead.filter((colonne) =>
                      colonne.visible.includes('table')
                    ).length + 1
                  }
                  sx={{ padding: '8px', border: 'none' }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress sx={{ color: '#1976D2' }} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : canalVenteDetails?.length > 0 ? (
            <TableBody
              sx={{
                '& tr': {
                  '& th:first-of-type': {
                    borderLeft: '1px solid #EBEDF0',
                  },
                },
                '& tr:nth-of-type(2)': {
                  '& th': {
                    borderTop: '1px solid #EBEDF0',
                  },
                },
                '& tr:last-child': {
                  '& th:first-of-type': {
                    borderBottomLeftRadius: '4px',
                  },
                },
              }}
            >
              {canalVenteDetails.map((row, rowIndex) => {
                const labelId = `enhanced-table-${rowIndex}`

                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {DisponibiliteTableHead.filter((colonne) =>
                      colonne.visible.includes('table')
                    ).map((col, index) => (
                      <TableCell
                        key={index}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normale"
                        sx={{ color: '#6D7B8E' }}
                      >
                        {col.id === 'day' ? (
                          DAYS_OF_WEEK_INDEX[row[col.id]].label
                        ) : col.id === 'creneaux' ? (
                          `${moment(row.start).format('HH:mm')} - ${moment(
                            row.end
                          ).format('HH:mm')}`
                        ) : col.id === 'spanRestants' ? (
                          row.spanCounts - row.spanReserved
                        ) : col.id === 'zones' ? (
                          row?.zones?.length > 0 ? (
                            <Box sx={{ display: 'flex' }}>
                              {row.zones.map((zone, i) => (
                                <Box
                                  key={i}
                                  sx={{
                                    display: 'flex',
                                    paddingRight: '10px',
                                  }}
                                >
                                  <PlaceIcon sx={{ color: '#54865E' }} />
                                  <Box
                                    sx={{
                                      color: '#6D7B8E',
                                      paddingTop: '3px',
                                    }}
                                  >
                                    {canalVenteZones.find(
                                      (canalVenteZone) =>
                                        canalVenteZone.keyReference == zone
                                    )?.zoneLabel || 'N/A'}
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          ) : (
                            'N/A'
                          )
                        ) : row[col.id] != undefined || row[col.id] != null ? (
                          row[col.id]
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                    ))}

                    <TableCell
                      align="right"
                      sx={{
                        borderRight: '1px solid #EBEDF0',

                        ...(rowIndex === 0 && {
                          borderTop: '1px solid #EBEDF0',
                        }),
                        ...(rowIndex === canalVenteDetails?.length - 1 && {
                          borderBottomRightRadius: '4px',
                        }),
                      }}
                    ></TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          ) : (
            <EmptyTable
              colspan={
                DisponibiliteTableHead.filter((colonne) =>
                  colonne.visible.includes('table')
                ).length + 1
              }
              message={'Pas de disponibilités pour ce canal'}
            />
          )}
        </Table>
      </TabConatainer>
      {openDialogInfoZones && (
        <Dialog
          maxWidthDialog={'md'}
          open={openDialogInfoZones}
          title={'Code postaux par zone'}
          iconTitle={<InfoOutlinedIcon sx={{ marginRight: '10px' }} />}
          content={
            <Box
              sx={{
                minWidth: '300px',
                display: 'flex',
                margin: '0px 16px 16px 0px',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {canalVenteZones.map((zone, indexZone) => (
                  <Box
                    key={indexZone}
                    sx={{ display: 'flex', paddingBottom: '5px' }}
                  >
                    <Box
                      sx={{ minWidth: '60px', fontWeight: 700 }}
                    >{`${zone.zoneLabel} : `}</Box>
                    <Box sx={{ display: 'flex' }}>{zone.zipCodes.join(', ')}</Box>
                  </Box>
                ))}
              </Box>
            </Box>
          }
          handleClose={() => setOpenDialogInfoZones(false)}
          isClosedIcon={true}
        />
      )}
    </>
  )
}
TableDisponibilites.propTypes = {
  canalVenteDetails: PropTypes.array,
  isLoadingCanalVenteDetails: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  isCreate: PropTypes.bool,
  canalVenteZones: PropTypes.array,
}
export default TableDisponibilites

const EnhancedTableHead = ({
  tableHead,
  setOpen,
  globalID,
  isCreate,
  isLoadingCanalVenteDetails,
  setOpenDialogInfoZones,
}) => {
  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sx={{
              fontWeight: '600',
              fontSize: '12px',
              borderColor: '#F0F0F0',
              background: '#E3EFFA',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {headCell.label}
              {headCell.id == 'zones' && (
                <IconButton
                  aria-label="infos"
                  sx={{ color: 'black' }}
                  onClick={setOpenDialogInfoZones}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </TableCell>
        ))}
        <Tooltip
          title={
            <span className="tootltip-content">
              {isCreate ? 'Ajouter' : 'Modifier'}
            </span>
          }
          arrow
        >
          <TableCell
            sx={{
              position: 'sticky',
              right: '0',
              top: '0',
              borderColor: '#F0F0F0',

              background: '#E3EFFA',
            }}
            align={'right'}
            padding={'normal'}
          >
            {!isLoadingCanalVenteDetails &&
              (isCreate ? (
                <IconButton id={`Ajoute-${globalID}`} onClick={() => setOpen(true)}>
                  <AddCircleIcon sx={{ fontSize: 25 }} color="primary" />
                </IconButton>
              ) : (
                <IconButton id={`Ajoute-${globalID}`} onClick={() => setOpen(true)}>
                  <EditIcon sx={{ fontSize: 25 }} color="primary" />
                </IconButton>
              ))}
          </TableCell>
        </Tooltip>
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  tableHead: PropTypes.array,
  setOpen: PropTypes.func,
  globalID: PropTypes.string,
  isCreate: PropTypes.bool,
  isLoadingCanalVenteDetails: PropTypes.bool,
  setOpenDialogInfoZones: PropTypes.func,
}
