import React, { useEffect } from 'react'
import Dialog from '../ModalDialog'
import DialogContentText from '@mui/material/DialogContentText'
import PropTypes from 'prop-types'
export default function Deprogram({
  open,
  handleClose,
  handleDeprogramClick,
  payload,
  isLoadingActionOrder,
  errorActionCourse,
}) {
  const deprogramme = () => {
    handleDeprogramClick(payload)
  }

  useEffect(() => {
    if (errorActionCourse) handleClose()
  }, [errorActionCourse])

  return (
    <Dialog
      maxWidthDialog={'sm'}
      open={open}
      title={'Déprogrammation'}
      content={
        <DialogContentText sx={{ pr: 2, pb: 2 }}>
          {
            'La livraison va repasser en statut d’approvisionnement. Elle pourra être reprogrammée plus tard.'
          }
        </DialogContentText>
      }
      handleClose={handleClose}
      handleClickAction={deprogramme}
      isLoadingButtonAction={isLoadingActionOrder}
    />
  )
}
Deprogram.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDeprogramClick: PropTypes.func,
  payload: PropTypes.object,
  isLoadingActionOrder: PropTypes.bool,
  errorActionCourse: PropTypes.string,
}
