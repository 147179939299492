import React from 'react'

function PoidsEnlevement({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      data-name="Calque 1"
      viewBox="0 0 32 32"
    >
      <path
        fill={color}
        d="M28.82 26.44l-2.51-14.67a1.32 1.32 0 00-1.31-1.1h-4.63a5.33 5.33 0 10-9.24 0H6.51a1.34 1.34 0 00-1.32 1.11l-2.5 14.66A1.32 1.32 0 003.78 28h23.73a1.34 1.34 0 001.33-1.33 1.79 1.79 0 00-.02-.23zM15.75 5.33A2.67 2.67 0 1113.09 8a2.67 2.67 0 012.66-2.67zm0 21.45L9 20h4.17v-6.9h5.18V20h4.18z"
      ></path>
    </svg>
  )
}

export default PoidsEnlevement
