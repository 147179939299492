export const GET_SESSIONS = 'GET_SESSIONS'

export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS'
export const GET_SESSIONS_FAILURE = 'GET_SESSIONS_FAILURE'

export const PAGE_LIMIT_CHANGED = 'PAGE_LIMIT_CHANGED'
export const PAGE_CHANGED = 'PAGE_CHANGED'

export const REMOVE_ERROR = 'REMOVE_ERROR'
export const RESET_SUCCESS_ACTION = 'RESET_SUCCESS_ACTION'
export const RESET_CONFIG_SESSION = 'RESET_CONFIG_SESSION'

export const SEARCH_SESSIONS = 'SEARCH_SESSIONS'

export const ADD_SESSION = 'ADD_SESSION'
export const ADD_SESSION_SUCCESS = 'ADD_SESSION_SUCCESS'
export const ADD_SESSION_FAILURE = 'ADD_SESSION_FAILURE'

export const UPDATE_SESSION = 'UPDATE_SESSION'
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS'
export const UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE'

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'

export const GET_SESSION_BY_ID = 'GET_SESSION_BY_ID'
export const GET_SESSION_BY_ID_SUCCESS = 'GET_SESSION_BY_ID_SUCCESS'
export const GET_SESSION_BY_ID_FAILURE = 'GET_SESSION_BY_ID_FAILURE'
export const FETCH_SESSION_DETAILS = 'FETCH_SESSION_DETAILS'
export const FETCH_SESSION_DETAILS_SUCCESS = 'FETCH_SESSION_DETAILS_SUCCESS'
export const FETCH_SESSION_DETAILS_FAILURE = 'FETCH_SESSION_DETAILS_FAILURE'

export const FETCH_SESSION_PARTICIPANTS = 'FETCH_SESSION_PARTICIPANTS'
export const FETCH_SESSION_PARTICIPANTS_SUCCESS =
  'FETCH_SESSION_PARTICIPANTS_SUCCESS'
export const FETCH_SESSION_PARTICIPANTS_FAILURE =
  'FETCH_SESSION_PARTICIPANTS_FAILURE'
export const SESSION_PARTICIPANT_UPDATE = 'SESSION_PARTICIPANT_UPDATE'
export const SESSION_PARTICIPANT_UPDATE_SUCCESS =
  'SESSION_PARTICIPANT_UPDATE_SUCCESS'
export const SESSION_PARTICIPANT_UPDATE_FAILURE =
  'SESSION_PARTICIPANT_UPDATE_FAILURE'

export const SESSION_DELETE = 'SESSION_DELETE'
export const SESSION_DELETE_SUCCESS = 'SESSION_DELETE_SUCCESS'
export const SESSION_DELETE_FAILURE = 'SESSION_DELETE_FAILURE'
