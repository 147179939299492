import { connect } from 'react-redux'
import TourneesImportation from '../../../components/NavBar/main/ActionsMenu/tourneesImportation'

import {
  importerCourses,
  resetActionImportCourses,
} from '../../../redux/order/actions'
import {
  getErrorImportCourses,
  getIsActionImportCourses,
  getIsLoadingActionImportCourses,
} from '../../../redux/order/selectors'

const mapStateToProps = (state) => ({
  isLoadingActionImporterFile: getIsLoadingActionImportCourses(state),
  errorImportCourses: getErrorImportCourses(state),
  isActionImportCourses: getIsActionImportCourses(state),
})
const mapDisptachToProps = (dispatch) => ({
  importerCourses: (payload, macroCanalCode) =>
    dispatch(importerCourses(payload, macroCanalCode)),
  resetActionImportCourses: () => dispatch(resetActionImportCourses()),
})

export default connect(mapStateToProps, mapDisptachToProps)(TourneesImportation)
