import React from 'react'
import PropTypes from 'prop-types'

import ToggelButton from '../../../../../ToggelButton'

export default function CourseLieeFilter({
  filterStateChanged,
  courseLieeFiltersValues,
  disabled,
}) {
  const onChecked = (event, newAlignment) => {
    filterStateChanged('deliveryRelated', newAlignment ? [newAlignment] : [])
  }

  const items = [
    { label: 'Oui', value: 'true' },
    { label: 'Non', value: 'false' },
  ]

  return (
    <div
      style={{
        padding: '16px',
      }}
    >
      <span style={{ color: '#6D7B8E', verticalAlign: 'middle' }}>
        {'Courses Liées: '}
      </span>
      <ToggelButton
        value={courseLieeFiltersValues && courseLieeFiltersValues[0]}
        onChange={onChecked}
        items={items}
        disabled={disabled}
        labelId={'COURSE-LIEE'}
      />
    </div>
  )
}
CourseLieeFilter.propTypes = {
  filterStateChanged: PropTypes.func,
  courseLieeFiltersValues: PropTypes.array,
  disabled: PropTypes.bool,
}
