import { connect } from 'react-redux'
import {
  fetchDelayedOrders,
  getOrderByFilter,
  fetchSearchCornerSave,
  resetOrders,
} from '../../redux/order/actions'
import {
  getIsLoading,
  getError,
  getDelayedOrders,
  getDashboardOrders,
} from '../../redux/order/selectors'

import {
  getLimit,
  getPagesCount,
  getCount,
  getDefaultFilterTableauBord,
  getSearchCorner,
} from '../../redux/filter/selectors'

import Dashboard from '../../pages/main/Dashboard'
import { getDrivers, getDriversIsLoading } from '../../redux/driver/selectors'
import { fetchtDrivers, searchDriver } from '../../redux/driver/actions'
import { getDirectionRoute } from '../../redux/googleMapDirections/selectors'
import {
  getGoogleMapDirectionRequest,
  resetDirections,
} from '../../redux/googleMapDirections/actions'
import {
  getSalesChannelList,
  getIsGetSalesChannelListLoading,
} from '../../redux/filter/selectors'
import { getfilterTableauBord, getfilterError } from '../../redux/filter/selectors'
import {
  setFilterTableauBord,
  removeErrorFilter,
  resetFilterTableauBord,
  pageChanged,
  pageLimitChanged,
  getSalesChannelListRequest,
} from '../../redux/filter/actions'

const mapStateToProps = (state) => ({
  filterReducer: getfilterTableauBord(state),
  shipments: getDashboardOrders(state),
  isLoading: getIsLoading(state),
  error: getError(state),
  count: getCount(state),
  pageCount: getPagesCount(state),
  limit: getLimit(state),
  drivers: getDrivers(state),
  delayedShipments: getDelayedOrders(state),
  salesChannels: getSalesChannelList(state),
  directionRoute: getDirectionRoute(state),
  driversIsLoading: getDriversIsLoading(state),
  isLoadingSalesChannel: getIsGetSalesChannelListLoading(state),
  errorFilter: getfilterError(state),
  searchReducer: getSearchCorner(state),
  defaultFilterTableauBord: getDefaultFilterTableauBord(state),
})
const mapDisptachToProps = (dispatch) => ({
  setFilter: (filter, fromAppliquer) =>
    dispatch(setFilterTableauBord(filter, fromAppliquer)),
  getDriversByFilter: (actif) => dispatch(fetchtDrivers(actif)),
  searchDriver: (query, actif) => {
    dispatch(searchDriver(query, actif))
  },
  getDelayedShipments: () => dispatch(fetchDelayedOrders()),
  getSalesChannels: () => dispatch(getSalesChannelListRequest()),
  getGoogleMapDirection: (id, origin, destination) =>
    dispatch(getGoogleMapDirectionRequest(id, origin, destination)),
  resetDirections: () => dispatch(resetDirections()),
  pageChanged: (offset, search, searchCorner) => {
    dispatch(pageChanged(offset))
    search
      ? dispatch(fetchSearchCornerSave(searchCorner))
      : dispatch(getOrderByFilter())
  },
  pageLimitChanged: (limit, search, searchCorner) => {
    dispatch(pageLimitChanged(limit))
    search
      ? dispatch(fetchSearchCornerSave(searchCorner))
      : dispatch(getOrderByFilter())
  },
  removeErrorFilter: () => dispatch(removeErrorFilter()),
  resetFilterTableauBord: () => dispatch(resetFilterTableauBord()),
  resetShipments: () => dispatch(resetOrders()),
})

export default connect(mapStateToProps, mapDisptachToProps)(Dashboard)
