import { OrderPackageConstants } from './constants'
import { OrderInitialState as initialState } from './initialState'

import onFailure from '../OnFailure'
export default (state = initialState, action) => {
  switch (action.type) {
    //Reset order Package
    case OrderPackageConstants.RESET_ORDER_PACKAGE: {
      return {
        ...state,
        error: null,
        isLoadingPackageOrder: false,
        isActionPointagePackageDone: false,
        isActionPointagePackageErorr: false,
        orderPackage: [],
        orderPackageOriginal: [],
        warehousePackage: null,
        receptionDatePackage: null,
        errorPackage: null,
        isLoadingPackageStatus: false,
        packagesStatus: [],
        isLoadingPointagePackage: false,
        orderPackageLitigeUploadImage: null,
        isLoadingPointageForcePackage: false,
        isActionPointageForcePackageDone: false,
        isLoadingUploadImageLitige: false,
        errorImageLitigeUpload: false,
        isLoadingPackageLitigeMotif: false,
        packagesLitigeMotif: [],
        isLoadingPackageLitigeResponsabilite: false,
        packagesLitigeResponsabilite: [],
        isLoadingLitigeHistoriquePackage: false,
        litigeHistoriquePackage: null,
        isLoadingProduitsPackage: false,
        produitsPackage: [],
        isLoadingHistoriquesPackage: false,
        historiquesPackage: [],
        isLoadingPhotosHistoriquePackage: false,
        photosHistoriquePackage: null,
        isLoadingEtatPackage: false,
        isVisibiliteEtatPackage: false,
        isVisibiliteEtatPackageDone: false,
        isAnnulationLivraisonPartielleDone: false,
        isLoadingLivraisonPartielle: false,
        canalconfig: [],
        isLoadingUploadImageMDM: false,
        orderPackageMDMUploadImage: null,
        courseSource: null,
        isLoadingEditPackage: false,
        isActionEditPackageDone: false,
      }
    }
    //Fetch order Package
    case OrderPackageConstants.GET_PACKAGES_ORDER: {
      return {
        ...state,
        isLoadingPackageOrder: true,
        isActionPointagePackageDone: false,
        isActionPointagePackageErorr: false,
      }
    }

    case OrderPackageConstants.GET_PACKAGES_ORDER_SUCCESS: {
      const { courseSource, packages } = action.payload.data.response
      return {
        ...state,
        orderPackage: packages,
        orderPackageOriginal: packages,
        warehousePackage: packages[0]?.warehouseLabel || null,
        receptionDatePackage: packages[0]?.receptionDate?.date || null,
        isLoadingPackageOrder: false,
        errorPackage: null,
        courseSource: courseSource,
      }
    }

    case OrderPackageConstants.GET_PACKAGES_ORDER_FAILED: {
      return {
        ...state,
        isLoadingPackageOrder: false,
      }
    }

    //Fetch motif Etat package (Pointage)
    case OrderPackageConstants.GET_PACKAGES_STATUS: {
      return {
        ...state,
        isLoadingPackageStatus: true,
      }
    }

    case OrderPackageConstants.GET_PACKAGES_STATUS_SUCCESS: {
      const { response } = action.payload.data

      return {
        ...state,
        packagesStatus: response,
        isLoadingPackageStatus: false,
      }
    }

    case OrderPackageConstants.GET_PACKAGES_STATUS_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingPackageStatus: false,
      }
    }

    //Action Pointage package
    case OrderPackageConstants.POINTAGE_PACKAGES: {
      return {
        ...state,
        isLoadingPointagePackage: true,
        isActionPointagePackageDone: false,
        isActionPointagePackageErorr: false,
      }
    }

    case OrderPackageConstants.POINTAGE_PACKAGES_SUCCESS: {
      const responsePackages = action.payload.data.response

      let isActionPointagePackageErorr = false
      const newPackages = state.orderPackageOriginal.map((pack) => {
        let responsePackage =
          responsePackages.find((el) => el.code === pack.code) || {}
        if (Object.keys(responsePackage).length > 0 && !responsePackage?.valid) {
          isActionPointagePackageErorr = true
          const paylodForce = {
            status: responsePackage.deliveryStatus.code,
            deliveryStep: responsePackage.deliveryStep,
            addressBarCode: responsePackage?.addressBarCode
              ? responsePackage.addressBarCode
              : undefined,
            litigation: responsePackage?.litigation
              ? responsePackage.litigation
              : undefined,
            pictureBeforePacking: responsePackage?.pictureBeforePacking
              ? responsePackage?.pictureBeforePacking
              : [],
            pictureAfterPacking: responsePackage?.pictureAfterPacking
              ? responsePackage?.pictureAfterPacking
              : [],
            flashedAt: responsePackage.flashedAt,
          }
          pack = {
            ...pack,
            error: responsePackage.error,
            paylodForce: paylodForce,
          }
        }
        return pack
      })

      if (isActionPointagePackageErorr) {
        return {
          ...state,
          orderPackage: newPackages,
          isLoadingPointagePackage: false,
          isActionPointagePackageDone: false,
          isActionPointagePackageErorr,
          orderPackageLitigeUploadImage: null,
        }
      } else {
        return {
          ...state,
          isLoadingPointagePackage: false,
          isActionPointagePackageDone: true,
          isActionPointagePackageErorr,
          orderPackageLitigeUploadImage: null,
        }
      }
    }

    case OrderPackageConstants.POINTAGE_PACKAGES_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingPointagePackage: false,
        orderPackageLitigeUploadImage: null,
      }
    }

    //Action Forcage Pointage package
    case OrderPackageConstants.POINTAGE_FORCE_PACKAGES: {
      return {
        ...state,
        isLoadingPointageForcePackage: true,
        isActionPointageForcePackageDone: false,
      }
    }

    case OrderPackageConstants.POINTAGE_FORCE_PACKAGES_SUCCESS: {
      const responsePackages = action.payload.data.response

      // inset package forcé dans la list de package

      const packageForce = state.orderPackageOriginal.find(
        (el) => el.code === responsePackages[0].code
      )

      const packageForceIndex = state.orderPackage
        .map((el) => el.code)
        .indexOf(packageForce.code)

      let newPackages = []
      let modif = false

      if (packageForceIndex === 0) {
        newPackages = newPackages.concat(packageForce, state.orderPackage.slice(1))
        modif = true
      } else if (packageForceIndex === state.orderPackage.length - 1) {
        newPackages = newPackages.concat(
          state.orderPackage.slice(0, -1),
          packageForce
        )
        modif = true
      } else if (packageForceIndex > 0) {
        newPackages = newPackages.concat(
          state.orderPackage.slice(0, packageForceIndex),
          packageForce,
          state.orderPackage.slice(packageForceIndex + 1)
        )
        modif = true
      }
      return {
        ...state,
        isActionPointageForcePackageDone: true,
        isLoadingPointageForcePackage: false,
        orderPackage: modif ? newPackages : state.orderPackage,
      }
    }
    case OrderPackageConstants.POINTAGE_FORCE_PACKAGES_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingPointageForcePackage: false,
        isActionPointageForcePackageDone: false,
      }
    }

    //Upload image litige
    case OrderPackageConstants.UPLOAD_IMAGE_LITIGE: {
      return {
        ...state,
        isLoadingUploadImageLitige: true,
        orderPackageLitigeUploadImage: null,
      }
    }

    case OrderPackageConstants.UPLOAD_IMAGE_LITIGE_SUCCESS: {
      const { pictures, codePackage } = action.payload.data

      return {
        ...state,
        orderPackageLitigeUploadImage: { pictures, codePackage },
        isLoadingUploadImageLitige: false,
      }
    }

    case OrderPackageConstants.UPLOAD_IMAGE_LITIGE_FAILED: {
      return {
        ...state,
        errorImageLitigeUpload: true,
        isLoadingUploadImageLitige: false,
      }
    }

    //Set error upload image litige
    case OrderPackageConstants.SET_ERROR_UPLOAD_IMAGE_LITIGE: {
      const { errorImageLitigeUpload } = action.payload

      return {
        ...state,
        errorImageLitigeUpload,
      }
    }

    //Set Loading Upload Image Litige
    case OrderPackageConstants.SET_LOADING_UPLOAD_IMAGE_LITIGE: {
      const { loadingUploadImageLitige } = action.payload

      return {
        ...state,
        isLoadingUploadImageLitige: loadingUploadImageLitige,
      }
    }

    //Delete Image Litige Packages
    case OrderPackageConstants.DELETE_IMAGE_LITIGE: {
      return {
        ...state,
        orderPackageLitigeUploadImage: null,
        errorPackage: null,
      }
    }

    //Fetch Motif Litige Packages
    case OrderPackageConstants.GET_PACKAGES_LITIGE_MOTIF: {
      return {
        ...state,
        isLoadingPackageLitigeMotif: true,
      }
    }

    case OrderPackageConstants.GET_PACKAGES_LITIGE_MOTIF_SUCCESS: {
      const { response } = action.payload.data

      return {
        ...state,
        packagesLitigeMotif: response,
        isLoadingPackageLitigeMotif: false,
      }
    }

    case OrderPackageConstants.GET_PACKAGES_LITIGE_MOTIF_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingPackageLitigeMotif: false,
      }
    }

    //Fetch Motif  Responsabilite Litige Packages
    case OrderPackageConstants.GET_PACKAGES_LITIGE_RESPONSABILITE: {
      return {
        ...state,
        isLoadingPackageLitigeResponsabilite: true,
      }
    }

    case OrderPackageConstants.GET_PACKAGES_LITIGE_RESPONSABILITE_SUCCESS: {
      const { response } = action.payload.data

      return {
        ...state,
        packagesLitigeResponsabilite: response,
        isLoadingPackageLitigeResponsabilite: false,
      }
    }

    case OrderPackageConstants.GET_PACKAGES_LITIGE_RESPONSABILITE_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingPackageLitigeResponsabilite: false,
      }
    }

    //Fetch Litige Historique Package
    case OrderPackageConstants.GET_LITIGE_HISTORIQUE_PACKAGE: {
      return {
        ...state,
        isLoadingLitigeHistoriquePackage: true,
      }
    }

    case OrderPackageConstants.GET_LITIGE_HISTORIQUE_PACKAGE_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        litigeHistoriquePackage: response,
        isLoadingLitigeHistoriquePackage: false,
      }
    }

    case OrderPackageConstants.GET_LITIGE_HISTORIQUE_PACKAGE_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingLitigeHistoriquePackage: false,
      }
    }

    //Fetch Details Produits
    case OrderPackageConstants.GET_PRODUITS_PACKAGE: {
      return {
        ...state,
        isLoadingProduitsPackage: true,
      }
    }

    case OrderPackageConstants.GET_PRODUITS_PACKAGE_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        produitsPackage: response,
        isLoadingProduitsPackage: false,
      }
    }

    case OrderPackageConstants.GET_PRODUITS_PACKAGE_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingProduitsPackage: false,
      }
    }

    //Fetch Package Historiques
    case OrderPackageConstants.GET_HISTORIQUES_PACKAGE: {
      return {
        ...state,
        isLoadingHistoriquesPackage: true,
      }
    }

    case OrderPackageConstants.GET_HISTORIQUES_PACKAGE_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        historiquesPackage: response,
        isLoadingHistoriquesPackage: false,
      }
    }

    case OrderPackageConstants.GET_HISTORIQUES_PACKAGE_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingHistoriquesPackage: false,
      }
    }

    //Fetch Photos Historique Package
    case OrderPackageConstants.GET_PHOTOS_HISTORIQUE_PACKAGE: {
      return {
        ...state,
        isLoadingPhotosHistoriquePackage: true,
      }
    }

    case OrderPackageConstants.GET_PHOTOS_HISTORIQUE_PACKAGE_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        photosHistoriquePackage: response,
        isLoadingPhotosHistoriquePackage: false,
      }
    }

    case OrderPackageConstants.GET_PHOTOS_HISTORIQUE_PACKAGE_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingPhotosHistoriquePackage: false,
      }
    }

    //Fetch Etat Package
    case OrderPackageConstants.GET_ETAT_PACKAGE: {
      return {
        ...state,
        isLoadingEtatPackage: true,
        isVisibiliteEtatPackage: true,
        isVisibiliteEtatPackageDone: false,
        isAnnulationLivraisonPartielleDone: false,
      }
    }

    case OrderPackageConstants.GET_ETAT_PACKAGE_SUCCESS: {
      const responseEtatPackages = action.payload.data.response
      let tabEtatPackage = responseEtatPackages.reduce(
        (a, v) => ({ ...a, [v.code]: v }),
        {}
      )

      const newPackages = state.orderPackageOriginal.map((pack) => {
        if (Object.keys(tabEtatPackage).includes(pack.code)) {
          pack = {
            ...pack,
            etatColisLivree: true,
          }
        } else {
          pack = {
            ...pack,
            etatColisLivree: false,
          }
        }
        return pack
      })
      return {
        ...state,
        orderPackage: newPackages,
        isVisibiliteEtatPackageDone: true,
        isLoadingEtatPackage: false,
      }
    }

    case OrderPackageConstants.GET_ETAT_PACKAGE_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingEtatPackage: false,
        isVisibiliteEtatPackage: false,
      }
    }

    case OrderPackageConstants.ANNULATION_LIVRAISON_PARTIELLE_PACKAGE: {
      return {
        ...state,
        isVisibiliteEtatPackage: false,
        isVisibiliteEtatPackageDone: false,
        isAnnulationLivraisonPartielleDone: true,
        orderPackage: state.orderPackageOriginal,
      }
    }

    //Livraison Partielle
    case OrderPackageConstants.LIVRAISON_PARTIELLE_PACKAGE: {
      return {
        ...state,
        isLoadingLivraisonPartielle: true,
      }
    }
    case OrderPackageConstants.LIVRAISON_PARTIELLE_PACKAGE_SUCCESS: {
      return {
        ...state,
        isLoadingLivraisonPartielle: false,
      }
    }

    case OrderPackageConstants.LIVRAISON_PARTIELLE_PACKAGE_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingLivraisonPartielle: false,
      }
    }
    //Fetch motif Etat package (Pointage)
    case OrderPackageConstants.GET_PAKAGE_CONFIG_MDM: {
      return {
        ...state,
        isLoadingPackageStatus: true,
      }
    }

    case OrderPackageConstants.GET_PAKAGE_CONFIG_MDM_SUCCESS: {
      const { response } = action.payload.data

      return {
        ...state,
        canalconfig: response.metaValue,
        isLoadingPackageStatus: false,
      }
    }

    case OrderPackageConstants.GET_PAKAGE_CONFIG_MDM_FAILED: {
      const errorPackage = onFailure(action.payload)
      return {
        ...state,
        errorPackage,
        isLoadingPackageStatus: false,
      }
    }
    //Upload image litige
    case OrderPackageConstants.UPLOAD_IMAGE_MDM: {
      return {
        ...state,
        isLoadingUploadImageMDM: true,
        orderPackageMDMUploadImage: null,
      }
    }

    case OrderPackageConstants.UPLOAD_IMAGE_MDM_SUCCESS: {
      const { response } = action.payload.data
      return {
        ...state,
        orderPackageMDMUploadImage: response,
        isLoadingUploadImageMDM: false,
      }
    }

    case OrderPackageConstants.UPLOAD_IMAGE_MDM_FAILED: {
      return {
        ...state,
        errorImageLitigeUpload: true,
        isLoadingUploadImageMDM: false,
      }
    }
    //Set Loading Upload Image MDM
    case OrderPackageConstants.SET_LOADING_UPLOAD_IMAGE_MDM: {
      const { loadingUploadImageMDM } = action.payload

      return {
        ...state,
        isLoadingUploadImageMDM: loadingUploadImageMDM,
      }
    }
    //Edit Package
    case OrderPackageConstants.EDIT_PACKAGE: {
      return {
        ...state,
        isLoadingEditPackage: true,
        isActionEditPackageDone: false,
      }
    }

    case OrderPackageConstants.EDIT_PACKAGE_SUCCESS: {
      const { response } = action.payload.data

      // inset package a modifiée dans la list de package
      const packageObject = state.orderPackage.find(
        (el) => el.packageId === response.id
      )
      const packageInset = {
        ...packageObject,
        weight: response.poid,
        label: response.label,
      }

      const packageForceIndex = state.orderPackage
        .map((el) => el.packageId)
        .indexOf(response.id)

      let newPackages = []
      let modif = false

      if (packageForceIndex === 0) {
        newPackages = newPackages.concat(packageInset, state.orderPackage.slice(1))
        modif = true
      } else if (packageForceIndex === state.orderPackage.length - 1) {
        newPackages = newPackages.concat(
          state.orderPackage.slice(0, -1),
          packageInset
        )
        modif = true
      } else if (packageForceIndex > 0) {
        newPackages = newPackages.concat(
          state.orderPackage.slice(0, packageForceIndex),
          packageInset,
          state.orderPackage.slice(packageForceIndex + 1)
        )
        modif = true
      }
      return {
        ...state,
        isLoadingEditPackage: false,
        orderPackage: modif ? [...newPackages] : state.orderPackage,
        isActionEditPackageDone: true,
      }
    }

    case OrderPackageConstants.EDIT_PACKAGE_FAILED: {
      const errorPackage = onFailure(action.payload)

      return {
        ...state,
        isLoadingEditPackage: false,
        errorPackage,
      }
    }
    default:
      return state
  }
}
