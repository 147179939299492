import axios from 'axios'
import checkExpirationDate, {
  COLLABORATOR_EXPIRATION_DATE,
} from './checkExpirationDate'
axios.interceptors.request.use(
  (config) => {
    if (!config.headers['X-Auth-Token']) {
      checkExpirationDate(COLLABORATOR_EXPIRATION_DATE)
      config.headers = { 'X-Auth-Token': localStorage.getItem('token') }
    }
    return config
  },
  (error) => Promise.reject(error)
)
// because BE returns code in body different than the response status, the error won't be catched ( ex: body: 400 , status 200)
// ==> so I have used interceptor to reject the response if it's code >= 400
axios.interceptors.response.use(
  function (response) {
    if (parseInt(response.data.code) >= 400) {
      return Promise.reject(response)
    }
    return response
  },
  function (error) {
    console.log('error in response: ', error)
    // catch 401
    if (
      error.toString() === 'Error: Request failed with status code 401' ||
      error?.response?.data?.code === 401 ||
      error?.response?.status === 401
    ) {
      localStorage.clear()
      window.location.replace('/login')
      return
    }
    return Promise.reject(error)
  }
)

export default axios
