import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../../../components/ModalDialog'
import CreateIcon from '../../../../assets/icons/settingsCorner/iconsCreate'
import { StyledDivContent, StyledInputTitle, sxInput } from '../../styled'
import AutoComplete from '../../../../components/AutoComplete'

function SalesChannelModal({
  open,
  handleClose,
  modalTitle,
  salesChannels,
  keys,
  fetchKeys,
  searchKeys,
  getSalesChannels,
  assign,
  isLoadingAssign,
  success,
  errorMessage,
  isLoadingKeysList,
  fetchMoreKeys,
  keysCount,
}) {
  const [salesChannel, setsalesChannel] = useState('')
  const [select, setSelect] = useState(false)
  const [init, setInit] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [key, setKey] = useState('')
  const [offset, setOffset] = useState(25)
  const UserId = String(JSON.parse(localStorage.getItem('currentUser'))?.id)
  const date = new Date().toISOString()

  useEffect(() => {
    getSalesChannels()
    fetchKeys()
    setInit(true)
  }, [])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchKey.length > 0 && !select) {
        searchKeys(searchKey)
        setOffset(25)
      } else if (searchKey == '') {
        if (init) {
          searchKeys('')
        }
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchKey])
  const onChangeSearchKey = (value) => {
    setSelect(false)
    if (value) {
      let val = value.trim().toUpperCase()
      setSearchKey(val)
    } else {
      setKey('')
      setSearchKey('')
      setSelect(false)
    }
  }

  useEffect(() => {
    if (success) {
      handleClose()
    }
  }, [success])

  const onSelect = (value) => {
    if (value) {
      setsalesChannel(value)
    }
  }
  const onSelectKey = (value) => {
    if (value) {
      setKey(value)
      setSelect(true)
    }
  }
  const onChangeAssign = () => {
    const data = {
      affectDate: date,
      keyId: key.id,
      userId: String(UserId),
      salesChanelCode: salesChannel.code,
    }
    assign(data)
  }
  const loadMoreKeys = (event) => {
    if (
      Math.round(event.target.scrollHeight - event.target.scrollTop) ==
        event.target.clientHeight &&
      keysCount >= offset
    ) {
      setOffset(offset + 25)
      fetchMoreKeys(offset)
    }
  }
  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={modalTitle}
      iconTitle={<CreateIcon />}
      style={errorMessage ? { display: 'none' } : {}}
      content={
        <StyledDivContent>
          <StyledInputTitle>Canal</StyledInputTitle>

          <AutoComplete
            value={salesChannel}
            onChange={(event, value) => {
              onSelect(value)
            }}
            id="Canal-list"
            options={salesChannels}
            getOptionLabel={(option) => `${option.name}`}
            variant="outlined"
            placeholder={'Canal'}
            sxInputStyle={sxInput}
            disabled={salesChannels.length === 0}
            renderOption={(props, option, index) => (
              <li key={index.index} id={`canal-${option.id}`} {...props}>
                <div className="AutoCompteFilterOption"> {`${option.name} `}</div>
              </li>
            )}
          />
          <div style={{ marginTop: '20px', width: '100%' }}>
            <StyledInputTitle>Clé </StyledInputTitle>{' '}
            <AutoComplete
              value={key}
              onChange={(event, value) => {
                onSelectKey(value)
              }}
              onInputChange={(event, value) => onChangeSearchKey(value)}
              id="Cle-Canal-list"
              options={keys}
              getOptionLabel={(option) => option.value}
              variant="outlined"
              placeholder={'Choisir une clé'}
              sxInputStyle={sxInput}
              ListboxProps={{
                onScroll: loadMoreKeys,
              }}
              renderOption={(props, option) => (
                <li {...props} id={`cle-${option.id}`} key={option.id}>
                  <div className="AutoCompteFilterOption">{option.value}</div>
                </li>
              )}
              loading={isLoadingKeysList}
            />
          </div>
        </StyledDivContent>
      }
      handleClose={handleClose}
      handleClickAction={onChangeAssign}
      disabled={!salesChannel || !key}
      isLoadingButtonAction={isLoadingAssign}
    />
  )
}
SalesChannelModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  keys: PropTypes.array,
  modalTitle: PropTypes.string,
  salesChannels: PropTypes.array,
  fetchKeys: PropTypes.func,
  fetchSalesChannels: PropTypes.func,
  fetchSalesChannelsMore: PropTypes.func,
  SalesChannelsCount: PropTypes.number,
  isLoadingSalesChannels: PropTypes.bool,
  searchKeys: PropTypes.func,
  assign: PropTypes.func,
  isLoadingAssign: PropTypes.bool,
  errorMessage: PropTypes.bool,
  success: PropTypes.bool,
  getSalesChannels: PropTypes.func,
  isLoadingKeysList: PropTypes.bool,
  fetchMoreKeys: PropTypes.func,
  keysCount: PropTypes.number,
}

export default SalesChannelModal
